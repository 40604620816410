import {
  EXEC_VOCALASSISTANT_ACTION,
  FIND_CALL_NUMBER_REQUEST,
  FIND_CALL_NUMBER_SUCCESS,
  FIND_CALL_NUMBER_FAILURE,
} from './types';

export const execVocalassistantAction = (payload) => ({
  type: EXEC_VOCALASSISTANT_ACTION,
  payload,
});
export const findCallNumberRequest = (payload) => ({
  type: FIND_CALL_NUMBER_REQUEST,
  payload,
});
export const findCallNumberSuccess = (number) => ({
  type: FIND_CALL_NUMBER_SUCCESS,
  number,
});
export const findCallNumberFailure = () => ({
  type: FIND_CALL_NUMBER_FAILURE,
});
