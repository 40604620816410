import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Popover from '../../../components/Popover';
import { Label } from 'reactstrap';

const messages = defineMessages({
  header: {
    id: 'AbookListsContactsPopover.header',
    defaultMessage: 'Contacts:',
  },
  contacts: {
    id: 'AbookListsContactsPopover.contacts',
    defaultMessage: 'Contacts: ',
  },
  withEmail: {
    id: 'AbookListsContactsPopover.withEmail',
    defaultMessage: 'With email: ',
  },
  withPostalAddress: {
    id: 'AbookListsContactsPopover.withPostalAddress',
    defaultMessage: 'With postal address: ',
  },
});

const AbookListsContactsPopover = ({
  contacts,
  isOpen,
  target,
  toggle,
  intl: { formatMessage },
}) => {
  return (
    <Popover
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      header={formatMessage(messages.header)}
    >
      <div>
        <Label>
          {formatMessage(messages.contacts)}
          {contacts.length}
        </Label>
      </div>
      <div>
        <Label>
          {formatMessage(messages.withEmail)}
          {contacts.filter((c) => c.email).length}
        </Label>
      </div>
      <div>
        <Label>
          {formatMessage(messages.withPostalAddress)}
          {contacts.filter((c) => c.addresses && c.addresses.length).length}
        </Label>
      </div>
      {contacts &&
        contacts.map((c) => <div key={`contact_${c.id}`}>{c.fullname}</div>)}
    </Popover>
  );
};

AbookListsContactsPopover.propTypes = {
  contacts: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

AbookListsContactsPopover.defaultProps = {
  contacts: [],
};

export default injectIntl(AbookListsContactsPopover);
