import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '../../../components/formControls/Button';
import KeyPad from './KeyPad';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  online: {
    id: 'PhonePanelGuest.title.online',
    defaultMessage: 'Online',
  },
  call: {
    id: 'PhonePanelGuest.button.call',
    defaultMessage: 'Call',
  },
});

const PhonePanelGuest = ({ intl: { formatMessage } }) => (
  <div className="card" id="PhonePanelGuest">
    <div className="media p-1">
      <span
        className="rounded-circle align-self-center d-inline-block"
        style={{
          background: 'var(--green)',
          width: '1.8rem',
          height: '1.8rem',
        }}
      />
      <div className="media-body text-left p-2 row">
        <div className="col-9">{formatMessage(messages.online)}</div>
      </div>
    </div>
    <div className="p-1 border-bottom border-top text-left position-relative">
      <span
        style={{
          height: '0.7rem',
          width: '0.7rem',
          display: 'block',
          position: 'absolute',
          top: '0.7rem',
          right: '0.8rem',
          color: 'var(--yn-blue)',
          cursor: 'pointer',
        }}
      >
        <Icon name="close-filled" width={12} height={12} />
      </span>
      <input type="text" className="form-control" disabled />
    </div>
    <KeyPad disabled />
    <div className="p-1">
      <Button
        text={formatMessage(messages.call)}
        className="bt btn-success btn-block"
        disabled
      />
    </div>
  </div>
);

export default injectIntl(PhonePanelGuest);
