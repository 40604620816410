import $ from 'jquery';
import { PhoneEnums } from './PhoneUtils';
import { PbxSettings } from './PbxSettingsUtils';
import { request } from '../request';
import Utils from '../lib/utils';

const actions = {
  call: 'CALL',
  hangupCall: 'CLOSE',
  switchCalls: 'SWITCHCALL',
  holdCall: 'HOLD',
  unholdCall: 'UNHOLD',
  callTransfer: 'TRANSFER',
  blindTransfer: '3PTRANSFER',
  queueAnswer: 'CHANGEQUEUE',
};

export class AbilisCommands {
  constructor(data) {
    this.abilisType = data.abilisType;
    this.operator = data.operator;
    this.url = data.url;
    this.username = data.username;
    this.pbxInterface = null;
  }

  setAbilisType = (abilisType) => {
    this.abilisType = abilisType;
  };

  setOperator = (operator) => {
    this.operator = operator;
  };

  setInterface = (pbxInterface) => {
    this.pbxInterface = pbxInterface;
  };

  send = (xmlDoc) =>
    new Promise((resolve, reject) => {
      request(
        {
          url: PbxSettings.CTI_URL,
          method: 'POST',
          data: xmlDoc,
        },
        {
          baseUrl: this.url,
          withCredentials: true,
        }
      )
        .then((response) => {
          if ($('rsp', $(response.data)).text() !== 'OK') {
            reject($('errcode', $(response.data)).text());
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  buildCtiControlStringDocument = (user, abilisInterface, command, params) => {
    const strDoc = [
      '<?xml version="1.0" encoding="ISO-8859-1"?><CtiControl>',
      '<cmd A="',
      this.abilisType,
      '" U="',
      user,
      '" I="',
      abilisInterface,
      '">',
      command,
      '</cmd>',
    ];
    for (let i = 0; i < params.length; i += 1) {
      strDoc.push(
        '<par name="',
        params[i].key,
        '">',
        params[i].value,
        '</par>'
      );
    }
    strDoc.push('</CtiControl>');
    return strDoc.join('');
  };

  sendCommand = (command, params, hideInterface) => {
    const xmlStrRequest = this.buildCtiControlStringDocument(
      hideInterface ? '' : this.operator,
      hideInterface ? '' : this.pbxInterface,
      command,
      params || []
    );
    return this.send(xmlStrRequest);
  };

  serialize = (obj) => {
    const str = [];
    // eslint-disable-next-line prefer-const
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  };

  login = (password, timeout, username, url) => {
    const req = () =>
      request(
        {
          url: '/login.htm',
          method: 'POST',
          data: this.serialize({
            username: username || this.username,
            password,
            login_method: 0,
            Login: 'Send',
          }),
        },
        {
          baseUrl: url || this.url,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          withCredentials: true,
        }
      );

    const exec = () => {
      return new Promise((resolve, reject) => {
        if (timeout) {
          setTimeout(
            () =>
              req()
                .then((response) => resolve(response))
                .catch((err) => reject(err)),
            timeout
          );
        } else {
          req()
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        }
      });
    };
    return exec();
  };

  logout = () =>
    request(
      {
        url: '/logout.htm',
        method: 'POST',
        data: this.serialize({
          logout: 'YES',
        }),
      },
      {
        baseUrl: this.url,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      }
    );

  getInitialStatus = () =>
    request(
      {
        url: '/sys/opc/GetSesStatus.xml',
        method: 'GET',
      },
      {
        baseUrl: this.url,
        withCredentials: true,
      }
    );

  getStatusChanges = (sessionId) =>
    request(
      {
        url: `/sys/opc/GetSesChanges.xml?SesId=${sessionId}`,
        method: 'GET',
      },
      {
        baseUrl: this.url,
        withCredentials: true,
      }
    );

  toggleDND = (status) => {
    const xmlCommand =
      this.pbxInterface === PhoneEnums.Interfaces.CTIP
        ? '<?xml version="1.0" encoding="ISO-8859-1"?><CtiPreferences>' +
          `<record user="${this.username}">` +
          `<param name="ctip-action">${status.toLowerCase()}</param>` +
          '</record></CtiPreferences>'
        : '<?xml version="1.0" encoding="ISO-8859-1"?><CtiPreferences>' +
          `<record user="${this.username}">` +
          `<param name="sip-action">${status.toLowerCase()}</param>` +
          '</record></CtiPreferences>';

    return request(
      {
        url: '/sys/user/cti_pref_set.xml',
        method: 'POST',
        data: xmlCommand,
      },
      {
        baseUrl: this.url,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      }
    );
  };

  callNumber = (number) => {
    const params = [
      {
        key: 'NUM',
        value: Utils.adjustAbilisPhoneNumber(number),
      },
    ];
    return this.sendCommand(actions.call, params);
  };

  hangupCall = (callid) => {
    const params = [
      {
        key: 'CallId',
        value: callid,
      },
    ];
    return this.sendCommand(actions.hangupCall, params);
  };

  switchCalls = () => this.sendCommand(actions.switchCalls);

  holdCall = (callid) => {
    const params = [
      {
        key: 'CallId',
        value: callid,
      },
    ];
    return this.sendCommand(actions.holdCall, params);
  };

  unholdCall = (callid) => {
    const params = [
      {
        key: 'CallId',
        value: callid,
      },
    ];
    return this.sendCommand(actions.unholdCall, params);
  };

  blindTransfer = (callId, to) => {
    const params = [
      {
        key: 'CallId',
        value: callId,
      },
      {
        key: 'NUM',
        value: Utils.adjustAbilisPhoneNumber(to),
      },
      {
        key: 'PHASE',
        value: 'ALERTING',
      },
      {
        key: 'OVERTAKE',
        value: 'YES',
      },
    ];
    return this.sendCommand(actions.blindTransfer, params);
  };

  queueTransfer = (queueId, to) => {
    const params = [
      {
        key: 'UUID',
        value: queueId,
      },
      {
        key: 'NUM',
        value: Utils.adjustAbilisPhoneNumber(to),
      },
      {
        key: 'POSITION',
        value: 'LAST',
      },
    ];
    return this.sendCommand(actions.queueAnswer, params, true);
  };

  queueAnswer = (queueId, number) => {
    const params = [
      {
        key: 'UUID',
        value: queueId,
      },
      {
        key: 'NUM',
        value: Utils.adjustAbilisPhoneNumber(number),
      },
      {
        key: 'POSITION',
        value: 'LAST',
      },
      {
        key: 'OVERTAKE',
        value: 'YES',
      },
    ];
    return this.sendCommand(actions.queueAnswer, params, true);
  };

  callTransfer = () => this.sendCommand(actions.callTransfer, []);

  addUserSetting = (settings, url) =>
    new Promise((resolve, reject) => {
      request(
        {
          url: '/sys/user/cti_cf_preferences_add_post.htm',
          method: 'POST',
          data: this.serialize(settings),
        },
        {
          baseUrl: url || this.url,
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
        .then((response) => {
          const result$ = $(Utils.adjustAbilisHtml(response.data));
          if (
            result$.filter("pre:contains('SAVE PREFERENCES EXECUTED')").length <
            1
          ) {
            reject();
          } else {
            resolve();
          }
        })
        .catch((err) => reject(err));
    });

  updateUserSetting = (settings, url) =>
    new Promise((resolve, reject) => {
      request(
        {
          url: '/sys/user/cti_cf_preferences_modify_post.htm',
          method: 'POST',
          data: this.serialize(settings),
        },
        {
          baseUrl: url || this.url,
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
        .then((response) => {
          const result$ = $(Utils.adjustAbilisHtml(response.data));
          if (
            result$.filter("pre:contains('SAVE PREFERENCES EXECUTED')").length <
            1
          ) {
            reject();
          } else {
            resolve();
          }
        })
        .catch((err) => reject(err));
    });

  retrieveUserSettings = (url) =>
    request(
      {
        url: '/sys/user/cti_cf_preferences.htm',
        method: 'GET',
      },
      {
        baseUrl: url || this.url,
        withCredentials: true,
      }
    );

  deleteUserSettings = (rule, url) =>
    new Promise((resolve, reject) =>
      request(
        {
          url: '/sys/user/cti_cf_preferences_delete_post.htm',
          method: 'POST',
          data: this.serialize({
            priority: rule.priority,
            user: rule.user,
            owner_type: rule.ownerType,
            owner_id: rule.ownerId,
            Yes: 'Yes',
          }),
        },
        {
          baseUrl: url || this.url,
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
        .then((response) => {
          const result$ = $(Utils.adjustAbilisHtml(response.data));
          if (
            result$.filter("pre:contains('SAVE PREFERENCES EXECUTED')").length <
            1
          ) {
            reject();
          } else {
            resolve();
          }
        })
        .catch((err) => reject(err))
    );

  retrieveLostCalls = () =>
    request(
      {
        url: '/sys/user/GetCtiCallsLogIn.xml?callenabled_ctip=yes&callenabled_sip=yes',
        method: 'GET',
      },
      {
        baseUrl: this.url,
        withCredentials: true,
      }
    );
}
