import VocalAssistantSystemUtils from './VocalAssistantSystemUtils';
import VocalAssistantUtils from './VocalAssistantUtils';

export default class Speech {
  constructor(language) {
    this.speakActive = false;
    this.recognition = null;
    this.continous = false;
    this.language = language || 'it-IT';
  }

  isSpeakActive = () => {
    return this.speakActive;
  };

  speak = (message, callback) => {
    if (!message) {
      return;
    }
    if (VocalAssistantSystemUtils.isTtsSupported()) {
      let chunk;
      // / check if message is less than 220 (maximum for speaking is 250) else read it in chunk
      if (message.length > 220) {
        // / take a chunk until last comma or space (if comma not found)
        const lastSeparationPosition =
          message.substring(0, 220).lastIndexOf('.') >= 0
            ? message.substring(0, 220).lastIndexOf('.')
            : message.substring(0, 220).lastIndexOf(' ');
        chunk = message.substring(0, lastSeparationPosition + 1);
        message = message.substring(lastSeparationPosition + 1);
      } else {
        chunk = message;
        message = null;
      }
      const msg = new SpeechSynthesisUtterance(chunk);
      // / speak the language in the language used on youneed (not Male)
      msg.voice = VocalAssistantSystemUtils.getSpeechVoice(
        VocalAssistantUtils.getLanguageISOcode(this.language)
      );
      // / safari speak too much fast, change its rate
      if (VocalAssistantSystemUtils.isSafari()) {
        msg.rate = 0.1;
      }
      // / take volume from youneed settings
      const volume = 100;
      msg.volume = volume / 100;
      // / @see http://stackoverflow.com/questions/23483990/speechsynthesis-api-onend-callback-not-working
      // / store the utterance to prevent garbage collector
      window.utterances = [msg];
      if (message) {
        msg.onend = () => {
          this.speak(message, callback);
        };
      } else {
        msg.onend = () => {
          this.speakActive = false;
          callback && callback();
        };
      }
      window.speechSynthesis.speak(msg);
      this.speakActive = true;
    } else {
      this.speakActive = true;
      const secondsReading = message.length * 70;
      setTimeout(() => {
        this.speakActive = false;
        callback && callback();
      }, secondsReading);
    }
  };

  stopSpeaking = () => {
    window.speechSynthesis && window.speechSynthesis.cancel();
  };

  activateRecognition = (prm) => {
    this.continous = true;
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      return;
    }
    this.recognition = new SpeechRecognition();
    this.recognition.lang = VocalAssistantUtils.getLanguageISOcode(
      this.language
    );

    this.recognition.interimResults = false;
    // / continous = true, does not always work well
    this.recognition.continuous = false;
    let result = false;
    this.recognition.onsoundstart = () => {
      result = false;
      prm.onstart && prm.onstart();
    };
    this.recognition.onresult = (event) => {
      result = true;
      // / stop speaking
      this.stopSpeaking();
      // / retrieve text
      const speechText = event.results[event.results.length - 1][0].transcript
        .toLowerCase()
        .trim();
      prm.onresult && prm.onresult(speechText);
      this.recognition.stop();
    };
    this.recognition.onend = () => {
      prm.onend && prm.onend(result);
      result = false;
      // / if continous, start a new recognition
      if (this.continous) {
        this.recognition.start();
      } else {
        this.recognition = null;
      }
    };
    this.recognition.start();
  };

  stopRecognition = () => {
    if (!this.recognition) {
      return;
    }
    // / prevent new recognition on onend event
    this.continous = false;
    this.recognition.stop();
  };
}
