import React from 'react';
import { connect } from 'react-redux';
import Validations from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { isMeVacationsValidator } from '../../../js/me/selectors';
const AdminValidationsVacationsList = React.lazy(() =>
  import('./AdminValidationsVacationsList')
);
const AdminValidationsVacationsPage = ({ props, canManageVacations }) => (
  <Validations scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        {canManageVacations ? (
          <AdminValidationsVacationsList {...props} />
        ) : (
          <ErrorBoundary code={304} />
        )}
      </React.Suspense>
    </ErrorBoundary>
  </Validations>
);

function mapStateToProps(state) {
  return {
    canManageVacations: isMeVacationsValidator(state),
  };
}
export default connect(mapStateToProps)(AdminValidationsVacationsPage);
