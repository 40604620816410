import React from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import MePhoneSettings from './MePhoneSettings';

const MePhoneRulesShow = React.lazy(() => import('./MePhoneRulesShow'));
const MePhoneRulesShowPage = (props) => (
  <MePhoneSettings scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <MePhoneRulesShow {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </MePhoneSettings>
);

export default MePhoneRulesShowPage;
