import {
  SAVE_LOGO_REQUEST,
  SAVE_LOGO_SUCCESS,
  SAVE_LOGO_FAILURE,
  SET_LOGO_PREVIEW,
} from './types';

export const saveLogoRequest = (payload) => ({
  type: SAVE_LOGO_REQUEST,
  payload,
});
export const saveLogoSuccess = (payload, success) => ({
  type: SAVE_LOGO_SUCCESS,
  payload,
  success,
});
export const saveLogoFailure = (errors) => ({
  type: SAVE_LOGO_FAILURE,
  errors,
});

export const setLogoPreview = (data) => ({
  type: SET_LOGO_PREVIEW,
  data,
});
