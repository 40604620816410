import * as React from 'react';
import { NavItem } from 'reactstrap';
import { DragSource } from 'react-dnd';
import { injectIntl, defineMessages } from 'react-intl';
import { getEmptyImage } from 'react-dnd-html5-backend';
import Icon from '../../../components/icons/Icon';
import Utils from '../../../js/lib/utils';

const messages = defineMessages({
  aidTransfer: {
    id: 'AssistedTransferButton.tooltip.aidTransfer',
    defaultMessage: 'Assisted transfer',
  },
});

const assistedTransferSource = {
  beginDrag(props) {
    return {
      callId: props.callId,
    };
  },
};

class AssistedTransferButton extends React.Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    connectDragPreview(getEmptyImage());
  }

  render() {
    const {
      intl: { formatMessage },
      handleAidTransferClick,
      connectDragSource,
    } = this.props;

    return connectDragSource(
      <div>
        <NavItem>
          <span onClick={handleAidTransferClick} className="mx-1">
            <Icon name="phone-call-split" color="var(--yn-blue-dark)">
              {formatMessage(messages.aidTransfer)}{' '}
            </Icon>
          </span>
        </NavItem>
      </div>
    );
  }
}

export default DragSource(
  Utils.DragAndDropTypes.attendedTransfer,
  assistedTransferSource,
  (connect) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  })
)(injectIntl(AssistedTransferButton));
