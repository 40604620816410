import React from 'react';
import Document from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const PhoneLines = React.lazy(() => import('./PhoneLines'));
const PhoneLinesPage = (props) => (
  <Document>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PhoneLines {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Document>
);

export default PhoneLinesPage;
