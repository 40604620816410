import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { throttle, debounce } from 'throttle-debounce';
import {
  fetchSearchedContactsRequest,
  setSearchContactsFilter,
} from '../js/phone/actions';
import {
  getSearchedContacts,
  isSearchedContactsLoaded,
  isSearchContactsListOpen,
  getSearchedNumber,
  isSearchedContactSelected,
} from '../js/phone/selectors';
import PhoneNumberSearchElement from './PhoneNumberSearchElement';
import Popover from './Popover';

class PhoneNumberSearchList extends Component {
  constructor(props) {
    super(props);
    this.fetchThrottled = throttle(500, this.fetch);
    this.fetchDebounced = debounce(500, this.fetch);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter && !this.props.selected) {
      const params = {
        filter: this.props.filter,
        page: 0,
        pageSize: 50,
        numberTypes: this.props.numberTypes || [
          'MOBILE',
          'OFFICE',
          'HOME',
          'OTHERS',
        ],
        target: this.props.target,
      };
      if (!this.props.filter || this.props.filter === '') {
        this.fetch(params);
      } else if (this.props.filter.length < 5) {
        this.fetchThrottled(params);
      } else {
        this.fetchDebounced(params);
      }
    }
  }

  fetch = (params) => {
    this.props.fetch(params);
  };

  handleSelect = (number, isExtension) => {
    this.props.onSelect(number, isExtension);
    this.props.setFilter({
      target: this.props.target,
      number,
      listOpen: false,
      selected: true,
    });
  };

  toggle = () => {
    this.props.setFilter({
      target: this.props.target,
      listOpen: false,
      selected: false,
    });
  };

  render() {
    const { contacts, loaded, target, placement, isOpen } = this.props;
    return (
      isOpen && (
        <Popover
          hideArrow
          isOpen={isOpen}
          toggle={this.toggle}
          target={target}
          placement={placement}
          loading={!loaded}
        >
          <ul className="list-unstyled">
            {contacts.length > 0 &&
              contacts.map((contact, idx) => (
                <PhoneNumberSearchElement
                  key={idx}
                  contact={contact}
                  onClick={() =>
                    this.handleSelect(
                      contact.number,
                      contact.type === 'EXTENSION'
                    )
                  }
                />
              ))}
          </ul>
        </Popover>
      )
    );
  }
}

PhoneNumberSearchList.propTypes = {
  target: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  numberTypes: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  return {
    loaded: isSearchedContactsLoaded(state),
    contacts: getSearchedContacts(state),
    isOpen: isSearchContactsListOpen(state, ownProps.target),
    filter: getSearchedNumber(state, ownProps.target),
    selected: isSearchedContactSelected(state, ownProps.target),
  };
}

export default connect(mapStateToProps, {
  fetch: fetchSearchedContactsRequest,
  setFilter: setSearchContactsFilter,
})(PhoneNumberSearchList);
