import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const AdminAssociationsList = React.lazy(() =>
  import('./AdminAssociationsList')
);

const AdminAssociationsListPage = (props) => (
  <Groups scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AdminAssociationsList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminAssociationsListPage;
