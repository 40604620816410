import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Calendar from './index';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const CalendarAppointmentReportDashboard = React.lazy(() =>
  import('./CalendarAppointmentReportDashboard')
);
const CalendarAppointmentReportDashboardPage = () => {
  return (
    <Calendar scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="appointments-dashboard" />
            </div>
            <CalendarAppointmentReportDashboard />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </Calendar>
  );
};

export default CalendarAppointmentReportDashboardPage;
