import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { getMeType } from '../../../js/me/selectors';
import { MeEnums } from '../../../js/me/MeUtils';
import {
  getVideocallNotifications,
  isVideocallNotificationsLoaded,
} from '../../../js/videocalls/selectors';
import { getLostVideocallNotifications } from '../../../js/notifications/selectors';

const messages = defineMessages({
  title: {
    id: 'Videocall.title',
    defaultMessage: 'Video calls',
  },
  exec: {
    id: 'Videocall.exec.navLabel',
    defaultMessage: 'Exec',
  },
  list: {
    id: 'Videocall.list.navLabel',
    defaultMessage: 'Archive',
  },
  settings: {
    id: 'Videocall.settings.navLabel',
    defaultMessage: 'Settings',
  },
  notifications: {
    id: 'Videocall.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
  scheduled: {
    id: 'Videocall.scheduled.navLabel',
    defaultMessage: 'Scheduled',
  },
});

const JitsiVideocalls = ({
  intl: { formatMessage },
  children,
  notificationsExecTab,
  notificationsListTab,
  meType,
  notificationsLoaded,
  notifications,
  lostVideocallNotifications,
}) => {
  const nav = [
    {
      id: 'JitsiVideocalls.exec.linkLabel',
      href: '/videocall/exec',
      label: messages.exec,
      notifications: notificationsExecTab,
    },
    {
      id: 'JitsiVideocalls.list.linkLabel',
      href: '/videocall/list',
      label: messages.list,
      notifications: notificationsListTab,
    },
    {
      id: 'JitsiVideocalls.scheduled.linkLabel',
      href: '/videocall/scheduled',
      label: messages.scheduled,
    },
    {
      id: 'JitsiVideocalls.settings.linkLabel',
      href: '/videocall/settings',
      label: messages.settings,
    },
  ];

  if (
    notificationsLoaded &&
    (notifications.length || lostVideocallNotifications.length)
  ) {
    nav.push({
      id: 'JitsiVideocalls.settings.linkLabel',
      href: '/videocall/notifications',
      label: messages.notifications,
      notifications: notifications.length + lostVideocallNotifications.length,
    });
  }

  const isUser = meType === MeEnums.MeTypes.USER;

  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      {isUser && <SecondaryNav tabs={nav} />}
      <div
        className={`${
          isUser ? 'yn-section-with-nav-scrolling' : 'yn-section-scrolling'
        } p-3`}
      >
        {children}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    meType: getMeType(state),
    notifications: getVideocallNotifications(state),
    lostVideocallNotifications: getLostVideocallNotifications(state),
    notificationsLoaded: isVideocallNotificationsLoaded(state),
  };
}
export default injectIntl(connect(mapStateToProps)(JitsiVideocalls));
