import React from 'react';
import PropTypes from 'prop-types';
import ynConf from '../conf';

const GroupAvatar = ({ main, size, alt, className, clickable }) => {
  const style = {
    cursor: clickable ? 'pointer' : 'default',
  };

  const width =
    size === 'sm' ? 25 : size === 'md' ? 40 : size === 'lg' ? 80 : 40;
  const height = width;

  return (
    <div className="d-inline-block mb-1 ml-1">
      <img
        className={`rounded-circle ${className}`}
        style={style}
        src={
          main
            ? `${ynConf.publicFolderPath}/balloon-megaphone.jpg`
            : `${ynConf.publicFolderPath}/balloon-users.jpg`
        }
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

GroupAvatar.propTypes = {
  main: PropTypes.bool,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  clickable: PropTypes.bool,
};

GroupAvatar.defaultProps = {
  main: false,
  size: 'md',
  alt: 'YouNeed Unknown Image',
  className: 'd-inline-block',
  clickable: false,
};

export default GroupAvatar;
