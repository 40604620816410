import {
  FETCH_FILEBOX_ITEMS_REQUEST,
  SET_FILEBOX_FILTER,
  FETCH_FILEBOX_ITEMS_SUCCESS,
  FETCH_FILEBOX_ITEMS_FAILURE,
  FETCH_FILEBOX_SPACE_USAGE_SUCCESS,
  FETCH_FILEBOX_SPACE_USAGE_FAILURE,
  FETCH_FILEBOX_SPACE_USAGE_REQUEST,
  DELETE_FILEBOX_FILE_REQUEST,
  DELETE_FILEBOX_FILE_SUCCESS,
  DELETE_FILEBOX_FILE_FAILURE,
  CREATE_FILEBOX_FOLDER_REQUEST,
  CREATE_FILEBOX_FOLDER_SUCCESS,
  CREATE_FILEBOX_FOLDER_FAILURE,
  DELETE_FILEBOX_FOLDER_REQUEST,
  DELETE_FILEBOX_FOLDER_SUCCESS,
  DELETE_FILEBOX_FOLDER_FAILURE,
  RENAME_FILEBOX_FILE_REQUEST,
  RENAME_FILEBOX_FILE_SUCCESS,
  RENAME_FILEBOX_FILE_FAILURE,
  FILEBOX_CLEAR_ERRORS,
  EDIT_FILEBOX_FOLDER_REQUEST,
  EDIT_FILEBOX_FOLDER_SUCCESS,
  EDIT_FILEBOX_FOLDER_FAILURE,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE,
  FETCH_FILEBOX_FOLDER_DETAILS_REQUEST,
  FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS,
  FETCH_FILEBOX_FOLDER_DETAILS_FAILURE,
  TOGGLE_LOCKING_FILE_REQUEST,
  TOGGLE_LOCKING_FILE_SUCCESS,
  TOGGLE_LOCKING_FILE_FAILURE,
  FETCH_FILEBOX_FAVORITE_FILES_REQUEST,
  FETCH_FILEBOX_FAVORITE_FILES_SUCCESS,
  FETCH_FILEBOX_FAVORITE_FILES_FAILURE,
  MOVE_FILEBOX_FILE_REQUEST,
  MOVE_FILEBOX_FILE_SUCCESS,
  MOVE_FILEBOX_FILE_FAILURE,
  COPY_FILEBOX_FILE_REQUEST,
  COPY_FILEBOX_FILE_SUCCESS,
  COPY_FILEBOX_FILE_FAILURE,
  COPY_FILEBOX_FOLDER_REQUEST,
  COPY_FILEBOX_FOLDER_SUCCESS,
  COPY_FILEBOX_FOLDER_FAILURE,
  FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST,
  FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS,
  FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE,
  SET_FILEBOX_ACTIVE_FILTER,
  ADD_FILEBOX_FAVORITE_FILE_REQUEST,
  ADD_FILEBOX_FAVORITE_FILE_SUCCESS,
  ADD_FILEBOX_FAVORITE_FILE_FAILURE,
  SELECT_FILEBOX_FOLDER,
  REMOVE_FILEBOX_FAVORITE_FILE_REQUEST,
  REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS,
  REMOVE_FILEBOX_FAVORITE_FILE_FAILURE,
  SEARCH_FILEBOX_FILES_FOLDERS_REQUEST,
  SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS,
  SEARCH_FILEBOX_FILES_FOLDERS_FAILURE,
  SET_FILEBOX_PATH_FROM_SEARCH,
  NEW_FILE_FILEBOX_NOTIFICATION,
  UNSHARE_FOLDER_FILEBOX_NOTIFICATION,
  SHARE_FOLDER_FILEBOX_NOTIFICATION,
  MOVE_FILEBOX_FOLDER_REQUEST,
  MOVE_FILEBOX_FOLDER_SUCCESS,
  MOVE_FILEBOX_FOLDER_FAILURE,
  UPDATE_FILEBOX_SPACE_REQUEST,
  UPDATE_FILEBOX_SPACE_SUCCESS,
  UPDATE_FILEBOX_SPACE_FAILURE,
  DELETE_FILEBOX_MASSIVE_FILES_REQUEST,
  DELETE_FILEBOX_MASSIVE_FILES_SUCCESS,
  DELETE_FILEBOX_MASSIVE_FILES_FAILURE,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE,
  REMOVE_FILEBOX_FILE_VERSION_REQUEST,
  REMOVE_FILEBOX_FILE_VERSION_SUCCESS,
  REMOVE_FILEBOX_FILE_VERSION_FAILURE,
  SET_FILEBOX_LIST_ORDER,
  FETCH_FILEBOX_DELETED_ITEMS_REQUEST,
  FETCH_FILEBOX_DELETED_ITEMS_SUCCESS,
  FETCH_FILEBOX_DELETED_ITEMS_FAILURE,
  SELECT_FILEBOX_DELETED_FOLDER,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE,
  RESTORE_FILEBOX_DELETED_ITEM_REQUEST,
  RESTORE_FILEBOX_DELETED_ITEM_SUCCESS,
  RESTORE_FILEBOX_DELETED_ITEM_FAILURE,
  SET_FILEBOX_ACTUAL_PATH,
  FETCH_FILEBOX_FILE_COMMENTS_REQUEST,
  FETCH_FILEBOX_FILE_COMMENTS_FAILURE,
  FETCH_FILEBOX_FILE_COMMENTS_SUCCESS,
  CREATE_FILEBOX_FILE_COMMENT_REQUEST,
  CREATE_FILEBOX_FILE_COMMENT_SUCCESS,
  CREATE_FILEBOX_FILE_COMMENT_FAILURE,
  DELETE_FILEBOX_FILE_COMMENT_REQUEST,
  DELETE_FILEBOX_FILE_COMMENT_SUCCESS,
  DELETE_FILEBOX_FILE_COMMENT_FAILURE,
  NEW_FILE_COMMENT_FILEBOX_NOTIFICATION,
  SET_FILEBOX_ACTIVE_ORDER,
  SET_FILEBOX_DELETED_ORDER,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE,
  SHARE_FILEBOX_FILE_REQUEST,
  SHARE_FILEBOX_FILE_SUCCESS,
  SHARE_FILEBOX_FILE_FAILURE,
} from './types';

export const setFileboxFilter = (filter) => ({
  type: SET_FILEBOX_FILTER,
  filter,
});
export const fetchFileboxItemsRequest = (payload, isFromMassiveDeletion) => ({
  type: FETCH_FILEBOX_ITEMS_REQUEST,
  payload,
  isFromMassiveDeletion,
});
export const fetchFileboxItemsSuccess = (payload) => ({
  type: FETCH_FILEBOX_ITEMS_SUCCESS,
  payload,
});
export const fetchFileboxItemsFailure = (errors) => ({
  type: FETCH_FILEBOX_ITEMS_FAILURE,
  errors,
});
export const fetchFileboxSpaceUsageRequest = () => ({
  type: FETCH_FILEBOX_SPACE_USAGE_REQUEST,
});
export const fetchFileboxSpaceUsageSuccess = (spaceUsage) => ({
  type: FETCH_FILEBOX_SPACE_USAGE_SUCCESS,
  spaceUsage,
});
export const fetchFileboxSpaceUsageFailure = (errors) => ({
  type: FETCH_FILEBOX_SPACE_USAGE_FAILURE,
  errors,
});
export const deleteFileboxFileRequest = (id) => ({
  type: DELETE_FILEBOX_FILE_REQUEST,
  id,
});
export const deleteFileboxFileSuccess = () => ({
  type: DELETE_FILEBOX_FILE_SUCCESS,
});
export const deleteFileboxFileFailure = (errors) => ({
  type: DELETE_FILEBOX_FILE_FAILURE,
  errors,
});
export const createFileboxFolderRequest = (payload) => ({
  type: CREATE_FILEBOX_FOLDER_REQUEST,
  payload,
});
export const createFileboxFolderSuccess = (data) => ({
  type: CREATE_FILEBOX_FOLDER_SUCCESS,
  data,
});
export const createFileboxFolderFailure = (errors) => ({
  type: CREATE_FILEBOX_FOLDER_FAILURE,
  errors,
});
export const deleteFileboxFolderRequest = (id) => ({
  type: DELETE_FILEBOX_FOLDER_REQUEST,
  id,
});
export const deleteFileboxFolderSuccess = () => ({
  type: DELETE_FILEBOX_FOLDER_SUCCESS,
});
export const deleteFileboxFolderFailure = (errors) => ({
  type: DELETE_FILEBOX_FOLDER_FAILURE,
  errors,
});
export const renameFileboxFileRequest = (payload) => ({
  type: RENAME_FILEBOX_FILE_REQUEST,
  payload,
});
export const renameFileboxFileSuccess = (payload) => ({
  type: RENAME_FILEBOX_FILE_SUCCESS,
  payload,
});
export const renameFileboxFileFailure = (errors) => ({
  type: RENAME_FILEBOX_FILE_FAILURE,
  errors,
});
export const clearFileboxErrors = () => ({
  type: FILEBOX_CLEAR_ERRORS,
});
export const editFileboxFolderRequest = (payload) => ({
  type: EDIT_FILEBOX_FOLDER_REQUEST,
  payload,
});
export const editFileboxFolderSuccess = () => ({
  type: EDIT_FILEBOX_FOLDER_SUCCESS,
});
export const editFileboxFolderFailure = (errors) => ({
  type: EDIT_FILEBOX_FOLDER_FAILURE,
  errors,
});
export const enableDisableFileboxFileExternalLinkRequest = (payload) => ({
  type: ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST,
  payload,
});
export const enableDisableFileboxFileExternalLinkSuccess = (
  id,
  enabled,
  payload
) => ({
  type: ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS,
  id,
  enabled,
  payload,
});
export const enableDisableFileboxFileExternalLinkFailure = (errors) => ({
  type: ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE,
  errors,
});
export const fetchFileboxFolderDetailsRequest = (id) => ({
  type: FETCH_FILEBOX_FOLDER_DETAILS_REQUEST,
  id,
});
export const fetchFileboxFolderDetailsSuccess = (payload) => ({
  type: FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS,
  payload,
});
export const fetchFileboxFolderDetailsFailure = (errors) => ({
  type: FETCH_FILEBOX_FOLDER_DETAILS_FAILURE,
  errors,
});
export const toggleLockingFileRequest = (payload) => ({
  type: TOGGLE_LOCKING_FILE_REQUEST,
  payload,
});
export const toggleLockingFileSuccess = (payload) => ({
  type: TOGGLE_LOCKING_FILE_SUCCESS,
  payload,
});
export const toggleLockingFileFailure = (payload) => ({
  type: TOGGLE_LOCKING_FILE_FAILURE,
  payload,
});
export const fetchFileboxFavoriteFilesRequest = () => ({
  type: FETCH_FILEBOX_FAVORITE_FILES_REQUEST,
});
export const fetchFileboxFavoriteFilesSuccess = (payload) => ({
  type: FETCH_FILEBOX_FAVORITE_FILES_SUCCESS,
  payload,
});
export const fetchFileboxFavoriteFilesFailure = (errors) => ({
  type: FETCH_FILEBOX_FAVORITE_FILES_FAILURE,
  errors,
});
export const setFileboxActiveFilter = (filter) => ({
  type: SET_FILEBOX_ACTIVE_FILTER,
  filter,
});
export const fetchFileboxActiveItemsRequest = (payload) => ({
  type: FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST,
  payload,
});
export const fetchFileboxActiveItemsSuccess = (payload) => ({
  type: FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS,
  payload,
});
export const fetchFileboxActiveItemsFailure = (errors) => ({
  type: FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE,
  errors,
});
export const moveFileboxFileRequest = (payload) => ({
  type: MOVE_FILEBOX_FILE_REQUEST,
  payload,
});
export const moveFileboxFileSuccess = () => ({
  type: MOVE_FILEBOX_FILE_SUCCESS,
});
export const moveFileboxFileFailure = (errors) => ({
  type: MOVE_FILEBOX_FILE_FAILURE,
  errors,
});
export const moveFileboxFolderRequest = (payload) => ({
  type: MOVE_FILEBOX_FOLDER_REQUEST,
  payload,
});
export const moveFileboxFolderSuccess = () => ({
  type: MOVE_FILEBOX_FOLDER_SUCCESS,
});
export const moveFileboxFolderFailure = (errors) => ({
  type: MOVE_FILEBOX_FOLDER_FAILURE,
  errors,
});
export const addFileboxFavoriteFileRequest = (payload) => ({
  type: ADD_FILEBOX_FAVORITE_FILE_REQUEST,
  payload,
});
export const addFileboxFavoriteFileSuccess = (payload) => ({
  type: ADD_FILEBOX_FAVORITE_FILE_SUCCESS,
  payload,
});
export const addFileboxFavoriteFileFailure = (errors) => ({
  type: ADD_FILEBOX_FAVORITE_FILE_FAILURE,
  errors,
});
export const selectFileboxFolder = (folderId) => ({
  type: SELECT_FILEBOX_FOLDER,
  folderId,
});
export const setFileboxActualPath = (path) => ({
  type: SET_FILEBOX_ACTUAL_PATH,
  path,
});
export const removeFileboxFavoriteFileRequest = (id) => {
  return {
    type: REMOVE_FILEBOX_FAVORITE_FILE_REQUEST,
    id,
  };
};
export const removeFileboxFavoriteFileSuccess = (id) => ({
  type: REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS,
  id,
});
export const removeFileboxFavoriteFileFailure = (errors) => ({
  type: REMOVE_FILEBOX_FAVORITE_FILE_FAILURE,
  errors,
});
export const copyFileboxFileRequest = (payload) => ({
  type: COPY_FILEBOX_FILE_REQUEST,
  payload,
});
export const copyFileboxFileSuccess = () => ({
  type: COPY_FILEBOX_FILE_SUCCESS,
});
export const copyFileboxFileFailure = (errors) => ({
  type: COPY_FILEBOX_FILE_FAILURE,
  errors,
});
export const copyFileboxFolderRequest = (payload) => ({
  type: COPY_FILEBOX_FOLDER_REQUEST,
  payload,
});
export const copyFileboxFolderSuccess = () => ({
  type: COPY_FILEBOX_FOLDER_SUCCESS,
});
export const copyFileboxFolderFailure = (errors) => ({
  type: COPY_FILEBOX_FOLDER_FAILURE,
  errors,
});
export const searchFileboxFilesFoldersRequest = (payload) => ({
  type: SEARCH_FILEBOX_FILES_FOLDERS_REQUEST,
  payload,
});
export const searchFileboxFilesFoldersSuccess = (payload) => ({
  type: SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS,
  payload,
});
export const searchFileboxFilesFoldersFailure = (errors) => ({
  type: SEARCH_FILEBOX_FILES_FOLDERS_FAILURE,
  errors,
});
export const setFileboxPathFromSearch = (pathIndexes, pathNames) => ({
  type: SET_FILEBOX_PATH_FROM_SEARCH,
  pathIndexes,
  pathNames,
});
export const deleteFileboxMassiveFilesRequest = (files, folders) => ({
  type: DELETE_FILEBOX_MASSIVE_FILES_REQUEST,
  files,
  folders,
});
export const deleteFileboxMassiveFilesSuccess = () => ({
  type: DELETE_FILEBOX_MASSIVE_FILES_SUCCESS,
});
export const deleteFileboxMassiveFilesFailure = (errors, ids) => ({
  type: DELETE_FILEBOX_MASSIVE_FILES_FAILURE,
  errors,
  ids,
});
export const newFileFileboxNotification = (payload) => ({
  type: NEW_FILE_FILEBOX_NOTIFICATION,
  payload,
});
export const newFileCommentFileboxNotification = (payload) => ({
  type: NEW_FILE_COMMENT_FILEBOX_NOTIFICATION,
  payload,
});
export const shareFolderFileboxNotification = (payload) => ({
  type: SHARE_FOLDER_FILEBOX_NOTIFICATION,
  payload,
});
export const unshareFolderFileboxNotification = (payload) => ({
  type: UNSHARE_FOLDER_FILEBOX_NOTIFICATION,
  payload,
});
export const retrieveAllFileboxFilePreviousVersionsRequest = (id) => ({
  type: RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST,
  id,
});
export const retrieveAllFileboxFilePreviousVersionsSuccess = (payload, id) => ({
  type: RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS,
  payload,
  id,
});
export const retrieveAllFileboxFilePreviousVersionsFailure = (errors) => ({
  type: RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE,
  errors,
});
export const updateFileboxSpaceRequest = (payload) => ({
  type: UPDATE_FILEBOX_SPACE_REQUEST,
  payload,
});
export const updateFileboxSpaceSuccess = () => ({
  type: UPDATE_FILEBOX_SPACE_SUCCESS,
});
export const updateFileboxSpaceFailure = (errors) => ({
  type: UPDATE_FILEBOX_SPACE_FAILURE,
  errors,
});
export const removeFileboxFileVersionRequest = (fileId, id) => ({
  type: REMOVE_FILEBOX_FILE_VERSION_REQUEST,
  fileId,
  id,
});
export const removeFileboxFileVersionSuccess = (fileId, id) => ({
  type: REMOVE_FILEBOX_FILE_VERSION_SUCCESS,
  id,
  fileId,
});
export const removeFileboxFileVersionFailure = (errors) => ({
  type: REMOVE_FILEBOX_FILE_VERSION_FAILURE,
  errors,
});
export const fetchFileboxFileCommentsRequest = (id) => ({
  type: FETCH_FILEBOX_FILE_COMMENTS_REQUEST,
  id,
});
export const fetchFileboxFileCommentsSuccess = (payload) => ({
  type: FETCH_FILEBOX_FILE_COMMENTS_SUCCESS,
  payload,
});
export const fetchFileboxFileCommentsFailure = (error) => ({
  type: FETCH_FILEBOX_FILE_COMMENTS_FAILURE,
  error,
});
export const createFileboxFileCommentRequest = (payload) => ({
  type: CREATE_FILEBOX_FILE_COMMENT_REQUEST,
  payload,
});
export const createFileboxFileCommentSuccess = () => ({
  type: CREATE_FILEBOX_FILE_COMMENT_SUCCESS,
});
export const createFileboxFileCommentFailure = (error) => ({
  type: CREATE_FILEBOX_FILE_COMMENT_FAILURE,
  error,
});
export const deleteFileboxFileCommentRequest = (payload) => ({
  type: DELETE_FILEBOX_FILE_COMMENT_REQUEST,
  payload,
});
export const deleteFileboxFileCommentSuccess = () => ({
  type: DELETE_FILEBOX_FILE_COMMENT_SUCCESS,
});
export const deleteFileboxFileCommentFailure = (error) => ({
  type: DELETE_FILEBOX_FILE_COMMENT_FAILURE,
  error,
});
export const setFileboxListOrder = (order) => ({
  type: SET_FILEBOX_LIST_ORDER,
  order,
});
export const fetchFileboxDeletedItemsRequest = (payload) => ({
  type: FETCH_FILEBOX_DELETED_ITEMS_REQUEST,
  payload,
});
export const fetchFileboxDeletedItemsSuccess = (payload) => ({
  type: FETCH_FILEBOX_DELETED_ITEMS_SUCCESS,
  payload,
});
export const fetchFileboxDeletedItemsFailure = (errors) => ({
  type: FETCH_FILEBOX_DELETED_ITEMS_FAILURE,
  errors,
});
export const selectFileboxDeletedFolder = (folderId) => ({
  type: SELECT_FILEBOX_DELETED_FOLDER,
  folderId,
});
export const removePermanentlyFileboxDeletedItemRequest = (id) => ({
  type: REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST,
  id,
});
export const removePermanentlyFileboxDeletedItemSuccess = () => ({
  type: REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS,
});
export const removePermanentlyFileboxDeletedItemFailure = (errors) => ({
  type: REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE,
  errors,
});
export const restoreFileboxDeletedItemRequest = (id) => ({
  type: RESTORE_FILEBOX_DELETED_ITEM_REQUEST,
  id,
});
export const restoreFileboxDeletedItemSuccess = () => ({
  type: RESTORE_FILEBOX_DELETED_ITEM_SUCCESS,
});
export const restoreFileboxDeletedItemFailure = (errors) => ({
  type: RESTORE_FILEBOX_DELETED_ITEM_FAILURE,
  errors,
});
export const setFileboxActiveOrder = (order) => ({
  type: SET_FILEBOX_ACTIVE_ORDER,
  order,
});
export const setFileboxDeletedOrder = (order) => ({
  type: SET_FILEBOX_DELETED_ORDER,
  order,
});
export const fetchFileboxFileEmailRecipientsRequest = (id) => ({
  type: FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST,
  id,
});
export const fetchFileboxFileEmailRecipientsSuccess = (payload) => ({
  type: FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS,
  payload,
});
export const fetchFileboxFileEmailRecipientsFailure = (errors) => ({
  type: FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE,
  errors,
});

export const shareFileboxFileRequest = (payload) => ({
  type: SHARE_FILEBOX_FILE_REQUEST,
  payload,
});
export const shareFileboxFileSuccess = () => ({
  type: SHARE_FILEBOX_FILE_SUCCESS,
});
export const shareFileboxFileFailure = (errors) => ({
  type: SHARE_FILEBOX_FILE_FAILURE,
  errors,
});
