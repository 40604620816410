import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const QRCodeSettingsEdit = React.lazy(() => import('./QRCodeSettingsEdit'));

const QRCodeSettingsPage = (props) => {
  return (
    <PresencesManagement>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <QRCodeSettingsEdit {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default QRCodeSettingsPage;
