import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import HelpButton from '../../../../components/HelpButton';
import { hasGrants } from '../../../../js/me/selectors';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import PbxSettings from './PbxSettings';

const ArchivedGreetingMessagesTable = React.lazy(() =>
  import('./ArchivedMessagesTable')
);
const messages = defineMessages({
  newMessage: {
    id: 'ArchivedMessagesPage.button.newMessage',
    defaultMessage: 'New message',
  },
});

const ArchivedMessagesPage = ({ canEdit, intl: { formatMessage } }) => {
  return (
    <PbxSettings scrolling>
      <div className="text-right pb-3" style={{ display: 'flex' }}>
        {canEdit && (
          <div style={{ flex: 1 }}>
            <Link to="/pbx/archived/list/new">
              <button type="button" className="btn btn-primary">
                {formatMessage(messages.newMessage)}
              </button>
            </Link>
          </div>
        )}
        <HelpButton
          fileName="pbx-greeting-messages-archive"
          style={{ paddingLeft: '0.5rem', paddingTop: '0.35rem' }}
        />
      </div>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <ArchivedGreetingMessagesTable canEdit={canEdit} />
        </React.Suspense>
      </ErrorBoundary>
    </PbxSettings>
  );
};

function mapStateToProps(state) {
  return {
    canEdit:
      hasGrants(state, 'PBX_HOLIDAYS_WRITE') ||
      hasGrants(state, 'BACKEND_TVOX'),
  };
}

export default injectIntl(connect(mapStateToProps)(ArchivedMessagesPage));
