import { request } from '../request';

export default {
  getContact: (contactId) =>
    request({
      url: `/contacts/${contactId}`,
      method: 'GET',
    }),
  getContacts: (params) =>
    request({
      url: '/contacts',
      method: 'GET',
      params,
    }),
  getContactFromNumber: (params) =>
    request({
      url: '/contacts/phone',
      method: 'GET',
      params,
    }),
  createContact: (contactData) =>
    request({
      url: '/contacts',
      method: 'POST',
      data: contactData,
    }),
  updateContact: (contactData) =>
    request({
      url: `/contacts/${contactData.id}`,
      method: 'POST',
      data: contactData,
    }),
  deleteContact: (contactId) =>
    request({
      url: `/contacts/${contactId}`,
      method: 'DELETE',
    }),
  getAbookInitials: (params) =>
    request({
      url: `/contacts/initials`,
      method: 'GET',
      params,
    }),
  getAbookTags: (tag) =>
    request({
      url: `/contacts/tags?filter=${tag}`,
      method: 'GET',
    }),
  importAbookContacts: (data) =>
    request({
      url: 'contacts/import',
      method: 'POST',
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  cancelImportAbookContacts: (status) =>
    request({
      url: 'contacts/import',
      method: 'DELETE',
      data: status,
    }),
  deleteMassiveContacts: (params) =>
    request({
      url: 'contacts/bulk-delete',
      method: 'POST',
      data: params,
    }),
  getCities: (city) =>
    request({
      url: `addresses/towns?name=${city}`,
      method: 'GET',
    }),
  getNations: (nation) =>
    request({
      url: `addresses/nations?name=${nation}`,
      method: 'GET',
    }),
  getCompanies: (company) =>
    request({
      url: `contacts/companies?filter=${company}`,
      method: 'GET',
    }),
  getImportStatus: () =>
    request({
      url: `contacts/import-status`,
      method: 'GET',
    }),
  getAtecoCodes: (code) =>
    request({
      url: `contacts/ateco-codes?filter=${code}`,
      method: 'GET',
    }),
  getAbookList: (id) =>
    request({
      url: `/contacts/distribution-lists/${id}`,
      method: 'GET',
    }),
  getAbookLists: (params) =>
    request({
      url: '/contacts/distribution-lists',
      method: 'GET',
      params,
    }),
  createAbookList: (data) =>
    request({
      url: '/contacts/distribution-lists',
      method: 'POST',
      data,
    }),
  updateAbookList: (data) =>
    request({
      url: `/contacts/distribution-lists/${data.id}`,
      method: 'POST',
      data,
    }),
  deleteAbookList: (id) =>
    request({
      url: `/contacts/distribution-lists/${id}`,
      method: 'DELETE',
    }),
  getEmails: (params) =>
    request({
      url: '/contacts/emails',
      method: 'GET',
      params,
    }),
};
