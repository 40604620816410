import React, { Component } from 'react';
import Select, { createFilter } from 'react-select';
import { injectIntl, defineMessages } from 'react-intl';
import classnames from 'classnames';
import FormFieldError from '../messages/FormFieldError';

const messages = defineMessages({
  defaultPlaceholder: {
    id: 'Select2.defaultPlaceholder',
    defaultMessage: 'Select...',
  },
});

const customStyles = (custom) => {
  return {
    container: (base) => ({
      ...base,
      height: 'auto',
      ...custom.container,
    }),
    valueContainer: (base) => ({
      ...base,
      flex: 1,
      overflow: 'auto',
      paddingTop: '3px',
      paddingBottom: '3px',
      maxHeight: '200px',
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? '#e9ecef' : 'var(--white)',
      border: 'none',
      minHeight: 'auto',
      webkitBoxShadow: state.isFocused
        ? 'var(--yn-input-focus-box-shadow)'
        : 'transparent',
      boxShadow: state.isFocused
        ? 'var(--yn-input-focus-box-shadow)'
        : 'transparent',
      ...custom.control,
    }),
    option: (base) => ({
      ...base,
      ...custom.option,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0.45em',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '0.45em',
    }),
    multiValue: (base, { data }) => {
      const backgroundColor = custom.colors
        ? custom.colors.background[data.value]
        : base.backgroundColor;
      return {
        ...base,
        ...custom.multiValue,
        backgroundColor,
      };
    },
    multiValueLabel: (base, { data }) => {
      const color = custom.colors ? custom.colors.text[data.value] : base.color;
      return {
        ...base,
        ...custom.multiValueLabel,
        color,
      };
    },
    multiValueRemove: (base, { data }) => {
      const color = custom.colors ? custom.colors.text[data.value] : base.color;
      const backgroundColor = custom.colors
        ? custom.colors.remove[data.value]
        : base.backgroundColor;
      const hover = custom.colors
        ? {
            ':hover': {
              backgroundColor,
              color,
            },
          }
        : null;
      return {
        ...base,
        ...custom.multiValueRemove,
        /** to remove the X from some specific groups */
        display: data.isNotRemovable ? 'none' : 'block',
        color,
        ...hover,
      };
    },
    singleValue: (base) => ({
      ...base,
      ...custom.singleValue,
      color: 'var(--yn-blue-darker)',
    }),
    placeholder: (base) => ({
      ...base,
      ...custom.multiValue,
      color: 'var(--yn-gray-600)',
    }),
  };
};

class Select2 extends Component {
  onChange = (e) => {
    this.props.onChange(e);
  };

  render() {
    const {
      error,
      className,
      style,
      placeholder,
      intl: { formatMessage },
    } = this.props;
    const classNames = classnames('form-control p-0', className, {
      'is-invalid': error,
    });
    const stringify = (option) => option.label;
    const filterOption = createFilter({ stringify });

    return (
      <>
        <Select
          filterOption={filterOption}
          styles={customStyles(style || {})}
          className={classNames}
          placeholder={
            placeholder || formatMessage(messages.defaultPlaceholder)
          }
          isOptionDisabled={(option) => option.disabled}
          {...this.props}
        />
        {error !== null && <FormFieldError text={error} />}
      </>
    );
  }
}

export default injectIntl(Select2);
