import {
  CREATE_USER_PROFILE_FAILURE,
  CREATE_USER_PROFILE_REQUEST,
  CREATE_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILES_FAILURE,
  FETCH_USER_PROFILES_REQUEST,
  FETCH_USER_PROFILES_SUCCESS,
  FETCH_USER_PROFILE_GRANTS_FAILURE,
  FETCH_USER_PROFILE_GRANTS_REQUEST,
  FETCH_USER_PROFILE_GRANTS_SUCCESS,
  REMOVE_USER_PROFILE_FAILURE,
  REMOVE_USER_PROFILE_REQUEST,
  REMOVE_USER_PROFILE_SUCCESS,
} from './types';

export const createUserProfileRequest = (payload) => ({
  type: CREATE_USER_PROFILE_REQUEST,
  payload,
});
export const createUserProfileSuccess = (data) => ({
  type: CREATE_USER_PROFILE_SUCCESS,
  data,
});
export const createUserProfileFailure = (error) => ({
  type: CREATE_USER_PROFILE_FAILURE,
  error,
});

export const editUserProfileRequest = (payload) => ({
  type: EDIT_USER_PROFILE_REQUEST,
  payload,
});
export const editUserProfileSuccess = (data) => ({
  type: EDIT_USER_PROFILE_SUCCESS,
  data,
});
export const editUserProfileFailure = (error) => ({
  type: EDIT_USER_PROFILE_FAILURE,
  error,
});

export const fetchUserProfilesRequest = (params) => ({
  type: FETCH_USER_PROFILES_REQUEST,
  params,
});
export const fetchUserProfilesSuccess = (data) => ({
  type: FETCH_USER_PROFILES_SUCCESS,
  data,
});
export const fetchUserProfilesFailure = (errors) => ({
  type: FETCH_USER_PROFILES_FAILURE,
  errors,
});
export const removeUserProfileRequest = (id) => ({
  type: REMOVE_USER_PROFILE_REQUEST,
  id,
});
export const removeUserProfileSuccess = (id) => ({
  type: REMOVE_USER_PROFILE_SUCCESS,
  id,
});
export const removeUserProfileFailure = (errors) => ({
  type: REMOVE_USER_PROFILE_FAILURE,
  errors,
});
export const fetchUserProfileGrantsRequest = (id) => ({
  type: FETCH_USER_PROFILE_GRANTS_REQUEST,
  id,
});
export const fetchUserProfileGrantsSuccess = (id, data) => ({
  type: FETCH_USER_PROFILE_GRANTS_SUCCESS,
  id,
  data,
});
export const fetchUserProfileGrantsFailure = (error) => ({
  type: FETCH_USER_PROFILE_GRANTS_FAILURE,
  error,
});
