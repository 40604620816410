import { LOGOUT_SUCCESS } from '../auth/types';
import {
  /** Fetch Youtube info */
  FETCH_YOUTUBE_INFO_SUCCESS,
} from './types';

const initialState = {
  youtube: {},
};

export default function ext(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    /** Fetch Youtube info */
    case FETCH_YOUTUBE_INFO_SUCCESS:
      return {
        ...state,
        youtube: {
          ...state.youtube,
          [action.payload.code]: action.payload.info.items[0].snippet.title,
        },
      };
    default:
      return state;
  }
}
