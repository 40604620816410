import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '../../../components/formControls/Button';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import { showConfirmModal } from '../../../js/confirm/actions';
import ToastMessage from '../../../components/messages/ToastMessage';
import FormSectionTitle from '../../../components/FormSectionTitle';
import { fetchPhoneExternalUrlRequest } from '../../../js/externalUrl/actions';
import {
  getErrorDeletePhoneExternalUrl,
  getPhoneExternalUrlError,
  getPhoneExternalUrlLoaded,
  getPhoneExternalUrls,
} from '../../../js/externalUrl/selectors';
import PhoneExternalUrlRow from './PhoneExternalUrlRow';
import MeEditPhoneExternalUrlModal from './EditPhoneExternalUrlModal';

const messages = defineMessages({
  title: {
    id: 'PhoneExternalUrlList.title',
    defaultMessage: 'External urls',
  },
  event: {
    id: 'PhoneExternalUrlList.event',
    defaultMessage: 'Event',
  },
  action: {
    id: 'PhoneExternalUrlList.action',
    defaultMessage: 'Action',
  },
  constraint: {
    id: 'PhoneExternalUrlList.constraint',
    defaultMessage: 'Constraint',
  },
  url: {
    id: 'PhoneExternalUrlList.url',
    defaultMessage: 'Url',
  },
  noUrls: {
    id: 'PhoneExternalUrlList.noUrls',
    defaultMessage: 'No Urls',
  },
  newUrl: {
    id: 'PhoneExternalUrlList.button.newUrl',
    defaultMessage: 'New Url',
  },
  deleteMessageError: {
    id: 'PhoneExternalUrlList.label.deleteMessageError',
    defaultMessage: 'Sorry, the Url has not been deleted due to an error.',
  },
});

class PhoneExternalUrlList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      editingElement: null,
    };
  }

  componentDidMount() {
    this.props.fetchPhoneExternalUrl(
      this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null
    );
  }

  handleEditClick = (id) => {
    this.setState(
      {
        ...this.state,
        editingElement: id,
      },
      () => this.toggleModal()
    );
  };

  toggleModal = () => {
    this.setState({
      ...this.state,
      isModalOpen: !this.state.isModalOpen,
      editingElement: this.state.isModalOpen ? null : this.state.editingElement,
    });
  };

  render() {
    const {
      externalUrls,
      externalUrlsLoading,
      externalUrlsError,
      deleteExternalUrlError,
      intl: { formatMessage },
    } = this.props;
    const { isModalOpen, editingElement } = this.state;
    return externalUrlsError ? (
      <GlobalErrorPage />
    ) : (
      <div className="border rounded p-3">
        <FormSectionTitle className="mb-3">
          {formatMessage(messages.title)}
        </FormSectionTitle>
        {deleteExternalUrlError && (
          <div>
            <ToastMessage
              type="danger"
              text={formatMessage(messages.deleteMessageError)}
              closeTimeout={10000}
            />
          </div>
        )}
        <div className="text-right pb-2">
          <Button
            text={formatMessage(messages.newUrl)}
            onClick={() => this.toggleModal()}
          />
        </div>
        <div className="rounded border mt-2 p-2">
          {externalUrlsLoading ? (
            <Loading />
          ) : (
            <table className="table table-borderless table-hover mx-2">
              <thead>
                <tr>
                  <th>{formatMessage(messages.event)}</th>
                  <th>{formatMessage(messages.action)}</th>
                  <th>{formatMessage(messages.constraint)}</th>
                  <th>{formatMessage(messages.url)}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {externalUrls.map((url) => {
                  return (
                    <PhoneExternalUrlRow
                      key={url.id}
                      url={url}
                      user={
                        this.props.match &&
                        this.props.match.params &&
                        this.props.match.params.id
                          ? this.props.match.params.id
                          : null
                      }
                      handleEditClick={this.handleEditClick}
                    />
                  );
                })}
              </tbody>

              {externalUrls.length === 0 && (
                <tfoot>
                  <tr>
                    <td colSpan="5">{formatMessage(messages.noUrls)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
          <MeEditPhoneExternalUrlModal
            isOpen={isModalOpen}
            toggle={this.toggleModal}
            editingElement={editingElement}
            user={
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.id
                ? this.props.match.params.id
                : null
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    externalUrls: getPhoneExternalUrls(state),
    externalUrlsLoading: !getPhoneExternalUrlLoaded(state),
    externalUrlsError: getPhoneExternalUrlError(state),
    deleteExternalUrlError: getErrorDeletePhoneExternalUrl(state),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchPhoneExternalUrl: fetchPhoneExternalUrlRequest,
    confirm: showConfirmModal,
  })(PhoneExternalUrlList)
);
