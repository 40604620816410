import moment from 'moment';
import VocalAssistantMessages from './VocalAssistantMessages';
import VocalAssistantDateUtils from './VocalAssistantDateUtils';
import VocalAssistantUtils from './VocalAssistantUtils';
import api from '../api';
import {
  updateNotificationSettingsSuccess,
  updateNotificationSettingsFailure,
} from '../settings/actions';
import {
  updateStickyVisibilitySuccess,
  updateStickyVisibilityFailure,
  addStickyNotesSuccess,
  addStickyNotesFailure,
} from '../sticky/actions';
import {
  saveCalendarEventSuccess,
  saveCalendarEventFailure,
  fetchCalendarEventsFailure,
} from '../calendar/actions';
import Utils from '../lib/utils';
import StickyUtils from '../sticky/StickyUtils';
import { CalendarEnums } from '../calendar/CalendarUtils';
import {
  setPinnedGroupsFilter,
  updatePinnedGroupSettingsSuccess,
  updatePinnedGroupSettingsFailure,
} from '../groups/actions';
import {
  sendChatMessageRequest,
  sendReadMessageRequest,
  openChatConversation,
  sendReadAllMessageRequest,
} from '../chat/actions';
import { generateAckUid } from '../websocket/WsUtils';
import { generateConversationId } from '../chat/ChatUtils';
import {
  enablePhoneDnd,
  disablePhoneDnd,
  execCallRequest,
} from '../phone/actions';
import { PbxManager } from '../phone/PbxManager';

export default class VocalAssistantVoiceCommands {
  static confirmRegexp = [
    /^conferm\w*$/i,
    /^ok$/i,
    /^sì$/i,
    /^si$/i,
    /^confirm$/i,
    /^yes$/i,
  ];

  static cancelRegexp = [/^annull\w*$/i, /^no$/i, /^reject$/i];

  static urlRegEx =
    /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;

  static replaceStringInMessage = (message, search, replace) => {
    let text;
    if (typeof message === 'object') {
      text = message[Math.floor(Math.random() * Object.keys(message).length)];
    } else {
      text = message;
    }
    return VocalAssistantUtils.replace(text, search, replace);
  };

  static replaceStringAndTranslate = (messages, search, replace, lang) => {
    const intl = VocalAssistantUtils.retriveIntl(lang);
    return VocalAssistantVoiceCommands.replaceStringInMessage(
      intl.formatMessage(
        messages[Math.floor(Math.random() * Object.keys(messages).length)]
      ),
      search,
      replace
    );
  };

  static retrieveDateFromText = (text, intl) => {
    let startingDate;
    const retrieveDate = () => {
      // / search month name
      let month;
      Object.keys(VocalAssistantMessages.months).forEach((key, i) => {
        const item = intl.formatMessage(VocalAssistantMessages.months[key]);
        if (text.indexOf(item.toLowerCase()) >= 0) {
          month = i + 1; // VocalAssistantUtils.getMonthFromString(i);
        }
      });

      // / no month found
      if (!month) {
        return null;
      }
      // / replace first of month with number
      text = VocalAssistantUtils.replace(
        text,
        VocalAssistantMessages.genericFirst,
        1
      );
      // / search day and year
      const dateNumbers = text.match(/\d+/g);
      let day;
      let year;
      if (!dateNumbers || dateNumbers.length > 2 || dateNumbers.length === 0) {
        return null;
      }
      if (dateNumbers.length === 2) {
        if (dateNumbers[0] > 31) {
          day = parseInt(dateNumbers[1], 10);
          year = parseInt(dateNumbers[0], 10);
        } else {
          day = parseInt(dateNumbers[0], 10);
          year = parseInt(dateNumbers[1], 10);
        }
      } else {
        day = parseInt(dateNumbers[0], 10);
        year = new Date().getFullYear();
        if (
          month - 1 < new Date().getMonth() ||
          (month - 1 === new Date().getMonth() && day < new Date().getDate())
        ) {
          year += 1;
        }
      }
      startingDate = new Date(year, month - 1, day);
      // / check it is a valid date and it is the same in input (example 32/01 become 01/02)
      if (
        startingDate &&
        startingDate.getDate() === day &&
        startingDate.getMonth() === month - 1 &&
        startingDate.getFullYear() === year
      ) {
        return startingDate;
      }
      return null;
    };
    text = VocalAssistantUtils.replace(text, 'ì', 'i').toLowerCase();
    let found = false;
    switch (text) {
      case intl
        .formatMessage(VocalAssistantMessages.genericToday[0])
        .toLowerCase():
        // / today
        found = true;
        startingDate = new Date();
        break;
      case intl
        .formatMessage(VocalAssistantMessages.genericYesterday[0])
        .toLowerCase():
        // / yesterday
        found = true;
        startingDate = VocalAssistantUtils.getYesterday();
        break;
      case intl
        .formatMessage(VocalAssistantMessages.genericTomorrow[0])
        .toLowerCase():
        // / tomorrow
        found = true;
        startingDate = VocalAssistantUtils.getTomorrow();
        break;
      default:
        break;
    }
    if (!found) {
      // / search days of week
      Object.keys(VocalAssistantMessages.daysOfWeek).forEach((key, i) => {
        const regExp = new RegExp(
          intl.formatMessage(VocalAssistantMessages.daysOfWeek[key])
        );
        if (!text.match(/(\d)+/g) && text.match(regExp)) {
          found = true;
          startingDate = new Date();
          const today = startingDate.getDay();
          let diffDay = (parseInt(i, 10) + 7 - today) % 7;
          diffDay = diffDay === 0 ? 7 : diffDay;
          startingDate.setDate(startingDate.getDate() + diffDay);
        }
      });

      if (!found) {
        startingDate = retrieveDate();
      }
    }
    if (startingDate) {
      startingDate.setHours(0, 0, 0, 0);
    }
    return startingDate;
  };

  static searchChatContact = (prm, callback) => {
    const string = prm.text;
    prm.data.receiver = string;
    const search = (receiver) => {
      let candidates = [];
      api.users
        .getUsers()
        .then((res) => {
          const error = Utils.checkApiError(res);
          if (error) throw error;
          const exactUser = res.data.data.filter(
            (u) =>
              u.fullname &&
              receiver &&
              u.fullname.toLowerCase() === receiver.toLowerCase()
          );
          if (exactUser.length === 1) {
            callback(false, {
              data: { ...prm.data, receiverUserId: exactUser[0].id },
              text: prm.data.message,
            });
            return;
          }
          const users = res.data.data.filter(
            (u) => u.fullname.toLowerCase().indexOf(receiver.toLowerCase()) > -1
          );

          candidates = [...candidates, ...users.map((o) => o.fullname)];

          api.me
            .getMe()
            .then((me) => {
              const error = Utils.checkApiError(me);
              if (error) throw error;
              const myGroups = me.data.groups;
              api.me
                .getMeGroups()
                .then((response) => {
                  const error = Utils.checkApiError(response);
                  if (error) throw error;
                  const exactGroup = response.data.filter(
                    (g) => g.name.toLowerCase() === receiver.toLowerCase()
                  );
                  if (exactGroup.length === 1) {
                    callback(false, {
                      data: { ...prm.data, receiverGroupId: exactGroup[0].id },
                      text: prm.data.message,
                    });
                    return;
                  }
                  const groups = response.data.filter(
                    (g) =>
                      g.name.toLowerCase().indexOf(receiver.toLowerCase()) >
                        -1 &&
                      g.xmpp &&
                      (g.public || (g.private && myGroups.indexOf(g.id) > -1))
                  );
                  candidates = [...candidates, ...groups.map((o) => o.name)];
                  if (candidates.length === 0) {
                    callback(true, {
                      end: true,
                      messages: {
                        messages:
                          VocalAssistantMessages.voiceCommandChatNotFound,
                        search: '%s',
                        replace: receiver,
                      },
                    });
                  } else if (candidates.length === 1) {
                    search(candidates[0]);
                  } else if (candidates.length > 4) {
                    callback(true, {
                      data: prm.data,
                      messages: {
                        messages:
                          VocalAssistantMessages.voiceCommandManyChatFound,
                        search: '%s',
                        replace: receiver,
                      },
                    });
                  } else {
                    callback(true, {
                      data: prm.data,
                      messages: {
                        messages: VocalAssistantMessages.voiceCommandChatFound,
                        search: '%s',
                        replace: candidates.join(', '),
                      },
                    });
                  }
                })
                .catch(
                  () => () =>
                    callback(true, {
                      end: true,
                      messages: {
                        messages:
                          VocalAssistantMessages.voiceCommandGenericError,
                      },
                    })
                );
            })
            .catch(() =>
              callback(true, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              })
            );
        })
        .catch(() => {
          callback(true, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandChatNotFound,
              search: '%s',
              replace: prm.data.receiver,
            },
          });
        });
    };
    search(string);
  };

  static voiceCommands = [
    // / call a number
    {
      command: 'CALL_NUMBER',
      templates: [
        /^(?:ambrosia\s)?chiam[\w]*\s(?:il\s)?numero\s?([\d\s]*)$/i,
        /^(?:ambrosia\s)?telefon[\w]*\s(?:al\s)?numero\s?([\d\s]*)$/i,
        /^(?:ambrosia\s)?call\s(?:the\s)?number\s?([\d\s]*)$/i,
      ],
      steps: [
        (prm, callback) => {
          let contactNumber = prm.text;
          if (!contactNumber) {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandCallMissingNumber,
              },
            });
          }
          contactNumber = contactNumber.replace(' ', '');
          callback(false, {
            end: true,
            action: execCallRequest,
            params: { number: contactNumber },
          });
        },
      ],
    },
    {
      command: 'CALL_CONTACT',
      templates: [
        /^(?:ambrosia\s)?chiam[\w]*\s?(.*)$/i,
        /^(?:ambrosia\s)?telefon[\w]*\s?(?:a\s)?(.*)$/i,
        /^(?:ambrosia\s)?call\s?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const input = prm.text;
          // / check if user has detailed the type of number at the end of the speech
          let contactName = input;
          let numberType = '';
          const { numberTypes } = VocalAssistantMessages;

          for (let i = 0; i < Object.keys(numberTypes).length; i += 1) {
            const type = intl.formatMessage(
              numberTypes[Object.keys(numberTypes)[i]]
            );
            const regExp = new RegExp(`(?:.)*(${type})$`, 'i');
            if (input.match(regExp)) {
              numberType = Object.keys(numberTypes)[i];
              contactName = input
                .substring(0, input.lastIndexOf(type.toLowerCase()))
                .trim();
              break;
            }
          }
          if (!contactName) {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandCallMissingContact,
              },
            });
            return;
          }
          callback(false, {
            data: {
              type: numberType,
            },
            text: contactName,
          });
        },
        (prm, callback) => {
          const name = prm.text;
          const search = (contactName) => {
            const contactType = prm.data.type;
            const candidates = [];
            let users = [];
            if (!contactType || contactType === 'EXTENSION') {
              api.users
                .getUsers({ name: contactName })
                .then((res) => {
                  users = prm.data.exact
                    ? res.data.data.filter(
                        (user) => contactName.toLowerCase() === user.fullname
                      )
                    : res.data.data;
                  const usersQuantity = res.data.total || 0;
                  if (usersQuantity > 0) {
                    if (usersQuantity > 1 || !contactType) {
                      candidates.push(users.map((o) => o.fullname));
                    } else {
                      callback(false, {
                        end: true,
                        action: execCallRequest,
                        params: { number: users[0].mainExtensionNumber },
                      });
                    }
                  }
                })
                .catch(() => {
                  callback(true, {
                    end: true,
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandGenericError,
                    },
                  });
                });
            }
            api.contacts
              .getContacts({
                name: contactName,
                page: 0,
                pageSize: 5,
                public: true,
                private: true,
              })
              .then((res) => {
                const contacts = prm.data.exact
                  ? res.data !== ''
                    ? res.data.filter(
                        (contact) =>
                          contactName.toLowerCase() === contact.fullname
                      )
                    : res.data
                  : res.data !== ''
                  ? res.data
                  : [];
                if (contacts.length + candidates.length > 4) {
                  // too many candidates
                  callback(true, {
                    end: false,
                    data: prm.data,
                    messages: {
                      messages:
                        VocalAssistantMessages.voiceCommandManyCallFound,
                      search: '%s',
                      replace: contactName,
                    },
                  });
                } else if (contacts.length + candidates.length === 0) {
                  // no candidates
                  callback(false, {
                    end: true,
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandCallNotFound,
                      search: '%s',
                      replace: contactName,
                    },
                  });
                } else if (candidates.length === 0 && contacts.length === 1) {
                  // found candidate
                  const numbers = contacts[0].numbers.filter(
                    (number) => number.type !== 'FAX'
                  );
                  if (numbers.length === 0) {
                    callback(false, {
                      end: true,
                      messages: {
                        messages:
                          VocalAssistantMessages.voiceCommandCallNotFound,
                      },
                    });
                  } else if (numbers.length === 1) {
                    callback(false, {
                      end: true,
                      action: execCallRequest,
                      params: { number: contacts[0].numbers[0].number },
                    });
                  } else {
                    callback(false, {
                      data: { ...prm.data, numbers },
                      text: prm.data.type,
                    });
                  }
                } else if (candidates.length === 1 && contacts.length === 0) {
                  callback(false, {
                    end: true,
                    action: execCallRequest,
                    params: { number: users[0].mainExtensionNumber },
                  });
                } else {
                  candidates.push(contacts.map((o) => o.fullname));
                  callback(true, {
                    end: false,
                    data: { ...prm.data, exact: true },
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandCallFound,
                      search: '%s',
                      replace: candidates.join(', '),
                    },
                  });
                }
              })
              .catch(() => {
                callback(true, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                });
              });
          };
          search(name);
        },
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const numberType = prm.text;
          // / only a number and user hasn't specified number type -> it's ok
          if (prm.data.numbers.length === 1 && !numberType) {
            callback(false, {
              end: true,
              action: execCallRequest,
              params: { number: prm.data.numbers[0].number },
            });
            return;
          }
          // / no number type specified but need it
          if (!numberType) {
            callback(true, {
              data: prm.data,
              messages: {
                messages: VocalAssistantMessages.voiceCommandCallGetType,
                search: '%s',
                replace: prm.data.numbers
                  .map((n) =>
                    intl.formatMessage(
                      VocalAssistantMessages.numberTypes[n.type]
                    )
                  )
                  .join(', '),
              },
            });
            return;
          }

          // / retrieve the number type name for the type specified by user
          let selectedType = null;
          const { numberTypes } = VocalAssistantMessages;
          for (let i = 0; i < Object.keys(numberTypes).length; i += 1) {
            const type = intl.formatMessage(
              numberTypes[Object.keys(numberTypes)[i]]
            );
            if (type.toLowerCase() === numberType.toLowerCase()) {
              selectedType = Object.keys(numberTypes)[i];
              break;
            }
          }
          if (
            !selectedType ||
            prm.data.numbers.filter((n) => n.type === selectedType).length === 0
          ) {
            callback(true, {
              data: { ...prm.data },
              messages: {
                messages: VocalAssistantMessages.voiceCommandCallNotFoundType,
              },
            });
          } else {
            callback(false, {
              end: true,
              action: execCallRequest,
              params: {
                number: prm.data.numbers.filter(
                  (n) => n.type === selectedType
                )[0].number,
              },
            });
          }
        },
      ],
    },
    // / recall last call
    {
      command: 'RECALL',
      templates: [
        /^(?:ambrosia\s)?richiam[\w]*\s(?:ultima\s)?chiamata\s(.*)$/i,
        /^(?:ambrosia\s)?recall\s(?:last\s)?(.*)\scall$/i,
      ],
      steps: [
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const callType = prm.text;
          if (!callType) {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandMissingCallType,
              },
            });
            return;
          }
          // / link right variable names to call type
          const filter = {};
          let resultName = null;
          switch (callType) {
            case intl.formatMessage(
              VocalAssistantMessages.messages.voiceCommandGeneralLostCallType
            ):
              filter.lost = true;
              resultName = 'calling';
              break;
            case intl.formatMessage(
              VocalAssistantMessages.messages.voiceCommandGeneralInCallType
            ):
              filter.in = true;
              resultName = 'calling';
              break;
            case intl.formatMessage(
              VocalAssistantMessages.messages.voiceCommandGeneralOutCallType
            ):
              filter.out = true;
              resultName = 'called';
              break;
            default:
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandMissingCallType,
                },
              });
              return;
          }

          PbxManager.retrieveMeCdr(filter)
            .then((res) => {
              if (!res.data || res.data.length === 0) {
                callback(false, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandNoCallFound,
                  },
                });
                return;
              }
              callback(false, {
                end: true,
                action: execCallRequest,
                params: { number: res.data[0][resultName] },
              });
            })
            .catch(() => {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              });
            });
        },
      ],
    },
    // / send chat message
    {
      command: 'SEND_MESSAGE',
      templates: [
        /^(?:ambrosia\s)?scriv[\w]*\s?(?:ad?\s)?(.*)$/i,
        /^(?:ambrosia\s)?mand[\w]*\s?(?:un\s)?(?:messaggio\s)?(?:ad?\s)?(.*)$/i,
        /^(?:ambrosia\s)?write\s?(?:to\s)?(.*)$/i,
        /^(?:ambrosia\s)?send\s?(?:a\s)?(?:message\s)?(?:to\s)?(.*)$/i,
      ],
      steps: [
        // Parse the input text to retrieve message and receiver
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const input = prm.text;
          // / retrieve message
          const delimiter = intl.formatMessage(
            VocalAssistantMessages.voiceCommandChatTextDelimiter[0]
          );

          const message =
            input.indexOf(delimiter) > 0
              ? input
                  .substring(input.indexOf(delimiter) + delimiter.length)
                  .trim()
              : null;

          // / retrieve receiver
          const receiver = message
            ? input.substring(0, input.indexOf(delimiter)).trim()
            : input;

          if (!receiver) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandChatMissingReceiver,
              },
            });
            return;
          }
          callback(false, {
            data: {
              message,
              receiver,
            },
            text: receiver,
          });
        },
        // Search the receiver among users and groups
        (prm, callback) => {
          this.searchChatContact(prm, callback);
        },
        // Check if user has said a message to send
        (prm, callback) => {
          const messageText = prm.message || prm.text;
          if (messageText) {
            prm.data.message = messageText;
            callback(false, {
              data: prm.data,
              text: '',
            });
          } else {
            callback(true, {
              data: prm.data,
              messages: {
                messages: VocalAssistantMessages.voiceCommandChatGetText,
                search: '%s',
                replace: prm.data.receiver,
              },
            });
          }
        },

        // Confirm or reject the chat operation
        (prm, callback) => {
          const confirmationAnswer = prm.text;
          // / no confirmation answer, ask user to confirm
          if (!confirmationAnswer) {
            callback(true, {
              data: prm.data,
              messages: {
                messages: VocalAssistantMessages.voiceCommandChatConfirmation,
                search: '%s',
                replace: prm.data.receiver,
              },
            });
            return;
          }
          let i;
          // / check if user has rejected the operation
          for (i = 0; i < this.cancelRegexp.length; i += 1) {
            if (confirmationAnswer.match(this.cancelRegexp[i])) {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandDeletedOperation,
                },
              });
              return;
            }
          }
          // / check if user has confirmed the operation
          for (i = 0; i < this.confirmRegexp.length; i += 1) {
            if (confirmationAnswer.match(this.confirmRegexp[i])) {
              api.me
                .getMe()
                .then((res) => {
                  const error = Utils.checkApiError(res);
                  if (error) throw error;

                  // Send message
                  const myId = res.data.id;
                  const ackUid = generateAckUid(myId);
                  const params = {
                    ackUid,
                    recipient: {
                      type: prm.data.receiverUserId ? 'USER' : 'GROUP',
                      id: prm.data.receiverUserId
                        ? prm.data.receiverUserId
                        : prm.data.receiverGroupId,
                    },
                    message: { text: prm.data.message, duration: null },
                  };
                  callback(false, {
                    end: true,
                    messages: {
                      messages:
                        VocalAssistantMessages.voiceCommandOperationExecuted,
                    },
                    action: sendChatMessageRequest,
                    params,
                  });
                })
                .catch(() => {
                  callback(true, {
                    end: true,
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandGenericError,
                    },
                  });
                });
              return;
            }
          }
          // / operation not found
          callback(true, {
            messages: {
              messages: VocalAssistantMessages.voiceCommandUnknownConfirmation,
            },
          });
        },
      ],
    },
    // / mark all chat messages as read
    {
      command: 'MARK_READ',
      templates: [
        /^(?:ambrosia\s)?segn[\w]* (?:tutti\s)?(?:i\s)?messaggi come letti*\s?(?:di?\s)?(.*)$/i,
        /^(?:ambrosia\s)?mark (?:all\s)?messages (?:as\s)?read*\s?(?:from?\s)?(.*)$/i,
      ],
      steps: [
        // Parse the input text to retrieve message and receiver
        (prm, callback) => {
          const receiver = prm.text;
          if (!receiver) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandChatMissingReceiver,
              },
            });
            return;
          }
          callback(false, {
            data: {
              receiver,
            },
            text: receiver,
          });
        },
        // Search the receiver among users and groups
        (prm, callback) => {
          this.searchChatContact(prm, callback);
        },
        // Confirm or reject the chat operation
        (prm, callback) => {
          // Send message
          // const myId = res.data.id;
          // const ackUid = generateAckUid(myId);
          const params = {
            userId: prm.data.receiverUserId,
            groupId: prm.data.receiverGroupId,
          };
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            action: sendReadAllMessageRequest,
            params,
          });
        },
      ],
    },
    // / read all unread messages
    /* {
      command: 'READ_UNREAD',
      templates: [
        /^(?:ambrosia\s)?leggi (?:i\s)?messaggi da leggere$/i,
        /^(?:ambrosia\s)?leggi (?:i\s)?messaggi non letti$/i,
        /^(?:ambrosia\s)?read unread messages$/i,
      ],
      steps: [
        (prm, callback) => {
          console.log('TODO READ_UNREAD');
        },
      ],
    },*/
    // / read messages of a given channel
    {
      command: 'READ_MESSAGES',
      templates: [
        /^(?:ambrosia\s)?leggi (?:i\s)?messaggi(?:\sdi)?\s(.*)?$/i,
        /^(?:ambrosia\s)?read messages(?:\sof)?\s(.*)?$/i,
      ],
      steps: [
        // Read messages for a channel
        (prm, callback) => {
          const receiver = prm.text;
          if (!receiver) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandChatMissingReceiver,
              },
            });
            return;
          }
          callback(false, {
            data: {
              receiver,
            },
            text: receiver,
          });
        },
        // Search the receiver among users and groups
        (prm, callback) => {
          this.searchChatContact(prm, callback);
        },
        // Check if user has said a starting date
        (prm, callback) => {
          const date = prm.text;
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          if (date) {
            const startingDate = this.retrieveDateFromText(date, intl);
            if (!startingDate) {
              callback(true, {
                messages: {
                  messages: VocalAssistantMessages.voiceCommandWrongDate,
                },
              });
              return;
            }
            const params = {
              userId: prm.data.receiverUserId,
              groupId: prm.data.receiverGroupId,
              after: startingDate.valueOf(),
            };
            api.chat
              .getHistory(params)
              .then((res) => {
                const error = Utils.checkApiError(res);
                if (error) throw error;
                let messages = [];
                res.data.forEach((el) => {
                  if (el.type === 'MESSAGE') {
                    if (this.urlRegEx.test(el.message.text)) {
                      messages = [...messages, 'link'];
                    } else messages = [...messages, el.message.text];
                  }
                });
                if (messages.length === 0) {
                  callback(false, {
                    end: true,
                    messages: {
                      messages:
                        VocalAssistantMessages.voiceCommandReadChannelNoMessages,
                    },
                  });
                  return;
                }
                // Mark retrieved messages as read
                let listOfMessagesToMarkAsRead = [];
                res.data.forEach((el) => {
                  if (
                    el.type === 'MESSAGE' &&
                    el.message.readTime === undefined &&
                    el.senderHistoryId !== undefined
                  ) {
                    const unreadMessage = {
                      senderHistoryId: el.senderHistoryId,
                      messageId: el.historyId,
                      duration: el.message.duration,
                    };
                    listOfMessagesToMarkAsRead = [
                      ...listOfMessagesToMarkAsRead,
                      unreadMessage,
                    ];
                  }
                });
                listOfMessagesToMarkAsRead.map((message) =>
                  callback(false, {
                    end: true,
                    action: sendReadMessageRequest,
                    params: message,
                  })
                );
                callback(false, {
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandReadChannelHeader,
                  },
                  end: true,
                  additionalMessages: messages.reverse(),
                });
              })
              .catch(() =>
                callback(true, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                })
              );
          } else {
            callback(true, {
              data: prm.data,
              messages: {
                messages: VocalAssistantMessages.voiceCommandReadChannelDate,
              },
            });
          }
        },
      ],
    },
    // / open chat
    {
      command: 'OPEN_CHAT',
      templates: [
        /^(?:ambrosia\s)?apr[\w]*\schat\s?(?:con\s)?(.*)$/i,
        /^(?:ambrosia\s)?open chat\s?(?:with\s)?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          this.searchChatContact(prm, (err, output) => {
            if (err) {
              return callback(err, output);
            }
            callback(false, {
              end: true,
              action: openChatConversation,
              params: {
                conversationId: generateConversationId({
                  userId: output.data.receiverUserId,
                  groupId: output.data.receiverGroupId,
                }),
                byMe: true,
              },
              messages: {
                messages: VocalAssistantMessages.voiceCommandOperationExecuted,
              },
            });
          });
        },
      ],
    },
    // / read lost calls
    {
      command: 'READ_LOST',
      templates: [
        /^(?:ambrosia\s)?leggi chiamate perse(?:\sdal?\s(.*))?$/i,
        /^(?:ambrosia\s)?read lost calls(?:(?:\sfrom)\s(.*))?$/i,
      ],
      steps: [
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const date = prm.text;
          if (!date) {
            callback(true, {
              // TODO messages: [amb.language.voice_command_read_lost_calls_date]
              messages: {
                messages: VocalAssistantMessages.voiceCommandNoCallFound,
              },
            });
            return;
          }
          // / retrieve starting date
          let startingDate = null;
          try {
            startingDate = VocalAssistantVoiceCommands.retrieveDateFromText(
              date,
              intl
            );
          } catch (e) {}

          if (!startingDate) {
            callback(true, {
              // TODO messages: [amb.language.voice_command_wrong_date]
              messages: {
                messages: VocalAssistantMessages.voiceCommandNoCallFound,
              },
            });
            return;
          }
          const filter = {
            lost: true,
            in: false,
            out: false,
            start: moment(startingDate).subtract(1, 'year').toDate(),
            end: moment(new Date()).toDate(),
          };

          callback(false, {
            end: true,
            task: 'CALLS_GET',
            params: {
              filter,
            },
          });
        },
      ],
    },
    {
      command: 'READ_LOST2',
      templates: [/^lost_calls\s(.*)$/i],
      steps: [
        (prm, callback) => {
          const calls = prm.text;
          if (!calls || calls === '') {
            callback(true, {
              end: true,
              messages: {
                // TODO MESSAGE
                messages: VocalAssistantMessages.voiceCommandGenericError,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              // TODO voice_command_read_lost_calls_header
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            additionalMessages: [calls],
          });
        },
      ],
    },
    // / search in abook
    {
      command: 'ABOOK_SEARCH',
      templates: [
        /^(?:ambrosia\s)?(?:ri)?cerc[\w]*\s(?:in\s)?rubrica\s?(.*)$/i,
        /^(?:ambrosia\s)?search\s(?:in\s)?address book\s?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const contactName = prm.text;
          if (!contactName) {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandSearchMissingName,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            path: `/abook/contacts/?name=${contactName}`,
          });
        },
      ],
    },
    // / add to abook last call without related contact
    {
      command: 'ABOOK_ADD',
      templates: [
        /^(?:ambrosia\s)?aggiung[\w]*\s(?:in\s)?(?:al?l?a?\s)?rubrica chiamata\s?(\w*)(?:\snon associata)?$/i,
        /^(?:ambrosia\s)?add\s(?:to\s)?address book\s?(.*)?\scall(?:\snot linked)?$/i,
      ],
      steps: [
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const callType = prm.text;
          if (!callType) {
            callback(true, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandMissingCallType,
              },
            });
            return;
          }

          // / link right variable names to call type
          const filter = {};
          const lostCall = intl.formatMessage(
            VocalAssistantMessages.messages.voiceCommandGeneralLostCallType
          );
          const inCall = intl.formatMessage(
            VocalAssistantMessages.messages.voiceCommandGeneralInCallType
          );
          const outCall = intl.formatMessage(
            VocalAssistantMessages.messages.voiceCommandGeneralOutCallType
          );
          switch (callType) {
            case lostCall:
              filter.lost = true;
              break;
            case inCall:
              filter.in = true;
              break;
            case outCall:
              filter.out = true;
              break;
            default:
              callback(true, {
                messages: {
                  messages: VocalAssistantMessages.voiceCommandMissingCallType,
                },
              });
              return;
          }
          callback(false, {
            end: true,
            task: 'LAST_CALL_GET',
            params: {
              filter,
            },
          });
        },
      ],
    },
    {
      command: 'ABOOK_ADD2',
      templates: [/^add_number\s(.*)$/i],
      steps: [
        (prm, callback) => {
          const numberToAdd = prm.text;
          if (!numberToAdd || numberToAdd === '') {
            callback(true, {
              end: true,
              messages: {
                // TODO MESSAGE
                messages: VocalAssistantMessages.voiceCommandGenericError,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            task: 'SECTION_GOTO',
            params: {
              path: `/abook/contacts/edit?type=MOBILE&number=${numberToAdd}`,
            },
          });
        },
      ],
    },

    // / play unlistened received vbox
    {
      command: 'PLAY_UNLISTENED',
      templates: [
        /^(?:ambrosia\s)?riproduci (?:i\s)?messaggi ricevuti(?:\snon ascoltati)?$/i,
        /^(?:ambrosia\s)?play received(?:\sunlistened)? messages$/i,
      ],
      steps: [
        (prm, callback) => {
          api.vbox
            .getVboxMessages()
            .then((res) => {
              if (res.data && res.data.data && res.data.data.length > 0) {
                // const messages = res.data.data;
                console.log('TODO READ MESSAGES');
              } else {
                callback(true, {
                  end: true,
                  // TODO voice_command_play_vboxes_no_messages
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                });
                return;
              }
            })
            .catch(() => {
              callback(true, {
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              });
            });
        },
      ],
    },
    // / enable do not disturb
    {
      command: 'ENABLE_DND',
      templates: [
        /^(?:ambrosia\s)?abilit[\w]*\s(?:opzione\s)?non disturbare$/i,
        /^(?:ambrosia\s)?enable\s(?:don't\s|do not\s)disturb$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            action: enablePhoneDnd,
          });
        },
      ],
    },
    // / disable do not disturb
    {
      command: 'DISABLE_DND',
      templates: [
        /^(?:ambrosia\s)?disabilit[\w]*\s(?:opzione\s)?non disturbare$/i,
        /^(?:ambrosia\s)?disable\s(?:don't\s|do not\s)disturb$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            action: disablePhoneDnd,
          });
        },
      ],
    },
    // / show favourites
    {
      command: 'SHOW_FAVOURITE',
      templates: [
        /^(?:ambrosia\s)?visualizz[\w]*\s(?:gruppo\s)?preferiti$/i,
        /^(?:ambrosia\s)?view favou?rites(?:\sgroup)?$/i,
      ],
      steps: [
        (prm, callback) => {
          api.me
            .getMeGroups()
            .then((res) => {
              const error = Utils.checkApiError(res);
              if (error) throw error;
              const group = res.data.filter((el) => el.favorites === true);
              const params = {
                id: group[0].id,
                settings: { hidden: false, status: 'NORMAL' },
              };
              api.me
                .updateMyGroupSettings(params)
                .then(() => {
                  const error = Utils.checkApiError(res);
                  if (error) throw error;
                  callback(false, {
                    end: true,
                    messages: {
                      messages:
                        VocalAssistantMessages.voiceCommandOperationExecuted,
                    },
                    action: updatePinnedGroupSettingsSuccess,
                    params,
                    scroll: true,
                  });
                })
                .catch(() =>
                  callback(true, {
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandGenericError,
                    },
                    action: updatePinnedGroupSettingsFailure,
                  })
                );
            })
            .catch(() =>
              callback(true, {
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              })
            );
        },
      ],
    },
    // / search in groups (right column)
    {
      command: 'GROUP_SEARCH',
      templates: [
        /^(?:ambrosia\s)?(?:ri)?cerc[\w]*\s(?:in\s)?gruppi\s?(.*)$/i,
        /^(?:ambrosia\s)?search\s(?:in\s)?groups\s?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const contactName = prm.text;
          if (!contactName) {
            callback(true, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandSearchMissingName,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            action: setPinnedGroupsFilter,
            params: contactName,
          });
        },
      ],
    },
    // / search in contacts (right column)
    {
      command: 'CONTACT_SEARCH',
      templates: [
        /^(?:ambrosia\s)?(?:ri)?cerc[\w]*\s(?:in\s)?contatti\s?(.*)$/i,
        /^(?:ambrosia\s)?search\s(?:in\s)?contacts\s?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const contactName = prm.text;
          if (!contactName) {
            callback(true, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandSearchMissingName,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            action: setPinnedGroupsFilter,
            params: contactName,
          });
        },
      ],
    },
    // / enable notifications
    {
      command: 'ENABLE_NOTIFICATIONS',
      templates: [
        /^(?:ambrosia\s)?abilit[\w]*\snotifiche$/i,
        /^(?:ambrosia\s)?enable notifications$/i,
      ],
      steps: [
        // enable notifications
        (prm, callback) => {
          let actualNotifications;
          api.me.getSettings().then((response) => {
            actualNotifications = response.data;
            const params = {
              enabled: true,
              calls: actualNotifications.notifCalls,
              messages: actualNotifications.notifMessages,
              fax: actualNotifications.notifFax,
              vbox: actualNotifications.notifVbox,
              data: actualNotifications.notifData,
              users: actualNotifications.notifUsers,
              sound: actualNotifications.notifSound,
              mailoffice: actualNotifications.notifMailoffice,
              calendar: actualNotifications.notifCalendar,
              presences: actualNotifications.notifPresences,
              timeout: parseInt(actualNotifications.notifTimeout, 10),
              videocalls: actualNotifications.notifVideocalls,
            };
            api.me
              .updateNotifictionSettings(params)
              .then(() => {
                callback(false, {
                  end: true,
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandOperationExecuted,
                  },
                  action: updateNotificationSettingsSuccess,
                  params,
                });
              })
              .catch((err) => {
                callback(false, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                  action: updateNotificationSettingsFailure,
                  error: err.status,
                });
              });
          });
        },
      ],
    },
    // / disable notifications
    {
      command: 'DISABLE_NOTIFICATIONS',
      templates: [
        /^(?:ambrosia\s)?disabilit[\w]*\snotifiche$/i,
        /^(?:ambrosia\s)?disable notifications$/i,
      ],
      steps: [
        // enable notifications
        (prm, callback) => {
          let actualNotifications;
          api.me.getSettings().then((response) => {
            actualNotifications = response.data;
            const params = {
              enabled: false,
              calls: actualNotifications.notifCalls,
              messages: actualNotifications.notifMessages,
              fax: actualNotifications.notifFax,
              vbox: actualNotifications.notifVbox,
              data: actualNotifications.notifData,
              users: actualNotifications.notifUsers,
              sound: actualNotifications.notifSound,
              mailoffice: actualNotifications.notifMailoffice,
              calendar: actualNotifications.notifCalendar,
              presences: actualNotifications.notifPresences,
              timeout: parseInt(actualNotifications.notifTimeout, 10),
              videocalls: actualNotifications.notifVideocalls,
            };
            api.me
              .updateNotifictionSettings(params)
              .then(() => {
                callback(false, {
                  end: true,
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandOperationExecuted,
                  },
                  action: updateNotificationSettingsSuccess,
                  params,
                });
              })
              .catch((err) => {
                callback(false, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                  action: updateNotificationSettingsFailure,
                  error: err.status,
                });
              });
          });
        },
      ],
    },
    // / go to section
    {
      command: 'SECTION_GOTO',
      templates: [
        /^(?:ambrosia\s)?vai al?l?a?\s(?:sezione\s)?(.*)$/i,
        /^(?:ambrosia\s)?go to\s(?:section\s)?(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          let navigation = prm.text;
          if (!navigation) {
            callback(true, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandGotoMissingSection,
              },
            });
            return;
          }
          navigation = navigation.toLowerCase();
          const sections = VocalAssistantMessages.gotoSections.map((o) => ({
            section: intl.formatMessage(o.section),
            subSection: o.subSection ? intl.formatMessage(o.subSection) : '',
            path: o.path,
          }));
          const section = sections.filter(
            (o) => navigation.indexOf(o.section.toLowerCase()) === 0
          );
          if (section.length === 0) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandGotoSectionNotFound,
              },
            });
            return;
          }
          if (section.length === 1) {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandOperationExecuted,
              },
              task: 'SECTION_GOTO',
              params: {
                path: section[0].path,
              },
            });
            return;
          }
          const navigationSubSection = navigation
            .replace(section[0].section.toLowerCase(), '')
            .trim()
            .toLowerCase();
          if (!navigationSubSection || navigationSubSection === '') {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandOperationExecuted,
              },
              task: 'SECTION_GOTO',
              params: {
                path: section[0].path,
              },
            });
            return;
          }
          const subSection = section.filter(
            (o) =>
              navigationSubSection.indexOf(o.subSection.toLowerCase()) === 0
          );
          if (subSection.length === 0) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandGotoSubSectionNotFound,
              },
            });
            return;
          }
          if (subSection.length === 1) {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandOperationExecuted,
              },
              task: 'SECTION_GOTO',
              params: {
                path: subSection[0].path,
              },
            });
            return;
          }
          const navigationSubSubSection = navigationSubSection
            .replace(subSection[0].subSection.toLowerCase(), '')
            .trim()
            .toLowerCase();
          if (!navigationSubSubSection || navigationSubSubSection === '') {
            callback(false, {
              end: true,
              messages: {
                messages: VocalAssistantMessages.voiceCommandOperationExecuted,
              },
              task: 'SECTION_GOTO',
              params: {
                path: subSection[0].path,
              },
            });
            return;
          }
          const subSubSection = section.filter(
            (o) =>
              navigationSubSubSection.indexOf(o.subSubSection.toLowerCase()) ===
              0
          );
          if (subSubSection.length === 0) {
            callback(true, {
              end: true,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandGotoSubSectionNotFound,
              },
            });
            return;
          }
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationExecuted,
            },
            task: 'SECTION_GOTO',
            params: {
              path: subSubSection[0].path,
            },
          });
        },
      ],
    },
    // / check weather
    {
      command: 'CHECK_WEATHER',
      templates: [
        /^(?:ambrosia\s)?che tempo fa\s?(domenica\s|luned(?:\xCC|i)\s|marted(?:\xCC|i)\s|mercoled(?:\xCC|i)\s|gioved(?:\xCC|i)\s|venerd(?:\xCC|i)\s|sabato\s|oggi\s|domani\s|[t|f]ra\s\d{1,2}\sgiorni\s|[t|f]ra\sdue\sgiorni\s|il\s\d{1,2}\s\w*\s)?(?:prossim(?:o|a)\s)?a\s(.*)?$/i,
        /^(?:ambrosia\s)?what(?:'s|\sis) the weather like\s?(?:next\s)?(sunday\s|monday\s|tuesday\s|wednesday\s|thursday\s|friday\s|saturday\s|today\s|tomorrow\s|in\s\d{1,2}\sdays\s|in\stwo\sdays\s|the\s\d{1,2}\s\w*\s)?in\s(.*)?$/i,
      ],
      steps: [
        (prm, callback) => {
          const nextDays = prm.text[0] ? prm.text[0].trim() : null;
          const place = prm.text[1];
          callback(false, {
            data: {
              nextDays,
              place,
            },
            text: place,
          });
        },

        (prm, callback) => {
          const place = prm.text;
          if (!place) {
            callback(true, {
              end: false,
              messages: {
                messages:
                  VocalAssistantMessages.voiceCommandWeatherMissingPlace,
              },
            });
            return;
          }
          prm.data.place = place;
          callback(false, {
            data: prm.data,
            text: prm.data.nextDays,
          });
        },

        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          const nextDays = prm.text;
          if (!nextDays) {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandWeatherMissingDate,
              },
            });
            return;
          }
          // / retrieve day count from today to input day
          let count = null;
          const weatherDate = VocalAssistantVoiceCommands.retrieveDateFromText(
            nextDays,
            intl
          );
          if (weatherDate) {
            // / specified date, check it's from today
            if (weatherDate > VocalAssistantUtils.getYesterday()) {
              count = moment(weatherDate).diff(
                VocalAssistantUtils.getYesterday(),
                'days'
              );
            } else {
              callback(true, {
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandWeatherPreviousDate,
                },
              });
              return;
            }
          } else {
            // / user has said "in 2 days"
            count = nextDays.match(/\d+/) ? +nextDays.match(/\d+/)[0] : null;
            // / speech recognition write 2 with chars, detect it
            const regExp = new RegExp(
              `\\b${VocalAssistantMessages.genericTwo}\\b`,
              'i'
            );
            if (!count && nextDays.match(regExp)) {
              count = 2;
            }
          }
          // / no number found
          if (count === null) {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandWeatherInvalidDate,
              },
            });
            return;
          }
          // / not available forecast
          if (count > 4) {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandWeatherFarDate,
              },
            });
            return;
          }

          const requestedDate = new Date();
          requestedDate.setDate(requestedDate.getDate() + count);
          const morning = requestedDate.setHours(9, 0, 0);
          const afternoon = requestedDate.setHours(15, 0, 0);
          const evening = requestedDate.setHours(21, 0, 0);
          // / call weather webservice
          const urlplace = encodeURI(prm.data.place);
          api
            .getWeather({ place: urlplace, lang: prm.lang })
            .then((response) => {
              if (
                response.error ||
                !response.data.list ||
                response.data.cod !== '200'
              ) {
                callback(true, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                });
                return;
              }
              const weatherData = response.data; // JSON.parse(response)
              let message = '';
              // / cycle days in response to find the right one
              for (let i = 0; i < weatherData.list.length; i += 1) {
                const resultTime = weatherData.list[i]['dt_txt'];
                if (
                  resultTime ===
                  VocalAssistantDateUtils.datetimeFormat(
                    'YYYY-MM-DD HH:mm:ss',
                    morning
                  )
                ) {
                  message += `${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherMorning,
                    '%s',
                    weatherData.list[i].weather[0].description
                  )} ${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherTemp,
                    '%s',
                    +weatherData.list[i].main.temp_min
                  )}`;
                } else if (
                  resultTime ===
                  VocalAssistantDateUtils.datetimeFormat(
                    'YYYY-MM-DD HH:mm:ss',
                    afternoon
                  )
                ) {
                  message += `${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherAfternoon,
                    '%s',
                    weatherData.list[i].weather[0].description
                  )} ${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherTemp,
                    '%s',
                    +weatherData.list[i].main.temp
                  )}`;
                } else if (
                  resultTime ===
                  VocalAssistantDateUtils.datetimeFormat(
                    'YYYY-MM-DD HH:mm:ss',
                    evening
                  )
                ) {
                  message += `${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherEvening,
                    '%s',
                    weatherData.list[i].weather[0].description
                  )} ${VocalAssistantVoiceCommands.replaceStringAndTranslate(
                    VocalAssistantMessages.voiceCommandWeatherTemp,
                    '%s',
                    +weatherData.list[i].main.temp
                  )}`;
                } else {
                  continue;
                }
              }

              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandWeatherHeader,
                  search: '%s',
                  replace: weatherData.city.name,
                },
                additionalMessages: [
                  message,
                  intl.formatMessage(
                    VocalAssistantMessages.voiceCommandWeatherOpenweathermap[0]
                  ),
                ],
              });
            })
            .catch(() => {
              callback(true, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              });
            });
        },
      ],
    },
    // / help
    {
      command: 'HELP',
      templates: [/^aiuto$/i, /^comandi$/i, /^help$/i, /^commands$/i],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandOperationList,
            },
            helpModal: true,
          });
        },
      ],
    },
    // / jokes
    {
      command: 'JOKE',
      templates: [
        /^(?:ambrosia\s)?racconta[\w]*\suna barzelletta$/i,
        /^(?:ambrosia\s)?dimmi una barzelletta$/i,
        /^(?:ambrosia\s)?fammi ridere$/i,
        /^(?:ambrosia\s)?tell me a joke$/i,
        /^(?:ambrosia\s)?make me laugh$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandJokes,
            },
          });
        },
      ],
    },
    // / check date
    {
      command: 'CHECK_DATE',
      templates: [
        /^dimmi il giorno/i,
        /^(?:ambrosia\s)?che giorno \xE8(?:\soggi)?\??$/i,
        /^(?:ambrosia\s)?what day is today\??$/i,
      ],
      steps: [
        (prm, callback) => {
          const d = new Date();
          const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
          const sdate = {
            0: {
              id: 'VocalAssistantMessages.sdate',
              defaultMessage: d.toLocaleDateString(
                prm.lang
                  ? VocalAssistantUtils.getLanguageISOcode(prm.lang)
                  : 'it_IT',
                options
              ),
            },
          };
          callback(false, {
            end: true,
            messages: {
              messages: sdate,
            },
          });
        },
      ],
    },
    // / check time
    {
      command: 'CHECK_TIME',
      templates: [
        /^(?:ambrosia\s)?dimmi l'ora$/i,
        /^(?:ambrosia\s)?che ora è\??$/i,
        /^(?:ambrosia\s)?che ore sono\??$/i,
        /^(?:ambrosia\s)?what time is it\??$/i,
        /^(?:ambrosia\s)?what(?:'s|\sis) the time\??$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: {
                0: {
                  id: 'VocalAssistantMessages.stime',
                  defaultMessage: VocalAssistantDateUtils.datetimeFormat(
                    'HH:mm',
                    new Date()
                  ),
                },
              },
            },
          });
        },
      ],
    },
    // / bye bye
    {
      templates: [
        /^no(?:\sgrazie)?(?:\sciao)?$/i,
        /^nulla(?:\sgrazie)?(?:\sciao)?$/i,
        /^niente(?:\sgrazie)?(?:\sciao)?$/i,
        /^puoi andare(?:\sgrazie)?(?:\sciao)?$/i,
        /^(?:stai\s)?zitta(?:\sambrosia)?(?:\sciao)?$/i,
        /^silenzio(?:\sambrosia)?(?:\sciao)?$/i,
        /^no(?:\sthanks)?(?:\sbye)?$/i,
        /^nothing(?:\sthanks)?(?:\sbye)?$/i,
        /^you can go(?:\sthanks)?(?:\sbye)?$/i,
        /^shut up(?:\sambrosia)?(?:\sbye)?$/i,
        /^silence(?:\sambrosia)?(?:\sbye)?$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandByeBye,
            },
            stop: true,
          });
        },
      ],
    },
    // / bad words
    {
      templates: [/\*\*/i],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandBadWords,
            },
          });
        },
      ],
    },
    // / compliments
    {
      templates: [
        /^(?:ambrosia\s)?(?:come\s)?(?:sei\s)?(?:brava|bravissima|bella|bellissima|stupenda|meravigliosa|elegante|sexy)(?:\sambrosia)?\??$/i,
        /^(?:ambrosia\s)?(?:you\s?)?(?:are\s|'re\s)?(?:so\s)?(?:good|very good|beautiful|very beautiful|gorgeous|wonderful|elegant|stylish|sexy)(?:\sambrosia)?$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandCompliments,
            },
          });
        },
      ],
    },
    // / say the name
    {
      templates: [/^ambrosia$/i],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
            messages: {
              messages: VocalAssistantMessages.voiceCommandIntroAgain,
            },
          });
        },
      ],
    },
    // / bypass normal greetings or words
    {
      templates: [
        /^ciao(?:\sambrosia)?$/i,
        /^buongiorno(?:\sambrosia)?$/i,
        /^buon pomeriggio(?:\sambrosia)?$/i,
        /^buonasera(?:\sambrosia)?$/i,
        /^si(?:\sgrazie)?$/i,
        /^sì(?:\sgrazie)?$/i,
        /^grazie(?:\smille)?$/i,
        /^ferm[\w]*$/i,
        /^stop$/i,
        /^hi(?:\sambrosia)?$/i,
        /^hello(?:\sambrosia)?$/i,
        /^good morning(?:\sambrosia)?$/i,
        /^good afternoon(?:\sambrosia)?$/i,
        /^good evening(?:\sambrosia)?$/i,
        /^yes$/i,
        /^thank you(?:\svery much)?$/i,
      ],
      steps: [
        (prm, callback) => {
          callback(false, {
            end: true,
          });
        },
      ],
    },
    // / read today events
    {
      command: 'READ_EVENTS',
      templates: [
        /^(?:ambrosia\s)?leggi (?:gli\s)?eventi di oggi$/i,
        /^(?:ambrosia\s)?read today events$/i,
      ],
      steps: [
        (prm, callback) => {
          // / retrieve today events
          const params = {
            start: moment()
              .startOf('day')
              .format(CalendarEnums.GetEventStartDateFormat),
            end: moment()
              .endOf('day')
              .format(CalendarEnums.GetEventEndDateFormat),
            onlyMine: true,
          };
          api.calendar
            .getEvents(params)
            .then((res) => {
              const error = Utils.checkApiError(res);
              if (error) throw error;
              const events = res.data;
              const eventTitles = [];
              if (events.length === 0) {
                callback(false, {
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandReadCalendarNoEvents,
                  },
                  end: true,
                });
              } else {
                events.forEach((event) => {
                  let title;
                  if (event.allDay) {
                    title = this.replaceStringAndTranslate(
                      VocalAssistantMessages.voiceCommandReadCalendarMessageAllday,
                      '%s',
                      event.title
                    );
                  } else {
                    title = this.replaceStringAndTranslate(
                      VocalAssistantMessages.voiceCommandReadCalendarMessage,
                      '%s',
                      event.title
                    );
                    title = this.replaceStringInMessage(
                      title,
                      '%a',
                      VocalAssistantDateUtils.datetimeFormat(
                        CalendarEnums.EventHourFormat,
                        event.start
                      )
                    );
                    title = this.replaceStringInMessage(
                      title,
                      '%b',
                      VocalAssistantDateUtils.datetimeFormat(
                        CalendarEnums.EventHourFormat,
                        event.end
                      )
                    );
                  }
                  eventTitles.push(title);
                });

                callback(false, {
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandReadCalendarHeader,
                  },
                  end: true,
                  action: fetchCalendarEventsFailure,
                  additionalMessages: eventTitles,
                });
              }
            })
            .catch(() => {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              });
            });
        },
      ],
    },
    // / create calendar event
    {
      command: 'CREATE_EVENT',
      templates: [
        // eslint-disable-next-line
        /^(?:ambrosia\s)?crea (?:un\s)?(?:l\')?evento\s?(.*)$/i,
        /^(?:ambrosia\s)?create event\s?(.*)$/i,
      ],
      steps: [
        // Parse the input text to retrieve message
        (prm, callback) => {
          const eventName = prm.text;
          callback(false, {
            text: eventName,
            data: {},
          });
        },
        // Check if user has said a name for the event
        (prm, callback) => {
          const eventName = prm.text;
          if (eventName) {
            prm.data.title = eventName;
            callback(false, {
              data: prm.data,
            });
          } else {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandCreateEventText,
              },
            });
          }
        },
        // Retrieve day for the event
        (prm, callback) => {
          const intl = VocalAssistantUtils.retriveIntl(prm.lang);
          let dayStart;
          let dayEnd;
          let found = false;
          // / build date from text
          const retrieveDate = (date) => {
            if (date.match(/[a-z]/i)) {
              return this.retrieveDateFromText(date, intl);
            }
            let newDate = moment();
            const matches = date.match(/(\d)+/g);
            let day;
            let month;
            let year;
            if (matches[0]) {
              if (parseInt(matches[0], 10) > 31) {
                return null;
              }
              if (parseInt(matches[0], 10) < newDate.getDate()) {
                newDate = moment(newDate).add(1, 'M').toDate();
              }
              day = parseInt(matches[0], 10);
            }
            if (matches[1]) {
              if (parseInt(matches[1], 10) > 12) {
                return null;
              }
              if (parseInt(matches[1], 10) - 1 < newDate.getMonth()) {
                newDate = moment(newDate).add('years', 1).toDate();
              }
              month = parseInt(matches[1], 10) - 1;
            }
            if (matches[2]) {
              if (parseInt(matches[2], 10) > 9999) {
                return null;
              }
              year = parseInt(matches[2], 10);
              year = year < 100 ? year + 2000 : year;
            }
            if (year) {
              newDate.setFullYear(year);
            }
            if (month) {
              newDate.setMonth(month);
            }
            if (day) {
              newDate.setDate(parseInt(matches[0], 10));
            }
            return newDate;
          };
          if (prm.text) {
            // / check if event is in multiple days
            const multipleDaysTemplates = [
              /^dal? (.*) al? (.*)$/i,
              /^from (?:the )?(.*) to (?:the )?(.*)$/i,
            ];
            if (prm.text) {
              for (let i = 0; i < multipleDaysTemplates.length; i += 1) {
                if (prm.text.match(multipleDaysTemplates[i])) {
                  found = true;
                  dayStart = retrieveDate(
                    prm.text.match(multipleDaysTemplates[i])[1]
                  );
                  dayEnd = retrieveDate(
                    prm.text.match(multipleDaysTemplates[i])[2]
                  );
                  break;
                }
              }
            }
            if (!found) {
              // / check if it is in a single day
              const singleDayTemplates = [
                /^(?:il\s)?(?:giorno\s)?(.*)$/i,
                /^(?:the\s)?(?:day\s)?(.*)$/i,
              ];
              for (let i = 0; i < singleDayTemplates.length; i += 1) {
                if (prm.text.match(singleDayTemplates[i])) {
                  found = true;
                  dayStart = retrieveDate(
                    prm.text.match(singleDayTemplates[i])[1]
                  );
                  dayEnd = retrieveDate(
                    prm.text.match(singleDayTemplates[i])[1]
                  );
                  break;
                }
              }
            }
          }
          if (found && dayStart && dayEnd) {
            // / check dates are correct
            if (
              isNaN(dayStart.getTime()) ||
              isNaN(dayEnd.getTime()) ||
              dayStart.getTime() > dayEnd.getTime()
            ) {
              callback(true, {
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandCreateEventInvalidDate,
                },
              });
              return;
            }
            prm.data.startDay = dayStart;
            prm.data.endDay = dayEnd;
            callback(false, {
              data: prm.data,
            });
          } else {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandCreateEventDay,
              },
            });
          }
        },
        // Retrieve time for the event
        (prm, callback) => {
          let found = false;
          let allDay = false;
          let timeStart;
          let timeEnd;
          // / build time from text
          const retrieveTime = (date) => {
            const matches = date.match(/(\d)+/g);
            const hours =
              parseInt(matches[0], 10) < 10
                ? `0${parseInt(matches[0], 10)}`
                : matches[0];
            let minutes;
            if (matches[1]) {
              minutes =
                parseInt(matches[1], 10) < 10
                  ? `0${parseInt(matches[1], 10)}`
                  : matches[1];
            } else {
              minutes = '00';
            }
            if (hours < 24 && minutes < 60) {
              return `${hours}:${minutes}`;
            }
            return null;
          };
          if (prm.text) {
            // / check if all day event
            const allDayTemplates = [
              /^tutt(?:i|o) il? giorn(?:o|i)$/i,
              /^all (?:the )days?$/i,
            ];
            for (let i = 0; i < allDayTemplates.length; i += 1) {
              if (prm.text.match(allDayTemplates[i])) {
                found = true;
                allDay = true;
                break;
              }
            }
            if (!found) {
              // / check if 'from .. to ..' event
              const intraDayTemplates = [
                /^dal?(?:le)? (?:ore )?(.*) al?(?:le)? (?:ore )?(.*)$/i,
                /^from (.*) to (.*)$/i,
              ];
              if (prm.text) {
                for (let i = 0; i < intraDayTemplates.length; i += 1) {
                  if (prm.text.match(intraDayTemplates[i])) {
                    found = true;
                    timeStart = retrieveTime(
                      prm.text.match(intraDayTemplates[i])[1]
                    );
                    timeEnd = retrieveTime(
                      prm.text.match(intraDayTemplates[i])[2]
                    );
                    break;
                  }
                }
              }
            }
          }
          if (found) {
            // / check time was correct
            if (!allDay && (!timeStart || !timeEnd)) {
              callback(true, {
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandCreateEventInvalidTime,
                },
              });
              return;
            }
            // / set start and end dates
            const start = prm.data.startDay;
            const end = prm.data.endDay;
            if (allDay) {
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
            } else {
              start.setHours(
                VocalAssistantDateUtils.stringToTime(timeStart).getHours()
              );
              start.setMinutes(
                VocalAssistantDateUtils.stringToTime(timeStart).getMinutes()
              );
              start.setSeconds(0);
              end.setHours(
                VocalAssistantDateUtils.stringToTime(timeEnd).getHours()
              );
              end.setMinutes(
                VocalAssistantDateUtils.stringToTime(timeEnd).getMinutes()
              );
              end.setSeconds(0);
            }
            if (start > end) {
              callback(true, {
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandCreateEventInvalidDate,
                },
              });
              return;
            }
            if (!allDay) {
              prm.data.startTime =
                VocalAssistantDateUtils.stringToTime(timeStart);
              prm.data.endTime = VocalAssistantDateUtils.stringToTime(timeEnd);
            }
            prm.data.start = start;
            prm.data.end = end;
            prm.data.allDay = allDay;
            callback(false, {
              data: prm.data,
              text: '',
            });
          } else {
            callback(true, {
              messages: {
                messages: VocalAssistantMessages.voiceCommandCreateEventTime,
              },
            });
          }
        },
        // Confirm or reject the event inserting/
        (prm, callback) => {
          const confirmationAnswer = prm.text;
          // / no confirmation answer, ask user to confirm
          if (!confirmationAnswer) {
            let message;
            if (
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventDateFormat,
                prm.data.startDay
              ) ===
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventDateFormat,
                prm.data.endDay
              )
            ) {
              message = prm.data.allDay
                ? VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation
                : VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation;
            } else {
              message = prm.data.allDay
                ? VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation
                : VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation;
            }

            const search = ['%l', '%s', '%f', '%e', '%t'];
            const replace = [
              prm.data.title,
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventDateFormat,
                prm.data.startDay
              ),
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventHourFormat,
                prm.data.startTime
              ),
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventDateFormat,
                prm.data.endDay
              ),
              VocalAssistantDateUtils.datetimeFormat(
                CalendarEnums.EventHourFormat,
                prm.data.endTime
              ),
            ];
            callback(true, {
              data: prm.data,
              messages: {
                messages: message,
                search,
                replace,
              },
            });
            return;
          }

          let i;
          // / check if user has rejected the operation
          for (i = 0; i < this.cancelRegexp.length; i += 1) {
            if (confirmationAnswer.match(this.cancelRegexp[i])) {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandDeletedOperation,
                },
              });
              return;
            }
          }
          // / check if user has confirmed the operation
          for (i = 0; i < this.confirmRegexp.length; i += 1) {
            if (confirmationAnswer.match(this.confirmRegexp[i])) {
              api.me
                .getMe()
                .then((res) => {
                  const error = Utils.checkApiError(res);
                  if (error) throw error;
                  const userId = res.data.id;
                  // / collect event data
                  const eventData = {
                    color: CalendarEnums.EventDefaultColor,
                    title: prm.data.title,
                    start: prm.data.start,
                    end: prm.data.end,
                    allDay: prm.data.allDay,
                    shared: [
                      { user: userId, participate: true, management: true },
                    ],
                  };
                  api.calendar
                    .createEvent(eventData)
                    .then(() => {
                      const error = Utils.checkApiError(res);
                      if (error) throw error;
                      callback(false, {
                        messages: {
                          messages:
                            VocalAssistantMessages.voiceCommandOperationExecuted,
                        },
                        action: saveCalendarEventSuccess,
                      });
                    })
                    .catch((err) => {
                      callback(false, {
                        end: true,
                        messages: {
                          messages:
                            VocalAssistantMessages.voiceCommandGenericError,
                        },
                        action: saveCalendarEventFailure,
                        error: err.status,
                      });
                    });
                })
                .catch(() =>
                  callback(false, {
                    end: true,
                    messages: {
                      messages: VocalAssistantMessages.voiceCommandGenericError,
                    },
                  })
                );
              return;
            }
          }
          // / operation not found
          callback(true, {
            messages: {
              messages: VocalAssistantMessages.voiceCommandUnknownConfirmation,
            },
          });
        },
        (prm, callback) => {
          callback(false, {
            end: true,
            task: 'SECTION_GOTO',
            params: {
              path: '/calendar/show',
            },
          });
        },
      ],
    },
    // / read sticky notes
    {
      command: 'READ_STICKY',
      templates: [
        /^(?:ambrosia\s)?leggi[\w]*\s(?:i\s)?postit$/i,
        /^(?:ambrosia\s)?leggi[\w]*\s(?:i\s)?post it$/i,
        /^(?:ambrosia\s)?leggi[\w]*\s(?:i\s)?post-it$/i,
        /^(?:ambrosia\s)?leggi[\w]*\s(?:gli\s)?sticky$/i,
        /^(?:ambrosia\s)?leggi[\w]*\s(?:le\s)?note$/i,
        /^(?:ambrosia\s)?read sticky(?:\snotes)?$/i,
      ],
      steps: [
        (prm, callback) => {
          api.stickyNotes
            .getStickyNotes()
            .then((res) => {
              const stickies = res.data;
              let notes = [];
              notes = stickies && stickies.map((sticky) => sticky.text);
              if (notes.length === 0) {
                callback(false, {
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandReadStickyNoNotes,
                  },
                  end: true,
                });
              } else {
                callback(false, {
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandReadStickyHeader,
                  },
                  end: true,
                  additionalMessages: notes,
                });
              }
            })
            .catch(() => {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
              });
            });
        },
      ],
    },
    // / create sticky
    {
      command: 'CREATE_STICKY',
      templates: [
        /^(?:ambrosia\s)?crea[\w]*\s(?:un\s)?postit(.*)$/i,
        /^(?:ambrosia\s)?crea[\w]*\s(?:un\s)?post it(.*)$/i,
        /^(?:ambrosia\s)?crea[\w]*\s(?:un\s)?post-it(.*)$/i,
        /^(?:ambrosia\s)?crea[\w]*\s(?:uno\s)?sticky(.*)$/i,
        /^(?:ambrosia\s)?crea[\w]*\s(?:una\s)?nota(.*)$/i,
        /^(?:ambrosia\s)?create sticky(?:\snotes)(.*)$/i,
      ],
      steps: [
        (prm, callback) => {
          const input = prm.text;
          // / retrieve message
          const message =
            input.indexOf(
              VocalAssistantMessages.voiceCommandCreateStickyDelimiter[0]
                .defaultMessage
            ) > 0
              ? input
                  .substring(
                    input.indexOf(
                      VocalAssistantMessages
                        .voiceCommandCreateStickyDelimiter[0].defaultMessage
                    ) +
                      VocalAssistantMessages
                        .voiceCommandCreateStickyDelimiter[0].defaultMessage
                        .length
                  )
                  .trim()
              : null;
          callback(false, {
            text: message,
          });
        },

        (prm, callback) => {
          const messageText = prm.text;
          const params = {
            text: messageText,
            detail: StickyUtils.stickyDetails,
          };
          if (messageText) {
            api.stickyNotes
              .addStickyNote(params)
              .then((res) => {
                const error = Utils.checkApiError(res);
                if (error) throw error;
                callback(false, {
                  end: true,
                  messages: {
                    messages:
                      VocalAssistantMessages.voiceCommandOperationExecuted,
                  },
                  action: addStickyNotesSuccess,
                  params: { ...params, id: res.data.id },
                });
              })
              .catch((err) => {
                callback(false, {
                  end: true,
                  messages: {
                    messages: VocalAssistantMessages.voiceCommandGenericError,
                  },
                  action: addStickyNotesFailure,
                  error: err.status,
                });
              });
          } else {
            callback(true, {
              data: prm.data,
              messages: {
                messages: VocalAssistantMessages.voiceCommandCreateStickyText,
              },
            });
          }
        },
      ],
    },
    // / show sticky
    {
      command: 'SHOW_STICKY',
      templates: [
        /^(?:ambrosia\s)?mostr[\w]*\s(?:i\s)?postit$/i,
        /^(?:ambrosia\s)?mostr[\w]*\s(?:i\s)?post it$/i,
        /^(?:ambrosia\s)?mostr[\w]*\s(?:i\s)?post-it$/i,
        /^(?:ambrosia\s)?mostr[\w]*\s(?:gli\s)?sticky$/i,
        /^(?:ambrosia\s)?mostr[\w]*\s(?:le\s)?note$/i,
        /^(?:ambrosia\s)?show sticky(?:\snotes)?$/i,
      ],
      steps: [
        (prm, callback) => {
          api.stickyNotes
            .updateStickyVisibility(true)
            .then(() =>
              callback(false, {
                end: true,
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandOperationExecuted,
                },
                action: updateStickyVisibilitySuccess,
                params: { show: true },
              })
            )
            .catch((err) => {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
                action: updateStickyVisibilityFailure,
                error: err.status,
              });
            });
        },
      ],
    },
    // / hide sticky notes
    {
      command: 'HIDE_STICKY',
      templates: [
        /^(?:ambrosia\s)?nascond[\w]*\s(?:i\s)?postit$/i,
        /^(?:ambrosia\s)?nascond[\w]*\s(?:i\s)?post it$/i,
        /^(?:ambrosia\s)?nascond[\w]*\s(?:i\s)?post-it$/i,
        /^(?:ambrosia\s)?nascond[\w]*\s(?:gli\s)?sticky$/i,
        /^(?:ambrosia\s)?nascond[\w]*\s(?:le\s)?note$/i,
        /^(?:ambrosia\s)?hide sticky(?:\snotes)?$/i,
      ],
      steps: [
        (prm, callback) => {
          api.stickyNotes
            .updateStickyVisibility(false)
            .then(() =>
              callback(false, {
                end: true,
                messages: {
                  messages:
                    VocalAssistantMessages.voiceCommandOperationExecuted,
                },
                action: updateStickyVisibilitySuccess,
              })
            )
            .catch((err) => {
              callback(false, {
                end: true,
                messages: {
                  messages: VocalAssistantMessages.voiceCommandGenericError,
                },
                action: updateStickyVisibilityFailure,
                error: err.status,
              });
            });
        },
      ],
    },
  ];
}
