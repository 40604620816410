/** Payments */
export const FETCH_PAYMENTS_CREDITS_REQUEST = 'FETCH_PAYMENTS_CREDITS_REQUEST';
export const FETCH_PAYMENTS_CREDITS_SUCCESS = 'FETCH_PAYMENTS_CREDITS_SUCCESS';
export const FETCH_PAYMENTS_CREDITS_FAILURE = 'FETCH_PAYMENTS_CREDITS_FAILURE';
export const UPDATE_PAYMENTS_EMAILS_REQUEST = 'UPDATE_PAYMENTS_EMAILS_REQUEST';
export const UPDATE_PAYMENTS_EMAILS_SUCCESS = 'UPDATE_PAYMENTS_EMAILS_SUCCESS';
export const UPDATE_PAYMENTS_EMAILS_FAILURE = 'UPDATE_PAYMENTS_EMAILS_FAILURE';
/** Transactions */
export const FETCH_PURCHASES_REQUEST = 'FETCH_PURCHASES_REQUEST';
export const FETCH_PURCHASES_SUCCESS = 'FETCH_PURCHASES_SUCCESS';
export const FETCH_PURCHASES_FAILURE = 'FETCH_PURCHASES_FAILURE';
export const FETCH_ALL_PURCHASES_REQUEST = 'FETCH_ALL_PURCHASES_REQUEST';
export const FETCH_ALL_PURCHASES_SUCCESS = 'FETCH_ALL_PURCHASES_SUCCESS';
export const FETCH_ALL_PURCHASES_FAILURE = 'FETCH_ALL_PURCHASES_FAILURE';
/** PayPal */
export const PAYPAL_PAY_REQUEST = 'PAYPAL_PAY_REQUEST';
export const PAYPAL_PAY_SUCCESS = 'PAYPAL_PAY_SUCCESS';
export const PAYPAL_PAY_FAILURE = 'PAYPAL_PAY_FAILURE';
/** Denominations */
export const FETCH_DENOMINATIONS_REQUEST = 'FETCH_DENOMINATIONS_REQUEST';
export const FETCH_DENOMINATIONS_SUCCESS = 'FETCH_DENOMINATIONS_SUCCESS';
export const FETCH_DENOMINATIONS_FAILURE = 'FETCH_DENOMINATIONS_FAILURE';
