import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash.uniq';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import Button from './formControls/Button';
import ContactsSelect from './formControls/ContactsSelect';
import { getContactById } from '../js/contacts/selectors';
import TagsInput from './TagsInput';
import { addressbookGrant } from '../js/me/selectors';

const messages = defineMessages({
  contactsPlaceholder: {
    id: 'SelectPhoneNumbers.contactsPlaceholder',
    defaultMessage: 'Digit one or more phone number',
  },
  buttonSearchAbook: {
    id: 'SelectPhoneNumbers.buttonSearchAbook',
    defaultMessage: 'Search',
  },
  contactsModalNumbersLabel: {
    id: 'SelectPhoneNumbers.label.contactsModalNumbersLabel',
    defaultMessage: 'Select numbers you want to search calls: ',
  },
  contactsModalPlaceholder: {
    id: 'SelectPhoneNumbers.contactsModalPlaceholder',
    defaultMessage: 'Search name, surname, company, vat / fiscal code',
  },
  contactsModalTitle: {
    id: 'SelectPhoneNumbers.contactsModalTitle',
    defaultMessage: 'Search for contacts phone numbers',
  },
  buttonProceed: {
    id: 'SelectPhoneNumbers.buttonProceed',
    defaultMessage: 'Proceed',
  },
  buttonCancel: {
    id: 'SelectPhoneNumbers.buttonCancel',
    defaultMessage: 'Cancel',
  },
});

class SelectPhoneNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumbers: undefined,
      contactsModalOpen: false,
      selectedContact: undefined,
      selectedNumbers: [],
      errors: {},
    };
  }

  handleChangePhoneNumbers = (phoneNumbers) => {
    this.setState(
      {
        phoneNumbers: uniq(phoneNumbers.split(',')).filter((c) => c !== ''),
      },
      () => {
        this.handleOnChange();
      }
    );
  };

  handleSelectedContact = (selectedContact) => {
    this.setState({ selectedContact, selectedNumbers: [] });
  };

  handleSelectContactNumber = (e, number) => {
    this.setState({
      selectedNumbers: e.target.checked
        ? [...this.state.selectedNumbers, number]
        : this.state.selectedNumbers.filter((n) => n !== number),
    });
  };

  handleSelectedNumbers = () => {
    this.setState(
      {
        phoneNumbers: uniq([
          ...(this.state.phoneNumbers || []),
          ...this.state.selectedNumbers,
        ]),
      },
      () => {
        this.handleOnChange();
        this.toggleContactsModal();
      }
    );
  };

  handleOnChange = () => {
    const { onChange } = this.props;
    const { phoneNumbers } = this.state;
    onChange(phoneNumbers);
  };

  toggleContactsModal = (e) => {
    e && e.preventDefault();
    this.setState((prevState) => ({
      contactsModalOpen: !prevState.contactsModalOpen,
      selectedContact: prevState.contactsModalOpen
        ? prevState.selectedContact
        : null,
      selectedNumbers: prevState.contactsModalOpen
        ? prevState.selectedNumbers
        : [],
    }));
  };

  render() {
    const {
      phoneNumbers,
      contact,
      contactGrant,
      error,
      intl: { formatMessage },
    } = this.props;

    const { selectedContact, contactsModalOpen } = this.state;

    return (
      <>
        <div className="row no-gutters">
          <div className="col">
            <TagsInput
              id="phoneNumbers"
              name="phoneNumbers"
              pattern="phoneNumber"
              value={phoneNumbers.join(', ')}
              onChange={this.handleChangePhoneNumbers}
              placeholder={formatMessage(messages.contactsPlaceholder)}
              error={error}
            />
          </div>
          {contactGrant && (
            <div className="col-auto ml-1">
              <Button
                className="btn btn-primary"
                onClick={this.toggleContactsModal}
                text={formatMessage(messages.buttonSearchAbook)}
              />
            </div>
          )}
        </div>

        <Modal toggle={this.toggleContactsModal} isOpen={contactsModalOpen}>
          <ModalHeader toggle={this.toggleContactsModal}>
            {formatMessage(messages.contactsModalTitle)}
          </ModalHeader>
          <ModalBody>
            <ContactsSelect
              isMulti={false}
              onSelect={this.handleSelectedContact}
              selected={selectedContact}
              isClearable
              showVatFiscalcode
              placeholder={formatMessage(messages.contactsModalPlaceholder)}
            />
            {selectedContact && (
              <div className="border rounded mt-2 p-3">
                <div className="font-weight-bold mb-2">
                  {formatMessage(messages.contactsModalNumbersLabel)}
                </div>
                {contact(selectedContact) &&
                  contact(selectedContact).numbers &&
                  contact(selectedContact).numbers.length &&
                  contact(selectedContact).numbers.map((number) => (
                    <div key={number.number} className="row">
                      <div className="col">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id={`contact_${number.number}`}
                            name={`contact_${number.number}`}
                            className="mt-2 custom-control-input"
                            onChange={(e) =>
                              this.handleSelectContactNumber(e, number.number)
                            }
                            checked={
                              this.state.selectedNumbers.indexOf(
                                number.number
                              ) > -1
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`contact_${number.number}`}
                          >
                            {number.number}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-primary ml-2"
              text={formatMessage(messages.buttonProceed)}
              onClick={this.handleSelectedNumbers}
              disabled={!this.state.selectedNumbers.length}
            />
            <Button
              className="btn btn-outline-primary ml-2"
              text={formatMessage(messages.buttonCancel)}
              onClick={this.toggleContactsModal}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

SelectPhoneNumbers.propTypes = {
  onChange: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.array, // (comma separated values)
  error: PropTypes.string,
};

SelectPhoneNumbers.defaultProps = { phoneNumbers: [], error: '' };

function mapStateToProps(state) {
  return {
    contact: (id) => getContactById(state, id),
    contactGrant: addressbookGrant(state),
  };
}

export default injectIntl(connect(mapStateToProps)(SelectPhoneNumbers));
