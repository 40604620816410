import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Platform from './index';

const PlatformSettingsCustomer = React.lazy(() =>
  import('./PlatformSettingsCustomer')
);

const PlatformSettingsCustomerPage = (props) => (
  <Platform>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PlatformSettingsCustomer {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Platform>
);

export default PlatformSettingsCustomerPage;
