import React from 'react';
import PersonalSettings from './index';
import ErrorBoundary from '../../../../components/ErrorBoundary';

const ProfileSettings = React.lazy(() => import('./ProfileSettings'));

const ProfileSettingsPage = (props) => (
  <PersonalSettings>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <ProfileSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PersonalSettings>
);

export default ProfileSettingsPage;
