import {
  select,
  take,
  takeLatest,
  takeEvery,
  call,
  put,
  all,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import {
  fetchVboxMessagesSuccess,
  fetchVboxMessagesFailure,
  deleteVboxMessagesSuccess,
  deleteVboxMessagesFailure,
  fetchTvoxSoundsSuccess,
  fetchTvoxSoundsFailure,
  fetchTvoxPriceSuccess,
  fetchTvoxPriceFailure,
  purchaseTvoxMessageSuccess,
  purchaseTvoxMessageFailure,
  fetchArchivedGreetingMessagesSuccess,
  fetchArchivedGreetingMessagesFailure,
  loadOwnGreetingFailure,
  loadOwnGreetingSuccess,
  saveOwnGreetingSuccess,
  saveOwnGreetingFailure,
  fetchActiveGreetingMessagesSuccess,
  fetchActiveGreetingMessagesFailure,
  activatePbxGreetingSuccess,
  activatePbxGreetingFailure,
  fetchArchivedGreetingMessagesRequest,
  deleteArchivedGreetingMessageSuccess,
  deleteArchivedGreetingMessageFailure,
  savePbxGreetingSuccess,
  savePbxGreetingFailure,
  listenVboxSuccess,
  listenVboxFailure,
  fetchVboxMessagesRequest,
} from './actions';
import {
  FETCH_VBOX_MESSAGES_REQUEST,
  DELETE_VBOX_MESSAGES_REQUEST,
  FETCH_TVOX_SOUNDS_REQUEST,
  FETCH_TVOX_PRICE_REQUEST,
  PURCHASE_TVOX_MESSAGE_REQUEST,
  FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST,
  LOAD_OWN_GREETING_REQUEST,
  SAVE_OWN_GREETING_REQUEST,
  FETCH_ACTIVE_GREETING_MESSAGES_REQUEST,
  ACTIVATE_PBX_GREETING_REQUEST,
  DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST,
  SAVE_PBX_GREETING_REQUEST,
  DELIVERED_TVOX_NOTIFICATION,
  ME_RECEIVED_VBOX_NOTIFICATION,
  PBX_RECEIVED_VBOX_NOTIFICATION,
  LISTEN_VBOX_REQUEST,
} from './types';
import history from '../../history';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { getSupplier, isPhoneLogged } from '../phone/selectors';
import { PhoneEnums } from '../phone/PhoneUtils';
import { PbxManager } from '../phone/PbxManager';
import {
  getMeVboxMessagesFilter,
  getPbxArchivedGreetingMessagesCurrentPage,
  getPbxVboxMessagesFilter,
} from './selectors';
import { ARCHIVED_MESSAGES_PAGE_SIZE } from './VboxUtils';
import { getAbilisVboxGreeting } from '../me/selectors';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import Utils from '../lib/utils';
import { getInterlocutorData } from '../phone/sagas';

const intlStrings = defineMessages({
  receivedVbox: {
    id: 'receivedVbox',
    defaultMessage: 'You have received a vocal message',
  },
  deliveredTvox: {
    id: 'deliveredTvox',
    defaultMessage: 'A professional message has been delivered',
  },
});

export function* fetchVboxMessages(action) {
  try {
    const res = yield call(api.vbox.getVboxMessages, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchVboxMessagesSuccess({
          messages: [],
          page: action.payload.page,
          total: res.data.total,
          isPbx: !!action.payload.pbx,
        })
      );
    } else {
      const interlocutors = res.data.data.map((rows) =>
        call(getInterlocutorData, rows.numberSender, false)
      );
      for (let i = 0; i < interlocutors.length; i += 1) {
        const response = yield interlocutors[i];
        res.data.data[i].contact = response;
      }
      yield put(
        fetchVboxMessagesSuccess({
          messages: res.data.data,
          page: action.payload.page,
          total: res.data.total,
          isPbx: !!action.payload.pbx,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVboxMessagesFailure(error, !!action.payload.pbx));
  }
}

function* deleteVboxMessages(action) {
  try {
    const notDeletedVboxMessagesIds = [];
    let errors = null;
    if (action.payload.ids && action.payload.ids.length) {
      for (let i = 0; i < action.payload.ids.length; i += 1) {
        try {
          const res = yield call(
            api.vbox.deleteVboxMessage,
            action.payload.ids[i]
          );
          yield call(checkApiResponse, res);
        } catch (err) {
          errors = err;
          notDeletedVboxMessagesIds.push(action.payload.ids[i]);
        }
      }
    }
    if (!notDeletedVboxMessagesIds.length) {
      yield put(deleteVboxMessagesSuccess(action.payload));
      const filter = yield select(
        action.payload.isPbx
          ? getPbxVboxMessagesFilter
          : getMeVboxMessagesFilter
      );
      yield put(fetchVboxMessagesRequest(filter));
    } else {
      yield put(deleteVboxMessagesFailure(errors, action.payload.isPbx));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(deleteVboxMessagesFailure(error, action.payload.isPbx));
  }
}

function* fetchTvoxSounds() {
  try {
    const res = yield call(api.vbox.getTvoxSounds);
    yield call(checkApiResponse, res);
    yield put(fetchTvoxSoundsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTvoxSoundsFailure(error));
  }
}

function* fetchTvoxPrice(action) {
  try {
    const res = yield call(api.vbox.getTvoxPrice, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchTvoxPriceSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTvoxPriceFailure(error));
  }
}

function* purchaseTvoxMessage(action) {
  try {
    const res = yield call(api.vbox.purchaseTvoxMessage, action.payload);
    yield call(checkApiResponse, res);
    yield put(purchaseTvoxMessageSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        purchaseTvoxMessageFailure(
          err.data && err.data !== '' && err.data.length > 0
            ? err.data[0]
            : error
        )
      );
  }
}

function* fetchArchivedGreetingMessages(action) {
  try {
    const res = yield call(
      api.vbox.getArchivedGreetingMessages,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(
      fetchArchivedGreetingMessagesSuccess({
        greetingMessages: res.data.data || [],
        page: action.payload && action.payload.page,
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchArchivedGreetingMessagesFailure(error));
  }
}

export function* loadOwnGreeting() {
  const supplier = yield select(getSupplier);
  if (supplier === PhoneEnums.PbxType.ABILIS) {
    const greeting = yield select(getAbilisVboxGreeting);
    yield put(loadOwnGreetingSuccess(greeting));
  } else {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const res = yield call(PbxManager.loadOwnVboxGreeting.bind(PbxManager));
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      yield put(loadOwnGreetingFailure());
    } else {
      yield put(loadOwnGreetingSuccess(res.data));
    }
  }
}

export function* saveOwnGreeting(action) {
  try {
    const res = yield call(api.me.saveGreetingMessage, action.greeting);
    yield call(checkApiResponse, res);
    yield put(saveOwnGreetingSuccess(action.greeting));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveOwnGreetingFailure(error));
  }
}

function* fetchActiveGreetingMessages() {
  try {
    const [active, archived] = yield all([
      call(api.vbox.getActiveGreetingMessages),
      call(api.vbox.getArchivedGreetingMessages),
    ]);
    yield call(checkApiResponse, archived);
    yield call(checkApiResponse, active);
    yield put(fetchActiveGreetingMessagesSuccess(active));
    yield put(
      fetchArchivedGreetingMessagesSuccess({
        greetingMessages: archived.data.data || [],
        page: 0,
        total: 0,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchActiveGreetingMessagesFailure(error));
  }
}

export function* activatePbxGreeting(action) {
  try {
    const res = yield call(
      api.vbox.activatePbxGreetingMessage,
      action.greeting
    );
    yield call(checkApiResponse, res);
    yield put(activatePbxGreetingSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(activatePbxGreetingFailure(error));
  }
}

function* deleteArchivedGreetingMessage(action) {
  try {
    const res = yield call(
      api.vbox.deleteArchivedGreetingMessage,
      action.payload.id
    );
    yield call(checkApiResponse, res);
    yield put(deleteArchivedGreetingMessageSuccess());
    const currentPage = yield select(getPbxArchivedGreetingMessagesCurrentPage);
    yield put(
      fetchArchivedGreetingMessagesRequest({
        page: currentPage,
        pageSize: ARCHIVED_MESSAGES_PAGE_SIZE,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteArchivedGreetingMessageFailure(error));
  }
}

export function* savePbxGreeting(action) {
  try {
    const res = yield call(api.vbox.savePbxGreetingMessage, action.greeting);
    yield call(checkApiResponse, res);
    yield put(savePbxGreetingSuccess());
    yield put(push('/pbx/archived/list'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(savePbxGreetingFailure(error));
  }
}

export function* notifyDeliveredTvox(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.DELIVERED_TVOX}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.deliveredTvox),
      group: NotificationEnums.NotificationGroups.DELIVERED_TVOX,
      image: NotificationEnums.NotificationImages.PBX_TVOX_RECEIVED,
      onclick: () => {
        history.push('/pbx/vbox/notifications');
      },
    });
  }
}

export function* notifyReceivedVbox(action, isPbx) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.RECEIVED_VBOX}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.receivedVbox),
      group: NotificationEnums.NotificationGroups.RECEIVED_VBOX,
      image: isPbx
        ? NotificationEnums.NotificationImages.PBX_VBOX_RECEIVED
        : NotificationEnums.NotificationImages.ME_VBOX_RECEIVED,
      onclick: () => {
        if (isPbx) {
          history.push('/pbx/vbox/notifications');
        } else {
          history.push('/phone/vbox/notifications');
        }
      },
    });
  }
}

export function* listenVbox(action) {
  try {
    const res = yield call(api.vbox.listenVboxMessage, action.vboxId);
    yield call(checkApiResponse, res);
    yield put(listenVboxSuccess(action.vboxId, action.isPbx));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(listenVboxFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_VBOX_MESSAGES_REQUEST, fetchVboxMessages);
  yield takeLatest(DELETE_VBOX_MESSAGES_REQUEST, deleteVboxMessages);
  yield takeLatest(FETCH_TVOX_SOUNDS_REQUEST, fetchTvoxSounds);
  yield takeLatest(FETCH_TVOX_PRICE_REQUEST, fetchTvoxPrice);
  yield takeLatest(PURCHASE_TVOX_MESSAGE_REQUEST, purchaseTvoxMessage);
  yield takeLatest(
    FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST,
    fetchArchivedGreetingMessages
  );
  yield takeLatest(LOAD_OWN_GREETING_REQUEST, loadOwnGreeting);
  yield takeLatest(SAVE_OWN_GREETING_REQUEST, saveOwnGreeting);
  yield takeLatest(
    FETCH_ACTIVE_GREETING_MESSAGES_REQUEST,
    fetchActiveGreetingMessages
  );
  yield takeLatest(ACTIVATE_PBX_GREETING_REQUEST, activatePbxGreeting);
  yield takeLatest(
    DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST,
    deleteArchivedGreetingMessage
  );
  yield takeLatest(SAVE_PBX_GREETING_REQUEST, savePbxGreeting);
  yield takeEvery(DELIVERED_TVOX_NOTIFICATION, notifyDeliveredTvox);
  yield takeEvery(ME_RECEIVED_VBOX_NOTIFICATION, (action) =>
    notifyReceivedVbox(action, false)
  );
  yield takeEvery(PBX_RECEIVED_VBOX_NOTIFICATION, (action) =>
    notifyReceivedVbox(action, true)
  );
  yield takeEvery(LISTEN_VBOX_REQUEST, listenVbox);
}
