import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { checkApiResponse, checkApiError } from '../rootSaga';
import api from '../api';
import {
  assignWorkingHoursPlanToUserFailure,
  assignWorkingHoursPlanToUserSuccess,
  assignWorkingHoursPlanToAllUsersFailure,
  assignWorkingHoursPlanToAllUsersSuccess,
  createWorkingHoursPlanFailure,
  createWorkingHoursPlanSuccess,
  deleteWorkingHoursPlanFailure,
  deleteWorkingHoursPlanSuccess,
  getWorkingHoursPlanFailure,
  getWorkingHoursPlansFailure,
  getWorkingHoursPlansRequest,
  getWorkingHoursPlansSuccess,
  getWorkingHoursPlanSuccess,
  updateWorkingHoursPlanFailure,
  updateWorkingHoursPlanSuccess,
  assignWorkingHoursPlanSuccess,
  assignWorkingHoursPlanFailure,
  saveWorkingRoundSuccess,
  saveWorkingRoundFailure,
  saveWorkingBreakSuccess,
  saveWorkingBreakFailure,
} from './actions';
import {
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST,
  CREATE_WORKING_HOURS_PLAN_REQUEST,
  DELETE_WORKING_HOURS_PLAN_REQUEST,
  GET_WORKING_HOURS_PLANS_REQUEST,
  GET_WORKING_HOURS_PLAN_REQUEST,
  UPDATE_WORKING_HOURS_PLAN_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_REQUEST,
  SAVE_WORKING_ROUND_REQUEST,
  SAVE_WORKING_BREAK_REQUEST,
} from './types';
import { getWorkingPlansCurrentPage } from './selectors';
import { WorkingPlansEnums } from './WorkingPlansUtils';
import { fetchUsersRequest } from '../users/actions';

export function* createWorkingHoursPlan(action) {
  try {
    const res = yield call(
      api.workingPlans.createWorkingHoursPlan,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(createWorkingHoursPlanSuccess());
    yield put(push('/administration/presences/management/working-hours'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        createWorkingHoursPlanFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* updateWorkingHoursPlan(action) {
  try {
    const res = yield call(
      api.workingPlans.updateWorkingHoursPlan,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(updateWorkingHoursPlanSuccess());
    yield put(push('/administration/presences/management/working-hours'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        updateWorkingHoursPlanFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* getWorkingHoursPlan(action) {
  try {
    const res = yield call(api.workingPlans.getWorkingHoursPlan, action.id);
    yield call(checkApiResponse, res);
    yield put(getWorkingHoursPlanSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        getWorkingHoursPlanFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* getWorkingHoursPlans(action) {
  try {
    const res = yield call(
      api.workingPlans.getWorkingHoursPlans,
      action.params
    );
    yield call(checkApiResponse, res);
    yield put(
      getWorkingHoursPlansSuccess({
        data: res.status === 200 ? res.data.data : [],
        page: action.params && action.params.page ? action.params.page : 0,
        total: res.data && res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(getWorkingHoursPlansFailure(error));
  }
}

export function* assignWorkingHoursPlanToUser(action) {
  try {
    const res = yield call(
      api.workingPlans.assignWorkingHoursPlanToUser,
      action.id,
      action.userId
    );
    yield call(checkApiResponse, res);
    yield put(assignWorkingHoursPlanToUserSuccess(res.data));
    yield put(fetchUsersRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        assignWorkingHoursPlanToUserFailure(
          err.data.error ? err.data.error : error
        )
      );
  }
}
export function* deleteWorkingHoursPlan(action) {
  try {
    const res = yield call(api.workingPlans.removeWorkingHoursPlan, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteWorkingHoursPlanSuccess());
    const currentPage = yield select(getWorkingPlansCurrentPage);
    yield put(
      getWorkingHoursPlansRequest({
        page: currentPage,
        pageSize: WorkingPlansEnums.WORKING_PLANS_PAGE_SIZE,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteWorkingHoursPlanFailure(error));
  }
}

export function* assignWorkingHoursPlantoAllUsers(action) {
  try {
    const res = yield call(
      api.workingPlans.assignWorkingHoursPlanToAllUsers,
      action.id
    );
    yield call(checkApiResponse, res);
    yield put(assignWorkingHoursPlanToAllUsersSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(assignWorkingHoursPlanToAllUsersFailure(error));
  }
}

export function* assignWorkingHoursPlan(action) {
  try {
    const res = yield call(
      api.workingPlans.assignWorkingHoursPlan,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(assignWorkingHoursPlanSuccess(res.data));
    const currentPage = yield select(getWorkingPlansCurrentPage);
    yield put(
      getWorkingHoursPlansRequest({
        page: currentPage,
        pageSize: WorkingPlansEnums.WORKING_PLANS_PAGE_SIZE,
      })
    );
    yield put(fetchUsersRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        assignWorkingHoursPlanFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* saveWorkingRound(action) {
  try {
    const res = yield call(api.workingPlans.roundWorkingHours, action.payload);
    yield call(checkApiResponse, res);
    yield put(saveWorkingRoundSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        saveWorkingRoundFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* saveWorkingBreak(action) {
  try {
    const res = yield call(
      api.workingPlans.breakWorkingHours,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(saveWorkingBreakSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        saveWorkingBreakFailure(err.data.error ? err.data.error : error)
      );
  }
}

export default function* rootSaga() {
  yield takeLatest(CREATE_WORKING_HOURS_PLAN_REQUEST, createWorkingHoursPlan);
  yield takeLatest(UPDATE_WORKING_HOURS_PLAN_REQUEST, updateWorkingHoursPlan);
  yield takeLatest(GET_WORKING_HOURS_PLAN_REQUEST, getWorkingHoursPlan);
  yield takeLatest(GET_WORKING_HOURS_PLANS_REQUEST, getWorkingHoursPlans);
  yield takeLatest(
    ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST,
    assignWorkingHoursPlanToUser
  );
  yield takeLatest(DELETE_WORKING_HOURS_PLAN_REQUEST, deleteWorkingHoursPlan);
  yield takeLatest(
    ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST,
    assignWorkingHoursPlantoAllUsers
  );
  yield takeLatest(ASSIGN_WORKING_HOURS_PLAN_REQUEST, assignWorkingHoursPlan);
  yield takeLatest(SAVE_WORKING_ROUND_REQUEST, saveWorkingRound);
  yield takeLatest(SAVE_WORKING_BREAK_REQUEST, saveWorkingBreak);
}
