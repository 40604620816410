import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_PBX_REGISTRY_REQUEST,
  FETCH_PBX_REGISTRY_SUCCESS,
  FETCH_PBX_REGISTRY_FAILURE,
  SAVE_PBX_REGISTRY_REQUEST,
  SAVE_PBX_REGISTRY_SUCCESS,
  SAVE_PBX_REGISTRY_FAILURE,
} from './types';

const initialstate = {
  fetched: true,
  errorFetching: false,
  errorSaving: false,
  successSaving: false,
  savingLoaded: true,
  data: {},
};

export default function registry(state = initialstate, action = {}) {
  switch (action.type) {
    case FETCH_PBX_REGISTRY_REQUEST:
      return {
        ...state,
        fetched: false,
        errorFetching: false,
      };
    case FETCH_PBX_REGISTRY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.params.id]: action.params.data || {},
        },
        fetched: true,
        errorFetching: false,
      };
    case FETCH_PBX_REGISTRY_FAILURE: {
      return {
        ...state,
        fetched: false,
        errorFetching: true,
      };
    }
    case SAVE_PBX_REGISTRY_REQUEST:
      return {
        ...state,
        savingLoaded: false,
        errorSaving: false,
        successSaving: false,
      };
    case SAVE_PBX_REGISTRY_SUCCESS:
      return {
        ...state,
        savingLoaded: true,
        errorSaving: false,
        successSaving: true,
      };
    case SAVE_PBX_REGISTRY_FAILURE: {
      return {
        ...state,
        savingLoaded: true,
        errorSaving: true,
        successSaving: false,
      };
    }

    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE:
      return initialstate;
    default:
      return state;
  }
}
