import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchPaymentsCreditsSuccess,
  fetchPaymentsCreditsFailure,
  updatePaymentsEmailsSuccess,
  updatePaymentsEmailsFailure,
  fetchPurchasesSuccess,
  fetchPurchasesFailure,
  fetchAllPurchasesSuccess,
  fetchAllPurchasesFailure,
  payPalPaySuccess,
  payPalPayFailure,
  fetchDenominationsSuccess,
  fetchDenominationsFailure,
} from './actions';
import {
  FETCH_PAYMENTS_CREDITS_REQUEST,
  UPDATE_PAYMENTS_EMAILS_REQUEST,
  FETCH_PURCHASES_REQUEST,
  FETCH_ALL_PURCHASES_REQUEST,
  PAYPAL_PAY_REQUEST,
  FETCH_DENOMINATIONS_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

function* fetchPaymentsCredits() {
  try {
    const res = yield call(api.payments.getPaymentsCredits);
    yield call(checkApiResponse, res);
    yield put(fetchPaymentsCreditsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPaymentsCreditsFailure(error));
  }
}

function* updatePaymentsEmail(action) {
  try {
    const res = yield call(api.payments.updatePaymentsEmail, action.params);
    yield call(checkApiResponse, res);
    yield put(updatePaymentsEmailsSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePaymentsEmailsFailure(error));
  }
}

export function* fetchPurchases(action) {
  try {
    const res = yield call(api.payments.getPurchases, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchPurchasesSuccess(
        {
          purchases: res.data.data,
          page: action.payload.page,
          total: res.data.count,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPurchasesFailure(error));
  }
}

export function* fetchAllPurchases(action) {
  try {
    const res = yield call(api.payments.getPurchases, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchAllPurchasesSuccess(res.data.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAllPurchasesFailure(error));
  }
}

function* payPalPayment(action) {
  try {
    const res = yield call(api.payments.pay, action.payment);
    yield call(checkApiResponse, res);
    yield put(payPalPaySuccess(res.data.paypalUrl, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(payPalPayFailure(error));
  }
}

function* fetchDenominations() {
  try {
    const res = yield call(api.payments.getDenominations);
    yield call(checkApiResponse, res);
    yield put(fetchDenominationsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchDenominationsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_PAYMENTS_CREDITS_REQUEST, fetchPaymentsCredits);
  yield takeLatest(UPDATE_PAYMENTS_EMAILS_REQUEST, updatePaymentsEmail);
  yield takeLatest(FETCH_PURCHASES_REQUEST, fetchPurchases);
  yield takeLatest(FETCH_ALL_PURCHASES_REQUEST, fetchAllPurchases);
  yield takeLatest(PAYPAL_PAY_REQUEST, payPalPayment);
  yield takeLatest(FETCH_DENOMINATIONS_REQUEST, fetchDenominations);
}
