import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import {
  fetchPinnedGroupsRequest,
  updatePinnedGroupSettingsRequest,
  updatePinnedGroupsOrderRequest,
  setFavoritesGroupScroll,
} from '../../../js/groups/actions';
import {
  getGroupsPinned,
  getFetchPinnedGroupsLoaded,
  getFetchPinnedGroupsError,
  getAllGroupsIds,
  getPinnedGroupsFilter,
  getGroupsById,
} from '../../../js/groups/selectors';
import GroupPanel from './GroupPanel';
import ToastMessage from '../../../components/messages/ToastMessage';
import Loading from '../../../components/Loading';

const messages = defineMessages({
  genericError: {
    id: 'GroupsPanel.error',
    defaultMessage: 'Generic error',
  },
});

const GroupItem = SortableElement((props) => <GroupPanel {...props} />);

const GroupsList = SortableContainer(({ groups, filter }) => (
  <div>
    {groups.map(
      (group, index) =>
        !group.hidden && (
          <GroupItem
            key={group.id}
            group={group}
            index={index}
            disabled={group.main || group.favorites || !!filter}
          />
        )
    )}
  </div>
));

class GroupsPanel extends Component {
  constructor(props) {
    super(props);
    this.groupDiv = React.createRef();
  }

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  componentDidUpdate(prevProps) {
    if (this.props.scroll !== prevProps.scroll && this.props.scroll !== false) {
      this.groupDiv.current.scrollTop = 0;
      this.props.favoritesScroll(false);
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { order, updateOrder } = this.props;
    const newOrder = arrayMove(order, oldIndex, newIndex);
    updateOrder(newOrder);
  };

  render() {
    const {
      intl: { formatMessage },
      groups,
      loading,
      serverError,
      error,
      filter,
    } = this.props;

    return loading ? (
      <Loading />
    ) : (
      <div
        className="bg-white"
        ref={this.groupDiv}
        style={{
          height: 'calc(100% - 45px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {serverError && (
          <Fade tag="div" className="pr-2 pt-2 pl-2">
            <ToastMessage
              type="danger"
              dismissable={false}
              text="Syncro error"
            />
          </Fade>
        )}
        {error && (
          <Fade tag="div" className="pr-2 pt-2 pl-2">
            <ToastMessage
              type="danger"
              dismissable={false}
              text={formatMessage(messages.genericError)}
            />
          </Fade>
        )}
        {groups.length > 0 && (
          <GroupsList
            groups={groups}
            onSortEnd={this.onSortEnd}
            distance={3}
            filter={filter}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groups: getGroupsPinned(state),
    order: getAllGroupsIds(state),
    loading: !getFetchPinnedGroupsLoaded(state),
    error: getFetchPinnedGroupsError(state),
    filter: getPinnedGroupsFilter(state),
    groupsById: getGroupsById(state),
    scroll: state.groups.favoritesGroupScroll,
  };
}

GroupsPanel.propTypes = {
  groups: PropTypes.array.isRequired,
  serverError: PropTypes.bool,
};

GroupsPanel.defaultProps = {
  serverError: false,
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchGroups: fetchPinnedGroupsRequest,
    updateSettings: updatePinnedGroupSettingsRequest,
    updateOrder: updatePinnedGroupsOrderRequest,
    favoritesScroll: setFavoritesGroupScroll,
  })(GroupsPanel)
);
