import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const CalendarShow = React.lazy(() => import('./CalendarShow'));

const CalendarShowPage = (props) => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <CalendarShow {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarShowPage;
