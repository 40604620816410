import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ChatMessage from './ChatMessage';
import {
  DAYMESSAGEFORMAT,
  retrieveSenderId,
  retrieveMessageId,
  ChatEnums,
} from '../../../js/chat/ChatUtils';
import ChatHistorySender from './ChatHistorySender';
import ChatMessageInfo from './ChatMessageInfo';
import ChatMessageInfoTemplate from './ChatMessageInfoTemplate';

class ChatHistoryDay extends React.Component {
  componentDidMount() {}

  render() {
    const {
      isChatGroup,
      day,
      messagesList,
      deletingEnabled,
      onChangeDeleting,
      historyVisibleHeight,
      historyVisibleTop,
      scrollToSearchedMessage,
      onPreviewLoaded,
      conversationId,
    } = this.props;
    const senderGroups = [];
    let lastSenderId;
    messagesList.forEach((messageSenderId) => {
      const senderId = retrieveSenderId(messageSenderId);
      const messageId = retrieveMessageId(messageSenderId);
      if (
        !lastSenderId ||
        lastSenderId !== senderId ||
        senderId === ChatEnums.ChatTopics.INFO
      ) {
        senderGroups.push({
          sender: senderId,
          messagesIds: [messageId],
          info: senderId === ChatEnums.ChatTopics.INFO,
        });
        lastSenderId = senderId;
      } else {
        senderGroups[senderGroups.length - 1].messagesIds.push(messageId);
      }
    });

    return (
      <>
        <div className="text-center my-2">
          <span className="badge badge-pill badge-primary">
            {moment(day, DAYMESSAGEFORMAT).format('dddd DD MMMM YYYY')}
          </span>
        </div>
        {senderGroups.map((senderData, index) =>
          senderData.info ? (
            <ChatMessageInfoTemplate key={index}>
              <ChatMessageInfo messageId={senderData.messagesIds[0]} />
            </ChatMessageInfoTemplate>
          ) : (
            <ChatHistorySender sender={senderData.sender} key={index}>
              {senderData.messagesIds.map((messageId) => (
                <ChatMessage
                  conversationId={conversationId}
                  isChatGroup={isChatGroup}
                  historyVisibleHeight={historyVisibleHeight}
                  historyVisibleTop={historyVisibleTop}
                  key={messageId}
                  messageId={messageId}
                  onChangeDeleting={onChangeDeleting}
                  deletingEnabled={deletingEnabled}
                  scrollToSearchedMessage={scrollToSearchedMessage}
                  onRetrySendFile={this.props.onRetrySendFile}
                  onRetrySendMessage={this.props.onRetrySendMessage}
                  onPreviewLoaded={onPreviewLoaded}
                />
              ))}
            </ChatHistorySender>
          )
        )}
      </>
    );
  }
}

ChatHistoryDay.propTypes = {
  conversationId: PropTypes.string.isRequired,
  isChatGroup: PropTypes.bool.isRequired,
  day: PropTypes.string.isRequired,
  messagesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  deletingEnabled: PropTypes.bool.isRequired,
  onChangeDeleting: PropTypes.func.isRequired,
  scrollToSearchedMessage: PropTypes.func.isRequired,
};

export default ChatHistoryDay;
