export class ChatEnums {
  static ChatWindowSize = {
    MIN: 'MIN',
    NORMAL: 'NORMAL',
    BIG: 'BIG',
  };

  static ChatTopics = {
    MESSAGES: 'MESSAGES',
    INFO: 'INFO',
    STATUS: 'STATUS',
  };

  static ChatActions = {
    CONVERSATIONS_LIST: 'CONVERSATIONS_LIST',
    CONVERSATIONS_LIST_ACK: 'CONVERSATIONS_LIST_ACK',
    NEW_MESSAGE: 'NEW_MESSAGE',
    NEW_MESSAGE_CC: 'NEW_MESSAGE_CC',
    NEW_MESSAGE_ACK: 'NEW_MESSAGE_ACK',
    DELIVERY: 'DELIVERY',
    DELIVERY_CC: 'DELIVERY_CC',
    DELIVERY_ACK: 'DELIVERY_ACK',
    READ: 'READ',
    READ_CC: 'READ_CC',
    READ_ACK: 'READ_ACK',
    READ_ALL: 'READ_ALL',
    READ_ALL_CC: 'READ_ALL_CC',
    READ_ALL_ACK: 'READ_ALL_ACK',
    DELETE: 'DELETE',
    DELETE_CC: 'DELETE_CC',
    DELETE_ACK: 'DELETE_ACK',
    DELETE_ALL: 'DELETE_ALL',
    DELETE_ALL_CC: 'DELETE_ALL_CC',
    DELETE_ALL_ACK: 'DELETE_ALL_ACK',
    ARCHIVE: 'ARCHIVE',
    ARCHIVE_ACK: 'ARCHIVE_ACK',
    ARCHIVE_CC: 'ARCHIVE_CC',
    UNARCHIVE: 'UNARCHIVE',
    UNARCHIVE_CC: 'UNARCHIVE_CC',
    UNARCHIVE_ACK: 'UNARCHIVE_ACK',
    COMPOSING: 'COMPOSING',
    PAUSED: 'PAUSED',
    MARK_IMPORTANT: 'MARK_IMPORTANT',
    MARK_IMPORTANT_ACK: 'MARK_IMPORTANT_ACK',
    MARK_IMPORTANT_CC: 'MARK_IMPORTANT_CC',
    MARK_UNIMPORTANT: 'MARK_UNIMPORTANT',
    MARK_UNIMPORTANT_ACK: 'MARK_UNIMPORTANT_ACK',
    MARK_UNIMPORTANT_CC: 'MARK_UNIMPORTANT_CC',
  };

  static ChatInfoTypes = {
    WELCOME: 'WELCOME',
    USER_ADDED: 'USER_ADDED',
    USER_REMOVED: 'USER_REMOVED',
    GROUP_CREATED: 'GROUP_CREATED',
    GROUP_UPDATED: 'GROUP_UPDATED',
  };

  static MuteChatFrequency = {
    oneDay: {
      label: '1 day',
      keyword: 'd',
    },
    oneWeek: {
      label: '1 week',
      keyword: 'w',
    },
    oneMonth: {
      label: '1 month',
      keyword: 'M',
    },
    always: {
      label: 'Always',
      keyword: null,
    },
  };
}

export const DAYMESSAGEFORMAT = 'DDMMYYYY';

export const MESSAGE_COMPOSING_TIMEOUT = 3000;

export const RESET_COMPOSING_TIMEOUT = 120000;

export const CONVERSATION_FILES_PAGE_SIZE = 10;

export const CHAT_HISTORY_CHUNK = 20;

export const MIN_CHARS_FOR_SEARCH = 3;

export const CHAT_SEARCH_PAGE_SIZE = 50;

export const CHAT_SEARCHED_TEXT_HIGHLIGHT_TIMEOUT = 2000;

export const CHAT_TOOLBAR_HEIGHT = 30;

export const CHAT_WINDOWS_HEIGHTS = {
  BIG: 800,
  NORMAL: 400,
  MIN: 35,
};

export const CHAT_PANEL_HEIGHTS = {
  BIG: 735,
  NORMAL: 335,
  MIN: 0,
};

export const CHAT_EMOJI_PANEL_HEIGHTS = {
  BIG: 200,
  NORMAL: 100,
  MIN: 0,
};

export const CHAT_TEXTAREA_HEIGHTS = {
  BIG: 120,
  NORMAL: 60,
  MIN: 0,
};

export const CHAT_REPLY_TEXTAREA_HEIGHTS = {
  BIG: 70,
  NORMAL: 70,
  MIN: 0,
};

export const TIMED_MESSAGES_SECONDS = [
  {
    value: 10,
    label: '0 10',
  },
  {
    value: 30,
    label: '0 30',
  },
  {
    value: 120,
    label: '2 00',
  },
  {
    value: 300,
    label: '5 00',
  },
  {
    value: null,
    label: '',
  },
];

export const EMOJIS_SECTIONS = {
  RECENT: 'recent',
  PEOPLE: 'people',
  NATURE: 'nature',
  FOOD: 'food',
  SPORT: 'sport',
  LIFE: 'life',
  OBJECT: 'objects',
  SYMBOLS: 'symbols',
  FLAGS: 'flags',
};

export const CHAT_FILE_MAXSIZE = 100 * 1024 * 1024;

export const generateConversationId = (data) => {
  if (data.userId) {
    return `user-${data.userId}`;
  }
  if (data.groupId) {
    return `group-${data.groupId}`;
  }
  return null;
};

export const retrieveInterlocutorIdFromConversationId = (conversationId) => {
  if (conversationId.indexOf('user-') === 0) {
    return {
      userId: parseInt(conversationId.replace('user-', ''), 10),
    };
  }
  if (conversationId.indexOf('group-') === 0) {
    return {
      groupId: parseInt(conversationId.replace('group-', ''), 10),
    };
  }
  return {};
};

export const generateSenderMessageId = (sender, messageId, info) =>
  info ? `${ChatEnums.ChatTopics.INFO}_${messageId}` : `${sender}_${messageId}`;

export const retrieveMessageId = (id) => id.substring(id.indexOf('_') + 1);

export const retrieveSenderId = (id) =>
  id.substring(0, id.indexOf('_')) === ChatEnums.ChatTopics.INFO
    ? id.substring(0, id.indexOf('_'))
    : parseInt(id.substring(0, id.indexOf('_')), 10);

export const generateSenderFullname = (name, surname) => {
  if (name) {
    if (surname) {
      return `${name} ${surname}`;
    }
    return name;
  }
  return surname;
};

export function getInfoMessageText({ type, sender, user, myId }) {
  if (!type) return;

  let senderFullname = '';
  let userFullname = '';
  senderFullname = myId && myId === sender.id ? 'YOU' : sender.fullname;
  userFullname =
    myId && myId === user.id
      ? 'YOU'
      : user.surname
      ? `${user.name} ${user.surname}`
      : user.name;

  return {
    sender: senderFullname,
    user: userFullname,
  };
}
