/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { getSupplier } from '../../../js/phone/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import ynConf from '../../../conf';
import { charsToCode } from '../../../js/chat/charsToCode';

const messages = defineMessages({
  adv1text1: {
    id: 'AdvBalloon.adv1text1',
    defaultMessage: 'Vuoi dare il {bold} ai tuoi clienti? ',
  },
  adv1text1bold: {
    id: 'AdvBalloon.adv1text1bold',
    defaultMessage: 'wi-fi gratuito',
  },
  adv1text2: {
    id: 'AdvBalloon.adv1text2',
    defaultMessage: ' Con {link} puoi!',
  },
  adv2text1: {
    id: 'AdvBalloon.adv2text1',
    defaultMessage: 'Vuoi inserire un {bold}? ',
  },
  adv2text1bold: {
    id: 'AdvBalloon.adv2text1bold',
    defaultMessage: 'messaggio di chiusura',
  },
  adv2text2: {
    id: 'AdvBalloon.adv2text2',
    defaultMessage: ' per sapere come',
  },
  adv2link: {
    id: 'AdvBalloon.adv2link',
    defaultMessage: 'Clicca qui',
  },
  adv3text1: {
    id: 'AdvBalloon.adv3text1',
    defaultMessage: 'Vuoi un {bold} per la tua segreteria?',
  },
  adv3text1bold: {
    id: 'AdvBalloon.adv3text1bold',
    defaultMessage: 'messaggio professionale',
  },
  adv3text2: {
    id: 'AdvBalloon.adv3text2',
    defaultMessage: 'Ti aiutiamo a ',
  },
  adv3link: {
    id: 'AdvBalloon.adv3link',
    defaultMessage: 'realizzarlo',
  },
  adv4text1: {
    id: 'AdvBalloon.adv4text1',
    defaultMessage: 'Hai bisogno di assistenza? Chiama il nostro ',
  },
  adv4text1bold: {
    id: 'AdvBalloon.adv4text1bold',
    defaultMessage: 'servizio clienti',
  },
  adv5text1: {
    id: 'AdvBalloon.adv5text1',
    defaultMessage: 'Stai lavorando da casa? Scarica la nostra ',
  },
  adv5link: {
    id: 'AdvBalloon.adv5link',
    defaultMessage: 'App Desktop',
  },
});

const ADVS_TIMEOUT = 1 * 60 * 1000;

const Adv1 = ({ format }) => (
  <div>
    {format(messages.adv1text1, {
      bold: <b>{format(messages.adv1text1bold)}</b>,
    })}
    <img
      alt="📡"
      width="16px"
      height="16px"
      src={`${ynConf.publicFolderPath}/emojis/${charsToCode['📡']}.png`}
    />
    {format(messages.adv1text2, {
      link: (
        <a href="http://www.weefi.it" target="_blank" rel="noopener noreferrer">
          <u>
            <b>Weefi</b>
          </u>
        </a>
      ),
    })}
  </div>
);

const Adv2 = ({ format }) => (
  <div>
    {format(messages.adv2text1, {
      bold: <b>{format(messages.adv2text1bold)}</b>,
    })}
    <img
      alt="📅"
      width="16px"
      height="16px"
      src={`${ynConf.publicFolderPath}/emojis/${charsToCode['📅']}.png`}
    />{' '}
    <a
      href="https://youtu.be/awWTENSsyo4"
      target="_blank"
      rel="noopener noreferrer"
    >
      <u>
        <b>{format(messages.adv2link)}</b>
      </u>
    </a>
    {format(messages.adv2text2)}
  </div>
);

const Adv3 = ({ format }) => (
  <div>
    {format(messages.adv3text1, {
      bold: <b>{format(messages.adv3text1bold)}</b>,
    })}
    <img
      alt="☎"
      width="16px"
      height="16px"
      src={`${ynConf.publicFolderPath}/emojis/${charsToCode['☎']}.png`}
    />
    {format(messages.adv3text2)}
    <a href="/switchboard/vbox/recorded-messages" rel="noopener noreferrer">
      <u>
        <b>{format(messages.adv3link)}</b>
      </u>
    </a>
  </div>
);

const Adv4 = ({ format }) => (
  <div>
    {format(messages.adv4text1)}
    <b>{format(messages.adv4text1bold)}</b>
  </div>
);

const Adv5 = ({ format }) => (
  <div>
    {format(messages.adv5text1)}
    <br />
    <a
      href="https://youtu.be/x25soD1-YY4?si=-e7exE60wOP0D3Nx"
      target="_blank"
      rel="noopener noreferrer"
    >
      <u>
        <b>{format(messages.adv5link)}</b>
      </u>
    </a>
  </div>
);

class AdvBalloon extends Component {
  abilisAdvs = [
    <Adv5 format={this.props.intl.formatMessage} />,
    <Adv1 format={this.props.intl.formatMessage} />,
    <Adv2 format={this.props.intl.formatMessage} />,
    <Adv3 format={this.props.intl.formatMessage} />,
  ];

  nethesisAdvs = [
    <Adv5 format={this.props.intl.formatMessage} />,
    <Adv1 format={this.props.intl.formatMessage} />,
    <Adv4 format={this.props.intl.formatMessage} />,
  ];

  constructor(props) {
    super(props);
    this.state = {
      advIndex: 0,
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), ADVS_TIMEOUT);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    const { supplier } = this.props;

    let max = 0;
    if (supplier === PhoneEnums.PbxType.ABILIS) {
      max = this.abilisAdvs.length;
    } else {
      max = this.nethesisAdvs.length;
    }

    this.setState({
      advIndex: Math.floor(Math.random() * max),
    });
  };

  render() {
    const { supplier } = this.props;

    const { advIndex } = this.state;

    const advs =
      supplier === PhoneEnums.PbxType.ABILIS
        ? this.abilisAdvs
        : this.nethesisAdvs;

    return (
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '25px',
          fontSize: '14px',
          width: '150px',
        }}
      >
        {advs[advIndex]}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
  };
}

export default injectIntl(connect(mapStateToProps)(AdvBalloon));
