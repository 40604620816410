import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSPackages from './SMSPackages';

const SMSAliasPage = () => (
  <SMS>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <SMSPackages />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSAliasPage;
