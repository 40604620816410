import React from 'react';
import { connect } from 'react-redux';
import MePhoneSettings from './MePhoneSettings';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { PhoneRulesEnums } from '../../../../js/phoneRules/PhoneRulesUtils';
import BackButton from '../../../../components/buttons/BackButton';
import HelpButton from '../../../../components/HelpButton';
import { getSupplier } from '../../../../js/phone/selectors';

const PhoneRulesEdit = React.lazy(() => import('../PhoneRulesEdit'));
const MePhoneRulesEditPage = (props) => {
  const childrenProps = {
    ...props,
    context: PhoneRulesEnums.SettingsContext.me,
  };
  return (
    <MePhoneSettings>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div className="row mb-3">
            <div className="col text-left">
              <BackButton />
            </div>
            <div className="col text-right">
              <HelpButton
                fileName={`phone-settings-new-rule-${props.supplier}`}
              />
            </div>
          </div>
          <PhoneRulesEdit {...childrenProps} />
        </React.Suspense>
      </ErrorBoundary>
    </MePhoneSettings>
  );
};

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
  };
}

export default connect(mapStateToProps)(MePhoneRulesEditPage);
