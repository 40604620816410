import React from 'react';
import AdminGrants from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const AdminEditUsersProfile = React.lazy(() =>
  import('./AdminEditUsersProfile')
);

const AdminEditUsersProfilePage = (props) => (
  <AdminGrants scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-3">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="users-profiles-edit" />
          </div>
        </div>
        <AdminEditUsersProfile {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </AdminGrants>
);

export default AdminEditUsersProfilePage;
