import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import mapValues from 'lodash.mapvalues';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_GRANTS_REQUEST,
  FETCH_GRANTS_SUCCESS,
  FETCH_GRANTS_FAILURE,
  TOGGLE_GRANTS_CATEGORY,
  TOGGLE_GRANT,
  FETCH_USER_GRANTS_REQUEST,
  FETCH_USER_GRANTS_SUCCESS,
  FETCH_USER_GRANTS_FAILURE,
  TOGGLE_GRANT_COLLAPSE,
  GRANTS_EXPAND_ALL,
  GRANTS_COLLAPSE_ALL,
  SAVE_USER_GRANTS_REQUEST,
  SAVE_USER_GRANTS_SUCCESS,
  SAVE_USER_GRANTS_FAILURE,
  SAVE_ME_ADVANCED_GRANTS_REQUEST,
  SAVE_ME_ADVANCED_GRANTS_SUCCESS,
  SAVE_ME_ADVANCED_GRANTS_FAILURE,
  FETCH_PROFILE_GRANTS_REQUEST,
  FETCH_PROFILE_GRANTS_SUCCESS,
  FETCH_PROFILE_GRANTS_FAILURE,
} from './types';
import { FETCH_ME_SUCCESS } from '../me/types';

const initialState = {
  grantsByCategory: {},
  grantsByCode: {},
  categories: [],
  fetchGrants: {
    loaded: true,
    error: null,
  },
  fetchUserGrants: {
    loaded: true,
    error: null,
  },
  saveUserGrants: {
    loaded: true,
    success: null,
    error: null,
  },
  saveMeAdvancedGrants: {
    loaded: true,
    success: null,
    error: null,
  },
  fetchProfileGrants: {
    loaded: true,
    error: null,
    allIds: [],
  },
};

export default function grants(state = initialState, action = {}) {
  let grantsByCategory = {};
  let grantsByCode = {};
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        customerType: action.me.customerType,
      };
    case FETCH_GRANTS_REQUEST:
      return {
        ...state,
        fetchGrants: {
          loaded: false,
          error: null,
        },
        saveUserGrants: {
          loaded: true,
          success: null,
          error: null,
        },
        saveMeAdvancedGrants: {
          loaded: true,
          success: null,
          error: null,
        },
        fetchUserGrants: {
          loaded: true,
          error: null,
        },
      };
    case FETCH_GRANTS_SUCCESS:
      if (action.grants) {
        action.grants.forEach((element) => {
          const grants = mapValues(element.grants, (item) => {
            return {
              ...item,
              type: element.type,
            };
          });
          grantsByCode = {
            ...grantsByCode,
            ...keyBy(grants, 'description'),
          };
          grantsByCategory[element.type] = {
            collapse: true,
            grants: element.grants
              ? element.grants.map((item) => item.description)
              : [],
          };
        });
      }
      return {
        ...state,
        grantsByCategory: grantsByCategory,
        categories: action.grants ? action.grants.map((item) => item.type) : [],
        grantsByCode: grantsByCode,
        fetchGrants: {
          loaded: true,
          error: null,
        },
      };
    case FETCH_GRANTS_FAILURE:
      return {
        ...state,
        fetchGrants: {
          loaded: true,
          error: action.errors,
        },
      };
    case TOGGLE_GRANTS_CATEGORY:
      const category = state.grantsByCategory[action.category];
      grantsByCode = state.grantsByCode;
      if (category.checked) {
        category.checked = false;
        category.grants.forEach((element) => {
          if (
            state.customerType !== 'AMBROGIO' ||
            !grantsByCode[element].ambrogioLocked
          ) {
            grantsByCode[element].checked = false;
          }
        });
      } else {
        category.checked = true;
        category.grants.forEach((element) => {
          if (
            state.customerType !== 'AMBROGIO' ||
            !grantsByCode[element].ambrogioLocked
          ) {
            grantsByCode[element].checked = true;
          }
        });
      }
      return {
        ...state,
        grantsByCategory: {
          ...state.grantsByCategory,
          category,
        },
        grantsByCode: grantsByCode,
      };
    case TOGGLE_GRANT:
      const grantCategory = state.grantsByCategory[action.category];
      const grant = state.grantsByCode[action.code];
      grant.checked = grant.checked === undefined ? true : !grant.checked;
      if (grant.checked) {
        grantCategory.checked = true;
      } else {
        let checked = false;
        grantCategory.grants.forEach((code) => {
          if (state.grantsByCode[code].checked) checked = true;
        });
        grantCategory.checked = checked;
      }
      return {
        ...state,
        grantsByCategory: {
          ...state.grantsByCategory,
          ...grantCategory,
        },
        grantsByCode: {
          ...state.grantsByCode,
          ...grant,
        },
      };
    case FETCH_USER_GRANTS_REQUEST:
      return {
        ...state,
        fetchUserGrants: {
          loaded: false,
          error: null,
        },
      };
    case FETCH_USER_GRANTS_SUCCESS:
      const userGrants = action.grants;
      grantsByCategory = mapValues(state.grantsByCategory, (item) => {
        return {
          ...item,
          checked: false,
        };
      });
      grantsByCode = mapValues(state.grantsByCode, (item) => {
        return {
          ...item,
          checked: false,
        };
      });
      userGrants.forEach((code) => {
        const grant = grantsByCode[code];
        if (grant) {
          grant.checked = true;
          const grantsCategory = grantsByCategory[grant.type];
          if (grantsCategory) grantsCategory.checked = true;
        }
      });
      return {
        ...state,
        grantsByCategory,
        grantsByCode,
        fetchUserGrants: {
          loaded: true,
          error: null,
        },
      };
    case FETCH_USER_GRANTS_FAILURE:
      return {
        ...state,
        fetchUserGrants: {
          loaded: true,
          error: action.errors,
        },
      };
    case TOGGLE_GRANT_COLLAPSE:
      const categoryToToggle = state.grantsByCategory[action.category];
      categoryToToggle.collapse = !categoryToToggle.collapse;
      return {
        ...state,
        grantsByCategory: {
          ...state.grantsByCategory,
          ...categoryToToggle,
        },
      };
    case GRANTS_COLLAPSE_ALL:
      grantsByCategory = state.grantsByCategory;
      grantsByCategory = mapValues(state.grantsByCategory, (item) => {
        return {
          ...item,
          collapse: true,
        };
      });
      return {
        ...state,
        grantsByCategory: grantsByCategory,
      };
    case GRANTS_EXPAND_ALL:
      grantsByCategory = state.grantsByCategory;
      grantsByCategory = mapValues(state.grantsByCategory, (item) => {
        return {
          ...item,
          collapse: false,
        };
      });
      return {
        ...state,
        grantsByCategory: grantsByCategory,
      };
    case SAVE_USER_GRANTS_REQUEST:
      return {
        ...state,
        saveUserGrants: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case SAVE_USER_GRANTS_SUCCESS:
      return {
        ...state,
        saveUserGrants: {
          loaded: true,
          success: true,
          error: null,
        },
      };
    case SAVE_USER_GRANTS_FAILURE:
      return {
        ...state,
        saveUserGrants: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case SAVE_ME_ADVANCED_GRANTS_REQUEST:
      return {
        ...state,
        saveMeAdvancedGrants: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case SAVE_ME_ADVANCED_GRANTS_SUCCESS:
      return {
        ...state,
        saveMeAdvancedGrants: {
          loaded: true,
          success: true,
          error: null,
        },
      };
    case SAVE_ME_ADVANCED_GRANTS_FAILURE:
      return {
        ...state,
        saveMeAdvancedGrants: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case FETCH_PROFILE_GRANTS_REQUEST:
      return {
        ...state,
        fetchProfileGrants: {
          ...state.fetchProfileGrants,
          loaded: false,
          error: null,
        },
      };
    case FETCH_PROFILE_GRANTS_SUCCESS: {
      const profileGrants = action.grants;
      grantsByCategory = mapValues(state.grantsByCategory, (item) => {
        return {
          ...item,
          checked: false,
        };
      });
      grantsByCode = mapValues(state.grantsByCode, (item) => {
        return {
          ...item,
          checked: false,
        };
      });
      profileGrants.forEach((code) => {
        const profileGrant = grantsByCode[code];
        if (profileGrant) {
          profileGrant.checked = true;
          const profileGrantCategory = grantsByCategory[profileGrant.type];
          if (profileGrantCategory) profileGrantCategory.checked = true;
        }
      });
      return {
        ...state,
        grantsByCategory,
        grantsByCode,
        fetchProfileGrants: {
          loaded: true,
          error: null,
          allIds: profileGrants,
        },
      };
    }
    case FETCH_PROFILE_GRANTS_FAILURE:
      return {
        ...state,
        fetchProfileGrants: {
          ...state.fetchProfileGrants,
          loaded: true,
          error: action.errors,
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        saveMeAdvancedGrants: {
          loaded: true,
          success: null,
          error: null,
        },
      };
    default:
      return state;
  }
}
