import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const CalendarSettings = React.lazy(() => import('./CalendarSettings'));

const CalendarSettingsPage = (props) => (
  <Calendar>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <CalendarSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarSettingsPage;
