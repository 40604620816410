import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const WorkingUsersList = React.lazy(() => import('./WorkingUsersList'));

const WorkingUsersPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <WorkingUsersList {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default WorkingUsersPage;
