import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';
import Input from './Input';

const style = {
  height: '1.4rem',
  width: '1.4rem',
  display: 'block',
  position: 'absolute',
  top: '0.4rem',
  right: '0.8rem',
  color: 'var(--yn-blue)',
  cursor: 'pointer',
};

class Password extends Component {
  state = {
    visible: false,
  };

  onChange = (e) => {
    this.props.onChange(e);
  };

  toggle = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const { onChange, onKeyDown, name, id, error, value, loginPassword } =
      this.props;
    return (
      <div className="position-relative">
        <span onClick={this.toggle} style={style}>
          <Icon
            width={24}
            height={24}
            name={this.state.visible ? 'view-2' : 'hide'}
            strokeWidth={1.5}
            color={this.state.visible ? 'var(--yn-blue-dark)' : 'var(--gray)'}
          />
        </span>
        <Input
          type={this.state.visible ? 'text' : 'password'}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          style={{ paddingRight: '2.8rem' }}
          error={error}
          randomizeName={!loginPassword}
          autoComplete="new-password"
        />
      </div>
    );
  }
}

Password.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};

Password.defaultProps = {
  value: '',
  error: undefined,
};

export default Password;
