import { request } from '../request';

export default {
  createUserProfile: (data) =>
    request({
      url: `/profiles`,
      method: 'POST',
      data,
    }),
  editUserProfile: (data) =>
    request({
      url: `/profiles/${data.id}`,
      method: 'POST',
      data: data.data,
    }),
  getUserProfilesList: (params) =>
    request({
      url: `/profiles`,
      method: 'GET',
      params,
    }),
  removeUserProfile: (id) =>
    request({
      url: `/profiles/${id}`,
      method: 'DELETE',
    }),
  getUserProfileGrants: (id) =>
    request({
      url: `/profiles/${id}/grants`,
      method: 'GET',
    }),
};
