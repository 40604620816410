import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import HelpButton from '../../../components/HelpButton';
import Button from '../../../components/formControls/Button';
import { fetchAbookListsRequest } from '../../../js/contacts/actions';
import {
  getAbookListsIds,
  getDeleteAbookListError,
  getFetchAbookListsError,
  getFetchAbookListsLoaded,
} from '../../../js/contacts/selectors';
import AbookListsRow from './AbookListsRow';
import ToastMessage from '../../../components/messages/ToastMessage';

const messages = defineMessages({
  newListButton: {
    id: 'AbookLists.newListButton',
    defaultMessage: 'New list',
  },
  creationTime: {
    id: 'AbookLists.label.creationTime',
    defaultMessage: 'Creation date',
  },
  creator: {
    id: 'AbookLists.label.creator',
    defaultMessage: 'Created by',
  },
  name: {
    id: 'AbookLists.label.name',
    defaultMessage: 'Name',
  },
  sharing: {
    id: 'AbookLists.label.sharing',
    defaultMessage: 'Sharing',
  },
  noData: {
    id: 'AbookLists.noData',
    defaultMessage: 'There is nothing to show',
  },
  errorDeleting: {
    id: 'AbookLists.errorDeleting',
    defaultMessage: 'There has been an error while deleting list',
  },
  PENDING_CAMPAIGN: {
    id: 'AbookLists.PENDING_CAMPAIGN',
    defaultMessage:
      'Cannot delete this list because there is a pending campaign',
  },
});

class AbookLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = () => {
    const { fetch } = this.props;
    fetch();
  };

  render() {
    const {
      intl: { formatMessage },
      fetching,
      fetchingError,
      listsIds,
      deletingError,
    } = this.props;

    return fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        {deletingError && (
          <ToastMessage
            type="danger"
            text={
              messages[deletingError]
                ? formatMessage(messages[deletingError])
                : formatMessage(messages.errorDeleting)
            }
          />
        )}

        <div className="text-right mb-1">
          <HelpButton fileName="abook-lists" />
        </div>

        <div className="text-right mb-2">
          <Link to="/abook/lists/edit">
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.newListButton)}
            />
          </Link>
        </div>
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th>{formatMessage(messages.creationTime)}</th>
                  <th>{formatMessage(messages.name)}</th>
                  <th>{formatMessage(messages.creator)}</th>
                  {/*<th>{formatMessage(messages.sharing)}</th>*/}
                  <th />
                </tr>
              </thead>
              {listsIds && listsIds.length > 0 ? (
                <tbody>
                  {listsIds.map((id) => (
                    <AbookListsRow key={id} id={id} />
                  ))}
                </tbody>
              ) : (
                <tfoot>
                  <tr>
                    <td colSpan="4">{formatMessage(messages.noData)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: !getFetchAbookListsLoaded(state),
    fetchingError: getFetchAbookListsError(state),
    listsIds: getAbookListsIds(state),
    deletingError: getDeleteAbookListError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchAbookListsRequest,
  })(AbookLists)
);
