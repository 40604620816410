import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormFieldError from '../messages/FormFieldError';

class TextArea extends Component {
  textArea = React.createRef();

  componentDidMount() {}

  componentDidUpdate(prevprops) {
    if (this.props.hasFocus && this.props.hasFocus !== prevprops.hasFocus) {
      this.textArea.current.focus();
    }
  }

  onChange = (e) => {
    this.props.onChange(e);
  };

  render() {
    const {
      id,
      name,
      value,
      rows,
      error,
      className,
      style,
      onChange,
      onKeyPress,
      onBlur,
      disabled,
      placeholder,
      maxLength,
    } = this.props;
    const classNames = classnames('form-control', className, {
      'is-invalid': error,
    });

    return (
      <>
        <textarea
          id={id}
          name={name}
          value={value}
          rows={rows}
          style={style}
          className={classNames}
          ref={this.textArea}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {error && <FormFieldError text={error} />}
      </>
    );
  }
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

TextArea.defaultProps = {
  onChange: null,
  onKeyPress: null,
  onBlur: null,
  error: null,
  className: null,
  style: null,
  disabled: false,
  placeholder: null,
  maxLength: 500,
  rows: 5,
};

export default TextArea;
