import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  fetchSMSListSuccess,
  fetchSMSListFailure,
  sendSMSSuccess,
  sendSMSFailure,
  fetchSMSCreditsSuccess,
  fetchSMSCreditsFailure,
  fetchSMSScheduledSuccess,
  fetchSMSScheduledFailure,
  saveSMSScheduledSuccess,
  saveSMSScheduledFailure,
  deleteSMSScheduledSuccess,
  deleteSMSScheduledFailure,
  fetchSMSScheduledRequest,
  fetchSMSAliasFailure,
  saveSMSAliasSuccess,
  saveSMSAliasFailure,
  fetchSMSAliasSuccess,
  saveSMSCampaignFailure,
  saveSMSCampaignSuccess,
  fetchSMSCampaignsFailure,
  fetchSMSCampaignsSuccess,
  fetchSMSCampaignSuccess,
  fetchSMSCampaignFailure,
  deleteSMSCampaignSuccess,
  fetchSMSCampaignsRequest,
  deleteSMSCampaignFailure,
  buySMSPackagesSuccess,
  buySMSPackagesFailure,
  fetchSMSCreditsRequest,
} from './actions';
import {
  BUY_SMS_PACKAGES_REQUEST,
  DELETE_SMS_CAMPAIGN_REQUEST,
  DELETE_SMS_SCHEDULED_REQUEST,
  FETCH_SMS_ALIAS_REQUEST,
  FETCH_SMS_CAMPAIGNS_REQUEST,
  FETCH_SMS_CAMPAIGN_REQUEST,
  FETCH_SMS_CREDITS_REQUEST,
  FETCH_SMS_LIST_REQUEST,
  FETCH_SMS_SCHEDULED_REQUEST,
  SAVE_SMS_ALIAS_REQUEST,
  SAVE_SMS_CAMPAIGN_REQUEST,
  SAVE_SMS_SCHEDULED_REQUEST,
  SEND_SMS_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import history from '../../history';
import { getSMSCampaignsCurrentPage } from './selectors';
import { SMS_CAMPAIGNS_PAGE_SIZE } from './SMSUtils';

export function* fetchSMSList(action) {
  try {
    const res = yield call(api.sms.getSMS, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchSMSListSuccess(
        res.status === 204
          ? {
              data: [],
              page: action.payload.page,
              total: 0,
            }
          : {
              data: res.data.data,
              page: action.payload.page,
              total: res.data.total,
            }
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSListFailure({ error }));
  }
}

export function* sendSMS(action) {
  try {
    const res = yield call(api.sms.sendSMS, action.payload);
    yield call(checkApiResponse, res);
    yield put(sendSMSSuccess());
    history.push('/sms/sent');
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(sendSMSFailure({ error: err.data.error || error }));
  }
}

export function* fetchSMSCredits() {
  try {
    const res = yield call(api.sms.getSMScredits);
    yield call(checkApiResponse, res);
    yield put(fetchSMSCreditsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSCreditsFailure({ error }));
  }
}

export function* fetchSMSScheduled() {
  try {
    const res = yield call(api.sms.getSMSScheduled);
    yield call(checkApiResponse, res);
    yield put(
      fetchSMSScheduledSuccess({
        data: res.status === 204 ? [] : res.data,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSScheduledFailure({ error }));
  }
}

export function* saveSMSScheduled(action) {
  try {
    const res = yield call(
      action.payload.id
        ? api.sms.updateSMSScheduled
        : api.sms.createSMSScheduled,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(saveSMSScheduledSuccess());
    history.push('/sms/scheduled');
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(saveSMSScheduledFailure({ error: err.data.error || error }));
  }
}

export function* deleteSMSScheduled(action) {
  try {
    const res = yield call(api.sms.deleteSMSScheduled, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(deleteSMSScheduledSuccess());
    yield put(fetchSMSScheduledRequest());
  } catch (err) {
    yield put(deleteSMSScheduledFailure(err));
  }
}

export function* fetchSMSAlias() {
  try {
    const res = yield call(api.sms.getSMSAlias);
    yield call(checkApiResponse, res);
    yield put(
      fetchSMSAliasSuccess(
        res.status === 204
          ? {
              data: [],
            }
          : {
              data: res.data.data,
            }
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSAliasFailure({ error }));
  }
}

export function* saveSMSAlias(action) {
  try {
    const res = yield call(api.sms.createSMSAlias, action.payload);
    yield call(checkApiResponse, res);
    yield put(saveSMSAliasSuccess());
    history.push('/sms/alias');
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(saveSMSAliasFailure({ error: err.data.error || error }));
  }
}

export function* fetchSMSCampaigns(action) {
  try {
    const res = yield call(api.sms.getSMSCampaigns, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchSMSCampaignsSuccess(
        res.status === 204
          ? {
              data: [],
              page: action.payload.page,
              total: 0,
            }
          : {
              data: res.data.data,
              page: action.payload.page,
              total: res.data.total,
            }
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSCampaignsFailure({ error }));
  }
}

export function* fetchSMSCampaign(action) {
  try {
    const res = yield call(api.sms.getSMSCampaign, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(fetchSMSCampaignSuccess({ ...res.data, id: action.payload.id }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchSMSCampaignFailure({ error }));
  }
}

export function* saveSMSCampaign(action) {
  try {
    const res = yield call(
      action.payload.id ? api.sms.updateSMSCampaign : api.sms.createSMSCampaign,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(saveSMSCampaignSuccess());
    history.push('/sms/campaigns');
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(saveSMSCampaignFailure({ error: err.data.error || error }));
  }
}

export function* deleteSMSCampaign(action) {
  try {
    const res = yield call(api.sms.deleteSMSCampaign, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(deleteSMSCampaignSuccess());
    const page = yield select(getSMSCampaignsCurrentPage);
    yield put(
      fetchSMSCampaignsRequest({ page, pageSize: SMS_CAMPAIGNS_PAGE_SIZE })
    );
  } catch (err) {
    yield put(deleteSMSCampaignFailure(err));
  }
}

export function* buySMSPackages(action) {
  try {
    const res = yield call(api.sms.buySMSPackages, action.payload);
    yield call(checkApiResponse, res);
    yield put(buySMSPackagesSuccess());
    yield put(fetchSMSCreditsRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(buySMSPackagesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_SMS_LIST_REQUEST, fetchSMSList);
  yield takeLatest(SEND_SMS_REQUEST, sendSMS);
  yield takeLatest(FETCH_SMS_CREDITS_REQUEST, fetchSMSCredits);
  yield takeLatest(FETCH_SMS_SCHEDULED_REQUEST, fetchSMSScheduled);
  yield takeLatest(SAVE_SMS_SCHEDULED_REQUEST, saveSMSScheduled);
  yield takeLatest(DELETE_SMS_SCHEDULED_REQUEST, deleteSMSScheduled);
  yield takeLatest(FETCH_SMS_ALIAS_REQUEST, fetchSMSAlias);
  yield takeLatest(SAVE_SMS_ALIAS_REQUEST, saveSMSAlias);
  yield takeLatest(FETCH_SMS_CAMPAIGNS_REQUEST, fetchSMSCampaigns);
  yield takeLatest(FETCH_SMS_CAMPAIGN_REQUEST, fetchSMSCampaign);
  yield takeLatest(SAVE_SMS_CAMPAIGN_REQUEST, saveSMSCampaign);
  yield takeLatest(DELETE_SMS_CAMPAIGN_REQUEST, deleteSMSCampaign);
  yield takeLatest(BUY_SMS_PACKAGES_REQUEST, buySMSPackages);
}
