export const FETCH_WIKI_ROOT_DOCUMENTS_REQUEST =
  'FETCH_WIKI_ROOT_DOCUMENTS_REQUEST';
export const FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS =
  'FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS';
export const FETCH_WIKI_ROOT_DOCUMENTS_FAILURE =
  'FETCH_WIKI_ROOT_DOCUMENTS_FAILURE';
export const FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST =
  'FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST';
export const FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS =
  'FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS';
export const FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE =
  'FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE';
export const FETCH_WIKI_SEARCH_REQUEST = 'FETCH_WIKI_SEARCH_REQUEST';
export const FETCH_WIKI_SEARCH_SUCCESS = 'FETCH_WIKI_SEARCH_SUCCESS';
export const FETCH_WIKI_SEARCH_FAILURE = 'FETCH_WIKI_SEARCH_FAILURE';
export const FETCH_WIKI_TAGS_REQUEST = 'FETCH_WIKI_TAGS_REQUEST';
export const FETCH_WIKI_TAGS_SUCCESS = 'FETCH_WIKI_TAGS_SUCCESS';
export const FETCH_WIKI_TAGS_FAILURE = 'FETCH_WIKI_TAGS_FAILURE';
export const FETCH_WIKI_DOCUMENT_REQUEST = 'FETCH_WIKI_DOCUMENT_REQUEST';
export const FETCH_WIKI_DOCUMENT_SUCCESS = 'FETCH_WIKI_DOCUMENT_SUCCESS';
export const FETCH_WIKI_DOCUMENT_FAILURE = 'FETCH_WIKI_DOCUMENT_FAILURE';
export const SET_WIKI_CHILDREN_COLLAPSE = 'SET_WIKI_CHILDREN_COLLAPSE';
export const SET_WIKI_FILTER = 'SET_WIKI_FILTER';
export const SAVE_WIKI_DOCUMENT_REQUEST = 'SAVE_WIKI_DOCUMENT_REQUEST';
export const SAVE_WIKI_DOCUMENT_SUCCESS = 'SAVE_WIKI_DOCUMENT_SUCCESS';
export const SAVE_WIKI_DOCUMENT_FAILURE = 'SAVE_WIKI_DOCUMENT_FAILURE';
export const REMOVE_WIKI_DOCUMENT_REQUEST = 'REMOVE_WIKI_DOCUMENT_REQUEST';
export const REMOVE_WIKI_DOCUMENT_SUCCESS = 'REMOVE_WIKI_DOCUMENT_SUCCESS';
export const REMOVE_WIKI_DOCUMENT_FAILURE = 'REMOVE_WIKI_DOCUMENT_FAILURE';
