import React from 'react';
import { connect } from 'react-redux';
import { getpreviewLogoUrl } from '../../../js/logo/selectors';
import { getCustomerLogo, getCustomerLogoUrl } from '../../../js/me/selectors';
import { getFiles } from '../../../js/files/selectors';
import ynConf from '../../../conf';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  arrangepreviewLogo = () =>
    this.props.previewLogo !== undefined &&
    this.props.previewLogo !== null &&
    this.props.uploadedLogo &&
    Object.keys(this.props.uploadedLogo).length > 0 &&
    this.props.uploadedLogo.data.file !== undefined
      ? this.props.uploadedLogo.data.file.preview
      : null;

  arrangeSavedLogo = () =>
    this.props.customerLogo !== null &&
    this.props.customerLogo.file !== undefined
      ? this.props.customerLogo.file
      : null;

  arrangeUrl = () =>
    this.props.previewLogoUrl !== undefined ||
    this.props.previewLogoUrl !== null
      ? this.props.previewLogoUrl
      : this.props.customerLogoUrl !== null &&
        this.props.customerLogoUrl.url !== undefined
      ? this.props.customerLogo.url
      : '';

  render() {
    const savedLogo = this.arrangeSavedLogo();
    const previewLogo = this.arrangepreviewLogo();
    const url = this.arrangeUrl();
    return (
      <div className="bg-white yn-footer row no-gutters align-items-end">
        {/*<div className="float-left">
          <a
            href="https://apps.apple.com/it/app/youneed-communicator/id1391062646"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${ynConf.publicFolderPath}/AppiOs-${this.props.language}.png`}
              height="38"
              alt="iOs YouNeed App"
              className="pl-1"
            />
          </a>
        </div>
        <div className="float-left">
          <a
            href="https://play.google.com/store/apps/details?id=com.ambrogio.android.communicator"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${ynConf.publicFolderPath}/AppAndroid-${this.props.language}.png`}
              height="38"
              alt="Android YouNeed App"
              className="pl-1"
            />
          </a>
    </div>*/}
        {(savedLogo !== null || previewLogo !== null) && (
          <div className="float-right">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img
                src={
                  previewLogo ||
                  `${ynConf.fileBaseUrl}/logo/download/${savedLogo}`
                }
                style={{ maxHeight: '40px' }}
                alt="logo"
                className="pb-1 pr-2"
              />
            </a>
          </div>
        )}
        {/*<div className="col-auto ml-auto mr-2 pb-1">
          v {process.env.REACT_APP_VERSION}
              </div>*/}
        <div className="col-auto ml-auto">
          <a
            href="http://www.ambrogio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${ynConf.publicFolderPath}/poweredByAmbrogio.svg`}
              alt="Powered by Ambrogio"
              style={{ height: 'var(--yn-footer-height' }}
              className="pb-1 pr-2"
            />
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    uploadedLogo: getFiles(state, 'logo')[0],
    previewLogo: state.logo.previewLogo,
    customerLogo: getCustomerLogo(state),
    customerLogoUrl: getCustomerLogoUrl(state),
    previewLogoUrl: getpreviewLogoUrl(state),
    language: state.settings.language,
  };
}

export default connect(mapStateToProps)(Footer);
