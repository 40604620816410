export const FETCH_ME_PHONERULES_REQUEST = 'FETCH_ME_PHONERULES_REQUEST';
export const FETCH_ME_PHONERULES_SUCCESS = 'FETCH_ME_PHONERULES_SUCCESS';
export const FETCH_ME_PHONERULES_FAILURE = 'FETCH_ME_PHONERULES_FAILURE';
export const DELETE_ME_PHONERULE_REQUEST = 'DELETE_ME_PHONERULE_REQUEST';
export const DELETE_ME_PHONERULE_SUCCESS = 'DELETE_ME_PHONERULE_SUCCESS';
export const DELETE_ME_PHONERULE_FAILURE = 'DELETE_ME_PHONERULE_FAILURE';
export const SAVE_ME_PHONERULE_REQUEST = 'SAVE_ME_PHONERULE_REQUEST';
export const SAVE_ME_PHONERULE_SUCCESS = 'SAVE_ME_PHONERULE_SUCCESS';
export const SAVE_ME_PHONERULE_FAILURE = 'SAVE_ME_PHONERULE_FAILURE';
export const TOGGLE_ME_ENABLED_PHONERULE_REQUEST =
  'TOGGLE_ME_ENABLED_PHONERULE_REQUEST';
export const TOGGLE_ME_ENABLED_PHONERULE_SUCCESS =
  'TOGGLE_ME_ENABLED_PHONERULE_SUCCESS';
export const TOGGLE_ME_ENABLED_PHONERULE_FAILURE =
  'TOGGLE_ME_ENABLED_PHONERULE_FAILURE';
export const FETCH_PBX_PHONERULES_REQUEST = 'FETCH_PBX_PHONERULES_REQUEST';
export const FETCH_PBX_PHONERULES_SUCCESS = 'FETCH_PBX_PHONERULES_SUCCESS';
export const FETCH_PBX_PHONERULES_FAILURE = 'FETCH_PBX_PHONERULES_FAILURE';
export const DELETE_PBX_PHONERULE_REQUEST = 'DELETE_PBX_PHONERULE_REQUEST';
export const DELETE_PBX_PHONERULE_SUCCESS = 'DELETE_PBX_PHONERULE_SUCCESS';
export const DELETE_PBX_PHONERULE_FAILURE = 'DELETE_PBX_PHONERULE_FAILURE';
export const SAVE_PBX_PHONERULE_REQUEST = 'SAVE_PBX_PHONERULE_REQUEST';
export const SAVE_PBX_PHONERULE_SUCCESS = 'SAVE_PBX_PHONERULE_SUCCESS';
export const SAVE_PBX_PHONERULE_FAILURE = 'SAVE_PBX_PHONERULE_FAILURE';
export const TOGGLE_PBX_ENABLED_PHONERULE_REQUEST =
  'TOGGLE_PBX_ENABLED_PHONERULE_REQUEST';
export const TOGGLE_PBX_ENABLED_PHONERULE_SUCCESS =
  'TOGGLE_PBX_ENABLED_PHONERULE_SUCCESS';
export const TOGGLE_PBX_ENABLED_PHONERULE_FAILURE =
  'TOGGLE_PBX_ENABLED_PHONERULE_FAILURE';
export const SET_PBX_SELECTED = 'SET_PBX_SELECTED';
export const SAVE_USER_PHONERULE_REQUEST = 'SAVE_USER_PHONERULE_REQUEST';
export const SAVE_USER_PHONERULE_SUCCESS = 'SAVE_USER_PHONERULE_SUCCESS';
export const SAVE_USER_PHONERULE_FAILURE = 'SAVE_USER_PHONERULE_FAILURE';
export const SET_PBX_PHONERULES_FILTER = 'SET_PBX_PHONERULES_FILTER';
