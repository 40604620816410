import { eventChannel } from 'redux-saga';
import {
  loginPhoneFailure,
  getNethesisPhonesStatusChangesSuccess,
  reloginPhoneRequest,
  activateWebrtcPhoneSuccess,
  activateWebrtcPhoneFailure,
  deactivateWebrtcPhoneSuccess,
  incomingWebrtcCall,
  acceptedWebrtcCall,
  fetchConferenceSuccess,
  noSdpWebrtcCall,
  busyWebrtcCall,
  hangupWebrtcCall,
  decliningWebrtcCall,
  outgoingWebrtcCall,
  webphoneDetached,
  sdpWebrtcCall,
  receiveTrunkUpdate,
} from './actions';
import { PbxManager } from './PbxManager';
import { Janus } from './janus';
import { PhoneRulesEnums } from '../phoneRules/PhoneRulesUtils';
import { PhoneEnums } from './PhoneUtils';

export function initListener(socket) {
  return eventChannel((emit) => {
    socket.on('allWsClientDisonnection', () => {
      emit(loginPhoneFailure());
    });
    socket.on('extenUpdate', (res) => {
      // parse extensions update data
      const parsedData = PbxManager.parseSingleExtensionData(res);
      if (
        parsedData.user &&
        (Object.keys(parsedData.user).length > 0 ||
          parsedData.calls.length >
            0) /* || parsedData.conferences.length > 0 */
      ) {
        emit({
          action: getNethesisPhonesStatusChangesSuccess,
          data: parsedData,
        });
      }
    });
    socket.on('userMainPresenceUpdate', (res) => {
      if (res.mainPresence) {
        const username = res.mainPresence.username;
        const status = res.mainPresence.status;
        const userData = {
          username,
          dnd:
            status === 'dnd'
              ? PhoneEnums.DndStatus.ON
              : PhoneEnums.DndStatus.OFF,
        };
        if (status === 'voicemail') {
          userData.pbxSettings = {
            active: true,
            status: PhoneRulesEnums.SettingsActions.vbox,
          };
        } else if (status === 'callforward') {
          userData.pbxSettings = {
            active: true,
            status: PhoneRulesEnums.SettingsActions.forward,
          };
        }
        emit({
          action: getNethesisPhonesStatusChangesSuccess,
          data: { user: userData, calls: [], parks: [], queues: [] },
        });
      }
    });
    socket.on('parkingUpdate', (res) => {
      // parse parkings update data
      const parsedData = PbxManager.parseSingleParkingData(res);
      const data = {
        user: null,
        calls: [],
        parks: parsedData ? [parsedData] : [],
        queues: [],
      };
      // test
      if (parsedData) {
        emit({ action: getNethesisPhonesStatusChangesSuccess, data });
      }
    });
    socket.on('queueUpdate', (res) => {
      // parse queues update data
      const parsedData = PbxManager.parseSingleQueueData(res);
      const data = {
        user: null,
        calls: [],
        parks: [],
        queues: parsedData || [],
      };
      // test
      if (parsedData && parsedData.length > 0) {
        emit({ action: getNethesisPhonesStatusChangesSuccess, data });
      }
    });
    socket.on('meetmeConfUpdate', (res) => {
      emit({ action: fetchConferenceSuccess, data: res });
    });
    socket.on('meetmeConfEnd', (res) => {
      emit({ action: fetchConferenceSuccess, data: res });
    });
    socket.on('trunkUpdate', (res) => {
      emit({ action: receiveTrunkUpdate, data: res });
    });
    socket.on('authe_ok', () => {
      // emit(loginPhoneSuccess())
    });
    socket.on('401', () => {
      // / relogin again (auth token could be expired)
      emit({ action: reloginPhoneRequest, data: null });
    });
    socket.on('disconnect', () => {
      Janus.debug('Phone ws disconnected');
      emit({ disconnect: true });
      socket.off('allWsClientDisonnection');
      socket.off('extenUpdate');
      socket.off('userMainPresenceUpdate');
      socket.off('parkingUpdate');
      socket.off('queueUpdate');
      socket.off('meetmeConfUpdate');
      socket.off('meetmeConfEnd');
      socket.off('trunkUpdate');
      socket.off('authe_ok');
      socket.off('401');
      socket.off('disconnect');
    });
    return () => {};
  });
}

export function initWebrtcListener(eventEmitter) {
  return eventChannel((emit) => {
    eventEmitter.on('registration_failed', () => {
      Janus.debug('------> Webphone: registration_failed');
      emit({ action: activateWebrtcPhoneFailure });
    });
    eventEmitter.on('registered', () => {
      Janus.debug('------> Webphone: registered');
      emit({ action: activateWebrtcPhoneSuccess });
    });
    eventEmitter.on('unregistered', () => {
      Janus.debug('------> Webphone: unregistered');
      emit({ action: deactivateWebrtcPhoneSuccess });
    });
    eventEmitter.on('calling', () => {
      Janus.debug('------> Webphone: calling');
      emit({ action: outgoingWebrtcCall });
    });
    eventEmitter.on('incomingcall', (data) => {
      Janus.debug('------> Webphone: incomingcall', data);
      emit({ action: incomingWebrtcCall, data });
    });
    eventEmitter.on('progress', () => {
      Janus.debug('------> Webphone: progress');
    });
    eventEmitter.on('accepted', () => {
      Janus.debug('------> Webphone: accepted');
      emit({ action: acceptedWebrtcCall });
    });
    eventEmitter.on('declining', () => {
      Janus.debug('------> Webphone: declining');
      Janus.resetLog();
      emit({ action: decliningWebrtcCall });
    });
    eventEmitter.on('hangup', () => {
      Janus.debug('------> Webphone: hangup');
      Janus.resetLog();
      emit({ action: hangupWebrtcCall });
    });
    eventEmitter.on('busy', () => {
      Janus.debug('------> Webphone: busy');
      emit({ action: busyWebrtcCall });
    });
    eventEmitter.on('disconnect', () => {
      Janus.debug('------> Webphone: disconnect');
      Janus.resetLog();
      emit({ disconnect: true });
    });
    eventEmitter.on('proceeding_no_sdp', () => {
      /* in that case janus doesn't ring */
      Janus.debug('------> Webphone: proceeding_no_sdp');
      emit({ action: noSdpWebrtcCall });
    });
    eventEmitter.on('proceeding_sdp', () => {
      /* in that case janus ring */
      Janus.debug('------> Webphone: proceeding_sdp');
      emit({ action: sdpWebrtcCall });
    });
    eventEmitter.on('detached', () => {
      Janus.debug('------> Webphone: detached');
      emit({ action: webphoneDetached });
      // emit({ disconnect: true });
    });
    return () => {};
  });
}
