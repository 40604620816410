const defaultObject = {};

export const getStickyNotes = (state) => state.sticky.fetchStickyNotes.sticky;

export const getShareStickyLoaded = (state) => state.sticky.shareStickyLoaded;
export const getShareStickyError = (state) => state.sticky.shareStickyError;

/* Static Notes */
export const getStaticNotesLoaded = (state) =>
  state.sticky.fetchStaticNotesLoaded;
export const getStaticNotesError = (state) =>
  state.sticky.fetchStaticNotesError;
export const getStaticNotesIds = (state) =>
  state.sticky.staticNotesAllIds.current;
export const getStaticNoteById = (state, id) =>
  state.sticky.staticNotesById[id] || defaultObject;
export const getCreateStaticNoteLoaded = (state) =>
  state.sticky.createStaticNoteLoaded;
export const getCreateStaticNoteError = (state) =>
  state.sticky.createStaticNoteError;
export const getEditStaticNoteLoaded = (state) =>
  state.sticky.editStaticNoteLoaded;
export const getEditStaticNoteError = (state) =>
  state.sticky.editStaticNoteError;
export const getDeleteStaticNoteError = (state) =>
  state.sticky.deleteStaticNoteError;
