import { createSelector } from 'reselect';

const defaultObject = {};

export const getTicketsFilter = (state) => state.tickets.filter;
export const getTicketStatusById = (state) => state.tickets.ticketStatusById;
export const getTicketStatusIds = (state) => state.tickets.ticketStatusAllIds;
export const getTicketStatus = createSelector(
  [getTicketStatusById, getTicketStatusIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getTicketStatusFromId = (state, id) =>
  state.tickets.ticketStatusById[id] || defaultObject;
export const getTicketsById = (state) => state.tickets.byId;
export const getTicketsIds = (state) => state.tickets.ids;
export const getTickets = createSelector(
  [getTicketsById, getTicketsIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getTicketById = (state, id) =>
  state.tickets.byId[id] || defaultObject;
export const isTicketsListSearched = (state) =>
  state.tickets.fetchTicketsSearched;
export const isTicketsListLoaded = (state) => state.tickets.fetchTicketsLoaded;
export const isTicketsListError = (state) => state.tickets.fetchTicketsError;
export const getTicketsTotal = (state) => state.tickets.total;

export const isTicketsGroupListLoaded = (state) =>
  state.tickets.fetchTicketsGroupLoaded;
export const isTicketsGroupListError = (state) =>
  state.tickets.fetchTicketsGroupError;
export const getTicketsGroup = (state) => state.tickets.ticketsGroup;

export const customizedQuestionsLoading = (state, choiceParent) =>
  state.tickets.saveTicketTree.loadingParent === choiceParent;
export const areCustomizedQuestionsError = (state) =>
  state.tickets.saveTicketTree.error;
export const getCustomizedQuestions = (state, choiceParent) =>
  state.tickets.saveTicketTree.questionsByChoiceParent[choiceParent || 0] || [];
export const getCustomizedQuestionById = (state, id) =>
  state.tickets.saveTicketTree.questionsById[id] || {};
export const getTicketAnswer = (state, ticketId, questionId) => {
  if (
    !ticketId ||
    !state.tickets.byId[ticketId] ||
    !state.tickets.byId[ticketId].customizedAnswers
  ) {
    return null;
  }

  for (
    let i = 0;
    i < state.tickets.byId[ticketId].customizedAnswers.length;
    i += 1
  ) {
    if (state.tickets.byId[ticketId].customizedAnswers[i].id === questionId) {
      return state.tickets.byId[ticketId].customizedAnswers[i];
    }
  }
  return null;
};
export const getTicketAnswerValueById = (state, ticketId, questionId) => {
  const ticketAnswer = getTicketAnswer(state, ticketId, questionId);
  return ticketAnswer ? ticketAnswer.value : null;
};
export const getTicketAnswerChoiceById = (state, ticketId, questionId) => {
  const ticketAnswer = getTicketAnswer(state, ticketId, questionId);
  return ticketAnswer ? ticketAnswer.choiceId : null;
};
export const getTicketAnswerChoicesById = (state, ticketId, questionId) => {
  const ticketAnswer = getTicketAnswer(state, ticketId, questionId);
  return ticketAnswer ? ticketAnswer.choicesIds : null;
};
export const getSaveTicketLoaded = (state) => state.tickets.saveTicketLoaded;
export const getSaveTicketError = (state) => state.tickets.saveTicketError;
export const getAreTicketDetailsLoaded = (state) =>
  state.tickets.fetchTicketDetailsLoaded;
export const getAreTicketDetailsError = (state) =>
  state.tickets.fetchTicketDetailsError;
export const getFetchTicketsTreeLoaded = (state) =>
  state.tickets.fetchTicketsTreeLoaded;
export const getFetchTicketsTreeError = (state) =>
  state.tickets.getFetchTicketsTreeError;
export const getSaveTicketsTreeLoaded = (state) =>
  state.tickets.saveTicketsTreeLoaded;
export const getSaveTicketsTreeError = (state) =>
  state.tickets.saveTicketsTreeError;
export const getDeleteTicketsTreeLoaded = (state) =>
  state.tickets.deleteTicketsTreeLoaded;
export const getDeleteicketsTreeError = (state) =>
  state.tickets.deleteTicketsTreeError;
export const getSaveTreeQuestionLoaded = (state) =>
  state.tickets.saveTreeQuestionLoaded;
export const getSaveTreeQuestionError = (state) =>
  state.tickets.saveTreeQuestionError;
export const getTreeQuestionsById = (state) =>
  state.tickets.customTree.questionsById;
export const isCustomFieldsEditing = (state) =>
  state.tickets.customTree.isEditing;
export const isCustomFieldsDraft = (state) => state.tickets.customTree.isDraft;
export const getTreeId = (state) => state.tickets.customTree.treeId;
export const getTreeQuestionsIds = (state, choiceParent) =>
  state.tickets.customTree.questionsByChoiceParent[choiceParent];
export const getDeleteTreeQuestionLoaded = (state) =>
  state.tickets.deleteTreeQuestionLoaded;
export const getDeleteTreeQuestionError = (state) =>
  state.tickets.deleteTreeQuestionError;
export const getSaveTreeChoiceLoaded = (state) =>
  state.tickets.saveTreeChoiceLoaded;
export const getSaveTreeChoiceError = (state) =>
  state.tickets.saveTreeChoiceError;
export const getDeleteTreeChoiceLoaded = (state) =>
  state.tickets.deleteTreeChoiceLoaded;
export const getDeleteTreeChoiceError = (state) =>
  state.tickets.deleteTreeChoiceError;
export const getNewQuestionOrder = (state, questionId) =>
  state.tickets.customTree.questionsById[questionId] &&
  state.tickets.customTree.questionsByChoiceParent[
    state.tickets.customTree.questionsById[questionId].choiceParent || 0
  ].length;
export const getLoadedSaving = (state) => state.tickets.loadedSaving;
export const getErrorSaving = (state) => state.tickets.errorSaving;
export const getActionSaved = (state) => state.tickets.actionSaved;
export const getLoadedDelete = (state) => state.tickets.loadedDelete;
export const getErrorDelete = (state) => state.tickets.errorDelete;
export const getInsertTicketStatusLoaded = (state) =>
  state.tickets.insertTicketStatusLoaded;
export const getInsertTicketStatusError = (state) =>
  state.tickets.insertTicketStatusError;
export const getTicketStatusLoaded = (state) =>
  state.tickets.fetchTicketStatusLoaded;
export const getTicketStatusError = (state) =>
  state.tickets.fetchTicketStatusError;
export const getLoadedDeleteStatus = (state) =>
  state.tickets.loadedDeleteStatus;
export const getErrorDeleteStatus = (state) => state.tickets.errorDeleteStatus;

export const getTicketCategoriesById = (state) =>
  state.tickets.ticketCategoriesById;
export const getTicketCategoriesIds = (state) =>
  state.tickets.ticketCategoriesAllIds;
export const getTicketCategories = createSelector(
  [getTicketCategoriesById, getTicketCategoriesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getTicketCategoriesLoaded = (state) =>
  state.tickets.fetchTicketCategoriesLoaded;
export const getTicketCategoriesError = (state) =>
  state.tickets.fetchTicketCategoriesError;
export const getTicketCategoryById = (state, id) =>
  state.tickets.ticketCategoriesById[id] || defaultObject;
export const getInsertTicketCategoryLoaded = (state) =>
  state.tickets.insertTicketCategoryLoaded;
export const getInsertTicketCategoryError = (state) =>
  state.tickets.insertTicketCategoryError;
export const getLoadedDeleteCategory = (state) =>
  state.tickets.loadedDeleteCategory;
export const getErrorDeleteCategory = (state) =>
  state.tickets.errorDeleteCategory;
export const getTicketSettings = (state) => state.tickets.ticketSettings;
export const getTicketSettingsLoaded = (state) =>
  state.tickets.ticketSettingsLoaded;
export const getTicketSettingsError = (state) =>
  state.tickets.ticketSettingsError;
export const getUpdateTicketSettingsLoaded = (state) =>
  state.tickets.updateTicketSettingsLoaded;
export const getUpdateTicketSettingsSuccess = (state) =>
  state.tickets.updateTicketSettingsSuccess;
export const getUpdateTicketSettingsError = (state) =>
  state.tickets.updateTicketSettingsError;
export const getDashboardHeader = (state) => state.tickets.dashboardHeader;
export const getDashboardHeaderLoaded = (state) =>
  state.tickets.dashboardHeaderLoaded;
export const getDashboardHeaderError = (state) =>
  state.tickets.dashboardHeaderError;
export const getDashboardProgressBar = (state) =>
  state.tickets.dashboardProgressBar;
export const getDashboardProgressBarLoaded = (state) =>
  state.tickets.dashboardProgressBarLoaded;
export const getDashboardProgressBarError = (state) =>
  state.tickets.dashboardProgressBarError;

export const getDashboardUsersNumbers = (state) =>
  state.tickets.dashboardUsersNumbers;
export const getDashboardUsersProgressBar = (state) =>
  state.tickets.dashboardUsersProgressBar;
export const getDashboardUsersStatisticsLoaded = (state) =>
  state.tickets.dashboardUsersStatisticsLoaded;
export const getDashboardUsersStatisticsError = (state) =>
  state.tickets.dashboardUsersStatisticsError;
export const getDashboardGraphStatistics = (state) =>
  state.tickets.dashboardGraphStatistics;
export const getDashboardGraphStatisticsLoaded = (state) =>
  state.tickets.dashboardGraphStatisticsLoaded;
export const getDashboardGraphStatisticsError = (state) =>
  state.tickets.dashboardGraphStatisticsError;
export const getTicketsListShortcut = (state) =>
  state.tickets.ticketsListShortcut;
export const getTicketsListShortcutLoaded = (state) =>
  state.tickets.ticketsListShortcutLoaded;
export const getTicketsListShortcutError = (state) =>
  state.tickets.ticketsListShortcutError;
export const getTicketsForExcelIds = (state) => state.tickets.excelTicketsIds;
export const getTicketsForExcel = createSelector(
  [getTicketsById, getTicketsForExcelIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getTicketsForExcelLoaded = (state) =>
  state.tickets.fetchTicketForExcelLoaded;
export const getTicketsForExcelError = (state) =>
  state.tickets.fetchTicketForExcelError;
export const getTicketsListOrder = (state) => state.tickets.order;
