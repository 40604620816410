import React from 'react';
import Wiki from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const WikiDetail = React.lazy(() => import('./WikiDetail'));

const WikiDetailPage = (props) => (
  <Wiki scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <WikiDetail {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wiki>
);

export default WikiDetailPage;
