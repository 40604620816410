import React from 'react';
import { connect } from 'react-redux';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { isVacationEnabled } from '../../../js/calendar/selectors';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const CalendarVacationEdit = React.lazy(() => import('./CalendarVacationEdit'));
const CalendarEventEditPage = (props) => (
  <Calendar>
    <div className="row mb-3">
      <div className="col text-left">
        <BackButton />
        <span className="float-right">
          <HelpButton fileName="vacation-edit" />
        </span>
      </div>
    </div>
    {props.vacationEnabled ? (
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <CalendarVacationEdit {...props} />
        </React.Suspense>
      </ErrorBoundary>
    ) : (
      <GlobalErrorPage code={403} />
    )}
  </Calendar>
);

function mapStateToProps(state) {
  return {
    vacationEnabled: isVacationEnabled(state),
  };
}

export default connect(mapStateToProps)(CalendarEventEditPage);
