import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Redirect } from 'react-router-dom';
import SectionTitle from '../../../components/SectionTitle';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import {
  isPhoneAvailable,
  isPhoneInited,
  isPhoneOffline,
  getSupplier,
  getPhoneError,
} from '../../../js/phone/selectors';
import Loading from '../../../components/Loading';
import { getMeCallsAndConferences, hasGrants } from '../../../js/me/selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ProgressCallsSection = React.lazy(() => import('./ProgressCallsSection'));
const IncomingCallsSection = React.lazy(() => import('./IncomingCallsSection'));
const InternalStatusSection = React.lazy(() =>
  import('./InternalStatusSection')
);
const ParkedCallsSection = React.lazy(() => import('./ParkedCallsSection'));

const messages = defineMessages({
  title: {
    id: 'CallsPage.title',
    defaultMessage: 'Calls',
  },
  disconnected: {
    id: 'CallsPage.error.disconnected',
    defaultMessage: 'Pbx connection lost. Trying to reconnect',
  },
});

const CallsPage = ({
  intl: { formatMessage },
  initing,
  offline,
  error,
  hasPhone,
  canSeeQueue,
  supplier,
}) => (
  <div className="border rounded h-100 bg-white">
    {offline || error ? (
      <GlobalErrorPage text={formatMessage(messages.disconnected)} />
    ) : initing ? (
      <Loading />
    ) : hasPhone ? (
      <>
        <SectionTitle
          helpButton={{
            fileName: `calls-${supplier}`,
            style: { paddingTop: '3px' },
          }}
        >
          {formatMessage(messages.title)}
        </SectionTitle>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <>
              <div
                className="yn-section"
                style={{ overflowY: 'unset', padding: 0 }}
              >
                <div className="row yn-calls-row">
                  <div
                    className={`p-0 h-100 ${canSeeQueue ? 'col-8' : 'col-12'}`}
                  >
                    <ProgressCallsSection />
                  </div>
                  {canSeeQueue && (
                    <div className="col-4 p-0 h-100">
                      <IncomingCallsSection />
                    </div>
                  )}
                </div>
                <div className="row yn-calls-row">
                  <div className="col-8 p-0 h-100">
                    <InternalStatusSection />
                  </div>
                  <div className="col-4 p-0 h-100">
                    <ParkedCallsSection />
                  </div>
                </div>
              </div>
            </>
          </React.Suspense>
        </ErrorBoundary>
      </>
    ) : (
      <Redirect exact path="/" to="/chat/messages" />
    )}
  </div>
);

function mapStateToProps(state) {
  return {
    offline: isPhoneOffline(state),
    error: getPhoneError(state),
    initing: !isPhoneInited(state),
    hasPhone: isPhoneAvailable(state),
    canSeeQueue: hasGrants(state, 'PBX_QUEUE'),
    supplier: getSupplier(state),
    existsPendingCall: getMeCallsAndConferences(state).length > 0,
  };
}

export default injectIntl(connect(mapStateToProps)(CallsPage));
