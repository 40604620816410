import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Navbar, Nav, NavItem } from 'reactstrap';
import {
  holdCallRequest,
  unholdCallRequest,
  blindTransferRequest,
  aidTransferRequest,
  linkCallsRequest,
  parkCallRequest,
  switchCallsRequest,
  toggleMuteRequest,
  recordCallRequest,
  startConferenceRequest,
  setSearchContactsFilter,
  stopRecordCallRequest,
} from '../../../js/phone/actions';
import { isPhoneMuted, getSearchedNumber } from '../../../js/phone/selectors';
import Icon from '../../../components/icons/Icon';
import PhoneNumberSearchInput from './PhoneNumberSearchInput';
import Utils from '../../../js/lib/utils';
import AssistedTransferButton from './AssistedTransferButton';
import BlindTransferButton from './BlindTransferButton';
import PhoneNumberSearchList from '../../../components/PhoneNumberSearchList';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { showConfirmModal } from '../../../js/confirm/actions';

const messages = defineMessages({
  hold: {
    id: 'CallToolbar.tooltip.hold',
    defaultMessage: 'Pause',
  },
  park: {
    id: 'CallToolbar.tooltip.park',
    defaultMessage: 'Park',
  },
  aidTransfer: {
    id: 'CallToolbar.tooltip.aidTransfer',
    defaultMessage: 'Assisted transfer',
  },
  unhold: {
    id: 'CallToolbar.tooltip.unhold',
    defaultMessage: 'Unhold',
  },
  switch: {
    id: 'CallToolbar.tooltip.switch',
    defaultMessage: 'Switch',
  },
  merge: {
    id: 'CallToolbar.tooltip.merge',
    defaultMessage: 'Merge',
  },
  mute: {
    id: 'CallToolbar.tooltip.mute',
    defaultMessage: 'Mute',
  },
  unmute: {
    id: 'CallToolbar.tooltip.unmute',
    defaultMessage: 'Unmute',
  },
  startRec: {
    id: 'CallToolbar.tooltip.startRec',
    defaultMessage: 'Start rec',
  },
  stopRecording: {
    id: 'CallToolbar.tooltip.stopRecording',
    defaultMessage: 'Stop rec',
  },
  stopRecordingMessage: {
    id: 'CallToolbar.stopRecordingMessage',
    defaultMessage:
      'Are you sure to stop recording? You cannot record again this conversation',
  },
  startConference: {
    id: 'CallToolbar.tooltip.startConference',
    defaultMessage: 'Start conference',
  },
  addToConference: {
    id: 'CallToolbar.tooltip.addToConference',
    defaultMessage: 'Add to conference',
  },
});

class CallToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferPanelOpen: false,
      transferType: null,
      error: false,
    };
  }

  handleCall = (number, addExitCode) => {
    if (Utils.validatePhoneNumber(number)) {
      this.handleTransfer(number, addExitCode);
      this.setState({ transferPanelOpen: false });
    } else {
      this.setState({ error: true });
    }
  };

  handleHold = () => {
    this.props.holdCall(this.props.callId);
  };

  handleUnhold = () => {
    this.props.unholdCall(this.props.callId);
  };

  handleLink = () => {
    this.props.linkCalls();
  };

  handleSwitch = () => {
    this.props.switchCalls();
  };

  handleBlindTransferClick = () => {
    this.props.setFilter({
      target: 'CallPanel',
      number: '',
      listOpen: false,
      selected: false,
    });
    this.setState({
      transferPanelOpen: !this.state.transferPanelOpen,
      transferType: 'blind',
    });
  };

  handleAidTransferClick = () => {
    this.props.setFilter({
      target: 'CallPanel',
      number: '',
      listOpen: false,
      selected: false,
    });
    this.setState({
      transferPanelOpen: !this.state.transferPanelOpen,
      transferType: 'aid',
    });
  };

  handleTransfer = (number, addExitCode) => {
    this.props.setFilter({
      target: 'CallPanel',
      number: '',
      listOpen: false,
      selected: false,
    });
    if (this.state.transferType === 'blind') {
      this.props.blindTransfer({
        callId: this.props.callId,
        number,
        addExitCode,
      });
    } else if (this.state.transferType === 'aid') {
      this.props.aidTransfer({
        callId: this.props.callId,
        number,
        addExitCode,
      });
    }
    this.setState({ transferPanelOpen: false });
  };

  handlePark = () => {
    this.props.parkCall({
      callId: this.props.callId,
      number:
        this.props.direction === PhoneEnums.Direction.Out
          ? this.props.calling
          : this.props.called,
    });
  };

  handleRec = () => {
    this.props.recordCall({
      callId: this.props.callId,
      users: this.props.users,
    });
  };

  handleStopRec = () => {
    this.props.confirm({
      message: this.props.intl.formatMessage(messages.stopRecordingMessage),
      modalAction: this.props.stopRecordCall,
      actionParams: {
        callId: this.props.callId,
        users: this.props.users,
      },
    });
  };

  handleStartConference = () => {
    this.props.startConference({
      callId: this.props.callId,
      number: this.props.interlocutor,
    });
  };

  toggleMute = () => {
    this.props.toggleMute({
      muted: !this.props.muted,
      callId: this.props.callId,
    });
  };

  render() {
    const {
      muted,
      callId,
      intl: { formatMessage },
      buttons: {
        record,
        recording,
        toggleMute,
        hold,
        park,
        blindTransfer,
        aidTransfer,
        unhold,
        reverse,
        link,
        startConference,
        addToConference,
      },
    } = this.props;
    return (
      <>
        <Navbar
          color="faded"
          light
          expand="sm"
          key={`${this.props.target}_toolbar`}
        >
          <Nav className="mx-auto" navbar>
            {record && (
              <NavItem>
                <span onClick={this.handleRec} className="mx-1">
                  <Icon name="record-button" color="var(--yn-blue-dark)">
                    {formatMessage(messages.startRec)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {recording && (
              <NavItem>
                <span onClick={this.handleStopRec} className="mx-1">
                  <Icon name="record-button-filled" color="var(--yn-blue-dark)">
                    {formatMessage(messages.stopRecording)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {toggleMute && (
              <NavItem>
                <span onClick={this.toggleMute} className="mx-1">
                  {!muted && (
                    <Icon name="microphone-off" color="var(--yn-blue-dark)">
                      {formatMessage(messages.mute)}
                    </Icon>
                  )}
                  {muted && (
                    <Icon name="microphone-3" color="var(--yn-blue-dark)">
                      {formatMessage(messages.unmute)}
                    </Icon>
                  )}
                </span>
              </NavItem>
            )}
            {hold && (
              <NavItem>
                <span onClick={this.handleHold} className="mx-1">
                  <Icon name="phone-call-pause" color="var(--yn-blue-dark)">
                    {formatMessage(messages.hold)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {park && (
              <NavItem>
                <span onClick={this.handlePark} className="mx-1">
                  <Icon name="phone-call-timeout" color="var(--yn-blue-dark)">
                    {formatMessage(messages.park)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {blindTransfer && (
              <BlindTransferButton
                callId={callId}
                handleBlindTransferClick={this.handleBlindTransferClick}
              />
            )}
            {aidTransfer && (
              <AssistedTransferButton
                callId={callId}
                handleAidTransferClick={this.handleAidTransferClick}
              />
            )}
            {unhold && (
              <NavItem>
                <span onClick={this.handleUnhold} className="mx-1">
                  <Icon name="phone-ringing" color="var(--yn-blue-dark)">
                    {formatMessage(messages.unhold)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {reverse && (
              <NavItem>
                <span onClick={this.handleSwitch} className="mx-1">
                  <Icon name="phone-call-refresh" color="var(--yn-blue-dark)">
                    {formatMessage(messages.switch)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {link && (
              <NavItem>
                <span onClick={this.handleLink} className="mx-1">
                  <Icon name="phone-call-merge" color="var(--yn-blue-dark)">
                    {formatMessage(messages.merge)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {startConference && (
              <NavItem>
                <span onClick={this.handleStartConference} className="mx-1">
                  <Icon name="account-group" color="var(--yn-blue-dark)">
                    {formatMessage(messages.startConference)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {addToConference && (
              <NavItem>
                <span onClick={this.handleStartConference} className="mx-1">
                  <Icon name="person-download-2" color="var(--yn-blue-dark)">
                    {formatMessage(messages.addToConference)}
                  </Icon>
                </span>
              </NavItem>
            )}
          </Nav>
        </Navbar>
        {this.state.transferPanelOpen && (
          <PhoneNumberSearchInput
            name="PhonePanelInput"
            id="PhonePanelInput"
            target="CallPanel"
            onEnter={() => this.handleCall(this.props.number, false)}
            error={this.state.error}
          />
        )}
        <PhoneNumberSearchList
          target="CallPanel"
          placement="right-start"
          onSelect={(number, isExtension) =>
            this.handleCall(number, !isExtension)
          }
        />
      </>
    );
  }
}

CallToolbar.propTypes = {
  callId: PropTypes.string.isRequired,
  callStatus: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  target: PropTypes.string.isRequired, // the id of the call the toolbar is related
  //direction
  //called
  //calling
};

function mapStateToProps(state) {
  return {
    muted: isPhoneMuted(state),
    number: getSearchedNumber(state, 'CallPanel'),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    blindTransfer: blindTransferRequest,
    aidTransfer: aidTransferRequest,
    holdCall: holdCallRequest,
    unholdCall: unholdCallRequest,
    linkCalls: linkCallsRequest,
    parkCall: parkCallRequest,
    switchCalls: switchCallsRequest,
    toggleMute: toggleMuteRequest,
    recordCall: recordCallRequest,
    stopRecordCall: stopRecordCallRequest,
    startConference: startConferenceRequest,
    setFilter: setSearchContactsFilter,
    confirm: showConfirmModal,
  })(CallToolbar)
);
