import keyBy from 'lodash.keyby';
import merge from 'lodash.merge';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_FILEBOX_ITEMS_REQUEST,
  SET_FILEBOX_FILTER,
  FETCH_FILEBOX_ITEMS_SUCCESS,
  FETCH_FILEBOX_ITEMS_FAILURE,
  FETCH_FILEBOX_SPACE_USAGE_REQUEST,
  FETCH_FILEBOX_SPACE_USAGE_SUCCESS,
  FETCH_FILEBOX_SPACE_USAGE_FAILURE,
  DELETE_FILEBOX_FILE_REQUEST,
  DELETE_FILEBOX_FILE_SUCCESS,
  DELETE_FILEBOX_FILE_FAILURE,
  CREATE_FILEBOX_FOLDER_REQUEST,
  CREATE_FILEBOX_FOLDER_SUCCESS,
  CREATE_FILEBOX_FOLDER_FAILURE,
  DELETE_FILEBOX_FOLDER_REQUEST,
  DELETE_FILEBOX_FOLDER_SUCCESS,
  DELETE_FILEBOX_FOLDER_FAILURE,
  RENAME_FILEBOX_FILE_REQUEST,
  RENAME_FILEBOX_FILE_SUCCESS,
  RENAME_FILEBOX_FILE_FAILURE,
  FILEBOX_CLEAR_ERRORS,
  EDIT_FILEBOX_FOLDER_REQUEST,
  EDIT_FILEBOX_FOLDER_SUCCESS,
  EDIT_FILEBOX_FOLDER_FAILURE,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE,
  FETCH_FILEBOX_FOLDER_DETAILS_REQUEST,
  FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS,
  FETCH_FILEBOX_FOLDER_DETAILS_FAILURE,
  TOGGLE_LOCKING_FILE_REQUEST,
  TOGGLE_LOCKING_FILE_SUCCESS,
  TOGGLE_LOCKING_FILE_FAILURE,
  FETCH_FILEBOX_FAVORITE_FILES_REQUEST,
  FETCH_FILEBOX_FAVORITE_FILES_SUCCESS,
  FETCH_FILEBOX_FAVORITE_FILES_FAILURE,
  MOVE_FILEBOX_FILE_REQUEST,
  MOVE_FILEBOX_FILE_SUCCESS,
  MOVE_FILEBOX_FILE_FAILURE,
  FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST,
  FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS,
  FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE,
  SET_FILEBOX_ACTIVE_FILTER,
  ADD_FILEBOX_FAVORITE_FILE_REQUEST,
  ADD_FILEBOX_FAVORITE_FILE_SUCCESS,
  ADD_FILEBOX_FAVORITE_FILE_FAILURE,
  SELECT_FILEBOX_FOLDER,
  REMOVE_FILEBOX_FAVORITE_FILE_REQUEST,
  REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS,
  REMOVE_FILEBOX_FAVORITE_FILE_FAILURE,
  COPY_FILEBOX_FILE_REQUEST,
  COPY_FILEBOX_FILE_SUCCESS,
  COPY_FILEBOX_FILE_FAILURE,
  COPY_FILEBOX_FOLDER_REQUEST,
  COPY_FILEBOX_FOLDER_SUCCESS,
  COPY_FILEBOX_FOLDER_FAILURE,
  SEARCH_FILEBOX_FILES_FOLDERS_REQUEST,
  SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS,
  SEARCH_FILEBOX_FILES_FOLDERS_FAILURE,
  SET_FILEBOX_PATH_FROM_SEARCH,
  MOVE_FILEBOX_FOLDER_FAILURE,
  MOVE_FILEBOX_FOLDER_SUCCESS,
  MOVE_FILEBOX_FOLDER_REQUEST,
  UPDATE_FILEBOX_SPACE_REQUEST,
  UPDATE_FILEBOX_SPACE_SUCCESS,
  UPDATE_FILEBOX_SPACE_FAILURE,
  DELETE_FILEBOX_MASSIVE_FILES_REQUEST,
  DELETE_FILEBOX_MASSIVE_FILES_SUCCESS,
  DELETE_FILEBOX_MASSIVE_FILES_FAILURE,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE,
  REMOVE_FILEBOX_FILE_VERSION_FAILURE,
  REMOVE_FILEBOX_FILE_VERSION_SUCCESS,
  REMOVE_FILEBOX_FILE_VERSION_REQUEST,
  SET_FILEBOX_LIST_ORDER,
  FETCH_FILEBOX_DELETED_ITEMS_REQUEST,
  FETCH_FILEBOX_DELETED_ITEMS_SUCCESS,
  FETCH_FILEBOX_DELETED_ITEMS_FAILURE,
  SELECT_FILEBOX_DELETED_FOLDER,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE,
  RESTORE_FILEBOX_DELETED_ITEM_REQUEST,
  RESTORE_FILEBOX_DELETED_ITEM_SUCCESS,
  RESTORE_FILEBOX_DELETED_ITEM_FAILURE,
  SET_FILEBOX_ACTUAL_PATH,
  FETCH_FILEBOX_FILE_COMMENTS_REQUEST,
  DELETE_FILEBOX_FILE_COMMENT_REQUEST,
  CREATE_FILEBOX_FILE_COMMENT_REQUEST,
  DELETE_FILEBOX_FILE_COMMENT_FAILURE,
  CREATE_FILEBOX_FILE_COMMENT_FAILURE,
  FETCH_FILEBOX_FILE_COMMENTS_FAILURE,
  FETCH_FILEBOX_FILE_COMMENTS_SUCCESS,
  SET_FILEBOX_ACTIVE_ORDER,
  SET_FILEBOX_DELETED_ORDER,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE,
  SHARE_FILEBOX_FILE_REQUEST,
  SHARE_FILEBOX_FILE_SUCCESS,
  SHARE_FILEBOX_FILE_FAILURE,
} from './types';
import Utils from '../lib/utils';
import { FileboxEnums } from './FileboxUtils';

const initialState = {
  fetchItemsLoaded: true,
  fetchItemsError: null,
  fetchSpaceUsageLoaded: true,
  fetchSpaceUsageError: null,
  planExpirationDate: null,
  spaceUsage: {},
  spaceUsageFetchDatetime: new Date(),
  filter: { folders: true, files: true },
  selectedFolderId: null,
  actualPath: [],
  byId: {},
  filesAllIds: [],
  foldersAllIds: [],
  filesAllNames: [],
  deleteFileLoaded: true,
  deleteFileError: false,
  createFolderLoaded: true,
  createFolderError: false,
  deleteFolderLoaded: true,
  deleteFolderError: false,
  renameFileLoaded: true,
  renameFileError: false,
  editFolderLoaded: true,
  editFolderError: false,
  externalLinkHash: false,
  enableDisableFileExternalLinkLoaded: true,
  enableDisableFileExternalLinkError: false,
  fetchFolderDetailsLoaded: true,
  fetchFolderDetailsError: false,
  toggleLockingFileLoaded: true,
  toggleLockingFileError: false,
  fetchFavoriteFilesLoaded: true,
  fetchFavoriteFilesError: false,
  favoriteFilesAllIds: [],
  moveFileLoaded: true,
  moveFileError: false,
  copyFileLoaded: true,
  copyFileError: false,
  copyFolderLoaded: true,
  copyFolderError: false,
  activeFoldersAllIds: [],
  activeFilesAllIds: [],
  activeFilter: {},
  activeOrder: {
    type: FileboxEnums.orderType.NAME,
    direction: FileboxEnums.orderDirection.ASC,
  },
  fetchActiveItemsLoaded: true,
  fetchActiveItemsError: null,
  activeActualPath: {},
  activeSelectedFolderId: null,
  removeFavoriteFileLoaded: true,
  removeFavoriteFileError: false,
  searchFilesFoldersLoaded: true,
  searchFilesFoldersError: false,
  searchedFilesAllIds: [],
  searchedFoldersAllIds: [],
  namedActualPath: [],
  markFavoriteFileLoaded: true,
  markFavoriteFileError: false,
  moveFolderLoaded: true,
  moveFolderError: false,
  updateSpaceLoaded: true,
  updateSpaceError: false,
  deleteMassiveFilesLoaded: true,
  deleteMassiveFilesError: false,
  failedFoldersDeletion: [],
  operationFileCommentsLoaded: true,
  operationFileCommentsError: false,
  order: {
    type: FileboxEnums.orderType.NAME,
    direction: FileboxEnums.orderDirection.ASC,
  },
  fetchPreviousVersionsOfFileLoaded: true,
  fetchPreviousVersionsOfFileError: false,
  deleteFileVersionLoaded: true,
  deleteFileVersionError: false,
  fetchDeletedItemsLoaded: true,
  fetchDeletedItemsError: null,
  deletedFilesAllIds: [],
  deletedFoldersAllIds: [],
  deletedActualPath: [],
  deletedSelectedFolderId: null,
  deletedOrder: {
    type: FileboxEnums.orderType.NAME,
    direction: FileboxEnums.orderDirection.ASC,
  },
  removePermanentlyDeletedItemLoaded: true,
  removePermanentlyDeletedItemError: false,
  restoreDeletedItemLoaded: true,
  restoreDeletedItemError: false,
  fetchFileboxFileEmailRecipientsLoaded: true,
  fetchFileboxFileEmailRecipientsError: false,
  shareFileboxFileLoaded: true,
  shareFileboxFileError: false,
  lastRoute: [],
};

export default function filebox(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case SELECT_FILEBOX_FOLDER:
      return {
        ...state,
        selectedFolderId: action.folderId,
        actualPath:
          state.actualPath.indexOf(action.folderId) > -1
            ? state.actualPath.slice(
                0,
                state.actualPath.indexOf(action.folderId) + 1
              )
            : [...state.actualPath, action.folderId],
      };

    case SET_FILEBOX_ACTUAL_PATH:
      return {
        ...state,
        actualPath: action.path,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case SET_FILEBOX_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };

    case FETCH_FILEBOX_ITEMS_REQUEST:
      return {
        ...state,
        fetchItemsLoaded: false,
        fetchItemsError: null,
        deleteFileError: false,
        deleteFileVersionError: false,
        deleteFolderError: false,
        markFavoriteFileError: false,
        removeFavoriteFileError: false,
        deleteMassiveFilesError: action.isFromMassiveDeletion
          ? state.deleteMassiveFilesError
          : false,
      };

    case FETCH_FILEBOX_ITEMS_SUCCESS: {
      const folders = action.payload.items
        .filter((o) => o.isDirectory)
        .map((o) => (state.byId[o.id] ? merge(state.byId[o.id], o) : o));
      const files = action.payload.items
        .filter((o) => !o.isDirectory)
        .map((o) => (state.byId[o.id] ? merge(state.byId[o.id], o) : o));
      return {
        ...state,
        fetchItemsLoaded: true,
        fetchItemsError: null,
        byId: { ...state.byId, ...keyBy(folders, 'id'), ...keyBy(files, 'id') },
        filesAllIds: files.map((file) => file.id),
        foldersAllIds: folders.map((folder) => folder.id),
        filesAllNames: action.payload.items
          .filter((o) => !o.isDirectory)
          .map((o) => o.name),
      };
    }
    case FETCH_FILEBOX_ITEMS_FAILURE:
      return {
        ...state,
        fetchItemsLoaded: true,
        fetchItemsError: action.errors,
      };
    case FETCH_FILEBOX_SPACE_USAGE_REQUEST:
      return {
        ...state,
        fetchSpaceUsageLoaded: false,
        fetchSpaceUsageError: null,
      };
    case FETCH_FILEBOX_SPACE_USAGE_SUCCESS:
      return {
        ...state,
        fetchSpaceUsageLoaded: true,
        fetchSpaceUsageError: null,
        planExpirationDate: action.spaceUsage.planExpirationDate,
        spaceUsage: {
          total: +action.spaceUsage.total,
          used: +action.spaceUsage.used,
          deleted: +action.spaceUsage.deleted,
          versions: +action.spaceUsage.versions,
          totalUsed:
            +action.spaceUsage.used +
            +action.spaceUsage.deleted +
            +action.spaceUsage.versions,
          actualSizePlan:
            Utils.bytesToGigabytes(+action.spaceUsage.total) - 1 || null,
        },
      };
    case FETCH_FILEBOX_SPACE_USAGE_FAILURE:
      return {
        ...state,
        fetchSpaceUsageLoaded: true,
        fetchSpaceUsageError: action.errors,
      };
    case DELETE_FILEBOX_FILE_REQUEST:
      return {
        ...state,
        deleteFileLoaded: false,
        deleteFileError: false,
      };
    case DELETE_FILEBOX_FILE_SUCCESS:
      return {
        ...state,
        deleteFileLoaded: true,
        deleteFileError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case DELETE_FILEBOX_FILE_FAILURE:
      return {
        ...state,
        deleteFileLoaded: true,
        deleteFileError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case CREATE_FILEBOX_FOLDER_REQUEST:
      return {
        ...state,
        createFolderLoaded: false,
        createFolderError: false,
      };
    case CREATE_FILEBOX_FOLDER_SUCCESS:
      return {
        ...state,
        createFolderLoaded: true,
        createFolderError: false,
      };
    case CREATE_FILEBOX_FOLDER_FAILURE:
      return {
        ...state,
        createFolderLoaded: true,
        createFolderError: action.errors,
      };
    case DELETE_FILEBOX_FOLDER_REQUEST:
      return {
        ...state,
        deleteFolderLoaded: false,
        deleteFolderError: false,
      };
    case DELETE_FILEBOX_FOLDER_SUCCESS:
      return {
        ...state,
        deleteFolderLoaded: true,
        deleteFolderError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case DELETE_FILEBOX_FOLDER_FAILURE:
      return {
        ...state,
        deleteFolderLoaded: true,
        deleteFolderError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case RENAME_FILEBOX_FILE_REQUEST:
      return {
        ...state,
        renameFileLoaded: false,
        renameFileError: false,
      };
    case RENAME_FILEBOX_FILE_SUCCESS:
      return {
        ...state,
        renameFileLoaded: true,
        renameFileError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case RENAME_FILEBOX_FILE_FAILURE:
      return {
        ...state,
        renameFileLoaded: true,
        renameFileError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case EDIT_FILEBOX_FOLDER_REQUEST:
      return {
        ...state,
        editFolderLoaded: false,
        editFolderError: false,
      };
    case EDIT_FILEBOX_FOLDER_SUCCESS:
      return {
        ...state,
        editFolderLoaded: true,
        editFolderError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case EDIT_FILEBOX_FOLDER_FAILURE:
      return {
        ...state,
        editFolderLoaded: true,
        editFolderError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST:
      return {
        ...state,
        externalLinkHash: false,
        enableDisableFileExternalLinkLoaded: false,
        enableDisableFileExternalLinkError: false,
      };
    case ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            publicUrl: action.enabled ? action.payload.publicUrl : null,
          },
        },
        enableDisableFileExternalLinkLoaded: true,
        enableDisableFileExternalLinkError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE:
      return {
        ...state,
        externalLinkHash: false,
        enableDisableFileExternalLinkLoaded: true,
        enableDisableFileExternalLinkError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case FILEBOX_CLEAR_ERRORS:
      return {
        ...state,
        fetchItemsError: null,
        fetchSpaceUsageLoaded: true,
        fetchSpaceUsageError: null,
        deleteFileError: false,
        createFolderError: false,
        deleteFolderError: false,
        renameFileError: false,
        editFolderError: false,
        enableDisableFileExternalLinkError: false,
        fetchFolderDetailsError: false,
        toggleLockingFileError: false,
        fetchFavoriteFilesError: false,
        moveFileError: false,
        copyFileError: false,
        copyFolderError: false,
        fetchActiveItemsLoaded: true,
        fetchActiveItemsError: null,
        removeFavoriteFileError: false,
        searchFilesFoldersError: false,
        markFavoriteFileError: false,
        moveFolderError: false,
        updateSpaceError: false,
        deleteMassiveFilesError: false,
        operationFileCommentsError: false,
        fetchPreviousVersionsOfFileError: false,
        deleteFileVersionError: false,
        fetchDeletedItemsError: null,
        removePermanentlyDeletedItemError: false,
        restoreDeletedItemError: false,
        shareFileboxFileError: false,
      };
    case FETCH_FILEBOX_FOLDER_DETAILS_REQUEST:
      return {
        ...state,
        fetchFolderDetailsLoaded: false,
        fetchFolderDetailsError: false,
      };
    case FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchFolderDetailsLoaded: true,
        fetchFolderDetailsError: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
            isShared:
              action.payload.sharing && action.payload.sharing.length > 0,
          },
        },
      };
    case FETCH_FILEBOX_FOLDER_DETAILS_FAILURE:
      return {
        ...state,
        fetchFolderDetailsLoaded: true,
        fetchFolderDetailsError: action.errors,
      };
    case TOGGLE_LOCKING_FILE_REQUEST:
      return {
        ...state,
        toggleLockingFileLoaded: false,
        toggleLockingFileError: false,
      };
    case TOGGLE_LOCKING_FILE_SUCCESS:
      return {
        ...state,
        toggleLockingFileLoaded: true,
        toggleLockingFileError: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            blockingUser: action.payload.data.lock
              ? action.payload.blockingUser
              : null,
          },
        },
      };
    case TOGGLE_LOCKING_FILE_FAILURE:
      return {
        ...state,
        toggleLockingFileLoaded: true,
        toggleLockingFileError: action.payload.errors,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            blockingUser:
              action.payload.blockingUser ||
              state.byId[action.payload.id].blockingUser,
          },
        },
      };
    case FETCH_FILEBOX_FAVORITE_FILES_REQUEST:
      return {
        ...state,
        fetchFavoriteFilesLoaded: false,
        fetchFavoriteFilesError: false,
      };
    case FETCH_FILEBOX_FAVORITE_FILES_SUCCESS:
      return {
        ...state,
        fetchFavoriteFilesLoaded: true,
        fetchFavoriteFilesError: false,
        byId: {
          ...state.byId,
          ...keyBy(action.payload.items, 'id'),
        },
        favoriteFilesAllIds: action.payload.items.map((o) => o.id),
      };
    case FETCH_FILEBOX_FAVORITE_FILES_FAILURE:
      return {
        ...state,
        fetchFavoriteFilesLoaded: true,
        fetchFavoriteFilesError: action.errors,
      };
    case SET_FILEBOX_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: {
          ...state.activeFilter,
          [action.filter.context]: {
            ...state.activeFilter[action.filter.context],
            ...action.filter,
          },
        },
      };
    case FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST:
      return {
        ...state,
        fetchActiveItemsLoaded: false,
        fetchActiveItemsError: null,
      };
    case FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS:
      const actualPath = state.activeActualPath[action.payload.context] || [];
      return {
        ...state,
        fetchActiveItemsLoaded: true,
        fetchActiveItemsError: null,
        byId: { ...state.byId, ...keyBy(action.payload.items, 'id') },
        activeFilesAllIds: action.payload.items
          .filter((o) => !o.isDirectory)
          .map((o) => o.id),
        activeFoldersAllIds: action.payload.items
          .filter((o) => o.isDirectory)
          .map((o) => o.id),
        activeSelectedFolderId: action.payload.folderId,
        activeActualPath: {
          ...state.activeActualPath,
          [action.payload.context]: !action.payload.folderId
            ? [null]
            : actualPath.indexOf(action.payload.folderId) > -1
            ? actualPath.slice(
                0,
                actualPath.indexOf(action.payload.folderId) + 1
              )
            : [...actualPath, action.payload.folderId],
        },
      };
    case FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE:
      return {
        ...state,
        fetchActiveItemsLoaded: true,
        fetchActiveItemsError: action.errors,
      };
    case MOVE_FILEBOX_FILE_REQUEST:
      return {
        ...state,
        moveFileLoaded: false,
        moveFileError: false,
      };
    case MOVE_FILEBOX_FILE_SUCCESS:
      return {
        ...state,
        moveFileLoaded: true,
        moveFileError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case MOVE_FILEBOX_FILE_FAILURE:
      return {
        ...state,
        moveFileLoaded: true,
        moveFileError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case MOVE_FILEBOX_FOLDER_REQUEST:
      return {
        ...state,
        moveFolderLoaded: false,
        moveFolderError: false,
      };
    case MOVE_FILEBOX_FOLDER_SUCCESS:
      return {
        ...state,
        moveFolderLoaded: true,
        moveFolderError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case MOVE_FILEBOX_FOLDER_FAILURE:
      return {
        ...state,
        moveFolderLoaded: true,
        moveFolderError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case COPY_FILEBOX_FILE_REQUEST:
      return {
        ...state,
        copyFileLoaded: false,
        copyFileError: false,
      };
    case COPY_FILEBOX_FILE_SUCCESS:
      return {
        ...state,
        copyFileLoaded: true,
        copyFileError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case COPY_FILEBOX_FILE_FAILURE:
      return {
        ...state,
        copyFileLoaded: true,
        copyFileError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case COPY_FILEBOX_FOLDER_REQUEST:
      return {
        ...state,
        copyFolderLoaded: false,
        copyFolderError: false,
      };
    case COPY_FILEBOX_FOLDER_SUCCESS:
      return {
        ...state,
        copyFolderLoaded: true,
        copyFolderError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case COPY_FILEBOX_FOLDER_FAILURE:
      return {
        ...state,
        copyFolderLoaded: true,
        copyFolderError: action.errors,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case SEARCH_FILEBOX_FILES_FOLDERS_REQUEST:
      return {
        ...state,
        searchFilesFoldersLoaded: false,
        searchFilesFoldersError: false,
      };
    case SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS: {
      const folders = action.payload.items
        .filter((o) => o.isDirectory)
        .map((o) => (state.byId[o.id] ? { ...o, ...state.byId[o.id] } : o));
      const files = action.payload.items.filter((o) => !o.isDirectory);
      return {
        ...state,
        searchFilesFoldersLoaded: true,
        searchFilesFoldersError: false,
        byId: { ...state.byId, ...keyBy(folders, 'id'), ...keyBy(files, 'id') },
        searchedFilesAllIds: files.map((o) => o.id),
        searchedFoldersAllIds: folders.map((o) => o.id),
      };
    }
    case SEARCH_FILEBOX_FILES_FOLDERS_FAILURE:
      return {
        ...state,
        searchFilesFoldersLoaded: true,
        searchFilesFoldersError: action.errors,
      };
    case SET_FILEBOX_PATH_FROM_SEARCH:
      return {
        ...state,
        selectedFolderId: action.pathIndexes[action.pathIndexes.length - 1],
        actualPath: action.pathIndexes,
        namedActualPath: action.pathNames,
      };
    case ADD_FILEBOX_FAVORITE_FILE_REQUEST:
      return {
        ...state,
        markFavoriteFileLoaded: false,
        markFavoriteFileError: false,
      };
    case ADD_FILEBOX_FAVORITE_FILE_SUCCESS:
      return {
        ...state,
        markFavoriteFileLoaded: true,
        markFavoriteFileError: false,
        favoriteFilesAllIds: [...state.favoriteFilesAllIds, action.payload.id],
      };
    case ADD_FILEBOX_FAVORITE_FILE_FAILURE:
      return {
        ...state,
        markFavoriteFileLoaded: false,
        markFavoriteFileError: action.errors,
      };
    case REMOVE_FILEBOX_FAVORITE_FILE_REQUEST: {
      return {
        ...state,
        removeFavoriteFileLoaded: false,
        removeFavoriteFileError: false,
      };
    }
    case REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS:
      return {
        ...state,
        removeFavoriteFileLoaded: true,
        removeFavoriteFileError: false,
        favoriteFilesAllIds: state.favoriteFilesAllIds.filter(
          (favoriteFile) => favoriteFile !== action.id
        ),
      };
    case REMOVE_FILEBOX_FAVORITE_FILE_FAILURE:
      return {
        ...state,
        removeFavoriteFileLoaded: true,
        removeFavoriteFileError: action.errors,
      };
    case UPDATE_FILEBOX_SPACE_REQUEST:
      return {
        ...state,
        updateSpaceLoaded: false,
        updateSpaceError: false,
      };
    case UPDATE_FILEBOX_SPACE_SUCCESS:
      return {
        ...state,
        updateSpaceLoaded: true,
        updateSpaceError: false,
      };
    case UPDATE_FILEBOX_SPACE_FAILURE:
      return {
        ...state,
        updateSpaceLoaded: true,
        updateSpaceError: action.errors,
      };
    case DELETE_FILEBOX_MASSIVE_FILES_REQUEST:
      return {
        ...state,
        deleteMassiveFilesLoaded: false,
        deleteMassiveFilesError: false,
      };
    case DELETE_FILEBOX_MASSIVE_FILES_SUCCESS:
      return {
        ...state,
        deleteMassiveFilesLoaded: true,
        deleteMassiveFilesError: false,
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case DELETE_FILEBOX_MASSIVE_FILES_FAILURE:
      return {
        ...state,
        deleteMassiveFilesLoaded: true,
        deleteMassiveFilesError: action.errors,
        failedFoldersDeletion: action.ids || [],
        filter: {
          ...state.filter,
          name: '',
        },
      };
    case FETCH_FILEBOX_FILE_COMMENTS_REQUEST:
    case DELETE_FILEBOX_FILE_COMMENT_REQUEST:
    case CREATE_FILEBOX_FILE_COMMENT_REQUEST:
      return {
        ...state,
        operationFileCommentsLoaded: false,
        operationFileCommentsError: false,
      };
    case FETCH_FILEBOX_FILE_COMMENTS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            comments: action.payload.items,
          },
        },
        operationFileCommentsLoaded: true,
        operationFileCommentsError: false,
      };
    }
    case FETCH_FILEBOX_FILE_COMMENTS_FAILURE:
    case DELETE_FILEBOX_FILE_COMMENT_FAILURE:
    case CREATE_FILEBOX_FILE_COMMENT_FAILURE:
      return {
        ...state,
        operationFileCommentsLoaded: true,
        operationFileCommentsError: true,
      };
    case RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST:
      return {
        ...state,
        fetchPreviousVersionsOfFileLoaded: false,
        fetchPreviousVersionsOfFileError: false,
        deleteFileVersionError: false,
      };
    case RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS:
      return {
        ...state,
        fetchPreviousVersionsOfFileLoaded: true,
        fetchPreviousVersionsOfFileError: false,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            versions: action.payload,
          },
        },
      };
    case RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE:
      return {
        ...state,
        fetchPreviousVersionsOfFileLoaded: true,
        fetchPreviousVersionsOfFileError: action.errors,
      };
    case REMOVE_FILEBOX_FILE_VERSION_REQUEST:
      return {
        ...state,
        deleteFileVersionLoaded: false,
        deleteFileVersionError: false,
      };
    case REMOVE_FILEBOX_FILE_VERSION_SUCCESS:
      return {
        ...state,
        deleteFileVersionLoaded: true,
        deleteFileVersionError: false,
        byId: {
          ...state.byId,
          [action.fileId]: {
            ...state.byId[action.fileId],
            versions: state.byId[action.fileId].versions.filter(
              (file) => file.id !== action.id
            ),
          },
        },
      };
    case REMOVE_FILEBOX_FILE_VERSION_FAILURE:
      return {
        ...state,
        deleteFileVersionLoaded: true,
        deleteFileVersionError: action.errors,
      };

    case SET_FILEBOX_LIST_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }
    case FETCH_FILEBOX_DELETED_ITEMS_REQUEST:
      return {
        ...state,
        fetchDeletedItemsLoaded: false,
        fetchDeletedItemsError: null,
        removePermanentlyDeletedItemError: false,
        restoreDeletedItemError: false,
      };
    case FETCH_FILEBOX_DELETED_ITEMS_SUCCESS:
      return {
        ...state,
        fetchDeletedItemsLoaded: true,
        fetchDeletedItemsError: null,
        byId: { ...state.byId, ...keyBy(action.payload.items, 'id') },
        deletedFilesAllIds: action.payload.items
          .filter((o) => !o.isDirectory)
          .map((o) => o.id),
        deletedFoldersAllIds: action.payload.items
          .filter((o) => o.isDirectory)
          .map((o) => o.id),
        deletedSelectedFolderId: action.payload.folderId,
        deletedActualPath: !action.payload.folderId
          ? []
          : state.deletedActualPath.indexOf(action.payload.folderId) > -1
          ? state.deletedActualPath.slice(
              0,
              state.deletedActualPath.indexOf(action.payload.folderId) + 1
            )
          : [...state.deletedActualPath, action.payload.folderId],
      };
    case FETCH_FILEBOX_DELETED_ITEMS_FAILURE:
      return {
        ...state,
        fetchDeletedItemsLoaded: true,
        fetchDeletedItemsError: action.errors,
      };
    case SELECT_FILEBOX_DELETED_FOLDER:
      return {
        ...state,
        deletedSelectedFolderId: action.folderId,
        deletedActualPath:
          state.deletedActualPath.indexOf(action.folderId) > -1
            ? state.deletedActualPath.slice(
                0,
                state.deletedActualPath.indexOf(action.folderId) + 1
              )
            : [...state.deletedActualPath, action.folderId],
      };
    case REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST:
      return {
        ...state,
        removePermanentlyDeletedItemLoaded: false,
        removePermanentlyDeletedItemError: false,
      };
    case REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS:
      return {
        ...state,
        removePermanentlyDeletedItemLoaded: true,
        removePermanentlyDeletedItemError: false,
      };
    case REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE:
      return {
        ...state,
        removePermanentlyDeletedItemLoaded: true,
        removePermanentlyDeletedItemError: action.errors,
      };
    case RESTORE_FILEBOX_DELETED_ITEM_REQUEST:
      return {
        ...state,
        restoreDeletedItemLoaded: false,
        restoreDeletedItemError: false,
      };
    case RESTORE_FILEBOX_DELETED_ITEM_SUCCESS:
      return {
        ...state,
        restoreDeletedItemLoaded: true,
        restoreDeletedItemError: false,
      };
    case RESTORE_FILEBOX_DELETED_ITEM_FAILURE:
      return {
        ...state,
        restoreDeletedItemLoaded: true,
        restoreDeletedItemError: action.errors,
      };
    case SET_FILEBOX_ACTIVE_ORDER: {
      return {
        ...state,
        activeOrder: action.order,
      };
    }
    case SET_FILEBOX_DELETED_ORDER: {
      return {
        ...state,
        deletedOrder: action.order,
      };
    }
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      const newState =
        lastRoute[0] === newRoute[0] && lastRoute[1] === newRoute[1]
          ? state
          : initialState;
      return {
        ...newState,
        lastRoute: newRoute,
      };
    }
    case FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST:
      return {
        ...state,
        fetchFileboxFileEmailRecipientsLoaded: false,
        fetchFileboxFileEmailRecipientsError: false,
      };
    case FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS:
      return {
        ...state,
        fetchFileboxFileEmailRecipientsLoaded: true,
        fetchFileboxFileEmailRecipientsError: false,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            recipients: action.payload.items,
          },
        },
      };
    case FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE:
      return {
        ...state,
        fetchFileboxFileEmailRecipientsLoaded: true,
        fetchFileboxFileEmailRecipientsError: action.errors,
      };
    case SHARE_FILEBOX_FILE_REQUEST:
      return {
        ...state,
        shareFileboxFileLoaded: false,
        shareFileboxFileError: false,
      };
    case SHARE_FILEBOX_FILE_SUCCESS:
      return {
        ...state,
        shareFileboxFileLoaded: true,
        shareFileboxFileError: false,
      };
    case SHARE_FILEBOX_FILE_FAILURE:
      return {
        ...state,
        shareFileboxFileLoaded: true,
        shareFileboxFileError: action.errors,
      };
    default:
      return state;
  }
}
