import React from 'react';
import ynConf from '../conf';
import { charsToCode } from '../js/chat/charsToCode';

const EmojiImg = ({ emoji, big, small }) => {
  const size = big ? 64 : small ? 16 : 24;
  return (
    <img
      width={size}
      height={size}
      src={`${ynConf.publicFolderPath}/emojis/${charsToCode[emoji]}.png`}
      alt={emoji}
    />
  );
};

export default EmojiImg;
