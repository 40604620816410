import React from 'react';
import Fax from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const FaxNotifications = React.lazy(() => import('./FaxNotifications'));

const FaxNotificationsPage = (props) => (
  <Fax scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-fax" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <FaxNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Fax>
);

export default FaxNotificationsPage;
