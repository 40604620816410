import {
  FETCH_ME_PHONERULES_REQUEST,
  FETCH_ME_PHONERULES_SUCCESS,
  FETCH_ME_PHONERULES_FAILURE,
  DELETE_ME_PHONERULE_REQUEST,
  DELETE_ME_PHONERULE_SUCCESS,
  DELETE_ME_PHONERULE_FAILURE,
  SAVE_ME_PHONERULE_REQUEST,
  SAVE_ME_PHONERULE_SUCCESS,
  SAVE_ME_PHONERULE_FAILURE,
  TOGGLE_ME_ENABLED_PHONERULE_REQUEST,
  TOGGLE_ME_ENABLED_PHONERULE_SUCCESS,
  TOGGLE_ME_ENABLED_PHONERULE_FAILURE,
  FETCH_PBX_PHONERULES_REQUEST,
  FETCH_PBX_PHONERULES_SUCCESS,
  FETCH_PBX_PHONERULES_FAILURE,
  DELETE_PBX_PHONERULE_REQUEST,
  DELETE_PBX_PHONERULE_SUCCESS,
  DELETE_PBX_PHONERULE_FAILURE,
  SAVE_PBX_PHONERULE_REQUEST,
  SAVE_PBX_PHONERULE_SUCCESS,
  SAVE_PBX_PHONERULE_FAILURE,
  TOGGLE_PBX_ENABLED_PHONERULE_REQUEST,
  TOGGLE_PBX_ENABLED_PHONERULE_SUCCESS,
  TOGGLE_PBX_ENABLED_PHONERULE_FAILURE,
  SET_PBX_SELECTED,
  SAVE_USER_PHONERULE_REQUEST,
  SAVE_USER_PHONERULE_SUCCESS,
  SAVE_USER_PHONERULE_FAILURE,
  SET_PBX_PHONERULES_FILTER,
} from './types';

export const fetchMePhoneRulesRequest = (params) => ({
  type: FETCH_ME_PHONERULES_REQUEST,
  params,
});
export const fetchMePhoneRulesSuccess = (rules) => ({
  type: FETCH_ME_PHONERULES_SUCCESS,
  rules,
});
export const fetchMePhoneRulesFailure = (error) => ({
  type: FETCH_ME_PHONERULES_FAILURE,
  error,
});

export const deleteMePhoneRuleRequest = (rule) => ({
  type: DELETE_ME_PHONERULE_REQUEST,
  rule,
});
export const deleteMePhoneRuleSuccess = (params) => ({
  type: DELETE_ME_PHONERULE_SUCCESS,
  params,
});
export const deleteMePhoneRuleFailure = (params) => ({
  type: DELETE_ME_PHONERULE_FAILURE,
  params,
});

export const toggleMeEnabledPhoneRuleRequest = (rule) => ({
  type: TOGGLE_ME_ENABLED_PHONERULE_REQUEST,
  rule,
});
export const toggleMeEnabledPhoneRuleSuccess = (priority) => ({
  type: TOGGLE_ME_ENABLED_PHONERULE_SUCCESS,
  priority,
});
export const toggleMeEnabledPhoneRuleFailure = (error) => ({
  type: TOGGLE_ME_ENABLED_PHONERULE_FAILURE,
  error,
});

export const saveMePhoneRuleRequest = (rule, route) => ({
  type: SAVE_ME_PHONERULE_REQUEST,
  rule,
  route,
});
export const saveMePhoneRuleSuccess = (params) => ({
  type: SAVE_ME_PHONERULE_SUCCESS,
  params,
});
export const saveMePhoneRuleFailure = (error) => ({
  type: SAVE_ME_PHONERULE_FAILURE,
  error,
});
export const fetchPbxPhoneRulesRequest = (payload) => ({
  type: FETCH_PBX_PHONERULES_REQUEST,
  payload,
});
export const fetchPbxPhoneRulesSuccess = (rules) => ({
  type: FETCH_PBX_PHONERULES_SUCCESS,
  rules,
});
export const fetchPbxPhoneRulesFailure = (error) => ({
  type: FETCH_PBX_PHONERULES_FAILURE,
  error,
});

export const deletePbxPhoneRuleRequest = (rule) => ({
  type: DELETE_PBX_PHONERULE_REQUEST,
  rule,
});
export const deletePbxPhoneRuleSuccess = (pbxId) => ({
  type: DELETE_PBX_PHONERULE_SUCCESS,
  pbxId,
});
export const deletePbxPhoneRuleFailure = (error) => ({
  type: DELETE_PBX_PHONERULE_FAILURE,
  error,
});

export const savePbxPhoneRuleRequest = (rule) => ({
  type: SAVE_PBX_PHONERULE_REQUEST,
  rule,
});
export const savePbxPhoneRuleSuccess = (pbxId) => ({
  type: SAVE_PBX_PHONERULE_SUCCESS,
  pbxId,
});
export const savePbxPhoneRuleFailure = (error) => ({
  type: SAVE_PBX_PHONERULE_FAILURE,
  error,
});
export const togglePbxEnabledPhoneRuleRequest = (rule) => ({
  type: TOGGLE_PBX_ENABLED_PHONERULE_REQUEST,
  rule,
});
export const togglePbxEnabledPhoneRuleSuccess = (priority) => ({
  type: TOGGLE_PBX_ENABLED_PHONERULE_SUCCESS,
  priority,
});
export const togglePbxEnabledPhoneRuleFailure = (pbxId) => ({
  type: TOGGLE_PBX_ENABLED_PHONERULE_FAILURE,
  pbxId,
});
export const setPbxSelected = (pbxId) => ({
  type: SET_PBX_SELECTED,
  pbxId,
});
export const saveUserPhoneRuleRequest = (rule) => ({
  type: SAVE_USER_PHONERULE_REQUEST,
  rule,
});
export const saveUserPhoneRuleSuccess = (params) => ({
  type: SAVE_USER_PHONERULE_SUCCESS,
  params,
});
export const saveUserPhoneRuleFailure = (error) => ({
  type: SAVE_USER_PHONERULE_FAILURE,
  error,
});
export const setPbxPhoneRulesFilter = (filter) => ({
  type: SET_PBX_PHONERULES_FILTER,
  filter,
});
