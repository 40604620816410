import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import {
  fetchVideocallsListSuccess,
  fetchVideocallsListFailure,
  sendGuestEmailAddressSuccess,
  sendGuestEmailAddressFailure,
  fetchScheduledVideocallsSuccess,
  enterVideocallSuccess,
  enterVideocallFailure,
  checkVideocallAuthorizationSuccess,
  deleteScheduledVideocallSuccess,
  deleteScheduledVideocallFailure,
  saveScheduledVideocallSuccess,
  saveScheduledVideocallFailure,
  fetchScheduledVideocallsFailure,
  checkVideocallAuthorizationFailure,
  inviteVideocallSuccess,
  inviteVideocallFailure,
  fetchVideocallSettingsSuccess,
  fetchVideocallSettingsFailure,
  saveVideocallSettingsSuccess,
  saveVideocallSettingsFailure,
  fetchScheduledVideocallsRequest,
  saveVideocallChatSuccess,
  saveVideocallChatFailure,
  startUserVideocallFailure,
  enterVideocallRequest,
  deleteScheduledVideocallRequest,
  fetchVideocallInvitationsSuccess,
  fetchVideocallInvitationsFailure,
} from './actions';
import {
  FETCH_VIDEOCALLS_LIST_REQUEST,
  SEND_GUEST_EMAIL_ADDRESS_REQUEST,
  SAVE_SCHEDULED_VIDEOCALL_REQUEST,
  FETCH_SCHEDULED_VIDEOCALLS_REQUEST,
  ENTER_VIDEOCALL_REQUEST,
  CHECK_VIDEOCALL_AUTHORIZATION_REQUEST,
  DELETE_SCHEDULED_VIDEOCALL_REQUEST,
  INVITE_VIDEOCALL_REQUEST,
  FETCH_VIDEOCALL_SETTINGS_REQUEST,
  SAVE_VIDEOCALL_SETTINGS_REQUEST,
  VIDEOCALL_REMINDER_NOTIFICATION,
  SAVE_VIDEOCALL_CHAT_REQUEST,
  START_USER_VIDEOCALL_REQUEST,
  INCOMING_VIDEOCALL,
  ANSWER_VIDEOCALL_REQUEST,
  ANSWERED_VIDEOCALL,
  DECLINE_VIDEOCALL_REQUEST,
  SET_DND_STATUS_REQUEST,
  DECLINED_VIDEOCALL,
  START_USER_VIDEOCALL_FAILURE,
  DECLINE_VIDEOCALL_SUCCESS,
  DECLINE_VIDEOCALL_CC,
  FETCH_VIDEOCALL_INVITATIONS_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { getCurrentVideocallRoom, getOutgoingVideocall } from './selectors';
import history from '../../history';
import { getMeAlias, getMeId } from '../me/selectors';
import { generateAckUid } from '../websocket/WsUtils';
import ynConf from '../../conf';
import Utils from '../lib/utils';
import moment from 'moment';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import { openChatConversation, sendChatMessageRequest } from '../chat/actions';
import { generateConversationId } from '../chat/ChatUtils';
import VideocallsManager from './VideocallsManager';
import { isVideocallsNotificationEnabled } from '../settings/selectors';
import { getUserById } from '../users/selectors';
import { AVATAR_TYPES } from '../files/FileUtils';
import {
  addLostVideocallNotification,
  removeIncomingVideocallNotification,
} from '../notifications/actions';

const intlStrings = defineMessages({
  incomingVideocall: {
    id: 'incomingVideocall',
    defaultMessage: 'Incoming videocall from {user}',
  },
  lostVideocall: {
    id: 'lostVideocall',
    defaultMessage: 'Lost videocall from {user}',
  },
  videocallInviteMessage: {
    id: 'videocallInviteMessage',
    defaultMessage: 'This is the videocall room:  ',
  },
  videocallReminder: {
    id: 'videocallReminder',
    defaultMessage: 'Videocall "{title}"',
  },
});

function* fetchVideocallsList(action) {
  try {
    const res = yield call(api.videocalls.getVideocallsList, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchVideocallsListSuccess([]));
    } else {
      yield put(fetchVideocallsListSuccess(res.data.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVideocallsListFailure(error));
  }
}

function* sendGuestEmailAddress(action) {
  try {
    const res = yield call(api.videocalls.sendGuestEmailAddress, {
      email: action.email,
    });
    yield call(checkApiResponse, res);
    yield put(sendGuestEmailAddressSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(sendGuestEmailAddressFailure(error));
  }
}

function* saveScheduledVideocall(action) {
  try {
    const res = yield call(
      action.payload.room
        ? api.videocalls.editScheduledVideocall
        : api.videocalls.createScheduledVideocall,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(saveScheduledVideocallSuccess());
    if (action.byButton) {
      try {
        const userId = yield select(getMeId);
        const userAlias = yield select(getMeAlias);
        const resEnter = yield call(api.videocalls.enterVideocall, {
          room: res.data.room,
          guestName: userAlias,
          guestUid: `U${userId}`,
        });
        const ackUid = generateAckUid(userId);
        const intl = yield call(Utils.retrieveIntl);
        const arrangeMessage = {
          text: `${intl.formatMessage(intlStrings.videocallInviteMessage)} ${
            ynConf.clientBaseUrl
          }/videocall/exec/${ynConf.jitsi.appId}/${res.data.room}`,
        };
        yield call(checkApiResponse, res);
        yield put(
          enterVideocallSuccess({
            ...resEnter.data,
            room: res.data.room,
          })
        );
        history.push('/videocall/exec');
        const data = {
          ackUid,
          recipient: {
            type: action.byButton.user ? 'USER' : 'GROUP',
            id: action.byButton.user || action.byButton.group,
          },
          message: arrangeMessage,
        };
        yield put(sendChatMessageRequest(data));
        yield put(
          openChatConversation({
            conversationId: generateConversationId({
              userId: action.byButton.user,
              groupId: action.byButton.group,
            }),
          })
        );
      } catch (err) {
        const error = yield call(checkApiError, err);
        if (error) yield put(enterVideocallFailure(error));
      }
    } else {
      if (action.payload.room) {
        yield put(fetchScheduledVideocallsRequest());
      } else {
        history.push('/videocall/scheduled');
      }
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveScheduledVideocallFailure(error));
  }
}

function* fetchScheduledVideocallsList() {
  try {
    const res = yield call(api.videocalls.getScheduledVideocallsList);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchScheduledVideocallsSuccess([]));
    } else {
      yield put(fetchScheduledVideocallsSuccess(res.data.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchScheduledVideocallsFailure(error));
  }
}

function* enterVideocall(action) {
  try {
    const currentVideocall = yield select(getCurrentVideocallRoom);
    if (currentVideocall) {
      history.push('/videocall/exec');
      return;
    }
    const res = yield call(api.videocalls.enterVideocall, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      enterVideocallSuccess({
        ...action.payload,
        ...res.data,
      })
    );
    if (!action.payload.guest) {
      history.push('/videocall/exec');
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(enterVideocallFailure(error));
  }
}

function* checkVideocall(action) {
  try {
    const res = yield call(api.videocalls.checkVideocall, action.room);
    yield call(checkApiResponse, res);
    yield put(checkVideocallAuthorizationSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(checkVideocallAuthorizationFailure(error));
  }
}

export function* deleteScheduledVideocall(action) {
  try {
    const res = yield call(api.videocalls.deleteScheduledVideocall, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteScheduledVideocallSuccess(action.id));
    if (history.location.pathname === '/videocall/scheduled') {
      yield put(fetchScheduledVideocallsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteScheduledVideocallFailure(error));
  }
}

export function* inviteVideocall(action) {
  try {
    const res = yield call(api.videocalls.inviteVideocall, action.payload);
    yield call(checkApiResponse, res);
    yield put(inviteVideocallSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(inviteVideocallFailure(error));
  }
}

function* fetchVideocallSettings() {
  try {
    const res = yield call(api.videocalls.getVideocallSettings);
    yield call(checkApiResponse, res);
    yield put(fetchVideocallSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVideocallSettingsFailure(error));
  }
}

function* saveVideocallSettings(action) {
  try {
    const res = yield call(api.videocalls.saveVideocallSettings, {
      fileboxRecordingFolder: action.payload.fileboxRecordingFolder,
      fileboxChatFolder: action.payload.fileboxChatFolder,
    });
    yield call(checkApiResponse, res);
    yield put(saveVideocallSettingsSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveVideocallSettingsFailure(error));
  }
}

export function* sendReminder(action) {
  const intl = yield call(Utils.retrieveIntl);
  const videocallRoom = yield select(getCurrentVideocallRoom);
  if (
    !action.payload.archived &&
    moment(action.payload.end).isSameOrAfter(moment()) &&
    (!videocallRoom || videocallRoom !== action.payload.room)
  ) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.VIDEOCALL_REMINDER}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.videocallReminder, {
        title: action.payload.name,
      }),
      group: NotificationEnums.NotificationGroups.VIDEOCALL_REMINDER,
      image: NotificationEnums.NotificationImages.VIDEOCALL_REMINDER,
      onclick: () => {
        history.push('/videocall/scheduled');
      },
    });
  }
}

function* saveVideocallChat(action) {
  try {
    const res = yield call(api.videocalls.saveVideocallChat, action.room);
    yield call(checkApiResponse, res);
    yield put(saveVideocallChatSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveVideocallChatFailure(error));
  }
}

function* startUserVideocall(action) {
  let room = action.payload.room;
  try {
    if (!action.payload.room) {
      const res = yield call(
        api.videocalls.createScheduledVideocall,
        action.payload.videocallData
      );
      yield call(checkApiResponse, res);
      yield put(saveScheduledVideocallSuccess());
      room = res.data.room;
    }
    VideocallsManager.startVideocall({
      room: room,
      called: action.payload.user,
    });
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        startUserVideocallFailure({
          ...action.payload,
          error,
        })
      );
  }
}

function* manageIncomingVideocall(action) {
  const intl = yield call(Utils.retrieveIntl);
  const videocallsNotificationEnabled = yield select(
    isVideocallsNotificationEnabled
  );
  if (videocallsNotificationEnabled) {
    const caller = action.data.caller;
    if (!caller) {
      return;
    }
    const callerUser = yield select(getUserById, caller);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.INCOMING_VIDEOCALLS}-${action.data.room}`,
      body: intl.formatMessage(intlStrings.incomingVideocall, {
        user: callerUser.departmentFullname,
      }),
      group: NotificationEnums.NotificationGroups.INCOMING_VIDEOCALLS,
      image: Utils.getAvatarUrl(AVATAR_TYPES.USER, callerUser.avatar, 'sm'),
      onclick: () => {
        history.push('/videocall/exec');
      },
    });
  }
}

function* manageAnsweredVideocall(action) {
  const outgoingVideocall = yield select(getOutgoingVideocall);
  if (outgoingVideocall) {
    const myId = yield select(getMeId);
    const myAlias = yield select(getMeAlias);
    yield put(
      enterVideocallRequest({
        room: action.payload.room,
        guestName: myAlias,
        guestUid: `U${myId}`,
      })
    );
    DesktopNotificationManager.hideNotification(
      `${NotificationEnums.NotificationGroups.LOST_VIDEOCALLS}-${action.payload.room}`
    );
    yield put(removeIncomingVideocallNotification(action.payload.room));
  }
}

function* manageFailedVideocallToUser(room) {
  if (!room) {
    return;
  }
  const currentVideocall = yield select(getCurrentVideocallRoom);
  if (!currentVideocall || currentVideocall !== room) {
    yield put(deleteScheduledVideocallRequest(room));
  }
}

function* manageDeclineVideocall(action) {
  yield call(() => manageFailedVideocallToUser(action.payload.room));
  const myId = yield select(getMeId);
  if (action.payload.caller && myId !== action.payload.caller) {
    const callerUser = yield select(getUserById, action.payload.caller);
    DesktopNotificationManager.hideNotification(
      `${NotificationEnums.NotificationGroups.INCOMING_VIDEOCALLS}-${action.payload.room}`
    );
    yield put(removeIncomingVideocallNotification(action.payload.room));
    yield put(
      addLostVideocallNotification({
        notificationId: action.payload.room,
        caller: callerUser.departmentFullname,
      })
    );
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.LOST_VIDEOCALLS}-${action.payload.room}`,
      body: intl.formatMessage(intlStrings.lostVideocall, {
        user: callerUser.departmentFullname,
      }),
      group: NotificationEnums.NotificationGroups.LOST_VIDEOCALLS,
      image: Utils.getAvatarUrl(AVATAR_TYPES.USER, callerUser.avatar, 'sm'),
      onclick: () => {
        history.push('/videocall/exec');
      },
    });
  }
}

export function* fetchVideocallInvitations(action) {
  try {
    const res = yield call(api.videocalls.getVideocallInvitations, action.id);
    yield call(checkApiResponse, res);
    yield put(
      fetchVideocallInvitationsSuccess({
        items: res.data,
        id: action.id,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVideocallInvitationsFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_VIDEOCALLS_LIST_REQUEST, fetchVideocallsList);
  yield takeLatest(SEND_GUEST_EMAIL_ADDRESS_REQUEST, sendGuestEmailAddress);
  yield takeLatest(SAVE_SCHEDULED_VIDEOCALL_REQUEST, saveScheduledVideocall);
  yield takeLatest(
    FETCH_SCHEDULED_VIDEOCALLS_REQUEST,
    fetchScheduledVideocallsList
  );
  yield takeLatest(ENTER_VIDEOCALL_REQUEST, enterVideocall);
  yield takeLatest(CHECK_VIDEOCALL_AUTHORIZATION_REQUEST, checkVideocall);
  yield takeLatest(
    DELETE_SCHEDULED_VIDEOCALL_REQUEST,
    deleteScheduledVideocall
  );
  yield takeLatest(INVITE_VIDEOCALL_REQUEST, inviteVideocall);
  yield takeLatest(FETCH_VIDEOCALL_SETTINGS_REQUEST, fetchVideocallSettings);
  yield takeLatest(SAVE_VIDEOCALL_SETTINGS_REQUEST, saveVideocallSettings);
  yield takeEvery(VIDEOCALL_REMINDER_NOTIFICATION, sendReminder);
  yield takeLatest(SAVE_VIDEOCALL_CHAT_REQUEST, saveVideocallChat);
  yield takeLatest(START_USER_VIDEOCALL_REQUEST, startUserVideocall);
  yield takeLatest(INCOMING_VIDEOCALL, manageIncomingVideocall);
  yield takeLatest(ANSWER_VIDEOCALL_REQUEST, (action) => {
    VideocallsManager.answerVideocall(action.payload);
  });
  yield takeLatest(ANSWERED_VIDEOCALL, manageAnsweredVideocall);
  yield takeLatest(DECLINE_VIDEOCALL_REQUEST, (action) => {
    VideocallsManager.hangupVideocall(action.payload);
  });
  yield takeLatest(SET_DND_STATUS_REQUEST, (action) => {
    VideocallsManager.sendDndStatus(action.enabled);
  });
  yield takeLatest(DECLINED_VIDEOCALL, manageDeclineVideocall);
  yield takeLatest(DECLINE_VIDEOCALL_SUCCESS, manageDeclineVideocall);
  yield takeLatest(DECLINE_VIDEOCALL_CC, manageDeclineVideocall);
  yield takeLatest(START_USER_VIDEOCALL_FAILURE, (action) => {
    if (
      action.payload.error !== 'BUSY_RECIPIENT' &&
      action.payload.error !== 'DND_RECIPIENT'
    ) {
      manageFailedVideocallToUser(action.payload.data.room);
    }
  });
  yield takeLatest(
    FETCH_VIDEOCALL_INVITATIONS_REQUEST,
    fetchVideocallInvitations
  );
}
