import React from 'react';
import Tickets from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const TicketsSettings = React.lazy(() => import('./TicketsSettings'));
const TicketsSettingsPage = () => {
  return (
    <Tickets>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="settings-tickets" />
            </div>
            <TicketsSettings />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </Tickets>
  );
};

export default TicketsSettingsPage;
