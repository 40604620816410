const defaultObject = {};
const defaultList = [];

export const getFaxListFilter = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].filters;
export const getFaxById = (state, type, id) =>
  state.fax.fetchList[type.toLowerCase()].byId[id] || defaultObject;
export const getFaxesById = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].byId;
const getFaxesIds = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].allIds;
export const getFaxListResult = (state, type) =>
  getFaxesIds(state, type).map((key) => getFaxesById(state, type)[key]);
export const getFaxTotal = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].total;
export const getFaxCurrentPage = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].currentPage;
export const isFaxListLoaded = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].fetchLoaded;
export const isFaxListError = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].fetchError;
export const isFaxDetailsLoaded = (state, type) =>
  state.fax.fetchList[type.toLowerCase()] &&
  state.fax.fetchList[type.toLowerCase()].detailsLoaded;
export const isFaxDetailsError = (state, type) =>
  state.fax.fetchList[type.toLowerCase()] &&
  state.fax.fetchList[type.toLowerCase()].detailsError;
export const isFaxSearchInited = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].inited;
export const isFaxActive = (state) =>
  state.me.customer.faxEnabled &&
  (state.me.grants.indexOf('FAX_PUBLIC_ENABLED') > -1 ||
    state.me.grants.indexOf('FAX_PRIVATE_ENABLED') > -1);
export const isFaxSettingsFetchLoaded = (state) =>
  state.fax.fetchSettings.loaded;
export const getFaxSettingsFetchError = (state) =>
  state.fax.fetchSettings.error;
export const isFaxSettingsSaveLoaded = (state) => state.fax.saveSettings.loaded;
export const getFaxSettingsSaveError = (state) => state.fax.saveSettings.error;
export const getFaxNumbers = (state) =>
  state.fax.fetchSettings.allIds
    ? state.fax.fetchSettings.allIds.map(
        (settingId) => state.fax.fetchSettings.byId[settingId].number
      )
    : defaultList;
export const getFaxSettings = (state) =>
  state.fax.fetchSettings.allIds.map(
    (settingId) => state.fax.fetchSettings.byId[settingId]
  );
export const isFaxSending = (state) => !state.fax.send.sent;
export const getFaxSendingData = (state) => state.fax.send.data;
export const getFaxErrorSending = (state) => state.fax.send.error;
export const isFaxRefreshStatusLoaded = (state, faxId) =>
  !state.fax.fetchList.send.refreshStatusLoading ||
  state.fax.fetchList.send.refreshStatusLoading !== faxId;
export const isFaxListChanged = (state, type) =>
  state.fax.fetchList[type.toLowerCase()].faxesChanged;
