import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

class MenuIcon extends Component {
  state = {
    hovered: false,
  };

  mouseEnter = () => {
    if (this.props.hoverable) {
      this.setState({ hovered: true });
    }
  };

  mouseLeave = () => {
    if (this.props.hoverable) {
      this.setState({ hovered: false });
    }
  };

  render() {
    const { name, filled, color, hoverable, children } = this.props;
    const { hovered } = this.state;
    return (
      <Icon
        name={filled ? `${name}-filled` : hovered ? `${name}-filled` : name}
        color={color}
        hoverable={hoverable}
        onMouseEnter={() => this.mouseEnter()}
        onMouseOut={() => this.mouseLeave()}
      >
        {children}
      </Icon>
    );
  }
}

MenuIcon.propTypes = {
  name: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  filled: PropTypes.bool,
};

MenuIcon.defaultProps = {
  hoverable: true,
  filled: false,
};

export default MenuIcon;
