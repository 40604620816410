import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import {
  isFaxSettingsFetchLoaded,
  getFaxSettingsFetchError,
} from '../../../js/fax/selectors';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import { getWebNotificationsFaxNumber } from '../../../js/notifications/selectors';

const messages = defineMessages({
  title: {
    id: 'Fax.title',
    defaultMessage: 'Fax',
  },
  send: {
    id: 'Fax.send.navLabel',
    defaultMessage: 'Send',
  },
  received: {
    id: 'Fax.received.navLabel',
    defaultMessage: 'Received',
  },
  sent: {
    id: 'Fax.sent.navLabel',
    defaultMessage: 'Sent',
  },
  settings: {
    id: 'Fax.settings.navLabel',
    defaultMessage: 'Settings',
  },
  notifications: {
    id: 'Fax.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
});

const Fax = ({
  intl: { formatMessage },
  children,
  loading,
  error,
  scrolling,
  notificationsQuantity,
}) => {
  const nav = [
    {
      id: 'Fax.send.linkLabel',
      href: '/fax/send',
      label: messages.send,
    },
    {
      id: 'Fax.received.linkLabel',
      href: '/fax/received',
      label: messages.received,
    },
    {
      id: 'Fax.sent.linkLabel',
      href: '/fax/sent',
      label: messages.sent,
    },
    {
      id: 'Fax.settings.linkLabel',
      href: '/fax/settings',
      label: messages.settings,
    },
    {
      id: 'Fax.notifications.linkLabel',
      href: '/fax/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    },
  ];
  return (
    <div className="border rounded bg-white h-100">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      {error ? (
        <GlobalErrorPage />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <SecondaryNav tabs={nav} />
          <div
            className={
              scrolling
                ? 'yn-section-with-nav-scrolling'
                : 'yn-section-with-nav'
            }
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: !isFaxSettingsFetchLoaded(state),
    error: getFaxSettingsFetchError(state),
    notificationsQuantity: getWebNotificationsFaxNumber(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Fax));
