export class GroupsEnums {
  static MemberType = {
    User: 'U',
    Abook: 'A',
  };

  static GroupStatus = {
    NORMAL: 'NORMAL',
    MIN: 'MIN',
  };
}

export const GROUP_COLORS = [
  '#FE8989',
  '#FEC73B',
  '#FFF028',
  '#BEF027',
  '#96D2FD',
  '#EAB4FE',
  '#181818',
  '#C5C6C7',
];
