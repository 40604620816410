import { takeLatest, takeEvery, select, put, call } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import moment from 'moment';
import { SEND_SUBSCRIPTION_SUCCESS } from '../websocket/types';
import ChatManager from './ChatManager';
import api from '../api';
import {
  RECEIVE_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_REQUEST,
  SEND_READ_MESSAGE_REQUEST,
  SEND_READ_ALL_MESSAGE_REQUEST,
  SEND_DELETE_MESSAGES_REQUEST,
  SEND_DELETE_ALL_MESSAGES_REQUEST,
  SEND_ARCHIVE_CONVERSATION_REQUEST,
  SEND_UNARCHIVE_CONVERSATION_REQUEST,
  OPEN_CHAT_CONVERSATION,
  SEND_COMPOSING_STATUS,
  SEND_PAUSED_STATUS,
  FETCH_CONVERSATION_FILES_REQUEST,
  CHANGE_CONVERSATION_FILES_PAGE_REQUEST,
  READ_SELF_TIMED_MESSAGE,
  FETCH_CONVERSATION_HISTORY_REQUEST,
  FETCH_CONVERSATIONS_LIST_SUCCESS,
  SEND_DELETE_ALL_MESSAGES_SUCCESS,
  SEND_DELETE_ALL_MESSAGES_CC,
  SEND_READ_ALL_MESSAGE_SUCCESS,
  SEND_READ_ALL_MESSAGE_CC,
  SET_CONVERSATIONS_LIST_FILTER,
  FETCH_OTHER_CHAT_RESULTS_REQUEST,
  OPEN_CHAT_SEARCH_RESULT_REQUEST,
  RECEIVE_READ_MESSAGE_CC,
  SEND_READ_MESSAGE_SUCCESS,
  FETCH_IMPORTANT_MESSAGES_REQUEST,
  MARK_IMPORTANT_MESSAGE_REQUEST,
  MARK_UNIMPORTANT_MESSAGE_REQUEST,
  FETCH_SCHEDULED_MESSAGES_REQUEST,
  SAVE_SCHEDULED_MESSAGE_REQUEST,
  DELETE_SCHEDULED_MESSAGE_REQUEST,
  MUTE_UNMUTE_CHAT_REQUEST,
  GET_MUTED_CHATS_REQUEST,
} from './types';
import {
  isFirstHistoryLoaded,
  getConversationFilesCurrentPage,
  getChatMessageById,
  getConversationsListFilter,
  getOpenConversationsIds,
  getLastMessageByConversation,
  getMutedChats,
} from './selectors';
import {
  fetchConversationHistoryRequest,
  fetchConversationHistorySuccess,
  openChatConversation,
  fetchConversationFilesSuccess,
  fetchConversationFilesFailure,
  removeTimedMessageSuccess,
  fetchConversationHistoryFailure,
  refreshConversationSuccess,
  refreshConversationFailure,
  fetchChatSearchSuccess,
  fetchChatSearchFailure,
  fetchOtherChatResultsSuccess,
  fetchOtherChatResultsFailure,
  fetchImportantMessagesSuccess,
  fetchImportantMessagesFailure,
  fetchScheduledMessagesSuccess,
  fetchScheduledMessagesFailure,
  updateScheduledMessageSuccess,
  updateScheduledMessageFailure,
  deleteScheduledMessageSuccess,
  deleteScheduledMessageFailure,
  muteUnmuteChatSuccess,
  muteUnmuteChatFailure,
  getMutedChatsRequest,
  getMutedChatsSuccess,
  getMutedChatsFailure,
} from './actions';
import {
  isAutoChatOpen,
  isChatNotificationEnabled,
} from '../settings/selectors';
import {
  generateConversationId,
  CONVERSATION_FILES_PAGE_SIZE,
  retrieveInterlocutorIdFromConversationId,
  CHAT_HISTORY_CHUNK,
  MIN_CHARS_FOR_SEARCH,
  CHAT_SEARCH_PAGE_SIZE,
  ChatEnums,
} from './ChatUtils';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import { getGroupById } from '../groups/selectors';
import history from '../../history';
import { getUserById } from '../users/selectors';
import { checkApiResponse, checkApiError } from '../rootSaga';
import Utils from '../lib/utils';
import { UPLOAD_FILES_SUCCESS } from '../files/types';
import { AVATAR_TYPES } from '../files/FileUtils';
import { getMeActiveCallId } from '../me/selectors';
import { getCurrentVideocallRoom } from '../videocalls/selectors';

const intlStrings = defineMessages({
  receivedMessageUser: {
    id: 'receivedMessageUser',
    defaultMessage: 'You have received a message from {user}',
  },
  receivedMessageGroup: {
    id: 'receivedMessageGroup',
    defaultMessage: 'You have received a message in {group}',
  },
  publicGroupName: {
    id: 'publicGroupName',
    defaultMessage: 'All users',
  },
});

function* openConversation(action) {
  const alreadyLoaded = yield select(
    isFirstHistoryLoaded,
    action.payload.conversationId
  );
  if (!alreadyLoaded) {
    yield put(
      fetchConversationHistoryRequest({
        conversationId: action.payload.conversationId,
      })
    );
  }
}

// const messages = {
//   beforeMessage: undefined,
//   conversationId: 'user-13649',
//   searchedMessage: undefined,
//   messages: [
//     {
//       ackUid: '13655_0cd48e00-022a-11ea-be6f-47406f446760',
//       durationTime: true,
//       historyId: '5dcbcc4516ffe75570eed432',
//       interlocutor: {
//         user: {
//           blocked: false,
//           customerId: 214,
//           deleted: false,
//           name: 'Malfor Saja',
//           surname: '',
//           userId: 13649,
//         },
//       },
//       message: {
//         duration: null,
//         source: 'WEB',
//         text: 'forwarded message',
//         isForwarded: true,
//       },
//       sender: {
//         blocked: false,
//         customerId: 214,
//         deleted: false,
//         name: 'Timonela Fani',
//         surname: '',
//         userId: 13655,
//       },
//       timestamp: 1573219021548,
//       type: 'MESSAGE',
//       userId: 13655,
//       replyTo: {
//         senderId: 13655,
//         text: 'test'
//       },
//     },
//     {
//       ackUid: '13655_0cd48e00-022a-11ea-be6f-47406f446760',
//       durationTime: true,
//       historyId: '5dc56acd380de4145d48df7f',
//       interlocutor: {
//         user: {
//           blocked: false,
//           customerId: 214,
//           deleted: false,
//           name: 'Malfor Saja',
//           surname: '',
//           userId: 13649,
//         },
//       },
//       message: {
//         duration: null,
//         source: 'WEB',
//         text: 'forwarded message',
//         forwarded: true,
//       },
//       sender: {
//         blocked: false,
//         customerId: 214,
//         deleted: false,
//         name: 'Timonela Fani',
//         surname: '',
//         userId: 13655,
//       },
//       timestamp: 1573219021548,
//       type: 'MESSAGE',
//       userId: 13655,
//       replyTo: {
//         senderId: 13655,
//         file:{
//           thumbnail: 'https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
//         }
//       },
//     },
//     {
//       ackUid: '13655_2b108790-020f-11ea-99f1-f1aa7641369d',
//       durationTime: true,
//       historyId: '5dc53df5380de4145d48df7b',
//       interlocutor: {
//         user: {
//           blocked: false,
//           customerId: 214,
//           deleted: false,
//           name: 'Malfor Saja',
//           surname: '',
//           userId: 13649,
//         },
//       },
//       message: {
//         duration: null,
//         source: 'WEB',
//         text: 'not forwarded message',
//       },
//       sender: {
//         blocked: false,
//         customerId: 214,
//         deleted: false,
//         name: 'Timonela Fani',
//         surname: '',
//         userId: 13655,
//       },
//       timestamp: 1573219021548,
//       type: 'MESSAGE',
//       userId: 13655,
//       replyTo: {
//         senderId: 13655,
//         file: {
//           size: 141483,
//           name: 'main view.png',
//         },
//       },
//     },
//   ],
// };

function* fetchConversationHistory(action) {
  let beforeTimestamp;
  if (action.payload.beforeMessage) {
    const message = yield select(
      getChatMessageById,
      action.payload.beforeMessage
    );
    beforeTimestamp = message.timestamp;
  }
  try {
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      action.payload.conversationId
    );
    const res = yield call(api.chat.getHistory, {
      userId: interlocutor.userId,
      groupId: interlocutor.groupId,
      before: beforeTimestamp,
      after: action.payload.afterMessage,
      limit: CHAT_HISTORY_CHUNK,
    });
    yield call(checkApiResponse, res);
    const messages = res.data.map((c) => ({
      ...c,
      message: c.message
        ? {
            ...c.message,
            file: c.message.file
              ? { ...c.message.file, thumbnail: null }
              : null,
          }
        : null,
    }));
    yield put(
      fetchConversationHistorySuccess(
        // messages
        {
          conversationId: action.payload.conversationId,
          messages, //: res.data,
          searchedMessage: action.payload.searchedMessage,
          beforeMessage: action.payload.beforeMessage,
          afterMessage: action.payload.searchedMessage
            ? null
            : action.payload.afterMessage,
        }
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(fetchConversationHistoryFailure(action.payload.conversationId));
  }
}

function* refreshConversationsHistory() {
  const openConversations = yield select(getOpenConversationsIds);
  for (let i = 0; i < openConversations.length; i += 1) {
    const conversationId = openConversations[i];
    const message = yield select(getLastMessageByConversation, conversationId);
    try {
      const interlocutor =
        retrieveInterlocutorIdFromConversationId(conversationId);
      const res = yield call(api.chat.getHistory, {
        userId: interlocutor.userId,
        groupId: interlocutor.groupId,
        after: message.timestamp + 1,
      });
      yield call(checkApiResponse, res);
      yield put(
        fetchConversationHistorySuccess({
          conversationId,
          messages: res.data,
          refresh: true,
        })
      );
    } catch (err) {
      const error = yield call(checkApiError, err);
      if (error) yield put(fetchConversationHistoryFailure(conversationId));
    }
  }
}

function* receiveChatMessage(action) {
  const intl = yield call(Utils.retrieveIntl);
  const autoChatOpen = yield select(isAutoChatOpen);
  const conversationId = generateConversationId({
    userId: action.data.interlocutor.user
      ? action.data.interlocutor.user.userId
      : null,
    groupId: action.data.interlocutor.group
      ? action.data.interlocutor.group.groupId
      : null,
  });

  const mutedChats = yield select(getMutedChats);
  const isMuted = mutedChats[conversationId];

  let muteExpired;
  if (isMuted && isMuted.expirationDate) {
    muteExpired = moment().format('x') > isMuted.expirationDate;
    // if (muteExpired) {
    //   yield put(getMutedChatsRequest());
    // }
  }

  if (autoChatOpen && (!isMuted || (isMuted && muteExpired))) {
    yield put(openChatConversation({ conversationId }));
  }

  if (action.data.type !== ChatEnums.ChatTopics.INFO) {
    ChatManager.sendDeliveryMessage(
      action.data.senderHistoryId,
      action.data.historyId
    );
    const chatEnabled = yield select(isChatNotificationEnabled);
    if (chatEnabled) {
      let avatar;
      let notificationMessage;
      if (action.data.interlocutor.user) {
        const user = yield select(
          getUserById,
          action.data.interlocutor.user.userId
        );
        if (user) {
          avatar = Utils.getAvatarUrl(AVATAR_TYPES.USER, user.avatar, 'sm');
          notificationMessage = intl.formatMessage(
            intlStrings.receivedMessageUser,
            { user: user.fullname }
          );
        }
      } else {
        const group = yield select(
          getGroupById,
          action.data.interlocutor.group.groupId
        );
        if (group) {
          avatar = group.main
            ? NotificationEnums.NotificationImages.CHAT_PUBLIC_MESSAGE
            : NotificationEnums.NotificationImages.CHAT_GROUP_MESSAGE;
          notificationMessage = intl.formatMessage(
            intlStrings.receivedMessageGroup,
            {
              group: group.main
                ? intl.formatMessage(intlStrings.publicGroupName)
                : group.name,
            }
          );
        }
      }
      const currentVideocall = yield select(getCurrentVideocallRoom);
      const activeCallId = yield select(getMeActiveCallId);

      if (!isMuted || (isMuted && muteExpired)) {
        DesktopNotificationManager.sendNotification({
          id: `${NotificationEnums.NotificationGroups.RECEIVED_MESSAGE}-${action.data.historyId}`,
          body: notificationMessage,
          group: `${NotificationEnums.NotificationGroups.RECEIVED_MESSAGE}-${conversationId}`,
          image: avatar,
          mute: !!(currentVideocall || activeCallId),
          onclick: () => {
            history.push(`/chat/messages/${conversationId}`);
          },
        });
      }
    }
  }
}

function* readMessage(action) {
  ChatManager.sendReadMessage(
    action.data.senderHistoryId,
    action.data.messageId
  );
  if (action.data.duration) {
    yield Utils.delay(action.data.duration * 1000);
    yield put(removeTimedMessageSuccess({ ids: [action.data.messageId] }));
  }
}

function* fetchFiles(action) {
  try {
    const pageNumber = yield select(getConversationFilesCurrentPage);
    const res = yield call(api.chat.getFiles, {
      userId: action.payload.userId,
      groupId: action.payload.groupId,
      page: pageNumber,
      pageSize: CONVERSATION_FILES_PAGE_SIZE,
    });
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchConversationFilesSuccess({ total: 0, data: [] }));
    } else {
      yield put(fetchConversationFilesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchConversationFilesFailure(err));
  }
}

function* readSelfTimedMessage(action) {
  yield Utils.delay(action.data.duration * 1000);
  yield put(removeTimedMessageSuccess({ ids: [action.data.messageId] }));
}

function* markMessageAsRead(action) {
  yield Utils.delay(1000);
  DesktopNotificationManager.hideNotification(
    `${NotificationEnums.NotificationGroups.RECEIVED_MESSAGE}-${action.data.historyId}`
  );
}

function* refreshConversation(action) {
  DesktopNotificationManager.hideNotificationsForGroup(
    `${
      NotificationEnums.NotificationGroups.RECEIVED_MESSAGE
    }-${generateConversationId(action.data.interlocutor)}`
  );
  try {
    const res = yield call(api.chat.getConversation, {
      userId: action.data.interlocutor.userId,
      groupId: action.data.interlocutor.groupId,
    });
    yield call(checkApiResponse, res);
    yield put(
      refreshConversationSuccess({
        conversationId: generateConversationId(action.data.interlocutor),
        data: res.data,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        refreshConversationFailure(
          generateConversationId(action.data.interlocutor)
        )
      );
  }
}

function* searchText(action) {
  if (
    !action.data.textualSearch ||
    !action.data.filter.text ||
    action.data.filter.text.length < MIN_CHARS_FOR_SEARCH
  ) {
    return;
  }
  try {
    const res = yield call(api.chat.searchText, action.data.filter.text, {
      limit: CHAT_SEARCH_PAGE_SIZE,
      offset: 0,
    });
    yield call(checkApiResponse, res);
    yield put(fetchChatSearchSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchChatSearchFailure());
  }
}

function* fetchOtherSearchResults(action) {
  const chatFilters = yield select(getConversationsListFilter);
  try {
    const res = yield call(api.chat.searchText, chatFilters.text, {
      limit: CHAT_SEARCH_PAGE_SIZE,
      timestamp: action.timestamp,
      offset: 0,
    });
    yield call(checkApiResponse, res);
    yield put(fetchOtherChatResultsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchOtherChatResultsFailure());
  }
}

function* openChatSearchResult(action) {
  yield put(
    fetchConversationHistoryRequest({
      conversationId: action.payload.conversationId,
      afterMessage: action.payload.messageTimestamp,
      searchedMessage: action.payload.messageId,
    })
  );
}

function* sendChatFile(action) {
  if (action.payload.scope === 'chat') {
    const message = yield select(getChatMessageById, action.payload.id);
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      message.conversationId
    );
    return ChatManager.sendChatMessage({
      ackUid: action.payload.id,
      recipient: {
        type: interlocutor.userId ? 'USER' : 'GROUP',
        id: interlocutor.userId || interlocutor.groupId,
      },
      message: {
        file: {
          ...message.file,
          stored: action.payload.data.stored,
        },
        replyTo: message.replyTo,
      },
    });
  }
}
function* fetchImportantMessages(action) {
  try {
    const params = action.payload.userId
      ? { userId: action.payload.userId }
      : { groupId: action.payload.groupId };
    const res = yield call(api.chat.getImportantMessages, params);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchImportantMessagesSuccess([]));
    } else {
      yield put(fetchImportantMessagesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchImportantMessagesFailure(error));
  }
}

export function* fetchScheduledMessages() {
  try {
    const res = yield call(api.chat.getScheduledMessages);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchScheduledMessagesSuccess([]));
    } else {
      yield put(fetchScheduledMessagesSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchScheduledMessagesFailure(error));
  }
}

export function* saveScheduledMessage(action) {
  try {
    const res = yield call(
      action.data.id
        ? api.chat.updateScheduledMessage
        : api.chat.createScheduledMessage,
      action.data
    );
    yield call(checkApiResponse, res);
    const scheduledMessageData = {
      ...action.data,
      id: action.data.id || res.data.id,
    };
    yield put(updateScheduledMessageSuccess(scheduledMessageData));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateScheduledMessageFailure(error));
  }
}

export function* deleteScheduledMessage(action) {
  try {
    const res = yield call(api.chat.deleteScheduledMessage, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteScheduledMessageSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteScheduledMessageFailure(error));
  }
}

export function* fetchMutedChats() {
  try {
    const res = yield call(api.chat.getMutedChats);
    yield call(checkApiResponse, res);
    yield put(getMutedChatsSuccess(res.status === 204 ? [] : res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(getMutedChatsFailure(error));
  }
}

export function* muteUnmuteChat(action) {
  try {
    const res = yield call(api.chat.muteUnmuteChat, action.payload);
    yield call(checkApiResponse, res);
    yield put(muteUnmuteChatSuccess(action.conversationId));
    yield put(getMutedChatsRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(muteUnmuteChatFailure(error, action.conversationId));
  }
}

export default function* rootSaga() {
  yield takeLatest(SEND_SUBSCRIPTION_SUCCESS, () =>
    ChatManager.retrieveConversationsList()
  );
  yield takeEvery(RECEIVE_CHAT_MESSAGE, receiveChatMessage);
  yield takeEvery(SEND_CHAT_MESSAGE_REQUEST, (action) =>
    ChatManager.sendChatMessage(action.data)
  );
  yield takeEvery(SEND_READ_MESSAGE_REQUEST, readMessage);
  yield takeEvery(SEND_READ_ALL_MESSAGE_REQUEST, (action) =>
    ChatManager.sendReadAllMessages(action.interlocutor)
  );
  yield takeEvery(SEND_DELETE_MESSAGES_REQUEST, (action) =>
    ChatManager.sendDeleteMessages(action.payload.messagesIds)
  );
  yield takeEvery(SEND_DELETE_ALL_MESSAGES_REQUEST, (action) =>
    ChatManager.sendDeleteAllMessages(action.interlocutor)
  );
  yield takeEvery(SEND_ARCHIVE_CONVERSATION_REQUEST, (action) =>
    ChatManager.sendArchiveConversation(action.interlocutor)
  );
  yield takeEvery(SEND_UNARCHIVE_CONVERSATION_REQUEST, (action) =>
    ChatManager.sendUnarchiveConversation(action.interlocutor)
  );
  yield takeLatest(OPEN_CHAT_CONVERSATION, openConversation);
  yield takeLatest(SEND_COMPOSING_STATUS, (action) =>
    ChatManager.sendComposingStatus(action.recipient)
  );
  yield takeLatest(SEND_PAUSED_STATUS, (action) =>
    ChatManager.sendPausedStatus(action.recipient)
  );
  yield takeLatest(FETCH_CONVERSATION_FILES_REQUEST, fetchFiles);
  yield takeLatest(CHANGE_CONVERSATION_FILES_PAGE_REQUEST, fetchFiles);
  yield takeEvery(READ_SELF_TIMED_MESSAGE, readSelfTimedMessage);
  yield takeEvery(FETCH_CONVERSATION_HISTORY_REQUEST, fetchConversationHistory);
  yield takeLatest(
    FETCH_CONVERSATIONS_LIST_SUCCESS,
    refreshConversationsHistory
  );
  yield takeLatest(FETCH_CONVERSATIONS_LIST_SUCCESS, () =>
    ChatManager.sendDeliveryMessagesTillTime()
  );
  yield takeEvery(SEND_DELETE_ALL_MESSAGES_SUCCESS, refreshConversation);
  yield takeEvery(SEND_DELETE_ALL_MESSAGES_CC, refreshConversation);
  yield takeEvery(RECEIVE_READ_MESSAGE_CC, markMessageAsRead);
  yield takeEvery(SEND_READ_MESSAGE_SUCCESS, markMessageAsRead);
  yield takeEvery(SEND_READ_ALL_MESSAGE_SUCCESS, refreshConversation);
  yield takeEvery(SEND_READ_ALL_MESSAGE_CC, refreshConversation);
  yield takeLatest(SET_CONVERSATIONS_LIST_FILTER, searchText);
  yield takeLatest(FETCH_OTHER_CHAT_RESULTS_REQUEST, fetchOtherSearchResults);
  yield takeLatest(OPEN_CHAT_SEARCH_RESULT_REQUEST, openChatSearchResult);
  yield takeEvery(UPLOAD_FILES_SUCCESS, sendChatFile);
  yield takeEvery(FETCH_IMPORTANT_MESSAGES_REQUEST, fetchImportantMessages);
  yield takeEvery(MARK_IMPORTANT_MESSAGE_REQUEST, (action) =>
    ChatManager.markImportantMessage(action.payload.messageId)
  );
  yield takeEvery(MARK_UNIMPORTANT_MESSAGE_REQUEST, (action) =>
    ChatManager.markUnImportantMessage(action.payload.messageId)
  );
  yield takeLatest(FETCH_SCHEDULED_MESSAGES_REQUEST, fetchScheduledMessages);
  yield takeLatest(SAVE_SCHEDULED_MESSAGE_REQUEST, saveScheduledMessage);
  yield takeLatest(DELETE_SCHEDULED_MESSAGE_REQUEST, deleteScheduledMessage);
  yield takeLatest(GET_MUTED_CHATS_REQUEST, fetchMutedChats);
  yield takeLatest(MUTE_UNMUTE_CHAT_REQUEST, muteUnmuteChat);
}
