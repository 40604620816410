import {
  /** Fetch Stickies */
  FETCH_STICKY_NOTES_REQUEST,
  FETCH_STICKY_NOTES_SUCCESS,
  FETCH_STICKY_NOTES_FAILURE,
  /** Add Stickies */
  ADD_STICKY_NOTES_REQUEST,
  ADD_STICKY_NOTES_SUCCESS,
  ADD_STICKY_NOTES_FAILURE,
  /** Delete Sticky */
  DELETE_STICKY_NOTE_REQUEST,
  DELETE_STICKY_NOTE_SUCCESS,
  DELETE_STICKY_NOTE_FAILURE,
  /** Update Sticky */
  UPDATE_STICKY_VISIBILITY_REQUEST,
  UPDATE_STICKY_VISIBILITY_SUCCESS,
  UPDATE_STICKY_VISIBILITY_FAILURE,
  /** Modify Sticky */
  UPDATE_STICKY_REQUEST,
  UPDATE_STICKY_SUCCESS,
  UPDATE_STICKY_FAILURE,
  /* Static notes */
  FETCH_STATIC_NOTES_REQUEST,
  FETCH_STATIC_NOTES_SUCCESS,
  FETCH_STATIC_NOTES_FAILURE,
  CREATE_STATIC_NOTE_REQUEST,
  CREATE_STATIC_NOTE_SUCCESS,
  CREATE_STATIC_NOTE_FAILURE,
  UPDATE_STATIC_NOTES_ORDER_REQUEST,
  UPDATE_STATIC_NOTES_ORDER_SUCCESS,
  UPDATE_STATIC_NOTES_ORDER_FAILURE,
  NOTES_CLEAR_ERRORS,
  EDIT_STATIC_NOTE_REQUEST,
  EDIT_STATIC_NOTE_SUCCESS,
  EDIT_STATIC_NOTE_FAILURE,
  REMOVE_STATIC_NOTE_REQUEST,
  REMOVE_STATIC_NOTE_SUCCESS,
  REMOVE_STATIC_NOTE_FAILURE,
  SHARE_STICKY_REQUEST,
  SHARE_STICKY_SUCCESS,
  SHARE_STICKY_FAILURE,
  SHARE_STICKY_NOTIFICATION,
  EDIT_STICKY_NOTIFICATION,
  DELETE_STICKY_NOTIFICATION,
} from './types';

/** Fetch Stickies */

export const fetchStickyNotesRequest = () => ({
  type: FETCH_STICKY_NOTES_REQUEST,
});
export const fetchStickyNotesSuccess = (stickies, success) => ({
  type: FETCH_STICKY_NOTES_SUCCESS,
  stickies,
  success,
});
export const fetchStickyNotesFailure = (errors) => ({
  type: FETCH_STICKY_NOTES_FAILURE,
  errors,
});

/** Add Stickies */
export const addStickyNotesRequest = (params) => ({
  type: ADD_STICKY_NOTES_REQUEST,
  params,
});
export const addStickyNotesSuccess = (sticky, success) => ({
  type: ADD_STICKY_NOTES_SUCCESS,
  sticky,
  success,
});
export const addStickyNotesFailure = (errors) => ({
  type: ADD_STICKY_NOTES_FAILURE,
  errors,
});

/** Delete Sticky */
export const deleteStickyNoteRequest = (id) => ({
  type: DELETE_STICKY_NOTE_REQUEST,
  id,
});
export const deleteStickyNoteSuccess = (success) => ({
  type: DELETE_STICKY_NOTE_SUCCESS,
  success,
});
export const deleteStickyNoteFailure = (errors) => ({
  type: DELETE_STICKY_NOTE_FAILURE,
  errors,
});

/** Update Sticky */
export const updateStickyVisibilityRequest = (stickyShow) => ({
  type: UPDATE_STICKY_VISIBILITY_REQUEST,
  stickyShow,
});
export const updateStickyVisibilitySuccess = (stickyShow, success) => ({
  type: UPDATE_STICKY_VISIBILITY_SUCCESS,
  stickyShow,
  success,
});
export const updateStickyVisibilityFailure = (errors) => ({
  type: UPDATE_STICKY_VISIBILITY_FAILURE,
  errors,
});

/** Modify Sticky */
export const updateStickyRequest = (id, sticky) => ({
  type: UPDATE_STICKY_REQUEST,
  id,
  sticky,
});
export const updateStickySuccess = (success) => ({
  type: UPDATE_STICKY_SUCCESS,
  success,
});
export const updateStickyFailure = (errors) => ({
  type: UPDATE_STICKY_FAILURE,
  errors,
});

/** Share Sticky */
export const shareStickyRequest = (id, users) => ({
  type: SHARE_STICKY_REQUEST,
  id,
  users,
});
export const shareStickySuccess = () => ({
  type: SHARE_STICKY_SUCCESS,
});
export const shareStickyFailure = (error) => ({
  type: SHARE_STICKY_FAILURE,
  error,
});

export const shareStickyNotification = (payload) => ({
  type: SHARE_STICKY_NOTIFICATION,
  payload,
});
export const editStickyNotification = (payload) => ({
  type: EDIT_STICKY_NOTIFICATION,
  payload,
});
export const deleteStickyNotification = (payload) => ({
  type: DELETE_STICKY_NOTIFICATION,
  payload,
});

/* Fetch Static Notes */
export const fetchStaticNotesRequest = () => ({
  type: FETCH_STATIC_NOTES_REQUEST,
});
export const fetchStaticNotesSuccess = (staticNotes) => ({
  type: FETCH_STATIC_NOTES_SUCCESS,
  staticNotes,
});
export const fetchStaticNotesFailure = (errors) => ({
  type: FETCH_STATIC_NOTES_FAILURE,
  errors,
});
export const createStaticNoteRequest = (payload) => ({
  type: CREATE_STATIC_NOTE_REQUEST,
  payload,
});

export const createStaticNoteSuccess = (data) => ({
  type: CREATE_STATIC_NOTE_SUCCESS,
  data,
});
export const createStaticNoteFailure = (errors) => ({
  type: CREATE_STATIC_NOTE_FAILURE,
  errors,
});

export const updateStaticNotesOrderRequest = (id, data, newOrderedNotes) => {
  return {
    type: UPDATE_STATIC_NOTES_ORDER_REQUEST,
    id,
    data,
    newOrderedNotes,
  };
};
export const updateStaticNotesOrderSuccess = () => ({
  type: UPDATE_STATIC_NOTES_ORDER_SUCCESS,
});
export const updateStaticNotesOrderFailure = (errors) => ({
  type: UPDATE_STATIC_NOTES_ORDER_FAILURE,
  errors,
});
export const clearNotesErrors = () => ({
  type: NOTES_CLEAR_ERRORS,
});
export const editStaticNoteRequest = (payload) => {
  return {
    type: EDIT_STATIC_NOTE_REQUEST,
    payload,
  };
};
export const editStaticNoteSuccess = () => ({
  type: EDIT_STATIC_NOTE_SUCCESS,
});
export const editStaticNoteFailure = (errors) => ({
  type: EDIT_STATIC_NOTE_FAILURE,
  errors,
});
export const removeStaticNoteRequest = (id) => ({
  type: REMOVE_STATIC_NOTE_REQUEST,
  id,
});
export const removeStaticNoteSuccess = (id) => ({
  type: REMOVE_STATIC_NOTE_SUCCESS,
  id,
});
export const removeStaticNoteFailure = (errors) => ({
  type: REMOVE_STATIC_NOTE_FAILURE,
  errors,
});
