import React from 'react';
import HelpButton from './HelpButton';

const style = {
  color: 'var(--yn-blue-darker)',
  fontSize: '1.5em',
  fontWeight: 700,
  height: 'var(--yn-section-title-height)',
  padding: '0.2em 0.7em 0 0.7em',

  //borderBottom: '1px solid grey'
};

const SectionTitle = (props) => (
  <div style={{ ...style, display: 'flex' }}>
    <div
      style={{
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      {props.children}
    </div>
    {props.helpButton && (
      <HelpButton
        fileName={props.helpButton.fileName}
        style={props.helpButton.style}
      />
    )}
  </div>
);

export default SectionTitle;
