import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import FormFieldError from './messages/FormFieldError';

const customStyles = (custom) => {
  return {
    container: (base) => ({
      ...base,
      height: 'auto',
      ...custom.container,
    }),
    valueContainer: (base) => ({
      ...base,
      flex: 1,
      overflow: 'auto',
      paddingTop: '3px',
      paddingBottom: '3px',
      maxHeight: '200px',
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: 'var(--white)',
      border: 'none',
      minHeight: 'auto',
      webkitBoxShadow: state.isFocused
        ? 'var(--yn-input-focus-box-shadow)'
        : 'transparent',
      boxShadow: state.isFocused
        ? 'var(--yn-input-focus-box-shadow)'
        : 'transparent',
      ...custom.control,
    }),
    option: (base) => ({
      ...base,
      ...custom.option,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0.45em',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '0.45em',
    }),
    multiValue: (base, { data }) => {
      const backgroundColor = data.isWrong ? '#FFBDAD' : base.backgroundColor;
      return {
        ...base,
        ...custom.multiValue,
        backgroundColor,
      };
    },
    multiValueLabel: (base, { data }) => {
      const color = data.isWrong ? '#DE350B' : base.color;
      return {
        ...base,
        ...custom.multiValueLabel,
        paddingRight: data.isWrong ? '6px' : 'inherit',
        color,
      };
    },
    multiValueRemove: (base, { data }) => {
      return {
        ...base,
        display: data.isWrong ? 'none' : 'block',
      };
    },
    singleValue: (base) => ({
      ...base,
      ...custom.singleValue,
      color: 'var(--yn-blue-darker)',
    }),
    placeholder: (base) => ({
      ...base,
      ...custom.multiValue,
      color: 'var(--yn-gray-600)',
    }),
  };
};

const Menu = (props) => {
  if (props.options.length === 0) return null;
  return <components.Menu {...props} />;
};

class TagsInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      tags: undefined,
      wrongTag: null,
    };
  }

  handleChange = (tags) => {
    this.setState({ tags }, () => {
      this.onChange();
    });
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        this.handleNewInput();
        event.preventDefault();
        break;
      }
      default:
    }
  };

  handleNewInput = () => {
    const pattern = this.arrangePattern();
    const { inputValue } = this.state;
    if (!inputValue) return;
    if (!pattern || (pattern && inputValue.search(pattern) === 0)) {
      const newTag = {
        label: inputValue,
        value: inputValue,
      };
      this.setState(
        {
          inputValue: '',
          tags: [...this.arrangeTags(), newTag],
        },
        () => {
          this.onChange();
        }
      );
    } else if (pattern && inputValue.search(pattern) < 0) {
      this.setState({
        inputValue: '',
        wrongTag: { value: inputValue, label: inputValue, isWrong: true },
      });
      setTimeout(() => {
        this.setState({
          wrongTag: null,
        });
      }, 150);
    } else {
      this.setState({
        inputValue: '',
      });
    }
  };

  handleBlur = () => {
    this.handleNewInput();
  };

  arrangeTags = () => {
    const { tags } = this.state;
    const { value } = this.props;
    if (tags === null && value === '') return [];
    if (tags !== undefined && (!value || value === '')) return tags;
    if (value && value !== '')
      return value.split(',').map((v) => ({ value: v, label: v }));
    return [];
  };

  onChange = () => {
    const { tags } = this.state;
    const { value, onChange } = this.props;
    if (tags !== undefined) {
      if (tags === null || tags === '') {
        return onChange('');
      }
      if (tags.length > 0) {
        return onChange(tags.map((e) => e.value).join(','));
      }
      return onChange('');
    }
    return onChange(value);
  };

  arrangePattern = () => {
    const { pattern } = this.props;
    switch (pattern) {
      case 'email':
        // email address validation (https://stackoverflow.com/a/46181/104380)
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      case 'phoneNumber':
        return /^\+*[\s0-9#*]+$/;
      default:
        return null;
    }
  };

  render() {
    const {
      error,
      className,
      style,
      showSuggestions,
      suggestions,
      placeholder,
      // intl: { formatMessage },
    } = this.props;
    const { inputValue, wrongTag } = this.state;
    const classNames = classnames('form-control p-0', className, {
      'is-invalid': error,
    });

    return (
      <>
        <Select
          styles={customStyles(style || {})}
          className={classNames}
          components={{
            DropdownIndicator: null,
            Menu,
          }}
          inputValue={inputValue}
          isClearable
          isMulti
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          value={[...this.arrangeTags(), wrongTag]}
          formatCreateLabel={() => null}
          options={showSuggestions ? suggestions : []}
          noOptionsMessage={() => null}
        />
        {error && <FormFieldError text={error} />}
      </>
    );
  }
}

TagsInput.propTypes = {
  value: PropTypes.string, // comma separated values
  pattern: PropTypes.oneOf(['email', 'phoneNumber']),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  showSuggestions: PropTypes.bool,
};

TagsInput.defaultProps = {
  value: '',
  pattern: null,
  placeholder: null,
  suggestions: [],
  showSuggestions: false,
};

export default TagsInput;
