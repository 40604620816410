import { request } from '../request';

export default {
  getFileboxSpaceUsage: () =>
    request({
      url: '/customer/filebox',
      method: 'GET',
    }),
  getFileboxItems: (params) =>
    request({
      url: '/filebox',
      method: 'GET',
      params,
    }),
  deleteFileboxFile: (id) =>
    request({
      url: `/filebox/files/${id}`,
      method: 'DELETE',
    }),
  createFileboxFolder: (data) =>
    request({
      url: '/filebox/folders',
      method: 'POST',
      data,
    }),
  deleteFileboxFolder: (id) =>
    request({
      url: `/filebox/folders/${id}`,
      method: 'DELETE',
    }),
  renameFileboxFile: (data) =>
    request({
      url: `/filebox/files/${data.id}`,
      method: 'POST',
      data: data.data,
    }),
  editFileboxFolder: (data) =>
    request({
      url: `/filebox/folders/${data.id}`,
      method: 'POST',
      data: data.data,
    }),
  enableDisableFileboxFileExternalLink: (data) =>
    request({
      url: `/filebox/files/${data.id}/publish`,
      method: 'POST',
      data: data.data,
    }),
  getFileboxFolderDetails: (id) =>
    request({
      url: `/filebox/folders/${id}`,
      method: 'GET',
    }),
  toggleLockingFile: (data) =>
    request({
      url: `/filebox/files/${data.id}/block`,
      method: 'POST',
      data: data.data,
    }),
  getFileboxFavoriteFiles: () =>
    request({
      url: '/filebox/favorites',
      method: 'GET',
    }),
  moveFileboxFile: (data) =>
    request({
      url: `/filebox/files/${data.id}/move`,
      method: 'POST',
      data: data.data,
    }),
  moveFileboxFolder: (data) =>
    request({
      url: `/filebox/folders/${data.id}/move`,
      method: 'POST',
      data: data.data,
    }),
  copyFileboxFile: (data) =>
    request({
      url: `/filebox/files/${data.id}/copy`,
      method: 'POST',
      data: data.data,
    }),
  copyFileboxFolder: (data) =>
    request({
      url: `/filebox/folders/${data.id}/copy`,
      method: 'POST',
      data: data.data,
    }),
  searchFileboxFilesFolders: (params) =>
    request({
      url: '/filebox/search',
      method: 'GET',
      params,
    }),
  addFileboxFavoriteFile: (data) =>
    request({
      url: '/filebox/favorites',
      method: 'POST',
      data,
    }),
  removeFileboxFavoriteFile: (id) =>
    request({
      url: `/filebox/favorites/${id}`,
      method: 'DELETE',
    }),
  updateFileboxSpace: (data) =>
    request({
      url: `/filebox/purchase-space`,
      method: 'POST',
      data,
    }),
  deleteFileboxMassiveFiles: (ids) =>
    request({
      url: `/filebox/delete-files`,
      method: 'POST',
      data: { ids },
    }),
  getFileComments: (id) =>
    request({
      url: `/filebox/files/${id}/comments`,
      method: 'GET',
    }),
  addFileComment: (data) =>
    request({
      url: `/filebox/files/${data.id}/comments`,
      method: 'POST',
      data: data.data,
    }),
  deleteFileComment: (data) =>
    request({
      url: `/filebox/files/${data.fileId}/comments/${data.commentId}`,
      method: 'DELETE',
    }),
  getFileboxPreviousVersionFiles: (id) =>
    request({
      url: `/filebox/files/${id}/versions`,
      method: 'GET',
    }),
  removeFileboxOldFileVersion: (fileId, id) =>
    request({
      url: `/filebox/files/${fileId}/versions/${id}`,
      method: 'DELETE',
    }),
  getFileboxDeletedItems: (params) =>
    request({
      url: '/filebox/recycle-bin',
      method: 'GET',
      params,
    }),
  removePermanentlyFileboxDeletedItem: (id) =>
    request({
      url: `/filebox/recycle-bin/${id}`,
      method: 'DELETE',
    }),
  restoreFileboxDeletedItem: (id) =>
    request({
      url: `/filebox/recycle-bin/${id}/restore`,
      method: 'POST',
    }),
  getFileboxFileEmailRecipientsDetails: (id) =>
    request({
      url: `/filebox/files/${id}/recipients`,
      method: 'GET',
    }),
  shareFileboxFile: (data) =>
    request({
      url: `/filebox/files/${data.id}/share`,
      method: 'POST',
      data: data.data,
    }),
};
