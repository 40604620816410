import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const CallsLogFilter = React.lazy(() =>
  import('../../../components/CallsLogFilter')
);
const MeCallsLogList = React.lazy(() => import('./MeCallsLogList'));

const messages = defineMessages({
  title: {
    id: 'CallsLog.title',
    defaultMessage: 'Calls log',
  },
  lost: {
    id: 'CallsLog.lost.navLabel',
    defaultMessage: 'Lost',
  },
  received: {
    id: 'CallsLog.received.navLabel',
    defaultMessage: 'Received',
  },
  executed: {
    id: 'CallsLog.executed.navLabel',
    defaultMessage: 'Executed',
  },
});

const nav = [
  {
    id: 'CallsLog.lost.linkLabel',
    href: '/phone/log/lost',
    label: messages.lost,
  },
  {
    id: 'CallsLog.received.linkLabel',
    href: '/phone/log/in',
    label: messages.received,
  },
  {
    id: 'CallsLog.executed.linkLabel',
    href: '/phone/log/out',
    label: messages.executed,
  },
];

class MeCallsLogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      intl: { formatMessage },
      history: {
        location: { pathname },
      },
    } = this.props;

    let context = null;
    if (pathname.endsWith('/in')) {
      context = 'in';
    } else if (pathname.endsWith('/out')) {
      context = 'out';
    } else if (pathname.endsWith('/lost')) {
      context = 'lost';
    }

    return (
      <div className="border rounded bg-white h-100">
        <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
        <SecondaryNav tabs={nav} />
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <>
              <div className="yn-section-with-nav-scrolling">
                <div className="text-right">
                  <HelpButton
                    fileName={
                      context === 'in'
                        ? 'log-in'
                        : context === 'out'
                        ? 'log-out'
                        : 'log-lost'
                    }
                  />
                </div>
                <CallsLogFilter context={context} />
                <MeCallsLogList context={context} />
              </div>
            </>
          </React.Suspense>
        </ErrorBoundary>
      </div>
    );
  }
}

export default injectIntl(MeCallsLogPage);
