import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Fade } from 'reactstrap';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { getWebNotificationsTicketNumber } from '../../../js/notifications/selectors';
import { isTicketingEnabled, hasGrants } from '../../../js/me/selectors';

const messages = defineMessages({
  title: {
    id: 'Tickets.title',
    defaultMessage: 'Tickets',
  },
  ticketList: {
    id: 'Tickets.ticketList.label',
    defaultMessage: 'Ticket list',
  },
  ticketFields: {
    id: 'Tickets.ticketFields.label',
    defaultMessage: 'Ticket fields',
  },
  notifications: {
    id: 'Tickets.notifications.label',
    defaultMessage: 'Notifications',
  },
  customizedStatus: {
    id: 'Tickets.customizedStatus.label',
    defaultMessage: 'Customized status',
  },
  ticketCategories: {
    id: 'Tickets.ticketCategories.label',
    defaultMessage: 'Ticket categories',
  },
  ticketSettings: {
    id: 'Tickets.ticketSettings.label',
    defaultMessage: 'Settings',
  },
  dashboard: {
    id: 'Tickets.dashboard.label',
    defaultMessage: 'Dashboard',
  },
  manage: {
    id: 'Tickets.manage.label',
    defaultMessage: 'Manage',
  },
});

const Tickets = ({
  intl: { formatMessage },
  hasManageAdminTicketGrant,
  hasViewTicketGrant,
  notificationsQuantity,
  children,
}) => {
  const nav = [];
  if (hasManageAdminTicketGrant) {
    nav.push({
      id: 'Tickets.dashboard.label',
      href: '/tickets/dashboard',
      label: messages.dashboard,
    });
  }
  if (hasViewTicketGrant) {
    nav.push({
      id: 'Tickets.ticketList.label',
      href: '/tickets/tickets-list',
      label: messages.ticketList,
    });
  }
  if (hasManageAdminTicketGrant) {
    nav.push({
      id: 'Tickets.manage.label',
      href: '/tickets/manage',
      label: messages.manage,
      subNav: [
        {
          id: 'Tickets.ticketFields.label',
          href: '/tickets/manage/tickets-fields',
          label: messages.ticketFields,
        },
        {
          id: 'Tickets.customizedStatus.label',
          href: '/tickets/manage/customized-status',
          label: messages.customizedStatus,
        },
        {
          id: 'Tickets.ticketCategories.label',
          href: '/tickets/manage/ticket-categories',
          label: messages.ticketCategories,
        },
        {
          id: 'Tickets.ticketSettings.label',
          href: '/tickets/manage/settings',
          label: messages.ticketSettings,
        },
      ],
    });
  }
  if (hasViewTicketGrant) {
    nav.push({
      id: 'Tickets.notifications.label',
      href: '/tickets/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    });
  }

  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <Fade className="yn-section-with-nav-scrolling">{children}</Fade>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsTicketNumber(state),
    ticketEnabled: isTicketingEnabled(state),
    hasManageAdminTicketGrant: hasGrants(state, 'MANAGE_ADMIN_TICKETS'),
    hasViewTicketGrant:
      hasGrants(state, 'MANAGE_OWN_TICKETS') ||
      hasGrants(state, 'MANAGE_ALL_TICKETS'),
  };
}

export default injectIntl(connect(mapStateToProps)(Tickets));
