import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import MeVbox from './MeVbox';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const VboxReceivedMessagesForm = React.lazy(() =>
  import('./VboxReceivedMessagesForm')
);
const VboxReceivedMessagesTable = React.lazy(() =>
  import('./VboxReceivedMessagesTable')
);

const VboxReceivedMessagesPage = () => {
  return (
    <MeVbox>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right">
              <HelpButton fileName="vbox-received" />
            </div>
            <VboxReceivedMessagesForm />
            <VboxReceivedMessagesTable />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </MeVbox>
  );
};

export default VboxReceivedMessagesPage;
