import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select2 from './Select2';
import {
  fetchAbookListRequest,
  fetchAbookListsRequest,
} from '../../js/contacts/actions';
import {
  getAbookListsIds,
  getAbookListsById,
  getSavedAbookListId,
} from '../../js/contacts/selectors';

const messages = defineMessages({
  selectPlaceholder: {
    id: 'ContactsListsSelect.selectPlaceholder',
    defaultMessage: 'Select a list',
  },
  noOptionsMessage: {
    id: 'ContactsListsSelect.noOptionsMessage',
    defaultMessage: 'No list found',
  },
  contactsLengthLabel: {
    id: 'ContactsListsSelect.contactsLengthLabel',
    defaultMessage: 'Contacts selected: ',
  },
  contactsLengthPHONELabel: {
    id: 'ContactsListsSelect.contactsLengthPHONELabel',
    defaultMessage: 'Contacts selected: ',
  },
  contactsLengthEMAILLabel: {
    id: 'ContactsListsSelect.contactsLengthEMAILLabel',
    defaultMessage: 'Contacts with email address: ',
  },
  contactsLengthPOSTALLabel: {
    id: 'ContactsListsSelect.contactsLengthPOSTALLabel',
    defaultMessage: 'Contacts with postal address: ',
  },
});

class ContactsListsSelect extends React.Component {
  componentDidMount() {
    const { fetchLists, fetchList, selected, isMulti } = this.props;
    fetchLists();
    if (selected && !isMulti) {
      fetchList({ id: selected });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      listsIds,
      selected,
      onSelect,
      isMulti,
      savedListId,
      fetchLists,
      fetchList,
    } = this.props;
    if (selected && !isMulti && listsIds !== prevProps.listsIds) {
      if (listsIds.indexOf(selected) < 0) {
        onSelect(null);
      }
    }
    if (
      (savedListId && !prevProps.savedListId) ||
      (savedListId &&
        prevProps.savedListId &&
        savedListId !== prevProps.savedListId)
    ) {
      fetchLists();
      if (selected && !isMulti) {
        fetchList({ id: selected });
      }
    }
  }

  handleSelect = (selected) => {
    const { isMulti, onSelect, fetchList } = this.props;
    onSelect(
      selected
        ? isMulti
          ? selected.map((o) => o.value)
          : selected.value
        : null
    );
    if (selected && !isMulti) {
      fetchList({ id: selected.value });
    }
  };

  calculateContactsLength = () => {
    const { selected, listsById, type, showContactsLength } = this.props;
    if (!showContactsLength) return null;
    const contacts =
      selected && listsById[selected] && listsById[selected].contacts;
    if (!contacts) return null;
    if (type === 'PHONE') return contacts.length;
    if (type === 'EMAIL') return contacts.filter((c) => c.email).length;
    if (type === 'POSTAL')
      return contacts.filter((c) => c.addresses && c.addresses.length > 0)
        .length;
  };

  render() {
    const {
      selected,
      intl: { formatMessage },
      listsIds,
      listsById,
      error,
      showContactsLength,
      type,
      isMulti,
    } = this.props;

    const options = listsIds.map((id) => ({
      value: id,
      label: listsById[id].name,
    }));

    const selectedValue = Array.isArray(selected)
      ? options.filter((o) => selected.indexOf(o.value) > -1)
      : options.filter((o) => o.value === selected);

    const contactsLength = this.calculateContactsLength();

    return (
      <>
        <Select2
          id="abookLists"
          name="abookLists"
          value={selectedValue}
          onChange={this.handleSelect}
          options={options}
          isClearable
          isMulti={isMulti}
          placeholder={formatMessage(messages.selectPlaceholder)}
          noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
          error={error}
        />
        {showContactsLength && selected && (
          <div>
            {messages[`contactsLength${type}Label`]
              ? formatMessage(messages[`contactsLength${type}Label`])
              : formatMessage(messages.contactsLengthLabel)}
            {contactsLength}
          </div>
        )}
      </>
    );
  }
}

ContactsListsSelect.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  type: PropTypes.oneOf(['PHONE', 'EMAIL', 'POSTAL']),
  showContactsLength: PropTypes.bool,
  isMulti: PropTypes.bool,
};

ContactsListsSelect.defaultProps = {
  selected: null,
  error: '',
  type: 'PHONE',
  showContactsLength: false,
  isMulti: false,
};

function mapStateToProps(state) {
  return {
    listsIds: getAbookListsIds(state),
    listsById: getAbookListsById(state),
    savedListId: getSavedAbookListId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchLists: fetchAbookListsRequest,
    fetchList: fetchAbookListRequest,
  })(ContactsListsSelect)
);
