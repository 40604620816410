import { Promise } from 'bluebird';
import adapter from 'webrtc-adapter';

import { Janus } from './janus';

export class JanusCommands {
  constructor(data) {
    this.url = data.url;
    this.username = data.username;
    this.number = data.number;
    this.password = data.password;
    this.janus = null;
    this.sipcall = null;
    this.localStream = null;
    this.remoteStream = null;
  }

  getSupportedDevices = () =>
    new Promise((resolve, reject) => {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = (callback) => {
          navigator.mediaDevices.enumerateDevices().then(callback);
        };
      }
      let mediaDevices = [];
      let canEnumerate = false;
      if (
        typeof MediaStreamTrack !== 'undefined' &&
        'getSources' in MediaStreamTrack
      ) {
        canEnumerate = true;
      } else if (
        navigator.mediaDevices &&
        !!navigator.mediaDevices.enumerateDevices
      ) {
        canEnumerate = true;
      }
      let hasMicrophone = false;
      // let hasSpeakers = false;
      let isMicrophoneAlreadyCaptured = false;
      const checkDeviceSupport = () =>
        new Promise((resolveDeviceSupport, rejectDeviceSupport) => {
          if (!canEnumerate) {
            rejectDeviceSupport();
            return;
          }
          if (
            !navigator.enumerateDevices &&
            window.MediaStreamTrack &&
            window.MediaStreamTrack.getSources
          ) {
            navigator.enumerateDevices =
              window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
          }

          if (!navigator.enumerateDevices && navigator.enumerateDevices) {
            navigator.enumerateDevices =
              navigator.enumerateDevices.bind(navigator);
          }
          if (!navigator.enumerateDevices) {
            rejectDeviceSupport();
            return;
          }
          mediaDevices = [];
          navigator.enumerateDevices((devices) => {
            devices.forEach((device) => {
              const foundDevice = {};
              Object.keys(device).forEach((key) => {
                foundDevice[key] = device[key];
              });
              if (foundDevice.kind === 'audio') {
                foundDevice.kind = 'audioinput';
              }
              let skip = false;
              mediaDevices.forEach((d) => {
                if (d.id === foundDevice.id && d.kind === foundDevice.kind) {
                  skip = true;
                }
              });
              if (!skip) {
                if (!foundDevice.deviceId) {
                  foundDevice.deviceId = foundDevice.id;
                }

                if (!foundDevice.id) {
                  foundDevice.id = foundDevice.deviceId;
                }
              }
              if (!foundDevice.label) {
                foundDevice.label = 'Please invoke getUserMedia once.';
              } else if (
                foundDevice.kind === 'audioinput' &&
                !isMicrophoneAlreadyCaptured
              ) {
                isMicrophoneAlreadyCaptured = true;
              }
              if (foundDevice.kind === 'audioinput') {
                hasMicrophone = true;
              }

              /* if (foundDevice.kind === "audiooutput") {
                hasSpeakers = true;
              } */
              mediaDevices.push(foundDevice);
            });
            resolveDeviceSupport();
          });
        });

      checkDeviceSupport()
        .then(() => {
          const supportedDevices = {
            audio: hasMicrophone,
            audioCap: isMicrophoneAlreadyCaptured,
          };
          console.log('*********************', supportedDevices);
          resolve();
        })
        .catch((err) => reject(err));
    });

  initJanus = (eventEmitter) =>
    new Promise((resolve, reject) => {
      this.localStream = document.getElementById('localaudio');
      this.remoteStream = document.getElementById('remoteaudio');
      Janus.init({
        debug: 'all',
        callback: () => {
          if (!Janus.isWebrtcSupported()) {
            reject();
            return;
          }
          this.closeJanus();
          // Create session
          this.janus = new Janus({
            server: `https://${this.url}/janus`,
            success: () => {
              this.janus.attach({
                plugin: 'janus.plugin.sip',
                success: (pluginHandle) => {
                  // console.log('-----------> plugin attached');
                  this.sipcall = pluginHandle;
                  return resolve();
                },
                error: (error) => {
                  console.log('-----------> error attaching plugin', error);
                  return reject();
                },
                onmessage: (msg, jsep) => {
                  if (msg.error) {
                    console.log('-----------> receiving message error');
                    if (this.webrtcRegistered) {
                      // Reset status
                      this.sipcall.hangup();
                    }
                    reject();
                    return;
                  }
                  const { result } = msg;
                  if (result && result.event) {
                    // get event
                    let { event } = result;
                    const { code } = result;
                    //console.log('----------->', event, code, jsep);
                    // if called is Busy
                    if (code === 486) {
                      event = 'busy';
                    }
                    // if we not receive sdp (no ringing)
                    if (code === 180) {
                      event = 'proceeding_no_sdp';
                    }
                    // if we receive sdp (ringing)
                    if (code === 183) {
                      event = 'proceeding_sdp';
                    }
                    eventEmitter.emit(event, jsep);
                  }
                },
                onlocalstream: (stream) => {
                  Janus.attachMediaStream(this.localStream, stream);
                },
                onremotestream: (stream) => {
                  Janus.attachMediaStream(this.remoteStream, stream);
                },
                oncleanup: () => {},
                slowLink: () => {
                  console.log('-----------> janus plugin slow link');
                },
                detached: () => {
                  console.log('-----------> plugin detached');
                  eventEmitter.emit('detached');
                },
                ondetached: () => {
                  console.log('-----------> plugin detached');
                  eventEmitter.emit('detached');
                },
                webrtcState: () => {
                  // console.log('-----------> plugin webrtcState active? ', active);
                },
              });
            },
            error: (error) => {
              if (this.janus && !this.janus.isConnected()) {
                eventEmitter.emit('disconnect');
              }
              return reject(error);
            },
            destroyed: () => reject(),
          });
        },
      });
    });

  closeJanus = () => {
    if (this.janus) {
      this.janus.destroy();
    }
  };

  register = () =>
    new Promise((resolve) => {
      const message = {
        request: 'register',
        proxy: `sip:${this.url}`,
        refresh: false,
        authuser: this.username,
        username: `sip:${this.username}@${this.url}`,
        secret: this.password,
        sips: false,
      };
      this.sipcall.send({
        message,
      });
      resolve();
    });

  unregister = () =>
    new Promise((resolve) => {
      const message = {
        request: 'unregister',
      };
      this.sipcall.send({
        message,
      });
      resolve();
    });

  handleRemote = (jsep) => {
    this.sipcall.handleRemoteJsep({
      jsep,
      error: () => {
        const message = {
          request: 'hangup',
        };
        this.sipcall.send({
          message,
        });
        this.sipcall.hangup();
      },
    });
  };

  call = (number) =>
    new Promise((resolve, reject) => {
      /* this.getSupportedDevices()
        .then(() => { */
      this.sipcall.createOffer({
        media: {
          audioSend: true,
          audioRecv: true, // We DO want audio
          videoSend: false,
          videoRecv: false, // We MAY want video
        },
        success: (jsep) => {
          const message = {
            request: 'call',
            uri: `sip:${number}@${this.url}`,
          };
          this.sipcall.send({
            message,
            jsep,
          });
        },
        error: (error) => {
          reject(error);
        },
      });
      /* })
        .catch(err => reject(err)); */
    });

  answer = (callData) =>
    new Promise((resolve, reject) => {
      /* this.getSupportedDevices()
        .then(() => { */
      this.sipcall.createAnswer({
        jsep: callData,
        media: {
          audio: true,
          video: false,
        },
        success: (jsep) => {
          const message = {
            request: 'accept',
          };
          this.sipcall.send({
            message,
            jsep,
          });
          resolve();
        },
        error: (error) => {
          // Don't keep the caller waiting any longer, but use a 480 instead of the default 486 to clarify the cause
          const message = {
            request: 'decline',
            code: 480,
          };
          this.sipcall.send({
            message,
          });
          console.log('janus command ---- ', error);
          reject(error);
        },
      });
      /* })
        .catch(err => reject(err)); */
    });

  hangup = () =>
    new Promise((resolve) => {
      const message = {
        request: 'hangup',
      };console.log(message)
      this.sipcall.send({
        message,
      });
      this.sipcall.hangup();
      resolve();
    });

  decline = () =>
    new Promise((resolve) => {
      const message = {
        request: 'decline',
      };
      this.sipcall.send({
        message,
      });
      resolve();
    });

  hold = () =>
    new Promise((resolve) => {
      const message = {
        request: 'hold',
      };
      this.sipcall.send({
        message,
      });
      resolve();
    });

  unhold = () =>
    new Promise((resolve) => {
      const message = {
        request: 'unhold',
      };
      this.sipcall.send({
        message,
      });
      resolve();
    });

  mute = () =>
    new Promise((resolve) => {
      this.sipcall.muteAudio();
      resolve();
    });

  unmute = () =>
    new Promise((resolve) => {
      this.sipcall.unmuteAudio();
      resolve();
    });

  sendDtmf = (value) =>
    new Promise((resolve) => {
      if (adapter.browserDetails.browser === 'chrome') {
        // Send DTMF tone (inband)
        this.sipcall.dtmf({
          dtmf: {
            tones: value,
          },
        });
      } else {
        // Try sending the DTMF tone using SIP INFO
        const message = {
          request: 'dtmf_info',
          digit: value,
        };
        this.sipcall.send({
          message,
        });
      }
      resolve();
    });

  isJanusWorking = () => this.sipcall && this.janus && this.janus.isConnected;
}
