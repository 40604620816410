import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DELETE_ASSOCIATION_REQUEST,
  DELETE_MEMBER_ASSOCIATION_REQUEST,
  EDIT_MEMBER_ASSOCIATION_REQUEST,
  FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse } from '../rootSaga';
import {
  deleteAssociationFailure,
  deleteAssociationMemberFailure,
  deleteAssociationMemberSuccess,
  deleteAssociationSuccess,
  editAssociationMemberFailure,
  editAssociationMemberSuccess,
  fetchAssociationMemberDetailsFailure,
  fetchAssociationMemberDetailsSuccess,
} from './actions';

export function* deleteAssociation(action) {
  try {
    const res = yield call(api.associations.deleteAssociation, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteAssociationSuccess(action.id));
  } catch (err) {
    yield put(deleteAssociationFailure(err));
  }
}

export function* deleteMember(action) {
  try {
    const res = yield call(api.associations.deleteMember, action.payload);
    yield call(checkApiResponse, res);
    yield put(deleteAssociationMemberSuccess(action.payload));
  } catch (err) {
    yield put(deleteAssociationMemberFailure(err));
  }
}

export function* editMember(action) {
  try {
    const res = yield call(api.associations.editMember, action.payload);
    yield call(checkApiResponse, res);
    yield put(editAssociationMemberSuccess(action.payload));
  } catch (err) {
    yield put(editAssociationMemberFailure(err));
  }
}

export function* fetchMemberData(action) {
  try {
    const res = yield call(api.associations.getMemberDetails, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchAssociationMemberDetailsSuccess(res.data));
  } catch (err) {
    yield put(fetchAssociationMemberDetailsFailure(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(DELETE_ASSOCIATION_REQUEST, deleteAssociation);
  yield takeLatest(DELETE_MEMBER_ASSOCIATION_REQUEST, deleteMember);
  yield takeLatest(EDIT_MEMBER_ASSOCIATION_REQUEST, editMember);
  yield takeLatest(FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST, fetchMemberData);
}
