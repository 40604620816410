import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import classnames from 'classnames';
import FormFieldError from '../messages/FormFieldError';

const Input = ({
  type,
  id,
  value,
  name,
  maxLength,
  style,
  accept,
  placeholder,
  error,
  disabled,
  randomizeName,
  onKeyPress,
  onBlur,
  onChange,
  onMouseDown,
  onKeyDown,
  onClick,
  size,
  readOnly,
  className,
  plaintext,
  autoComplete,
}) => {
  const inputName = randomizeName ? `${name}_${uuidv1()}` : name;

  const classNames = classnames(className, {
    'is-invalid': error,
    'form-control': !plaintext,
    'form-control-plaintext': plaintext,
  });

  return (
    <>
      <input
        id={id}
        name={inputName}
        type={type}
        value={value}
        maxLength={maxLength}
        style={style}
        accept={accept}
        placeholder={placeholder}
        className={classNames}
        disabled={disabled ? 'disabled' : ''}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        size={size}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
      {error && <FormFieldError text={error} />}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  accept: PropTypes.string,
  maxLength: PropTypes.number,
  randomizeName: PropTypes.bool,
  autoComplete: PropTypes.string,
  plaintext: PropTypes.bool,
  size: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseDown: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

Input.defaultProps = {
  value: '',
  disabled: false,
  placeholder: null,
  error: null,
  className: null,
  style: null,
  accept: null,
  maxLength: null,
  randomizeName: false,
  autoComplete: 'off',
  plaintext: false,
  size: null,
  readOnly: false,
  onKeyPress: null,
  onBlur: null,
  onMouseDown: null,
  onKeyDown: null,
  onClick: null,
};

export default Input;
