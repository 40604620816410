import axios from 'axios';
import {
  takeLatest,
  takeEvery,
  call,
  put,
  take,
  select,
} from 'redux-saga/effects';
import {
  putAvatarSuccess,
  putAvatarFailure,
  uploadFilesSuccess,
  uploadFilesFailure,
  removeFilesSuccess,
  removeFilesFailure,
  uploadFilesProgress,
  uploadFilesCancel,
  importFileToFileboxSuccess,
  importFileToFileboxFailure,
} from './actions';
import {
  PUT_AVATAR_REQUEST,
  UPLOAD_FILES_REQUEST,
  REMOVE_FILES_REQUEST,
  STOP_UPLOAD_FILES,
  UPLOAD_PROGRESS_FILES_REQUEST,
  IMPORT_FILE_TO_FILEBOX_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { AVATAR_TYPES } from './FileUtils';
import FileManager from './FileManager';
import { fetchFileboxItemsRequest } from '../filebox/actions';
import { getSelectedFolderId } from '../filebox/selectors';
import history from '../../history';

export function* putAvatarRequest(action) {
  try {
    let apiExec = null;
    switch (action.avatar.type) {
      case AVATAR_TYPES.ME:
      default:
        apiExec = api.files.updateMeAvatar;
        break;
      case AVATAR_TYPES.USER:
        apiExec = api.files.updateUserAvatar;
        break;
      case AVATAR_TYPES.CONTACT:
        if (action.avatar.id) {
          apiExec = api.files.updateContactAvatar;
        } else {
          apiExec = api.files.createContactAvatar;
        }
        break;
    }
    const res = yield call(apiExec, action.avatar);
    yield call(checkApiResponse, res);
    yield put(
      putAvatarSuccess({
        ...res.data,
        type: action.avatar.type,
        id: action.avatar.id,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(putAvatarFailure(error));
  }
}

export function* uploadFiles(action) {
  try {
    const res = yield call(api.files.uploadFiles, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      uploadFilesSuccess({
        data: res.data,
        scope: action.payload.scope,
        id: action.payload.id,
        entityId: action.payload.entityId,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        uploadFilesFailure({
          error,
          scope: action.payload.scope,
          id: action.payload.id,
        })
      );
  }
}

export function* uploadFilesWithProgress(action) {
  const cancelSource = axios.CancelToken.source();
  FileManager.addPendingFile(action.payload.id, cancelSource);
  const channel = yield call(
    api.files.createUploadFilesChannel,
    action.payload,
    cancelSource.token
  );
  try {
    while (true) {
      const { progress, success, error } = yield take(channel);
      if (error) {
        FileManager.removePendingFile(action.payload.id);
        if (error.message === '499') {
          yield put(
            uploadFilesCancel({
              error: error.message,
              scope: action.payload.scope,
              id: action.payload.id,
            })
          );
        } else {
          yield put(
            uploadFilesFailure({
              error: (error.data && error.data.error) || error.status,
              scope: action.payload.scope,
              id: action.payload.id,
            })
          );
        }
        return;
      }
      if (success) {
        FileManager.removePendingFile(action.payload.id);
        yield put(
          uploadFilesSuccess({
            data: success.data,
            scope: action.payload.scope,
            id: action.payload.id,
          })
        );
        if (action.payload.scope === 'filebox') {
          const folderId = yield select(getSelectedFolderId);
          yield put(fetchFileboxItemsRequest({ parent: folderId }));
        }

        return;
      }
      if (progress) {
        yield put(
          uploadFilesProgress({
            progress,
            scope: action.payload.scope,
            id: action.payload.id,
          })
        );
      }
    }
  } finally {
    channel.close();
  }
}

export function stopUploadingFiles(action) {
  const cancelSource = FileManager.retrievePendingFile(action.id);
  cancelSource.cancel();
}

export function* removeFiles(action) {
  try {
    const res = yield call(
      /** this will be checked after the file server is created */
      api.files.removeFile,
      action.payload.fileId
    );
    yield call(checkApiResponse, res);
    yield put(removeFilesSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(removeFilesFailure({ error, scope: action.payload.scope }));
  }
}

export function* importFileToFilebox(action) {
  try {
    const res = yield call(api.files.importFileToFilebox, action.payload);
    yield call(checkApiResponse, res);
    yield put(importFileToFileboxSuccess(res.data));
    history.push(
      `/filebox/list/${action.payload.path.filter((f) => f !== null).join('/')}`
      //.join('/')}?preview=${res.data.id}`
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        importFileToFileboxFailure(err.data.error ? err.data.error : error)
      );
  }
}

export default function* rootSaga() {
  yield takeLatest(PUT_AVATAR_REQUEST, putAvatarRequest);
  yield takeEvery(UPLOAD_PROGRESS_FILES_REQUEST, uploadFilesWithProgress);
  yield takeEvery(UPLOAD_FILES_REQUEST, uploadFiles);
  yield takeLatest(REMOVE_FILES_REQUEST, removeFiles);
  yield takeLatest(STOP_UPLOAD_FILES, stopUploadingFiles);
  yield takeLatest(IMPORT_FILE_TO_FILEBOX_REQUEST, importFileToFilebox);
}
