import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { ChatEnums } from '../../../js/chat/ChatUtils';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  muteNotificationsFor: {
    id: 'MuteChatDropdown.muteNotificationsFor',
    defaultMessage: 'Mute notifications for...',
  },
  never: {
    id: 'MuteChatDropdown.never',
    defaultMessage: 'Never',
  },
  oneDay: {
    id: 'MuteChatDropdown.oneDay',
    defaultMessage: '1 day',
  },
  oneWeek: {
    id: 'MuteChatDropdown.oneWeek',
    defaultMessage: '1 week',
  },
  oneMonth: {
    id: 'MuteChatDropdown.oneMonth',
    defaultMessage: '1 month',
  },
  always: {
    id: 'MuteChatDropdown.always',
    defaultMessage: 'Always',
  },
});

const MuteChatDropdown = ({
  isOpen,
  toggle,
  onMute,
  muted,
  tooltip,
  expirationDate,
  intl: { formatMessage },
}) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="yn-dropdown">
      <DropdownToggle className="position-relative p-0" nav>
        <Icon
          name={muted ? 'alarm-silent-filled' : 'alarm-sound-filled'}
          width={20}
          height={20}
        >
          {tooltip}
        </Icon>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>
          {formatMessage(messages.muteNotificationsFor)}
        </DropdownItem>
        <DropdownItem disabled={!muted} onClick={() => onMute()}>
          {formatMessage(messages.never)}
        </DropdownItem>
        {Object.keys(ChatEnums.MuteChatFrequency).map((el) => (
          <DropdownItem
            key={el}
            disabled={expirationDate === null && el === 'always'}
            onClick={() => onMute(ChatEnums.MuteChatFrequency[el])}
          >
            {formatMessage(messages[el])}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

MuteChatDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  tooltip: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
};

export default injectIntl(MuteChatDropdown);
