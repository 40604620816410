import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import ChatOptionsDropdown from './ChatOptionsDropdown';
import {
  isConversationOperationLoading,
  isInterlocutorGroupEditable,
  getCreateChatGroupLoaded,
  getCreateChatGroupError,
  isInterlocutorRemoved,
  getInterlocutorName,
  getUnreadMessagesByConversation,
  isConversationArchived,
  getMutedChats,
} from '../../../js/chat/selectors';
import AnimatedDots from './AnimatedDots';
import ChatWindowToolbarErrorMessage from './ChatWindowToolbarErrorMessage';
import { retrieveInterlocutorIdFromConversationId } from '../../../js/chat/ChatUtils';

import { getMeId } from '../../../js/me/selectors';
import {
  createChatGroupRequest,
  updateChatGroupRequest,
} from '../../../js/groups/actions';
import {
  getGroupMembersById,
  getGroupMembersLoaded,
} from '../../../js/groups/selectors';
import { showConfirmModal } from '../../../js/confirm/actions';
import { isWsAvailable } from '../../../js/auth/selectors';
import Icon from '../../../components/icons/Icon';
import GroupMembersPopover from '../../../components/GroupMembersPopover';
import ChatAddUserDropdown from './ChatAddUserDropdown';
import HelpButton from '../../../components/HelpButton';
import { muteUnmuteChatRequest } from '../../../js/chat/actions';
import MuteChatDropdown from './MuteChatDropdown';
import VideoButton from '../../../components/buttons/VideoButton';

const messages = defineMessages({
  chatUnavailable: {
    id: 'ChatWindowToolbarTop.label.chatUnavailable',
    defaultMessage: 'Chat is temporarily unavailable',
  },
  operationLoading: {
    id: 'ChatWindowToolbarTop.label.operationLoading',
    defaultMessage: 'Executing',
  },
  noUsers: {
    id: 'ChatWindowToolbarTop.error.noUsers',
    defaultMessage: 'Select at least one user',
  },
  deleteMessage: {
    id: 'ChatWindowToolbarTop.deleteMessage',
    defaultMessage: 'Are you sure to remove this user from the group?',
  },
  deleteMeMessage: {
    id: 'ChatWindowToolbarTop.deleteMeMessage',
    defaultMessage: 'Are you sure to leave the group?',
  },
  information: {
    id: 'ChatWindowToolbarTop.tooltip.information',
    defaultMessage: 'Information',
  },
  addUsers: {
    id: 'ChatWindowToolbarTop.tooltip.addUsers',
    defaultMessage: 'Add users',
  },
  muted: {
    id: 'ChatWindowToolbarTop.muted',
    defaultMessage: 'Muted until {date}',
  },
  mutedAlways: {
    id: 'ChatWindowToolbarTop.mutedAlways',
    defaultMessage: 'Muted always',
  },
  unMuted: {
    id: 'ChatWindowToolbarTop.unMuted',
    defaultMessage: 'Un muted',
  },
});

class ChatWindowToolbarTop extends Component {
  constructor(props) {
    super(props);
    this.muteTimeout = null;
    this.state = {
      membersPopoverOpen: false,
      addUserPanelOpen: false,
      muteChatPopoverOpen: false,
      userToBeMuted: null,
      groupToBeMuted: null,
      error: null,
      chatMuted: false,
    };
    this.handleShowGroupMembers = this.handleShowGroupMembers.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  componentDidMount() {
    this.manageMuteChatTimeout();
  }

  componentDidUpdate(prevprops) {
    const { mutedChats, conversationId } = this.props;
    if (
      (!mutedChats[conversationId] && prevprops.mutedChats[conversationId]) ||
      (mutedChats[conversationId] && !prevprops.mutedChats[conversationId]) ||
      (mutedChats[conversationId] &&
        prevprops.mutedChats[conversationId] &&
        mutedChats[conversationId].expirationDate !==
          prevprops.mutedChats[conversationId].expirationDate)
    ) {
      if (this.muteTimeout) {
        clearTimeout(this.muteTimeout);
      }
      this.manageMuteChatTimeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.muteTimeout);
  }

  manageMuteChatTimeout = () => {
    const { mutedChats, conversationId } = this.props;

    if (!mutedChats[conversationId]) {
      this.setState({
        chatMuted: false,
      });
    }
    if (
      mutedChats[conversationId] &&
      (!mutedChats[conversationId].expirationDate ||
        (mutedChats[conversationId].expirationDate &&
          mutedChats[conversationId].expirationDate > +new Date()))
    ) {
      this.setState({ chatMuted: true });
      const expire = +mutedChats[conversationId].expirationDate - +new Date();
      if (expire > 0 && expire < 1000 * 60 * 60 * 24 * 7) {
        this.muteTimeout = setTimeout(() => {
          this.setState({
            chatMuted: false,
          });
        }, expire);
      }
    }
  };

  handleShowGroupMembers = () => {
    this.togglePopover();
  };

  togglePopover = () => {
    const { membersPopoverOpen } = this.state;
    this.setState({
      membersPopoverOpen: !membersPopoverOpen,
    });
  };

  addUserPanelToggle = () => {
    this.setState((prevState) => ({
      addUserPanelOpen: !prevState.addUserPanelOpen,
      error: null,
    }));
  };

  saveChatGroup = (users) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const error = users.length === 0 ? formatMessage(messages.noUsers) : null;
    this.setState({ error });
    if (!error) {
      if (this.interlocutor().userId) {
        this.createChatGroup(users);
      } else if (this.interlocutor().groupId) {
        this.addUsersToChat(users);
      }
      this.addUserPanelToggle();
    }
  };

  createChatGroup = (users) => {
    const { myId, createGroup } = this.props;
    const members = [myId, this.interlocutor().userId, ...users];
    createGroup(members);
  };

  addUsersToChat = (users) => {
    const { updateGroup } = this.props;
    updateGroup({
      groupId: this.interlocutor().groupId,
      addedMembers: users,
    });
  };

  removeMember = (userId) => {
    const {
      confirm,
      myId,
      intl: { formatMessage },
    } = this.props;

    confirm({
      message:
        userId === myId
          ? formatMessage(messages.deleteMeMessage)
          : formatMessage(messages.deleteMessage),
      modalAction: this.confirmRemovingMember,
      actionParams: userId,
    });
  };

  confirmRemovingMember = (userId) => {
    const { updateGroup } = this.props;
    updateGroup({
      groupId: this.interlocutor().groupId,
      removedMembers: [userId],
    });
  };

  interlocutor = () => {
    const { conversationId } = this.props;
    return retrieveInterlocutorIdFromConversationId(conversationId);
  };

  toggleMuteChatPopover = (idUser, idGroup) => {
    const { muteChatPopoverOpen } = this.state;
    this.setState({
      muteChatPopoverOpen: !muteChatPopoverOpen,
      userToBeMuted: idUser,
      groupToBeMuted: idGroup,
    });
  };

  muteUnmuteChat = (muteOption) => {
    const { muteUnmuteChat, conversationId } = this.props;
    const { userToBeMuted, groupToBeMuted } = this.state;

    const data = {
      idUser: userToBeMuted,
      idGroup: groupToBeMuted,
      mute: !!muteOption,
      expirationDate:
        muteOption && muteOption.keyword
          ? moment().add(1, muteOption.keyword).format('x')
          : null,
    };

    muteUnmuteChat(data, conversationId);
  };

  render() {
    const {
      chatUnavailable,
      conversationId,
      readAllMessages,
      deleteAllMessages,
      archiveConversation,
      enableRenameGroup,
      membersIds,
      unreadMessages,
      membersLoading,
      archived,
      operationLoading,
      isGroupEditable,
      isMain,
      interlocutorRemoved,
      interlocutorName,
      myId,
      loading,
      dateFormat,
      timeFormat,
      mutedChats,
      intl: { formatMessage },
    } = this.props;
    const {
      addUserPanelOpen,
      membersPopoverOpen,
      muteChatPopoverOpen,
      error,
      chatMuted,
    } = this.state;
    const interlocutor = this.interlocutor();
    const mutedChat = mutedChats[conversationId];

    return (
      <>
        <div className="p-1 border-bottom d-flex" style={{ height: '30px' }}>
          {chatUnavailable ? (
            <span style={{ color: 'var(--red)' }}>
              {formatMessage(messages.chatUnavailable)}
            </span>
          ) : (
            <>
              {!interlocutorRemoved &&
                interlocutor.groupId &&
                !loading &&
                membersIds.length > 0 && (
                  <>
                    <span
                      className="ml-1"
                      id={`groupinfo_${conversationId}`}
                      onClick={this.handleShowGroupMembers}
                    >
                      <Icon
                        name="information-circle"
                        tooltipPosition="left"
                        width={18}
                        height={18}
                        color="var(--yn-blue-dark)"
                        clickable
                        strokeWidth={1.5}
                      >
                        {formatMessage(messages.information)}
                      </Icon>
                    </span>

                    <GroupMembersPopover
                      isOpen={membersPopoverOpen}
                      target={`groupinfo_${conversationId}`}
                      toggle={this.togglePopover}
                      id={interlocutor.groupId}
                      name={interlocutorName}
                      removeMember={(user) => this.removeMember(user)}
                      showContacts
                    />
                  </>
                )}
              <span className="pl-1">
                <HelpButton fileName="chat" width={18} height={18} />
              </span>
              {operationLoading && (
                <>
                  <span className="ml-2">
                    {formatMessage(messages.operationLoading)}
                  </span>
                  <AnimatedDots />
                </>
              )}
              <ChatWindowToolbarErrorMessage
                conversationId={conversationId}
                error={error}
              />
              <div className="ml-auto">
                {this.interlocutor().groupId && !isMain && (
                  <VideoButton
                    className="mr-1"
                    group={this.interlocutor().groupId}
                    width={18}
                    tooltipPosition="left"
                    height={18}
                  />
                )}
                <span className="d-inline-block mr-1">
                  <MuteChatDropdown
                    isOpen={muteChatPopoverOpen}
                    toggle={() =>
                      this.toggleMuteChatPopover(
                        interlocutor.userId,
                        interlocutor.groupId
                      )
                    }
                    id={`conversation_mute_${conversationId}`}
                    onMute={this.muteUnmuteChat}
                    muted={chatMuted}
                    expirationDate={mutedChat && mutedChat.expirationDate}
                    tooltip={
                      mutedChat
                        ? mutedChat.expirationDate
                          ? formatMessage(messages.muted, {
                              date: moment(
                                parseInt(mutedChat.expirationDate, 10)
                              ).format(`${dateFormat} ${timeFormat}`),
                            })
                          : formatMessage(messages.mutedAlways)
                        : formatMessage(messages.unMuted)
                    }
                  />
                </span>
                {(isGroupEditable || this.interlocutor().userId) && !isMain && (
                  <>
                    <span
                      className="d-inline-block mr-1"
                      onClick={this.addUserPanelToggle}
                    >
                      <Icon
                        name="person-add-2-filled"
                        width={20}
                        height={20}
                        color="var(--yn-blue-dark)"
                      >
                        {formatMessage(messages.addUsers)}
                      </Icon>
                    </span>
                    {addUserPanelOpen && (
                      <ChatAddUserDropdown
                        onSelect={(users) => this.saveChatGroup(users)}
                        excluded={membersIds}
                      />
                    )}
                  </>
                )}
                <span className="d-inline-block">
                  <ChatOptionsDropdown
                    conversationId={conversationId}
                    isGroupEditable={
                      !interlocutorRemoved && isGroupEditable && !isMain
                    }
                    operationLoading={operationLoading}
                    readAllMessages={
                      unreadMessages > 0 ? readAllMessages : null
                    }
                    deleteAllMessages={deleteAllMessages}
                    archiveConversation={
                      !archived && !isMain ? archiveConversation : null
                    }
                    enableRenameGroup={
                      !interlocutorRemoved && isGroupEditable && !isMain
                        ? enableRenameGroup
                        : null
                    }
                    addMeToGroup={
                      !interlocutorRemoved &&
                      membersIds &&
                      !isMain &&
                      membersIds.indexOf(myId) < 0
                        ? () => this.addUsersToChat([myId])
                        : null
                    }
                    removeMeFromGroup={
                      !interlocutorRemoved &&
                      membersIds &&
                      !isMain &&
                      membersIds.indexOf(myId) > -1
                        ? () => this.removeMember(myId)
                        : null
                    }
                    membersLoading={!interlocutorRemoved && membersLoading}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

ChatWindowToolbarTop.propTypes = {
  conversationId: PropTypes.string.isRequired,
  readAllMessages: PropTypes.func.isRequired,
  deleteAllMessages: PropTypes.func.isRequired,
  archiveConversation: PropTypes.func.isRequired,
  enableRenameGroup: PropTypes.func.isRequired,
  isMain: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { groupId } = retrieveInterlocutorIdFromConversationId(
    ownProps.conversationId
  );
  return {
    chatUnavailable: !isWsAvailable(state),
    operationLoading: isConversationOperationLoading(
      state,
      ownProps.conversationId
    ),
    isGroupEditable: isInterlocutorGroupEditable(
      state,
      ownProps.conversationId
    ),
    interlocutorRemoved: isInterlocutorRemoved(state, ownProps.conversationId),
    interlocutorName: getInterlocutorName(state, ownProps.conversationId),
    loading: !getCreateChatGroupLoaded(state),
    myId: getMeId(state),
    serverError: getCreateChatGroupError(state),
    membersIds:
      groupId &&
      getGroupMembersById(state, groupId)
        .filter((m) => m.type === 'U')
        .map((m) => m.id),
    membersLoading: groupId && !getGroupMembersLoaded(state, groupId),
    unreadMessages: getUnreadMessagesByConversation(
      state,
      ownProps.conversationId
    ),
    archived: isConversationArchived(state, ownProps.conversationId),
    mutedChats: getMutedChats(state),
    dateFormat: state.settings.dateFormat,
    timeFormat: state.settings.timeFormat,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    createGroup: createChatGroupRequest,
    updateGroup: updateChatGroupRequest,
    confirm: showConfirmModal,
    muteUnmuteChat: muteUnmuteChatRequest,
  })(ChatWindowToolbarTop)
);
