import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { getDatetimeFormat } from '../../../js/settings/selectors';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import { showConfirmModal } from '../../../js/confirm/actions';
import { getAbookListById } from '../../../js/contacts/selectors';
import {
  deleteAbookListRequest,
  fetchAbookListRequest,
} from '../../../js/contacts/actions';
import AbookListsSharingPopover from './AbookListsSharingPopover';
import AbookListsContactsPopover from './AbookListsContactsPopover';

const messages = defineMessages({
  deleteConfirmationMessage: {
    id: 'AbookListsRow.deleteConfirmationMessage',
    defaultMessage: 'Are you sure you want to delete this list?',
  },
  editTooltip: {
    id: 'AbookListsRow.tooltip.editTooltip',
    defaultMessage: 'Edit',
  },
  deleteTooltip: {
    id: 'AbookListsRow.tooltip.deleteTooltip',
    defaultMessage: 'Delete',
  },
  sharingTooltip: {
    id: 'AbookListsRow.tooltip.sharingTooltip',
    defaultMessage: 'Sharing',
  },
  contactsTooltip: {
    id: 'AbookListsRow.tooltip.contactsTooltip',
    defaultMessage: 'Contacts',
  },
  unknownUser: {
    id: 'AbookListsRow.unknownUser',
    defaultMessage: 'Unknown user',
  },
  unknownGroup: {
    id: 'AbookListsRow.unknownGroup',
    defaultMessage: 'Unknown group',
  },
});

const AbookListsRow = ({
  listData,
  users,
  dateTimeFormat,
  confirm,
  deleteList,
  fetchDetails,
  intl: { formatMessage },
}) => {
  const user = users[listData.creator];
  const [sharingPopoverOpen, toggleSharingPopover] = useState(false);
  const [contactsToShow, toggleContactsPopover] = useState(null);
  const [fetched, setFetched] = useState(null);

  useEffect(() => {
    if (contactsToShow && contactsToShow !== fetched) {
      fetchDetails({ id: contactsToShow });
      setFetched(contactsToShow);
    }
  }, [contactsToShow, fetched, fetchDetails]);

  const handleDeleteList = () => {
    confirm({
      message: formatMessage(messages.deleteConfirmationMessage),
      modalAction: deleteList,
      actionParams: { id: listData.id },
    });
  };

  const arrangedUser = user ? (
    <>
      <UserAvatar
        size="sm"
        alt={user.fullname}
        src={user.avatar}
        id={listData.user}
        withPopover
      />
      {user.fullname}
    </>
  ) : (
    listData.historyCreator
  );

  const creationTime = moment(+listData.creationTime).format(dateTimeFormat);
  const sharingUsers =
    listData.sharing &&
    listData.sharing.length &&
    listData.sharing.filter((s) => s.idUser).map((s) => s.idUser);

  const sharingGroups =
    listData.sharing &&
    listData.sharing.length &&
    listData.sharing.filter((s) => s.idGroup).map((s) => s.idGroup);

  return (
    <tr>
      <td>{creationTime}</td>
      <td>{listData.name}</td>
      <td>{arrangedUser}</td>
      <td className="text-right">
        {listData.sharing && listData.sharing.length > 0 && (
          <>
            <span
              id={`sharingPopover_${listData.id}`}
              onClick={() => toggleSharingPopover(!sharingPopoverOpen)}
            >
              <ToolboxIcon name="share">
                {formatMessage(messages.sharingTooltip)}
              </ToolboxIcon>
            </span>
            <AbookListsSharingPopover
              isOpen={sharingPopoverOpen}
              toggle={() => toggleSharingPopover(!sharingPopoverOpen)}
              target={`sharingPopover_${listData.id}`}
              sharingUsers={sharingUsers}
              sharingGroups={sharingGroups}
            />
          </>
        )}
        <>
          <span
            id={`contactsPopover_${listData.id}`}
            onClick={() =>
              toggleContactsPopover(contactsToShow ? null : listData.id)
            }
          >
            <ToolboxIcon name="group-information">
              {formatMessage(messages.contactsTooltip)}
            </ToolboxIcon>
          </span>
          <AbookListsContactsPopover
            isOpen={!!contactsToShow}
            toggle={() => toggleContactsPopover(null)}
            target={`contactsPopover_${listData.id}`}
            contacts={listData.contacts}
          />
        </>
        <Link to={`/abook/lists/edit/${listData.id}`}>
          <ToolboxIcon name="pencil-write-2">
            {formatMessage(messages.editTooltip)}
          </ToolboxIcon>
        </Link>
        <span onClick={() => handleDeleteList()}>
          <ToolboxIcon name="bin-1">
            {formatMessage(messages.deleteTooltip)}
          </ToolboxIcon>
        </span>
      </td>
    </tr>
  );
};

AbookListsRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    listData: getAbookListById(state, ownProps.id),
    dateTimeFormat: getDatetimeFormat(state),
    users: getUsersById(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    fetchDetails: fetchAbookListRequest,
    deleteList: deleteAbookListRequest,
  })(AbookListsRow)
);
