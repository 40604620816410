import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import { getSupplier } from '../../../js/phone/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SecondaryNav from '../../../components/SecondaryNav';

const PbxCallsLog = React.lazy(() => import('./PbxCallsLog'));

const messages = defineMessages({
  title: {
    id: 'PbxCallsLogPage.title',
    defaultMessage: 'Calls log',
  },
  extensions: {
    id: 'PbxCallsLogPage.extensions',
    defaultMessage: 'Extensions',
  },
  queues: {
    id: 'PbxCallsLogPage.queues',
    defaultMessage: 'Queues',
  },
});

const nav = [
  {
    id: 'PbxCallsLogPage.extensions',
    href: '/pbx/calls/switchboard',
    label: messages.extensions,
  },
  {
    id: 'PbxCallsLogPage.queues',
    href: '/pbx/calls/queues',
    label: messages.queues,
  },
];

const PbxCallsLogPage = ({ supplier, intl: { formatMessage } }) => (
  <>
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      {supplier === PhoneEnums.PbxType.NETHESIS && <SecondaryNav tabs={nav} />}
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div
            className={
              supplier === PhoneEnums.PbxType.NETHESIS
                ? 'yn-section-with-nav-scrolling'
                : 'yn-section-scrolling'
            }
          >
            <PbxCallsLog />
          </div>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  </>
);

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
  };
}

export default injectIntl(connect(mapStateToProps)(PbxCallsLogPage));
