import { createSelector } from 'reselect';

const defaultList = [];
export const getMeVboxMessagesById = (state) => state.vbox.me.byId;
export const getPbxVboxMessagesById = (state) => state.vbox.pbx.byId;
export const getMeVboxMessagesFilter = (state) => state.vbox.me.filter;
export const getPbxVboxMessagesFilter = (state) => state.vbox.pbx.filter;
export const getPbxTvoxVoices = (state) => state.vbox.pbx.telephonevox.sounds;
export const getPbxTvoxPrice = (state) => state.vbox.pbx.telephonevox.price;
export const getPbxTvoxPriceSuccess = (state) =>
  state.vbox.pbx.telephonevox.fetchTvoxPriceSuccess;
export const purchaseTvoxMessageSuccess = (state) =>
  state.vbox.pbx.telephonevox.purchaseTvoxMessageSuccess;
export const purchaseTvoxMessageLoaded = (state) =>
  state.vbox.pbx.telephonevox.purchaseTvoxMessageLoaded;
export const getPurchaseTvoxMessageError = (state) =>
  state.vbox.pbx.telephonevox.purchaseTvoxMessageError;
export const getClassicalMusicGenre = (state) =>
  state.vbox.pbx.telephonevox.musicByGenre.Classical;
export const getModernMusicGenre = (state) =>
  state.vbox.pbx.telephonevox.musicByGenre.Modern;
export const getChristmasMusicGenre = (state) =>
  state.vbox.pbx.telephonevox.musicByGenre.Christmas;
export const getPbxTvoxMusic = (state) =>
  state.vbox.pbx.telephonevox.musicByGenre;
export const getPbxArchivedGreetingMessagesById = (state) =>
  state.vbox.pbx.greetingsArchived.byId;
export const getPbxArchivedGreetingMessagesCurrentPage = (state) =>
  state.vbox.pbx.greetingsArchived.currentPage;
export const getPbxArchivedGreetingMessagesTotal = (state) =>
  state.vbox.pbx.greetingsArchived.total;
const getPbxArchivedGreetingMessagesIds = (state) => {
  const page = state.vbox.pbx.greetingsArchived.currentPage;
  const pageIds = state.vbox.pbx.greetingsArchived.pagesGreetingMessages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getPbxArchivedGreetingMessages = createSelector(
  [getPbxArchivedGreetingMessagesById, getPbxArchivedGreetingMessagesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
const getMeVboxMessagesIds = (state) => {
  const page = state.vbox.me.currentPage;
  const pageIds = state.vbox.me.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getMeVboxMessages = createSelector(
  [getMeVboxMessagesById, getMeVboxMessagesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
const getPbxVboxMessagesIds = (state) => {
  const page = state.vbox.pbx.currentPage;
  const pageIds = state.vbox.pbx.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getPbxVboxMessages = createSelector(
  [getPbxVboxMessagesById, getPbxVboxMessagesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const isPbxVboxSearched = (state) => state.vbox.pbx.doneSearching;

export const getClassicGenreSelectOptions = createSelector(
  getClassicalMusicGenre,
  (items) =>
    items && items.map((item) => ({ value: item.id, label: item.title }))
);

export const getModernGenreSelectOptions = createSelector(
  getModernMusicGenre,
  (items) =>
    items && items.map((item) => ({ value: item.id, label: item.title }))
);

export const getChristmasGenreSelectOptions = createSelector(
  getChristmasMusicGenre,
  (items) =>
    items && items.map((item) => ({ value: item.id, label: item.title }))
);
export const getNethesisVboxGreeting = (state) =>
  state.vbox.me.greeting.message;
export const isGreetingLoaded = (state) =>
  state.vbox.me.greeting.loadedGreeting;
export const isGreetingLoadingError = (state) =>
  state.vbox.me.greeting.errorLoadingGreeting;
export const isGreetingSaved = (state) => state.vbox.me.greeting.savedGreeting;
export const isGreetingSavingError = (state) =>
  state.vbox.me.greeting.errorSavingGreeting;
export const getFetchActiveMessagesLoaded = (state) =>
  state.vbox.pbx.greetingsActives.fetchActiveGreetingMessagesLoaded;
export const getFetchActiveMessagesError = (state) =>
  state.vbox.pbx.greetingsActives.fetchActiveGreetingMessagesError;
export const getPbxActiveGreetingMessage = (state) =>
  state.vbox.pbx.greetingsActives.data;
export const getGreetingMessagesSelect = createSelector(
  getPbxArchivedGreetingMessages,
  (items) =>
    items && items.map((item) => ({ value: item.id, label: item.filename }))
);
export const getDeleteArchivedGreetingMessagesLoaded = (state) =>
  state.vbox.pbx.greetingsArchived.deleteArchivedGreetingMessagesLoaded;
export const getDeleteArchivedGreetingMessagesError = (state) =>
  state.vbox.pbx.greetingsArchived.deleteArchivedGreetingMessagesError;
export const getDeleteArchivedGreetingMessageId = (state) =>
  state.vbox.pbx.greetingsArchived.deletingId;
export const getActivateGreetingMessagesLoaded = (state) =>
  state.vbox.pbx.greetingsActives.activateArchivedGreetingMessagesLoaded;
export const getActivateGreetingMessagesError = (state) =>
  state.vbox.pbx.greetingsActives.activateArchivedGreetingMessagesError;
export const getSaveArchivedGreetingMessagesLoaded = (state) =>
  state.vbox.pbx.greetingsArchived.saveArchivedGreetingMessagesLoaded;
export const getSaveArchivedGreetingMessagesError = (state) =>
  state.vbox.pbx.greetingsArchived.saveArchivedGreetingMessagesError;
export const isMeVboxChanged = (state) => state.vbox.me.vboxMessagesChanged;
export const isPbxVboxChanged = (state) => state.vbox.pbx.vboxMessagesChanged;
// export const getOwnVboxGreeting = state => state.vbox.me.greeting.message;
export const getDeleteVboxMessagesLoaded = (state, isPbx) =>
  isPbx
    ? state.vbox.pbx.deleteVboxMessagesLoaded
    : state.vbox.me.deleteVboxMessagesLoaded;
export const getDeleteVboxMessagesError = (state, isPbx) =>
  isPbx
    ? state.vbox.pbx.deleteVboxMessagesError
    : state.vbox.me.deleteVboxMessagesError;
