import { request } from '../request';

export default {
  getMe: () =>
    request({
      url: '/me',
      method: 'GET',
    }),
  getSettings: () =>
    request({
      url: '/me/settings',
      method: 'GET',
    }),
  getMeGroups: () =>
    request({
      url: '/me/groups',
      method: 'GET',
    }),
  getMeGroupMembers: (groupId) =>
    request({
      url: `/me/groups/${groupId}/members`,
      method: 'GET',
    }),
  addFavoriteUser: (member) =>
    request({
      url: '/me/favorites/user',
      method: 'POST',
      data: member,
    }),
  removeFavoriteUser: (member) =>
    request({
      url: `/me/favorites/user/${member.id}`,
      method: 'DELETE',
    }),
  addFavoriteContact: (member) =>
    request({
      url: '/me/favorites/abook',
      method: 'POST',
      data: member,
    }),
  removeFavoriteContact: (member) =>
    request({
      url: `/me/favorites/abook/${member.id}`,
      method: 'DELETE',
    }),
  updateEmail: (payload) =>
    request({
      url: '/me/email',
      method: 'POST',
      data: payload,
    }),
  updateProfileSettings: (profileSettings) =>
    request({
      url: '/me',
      method: 'POST',
      data: profileSettings,
    }),
  updatePassword: (password) =>
    request({
      url: '/me/password',
      method: 'POST',
      data: password,
    }),
  updatePlatformSettings: (preferences) =>
    request({
      url: '/me/platform-settings',
      method: 'POST',
      data: preferences,
    }),
  updateMyGroupsOrder: (orderedGroups) =>
    request({
      url: '/me/groups/order',
      method: 'POST',
      data: {
        orderedGroups,
      },
    }),
  updateMyGroupSettings: (params) =>
    request({
      url: `/me/groups/${params.id}/settings`,
      method: 'POST',
      data: params.settings,
    }),
  updateMyGroupMembersOrder: (params) =>
    request({
      url: `/me/groups/${params.id}/membersOrder`,
      method: 'POST',
      data: params.settings,
    }),
  updateNotifictionSettings: (params) =>
    request({
      url: '/me/notification-settings',
      method: 'POST',
      data: params,
    }),
  getCustomerSettings: () =>
    request({
      url: '/customer/settings',
      method: 'GET',
    }),
  getExtensionsOrder: () =>
    request({
      url: '/me/extensions-order',
      method: 'GET',
    }),
  updateExtensionsOrder: (users) =>
    request({
      url: '/me/extensions-order',
      method: 'POST',
      data: {
        users,
      },
    }),
  getPbxs: () =>
    request({
      url: '/pbxs',
      method: 'GET',
    }),
  saveGreetingMessage: (params) =>
    request({
      url: '/me/greeting',
      method: 'POST',
      data: params,
    }),
  restoreNotificationSettings: () =>
    request({
      url: '/me/restore-notification-settings',
      method: 'POST',
    }),
  getCustomerRegistryFields: () =>
    request({
      url: '/customer/registry-fields',
      method: 'GET',
    }),
  setUserStatus: (params) =>
    request({
      url: '/me/set-status',
      method: 'POST',
      data: params,
    }),
  saveHideIntro: () =>
    request({
      url: '/me/hide-intro',
      method: 'POST',
    }),
  updatePlatformCustomerSettings: (payload) =>
    request({
      url: '/customer/settings',
      method: 'POST',
      data: payload,
    }),
  checkRelease: () =>
    request({
      url: '/me/check-release',
      method: 'GET',
    }),
};
