export class SMSEnums {
  static Status = {
    PENDING: 'PENDING',
    ERROR: 'ERROR',
    SENT: 'SENT',
    DELIVERED: 'DELIVERED',
  };

  static AliasStatus = {
    PENDING: 'PENDING',
    GATEWAY_CONFIRMED: 'GATEWAY_CONFIRMED',
    AGCOM_CONFIRMED: 'AGCOM_CONFIRMED',
    GATEWAY_BLOCKED: 'GATEWAY_BLOCKED',
    AGCOM_BLOCKED: 'AGCOM_BLOCKED',
  };

  static Errors = {
    TOO_MANY_SMS_FROM_USER: 'TOOM4USER',
    TOO_MANY_SMS_FOR_NUMBER: 'TOOM4NUM',
    ERROR: 'ERROR',
    TIMEOUT: 'TIMEOUT',
    UNPARSABLE_RCPT: 'UNKNRCPT',
    UNKNOWN_PREFIX: 'UNKNPFX',
    SENT_IN_DEMO_MODE: 'DEMO',
    WAITING_DELAYED: 'SCHEDULED',
    INVALID_DESTINATION: 'INVALIDDST',
    NUMBER_BLACKLISTED: 'BLACKLISTED',
    NUMBER_USER_BLACKLISTED: 'BLACKLISTED',
    SMSC_REJECTED: 'KO',
    INVALID_CONTENTS: 'INVALIDCONTENTS',
  };
}

export const SMS_LIST_FILTER = {
  sender: '',
  recipients: [],
  status: '',
  start: '',
  end: '',
  user: '',
  showCampaigns: false,
};

export const SMS_LIST_PAGE_SIZE = 10;
export const SMS_CAMPAIGNS_PAGE_SIZE = 10;
export const SMS_CREDITS_WARNING_LIMIT = 100;

export const SMS_FILE_SHARING_URL_LENGTH = 82;
export const SMS_TICKET_NOTIFICATION_ID_LENGTH = 20;
export const SMS_TOTAL_CHARS_LENGTH = 1000;
export const SMS_MAX_SMS_SENT_LENGTH = 7;

export const SMSStatusColor = (status) => {
  switch (status) {
    case 'ERROR':
      return 'yn-td-bordered-red';
    case 'DELIVERED':
      return 'yn-td-bordered-green';
    case 'SENT':
    case 'PENDING':
    default:
      return 'yn-td-bordered-yellow';
  }
};

export const aliasStatusColor = (status) => {
  switch (status) {
    case 'AGCOM_BLOCKED':
    case 'GATEWAY_BLOCKED':
      return 'yn-td-bordered-red';
    case 'AGCOM_CONFIRMED':
      return 'yn-td-bordered-green';
    case 'GATEWAY_CONFIRMED':
    case 'PENDING':
    default:
      return 'yn-td-bordered-yellow';
  }
};

export const calculateSMSNumber = (text, placeholderLength) => {
  if (!text || !text.trim().length) return 0;
  return Math.ceil((text.length + placeholderLength) / 160);
};
