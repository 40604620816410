import { takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchGrantsSuccess,
  fetchGrantsFailure,
  fetchUserGrantsSuccess,
  fetchUserGrantsFailure,
  saveUserGrantsSuccess,
  saveUserGrantsFailure,
  saveMeAdvancedGrantsSuccess,
  saveMeAdvancedGrantsFailure,
  fetchProfileGrantsSuccess,
  fetchProfileGrantsFailure,
} from './actions';
import {
  FETCH_GRANTS_REQUEST,
  FETCH_USER_GRANTS_REQUEST,
  SAVE_USER_GRANTS_REQUEST,
  SAVE_ME_ADVANCED_GRANTS_REQUEST,
  FETCH_PROFILE_GRANTS_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

function* fetchGrants() {
  try {
    const res = yield call(api.grants.getGrants);
    yield call(checkApiResponse, res);
    yield put(fetchGrantsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchGrantsFailure(error));
  }
}

function* fetchUserGrants(action) {
  try {
    const res = yield call(api.grants.getUserGrants, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchUserGrantsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserGrantsFailure(error));
  }
}

function* saveUserGrants(action) {
  try {
    const res = yield call(
      api.grants.setUserGrants,
      action.payload.idUser,
      action.payload.grants
    );
    yield call(checkApiResponse, res);
    yield put(saveUserGrantsSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error && err.data && err.data !== '' && err.data.length > 0) {
      yield put(
        saveUserGrantsFailure({ status: err.status, messages: err.data })
      );
    } else {
      yield put(saveUserGrantsFailure({ status: error }));
    }
  }
}

function* saveMeAdvancedGrants(action) {
  try {
    const res = yield call(
      api.grants.setMeAdvancedGrants,
      action.payload.idUser,
      action.payload.grants
    );
    yield call(checkApiResponse, res);
    yield put(saveMeAdvancedGrantsSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveMeAdvancedGrantsFailure({ status: error }));
  }
}

function* fetchProfileGrants(action) {
  try {
    const res = yield call(api.grants.getProfileGrants, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchProfileGrantsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchProfileGrantsFailure(error));
  }
}

export default function* rootSaga() {
  /** Grants */
  yield takeLatest(FETCH_GRANTS_REQUEST, fetchGrants);
  yield takeLatest(FETCH_USER_GRANTS_REQUEST, fetchUserGrants);
  yield takeLatest(SAVE_USER_GRANTS_REQUEST, saveUserGrants);
  yield takeLatest(SAVE_ME_ADVANCED_GRANTS_REQUEST, saveMeAdvancedGrants);
  yield takeLatest(FETCH_PROFILE_GRANTS_REQUEST, fetchProfileGrants);
}
