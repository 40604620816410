export const getRulesById = (state, context) =>
  state.phoneRules[context].rulesById;
export const getRulesIds = (state, context) =>
  state.phoneRules[context].rulesAllIds;

export const getRules = (state, context) =>
  getRulesIds(state, context).map((key) => getRulesById(state, context)[key]);
export const getRule = (state, context, id) => {
  if (!id) {
    return null;
  }
  return getRulesById(state, context) && getRulesById(state, context)[id];
};
export const getRulesNumber = (state, context) =>
  state.phoneRules[context].rulesAllIds.length;
export const retrieveNewRulePriority = (state, context) =>
  getRulesNumber(state, context) + 1;

export const getSelectedPbxForRuleManagement = (state) =>
  state.phoneRules.selectedPbxId;
export const getPbxPhoneRulesFilter = (state) => state.phoneRules.filter;
