import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Filebox from './index';

const FileboxNotifications = React.lazy(() => import('./FileboxNotifications'));
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const FileboxNotificationsPage = (props) => (
  <Filebox scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="notifications-filebox" />
          </div>
          <FileboxNotifications {...props} />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Filebox>
);

export default FileboxNotificationsPage;
