import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import FormSectionTitle from '../../../components/FormSectionTitle';
import Form from '../../../components/Form';
import Button from '../../../components/formControls/Button';
import ToastMessage from '../../../components/messages/ToastMessage';
import Input from '../../../components/formControls/Input';
import { saveWorkingRoundRequest } from '../../../js/workingPlans/actions';
import {
  getRoundWorkingHours,
  getRoundWorkingHoursError,
  getRoundWorkingHoursLoading,
  getRoundWorkingHoursSuccess,
} from '../../../js/workingPlans/selectors';
import Label from '../../../components/formControls/Label';
import UsersSelect from '../../../components/formControls/UsersSelect';
import { FormGroup } from 'reactstrap';
import GroupsMembersShortcuts from '../../../components/GroupsMembersShortcuts';

const messages = defineMessages({
  title: {
    id: 'WorkingHoursRound.label.title',
    defaultMessage: 'Round working hours',
  },
  roundIn: {
    id: 'WorkingHoursRound.label.roundIn',
    defaultMessage: 'Round entrance to next',
  },
  roundOut: {
    id: 'WorkingHoursRound.label.roundOut',
    defaultMessage: 'Round exit to previous',
  },
  users: {
    id: 'WorkingHoursRound.label.users',
    defaultMessage: 'For these users',
  },
  missingField: {
    id: 'WorkingHoursRound.label.missingField',
    defaultMessage: 'Missing field',
  },
  wrongFormat: {
    id: 'WorkingHoursRound.label.wrongFormat',
    defaultMessage: 'It must be a number',
  },
  missingUsers: {
    id: 'WorkingHoursRound.label.missingUsers',
    defaultMessage: 'Select at least an user for that value',
  },
  minutes: {
    id: 'WorkingHoursRound.label.minutes',
    defaultMessage: 'minutes',
  },
  save: {
    id: 'WorkingHoursRound.button.save',
    defaultMessage: 'Save',
  },
  saveSuccess: {
    id: 'WorkingHoursRound.saveSuccess',
    defaultMessage: 'Your data has been saved',
  },
  saveError: {
    id: 'WorkingHoursRound.saveError',
    defaultMessage: 'An error occurred',
  },
});

class WorkingHoursRound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        roundIn: 0,
        roundOut: 0,
      },
      errors: {},
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const roundIn = this.arrangeRoundIn();
    const roundOut = this.arrangeRoundOut();
    const roundUsers = this.arrangeRoundUsers();
    const errors = {};
    if (!roundIn && roundIn !== 0) {
      errors.roundIn = this.props.intl.formatMessage(messages.missingField);
    } else if (isNaN(roundIn)) {
      errors.roundIn = this.props.intl.formatMessage(messages.wrongFormat);
    }
    if (!roundOut && roundOut !== 0) {
      errors.roundOut = this.props.intl.formatMessage(messages.missingField);
    } else if (isNaN(roundOut)) {
      errors.roundOut = this.props.intl.formatMessage(messages.wrongFormat);
    }
    if ((roundIn > 0 || roundOut > 0) && !roundUsers.length) {
      errors.roundUsers = this.props.intl.formatMessage(messages.missingUsers);
    }
    if (Object.keys(errors).length) {
      this.setState({
        errors,
      })
      return;
    }
    this.props.save({
      roundIn,
      roundOut,
      roundUsers,
    });
  };

  arrangeRoundIn = () => {
    return this.state.data.roundIn !== 0
      ? this.state.data.roundIn
      : this.props.workingRound.roundIn
      ? this.props.workingRound.roundIn
      : 0;
  };

  arrangeRoundOut = () => {
    return this.state.data.roundOut !== 0
      ? this.state.data.roundOut
      : this.props.workingRound.roundOut
      ? this.props.workingRound.roundOut
      : 0;
  };

  arrangeRoundUsers = () => {
    return this.state.data.roundUsers
      ? this.state.data.roundUsers
      : this.props.workingRound.roundUsers
      ? this.props.workingRound.roundUsers
      : [];
  };

  handleRoundInChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        roundIn: e.target.value,
      },
      errors: {},
    });
  };

  handleRoundOutChange = (e) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        roundOut: e.target.value,
      },
      errors: {},
    });
  };

  handleUsersSelection = (selected) => {
    this.setState({
      data: {
        ...this.state.data,
        roundUsers: selected,
      },
      errors: {},
    });
  };

  render() {
    const {
      saveError,
      saveSuccess,
      saveLoading,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {saveError && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.saveError)}
          />
        )}
        {saveSuccess && (
          <ToastMessage
            type="success"
            text={formatMessage(messages.saveSuccess)}
          />
        )}
        <Form onSubmit={this.onSubmit} scrolling required={false}>
          <FormSectionTitle className="my-3">
            {formatMessage(messages.title)}
          </FormSectionTitle>
          <div className="form-row mt-4">
            <div className="col-12 custom-control custom-control-inline" style={{lineHeight: '35px'}}>
              <label htmlFor="roundIn">{formatMessage(messages.roundIn)}</label>
              <span className="ml-3">
                <Input
                  maxLength={2}
                  style={{ width: '50px' }}
                  type="text"
                  onChange={this.handleRoundInChange}
                  value={this.arrangeRoundIn()}
                  name="roundIn"
                  id="roundIn"
                  error={this.state.errors.roundIn}
                />
              </span>
              <span className="ml-3">{formatMessage(messages.minutes)}</span>
            </div>
          </div>
          <div className="form-row mt-4">
            <div className="col-12 custom-control custom-control-inline" style={{lineHeight: '35px'}}>
              <label htmlFor="roundIn">
                {formatMessage(messages.roundOut)}
              </label>
              <span className="ml-3">
                <Input
                  maxLength={2}
                  style={{ width: '50px' }}
                  type="text"
                  onChange={this.handleRoundOutChange}
                  value={this.arrangeRoundOut()}
                  name="roundOut"
                  id="roundOut"
                  error={this.state.errors.roundOut}
                />
              </span>
              <span className="ml-3">{formatMessage(messages.minutes)}</span>
            </div>
          </div>
          <div className="form-row">
            <div className="col-xl-6">
              <Label for="users">
                {formatMessage(messages.users)}
              </Label>
              <UsersSelect
                isMulti
                isClearable
                onSelect={this.handleUsersSelection}
                selected={this.arrangeRoundUsers()}
                required
                error={this.state.errors.roundUsers}
              />
            </div>
            <div className="col-xl-6 mt-xl-4">
              <FormGroup>
                <GroupsMembersShortcuts
                  includeMyself={false}
                  includeFavourites={false}
                  allowedContacts={false}
                  onSelect={(members) =>
                    this.handleUsersSelection(members)
                  }
                />
              </FormGroup>
            </div>
          </div>
          <div className="pt-3 text-center">
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.save)}
              loading={saveLoading}
            />
          </div>
        </Form>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    saveLoading: getRoundWorkingHoursLoading(state),
    saveError: getRoundWorkingHoursError(state),
    saveSuccess: getRoundWorkingHoursSuccess(state),
    workingRound: getRoundWorkingHours(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    save: saveWorkingRoundRequest,
  })(WorkingHoursRound)
);
