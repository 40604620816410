import React from 'react';

const style = {
  color: 'var(--yn-blue-darker)',
  fontSize: '1.3em',
  fontWeight: 400,
  padding: '0 0.3em 0 0.3em',
};

const FormSectionTitle = ({ className, children, mandatory }) => (
  <div className={`border-bottom ${className || ''}`} style={style}>
    {children}
    {mandatory && ' *'}
  </div>
);

export default FormSectionTitle;
