import React from 'react';
import PropTypes from 'prop-types';

const FormFieldError = ({ text, style }) => {
  const errors = Array.isArray(text) ? text : [text];
  return (
    <>
      {errors.map((error, index) => (
        <div key={index} style={style} className="invalid-feedback">
          {error}
        </div>
      ))}
    </>
  );
};

FormFieldError.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  style: PropTypes.object,
};

FormFieldError.defaultProps = {
  text: '',
  style: {},
};

export default FormFieldError;
