import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Fade } from 'reactstrap';
import SectionTitle from '../../../components/SectionTitle';

const messages = defineMessages({
  title: {
    id: 'Notes.title',
    defaultMessage: 'Notes',
  },
});

const Notes = ({ intl: { formatMessage }, children }) => (
  <div className="border rounded bg-white h-100">
    <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
    <Fade className="yn-section-scrolling">{children}</Fade>
  </div>
);

export default injectIntl(Notes);
