import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from './Button';
import { hideConfirmModal } from '../../js/confirm/actions';

const messages = defineMessages({
  yes: {
    id: 'ConfirmModal.button.confirm',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'ConfirmModal.button.cancel',
    defaultMessage: 'No',
  },
});

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  confirm(e) {
    e.preventDefault();
    if (this.props.confirm.modalAction)
      this.props.confirm.modalAction(this.props.confirm.actionParams);
    if (this.props.confirm.modalCallback) this.props.confirm.modalCallback();
    this.props.hide();
  }

  cancel(e) {
    e.preventDefault();
    this.props.hide();
  }

  render() {
    const {
      intl: { formatMessage },
      confirm,
    } = this.props;

    return (
      <Modal isOpen={confirm.isOpen}>
        <ModalBody>{confirm.message}</ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            text={formatMessage(messages.yes)}
            onClick={this.confirm}
          />
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.no)}
            onClick={this.cancel}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    confirm: state.confirm,
  };
}

function mapDispatchToProps() {
  return {
    hide: hideConfirmModal,
  };
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps())(ConfirmModal)
);
