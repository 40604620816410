import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneNumbersRow from './PhoneNumbersRow';

class PhoneNumbers extends Component {
  handleChange = (number, idx) => {
    const { numbers } = this.props;
    numbers[idx] = number;
    this.props.onChange(numbers);
  };

  handleAdd = () => {
    const { numbers } = this.props;
    numbers.push({ type: 'MOBILE', number: '' });
    this.props.onChange(numbers);
  };

  handleRemove = (idx) => {
    const { numbers } = this.props;
    numbers.splice(idx, 1);
    this.props.onChange(numbers);
  };

  render() {
    return this.props.numbers.map((v, idx) => (
      <PhoneNumbersRow
        key={idx}
        type={v.type}
        number={v.number}
        error={this.props.errors && this.props.errors[idx]}
        onChange={(number) => this.handleChange(number, idx)}
        onAdd={
          idx === this.props.numbers.length - 1 ? () => this.handleAdd() : null
        }
        onRemove={
          idx !== this.props.numbers.length - 1
            ? () => this.handleRemove(idx)
            : null
        }
      />
    ));
  }
}

PhoneNumbers.propTypes = {
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      error: PropTypes.string,
    })
  ).isRequired,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default PhoneNumbers;
