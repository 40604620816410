import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';
import WorkingHoursRound from './WorkingHoursRound';
import WorkingHoursBreak from './WorkingHoursBreak';

const WorkingHoursList = React.lazy(() => import('./WorkingHoursList'));

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const WorkingUsersPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div className="text-right">
            <HelpButton fileName="working-hours" />
          </div>
          <WorkingHoursRound />
          <div className='mt-1' />
          <WorkingHoursBreak />
          <WorkingHoursList {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default WorkingUsersPage;
