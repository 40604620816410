export class WorkingPlansEnums {
  static daysOfWeekById = {
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
    7: 'sunday',
  };

  static WORKING_PLANS_PAGE_SIZE = 10;
}
