import {
  FETCH_MEDIADEVICES_SUCCESS,
  FETCH_MEDIADEVICES_REQUEST,
  FETCH_MEDIADEVICES_FAILURE,
  GET_USERMEDIA_FAILURE,
  GET_USERMEDIA_SUCCESS,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialState = {
  available: false,
  fetchAudioDevicesLoaded: true,
  fetchAudioDevicesError: false,
  fetchVideoDevicesLoaded: true,
  fetchVideoDevicesError: false,
  getAudioDevicesLoaded: true,
  getAudioDevicesError: false,
  getVideoDevicesLoaded: true,
  getVideoDevicesError: false,
  mediastream: null,
  audioEnabled: false,
  videoEnabled: false,
  devices: {
    audioIn: {
      list: [],
      // selected: null,
    },
    videoIn: {
      list: [],
      // selected: null,
    },
  },
};

export function media(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_MEDIADEVICES_REQUEST:
      return {
        ...state,
        fetchAudioDevicesLoaded: action.payload && !action.payload.audio,
        fetchAudioDevicesError: false,
        fetchVideoDevicesLoaded: action.payload && !action.payload.video,
        fetchVideoDevicesError: false,
      };
    case FETCH_MEDIADEVICES_SUCCESS: {
      if (!action.data.available)
        return {
          ...state,
          fetchAudioDevicesLoaded: true,
          fetchAudioDevicesError: false,
          fetchVideoDevicesLoaded: true,
          fetchVideoDevicesError: false,
        };

      const audioInList =
        action.data.devices.filter((d) => d.kind === 'audioinput').length > 0
          ? action.data.devices.filter((d) => d.kind === 'audioinput')
          : [];
      const videoInList =
        action.data.devices.filter((d) => d.kind === 'audioinput').length > 0
          ? action.data.devices.filter((d) => d.kind === 'videoinput')
          : [];
      return {
        ...state,
        available: true,
        fetchAudioDevicesLoaded: true,
        fetchAudioDevicesError: false,
        fetchVideoDevicesLoaded: true,
        fetchVideoDevicesError: false,
        devices: {
          audioIn: { list: audioInList },
          videoIn: {
            list: videoInList,
          },
        },
      };
    }
    case FETCH_MEDIADEVICES_FAILURE:
      return {
        ...state,
        fetchAudioDevicesLoaded: true,
        fetchAudioDevicesError: true,
        fetchVideoDevicesLoaded: true,
        fetchVideoDevicesError: true,
        devices: {
          audioIn: {
            list: [],
          },
          videoIn: {
            list: [],
          },
        },
      };
    case GET_USERMEDIA_SUCCESS:
      return {
        ...state,
        getAudioDevicesLoaded: true,
        getAudioDevicesError: false,
        getVideoDevicesLoaded: true,
        getVideoDevicesError:
          action.data && action.data.error ? action.data.error.video : false,
        mediastream: action.data.stream,
        audioEnabled: action.data.audio,
        videoEnabled: action.data.video || state.videoEnabled,
      };
    case GET_USERMEDIA_FAILURE:
      return {
        ...state,
        getAudioDevicesLoaded: true,
        getAudioDevicesError: action.error && action.error.audio,
        getVideoDevicesLoaded: true,
        getVideoDevicesError: action.error && action.error.video,
        audioEnabled: false,
        videoEnabled: action.data ? action.data.video : false,
      };
    default:
      return state;
  }
}
