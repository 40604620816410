import { takeLatest, call, put, select } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';
import { push } from 'connected-react-router';
import { YnSid } from '../lib/YnCookies';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_REQUEST,
  GUEST_LOGIN_REQUEST,
  TECH_LOGIN_REQUEST,
} from './types';
import {
  loginSuccess,
  loginFailure,
  logoutFailure,
  logoutSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
  guestLoginSuccess,
  guestLoginFailure,
  authSuccess,
  techLoginSuccess,
  techLoginFailure,
} from './actions';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { isAuthenticated } from './selectors';

const decode = () => jwt.decode(YnSid.get());

const removeCookie = () => YnSid.remove();

export function* login(action) {
  try {
    const res = yield call(api.auth.login, action.credentials);
    yield call(checkApiResponse, res);
    const videocallRoom = sessionStorage.getItem('videocallRoom');
    if (videocallRoom) {
      yield put(push(`/videocall/exec/${videocallRoom}`));
      sessionStorage.removeItem('videocallRoom');
    } else {
      yield put(push('/calls'));
    }
    yield put(authSuccess());
    yield put(loginSuccess(decode()));
  } catch (err) {
    yield put(loginFailure(err));
  }
}

export function* resetPassword(action) {
  try {
    const res = yield call(api.auth.resetPassword, action.payload);
    yield call(checkApiResponse, res);
    yield put(resetPasswordSuccess());
    const isLogged = yield select(isAuthenticated);
    if (!isLogged) yield put(push('/password-reset'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(resetPasswordFailure(error));
  }
}

export function* logout() {
  try {
    const res = yield call(api.auth.logout);
    yield call(checkApiResponse, res);
    yield put(logoutSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(logoutFailure(error));
  }
}

export function* guestLogin(action) {
  try {
    const res = yield call(api.auth.guestLogin, action.credentials);
    yield call(checkApiResponse, res);
    yield put(push('/videocall/guest/exec'));
    yield put(guestLoginSuccess(decode()));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(guestLoginFailure(error));
  }
}

export function* techLogin(action) {
  try {
    const res = yield call(api.auth.techLogin, action.credentials);
    yield call(checkApiResponse, res);
    if (res.status === 200) {
      yield put(techLoginSuccess());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(techLoginFailure());
  }
}

export default function* rootSaga() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(LOGOUT_SUCCESS, removeCookie);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(GUEST_LOGIN_REQUEST, guestLogin);
  yield takeLatest(TECH_LOGIN_REQUEST, techLogin);
}
