import ynConf from '../../conf';

export class MailofficeEnums {
  static Status = {
    NEW: 'NEW',
    VALID: 'VALID',
    PENDING: 'PENDING',
    PRICED: 'PRICED',
    CONFIRMED: 'CONFIRMED',
    PRINTING: 'PRINTING',
    STOCK: 'STOCK',
    TRANSIT: 'TRANSIT',
    SENT: 'SENT',
    COMPLETED: 'COMPLETED',
    DELIVERED: 'DELIVERED',
    RETURNED: 'RETURNED',
    ERROR: 'ERROR',
    REJECTED: 'REJECTED',
  };

  static Types = {
    RACCOMANDATA: 'RACCOMANDATA',
    POSTA1: 'POSTA1',
    POSTA4: 'POSTA4',
    TELEGRAMMA: 'TELEGRAMMA',
  };

  static ReceiverTypes = {
    SINGLE: 'SINGLE',
    LIST: 'LIST',
  };

  static FormSteps = {
    SERVICE: 'SERVICE',
    RECEIVER: 'RECEIVER',
    SENDER: 'SENDER',
    DOCUMENT: 'DOCUMENT',
  };
}

export const statusColor = (status) => {
  switch (status) {
    case 'ERROR':
    case 'REJECTED':
      return 'yn-td-bordered-red';
    case 'SENT':
    case 'RETURNED':
    case 'DELIVERED':
    case 'COMPLETED':
      return 'yn-td-bordered-green';
    case 'NEW':
    case 'VALID':
    case 'PRICED':
    case 'CONFIRMED':
    case 'PRINTING':
    case 'STOCK':
    case 'TRANSIT':
    case 'PENDING':
    default:
      return 'yn-td-bordered-yellow';
  }
};

export const statusError = (status) => {
  switch (status) {
    case 'ERROR':
    case 'REJECTED':
      return true;
    default:
      return false;
  }
};
export const switchMailofficeImg = (service) => {
  switch (service) {
    case 'raccomandata':
    default:
      return `${ynConf.publicFolderPath}/mailoffice/raccomandata.png`;
    case 'posta1':
      return `${ynConf.publicFolderPath}/mailoffice/posta1.png`;
    case 'posta4':
      return `${ynConf.publicFolderPath}/mailoffice/posta4.png`;
    case 'telegramma':
      return `${ynConf.publicFolderPath}/mailoffice/telegramma.png`;
  }
};

export const MAILOFFICE_ARCHIVE_PAGE_SIZE = 10;

export const adjustMailofficeData = (data) => {
  const adjustedData = {
    id: data.id,
    type: data.type,
    datetime: data.datetime,
    user: data.user,
    historyuser: data.historyuser,
    document: data.document,
    text: data.text,
    options: data.options,
    price: data.price,
    sender: {
      name: data.sender_name,
      company: data.sender_name2,
      address: data.sender_address,
      streetnumber: data.sender_streetnumber,
      locality: data.sender_locality || data.sender_town,
      town: data.sender_town,
      postalcode: data.sender_postalcode,
      postaloffice: data.sender_postaloffice,
      postalofficenumber: data.sender_postalofficenumber,
      district: data.sender_district,
      nation: data.sender_nation,
    },
    status: Array.isArray(data.status)
      ? data.status
      : [{ status: data.status }],
    error: data.error,
    raccomandataNumber: data.raccomandata_number,
    returnReceipt: data.returnReceipt,
  };

  if (data.distributionList) {
    adjustedData.distributionList = data.distributionList;
  }
  if (data.receiver || data.receiver_name) {
    adjustedData.receiver = {
      name: data.receiver || data.receiver_name,
      company: data.receiver_name2,
      address: data.receiver_address,
      streetnumber: data.receiver_streetnumber,
      locality: data.receiver_locality || data.receiver_town,
      town: data.receiver_town,
      postalcode: data.receiver_postalcode,
      postaloffice: data.receiver_postaloffice,
      postalofficenumber: data.receiver_postalofficenumber,
      district: data.receiver_district,
      nation: data.receiver_nation,
    };
  }
  return adjustedData;
};

export const addDistributionListName = (data, lists) => {
  if (!data) return {};

  if (data.distributionList) {
    const list = lists[data.distributionList];
    return { ...data, distributionListName: list && list.name };
  }
  return data;
};
