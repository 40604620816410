import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import { SMS_CAMPAIGNS_PAGE_SIZE } from '../../../js/sms/SMSUtils';
import {
  fetchSMSAliasRequest,
  fetchSMSScheduledRequest,
} from '../../../js/sms/actions';
import {
  getSMSCredits,
  showIncomingSMSAlert,
  getFetchSMSCreditsError,
  isSMSCreditsFetched,
  usingSMSAlias,
  getFetchSMSScheduledLoaded,
  getFetchSMSScheduledError,
  getSMSScheduledIds,
  getSMSCampaignsTotal,
  getSMSCampaignsCurrentPage,
} from '../../../js/sms/selectors';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/formControls/Button';
import Alert from '../../../components/messages/Alert';
import SMSScheduledRow from './SMSScheduledRow';
import SMSCreditsAlert from './SMSCreditsAlert';
import HelpButton from '../../../components/HelpButton';
import { isSMSenabled } from '../../../js/me/selectors';

const messages = defineMessages({
  scheduleSMSButton: {
    id: 'SMSScheduled.scheduleSMSButton',
    defaultMessage: 'Schedule an SMS',
  },
  creditsWarningText: {
    id: 'SMSScheduled.creditsWarningText',
    defaultMessage: 'Hei! You have only {credits} more available SMS!',
  },
  creditsAlertText: {
    id: 'SMSScheduled.creditsAlertText',
    defaultMessage: 'You have no more available SMS',
  },
  incomingSMSAlertText: {
    id: 'SMSScheduled.incomingSMSAlertText',
    defaultMessage:
      'In the next week some SMS have to be sent! Check if you have enough credits',
  },
  errorFetchingCredits: {
    id: 'SMSScheduled.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  scheduledDate: {
    id: 'SMSScheduled.label.scheduledDate',
    defaultMessage: 'Scheduled date',
  },
  user: {
    id: 'SMSScheduled.label.user',
    defaultMessage: 'User',
  },
  recipients: {
    id: 'SMSScheduled.label.recipients',
    defaultMessage: 'Recipients',
  },
  sender: {
    id: 'SMSScheduled.label.sender',
    defaultMessage: 'Sender',
  },
  noData: {
    id: 'SMSScheduled.noData',
    defaultMessage: 'There is nothing to show',
  },
});

class SMSScheduled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { useAlias, fetchAlias } = this.props;
    if (useAlias) {
      fetchAlias();
    }
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { filter, useAlias, fetchAlias } = this.props;
    if (filter !== prevProps.filter) this.fetchItems();
    if (useAlias && !prevProps.useAlias) {
      fetchAlias();
    }
  }

  fetchItems = () => {
    const { fetch } = this.props;
    fetch();
  };

  render() {
    const {
      intl: { formatMessage },
      fetching,
      fetchingError,
      pages,
      page,
      smsIds,
      credits,
      showAlert,
      creditsFetched,
      fetchingCreditsError,
      useAlias,
      smsEnabled,
    } = this.props;

    return !smsEnabled ? (
      <></>
    ) : fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        <div className="text-right">
          <HelpButton fileName="sms-scheduled" />
        </div>
        {creditsFetched && <SMSCreditsAlert credits={credits} />}
        {showAlert && creditsFetched && (
          <Alert
            text={formatMessage(messages.incomingSMSAlertText)}
            type="danger"
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        <div className="text-right mb-2">
          <Link to="/sms/scheduled/edit">
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.scheduleSMSButton)}
            />
          </Link>
        </div>
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th>{formatMessage(messages.scheduledDate)}</th>
                  <th>{formatMessage(messages.recipients)}</th>
                  {useAlias && <th>{formatMessage(messages.sender)}</th>}
                  <th>{formatMessage(messages.user)}</th>
                  <th />
                </tr>
              </thead>
              {smsIds && smsIds.length > 0 ? (
                <tbody>
                  {smsIds.map((id) => (
                    <SMSScheduledRow key={id} id={id} />
                  ))}
                </tbody>
              ) : (
                <tfoot>
                  <tr>
                    <td colSpan="6">{formatMessage(messages.noData)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
        {pages > 1 && (
          <Pagination
            pages={pages}
            currentPage={page}
            handlePage={this.handlePage}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: !getFetchSMSScheduledLoaded(state),
    fetchingError: getFetchSMSScheduledError(state),
    smsIds: getSMSScheduledIds(state),
    credits: getSMSCredits(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    creditsFetched: isSMSCreditsFetched(state),
    showAlert: showIncomingSMSAlert(state),
    useAlias: usingSMSAlias(state),
    pages: Math.ceil(getSMSCampaignsTotal(state) / SMS_CAMPAIGNS_PAGE_SIZE),
    page: getSMSCampaignsCurrentPage(state),
    smsEnabled: isSMSenabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchSMSScheduledRequest,
    fetchAlias: fetchSMSAliasRequest,
  })(SMSScheduled)
);
