import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  isChatConversationHistoryLoaded,
  getDayGroupsMessagesByConversation,
  isChatConversationSendingError,
  getLastMessageByConversation,
  isChatConversationHistoryError,
  isChatConversationHistoryCompleted,
  isFirstHistoryLoaded,
  getUnreadUnfetchedMessagesByConversation,
  canScrollToBottom,
  getBigConversationId,
  isChatConversationFirstMessagesLoaded,
  getChatMessageById,
  isFetchRecentHistoryLoaded,
  isFetchOldHistoryLoaded,
  getMuteChatError,
} from '../../../js/chat/selectors';
import Loading from '../../../components/Loading';
import ToastMessage from '../../../components/messages/ToastMessage';
import ChatHistoryDay from './ChatHistoryDay';
import ChatWindowComposing from './ChatWindowComposing';
import {
  fetchConversationHistoryRequest,
  unsetChatSearchResult,
} from '../../../js/chat/actions';
import {
  ChatEnums,
  retrieveMessageId,
  retrieveInterlocutorIdFromConversationId,
  CHAT_EMOJI_PANEL_HEIGHTS,
  CHAT_TOOLBAR_HEIGHT,
} from '../../../js/chat/ChatUtils';
import NotificationBadge from '../../../components/NotificationBadge';
import ChatMessageInfoTemplate from './ChatMessageInfoTemplate';
import { getYnPageHeight } from '../../../js/ui/selectors';
import { getMeId } from '../../../js/me/selectors';

const messages = defineMessages({
  errorSendingMessage: {
    id: 'ChatHistory.error.errorSendingMessage',
    defaultMessage: 'Error sending message',
  },
  errorSendingFiles: {
    id: 'ChatHistory.error.errorSendingFiles',
    defaultMessage: 'Files too big',
  },
  errorLoading: {
    id: 'ChatHistory.error.errorLoading',
    defaultMessage: 'Error loading history',
  },
  startHistory: {
    id: 'ChatHistory.Label.startHistory',
    defaultMessage: 'Start',
  },
  groupRemoved: {
    id: 'ChatHistory.label.groupRemoved',
    defaultMessage: 'The group has been removed',
  },
  userRemoved: {
    id: 'ChatHistory.label.userRemoved',
    defaultMessage: 'The user has been removed',
  },
  isMeOut: {
    id: 'ChatHistory.label.isMeOut',
    defaultMessage: 'You are not into this group',
  },
  errorMuting: {
    id: 'ChatHistory.error.errorMuting',
    defaultMessage: 'Error muting/unmuting chat',
  },
});

class ChatHistory extends React.Component {
  constructor(props) {
    super(props);
    this.outerDiv = React.createRef();
    this.innerDiv = React.createRef();
    this.fetchHistory = this.fetchHistory.bind(this);
    this.scrollToSearchedMessage = this.scrollToSearchedMessage.bind(this);
    this.state = {
      historyVisibleHeight: props.height,
      historyVisibleTop: 0,
      previousScroll: null,
      manualScroll: false,
      scrolledBottom: false,
    };
  }

  componentDidMount() {
    const node = this.outerDiv.current;
    if (node) {
      this.outerDiv.current.addEventListener('scroll', this.handleScroll);
      this.outerDiv.current.addEventListener(
        'mousedown',
        this.handleManualScroll
      );
      this.outerDiv.current.addEventListener(
        'mouseup',
        this.handleManualScroll
      );
      this.outerDiv.current.addEventListener('wheel', this.handleManualScroll);
      this.outerDiv.current.addEventListener(
        'DOMMouseScroll',
        this.handleManualScroll
      );
      this.outerDiv.current.addEventListener(
        'mousewheel',
        this.handleManualScroll
      );
      this.outerDiv.current.addEventListener('keyup', this.handleManualScroll);
    }
    this.scrollToBottom();
  }

  shouldComponentUpdate(nextprops, nextstate) {
    if (
      this.state.historyVisibleHeight !== nextstate.historyVisibleHeight ||
      this.state.historyVisibleTop !== nextstate.historyVisibleTop
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevprops) {
    const {
      meId,
      lastMessage,
      firstHistoryLoaded,
      firstMessagesLoaded,
      fetchRecentHistoryLoaded,
      fetchOldHistoryLoaded,
      dayGroups,
      loading,
      emojiPanelOpen,
      bigConversation,
      conversationSize,
      windowPosition,
    } = this.props;

    if (
      lastMessage &&
      prevprops.lastMessage &&
      (lastMessage.senderId !== prevprops.lastMessage.senderId ||
        lastMessage.timestamp !== prevprops.lastMessage.timestamp)
    ) {
      if (
        meId === lastMessage.senderId ||
        !this.state.manualScroll ||
        this.state.scrolledBottom
      ) {
        this.scrollToBottom();
      }
    }
    if (
      (firstMessagesLoaded && !prevprops.firstMessagesLoaded) ||
      (!firstMessagesLoaded &&
        !(fetchOldHistoryLoaded && !prevprops.fetchOldHistoryLoaded) &&
        (dayGroups.length !== prevprops.dayGroups.length ||
          dayGroups[0].messages.length !==
            prevprops.dayGroups[0].messages.length))
    ) {
      this.scrollToBottom();
    }
    if (!prevprops.firstHistoryLoaded && firstHistoryLoaded && !loading) {
      this.scrollToBottom();
    }
    if (windowPosition !== prevprops.windowPosition) {
      this.scrollToBottom();
    }
    if (!prevprops.fetchRecentHistoryLoaded && fetchRecentHistoryLoaded) {
      this.scrollToBottom();
    }
    if (
      prevprops.conversationSize !== conversationSize &&
      prevprops.conversationSize === ChatEnums.ChatWindowSize.MIN
    ) {
      this.scrollToBottom();
    }
    if (
      emojiPanelOpen !== prevprops.emojiPanelOpen &&
      bigConversation === prevprops.bigConversation &&
      this.outerDiv
    ) {
      this.setState({ previousScroll: this.outerDiv.current.scrollTop });
      let scrollValue = emojiPanelOpen
        ? CHAT_EMOJI_PANEL_HEIGHTS[conversationSize] + CHAT_TOOLBAR_HEIGHT
        : (CHAT_EMOJI_PANEL_HEIGHTS[conversationSize] + CHAT_TOOLBAR_HEIGHT) *
          -1;
      if (
        scrollValue < 0 &&
        this.state.previousScroll &&
        this.state.previousScroll + this.props.height - scrollValue >=
          this.outerDiv.current.scrollHeight
      ) {
        scrollValue =
          this.state.previousScroll +
          this.props.height -
          this.outerDiv.current.scrollHeight;
      }
      this.outerDiv.current.scrollBy({
        top: scrollValue,
        behavior: 'smooth',
      });
    }
  }

  componentWillUnmount() {
    if (this.outerDiv.current) {
      this.outerDiv.current.removeEventListener('scroll', this.handleScroll);
      this.outerDiv.current.removeEventListener(
        'mousedown',
        this.handleManualScroll
      );
      this.outerDiv.current.removeEventListener(
        'mouseup',
        this.handleManualScroll
      );
      this.outerDiv.current.removeEventListener(
        'wheel',
        this.handleManualScroll
      );
      this.outerDiv.current.removeEventListener(
        'DOMMouseScroll',
        this.handleManualScroll
      );
      this.outerDiv.current.removeEventListener(
        'mousewheel',
        this.handleManualScroll
      );
      this.outerDiv.current.removeEventListener(
        'keyup',
        this.handleManualScroll
      );
    }
  }

  scrollToBottom = () => {
    setImmediate(
      () =>
        this.innerDiv.current &&
        this.innerDiv.current.scrollIntoView(false, { behavior: 'smooth' })
    );
    this.setState({
      manualScroll: false,
      scrolledBottom: false,
    });
  };

  handleManualScroll = () => {
    this.setState({
      manualScroll: true,
    });
  };

  handleScroll = () => {
    if (!this.innerDiv.current || !this.outerDiv.current) return;
    this.setState({
      historyVisibleTop: this.outerDiv.current.scrollTop,
      historyVisibleHeight: this.outerDiv.current.clientHeight,
      scrolledBottom:
        this.state.manualScroll &&
        this.innerDiv.current.clientHeight - this.state.historyVisibleTop <
          this.state.historyVisibleHeight,
    });
  };

  fetchHistory = () => {
    let lastMessage;
    if (this.props.dayGroups && this.props.dayGroups.length > 0) {
      const lastDayGroup =
        this.props.dayGroups[this.props.dayGroups.length - 1];
      lastMessage = lastDayGroup.messages[lastDayGroup.messages.length - 1];
    }
    this.props.fetchHistory({
      conversationId: this.props.conversationId,
      beforeMessage: lastMessage ? retrieveMessageId(lastMessage) : null,
    });
  };

  fetchRecentHistory = () => {
    let recentMessage;
    if (this.props.dayGroups && this.props.dayGroups.length > 0) {
      if (this.props.dayGroups[0].messages.length > 1) {
        recentMessage = this.props.dayGroups[0].messages[1];
      } else if (this.props.dayGroups[1]) {
        recentMessage = this.props.dayGroups[1].messages[0];
      }
    }
    if (recentMessage) {
      const message = this.props.messageById(retrieveMessageId(recentMessage));
      if (message) {
        this.props.fetchHistory({
          conversationId: this.props.conversationId,
          afterMessage: message.timestamp + 1,
        });
      }
    }
  };

  scrollToSearchedMessage = (top) => {
    if (!this.outerDiv.current) return;
    this.props.unsetChatResult();
    setImmediate(() => {
      this.outerDiv.current.scrollTo({ top });
    });
  };

  onPreviewLoaded = () => {
    this.scrollToBottom();
  };

  render() {
    const {
      intl: { formatMessage },
      conversationId,
      dayGroups,
      historyCompleted,
      firstMessagesLoaded,
      unreadUnfetchedMessages,
      loading,
      errorLoading,
      fetchingMembers,
      errors,
      deletingEnabled,
      onChangeDeleting,
      isRemoved,
      isMeOut,
      height,
      maxHeight,
      errorMuting,
    } = this.props;

    const { historyVisibleHeight, historyVisibleTop } = this.state;

    const isChatGroup =
      !!retrieveInterlocutorIdFromConversationId(conversationId).groupId;

    return (
      <div
        id={`chatHistory-${conversationId}`}
        ref={this.outerDiv}
        className="p-1"
        style={{
          height: `${height}px`,
          maxHeight: `${maxHeight}px`,
          overflowY: 'auto',
          overflowAnchor: 'none',
        }}
      >
        {errorLoading && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.errorLoading)}
            disableAutoClose
            dismissable={false}
          />
        )}
        {loading || (!isRemoved && fetchingMembers) ? (
          <Loading style={{ height: null }} />
        ) : historyCompleted ? (
          <div className="text-center">
            {formatMessage(messages.startHistory)}
          </div>
        ) : (
          <div
            className="text-center"
            style={{ cursor: 'pointer' }}
            onClick={this.fetchHistory}
          >
            ...
            {unreadUnfetchedMessages > 0 && (
              <NotificationBadge
                number={unreadUnfetchedMessages}
                top="0px"
                left="6px"
                style={{ position: 'relative' }}
              />
            )}
          </div>
        )}
        <div ref={this.innerDiv}>
          {!loading && dayGroups && (
            <>
              {dayGroups
                .slice(0)
                .reverse()
                .map(
                  (dayGroup, index) =>
                    (firstMessagesLoaded ||
                      index < dayGroups.length - 1 ||
                      (index === dayGroups.length - 1 &&
                        dayGroup.messages.length > 1)) && (
                      <ChatHistoryDay
                        // eslint-disable-next-line
                        isChatGroup={isChatGroup}
                        conversationId={conversationId}
                        key={index}
                        day={dayGroup.day}
                        messagesList={
                          !firstMessagesLoaded &&
                          index === dayGroups.length - 1 &&
                          dayGroup.messages.length > 1
                            ? dayGroup.messages.slice(1).reverse()
                            : dayGroup.messages.slice(0).reverse()
                        }
                        historyVisibleHeight={historyVisibleHeight}
                        historyVisibleTop={historyVisibleTop}
                        deletingEnabled={deletingEnabled}
                        onChangeDeleting={onChangeDeleting}
                        scrollToSearchedMessage={this.scrollToSearchedMessage}
                        onRetrySendFile={this.props.onRetrySendFile}
                        onRetrySendMessage={this.props.onRetrySendMessage}
                        onPreviewLoaded={this.onPreviewLoaded}
                      />
                    )
                )}
              {isRemoved && (
                <ChatMessageInfoTemplate
                  style={{
                    backgroundColor: 'var(--yn-danger-background-color',
                  }}
                >
                  {formatMessage(
                    retrieveInterlocutorIdFromConversationId(conversationId)
                      .groupId
                      ? messages.groupRemoved
                      : messages.userRemoved
                  )}
                </ChatMessageInfoTemplate>
              )}
              {!isRemoved && !fetchingMembers && isMeOut && (
                <ChatMessageInfoTemplate
                  style={{
                    backgroundColor: 'var(--yn-danger-background-color',
                  }}
                >
                  {formatMessage(messages.isMeOut)}
                </ChatMessageInfoTemplate>
              )}
            </>
          )}
          {errors && errors.files && (
            <span className="ml-2" style={{ color: 'var(--red)' }}>
              {formatMessage(messages.errorSendingFiles)}
            </span>
          )}
          {errorMuting && (
            <ToastMessage
              type="danger"
              text={formatMessage(messages.errorMuting)}
              closeTimeout={10000}
            />
          )}
          {!isRemoved && firstMessagesLoaded && (
            <div style={{ height: '1.5em' }}>
              <ChatWindowComposing conversationId={conversationId} />
            </div>
          )}
          {/* TODO <div className="rounded-circle" style={{position: 'absolute', top: height < maxHeight ? height - 50 : maxHeight - 50, right: 10, width: '20px'}}>+</div>*/}
        </div>
        {!firstMessagesLoaded && (
          <div
            className="text-center"
            style={{ cursor: 'pointer' }}
            onClick={this.fetchRecentHistory}
          >
            ...
          </div>
        )}
        <div
          id={`lastHistory-${conversationId}`}
          style={{ overflowAnchor: 'auto', height: '1px' }}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    dayGroups: getDayGroupsMessagesByConversation(
      state,
      ownProps.conversationId
    ),
    firstHistoryLoaded: isFirstHistoryLoaded(state, ownProps.conversationId),
    fetchRecentHistoryLoaded: isFetchRecentHistoryLoaded(
      state,
      ownProps.conversationId
    ),
    fetchOldHistoryLoaded: isFetchOldHistoryLoaded(
      state,
      ownProps.conversationId
    ),
    historyCompleted: isChatConversationHistoryCompleted(
      state,
      ownProps.conversationId
    ),
    firstMessagesLoaded: isChatConversationFirstMessagesLoaded(
      state,
      ownProps.conversationId
    ),
    loading: !isChatConversationHistoryLoaded(state, ownProps.conversationId),
    errorLoading: isChatConversationHistoryError(
      state,
      ownProps.conversationId
    ),
    errorSending: isChatConversationSendingError(
      state,
      ownProps.conversationId
    ),
    lastMessage: getLastMessageByConversation(state, ownProps.conversationId),
    unreadUnfetchedMessages: getUnreadUnfetchedMessagesByConversation(
      state,
      ownProps.conversationId
    ),
    scrollToBottom: canScrollToBottom(state, ownProps.conversationId),
    pageHeigth: getYnPageHeight(state),
    bigConversation: getBigConversationId(state),
    meId: getMeId(state),
    messageById: (messageId) => getChatMessageById(state, messageId),
    errorMuting: getMuteChatError(state, ownProps.conversationId),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchHistory: fetchConversationHistoryRequest,
    unsetChatResult: unsetChatSearchResult,
  })(ChatHistory)
);
