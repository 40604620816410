import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import uniqueid from 'lodash.uniqueid';
import Input from '../formControls/Input';
import Icon from '../icons/Icon';
import PhoneNumberTypeSelect from './PhoneNumberTypeSelect';

export const messages = defineMessages({
  tooltipRemove: {
    id: 'PhoneNumbersRow.tooltip.remove',
    defaultMessage: 'Remove',
  },
  tooltipAdd: {
    id: 'PhoneNumbersRow.tooltip.add',
    defaultMessage: 'Add',
  },
  placeholder: {
    id: 'PhoneNumbersRow.placeholder',
    defaultMessage: 'New number',
  },
});

class PhoneNumbersRow extends Component {
  state = {
    type: undefined,
    number: undefined,
  };

  onChange = () => {
    this.props.onChange({
      type: this.arrangeType(),
      number: this.arrangeNumber(),
    });
  };

  handleTypeChange = (option) => {
    this.setState({ type: option.value }, () => this.onChange());
  };

  handleNumberChange = (e) => {
    this.setState({ number: e.target.value }, () => this.onChange());
  };

  arrangeType = () =>
    this.state.type === undefined ? this.props.type : this.state.type;

  arrangeNumber = () =>
    this.state.number === undefined ? this.props.number : this.state.number;

  render() {
    const {
      onAdd,
      onRemove,
      error,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="form-row">
        <div className="col-4">
          <PhoneNumberTypeSelect
            selected={this.arrangeType()}
            handleChange={this.handleTypeChange}
          />
        </div>
        <div className="form-group col-7">
          <Input
            type="text"
            id={uniqueid('PhoneNumberNumber-')}
            name="PhoneNumberNumber"
            value={this.arrangeNumber()}
            onChange={this.handleNumberChange}
            error={error}
          />
        </div>
        {onAdd && (
          <div className=" form-group col-1">
            <span className="text-center" onClick={onAdd}>
              <Icon
                className=" pt-2 ml-2 text-center"
                name="add-plus"
                width={16}
                height={16}
                color="var(--yn-blue-dark)"
                tooltipPosition="right"
              >
                {formatMessage(messages.tooltipAdd)}
              </Icon>
            </span>
          </div>
        )}
        {onRemove && (
          <div className=" form-group col-1">
            <span className="text-center" onClick={onRemove}>
              <Icon
                className=" pt-2 ml-2 text-center"
                name="remove-minus"
                width={16}
                height={16}
                color="var(--yn-blue-dark)"
                tooltipPosition="right"
              >
                {' '}
                {formatMessage(messages.tooltipRemove)}{' '}
              </Icon>
            </span>
          </div>
        )}
      </div>
    );
  }
}

PhoneNumbersRow.propTypes = {
  type: PropTypes.string.isRequired,
  number: PropTypes.string,
  error: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};
PhoneNumbersRow.defaultProps = {
  number: '',
  error: undefined,
  onAdd: undefined,
  onRemove: undefined,
};
export default injectIntl(PhoneNumbersRow);
