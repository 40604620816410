import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { setSearchContactsFilter } from '../../../js/phone/actions';
import { getSearchedNumber } from '../../../js/phone/selectors';
import Input from '../../../components/formControls/Input';
import Icon from '../../../components/icons/Icon';
import Button from '../../../components/formControls/Button';

const messages = defineMessages({
  placeholder: {
    id: 'PhoneNumberSearchInput.placeholder',
    defaultMessage: 'Insert name or number',
  },
  notValidNumber: {
    id: 'PhoneNumberSearchInput.error.number',
    defaultMessage: 'Not valid number',
  },
  transferButton: {
    id: 'PhoneNumberSearchInput.button.transferButton',
    defaultMessage: 'Transfer',
  },
});

const style = {
  input: {
    paddingRight: '2rem',
  },
  close: {
    height: '0.7rem',
    width: '0.7rem',
    display: 'block',
    position: 'absolute',
    top: '0.7rem',
    right: '0.8rem',
    color: 'var(--yn-blue)',
    cursor: 'pointer',
  },
};

class PhoneNumberSearchInput extends Component {
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.props.onEnter();
    }
  };

  setFilter = (filter) => {
    this.props.setFilter({
      target: this.props.target,
      number: filter,
      selected: false,
    });
  };

  render() {
    const {
      id,
      name,
      number,
      error,
      disabled,
      readOnly,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className="p-1 border-bottom border-top text-left position-relative">
        {!disabled && (
          <span
            style={style.close}
            onClick={() =>
              this.props.setFilter({
                target: this.props.target,
                number: '',
                selected: false,
                listOpen: false,
              })
            }
          >
            <Icon name="close-filled" width={12} height={12} />
          </span>
        )}
        <Input
          type="text"
          name={id}
          id={name}
          value={number}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => this.setFilter(e.target.value)}
          onKeyDown={this.handleKeyDown}
          placeholder={formatMessage(messages.placeholder)}
          style={style.input}
          error={error ? formatMessage(messages.notValidNumber) : null}
        />
        {this.props.target === 'CallPanel' && (
          <Button
            text={formatMessage(messages.transferButton)}
            className="btn-primary btn-block mt-1"
            disabled={!this.props.number}
            onClick={this.props.onEnter}
          />
        )}
      </div>
    );
  }
}

PhoneNumberSearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  onEnter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    number: getSearchedNumber(state, ownProps.target),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    setFilter: setSearchContactsFilter,
  })(PhoneNumberSearchInput)
);
