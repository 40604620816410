import moment from 'moment';
import ynConf from '../../conf';
import Utils from '../lib/utils';

export class FileboxEnums {
  static fileboxPrivacy = {
    private: 'private',
    public: 'public',
    shared: 'shared',
  };

  static errorMessages = {
    LOCKED_FILE: 'LOCKED_FILE',
    MOVE_FILE_LOCKED: 'MOVE_FILE_LOCKED',
    MOVE_FOLDER_LOCKED: 'MOVE_FOLDER_LOCKED',
    MOVE_FILE_ERROR: 'MOVE_FILE_ERROR',
    MOVE_FOLDER_ERROR: 'MOVE_FOLDER_ERROR',
    COPY_FILE_ERROR: 'COPY_FILE_ERROR',
    COPY_FOLDER_ERROR: 'COPY_FOLDER_ERROR',
    EXISTING_FILE: 'EXISTING_FILE',
    EXISTING_FILENAME: 'EXISTING_FILENAME',
    FILE_TOO_BIG: 'FILE_TOO_BIG',
    MASSIVE_FOLDER_DELETION_ERROR: 'MASSIVE_FOLDER_DELETION_ERROR',
    MASSIVE_FILE_FOLDER_DELETION_ERROR: 'MASSIVE_FILE_FOLDER_DELETION_ERROR',
    TOO_MANY_FILES: 'TOO_MANY_FILES',
    REQUIRED_FILE_FOLDER: 'REQUIRED_FILE_FOLDER',
    CANNOT_UPLOAD_FOLDER: 'CANNOT_UPLOAD_FOLDER',
  };

  static orderType = {
    NAME: 'name',
    SIZE: 'size',
    LAST_UPDATE: 'lastUpdate',
    DELETION_TIME: 'deletionTime',
  };

  static orderDirection = {
    ASC: 1,
    DESC: -1,
  };
}

export const FILEBOX_SPACE_USAGE_WARNING_LIMIT = 150;
export const FILEBOX_SPACE_USAGE_DANGER_LIMIT = 50;
export const FILEBOX_FILE_MAX_SIZE = 1024 * 1024 * 1024; // 1 GB
export const FILEBOX_MAX_FILE_UPLOADED_TOGETHER = 10;

export const generateFileExternalLink = (idCustomer, hash) =>
  `${ynConf.clientBaseUrl}/files/v1/filebox/ext/${idCustomer}/${hash}`;

export const retrieveFileIcon = (mime) => {
  if (!mime) {
    return { name: 'file-new-1', right: '0' };
  }
  if (mime.indexOf('image') === 0) {
    return { name: 'file-picture', right: '0' };
  }
  if (mime.indexOf('audio') > -1) {
    return { name: 'file-music-1', right: '1px' };
  }
  if (mime.indexOf('video') > -1) {
    return { name: 'file-video-1', right: '0' };
  }
  if (mime.indexOf('text') > -1 || mime === 'application/octet-stream') {
    return { name: 'file-text', right: '2px' };
  }
  if (mime === 'application/pdf') {
    return { name: 'file-pdf-1', right: '2px' };
  }
  if (mime.indexOf('excel') > -1 || mime.indexOf('sheet') > -1) {
    return { name: 'file-excel-1', right: '0' };
  }
  if (mime.indexOf('word') > -1) {
    return { name: 'file-words-1', right: '0' };
  }
  return { name: 'file-new-1', right: '0' };
};

export const FILEBOX_FILE_MIME_TYPES = [
  'image',
  'video',
  'audio',
  'application/pdf',
  'application/octet-stream',
  'text/plain',
];

export const FILEBOX_SPACE_UNIT = 5;
export const FILEBOX_SPACE_MAX_PURCHASE = 1000;
export const FILEBOX_MAX_TIME_RENEW_MONTHS = 6;
export const FILEBOX_MIN_TIME_RENEW_MONTHS = 1;

export const arrangeLastUpdate = (datetime, dateFormat, timeFormat) => {
  if (moment(datetime).isAfter(moment().startOf('day'))) {
    return moment(datetime).format(timeFormat);
  }
  if (moment(datetime).isAfter(moment().startOf('day').subtract(1, 'day'))) {
    return 'yesterday';
  }
  return moment(datetime).format(dateFormat);
};

export const orderFileboxItems = (ids, items, type, direction) => {
  return ids
    .map((fileId) => items[fileId])
    .sort((file1, file2) => {
      if (type !== FileboxEnums.orderType.SIZE) {
        if (file1[type].toLowerCase() > file2[type].toLowerCase()) {
          return direction;
        }
        return -direction;
      }
      return direction * (file1[type] - file2[type]);
    })
    .map((file) => file.id);
};
export const getFileboxItemNameTruncated = (
  itemName,
  pageWidth,
  isDirectory
) => {
  if (!itemName || !pageWidth) return;
  const columnWidth =
    pageWidth < 992
      ? pageWidth - 750
      : pageWidth < 1200
      ? pageWidth - 960
      : pageWidth < 1400
      ? pageWidth - 1050
      : pageWidth - 1080;
  const maxChars = columnWidth / 8;
  if (itemName.length <= maxChars + 3) return itemName;
  if (isDirectory) {
    return `${itemName.substr(0, maxChars)}...`;
  }
  const name = Utils.getFilenameWithoutExtension(itemName);
  const extension = Utils.getFilenameExtension(itemName);
  const firstPart = name.substr(0, maxChars / 2);
  const secondPart = name.substr(-maxChars / 2);
  const ext = extension.length > 5 ? `${extension.substr(0, 5)}...` : extension;
  return `${firstPart}...${secondPart}${ext}`;
};

export const nextSize = (size, prices) => {
  if (!size) return +Object.keys(prices.thresholds)[0];
  const index = Object.keys(prices.thresholds).indexOf(size.toString());
  if (index === -1) return +size + +prices.granularity;
  return (
    +Object.keys(prices.thresholds)[index + 1] || +size + +prices.granularity
  );
};

export const prevSize = (size, prices) => {
  if (!size)
    return +Object.keys(prices.thresholds)[
      Object.keys(prices.thresholds).length - 1
    ];
  const index = Object.keys(prices.thresholds).indexOf(size.toString());
  return +Object.keys(prices.thresholds)[index - 1] || 0;
};

export const pricePerGigaBySize = (size, prices) => {
  if (!size) return;
  if (prices.filter((p) => p.size === size).length)
    return {
      new: prices.filter((p) => p.size === size)[0].new / size,
      old: prices.filter((p) => p.size === size)[0].old
        ? prices.filter((p) => p.size === size)[0].old / size
        : 0,
    };
  return {
    new: prices[0].new / size,
    old: prices[0].old ? prices[0].old / size : 0,
  };
};
