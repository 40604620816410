import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import HelpButton from '../../../components/HelpButton';
import { SMS_CREDITS_WARNING_LIMIT } from '../../../js/sms/SMSUtils';
import { buySMSPackagesRequest } from '../../../js/sms/actions';
import {
  getSMSCredits,
  showIncomingSMSAlert,
  getFetchSMSCreditsError,
  isSMSCreditsFetched,
  getFetchSMSCreditsLoaded,
  getSMSPackages,
  getBuySMSPackagesError,
  getBuySMSPackagesLoaded,
} from '../../../js/sms/selectors';
import Button from '../../../components/formControls/Button';
import Alert from '../../../components/messages/Alert';
import { getPbxsById, isSMSenabled } from '../../../js/me/selectors';
import PbxsCreditsSelect from '../../../components/PbxsCreditsSelect';
import ToastMessage from '../../../components/messages/ToastMessage';

const messages = defineMessages({
  creditsWarningText: {
    id: 'SMSPackages.creditsWarningText',
    defaultMessage: 'Hei! You have only {credits} more available SMS!',
  },
  creditsAlertText: {
    id: 'SMSPackages.creditsAlertText',
    defaultMessage: 'You have no more available SMS',
  },
  incomingSMSAlertText: {
    id: 'SMSPackages.incomingSMSAlertText',
    defaultMessage:
      'In the next week some SMS have to be sent! Check if you have enough credits',
  },
  errorNoPbxSelected: {
    id: 'SMSPackages.errorNoPbxSelected',
    defaultMessage: 'Please select one office',
  },
  errorBuying: {
    id: 'SMSPackages.errorBuying',
    defaultMessage: 'There has been an error. Please try again in a while',
  },
  packageAmount: {
    id: 'SMSPackages.packageAmount',
    defaultMessage: 'SMS amount',
  },
  priceSMS: {
    id: 'SMSPackages.priceSMS',
    defaultMessage: 'Price single',
  },
  pricePackage: {
    id: 'SMSPackages.pricePackage',
    defaultMessage: 'Price',
  },
  buttonBuy: {
    id: 'SMSPackages.buttonBuy',
    defaultMessage: 'Buy',
  },
  noData: {
    id: 'SMSPackages.noData',
    defaultMessage: 'No data',
  },
  confirmText: {
    id: 'SMSPackages.confirmText',
    defaultMessage: 'You are going to buy {amount} SMS at a cost of {price} €',
  },
  buttonNext: {
    id: 'SMSPackages.buttonNext',
    defaultMessage: 'Next',
  },
  buttonCancel: {
    id: 'SMSPackages.buttonCancel',
    defaultMessage: 'Cancel',
  },
  buyingSuccess: {
    id: 'SMSPackages.buyingSuccess',
    defaultMessage: 'Well done!',
  },
});

class SMSPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeModalOpen: false,
      packageSelected: {},
      pbxSelected: null,
      errorPbx: null,
      step: 1,
      buyingSuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { buying, buyingError } = this.props;
    if (prevProps.buying && !buying && !buyingError) {
      this.setState({ buyingSuccess: true });
    }
  }

  handleSelectPbx = (selected) => {
    this.setState({ pbxSelected: selected, errorPbx: null });
  };

  arrangeOfficesOptions = () => {
    const { credits, pbxs, totalCost } = this.props;
    return credits.map((c) => ({
      label: pbxs[c.pbxId].name,
      value: c.pbxId,
      isDisabled: c.credit < totalCost,
    }));
  };

  handleBuyPackageClick = (packageSelected) => {
    this.setState({ resumeModalOpen: true, packageSelected });
  };

  toggleResumeModal = () => {
    this.setState({
      resumeModalOpen: !this.state.resumeModalOpen,
      packageSelected: {},
      pbxSelected: null,
      errorPbx: null,
      step: 1,
      buyingSuccess: false,
    });
  };

  confirmBuy = () => {
    const { pbxSelected } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    if (!pbxSelected) {
      this.setState({ errorPbx: formatMessage(messages.errorNoPbxSelected) });
    } else {
      this.setState({ step: 2 });
    }
  };

  buyPackage = () => {
    const { pbxSelected, packageSelected } = this.state;
    const { buyPackage } = this.props;
    this.setState({ step: 3 });
    buyPackage({ pbxId: pbxSelected.value, amount: packageSelected.amount });
  };

  render() {
    const {
      intl: { formatMessage },
      credits,
      showAlert,
      creditsFetched,
      fetchingError,
      fetching,
      packages,
      buyingError,
      buying,
      smsEnabled,
    } = this.props;
    const {
      packageSelected,
      resumeModalOpen,
      step,
      errorPbx,
      pbxSelected,
      buyingSuccess,
    } = this.state;

    return fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        <div className="text-right mb-1">
          <HelpButton fileName="sms-credits" />
        </div>
        {smsEnabled &&
          creditsFetched &&
          credits > 0 &&
          credits < SMS_CREDITS_WARNING_LIMIT && (
            <Alert
              text={formatMessage(messages.creditsWarningText, { credits })}
              type="warning"
            />
          )}
        {smsEnabled && creditsFetched && credits === 0 && (
          <Alert
            text={formatMessage(messages.creditsAlertText)}
            type="danger"
          />
        )}
        {smsEnabled && showAlert && creditsFetched && (
          <Alert
            text={formatMessage(messages.incomingSMSAlertText)}
            type="danger"
          />
        )}
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <>
              <table className="table table-hover table-borderless">
                <thead>
                  <tr>
                    <th>{formatMessage(messages.packageAmount)}</th>
                    <th>{formatMessage(messages.priceSMS)}</th>
                    <th>{formatMessage(messages.pricePackage)}</th>
                    <th />
                  </tr>
                </thead>
                {packages && packages.length > 0 ? (
                  <tbody>
                    {packages.map((p) => (
                      <tr key={p.amount}>
                        <td>{p.amount}</td>
                        <td>{`${(p.price / p.amount).toFixed(3)} €`}</td>
                        <td>{`${p.price} €`}</td>
                        <td>
                          {smsEnabled && (
                            <Button
                              className="btn-primary"
                              text={formatMessage(messages.buttonBuy)}
                              onClick={() => this.handleBuyPackageClick(p)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tfoot>
                    <tr>
                      <td colSpan="4">{formatMessage(messages.noData)}</td>
                    </tr>
                  </tfoot>
                )}
              </table>
              <Modal isOpen={resumeModalOpen} toggle={this.toggleResumeModal}>
                <ModalBody>
                  {buyingSuccess && (
                    <ToastMessage
                      type="success"
                      text={formatMessage(messages.buyingSuccess)}
                      onClose={this.toggleResumeModal}
                    />
                  )}
                  {buyingError && step === 3 && (
                    <ToastMessage
                      type="danger"
                      text={formatMessage(messages.errorBuying)}
                    />
                  )}
                  {step === 1 && (
                    <PbxsCreditsSelect
                      onChange={this.handleSelectPbx}
                      error={errorPbx}
                      selected={pbxSelected}
                      toPay={packageSelected.price}
                    />
                  )}
                  {step > 1 && step < 3 && (
                    <div>
                      {formatMessage(messages.confirmText, {
                        amount: packageSelected.amount,
                        price: packageSelected.price,
                      })}
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  {step < 3 && (
                    <Button
                      className="btn-primary"
                      text={formatMessage(messages.buttonNext)}
                      loading={buying}
                      disabled={!pbxSelected}
                      onClick={step === 1 ? this.confirmBuy : this.buyPackage}
                    />
                  )}
                  <Button
                    className="btn-outline-primary"
                    onClick={this.toggleResumeModal}
                    text={formatMessage(messages.buttonCancel)}
                  />
                </ModalFooter>
              </Modal>
            </>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    credits: getSMSCredits(state),
    fetching: !getFetchSMSCreditsLoaded(state),
    fetchingError: getFetchSMSCreditsError(state),
    creditsFetched: isSMSCreditsFetched(state),
    showAlert: showIncomingSMSAlert(state),
    packages: getSMSPackages(state),
    pbxs: getPbxsById(state),
    buyingError: getBuySMSPackagesError(state),
    buying: !getBuySMSPackagesLoaded(state),
    smsEnabled: isSMSenabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    buyPackage: buySMSPackagesRequest,
  })(SMSPackages)
);
