import { request } from '../request';

export default {
  getArchivedNotifications: (params) =>
    request({
      url: '/notifications',
      method: 'GET',
      params,
    }),
};
