import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import history from '../../../history';
import Button from '../../../components/formControls/Button';

const messages = defineMessages({
  PENDING: {
    id: 'SMSDetailsModal.PENDING',
    defaultMessage: 'Sending',
  },
  SENT: {
    id: 'SMSDetailsModal.SENT',
    defaultMessage: 'Sent',
  },
  ERROR: {
    id: 'SMSDetailsModal.ERROR',
    defaultMessage: 'Error',
  },
  DELIVERED: {
    id: 'SMSDetailsModal.DELIVERED',
    defaultMessage: 'Delivered',
  },
  scheduled: {
    id: 'SMSDetailsModal.label.scheduled',
    defaultMessage: 'Scheduled on',
  },
  user: {
    id: 'SMSDetailsModal.label.user',
    defaultMessage: 'Scheduled by',
  },
  sender: {
    id: 'SMSDetailsModal.label.sender',
    defaultMessage: 'Sender',
  },
  recipients: {
    id: 'SMSDetailsModal.label.recipients',
    defaultMessage: 'Recipients',
  },
  lists: {
    id: 'SMSDetailsModal.label.lists',
    defaultMessage: 'Lists',
  },
  name: {
    id: 'SMSDetailsModal.label.name',
    defaultMessage: 'Name',
  },
  text: {
    id: 'SMSDetailsModal.label.text',
    defaultMessage: 'Text',
  },
  url: {
    id: 'SMSDetailsModal.label.url',
    defaultMessage: 'Url',
  },
  cancelButton: {
    id: 'SMSDetailsModal.cancelButton',
    defaultMessage: 'Close',
  },
  public: {
    id: 'SMSDetailsModal.label.public',
    defaultMessage: 'Public',
  },
  totalSent: {
    id: 'SMSDetailsModal.label.totalSent',
    defaultMessage: 'Sent',
  },
  totalDelivered: {
    id: 'SMSDetailsModal.label.totalDelivered',
    defaultMessage: 'Delivered',
  },
  totalError: {
    id: 'SMSDetailsModal.label.totalError',
    defaultMessage: 'In error',
  },
  showSms: {
    id: 'SMSDetailsModal.label.showSms',
    defaultMessage: 'Show messages',
  },
  yes: {
    id: 'SMSDetailsModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'SMSDetailsModal.no',
    defaultMessage: 'No',
  },
});

const SMSDetailsModal = ({
  id,
  title,
  user,
  isOpen,
  onToggle,
  recipients,
  scheduledDate,
  sender,
  text,
  link,
  publicSMS,
  lists,
  name,
  totalSent,
  totalDelivered,
  totalError,
  intl: { formatMessage },
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{title}</ModalHeader>
      <ModalBody>
        <table className="yn-table-vertical">
          <thead>
            <tr>
              <th scope="row">{formatMessage(messages.scheduled)}</th>
              <td>{scheduledDate}</td>
            </tr>
            <tr>
              <th scope="row">{formatMessage(messages.user)}</th>
              <td>{user}</td>
            </tr>
            {name && (
              <tr>
                <th scope="row">{formatMessage(messages.name)}</th>
                <td>{name}</td>
              </tr>
            )}
            {sender && (
              <tr>
                <th scope="row">{formatMessage(messages.sender)}</th>
                <td>{sender}</td>
              </tr>
            )}
            {lists && (
              <tr>
                <th scope="row">{formatMessage(messages.lists)}</th>
                <td>{lists}</td>
              </tr>
            )}
            {recipients && (
              <tr>
                <th scope="row">{formatMessage(messages.recipients)}</th>
                <td>{recipients}</td>
              </tr>
            )}
            <tr>
              <th scope="row">{formatMessage(messages.text)}</th>
              <td>{text}</td>
            </tr>
            {link && (
              <tr>
                <th scope="row">{formatMessage(messages.url)}</th>
                <td>{link}</td>
              </tr>
            )}
            {publicSMS !== null && (
              <tr>
                <th scope="row">{formatMessage(messages.public)}</th>
                <td>
                  {publicSMS
                    ? formatMessage(messages.yes)
                    : formatMessage(messages.no)}
                </td>
              </tr>
            )}
            {totalSent !== null && totalSent > 0 && (
              <tr>
                <th scope="row">{formatMessage(messages.totalSent)}</th>
                <td>
                  {totalSent}
                  &nbsp;(
                  <span
                    className="mb-0 mt-2"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      onToggle();
                      history.push(`/sms/sent?campaign=${id}`);
                    }}
                  >
                    {formatMessage(messages.showSms)}
                  </span>
                  )
                </td>
              </tr>
            )}
            {totalDelivered !== null && totalDelivered > 0 && (
              <tr>
                <th scope="row">{formatMessage(messages.totalDelivered)}</th>
                <td>{totalDelivered}</td>
              </tr>
            )}
            {totalError !== null && totalError > 0 && (
              <tr>
                <th scope="row">{formatMessage(messages.totalError)}</th>
                <td>{totalError}</td>
              </tr>
            )}
          </thead>
        </table>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-outline-primary"
          onClick={onToggle}
          text={formatMessage(messages.cancelButton)}
        />
      </ModalFooter>
    </Modal>
  );
};

SMSDetailsModal.propTypes = {
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  scheduledDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.string.isRequired,
  sender: PropTypes.string,
  link: PropTypes.string,
  recipients: PropTypes.array,
  lists: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  publicSMS: PropTypes.bool,
};

SMSDetailsModal.defaultProps = {
  recipients: null,
  lists: null,
  publicSMS: null,
  sender: null,
  link: null,
  name: null,
};

export default injectIntl(SMSDetailsModal);
