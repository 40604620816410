import { LOCATION_CHANGE } from 'connected-react-router';
import flattenDepth from 'lodash.flattendepth';
import values from 'lodash.values';
import max from 'lodash.max';
import min from 'lodash.min';
import {
  FETCH_SETTINGS_SUCCESS,
} from '../me/types';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  UPDATE_PLATFORM_SETTINGS_REQUEST,
  UPDATE_PLATFORM_SETTINGS_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_CONFIGURATIONS_REQUEST,
  FETCH_CONFIGURATIONS_SUCCESS,
  FETCH_CONFIGURATIONS_FAILURE,
  RESTORE_NOTIFICATIONS_SETTINGS_REQUEST,
  RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS,
  RESTORE_NOTIFICATIONS_SETTINGS_FAILURE,
  SAVE_HIDE_INTRO_REQUEST,
} from './types';
import Utils from '../lib/utils';
import {
  UPDATE_STICKY_VISIBILITY_SUCCESS,
  ADD_STICKY_NOTES_SUCCESS,
} from '../sticky/types';
import { EXEC_VOCALASSISTANT_ACTION } from '../vocalassistant/types';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';

const initialErrorState = {
  changePreferences: {
    errorChangePreferences: null,
    successChangePreferences: null,
  },
};

const initialConfigurations = {
  mailoffice: {
    perc: 100,
    prices: {},
    maxPrice: 0,
    minPrice: 0,
  },
  filebox: {},
};

const initialState = {
  loaded: true,
  language: Utils.getBrowserLocale() || 'en',
  changePreferences: {
    errorChangePreferences: null,
    successChangePreferences: null,
  },
  notificationSettings: {
    error: null,
    success: null,
    loaded: true,
  },
  fetchConfigurationsError: null,
  fetchConfigurationsSuccess: null,
  configurations: initialConfigurations,
  restoreNotificationsSettings: {
    error: null,
    success: null,
    loaded: true,
  },
  hideIntro: true,
};

const innerObjects = (o) => {
  return values(o).map((el) =>
    typeof el === 'object' ? innerObjects(el) : el
  );
};

const allValues = (prices) => {
  return flattenDepth(innerObjects(prices), 4);
};

export function settings(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.settings,
        language: action.settings.language.toLowerCase(),
      };
    case UPDATE_PLATFORM_SETTINGS_REQUEST:
      return {
        ...state,
        ...action.settings,
        loaded: false,
        changePreferences: initialErrorState,
      };
    case UPDATE_PLATFORM_SETTINGS_SUCCESS: {
      DesktopNotificationManager.changeVolume(action.preferences.volume);
      return {
        ...state,
        language: action.preferences.language.toLowerCase(),
        autoChatOpen: action.preferences.autoChatOpen,
        dateFormat: action.preferences.dateFormat,
        timeFormat: action.preferences.timeFormat,
        volume: action.preferences.volume,
        ownViewedName: action.preferences.ownViewedName,
        loaded: true,
        changePreferences: {
          errorChangePreferences: null,
          successChangePreferences: action.success,
        },
      };
    }
    case UPDATE_PLATFORM_SETTINGS_FAILURE:
      return {
        ...state,
        ...action.settings,
        loaded: true,
        changePreferences: {
          successChangePreferences: null,
          errorChangePreferences: action.errors,
        },
      };
    /** Update Sticky */
    case ADD_STICKY_NOTES_SUCCESS:
      return {
        ...state,
        stickyShow: true,
      };
    case UPDATE_STICKY_VISIBILITY_SUCCESS:
      return {
        ...state,
        stickyShow: action.stickyShow,
      };
    /** Notification settings */
    case UPDATE_NOTIFICATION_SETTINGS_REQUEST:
    case EXEC_VOCALASSISTANT_ACTION:
      return {
        ...state,
        ...action.settings,
        notificationSettings: {
          error: null,
          success: null,
          loaded: false,
        },
      };
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.settings,
        notifCalendar: action.notificationSettings.calendar,
        notifCalls: action.notificationSettings.calls,
        notifData: action.notificationSettings.data,
        notifEnabled: action.notificationSettings.enabled,
        notifFax: action.notificationSettings.fax,
        notifMailoffice: action.notificationSettings.mailoffice,
        notifMessages: action.notificationSettings.messages,
        notifSound: action.notificationSettings.sound,
        notifTimeout: action.notificationSettings.timeout,
        notifUsers: action.notificationSettings.users,
        notifVbox: action.notificationSettings.vbox,
        notifVideocalls: action.notificationSettings.videocalls,
        notifPresences: action.notificationSettings.presences,
        notifTicket: action.notificationSettings.ticket,
        notifFilebox: action.notificationSettings.filebox,
        notificationSettings: {
          error: null,
          success: action.success,
          loaded: true,
        },
      };
    }
    case UPDATE_NOTIFICATION_SETTINGS_FAILURE:
      return {
        ...state,
        ...action.settings,
        notificationSettings: {
          error: action.errors,
          success: null,
          loaded: true,
        },
      };
    case RESTORE_NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        restoreNotificationsSettings: {
          error: null,
          success: null,
          loaded: false,
        },
      };
    case RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS: {
      return {
        ...state,
        restoreNotificationsSettings: {
          error: null,
          success: 200,
          loaded: true,
        },
      };
    }
    case RESTORE_NOTIFICATIONS_SETTINGS_FAILURE:
      return {
        ...state,
        restoreNotificationsSettings: {
          error: action.error,
          success: null,
          loaded: true,
        },
      };
    case FETCH_CONFIGURATIONS_REQUEST: {
      return {
        ...state,
        fetchConfigurationsLoaded: false,
        fetchConfigurationsError: null,
        configurations: initialConfigurations,
      };
    }
    case FETCH_CONFIGURATIONS_SUCCESS: {
      if (
        !action.payload.mailoffice ||
        !action.payload.mailoffice.prices ||
        !action.payload.mailoffice.perc
      )
        return state;
      const maxValue = max(allValues(action.payload.mailoffice.prices));
      const minValue = min(allValues(action.payload.mailoffice.prices));
      const maxPrice =
        maxValue + (maxValue * action.payload.mailoffice.perc) / 100;
      const minPrice =
        minValue + (minValue * action.payload.mailoffice.perc) / 100;

      return {
        ...state,
        fetchConfigurationsLoaded: true,
        fetchConfigurationsError: null,
        configurations: {
          mailoffice: {
            ...action.payload.mailoffice,
            maxPrice,
            minPrice,
          },
          filebox: action.payload.filebox,
        },
      };
    }
    case FETCH_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        fetchConfigurationsLoaded: true,
        fetchConfigurationsError: action.errors,
        configurations: initialConfigurations,
      };

    case SAVE_HIDE_INTRO_REQUEST:
      return {
        ...state,
        hideIntro: true,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        changePreferences: initialErrorState,
        notificationSettings: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    default:
      return state;
  }
}
