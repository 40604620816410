import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { isMePbxAdmin } from '../../../js/me/selectors';

const messages = defineMessages({
  title: {
    id: 'Platform.title',
    defaultMessage: 'Preferences',
  },
  personalSettings: {
    id: 'Platform.personalSettings.navLabel',
    defaultMessage: 'Personal settings',
  },
  customerSettings: {
    id: 'Platform.customerSettings.navLabel',
    defaultMessage: 'Customer settings',
  },
});
const Platform = ({ intl: { formatMessage }, children, isPbxAdmin }) => {
  const nav = [
    {
      id: 'Platform.personalSettings.linkLabel',
      href: '/settings/platform/personal',
      label: messages.personalSettings,
    },
  ];

  if (isPbxAdmin) {
    nav.push({
      id: 'Platform.customerSettings.linkLabel',
      href: '/settings/platform/customer',
      label: messages.customerSettings,
    });
  }
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div className="yn-section-with-nav-scrolling">{children}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isPbxAdmin: isMePbxAdmin(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Platform));
