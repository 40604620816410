const ynConf = {
  clientBaseUrl: "https://web.youneed.it",
  apiBaseUrl: "https://web.youneed.it/api/v1",
  fileBaseUrl: "https://web.youneed.it/files/v1",
  wsUrl: "wss://web.youneed.it/ws/web",
  wsGuestUrl: "wss://web.youneed.it/ws/guest",
  recCallBaseUrl: 'https://web.youneed.it/reccall/',
  publicFolderPath: "",
  googleKey: 'AIzaSyDlsG83ErItAKC0xR2cafuVdge9_ODbYvg',
  youtubeKey: 'AIzaSyC2J9iDNqf7Lepd8G8oXzcuSPJbw188rRI',
  screenSharingExtensionUrl: 'https://chrome.google.com/webstore/detail/youneed-screensharing/hmkcpajkidhbjcjfpnohokkhgdmfecpl?hl=en-US&gl=IT&authuser=1',
  paypalClientId: 'AcP9FXlef2Tv0HjUdpeC1_G52XW2qZ3PTslsmcNsdAgJpKn0b7xkQmas8N58z3dOoDOLVOwOnlF-ekNm',
  jitsi: {
    appId: 'vpaas-magic-cookie-5f0208e7a34246e89147a93313c46982',
  }
};
export default ynConf;
