import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Button from './formControls/Button';
import history from '../history';

const messages = defineMessages({
  message: {
    id: 'ErrorBoundaryLeftCol.message',
    defaultMessage:
      'There has been an error. Please click button to reload page.',
  },
  reload: {
    id: 'ErrorBoundaryLeftCol.reload',
    defaultMessage: 'Realod',
  },
});

class ErrorBoundaryLeftCol extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="alert alert-danger p-1 m-0 text-center"
          style={{
            height: `${this.props.height}px`,
            position: 'absolute',
            bottom: this.props.bottom,
            left: 0,
            width: '100%',
            overflowY: 'auto',
            fontSize: '0.85em',
          }}
        >
          {this.props.intl.formatMessage(messages.message)}
          <Button
            className="btn btn-primary"
            text={this.props.intl.formatMessage(messages.reload)}
            onClick={() => history.go(0)}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundaryLeftCol);
