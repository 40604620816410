import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Label, FormGroup } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { resetPasswordRequest } from '../../js/auth/actions';
import ToastMessage from '../../components/messages/ToastMessage';
import Button from '../../components/formControls/Button';
import Input from '../../components/formControls/Input';

const messages = defineMessages({
  username: {
    id: 'ForgotPasswordForm.label.username',
    defaultMessage: 'Username or email:',
  },
  errorUsername: {
    id: 'ForgotPasswordForm.error.username',
    defaultMessage: 'You must enter a valid username or email address',
  },
  buttonResetPassword: {
    id: 'ForgotPasswordForm.button.resetPassword',
    defaultMessage: 'Reset password',
  },
  403: {
    id: 'ForgotPasswordForm.serverError.403',
    defaultMessage: 'Username or email not found',
  },
});

class ForgotPasswordForm extends Component {
  state = {
    data: {
      username: '',
    },
    errors: {},
  };

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
    const errors = {
      ...this.state.errors,
    };
    delete errors[e.target.name];
    this.setState({ errors });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
    e.preventDefault();
  };

  validate = (data) => {
    const errors = {};
    if (!data.username)
      errors.username = this.props.intl.formatMessage(messages.errorUsername);
    return errors;
  };

  render() {
    const {
      serverError,
      loading,
      intl: { formatMessage },
    } = this.props;
    const { data, errors } = this.state;
    return (
      <Form
        onSubmit={this.onSubmit}
        noValidate
        className="p-4 rounded text-left needs-validation"
      >
        {serverError && (
          <ToastMessage
            type="danger"
            closeTimeout={2000}
            text={serverError === 403 && formatMessage(messages[403])}
            messageCode={serverError}
          />
        )}
        <FormGroup>
          <Label for="username">{formatMessage(messages.username)}</Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={data.username}
            onChange={this.onChange}
            error={errors.username}
          />
        </FormGroup>
        <div className="pt-3">
          <Button
            className="btn btn-primary btn-block"
            loading={loading}
            text={formatMessage(messages.buttonResetPassword)}
          />
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    serverError: state.auth.error.reset,
    loading: !state.auth.loaded,
  };
}

ForgotPasswordForm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default injectIntl(
  connect(mapStateToProps, { submit: resetPasswordRequest })(ForgotPasswordForm)
);
