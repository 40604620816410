import React from 'react';
import PersonalSettings from './index';
import ErrorBoundary from '../../../../components/ErrorBoundary';

const AdminUsersEditUserValidations = React.lazy(() =>
  import('../../Groups/AdminUsersEditUserValidations')
);

const ValidationsSettingsPage = (props) => (
  <PersonalSettings scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AdminUsersEditUserValidations {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PersonalSettings>
);

export default ValidationsSettingsPage;
