import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../../components/icons/Icon';
import { isWsAvailable } from '../../../js/auth/selectors';
import { getMeVideocallDnd } from '../../../js/phone/selectors';
import { setDndStatusRequest } from '../../../js/videocalls/actions';

const messages = defineMessages({
  enableDnd: {
    id: 'VideocallStatusButton.enableDnd',
    defaultMessage: 'Enable videocall dnd',
  },
  disableDnd: {
    id: 'VideocallStatusButton.disableDnd',
    defaultMessage: 'Disable videocall dnd',
  },
  unavailable: {
    id: 'VideocallStatusButton.unavailable',
    defaultMessage: 'Unavailable',
  },
});

class VideocallStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  handleChange = () => {
    this.props.setDnd(!this.props.videocallDnd);
  };

  render() {
    const {
      unavailable,
      videocallDnd,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <Tooltip
          isOpen={this.state.tooltipOpen}
          toggle={() => {
            this.setState({ tooltipOpen: !this.state.tooltipOpen });
          }}
          target="videocall_dnd_switch"
        >
          {unavailable
            ? formatMessage(messages.unavailable)
            : videocallDnd
            ? formatMessage(messages.disableDnd)
            : formatMessage(messages.enableDnd)}
        </Tooltip>
        <div
          id="videocall_dnd_switch"
          className="mr-2"
          onClick={this.handleChange}
          style={{ cursor: unavailable ? 'not-allowed' : 'pointer' }}
        >
          <span
            className="rounded-circle align-self-center d-inline-block"
            style={{
              background: unavailable
                ? 'var(--white)'
                : videocallDnd
                ? 'var(--orange)'
                : 'var(--green)',
              width: '30px',
              height: '30px',
              border: unavailable ? '1px solid var(--yn-gray-500)' : 'none',
            }}
          >
            {unavailable ? (
              <Icon
                name="video-camera-filled"
                color="var(--yn-gray-500)"
                width={16}
                height={16}
                disabled={unavailable}
                clickable={false}
                style={{ left: '7px', top: '7px' }}
              />
            ) : videocallDnd ? (
              <Icon
                name="camera-off-filled"
                color="white"
                width={16}
                height={16}
                style={{ left: '7px', top: '7px' }}
              />
            ) : (
              <Icon
                name="video-camera-filled"
                color="white"
                width={16}
                height={16}
                style={{ left: '7px', top: '7px' }}
              />
            )}
          </span>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    unavailable: !isWsAvailable(state),
    videocallDnd: getMeVideocallDnd(state),
    videocallUnavailable: !isWsAvailable(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { setDnd: setDndStatusRequest })(
    VideocallStatusButton
  )
);
