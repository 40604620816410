import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SecondaryNav from '../../../../components/SecondaryNav';
import SectionTitle from '../../../../components/SectionTitle';

const messages = defineMessages({
  title: {
    id: 'MePhoneSettings.title',
    defaultMessage: 'Phone settings',
  },
  switchboardRules: {
    id: 'MePhoneSettings.switchboardRules.navLabel',
    defaultMessage: 'Phone rules',
  },
  activeGreetingsMessages: {
    id: 'MePhoneSettings.activeGreetingsMessages.navLabel',
    defaultMessage: 'Active message',
  },
  externalUrl: {
    id: 'MePhoneSettings.externalUrl.navLabel',
    defaultMessage: 'External url',
  },
});

const nav = [
  {
    id: 'MePhoneSettings.receivedMessages.linkLabel',
    href: '/phone/settings',
    label: messages.switchboardRules,
  },
  {
    id: 'MePhoneSettings.activeGreetingsMessages.linkLabel',
    href: '/phone/greeting',
    label: messages.activeGreetingsMessages,
  },
  {
    id: 'MePhoneSettings.externalUrl.linkLabel',
    href: '/phone/external-url',
    label: messages.externalUrl,
  },
];

const MePhoneSettings = ({
  scrolling,
  filter,
  intl: { formatMessage },
  children,
}) => (
  <div className="border rounded h-100 bg-white">
    <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
    <SecondaryNav tabs={nav} />
    <div
      className={
        scrolling
          ? filter
            ? 'yn-section-with-nav-and-filter-scrolling'
            : 'yn-section-with-nav-scrolling'
          : 'yn-section-with-nav'
      }
    >
      {children}
    </div>
  </div>
);
export default injectIntl(MePhoneSettings);
