import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import Logo from './Logo';
import NotificationHeaderWrap from './NotificationHeaderWrap';

const Header = () => (
  <div className="bg-white yn-header">
    <Navbar color="faded" light expand="sm" className="w-100">
      <div className="yn-left-width">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="collapse navbar-collapse">
        <NotificationHeaderWrap />
      </div>
    </Navbar>
  </div>
);

export default Header;
