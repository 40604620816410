import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import uiqueid from 'lodash.uniqueid';
import Svg from './Svg';

class Icon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      id: uiqueid('Icon-'),
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  };

  render() {
    const {
      children,
      name,
      width,
      height,
      color,
      tooltipPosition,
      disabled,
      clickable,
      tooltipDelay,
      strokeWidth,
      className,
      style,
      tooltip,
    } = this.props;
    const { tooltipOpen, id } = this.state;

    return (
      <>
        <span
          style={{
            display: 'inline-block',
            width: `${width}px`,
            height: `${height}px`,
            color,
            position: 'relative',
            cursor: disabled
              ? 'not-allowed'
              : clickable
              ? 'pointer'
              : 'default',
            ...style,
          }}
          className={className}
          id={id}
        >
          <Svg
            name={name}
            width={width}
            height={height}
            strokeWidth={strokeWidth}
            color={color}
          />
        </span>
        {children && tooltip && (
          <Tooltip
            placement={tooltipPosition}
            isOpen={tooltipOpen}
            toggle={this.toggle}
            target={id}
            delay={tooltipDelay}
            autohide={false}
          >
            {children}
          </Tooltip>
        )}
      </>
    );
  }
}

Icon.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  strokeWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  tooltipPosition: PropTypes.oneOf(['bottom', 'left', 'top', 'right', 'auto']),
  clickable: PropTypes.bool,
  tooltipDelay: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  tooltip: PropTypes.bool,
};

Icon.defaultProps = {
  children: undefined,
  width: 24,
  height: 24,
  color: 'currentcolor',
  tooltipPosition: 'bottom',
  clickable: true,
  tooltipDelay: 0,
  disabled: false,
  strokeWidth: 1.2,
  className: undefined,
  style: undefined,
  tooltip: true,
};

export default Icon;
