import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import Puncher from '.';

const PersonalPuncherNotifications = React.lazy(() =>
  import('./PersonalPuncherNotifications')
);

const PersonalPuncherNotificationsPage = ({ puncherEnabled }) => (
  <Puncher>
    {puncherEnabled ? (
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <PersonalPuncherNotifications />
        </React.Suspense>
      </ErrorBoundary>
    ) : (
      <GlobalErrorPage code={403} />
    )}
  </Puncher>
);

function mapStateToProps(state) {
  return {
    puncherEnabled: isPuncherEnabled(state),
  };
}

export default connect(mapStateToProps)(PersonalPuncherNotificationsPage);
