import { request } from '../request';

export default {
  getSettings: () =>
    request({
      url: `/presence/settings?${new Date().getTime()}`,
      method: 'GET',
    }),
  getPunchings: (params) =>
    request({
      url: '/presence/own-punchings',
      method: 'GET',
      params,
    }),
  createPunching: (params) =>
    request({
      url: '/presence/own-punchings',
      method: 'POST',
      data: params,
    }),
  updatePunching: (params) =>
    request({
      url: `/presence/own-punchings/${params.id}`,
      method: 'POST',
      data: params,
    }),
  getPunchingDetails: (id) =>
    request({
      url: `/presence/own-punchings/${id}`,
      method: 'GET',
    }),
  getUsersPunchings: (params) =>
    request({
      url: '/presence/users-punchings',
      method: 'GET',
      params,
    }),
  createUsersPunching: (params) =>
    request({
      url: '/presence/users-punchings',
      method: 'POST',
      data: params,
    }),
  editUsersPunching: (params) =>
    request({
      url: `/presence/users-punchings/${params.id}`,
      method: 'POST',
      data: params,
    }),
  deletePunching: (id) =>
    request({
      url: `/presence/users-punchings/${id}`,
      method: 'DELETE',
    }),
  getWorkingUsers: (params) =>
    request({
      url: '/presence/working-users',
      method: 'GET',
      params,
    }),
  getPbxRegistry: (id) =>
    request({
      url: `/pbxs/${id}/registry`,
      method: 'GET',
    }),
  savePbxRegistry: (payload) =>
    request({
      url: `/pbxs/${payload.id}/registry`,
      method: 'POST',
      data: {
        template: payload.data,
      },
    }),
  getExportTemplates: () =>
    request({
      url: '/presence/export-templates',
      method: 'GET',
    }),
  getExportTemplate: (id) =>
    request({
      url: `/presence/export-templates/${id}`,
      method: 'GET',
    }),
  createExportTemplate: (data) =>
    request({
      url: `/presence/export-templates`,
      method: 'POST',
      data,
    }),
  updateExportTemplate: (templateData) =>
    request({
      url: `/presence/export-templates/${templateData.id}`,
      method: 'POST',
      data: {
        name: templateData.name,
        template: templateData.template,
        groupby: templateData.groupby,
      },
    }),
  deleteExportTemplate: (id) =>
    request({
      url: `/presence/export-templates/${id}`,
      method: 'DELETE',
    }),
  exportUsersPunchings: (params) =>
    request({
      url: '/presence/export-templates',
      method: 'GET',
      params,
    }),
  getPunchingsToValidate: (params) =>
    request({
      url: '/presence/punchings-to-validate',
      method: 'GET',
      params,
    }),
  validatePunching: (id, params) =>
    request({
      url: `/presence/punchings/${id}/confirmation`,
      method: 'POST',
      data: params,
    }),
  getAbsences: (params) =>
    request({
      url: '/presence/absences',
      method: 'GET',
      params,
    }),
  insertAbsence: (data) =>
    request({
      url: `/presence/absences`,
      method: 'POST',
      data,
    }),
  updateAbsence: (data, id) =>
    request({
      url: `/presence/absences/${id}`,
      method: 'POST',
      data,
    }),
  deleteAbsence: (id) =>
    request({
      url: `/presence/absences/${id}`,
      method: 'DELETE',
    }),
  getQRCodeSettings: () =>
    request({
      url: '/presence/guest-management',
      method: 'GET',
    }),
  insertQRCodeSettings: (data) =>
    request({
      url: `/presence/guest-management`,
      method: 'POST',
      data,
    }),
  getGuestData: (params) => {
    return request({
      url: '/presence/guest-data',
      method: 'GET',
      params,
    });
  },
  insertGuestVisit: (data) =>
    request({
      url: `/presence/visits`,
      method: 'POST',
      data,
    }),
  getGuestVisits: (params) => {
    return request({
      url: '/presence/visits',
      method: 'GET',
      params,
    });
  },
  getJustifications: (params) => {
    return request({
      url: '/presence/justifications',
      method: 'GET',
      params,
    });
  },
  deleteJustification: (id) => {
    return request({
      url: `/presence/justifications/${id}`,
      method: 'DELETE',
    });
  },
  editJustification: (data, id) => {
    return request({
      url: `/presence/justifications/${id}`,
      method: 'POST',
      data,
    });
  },
  insertJustification: (data) => {
    return request({
      url: `/presence/justifications`,
      method: 'POST',
      data,
    });
  },
  getCassaIntegrazione: (params) =>
    request({
      url: '/presence/casse-integrazioni',
      method: 'GET',
      params,
    }),
  insertCassaIntegrazione: (data) =>
    request({
      url: `/presence/casse-integrazioni`,
      method: 'POST',
      data,
    }),
  updateCassaIntegrazione: (data, id) =>
    request({
      url: `/presence/casse-integrazioni/${id}`,
      method: 'POST',
      data,
    }),
  deleteCassaIntegrazione: (id) =>
    request({
      url: `/presence/casse-integrazioni/${id}`,
      method: 'DELETE',
    }),
  getExtra: (params) =>
    request({
      url: '/presence/extra',
      method: 'GET',
      params,
    }),
  createUsersExtra: (params) =>
    request({
      url: '/presence/extra',
      method: 'POST',
      data: params,
    }),
  deleteUsersExtra: (id) =>
    request({
      url: `/presence/extra/${id}`,
      method: 'DELETE',
    }),
};
