/* eslint react/prefer-stateless-function: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getSupplier,
  getUserPhoneStatus,
  isPhoneOffline,
  isWebrtcActive,
  isWebrtcInUse,
} from '../../js/phone/selectors';
import {
  execCallRequest,
  startConferenceRequest,
  aidTransferRequest,
} from '../../js/phone/actions';
import Icon from '../icons/Icon';
import PhoneButtonNumbersList from '../PhoneButtonNumbersList';
import {
  getMeConferenceCallId,
  isMeInConference,
  getMeCallsAndConferences,
  getMeId,
} from '../../js/me/selectors';
import Utils from '../../js/lib/utils';
import { PhoneEnums } from '../../js/phone/PhoneUtils';

const messages = defineMessages({
  defaultTooltip: {
    id: 'OtherPhoneButton.tooltip.defaultTooltip',
    defaultMessage: 'Call',
  },
  unavailable: {
    id: 'OtherPhoneButton.tooltip.unavailable',
    defaultMessage: 'Unavailable',
  },
});

class OtherPhoneButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
  }

  onNumberClick = () => {
    this.setState({
      popoverOpen: false,
    });
  };

  handleClick = () => {
    const {
      popoverOpen,
      conferenceCallId,
      addMember,
      numbers,
      myPhoneStatus,
      activeCalls,
      aidTransfer,
      call,
      onNumberClick,
      meInConference,
    } = this.props;
    if (numbers.length === 1) {
      if (myPhoneStatus === PhoneEnums.UserStatuses.unavailable) return;
      if (meInConference) {
        if (conferenceCallId) {
          addMember({
            callId: conferenceCallId,
            number: numbers[0].number,
          });
        }
      } else if (activeCalls && activeCalls.length) {
        if (activeCalls.length === 1 && !activeCalls[0].recording) {
          aidTransfer({
            number: numbers[0].number,
            callId: activeCalls[0].callid,
            addExitCode: true,
          });
        }
      } else {
        const numberToCall = Utils.adjustPhoneNumber(numbers[0].number);
        call({
          number: numberToCall,
          addExitCode: true,
          outOfPanel: true,
        });
      }
      onNumberClick && onNumberClick();
    } else {
      this.setState({
        popoverOpen: !popoverOpen,
      });
    }
  };

  render() {
    const {
      elementId,
      className,
      placementPopover,
      tooltip,
      width,
      height,
      myPhoneStatus,
      isMeOffline,
      webrtcActive,
      webrtcUsing,
      intl: { formatMessage },
    } = this.props;
    const { popoverOpen } = this.state;
    const numbers = this.props.numbers
      ? this.props.numbers.filter((number) => number.type !== 'FAX')
      : [];
    const isMyPhoneAvailable =
      myPhoneStatus !== PhoneEnums.UserStatuses.unavailable && !isMeOffline;
    const disabled =
      !isMyPhoneAvailable || (webrtcActive === 'ON' && !webrtcUsing);
    const iconName =
      !isMyPhoneAvailable || (webrtcActive === 'ON' && !webrtcUsing)
        ? 'phone-5'
        : 'phone-5-filled';

    return (
      <>
        <span onClick={this.handleClick}>
          <span id={elementId}>
            <Icon
              name={iconName}
              color="var(--yn-blue-dark)"
              width={width}
              height={height}
              className={className}
              clickable
              tooltipPosition="auto"
              disabled={myPhoneStatus === PhoneEnums.UserStatuses.unavailable}
            >
              {disabled
                ? formatMessage(messages.unavailable)
                : tooltip || formatMessage(messages.defaultTooltip)}
            </Icon>
          </span>
          {popoverOpen && (
            <PhoneButtonNumbersList
              target={elementId}
              isOpen={popoverOpen}
              numbers={numbers}
              placement={placementPopover}
              onNumberClick={this.onNumberClick}
              toggle={() => {
                this.setState({
                  popoverOpen: !popoverOpen,
                });
              }}
            />
          )}
        </span>
      </>
    );
  }
}

OtherPhoneButton.propTypes = {
  elementId: PropTypes.string.isRequired,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string.isRequired,
    })
  ),
  call: PropTypes.func.isRequired,
  placementPopover: PropTypes.string,
  onNumberClick: PropTypes.func,
  tooltip: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
OtherPhoneButton.defaultProps = {
  numbers: undefined,
  width: 20,
  height: 20,
  placementPopover: 'auto',
  onNumberClick: undefined,
  tooltip: undefined,
};
function mapStateToProps(state) {
  return {
    isMeOffline: isPhoneOffline(state),
    myPhoneStatus: getMeId(state) && getUserPhoneStatus(state, getMeId(state)),
    conferenceCallId: getMeConferenceCallId(state),
    meInConference: isMeInConference(state),
    supplier: getSupplier(state),
    activeCalls: getMeCallsAndConferences(state),
    webrtcActive: isWebrtcActive(state),
    webrtcUsing: isWebrtcInUse(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    call: execCallRequest,
    aidTransfer: aidTransferRequest,
    addMember: startConferenceRequest,
  })(OtherPhoneButton)
);
