import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const AdminUsersEditUserHRRegistry = React.lazy(() =>
  import('./AdminUsersEditUserHRRegistry')
);

const AdminUsersEditUserHRPage = ({ puncherEnabled, ...props }) => (
  <Groups scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="admin-edit-user-hr" />
          </div>
        </div>
        <AdminUsersEditUserHRRegistry {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminUsersEditUserHRPage;
