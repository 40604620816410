/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openChatConversation } from '../../js/chat/actions';
import { generateConversationId } from '../../js/chat/ChatUtils';
import Icon from '../icons/Icon';
import { isUserOnline } from '../../js/users/selectors';
import { isWsAvailable } from '../../js/auth/selectors';

const messages = defineMessages({
  openConversation: {
    id: 'ChatButton.tooltip.openConversation',
    defaultMessage: 'Open conversation',
  },
  serviceNotAvailable: {
    id: 'ChatButton.tooltip.serviceNotAvailable',
    defaultMessage: 'Not available',
  },
});

class ChatButton extends Component {
  constructor(props) {
    super(props);
    this.openConversation = this.openConversation.bind(this);
  }

  openConversation = () => {
    this.props.openConversation({
      conversationId: generateConversationId({
        userId: this.props.user,
        groupId: this.props.group,
      }),
      byMe: true,
    });
  };

  render() {
    const {
      unavailable,
      isFilled,
      intl: { formatMessage },
    } = this.props;
    return (
      <span onClick={this.openConversation}>
        <Icon
          name={isFilled ? 'bubble-chat-2-filled' : 'bubble-chat-2'}
          width={this.props.width}
          tooltipPosition={this.props.tooltip}
          height={this.props.height}
          color={unavailable ? 'var(--yn-gray-500)' : 'var(--yn-blue-dark)'}
          className={`${this.props.className || ''} chat-button`}
          clickable={!unavailable}
          disabled={unavailable}
        >
          {unavailable
            ? formatMessage(messages.serviceNotAvailable)
            : formatMessage(messages.openConversation)}
        </Icon>
      </span>
    );
  }
}

ChatButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  tooltip: PropTypes.string,
  user: PropTypes.number,
  group: PropTypes.number,
};

ChatButton.defaultProps = {
  width: 20,
  height: 20,
};

function mapStateToProps(state, ownProps) {
  return {
    unavailable: !isWsAvailable(state),
    isFilled: ownProps.group || isUserOnline(state, ownProps.user),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    openConversation: openChatConversation,
  })(ChatButton)
);
