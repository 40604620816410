import { request } from '../request';

export default {
  getExternalUrls: (params) =>
    request({
      url: '/phone/external-urls',
      method: 'GET',
      params,
    }),
  deleteExternalUrl: (id) =>
    request({
      url: `/phone/external-urls/${id}`,
      method: 'DELETE',
    }),
  insertExternalUrl: (params) =>
    request({
      url: `/phone/external-urls`,
      method: 'POST',
      data: params,
    }),
  editExternalUrl: (params) =>
    request({
      url: `/phone/external-urls/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
};
