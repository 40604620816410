import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import { toggleDNDRequest } from '../../../js/phone/actions';
import { getMePhoneDnd, isPhoneOffline } from '../../../js/phone/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  enableDnd: {
    id: 'PhoneStatusButton.enableDnd',
    defaultMessage: 'Enable phone dnd',
  },
  disableDnd: {
    id: 'PhoneStatusButton.disableDnd',
    defaultMessage: 'Disable phone dnd',
  },
  unavailable: {
    id: 'PhoneStatusButton.unavailable',
    defaultMessage: 'Unavailable',
  },
});

class PhoneStatusButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  handleChange = () => {
    if (this.props.offline) return;
    const newDND =
      this.props.phoneDnd === PhoneEnums.DndStatus.ON
        ? PhoneEnums.DndStatus.OFF
        : PhoneEnums.DndStatus.ON;
    this.props.toggle(newDND);
  };

  render() {
    const {
      offline,
      phoneDnd,
      intl: { formatMessage },
    } = this.props;
    const activeDnd = phoneDnd === PhoneEnums.DndStatus.ON;

    return (
      <>
        <Tooltip
          isOpen={this.state.tooltipOpen}
          toggle={() => {
            this.setState({ tooltipOpen: !this.state.tooltipOpen });
          }}
          target="phone_dnd_switch"
        >
          {offline
            ? formatMessage(messages.unavailable)
            : activeDnd
            ? formatMessage(messages.disableDnd)
            : formatMessage(messages.enableDnd)}
        </Tooltip>
        <div
          id="phone_dnd_switch"
          className="mr-2"
          onClick={this.handleChange}
          style={{ cursor: offline ? 'not-allowed' : 'pointer' }}
        >
          <span
            className="rounded-circle align-self-center d-inline-block"
            style={{
              background: offline
                ? 'var(--white)'
                : activeDnd
                ? 'var(--orange)'
                : 'var(--green)',
              width: '30px',
              height: '30px',
              border: offline ? '1px solid var(--yn-gray-500)' : 'none',
            }}
          >
            {offline ? (
              <Icon
                name="phone-5-filled"
                color="var(--yn-gray-500)"
                width={16}
                height={16}
                disabled={offline}
                clickable={false}
                style={{ left: '7px', top: '7px' }}
              />
            ) : activeDnd ? (
              <Icon
                name="phone-call-reject-filled"
                color="white"
                width={16}
                height={16}
                style={{ left: '7px', top: '7px' }}
              />
            ) : (
              <Icon
                name="phone-5-filled"
                color="white"
                width={16}
                height={16}
                style={{ left: '7px', top: '7px' }}
              />
            )}
          </span>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    phoneDnd: getMePhoneDnd(state),
    offline: isPhoneOffline(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { toggle: toggleDNDRequest })(PhoneStatusButton)
);
