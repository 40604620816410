import React from 'react';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import PbxSettings from './PbxSettings';
import HelpButton from '../../../../components/HelpButton';

const PbxPhoneRoutesList = React.lazy(() => import('./PbxPhoneRoutesList'));

const PbxPhoneRoutesPage = (props) => (
  <PbxSettings scrolling>
    <div className="text-right">
      <HelpButton fileName="pbx-settings-routes" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PbxPhoneRoutesList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PbxSettings>
);

export default PbxPhoneRoutesPage;
