import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import merge from 'lodash.merge';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  /** Archive */
  FETCH_MAILOFFICES_REQUEST,
  FETCH_MAILOFFICES_SUCCESS,
  FETCH_MAILOFFICES_FAILURE,
  FETCH_PAGED_MAILOFFICES_REQUEST,
  FETCH_PAGED_MAILOFFICES_SUCCESS,
  FETCH_PAGED_MAILOFFICES_FAILURE,
  FETCH_MAILOFFICE_REQUEST,
  FETCH_MAILOFFICE_SUCCESS,
  FETCH_MAILOFFICE_FAILURE,
  DELETE_MAILOFFICE_REQUEST,
  DELETE_MAILOFFICE_SUCCESS,
  DELETE_MAILOFFICE_FAILURE,
  SET_MAILOFFICES_FILTER,
  SAVE_MAILOFFICE_REQUEST,
  SAVE_MAILOFFICE_SUCCESS,
  SAVE_MAILOFFICE_FAILURE,
  FETCH_MAILOFFICE_CREDIT_REQUEST,
  FETCH_MAILOFFICE_CREDIT_SUCCESS,
  FETCH_MAILOFFICE_CREDIT_FAILURE,
  CONFIRM_MAILOFFICE_REQUEST,
  CONFIRM_MAILOFFICE_SUCCESS,
  CONFIRM_MAILOFFICE_FAILURE,
  REJECT_MAILOFFICE_REQUEST,
  REJECT_MAILOFFICE_SUCCESS,
  REJECT_MAILOFFICE_FAILURE,
  MAILOFFICE_PRICE_NOTIFICATION,
  MAILOFFICE_ERROR_NOTIFICATION,
  MAILOFFICE_COMPLETE_NOTIFICATION,
  DELETE_MAILOFFICE_RR_SUCCESS,
} from './types';
import { UPLOAD_FILES_SUCCESS } from '../files/types';
import { adjustMailofficeData } from './MailofficeUtils';

const initialstate = {
  mailofficesChanged: false,
  fetchMailofficesLoaded: true,
  fetchMailofficesError: null,
  fetchPagedMailofficesLoaded: true,
  fetchPagedMailofficesError: null,
  fetchMailofficeLoaded: true,
  fetchMailofficeError: null,
  saveMailofficeLoaded: true,
  saveMailofficeError: null,
  saveMailofficeSuccess: null,
  fetchMailofficeCreditLoaded: true,
  fetchMailofficeCreditError: null,
  deleteMailofficeError: null,
  confirmRejectMailofficeLoaded: true,
  confirmRejectMailofficeError: null,
  credit: null,
  byId: {},
  allIds: [],
  pages: {},
  currentPage: 0,
  total: 0,
  filter: {
    status: '',
    receiver: '',
    start: '',
    end: '',
    distributionList: null,
  },
};

export default function mailoffice(state = initialstate, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialstate;
    case FETCH_PAGED_MAILOFFICES_REQUEST: {
      return {
        ...state,
        fetchPagedMailofficesLoaded: false,
        fetchPagedMailofficesError: null,
        pages: {},
      };
    }
    case FETCH_MAILOFFICES_REQUEST: {
      return {
        ...state,
        mailofficesChanged: false,
        fetchMailofficesLoaded: false,
        fetchMailofficesError: null,
      };
    }
    case FETCH_MAILOFFICES_SUCCESS:
      return {
        ...state,
        fetchMailofficesLoaded: true,
        fetchMailofficesError: null,
        byId: keyBy(
          action.payload.map((mail) => adjustMailofficeData(mail)),
          'id'
        ),
        allIds: action.payload.map((obj) => obj.id),
      };
    case FETCH_PAGED_MAILOFFICES_SUCCESS:
      return {
        ...state,
        fetchPagedMailofficesLoaded: true,
        fetchPagedMailofficesError: null,
        byId: {
          ...state.byId,
          ...keyBy(
            action.payload.archive.map((mail) => adjustMailofficeData(mail)),
            'id'
          ),
        },
        pages: {
          ...state.pages,
          [action.payload.page]: action.payload.archive.map((obj) => obj.id),
        },
        currentPage: action.payload.page,
        total: action.payload.total,
      };
    case FETCH_PAGED_MAILOFFICES_FAILURE:
    case FETCH_MAILOFFICES_FAILURE:
      return {
        ...state,
        fetchPagedMailofficesLoaded: true,
        fetchMailofficesLoaded: true,
        fetchMailofficesError: action.errors,
      };
    case FETCH_MAILOFFICE_REQUEST: {
      return {
        ...state,
        fetchMailofficeLoaded: false,
      };
    }
    case FETCH_MAILOFFICE_SUCCESS:
      return {
        ...state,
        fetchMailofficeLoaded: true,
        fetchMailofficeError: null,
        confirmRejectMailofficeError: null,
        byId: {
          ...state.byId,
          [action.details.id]: merge(
            state.byId[action.details.id],
            adjustMailofficeData(action.details)
          ),
        },
      };
    case FETCH_MAILOFFICE_FAILURE:
      return {
        ...state,
        fetchMailofficeLoaded: true,
        fetchMailofficeError: action.errors,
      };
    case DELETE_MAILOFFICE_REQUEST: {
      return {
        ...state,
        deleteMailofficeError: null,
      };
    }
    case DELETE_MAILOFFICE_SUCCESS: {
      const byId = { ...state.byId };
      const allIds = [...state.allIds];
      const currentPageIds = [...state.pages[state.currentPage]];
      delete byId[action.id];
      pull(allIds, action.id);
      pull(currentPageIds, action.id);
      return {
        ...state,
        byId,
        allIds,
        pages: {
          ...state.pages,
          [state.currentPage]: currentPageIds,
        },
        deleteMailofficeError: null,
      };
    }
    case DELETE_MAILOFFICE_FAILURE:
      return {
        ...state,
        deleteMailofficeError: action.errors,
      };
    case SAVE_MAILOFFICE_REQUEST: {
      return {
        ...state,
        saveMailofficeLoaded: false,
        saveMailofficeError: null,
        saveMailofficeSuccess: null,
      };
    }
    case SAVE_MAILOFFICE_SUCCESS:
      return {
        ...state,
        saveMailofficeLoaded: true,
        saveMailofficeError: null,
        saveMailofficeSuccess: action.success,
        byId: {
          ...state.byId,
          [action.mailofficeData.id]: {
            ...state.byId[action.mailofficeData.id],
            ...action.mailofficeData,
          },
        },
      };
    case SAVE_MAILOFFICE_FAILURE:
      return {
        ...state,
        saveMailofficeLoaded: true,
        saveMailofficeError: action.errors,
        saveMailofficeSuccess: null,
      };
    case FETCH_MAILOFFICE_CREDIT_REQUEST: {
      return {
        ...state,
        fetchMailofficeCreditLoaded: false,
        fetchMailofficeCreditError: null,
      };
    }
    case FETCH_MAILOFFICE_CREDIT_SUCCESS:
      return {
        ...state,
        fetchMailofficeCreditLoaded: true,
        fetchMailofficeCreditError: null,
        credit: action.credit.money,
      };
    case FETCH_MAILOFFICE_CREDIT_FAILURE:
      return {
        ...state,
        fetchMailofficeCreditLoaded: true,
        fetchMailofficeCreditError: action.errors,
      };
    case SET_MAILOFFICES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    case CONFIRM_MAILOFFICE_REQUEST:
      return {
        ...state,
        confirmRejectMailofficeLoaded: false,
        confirmRejectMailofficeError: null,
      };
    case CONFIRM_MAILOFFICE_SUCCESS:
      return {
        ...state,
        confirmRejectMailofficeLoaded: true,
        confirmRejectMailofficeError: null,
      };
    case CONFIRM_MAILOFFICE_FAILURE:
      return {
        ...state,
        confirmRejectMailofficeLoaded: true,
        confirmRejectMailofficeError: action.error,
      };
    case REJECT_MAILOFFICE_REQUEST:
      return {
        ...state,
        confirmRejectMailofficeLoaded: false,
        confirmRejectMailofficeError: null,
      };
    case REJECT_MAILOFFICE_SUCCESS:
      return {
        ...state,
        confirmRejectMailofficeLoaded: true,
        confirmRejectMailofficeError: null,
      };
    case REJECT_MAILOFFICE_FAILURE:
      return {
        ...state,
        confirmRejectMailofficeLoaded: true,
        confirmRejectMailofficeError: action.error,
      };
    case MAILOFFICE_PRICE_NOTIFICATION:
    case MAILOFFICE_ERROR_NOTIFICATION:
    case MAILOFFICE_COMPLETE_NOTIFICATION:
      return {
        ...state,
        mailofficesChanged: !action.payload.archived
          ? true
          : state.mailofficesChanged,
      };
    case UPLOAD_FILES_SUCCESS: {
      if (action.payload.scope === 'mailofficeRR') {
        return {
          ...state,
          byId: {
            ...state.byId,
            [action.payload.entityId]: {
              ...state.byId[action.payload.entityId],
              returnReceipt: action.payload.data.stored,
            },
          },
        };
      }
      return state;
    }
    case DELETE_MAILOFFICE_RR_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            returnReceipt: null,
          },
        },
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        saveMailofficeError: null,
        saveMailofficeSuccess: null,
      };
    default:
      return state;
  }
}
