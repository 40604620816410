export const REMOVE_DESKTOP_NOTIFICATION = 'REMOVE_DESKTOP_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const ARCHIVE_NOTIFICATION = 'ARCHIVE_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const FETCH_ARCHIVED_NOTIFICATIONS_REQUEST =
  'FETCH_ARCHIVED_NOTIFICATIONS_REQUEST';
export const FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS =
  'FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS';
export const FETCH_ARCHIVED_NOTIFICATIONS_FAILURE =
  'FETCH_ARCHIVED_NOTIFICATIONS_FAILURE';
export const REMOVE_INCOMING_VIDEOCALL_NOTIFICATION =
  'REMOVE_INCOMING_VIDEOCALL_NOTIFICATION';
export const ADD_LOST_VIDEOCALL_NOTIFICATION =
  'ADD_LOST_VIDEOCALL_NOTIFICATION';
export const REMOVE_LOST_VIDEOCALL_NOTIFICATION =
  'REMOVE_LOST_VIDEOCALL_NOTIFICATION';
