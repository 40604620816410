import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  DELETE_ASSOCIATION_FAILURE,
  DELETE_ASSOCIATION_REQUEST,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_MEMBER_ASSOCIATION_FAILURE,
  DELETE_MEMBER_ASSOCIATION_REQUEST,
  DELETE_MEMBER_ASSOCIATION_SUCCESS,
  EDIT_MEMBER_ASSOCIATION_FAILURE,
  EDIT_MEMBER_ASSOCIATION_REQUEST,
  EDIT_MEMBER_ASSOCIATION_SUCCESS,
  FETCH_ASSOCIATIONS_FAILURE,
  FETCH_ASSOCIATIONS_REQUEST,
  FETCH_ASSOCIATIONS_SUCCESS,
  FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE,
  FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST,
  FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS,
} from './types';

const initialState = {
  associationsById: {},
  associationsIds: [],
  associationsLoaded: false,
  associationsError: false,
  deleteAssociationLoaded: true,
  deleteAssociationError: false,
  memberOperationLoaded: true,
  memberOperationError: false,
  memberDetailsLoaded: true,
  memberDetailsError: false,
  memberData: {},
};

export default function associations(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_ASSOCIATIONS_REQUEST:
      return {
        ...state,
        associationsById: {},
        associationsIds: [],
        associationsLoaded: false,
        associationsError: false,
      };

    case FETCH_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associationsById: keyBy(action.data, 'id'),
        associationsIds: action.data.map((row) => row.id),
        associationsLoaded: true,
        associationsError: false,
      };

    case FETCH_ASSOCIATIONS_FAILURE:
      return {
        ...state,
        associationsById: {},
        associationsIds: [],
        associationsLoaded: true,
        associationsError: true,
      };

    case DELETE_ASSOCIATION_REQUEST:
      return {
        ...state,
        deleteAssociationLoaded: false,
        deleteAssociationError: false,
      };
    case DELETE_ASSOCIATION_SUCCESS: {
      delete state.associationsById[action.id];
      pull(state.associationsIds, action.id);
      return {
        ...state,
        deleteAssociationLoaded: true,
        deleteAssociationError: false,
      };
    }
    case DELETE_ASSOCIATION_FAILURE:
      return {
        ...state,
        deleteAssociationLoaded: true,
        deleteAssociationError: true,
      };

    case DELETE_MEMBER_ASSOCIATION_REQUEST:
    case EDIT_MEMBER_ASSOCIATION_REQUEST:
      return {
        ...state,
        memberOperationLoaded: false,
        memberOperationError: false,
      };

    case DELETE_MEMBER_ASSOCIATION_SUCCESS: {
      let members =
        state.associationsById[action.payload.associationId].members;
      members = members.map(
        (member) => member.id !== action.payload.customerId
      );
      return {
        ...state,
        associationsById: {
          ...state.associationsById,
          [action.payload.associationId]: {
            ...state.associationsById[action.payload.associationId],
            members,
          },
        },
        memberOperationLoaded: true,
        memberOperationError: false,
      };
    }
    case DELETE_MEMBER_ASSOCIATION_FAILURE:
    case EDIT_MEMBER_ASSOCIATION_FAILURE:
      return {
        ...state,
        memberOperationLoaded: true,
        memberOperationError: true,
      };

    case EDIT_MEMBER_ASSOCIATION_SUCCESS: {
      let members =
        state.associationsById[action.payload.associationId].members;
      members = members.map((member) =>
        member.id === action.payload.customerId
          ? {
              ...member,
              isAdmin:
                action.payload.data.isAdmin !== null
                  ? action.payload.data.isAdmin
                  : member.isAdmin,
              mode:
                action.payload.data.mode !== null
                  ? action.payload.data.mode
                  : member.mode,
            }
          : member
      );
      return {
        ...state,
        associationsById: {
          ...state.associationsById,
          [action.payload.associationId]: {
            ...state.associationsById[action.payload.associationId],
            members,
          },
        },
        memberOperationLoaded: true,
        memberOperationError: false,
      };
    }

    case FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST:
      return {
        ...state,
        memberDetailsLoaded: false,
        memberDetailsError: false,
      };

    case FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS:
      return {
        ...state,
        memberDetailsLoaded: true,
        memberDetailsError: false,
        memberData: action.payload,
      };

    case FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE:
      return {
        ...state,
        memberDetailsLoaded: true,
        memberDetailsError: true,
      };

    default:
      return state;
  }
}
