import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSAliasEdit from './SMSAliasEdit';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const SMSAliasEditPage = () => (
  <SMS>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="sms-alias-edit" />
          </div>
        </div>
        <SMSAliasEdit />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSAliasEditPage;
