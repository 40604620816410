import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from '../../../components/formControls/Button';
import { sendStopUseWebphoneRequest } from '../../../js/phone/actions';

const messages = defineMessages({
  title: {
    id: 'OtherSessionsModal.title',
    defaultMessage: 'There are other sessions using web phone',
  },
  message: {
    id: 'OtherSessionsModal.message',
    defaultMessage:
      'Do you want to use the web phone here or leave it available for other sessions?',
  },
  buttonTake: {
    id: 'OtherSessionsModal.buttonTake',
    defaultMessage: 'Use it here',
  },
  buttonCancel: {
    id: 'OtherSessionsModal.buttonCancel',
    defaultMessage: 'Leave it',
  },
});

class OtherSessionsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  takePhone = () => {
    const { sendStopUseWebrtcPhone } = this.props;
    sendStopUseWebrtcPhone();
  };

  render() {
    const {
      isOpen,
      onCancel,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <Modal isOpen={isOpen}>
          <ModalHeader>{formatMessage(messages.title)}</ModalHeader>
          <ModalBody>{formatMessage(messages.message)}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.takePhone}
              //loading={loading}
              text={formatMessage(messages.buttonTake)}
            />
            <Button
              color="primary"
              onClick={onCancel}
              text={formatMessage(messages.buttonCancel)}
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

OtherSessionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

OtherSessionsModal.defaultProps = {
  isOpen: false,
};

export default injectIntl(
  connect(null, {
    sendStopUseWebrtcPhone: sendStopUseWebphoneRequest,
  })(OtherSessionsModal)
);
