import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import UsersAndContacts from '../../../components/formControls/UsersAndContacts';
import Button from '../../../components/formControls/Button';

const messages = defineMessages({
  ok: {
    id: 'FavoritesAddMembersDropdown.button.ok',
    defaultMessage: 'Ok',
  },
});

class FavoritesAddMembersDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  handleFavoritesSelection = (selected) => {
    this.setState({
      selected,
    });
  };

  render() {
    const {
      members,
      intl: { formatMessage },
      onSubmit,
    } = this.props;
    const { selected } = this.state;
    return (
      <div
        className="rounded-bottom p-1"
        style={{
          background: 'var(--white)',
          position: 'absolute',
          top: '33px',
          left: 15,
          right: 15,
          zIndex: 961,
          display: 'flex',
          flexflow: 'row wrap',
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          boxShadow: '0 0.05rem 0.25rem rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <UsersAndContacts
            isMulti
            isClearable
            onSelect={this.handleFavoritesSelection}
            excluded={members}
            selected={selected}
            style={{ multiValueLabel: { maxWidth: '120px' } }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0,
          }}
        >
          <Button
            text={formatMessage(messages.ok)}
            onClick={() => onSubmit(selected)}
            style={{ height: '35px' }}
          />
        </div>
      </div>
    );
  }
}

FavoritesAddMembersDropdown.propTypes = {
  excluded: PropTypes.arrayOf(PropTypes.string),
};

FavoritesAddMembersDropdown.defaultProps = {
  excluded: [],
};

export default injectIntl(FavoritesAddMembersDropdown);
