import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import ynConf from '../../conf';

const messages = defineMessages({
  title: {
    id: 'PasswordResetPage.title',
    defaultMessage: 'Perfect!',
  },
  altTextLogo: {
    id: 'PasswordResetPage.altTextLogo',
    defaultMessage: 'Logo YouNeed',
  },
  altTextAmbrosia: {
    id: 'PasswordResetPage.altTextAmbrosia',
    defaultMessage:
      'Ambrosia, YouNeed vocal assistant and mascot, showing a thumb up',
  },
  subtitle: {
    id: 'PasswordResetPage.subtitle',
    defaultMessage: 'We just sent you an email',
  },
  passwordResetLink: {
    id: 'PasswordResetPage.backToLoginLink',
    defaultMessage: 'Back to login',
  },
});

const PasswordResetPage = ({ intl: { formatMessage } }) => (
  <div className="bg-primary p-0 pt-sm-4 h-100">
    <div className="yn-login-form bg-white rounded p-3 m-auto text-center">
      <img
        src={`${ynConf.publicFolderPath}/logo.png`}
        className="img-fluid"
        style={{
          height: '50px',
        }}
        alt={formatMessage(messages.altTextLogo)}
      />
      <img
        src={`${ynConf.publicFolderPath}/yn20.png`}
        className="img-fluid"
        style={{
          height: '250px',
        }}
        alt={formatMessage(messages.altTextAmbrosia)}
      />
      <h5>
        <strong>{formatMessage(messages.title)}</strong>
      </h5>
      <h6>
        <strong>{formatMessage(messages.subtitle)}</strong>
      </h6>
      <Link to="/login">{formatMessage(messages.passwordResetLink)}</Link>
    </div>
  </div>
);

export default injectIntl(PasswordResetPage);
