import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { push } from 'connected-react-router';
import {
  fetchPunchingsSuccess,
  fetchPunchingsFailure,
  savePunchingSuccess,
  savePunchingFailure,
  fetchPunchingsRequest,
  fetchPresenceSettingsFailure,
  fetchPresenceSettingsSuccess,
  fetchPresenceSettingsRequest,
  fetchVacationsToValidateSuccess,
  fetchVacationsToValidateFailure,
  fetchPunchingDetailsSuccess,
  fetchPunchingDetailsFailure,
  fetchUsersPunchingsSuccess,
  fetchUsersPunchingsFailure,
  fetchWorkingUsersSuccess,
  fetchWorkingUsersFailure,
  fetchPbxRegistrySuccess,
  fetchPbxRegistryFailure,
  savePbxRegistrySuccess,
  savePbxRegistryFailure,
  fetchPunchingsPlanSuccess,
  fetchPunchingsPlanFailure,
  fetchExportTemplatesSuccess,
  fetchExportTemplatesFailure,
  fetchExportTemplateSuccess,
  fetchExportTemplateFailure,
  saveExportTemplateSuccess,
  saveExportTemplateFailure,
  deleteExportTemplateSuccess,
  deleteExportTemplateFailure,
  fetchPunchingsToValidateSuccess,
  fetchPunchingsToValidateFailure,
  validatePunchingFailure,
  validatePunchingSuccess,
  fetchPunchingsToValidateRequest,
  fetchAbsenceSuccess,
  fetchAbsenceFailure,
  saveOtherPunchingSuccess,
  saveOtherPunchingFailure,
  deleteAbsenceFailure,
  fetchAbsenceRequest,
  saveAbsenceSuccess,
  saveAbsenceFailure,
  fetchQRCodeSettingsSuccess,
  fetchQRCodeSettingsFailure,
  saveQRCodeSettingsSuccess,
  saveQRCodeSettingsFailure,
  fetchQRCodeSettingsRequest,
  fetchGuestDataSuccess,
  fetchGuestDataFailure,
  saveGuestVisitSuccess,
  saveGuestVisitFailure,
  fetchGuestVisitsSuccess,
  fetchGuestVisitsFailure,
  fetchPunchingJustificationsSuccess,
  deletePunchingJustificationSuccess,
  deletePunchingJustificationFailure,
  editPunchingJustificationSuccess,
  fetchPunchingJustificationsRequest,
  editPunchingJustificationFailure,
  deletePunchingSuccess,
  fetchUsersPunchingsRequest,
  editPunchingSuccess,
  deletePunchingFailure,
  editPunchingFailure,
  fetchWorkingUsersExcelSuccess,
  fetchWorkingUsersExcelFailure,
  fetchCassaIntegrazioneSuccess,
  fetchCassaIntegrazioneFailure,
  saveCassaIntegrazioneSuccess,
  fetchCassaIntegrazioneRequest,
  saveCassaIntegrazioneFailure,
  deleteCassaIntegrazioneFailure,
  saveExtraWorkingTimeSuccess,
  saveExtraWorkingTimeFailure,
  deleteExtraWorkingTimeSuccess,
  deleteExtraWorkingTimeFailure,
} from './actions';
import api from '../api';
import history from '../../history';
import { checkApiResponse, checkApiError } from '../rootSaga';
import {
  FETCH_PUNCHINGS_REQUEST,
  SAVE_PUNCHING_REQUEST,
  FETCH_PRESENCE_SETTINGS_REQUEST,
  FETCH_VACATIONS_TO_VALIDATE_REQUEST,
  FETCH_PUNCHING_DETAILS_REQUEST,
  FETCH_USERS_PUNCHINGS_REQUEST,
  INSERT_VACATION_NOTIFICATION,
  DELETE_VACATION_NOTIFICATION,
  ASK_DELETING_VACATION_NOTIFICATION,
  CONFIRM_VACATION_NOTIFICATION,
  REJECT_VACATION_NOTIFICATION,
  CONFIRM_DELETING_VACATION_NOTIFICATION,
  REJECT_DELETING_VACATION_NOTIFICATION,
  FETCH_WORKING_USERS_REQUEST,
  FETCH_PBX_REGISTRY_REQUEST,
  SAVE_PBX_REGISTRY_REQUEST,
  FETCH_PUNCHINGS_PLAN_REQUEST,
  FETCH_EXPORT_TEMPLATES_REQUEST,
  INSERT_PUNCHING_NOTIFICATION,
  CONFIRM_PUNCHING_NOTIFICATION,
  REJECT_PUNCHING_NOTIFICATION,
  FETCH_EXPORT_TEMPLATE_REQUEST,
  SAVE_EXPORT_TEMPLATE_REQUEST,
  DELETE_EXPORT_TEMPLATE_REQUEST,
  FETCH_PUNCHINGS_TO_VALIDATE_REQUEST,
  VALIDATE_PUNCHING_REQUEST,
  FETCH_ABSENCE_REQUEST,
  SAVE_OTHER_PUNCHING_REQUEST,
  DELETE_ABSENCE_REQUEST,
  SAVE_ABSENCE_REQUEST,
  FETCH_QRCODE_SETTINGS_REQUEST,
  SAVE_QRCODE_SETTINGS_REQUEST,
  FETCH_GUEST_DATA_REQUEST,
  SAVE_GUEST_VISIT_REQUEST,
  FETCH_GUEST_VISITS_REQUEST,
  FETCH_PUNCHING_JUSTIFICATIONS_REQUEST,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST,
  DELETE_OTHER_PUNCHING_REQUEST,
  EDIT_OTHER_PUNCHING_REQUEST,
  FETCH_CASSA_INTEGRAZIONE_REQUEST,
  SAVE_CASSA_INTEGRAZIONE_REQUEST,
  DELETE_CASSA_INTEGRAZIONE_REQUEST,
  SAVE_EXTRA_WORKINGTIME_REQUEST,
  DELETE_EXTRA_WORKINGTIME_REQUEST,
} from './types';
import {
  PUNCHER_LIST_PAGE_SIZE,
  RETRY_FETCH_SETTINGS_DELAY,
  PUNCHINGS_VALIDATION_PAGE_SIZE,
  CASSA_INTEGRAZIONE_LIST_PAGE_SIZE,
} from './PresenceUtils';
import {
  getPuncherFilter,
  getCurrentPage,
  getPunchingsToBeValidatedFilter,
  getPunchingsToValidateCurrentPage,
} from './selectors';
import { INIT_MAIN_SUCCESS } from '../me/types';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import Utils from '../lib/utils';
import { getAbsenceFilter, getCassaIntegrazioneFilter, getFetchFirstAbsences, getFetchFirstCassaIntegrazione, getPunchingsFilter } from './selectorsPresences';
import { isVacationEnabled } from '../calendar/selectors';

const intlStrings = defineMessages({
  insertVacation: {
    id: 'insertVacation',
    defaultMessage: 'There is a new vacation request',
  },
  deleteVacation: {
    id: 'deleteVacation',
    defaultMessage: 'A vacation request has been deleted',
  },
  askDeletingVacation: {
    id: 'askDeletingVacation',
    defaultMessage: 'There is a request to delete a confirmed vacation',
  },
  confirmVacation: {
    id: 'confirmVacation',
    defaultMessage: 'Your vacation request has been confirmed',
  },
  rejectVacation: {
    id: 'rejectVacation',
    defaultMessage: 'Your vacation request has been rejected',
  },
  confirmDeletingVacation: {
    id: 'confirmDeletingVacation',
    defaultMessage: 'Your vacation deletion has been confirmed',
  },
  rejectDeletingVacation: {
    id: 'rejectDeletingVacation',
    defaultMessage: 'Your vacation deletion has been rejected',
  },
  insertPunching: {
    id: 'insertPunching',
    defaultMessage: 'There is a punching to be validated',
  },
  confirmPunching: {
    id: 'confirmPunching',
    defaultMessage: 'Your punching has been confirmed',
  },
  rejectPunching: {
    id: 'rejectPunching',
    defaultMessage: 'Your punching has been rejected',
  },
});

export function* fetchSettings() {
  while (true) {
    try {
      const res = yield call(api.presence.getSettings);
      yield call(checkApiResponse, res);
      yield put(fetchPresenceSettingsSuccess(res.data));
      break;
    } catch (err) {
      const error = yield call(checkApiError, err);
      if (error) yield put(fetchPresenceSettingsFailure(error));
    }
    yield delay(RETRY_FETCH_SETTINGS_DELAY);
  }
}

export function* fetchPunchings(action) {
  try {
    const res = yield call(api.presence.getPunchings, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchPunchingsSuccess({
          punchings: [],
          total: 0,
          page: action.payload.page,
        })
      );
    } else
      yield put(
        fetchPunchingsSuccess({
          punchings: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPunchingsFailure(error));
  }
}

export function* fetchUsersPunchings(action) {
  try {
    const res = yield call(api.presence.getUsersPunchings, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchUsersPunchingsSuccess({ punchings: [], total: 0, page: 0 })
      );
    } else
      yield put(
        fetchUsersPunchingsSuccess({
          punchings: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUsersPunchingsFailure(error));
  }
}

export function* fetchPunchingsPlan(action) {
  try {
    let res = yield call(api.presence.getUsersPunchings, action.payload);
    yield call(checkApiResponse, res);
    const punchings = res.status === 204 ? [] : res.data.data;
    res = yield call(api.presence.getAbsences, action.payload);
    yield call(checkApiResponse, res);
    const absences = res.status === 204 ? [] : res.data.data;
    res = yield call(api.presence.getCassaIntegrazione, action.payload);
    yield call(checkApiResponse, res);
    const casse = res.status === 204 ? [] : res.data.data;
    res = yield call(api.presence.getExtra, action.payload);
    yield call(checkApiResponse, res);
    const extra = res.status === 204 ? [] : res.data.data;
    let vacations = [];
    const vacationEnabled = yield select(isVacationEnabled);
    if (vacationEnabled) {
      res = yield call(api.calendar.fetchVacationPlan, action.payload);
      yield call(checkApiResponse, res);
      vacations = res.data;
    }
    yield put(
      fetchPunchingsPlanSuccess({
        punchings,
        vacations,
        absences,
        casse,
        extra
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPunchingsPlanFailure(error));
  }
}

export function* savePunching(action) {
  try {
    const res = yield call(
      action.payload.id
        ? api.presence.updatePunching
        : api.presence.createPunching,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(savePunchingSuccess());
    const filter = yield select(getPuncherFilter);
    const page = yield select(getCurrentPage);
    const params = {
      start: filter.start,
      end: filter.end,
      page: action.payload.id ? page : 0,
      pageSize: PUNCHER_LIST_PAGE_SIZE,
    };
    yield put(fetchPresenceSettingsRequest());
    if (history.location.pathname.includes(`puncher/punchings`)) {
      yield put(fetchPunchingsRequest(params));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(savePunchingFailure(error));
  }
}

export function* saveOtherPunching(action) {
  try {
    const res = yield call(api.presence.createUsersPunching, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      saveOtherPunchingSuccess({
        ...action.payload,
        id: res.data.id,
      })
    );
  } catch (err) {
    yield put(saveOtherPunchingFailure(err));
  }
}

export function* fetchVacationsToValidate(action) {
  try {
    const res = yield call(api.calendar.getVacationsToValidate, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchVacationsToValidateSuccess({
          data: [],
          total: 0,
          page: action.payload.page,
        })
      );
    } else {
      yield put(
        fetchVacationsToValidateSuccess({
          page: action.payload.page,
          ...res.data,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVacationsToValidateFailure(error));
  }
}

export function* fetchPunchingDetails(action) {
  while (true) {
    try {
      const res = yield call(api.presence.getPunchingDetails, action.payload);
      yield call(checkApiResponse, res);
      yield put(fetchPunchingDetailsSuccess(res.data));
      break;
    } catch (err) {
      const error = yield call(checkApiError, err);
      if (error) yield put(fetchPunchingDetailsFailure(error));
    }
  }
}

export function* notifyInsertVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.INSERT_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.insertVacation),
      group: NotificationEnums.NotificationGroups.INSERT_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/administration/validations/notifications');
      },
    });
  }
}

export function* notifyDeleteVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.DELETE_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.deleteVacation),
      group: NotificationEnums.NotificationGroups.DELETE_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/administration/validations/notifications');
      },
    });
  }
}

export function* notifyAskDeletingVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ASK_DELETING_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.askDeletingVacation),
      group: NotificationEnums.NotificationGroups.ASK_DELETING_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/administration/validations/notifications');
      },
    });
  }
}

export function* notifyConfirmVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.CONFIRM_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.confirmVacation),
      group: NotificationEnums.NotificationGroups.CONFIRM_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/calendar/notifications');
      },
    });
  }
}

export function* notifyRejectVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.REJECT_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.rejectVacation),
      group: NotificationEnums.NotificationGroups.REJECT_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/calendar/notifications');
      },
    });
  }
}

export function* notifyConfirmDeletingVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.CONFIRM_DELETING_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.confirmDeletingVacation),
      group: NotificationEnums.NotificationGroups.CONFIRM_DELETING_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/calendar/notifications');
      },
    });
  }
}

export function* notifyRejectDeletingVacation(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.REJECT_DELETING_VACATION}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.rejectDeletingVacation),
      group: NotificationEnums.NotificationGroups.REJECT_DELETING_VACATION,
      image: NotificationEnums.NotificationImages.VACATION,
      onclick: () => {
        history.push('/calendar/notifications');
      },
    });
  }
}

export function* notifyInsertPunching(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.INSERT_PUNCHING}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.insertPunching),
      group: NotificationEnums.NotificationGroups.INSERT_PUNCHING,
      image: NotificationEnums.NotificationImages.PUNCHING,
      onclick: () => {
        history.push('/administration/validations/notifications');
      },
    });
  }
}

export function* notifyConfirmPunching(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.CONFIRM_PUNCHING}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.confirmPunching),
      group: NotificationEnums.NotificationGroups.CONFIRM_PUNCHING,
      image: NotificationEnums.NotificationImages.PUNCHING,
      onclick: () => {
        history.push('/puncher/notifications');
      },
    });
  }
}

export function* notifyRejectPunching(action) {
  if (!action.payload.archived) {
    const intl = yield call(Utils.retrieveIntl);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.REJECT_PUNCHING}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.rejectPunching),
      group: NotificationEnums.NotificationGroups.REJECT_PUNCHING,
      image: NotificationEnums.NotificationImages.PUNCHING,
      onclick: () => {
        history.push('/puncher/notifications');
      },
    });
    // update exit/enter
    yield put(fetchPresenceSettingsRequest());
  }
}

export function* fetchWorkingUsers(action) {
  try {
    const res = yield call(api.presence.getWorkingUsers, action.payload);

    yield call(checkApiResponse, res);
    if (res.status === 204) {
      if (action.payload.excelSearch) {
        yield put(fetchWorkingUsersExcelSuccess([]));
      } else {
        yield put(fetchWorkingUsersSuccess({ data: [], total: 0, page: 0 }));
      }
    } else if (action.payload.excelSearch) {
      yield put(fetchWorkingUsersExcelSuccess(res.data.data));
    } else {
      yield put(
        fetchWorkingUsersSuccess({
          data: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) {
      if (action.payload.excelSearch) {
        yield put(fetchWorkingUsersExcelFailure(error));
      } else {
        yield put(fetchWorkingUsersFailure(error));
      }
    }
  }
}

export function* fetchPbxRegistry(action) {
  try {
    const res = yield call(api.presence.getPbxRegistry, action.id);

    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchPbxRegistrySuccess({ data: [], id: action.id }));
    } else
      yield put(
        fetchPbxRegistrySuccess({
          data: res.data,
          id: action.id,
        })
      );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPbxRegistryFailure(error));
  }
}

export function* savePbxRegistry(action) {
  try {
    const res = yield call(api.presence.savePbxRegistry, action.payload);

    yield call(checkApiResponse, res);
    yield put(savePbxRegistrySuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(savePbxRegistryFailure(error));
  }
}

export function* fetchExportTemplates() {
  try {
    const res = yield call(api.presence.getExportTemplates);
    yield call(checkApiResponse, res);
    yield put(fetchExportTemplatesSuccess(res.status === 204 ? [] : res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchExportTemplatesFailure(error));
  }
}

export function* fetchExportTemplate(action) {
  try {
    const res = yield call(api.presence.getExportTemplate, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchExportTemplateSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchExportTemplateFailure(error));
  }
}

export function* saveExportTemplate(action) {
  try {
    const res = yield call(
      action.payload.id
        ? api.presence.updateExportTemplate
        : api.presence.createExportTemplate,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(saveExportTemplateSuccess());
    yield put(push('/administration/presences/management/export-template'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) {
      if (err.data && err.data !== '' && err.data.length > 0) {
        yield put(
          saveExportTemplateFailure({ status: error, messages: err.data })
        );
      } else {
        yield put(saveExportTemplateFailure({ status: error }));
      }
    }
  }
}

export function* deleteExportTemplate(action) {
  try {
    const res = yield call(
      api.presence.deleteExportTemplate,
      action.payload.id
    );
    yield call(checkApiResponse, res);
    yield put(deleteExportTemplateSuccess(action.payload.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteExportTemplateFailure(error));
  }
}

export function* fetchPunchingsToValidate(action) {
  try {
    const res = yield call(api.presence.getPunchingsToValidate, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchPunchingsToValidateSuccess({
          data: [],
          total: 0,
          page: action.payload.page,
        })
      );
    } else {
      yield put(
        fetchPunchingsToValidateSuccess({
          page: action.payload.page,
          ...res.data,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPunchingsToValidateFailure(error));
  }
}

export function* validatePunching(action) {
  try {
    const res = yield call(
      api.presence.validatePunching,
      action.payload.id,
      action.payload.params
    );
    yield call(checkApiResponse, res);
    yield put(validatePunchingSuccess());

    const filter = yield select(getPunchingsToBeValidatedFilter);
    const page = yield select(getPunchingsToValidateCurrentPage);
    const params = {
      name: filter && filter.name,
      page,
      pageSize: PUNCHINGS_VALIDATION_PAGE_SIZE,
    };
    yield put(fetchPunchingsToValidateRequest(params));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(validatePunchingFailure(error));
  }
}

export function* fetchAbsences(action) {
  try {
    const res = yield call(api.presence.getAbsences, action.payload);

    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchAbsenceSuccess({ data: [], total: 0, page: 0 }));
    } else
      yield put(
        fetchAbsenceSuccess({
          data: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
  } catch (err) {
    yield put(fetchAbsenceFailure(err));
  }
}

export function* saveAbsence(action) {
  try {
    let res;
    if (action.id) {
      res = yield call(api.presence.updateAbsence, action.payload, action.id);
    } else {
      res = yield call(api.presence.insertAbsence, action.payload);
    }
    yield call(checkApiResponse, res);
    yield put(saveAbsenceSuccess());
    const madeSearching = yield select(getFetchFirstAbsences);
    if (madeSearching) {
      const searchFilter = yield select(getAbsenceFilter);
      yield put(
        fetchAbsenceRequest({
          ...searchFilter,
          page: 0,
          pageSize: PUNCHER_LIST_PAGE_SIZE,
        })
      );
    }
  } catch (err) {
    yield put(saveAbsenceFailure(err));
  }
}

export function* deleteAbsence(action) {
  try {
    const res = yield call(api.presence.deleteAbsence, action.id);
    yield call(checkApiResponse, res);
    const searchFilter = yield select(getAbsenceFilter);
    yield put(
      fetchAbsenceRequest({
        ...searchFilter,
        page: 0,
        pageSize: PUNCHER_LIST_PAGE_SIZE,
      })
    );
  } catch (err) {
    yield put(deleteAbsenceFailure(err));
  }
}

export function* fetchQRCodeSettings() {
  try {
    const res = yield call(api.presence.getQRCodeSettings);
    yield call(checkApiResponse, res);
    yield put(fetchQRCodeSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchQRCodeSettingsFailure(error));
  }
}

export function* saveQRCodeSettings(action) {
  try {
    const res = yield call(api.presence.insertQRCodeSettings, action.payload);

    yield call(checkApiResponse, res);
    yield put(saveQRCodeSettingsSuccess());
    yield put(fetchQRCodeSettingsRequest());
  } catch (err) {
    yield put(saveQRCodeSettingsFailure(err));
  }
}

export function* fetchGuestData(action) {
  try {
    const res = yield call(api.presence.getGuestData, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchGuestDataSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchGuestDataFailure(error));
  }
}

export function* saveGuestVisit(action) {
  try {
    const res = yield call(api.presence.insertGuestVisit, action.payload);

    yield call(checkApiResponse, res);
    yield put(saveGuestVisitSuccess());
  } catch (err) {
    yield put(saveGuestVisitFailure(err.status));
  }
}

export function* fetchGuestVisits(action) {
  try {
    const res = yield call(api.presence.getGuestVisits, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchGuestVisitsSuccess({ data: [], total: 0, page: 0 }));
    } else {
      yield put(
        fetchGuestVisitsSuccess({
          data: res.data.data,
          total: res.data.total,
          page: action.payload ? action.payload.page : null,
        })
      );
    }
  } catch (err) {
    yield put(fetchGuestVisitsFailure(err));
  }
}

export function* fetchJustifications() {
  try {
    const res = yield call(api.presence.getJustifications);
    yield call(checkApiResponse, res);

    yield put(fetchPunchingJustificationsSuccess(res.data));
  } catch (err) {
    yield put(
      fetchPunchingJustificationsSuccess([
        { id: 1, direction: 'ENTER', name: 'prova' },
      ])
    ); /*
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPunchingJustificationsFailure(error));*/
  }
}

export function* deleteJustification(action) {
  try {
    const res = yield call(api.presence.deleteJustification, action.id);
    yield call(checkApiResponse, res);
    yield put(deletePunchingJustificationSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchPunchingJustificationsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deletePunchingJustificationFailure(error));
  }
}

export function* editJustification(action) {
  try {
    const res = action.payload.id
      ? yield call(
          api.presence.editJustification,
          action.payload.data,
          action.payload.id
        )
      : yield call(
          api.presence.insertJustification,
          action.payload.data,
          action.id
        );
    yield call(checkApiResponse, res);
    yield put(editPunchingJustificationSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchPunchingJustificationsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editPunchingJustificationFailure(error));
  }
}

export function* deletePunching(action) {
  try {
    const res = yield call(api.presence.deletePunching, action.payload.id);
    yield call(checkApiResponse, res);
    yield put(deletePunchingSuccess());
    const filter = yield select(getPunchingsFilter);
    const page = yield select(getCurrentPage);
    const params = {
      start: filter.start,
      end: filter.end,
      users: filter.users,
      page: action.payload.id ? page : 0,
      pageSize: PUNCHER_LIST_PAGE_SIZE,
    };
    yield put(fetchPresenceSettingsRequest());
    yield put(fetchUsersPunchingsRequest(params));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deletePunchingFailure(error));
  }
}

export function* editPunching(action) {
  try {
    const res = yield call(api.presence.editUsersPunching, action.payload);
    yield call(checkApiResponse, res);
    yield put(editPunchingSuccess());
    const filter = yield select(getPunchingsFilter);
    const page = yield select(getCurrentPage);
    const params = {
      start: filter.start,
      end: filter.end,
      users: filter.users,
      page: action.payload.id ? page : 0,
      pageSize: PUNCHER_LIST_PAGE_SIZE,
    };
    yield put(fetchUsersPunchingsRequest(params));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editPunchingFailure(error));
  }
}

export function* fetchCassaIntegrazione(action) {
  try {
    const res = yield call(api.presence.getCassaIntegrazione, action.payload);

    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchCassaIntegrazioneSuccess({ data: [], total: 0, page: 0 }));
    } else
      yield put(
        fetchCassaIntegrazioneSuccess({
          data: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
  } catch (err) {
    yield put(fetchCassaIntegrazioneFailure(err));
  }
}

export function* saveCassaIntegrazione(action) {
  try {
    let res;
    if (action.id) {
      res = yield call(api.presence.updateCassaIntegrazione, action.payload, action.id);
    } else {
      res = yield call(api.presence.insertCassaIntegrazione, action.payload);
    }
    yield call(checkApiResponse, res);
    yield put(saveCassaIntegrazioneSuccess());
    const madeSearching = yield select(getFetchFirstCassaIntegrazione);
    if (madeSearching) {
      const searchFilter = yield select(getCassaIntegrazioneFilter);
      yield put(
        fetchCassaIntegrazioneRequest({
          ...searchFilter,
          page: 0,
          pageSize: CASSA_INTEGRAZIONE_LIST_PAGE_SIZE,
        })
      );
    }
  } catch (err) {
    yield put(saveCassaIntegrazioneFailure(err));
  }
}

export function* deleteCassaIntegrazione(action) {
  try {
    const res = yield call(api.presence.deleteCassaIntegrazione, action.id);
    yield call(checkApiResponse, res);
    const searchFilter = yield select(getCassaIntegrazioneFilter);
    yield put(
      fetchCassaIntegrazioneRequest({
        ...searchFilter,
        page: 0,
        pageSize: CASSA_INTEGRAZIONE_LIST_PAGE_SIZE,
      })
    );
  } catch (err) {
    yield put(deleteCassaIntegrazioneFailure(err));
  }
}

export function* saveExtraWorkingTime(action) {
  try {
    const res = yield call(api.presence.createUsersExtra, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      saveExtraWorkingTimeSuccess({
        ...action.payload,
        id: res.data.id,
      })
    );
  } catch (err) {
    yield put(saveExtraWorkingTimeFailure(err));
  }
}

export function* deleteExtraWorkingTime(action) {
  try {
    const res = yield call(api.presence.deleteUsersExtra, action.id);
    yield call(checkApiResponse, res);
    yield put(
      deleteExtraWorkingTimeSuccess(action.id)
    );
  } catch (err) {
    yield put(deleteExtraWorkingTimeFailure(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, fetchSettings);
  yield takeLatest(FETCH_PRESENCE_SETTINGS_REQUEST, fetchSettings);
  yield takeLatest(FETCH_PUNCHINGS_REQUEST, fetchPunchings);
  yield takeLatest(SAVE_PUNCHING_REQUEST, savePunching);
  yield takeLatest(FETCH_USERS_PUNCHINGS_REQUEST, fetchUsersPunchings);
  yield takeLatest(FETCH_PUNCHINGS_PLAN_REQUEST, fetchPunchingsPlan);
  yield takeLatest(
    FETCH_VACATIONS_TO_VALIDATE_REQUEST,
    fetchVacationsToValidate
  );
  yield takeLatest(FETCH_PUNCHING_DETAILS_REQUEST, fetchPunchingDetails);
  yield takeLatest(SAVE_OTHER_PUNCHING_REQUEST, saveOtherPunching);
  yield takeLatest(INSERT_VACATION_NOTIFICATION, notifyInsertVacation);
  yield takeLatest(DELETE_VACATION_NOTIFICATION, notifyDeleteVacation);
  yield takeLatest(
    ASK_DELETING_VACATION_NOTIFICATION,
    notifyAskDeletingVacation
  );
  yield takeLatest(CONFIRM_VACATION_NOTIFICATION, notifyConfirmVacation);
  yield takeLatest(REJECT_VACATION_NOTIFICATION, notifyRejectVacation);
  yield takeLatest(
    CONFIRM_DELETING_VACATION_NOTIFICATION,
    notifyConfirmDeletingVacation
  );
  yield takeLatest(
    REJECT_DELETING_VACATION_NOTIFICATION,
    notifyRejectDeletingVacation
  );
  yield takeLatest(INSERT_PUNCHING_NOTIFICATION, notifyInsertPunching);
  yield takeLatest(CONFIRM_PUNCHING_NOTIFICATION, notifyConfirmPunching);
  yield takeLatest(REJECT_PUNCHING_NOTIFICATION, notifyRejectPunching);
  yield takeLatest(FETCH_WORKING_USERS_REQUEST, fetchWorkingUsers);
  yield takeLatest(FETCH_PBX_REGISTRY_REQUEST, fetchPbxRegistry);
  yield takeLatest(SAVE_PBX_REGISTRY_REQUEST, savePbxRegistry);
  yield takeLatest(FETCH_EXPORT_TEMPLATES_REQUEST, fetchExportTemplates);
  yield takeLatest(FETCH_EXPORT_TEMPLATE_REQUEST, fetchExportTemplate);
  yield takeLatest(SAVE_EXPORT_TEMPLATE_REQUEST, saveExportTemplate);
  yield takeLatest(DELETE_EXPORT_TEMPLATE_REQUEST, deleteExportTemplate);
  yield takeLatest(
    FETCH_PUNCHINGS_TO_VALIDATE_REQUEST,
    fetchPunchingsToValidate
  );
  yield takeLatest(VALIDATE_PUNCHING_REQUEST, validatePunching);
  yield takeLatest(FETCH_ABSENCE_REQUEST, fetchAbsences);
  yield takeLatest(SAVE_ABSENCE_REQUEST, saveAbsence);
  yield takeLatest(DELETE_ABSENCE_REQUEST, deleteAbsence);
  yield takeLatest(FETCH_QRCODE_SETTINGS_REQUEST, fetchQRCodeSettings);
  yield takeLatest(SAVE_QRCODE_SETTINGS_REQUEST, saveQRCodeSettings);
  yield takeLatest(FETCH_GUEST_DATA_REQUEST, fetchGuestData);
  yield takeLatest(SAVE_GUEST_VISIT_REQUEST, saveGuestVisit);
  yield takeLatest(FETCH_GUEST_VISITS_REQUEST, fetchGuestVisits);
  yield takeLatest(FETCH_PUNCHING_JUSTIFICATIONS_REQUEST, fetchJustifications);
  yield takeLatest(
    DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST,
    deleteJustification
  );
  yield takeLatest(
    EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST,
    editJustification
  );
  yield takeLatest(DELETE_OTHER_PUNCHING_REQUEST, deletePunching);
  yield takeLatest(EDIT_OTHER_PUNCHING_REQUEST, editPunching);
  yield takeLatest(FETCH_CASSA_INTEGRAZIONE_REQUEST, fetchCassaIntegrazione);
  yield takeLatest(SAVE_CASSA_INTEGRAZIONE_REQUEST, saveCassaIntegrazione);
  yield takeLatest(DELETE_CASSA_INTEGRAZIONE_REQUEST, deleteCassaIntegrazione);
  yield takeLatest(SAVE_EXTRA_WORKINGTIME_REQUEST, saveExtraWorkingTime);
  yield takeLatest(DELETE_EXTRA_WORKINGTIME_REQUEST, deleteExtraWorkingTime);
}
