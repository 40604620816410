import { createSelector } from 'reselect';

const defaultList = [];
export const getMailofficesById = (state) => state.mailoffice.byId;
export const getMailofficeById = (state, id) => state.mailoffice.byId[id];
export const getMailofficesFilter = (state) => state.mailoffice.filter;
export const getMailofficesIds = (state) => state.mailoffice.allIds;
export const getMailofficeCurrentPage = (state) => state.mailoffice.currentPage;
export const getMailoffices = createSelector(
  [getMailofficesById, getMailofficesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getMailofficesIdsPaged = (state) => {
  const page = state.mailoffice.currentPage;
  const pageIds = state.mailoffice.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getMailofficesPaged = createSelector(
  [getMailofficesById, getMailofficesIdsPaged],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const isMailofficeConfirmRejectLoaded = (state) =>
  state.mailoffice.confirmRejectMailofficeLoaded;
export const getMailofficeConfirmRejectError = (state) =>
  state.mailoffice.confirmRejectMailofficeError;
export const isMailofficeChanged = (state) =>
  state.mailoffice.mailofficesChanged;
export const getFetchPagedMailofficesLoaded = (state) =>
  state.mailoffice.fetchPagedMailofficesLoaded;
export const getFetchPagedMailofficesError = (state) =>
  state.mailoffice.fetchPagedMailofficesError;
