export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const CHECK_COOKIES = 'CHECK_COOKIES';
export const SET_ME_GRANTS = 'SET_ME_GRANTS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const GUEST_LOGIN_REQUEST = 'GUEST_LOGIN_REQUEST';
export const GUEST_LOGIN_SUCCESS = 'GUEST_LOGIN_SUCCESS';
export const GUEST_LOGIN_FAILURE = 'GUEST_LOGIN_FAILURE';
export const GUEST_LOGOUT_REQUEST = 'GUEST_LOGOUT_REQUEST';
export const GUEST_LOGOUT_SUCCESS = 'GUEST_LOGOUT_SUCCESS';
export const GUEST_LOGOUT_FAILURE = 'GUEST_LOGOUT_FAILURE';
export const TECH_LOGIN_REQUEST = 'TECH_LOGIN_REQUEST';
export const TECH_LOGIN_SUCCESS = 'TECH_LOGIN_SUCCESS';
export const TECH_LOGIN_FAILURE = 'TECH_LOGIN_FAILURE';
export const SHOW_CHANGED_GRANTS_MODAL = 'SHOW_CHANGED_GRANTS_MODAL';
export const HIDE_CHANGED_GRANTS_MODAL = 'HIDE_CHANGED_GRANTS_MODAL';
