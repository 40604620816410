import React from 'react';
import JitsiVideocalls from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const JitsiVideocallListFilter = React.lazy(() =>
  import('./JitsiVideocallListFilter')
);
const JitsiVideocallListResult = React.lazy(() =>
  import('./JitsiVideocallListResult')
);

const JitsiVideocallListPage = () => (
  <JitsiVideocalls>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="videocall-list" />
          </div>
          <JitsiVideocallListFilter />
          <JitsiVideocallListResult />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </JitsiVideocalls>
);

export default JitsiVideocallListPage;
