import React from 'react';
import { DragLayer } from 'react-dnd';
import { Fade } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';

import Icon from '../../../components/icons/Icon';
import Utils from '../../../js/lib/utils';

const messages = defineMessages({
  assistedTransfer: {
    id: 'TransferCustomDrag.assistedTransfer',
    defaultMessage: 'Assisted transfer to...',
  },
  blindTransfer: {
    id: 'TransferCustomDrag.blindTransfer',
    defaultMessage: 'Direct transfer to...',
  },
});

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
    pointerEvents: 'none',
    position: 'absolute',
  };
}

const TransferCustomDrag = ({
  intl: { formatMessage },
  initialOffset,
  currentOffset,
  isDragging,
  itemType,
}) => {
  if (
    !isDragging ||
    (itemType !== Utils.DragAndDropTypes.attendedTransfer &&
      itemType !== Utils.DragAndDropTypes.blindTransfer &&
      itemType !== Utils.DragAndDropTypes.queueTransfer)
  ) {
    return null;
  }
  return (
    <Fade
      style={getItemStyles(initialOffset, currentOffset)}
      className="p-2 card"
    >
      {itemType === Utils.DragAndDropTypes.attendedTransfer ? (
        <div>
          <Icon
            name="phone-call-split"
            color="var(--yn-blue-dark)"
            className="mr-2"
          />
          {formatMessage(messages.assistedTransfer)}
        </div>
      ) : (
        <div>
          <Icon
            name="phone-call-forward"
            color="var(--yn-blue-dark)"
            className="mr-2"
          />
          {formatMessage(messages.blindTransfer)}
        </div>
      )}
    </Fade>
  );
};

function collect(monitor) {
  return {
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

export default DragLayer(collect)(injectIntl(TransferCustomDrag));
