import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getYoutubeInfo } from '../js/ext/selectors';
import ynConf from '../conf';

const YoutubeLink = ({ info, code, url, text, className }) => (
  <div>
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      href={url}
    >
      {info || text}
      <div className="border rounded bg-white p-1 text-center">
        <img
          alt="Youtube preview"
          src={`https://img.youtube.com/vi/${code}/0.jpg`}
          style={{ maxWidth: '146px', maxHeight: '109px' }}
        />
        <img
          alt="Youtube logo"
          src={`${ynConf.publicFolderPath}/images/yt_logo_rgb_light.png`}
          style={{ maxWidth: '50px' }}
        />
      </div>
    </a>
  </div>
);

YoutubeLink.propTypes = {
  code: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    info: getYoutubeInfo(state, ownProps.code),
  };
}

export default connect(mapStateToProps)(YoutubeLink);
