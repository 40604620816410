import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import Switch from 'react-switch';
import { injectIntl, defineMessages } from 'react-intl';
import {
  activateWebrtcPhoneRequest,
  deactivateWebrtcPhoneRequest,
} from '../../../js/phone/actions';
import { getToggleWebrtcError } from '../../../js/phone/selectors';
import { getMeCallsAndConferences } from '../../../js/me/selectors';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  physical: {
    id: 'PhoneModeSwitch.physical',
    defaultMessage: 'Switch to phone',
  },
  web: {
    id: 'PhoneModeSwitch.web',
    defaultMessage: 'Switch to web',
  },
});

class PhoneModeSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }

  handleChange = () => {
    const { webrtcActive, deactivateWebrtc, activateWebrtc } = this.props;
    if (webrtcActive) return deactivateWebrtc();
    activateWebrtc();
  };

  render() {
    const {
      webrtcActive,
      color,
      existsPendingCall,
      disabled,
      intl: { formatMessage },
    } = this.props;

    const { tooltipOpen } = this.state;

    return (
      <>
        {!disabled && (
          <Tooltip
            isOpen={tooltipOpen}
            toggle={() => {
              this.setState({ tooltipOpen: !tooltipOpen });
            }}
            target="phone_mode_switch"
          >
            {webrtcActive
              ? formatMessage(messages.physical)
              : formatMessage(messages.web)}
          </Tooltip>
        )}
        <div id="phone_mode_switch">
          <Switch
            onChange={this.handleChange}
            disabled={existsPendingCall || disabled}
            checked={webrtcActive}
            offColor={color}
            onColor={color}
            className="m-1"
            checkedIcon={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Icon
                name="headphone-filled"
                color="white"
                width={20}
                height={20}
                style={{ left: '4px', top: '4px' }}
              />
            }
            uncheckedIcon={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Icon
                name="phone-call-2-filled"
                color="white"
                width={20}
                height={20}
                style={{ left: '6px', top: '4px' }}
              />
            }
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    toggleWebrtcError: getToggleWebrtcError(state),
    existsPendingCall: getMeCallsAndConferences(state).length > 0,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    activateWebrtc: activateWebrtcPhoneRequest,
    deactivateWebrtc: deactivateWebrtcPhoneRequest,
  })(PhoneModeSwitch)
);
