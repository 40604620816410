import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import HelpButton from '../../../components/HelpButton';
import SMSListFilters from './SMSListFilters';
import { SMS_LIST_PAGE_SIZE } from '../../../js/sms/SMSUtils';
import { fetchSMSListRequest } from '../../../js/sms/actions';
import {
  getSMSCurrentPage,
  getSMSTotal,
  getSMSFilter,
  getFetchSMSListLoaded,
  getFetchSMSListError,
  getSMSIds,
  getSMSCredits,
  showIncomingSMSAlert,
  getFetchSMSCreditsError,
  isSMSCreditsFetched,
  usingSMSAlias,
} from '../../../js/sms/selectors';
import Pagination from '../../../components/Pagination';
import SMSListRow from './SMSListRow';
import Button from '../../../components/formControls/Button';
import Alert from '../../../components/messages/Alert';
import LinkButton from '../../../components/LinkButton';
import SMSCreditsAlert from './SMSCreditsAlert';
import { isSMSenabled } from '../../../js/me/selectors';

const messages = defineMessages({
  sendSMSButton: {
    id: 'SMSList.sendSMSButton',
    defaultMessage: 'Send an SMS',
  },
  incomingSMSAlertText: {
    id: 'SMSList.incomingSMSAlertText',
    defaultMessage:
      'In the next week some SMS have to be sent! Check if you have enough credits',
  },
  errorFetchingCredits: {
    id: 'SMSList.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  creationDate: {
    id: 'SMSList.label.creationDate',
    defaultMessage: 'Creation date',
  },
  deliveringDate: {
    id: 'SMSList.label.deliveringDate',
    defaultMessage: 'Delivering date',
  },
  user: {
    id: 'SMSList.label.user',
    defaultMessage: 'User',
  },
  recipient: {
    id: 'SMSList.label.recipient',
    defaultMessage: 'Recipient',
  },
  status: {
    id: 'SMSList.label.status',
    defaultMessage: 'Status',
  },
  sender: {
    id: 'SMSList.label.sender',
    defaultMessage: 'Sender',
  },
  noData: {
    id: 'SMSList.noData',
    defaultMessage: 'There is nothing to show',
  },
  showFilters: {
    id: 'SMSList.showFilters',
    defaultMessage: 'Show filters',
  },
  hideFilters: {
    id: 'SMSList.hideFilters',
    defaultMessage: 'Hide filters',
  },
  removeFilters: {
    id: 'SMSList.removeFilters',
    defaultMessage: 'Remove filters',
  },
  modifyFilters: {
    id: 'SMSList.modifyFilters',
    defaultMessage: 'Modify filters',
  },
});

class SMSList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
    };
  }

  componentDidMount() {
    /*const queryParams = queryString.parse(location.search);
    if (queryParams && queryParams.campaign) {
      this.manageQueryParams();
    } else {
      this.fetchItems(0);
    }*/
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) this.fetchItems();
  }

  handlePage = (page) => {
    this.fetchItems(page);
  };

  fetchItems = (page) => {
    const { filter, fetch } = this.props;
    const params = {
      ...filter,
      pageSize: SMS_LIST_PAGE_SIZE,
      page,
    };
    fetch(params);
  };

  toggleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  render() {
    const {
      intl: { formatMessage },
      fetching,
      fetchingError,
      pages,
      page,
      smsIds,
      credits,
      showAlert,
      creditsFetched,
      fetchingCreditsError,
      useAlias,
      filter,
      smsEnabled,
    } = this.props;

    const { showFilters } = this.state;

    const noFilter =
      !filter.sender &&
      !filter.status &&
      !filter.start &&
      !filter.end &&
      !filter.user &&
      !filter.showCampaigns &&
      (!filter.recipients || !filter.recipients.length);

    return !smsEnabled ? (
      <></>
    ) : fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        <div className="text-right">
          <HelpButton fileName="sms-list" />
        </div>
        {creditsFetched && <SMSCreditsAlert credits={credits} />}
        {creditsFetched && showAlert && (
          <Alert
            text={formatMessage(messages.incomingSMSAlertText)}
            type="danger"
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        <div className="row mb-2">
          <div className="col pt-3">
            <LinkButton onClick={this.toggleShowFilters}>
              {formatMessage(
                !showFilters
                  ? noFilter
                    ? messages.showFilters
                    : messages.modifyFilters
                  : messages.hideFilters
              )}
            </LinkButton>
          </div>
          <div className="text-right col">
            <Link to="/sms/sent/edit">
              <Button
                className="btn btn-primary"
                text={formatMessage(messages.sendSMSButton)}
              />
            </Link>
          </div>
        </div>
        <SMSListFilters
          isOpen={showFilters}
          onApply={this.toggleShowFilters}
          {...this.props}
        />
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th>{formatMessage(messages.deliveringDate)}</th>
                  <th>{formatMessage(messages.recipient)}</th>
                  {useAlias && <th>{formatMessage(messages.sender)}</th>}
                  <th>{formatMessage(messages.user)}</th>
                  <th>{formatMessage(messages.status)}</th>
                  <th />
                </tr>
              </thead>
              {smsIds && smsIds.length > 0 ? (
                <tbody>
                  {smsIds.map((id) => (
                    <SMSListRow key={id} id={id} />
                  ))}
                </tbody>
              ) : (
                <tfoot>
                  <tr>
                    <td colSpan={useAlias ? 5 : 4}>
                      {formatMessage(messages.noData)}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
        {pages > 1 && (
          <Pagination
            pages={pages}
            currentPage={page}
            handlePage={this.handlePage}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: !getFetchSMSListLoaded(state),
    fetchingError: getFetchSMSListError(state),
    pages: Math.ceil(getSMSTotal(state) / SMS_LIST_PAGE_SIZE),
    page: getSMSCurrentPage(state),
    filter: getSMSFilter(state),
    smsIds: getSMSIds(state),
    credits: getSMSCredits(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    creditsFetched: isSMSCreditsFetched(state),
    showAlert: showIncomingSMSAlert(state),
    useAlias: usingSMSAlias(state),
    smsEnabled: isSMSenabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchSMSListRequest,
  })(SMSList)
);
