import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import HelpButton from '../../../components/HelpButton';
import { SMS_LIST_PAGE_SIZE } from '../../../js/sms/SMSUtils';
import { fetchSMSAliasRequest } from '../../../js/sms/actions';
import {
  getSMSCredits,
  getFetchSMSCreditsError,
  isSMSCreditsFetched,
  getFetchSMSAliasLoaded,
  getFetchSMSAliasError,
  getSMSAliasIds,
} from '../../../js/sms/selectors';
import Button from '../../../components/formControls/Button';
import Alert from '../../../components/messages/Alert';
import SMSAliasRow from './SMSAliasRow';
import { isSMSenabled } from '../../../js/me/selectors';

const messages = defineMessages({
  newAliasButton: {
    id: 'SMSAlias.newAliasButton',
    defaultMessage: 'New alias',
  },
  creditsWarningText: {
    id: 'SMSAlias.creditsWarningText',
    defaultMessage: 'Hei! You have only {credits} more available SMS!',
  },
  creditsAlertText: {
    id: 'SMSAlias.creditsAlertText',
    defaultMessage: 'You have no credit, so you cannot create any alias',
  },
  incomingSMSAlertText: {
    id: 'SMSAlias.incomingSMSAlertText',
    defaultMessage:
      'In the next week some SMS have to be sent! Check if you have enough credits',
  },
  errorFetchingCredits: {
    id: 'SMSAlias.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  creationDate: {
    id: 'SMSAlias.label.creationDate',
    defaultMessage: 'Created on',
  },
  user: {
    id: 'SMSAlias.label.user',
    defaultMessage: 'By',
  },
  alias: {
    id: 'SMSAlias.label.alias',
    defaultMessage: 'Alias',
  },
  status: {
    id: 'SMSAlias.label.status',
    defaultMessage: 'Status',
  },
  noData: {
    id: 'SMSAlias.noData',
    defaultMessage: 'There is nothing to show',
  },
});

class SMSAlias extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) this.fetchItems();
  }

  fetchItems = () => {
    const { filter, fetch, page } = this.props;
    const params = {
      ...filter,
      pageSize: SMS_LIST_PAGE_SIZE,
      page,
    };
    fetch(params);
  };

  render() {
    const {
      intl: { formatMessage },
      fetching,
      fetchingError,
      aliasIds,
      credits,
      creditsFetched,
      fetchingCreditsError,
      smsEnabled,
    } = this.props;

    return !smsEnabled ? (
      <></>
    ) : fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        <div className="text-right mb-1">
          <HelpButton fileName="sms-alias" />
        </div>
        {creditsFetched && credits === 0 && (
          <Alert
            text={formatMessage(messages.creditsAlertText)}
            type="danger"
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        <div className="text-right mb-2">
          <Link to="/sms/alias/edit">
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.newAliasButton)}
              disabled={creditsFetched && credits === 0}
            />
          </Link>
        </div>
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th>{formatMessage(messages.creationDate)}</th>
                  <th>{formatMessage(messages.user)}</th>
                  <th>{formatMessage(messages.alias)}</th>
                  <th>{formatMessage(messages.status)}</th>
                  <th />
                </tr>
              </thead>
              {aliasIds && aliasIds.length > 0 ? (
                <tbody>
                  {aliasIds.map((id) => (
                    <SMSAliasRow key={id} id={id} />
                  ))}
                </tbody>
              ) : (
                <tfoot>
                  <tr>
                    <td colSpan="6">{formatMessage(messages.noData)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: !getFetchSMSAliasLoaded(state),
    fetchingError: getFetchSMSAliasError(state),
    aliasIds: getSMSAliasIds(state),
    credits: getSMSCredits(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    creditsFetched: isSMSCreditsFetched(state),
    smsEnabled: isSMSenabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchSMSAliasRequest,
  })(SMSAlias)
);
