import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PhoneLabel from './PhoneLabel';
import Popover from './Popover';

const PhoneButtonNumbersList = ({
  numbers,
  placement,
  isOpen,
  toggle,
  target,
}) => (
  <Popover
    placement={placement}
    isOpen={isOpen}
    toggle={toggle}
    target={target}
    popoverMinWidth="10rem"
    popoverMinHeight="2rem"
    bodyPadding="1rem"
  >
    {numbers.map((number) => (
      <PhoneLabel key={number.number} number={number} showType />
    ))}
  </Popover>
);

PhoneButtonNumbersList.propTypes = {
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  target: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  onNumberClick: PropTypes.func.isRequired,
};

export default injectIntl(PhoneButtonNumbersList);
