import { LOCATION_CHANGE } from 'connected-react-router';
import keyBy from 'lodash.keyby';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';
import { now } from 'moment';
import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_ME_GRANTS,
  LOGIN_REQUEST,
  AUTH_SUCCESS,
  GUEST_LOGIN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SHOW_CHANGED_GRANTS_MODAL,
  HIDE_CHANGED_GRANTS_MODAL,
} from '../auth/types';
import {
  INIT_MAIN_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  FETCH_HELP_REQUEST,
  FETCH_HELP_SUCCESS,
  SET_STATUS_SUCCESS,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  FETCH_CUSTOMER_SETTINGS_REQUEST,
  FETCH_CUSTOMER_SETTINGS_SUCCESS,
  FETCH_EXTENSIONS_ORDER_SUCCESS,
  ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  UPDATE_EXTENSIONS_ORDER_REQUEST,
  UPDATE_EXTENSIONS_ORDER_SUCCESS,
  UPDATE_EXTENSIONS_ORDER_FAILURE,
  FETCH_PBXS_REQUEST,
  FETCH_PBXS_SUCCESS,
  FETCH_PBXS_FAILURE,
  TOGGLE_SHOW_HELP,
  FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS,
  FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST,
  FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE,
  SET_USER_STATUS_REQUEST,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE,
} from './types';
import {
  INITIAL_ABILIS_PHONE_STATUS_SUCCESS,
  INITIAL_NETHESIS_PHONE_STATUS_SUCCESS,
  FETCH_PHONE_SETTINGS_SUCCESS,
  FETCH_CONFERENCE_SUCCESS,
  FETCH_MEMBER_DATA_SUCCESS,
  FETCH_MEMBER_DATA_REQUEST,
  END_CONFERENCE_SUCCESS,
  AID_TRANSFER_SUCCESS,
  HANGUP_CHANNEL_SUCCESS,
  HANGUP_CALL_SUCCESS,
  DECLINE_CALL_SUCCESS,
  STOP_USE_WEBPHONE,
  STOP_RECORD_CALL_SUCCESS,
  HANGUP_WEBRTC_CALL,
  RECORD_CALL_SUCCESS,
} from '../phone/types';
import { PhoneEnums } from '../phone/PhoneUtils';
import { TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS } from '../hotel/types';
import { SAVE_LOGO_SUCCESS } from '../logo/types';
import { MeEnums } from './MeUtils';
import { SAVE_OWN_GREETING_SUCCESS } from '../vbox/types';
import {
  IMPORT_ABOOK_CONTACT_SUCCESS,
  IMPORT_ABOOK_CONTACT_REQUEST,
  CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS,
  IMPORT_ABOOK_BATCH_COMPLETED,
  CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS,
  IMPORT_ABOOK_CONTACT_FAILURE,
} from '../contacts/types';
import { USER_PRESENCE_CHANGE } from '../users/types';
import { PresenceEnums } from '../users/PresenceUtils';
import { AVATAR_TYPES } from '../files/FileUtils';
import { PUT_AVATAR_SUCCESS } from '../files/types';
import { SET_DND_STATUS_SUCCESS } from '../videocalls/types';

const initialErrorState = {
  error: '',
  success: '',
  loaded: true,
};

const initialState = {
  loaded: true,
  inited: false,
  otherSessions: 0,

  error: '',
  calls: {},
  conference: {},
  activeAssistedTransfer: false,
  hasRecorded: false,
  customer: {},
  fetchRegistryFieldsLoaded: true,
  fetchRegistryFieldsError: null,
  updateEmail: {
    error: '',
    success: '',
    loaded: true,
  },
  updateProfile: {
    error: '',
    success: '',
    loaded: true,
  },
  updateCustomerPreferences: {
    error: false,
    loaded: true,
    success: false,
  },
  updatePassword: {
    loaded: true,
    passwordError: null,
    passwordSuccess: null,
  },
  extensions: {
    current: [],
    old: null,
  },
  customerPbxs: {
    error: '',
    success: '',
    loaded: true,
    pbxs: null,
    byId: {},
    allIds: [],
    registryFields: [],
  },
  help: '',
  videocallDnd: false,
  changedGrantsModal: false,
  showHelp: false,
  puncherEnabled: false,
  status: {},
  setStatus: {
    error: '',
    success: false,
    loaded: true,
  },
};

const setStatus = (state, action) => {
  const newStatus =
    state.status[action.statusType] === 'DND' ? 'ONLINE' : 'DND';
  const status = {
    ...state.status,
    [action.statusType]: newStatus,
  };
  return {
    ...state,
    status,
  };
};

export function me(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return initialState;
    case LOGIN_SUCCESS:
    case AUTH_SUCCESS:
      return {
        ...state,
        ...action.me,
      };
    case SET_ME_GRANTS:
      return {
        ...state,
        grants: action.grants,
      };
    case SHOW_CHANGED_GRANTS_MODAL:
      return {
        ...state,
        changedGrantsModal: true,
      };
    case HIDE_CHANGED_GRANTS_MODAL:
      return {
        ...state,
        changedGrantsModal: false,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        fullname: action.guest.name,
        email: action.guest.email,
        id: action.guest.uid,
        type: MeEnums.MeTypes.GUEST,
        idCustomer: action.guest.idCustomer,
        videocallRoomId: action.guest.roomId,
      };
    case FETCH_ME_REQUEST:
      return {
        ...state,
        loaded: false,
        error: '',
      };
    case FETCH_ME_SUCCESS: {
      return {
        ...state,
        ...action.me,
        type: MeEnums.MeTypes.USER,
        loaded: true,
        error: '',
      };
    }
    case FETCH_CUSTOMER_SETTINGS_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_CUSTOMER_SETTINGS_SUCCESS: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.customerSettings,
        },
      };
    }
    case FETCH_EXTENSIONS_ORDER_SUCCESS: {
      return {
        ...state,
        extensions: {
          current: action.extensions,
          old: null,
        },
      };
    }
    case UPDATE_EXTENSIONS_ORDER_REQUEST: {
      return {
        ...state,
        extensions: {
          current: action.extensions,
          old: state.extensions.current,
        },
      };
    }
    case UPDATE_EXTENSIONS_ORDER_SUCCESS: {
      return {
        ...state,
        extensions: {
          ...state.extensions,
          old: null,
        },
      };
    }
    case UPDATE_EXTENSIONS_ORDER_FAILURE: {
      return {
        ...state,
        extensions: {
          current: state.extensions.old,
          old: null,
        },
      };
    }
    case FETCH_ME_FAILURE:
      return {
        ...state,
        loaded: true,
        inited: true,
        error: action.errors,
      };
    case SET_STATUS_SUCCESS:
      return setStatus(state, action);
    case INIT_MAIN_SUCCESS:
      return {
        ...state,
        inited: true,
      };
    case FETCH_PHONE_SETTINGS_SUCCESS: {
      const webrtcExtension =
        action.phoneSettings.extensions &&
        action.phoneSettings.extensions.others
          ? action.phoneSettings.extensions.others.filter(
              (extension) => extension.type === 'WEBRTC'
            )
          : null;
      const mobileExtension =
        action.phoneSettings.extensions &&
        action.phoneSettings.extensions.others
          ? action.phoneSettings.extensions.others.filter(
              (extension) => extension.type === 'MOBILE'
            )
          : null;
      return {
        ...state,
        webrtcExtensionUsername:
          webrtcExtension && webrtcExtension.length
            ? webrtcExtension[0].username
            : null,
        webrtcExtensionNumber:
          webrtcExtension && webrtcExtension.length
            ? webrtcExtension[0].number
            : null,
        mobileExtensionNumber:
          mobileExtension && mobileExtension.length
            ? mobileExtension[0].number
            : null,
      };
    }
    case UPDATE_EMAIL_REQUEST:
      return {
        ...state,
        updateEmail: {
          error: '',
          success: '',
          loaded: false,
        },
      };

    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        email: action.payload.newEmail,
        updateEmail: {
          error: '',
          success: action.success,
          loaded: true,
        },
      };

    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        updateEmail: {
          error: action.errors,
          success: '',
          loaded: true,
        },
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updatePassword: {
          loaded: false,
          passwordError: null,
          passwordSuccess: null,
        },
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        lastUpdatePwd: now(),
        updatePassword: {
          loaded: true,
          passwordError: null,
          passwordSuccess: action.success,
        },
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePassword: {
          loaded: true,
          passwordError: action.errors,
          passwordSuccess: null,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        lastUpdatePwd: null,
      };
    case INITIAL_ABILIS_PHONE_STATUS_SUCCESS: {
      let calls = { ...state.calls };
      action.status.calls.forEach((call) => {
        if (call.username === state.username && call.callid) {
          if (call.status !== PhoneEnums.CallsStatuses.none) {
            calls = {
              ...calls,
              [state.mainExtensionNumber]: {
                [call.callid]: {
                  active:
                    call.status === PhoneEnums.CallsStatuses.active ||
                    call.status === PhoneEnums.CallsStatuses.hold,
                },
              },
            };
          }
          if (call.status === PhoneEnums.CallsStatuses.none) {
            delete calls[state.mainExtensionNumber][call.callid];
          }
        }
      });
      return {
        ...state,
        calls,
      };
    }

    case ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS: {
      let calls = { ...state.calls };
      action.status.calls.forEach((call) => {
        const callId = call.callid || '0';
        if (call.status !== PhoneEnums.CallsStatuses.none) {
          calls = {
            ...calls,
            [state.mainExtensionNumber]: {
              ...calls[state.mainExtensionNumber],
              [callId]: {
                active:
                  call.status === PhoneEnums.CallsStatuses.active ||
                  // the row below has been added from PF on 13/08/2019
                  // to hangup hold calls even when other call is alerting
                  call.status === PhoneEnums.CallsStatuses.alerting ||
                  call.status === PhoneEnums.CallsStatuses.hold,
                callback: !call.callid,
              },
            },
          };
          if (call.callid) delete calls[state.mainExtensionNumber][0];
        }
        if (
          call.status === PhoneEnums.CallsStatuses.none &&
          call.callid &&
          calls[state.mainExtensionNumber]
        ) {
          delete calls[state.mainExtensionNumber][callId];
        }
        if (
          call.status === PhoneEnums.CallsStatuses.none &&
          !call.callid &&
          calls[state.mainExtensionNumber]
        ) {
          delete calls[state.mainExtensionNumber][0];
        }
      });
      return {
        ...state,
        calls,
      };
    }
    case INITIAL_NETHESIS_PHONE_STATUS_SUCCESS: {
      let calls = { ...state.calls };
      let conference = { ...state.conference };
      action.status.calls.forEach((call) => {
        if (call.username === state.mainExtensionUsername) {
          if (!call.conference) {
            if (call.status !== PhoneEnums.CallsStatuses.none) {
              calls = {
                ...calls,
                [call.number]: {
                  [call.callid]: {
                    active:
                      call.status === PhoneEnums.CallsStatuses.active ||
                      call.status === PhoneEnums.CallsStatuses.hold,
                  },
                },
              };
            }
            if (call.status === PhoneEnums.CallsStatuses.none) {
              delete calls[call.callid][call.number];
            }
          } else {
            conference = {
              ...conference,
              callId: call.callid,
            };
          }
        } else if (
          call.conference &&
          ([call.calling, call.called].indexOf(state.mainExtensionNumber) >=
            0 ||
            [call.calling, call.called].indexOf(state.webrtcExtensionNumber) >=
              0)
        ) {
          // check if I am the owner of a conference where I am not in
          conference = {
            ...conference,
            callId: call.callid,
          };
        }
      });
      return {
        ...state,
        calls,
        conference,
      };
    }
    case NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS: {
      let calls = { ...state.calls };
      let conference = { ...state.conference };
      if (!action.status.calls || action.status.calls.length === 0) {
        delete calls[action.status.user.number];
        // conference = {}
      }
      action.status.calls.forEach((call) => {
        if (call.status !== PhoneEnums.CallsStatuses.none) {
          const callId = call.callid;
          if (!call.conference) {
            calls = {
              ...calls,
              [call.number]: {
                [callId]: {
                  active: true,
                },
              },
            };
          } else if (
            [call.calling, call.called].indexOf(state.mainExtensionNumber) >=
              0 ||
            [call.calling, call.called].indexOf(state.webrtcExtensionNumber) >=
              0
            /* && (Object.keys(conference).length === 0 || [PhoneEnums.CallsStatuses.calling, PhoneEnums.CallsStatuses.alerting].indexOf(call.status) < 0) */
          ) {
            // put conference object only if I am in the conference (called or calling)
            // and there isn't already a callid and this call is calling/alerting
            // (otherwise put a wrong callid to this conference)
            if (calls[call.number]) {
              delete calls[call.number];
            }
            conference = {
              ...conference,
              callId: call.callid,
            };
          }
        } else {
          if (calls[call.number]) {
            delete calls[call.number];
          }
          if (call.callid === conference.callId) {
            // if the NULL call is a conference, clear it
            conference = {};
          }
        }
      });
      /* if (action.status.conferences.length > 0) {
        action.status.conferences.forEach(conf => {
          conference = {
            ...conference,
            callId: conf.callid
          };
        });
      } */
      return {
        ...state,
        activeAssistedTransfer:
          calls && Object.keys(calls).length
            ? state.activeAssistedTransfer
            : false,
        calls,
        conference,
      };
    }
    case FETCH_CONFERENCE_SUCCESS: {
      let users = {};
      let conference = {};
      // add all the users with username as key
      if (
        action.conference.users &&
        action.conference.id &&
        (action.conference.id === state.mainExtensionNumber ||
          action.conference.id === state.webrtcExtensionNumber)
      ) {
        let ownerIn = false;
        const ownerNumber =
          action.conference.id === state.mainExtensionNumber
            ? state.mainExtensionNumber
            : state.webrtcExtensionNumber;
        Object.keys(action.conference.users).forEach((number) => {
          if (action.conference.users[number].owner) {
            ownerIn = true;
          }
          const username =
            action.conference.users[number].name !== ''
              ? action.conference.users[number].name
              : action.conference.users[number].extenId;
          let user =
            (state.conference.users && state.conference.users[username]) || {};
          user = {
            ...user,
            number: action.conference.users[number].extenId,
            username,
            muted: action.conference.users[number].muted,
            owner: action.conference.users[number].owner,
            fetched: false,
            in: true,
          };
          users = {
            ...users,
            [username]: user,
          };
        });
        if (!ownerIn && Object.keys(action.conference.users).length > 0) {
          // add me as the owner
          let user =
            (state.conference.users &&
              state.conference.users[state.mainExtensionUsername]) ||
            {};
          user = {
            ...user,
            number: ownerNumber,
            username: state.mainExtensionUsername,
            muted: false,
            owner: true,
            fetched: false,
            in: false,
          };
          users = {
            ...users,
            [state.mainExtensionUsername]: user,
          };
        }
        conference = {
          ...state.conference,
          id: action.conference.id,
          users,
        };
      } else {
        // if I was the owner -> no more users and no more conference
        if (
          state.conference.users &&
          state.conference.users[state.mainExtensionUsername] &&
          state.conference.users[state.mainExtensionUsername].owner
        ) {
          conference = {};
        } else {
          conference = state.conference;
        }
      }

      return {
        ...state,
        conference,
      };
    }
    case AID_TRANSFER_SUCCESS: {
      return {
        ...state,
        activeAssistedTransfer: true,
      };
    }
    case RECORD_CALL_SUCCESS:
    case STOP_RECORD_CALL_SUCCESS:
      return {
        ...state,
        hasRecorded: true,
      };
    case HANGUP_WEBRTC_CALL:
    case HANGUP_CHANNEL_SUCCESS:
    case HANGUP_CALL_SUCCESS:
    case DECLINE_CALL_SUCCESS:
      return {
        ...state,
        hasRecorded: false,
        activeAssistedTransfer: false,
      };
    case FETCH_MEMBER_DATA_REQUEST: {
      const member = Object.keys(state.conference.users)
        .filter(
          (username) =>
            state.conference.users[username].number === action.number
        )
        .map((username) => state.conference.users[username])[0];
      return {
        ...state,
        conference: {
          ...state.conference,
          users: {
            ...state.conference.users,
            [member.username]: member,
          },
        },
      };
    }
    case FETCH_MEMBER_DATA_SUCCESS: {
      return {
        ...state,
        conference: {
          ...state.conference,
          users: {
            ...state.conference.users,
            [action.member.username]: {
              ...state.conference.users[action.member.username],
              ...action.member,
              fetched: true,
            },
          },
        },
      };
    }
    case END_CONFERENCE_SUCCESS: {
      return {
        ...state,
        conference: {},
      };
    }
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfile: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case UPDATE_PROFILE_SUCCESS: {
      const { name, surname, department, notes, avatar, groups, numbers } =
        action.success;
      return {
        ...state,
        name,
        surname,
        department,
        notes,
        // TODO UPDATE FULLNAME
        avatar,
        groups,
        numbers,
        updateProfile: {
          error: '',
          success: action.status,
          loaded: true,
        },
      };
    }
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfile: {
          error: action.errors,
          success: '',
          loaded: true,
        },
      };
    case FETCH_HELP_REQUEST:
      return {
        ...state,
        help: '',
      };
    case FETCH_HELP_SUCCESS:
      return {
        ...state,
        help: action.html,
      };
    case TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          wakeupEnabled: action.enabled,
        },
      };
    case SAVE_LOGO_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        updateEmail: initialErrorState,
        updateProfile: initialErrorState,
        updatePassword: {
          ...state.updatePassword,
          passwordError: null,
          passwordSuccess: null,
        },
        updateCustomerPreferences: {
          error: false,
          loaded: true,
          success: false,
        },
      };
    case FETCH_PBXS_REQUEST: {
      return {
        ...state,
        customerPbxs: {
          ...state.customerPbxs,
          error: '',
          success: '',
          loaded: false,
        },
      };
    }
    case FETCH_PBXS_SUCCESS:
      return {
        ...state,
        customerPbxs: {
          error: '',
          success: '',
          loaded: true,
          pbxs: action.pbxs,
          byId: keyBy(action.pbxs, 'id'),
          allIds: action.pbxs.map((obj) => obj.id),
        },
      };
    case FETCH_PBXS_FAILURE:
      return {
        ...state,
        customerPbxs: {
          ...state.customerPbxs,
          error: '',
          success: '',
          loaded: true,
        },
      };
    case SET_DND_STATUS_SUCCESS: {
      return {
        ...state,
        videocallDnd: action.enabled,
      };
    }
    case SAVE_OWN_GREETING_SUCCESS:
      if (
        action.greeting.supplier === PhoneEnums.PbxType.NETHESIS.toLowerCase()
      )
        return state;
      return {
        ...state,
        greeting: action.greeting.fileName,
      };
    case TOGGLE_SHOW_HELP:
      return {
        ...state,
        showHelp: !state.showHelp,
      };
    case IMPORT_ABOOK_CONTACT_REQUEST:
      return {
        ...state,
        pendingContactImportStatus: null,
      };
    case IMPORT_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        pendingContactImportStatus: 'PENDING',
      };
    case IMPORT_ABOOK_CONTACT_FAILURE:
      return {
        ...state,
        pendingContactImportStatus: 'ERROR',
      };
    case CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        pendingContactImportStatus: null,
      };
    case CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS:
      return {
        ...state,
        pendingContactImportStatus: null,
      };
    case IMPORT_ABOOK_BATCH_COMPLETED:
      return {
        ...state,
        pendingContactImportStatus: action.payload.status,
      };
    case FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST:
      return {
        ...state,
        fetchRegistryFieldsLoaded: false,
        fetchRegistryFieldsError: null,
        customerPbxs: {
          ...state.customerPbxs,
          registryFields: [],
        },
      };
    case FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS: {
      const fields = [];
      action.data
        .filter((pbx) => pbx.template)
        .forEach((pbx) => {
          Object.keys(pbx.template).forEach((field) =>
            fields.push({ field, ...pbx.template[field] })
          );
        });
      return {
        ...state,
        fetchRegistryFieldsLoaded: true,
        fetchRegistryFieldsError: null,
        customerPbxs: {
          ...state.customerPbxs,
          registryFields: sortBy(uniqBy(fields, 'field'), [
            (field) => field.description.toLowerCase(),
          ]),
        },
      };
    }
    case FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE:
      return {
        ...state,
        fetchRegistryFieldsLoaded: true,
        fetchRegistryFieldsError: action.errors,
        customerPbxs: {
          ...state.customerPbxs,
          registryFields: [],
        },
      };
    case SET_USER_STATUS_REQUEST:
      return {
        ...state,
        setStatus: {
          error: '',
          success: false,
          loaded: false,
        },
      };
    case SET_USER_STATUS_SUCCESS: {
      return {
        ...state,
        status: action.data.status,
        setStatus: {
          error: '',
          success: true,
          loaded: true,
        },
      };
    }
    case SET_USER_STATUS_FAILURE:
      return {
        ...state,
        setStatus: {
          error: action.errors,
          success: false,
          loaded: true,
        },
      };
    case USER_PRESENCE_CHANGE:
      return {
        ...state,
        otherSessions:
          action.payload.idUser === state.id &&
          action.payload.status === PresenceEnums.Status.ONLINE &&
          action.payload.source === 'WEB' &&
          action.payload.init
            ? state.otherSessions + 1
            : state.otherSessions,
      };
    case STOP_USE_WEBPHONE:
      return {
        ...state,
        otherSessions:
          state.otherSessions === 0
            ? state.otherSessions + 1
            : state.otherSessions,
      };

    case PUT_AVATAR_SUCCESS:
      if (action.avatar.type === AVATAR_TYPES.ME) {
        return {
          ...state,
          avatar: action.avatar.stored,
        };
      }
      return state;

    case UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST:
      return {
        ...state,
        updateCustomerPreferences: {
          error: false,
          loaded: false,
          success: false,
        },
      };

    case UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          publicChatAlert: action.preferences.publicChatAlert,
          avoidPasswordExpiration: action.preferences.avoidPasswordExpiration,
        },
        updateCustomerPreferences: {
          error: false,
          loaded: true,
          success: true,
        },
      };

    case UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE:
      return {
        ...state,
        updateCustomerPreferences: {
          error: true,
          loaded: true,
          success: false,
        },
      };

    default:
      return state;
  }
}
