import { request } from '../request';

export default {
  getMailoffices: (params) =>
    request({
      url: 'mailoffice/postal-services',
      method: 'GET',
      params,
    }),
  getMailoffice: (id) =>
    request({
      url: `mailoffice/postal-services/${id}`,
      method: 'GET',
    }),
  deleteMailoffice: (id) =>
    request({
      url: `/mailoffice/postal-services/${id}`,
      method: 'DELETE',
    }),
  createMailoffice: (mailofficeData) =>
    request({
      url: '/mailoffice/postal-services',
      method: 'POST',
      data: mailofficeData,
    }),
  getMailofficeCredits: () =>
    request({
      url: '/mailoffice/credits',
      method: 'GET',
    }),
  confirmMailoffice: (id) =>
    request({
      url: '/mailoffice/postal-services-confirmation',
      method: 'POST',
      data: { id },
    }),
  rejectMailoffice: (id) =>
    request({
      url: '/mailoffice/postal-services-rejection',
      method: 'POST',
      data: { id },
    }),
};
