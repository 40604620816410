import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  getSMSsAliasById,
  getSMSScheduledById,
  usingSMSAlias,
} from '../../../js/sms/selectors';
import { getDatetimeFormat } from '../../../js/settings/selectors';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import { showConfirmModal } from '../../../js/confirm/actions';
import { deleteSMSScheduledRequest } from '../../../js/sms/actions';
import SMSDetailsModal from './SMSDetailsModal';
import SMSRecipient from './SMSRecipient';

const messages = defineMessages({
  PENDING: {
    id: 'SMSScheduledRow.PENDING',
    defaultMessage: 'Sending',
  },
  SENT: {
    id: 'SMSScheduledRow.SENT',
    defaultMessage: 'Sent',
  },
  ERROR: {
    id: 'SMSScheduledRow.ERROR',
    defaultMessage: 'Error',
  },
  DELIVERED: {
    id: 'SMSScheduledRow.DELIVERED',
    defaultMessage: 'Delivered',
  },
  deleteConfirmationMessage: {
    id: 'SMSScheduledRow.deleteConfirmationMessage',
    defaultMessage: 'Are you sure you want to delete this SMS?',
  },
  detailsTooltip: {
    id: 'SMSScheduledRow.tooltip.detailsTooltip',
    defaultMessage: 'Details',
  },
  contactsTooltip: {
    id: 'SMSScheduledRow.tooltip.contactsTooltip',
    defaultMessage: 'Show contacts',
  },
  editTooltip: {
    id: 'SMSScheduledRow.tooltip.editTooltip',
    defaultMessage: 'Edit',
  },
  deleteTooltip: {
    id: 'SMSScheduledRow.tooltip.deleteTooltip',
    defaultMessage: 'Delete',
  },
  detailsModalTitle: {
    id: 'SMSScheduledRow.detailsModalTitle',
    defaultMessage: 'Scheduled SMS details',
  },
  scheduled: {
    id: 'SMSScheduledRow.label.scheduled',
    defaultMessage: 'Scheduled on',
  },
  user: {
    id: 'SMSScheduledRow.label.user',
    defaultMessage: 'Scheduled by',
  },
  sender: {
    id: 'SMSScheduledRow.label.sender',
    defaultMessage: 'Sender',
  },
  recipients: {
    id: 'SMSScheduledRow.label.recipients',
    defaultMessage: 'Recipients',
  },
  text: {
    id: 'SMSScheduledRow.label.text',
    defaultMessage: 'Text',
  },
  defaultSender: {
    id: 'SMSScheduledRow.defaultSender',
    defaultMessage: 'Default',
  },
  copyAndResend: {
    id: 'SMSScheduledRow.tooltip.copyAndResend',
    defaultMessage: 'Copy and resend',
  },
  multipleReceivers: {
    id: 'SMSScheduledRow.multipleReceivers',
    defaultMessage: 'Multiple receivers',
  },
});

const SMSScheduledRow = ({
  smsData,
  useAlias,
  users,
  dateTimeFormat,
  confirm,
  deleteSMS,
  aliasById,
  showRecipients,
  intl: { formatMessage },
}) => {
  const user = users[smsData.user];
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const handleDeleteSMS = () => {
    confirm({
      message: formatMessage(messages.deleteConfirmationMessage),
      modalAction: deleteSMS,
      actionParams: { id: smsData.id },
    });
  };

  const arrangedUser = user ? (
    <>
      <UserAvatar
        size="sm"
        alt={user.departmentFullname}
        src={user.avatar}
        id={smsData.user}
        withPopover
      />
      {user.departmentFullname}
    </>
  ) : (
    smsData.historyUser
  );

  const recipients = smsData.recipients.map((number) => (
    <div key={number}>
      <SMSRecipient recipient={number} id={smsData.id} />
    </div>
  ));

  const scheduledDate = moment(+smsData.scheduled).format(dateTimeFormat);
  const sender =
    (useAlias &&
      smsData.sender &&
      aliasById[smsData.sender] &&
      aliasById[smsData.sender].alias) ||
    formatMessage(messages.defaultSender);
  return (
    <tr>
      <td>{scheduledDate}</td>
      {showRecipients && (
        <td>
          {smsData.recipients.length === 1
            ? recipients
            : formatMessage(messages.multipleReceivers)}
        </td>
      )}
      {sender && <td>{sender}</td>}
      <td>{arrangedUser}</td>
      <td style={{ minWidth: '120px', textAlign: 'right' }}>
        <span onClick={() => setDetailsModalOpen(!detailsModalOpen)}>
          <ToolboxIcon name="bubble-chat-view-1">
            {formatMessage(messages.detailsTooltip)}
          </ToolboxIcon>
        </span>
        <Link to={`/sms/scheduled/copy/${smsData.id}`}>
          <ToolboxIcon name="file-add-1">
            {formatMessage(messages.copyAndResend)}
          </ToolboxIcon>
        </Link>
        <Link to={`/sms/scheduled/edit/${smsData.id}`}>
          <ToolboxIcon name="pencil-write-2">
            {formatMessage(messages.editTooltip)}
          </ToolboxIcon>
        </Link>
        <span onClick={() => handleDeleteSMS()}>
          <ToolboxIcon name="bin-1">
            {formatMessage(messages.deleteTooltip)}
          </ToolboxIcon>
        </span>
        <SMSDetailsModal
          id={smsData.id}
          isOpen={detailsModalOpen}
          onToggle={() => setDetailsModalOpen(!detailsModalOpen)}
          title={formatMessage(messages.detailsModalTitle)}
          scheduledDate={scheduledDate}
          recipients={recipients}
          user={arrangedUser}
          text={smsData.text}
          sender={sender}
          link={smsData.link}
        />
      </td>
    </tr>
  );
};

SMSScheduledRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.number.isRequired,
  showRecipients: PropTypes.bool,
};

SMSScheduledRow.defaultProps = {
  showRecipients: true,
};

function mapStateToProps(state, ownProps) {
  return {
    smsData: getSMSScheduledById(state, ownProps.id),
    useAlias: usingSMSAlias(state),
    dateTimeFormat: getDatetimeFormat(state),
    users: getUsersById(state),
    aliasById: usingSMSAlias(state) && getSMSsAliasById(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    deleteSMS: deleteSMSScheduledRequest,
  })(SMSScheduledRow)
);
