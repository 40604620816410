import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from './icons/Icon';
import { isHelpVisible } from '../js/me/selectors';
import HelpModal from './HelpModal';

class HelpButton extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { style, width, height, visible, fileName } = this.props;
    return (
      visible && (
        <>
          {fileName && (
            <div style={style}>
              <span
                onClick={this.toggle}
                style={{
                  color: 'var(--yn-blue-dark)',
                  cursor: 'pointer',
                }}
              >
                <Icon name="help-circle-filled" width={width} height={height} />
              </span>
            </div>
          )}
          <HelpModal
            fileName={fileName}
            isOpen={this.state.modal}
            onToggle={this.toggle}
          />
        </>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    visible: isHelpVisible(state),
  };
}

export default connect(mapStateToProps)(HelpButton);
