import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalBody, Fade } from 'reactstrap';

import {
  getPuncherTimeDifference,
  isLastPunghingIn,
  getFetchSettingsLoaded,
  getFetchSettingsError,
  getSavePunchingError,
  getSavePunchingLoaded,
} from '../../../js/presence/selectors';
import Button from '../../../components/formControls/Button';
import { savePunchingRequest } from '../../../js/presence/actions';
import Alert from '../../../components/messages/Alert';
import ToastMessage from '../../../components/messages/ToastMessage';
import Clock from '../../../components/Clock';

const messages = defineMessages({
  entering: {
    id: 'PersonalPuncherShortcut.entering',
    defaultMessage: 'Enter',
  },
  exiting: {
    id: 'PersonalPuncherShortcut.exiting',
    defaultMessage: 'Exit',
  },
  error: {
    id: 'PersonalPuncherShortcut.error',
    defaultMessage: 'Punching not available',
  },
  modalMessage: {
    id: 'PersonalPuncherShortcut.modalMessage',
    defaultMessage: 'You are not authorized for punching from this IP address',
  },
  modalOk: {
    id: 'PersonalPuncherShortcut.modalOk',
    defaultMessage: 'OK',
  },
});

class PersonalPuncherShortcut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notAuthorizedModal: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { errorSaving } = this.props;
    if (errorSaving && !prevProps.errorSaving && errorSaving.status === 403) {
      this.setState({
        notAuthorizedModal: true,
      });
    }
  };

  punch = () => {
    const { lastPunchingIsIn, save, saving } = this.props;
    if (saving) {
      return;
    }
    save({
      isIn: !lastPunchingIsIn,
    });
  };

  render() {
    const {
      lastPunchingIsIn,
      timeDifference,
      loading,
      saving,
      errorFetching,
      errorSaving,
      intl: { formatMessage },
    } = this.props;

    const { notAuthorizedModal } = this.state;

    const inited =
      lastPunchingIsIn !== undefined && timeDifference !== undefined;

    return (
      <>
        {errorSaving && errorSaving !== 200 && (
          <ToastMessage
            type="danger"
            closeTimeout={2000}
            messageCode={errorSaving}
          />
        )}
        <div
          className="d-flex bg-white border rounded p-1 mb-1"
          style={{ height: '3.1em' }}
        >
          {!inited || loading || saving ? (
            <div />
          ) : errorFetching ? (
            <Alert
              type="danger"
              text={formatMessage(messages.error)}
              style={{ margin: 0, width: '100%', padding: '0.5rem' }}
            />
          ) : (
            <>
              <Clock secondsSize="sm" />
              <Fade>
                {lastPunchingIsIn ? (
                  <Button
                    text={formatMessage(messages.exiting)}
                    loading={false}
                    className="btn btn-danger"
                    onClick={this.punch}
                  />
                ) : (
                  <Button
                    text={formatMessage(messages.entering)}
                    loading={false}
                    className="btn btn-success"
                    onClick={this.punch}
                  />
                )}
              </Fade>
            </>
          )}
        </div>
        <Modal isOpen={notAuthorizedModal}>
          <ModalBody>
            <div>{formatMessage(messages.modalMessage)}</div>
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary"
                color="primary"
                onClick={() => this.setState({ notAuthorizedModal: false })}
                text={formatMessage(messages.modalOk)}
              />
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    timeDifference: getPuncherTimeDifference(state),
    lastPunchingIsIn: isLastPunghingIn(state),
    loading: !getFetchSettingsLoaded(state),
    errorFetching: getFetchSettingsError(state),
    saving: !getSavePunchingLoaded(state),
    errorSaving: getSavePunchingError(state),
  };
}
export default injectIntl(
  connect(mapStateToProps, {
    save: savePunchingRequest,
  })(PersonalPuncherShortcut)
);
