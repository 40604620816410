import { createSelector } from 'reselect';
import sortBy from 'lodash.sortby';
import { getUsersById } from '../users/selectors';
import { getContactsById } from '../contacts/selectors';
import { getMeId } from '../me/selectors';

const defaultList = [];
const defaultObject = {};
export const getGroupsTotalQuantity = (state) => state.groups.total;
export const getFetchPinnedGroupsLoaded = (state) =>
  state.groups.fetchPinnedGroupsLoaded;
export const getFetchPinnedGroupsError = (state) =>
  state.groups.fetchPinnedGroupsError;
export const getFetchAdminGroupsLoaded = (state) =>
  state.groups.fetchAdminGroupsLoaded;
export const getFetchAdminGroupsError = (state) =>
  state.groups.fetchAdminGroupsError;
export const getUpdateGroupSettingsError = (state) =>
  state.groups.updateGroupSettingsError;
export const getUpdateGroupSettingsLoaded = (state) =>
  state.groups.updateGroupSettingsLoaded;
export const getFetchGroupLoaded = (state) => state.groups.fetchGroupLoaded;
export const getFetchGroupError = (state) => state.groups.fetchGroupError;
export const getSaveGroupLoaded = (state) => state.groups.saveGroupLoaded;
export const getSaveGroupError = (state) => state.groups.saveGroupError;
export const getDeleteGroupLoaded = (state) => state.groups.deleteGroupLoaded;
export const getDeleteGroupError = (state) => state.groups.DeleteGroupError;
export const getGroupsById = (state) => state.groups.byId;
export const getAllGroupsIds = (state) => state.groups.allIds;
export const getGroupById = (state, id) =>
  state.groups.byId[id] || defaultObject;
export const getAdminGroupsFilter = (state) => state.groups.filterAdmin;
export const getPinnedGroupsFilter = (state) => state.groups.filterPinned;
export const getGroupsIdsPaged = (state) => {
  const page = state.groups.currentPage;
  const pageIds = state.groups.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getCurrentPage = (state) => state.groups.currentPage;
export const getIsPageFetched = (state, page) =>
  state.groups.pages[page] !== undefined;

export const getGroups = createSelector(
  [getGroupsById, getAllGroupsIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getGroupsPinned = createSelector(
  [getGroupsById, getAllGroupsIds],
  (pById, pIds) =>
    pIds.map((o) => ({
      name: pById[o].name,
      color: pById[o].color,
      favorites: pById[o].favorites,
      hidden: pById[o].hidden,
      id: pById[o].id,
      main: pById[o].main,
      mine: pById[o].mine,
      owner: pById[o].owner,
      private: pById[o].private,
      status: pById[o].status,
      xmpp: pById[o].xmpp,
    }))
);

const selectGroupsSelectOptions = (state) =>
  getGroupsPinned(state)
    .filter((group) => !group.main && !group.favorites)
    .map((group) => ({
      value: group.id,
      label: group.name,
      isDisabled:
        (group.private === true && group.owner !== state.me.id) ||
        (group.private === false && !state.me.grants.includes('GROUP_PUBLIC')),
    }));

export const getGroupsSelectOptions = createSelector(
  [selectGroupsSelectOptions],
  (groups) =>
    sortBy(
      groups.map((group) => ({ ...group, isNotRemovable: group.isDisabled })),
      ['label']
    )
);
export const getMeGroupsSelectOptions = createSelector(
  [selectGroupsSelectOptions],
  (groups) =>
    sortBy(
      groups.map((group) => ({ ...group, isNotRemovable: false })),
      ['label']
    )
);

export const getGroupMembers = (state, id) =>
  state.groups.fetchMembers[id]
    ? state.groups.fetchMembers[id].members
    : defaultList;

export const getGroupOwnerAlias = (state, id) => {
  const users = getUsersById(state);
  const group = getGroupById(state, id);
  return group && users[group.owner] && users[group.owner].departmentFullname;
};

export const getFavoritesGroupId = createSelector(
  [getGroupsPinned],
  (groups) => {
    const favorites = groups.filter((group) => group.favorites);
    return favorites && favorites.length === 1 && favorites[0].id;
  }
);

export const getMainGroupId = createSelector([getGroupsPinned], (groups) => {
  const main = groups.filter((group) => group.main);
  return main && main.length === 1 && main[0].id;
});

export const getGroupMembersById = createSelector(
  [getGroupMembers, getUsersById, getContactsById],
  (groupMembers, users, contacts) => {
    const members = [];
    if (!groupMembers) {
      return defaultList;
    }
    groupMembers.forEach((member) => {
      if (member.type === 'U') {
        members.push({ ...users[member.id], type: 'U' });
      } else if (member.type === 'A' && contacts[member.id]) {
        members.push({
          ...contacts[member.id],
          fullname: contacts[member.id].fullname,
          type: 'A',
        });
      }
    });
    return members.length > 0 ? members : defaultList;
  }
);

export const getGroupMembersLoaded = (state, id) =>
  state.groups.fetchMembers[id] ? state.groups.fetchMembers[id].loaded : false;

export const isGroupsMembersLoaded = (state) =>
  !Object.keys(getGroupsById(state)).some(
    (id) =>
      state.groups.fetchMembers[id] && !state.groups.fetchMembers[id].loaded
  );

export const isMeMember = (state, groupId) =>
  groupId &&
  getGroupMembersById(state, groupId)
    .filter((m) => m.type === 'U')
    .map((m) => m.id)
    .indexOf(getMeId(state)) >= 0;

export const getMembersFetched = (state) => state.groups.fetchMembers;

export const isNormalGroupsMembersLoaded = createSelector(
  [getGroupsPinned, getMembersFetched],
  (pinned, fetched) =>
    !pinned
      .filter((group) => group.status === 'NORMAL' && !group.hidden)
      .some((group) => !fetched[group.id] || !fetched[group.id].loaded)
);

export const meChatGroupsSelectOptions = (state) =>
  getGroups(state)
    .filter((group) => group.meIsIn && group.xmpp)
    .map((group) => ({
      value: group.id,
      main: group.main,
      label: group.name,
    }));

export const fileboxSharingGroupsSelectOptions = (state) =>
  getGroups(state)
    .filter(
      (group) => !group.main && (!group.private || (group.xmpp && group.meIsIn))
    )
    .map((group) => ({
      value: group.id,
      main: group.main,
      label: group.name,
    }));

export const getGroupsPinnedNames = createSelector(
  [getGroupsById, getAllGroupsIds, getMembersFetched],
  (pById, pIds, fetched) =>
    pIds.map((o) => ({
      name: pById[o].name,
      id: pById[o].id,
      hidden: pById[o].hidden,
      membersFetched: !!fetched[o],
    }))
);
