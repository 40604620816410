import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const messages = defineMessages({
  requiredFieldsInfo: {
    id: 'Form.requiredFieldsInfo',
    defaultMessage: 'Fields marked with * are required',
  },
});

const Form = ({
  children,
  required,
  noBorder,
  scrolling,
  header,
  className,
  intl: { formatMessage },
  ...props
}) => {
  const classNames = classnames(
    {
      'rounded border px-3 pb-3 pt-1': !noBorder,
      'p-3': noBorder,
      'yn-form-scrolling-and-header': scrolling && header,
      'yn-form-scrolling': scrolling && !header,
    },
    className
  );
  return (
    <div className={classNames}>
      {required && (
        <div
          className="text-right small"
          style={{
            color: 'var(--gray)',
          }}
        >
          {formatMessage(messages.requiredFieldsInfo)}
        </div>
      )}
      <form noValidate className="needs-validation" {...props}>
        {children}
      </form>
    </div>
  );
};

Form.propTypes = {
  required: PropTypes.bool,
  noBorder: PropTypes.bool,
  scrolling: PropTypes.bool,
  header: PropTypes.bool,
};

Form.defaultProps = {
  required: true,
  noBorder: false,
  scrolling: false,
  header: false,
};

export default injectIntl(Form);
