import React from 'react';
import Filebox from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const FileboxDeletedList = React.lazy(() => import('./FileboxDeletedList'));

const FileboxDeletedPage = (props) => (
  <Filebox>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="text-right">
          <HelpButton fileName="filebox-recyclebin" />
        </div>
        <FileboxDeletedList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Filebox>
);

export default FileboxDeletedPage;
