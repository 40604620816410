import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import Button from './formControls/Button';
import { hideChangedGrantsModal } from '../js/auth/actions';

const messages = defineMessages({
  message: {
    id: 'ChangedGrantsModal.message',
    defaultMessage: 'Your grants have been changed',
  },
  ok: {
    id: 'ChangedGrantsModal.ok',
    defaultMessage: 'OK',
  },
});

const ChangedGrantsModal = ({ show, closeModal, intl: { formatMessage } }) => {
  return (
    <Modal isOpen={show}>
      <ModalBody>
        <div>{formatMessage(messages.message)}</div>
        <div className="pt-3 text-center">
          <Button
            className="btn btn-primary"
            color="primary"
            onClick={() => closeModal()}
            text={formatMessage(messages.ok)}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

ChangedGrantsModal.propTypes = {
  show: PropTypes.bool,
};

ChangedGrantsModal.defaultProps = {
  show: false,
};

export default injectIntl(
  connect(null, {
    closeModal: hideChangedGrantsModal,
  })(ChangedGrantsModal)
);
