import React from 'react';
import PropTypes from 'prop-types';

const NotificationBadge = ({
  number,
  badgeClass,
  position,
  top,
  right,
  left,
  style,
}) => (
  <span
    className={`badge ${badgeClass}`}
    style={{
      position: `${position}`,
      top: `${top}`,
      right: `${right}`,
      left: `${left}`,
      borderRadius: '50%',
      zIndex: 1,
      ...style,
    }}
  >
    {number}
  </span>
);

NotificationBadge.propTypes = {
  position: PropTypes.string,
  badgeClass: PropTypes.string,
  number: PropTypes.number.isRequired,
  top: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
};

NotificationBadge.defaultProps = {
  position: 'absolute',
  top: '0.3rem',
  badgeClass: 'badge-danger',
  right: '',
  left: '',
};

export default NotificationBadge;
