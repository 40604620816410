import pull from 'lodash.pull';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  REMOVE_DESKTOP_NOTIFICATION,
  ARCHIVE_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  FETCH_ARCHIVED_NOTIFICATIONS_REQUEST,
  FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS,
  FETCH_ARCHIVED_NOTIFICATIONS_FAILURE,
  REMOVE_INCOMING_VIDEOCALL_NOTIFICATION,
  ADD_LOST_VIDEOCALL_NOTIFICATION,
  REMOVE_LOST_VIDEOCALL_NOTIFICATION,
} from './types';
import {
  FETCH_SETTINGS_SUCCESS,
  ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  FETCH_ME_SUCCESS,
} from '../me/types';
import {
  FETCH_INTERLOCUTOR_DATA_SUCCESS,
  BLIND_TRANSFER_SUCCESS,
} from '../phone/types';
import { PhoneEnums } from '../phone/PhoneUtils';
import { NotificationEnums } from './NotificationUtils';
import { USER_UPDATE_NOTIFICATION } from '../users/types';
import { UPDATE_NOTIFICATION_SETTINGS_SUCCESS } from '../settings/types';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  GROUP_UPDATE_NOTIFICATION,
  GROUP_INSERT_NOTIFICATION,
  GROUP_DELETE_NOTIFICATION,
} from '../groups/types';
import {
  ABOOK_UPDATE_NOTIFICATION,
  ABOOK_INSERT_NOTIFICATION,
  ABOOK_DELETE_NOTIFICATION,
  ABOOK_IMPORT_NOTIFICATION,
  ABOOK_BULK_DELETE_NOTIFICATION,
} from '../contacts/types';
import {
  CALENDAR_UPDATE_NOTIFICATION,
  CALENDAR_INSERT_NOTIFICATION,
  CALENDAR_DELETE_NOTIFICATION,
  CALENDAR_DRAG_NOTIFICATION,
  CALENDAR_ADDED_PARTICIPANT_NOTIFICATION,
  CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION,
} from '../calendar/types';
import {
  FAX_SENT_NOTIFICATION,
  FAX_RECEIVED_NOTIFICATION,
  FAX_ERROR_NOTIFICATION,
} from '../fax/types';
import { FETCH_ME_CALLS_REQUEST } from '../cdr/types';
import {
  MAILOFFICE_PRICE_NOTIFICATION,
  MAILOFFICE_ERROR_NOTIFICATION,
  MAILOFFICE_COMPLETE_NOTIFICATION,
} from '../mailoffice/types';
import {
  ME_RECEIVED_VBOX_NOTIFICATION,
  PBX_RECEIVED_VBOX_NOTIFICATION,
  DELIVERED_TVOX_NOTIFICATION,
} from '../vbox/types';
import {
  INSERT_VACATION_NOTIFICATION,
  DELETE_VACATION_NOTIFICATION,
  ASK_DELETING_VACATION_NOTIFICATION,
  CONFIRM_VACATION_NOTIFICATION,
  REJECT_VACATION_NOTIFICATION,
  CONFIRM_DELETING_VACATION_NOTIFICATION,
  REJECT_DELETING_VACATION_NOTIFICATION,
  INSERT_PUNCHING_NOTIFICATION,
  CONFIRM_PUNCHING_NOTIFICATION,
  REJECT_PUNCHING_NOTIFICATION,
} from '../presence/types';
import {
  INSERT_TICKET_NOTIFICATION,
  DELETE_TICKET_NOTIFICATION,
  CLOSE_TICKET_NOTIFICATION,
  ASSIGN_TICKET_NOTIFICATION,
  TAKE_TICKET_NOTIFICATION,
  WORK_TICKET_NOTIFICATION,
  CHANGE_STATUS_TICKET_NOTIFICATION,
  SUSPEND_TICKET_NOTIFICATION,
  SHARE_TICKET_NOTIFICATION,
  UNSHARE_TICKET_NOTIFICATION,
  DEADLINE_TICKET_NOTIFICATION,
  REOPEN_TICKET_NOTIFICATION,
  INSERT_ASSIGNING_TICKET_NOTIFICATION,
  EDIT_TICKET_NOTIFICATION,
  UNASSIGN_TICKET_NOTIFICATION,
} from '../tickets/types';
import {
  NEW_FILE_FILEBOX_NOTIFICATION,
  NEW_FILE_COMMENT_FILEBOX_NOTIFICATION,
  SHARE_FOLDER_FILEBOX_NOTIFICATION,
  UNSHARE_FOLDER_FILEBOX_NOTIFICATION,
} from '../filebox/types';
import { ANSWER_VIDEOCALL_CC, INCOMING_VIDEOCALL } from '../videocalls/types';

const initialState = {
  myId: null,
  web: {
    calls: {
      alerting: [],
      lost: [],
    },
    calendar: [],
    abook: [],
    fax: [],
    admin: [],
    mailoffice: [],
    mevbox: [],
    pbxvbox: [],
    validation: [],
    punching: [],
    ticket: [],
    filebox: [],
    videocalls: {
      alerting: [],
      lost: [],
    },
  },
  archived: {
    calendar: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    abook: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    fax: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    admin: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    mailoffice: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    mevbox: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    pbxvbox: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    validation: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    punching: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    ticket: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    filebox: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
    videocalls: {
      fetched: false,
      loaded: true,
      error: null,
      count: 0,
      data: [],
    },
  },
  desktop: {
    calls: {
      lastCall: {},
      byId: {},
      allIds: [],
    },
    videocalls: [],
  },
  enabled: {
    calendar: false,
    calls: false,
    abook: false,
    admin: false,
    fax: false,
    mailoffice: false,
    mevbox: false,
    pbxvbox: false,
    validation: false,
    punching: false,
    filebox: false,
    videocalls: false,
  },
};

export default function notifications(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        myId: action.me.id,
      };
    case ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS:
    case NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS: {
      const direction =
        action.type === ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS
          ? PhoneEnums.Direction.Out
          : PhoneEnums.Direction.In;
      let webCalls = { ...state.web.calls };
      let desktopCalls = { ...state.desktop.calls };
      // prevent wrong behaviour for ring groups; if there is an active call with same calling, it is answered
      const answeredCallers = [];
      action.status.calls.forEach((call) => {
        if (
          call.direction === direction &&
          call.status === PhoneEnums.CallsStatuses.active
        ) {
          answeredCallers.push(call.calling);
        }
      });
      if (action.status.calls.length === 0) {
        const currentNumber = action.status.user.number;
        const toBeRemovedCallIds = [];
        Object.keys(desktopCalls.byId).forEach((callId) => {
          if (
            !desktopCalls.byId[callId].number ||
            desktopCalls.byId[callId].number === currentNumber
          ) {
            toBeRemovedCallIds.push(callId);
          }
        });
        toBeRemovedCallIds.forEach((removingId) => {
          pull(desktopCalls.allIds, removingId);
          pull(webCalls.alerting, removingId);
          delete desktopCalls.byId[removingId];
        });
      } else {
        action.status.calls.forEach((call) => {
          /* AL 07/08/2019 added       
            || webCalls.alerting.indexOf(call.callid) >= 0
            to manage as lost calls even calls made by callback that have direction=OUT */
          if (
            call.direction === direction ||
            webCalls.alerting.indexOf(call.callid) >= 0
          ) {
            // alerting case
            // if there are other alerting, it is a ring group so skip
            if (call.status === PhoneEnums.CallsStatuses.alerting) {
              if (webCalls.alerting.indexOf(call.callid) < 0) {
                webCalls = {
                  ...webCalls,
                  alerting: [...webCalls.alerting, call.callid],
                };
                // check if there is a callid included in this one
                webCalls.alerting = webCalls.alerting.filter(
                  (callId) =>
                    callId === call.callid ||
                    call.callid.indexOf(
                      callId.substring(0, callId.length - 1)
                    ) < 0
                );
              }
              if (desktopCalls.allIds.indexOf(call.callid) < 0) {
                desktopCalls = {
                  allIds: [...desktopCalls.allIds, call.callid],
                  byId: {
                    ...desktopCalls.byId,
                    [call.callid]: {
                      type: NotificationEnums.NotificationGroups.INCOMING_CALLS,
                      number: action.status.user
                        ? action.status.user.number
                        : null,
                      callid: call.callid,
                    },
                  },
                };
                // check if there is a callid included in this one
                desktopCalls.allIds.forEach((id) => {
                  if (
                    id !== call.callid &&
                    call.callid.indexOf(id.substring(0, id.length - 1)) >= 0
                  ) {
                    pull(desktopCalls.allIds, id);
                    delete desktopCalls.byId[id];
                  }
                });
              }
            }
            // active case, user could have answered
            // calling case, check if it exists to prevent conference calls
            if (
              call.status === PhoneEnums.CallsStatuses.active ||
              call.status === PhoneEnums.CallsStatuses.calling
            ) {
              // check if that call is in alerting -> user has answered
              if (webCalls.alerting.indexOf(call.callid) >= 0) {
                // if there are other alerting calls, it is towards webrtc+physical -> clear everything
                if (webCalls.alerting.length > 1) {
                  webCalls.alerting = [];
                } else {
                  pull(webCalls.alerting, call.callid);
                }
              }
              pull(desktopCalls.allIds, call.callid);
              delete desktopCalls.byId[call.callid];
              // clear alerting calls
              Object.keys(desktopCalls.byId).forEach((id) => {
                if (
                  desktopCalls.byId[id].type ===
                  NotificationEnums.NotificationGroups.INCOMING_CALLS
                ) {
                  delete desktopCalls.byId[id];
                  pull(desktopCalls.allIds, id);
                }
              });
            }
            // not answered call
            if (call.status === PhoneEnums.CallsStatuses.none) {
              const isQueue =
                webCalls.alerting.filter((id) => id.indexOf('from-queue') >= 0)
                  .length > 0;
              // it is lost only if the calling isn't in answered array (ring group case) and there aren't other alerting calls (webrtc case)
              if (
                answeredCallers.indexOf(call.calling) < 0 &&
                ((webCalls.alerting.length === 1 && !isQueue) ||
                  (webCalls.alerting.length && isQueue))
              ) {
                if (webCalls.alerting.indexOf(call.callid) >= 0) {
                  /* 10/09/19 replaced this line to allow lost call notification for call to queue
                  pull(webCalls.alerting, call.callid); */
                  webCalls.alerting = [];
                  if (webCalls.lost.indexOf(call.callid) < 0) {
                    webCalls = {
                      ...webCalls,
                      lost: [...webCalls.lost, call.callid],
                    };
                  }
                }
                if (
                  desktopCalls.allIds.indexOf(call.callid) >= 0 ||
                  (state.desktop.calls.lastCall &&
                    state.desktop.calls.lastCall.callid === call.callid)
                ) {
                  /* 10/09/19 replaced these lines to allow lost call notification for call to queue
                  desktopCalls = {
                    allIds:
                      desktopCalls.allIds.length > 0
                        ? desktopCalls.allIds
                        : [state.desktop.calls.lastCall.callid],
                    byId: {
                      ...desktopCalls.byId,
                      [call.callid]: {
                        ...(desktopCalls.byId[call.callid] ||
                          state.desktop.calls.lastCall),
                        number: action.status.user
                          ? action.status.user.number
                          : null,
                        type: NotificationEnums.NotificationGroups.LOST_CALLS,
                      },
                    },
                  };
                  */
                  desktopCalls = {
                    allIds:
                      desktopCalls.allIds.length > 0
                        ? [call.callid]
                        : [state.desktop.calls.lastCall.callid],
                    byId: {
                      [call.callid]: {
                        ...(desktopCalls.byId[call.callid] ||
                          state.desktop.calls.lastCall),
                        number: action.status.user
                          ? action.status.user.number
                          : null,
                        type: NotificationEnums.NotificationGroups.LOST_CALLS,
                      },
                    },
                  };
                }
              } else {
                pull(desktopCalls.allIds, call.callid);
                pull(webCalls.alerting, call.callid);
                delete desktopCalls.byId[call.callid];
              }
            }
          }
        });
      }
      return {
        ...state,
        web: {
          ...state.web,
          calls: webCalls,
        },
        desktop: {
          ...state.desktop,
          calls: {
            ...desktopCalls,
            lastCall: null,
          },
        },
      };
    }
    case FETCH_INTERLOCUTOR_DATA_SUCCESS: {
      const { context } = action.payload;
      if (context === 'calls') {
        const call = action.payload.data;
        if (state.desktop.calls.byId[call.callid]) {
          return {
            ...state,
            desktop: {
              ...state.desktop,
              calls: {
                ...state.desktop.calls,
                byId: {
                  ...state.desktop.calls.byId,
                  [call.callid]: {
                    ...state.desktop.calls.byId[call.callid],
                    ...call,
                  },
                },
              },
            },
          };
        }
        return state;
      }
      return state;
    }
    case REMOVE_DESKTOP_NOTIFICATION: {
      let calls;
      switch (action.payload.context) {
        case NotificationEnums.NotificationGroups.INCOMING_CALLS: {
          calls = { ...state.desktop.calls };
          const lastCall = calls.byId[action.payload.id];
          delete calls.byId[action.payload.id];
          pull(calls.allIds, action.payload.id);
          return {
            ...state,
            desktop: {
              ...state.desktop,
              calls: {
                ...state.desktop.calls,
                lastCall,
              },
            },
          };
        }
        case NotificationEnums.NotificationGroups.LOST_CALLS:
          calls = { ...state.desktop.calls };
          delete calls.byId[action.payload.id];
          pull(calls.allIds, action.payload.id);
          return {
            ...state,
            desktop: {
              ...state.desktop,
              calls,
            },
          };
        default:
          return state;
      }
    }
    case BLIND_TRANSFER_SUCCESS: {
      const desktopCalls = { ...state.desktop.calls };
      Object.keys(desktopCalls.byId).forEach((callId) => {
        if (
          callId === action.callId &&
          desktopCalls.byId[callId].type ===
            NotificationEnums.NotificationGroups.INCOMING_CALLS
        ) {
          delete desktopCalls.byId[callId];
          pull(desktopCalls.allIds, callId);
        }
      });
      return {
        ...state,
        desktop: {
          ...state.desktop,
          calls: desktopCalls,
        },
      };
    }
    case FETCH_SETTINGS_SUCCESS: {
      const enabled = action.settings.notifEnabled;
      return {
        ...state,
        enabled: {
          calendar:
            enabled &&
            (action.settings.notifCalendar || action.settings.notifPresences),
          calls: enabled && action.settings.notifCalls,
          abook: enabled && action.settings.notifData,
          admin: enabled && action.settings.notifData,
          fax: enabled && action.settings.notifFax,
          mailoffice: enabled && action.settings.notifMailoffice,
          mevbox: enabled && action.settings.notifVbox,
          pbxvbox: enabled && action.settings.notifVbox,
          validation: enabled && action.settings.notifPresences,
          punching: enabled && action.settings.notifPresences,
          ticket: enabled && action.settings.notifTicket,
          filebox: enabled && action.settings.notifFilebox,
          videocalls: enabled && action.settings.notifVideocalls,
        },
      };
    }
    // TODO //
    case UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return state;
    case ARCHIVE_NOTIFICATION: {
      const section = action.payload.section.toLowerCase();
      const toBeRead = state.web[section].filter(
        (o) => o.notificationId !== action.payload.notificationId
      );
      const read = state.web[section].filter(
        (o) => o.notificationId === action.payload.notificationId
      );
      return {
        ...state,
        web: {
          ...state.web,
          [section]: toBeRead,
        },
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            data: [...read, ...state.archived[section].data],
            count: state.archived[section].count + read.length,
          },
        },
      };
    }
    case READ_ALL_NOTIFICATIONS: {
      const section = action.section.toLowerCase();
      return {
        ...state,
        web: {
          ...state.web,
          [section]: [],
        },
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            data: [...state.web[section], ...state.archived[section].data],
            count: state.archived[section].count + state.web[section].length,
          },
        },
      };
    }
    case DELETE_NOTIFICATION: {
      const section = action.payload.section.toLowerCase();
      let archived = [...state.archived[section].data];
      archived = archived.filter(
        (event) => event.notificationId !== action.payload.notificationId
      );
      return {
        ...state,
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            data: archived,
            count: state.archived[section].count - 1,
          },
        },
      };
    }
    case DELETE_ALL_NOTIFICATIONS: {
      const section = action.section.toLowerCase();
      return {
        ...state,
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            data: [],
            count: 0,
          },
        },
      };
    }
    case USER_UPDATE_NOTIFICATION:
    case GROUP_UPDATE_NOTIFICATION:
    case GROUP_INSERT_NOTIFICATION:
    case GROUP_DELETE_NOTIFICATION:
    case ABOOK_UPDATE_NOTIFICATION:
    case ABOOK_INSERT_NOTIFICATION:
    case ABOOK_DELETE_NOTIFICATION:
    case ABOOK_IMPORT_NOTIFICATION:
    case ABOOK_BULK_DELETE_NOTIFICATION:
    case CALENDAR_UPDATE_NOTIFICATION:
    case CALENDAR_INSERT_NOTIFICATION:
    case CALENDAR_DELETE_NOTIFICATION:
    case CALENDAR_DRAG_NOTIFICATION:
    case CALENDAR_ADDED_PARTICIPANT_NOTIFICATION:
    case CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION:
    case FAX_SENT_NOTIFICATION:
    case FAX_ERROR_NOTIFICATION:
    case FAX_RECEIVED_NOTIFICATION:
    case MAILOFFICE_PRICE_NOTIFICATION:
    case MAILOFFICE_ERROR_NOTIFICATION:
    case MAILOFFICE_COMPLETE_NOTIFICATION:
    case ME_RECEIVED_VBOX_NOTIFICATION:
    case PBX_RECEIVED_VBOX_NOTIFICATION:
    case DELIVERED_TVOX_NOTIFICATION:
    case INSERT_VACATION_NOTIFICATION:
    case DELETE_VACATION_NOTIFICATION:
    case ASK_DELETING_VACATION_NOTIFICATION:
    case CONFIRM_VACATION_NOTIFICATION:
    case REJECT_VACATION_NOTIFICATION:
    case CONFIRM_DELETING_VACATION_NOTIFICATION:
    case REJECT_DELETING_VACATION_NOTIFICATION:
    case INSERT_PUNCHING_NOTIFICATION:
    case CONFIRM_PUNCHING_NOTIFICATION:
    case REJECT_PUNCHING_NOTIFICATION:
    case INSERT_TICKET_NOTIFICATION:
    case INSERT_ASSIGNING_TICKET_NOTIFICATION:
    case EDIT_TICKET_NOTIFICATION:
    case DELETE_TICKET_NOTIFICATION:
    case CLOSE_TICKET_NOTIFICATION:
    case ASSIGN_TICKET_NOTIFICATION:
    case UNASSIGN_TICKET_NOTIFICATION:
    case TAKE_TICKET_NOTIFICATION:
    case WORK_TICKET_NOTIFICATION:
    case CHANGE_STATUS_TICKET_NOTIFICATION:
    case SUSPEND_TICKET_NOTIFICATION:
    case SHARE_TICKET_NOTIFICATION:
    case UNSHARE_TICKET_NOTIFICATION:
    case DEADLINE_TICKET_NOTIFICATION:
    case REOPEN_TICKET_NOTIFICATION:
    case NEW_FILE_FILEBOX_NOTIFICATION:
    case NEW_FILE_COMMENT_FILEBOX_NOTIFICATION:
    case SHARE_FOLDER_FILEBOX_NOTIFICATION:
    case UNSHARE_FOLDER_FILEBOX_NOTIFICATION: {
      const section = action.payload.section.toLowerCase();
      if (!state.enabled[section]) {
        return state;
      }
      if (
        action.payload.idUserAction &&
        action.payload.idUserAction === state.myId
      ) {
        return state;
      }
      // prevent duplicate notifications
      const { notificationId } = action.payload;
      const found = state.web[section].some(
        (notification) => notification.notificationId === notificationId
      );
      if (found) {
        return state;
      }
      return {
        ...state,
        web: {
          ...state.web,
          [section]: [...state.web[section], action.payload],
        },
      };
    }
    case FETCH_ARCHIVED_NOTIFICATIONS_REQUEST: {
      const section = action.payload.section.toLowerCase();
      return {
        ...state,
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            loaded: false,
          },
        },
      };
    }
    case FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS: {
      const section = action.payload.section.toLowerCase();
      return {
        ...state,
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            count:
              action.payload.count !== null
                ? action.payload.count
                : state.archived[section].count,
            fetched: true,
            loaded: true,
            data: [
              ...state.archived[section].data,
              ...action.payload.notifications,
            ],
          },
        },
      };
    }
    case FETCH_ARCHIVED_NOTIFICATIONS_FAILURE: {
      const section = action.payload.section.toLowerCase();
      return {
        ...state,
        archived: {
          ...state.archived,
          [section]: {
            ...state.archived[section],
            loaded: true,
            error: action.payload.error,
          },
        },
      };
    }

    case FETCH_ME_CALLS_REQUEST: {
      if (action.params.lost) {
        return {
          ...state,
          web: {
            ...state.web,
            calls: {
              ...state.web.calls,
              lost: [],
            },
          },
        };
      }
      return state;
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        archived: {
          calendar: {
            ...state.archived.calendar,
            loaded: state.archived.error
              ? false
              : state.archived.calendar.loaded,
            error: null,
          },
          abook: {
            ...state.archived.abook,
            loaded: state.archived.error ? false : state.archived.abook.loaded,
            error: null,
          },
          fax: {
            ...state.archived.fax,
            loaded: state.archived.error ? false : state.archived.fax.loaded,
            error: null,
          },
          admin: {
            ...state.archived.admin,
            loaded: state.archived.error ? false : state.archived.admin.loaded,
            error: null,
          },
          mailoffice: {
            ...state.archived.mailoffice,
            loaded: state.archived.error
              ? false
              : state.archived.mailoffice.loaded,
            error: null,
          },
          mevbox: {
            ...state.archived.mevbox,
            loaded: state.archived.error ? false : state.archived.mevbox.loaded,
            error: null,
          },
          pbxvbox: {
            ...state.archived.pbxvbox,
            loaded: state.archived.error
              ? false
              : state.archived.pbxvbox.loaded,
            error: null,
          },
          validation: {
            ...state.archived.validation,
            loaded: state.archived.error
              ? false
              : state.archived.validation.loaded,
            error: null,
          },
          punching: {
            ...state.archived.punching,
            loaded: state.archived.error
              ? false
              : state.archived.punching.loaded,
            error: null,
          },
          ticket: {
            ...state.archived.ticket,
            loaded: state.archived.error ? false : state.archived.ticket.loaded,
            error: null,
          },
          filebox: {
            ...state.archived.filebox,
            loaded: state.archived.error
              ? false
              : state.archived.filebox.loaded,
            error: null,
          },
        },
      };

    case INCOMING_VIDEOCALL: {
      if (!state.enabled.videocalls) {
        return state;
      }
      const caller = action.data.caller;
      if (!caller) {
        return state;
      }
      return {
        ...state,
        web: {
          ...state.web,
          videocalls: {
            ...state.web.videocalls,
            alerting: [...state.web.videocalls.alerting, action.data.room],
          },
        },
        desktop: {
          ...state.desktop,
          videocalls: {
            ...state.desktop.videocalls,
            [action.data.room]: {
              caller,
            },
          },
        },
      };
    }

    case REMOVE_INCOMING_VIDEOCALL_NOTIFICATION: {
      const videocalls = {
        ...state.desktop.videocalls,
      };
      const alerting = [...state.web.videocalls.alerting];
      pull(alerting, action.notificationId);
      delete videocalls[action.notificationId];
      return {
        ...state,
        web: {
          ...state.web,
          videocalls: {
            ...state.web.videocalls,
            alerting,
          },
        },
        desktop: {
          ...state.desktop,
          videocalls,
        },
      };
    }

    case ANSWER_VIDEOCALL_CC: {
      const videocalls = {
        ...state.desktop.videocalls,
      };
      const alerting = [...state.web.videocalls.alerting];
      pull(alerting, action.room);
      delete videocalls[action.room];
      return {
        ...state,
        web: {
          ...state.web,
          videocalls: {
            ...state.web.videocalls,
            alerting,
          },
        },
        desktop: {
          ...state.desktop,
          videocalls,
        },
      };
    }

    case ADD_LOST_VIDEOCALL_NOTIFICATION: {
      const videocalls = {
        ...state.desktop.videocalls,
      };
      const alerting = [...state.web.videocalls.alerting];
      pull(alerting, action.notificationId);
      delete videocalls[action.notificationId];
      return {
        ...state,
        web: {
          ...state.web,
          videocalls: {
            ...state.web.videocalls,
            alerting,
            lost: [
              ...state.web.videocalls.lost,
              {
                notificationId: action.payload.notificationId,
                caller: action.payload.caller,
              },
            ],
          },
        },
        desktop: {
          ...state.desktop,
          videocalls: {
            ...state.desktop.videocalls,
          },
        },
      };
    }
    case REMOVE_LOST_VIDEOCALL_NOTIFICATION: {
      const videocalls = {
        ...state.desktop.videocalls,
      };
      let lost = [...state.web.videocalls.lost];
      lost = lost.filter(
        (videocall) => videocall.notificationId !== action.notificationId
      );
      delete videocalls[action.notificationId];
      return {
        ...state,
        web: {
          ...state.web,
          videocalls: {
            ...state.web.videocalls,
            lost,
          },
        },
        desktop: {
          ...state.desktop,
          videocalls,
        },
      };
    }
    default:
      return state;
  }
}
