import {
  CLEAR_PHONE_EXTERNAL_URL_ERRORS,
  DELETE_PHONE_EXTERNAL_URL_FAILURE,
  DELETE_PHONE_EXTERNAL_URL_REQUEST,
  DELETE_PHONE_EXTERNAL_URL_SUCCESS,
  EDIT_PHONE_EXTERNAL_URL_FAILURE,
  EDIT_PHONE_EXTERNAL_URL_REQUEST,
  EDIT_PHONE_EXTERNAL_URL_SUCCESS,
  FETCH_PHONE_EXTERNAL_URL_FAILURE,
  FETCH_PHONE_EXTERNAL_URL_REQUEST,
  FETCH_PHONE_EXTERNAL_URL_SUCCESS,
  INSERT_PHONE_EXTERNAL_URL_FAILURE,
  INSERT_PHONE_EXTERNAL_URL_REQUEST,
  INSERT_PHONE_EXTERNAL_URL_SUCCESS,
} from './types';

export const fetchPhoneExternalUrlRequest = (user) => ({
  type: FETCH_PHONE_EXTERNAL_URL_REQUEST,
  user,
});
export const fetchPhoneExternalUrlSuccess = (data) => ({
  type: FETCH_PHONE_EXTERNAL_URL_SUCCESS,
  data,
});
export const fetchPhoneExternalUrlFailure = () => ({
  type: FETCH_PHONE_EXTERNAL_URL_FAILURE,
});
export const deletePhoneExternalUrlRequest = (id, user) => ({
  type: DELETE_PHONE_EXTERNAL_URL_REQUEST,
  id,
  user,
});
export const deletePhoneExternalUrlSuccess = () => ({
  type: DELETE_PHONE_EXTERNAL_URL_SUCCESS,
});
export const deletePhoneExternalUrlFailure = (error) => ({
  type: DELETE_PHONE_EXTERNAL_URL_FAILURE,
  error,
});

export const insertPhoneExternalUrlRequest = (payload) => ({
  type: INSERT_PHONE_EXTERNAL_URL_REQUEST,
  payload,
});
export const insertPhoneExternalUrlSuccess = () => ({
  type: INSERT_PHONE_EXTERNAL_URL_SUCCESS,
});
export const insertPhoneExternalUrlFailure = (error) => ({
  type: INSERT_PHONE_EXTERNAL_URL_FAILURE,
  error,
});
export const editPhoneExternalUrlRequest = (payload) => ({
  type: EDIT_PHONE_EXTERNAL_URL_REQUEST,
  payload,
});
export const editPhoneExternalUrlSuccess = () => ({
  type: EDIT_PHONE_EXTERNAL_URL_SUCCESS,
});
export const editPhoneExternalUrlFailure = (error) => ({
  type: EDIT_PHONE_EXTERNAL_URL_FAILURE,
  error,
});
export const clearPhoneExternalUrlErrors = () => ({
  type: CLEAR_PHONE_EXTERNAL_URL_ERRORS,
});
