export const FETCH_GRANTS_REQUEST = 'FETCH_GRANTS_REQUEST';
export const FETCH_GRANTS_SUCCESS = 'FETCH_GRANTS_SUCCESS';
export const FETCH_GRANTS_FAILURE = 'FETCH_GRANTS_FAILURE';
export const TOGGLE_GRANTS_CATEGORY = 'TOGGLE_GRANTS_CATEGORY';
export const TOGGLE_GRANT = 'TOGGLE_GRANT';
export const FETCH_USER_GRANTS_REQUEST = 'FETCH_USER_GRANTS_REQUEST';
export const FETCH_USER_GRANTS_SUCCESS = 'FETCH_USER_GRANTS_SUCCESS';
export const FETCH_USER_GRANTS_FAILURE = 'FETCH_USER_GRANTS_FAILURE';
export const TOGGLE_GRANT_COLLAPSE = 'TOGGLE_GRANT_COLLAPSE';
export const GRANTS_EXPAND_ALL = 'GRANTS_EXPAND_ALL';
export const GRANTS_COLLAPSE_ALL = 'GRANTS_COLLAPSE_ALL';
export const SAVE_USER_GRANTS_REQUEST = 'SAVE_USER_GRANTS_REQUEST';
export const SAVE_USER_GRANTS_SUCCESS = 'SAVE_USER_GRANTS_SUCCESS';
export const SAVE_USER_GRANTS_FAILURE = 'SAVE_USER_GRANTS_FAILURE';
export const SAVE_ME_ADVANCED_GRANTS_REQUEST =
  'SAVE_ME_ADVANCED_GRANTS_REQUEST';
export const SAVE_ME_ADVANCED_GRANTS_SUCCESS =
  'SAVE_ME_ADVANCED_GRANTS_SUCCESS';
export const SAVE_ME_ADVANCED_GRANTS_FAILURE =
  'SAVE_ME_ADVANCED_GRANTS_FAILURE';
export const FETCH_PROFILE_GRANTS_REQUEST = 'FETCH_PROFILE_GRANTS_REQUEST';
export const FETCH_PROFILE_GRANTS_SUCCESS = 'FETCH_PROFILE_GRANTS_SUCCESS';
export const FETCH_PROFILE_GRANTS_FAILURE = 'FETCH_PROFILE_GRANTS_FAILURE';
