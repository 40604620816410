import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from 'reactstrap';
import history from '../history';

class SecondaryNavItemDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      dropdownOpen: false,
      offsetWidth: null,
      scrollWidth: null,
    };
    this.targetRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.targetRef.current &&
      this.targetRef.current.offsetWidth &&
      this.targetRef.current.offsetWidth !== prevState.offsetWidth
    ) {
      this.setState({ offsetWidth: this.targetRef.current.offsetWidth });
    }
    if (
      this.targetRef.current &&
      this.targetRef.current.scrollWidth &&
      this.targetRef.current.scrollWidth !== prevState.scrollWidth
    ) {
      this.setState({ scrollWidth: this.targetRef.current.scrollWidth });
    }
  }

  toggleDropdown = () => {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  };

  render() {
    const {
      tab,
      width,
      navs,
      intl: { formatMessage },
    } = this.props;

    const isActive = () => {
      return history.location.pathname.indexOf(tab.href) === 0;
    };

    const tabId = `tab_${tab.id.replace(/\./g, '_')}`;

    return (
      <Dropdown
        id={tabId}
        nav
        isOpen={this.state.dropdownOpen}
        toggle={this.toggleDropdown}
        className="yn-dropdown"
      >
        <DropdownToggle className={isActive() ? 'active' : ''} nav caret>
          <span
            className="text-truncate d-inline-block"
            ref={this.targetRef}
            style={{
              maxWidth: width,
            }}
          >
            {tab.label}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {navs &&
            navs.length > 0 &&
            navs.map(
              (nav, idx) =>
                nav.label && (
                  <DropdownItem key={idx} tag={Link} to={nav.href}>
                    {formatMessage(nav.label)}
                  </DropdownItem>
                )
            )}
        </DropdownMenu>
        {this.state.offsetWidth < this.state.scrollWidth && (
          <Tooltip
            isOpen={this.state.tooltipOpen}
            toggle={() => {
              this.setState({ tooltipOpen: !this.state.tooltipOpen });
            }}
            target={tabId}
          >
            {tab.label}
          </Tooltip>
        )}
      </Dropdown>
    );
  }
}

SecondaryNavItemDropdown.propTypes = {
  tab: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  navs: PropTypes.array.isRequired,
};

export default injectIntl(SecondaryNavItemDropdown);
