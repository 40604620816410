import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../../../components/icons/Icon';
import NotificationBadge from '../../../components/NotificationBadge';
import { getUnreadMessagesByConversation } from '../../../js/chat/selectors';
import OtherChatHeader from './OtherChatHeader';

class OtherChatsButton extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { width, conversations, notifications } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <div
          className="col"
          style={{
            padding: 0,
            paddingRight: '0.1em',
            paddingLeft: '0.1em',
            width: `${width}px`,
            position: 'absolute',
            bottom: '-2px',
            right: `-${width}px`,
            height: '36px',
          }}
        >
          <div
            className="rounded-top p-1"
            style={{
              color: 'var(--white)',
              backgroundClip: 'padding-box',
              border: '1px solid rgba(0, 0, 0, 0.15)',
              boxShadow: '-0.25rem -0.5rem 1rem rgba(0, 0, 0, 0.175)',
              background: isOpen ? 'var(--yn-blue)' : 'var(--yn-blue-dark)',
              height: '100%',
            }}
            onClick={this.toggle}
          >
            <Icon
              name="bubble-chat-text-2"
              style={{ position: 'absolute', bottom: '3px', left: '12px' }}
            />
            <NotificationBadge
              number={conversations.length}
              top="5px"
              left="7px"
              style={{
                color: 'var(--yn-blue-dark)',
                backgroundColor: 'var(--white)',
              }}
            />
            {notifications > 0 && (
              <NotificationBadge number={notifications} top="5px" right="7px" />
            )}
          </div>
        </div>
        {isOpen && (
          <div
            style={{
              padding: 0,
              paddingRight: '0.1em',
              paddingLeft: '0.1em',
              width: `${width * 3}px`,
              position: 'absolute',
              bottom: '35px',
              right: `-${width * 3}px`,
            }}
          >
            {conversations.map((id) => (
              <OtherChatHeader
                conversationId={id}
                key={id}
                toggle={this.toggle}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

OtherChatsButton.propTypes = {
  width: PropTypes.number.isRequired,
  conversations: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state, ownProps) {
  let tot = 0;
  ownProps.conversations.forEach((id) => {
    tot += getUnreadMessagesByConversation(state, id);
  });
  return {
    notifications: tot,
  };
}

export default connect(mapStateToProps)(OtherChatsButton);
