import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';
import Input from './Input';

const searchStyle = {
  input: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
  },
  search: {
    height: '1rem',
    width: '1rem',
    display: 'block',
    position: 'absolute',
    top: '0.6rem',
    left: '0.6rem',
    color: 'var(--yn-blue)',
  },
  close: {
    height: '0.7rem',
    width: '0.7rem',
    display: 'block',
    position: 'absolute',
    top: '0.5rem',
    right: '0.6rem',
    color: 'var(--yn-blue)',
    cursor: 'pointer',
  },
};

class Search extends Component {
  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  reset = () => {
    this.props.onChange('');
  };

  render() {
    const { name, id, text, error, disabled, placeholder, style } = this.props;
    return (
      <div className="position-relative" style={style}>
        <span style={searchStyle.search}>
          <Icon name="search-filled" width={16} height={16} clickable={false} />
        </span>
        <span style={searchStyle.close} onClick={this.reset}>
          <Icon name="close-filled" width={12} height={12} />
        </span>
        <Input
          type="text"
          name={name}
          disabled={disabled}
          id={id}
          value={text}
          onChange={this.onChange}
          placeholder={placeholder}
          style={searchStyle.input}
          error={error}
        />
      </div>
    );
  }
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

Search.defaultProps = {
  text: '',
  disabled: false,
  placeholder: '',
  style: undefined,
};

export default Search;
