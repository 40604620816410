import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';
import { getWebNotificationsAdminNumber } from '../../../js/notifications/selectors';
import {
  getFetchAdminGroupsError,
  getFetchGroupError,
  getSaveGroupError,
  getUpdateGroupSettingsError,
} from '../../../js/groups/selectors';
import { hasGrants } from '../../../js/me/selectors';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import {
  getAssociationsListIsAdmin,
  getAssociationsListIsNotAdmin,
} from '../../../js/associations/selectors';

const messages = defineMessages({
  title: {
    id: 'Groups.title',
    defaultMessage: 'Groups and users',
  },
  titleWithAssociations: {
    id: 'Groups.titleWithAssociations',
    defaultMessage: 'Groups, users and associations',
  },
  groups: {
    id: 'Groups.groups.navLabel',
    defaultMessage: 'Groups',
  },
  users: {
    id: 'Groups.users.navLabel',
    defaultMessage: 'Users',
  },
  associations: {
    id: 'Groups.associations.navLabel',
    defaultMessage: 'Associations',
  },
  notifications: {
    id: 'Groups.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
});

class Groups extends Component {
  componentDidUpdate(prevprops) {
    if (!isEqual(prevprops.error, this.props.error) && this.props.error) {
      this.Top.current.scrollTo({ top: 0, left: 0 });
    }
  }

  render() {
    const {
      intl: { formatMessage },
      children,
      notificationsQuantity,
      canManageUsers,
      canManageHRData,
      canManageAssociations,
      associationsLength,
      scrolling,
    } = this.props;
    const nav = [
      {
        id: 'Groups.groups.linkLabel',
        href: '/administration/groups',
        label: messages.groups,
      },
    ];
    if (canManageUsers || canManageHRData) {
      nav.push({
        id: 'Groups.users.linkLabel',
        href: '/administration/users',
        label: messages.users,
      });
    }
    if (canManageAssociations && associationsLength) {
      nav.push({
        id: 'Groups.associations.linkLabel',
        href: '/administration/associations',
        label: messages.associations,
      });
    }
    nav.push({
      id: 'Groups.notifications.linkLabel',
      href: '/administration/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    });
    return (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>
          {formatMessage(
            canManageAssociations && associationsLength
              ? messages.titleWithAssociations
              : messages.title
          )}
        </SectionTitle>
        <SecondaryNav tabs={nav} />
        <div
          className={
            scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
          }
        >
          {children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsAdminNumber(state),
    error:
      !!getFetchAdminGroupsError(state) ||
      !!getUpdateGroupSettingsError(state) ||
      !!getFetchGroupError(state) ||
      !!getSaveGroupError(state),
    canManageUsers: hasGrants(state, 'USERS_GRANTS'),
    canManageAssociations: hasGrants(state, 'MANAGE_ASSOCIATIONS'),
    canManageHRData:
      isPuncherEnabled(state) && hasGrants(state, 'MANAGE_PRESENCES'),
    associationsLength:
      getAssociationsListIsAdmin(state).length +
      getAssociationsListIsNotAdmin(state).length,
  };
}
export default injectIntl(connect(mapStateToProps)(Groups));
