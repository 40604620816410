import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getSMSById,
  getSMSsAliasById,
  usingSMSAlias,
} from '../../../js/sms/selectors';
import { getDatetimeFormat } from '../../../js/settings/selectors';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { SMSEnums, SMSStatusColor } from '../../../js/sms/SMSUtils';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import Popover from '../../../components/Popover';
import SMSRecipient from './SMSRecipient';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  PENDING: {
    id: 'SMSListRow.PENDING',
    defaultMessage: 'Sending',
  },
  SENT: {
    id: 'SMSListRow.SENT',
    defaultMessage: 'Sent',
  },
  ERROR: {
    id: 'SMSListRow.ERROR',
    defaultMessage: 'Error',
  },
  DELIVERED: {
    id: 'SMSListRow.DELIVERED',
    defaultMessage: 'Delivered',
  },
  showText: {
    id: 'SMSListRow.tooltip.showText',
    defaultMessage: 'Show text',
  },
  dateYestarday: {
    id: 'SMSListRow.dateYestarday',
    defaultMessage: 'yestarday',
  },
  copyAndResend: {
    id: 'SMSListRow.tooltip.copyAndResend',
    defaultMessage: 'Copy and resend',
  },
  defaultSender: {
    id: 'SMSCampaignsRow.defaultSender',
    defaultMessage: 'Default',
  },
  infoStatusTooltip: {
    id: 'SMSListRow.infoStatusTooltip',
    defaultMessage: 'Delivered on {date}',
  },
  errTOOM4USER: {
    id: 'SMSListRow.errTOOM4USER',
    defaultMessage: 'Too many SMS',
  },
  errTOOM4NUM: {
    id: 'SMSListRow.errTOOM4NUM',
    defaultMessage: 'Too many SMS',
  },
  errERROR: {
    id: 'SMSListRow.errERROR',
    defaultMessage: 'Generic error',
  },
  errTIMEOUT: {
    id: 'SMSListRow.errTIMEOUT',
    defaultMessage: 'Timeout',
  },
  errUNKNRCPT: {
    id: 'SMSListRow.errUNKNRCPT',
    defaultMessage: 'Recipient error',
  },
  errUNKNPFX: {
    id: 'SMSListRow.errUNKNPFX',
    defaultMessage: 'Prefix error',
  },
  errDEMO: {
    id: 'SMSListRow.errDEMO',
    defaultMessage: 'Sent in demo mode',
  },
  errSCHEDULED: {
    id: 'SMSListRow.errSCHEDULED',
    defaultMessage: 'Scheduling error',
  },
  errINVALIDDST: {
    id: 'SMSListRow.errINVALIDDST',
    defaultMessage: 'Invalid destination',
  },
  errBLACKLISTED: {
    id: 'SMSListRow.errBLACKLISTED',
    defaultMessage: 'Blacklisted',
  },
  errKO: {
    id: 'SMSListRow.errKO',
    defaultMessage: 'Ko',
  },
  errINVALIDCONTENTS: {
    id: 'SMSListRow.errINVALIDCONTENTS',
    defaultMessage: 'Invalid content',
  },
});

const SMSListRow = ({
  smsData,
  useAlias,
  users,
  dateTimeFormat,
  aliasById,
  showRecipients,
  intl: { formatMessage },
}) => {
  const user = users[smsData.user];
  const [textPopoverOpen, setTextPopoverOpen] = useState(false);
  const sender =
    (useAlias &&
      smsData.sender &&
      aliasById[smsData.sender] &&
      aliasById[smsData.sender].alias) ||
    formatMessage(messages.defaultSender);
  return (
    <tr>
      <td>{moment(+smsData.creationDate).format(dateTimeFormat)}</td>
      {showRecipients && (
        <td>
          <SMSRecipient recipient={smsData.recipient} id={smsData.id} />
        </td>
      )}
      {useAlias && <td>{sender}</td>}
      <td>
        {user ? (
          <>
            <UserAvatar
              size="sm"
              alt={user.departmentFullname}
              src={user.avatar}
              id={smsData.user}
              withPopover
            />
            {user.departmentFullname}
          </>
        ) : (
          smsData.historyUser
        )}
      </td>
      <td
        className={SMSStatusColor(smsData.status)}
        style={{ whiteSpace: 'nowrap' }}
      >
        <span>
          {messages[smsData.status]
            ? formatMessage(messages[smsData.status])
            : smsData.status}
        </span>
        {smsData.status === SMSEnums.Status.DELIVERED && (
          <span className="ml-1">
            <Icon
              name="information-circle"
              width={16}
              height={16}
              style={{ top: '3px' }}
            >
              {formatMessage(messages.infoStatusTooltip, {
                date: moment(+smsData.lastUpdate).format(dateTimeFormat),
              })}
            </Icon>
          </span>
        )}
        {smsData.status === SMSEnums.Status.ERROR && (
          <span className="ml-1">
            <Icon
              name="information-circle"
              width={16}
              height={16}
              style={{ top: '3px' }}
            >
              {messages[`err${smsData.error}`]
                ? formatMessage(messages[`err${smsData.error}`])
                : smsData.error}
            </Icon>
          </span>
        )}
      </td>
      <td style={{ minWidth: '65px', textAlign: 'right' }}>
        <span
          onClick={() => setTextPopoverOpen(!textPopoverOpen)}
          id={`show-text-popover-${smsData.id}`}
        >
          <ToolboxIcon name="bubble-chat-view-1">
            {formatMessage(messages.showText)}
          </ToolboxIcon>
        </span>
        <Link to={`/sms/sent/copy/${smsData.id}`}>
          <ToolboxIcon name="file-add-1">
            {formatMessage(messages.copyAndResend)}
          </ToolboxIcon>
        </Link>
        {textPopoverOpen && (
          <Popover
            isOpen={textPopoverOpen}
            toggle={() => setTextPopoverOpen(!textPopoverOpen)}
            target={`show-text-popover-${smsData.id}`}
            popoverMinHeight="fit-content"
            popoverMinWidth="fit-content"
            popoverMaxWidth="300px"
          >
            {smsData.text}
          </Popover>
        )}
      </td>
    </tr>
  );
};

SMSListRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.number.isRequired,
  showRecipients: PropTypes.bool,
};

SMSListRow.defaultProps = {
  showRecipients: true,
};

function mapStateToProps(state, ownProps) {
  return {
    smsData: getSMSById(state, ownProps.id),
    useAlias: usingSMSAlias(state),
    dateTimeFormat: getDatetimeFormat(state),
    users: getUsersById(state),
    aliasById: usingSMSAlias(state) && getSMSsAliasById(state),
  };
}

export default injectIntl(connect(mapStateToProps)(SMSListRow));
