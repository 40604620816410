export const FETCH_FAX_SETTINGS_SUCCESS = 'FETCH_FAX_SETTINGS_SUCCESS';
export const FETCH_FAX_SETTINGS_FAILURE = 'FETCH_FAX_SETTINGS_FAILURE';
export const FETCH_FAX_LIST_REQUEST = 'FETCH_FAX_LIST_REQUEST';
export const FETCH_FAX_LIST_SUCCESS = 'FETCH_FAX_LIST_SUCCESS';
export const FETCH_FAX_LIST_FAILURE = 'FETCH_FAX_LIST_FAILURE';
export const CHANGE_FAX_LIST_PAGE_REQUEST = 'CHANGE_FAX_LIST_PAGE_REQUEST';
export const RELOAD_FAX_LIST_REQUEST = 'RELOAD_FAX_LIST_REQUEST';
export const DELETE_FAX_REQUEST = 'DELETE_FAX_REQUEST';
export const DELETE_FAX_SUCCESS = 'DELETE_FAX_SUCCESS';
export const DELETE_FAX_FAILURE = 'DELETE_FAX_FAILURE';
export const READ_FAX_REQUEST = 'READ_FAX_REQUEST';
export const READ_FAX_SUCCESS = 'READ_FAX_SUCCESS';
export const READ_FAX_FAILURE = 'READ_FAX_FAILURE';
export const VIEW_FAX_DETAILS_REQUEST = 'VIEW_FAX_DETAILS_REQUEST';
export const VIEW_FAX_DETAILS_SUCCESS = 'VIEW_FAX_DETAILS_SUCCESS';
export const VIEW_FAX_DETAILS_FAILURE = 'VIEW_FAX_DETAILS_FAILURE';
export const SAVE_FAX_SETTINGS_REQUEST = 'SAVE_FAX_SETTINGS_REQUEST';
export const SAVE_FAX_SETTINGS_SUCCESS = 'SAVE_FAX_SETTINGS_SUCCESS';
export const SAVE_FAX_SETTINGS_FAILURE = 'SAVE_FAX_SETTINGS_FAILURE';
export const CHANGE_FAX_SEND_PARAMS = 'CHANGE_FAX_SEND_PARAMS';
export const SEND_FAX_REQUEST = 'SEND_FAX_REQUEST';
export const SEND_FAX_SUCCESS = 'SEND_FAX_SUCCESS';
export const SEND_FAX_FAILURE = 'SEND_FAX_FAILURE';
export const FAX_SENT_NOTIFICATION = 'FAX_SENT_NOTIFICATION';
export const FAX_ERROR_NOTIFICATION = 'FAX_ERROR_NOTIFICATION';
export const FAX_RECEIVED_NOTIFICATION = 'FAX_RECEIVED_NOTIFICATION';
export const REFRESH_FAX_STATUS_REQUEST = 'REFRESH_FAX_STATUS_REQUEST';
export const REFRESH_FAX_STATUS_SUCCESS = 'REFRESH_FAX_STATUS_SUCCESS';
export const REFRESH_FAX_STATUS_FAILURE = 'REFRESH_FAX_STATUS_FAILURE';
