import React from 'react';
import { injectIntl } from 'react-intl';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Calendar from './index';
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
/*const CalendarAppointmentsListShortcut = React.lazy(() =>
  import('./CalendarAppointmentsListShortcut.js')
);*/
const CalendarAppointmentsList = React.lazy(() =>
  import('./CalendarAppointmentsList.js')
);
const CalendarAppointmentsListFilter = React.lazy(() =>
  import('./CalendarAppointmentsListFilter.js')
);
const CalendarAppointmentReportUsersPage = (props) => {
  return (
    <Calendar scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <section>
            <div className="text-right">
              <HelpButton fileName="appointments-list" />
            </div>
            {/*<CalendarAppointmentsListShortcut />*/}
            <CalendarAppointmentsListFilter {...props} />
            <CalendarAppointmentsList {...props} />
          </section>
        </React.Suspense>
      </ErrorBoundary>
    </Calendar>
  );
};

export default injectIntl(CalendarAppointmentReportUsersPage);
