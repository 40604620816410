const defaultList = [];
export const getFetchVacationsLoaded = (state) =>
  state.vacations.fetchVacationsLoaded;
export const getFetchVacationsError = (state) =>
  state.vacations.fetchVacationsError;
export const getCurrentPage = (state) => state.vacations.currentPage;
export const getTotalQuantity = (state) => state.vacations.total;
export const getVacationsIdsPaged = (state) => {
  const page = state.vacations.currentPage;
  const pageIds = state.vacations.pages[page];
  if (pageIds === undefined) {
    return defaultList;
  }
  return pageIds;
};
export const getVacationById = (state, id) => state.vacations.byId[id];
export const getVacationsFilter = (state) => state.vacations.filter;
export const isVacationsChanged = (state) => state.vacations.vacationsChanged;
