import { request } from '../request';

export default {
  getSettings: () =>
    request({
      url: '/phone/settings',
      method: 'GET',
    }),
  getSearchedContacts: (params) =>
    request({
      url: '/contacts/number',
      method: 'GET',
      params,
    }),
  getRecordedCalls: () =>
    request({
      url: '/phone/recorded-calls',
      method: 'GET',
    }),
  startRecordingCall: (data) =>
    request({
      url: '/phone/recorded-calls',
      method: 'POST',
      data,
    }),
  deleteRecordingCall: (channel) =>
    request({
      url: '/phone/recorded-calls',
      method: 'DELETE',
      data: { channel },
    }),
  getRoutes: () =>
    request({
      url: `/phone/routes`,
      method: 'GET',
    }),
  createRoute: (data) =>
    request({
      url: `/phone/routes`,
      method: 'POST',
      data,
    }),
  updateRoute: (data) =>
    request({
      url: `/phone/routes/${data.id}`,
      method: 'POST',
      data,
    }),
  getQueues: () =>
    request({
      url: `/phone/queues`,
      method: 'GET',
    }),
  createQueue: (data) =>
    request({
      url: `/phone/queues`,
      method: 'POST',
      data,
    }),
  traceWebrtcTrouble: (data) =>
    request({
      url: `/phone/notify-error`,
      method: 'POST',
      data,
    }),
};
