export const FETCH_CONFIGURATIONS_REQUEST = 'FETCH_CONFIGURATIONS_REQUEST';
export const FETCH_CONFIGURATIONS_SUCCESS = 'FETCH_CONFIGURATIONS_SUCCESS';
export const FETCH_CONFIGURATIONS_FAILURE = 'FETCH_CONFIGURATIONS_FAILURE';
export const UPDATE_NOTIFICATION_SETTINGS_REQUEST =
  'UPDATE_NOTIFICATION_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATION_SETTINGS_FAILURE =
  'UPDATE_NOTIFICATION_SETTINGS_FAILURE';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS =
  'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const RESTORE_NOTIFICATIONS_SETTINGS_REQUEST =
  'RESTORE_NOTIFICATIONS_SETTINGS_REQUEST';
export const RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS =
  'RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const RESTORE_NOTIFICATIONS_SETTINGS_FAILURE =
  'RESTORE_NOTIFICATIONS_SETTINGS_FAILURE';
export const UPDATE_PLATFORM_SETTINGS_REQUEST =
  'UPDATE_PLATFORM_SETTINGS_REQUEST';
export const UPDATE_PLATFORM_SETTINGS_FAILURE =
  'UPDATE_PLATFORM_SETTINGS_FAILURE';
export const UPDATE_PLATFORM_SETTINGS_SUCCESS =
  'UPDATE_PLATFORM_SETTINGS_SUCCESS';
export const SAVE_HIDE_INTRO_REQUEST = 'SAVE_HIDE_INTRO_REQUEST';
