import {
  FETCH_CALENDAR_SETTINGS_SUCCESS,
  FETCH_CALENDAR_SETTINGS_FAILURE,
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  SET_CALENDAR_FILTER,
  FETCH_CALENDAR_EVENT_REQUEST,
  FETCH_CALENDAR_EVENT_SUCCESS,
  FETCH_CALENDAR_EVENT_FAILURE,
  SAVE_CALENDAR_EVENT_REQUEST,
  SAVE_CALENDAR_EVENT_SUCCESS,
  SAVE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT_FAILURE,
  SAVE_CALENDAR_SETTINGS_REQUEST,
  SAVE_CALENDAR_SETTINGS_SUCCESS,
  SAVE_CALENDAR_SETTINGS_FAILURE,
  IMPORT_CALENDAR_EVENTS_REQUEST,
  IMPORT_CALENDAR_EVENTS_SUCCESS,
  IMPORT_CALENDAR_EVENTS_FAILURE,
  CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST,
  CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS,
  CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE,
  CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST,
  CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS,
  CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE,
  CLOSE_IMPORT_CALENDAR_EVENTS_MODAL,
  FETCH_CALENDAR_SYNCOUT_REQUEST,
  FETCH_CALENDAR_SYNCOUT_SUCCESS,
  FETCH_CALENDAR_SYNCOUT_FAILURE,
  SAVE_CALENDAR_SYNCOUT_REQUEST,
  SAVE_CALENDAR_SYNCOUT_SUCCESS,
  SAVE_CALENDAR_SYNCOUT_FAILURE,
  DELETE_CALENDAR_SYNCOUT_REQUEST,
  DELETE_CALENDAR_SYNCOUT_SUCCESS,
  DELETE_CALENDAR_SYNCOUT_FAILURE,
  ENABLE_DISABLE_SYNCOUT_REQUEST,
  ENABLE_DISABLE_SYNCOUT_SUCCESS,
  ENABLE_DISABLE_SYNCOUT_FAILURE,
  FETCH_CALENDAR_SYNCIN_REQUEST,
  FETCH_CALENDAR_SYNCIN_SUCCESS,
  FETCH_CALENDAR_SYNCIN_FAILURE,
  SAVE_CALENDAR_SYNCIN_REQUEST,
  SAVE_CALENDAR_SYNCIN_SUCCESS,
  SAVE_CALENDAR_SYNCIN_FAILURE,
  DELETE_CALENDAR_SYNCIN_REQUEST,
  DELETE_CALENDAR_SYNCIN_SUCCESS,
  DELETE_CALENDAR_SYNCIN_FAILURE,
  FETCH_CALENDAR_SLOTS_REQUEST,
  FETCH_CALENDAR_SLOTS_SUCCESS,
  FETCH_CALENDAR_SLOTS_FAILURE,
  CALENDAR_REMINDER_NOTIFICATION,
  CALENDAR_INSERT_NOTIFICATION,
  CALENDAR_UPDATE_NOTIFICATION,
  CALENDAR_DELETE_NOTIFICATION,
  CALENDAR_DRAG_NOTIFICATION,
  CALENDAR_ADDED_PARTICIPANT_NOTIFICATION,
  CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION,
  FETCH_CALENDAR_EVENT_CONTACTS_REQUEST,
  FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS,
  FETCH_CALENDAR_EVENT_CONTACTS_FAILURE,
  TOGGLE_CALENDAR_SYNCIN_REQUEST,
  TOGGLE_CALENDAR_SYNCIN_SUCCESS,
  TOGGLE_CALENDAR_SYNCIN_FAILURE,
  FETCH_VACATION_PLAN_REQUEST,
  FETCH_VACATION_PLAN_SUCCESS,
  FETCH_VACATION_PLAN_FAILURE,
  APPROVE_VACATION_REQUEST,
  APPROVE_VACATION_SUCCESS,
  APPROVE_VACATION_FAILURE,
  REJECT_VACATION_REQUEST,
  REJECT_VACATION_SUCCESS,
  REJECT_VACATION_FAILURE,
  APPROVE_DELETION_VACATION_REQUEST,
  APPROVE_DELETION_VACATION_SUCCESS,
  APPROVE_DELETION_VACATION_FAILURE,
  REJECT_DELETION_VACATION_REQUEST,
  REJECT_DELETION_VACATION_SUCCESS,
  REJECT_DELETION_VACATION_FAILURE,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  FETCH_VACATION_RESUME_SUCCESS,
  FETCH_VACATION_RESUME_FAILURE,
  FETCH_VACATION_RESUME_REQUEST,
  FETCH_TASK_PLAN_REQUEST,
  FETCH_TASK_PLAN_SUCCESS,
  FETCH_TASK_PLAN_FAILURE,
  FETCH_APPOINTMENT_RESULTS_REQUEST,
  FETCH_APPOINTMENT_RESULTS_SUCCESS,
  FETCH_APPOINTMENT_RESULTS_FAILURE,
  DELETE_APPOINTMENT_RESULT_REQUEST,
  DELETE_APPOINTMENT_RESULT_SUCCESS,
  DELETE_APPOINTMENT_RESULT_FAILURE,
  INSERT_APPOINTMENT_RESULT_REQUEST,
  INSERT_APPOINTMENT_RESULT_SUCCESS,
  INSERT_APPOINTMENT_RESULT_FAILURE,
  EDIT_APPOINTMENT_RESULT_REQUEST,
  EDIT_APPOINTMENT_RESULT_SUCCESS,
  EDIT_APPOINTMENT_RESULT_FAILURE,
  CLEAR_APPOINTMENT_RESULT_ERRORS,
  FETCH_APPOINTMENT_TYPES_REQUEST,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  FETCH_APPOINTMENT_TYPES_FAILURE,
  DELETE_APPOINTMENT_TYPE_REQUEST,
  DELETE_APPOINTMENT_TYPE_SUCCESS,
  DELETE_APPOINTMENT_TYPE_FAILURE,
  INSERT_APPOINTMENT_TYPE_REQUEST,
  INSERT_APPOINTMENT_TYPE_SUCCESS,
  INSERT_APPOINTMENT_TYPE_FAILURE,
  EDIT_APPOINTMENT_TYPE_REQUEST,
  EDIT_APPOINTMENT_TYPE_SUCCESS,
  EDIT_APPOINTMENT_TYPE_FAILURE,
  CLEAR_APPOINTMENT_TYPE_ERRORS,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE,
  SET_APPOINTMENTS_FILTER,
  FETCH_APPOINTMENTS_REQUEST,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_MAP_REQUEST,
  FETCH_APPOINTMENTS_MAP_SUCCESS,
  FETCH_APPOINTMENTS_MAP_FAILURE,
  SAVE_CALENDAR_APPOINTMENT_REQUEST,
  SAVE_CALENDAR_APPOINTMENT_SUCCESS,
  FETCH_CALENDAR_APPOINTMENT_REQUEST,
  FETCH_CALENDAR_APPOINTMENT_SUCCESS,
  FETCH_CALENDAR_APPOINTMENT_FAILURE,
  SAVE_CALENDAR_APPOINTMENT_FAILURE,
  DELETE_CALENDAR_APPOINTMENT_REQUEST,
  DELETE_CALENDAR_APPOINTMENT_SUCCESS,
  DELETE_CALENDAR_APPOINTMENT_FAILURE,
  REGISTER_CALENDAR_APPOINTMENT_REQUEST,
  REGISTER_CALENDAR_APPOINTMENT_SUCCESS,
  REGISTER_CALENDAR_APPOINTMENT_FAILURE,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE,
  FETCH_LINKED_CALENDAR_EVENTS_REQUEST,
  FETCH_LINKED_CALENDAR_EVENTS_SUCCESS,
  FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST,
  FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS,
  FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE,
} from './types';

export const fetchCalendarSettingsSuccess = (settings) => ({
  type: FETCH_CALENDAR_SETTINGS_SUCCESS,
  settings,
});
export const fetchCalendarSettingsFailure = (errors) => ({
  type: FETCH_CALENDAR_SETTINGS_FAILURE,
  errors,
});
export const fetchCalendarEventsRequest = (params) => ({
  type: FETCH_CALENDAR_EVENTS_REQUEST,
  params,
});
export const fetchCalendarEventsSuccess = (events) => ({
  type: FETCH_CALENDAR_EVENTS_SUCCESS,
  events,
});
export const fetchCalendarEventsFailure = (errors) => ({
  type: FETCH_CALENDAR_EVENTS_FAILURE,
  errors,
});
export const setCalendarFilter = (filter) => ({
  type: SET_CALENDAR_FILTER,
  filter,
});
export const fetchCalendarEventRequest = (eventId) => ({
  type: FETCH_CALENDAR_EVENT_REQUEST,
  eventId,
});
export const fetchCalendarEventSuccess = (event) => ({
  type: FETCH_CALENDAR_EVENT_SUCCESS,
  event,
});
export const fetchCalendarEventFailure = (payload) => ({
  type: FETCH_CALENDAR_EVENT_FAILURE,
  payload,
});
export const saveCalendarEventRequest = (event) => ({
  type: SAVE_CALENDAR_EVENT_REQUEST,
  event,
});
export const saveCalendarEventSuccess = (eventId) => ({
  type: SAVE_CALENDAR_EVENT_SUCCESS,
  eventId,
});
export const saveCalendarEventFailure = (errors) => ({
  type: SAVE_CALENDAR_EVENT_FAILURE,
  errors,
});
export const deleteCalendarEventRequest = (event) => ({
  type: DELETE_CALENDAR_EVENT_REQUEST,
  event,
});
export const deleteCalendarEventSuccess = (event) => ({
  type: DELETE_CALENDAR_EVENT_SUCCESS,
  event,
});
export const deleteCalendarEventFailure = (errors) => ({
  type: DELETE_CALENDAR_EVENT_FAILURE,
  errors,
});
export const saveCalendarSettingsRequest = (settings) => ({
  type: SAVE_CALENDAR_SETTINGS_REQUEST,
  settings,
});
export const saveCalendarSettingsSuccess = (settings) => ({
  type: SAVE_CALENDAR_SETTINGS_SUCCESS,
  settings,
});
export const saveCalendarSettingsFailure = (errors) => ({
  type: SAVE_CALENDAR_SETTINGS_FAILURE,
  errors,
});
export const fetchCalendarSyncoutRequest = (data) => ({
  type: FETCH_CALENDAR_SYNCOUT_REQUEST,
  data,
});
export const fetchCalendarSyncoutSuccess = (data) => ({
  type: FETCH_CALENDAR_SYNCOUT_SUCCESS,
  data,
});
export const fetchCalendarSyncoutFailure = (errors) => ({
  type: FETCH_CALENDAR_SYNCOUT_FAILURE,
  errors,
});
export const saveCalendarSyncoutRequest = (data) => ({
  type: SAVE_CALENDAR_SYNCOUT_REQUEST,
  data,
});
export const saveCalendarSyncoutSuccess = (data) => ({
  type: SAVE_CALENDAR_SYNCOUT_SUCCESS,
  data,
});
export const saveCalendarSyncoutFailure = (errors) => ({
  type: SAVE_CALENDAR_SYNCOUT_FAILURE,
  errors,
});
export const deleteCalendarSyncoutRequest = (id) => ({
  type: DELETE_CALENDAR_SYNCOUT_REQUEST,
  id,
});
export const deleteCalendarSyncoutSuccess = (status) => ({
  type: DELETE_CALENDAR_SYNCOUT_SUCCESS,
  status,
});
export const deleteCalendarSyncoutFailure = (errors) => ({
  type: DELETE_CALENDAR_SYNCOUT_FAILURE,
  errors,
});
export const enableDisableCalendarSyncoutRequest = (status) => ({
  type: ENABLE_DISABLE_SYNCOUT_REQUEST,
  status,
});
export const enableDisableCalendarSyncoutSuccess = (success) => ({
  type: ENABLE_DISABLE_SYNCOUT_SUCCESS,
  success,
});
export const enableDisableCalendarSyncoutFailure = (errors) => ({
  type: ENABLE_DISABLE_SYNCOUT_FAILURE,
  errors,
});

export const importCalendarEventsRequest = (file) => ({
  type: IMPORT_CALENDAR_EVENTS_REQUEST,
  file,
});
export const importCalendarEventsSuccess = (success) => ({
  type: IMPORT_CALENDAR_EVENTS_SUCCESS,
  success,
});
export const importCalendarEventsFailure = (errors) => ({
  type: IMPORT_CALENDAR_EVENTS_FAILURE,
  errors,
});
export const confirmImportCalendarEventsRequest = (data) => ({
  type: CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST,
  data,
});
export const confirmImportCalendarEventsSuccess = (success) => ({
  type: CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS,
  success,
});
export const confirmImportCalendarEventsFailure = (errors) => ({
  type: CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE,
  errors,
});
export const cancelImportCalendarEventsRequest = (file) => ({
  type: CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST,
  file,
});
export const cancelImportCalendarEventsSuccess = (success) => ({
  type: CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS,
  success,
});
export const cancelImportCalendarEventsFailure = (errors) => ({
  type: CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE,
  errors,
});
export const closeImportCalendarEventsModal = (state) => ({
  type: CLOSE_IMPORT_CALENDAR_EVENTS_MODAL,
  state,
});
export const fetchCalendarSyncinRequest = (data) => ({
  type: FETCH_CALENDAR_SYNCIN_REQUEST,
  data,
});
export const fetchCalendarSyncinSuccess = (success) => ({
  type: FETCH_CALENDAR_SYNCIN_SUCCESS,
  success,
});
export const fetchCalendarSyncinFailure = (errors) => ({
  type: FETCH_CALENDAR_SYNCIN_FAILURE,
  errors,
});
export const saveCalendarSyncinRequest = (data) => ({
  type: SAVE_CALENDAR_SYNCIN_REQUEST,
  data,
});
export const saveCalendarSyncinSuccess = (success) => ({
  type: SAVE_CALENDAR_SYNCIN_SUCCESS,
  success,
});
export const saveCalendarSyncinFailure = (errors) => ({
  type: SAVE_CALENDAR_SYNCIN_FAILURE,
  errors,
});
export const deleteCalendarSyncinRequest = (data) => ({
  type: DELETE_CALENDAR_SYNCIN_REQUEST,
  data,
});
export const deleteCalendarSyncinSuccess = (success) => ({
  type: DELETE_CALENDAR_SYNCIN_SUCCESS,
  success,
});
export const deleteCalendarSyncinFailure = (errors) => ({
  type: DELETE_CALENDAR_SYNCIN_FAILURE,
  errors,
});
export const toggleCalendarSyncinRequest = (payload) => ({
  type: TOGGLE_CALENDAR_SYNCIN_REQUEST,
  payload,
});
export const toggleCalendarSyncinSuccess = () => ({
  type: TOGGLE_CALENDAR_SYNCIN_SUCCESS,
});
export const toggleCalendarSyncinFailure = (payload) => ({
  type: TOGGLE_CALENDAR_SYNCIN_FAILURE,
  payload,
});
export const fetchCalendarSlotsRequest = (params) => ({
  type: FETCH_CALENDAR_SLOTS_REQUEST,
  params,
});
export const fetchCalendarSlotsSuccess = (success) => ({
  type: FETCH_CALENDAR_SLOTS_SUCCESS,
  success,
});
export const fetchCalendarSlotsFailure = (errors) => ({
  type: FETCH_CALENDAR_SLOTS_FAILURE,
  errors,
});
export const fetchVacationPlanRequest = (params) => ({
  type: FETCH_VACATION_PLAN_REQUEST,
  params,
});
export const fetchVacationPlanSuccess = (data) => ({
  type: FETCH_VACATION_PLAN_SUCCESS,
  data,
});
export const fetchVacationPlanFailure = (errors) => ({
  type: FETCH_VACATION_PLAN_FAILURE,
  errors,
});
export const fetchCalendarEventContactsRequest = (contacts) => ({
  type: FETCH_CALENDAR_EVENT_CONTACTS_REQUEST,
  contacts,
});
export const fetchCalendarEventContactsSuccess = () => ({
  type: FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS,
});
export const fetchCalendarEventContactsFailure = () => ({
  type: FETCH_CALENDAR_EVENT_CONTACTS_FAILURE,
});
export const calendarReminderNotification = (payload) => ({
  type: CALENDAR_REMINDER_NOTIFICATION,
  payload,
});
export const calendarInsertNotification = (payload) => ({
  type: CALENDAR_INSERT_NOTIFICATION,
  payload,
});
export const calendarUpdateNotification = (payload) => ({
  type: CALENDAR_UPDATE_NOTIFICATION,
  payload,
});
export const calendarDeleteNotification = (payload) => ({
  type: CALENDAR_DELETE_NOTIFICATION,
  payload,
});
export const calendarDragNotification = (payload) => ({
  type: CALENDAR_DRAG_NOTIFICATION,
  payload,
});
export const calendarAddedParticipantNotification = (payload) => ({
  type: CALENDAR_ADDED_PARTICIPANT_NOTIFICATION,
  payload,
});
export const calendarRemovedParticipantNotification = (payload) => ({
  type: CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION,
  payload,
});
export const approveVacationRequest = (params) => ({
  type: APPROVE_VACATION_REQUEST,
  params,
});
export const approveVacationSuccess = (id) => ({
  type: APPROVE_VACATION_SUCCESS,
  id,
});
export const approveVacationFailure = (errors) => ({
  type: APPROVE_VACATION_FAILURE,
  errors,
});
export const rejectVacationRequest = (params) => ({
  type: REJECT_VACATION_REQUEST,
  params,
});
export const rejectVacationSuccess = (id) => ({
  type: REJECT_VACATION_SUCCESS,
  id,
});
export const rejectVacationFailure = (errors) => ({
  type: REJECT_VACATION_FAILURE,
  errors,
});
export const approveDeletionVacationRequest = (params) => ({
  type: APPROVE_DELETION_VACATION_REQUEST,
  params,
});
export const approveDeletionVacationSuccess = (id) => ({
  type: APPROVE_DELETION_VACATION_SUCCESS,
  id,
});
export const approveDeletionVacationFailure = (errors) => ({
  type: APPROVE_DELETION_VACATION_FAILURE,
  errors,
});
export const rejectDeletionVacationRequest = (params) => ({
  type: REJECT_DELETION_VACATION_REQUEST,
  params,
});
export const rejectDeletionVacationSuccess = (id) => ({
  type: REJECT_DELETION_VACATION_SUCCESS,
  id,
});
export const rejectDeletionVacationFailure = (errors) => ({
  type: REJECT_DELETION_VACATION_FAILURE,
  errors,
});
export const fetchCalendarListOfEmailSuggestionsRequest = () => ({
  type: FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST,
});
export const fetchCalendarListOfEmailSuggestionsSuccess = (payload) => ({
  type: FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS,
  payload,
});
export const fetchCalendarListOfEmailSuggestionsFailure = (errors) => ({
  type: FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE,
  errors,
});
export const fetchCalendarFestivitiesSettingsRequest = (settings) => ({
  type: FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  settings,
});
export const fetchCalendarFestivitiesSettingsSuccess = (settings) => ({
  type: FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  settings,
});
export const fetchCalendarFestivitiesSettingsFailure = (errors) => ({
  type: FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  errors,
});
export const saveCalendarFestivitiesSettingsRequest = (settings) => ({
  type: SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  settings,
});
export const saveCalendarFestivitiesSettingsSuccess = (settings) => ({
  type: SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  settings,
});
export const saveCalendarFestivitiesSettingsFailure = (errors) => ({
  type: SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  errors,
});
export const fetchVacationResumeRequest = (payload) => ({
  type: FETCH_VACATION_RESUME_REQUEST,
  payload,
});
export const fetchVacationResumeSuccess = (data) => ({
  type: FETCH_VACATION_RESUME_SUCCESS,
  data,
});
export const fetchVacationResumeFailure = (errors) => ({
  type: FETCH_VACATION_RESUME_FAILURE,
  errors,
});
export const fetchTaskPlanRequest = (params) => ({
  type: FETCH_TASK_PLAN_REQUEST,
  params,
});
export const fetchTaskPlanSuccess = (data) => ({
  type: FETCH_TASK_PLAN_SUCCESS,
  data,
});
export const fetchTaskPlanFailure = (errors) => ({
  type: FETCH_TASK_PLAN_FAILURE,
  errors,
});
export const fetchAppointmentResultsRequest = () => ({
  type: FETCH_APPOINTMENT_RESULTS_REQUEST,
});
export const fetchAppointmentResultsSuccess = (data) => ({
  type: FETCH_APPOINTMENT_RESULTS_SUCCESS,
  data,
});
export const fetchAppointmentResultsFailure = () => ({
  type: FETCH_APPOINTMENT_RESULTS_FAILURE,
});
export const deleteAppointmentResultRequest = (id) => ({
  type: DELETE_APPOINTMENT_RESULT_REQUEST,
  id,
});
export const deleteAppointmentResultSuccess = () => ({
  type: DELETE_APPOINTMENT_RESULT_SUCCESS,
});
export const deleteAppointmentResultFailure = (error) => ({
  type: DELETE_APPOINTMENT_RESULT_FAILURE,
  error,
});

export const insertAppointmentResultRequest = (payload) => ({
  type: INSERT_APPOINTMENT_RESULT_REQUEST,
  payload,
});
export const insertAppointmentResultSuccess = () => ({
  type: INSERT_APPOINTMENT_RESULT_SUCCESS,
});
export const insertAppointmentResultFailure = (error) => ({
  type: INSERT_APPOINTMENT_RESULT_FAILURE,
  error,
});
export const editAppointmentResultRequest = (payload) => ({
  type: EDIT_APPOINTMENT_RESULT_REQUEST,
  payload,
});
export const editAppointmentResultSuccess = () => ({
  type: EDIT_APPOINTMENT_RESULT_SUCCESS,
});
export const editAppointmentResultFailure = (error) => ({
  type: EDIT_APPOINTMENT_RESULT_FAILURE,
  error,
});
export const clearAppointmentResultErrors = () => ({
  type: CLEAR_APPOINTMENT_RESULT_ERRORS,
});
export const fetchAppointmentTypesRequest = () => ({
  type: FETCH_APPOINTMENT_TYPES_REQUEST,
});
export const fetchAppointmentTypesSuccess = (data) => ({
  type: FETCH_APPOINTMENT_TYPES_SUCCESS,
  data,
});
export const fetchAppointmentTypesFailure = () => ({
  type: FETCH_APPOINTMENT_TYPES_FAILURE,
});
export const deleteAppointmentTypeRequest = (id) => ({
  type: DELETE_APPOINTMENT_TYPE_REQUEST,
  id,
});
export const deleteAppointmentTypeSuccess = () => ({
  type: DELETE_APPOINTMENT_TYPE_SUCCESS,
});
export const deleteAppointmentTypeFailure = (error) => ({
  type: DELETE_APPOINTMENT_TYPE_FAILURE,
  error,
});

export const insertAppointmentTypeRequest = (payload) => ({
  type: INSERT_APPOINTMENT_TYPE_REQUEST,
  payload,
});
export const insertAppointmentTypeSuccess = () => ({
  type: INSERT_APPOINTMENT_TYPE_SUCCESS,
});
export const insertAppointmentTypeFailure = (error) => ({
  type: INSERT_APPOINTMENT_TYPE_FAILURE,
  error,
});
export const editAppointmentTypeRequest = (payload) => ({
  type: EDIT_APPOINTMENT_TYPE_REQUEST,
  payload,
});
export const editAppointmentTypeSuccess = () => ({
  type: EDIT_APPOINTMENT_TYPE_SUCCESS,
});
export const editAppointmentTypeFailure = (error) => ({
  type: EDIT_APPOINTMENT_TYPE_FAILURE,
  error,
});
export const clearAppointmentTypeErrors = () => ({
  type: CLEAR_APPOINTMENT_TYPE_ERRORS,
});
export const fetchAppointmentDashboardHeaderRequest = (appointmentType) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST,
  appointmentType,
});
export const fetchAppointmentDashboardHeaderSuccess = (data) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS,
  data,
});
export const fetchAppointmentDashboardHeaderFailure = (error) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE,
  error,
});
export const fetchAppointmentDashboardGraphStatisticsRequest = (params) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  params,
});
export const fetchAppointmentDashboardGraphStatisticsSuccess = (data) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  data,
});
export const fetchAppointmentDashboardGraphStatisticsFailure = (error) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  error,
});
export const fetchAppointmentDashboardUsersStatisticsRequest = (
  appointmentType
) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST,
  appointmentType,
});
export const fetchAppointmentDashboardUsersStatisticsSuccess = (data) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS,
  data,
});
export const fetchAppointmentDashboardUsersStatisticsFailure = (error) => ({
  type: FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE,
  error,
});
export const fetchAppointmentsListShortcutRequest = () => ({
  type: FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST,
});
export const fetchAppointmentsListShortcutSuccess = (data) => ({
  type: FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS,
  data,
});
export const fetchAppointmentsListShortcutFailure = (error) => ({
  type: FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE,
  error,
});
export const setAppointmentsFilter = (payload) => ({
  type: SET_APPOINTMENTS_FILTER,
  payload,
});
export const fetchAppointmentsRequest = (params) => ({
  type: FETCH_APPOINTMENTS_REQUEST,
  params,
});
export const fetchAppointmentsSuccess = (data) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  data,
});
export const fetchAppointmentsFailure = () => ({
  type: FETCH_APPOINTMENTS_FAILURE,
});
export const fetchAppointmentsMapRequest = (params) => ({
  type: FETCH_APPOINTMENTS_MAP_REQUEST,
  params,
});
export const fetchAppointmentsMapSuccess = (data) => ({
  type: FETCH_APPOINTMENTS_MAP_SUCCESS,
  data,
});
export const fetchAppointmentsMapFailure = () => ({
  type: FETCH_APPOINTMENTS_MAP_FAILURE,
});
export const saveCalendarAppointmentRequest = (appointment) => ({
  type: SAVE_CALENDAR_APPOINTMENT_REQUEST,
  appointment,
});
export const saveCalendarAppointmentSuccess = (appointmentId) => ({
  type: SAVE_CALENDAR_APPOINTMENT_SUCCESS,
  appointmentId,
});
export const saveCalendarAppointmentFailure = (errors) => ({
  type: SAVE_CALENDAR_APPOINTMENT_FAILURE,
  errors,
});
export const fetchCalendarAppointmentRequest = (appointmentId) => ({
  type: FETCH_CALENDAR_APPOINTMENT_REQUEST,
  appointmentId,
});
export const fetchCalendarAppointmentSuccess = (appointment) => ({
  type: FETCH_CALENDAR_APPOINTMENT_SUCCESS,
  appointment,
});
export const fetchCalendarAppointmentFailure = (payload) => ({
  type: FETCH_CALENDAR_APPOINTMENT_FAILURE,
  payload,
});
export const deleteCalendarAppointmentRequest = (id) => ({
  type: DELETE_CALENDAR_APPOINTMENT_REQUEST,
  id,
});
export const deleteCalendarAppointmentSuccess = (id) => ({
  type: DELETE_CALENDAR_APPOINTMENT_SUCCESS,
  id,
});
export const deleteCalendarAppointmentFailure = (errors) => ({
  type: DELETE_CALENDAR_APPOINTMENT_FAILURE,
  errors,
});
export const registerCalendarAppointmentRequest = (payload) => ({
  type: REGISTER_CALENDAR_APPOINTMENT_REQUEST,
  payload,
});
export const registerCalendarAppointmentSuccess = () => ({
  type: REGISTER_CALENDAR_APPOINTMENT_SUCCESS,
});
export const registerCalendarAppointmentFailure = (errors) => ({
  type: REGISTER_CALENDAR_APPOINTMENT_FAILURE,
  errors,
});
export const fetchLinkedCalendarAppointmentsRequest = (appointmentIds) => ({
  type: FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST,
  appointmentIds,
});
export const fetchLinkedCalendarAppointmentsSuccess = (appointments) => ({
  type: FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS,
  appointments,
});
export const fetchLinkedCalendarAppointmentsFailure = (error) => ({
  type: FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE,
  error,
});
export const fetchLinkedCalendarEventsRequest = (eventIds) => ({
  type: FETCH_LINKED_CALENDAR_EVENTS_REQUEST,
  eventIds,
});
export const fetchLinkedCalendarEventsSuccess = (events) => ({
  type: FETCH_LINKED_CALENDAR_EVENTS_SUCCESS,
  events,
});
export const fetchLinkedCalendarEventsFailure = (error) => ({
  type: FETCH_LINKED_CALENDAR_EVENTS_SUCCESS,
  error,
});
export const fetchAppointmentsForExcelRequest = (params) => ({
  type: FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST,
  params,
});
export const fetchAppointmentsForExcelSuccess = (data) => ({
  type: FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS,
  data,
});
export const fetchAppointmentsForExcelFailure = () => ({
  type: FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE,
});
