import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { openChatConversation } from '../../../js/chat/actions';
import {
  isChatConversationHistoryLoaded,
  isChatServiceLoading,
} from '../../../js/chat/selectors';

import { isWsAvailable } from '../../../js/auth/selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';

const Chat = React.lazy(() => import('./index'));
const Loading = React.lazy(() => import('../../../components/Loading'));
const GlobalErrorPage = React.lazy(() => import('../../pages/GlobalErrorPage'));
const ChatConversationsList = React.lazy(() =>
  import('./ChatConversationsList')
);

const messages = defineMessages({
  title: {
    id: 'ChatPage.title',
    defaultMessage: 'Chat',
  },
  serviceNotAvailable: {
    id: 'ChatPage.errors.serviceNotAvailable',
    defaultMessage: 'The chat service is not available',
  },
});

class ChatPage extends React.Component {
  componentDidMount() {
    if (
      !this.props.error &&
      this.props.match &&
      this.props.match.params.id &&
      this.props.isChatLoaded
    ) {
      this.props.openConversation({
        conversationId: this.props.match.params.id,
        byMe: true,
      });
    }
  }

  render() {
    const {
      unavailable,
      loading,
      intl: { formatMessage },
    } = this.props;
    return (
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <Chat>
              {loading ? (
                <Loading />
              ) : unavailable ? (
                <GlobalErrorPage
                  type="danger"
                  text={formatMessage(messages.serviceNotAvailable)}
                />
              ) : (
                <ChatConversationsList location={this.props.location} />
              )}
            </Chat>
          </>
        </React.Suspense>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: isChatServiceLoading(state),
    unavailable: !isWsAvailable(state),
    isChatLoaded:
      ownProps.match &&
      isChatConversationHistoryLoaded(state, ownProps.match.params.id),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    openConversation: openChatConversation,
  })(ChatPage)
);
