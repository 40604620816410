import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import ynConf from '../../conf';

const messages = defineMessages({
  altTextLogo: {
    id: 'AltText.ynLogo',
    defaultMessage: 'Logo YouNeed',
  },
  altTextAmbrosia: {
    id: 'AltText.ambrosiaThumbUp',
    defaultMessage:
      'Ambrosia, YouNeed vocal assistant and mascot, showing a thumb up',
  },
  info1: {
    id: 'ForgotPasswordPage.info1',
    defaultMessage: 'Insert your username or email address.',
  },
  info2: {
    id: 'ForgotPasswordPage.info2',
    defaultMessage: "We'll send you an email with all the instructions.",
  },
  backToLogin: {
    id: 'ForgotPasswordPage.backToLogin',
    defaultMessage: 'Go back to login',
  },
});

const ForgotPasswordPage = ({ intl: { formatMessage } }) => (
  <div className="bg-primary pt-5 h-100">
    <div
      className="bg-white rounded p-3 m-auto text-center"
      style={{
        width: '450px',
      }}
    >
      <img
        src={`${ynConf.publicFolderPath}/logo.png`}
        className="img-fluid"
        style={{
          height: '50px',
        }}
        alt={formatMessage(messages.altTextLogo)}
      />
      <img
        src={`${ynConf.publicFolderPath}/yn20.png`}
        className="img-fluid"
        style={{
          height: '250px',
        }}
        alt={formatMessage(messages.altTextAmbrosia)}
      />
      <h6>
        <strong>{formatMessage(messages.info1)}</strong>
      </h6>
      <h6>
        <strong>{formatMessage(messages.info2)}</strong>
      </h6>
      <ForgotPasswordForm />
      <Link to="/login">{formatMessage(messages.backToLogin)}</Link>
    </div>
  </div>
);

export default injectIntl(ForgotPasswordPage);
