import React, { Component } from 'react';
import VideocallGuestLogin from './VideocallGuestLogin';
import VideocallGuestExec from './VideocallGuestExec';

class VideocallGuestPage extends Component {
  state = {
    guestName: null,
    entered: false,
  };

  enterRoom = () => {
    this.setState({ entered: true });
  };

  changeName = (e) => {
    this.setState({ guestName: e.target.value });
  };

  render() {
    return (
      <>
        {this.state.entered ? (
          <VideocallGuestExec name={this.state.guestName} />
        ) : (
          <VideocallGuestLogin
            name={this.state.guestName}
            onChange={this.changeName}
            onSubmit={this.enterRoom}
          />
        )}
      </>
    );
  }
}

export default VideocallGuestPage;
