import React, { useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Progress, Collapse } from 'reactstrap';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  titleUploading: {
    id: 'FileboxUploaderToaster.titleUploading',
    defaultMessage: 'Uploading files',
  },
  titleUploaded: {
    id: 'FileboxUploaderToaster.titleUploaded',
    defaultMessage: 'Uploaded files',
  },
  defaultError: {
    id: 'FileboxUploaderToaster.defaultError',
    defaultMessage: 'There has been an error',
  },
  NETWORK_ERROR: {
    id: 'FileboxUploaderToaster.NETWORK_ERROR',
    defaultMessage: 'Network error',
  },
  EXISTING_FILE: {
    id: 'FileboxUploaderToaster.EXISTING_FILE',
    defaultMessage: 'Existing filename',
  },
  NO_SPACE_LEFT: {
    id: 'FileboxUploaderToaster.NO_SPACE_LEFT',
    defaultMessage: 'Not enough space left',
  },
  done: {
    id: 'FileboxUploaderToaster.done',
    defaultMessage: 'Done!',
  },
  504: {
    id: 'FileboxUploaderToaster.504',
    defaultMessage: 'Gateway timeout',
  },
});

const FileboxUploaderToaster = ({
  files,
  pending,
  error,
  intl: { formatMessage },
}) => {
  const [hidden, setHidden] = useState(false);
  return (
    <div>
      <div
        className="pt-1 pl-2"
        style={{
          backgroundColor: 'var(--yn-blue)',
          color: 'white',
          height: '30px',
        }}
      >
        <span>
          {pending
            ? formatMessage(messages.titleUploading)
            : formatMessage(messages.titleUploaded)}
        </span>
        {pending && !error && (
          <span
            className="Toastify__close-button Toastify__close-button--default"
            onClick={() => setHidden(!hidden)}
          >
            {hidden ? (
              <Icon
                width={12}
                height={12}
                name="arrow-up-8-filled"
                color="white"
                style={{ top: '3px', lefth: '-1px' }}
              />
            ) : (
              <Icon
                width={12}
                height={12}
                name="arrow-down-8-filled"
                color="white"
                style={{ top: '3px', lefth: '-1px' }}
              />
            )}
          </span>
        )}
      </div>
      <Collapse isOpen={!hidden}>
        {files &&
          files.map((f) => (
            <div className="p-2" key={f.data.filename}>
              <div className="row">
                <div className="col text-break">{f.data.filename}</div>
              </div>
              {f.progress && (
                <Progress
                  className="mt-1"
                  value={f.progress === -1 ? 100 : f.progress}
                  color={f.error ? 'danger' : null}
                  animated={f.progress >= 0 && f.progress <= 100 && !f.loaded}
                >
                  {f.error
                    ? messages[f.error]
                      ? formatMessage(messages[f.error])
                      : formatMessage(messages.defaultError)
                    : f.loaded
                    ? formatMessage(messages.done)
                    : `${f.progress}%`}
                </Progress>
              )}
              {/*f.error && (
              <div style={{ color: 'red' }}>
                {messages[f.error]
                  ? formatMessage(messages[f.error])
                  : formatMessage(messages.defaultError)}
              </div>
                )*/}
            </div>
          ))}
      </Collapse>
    </div>
  );
};
FileboxUploaderToaster.propTypes = {
  files: PropTypes.array.isRequired,
  pending: PropTypes.bool,
  error: PropTypes.bool,
};
export default injectIntl(FileboxUploaderToaster);
