import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import history from '../history';
import LinkButton from './LinkButton';
import Icon from './icons/Icon';

const messages = defineMessages({
  text: {
    id: 'VideocallLink.text',
    defaultMessage: 'Videocall link',
  },
});

class VideocallLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      error: '',
    };
  }

  onClick = () => {
    history.push(
      this.props.url.substring(this.props.url.indexOf('/videocall/exec'))
    );
  };

  render() {
    const {
      className,
      style,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <LinkButton
          className={`${className}  pl-0`}
          style={style}
          onClick={this.onClick}
        >
          {formatMessage(messages.text)}
        </LinkButton>
        <Icon
          name="video-camera-filled"
          width={20}
          height={20}
          color="var(--yn-blue)"
          style={{ top: '4px' }}
          clickable={false}
        />
      </>
    );
  }
}

VideocallLink.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default injectIntl(VideocallLink);
