import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { fetchContactsRequest } from '../../js/contacts/actions';
import {
  getContactsSelectOptions,
  getContactsById,
  getContactsSelectOptionsWithVatFiscalCode,
} from '../../js/contacts/selectors';
import Select2 from './Select2';

const PAGE_SIZE = 2000;

const messages = defineMessages({
  placeholder: {
    id: 'ContactsSelect.placeholder',
    defaultMessage: 'Select a contact',
  },
  noOptionsMessage: {
    id: 'ContactsSelect.noOptionsMessage',
    defaultMessage: 'No contacts found',
  },
});

class ContactsSelect extends Component {
  componentDidMount() {
    const { pageSize, fetch } = this.props;
    const params = {
      page: 0,
      pageSize: pageSize || PAGE_SIZE,
      public: this.props.public,
      private: this.props.private,
    };

    fetch(params);
  }

  handleInputChange = (name) => {
    const { pageSize, fetch } = this.props;
    const params = {
      page: 0,
      pageSize: pageSize || PAGE_SIZE,
      public: this.props.public,
      private: this.props.private,
      name,
    };
    fetch(params);
  };

  handleSelect = (selected) => {
    this.setState(
      { selected: selected || (this.props.isMulti ? [] : null) },
      () => {
        const adjustSselected = () => {
          if (this.props.isMulti)
            return this.state.selected.map((o) => o.value);
          if (this.state.selected) return this.state.selected.value;
          return null;
        };
        this.props.onSelect(adjustSselected());
      }
    );
  };

  arrangeValue = () => {
    if (!this.props.selected && this.props.isMulti) return [];
    if (!this.props.selected && !this.props.isMulti) return null;
    if (this.props.isMulti) {
      return this.props.selected.map((id) => ({
        value: id,
        label: this.props.contacts[id] && this.props.contacts[id].fullname,
      }));
    }
    return {
      value: this.props.selected,
      label:
        this.props.contacts[this.props.selected] &&
        this.props.contacts[this.props.selected].fullname,
    };
  };

  render() {
    const {
      options,
      excludedIds,
      error,
      isMulti,
      isClearable,
      placeholder,
      intl: { formatMessage },
    } = this.props;

    return (
      <Select2
        id="contacts"
        name="contacts"
        isMulti={isMulti}
        isClearable={isClearable}
        pageSize={PAGE_SIZE}
        onInputChange={(e) => this.handleInputChange(e)}
        value={this.arrangeValue()}
        onChange={this.handleSelect}
        options={
          excludedIds && excludedIds.length > 0
            ? options.filter((item) => excludedIds.indexOf(item.value) < 0)
            : options
        }
        error={error}
        noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
        placeholder={placeholder || formatMessage(messages.placeholder)}
      />
    );
  }
}

ContactsSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMulti: PropTypes.bool,
  private: PropTypes.bool,
  public: PropTypes.bool,
  isClearable: PropTypes.bool,
  pageSize: PropTypes.number,
  // eslint-disable-next-line react/no-unused-prop-types
  showVatFiscalcode: PropTypes.bool,
  placeholder: PropTypes.string,
};

ContactsSelect.defaultProps = {
  options: [],
  selected: null,
  error: '',
  isMulti: true,
  private: true,
  public: true,
  isClearable: false,
  pageSize: null,
  showVatFiscalcode: false,
  placeholder: '',
};

function mapStateToProps(state, ownProps) {
  return {
    contacts: getContactsById(state),
    options: ownProps.showVatFiscalcode
      ? getContactsSelectOptionsWithVatFiscalCode(state)
      : getContactsSelectOptions(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { fetch: fetchContactsRequest })(ContactsSelect)
);
