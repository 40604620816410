import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const LogoForm = React.lazy(() => import('./LogoForm'));
const messages = defineMessages({
  title: {
    id: 'Logo.title',
    defaultMessage: 'Logo',
  },
});

const LogoPage = ({ intl: { formatMessage } }) => {
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right">
              <HelpButton fileName="logo-management" />
            </div>
            <div className="yn-section-scrolling">
              <LogoForm />
            </div>
          </>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default injectIntl(LogoPage);
