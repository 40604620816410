import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ShareCalendar = React.lazy(() => import('./ShareCalendar'));

const CalendarManageSharePage = () => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <ShareCalendar />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarManageSharePage;
