import keyBy from 'lodash.keyby';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  CLEAR_PHONE_EXTERNAL_URL_ERRORS,
  DELETE_PHONE_EXTERNAL_URL_FAILURE,
  DELETE_PHONE_EXTERNAL_URL_REQUEST,
  DELETE_PHONE_EXTERNAL_URL_SUCCESS,
  EDIT_PHONE_EXTERNAL_URL_FAILURE,
  EDIT_PHONE_EXTERNAL_URL_REQUEST,
  EDIT_PHONE_EXTERNAL_URL_SUCCESS,
  FETCH_PHONE_EXTERNAL_URL_FAILURE,
  FETCH_PHONE_EXTERNAL_URL_REQUEST,
  FETCH_PHONE_EXTERNAL_URL_SUCCESS,
  INSERT_PHONE_EXTERNAL_URL_FAILURE,
  INSERT_PHONE_EXTERNAL_URL_REQUEST,
  INSERT_PHONE_EXTERNAL_URL_SUCCESS,
} from './types';

const initialState = {
  fetchExternalUrlLoaded: true,
  fetchExternalUrlError: false,
  externalUrlsById: {},
  externalUrlsAllIds: [],
  errorDeleteExternalUrl: false,
  insertExternalUrlLoaded: true,
  insertExternalUrlError: null,
  loadedDeleteExternalUrl: null,
};

export default function externalUrl(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_PHONE_EXTERNAL_URL_REQUEST:
      return {
        ...state,
        fetchExternalUrlLoaded: false,
        fetchExternalUrlError: false,
      };
    case FETCH_PHONE_EXTERNAL_URL_SUCCESS:
      return {
        ...state,
        fetchExternalUrlLoaded: true,
        fetchExternalUrlError: false,
        externalUrlsById: keyBy(action.data, (type) => type.id),
        externalUrlsAllIds: action.data.map((type) => type.id),
        errorDeleteExternalUrl: false,
      };
    case FETCH_PHONE_EXTERNAL_URL_FAILURE:
      return {
        ...state,
        fetchExternalUrlLoaded: true,
        fetchExternalUrlError: true,
      };
    case DELETE_PHONE_EXTERNAL_URL_REQUEST: {
      return {
        ...state,
        loadedDeleteExternalUrl: action.id,
        errorDeleteExternalUrl: false,
      };
    }
    case DELETE_PHONE_EXTERNAL_URL_SUCCESS:
      return {
        ...state,
        loadedDeleteExternalUrl: null,
        errorDeleteExternalUrl: false,
      };
    case DELETE_PHONE_EXTERNAL_URL_FAILURE:
      return {
        ...state,
        loadedDeleteExternalUrl: null,
        errorDeleteExternalUrl: action.error,
      };
    case EDIT_PHONE_EXTERNAL_URL_REQUEST:
    case INSERT_PHONE_EXTERNAL_URL_REQUEST:
      return {
        ...state,
        insertExternalUrlLoaded: false,
        insertExternalUrlError: null,
      };
    case EDIT_PHONE_EXTERNAL_URL_SUCCESS:
    case INSERT_PHONE_EXTERNAL_URL_SUCCESS:
      return {
        ...state,
        insertExternalUrlLoaded: true,
        insertExternalUrlError: null,
      };
    case EDIT_PHONE_EXTERNAL_URL_FAILURE:
    case INSERT_PHONE_EXTERNAL_URL_FAILURE:
      return {
        ...state,
        insertExternalUrlLoaded: true,
        insertExternalUrlError: action.error,
      };
    case CLEAR_PHONE_EXTERNAL_URL_ERRORS:
      return {
        ...state,
        insertExternalUrlLoaded: true,
        insertExternalUrlError: null,
      };
    default:
      return state;
  }
}
