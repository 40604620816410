export const FETCH_PINNED_GROUPS_REQUEST = 'FETCH_PINNED_GROUPS_REQUEST';
export const FETCH_PINNED_GROUPS_SUCCESS = 'FETCH_PINNED_GROUPS_SUCCESS';
export const FETCH_PINNED_GROUPS_FAILURE = 'FETCH_PINNED_GROUPS_FAILURE';
export const FETCH_ADMIN_GROUPS_REQUEST = 'FETCH_ADMIN_GROUPS_REQUEST';
export const FETCH_ADMIN_GROUPS_FAILURE = 'FETCH_ADMIN_GROUPS_FAILURE';
export const FETCH_ADMIN_GROUPS_SUCCESS = 'FETCH_ADMIN_GROUPS_SUCCESS';
export const FETCH_GROUP_REQUEST = 'FETCH_GROUP_REQUEST';
export const FETCH_GROUP_FAILURE = 'FETCH_GROUP_FAILURE';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_MEMBERS_REQUEST = 'FETCH_GROUP_MEMBERS_REQUEST';
export const FETCH_GROUP_MEMBERS_FAILURE = 'FETCH_GROUP_MEMBERS_FAILURE';
export const FETCH_GROUP_MEMBERS_SUCCESS = 'FETCH_GROUP_MEMBERS_SUCCESS';
export const SAVE_GROUP_REQUEST = 'SAVE_GROUP_REQUEST';
export const SAVE_GROUP_FAILURE = 'SAVE_GROUP_FAILURE';
export const SAVE_GROUP_SUCCESS = 'SAVE_GROUP_SUCCESS';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const SET_GROUPS_FILTER = 'SET_GROUPS_FILTER';
export const SET_PINNED_GROUPS_FILTER = 'SET_PINNED_GROUPS_FILTER';
export const UPDATE_GROUP_SETTINGS_REQUEST = 'UPDATE_GROUP_SETTINGS_REQUEST';
export const UPDATE_GROUP_SETTINGS_SUCCESS = 'UPDATE_GROUP_SETTINGS_SUCCESS';
export const UPDATE_GROUP_SETTINGS_FAILURE = 'UPDATE_GROUP_SETTINGS_FAILURE';
export const UPDATE_PINNED_GROUPS_ORDER_REQUEST =
  'UPDATE_PINNED_GROUPS_ORDER_REQUEST';
export const UPDATE_PINNED_GROUPS_ORDER_SUCCESS =
  'UPDATE_PINNED_GROUPS_ORDER_SUCCESS';
export const UPDATE_PINNED_GROUPS_ORDER_FAILURE =
  'UPDATE_PINNED_GROUPS_ORDER_FAILURE';
export const UPDATE_PINNED_GROUP_SETTINGS_REQUEST =
  'UPDATE_PINNED_GROUP_SETTINGS_REQUEST';
export const UPDATE_PINNED_GROUP_SETTINGS_SUCCESS =
  'UPDATE_PINNED_GROUP_SETTINGS_SUCCESS';
export const UPDATE_PINNED_GROUP_SETTINGS_FAILURE =
  'UPDATE_PINNED_GROUP_SETTINGS_FAILURE';
export const UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST =
  'UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST';
export const UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS =
  'UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS';
export const UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE =
  'UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE';
export const SET_GROUPS_CURRENT_PAGE = 'SET_GROUPS_CURRENT_PAGE';
export const GROUP_INSERT_NOTIFICATION = 'GROUP_INSERT_NOTIFICATION';
export const GROUP_UPDATE_NOTIFICATION = 'GROUP_UPDATE_NOTIFICATION';
export const GROUP_DELETE_NOTIFICATION = 'GROUP_DELETE_NOTIFICATION';
export const UPDATE_CHAT_GROUP_REQUEST = 'UPDATE_CHAT_GROUP_REQUEST';
export const UPDATE_CHAT_GROUP_SUCCESS = 'UPDATE_CHAT_GROUP_SUCCESS';
export const UPDATE_CHAT_GROUP_FAILURE = 'UPDATE_CHAT_GROUP_FAILURE';
export const CREATE_CHAT_GROUP_REQUEST = 'CREATE_CHAT_GROUP_REQUEST';
export const CREATE_CHAT_GROUP_FAILURE = 'CREATE_CHAT_GROUP_FAILURE';
export const CREATE_CHAT_GROUP_SUCCESS = 'CREATE_CHAT_GROUP_SUCCESS';
export const SET_FAVORITES_GROUP_SCROLL = 'SET_FAVORITES_GROUP_SCROLL';
