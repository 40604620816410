import React from 'react';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { logoutRequest } from '../../../js/auth/actions';
import UserAvatar from '../../../components/UserAvatar';
import PhoneStatusButton from './PhoneStatusButton';
import { getMePhoneStatus } from '../../../js/phone/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { fetchHelpRequest, toggleShowHelp } from '../../../js/me/actions';
import {
  hasAdminPanelGrants,
  isHelpVisible,
  isMyPuncherEnabled,
} from '../../../js/me/selectors';
import { getOwnViewedName } from '../../../js/settings/selectors';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import { SettingsEnums } from '../../../js/settings/SettingsUtils';
import NotificationBadge from '../../../components/NotificationBadge';
import { getWebNotificationsPunchingNumber } from '../../../js/notifications/selectors';
import ynConf from '../../../conf';
import SetStatusModal from './SetStatusModal';
import HelpModal from '../../../components/HelpModal';
import VideocallStatusButton from './VideocallStatusButton';

const messages = defineMessages({
  extension: {
    id: 'SelfDropdown.extension',
    defaultMessage: 'Extension',
  },
  changeStatusTitle: {
    id: 'SelfDropdown.changeStatusTitle',
    defaultMessage: 'Change status',
  },
  personalSettings: {
    id: 'SelfDropdown.personalSettings',
    defaultMessage: 'Personal settings',
  },
  notificationsSettings: {
    id: 'SelfDropdown.notificationsSettings',
    defaultMessage: 'Notifications settings',
  },
  preferences: {
    id: 'SelfDropdown.preferences',
    defaultMessage: 'Preferences',
  },
  adminPanel: {
    id: 'SelfDropdown.adminPanel',
    defaultMessage: 'Admin panel',
  },
  showHelp: {
    id: 'SelfDropdown.showHelp',
    defaultMessage: 'Show help',
  },
  hideHelp: {
    id: 'SelfDropdown.hideHelp',
    defaultMessage: 'Hide help',
  },
  logout: {
    id: 'SelfDropdown.logout',
    defaultMessage: 'Logout',
  },
  puncher: {
    id: 'SelfDropdown.puncher',
    defaultMessage: 'Puncher',
  },
  status: {
    id: 'SelfDropdown.status',
    defaultMessage: 'Set status',
  },
  downloadApps: {
    id: 'SelfDropdown.downloadApps',
    defaultMessage: 'Download apps',
  },
  chromeExtension: {
    id: 'SelfDropdown.chromeExtension',
    defaultMessage: 'Chrome extension',
  },
  showNews: {
    id: 'SelfDropdown.showNews',
    defaultMessage: 'Latest news',
  },
});

class SelfDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatusModal: false,
      showNewsModal: false,
      showAppModal: false,
    };
  }

  toggleSetStatusModal = () => {
    this.setState({
      showStatusModal: !this.state.showStatusModal,
    });
  };

  toggleNewsModal = () => {
    this.setState({
      showNewsModal: !this.state.showNewsModal,
    });
  };

  toggleAppModal = () => {
    this.setState({
      showAppModal: !this.state.showAppModal,
    });
  };

  openAdmin = () => {
    const { ipcRenderer } = window;
    if (ipcRenderer) {
      ipcRenderer.send('open-admin-panel');
    } else {
      window.open(`${ynConf.clientBaseUrl}/admin/`, '_blank');
    }
  };

  render() {
    const {
      me,
      logout,
      phoneStatus,
      helpVisible,
      toggleHelp,
      ownViewedName,
      puncherEnabled,
      punchingNotification,
      hasAdminGrants,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {phoneStatus !== PhoneEnums.UserStatuses.nophone && (
          <PhoneStatusButton />
        )}
        <VideocallStatusButton />
        <UncontrolledDropdown className="yn-dropdown">
          <DropdownToggle nav caret>
            <UserAvatar
              size="md"
              alt={`${me.fullname} avatar`}
              src={me.avatar}
              id={me.id}
              clickable
              className="mr-1"
              showStatus
              statusPosition={{ bottom: -6, left: -6 }}
            />
            {punchingNotification && punchingNotification > 0 ? (
              <NotificationBadge
                number={punchingNotification}
                top="0.5rem"
                left="2rem"
              />
            ) : null}
            {ownViewedName === SettingsEnums.OwnViewedNameTypes.FULLNAME
              ? me.fullname
              : ownViewedName === SettingsEnums.OwnViewedNameTypes.USERNAME
              ? me.username
              : `${formatMessage(messages.extension)} ${
                  me.mainExtensionNumber
                }` || (
                  <span
                    className="d-inline-block"
                    style={{ width: '3rem', height: '1rem' }}
                  />
                )}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>
              <strong>
                {ownViewedName === SettingsEnums.OwnViewedNameTypes.FULLNAME
                  ? me.username
                  : me.fullname}
              </strong>
              <div>
                {ownViewedName === SettingsEnums.OwnViewedNameTypes.EXTENSION
                  ? me.username
                  : `${formatMessage(messages.extension)} ${
                      me.mainExtensionNumber
                    }`}
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={Link} to="/settings/personal">
              {formatMessage(messages.personalSettings)}
            </DropdownItem>
            <DropdownItem tag={Link} to="/settings/notifications">
              {formatMessage(messages.notificationsSettings)}
            </DropdownItem>
            <DropdownItem tag={Link} to="/settings/platform/personal">
              {formatMessage(messages.preferences)}
            </DropdownItem>
            <DropdownItem onClick={() => this.toggleSetStatusModal()}>
              {formatMessage(messages.status)}
            </DropdownItem>
            {puncherEnabled && (
              <>
                <DropdownItem divider />
                <div className="position-relative">
                  {punchingNotification && punchingNotification > 0 ? (
                    <NotificationBadge
                      number={punchingNotification}
                      top="0.3rem"
                      right="1rem"
                    />
                  ) : null}
                  <DropdownItem tag={Link} to="/puncher/punchings">
                    {formatMessage(messages.puncher)}
                  </DropdownItem>
                </div>
              </>
            )}
            {hasAdminGrants && (
              <>
                <DropdownItem divider />
                <DropdownItem tag="button" onClick={this.openAdmin}>
                  {formatMessage(messages.adminPanel)}
                </DropdownItem>
              </>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={() => this.toggleAppModal()}>
              {formatMessage(messages.downloadApps)}
            </DropdownItem>
            <DropdownItem style={{ paddingRight: 0, paddingLeft: 0 }}>
              <a
                href="https://chrome.google.com/webstore/detail/youneed-phone/dfbhojponfcibnmoilkppklklaaibddj?hl=it"
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage(messages.chromeExtension)}
              </a>
            </DropdownItem>
            <DropdownItem>
              <span onClick={() => toggleHelp()}>
                {helpVisible
                  ? formatMessage(messages.hideHelp)
                  : formatMessage(messages.showHelp)}
              </span>
            </DropdownItem>
            <DropdownItem onClick={() => this.toggleNewsModal()}>
              {formatMessage(messages.showNews)}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => logout()}>
              {formatMessage(messages.logout)}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <SetStatusModal
          isOpen={this.state.showStatusModal}
          toggle={this.toggleSetStatusModal}
        />
        <HelpModal
          title=" "
          fileName="latest-news"
          isOpen={this.state.showNewsModal}
          onToggle={this.toggleNewsModal}
        />
        <HelpModal
          title=" "
          fileName="app-page"
          isOpen={this.state.showAppModal}
          onToggle={this.toggleAppModal}
        />
      </>
    );
  }
}

SelfDropdown.propTypes = {};

function mapStateToProps(state) {
  return {
    me: state.me,
    html: state.me.help,
    loading: !state.me.loaded,
    phoneStatus: getMePhoneStatus(state),
    hasAdminGrants: hasAdminPanelGrants(state),
    helpVisible: isHelpVisible(state),
    ownViewedName: getOwnViewedName(state),
    puncherEnabled: isPuncherEnabled(state) && isMyPuncherEnabled(state),
    punchingNotification: getWebNotificationsPunchingNumber(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    logout: logoutRequest,
    openModal: fetchHelpRequest,
    toggleHelp: toggleShowHelp,
  })(SelfDropdown)
);
