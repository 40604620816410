import React from 'react';
import Wakeup from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const WakeupArchive = React.lazy(() => import('./WakeupArchive'));

const WakeupArchivePage = (props) => (
  <Wakeup scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <WakeupArchive {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wakeup>
);

export default WakeupArchivePage;
