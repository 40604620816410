import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  CHAT_REPLY_TEXTAREA_HEIGHTS,
  CHAT_TEXTAREA_HEIGHTS,
} from '../../../js/chat/ChatUtils';
import { getChatMessageById } from '../../../js/chat/selectors';
import { getUserById } from '../../../js/users/selectors';
import { getMeId } from '../../../js/me/selectors';
import { removeReplyMessageRequest } from '../../../js/chat/actions';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  cancel: {
    id: 'ReplyMessageArea.tooltip.cancel',
    defaultMessage: 'Cancel',
  },
  altImage: {
    id: 'ReplyMessageArea.label.altImage',
    defaultMessage: 'File preview',
  },
});

class ReplyMessageArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      offsetWidth: null,
      scrollWidth: null,
    };
    this.targetRef = React.createRef();
  }

  componentDidMount() {
    this.updateWidth();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateWidth(prevState);
  }

  updateWidth = (prevState) => {
    if (
      this.targetRef.current &&
      this.targetRef.current.offsetWidth &&
      (!prevState ||
        this.targetRef.current.offsetWidth !== prevState.offsetWidth)
    ) {
      this.setState({ offsetWidth: this.targetRef.current.offsetWidth });
    }
    if (
      this.targetRef.current &&
      this.targetRef.current.scrollWidth &&
      (!prevState ||
        this.targetRef.current.scrollWidth !== prevState.scrollWidth)
    ) {
      this.setState({ scrollWidth: this.targetRef.current.scrollWidth });
    }
  };

  removeReplyMessage = () => {
    const { conversationId, removeReplyMessage } = this.props;
    const params = {
      conversationId,
    };
    removeReplyMessage(params);
  };

  toggleExpandedArea = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    const {
      intl: { formatMessage },
      size,
      meId,
      replyingMessage,
      sender,
      replyTo,
      expandable,
    } = this.props;

    const message = replyTo ? replyTo.message : null;

    const bgColor = message
      ? 'white'
      : replyingMessage.senderId === meId
      ? 'var(--yn-blue-lighter)'
      : 'var(--yn-gray-200)';

    const repliedText = message && message.text ? message.text : null;

    const replyingText =
      replyingMessage && replyingMessage.text ? replyingMessage.text : null;

    const parsedText = message ? repliedText : replyingText;

    const arrangedFile =
      message && message.file && message.file.name
        ? message.file
        : replyingMessage && replyingMessage.file && replyingMessage.file.name
        ? replyingMessage.file
        : null;

    const fileName = arrangedFile ? arrangedFile.name : null;

    return (
      <div
        className="border rounded"
        style={{
          position: message ? 'static' : 'absolute',
          left: '5px',
          right: '4px',
          bottom: CHAT_TEXTAREA_HEIGHTS[size] + 2,
          height: this.state.expanded
            ? null
            : CHAT_REPLY_TEXTAREA_HEIGHTS[size],
          backgroundColor: bgColor,
          fontSize: '0.8rem',
        }}
      >
        <div
          className="p-1"
          style={{
            borderBottom: '1px solid var(--yn-gray-400)',
            fontSize: '0.8rem',
          }}
        >
          {sender ? sender.fullname : replyingMessage.senderFullname}
          <span
            className="mr-2"
            style={{ float: 'right' }}
            onClick={this.removeReplyMessage}
          >
            {!message && (
              <Icon
                name="remove-1"
                color="var(--yn-blue-dark)"
                width={10}
                height={10}
              >
                {formatMessage(messages.cancel)}
              </Icon>
            )}
          </span>
        </div>
        {(replyingMessage && replyingMessage.text) ||
        (message && message.text) ? (
          <>
            {this.state.expanded ? (
              <div
                className="p-1"
                style={{
                  wordBreak: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {parsedText}
                {expandable && (
                  <div
                    className="float-right position-relative mr-1"
                    onClick={this.toggleExpandedArea}
                    style={{ cursor: 'pointer' }}
                  >
                    [-]
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="p-1 text-truncate" ref={this.targetRef}>
                  {expandable &&
                    this.state.offsetWidth < this.state.scrollWidth && (
                      <div
                        className="float-right position-relative mr-1"
                        onClick={this.toggleExpandedArea}
                        style={{ cursor: 'pointer' }}
                      >
                        [+]
                      </div>
                    )}
                  {parsedText}
                </div>
              </>
            )}
          </>
        ) : (message && message.file && !message.file.thumbnail) ||
          (replyingMessage &&
            replyingMessage.file &&
            !replyingMessage.file.thumbnail) ? (
          <>
            <Icon
              name="file-text-document"
              className="m-1"
              width={14}
              height={14}
              color="var(--yn-blue-dark)"
              style={{ float: 'left' }}
              clickable={false}
            />
            <div className="p-1 text-truncate">{fileName}</div>
          </>
        ) : (
          <div className="p-1" style={{ wordBreak: 'break-word' }}>
            <Icon
              name="camera-2"
              className="mt-2 mx-1"
              width={14}
              height={14}
              color="var(--yn-blue-dark)"
              style={{ float: 'left' }}
              clickable={false}
            />
            <img
              className="img-fluid"
              src={`data:image/png;base64, ${
                message && message.file
                  ? message.file.thumbnail
                  : replyingMessage && replyingMessage.file
                  ? replyingMessage.file.thumbnail
                  : ''
              }`}
              alt={formatMessage(messages.altImage)}
              style={{ height: `30px` }}
            />
          </div>
        )}
      </div>
    );
  }
}

ReplyMessageArea.propTypes = {
  expandable: PropTypes.bool,
  replyTo: PropTypes.object,
  size: PropTypes.string,
};

ReplyMessageArea.defaultProps = {
  expandable: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    meId: getMeId(state),
    replyingMessage: getChatMessageById(state, ownProps.repliedMessageId),
    removeReplyMessage: removeReplyMessageRequest(
      state,
      ownProps.conversationId
    ),
    sender: ownProps.replyTo
      ? getUserById(state, ownProps.replyTo.senderId)
      : ownProps.replyingMessage &&
        ownProps.replyingMessage.senderId &&
        getUserById(state, ownProps.replyingMessage.senderId),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    removeReplyMessage: removeReplyMessageRequest,
  })(ReplyMessageArea)
);
