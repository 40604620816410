import React from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Navbar, Nav, NavItem } from 'reactstrap';
import Icon from './icons/Icon';

const messages = defineMessages({
  ownerEnter: {
    id: 'InterlocutorConferenceToolbar.tooltip.ownerEnter',
    defaultMessage: 'Enter the conference',
  },
  ownerExit: {
    id: 'InterlocutorConferenceToolbar.tooltip.ownerExit',
    defaultMessage: 'Exit from conference',
  },
  otherExit: {
    id: 'InterlocutorConferenceToolbar.tooltip.otherExit',
    defaultMessage: 'Exclude from conference',
  },
});

const InterlocutorConferenceToolbar = ({
  interlocutor,
  onHangup,
  onJoin,
  intl: { formatMessage },
}) => (
  <Navbar color="faded" light expand="sm" className="p-0">
    <Nav navbar>
      <NavItem>
        <span className="mr-1">{interlocutor.number || '...'}</span>
      </NavItem>
      {interlocutor.owner && (
        <NavItem>
          <span className="mr-2" onClick={interlocutor.in ? onHangup : onJoin}>
            <Icon
              name="phone-ringing-filled"
              color={interlocutor.in ? 'var(--red)' : 'var(--green)'}
              width={20}
              height={20}
            >
              {formatMessage(
                interlocutor.in ? messages.ownerExit : messages.ownerEnter
              )}
            </Icon>
          </span>
        </NavItem>
      )}
      {!interlocutor.owner && (
        <NavItem>
          <span className="mr-2" onClick={onHangup}>
            <Icon
              name="phone-ringing-filled"
              color="var(--red)"
              width={20}
              height={20}
            >
              {formatMessage(messages.otherExit)}
            </Icon>
          </span>
        </NavItem>
      )}
    </Nav>
  </Navbar>
);

InterlocutorConferenceToolbar.propTypes = {
  interlocutor: PropTypes.object.isRequired,
};

export default injectIntl(InterlocutorConferenceToolbar);
