/* eslint-disable no-unused-vars */
import $ from 'jquery';
import { Promise } from 'bluebird';
import moment from 'moment';
import { PhoneEnums } from './PhoneUtils';
import { PbxSettings } from './PbxSettingsUtils';
import { PhoneRulesEnums } from '../phoneRules/PhoneRulesUtils';
import { AbilisCommands } from './AbilisCommands';
import Utils from '../lib/utils';
import { request } from '../request';
import api from '../api';

/**
 * @constructor
 */
export class Abilis {
  constructor(data) {
    this.username = data.username;
    this.password = data.password;
    this.number = data.number;
    this.pbxConfig = data.pbxConfig;
    this.isSIP = data.isSIP;
    this.url = `https://${data.url}`;
    this.pbxLen = data.pbxLen;
    this.pbxLine = data.pbxLine;
    this.queuePrefixes = data.queuePrefixes
      ? data.queuePrefixes.split(',')
      : [];
    this.parkPrefix = data.parkPrefix;
    this.extensionsLength = data.extensionsLength;
    this.data$ = null;
    this.sessionId = null;
    this.operator = null;
    this.abilisType = null;
    this.newRuleUser = null;
    this.newRuleOwnerType = null;
    this.newRuleOwnerId = null;
    this.command = new AbilisCommands({
      url: this.url,
      username: this.username,
    });
  }

  // TO REMOVE
  setUpdatedVersion = () => {};

  login = (timeout, username, password, url) =>
    this.command
      .login(password || this.password, timeout, username, url)
      .then((res) => {
        // TODO invalid credentials management
        let responseStatus;
        if (
          res.status === undefined ||
          !res.status ||
          res.status < 200 ||
          res.status >= 400
        ) {
          responseStatus = PhoneEnums.CallsActionsResults.ko;
        } else {
          responseStatus = PhoneEnums.CallsActionsResults.ok;
        }
        return responseStatus;
      })
      .catch((err) => PhoneEnums.CallsActionsResults.ko);

  logout = () =>
    this.command
      .logout()
      .then((res) => res)
      .catch((err) => err);

  parseSingleExtensionData = (user, init) => {
    const user$ = $(user);
    if (user$.attr('user') === this.username) {
      this.command.setInterface(user$.attr('interface'));
    }
    const userData = {
      interface: user$.attr('interface'),
      username: user$.attr('user'),
      number: user$.children('Num').text(),
      status: user$.children('State').text(),
      dnd:
        user$.children('Action') && user$.children('Action').text() === 'DND'
          ? PhoneEnums.DndStatus.ON
          : PhoneEnums.DndStatus.OFF,
    };
    const callsData = [];
    if (!init) {
      user$.find('Call').each((index, call) => {
        const call$ = $(call);
        let status = call$.children('State').text();

        // status translate
        if (status === 'INACTIVE' || status === 'DISCONNECTING') {
          status = PhoneEnums.CallsStatuses.none;
        } else if (status === PhoneEnums.CallsStatuses.active) {
          status = PhoneEnums.CallsStatuses.alerting;
        }
        // fix ctip
        if (userData.interface === PhoneEnums.Interfaces.CTIP) {
          if (userData.status === PhoneEnums.UserStatuses.ringing) {
            status = PhoneEnums.UserStatuses.alerting;
          }
        }

        const callData = {
          interface: userData.interface,
          username: userData.username,
          status,
          callid: null,
          calling: userData.number,
          called: call$.children('CdoNum').text(),
          direction:
            status === PhoneEnums.CallsStatuses.alerting
              ? PhoneEnums.Direction.In
              : PhoneEnums.Direction.Out,
          timestamp: new Date(),
        };
        callsData.push(callData);
      });
    }
    return {
      user: userData,
      calls: callsData,
    };
  };

  parseSingleCallData = (call) => {
    const call$ = $(call);
    const callData = {
      username: call$.attr('user'),
      status:
        call$.children('State').text() === 'DISCONNECTING'
          ? PhoneEnums.UserStatuses.calling
          : call$.children('State').text(),
      callid: call$.children('CallID').text(),
      calling: call$.children('Calling').text(),
      called: call$.children('Called').text(),
      direction: call$.children('Direction').text(),
      conference: call$.children('ConferenceUUID').text(),
      timestamp: new Date(
        new Date().getTime() - (call$.children('Duration').text() || 0) * 1000
      ),
    };
    return callData;
  };

  parseSingleParkData = (queue) => this.parseSingleQueueData(queue);

  parseSingleQueueData = (queue) => {
    const queue$ = $(queue);
    const queueData = {
      id: queue$.children('Uuid').text(),
      status: queue$.children('State').text(),
      position: queue$.children('Position').text(),
      timestamp: new Date(
        queue$
          .children('AddedTimestamp')
          .text()
          .replace(
            /(\d{4})(\d{2})(\d{2})-(\d{2})(\d{2})(\d{2})/,
            '$1-$2-$3T$4:$5:$6'
          )
      ),
      calling: queue$.children('Calling').text(),
      called: queue$.children('Called').text(),
      disaService: queue$.children('DisaService').text(),
      disaUser: queue$.children('DisaUser').text(),
    };
    // show only incoming queue or park queue
    if (queueData.called.indexOf(PbxSettings.PREFIX_PARK) === 0) {
      let called = queueData.called.substr(PbxSettings.PREFIX_PARK.length);
      called = called.substr(0, called.length - this.extensionsLength);
      const owner = queueData.called.substr(
        queueData.called.length - this.extensionsLength
      );
      return {
        type: PhoneEnums.QueueType.PARK,
        data: {
          ...queueData,
          called,
          owner,
        },
      };
    }
    for (var i in this.queuePrefixes) {
      if (queueData.called.indexOf(this.queuePrefixes[i]) === 0) {
        return {
          type: PhoneEnums.QueueType.QUEUE,
          data: {
            ...queueData,
            called: queueData.called.substring(this.queuePrefixes[i].length),
            prefix: this.queuePrefixes[i],
          },
        };
      }
    }
    return {
      data: null,
    };
  };

  parseStatus = (data$, init) => {
    // parse users
    const usersStatus = [];
    let callsStatus = [];
    data$.find('UserRecord').each((index, user) => {
      const result = this.parseSingleExtensionData(user, init);
      usersStatus.push(result.user);
      if (result.calls.length > 0)
        callsStatus = callsStatus.concat(result.calls);
    });
    // parse calls
    data$.find('CallRecord').each((index, call) => {
      callsStatus.push(this.parseSingleCallData(call));
    });
    // parse queues
    const queuesStatus = [];
    const parksStatus = [];
    data$.find('QueueRecord').each((index, queue) => {
      const queueData = this.parseSingleQueueData(queue);
      if (queueData.data) {
        if (queueData.type === PhoneEnums.QueueType.PARK) {
          parksStatus.push(queueData.data);
        } else if (queueData.type === PhoneEnums.QueueType.QUEUE) {
          queuesStatus.push(queueData.data);
        }
      }
    });
    return {
      users: usersStatus,
      calls: callsStatus,
      queues: queuesStatus,
      parks: parksStatus,
    };
  };

  getInitialStatus = () =>
    this.command
      .getInitialStatus()
      .then((response) => {
        // retrieve data from the response
        this.data$ = $(response.data);
        this.sessionId = this.data$.find('SesId').text();
        const userInfo = this.data$.find('UserInfo');
        this.operator = userInfo.attr('U');
        this.abilisType = userInfo.attr('A');
        this.command.setOperator(this.operator);
        this.command.setAbilisType(this.abilisType);
        // parse the status of pbx
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          ...this.parseStatus(this.data$, true),
        };
      })
      .catch((error) => ({ status: PhoneEnums.CallsActionsResults.ko }));

  getStatusChanges = () =>
    this.command
      .getStatusChanges(this.sessionId)
      .then((response) => {
        this.data$ = $(response.data);
        // parse the status of pbx
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          ...this.parseStatus(this.data$, false),
        };
      })
      .catch((error) => ({ status: PhoneEnums.CallsActionsResults.ko }));

  retrieveExitCode = () => this.pbxLine;

  toggleDND = (status) =>
    this.command
      .toggleDND(status)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  callNumber = (number) => {
    return this.command
      .callNumber(number)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);
    // TODO add info on missing signal on opc
  };

  hangupCall = (callId) =>
    this.command
      .hangupCall(callId)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  declineCall = (callId, incomingCalls) =>
    this.command
      .hangupCall(callId)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  hangupHoldCall = (callId) => {
    if (this.isSIP) {
      return this.command
        .hangupCall(callId)
        .then((response) => PhoneEnums.CallsActionsResults.ok)
        .catch((error) => PhoneEnums.CallsActionsResults.ko);
    }
    return this.command
      .switchCalls()
      .then(() => {
        setTimeout(() => {
          this.command
            .hangupCall(callId)
            .then((response) => PhoneEnums.CallsActionsResults.ok)
            .catch((error) => PhoneEnums.CallsActionsResults.ko);
        }, 200);
      })
      .catch((error) => PhoneEnums.CallsActionsResults.ko);
  };

  // UNUSED IN ABILIS
  hangupChannel = () => PhoneEnums.CallsActionsResults.ko;

  holdCall = (callId) =>
    this.command
      .holdCall(callId)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  unholdCall = (callId) =>
    this.command
      .unholdCall(callId)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  switchCalls = () =>
    this.command
      .switchCalls()
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  attendedTransfer = (callId, to) =>
    this.command
      .callNumber(to)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  blindTransfer = (callId, to) =>
    this.command
      .blindTransfer(callId, to)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  // UNUSED IN ABILIS
  blindTransferParking = () => PhoneEnums.CallsActionsResults.ko;

  queueTransfer = (queueId, to) =>
    this.command
      .queueTransfer(queueId, to)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  queueAnswer = (queueId, queueName) =>
    this.command
      .queueAnswer(queueId, this.number)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  parkAnswer = (queueId) =>
    this.command
      .queueAnswer(queueId, this.number)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  parkCall = (number, callId) => {
    const parkedNumber = (this.parkPrefix || '') + number + this.number;
    return this.command
      .blindTransfer(callId, parkedNumber)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);
  };

  pickupCall = (callId, calledNumber) =>
    this.command
      .blindTransfer(callId, this.number)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  linkCalls = () =>
    this.command
      .callTransfer()
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  // UNUSED IN ABILIS
  muteCall = (callId) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  unmuteCall = (callId) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  startConference = (number, callId) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  endConference = (conferenceId) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  joinConference = () => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  hangupUserInConference = (extensionId, conferenceId) =>
    PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  muteUserInConference = (userId, conferenceId) =>
    PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  unmuteUserInConference = (userId, conferenceId) =>
    PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  retrieveConferenceData = (owner) => ({
    status: PhoneEnums.CallsActionsResults.ko,
  });

  // UNUSED IN ABILIS
  recordCall = (callId, users) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  spyCall = (callId, number) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  intrudeCall = (callId, number) => PhoneEnums.CallsActionsResults.ko;

  saveUserSetting = (rule, url) => {
    let { action } = rule;
    let forward = rule.forwardCalled;
    if (rule.action === PhoneRulesEnums.SettingsActions.responder) {
      action = PhoneRulesEnums.SettingsActions.forward;
      forward = PbxSettings.RESPONDER_FORWARD_NUMBER;
    }
    const configData = {
      priority: rule.priority,
      user: rule.user || this.newRuleUser,
      owner_type: rule.ownerType || this.newRuleOwnerType,
      owner_id: rule.ownerId || this.newRuleOwnerId,
      cf_dnd_cdi: '*',
      cf_dnd_interface: 'ANY',
      cf_dnd_use: 'YES',
      cf_dnd_noanstout: rule.timeout || PbxSettings.FORWARD_NO_ANSWER_TIMEOUT,
      cf_dnd_action: action,
      cf_dnd_cgi: rule.numberCalling ? rule.numberCalling : '*',
      cf_dnd_cdo: forward || '#',
      cf_dnd_cgo_number: forward || '#',
      Submit: 'Submit',
      ti1_type: 'W',
      ti1_bT_h: rule.timeFromHour,
      ti1_bT_m: rule.timeFromMinute,
      ti1_eT_h: rule.timeToHour === '24' ? '00' : rule.timeToHour,
      ti1_eT_m: rule.timeToMinute,
    };
    if (rule.enabled) {
      configData.cf_dnd_enabled = 'YES';
    }
    if (rule.days[0]) {
      configData.ti1_SU = 'SU';
    }
    if (rule.days[1]) {
      configData.ti1_MO = 'MO';
    }
    if (rule.days[2]) {
      configData.ti1_TU = 'TU';
    }
    if (rule.days[3]) {
      configData.ti1_WE = 'WE';
    }
    if (rule.days[4]) {
      configData.ti1_TH = 'TH';
    }
    if (rule.days[5]) {
      configData.ti1_FR = 'FR';
    }
    if (rule.days[6]) {
      configData.ti1_SA = 'SA';
    }
    if (rule.event.indexOf(PhoneRulesEnums.SettingsEvents.always) >= 0) {
      configData.cf_dnd_rule_always = 'YES';
    }
    if (rule.event.indexOf(PhoneRulesEnums.SettingsEvents.busy) >= 0) {
      configData.cf_dnd_rule_busy = 'YES';
      configData.cf_dnd_rule_others = 'YES';
    }
    if (rule.event.indexOf(PhoneRulesEnums.SettingsEvents.unanswered) >= 0) {
      configData.cf_dnd_rule_noans = 'YES';
      configData.cf_dnd_rule_others = 'YES';
    }
    if (rule.defaultCgo) {
      configData.cf_dnd_cgo = '*';
    } else {
      configData.cf_dnd_cgo = 'USER';
    }
    if (rule.add) {
      return this.command
        .addUserSetting(configData, url)
        .then((response) => PhoneEnums.CallsActionsResults.ok)
        .catch((error) => PhoneEnums.CallsActionsResults.ko);
    }
    return this.command
      .updateUserSetting(configData, url)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);
  };

  retrieveUserSettings = (userId, url) =>
    this.command
      .retrieveUserSettings(url)
      .then((response) => {
        const getUrlVars = (url) => {
          const queryString = url.substr(url.indexOf('?') + 1);
          return JSON.parse(
            `{"${decodeURI(queryString)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"')}"}`
          );
        };
        const rows$ = $(
          'tr',
          $(Utils.adjustAbilisHtml(response.data)).filter('table:eq(2)')
        );
        // collect data for add new rule
        const href = getUrlVars(
          $(
            "a[href^='/sys/user/cti_cf_preferences_add']:last",
            $(response.data)
          ).attr('href')
        );
        this.newRuleUser = href.user;
        this.newRuleOwnerType = href.owner_type;
        this.newRuleOwnerId = href.owner_id;

        const rules = [];
        if (rows$.length > 2) {
          // get current settings
          rows$.each((i, row) => {
            if (i < 1) {
              return;
            }
            const row$ = $(row);
            const rule = {
              days: [],
              priority: parseInt($('td:eq(3)', row$).text().trim(), 10),
              admin: $('td:eq(4)', row$).text().trim() === 'YES' ? true : false,
              enabled:
                $('td:eq(5)', row$).text().trim() === 'YES' ? true : false,
              href: $(
                'a[href^="/sys/user/cti_cf_preferences_modify"]',
                row$
              ).attr('href'),
              event: [],
            };
            if (!rule.href && !rule.admin) {
              return;
            }
            // NB get only first interval
            const days = {};
            const interval = $('td:eq(11)', row$).text().trim().split(',');
            if (interval[0] === 'ALL' || interval[0] === '*') {
              days[1] = true;
              days[2] = true;
              days[3] = true;
              days[4] = true;
              days[5] = true;
              days[6] = true;
              days[0] = true;
            } else if (interval[0].indexOf('-') !== -1) {
              const weekDays = interval[0].split('-');
              days[1] = weekDays[0] === 'MO';
              days[2] = weekDays[0] === 'MO' || weekDays.indexOf('TU') >= 0;
              days[3] =
                (weekDays[0] === 'MO' && weekDays[1] !== 'TU') ||
                weekDays[0] === 'TU' ||
                weekDays.indexOf('WE') >= 0;
              days[4] =
                ((weekDays[0] === 'MO' ||
                  weekDays[0] === 'TU' ||
                  weekDays[0] === 'WE') &&
                  (weekDays[1] === 'FR' ||
                    weekDays[1] === 'SA' ||
                    weekDays[1] === 'SU')) ||
                weekDays.indexOf('TH') >= 0;
              days[5] =
                weekDays.indexOf('FR') >= 0 ||
                weekDays[1] === 'SA' ||
                (weekDays[1] === 'SU' && weekDays[0] !== 'SA');
              days[6] =
                weekDays.indexOf('SA') >= 0 || weekDays.indexOf('SU') >= 0;
              days[0] = weekDays[1] === 'SU';
            } else {
              const weekDays =
                interval[0].indexOf('+') !== -1
                  ? interval[0].split('+')
                  : [interval[0]];
              days[1] = weekDays.indexOf('MO') >= 0;
              days[2] = weekDays.indexOf('TU') >= 0;
              days[3] = weekDays.indexOf('WE') >= 0;
              days[4] = weekDays.indexOf('TH') >= 0;
              days[5] = weekDays.indexOf('FR') >= 0;
              days[6] = weekDays.indexOf('SA') >= 0;
              days[0] = weekDays.indexOf('SU') >= 0;
            }

            rule.days = Object.values(days);

            // / time interval
            if (interval[0] === '*') {
              rule.timeFromHour = '00';
              rule.timeFromMinute = '00';
              rule.timeToHour = '23';
              rule.timeToMinute = '59';
            } else {
              const time = interval[1].split('-');
              const from = time[0].split(':');
              const to = time[1].split(':');

              rule.timeFromHour = from[0];
              rule.timeFromMinute = from[1];
              rule.timeToHour = to[0];
              rule.timeToMinute = to[1];
            }

            switch ($('td:eq(18)', row$).text().trim()) {
              case 'NONE':
                rule.action = '';
                break;
              case 'Call Forward':
                rule.action = PhoneRulesEnums.SettingsActions.forward;
                break;
              case 'Do Not Disturb':
                rule.action = PhoneRulesEnums.SettingsActions.dnd;
                break;
              case 'Voice Mail':
                rule.action = PhoneRulesEnums.SettingsActions.vbox;
                break;
              default:
                break;
            }

            const event = $('td:eq(16)', row$).text().trim();
            if (event === 'Always') {
              rule.event.push(PhoneRulesEnums.SettingsEvents.always);
            } else {
              if (event.indexOf('Busy') !== -1) {
                rule.event.push(PhoneRulesEnums.SettingsEvents.busy);
              }
              if (event.indexOf('NoAnswer') !== -1) {
                rule.event.push(PhoneRulesEnums.SettingsEvents.unanswered);
              }
            }
            rule.forwardCalled = $('td:eq(23)', row$).text().trim();
            if (rule.forwardCalled === '#' || rule.forwardCalled === '-') {
              rule.forwardCalled = '';
            }
            rule.numberCalling = $('td:eq(10)', row$).text().trim();

            if (!rule.admin) {
              const data = getUrlVars(rule.href);
              rule.user = data.user;
              rule.ownerType = data.owner_type;
              rule.ownerId = data.owner_id;
            }

            rules.push(rule);
          });
        }
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          rules,
        };
      })
      .catch((error) => ({ status: PhoneEnums.CallsActionsResults.ko }));

  deleteUserSetting = (rule, url) =>
    this.command
      .deleteUserSettings(rule, url)
      .then((response) => PhoneEnums.CallsActionsResults.ok)
      .catch((error) => PhoneEnums.CallsActionsResults.ko);

  retrieveLostCalls = (start, end) =>
    new Promise((resolve, reject) => {
      const lostLogs = [];
      this.command
        .retrieveLostCalls()
        .then((response) => {
          const result$ = $(response.data);
          let orderedList;
          if ($('call', result$).length > 0) {
            const firstRow$ = $('call', result$).get(0);
            let firstDate;
            let lastDate;
            if ($('s-time', firstRow$).length === 0) {
              // / version 8.5
              firstDate = new Date($('s-datetime', firstRow$).text().trim());
              const lastRow$ = $('call', result$).get(
                $('call', result$).length - 1
              );
              lastDate = new Date($('s-datetime', lastRow$).text().trim());
            } else {
              const firstTime$ = $('s-time', firstRow$).text().trim();
              const firstTz = Utils.retrieveAbilisTimezone(
                firstTime$.substr(8)
              );
              firstDate = new Date(
                $('s-date', firstRow$).text().trim() +
                  'T' +
                  firstTime$.substr(0, 8) +
                  firstTz
              );
              const lastRow$ = $('call', result$).get(
                $('call', result$).length - 1
              );
              const lastTime$ = $('s-time', lastRow$).text().trim();
              const lastTz = Utils.retrieveAbilisTimezone(lastTime$.substr(8));
              lastDate = new Date(
                $('s-date', lastRow$).text().trim() +
                  'T' +
                  lastTime$.substr(0, 8) +
                  lastTz
              );
            }
            if (firstDate < lastDate) {
              orderedList = $('call', result$).get().reverse();
            } else {
              orderedList = $('call', result$).get();
            }
          }
          $(orderedList).each((i, row) => {
            let dateLog;
            if ($('s-time', row).length === 0) {
              // / version 8.5
              dateLog = new Date($('s-datetime', row).text().trim());
            } else {
              const time$ = $('s-time', row).text().trim();
              const tz = Utils.retrieveAbilisTimezone(time$.substr(8));
              dateLog = new Date(
                $('s-date', row).text().trim() + 'T' + time$.substr(0, 8) + tz
              );
            }
            if ((start && dateLog < start) || (end && dateLog > end)) {
              return;
            }
            const log = {
              datetime: dateLog,
              type: 'lost',
              calling: $('cg', row).text().trim(),
              called: $('cd', row).text().trim(),
              result: $('result', row).text().trim(),
              duration: $('duration', row).text().trim(),
              cause: $('itu-cause-descr', row).text().trim(),
              id: (
                $('cd', row).text().trim() +
                '-' +
                $('cg', row).text().trim() +
                '-' +
                $('s-datetime', row).text().trim()
              ).replace(/\D/g, ''),
            };
            if (
              log.result !== 'Answered' &&
              log.cause.indexOf('Non-selected user clearing') === -1
            ) {
              lostLogs.push(log);
            }
          });
          resolve(lostLogs);
        })
        .catch((error) => reject(error));
    });

  retrieveMeCdr = (filters, options) => this.retrieveCdr(filters, options);

  retrieveOthersCdr = (filters, options) => this.retrieveCdr(filters, options);

  // UNUSED IN ABILIS
  retrieveQueuesCdr = () => PhoneEnums.CallsActionsResults.ko;

  retrieveHotelCdr = (filters) =>
    this.retrieveCdr({ ...filters, isHotelCdr: true });

  retrieveWakeups = (filters) => {
    if (!filters.archive && !filters.active)
      return { status: PhoneEnums.CallsActionsResults.ko };
    let params;
    if (filters.active) {
      params = {
        activeEnabled: true,
        activeDay: filters.day,
        activeRooms: filters.users,
      };
      return api.hotel
        .getHotelWakeups(params)
        .then((response) => ({
          status: PhoneEnums.CallsActionsResults.ok,
          data: response.data,
        }))
        .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
    }
    if (filters.archive) {
      params = {
        archiveEnabled: true,
        archiveStartDay: filters.startDay,
        archiveEndDay: filters.endDay,
        activeRooms: filters.users,
      };
      const wakeups = [];
      return api.hotel
        .getHotelWakeups(params)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const promises = response.data.map((wakeup) => {
              return new Promise((resolve, reject) => {
                let wakeupData = wakeup;
                if (wakeup.rung) {
                  const time = wakeup.time.split(':');
                  const day = moment(wakeup.schedule.d, 'YYYY-MM-DD').toDate();
                  day.setHours(+time[0]);
                  day.setMinutes(+time[1]);
                  day.setSeconds(0);
                  day.setMilliseconds(0);
                  const start = moment(day).subtract(2, 'm');
                  const end = moment(day).add(2, 'm');
                  const extension = filters.searchingUsers.filter(
                    (o) => o.id === wakeup.user
                  );
                  const answerFilters = {
                    searchingUser: extension[0],
                    start,
                    end,
                    isWakeupCdr: true,
                  };

                  this.retrieveCdr(answerFilters)
                    .then((res) => {
                      wakeupData = {
                        ...wakeupData,
                        answer: res.data.length > 0,
                      };
                      wakeups.push(wakeupData);
                      resolve();
                    })
                    .catch((err) => reject(err));
                } else {
                  wakeups.push(wakeupData);
                  resolve();
                }
              });
            });
            return Promise.all(promises);
          }
        })
        .then(() => {
          return {
            status: PhoneEnums.CallsActionsResults.ok,
            data: wakeups,
          };
        })
        .catch(() => {
          return {
            status: PhoneEnums.CallsActionsResults.ko,
          };
        });
    }
  };

  saveWakeup = (data) => {
    const execApi = data.id
      ? api.hotel.updateHotelWakeup
      : api.hotel.createHotelWakeup;
    return execApi(data)
      .then((response) => {
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          data: response.data,
        };
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
  };

  deleteWakeup = (id) =>
    api.hotel
      .deleteHotelWakeup(id)
      .then((response) => {
        return {
          status: PhoneEnums.CallsActionsResults.ok,
          data: response.data,
        };
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));

  retrieveWakeupAnswer = (filters) =>
    this.retrieveCdr({ ...filters, isWakeupCdr: true });

  retrieveCdr = (filters, options) => {
    options = options || {};
    const promises = [];
    let logs = [];
    if (filters.lost) {
      // load from abilis
      promises.push(
        new Promise((resolve, reject) =>
          this.retrieveLostCalls(filters.start, filters.end)
            .then((lostLogs) => {
              logs = logs.concat(lostLogs);
              resolve();
            })
            .catch((error) => reject(error))
        )
      );
    }
    if (
      filters.isHotelCdr ||
      filters.isWakeupCdr ||
      filters.in ||
      filters.out
    ) {
      let url;
      if (filters.isHotelCdr) {
        url = '/phone/hotel-calls';
      } else if (filters.isWakeupCdr) {
        url = '/phone/wakeup-answer';
      } else if (filters.extensions && filters.extensions.length > 0) {
        url = '/phone/others-calls';
      } else {
        url = '/phone/own-calls';
      }

      let params = {
        start: filters.start
          ? moment(filters.start).format('YYYYMMDDTHHmmss')
          : moment(new Date(1970, 0, 1)).format('YYYYMMDDTHHmmss'),
        end: filters.end
          ? moment(filters.end).format('YYYYMMDDTHHmmss')
          : moment().format('YYYYMMDDTHHmmss'),
      };

      if (filters.in) {
        params = {
          ...params,
          in: filters.in,
        };
      }
      if (filters.out) {
        params = {
          ...params,
          out: filters.out,
        };
      }
      if (
        (filters.searchingUsers && filters.searchingUsers.length) ||
        (filters.searchingContacts && filters.searchingContacts.length)
      ) {
        params = {
          ...params,
          extensions: filters.searchingUsers
            ? filters.searchingUsers.map((user) => user.number)
            : [],
          contacts: filters.searchingContacts,
          lost: filters.lost,
        };
      } else if (filters.searchingUser) {
        params = {
          ...params,
          extension: filters.searchingUser.number,
        };
      }
      // load from accounting
      promises.push(
        new Promise((resolve, reject) => {
          request({
            url,
            method: 'GET',
            params,
          })
            .then((res) => {
              logs = logs.concat(res.data.rows || []);
              resolve();
            })
            .catch((error) => {
              reject(new Error(error));
            });
        })
      );
    }
    return Promise.all(promises)
      .then(() => ({
        status: PhoneEnums.CallsActionsResults.ok,
        data:
          (options.deletedCalls && options.deletedCalls.length > 0) ||
          (options.callsNotes &&
            options.callsNotes &&
            Object.keys(options.callsNotes).length > 0)
            ? logs.reduce((result, log) => {
                if (
                  !options.deletedCalls ||
                  options.deletedCalls.indexOf(`${log.id}`) < 0
                ) {
                  result.push({
                    ...log,
                    notes: options.callsNotes
                      ? options.callsNotes[log.id]
                      : null,
                  });
                }
                return result;
              }, [])
            : logs,
      }))
      .catch(() => ({
        status: PhoneEnums.CallsActionsResults.ko,
      }));
  };

  // UNUSED IN ABILIS
  activateWebrtcPhone = (eventEmitter) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  deactivateWebrtcPhone = () => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  closeWebrtc = () => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  retrieveDefaultDevice = () => null;

  // UNUSED IN ABILIS
  answerCall = (callData) => PhoneEnums.CallsActionsResults.ko;

  // UNUSED IN ABILIS
  sendDtmf = (value) => PhoneEnums.CallsActionsResults.ko;

  executeSwitchboardTask = (username, password, url, task) => {
    return this.login(null, username, password, url)
      .then((response) => {
        if (response === PhoneEnums.CallsActionsResults.ko) {
          return { status: PhoneEnums.CallsActionsResults.ko };
        }
        return task()
          .then((res) => {
            return this.login()
              .then(() => res)
              .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
          })
          .catch(() => {
            return this.login()
              .then(() => ({ status: PhoneEnums.CallsActionsResults.ko }))
              .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
          });
      })
      .catch(() => ({ status: PhoneEnums.CallsActionsResults.ko }));
  };

  retrievePbxSettings = (pbx) =>
    this.executeSwitchboardTask(pbx.username, pbx.password, pbx.url, () =>
      this.retrieveUserSettings(null, pbx.url)
    );

  deletePbxSetting = (pbx, rule) =>
    this.executeSwitchboardTask(pbx.username, pbx.password, pbx.url, () =>
      this.deleteUserSetting(rule, pbx.url)
    );

  savePbxSetting = (pbx, rule) => {
    return this.executeSwitchboardTask(
      pbx.username,
      pbx.password,
      pbx.url,
      () => this.saveUserSetting(rule, pbx.url)
    );
  };

  // UNUSED IN ABILIS
  loadOwnVboxGreeting = () => PhoneEnums.CallsActionsResults.ko;
}
