import React from 'react';
import AdminGrants from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const AdminGrantsAdvancedSettings = React.lazy(() =>
  import('./AdminGrantsAdvancedSettings')
);

const AdminSettingsEditPage = (props) => (
  <AdminGrants>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="text-right">
          <HelpButton fileName="admin-advanced" />
        </div>
        <AdminGrantsAdvancedSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </AdminGrants>
);

export default AdminSettingsEditPage;
