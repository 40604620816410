import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../../components/SecondaryNav';
import SectionTitle from '../../../../components/SectionTitle';
import { hasGrants } from '../../../../js/me/selectors';
import { isPuncherEnabled } from '../../../../js/presence/selectors';
import { isVacationEnabled } from '../../../../js/calendar/selectors';

const messages = defineMessages({
  title: {
    id: 'PersonalSettings.title',
    defaultMessage: 'Personal settings',
  },
  profile: {
    id: 'PersonalSettings.show.navLabel',
    defaultMessage: 'Profile',
  },
  validationsData: {
    id: 'PersonalSettings.HRData.validationsData',
    defaultMessage: 'Presence management',
  },
  HRData: {
    id: 'PersonalSettings.HRData.navLabel',
    defaultMessage: 'HR data',
  },
  calendarData: {
    id: 'PersonalSettings.HRData.calendarData',
    defaultMessage: 'Calendar management',
  },
  email: {
    id: 'PersonalSettings.email.navLabel',
    defaultMessage: 'Email',
  },
  password: {
    id: 'PersonalSettings.settings.navLabel',
    defaultMessage: 'Password',
  },
});

const nav = [
  {
    id: 'PersonalSettings.profile.linkLabel',
    href: '/settings/personal',
    label: messages.profile,
  },
  {
    id: 'PersonalSettings.email.linkLabel',
    href: '/settings/email',
    label: messages.email,
  },
  {
    id: 'PasswordSettings.linkLabel',
    href: '/settings/password',
    label: messages.password,
  },
];

const PersonalSettings = ({
  intl: { formatMessage },
  children,
  scrolling,
  canManageValidations,
  canManageHRData,
}) => {
  const tabs = [nav[0]];
  if (canManageValidations) {
    tabs.push({
      id: 'PersonalSettings.validationsData.linkLabel',
      href: '/settings/validations',
      label: messages.validationsData,
    });
  }
  if (canManageHRData) {
    tabs.push({
      id: 'PersonalSettings.HRData.linkLabel',
      href: '/settings/hr',
      label: messages.HRData,
    });
  }
  /*if (true) {
    tabs.push({
      id: 'PersonalSettings.calendarData.linkLabel',
      href: '/settings/superviewers',
      label: messages.calendarData,
    });
  }*/
  tabs.push(nav[1]);
  tabs.push(nav[2]);

  return (
    <div className="border rounded bg-white h-100">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={tabs} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    canManageValidations:
      (isPuncherEnabled(state) || isVacationEnabled(state)) &&
      hasGrants(state, 'MANAGE_PRESENCES'),
    canManageHRData:
      isPuncherEnabled(state) && hasGrants(state, 'MANAGE_PRESENCES'),
  };
}
export default injectIntl(connect(mapStateToProps)(PersonalSettings));
