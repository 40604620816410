import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { fetchCitiesRequest } from '../../../js/contacts/actions';
import Select2 from '../Select2';

import {
  getCities,
  areCitiesLoaded,
  getCitiesSearched,
} from '../../../js/contacts/selectors';

const PAGE_SIZE = 30;

const messages = defineMessages({
  searchingMessage: {
    id: 'CitiesSelect.searchingMessage',
    defaultMessage: 'Searching...',
  },
});

class CitiesSelect extends Component {
  componentDidMount() {
    const { selected, fetch, searched } = this.props;
    if (selected) {
      fetch(selected);
    } else if (searched.toLowerCase() !== 'a') {
      fetch('a');
    }
  }

  componentDidUpdate(prevProps) {
    const { selected, fetch } = this.props;
    if (selected && selected !== prevProps.selected) {
      fetch(selected);
    }
  }

  handleInputChange = (filter, e) => {
    const { onChange, fetch, searched } = this.props;
    if (filter.length > 0 && searched.toLowerCase() !== filter.toLowerCase()) {
      fetch(filter);
    }
    if (e.action === 'input-change') {
      onChange(filter);
    }
  };

  arrangeSelectedCity = () => {
    const { cities, selected } = this.props;
    const optionSelected = cities.filter((city) => city.locality === selected);
    if (optionSelected.length > 0) {
      return {
        label: optionSelected[0].locality,
        value: optionSelected[0].id,
      };
    }
    return null;
  };

  render() {
    const {
      cities,
      onSelect,
      loaded,
      error,
      intl: { formatMessage },
      id,
      name,
    } = this.props;

    const options = cities.map((city) => ({
      label: `${city.locality}, ${city.district} (${city.postalcode})`,
      value: city.id,
    }));

    const selectedCity = this.arrangeSelectedCity();
    return (
      <Select2
        id={id}
        name={name}
        pageSize={PAGE_SIZE}
        isClearable
        value={selectedCity}
        onInputChange={(e, action) => this.handleInputChange(e, action)}
        onChange={onSelect}
        options={options}
        error={error}
        noOptionsMessage={() =>
          loaded ? null : formatMessage(messages.searchingMessage)
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    cities: getCities(state),
    searched: getCitiesSearched(state),
    loaded: areCitiesLoaded(state),
  };
}

CitiesSelect.propTypes = {
  selected: PropTypes.string,
};

CitiesSelect.defaultProps = {};

export default injectIntl(
  connect(mapStateToProps, { fetch: fetchCitiesRequest })(CitiesSelect)
);
