import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const RegistryManagementEdit = React.lazy(() =>
  import('./RegistryManagementEdit')
);

const RegistryManagementPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <RegistryManagementEdit {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default RegistryManagementPage;
