import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_FAX_LIST_REQUEST,
  FETCH_FAX_LIST_SUCCESS,
  FETCH_FAX_LIST_FAILURE,
  CHANGE_FAX_LIST_PAGE_REQUEST,
  DELETE_FAX_REQUEST,
  DELETE_FAX_FAILURE,
  RELOAD_FAX_LIST_REQUEST,
  READ_FAX_SUCCESS,
  VIEW_FAX_DETAILS_REQUEST,
  VIEW_FAX_DETAILS_FAILURE,
  VIEW_FAX_DETAILS_SUCCESS,
  FETCH_FAX_SETTINGS_SUCCESS,
  FETCH_FAX_SETTINGS_FAILURE,
  SAVE_FAX_SETTINGS_REQUEST,
  SAVE_FAX_SETTINGS_FAILURE,
  SAVE_FAX_SETTINGS_SUCCESS,
  CHANGE_FAX_SEND_PARAMS,
  SEND_FAX_REQUEST,
  SEND_FAX_FAILURE,
  SEND_FAX_SUCCESS,
  REFRESH_FAX_STATUS_SUCCESS,
  REFRESH_FAX_STATUS_REQUEST,
  REFRESH_FAX_STATUS_FAILURE,
  FAX_ERROR_NOTIFICATION,
  FAX_SENT_NOTIFICATION,
  FAX_RECEIVED_NOTIFICATION,
} from './types';
import moment from 'moment';

const initialState = {
  fetchList: {
    send: {
      filters: {
        start: null,
        end: null,
        sender: null,
        receiver: null,
        subject: null,
      },
      total: null,
      currentPage: 0,
      byId: {},
      allIds: [],
      faxesChanged: false,
      fetchLoaded: true,
      inited: false,
      fetchError: null,
      detailsLoaded: true,
      detailsError: null,
      refreshStatusLoading: null,
    },
    receive: {
      filters: {
        start: null,
        end: null,
        sender: null,
        receiver: null,
      },
      total: null,
      currentPage: 0,
      byId: {},
      allIds: [],
      faxesChanged: false,
      fetchLoaded: true,
      inited: false,
      fetchError: null,
      detailsLoaded: true,
      detailsError: null,
    },
  },
  send: {
    data: {
      sender: null,
      receiver: null,
      document: null,
    },
    sent: true,
    error: null,
  },
  fetchSettings: {
    allIds: [],
    byId: {},
    loaded: false,
    error: null,
  },
  saveSettings: {
    loaded: true,
    error: null,
  },
  lastRoute: null,
};

export default function sticky(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_FAX_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchSettings: {
          byId: keyBy(action.data, (o) => o.id),
          allIds: action.data.map((o) => o.id),
          loaded: true,
          error: null,
        },
      };

    case FETCH_FAX_SETTINGS_FAILURE:
      return {
        ...state,
        fetchSettings: {
          allIds: [],
          byId: {},
          loaded: true,
          error: action.errors,
        },
      };

    case FETCH_FAX_LIST_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            currentPage: 0,
            filters: {
              start: action.payload.start,
              end: action.payload.end,
              receiver: action.payload.receiver,
              sender: action.payload.sender,
              subject: action.payload.subject,
            },
            faxesChanged: false,
            fetchLoaded: false,
            inited: true,
            fetchError: null,
          },
        },
      };

    case CHANGE_FAX_LIST_PAGE_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            currentPage: action.pageNumber,
            faxesChanged: false,
            fetchLoaded: false,
            inited: true,
            fetchError: null,
          },
        },
      };

    case RELOAD_FAX_LIST_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            faxesChanged: false,
            fetchLoaded: false,
            inited: true,
            fetchError: null,
          },
        },
      };

    case FETCH_FAX_LIST_SUCCESS:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            total: action.data.total,
            byId: keyBy(action.data.data, (o) => o.id),
            allIds: action.data.data.map((o) => o.id),
            fetchLoaded: true,
            fetchError: null,
          },
        },
      };

    case FETCH_FAX_LIST_FAILURE:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            fetchLoaded: true,
            fetchError: action.errors,
          },
        },
      };

    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      if (lastRoute && lastRoute !== newRoute[0]) {
        return {
          ...state,
          fetchList: initialState.fetchList,
          lastRoute: newRoute[0],
        };
      }
      return {
        ...state,
        lastRoute: newRoute[0],
      };
    }

    case DELETE_FAX_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.payload.context.toLowerCase()]: {
            ...state.fetchList[action.payload.context.toLowerCase()],
            fetchLoaded: false,
            fetchError: null,
          },
        },
      };
    case DELETE_FAX_FAILURE:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            fetchLoaded: true,
            fetchError: action.errors,
          },
        },
      };

    case READ_FAX_SUCCESS: {
      const faxObject = state.fetchList.receive.byId[action.faxId];
      faxObject.read = moment().toDate();
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          receive: {
            ...state.fetchList.receive,
            byId: {
              ...state.fetchList.receive.byId,
              [action.faxId]: { ...faxObject },
            },
          },
        },
      };
    }

    case VIEW_FAX_DETAILS_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            detailsLoaded: false,
            detailsError: null,
          },
        },
      };

    case VIEW_FAX_DETAILS_SUCCESS:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            detailsLoaded: true,
            detailsError: null,
            byId: {
              ...state.fetchList[action.context.toLowerCase()].byId,
              [action.data.id]: {
                ...state.fetchList[action.context.toLowerCase()].byId[
                  action.data.id
                ],
                ...action.data,
              },
            },
          },
        },
      };

    case VIEW_FAX_DETAILS_FAILURE:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          [action.context.toLowerCase()]: {
            ...state.fetchList[action.context.toLowerCase()],
            detailsLoaded: true,
            detailsError: action.errors,
          },
        },
      };

    case SAVE_FAX_SETTINGS_REQUEST:
      return {
        ...state,
        saveSettings: {
          loaded: false,
          error: null,
        },
      };

    case SAVE_FAX_SETTINGS_SUCCESS:
      const setting = state.fetchSettings.byId[action.payload.id];
      setting.emailReceivers = action.payload.receiving;
      setting.emailSenders = action.payload.sending;
      return {
        ...state,
        saveSettings: {
          loaded: true,
          error: null,
        },
        fetchSettings: {
          ...state.fetchSettings,
          byId: {
            ...state.fetchSettings.byId,
            [action.payload.id]: { ...setting },
          },
        },
      };

    case SAVE_FAX_SETTINGS_FAILURE:
      return {
        ...state,
        saveSettings: {
          loaded: true,
          error: action.errors,
        },
      };

    case CHANGE_FAX_SEND_PARAMS: {
      return {
        ...state,
        send: {
          ...state.send,
          data: {
            sender: action.payload.sender,
            receiver: action.payload.receiver,
            document: action.payload.document,
          },
        },
      };
    }

    case SEND_FAX_REQUEST: {
      return {
        ...state,
        send: {
          ...state.send,
          sent: false,
          error: null,
        },
      };
    }

    case SEND_FAX_SUCCESS: {
      return {
        ...state,
        send: initialState.send,
      };
    }

    case SEND_FAX_FAILURE: {
      return {
        ...state,
        send: {
          ...state.send,
          sent: true,
          error: action.errors,
        },
      };
    }

    case REFRESH_FAX_STATUS_REQUEST:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          send: {
            ...state.fetchList.send,
            refreshStatusLoading: action.faxId,
          },
        },
      };

    case REFRESH_FAX_STATUS_SUCCESS: {
      const faxObject = state.fetchList.send.byId[action.payload.faxId];
      faxObject.status = action.payload.status;
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          send: {
            ...state.fetchList.send,
            byId: {
              ...state.fetchList.send.byId,
              [action.payload.faxId]: { ...faxObject },
            },
            refreshStatusLoading: null,
          },
        },
      };
    }

    case REFRESH_FAX_STATUS_FAILURE:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          send: {
            ...state.fetchList.send,
            refreshStatusLoading: null,
          },
        },
      };

    case FAX_SENT_NOTIFICATION:
    case FAX_ERROR_NOTIFICATION:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          send: {
            ...state.fetchList.send,
            faxesChanged: !action.payload.archived
              ? true
              : state.fetchList.send.faxesChanged,
          },
        },
      };

    case FAX_RECEIVED_NOTIFICATION:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          receive: {
            ...state.fetchList.receive,
            faxesChanged: !action.payload.archived
              ? true
              : state.fetchList.receive.faxesChanged,
          },
        },
      };

    default:
      return state;
  }
}
