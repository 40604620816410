import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const CalendarNotifications = React.lazy(() =>
  import('./CalendarNotifications')
);

const CalendarNotificationsPage = (props) => (
  <Calendar scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-calendar" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <CalendarNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarNotificationsPage;
