import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchCustomerContractDataSuccess,
  fetchCustomerContractDataFailure,
  fetchCustomerBillsSuccess,
  fetchCustomerBillsFailure,
  fetchCustomerPhoneLinesSuccess,
  fetchCustomerPhoneLinesFailure,
  fetchCustomerContractsSuccess,
  fetchCustomerContractsFailure,
  fetchCustomerNumbersSuccess,
  fetchCustomerNumbersFailure,
  fetchCustomerTrafficSuccess,
  fetchCustomerTrafficFailure,
  fetchCustomerServicesFailure,
  fetchCustomerServicesSuccess,
} from './actions';
import api from '../api';
import {
  FETCH_CUSTOMER_CONTRACT_DATA_REQUEST,
  FETCH_CUSTOMER_BILLS_REQUEST,
  FETCH_CUSTOMER_PHONE_LINES_REQUEST,
  FETCH_CUSTOMER_CONTRACTS_REQUEST,
  FETCH_CONTRACT_NUMBERS_REQUEST,
  FETCH_CONTRACT_TRAFFIC_REQUEST,
  FETCH_CUSTOMER_SERVICES_REQUEST,
} from './types';
import { checkApiResponse, checkApiError } from '../rootSaga';

export function* fetchCustomerContractData(action) {
  try {
    const res = yield call(
      api.documents.getCustomerContractData,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(fetchCustomerContractDataSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerContractDataFailure(error));
  }
}

export function* fetchCustomerBills(action) {
  try {
    const res = yield call(api.documents.getCustomerBills, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerBillsSuccess(
        {
          bills: res.data,
          page: action.payload.page,
          total: res.data.count,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerBillsFailure(error));
  }
}

export function* fetchCustomerPhoneLines(action) {
  try {
    const res = yield call(api.documents.getPhoneLines, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerPhoneLinesSuccess(
        {
          phoneLines: res.data,
          page: action.payload.page,
          total: res.data.count,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerPhoneLinesFailure(error));
  }
}

export function* fetchCustomerContracts(action) {
  try {
    const res = yield call(api.documents.getContrats, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerContractsSuccess(
        {
          contract: res.data,
          page: action.payload.page,
          total: res.data.count,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerContractsFailure(error));
  }
}

export function* fetchContractNumbers(action) {
  try {
    const res = yield call(api.documents.getContractNumbers, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchCustomerNumbersSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerNumbersFailure(error));
  }
}

export function* fetchContractTraffic(action) {
  try {
    const res = yield call(
      api.documents.getContractTraffic,
      action.id,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerTrafficSuccess(
        {
          traffic: res.data,
          sdate: action.payload.sdate,
          edate: action.payload.edate,
          numbers: action.payload.numbers,
          page: action.payload.page,
          total: res.data.count,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerTrafficFailure(error));
  }
}

export function* fetchCustomerServices(action) {
  try {
    const res = yield call(
      api.documents.getCustomerServices,
      action.id,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerServicesSuccess(
        {
          services: res.data,
          total: res.data.count,
          page: action.payload.page,
        },
        res.status
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerServicesFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(
    FETCH_CUSTOMER_CONTRACT_DATA_REQUEST,
    fetchCustomerContractData
  );
  yield takeLatest(FETCH_CUSTOMER_BILLS_REQUEST, fetchCustomerBills);
  yield takeLatest(FETCH_CUSTOMER_PHONE_LINES_REQUEST, fetchCustomerPhoneLines);
  yield takeLatest(FETCH_CUSTOMER_CONTRACTS_REQUEST, fetchCustomerContracts);
  yield takeLatest(FETCH_CONTRACT_NUMBERS_REQUEST, fetchContractNumbers);
  yield takeLatest(FETCH_CONTRACT_TRAFFIC_REQUEST, fetchContractTraffic);
  yield takeLatest(FETCH_CUSTOMER_SERVICES_REQUEST, fetchCustomerServices);
}
