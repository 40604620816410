import React from 'react';
import MaskedInput from 'react-text-mask';
import classnames from 'classnames';
import FormFieldError from '../messages/FormFieldError';

const TimeInput = (props) => {
  const classNames = classnames('form-control', {
    'is-invalid': props.error,
  });
  return (
    <>
      <MaskedInput
        className={classNames}
        showMask={!props.disabled}
        mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
        {...props}
      />
      {props.error && <FormFieldError text={props.error} />}
    </>
  );
};

export default TimeInput;
