export default class User {
  static isAuthorized(selfGrants, routeGrants) {
    if (!routeGrants) {
      return true;
    }

    let authorized = false;
    routeGrants.forEach((grant) => {
      if (selfGrants.indexOf(grant) >= 0) {
        authorized = true;
      }
    });
    return authorized;
  }

  static getFullname(name, surname, department) {
    return (
      (surname || '') +
      (name ? ` ${name}` : '') +
      (department ? ` (${department})` : '')
    );
  }
}
