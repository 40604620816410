import React from 'react';
import PbxVbox from './PbxVbox';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const VboxReceivedMessagesForm = React.lazy(() =>
  import('./VboxReceivedMessagesForm')
);
const VboxReceivedMessagesTable = React.lazy(() =>
  import('./VboxReceivedMessagesTable')
);

const PbxReceivedMessagesPage = (props) => (
  <PbxVbox>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="pbx-vbox-received" />
          </div>
          <VboxReceivedMessagesForm isPbx {...props} />
          <VboxReceivedMessagesTable isPbx />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </PbxVbox>
);

export default PbxReceivedMessagesPage;
