import { v1 as uuidv1 } from 'uuid';

export class WsEnums {
  static Channels = {
    PRESENCE: 'PRESENCE',
    NOTIFICATIONS: 'NOTIFICATIONS',
    CHAT: 'CHAT',
    WEBRTC: 'WEBRTC',
  };
}

export const generateAckUid = (userId) => {
  return `${userId}_${uuidv1()}`;
};

export const WS_RECONNECT_MAX_TIMEOUT = 30 * 1000;

export const calculateReconnectWaiting = (attempt) => {
  const time = Math.min(
    attempt * (Math.log(attempt) / Math.log(3)) * 1000,
    WS_RECONNECT_MAX_TIMEOUT
  );
  return Math.round(time);
};
