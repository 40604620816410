import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import LoginForm from '../forms/LoginForm';
import ynConf from '../../conf';

const messages = defineMessages({
  altTextLogo: {
    id: 'LoginPage.altTextLogo',
    defaultMessage: 'Logo YouNeed',
  },
  altTextAmbrosia: {
    id: 'LoginPage.altTextAmbrosia',
    defaultMessage:
      'Ambrosia, YouNeed vocal assistant and mascot, showing a thumb up',
  },
  title: {
    id: 'LoginPage.title',
    defaultMessage: 'Welcome!',
  },
  subtitle: {
    id: 'LoginPage.subtitle',
    defaultMessage: 'Insert your data and start using YouNeed',
  },
  forgotPasswordText: {
    id: 'LoginPage.forgotPasswordText',
    defaultMessage: 'Forgot Password? ',
  },
  forgotPasswordLink: {
    id: 'LoginPage.forgotPasswordLink',
    defaultMessage: 'Click here',
  },
});

const LoginPage = ({ intl: { formatMessage } }) => (
  <div className="bg-primary p-0 pt-sm-4 h-100">
    <div className="yn-login-form bg-white p-3 m-auto text-center">
      <img
        src={`${ynConf.publicFolderPath}/logo.png`}
        className="img-fluid"
        style={{
          height: '50px',
        }}
        alt={formatMessage(messages.altTextLogo)}
      />
      <img
        src={`${ynConf.publicFolderPath}/yn20.png`}
        className="img-fluid"
        style={{
          height: '250px',
        }}
        alt={formatMessage(messages.altTextAmbrosia)}
      />
      <h5>
        <strong>{formatMessage(messages.title)}</strong>
      </h5>
      <h6>
        <strong>{formatMessage(messages.subtitle)}</strong>
      </h6>
      <LoginForm />
      {formatMessage(messages.forgotPasswordText)}
      <Link to="/forgot-password">
        {formatMessage(messages.forgotPasswordLink)}
      </Link>
    </div>
  </div>
);

export default injectIntl(LoginPage);
