import { takeLatest, call, put } from 'redux-saga/effects';
import {
  updatePlatformSettingsSuccess,
  updatePlatformSettingsFailure,
  updateNotificationSettingsSuccess,
  updateNotificationSettingsFailure,
  fetchConfigurationsSuccess,
  fetchConfigurationsFailure,
  restoreNotificationsSettingsSuccess,
  restoreNotificationsSettingsFailure,
} from './actions';
import {
  FETCH_CONFIGURATIONS_REQUEST,
  RESTORE_NOTIFICATIONS_SETTINGS_REQUEST,
  RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  SAVE_HIDE_INTRO_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { fetchMeSettings } from '../me/sagas';

export function* updateSettings(action) {
  try {
    const res = yield call(api.me.updatePlatformSettings, action.preferences);
    yield call(checkApiResponse, res);

    yield put(updatePlatformSettingsSuccess(action.preferences, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePlatformSettingsFailure(error));
  }
}

export function* updateNotificationSettings(action) {
  try {
    const res = yield call(
      api.me.updateNotifictionSettings,
      action.notificationSettings
    );
    yield call(checkApiResponse, res);

    yield put(
      updateNotificationSettingsSuccess(action.notificationSettings, res.status)
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateNotificationSettingsFailure(error));
  }
}

export function* fetchConfigurations() {
  try {
    const res = yield call(api.settings.getConfigurations);
    yield call(checkApiResponse, res);
    yield put(fetchConfigurationsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchConfigurationsFailure(error));
  }
}

export function* restoreNotificationsSettings() {
  try {
    const res = yield call(api.me.restoreNotificationSettings);
    yield call(checkApiResponse, res);
    yield put(restoreNotificationsSettingsSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(restoreNotificationsSettingsFailure(error));
  }
}

export function* saveHideIntro() {
  try {
    const res = yield call(api.me.saveHideIntro);
    yield call(checkApiResponse, res);
  } catch (err) {
    yield call(checkApiError, err);
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_CONFIGURATIONS_REQUEST, fetchConfigurations);
  yield takeLatest(
    RESTORE_NOTIFICATIONS_SETTINGS_REQUEST,
    restoreNotificationsSettings
  );
  yield takeLatest(RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS, fetchMeSettings);
  yield takeLatest(UPDATE_PLATFORM_SETTINGS_REQUEST, updateSettings);
  yield takeLatest(
    UPDATE_NOTIFICATION_SETTINGS_REQUEST,
    updateNotificationSettings
  );
  yield takeLatest(SAVE_HIDE_INTRO_REQUEST, saveHideIntro);
}
