import { request } from '../request';

export default {
  login: (credentials) =>
    request({
      url: '/auth/login',
      method: 'POST',
      data: credentials,
    }),
  desktopAutologin: () =>
    request({
      url: '/auth/desktop-autologin',
      method: 'GET',
    }),
  logout: () =>
    request({
      url: '/auth/logout',
      method: 'POST',
    }),
  resetPassword: (username) =>
    request({
      url: '/auth/password-recovery',
      method: 'POST',
      data: username,
    }),
  guestLogin: (credentials) =>
    request({
      url: '/auth/guest/login',
      method: 'POST',
      data: credentials,
    }),
  guestLogout: () =>
    request({
      url: '/auth/guest/logout',
      method: 'POST',
    }),
  techLogin: (credentials) =>
    request({
      url: '/auth/tech',
      method: 'POST',
      data: credentials,
    }),
};
