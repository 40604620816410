import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select2 from './formControls/Select2';
import {
  getFetchPaymentsCreditsLoaded,
  getPaymentsCredits,
} from '../js/payments/selectors';
import { getPbxsById } from '../js/me/selectors';
import { fetchPaymentsCreditsRequest } from '../js/payments/actions';

const messages = defineMessages({
  alertText1: {
    id: 'PbxsCreditsSelect.alertText1',
    defaultMessage:
      'In none of your offices you have enough credits to continue purchasing.',
  },
  alertText2: {
    id: 'PbxsCreditsSelect.alertText2',
    defaultMessage: 'Please recharge your credit',
  },
  infoText: {
    id: 'PbxsCreditsSelect.infoText',
    defaultMessage: 'Please select the office from which you wanna pay',
  },
});

class PbxsCreditsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchCredits } = this.props;
    fetchCredits();
  }

  arrangeOfficesOptions = () => {
    const { credits, pbxs, toPay } = this.props;
    return credits.map((c) => ({
      label: pbxs[c.pbxId].name,
      value: c.pbxId,
      isDisabled: toPay && c.credit < toPay,
    }));
  };

  render() {
    const {
      onChange,
      selected,
      error,
      credits,
      toPay,
      intl: { formatMessage },
    } = this.props;

    const maxCredit =
      credits.length > 0 ? Math.max(...credits.map((c) => c.credit)) : 0;

    const remainingCredit = toPay ? maxCredit - toPay : maxCredit;

    return remainingCredit < 0 ? (
      <div className="alert alert-danger">
        {formatMessage(messages.alertText1)}
        <Link to="/administration/credit/remaining" className="alert-link mx-2">
          {formatMessage(messages.alertText2)}
        </Link>
      </div>
    ) : (
      <>
        <div className="pb-2">{formatMessage(messages.infoText)}</div>
        <Select2
          id="PbxsCredits"
          name="PbxsCredits"
          options={this.arrangeOfficesOptions()}
          value={selected}
          onChange={onChange}
          error={error}
        />
      </>
    );
  }
}

PbxsCreditsSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.object,
  error: PropTypes.string,
  toPay: PropTypes.number,
};

PbxsCreditsSelect.defaultProps = {
  selected: null,
  toPay: null,
  error: '',
};

function mapStateToProps(state) {
  return {
    credits: getPaymentsCredits(state),
    loading: !getFetchPaymentsCreditsLoaded(state),
    pbxs: getPbxsById(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { fetchCredits: fetchPaymentsCreditsRequest })(
    PbxsCreditsSelect
  )
);
