import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import { hasGrants } from '../../../js/me/selectors';

const messages = defineMessages({
  title: {
    id: 'PresencesManagement.title',
    defaultMessage: 'Presences management',
  },
  punchings: {
    id: 'PresencesManagement.punchings',
    defaultMessage: 'Punchings',
  },
  whoIsInNow: {
    id: 'PresencesManagement.whoIsInNow',
    defaultMessage: 'Who is in now',
  },
  registryManagement: {
    id: 'PresencesManagement.registryManagement',
    defaultMessage: 'Registry management',
  },
  exportTemplate: {
    id: 'PresencesManagement.exportTemplate',
    defaultMessage: 'Export template',
  },
  punchingJustifications: {
    id: 'PresencesManagement.punchingJustifications',
    defaultMessage: 'Punching justifications',
  },
  absence: {
    id: 'PresencesManagement.absence',
    defaultMessage: 'Absences',
  },
  cassaIntegrazione: {
    id: 'PresencesManagement.cassaIntegrazione',
    defaultMessage: 'Cassa Integrazione',
  },
  qrcodeManagement: {
    id: 'PresencesManagement.qrcodeManagement',
    defaultMessage: 'QR code settings',
  },
  visitors: {
    id: 'PresencesManagement.visitors',
    defaultMessage: 'Visitors',
  },
  visitorsList: {
    id: 'PresencesManagement.visitorsList',
    defaultMessage: 'Guests',
  },
  workingHours: {
    id: 'PresencesManagement.workingHours',
    defaultMessage: 'Working Hours',
  },
  manage: {
    id: 'PresencesManagement.manage',
    defaultMessage: 'Manage',
  },
});

const links = {
  0: {
    id: 'PresencesManagement.punchings',
    href: '/administration/presences/punchings',
    label: messages.punchings,
  },
  1: {
    id: 'PresencesManagement.absence',
    href: '/administration/presences/absence-management',
    label: messages.absence,
  },
  2: {
    id: 'PresencesManagement.visitorsList',
    href: '/administration/presences/visits-list',
    label: messages.visitorsList,
  },
  3: {
    id: 'PresencesManagement.whoIsInNow',
    href: '/administration/presences/working-users',
    label: messages.whoIsInNow,
  },
  4: {
    id: 'PresencesManagement.workingHours',
    href: '/administration/presences/management/working-hours',
    label: messages.workingHours,
  },
  5: {
    id: 'PresencesManagement.registryManagement',
    href: '/administration/presences/management/registry-management',
    label: messages.registryManagement,
  },
  6: {
    id: 'PresencesManagement.exportTemplate',
    href: '/administration/presences/management/export-template',
    label: messages.exportTemplate,
  },
  7: {
    id: 'PresencesManagement.punchingJustifications',
    href: '/administration/presences/management/punching-justifications',
    label: messages.punchingJustifications,
  },
  8: {
    id: 'PresencesManagement.qrcodeManagement',
    href: '/administration/presences/management/qrcode-settings',
    label: messages.qrcodeManagement,
  },
  9: {
    id: 'PresencesManagement.cassaintegrazione',
    href: '/administration/presences/cassaintegrazione-management',
    label: messages.cassaIntegrazione,
  },
};

const PresencesManagement = ({
  puncherEnabled,
  hasGuestVisitsGrant,
  hasPresencesGrant,
  hasEnterpriseGrant,
  scrolling,
  intl: { formatMessage },
  children,
}) => {
  let nav = [];

  const management = {
    id: 'PresencesManagement.manage',
    href: '/administration/presences/management',
    label: messages.manage,
    subNav: [],
  };
  if (!(hasPresencesGrant && puncherEnabled)) {
    if (hasEnterpriseGrant) {
      nav.push(links[3]);
    }
    if (hasGuestVisitsGrant) {
      nav.push(links[2]);
      nav.push(links[8]);
    }
    if (!hasEnterpriseGrant && !hasGuestVisitsGrant) {
      return <GlobalErrorPage code={403} />;
    }
  } else {
    management.subNav = [links[4], links[5], links[6], links[7]];
    nav = [links[0], links[1], links[9]];
    if (hasGuestVisitsGrant) {
      nav.push(links[2]);
      management.subNav.push(links[8]);
    }
    if (hasEnterpriseGrant) {
      nav.push(links[3]);
    }
    nav.push(management);
  }

  return (
    <div className="border rounded bg-white h-100">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    puncherEnabled: isPuncherEnabled(state),
    hasGuestVisitsGrant: hasGrants(state, 'MANAGE_GUEST_PRESENCES'),
    hasPresencesGrant: hasGrants(state, 'MANAGE_PRESENCES'),
    hasEnterpriseGrant: hasGrants(state, 'ENTERPRISE_PRESENCES'),
  };
}
export default injectIntl(connect(mapStateToProps)(PresencesManagement));
