import React from 'react';

const ChatMessageInfoTemplate = ({ style, children }) => {
  return (
    <div className="text-center my-2">
      <span
        className="badge badge-pill badge-light"
        style={{
          whiteSpace: 'normal',
          lineHeight: '1.3',
          fontWeight: '600',
          ...style,
        }}
      >
        {children}
      </span>
    </div>
  );
};

export default ChatMessageInfoTemplate;
