/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Collapse } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import DOMPurify from 'dompurify';
import { loginRequest } from '../../js/auth/actions';
import ToastMessage from '../../components/messages/ToastMessage';
import Button from '../../components/formControls/Button';
import Input from '../../components/formControls/Input';
import Form from '../../components/Form';
import Label from '../../components/formControls/Label';
import Loading from '../../components/Loading';
import GlobalErrorPage from './GlobalErrorPage';
import Select2 from '../../components/formControls/Select2';
import LinkButton from '../../components/LinkButton';
import {
  fetchGuestDataRequest,
  saveGuestVisitRequest,
} from '../../js/presence/actions';
import {
  getFetchGuestDataError,
  getFetchGuestDataLoaded,
  getGuestData,
  getSaveGuestVisitError,
  getSaveGuestVisitLoaded,
  getSaveGuestVisitSuccess,
} from '../../js/presence/selectorsPresences';
import ynConf from '../../conf';

const messages = defineMessages({
  title: {
    id: 'VisitorPage.title',
    defaultMessage: 'Welcome!',
  },
  titleWithName: {
    id: 'VisitorPage.titleWithName',
    defaultMessage: 'Welcome back {name}!',
  },
  subtitle: {
    id: 'VisitorPage.subtitle',
    defaultMessage: 'Please insert your data',
  },
  subtitleWithName: {
    id: 'VisitorPage.subtitleWithName',
    defaultMessage: 'You are not {name}?',
  },
  resetFields: {
    id: 'VisitorPage.resetFields',
    defaultMessage: 'Reset form',
  },
  pbx: {
    id: 'VisitorPage.label.pbx',
    defaultMessage: 'Office:',
  },
  placeholderOffice: {
    id: 'VisitorPage.placeholderOffice',
    defaultMessage: 'Select an office',
  },
  name: {
    id: 'VisitorPage.label.name',
    defaultMessage: 'Name:',
  },
  surname: {
    id: 'VisitorPage.label.surname',
    defaultMessage: 'Surname:',
  },
  company: {
    id: 'VisitorPage.label.company',
    defaultMessage: 'Company:',
  },
  reason: {
    id: 'VisitorPage.label.reason',
    defaultMessage: 'Visit topic / Person to visit:',
  },
  contact: {
    id: 'VisitorPage.label.contact',
    defaultMessage: 'Email / Phone number:',
  },
  documentNumber: {
    id: 'VisitorPage.label.documentNumber',
    defaultMessage: 'Document ID number:',
  },
  informative: {
    id: 'VisitorPage.label.informative',
    defaultMessage: 'I agree with the informative',
  },
  show: {
    id: 'VisitorPage.label.show',
    defaultMessage: 'show',
  },
  hide: {
    id: 'VisitorPage.label.hide',
    defaultMessage: 'hide',
  },
  errorPbx: {
    id: 'VisitorPage.error.errorPbx',
    defaultMessage: 'This field is required',
  },
  errorName: {
    id: 'VisitorPage.error.errorName',
    defaultMessage: 'This field is required',
  },
  errorSurname: {
    id: 'VisitorPage.error.errorSurname',
    defaultMessage: 'This field is required',
  },
  errorReason: {
    id: 'VisitorPage.error.errorReason',
    defaultMessage: 'This field is required',
  },
  errorEntrance: {
    id: 'VisitorPage.error.errorEntrance',
    defaultMessage: 'Please choose an option',
  },
  errorAccepted: {
    id: 'VisitorPage.error.errorAccepted',
    defaultMessage: 'You need to accept privacy policy',
  },
  buttonEnter: {
    id: 'VisitorPage.button.buttonEnter',
    defaultMessage: 'Enter',
  },
  buttonExit: {
    id: 'VisitorPage.button.buttonExit',
    defaultMessage: 'Exit',
  },
  buttonSave: {
    id: 'VisitorPage.button.buttonSave',
    defaultMessage: 'Proceed',
  },
  saveError: {
    id: 'VisitorPage.saveError',
    defaultMessage: 'There has been an error, please try again',
  },
  saveErrorNotFound: {
    id: 'VisitorPage.saveErrorNotFound',
    defaultMessage: "You can't exit if you're not entered!",
  },
  saveSuccessIn: {
    id: 'VisitorPage.saveSuccessIn',
    defaultMessage: 'Ok, great {name}, now you in',
  },
  saveSuccessOut: {
    id: 'VisitorPage.saveSuccessOut',
    defaultMessage: 'Ok, great {name}, now you out',
  },
});

class VisitorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: undefined,
        surname: undefined,
        company: undefined,
        reason: undefined,
        contact: undefined,
        documentNumber: undefined,
        entrance: undefined,
        accepted: undefined,
        marketing: undefined,
        pbx: undefined,
      },
      stored: JSON.parse(localStorage.getItem('guestdata')) || {},
      showInformative: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { fetch, match } = this.props;
    const url = match.params;
    fetch(url);
  }

  handleOfficeChange = (selected) => {
    this.setState({
      data: {
        ...this.state.data,
        pbx: selected,
      },
      errors: {},
    });
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      },
      errors: {},
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { guestData, save } = this.props;
    const params = {
      idCustomer: guestData.id,
      name: this.arrangeName(),
      surname: this.arrangeSurname(),
      company: this.arrangeCompany(),
      contact: this.arrangeContact(),
      entrance: this.arrangeEntrance(),
      reason: this.arrangeReason(),
      marketing: this.arrangeMarketing(),
      documentNumber: this.arrangeDocumentNumber(),
      idPbx: this.arrangePbx().value,
    };
    const errors = this.validate(params, this.arrangeAccepted());
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      save(params);
      localStorage.setItem(
        'guestdata',
        JSON.stringify({ ...params, accepted: true })
      );
    }
  };

  validate = (data, accepted) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    if (!data.idPbx) {
      errors.pbx = formatMessage(messages.errorPbx);
    }
    if (!data.name) {
      errors.name = formatMessage(messages.errorName);
    }
    if (!data.surname) {
      errors.surname = formatMessage(messages.errorSurname);
    }
    if (!data.reason) {
      errors.reason = formatMessage(messages.errorReason);
    }
    if (data.entrance === null) {
      errors.entrance = formatMessage(messages.errorEntrance);
    }
    if (!accepted) {
      errors.accepted = formatMessage(messages.errorAccepted);
    }
    return errors;
  };

  arrangeName = () => {
    const {
      stored: { name },
      data,
    } = this.state;
    return data.name !== undefined ? data.name : name || '';
  };

  arrangeSurname = () => {
    const {
      stored: { surname },
      data,
    } = this.state;
    return data.surname !== undefined ? data.surname : surname || '';
  };

  arrangeCompany = () => {
    const {
      stored: { company },
      data,
    } = this.state;
    return data.company !== undefined ? data.company : company || '';
  };

  arrangeReason = () => {
    const {
      stored: { reason },
      data,
    } = this.state;
    return data.reason !== undefined ? data.reason : reason || '';
  };

  arrangeContact = () => {
    const {
      stored: { contact },
      data,
    } = this.state;
    return data.contact !== undefined ? data.contact : contact || '';
  };

  arrangeDocumentNumber = () => {
    const {
      stored: { documentNumber },
      data,
    } = this.state;
    return data.documentNumber !== undefined
      ? data.documentNumber
      : documentNumber || '';
  };

  arrangeEntrance = () => {
    const {
      stored: { entrance },
      data,
    } = this.state;
    if (data.entrance !== undefined) return data.entrance;
    if (entrance !== undefined && entrance !== null) return !entrance;
    return null;
  };

  arrangeMarketing = () => {
    const {
      stored: { marketing },
      data,
    } = this.state;
    return data.marketing !== undefined ? data.marketing : marketing || false;
  };

  arrangeAccepted = () => {
    const {
      stored: { accepted },
      data,
    } = this.state;
    return data.accepted !== undefined ? data.accepted : accepted || false;
  };

  arrangePbx = () => {
    const {
      stored: { idPbx },
      data,
    } = this.state;
    if (!this.props.guestData || !this.props.guestData.pbxs) {
      return {};
    }
    if (this.props.guestData.pbxs.length === 1) {
      return {
        value: this.props.guestData.pbxs[0].id,
      };
    }
    return data.pbx !== undefined
      ? data.pbx
      : (idPbx
          ? {
              value: idPbx,
              label: this.props.guestData.pbxs.find((pbx) => pbx.id === idPbx)
                .name,
            }
          : {}) || {};
  };

  resetForm = () => {
    localStorage.removeItem('guestdata');
    this.setState({ stored: {} });
  };

  render() {
    const {
      fetchingError,
      fetching,
      savingError,
      savingSuccess,
      saving,
      guestData,
      intl: { formatMessage },
    } = this.props;
    const { errors, showInformative, data, stored } = this.state;

    const name = this.arrangeName();
    const surname = this.arrangeSurname();
    const company = this.arrangeCompany();
    const reason = this.arrangeReason();
    const contact = this.arrangeContact();
    const documentNumber = this.arrangeDocumentNumber();
    const accepted = this.arrangeAccepted();
    const entrance = this.arrangeEntrance();
    const marketing = this.arrangeMarketing();
    const pbx = this.arrangePbx();

    const privacyText = guestData.informative
      ? DOMPurify.sanitize(guestData.informative)
      : null;

    const marketingText = guestData.marketing
      ? DOMPurify.sanitize(guestData.marketing)
      : null;

    const isButtonDisabled =
      !this.arrangeName() ||
      !this.arrangeSurname() ||
      !this.arrangeReason() ||
      !this.arrangeAccepted() ||
      this.arrangeEntrance() === null;

    const showMarketingCheckbox =
      DOMPurify.sanitize(marketingText, {
        ALLOWED_TAGS: false,
      }) !== null &&
      DOMPurify.sanitize(marketingText, {
        ALLOWED_TAGS: false,
      }) !== '';

    const pbxOptions =
      guestData && guestData.pbxs
        ? guestData.pbxs.map((pbx) => ({
            value: pbx.id,
            label: pbx.name,
          }))
        : [];

    return fetching ? (
      <Loading />
    ) : fetchingError || !guestData ? (
      <GlobalErrorPage />
    ) : (
      <div className="bg-light p-0 pt-sm-4 h-100">
        <div className="yn-login-form bg-white p-3 m-auto text-center">
          {savingSuccess ? (
            <div style={{ minHeight: '300px' }}>
              <div className="text-center">
                <img
                  src={`${ynConf.publicFolderPath}/stickers/sticker3w.png`}
                  alt="Success"
                  height="200"
                />
              </div>
              <div className="text-center h5 mt-2">
                {formatMessage(
                  entrance ? messages.saveSuccessIn : messages.saveSuccessOut,
                  { name }
                )}
              </div>
            </div>
          ) : (
            <>
              {stored.name ? (
                <>
                  <h5>
                    <strong>
                      {formatMessage(messages.titleWithName, {
                        name: stored.name,
                      })}
                    </strong>
                  </h5>
                  <h6>
                    <strong>
                      {formatMessage(messages.subtitleWithName, {
                        name: stored.name,
                      })}
                    </strong>
                    <LinkButton onClick={this.resetForm}>
                      {formatMessage(messages.resetFields)}
                    </LinkButton>
                  </h6>
                </>
              ) : (
                <>
                  <h5>
                    <strong>{formatMessage(messages.title)}</strong>
                  </h5>
                  <h6>
                    <strong>{formatMessage(messages.subtitle)}</strong>
                  </h6>
                </>
              )}

              <Form
                noValidate
                className="p-4 text-left border-0 needs-validation"
                onSubmit={this.onSubmit}
              >
                {savingError && (
                  <ToastMessage
                    type="danger"
                    closeTimeout={2000}
                    text={
                      savingError === 404
                        ? formatMessage(messages.saveErrorNotFound)
                        : formatMessage(messages.saveError)
                    }
                  />
                )}
                <FormGroup>
                  {guestData.pbxs && guestData.pbxs.length > 1 && (
                    <>
                      <Label mandatory for="office">
                        {formatMessage(messages.pbx)}
                      </Label>
                      {stored.idPbx ? (
                        <Input
                          type="office"
                          name="office"
                          id="office"
                          value={pbx.label}
                          disabled
                        />
                      ) : (
                        <Select2
                          id="office"
                          name="office"
                          options={pbxOptions}
                          isMulti={false}
                          isSearchable
                          isClearable
                          onChange={this.handleOfficeChange}
                          value={pbx}
                          error={errors.pbx}
                          placeholder={formatMessage(
                            messages.placeholderOffice
                          )}
                        />
                      )}
                    </>
                  )}
                  <Label mandatory for="name">
                    {formatMessage(messages.name)}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={this.handleChange}
                    error={errors.name}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup>
                  <Label mandatory for="surname">
                    {formatMessage(messages.surname)}
                  </Label>
                  <Input
                    type="text"
                    name="surname"
                    id="surname"
                    onChange={this.handleChange}
                    value={surname}
                    error={errors.surname}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="company">{formatMessage(messages.company)}</Label>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    onChange={this.handleChange}
                    value={company}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup>
                  <Label mandatory for="reason">
                    {formatMessage(messages.reason)}
                  </Label>
                  <Input
                    name="reason"
                    id="reason"
                    onChange={this.handleChange}
                    value={reason}
                    error={errors.reason}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="contact">{formatMessage(messages.contact)}</Label>
                  <Input
                    name="contact"
                    id="contact"
                    onChange={this.handleChange}
                    value={contact}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="documentNumber">
                    {formatMessage(messages.documentNumber)}
                  </Label>
                  <Input
                    name="documentNumber"
                    id="documentNumber"
                    onChange={this.handleChange}
                    value={documentNumber}
                    disabled={stored.entrance}
                  />
                </FormGroup>
                <FormGroup className="mt-3 text-center">
                  <div className="btn-group btn-block">
                    <span
                      className={`btn ${
                        errors.entrance
                          ? 'btn-outline-danger'
                          : entrance === true
                          ? 'btn-primary-dark'
                          : 'btn-outline-primary-dark'
                      } ${stored.entrance ? 'disabled' : ''}`}
                      onClick={() =>
                        this.setState({
                          data: { ...data, entrance: true },
                          errors: {},
                        })
                      }
                    >
                      {formatMessage(messages.buttonEnter)}
                    </span>
                    <span
                      className={`btn ${
                        errors.entrance
                          ? 'btn-outline-danger'
                          : entrance === false
                          ? 'btn-primary-dark'
                          : 'btn-outline-primary-dark'
                      }  ${stored.entrance ? 'disabled' : ''}`}
                      onClick={() =>
                        this.setState({
                          data: { ...data, entrance: false },
                          errors: {},
                        })
                      }
                    >
                      {formatMessage(messages.buttonExit)}
                    </span>
                  </div>
                  <div
                    className="text-left"
                    style={{ fontSize: '90%', color: '#dc3545' }}
                  >
                    {errors.entrance}
                  </div>
                </FormGroup>
                {entrance !== false && (
                  <>
                    <div className="small d-block pt-1 pb-2">
                      <div className="custom-control custom-checkbox custom-control-inline d-block">
                        <input
                          type="checkbox"
                          id="accepted"
                          name="accepted"
                          checked={accepted}
                          onChange={this.handleChange}
                          className={`custom-control-input ${
                            errors.accepted ? 'is-invalid' : ''
                          }`}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="accepted"
                        >
                          {formatMessage(messages.informative)}
                        </label>
                        <LinkButton
                          className="px-0"
                          onClick={() =>
                            this.setState({
                              showInformative: !showInformative,
                            })
                          }
                        >
                          {showInformative
                            ? formatMessage(messages.hide)
                            : formatMessage(messages.show)}
                        </LinkButton>
                      </div>
                      {errors.accepted && (
                        <div
                          className="text-left"
                          style={{ fontSize: '90%', color: '#dc3545' }}
                        >
                          {errors.accepted}
                        </div>
                      )}
                      <Collapse isOpen={showInformative}>
                        <div
                          className="small p-1 border rounded mt-2"
                          style={{
                            maxHeight: '100px',
                            overflowY: 'scroll',
                          }}
                          dangerouslySetInnerHTML={{ __html: privacyText }}
                        />
                      </Collapse>
                    </div>
                  </>
                )}
                {showMarketingCheckbox && entrance !== false && (
                  <div className="small d-block pt-1 pb-2">
                    <div className="custom-control custom-checkbox custom-control-inline text-right">
                      <input
                        type="checkbox"
                        id="marketing"
                        name="marketing"
                        checked={marketing}
                        onChange={this.handleChange}
                        className="custom-control-input"
                      />

                      <label
                        className="custom-control-label"
                        htmlFor="marketing"
                        dangerouslySetInnerHTML={{
                          __html: marketingText,
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="pt-1">
                  <Button
                    className="btn btn-primary btn-block"
                    loading={saving}
                    disabled={isButtonDisabled}
                    text={formatMessage(messages.buttonSave)}
                  />
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetchingError: getFetchGuestDataError(state),
    fetching: !getFetchGuestDataLoaded(state),
    savingError: getSaveGuestVisitError(state),
    savingSuccess: getSaveGuestVisitSuccess(state),
    saving: !getSaveGuestVisitLoaded(state),
    guestData: getGuestData(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    submit: loginRequest,
    fetch: fetchGuestDataRequest,
    save: saveGuestVisitRequest,
  })(VisitorPage)
);
