import React, { Component } from 'react';
import { Fade } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  fetchConferenceRequest,
  endConferenceRequest,
  fetchMemberDataRequest,
  joinConferenceRequest,
  hangupUserConferenceRequest,
  startConferenceRequest,
  hangupCallRequest,
  execCallRequest,
  addConferenceMemberWebrtcRequest,
} from '../../../js/phone/actions';
import {
  getConferenceUsers,
  getMeConferenceId,
  getMeActiveCallId,
} from '../../../js/me/selectors';
import Button from '../../../components/formControls/Button';
import Icon from '../../../components/icons/Icon';
import InterlocutorPanel from '../../../components/InterlocutorPanel';
import PhoneNumberSearchInput from './PhoneNumberSearchInput';
import { getSearchedNumber, isWebrtcActive } from '../../../js/phone/selectors';
import PhoneNumberSearchList from '../../../components/PhoneNumberSearchList';
import Utils from '../../../js/lib/utils';

const messages = defineMessages({
  header: {
    id: 'ConferencePanel.header',
    defaultMessage: 'On going conference',
  },
  body: {
    id: 'ConferencePanel.body',
    defaultMessage: 'Conference',
  },
  addMember: {
    id: 'ConferencePanel.tooltip.addMember',
    defaultMessage: 'Add member',
  },
  closeConferenceButton: {
    id: 'ConferencePanel.tooltip.closeConferenceButton',
    defaultMessage: 'Close conference',
  },
});

class ConferencePanel extends Component {
  state = {
    addMemberPanelOpen: false,
  };

  componentDidMount() {
    this.props.users.map((user) => this.props.fetchMember(user.number));
  }

  componentDidUpdate(prevprops) {
    if (prevprops.users.length < this.props.users.length) {
      this.props.users.forEach((user) => {
        if (!user.fetched) {
          this.props.fetchMember(user.number);
        }
      });
    }
  }

  handleClose = () => {
    this.props.users.length > 0
      ? this.props.hangupConference(this.props.id)
      : this.props.hangupCall(this.props.callId);
  };

  handleJoin = () => {
    this.props.join();
  };

  handleAddMemberClick = () => {
    this.setState({
      addMemberPanelOpen: true,
    });
  };

  handleAddMember = (number) => {
    if (this.props.webrtcActive === 'ON') {
      const numberToCall = Utils.adjustPhoneNumber(number);
      if (Utils.validatePhoneNumber(numberToCall)) {
        this.props.addMemberWebrtc({
          number: numberToCall,
          conferenceId: this.props.id,
        });
      }
    } else {
      this.props.addMember({
        callId: this.props.callId,
        number,
      });
    }
  };

  handleHangupUser = (number) => {
    this.props.hangupUser({ number, conferenceId: this.props.id });
  };

  render() {
    const {
      users,
      id,
      number,
      activeCall,
      intl: { formatMessage },
    } = this.props;
    const ownerIn =
      users.filter((member) => member.owner && member.in).length === 1;
    return (
      <Fade id="ConferencePanel" className="card mt-2">
        <div className="card-header text-left small p-1">
          <div className="row">
            <div className="col-12">{formatMessage(messages.header)}</div>
          </div>
        </div>
        {users.length > 0 &&
          users.map(
            (user) =>
              user.number &&
              !(activeCall && user.owner) && (
                <div
                  className="card-body p-1 text-left small border-bottom"
                  key={user.number}
                >
                  <InterlocutorPanel
                    interlocutor={user}
                    onJoin={this.handleJoin}
                    onHangup={() => this.handleHangupUser(user.number)}
                    conferenceId={id}
                    confToolbar
                    ellipsis
                  />
                </div>
              )
          )}
        {users.length > 0 && ownerIn && (
          <div className="card-body p-1 text-center small p-1">
            <span onClick={this.handleAddMemberClick} className="mx-1">
              <Icon name="person-add-2" color="var(--yn-blue-dark)">
                {formatMessage(messages.addMember)}
              </Icon>
            </span>
          </div>
        )}
        {users.length > 0 && ownerIn && this.state.addMemberPanelOpen && (
          <>
            <PhoneNumberSearchInput
              name="PhonePanelInput"
              id="PhonePanelInput"
              target="ConferencePanel"
              onEnter={() => this.handleAddMember(number)}
              error={this.state.error}
            />
            <PhoneNumberSearchList
              target="ConferencePanel"
              placement="right-start"
              onSelect={(num) => this.handleAddMember(num)}
            />
          </>
        )}
        <div className="card-body p-1">
          <Button
            text={formatMessage(messages.closeConferenceButton)}
            className="bt btn-danger btn-block"
            onClick={this.handleClose}
          />
        </div>
      </Fade>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: getConferenceUsers(state),
    id: getMeConferenceId(state),
    number: getSearchedNumber(state, 'ConferencePanel'),
    activeCall: !!getMeActiveCallId(state),
    webrtcActive: isWebrtcActive(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchConferenceRequest,
    fetchMember: fetchMemberDataRequest,
    addMember: startConferenceRequest,
    join: joinConferenceRequest,
    hangupUser: hangupUserConferenceRequest,
    hangupConference: endConferenceRequest,
    hangupCall: hangupCallRequest,
    call: execCallRequest,
    addMemberWebrtc: addConferenceMemberWebrtcRequest,
  })(ConferencePanel)
);
