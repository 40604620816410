export default class FileManager {
  static pendingFileUpload = {};

  static addPendingFile = (id, cancelSource) => {
    FileManager.pendingFileUpload[id] = cancelSource;
  };

  static retrievePendingFile = (id) => FileManager.pendingFileUpload[id];

  static removePendingFile = (id) => {
    delete FileManager.pendingFileUpload[id];
  };
}
