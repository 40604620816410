import React from 'react';
import AdminGrants from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const AdminUsersProfilesList = React.lazy(() =>
  import('./AdminUsersProfilesList')
);

const AdminUsersProfilesPage = () => {
  return (
    <AdminGrants>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div className="text-right mb-2">
            <HelpButton fileName="users-profiles" />
          </div>
          <AdminUsersProfilesList />
        </React.Suspense>
      </ErrorBoundary>
    </AdminGrants>
  );
};

export default AdminUsersProfilesPage;
