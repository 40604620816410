import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { hasGrants } from '../../../js/me/selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HotelPrices = React.lazy(() => import('./HotelPrices'));

const messages = defineMessages({
  title: {
    id: 'HotelPrices.title',
    defaultMessage: 'Costs settings',
  },
});

const HotelPricesPage = (props) => (
  <React.Fragment>
    {!props.canSee ? (
      <GlobalErrorPage code={403} />
    ) : (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>{props.intl.formatMessage(messages.title)}</SectionTitle>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <HotelPrices />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    )}
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    canSee: hasGrants(state, 'CDR_ENABLED') && state.me.customer.hotelEnabled,
  };
}

export default injectIntl(connect(mapStateToProps)(HotelPricesPage));
