import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
import MeVbox from './MeVbox';

const MeVboxNotifications = React.lazy(() => import('./MeVboxNotifications'));

const MeVboxNotificationsPage = (props) => (
  <MeVbox scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-fax" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <MeVboxNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </MeVbox>
);

export default MeVboxNotificationsPage;
