import { eventChannel } from 'redux-saga';
import {
  loginWebsocket,
  websocketDisconnection,
  websocketConnection,
} from './actions';
import { WsEnums, calculateReconnectWaiting } from './WsUtils';
import { managePresenceEvent } from '../users/PresenceUtils';
import { manageNotificationEvent } from '../notifications/NotificationUtils';
import AckHandler from './AckHandler';
import ChatManager from '../chat/ChatManager';
import WsManager from './WsManager';
import VideocallsManager from '../videocalls/VideocallsManager';

let retryAttempt = 0;

export function initListener(socket) {
  let pingInterval;
  return eventChannel((emit) => {
    socket.onopen = () => {
      console.log('Opened socket ws');
    };
    socket.onmessage = (event) => {
      //console.log('ONMESSAGE', event);
      if (event && event.data === 'READY') {
        retryAttempt = 0;
        AckHandler.setActive(true);
        emit({ action: websocketConnection });
        pingInterval = setInterval(() => {
          WsManager.sendMessage('PING');
        }, 30000);
        return;
      }
      try {
        const message = JSON.parse(event.data);
        if (message.action === 'ACK') {
          AckHandler.checkMessage(message);
        }
        let response;
        switch (message.channel) {
          case WsEnums.Channels.PRESENCE: {
            response = managePresenceEvent(message);
            break;
          }
          case WsEnums.Channels.NOTIFICATIONS: {
            response = manageNotificationEvent(message);
            break;
          }
          case WsEnums.Channels.CHAT: {
            response = ChatManager.manageChatEvent(message);
            break;
          }
          case WsEnums.Channels.WEBRTC: {
            response = VideocallsManager.manageVideocallsEvent(message);
            break;
          }
          default:
            break;
        }
        if (response) {
          emit(response);
        }
      } catch (e) {}
    };
    socket.onerror = (err) => {
      console.error('Error socket ws', err);
    };
    socket.onclose = (err) => {
      console.log('Closed socket ws', err);
      emit({ action: websocketDisconnection });
      if (pingInterval) {
        clearInterval(pingInterval);
      }
      retryAttempt += 1;
      setTimeout(() => {
        emit({ action: loginWebsocket });
      }, calculateReconnectWaiting(retryAttempt));
    };
    return () => {};
  });
}
