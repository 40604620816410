import keyBy from 'lodash.keyby';
import {
  /** Fetch Stickies */
  FETCH_STICKY_NOTES_SUCCESS,
  FETCH_STICKY_NOTES_FAILURE,
  /** Add Stickies */
  ADD_STICKY_NOTES_SUCCESS,
  ADD_STICKY_NOTES_FAILURE,
  /** Delete Sticky */
  DELETE_STICKY_NOTE_REQUEST,
  DELETE_STICKY_NOTE_SUCCESS,
  DELETE_STICKY_NOTE_FAILURE,
  /** Modify Sticky */
  UPDATE_STICKY_REQUEST,
  UPDATE_STICKY_SUCCESS,
  UPDATE_STICKY_FAILURE,
  /* Fetch static notes */
  FETCH_STATIC_NOTES_REQUEST,
  FETCH_STATIC_NOTES_SUCCESS,
  FETCH_STATIC_NOTES_FAILURE,
  CREATE_STATIC_NOTE_REQUEST,
  CREATE_STATIC_NOTE_SUCCESS,
  CREATE_STATIC_NOTE_FAILURE,
  /* Update Static Notes order */
  UPDATE_STATIC_NOTES_ORDER_REQUEST,
  UPDATE_STATIC_NOTES_ORDER_SUCCESS,
  UPDATE_STATIC_NOTES_ORDER_FAILURE,
  NOTES_CLEAR_ERRORS,
  /* Edit a static note */
  EDIT_STATIC_NOTE_REQUEST,
  EDIT_STATIC_NOTE_SUCCESS,
  EDIT_STATIC_NOTE_FAILURE,
  /* Delete a note */
  REMOVE_STATIC_NOTE_REQUEST,
  REMOVE_STATIC_NOTE_SUCCESS,
  REMOVE_STATIC_NOTE_FAILURE,
  SHARE_STICKY_REQUEST,
  SHARE_STICKY_SUCCESS,
  SHARE_STICKY_FAILURE,
  /* GET_STICKY_SHARINGS_REQUEST,
  GET_STICKY_SHARINGS_SUCCESS,
  GET_STICKY_SHARINGS_FAILURE, */
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialState = {
  fetchStickyNotes: {
    error: null,
    sticky: [],
  },
  addStickyNote: {
    error: null,
  },
  deleteStickyNote: {
    error: null,
    old: null,
  },
  updateSticky: {
    error: null,
    old: null,
  },
  shareStickyLoaded: true,
  shareStickyError: false,
  getStickySharingsLoaded: true,
  getStickySharingsError: false,

  fetchStaticNotesLoaded: true,
  fetchStaticNotesError: false,
  staticNotesById: {},
  createStaticNoteLoaded: true,
  createStaticNoteError: false,
  staticNotesAllIds: {
    current: [],
    old: null,
  },
  editStaticNoteLoaded: true,
  editStaticNoteError: false,
  deleteStaticNoteLoaded: true,
  deleteStaticNoteError: false,
};

export default function sticky(state = initialState, action = {}) {
  switch (action.type) {
    /** Fetch Stickies */
    case FETCH_STICKY_NOTES_SUCCESS:
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          error: null,
          sticky: action.stickies || [],
        },
      };
    case FETCH_STICKY_NOTES_FAILURE:
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          error: action.error,
        },
      };
    /** Add Stickies */
    case ADD_STICKY_NOTES_SUCCESS: {
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          sticky: [...state.fetchStickyNotes.sticky, action.sticky],
        },
      };
    }
    case ADD_STICKY_NOTES_FAILURE:
      return {
        ...state,
        addStickyNote: {
          error: action.error,
        },
      };
    case LOGOUT_SUCCESS:
      return initialState;
    /** Delete Sticky */
    case DELETE_STICKY_NOTE_REQUEST: {
      let oldSticky;
      const stickies = state.fetchStickyNotes.sticky.filter((sticky) => {
        if (sticky.id !== action.id) {
          return true;
        }
        oldSticky = sticky;
        return false;
      });
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          sticky: stickies,
        },
        deleteStickyNote: {
          error: null,
          old: oldSticky,
        },
      };
    }
    case DELETE_STICKY_NOTE_SUCCESS:
      return {
        ...state,
        deleteStickyNote: {
          error: null,
          old: null,
        },
      };
    case DELETE_STICKY_NOTE_FAILURE: {
      const oldSticky = state.deleteStickyNote.old;
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          sticky: [...state.fetchStickyNotes.sticky, oldSticky],
        },
        updateSticky: {
          error: null,
          old: null,
        },
      };
    }
    /** Modify Sticky */
    case UPDATE_STICKY_REQUEST: {
      let oldSticky;
      let stickyIndex;
      const stickies = state.fetchStickyNotes.sticky.map((sticky, index) => {
        if (sticky.id !== action.id) {
          return sticky;
        }
        oldSticky = sticky;
        stickyIndex = index;
        return action.sticky;
      });
      if (stickyIndex !== undefined) {
        stickies.push(stickies.splice(stickyIndex, 1)[0]);
      }
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          sticky: stickies,
        },
        updateSticky: {
          error: null,
          old: oldSticky,
        },
      };
    }
    case UPDATE_STICKY_SUCCESS:
      return {
        ...state,
        updateSticky: {
          error: null,
          old: null,
        },
      };
    case UPDATE_STICKY_FAILURE: {
      const oldSticky = state.updateSticky.old;
      const stickies = state.fetchStickyNotes.sticky.map((sticky) => {
        if (sticky.id !== oldSticky.id) {
          return sticky;
        }
        return oldSticky;
      });
      return {
        ...state,
        fetchStickyNotes: {
          ...state.fetchStickyNotes,
          sticky: stickies,
        },
        updateSticky: {
          error: null,
          old: null,
        },
      };
    }
    /** Share Sticky */
    case SHARE_STICKY_REQUEST: {
      return {
        ...state,
        shareStickyLoaded: false,
        shareStickyError: false,
      };
    }
    case SHARE_STICKY_SUCCESS:
      return {
        ...state,
        shareStickyLoaded: true,
        shareStickyError: false,
      };
    case SHARE_STICKY_FAILURE: {
      return {
        ...state,
        shareStickyLoaded: true,
        shareStickyError: action.error,
      };
    }
    /* Fetch Static Notes */
    case FETCH_STATIC_NOTES_REQUEST:
      return {
        ...state,
        fetchStaticNotesLoaded: false,
        fetchStaticNotesError: false,
        createStaticNoteError: false,
        deleteStaticNoteError: false,
      };
    case FETCH_STATIC_NOTES_SUCCESS:
      return {
        ...state,
        fetchStaticNotesLoaded: true,
        fetchStaticNotesError: false,
        staticNotesById: keyBy(action.staticNotes, 'id'),
        staticNotesAllIds: {
          current: action.staticNotes.map((o) => o.id),
          old: null,
        },
      };
    case FETCH_STATIC_NOTES_FAILURE:
      return {
        ...state,
        fetchStaticNotesLoaded: true,
        fetchStaticNotesError: action.errors,
      };
    case CREATE_STATIC_NOTE_REQUEST:
      return {
        ...state,
        createStaticNoteLoaded: false,
        createStaticNoteError: false,
      };
    case CREATE_STATIC_NOTE_SUCCESS:
      return {
        ...state,
        createStaticNoteLoaded: true,
        createStaticNoteError: false,
      };
    case CREATE_STATIC_NOTE_FAILURE:
      return {
        ...state,
        createStaticNoteLoaded: true,
        createStaticNoteError: action.errors,
      };

    case UPDATE_STATIC_NOTES_ORDER_REQUEST:
      return {
        ...state,
        staticNotesAllIds: {
          current: action.newOrderedNotes,
          old: state.staticNotesAllIds.current,
        },
      };

    case UPDATE_STATIC_NOTES_ORDER_SUCCESS:
      return {
        ...state,
        staticNotesAllIds: {
          ...state.staticNotesAllIds,
          old: null,
        },
      };

    case UPDATE_STATIC_NOTES_ORDER_FAILURE:
      return {
        ...state,
        staticNotesAllIds: {
          current: state.staticNotesAllIds.old,
          old: null,
        },
      };

    case NOTES_CLEAR_ERRORS: {
      return {
        ...state,
        createStaticNoteError: false,
        fetchStaticNotesError: false,
        editStaticNoteError: false,
        shareStickyError: false,
      };
    }
    case EDIT_STATIC_NOTE_REQUEST:
      return {
        ...state,
        editStaticNoteLoaded: false,
        editStaticNoteError: false,
      };
    case EDIT_STATIC_NOTE_SUCCESS:
      return {
        ...state,
        editStaticNoteLoaded: true,
        editStaticNoteError: false,
      };
    case EDIT_STATIC_NOTE_FAILURE:
      return {
        ...state,
        editStaticNoteLoaded: true,
        editStaticNoteError: action.errors,
      };
    case REMOVE_STATIC_NOTE_REQUEST: {
      return {
        ...state,
        deleteStaticNoteLoaded: false,
        deleteStaticNoteError: false,
      };
    }
    case REMOVE_STATIC_NOTE_SUCCESS:
      return {
        ...state,
        deleteStaticNoteLoaded: true,
        deleteStaticNoteError: false,
      };
    case REMOVE_STATIC_NOTE_FAILURE:
      return {
        ...state,
        deleteStaticNoteLoaded: true,
        deleteStaticNoteError: action.errors,
      };
    default:
      return state;
  }
}
