import { createIntl, createIntlCache } from 'react-intl';
import translations from '../../i18n/locales';

export default class VocalAssistantUtils {
  static getMonthFromString = (month) => {
    return new Date(Date.parse(`${month} 1, 2012`)).getMonth() + 2;
  };

  static getYesterday = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };

  static getTomorrow = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  static replace = (str, search, replace) => {
    if (!str) return '';
    if (typeof str === 'object') {
      return str.defaultMessage.split(search).join(replace);
    }
    return str.split(search).join(replace);
  };

  static retriveIntl = (lang) => {
    const messages = translations[lang];
    const intl = createIntl(
      {
        locale: lang,
        messages,
      },
      createIntlCache()
    );
    return intl;
  };

  static retrieveMessage = ({ messages, search, replace }, name, lang) => {
    let msg;
    if (typeof messages === 'object') {
      msg = messages[Math.floor(Math.random() * Object.keys(messages).length)];
    } else {
      msg = messages;
    }
    const intl = VocalAssistantUtils.retriveIntl(lang);
    let translatedMsg = intl.formatMessage(msg, name ? { name } : {});
    if (!search || typeof search === 'string') {
      translatedMsg = VocalAssistantUtils.replace(
        translatedMsg,
        search,
        replace
      );
    } else if (search && typeof search === 'object') {
      for (let i = 0; i < search.length; i += 1) {
        translatedMsg = VocalAssistantUtils.replace(
          translatedMsg,
          search[i],
          replace[i]
        );
      }
    }
    return translatedMsg;
  };

  static getLanguageISOcode = (language) => {
    switch (language) {
      case 'en':
        return 'en-GB';
      default:
      case 'it':
        return 'it-IT';
    }
  };
}
