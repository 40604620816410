export class PhoneEnums {
  static PbxType = {
    ABILIS: 'ABILIS',
    NETHESIS: 'NETHESIS',
  };

  static DndStatus = {
    ON: 'DND',
    OFF: 'ADVANCED',
  };

  static PhoneStatuses = {
    offline: 'OFFLINE',
    nophone: 'NOPHONE',
    logging: 'LOGGING',
    logged: 'LOGGED',
    inited: 'INITED',
    error: 'ERROR', // login error
  };

  static UserStatuses = {
    unavailable: 'NOTREADY', // login error
    ready: 'READY', // free to call/be called for intefaces != CTIP
    available: 'ONHOOK', // free to call/be called for intefaces = CTIP
    ringing: 'RINGING', // phone rings, user has been called
    alerting: 'ALERTING', // phone rings, user is calling
    calling: 'OFFHOOK', // active call
    // none: "NULL", // call hangup,
    // conference: "CONFERENCE",
    parked: 'PARKED',
    dnd: 'DND',
  };

  static CallsStatuses = {
    calling: 'CALLING', // pick up the phone
    alerting: 'ALERTING', // the phone is ringing
    active: 'ACTIVE', // active call
    offhook: 'OFFHOOK', // put down the phone
    hold: 'HOLD', // put down the phone
    none: 'NULL', // call hangup
    // lost: "LOST", // call hangup
    callback: 'CALLBACK',
  };

  static QueueType = {
    QUEUE: 'QUEUE',
    PARK: 'PARK',
  };

  static QueueStatus = {
    added: 'ADDED',
    removed: 'REMOVED',
  };

  static Interfaces = {
    CTIP: 'CTIP',
    SIP: 'SIP',
  };

  static Direction = {
    In: 'IN',
    Out: 'OUT',
  };

  static CallsActionsResults = {
    ok: 'ok',
    ko: 'ko',
  };

  static StatusPriorities = {
    NOTREADY: 0,
    NULL: 0,
    READY: 1,
    ONHOOK: 1,
    RINGING: 2,
    ALERTING: 2,
    OFFHOOK: 3,
  };

  static DndTypes = {
    attendedTransfer: 'ATTENDED_TRANSFER',
    blindTransfer: 'BLIND_TRANSFER',
    queueTransfer: 'QUEUE_TRANSFER',
  };
}

export const SIP_DEVICE_NAME = 'com.ambrogio.app';
