import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Popover from '../../../components/Popover';
import Label from '../../../components/formControls/Label';
import { getUsersById } from '../../../js/users/selectors';
import { getGroupsById } from '../../../js/groups/selectors';

const messages = defineMessages({
  header: {
    id: 'AbookListsSharingPopover.header',
    defaultMessage: 'Shared with:',
  },
  users: {
    id: 'AbookListsSharingPopover.users',
    defaultMessage: 'Users',
  },
  groups: {
    id: 'AbookListsSharingPopover.groups',
    defaultMessage: 'Groups',
  },
  unknownUser: {
    id: 'AbookListsSharingPopover.unknownUser',
    defaultMessage: 'Unknown user',
  },
  unknownGroup: {
    id: 'AbookListsSharingPopover.unknownGroup',
    defaultMessage: 'Unknown group',
  },
});

const AbookListsSharingPopover = ({
  sharingUsers,
  sharingGroups,
  isOpen,
  target,
  toggle,
  intl: { formatMessage },
  users,
  groups,
}) => {
  return (
    <Popover
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      header={formatMessage(messages.header)}
    >
      {sharingUsers.length > 0 && (
        <>
          <div id="users" className="pl-1 pt-2">
            <Label for="users">{formatMessage(messages.users)}</Label>
          </div>
          {sharingUsers.map((id) => (
            <span className="ml-1" key={`user_${id}`}>
              {users && users[id]
                ? users[id].fullname
                : formatMessage(messages.unknownUser)}
            </span>
          ))}
        </>
      )}
      {sharingGroups.length > 0 && (
        <>
          <div id="groups" className="pl-1 pt-2">
            <Label for="groups">{formatMessage(messages.groups)}</Label>
          </div>
          {sharingGroups.map((id) => (
            <span className="ml-1" key={`group_${id}`}>
              {groups && groups[id]
                ? groups[id].name
                : formatMessage(messages.unknownGroup)}
            </span>
          ))}
        </>
      )}
    </Popover>
  );
};

AbookListsSharingPopover.propTypes = {
  sharingUsers: PropTypes.array,
  sharingGroups: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

AbookListsSharingPopover.defaultProps = {
  sharingUsers: [],
  sharingGroups: [],
};

function mapStateToProps(state) {
  return {
    users: getUsersById(state),
    groups: getGroupsById(state),
  };
}

export default injectIntl(connect(mapStateToProps)(AbookListsSharingPopover));
