import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import ynConf from '../conf';

const messages = defineMessages({
  genericError: {
    id: 'ErrorBoundary.error.genericError',
    defaultMessage:
      'Something, somewhere went terribly wrong. Come back in this section later',
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    if (this.state.hasError) {
      return (
        <div className="p-4 rounded bg-white text-center w-100 h-100">
          <img
            src={`${ynConf.publicFolderPath}/404.svg`}
            className="mt-4"
            alt="Error"
            height="50%"
          />
          <div className="alert alert-danger m-4" role="alert">
            {formatMessage(messages.genericError)}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);
