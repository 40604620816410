import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCachedContact } from '../../../js/contacts/selectors';
import { fetchAbookNumberRequest } from '../../../js/contacts/actions';
import AddContactButton from '../../../components/buttons/AddContactButton';
import ContactAvatar from '../../../components/ContactAvatar';
import Fullname from '../../../components/Fullname';
import { addressbookGrant } from '../../../js/me/selectors';

const SMSRecipient = ({
  id,
  recipient,
  contact,
  contactGrant,
  fetchNumber,
  ellipsis,
}) => {
  if (!contact && contactGrant) {
    fetchNumber({ number: recipient });
  }
  return contact && contact.fullname ? (
    <span>
      <ContactAvatar
        size="sm"
        src={contact.avatar}
        alt={contact.fullname}
        className="mr-1"
        id={contact.id}
        withPopover
        imageSize="sm"
      />
      {ellipsis ? (
        <Fullname fullname={contact.fullname} id={`${id}-${contact.id}`} />
      ) : (
        contact.fullname
      )}
    </span>
  ) : (
    <span>
      {recipient}
      {contactGrant && <AddContactButton number={recipient} />}
    </span>
  );
};

SMSRecipient.propTypes = {
  recipient: PropTypes.string.isRequired,
  ellipsis: PropTypes.bool,
};

SMSRecipient.defaultProps = {
  ellipsis: false,
};

function mapStateToProps(state, ownProps) {
  return {
    contact: getCachedContact(state, ownProps.recipient),
    contactGrant: addressbookGrant(state),
  };
}

export default connect(mapStateToProps, {
  fetchNumber: fetchAbookNumberRequest,
})(SMSRecipient);
