import { put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  FETCH_PINNED_GROUPS_REQUEST,
  FETCH_ADMIN_GROUPS_REQUEST,
  FETCH_GROUP_REQUEST,
  UPDATE_PINNED_GROUPS_ORDER_REQUEST,
  UPDATE_PINNED_GROUP_SETTINGS_REQUEST,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST,
  UPDATE_GROUP_SETTINGS_REQUEST,
  SAVE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  FETCH_GROUP_MEMBERS_REQUEST,
  UPDATE_CHAT_GROUP_REQUEST,
  CREATE_CHAT_GROUP_REQUEST,
} from './types';
import {
  fetchPinnedGroupsSuccess,
  fetchPinnedGroupsFailure,
  fetchAdminGroupsSuccess,
  fetchAdminGroupsFailure,
  fetchGroupSuccess,
  fetchGroupFailure,
  saveGroupSuccess,
  saveGroupFailure,
  deleteGroupSuccess,
  deleteGroupFailure,
  updateGroupSettingsSuccess,
  updateGroupSettingsFailure,
  updatePinnedGroupsOrderSuccess,
  updatePinnedGroupsOrderFailure,
  updatePinnedGroupSettingsSuccess,
  updatePinnedGroupSettingsFailure,
  updatePinnedGroupMembersOrderSuccess,
  updatePinnedGroupMembersOrderFailure,
  fetchGroupMembersSuccess,
  fetchGroupMembersFailure,
  updateChatGroupSuccess,
  updateChatGroupFailure,
  createChatGroupFailure,
  createChatGroupSuccess,
} from './actions';
import { getFavoritesGroupId, getMainGroupId } from './selectors';
import api from '../api';
import { fetchAbookContactSuccess } from '../contacts/actions';
import { getContactsById } from '../contacts/selectors';
import { getUserById, getUsersById } from '../users/selectors';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { GROUP_COLORS } from './GroupsUtils';
import { generateConversationId } from '../chat/ChatUtils';
import history from '../../history';

export function* fetchPinnedGroups() {
  try {
    const res = yield call(api.me.getMeGroups);
    yield call(checkApiResponse, res);
    yield put(fetchPinnedGroupsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPinnedGroupsFailure(error));
  }
}

export function* fetchAdminGroups(action) {
  try {
    const res = yield call(api.groups.getAdminGroups, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchAdminGroupsSuccess({
          groups: [],
          total: 0,
          page: action.payload.page,
        })
      );
    } else {
      yield put(
        fetchAdminGroupsSuccess({
          groups: res.data.data,
          total: res.data.total,
          page: action.payload.page,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAdminGroupsFailure(error));
  }
}

export function* fetchGroup(action) {
  try {
    const res = yield call(api.groups.getGroup, action.groupId);
    yield call(checkApiResponse, res);
    yield put(fetchGroupSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchGroupFailure(error));
  }
}

export function* saveGroup(action) {
  try {
    const res = yield call(
      action.group.id ? api.groups.updateGroup : api.groups.createGroup,
      action.group
    );

    yield call(checkApiResponse, res);
    yield put(
      saveGroupSuccess({ ...action.group, id: action.group.id || res.data.id })
    );
    if (history.location.pathname.includes(`administration/groups/edit`))
      yield put(push('/administration/groups'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveGroupFailure(error));
  }
}

export function* deleteGroup(action) {
  try {
    const res = yield call(api.groups.deleteGroup, action.groupId);
    yield call(checkApiResponse, res);
    yield put(deleteGroupSuccess(action.groupId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteGroupFailure(error));
  }
}

export function* updateGroupSettings(action) {
  try {
    const res = yield call(api.me.updateMyGroupSettings, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      updateGroupSettingsSuccess({
        id: action.payload.id,
        settings: action.payload.settings,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateGroupSettingsFailure(error));
  }
}

export function* updatePinnedGroupsOrder(action) {
  try {
    const favouriteId = yield select(getFavoritesGroupId);
    const mainId = yield select(getMainGroupId);
    const filteredGroups = action.groups.filter(
      (groupId) => groupId !== favouriteId && groupId !== mainId
    );
    const res = yield call(api.me.updateMyGroupsOrder, filteredGroups);
    yield call(checkApiResponse, res);
    yield put(updatePinnedGroupsOrderSuccess(action.groups));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePinnedGroupsOrderFailure(error));
  }
}

export function* updatePinnedGroupSettings(action) {
  try {
    const res = yield call(api.me.updateMyGroupSettings, action.payload);
    yield call(checkApiResponse, res);
    yield put(
      updatePinnedGroupSettingsSuccess({
        id: action.payload.id,
        settings: action.payload.settings,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePinnedGroupSettingsFailure(error));
  }
}

export function* updatePinnedGroupMembersOrder(action) {
  try {
    const res = yield call(api.me.updateMyGroupMembersOrder, action.group);
    yield call(checkApiResponse, res);
    yield put(
      updatePinnedGroupMembersOrderSuccess({
        id: action.group.id,
        settings: action.group.settings,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(updatePinnedGroupMembersOrderFailure(error, action.group.id));
  }
}

export function* fetchGroupMembers(action) {
  try {
    const res = yield call(api.me.getMeGroupMembers, action.groupId);
    yield call(checkApiResponse, res);
    const members = [];
    for (let i = 0; i < res.data.length; i += 1) {
      if (!res.data[i].id) {
        continue;
      }
      if (res.data[i].type === 'U') {
        const user = yield select(getUserById, res.data[i].id);
        members.push({ ...user, ...res.data[i] });
      } else if (res.data[i].type === 'A') {
        const contacts = yield select(getContactsById);
        let contact = contacts[res.data[i].id];
        if (!contact) {
          const response = yield call(api.contacts.getContact, res.data[i].id);
          if (response.status < 200 || response.status >= 300) {
            throw response.status;
          }
          contact = response.data;
          yield put(fetchAbookContactSuccess(contact));
        }
        members.push({ ...contact, ...res.data[i] });
      }
    }
    yield put(fetchGroupMembersSuccess({ id: action.groupId, members }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchGroupMembersFailure(error));
  }
}

function* updateChatGroup(action) {
  const { groupId, name, addedMembers, removedMembers } = action.payload;
  // const myId = yield select(getMeId);
  try {
    const res = yield call(api.groups.updateChatGroup, {
      id: groupId,
      name,
      addedMembers,
      removedMembers,
    });
    yield call(checkApiResponse, res);
    let members = [];
    if (addedMembers) {
      for (let i = 0; i < addedMembers.length; i += 1) {
        // if (addedMembers[i] !== myId) {
        const user = yield select(getUserById, addedMembers[i]);
        members = [...members, { ...user, type: 'U' }];
        // }
      }
    }
    yield put(
      updateChatGroupSuccess({ ...action.payload, addedMembers: members })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateChatGroupFailure(groupId));
  }
}

export function* createChatGroup(action) {
  try {
    const users = yield select(getUsersById);
    const name = `* ${action.members
      .map((id) => users[id].fullname)
      .join(', ')}`;
    const group = {
      name,
      color: GROUP_COLORS[0],
      xmpp: true,
      private: true,
      members: action.members.map((id) => ({ type: 'U', id })),
      mine: true,
      favorites: false,
      main: false,
      hidden: false,
      status: 'MIN',
    };
    const res = yield call(api.groups.createGroup, group);
    yield call(checkApiResponse, res);
    yield put(saveGroupSuccess({ ...group, id: res.data.id }));
    yield put(
      createChatGroupSuccess({
        conversationId: generateConversationId({ groupId: res.data.id }),
        byMe: true,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(createChatGroupFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_PINNED_GROUPS_REQUEST, fetchPinnedGroups);
  yield takeLatest(FETCH_ADMIN_GROUPS_REQUEST, fetchAdminGroups);
  yield takeLatest(FETCH_GROUP_REQUEST, fetchGroup);
  yield takeLatest(UPDATE_GROUP_SETTINGS_REQUEST, updateGroupSettings);
  yield takeLatest(
    UPDATE_PINNED_GROUP_SETTINGS_REQUEST,
    updatePinnedGroupSettings
  );
  yield takeLatest(UPDATE_PINNED_GROUPS_ORDER_REQUEST, updatePinnedGroupsOrder);
  yield takeLatest(
    UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST,
    updatePinnedGroupMembersOrder
  );
  yield takeLatest(SAVE_GROUP_REQUEST, saveGroup);
  yield takeLatest(DELETE_GROUP_REQUEST, deleteGroup);
  yield takeEvery(FETCH_GROUP_MEMBERS_REQUEST, fetchGroupMembers);
  yield takeEvery(UPDATE_CHAT_GROUP_REQUEST, updateChatGroup);
  yield takeEvery(CREATE_CHAT_GROUP_REQUEST, createChatGroup);
}
