import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import union from 'lodash.union';
import sortBy from 'lodash.sortby';
import moment from 'moment';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_HOTEL_AREA_PREFIXES_REQUEST,
  FETCH_HOTEL_AREA_PREFIXES_SUCCESS,
  FETCH_HOTEL_AREA_PREFIXES_FAILURE,
  FETCH_HOTEL_PRICES_REQUEST,
  FETCH_HOTEL_PRICES_SUCCESS,
  FETCH_HOTEL_PRICES_FAILURE,
  SAVE_HOTEL_PRICES_REQUEST,
  SAVE_HOTEL_PRICES_SUCCESS,
  SAVE_HOTEL_PRICES_FAILURE,
  DELETE_HOTEL_PRICE_REQUEST,
  DELETE_HOTEL_PRICE_SUCCESS,
  DELETE_HOTEL_PRICE_FAILURE,
  RESET_HOTEL_PRICES_REQUEST,
  RESET_HOTEL_PRICES_FAILURE,
  SET_HOTEL_CALLS_FILTER,
  FETCH_HOTEL_CALLS_REQUEST,
  FETCH_HOTEL_CALLS_SUCCESS,
  FETCH_HOTEL_CALLS_FAILURE,
  TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS,
  TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST,
  TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE,
  FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST,
  FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE,
  UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST,
  UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE,
  FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST,
  FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS,
  FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE,
  SET_HOTEL_ACTIVE_WAKEUPS_FILTER,
  SET_HOTEL_ARCHIVE_WAKEUPS_FILTER,
  SAVE_HOTEL_WAKEUP_REQUEST,
  SAVE_HOTEL_WAKEUP_FAILURE,
  SAVE_HOTEL_WAKEUP_SUCCESS,
  DELETE_HOTEL_WAKEUP_SUCCESS,
  DELETE_HOTEL_WAKEUP_REQUEST,
  DELETE_HOTEL_WAKEUP_FAILURE,
  FETCH_HOTEL_WAKEUP_ANSWER_REQUEST,
  FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS,
  FETCH_HOTEL_WAKEUP_ANSWER_FAILURE,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE,
} from './types';
import { INSERT_CALLS_NOTES_SUCCESS } from '../cdr/types';

const setFilterStart = () => {
  const start = moment().toDate();
  start.setDate(start.getDate() - 1);
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  start.setMilliseconds(0);
  return start;
};

const setFilterEnd = () => {
  return moment().toDate();
};

const initialState = {
  fetchHotelAreaPrefixesLoaded: true,
  fetchHotelAreaPrefixesError: false,
  fetchHotelPricesLoaded: true,
  fetchHotelPricesError: false,
  deleteHotelPriceLoaded: true,
  deleteHotelPriceError: false,
  saveHotelPriceLoaded: true,
  saveHotelPriceError: false,
  resetHotelPricesLoaded: true,
  resetHotelPricesError: false,
  fetchHotelCallsLoaded: true,
  fetchHotelCallsError: false,
  toggleHotelWakeupServiceLoaded: true,
  toggleHotelWakeupServiceError: false,
  fetchHotelWakeupSettingsLoaded: true,
  fetchHotelWakeupSettingsError: false,
  updateHotelWakeupSettingsLoaded: true,
  updateHotelWakeupSettingsError: false,
  updateHotelWakeupSettingsSuccess: false,
  fetchHotelWakeupsLoaded: true,
  fetchHotelWakeupsError: false,
  saveHotelWakeupLoaded: true,
  saveHotelWakeupError: false,
  deleteHotelWakeupLoaded: true,
  deleteHotelWakeupError: false,
  fetchHotelWakeupAnswerLoaded: true,
  fetchHotelWakeupAnswerError: false,
  areaPrefixes: [],
  prices: {
    byId: {},
    allIds: [],
  },
  cdr: {
    filter: {
      start: setFilterStart(),
      end: setFilterEnd(),
      extensions: [],
    },
    calls: [],
  },
  wakeupSettings: {},
  activeWakeupsFilter: {
    users: [],
    day: '',
  },
  archiveWakeupsFilter: {
    users: [],
    startDay: '',
    endDay: '',
  },
  wakeups: {
    byId: {},
    allIds: [],
  },
  lastRoute: null,
};

export default function hotel(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_HOTEL_AREA_PREFIXES_REQUEST:
      return {
        ...state,
        fetchHotelAreaPrefixesLoaded: false,
        fetchHotelAreaPrefixesError: false,
      };
    case FETCH_HOTEL_AREA_PREFIXES_SUCCESS:
      return {
        ...state,
        areaPrefixes: sortBy(action.prefixes, 'prefix'),
        fetchHotelAreaPrefixesLoaded: true,
        fetchHotelAreaPrefixesError: false,
      };
    case FETCH_HOTEL_AREA_PREFIXES_FAILURE:
      return {
        ...state,
        fetchHotelAreaPrefixesLoaded: true,
        fetchHotelAreaPrefixesError: true,
      };
    case FETCH_HOTEL_PRICES_REQUEST:
      return {
        ...state,
        fetchHotelPricesLoaded: false,
        fetchHotelPricesError: false,
      };
    case FETCH_HOTEL_PRICES_SUCCESS:
      return {
        ...state,
        prices: {
          byId: keyBy(action.prices, 'id'),
          allIds: action.prices.map((obj) => obj.id),
        },
        fetchHotelPricesLoaded: true,
        fetchHotelPricesError: false,
        resetHotelPricesLoaded: true,
      };
    case FETCH_HOTEL_PRICES_FAILURE:
      return {
        ...state,
        fetchHotelPricesLoaded: true,
        fetchHotelPricesError: true,
      };
    case SAVE_HOTEL_PRICES_REQUEST:
      return {
        ...state,
        saveHotelPriceLoaded: false,
        saveHotelPriceError: false,
      };
    case SAVE_HOTEL_PRICES_SUCCESS:
      return {
        ...state,
        prices: {
          byId: {
            ...state.prices.byId,
            [action.price.id]: action.price,
          },
          allIds: union(state.prices.allIds, [action.price.id]),
        },
        saveHotelPriceLoaded: true,
        saveHotelPriceError: false,
      };

    case SAVE_HOTEL_PRICES_FAILURE:
      return {
        ...state,
        saveHotelPriceLoaded: true,
        saveHotelPriceError: true,
      };
    case DELETE_HOTEL_PRICE_REQUEST:
      return {
        ...state,
        deleteHotelPriceLoaded: false,
        deleteHotelPriceError: false,
      };
    case DELETE_HOTEL_PRICE_SUCCESS: {
      const byId = { ...state.prices.byId };
      const allIds = [...state.prices.allIds];
      delete byId[action.id];
      pull(allIds, action.id);
      return {
        ...state,
        prices: {
          byId,
          allIds,
        },
        deleteHotelPriceLoaded: true,
        deleteHotelPriceError: false,
      };
    }
    case DELETE_HOTEL_PRICE_FAILURE:
      return {
        ...state,
        deleteHotelPriceLoaded: true,
        deleteHotelPriceError: true,
      };
    case RESET_HOTEL_PRICES_REQUEST: {
      return {
        ...state,
        resetHotelPricesLoaded: false,
        resetHotelPricesError: false,
      };
    }
    case RESET_HOTEL_PRICES_FAILURE:
      return {
        ...state,
        resetHotelPricesLoaded: true,
        resetHotelPricesError: true,
      };
    case SET_HOTEL_CALLS_FILTER:
      return {
        ...state,
        cdr: {
          ...state.cdr,
          filter: action.filter,
        },
      };
    case FETCH_HOTEL_CALLS_REQUEST:
      return {
        ...state,
        fetchHotelCallsLoaded: false,
        fetchHotelCallsError: false,
      };
    case FETCH_HOTEL_CALLS_SUCCESS:
      return {
        ...state,
        fetchHotelCallsLoaded: true,
        fetchHotelCallsError: false,
        cdr: {
          ...state.cdr,
          calls: action.calls,
        },
      };
    case FETCH_HOTEL_CALLS_FAILURE:
      return {
        ...state,
        fetchHotelCallsLoaded: true,
        fetchHotelCallsError: true,
      };
    case INSERT_CALLS_NOTES_SUCCESS: {
      if (action.context !== 'hotel') {
        return state;
      }
      return {
        ...state,
        cdr: {
          ...state.cdr,
          calls: state.cdr.calls.map((call) => {
            if (call.id === action.params.call) {
              call.notes = call.notes
                ? [
                    ...call.notes,
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ]
                : [
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ];
              return { ...call };
            }
            return call;
          }),
        },
      };
    }
    case TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST:
      return {
        ...state,
        toggleHotelWakeupServiceLoaded: false,
        toggleHotelWakeupServiceError: false,
      };
    case TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS:
      return {
        ...state,
        toggleHotelWakeupServiceLoaded: true,
        toggleHotelWakeupServiceError: false,
      };
    case TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE:
      return {
        ...state,
        toggleHotelWakeupServiceLoaded: true,
        toggleHotelWakeupServiceError: true,
      };
    case FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST:
      return {
        ...state,
        fetchHotelWakeupSettingsLoaded: false,
        fetchHotelWakeupSettingsError: false,
      };
    case FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchHotelWakeupSettingsLoaded: true,
        fetchHotelWakeupSettingsError: false,
        wakeupSettings: action.settings,
      };
    case FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE:
      return {
        ...state,
        fetchHotelWakeupSettingsLoaded: true,
        fetchHotelWakeupSettingsError: true,
      };
    case UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST:
      return {
        ...state,
        updateHotelWakeupSettingsLoaded: false,
        updateHotelWakeupSettingsError: false,
        updateHotelWakeupSettingsSuccess: false,
      };
    case UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS:
      return {
        ...state,
        updateHotelWakeupSettingsLoaded: true,
        updateHotelWakeupSettingsError: false,
        updateHotelWakeupSettingsSuccess: true,
        wakeupSettings: action.settings,
      };
    case UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE:
      return {
        ...state,
        updateHotelWakeupSettingsLoaded: true,
        updateHotelWakeupSettingsError: true,
        updateHotelWakeupSettingsSuccess: false,
      };
    case FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST:
    case FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST:
      return {
        ...state,
        fetchHotelWakeupsLoaded: false,
        fetchHotelWakeupsError: false,
      };
    case FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS:
    case FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS:
      return {
        ...state,
        fetchHotelWakeupsLoaded: true,
        fetchHotelWakeupsError: false,
        wakeups: {
          byId: keyBy(action.wakeups, 'id'),
          allIds: action.wakeups.map((obj) => obj.id),
        },
      };
    case FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE:
    case FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE:
      return {
        ...state,
        fetchHotelWakeupsLoaded: true,
        fetchHotelWakeupsError: true,
      };
    case SAVE_HOTEL_WAKEUP_REQUEST:
      return {
        ...state,
        saveHotelWakeupLoaded: false,
        saveHotelWakeupError: false,
      };
    case SAVE_HOTEL_WAKEUP_SUCCESS:
      return {
        ...state,
        saveHotelWakeupLoaded: true,
        saveHotelWakeupError: false,
      };

    case SAVE_HOTEL_WAKEUP_FAILURE:
      return {
        ...state,
        saveHotelWakeupLoaded: true,
        saveHotelWakeupError: true,
      };
    case DELETE_HOTEL_WAKEUP_REQUEST:
      return {
        ...state,
        deleteHotelWakeupLoaded: false,
        deleteHotelWakeupError: false,
      };
    case DELETE_HOTEL_WAKEUP_SUCCESS: {
      /*const byId = { ...state.wakeups.byId };
      const allIds = [...state.wakeups.allIds];
      delete byId[action.id];
      pull(allIds, action.id);*/
      return {
        ...state,
        /*wakeups: {
          byId,
          allIds,
        },*/
        deleteHotelWakeupLoaded: true,
        deleteHotelWakeupeError: false,
      };
    }
    case DELETE_HOTEL_WAKEUP_FAILURE:
      return {
        ...state,
        deleteHotelWakeupLoaded: true,
        deleteHotelWakeupError: true,
      };
    case SET_HOTEL_ACTIVE_WAKEUPS_FILTER:
      return {
        ...state,
        activeWakeupsFilter: action.filter,
      };
    case SET_HOTEL_ARCHIVE_WAKEUPS_FILTER:
      return {
        ...state,
        archiveWakeupsFilter: action.filter,
      };
    case FETCH_HOTEL_WAKEUP_ANSWER_REQUEST:
      return {
        ...state,
        fetchHotelWakeupAnswerLoaded: false,
        fetchHotelWakeupAnswerError: false,
      };
    case FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS:
      return {
        ...state,
        wakeups: {
          ...state.wakeups,
          byId: {
            ...state.wakeups.byId,
            [action.answer.id]: {
              ...state.wakeups.byId[action.answer.id],
              answered: action.answer.status,
            },
          },
        },
        fetchHotelWakeupAnswerLoaded: true,
        fetchHotelWakeupAnswerError: false,
      };
    case FETCH_HOTEL_WAKEUP_ANSWER_FAILURE:
      return {
        ...state,
        fetchHotelWakeupAnswerLoaded: true,
        fetchHotelWakeupAnswerError: true,
      };
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      return {
        ...state,
        lastRoute: newRoute[0],
      };
    }
    case LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
