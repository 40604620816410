import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSEdit from './SMSEdit';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const SMSEditPage = ({
  location,
  match: {
    params: { id },
  },
}) => (
  <SMS>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="sms-edit" />
          </div>
        </div>
        <SMSEdit location={location} id={+id} />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSEditPage;
