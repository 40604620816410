import { request } from '../request';

export default {
  getFiles: (params) =>
    request({
      url: `/chat/files`,
      method: 'GET',
      params,
    }),
  getHistory: (params) =>
    request({
      url: '/chat/messages',
      method: 'GET',
      params,
    }),
  getConversation: (params) =>
    request({
      url: '/chat/conversation',
      method: 'GET',
      params,
    }),
  searchText: (text, params) =>
    request({
      url: `/chat/search/${encodeURIComponent(text)}`,
      method: 'GET',
      params,
    }),
  getScheduledMessages: () =>
    request({
      url: `/chat/scheduled-messages`,
      method: 'GET',
    }),
  createScheduledMessage: (params) =>
    request({
      url: `/chat/scheduled-messages`,
      method: 'POST',
      data: params,
    }),
  deleteScheduledMessage: (id) =>
    request({
      url: `/chat/scheduled-messages/${id}`,
      method: 'DELETE',
    }),
  updateScheduledMessage: (params) =>
    request({
      url: `/chat/scheduled-messages/${params.id}`,
      method: 'POST',
      data: params,
    }),
  getImportantMessages: (params) =>
    request({
      url: `/chat/important-messages`,
      method: 'GET',
      params,
    }),
  getMutedChats: () =>
    request({
      url: `/chat/muted`,
      method: 'GET',
    }),
  muteUnmuteChat: (data) =>
    request({
      url: `/chat/mute`,
      method: 'POST',
      data,
    }),
};
