import React from 'react';
import PropTypes from 'prop-types';

export const classicStyle = {
  width: '270px',
  height: '270px',
  maxFontSize: 30,
  menuStyle: {
    padding: '4px 0 1px 10px',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    position: 'absolute',
    top: '8px',
    left: '7px',
    width: '81%',
    height: '27px',
    display: 'flex',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '35px',
    left: '9px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '80%',
    height: '61%',
    padding: '6px',
    top: '34px',
    left: '9px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '200px',
    left: '166px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '198px',
    left: '200px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '12px',
    left: '200px',
  },
};

export const ClassicShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 150 150">
      <rect
        style={{ fill: `${color}`, padding: '10px' }}
        filter={`url(#sticky-filter-${id})`}
        width="120"
        height="120"
        x="5"
        y="5"
      />
      <filter
        id={`sticky-filter-${id}`}
        x="-1px"
        y="-1px"
        width="210%"
        height="210%"
      >
        <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </svg>
  </>
);

ClassicShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
