import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import { defineMessages } from 'react-intl';
import {
  fetchFaxSettingsSuccess,
  fetchFaxSettingsFailure,
  fetchFaxListSuccess,
  fetchFaxListFailure,
  deleteFaxSuccess,
  deleteFaxFailure,
  reloadFaxListRequest,
  changeFaxListPageRequest,
  readFaxSuccess,
  readFaxFailure,
  viewFaxDetailsFailure,
  viewFaxDetailsSuccess,
  saveFaxSettingsSuccess,
  saveFaxSettingsFailure,
  sendFaxSuccess,
  sendFaxFailure,
  refreshFaxStatusSuccess,
  refreshFaxStatusFailure,
} from './actions';
import {
  FETCH_FAX_LIST_REQUEST,
  CHANGE_FAX_LIST_PAGE_REQUEST,
  DELETE_FAX_REQUEST,
  DELETE_FAX_SUCCESS,
  RELOAD_FAX_LIST_REQUEST,
  READ_FAX_REQUEST,
  VIEW_FAX_DETAILS_REQUEST,
  SAVE_FAX_SETTINGS_REQUEST,
  SEND_FAX_REQUEST,
  REFRESH_FAX_STATUS_REQUEST,
  FAX_SENT_NOTIFICATION,
  FAX_RECEIVED_NOTIFICATION,
  FAX_ERROR_NOTIFICATION,
} from './types';
import api from '../api';
import { getDatetimeFormat } from '../settings/selectors';
import {
  getFaxListFilter,
  getFaxTotal,
  getFaxCurrentPage,
  isFaxActive,
} from './selectors';
import { FaxEnums } from './FaxUtils';
import { getInterlocutorData } from '../phone/sagas';
import { INIT_MAIN_SUCCESS } from '../me/types';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import history from '../../history';
import Utils from '../lib/utils';

const intlStrings = defineMessages({
  sentFax: {
    id: 'sentFax',
    defaultMessage: 'Your fax has been sent succesfully',
  },
  errorFax: {
    id: 'errorFax',
    defaultMessage: 'Error sending fax',
  },
  receivedFax: {
    id: 'receivedFax',
    defaultMessage: 'You have received a fax',
  },
});

function* fetchFaxNumbers() {
  const faxActive = yield select(isFaxActive);
  if (faxActive) {
    try {
      const res = yield call(api.fax.getFaxNumbers);
      yield call(checkApiResponse, res);
      yield put(fetchFaxSettingsSuccess(res.data || []));
    } catch (err) {
      const error = yield call(checkApiError, err);
      if (error) yield put(fetchFaxSettingsFailure(error));
    }
  }
}

function* fetchFaxList(action) {
  const dateFormat = yield select(getDatetimeFormat);
  const filters = yield select(getFaxListFilter, action.context);
  const pageNumber = yield select(getFaxCurrentPage, action.context);
  try {
    const res = yield call(api.fax.getFaxList, {
      type: action.context,
      start: filters.start
        ? moment(filters.start, dateFormat).format('YYYYMMDD')
        : null,
      end: filters.end
        ? moment(filters.end, dateFormat).format('YYYYMMDD')
        : null,
      sender: filters.sender,
      receiver: filters.receiver,
      page: pageNumber,
      pageSize: FaxEnums.FAX_PAGE_SIZE,
      subject: filters.subject,
    });
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchFaxListSuccess({ total: 0, data: [] }, action.context));
    } else {
      // retrieve interlocutor for each fax (in series)
      const interlocutors = res.data.data.map((faxData) =>
        call(
          getInterlocutorData,
          action.context === FaxEnums.FaxType.SEND
            ? faxData.numberReceiver
            : faxData.numberSender,
          false
        )
      );

      for (let i = 0; i < interlocutors.length; i += 1) {
        const response = yield interlocutors[i];
        res.data.data[i].interlocutor = response;
      }
      yield put(fetchFaxListSuccess(res.data, action.context));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFaxListFailure(error, action.context));
  }
}

function* deleteFax(action) {
  try {
    const res = yield call(api.fax.updateFax, action.payload.faxId, {
      deleted: true,
    });
    yield call(checkApiResponse, res);
    yield put(deleteFaxSuccess(action.payload.context));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteFaxFailure(error, action.payload.context));
  }
}

function* reloadList(action) {
  const pageNumber = yield select(getFaxCurrentPage, action.context);
  const total = yield select(getFaxTotal, action.context);
  if (Math.ceil((total - 1) / FaxEnums.FAX_PAGE_SIZE) < pageNumber + 1) {
    yield put(
      changeFaxListPageRequest(
        pageNumber === 0 ? 0 : pageNumber - 1,
        action.context
      )
    );
  } else {
    yield put(reloadFaxListRequest(action.context));
  }
}

function* readFax(action) {
  try {
    const res = yield call(api.fax.updateFax, action.faxId, {
      read: true,
    });
    yield call(checkApiResponse, res);
    yield put(readFaxSuccess(action.faxId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(readFaxFailure(error));
  }
}

function* loadFaxDetails(action) {
  try {
    const res = yield call(api.fax.loadFaxDetails, action.faxId);
    yield call(checkApiResponse, res);
    res.data.interlocutor = yield call(
      getInterlocutorData,
      action.context === FaxEnums.FaxType.SEND
        ? res.data.numberReceiver
        : res.data.numberSender,
      false
    );
    yield put(viewFaxDetailsSuccess(res.data, action.context));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(viewFaxDetailsFailure(error, action.context));
  }
}

function* saveFaxSettings(action) {
  try {
    const res = yield call(api.fax.saveFaxSettings, action.payload.id, {
      receiverEmails: action.payload.receiving,
      senderEmails: action.payload.sending,
    });
    yield call(checkApiResponse, res);
    yield put(saveFaxSettingsSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveFaxSettingsFailure(error));
  }
}

function* sendFax(action) {
  try {
    const res = yield call(api.fax.sendFax, action.payload);
    yield call(checkApiResponse, res);
    yield put(sendFaxSuccess());
    yield put(push('/fax/sent?reload=true'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(sendFaxFailure(error));
  }
}

function* refreshFaxStatus(action) {
  try {
    const res = yield call(api.fax.refreshFaxStatus, action.faxId);
    yield call(checkApiResponse, res);
    yield put(
      refreshFaxStatusSuccess({ status: res.data.status, faxId: action.faxId })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(refreshFaxStatusFailure(error));
  }
}

export function* notifySentFax(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.SENT_FAX}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.sentFax),
      group: NotificationEnums.NotificationGroups.SENT_FAX,
      image: NotificationEnums.NotificationImages.FAX_SENT,
      onclick: () => {
        history.push('/fax/notifications');
      },
    });
  }
}

export function* notifyErrorFax(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ERROR_FAX}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.errorFax),
      group: NotificationEnums.NotificationGroups.ERROR_FAX,
      image: NotificationEnums.NotificationImages.FAX_SENT,
      onclick: () => {
        history.push('/fax/notifications');
      },
    });
  }
}

export function* notifyReceivedFax(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.RECEIVED_FAX}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.receivedFax),
      group: NotificationEnums.NotificationGroups.RECEIVED_FAX,
      image: NotificationEnums.NotificationImages.FAX_RECEIVED,
      onclick: () => {
        history.push('/fax/notifications');
      },
    });
  }
}

/** RootSaga */
export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, fetchFaxNumbers);
  yield takeLatest(FETCH_FAX_LIST_REQUEST, fetchFaxList);
  yield takeLatest(CHANGE_FAX_LIST_PAGE_REQUEST, fetchFaxList);
  yield takeLatest(RELOAD_FAX_LIST_REQUEST, fetchFaxList);
  yield takeLatest(DELETE_FAX_REQUEST, deleteFax);
  yield takeLatest(DELETE_FAX_SUCCESS, reloadList);
  yield takeLatest(READ_FAX_REQUEST, readFax);
  yield takeLatest(VIEW_FAX_DETAILS_REQUEST, loadFaxDetails);
  yield takeLatest(SAVE_FAX_SETTINGS_REQUEST, saveFaxSettings);
  yield takeLatest(SEND_FAX_REQUEST, sendFax);
  yield takeLatest(REFRESH_FAX_STATUS_REQUEST, refreshFaxStatus);
  yield takeEvery(FAX_SENT_NOTIFICATION, notifySentFax);
  yield takeEvery(FAX_ERROR_NOTIFICATION, notifyErrorFax);
  yield takeEvery(FAX_RECEIVED_NOTIFICATION, notifyReceivedFax);
}
