import React, { Component } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import {
  enterVideocallRequest,
  leaveVideocallSuccess,
  sendGuestEmailAddressRequest,
} from '../../js/videocalls/actions';
import {
  getSendGuestEmailAddressLoaded,
  getSendGuestEmailAddressSuccess,
  getSendGuestEmailAddressError,
  getVideocallJwt,
  getCurrentVideocallRoom,
  getEnterVideocallError,
} from '../../js/videocalls/selectors';
import Input from '../../components/formControls/Input';
import Button from '../../components/formControls/Button';
import Videocalls from '../sections/Videocall/index';
import ToastMessage from '../../components/messages/ToastMessage';
import VideocallGuestTemplate from './VideocallGuestTemplate';
import ynConf from '../../conf';
import {
  getCommonVideocallButton,
  VideocallsEnums,
} from '../../js/videocalls/VideocallsUtils';
const { remote } = window;

const messages = defineMessages({
  sendEmailText1: {
    id: 'VideocallGuestExec.button.sendEmailText1',
    defaultMessage: 'Wanna know more about Youneed?',
  },
  sendEmailText2: {
    id: 'VideocallGuestExec.button.sendEmailText2',
    defaultMessage: "Leave your email and we'll contact you soon!",
  },
  noEmailAddress: {
    id: 'VideocallGuestExec.error.noEmailAddress',
    defaultMessage: 'Please insert an email address',
  },
  wrongEmailAddress: {
    id: 'VideocallGuestExec.error.wrongEmailAddress',
    defaultMessage: 'Please insert a valid email address',
  },
  sendEmailSuccess: {
    id: 'VideocallGuestExec.sendEmailSuccess',
    defaultMessage: 'Email sent successfully',
  },
  sendEmailError: {
    id: 'VideocallGuestExec.sendEmailError',
    defaultMessage: 'Error while sending email',
  },
  sendEmail: {
    id: 'VideocallGuestExec.button.sendEmail',
    defaultMessage: 'Send',
  },
  successSendingEmail: {
    id: 'VideocallGuestExec.error.successSendingEmail',
    defaultMessage: 'Email sent successfully',
  },
  errorSendingEmail: {
    id: 'VideocallGuestExec.error.errorSendingEmail',
    defaultMessage: 'Error while sending email',
  },
  enterVideocallError: {
    id: 'VideocallGuestExec.enterVideocallError',
    defaultMessage: 'Error while entering in the room',
  },
  INACTIVE_ROOM: {
    id: 'VideocallGuestExec.error.INACTIVE_ROOM',
    defaultMessage: 'The room is no more active',
  },
  TOO_EARLY: {
    id: 'VideocallGuestExec.error.TOO_EARLY',
    defaultMessage: 'You have to wait to enter in the room',
  },
  403: {
    id: 'VideocallGuestExec.error.403',
    defaultMessage: 'You are not allowed to enter into this room',
  },
});

class VideocallGuestExec extends Component {
  state = {
    email: '',
    error: null,
    finished: false,
  };

  componentDidMount() {
    const videocallRoom = sessionStorage
      .getItem('videocallRoom')
      .substring(sessionStorage.getItem('videocallRoom').lastIndexOf('/') + 1);
    sessionStorage.removeItem('videocallRoom');
    this.props.enterVideocall({
      room: videocallRoom,
      guestName: this.props.name,
      guestUid: `G${uuidv1()}`,
      guest: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.videocallRoom &&
      this.props.videocallJwt &&
      !prevProps.videocallJwt
    ) {
      const buttons = getCommonVideocallButton();
      if (!remote) {
        buttons.push(VideocallsEnums.VideocallsButtons.DESKTOP);
      }
      // eslint-disable-next-line no-undef
      const api = new JitsiMeetExternalAPI('8x8.vc', {
        roomName: `${ynConf.jitsi.appId}/${this.props.videocallRoom}`,
        parentNode: document.querySelector('#jaas-container'),
        jwt: this.props.videocallJwt,
        configOverwrite: {
          toolbarButtons: buttons,
        },
        lang: 'it',
      });
      api.addListener('videoConferenceLeft', () => {
        const node = document.getElementById('jaas-container');
        if (node) {
          node.innerHTML = '';
        }
        this.props.leaveVideocall();
        this.setState({ finished: true });
      });
    }
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value, error: null });
  };

  sendEmail = () => {
    const { email } = this.state;
    const {
      sendEmail,
      intl: { formatMessage },
    } = this.props;
    if (!email || email === '') {
      this.setState({ error: formatMessage(messages.noEmailAddress) });
      return;
    }
    if (!isEmail(email)) {
      this.setState({
        error: formatMessage(messages.wrongEmailAddress),
      });
      return;
    }
    sendEmail(email);
  };

  render() {
    const {
      sendingGuestEmail,
      sendGuestEmailSuccess,
      sendGuestEmailError,
      videocallError,
      intl: { formatMessage },
    } = this.props;
    const { email, error } = this.state;

    return (
      <VideocallGuestTemplate>
        <Videocalls>
          {videocallError && (
            <ToastMessage
              type="danger"
              text={formatMessage(
                messages[videocallError]
                  ? messages[videocallError]
                  : messages.enterVideocallError
              )}
              closeTimeout={5000}
            />
          )}
          {this.props.videocallRoom && (
            <div id="jaas-container" style={{ height: '100%' }}></div>
          )}
          {this.state.finished && sendGuestEmailSuccess && (
            <div
              style={{
                height: '30%',
              }}
              className="alert alert-success p-2 mx-5 text-center"
            >
              {formatMessage(messages.successSendingEmail)}
            </div>
          )}
          {this.state.finished && !sendGuestEmailSuccess && (
            <>
              {sendGuestEmailError && (
                <ToastMessage
                  type="danger"
                  closeTimeout={2000}
                  text={formatMessage(messages.errorSendingEmail)}
                />
              )}
              <div
                style={{
                  height: '95%',
                }}
                className="alert alert-info p-2 mx-3 text-center"
              >
                <div>{formatMessage(messages.sendEmailText1)}</div>
                <div
                  className="p-2 form-inline"
                  style={{ justifyContent: 'center' }}
                >
                  <Input
                    type="text"
                    id="VideocallGuestEmail"
                    name="VideocallGuestEmail"
                    value={email}
                    onChange={this.handleEmailChange}
                    error={error}
                  />
                  <Button
                    className="btn btn-primary ml-2"
                    onClick={this.sendEmail}
                    loading={sendingGuestEmail}
                    text={formatMessage(messages.sendEmail)}
                  />
                </div>
                <div>{formatMessage(messages.sendEmailText2)}</div>
              </div>
            </>
          )}
        </Videocalls>
      </VideocallGuestTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    videocallJwt: getVideocallJwt(state),
    videocallRoom: getCurrentVideocallRoom(state),
    videocallError: getEnterVideocallError(state),
    sendingGuestEmail: !getSendGuestEmailAddressLoaded(state),
    sendGuestEmailSuccess: getSendGuestEmailAddressSuccess(state),
    sendGuestEmailError: getSendGuestEmailAddressError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    leaveVideocall: leaveVideocallSuccess,
    enterVideocall: enterVideocallRequest,
    sendEmail: sendGuestEmailAddressRequest,
  })(VideocallGuestExec)
);
