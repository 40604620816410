import React from 'react';
import Payments from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const Transactions = React.lazy(() => import('./Transactions'));
const TransactionsPage = (props) => (
  <Payments scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <Transactions {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Payments>
);

export default TransactionsPage;
