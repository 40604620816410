import {
  FETCH_CONVERSATIONS_LIST_SUCCESS,
  FETCH_CONVERSATIONS_LIST_FAILURE,
  SEND_CHAT_MESSAGE_REQUEST,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  SEND_CHAT_MESSAGE_CC,
  RECEIVE_CHAT_MESSAGE,
  SEND_DELIVERY_MESSAGE_SUCCESS,
  SEND_DELIVERY_MESSAGE_FAILURE,
  SEND_DELIVERY_MESSAGE_CC,
  RECEIVE_DELIVERY_MESSAGE,
  SEND_READ_MESSAGE_REQUEST,
  SEND_READ_MESSAGE_SUCCESS,
  SEND_READ_MESSAGE_FAILURE,
  RECEIVE_READ_MESSAGE_CC,
  RECEIVE_READ_MESSAGE,
  SEND_READ_ALL_MESSAGE_REQUEST,
  SEND_READ_ALL_MESSAGE_SUCCESS,
  SEND_READ_ALL_MESSAGE_FAILURE,
  SEND_READ_ALL_MESSAGE_CC,
  SEND_DELETE_MESSAGES_REQUEST,
  SEND_DELETE_MESSAGES_SUCCESS,
  SEND_DELETE_MESSAGES_FAILURE,
  SEND_DELETE_MESSAGES_CC,
  SEND_ARCHIVE_CONVERSATION_REQUEST,
  SEND_ARCHIVE_CONVERSATION_SUCCESS,
  SEND_ARCHIVE_CONVERSATION_FAILURE,
  SEND_ARCHIVE_CONVERSATION_CC,
  SEND_UNARCHIVE_CONVERSATION_REQUEST,
  SEND_UNARCHIVE_CONVERSATION_SUCCESS,
  SEND_UNARCHIVE_CONVERSATION_FAILURE,
  SEND_UNARCHIVE_CONVERSATION_CC,
  SEND_DELETE_ALL_MESSAGES_REQUEST,
  SEND_DELETE_ALL_MESSAGES_SUCCESS,
  SEND_DELETE_ALL_MESSAGES_FAILURE,
  SEND_DELETE_ALL_MESSAGES_CC,
  OPEN_CHAT_CONVERSATION,
  MINIMIZE_CHAT_CONVERSATION,
  MAXIMIZE_CHAT_CONVERSATION,
  CLOSE_CHAT_CONVERSATION,
  FETCH_CONVERSATION_HISTORY_REQUEST,
  FETCH_CONVERSATION_HISTORY_SUCCESS,
  FETCH_CONVERSATION_HISTORY_FAILURE,
  SET_MAX_OPEN_CHAT_WINDOWS,
  NORMALIZE_CHAT_CONVERSATION,
  SET_CONVERSATIONS_LIST_FILTER,
  SEND_COMPOSING_STATUS,
  SEND_PAUSED_STATUS,
  RECEIVE_COMPOSING_STATUS,
  RECEIVE_PAUSED_STATUS,
  FETCH_CONVERSATION_FILES_REQUEST,
  FETCH_CONVERSATION_FILES_SUCCESS,
  FETCH_CONVERSATION_FILES_FAILURE,
  SET_ACTIVE_CHAT_WINDOW,
  UNSET_ACTIVE_CHAT_WINDOW,
  CHANGE_CONVERSATION_FILES_PAGE_REQUEST,
  REMOVE_TIMED_MESSAGE_SUCCESS,
  READ_SELF_TIMED_MESSAGE,
  REFRESH_CONVERSATION_SUCCESS,
  REFRESH_CONVERSATION_FAILURE,
  RECEIVE_CHAT_INFO_MESSAGE,
  FETCH_CHAT_SEARCH_SUCCESS,
  FETCH_CHAT_SEARCH_FAILURE,
  FETCH_OTHER_CHAT_RESULTS_REQUEST,
  FETCH_OTHER_CHAT_RESULTS_SUCCESS,
  FETCH_OTHER_CHAT_RESULTS_FAILURE,
  OPEN_CHAT_SEARCH_RESULT_REQUEST,
  OPEN_CHAT_SEARCH_RESULT_FAILURE,
  OPEN_CHAT_SEARCH_RESULT_SUCCESS,
  SET_CHAT_SEARCH_RESULT,
  UNSET_CHAT_SEARCH_RESULT,
  SEND_CHAT_FILE_PREVIEW,
  SEND_CHAT_FILE_REQUEST,
  FETCH_IMPORTANT_MESSAGES_REQUEST,
  FETCH_IMPORTANT_MESSAGES_SUCCESS,
  FETCH_IMPORTANT_MESSAGES_FAILURE,
  MARK_IMPORTANT_MESSAGE_REQUEST,
  MARK_IMPORTANT_MESSAGE_SUCCESS,
  MARK_IMPORTANT_MESSAGE_FAILURE,
  MARK_UNIMPORTANT_MESSAGE_REQUEST,
  MARK_UNIMPORTANT_MESSAGE_SUCCESS,
  MARK_UNIMPORTANT_MESSAGE_FAILURE,
  REPLY_MESSAGE_REQUEST,
  REMOVE_REPLY_MESSAGE_REQUEST,
  FETCH_SCHEDULED_MESSAGES_REQUEST,
  FETCH_SCHEDULED_MESSAGES_SUCCESS,
  FETCH_SCHEDULED_MESSAGES_FAILURE,
  SAVE_SCHEDULED_MESSAGE_REQUEST,
  SAVE_SCHEDULED_MESSAGE_SUCCESS,
  SAVE_SCHEDULED_MESSAGE_FAILURE,
  DELETE_SCHEDULED_MESSAGE_REQUEST,
  DELETE_SCHEDULED_MESSAGE_SUCCESS,
  DELETE_SCHEDULED_MESSAGE_FAILURE,
  MARK_IMPORTANT_MESSAGE_CC,
  MARK_UNIMPORTANT_MESSAGE_CC,
  GET_MUTED_CHATS_REQUEST,
  GET_MUTED_CHATS_SUCCESS,
  GET_MUTED_CHATS_FAILURE,
  MUTE_UNMUTE_CHAT_REQUEST,
  MUTE_UNMUTE_CHAT_SUCCESS,
  MUTE_UNMUTE_CHAT_FAILURE,
  CLEAR_CHAT_ERROR,
} from './types';

export const fetchConversationsListSuccess = (conversations) => ({
  type: FETCH_CONVERSATIONS_LIST_SUCCESS,
  conversations,
});

export const fetchConversationsListFailure = (errors) => ({
  type: FETCH_CONVERSATIONS_LIST_FAILURE,
  errors,
});

export const sendChatMessageRequest = (data) => ({
  type: SEND_CHAT_MESSAGE_REQUEST,
  data,
});

export const sendChatMessageSuccess = (data) => ({
  type: SEND_CHAT_MESSAGE_SUCCESS,
  data,
});

export const sendChatMessageFailure = (ackUid) => ({
  type: SEND_CHAT_MESSAGE_FAILURE,
  ackUid,
});

export const sendChatMessageCC = (data) => ({
  type: SEND_CHAT_MESSAGE_CC,
  data,
});

export const receiveChatMessage = (data) => ({
  type: RECEIVE_CHAT_MESSAGE,
  data,
});

export const sendDeliveryMessageSuccess = () => ({
  type: SEND_DELIVERY_MESSAGE_SUCCESS,
});

export const sendDeliveryMessageFailure = () => ({
  type: SEND_DELIVERY_MESSAGE_FAILURE,
});

export const sendDeliveryMessageCC = (data) => ({
  type: SEND_DELIVERY_MESSAGE_CC,
  data,
});

export const receiveDeliveryMessage = (data) => ({
  type: RECEIVE_DELIVERY_MESSAGE,
  data,
});

export const sendReadMessageRequest = (data) => ({
  type: SEND_READ_MESSAGE_REQUEST,
  data,
});

export const sendReadMessageSuccess = (data) => ({
  type: SEND_READ_MESSAGE_SUCCESS,
  data,
});

export const sendReadMessageFailure = () => ({
  type: SEND_READ_MESSAGE_FAILURE,
});

export const receiveReadMessageCC = (data) => ({
  type: RECEIVE_READ_MESSAGE_CC,
  data,
});

export const receiveReadMessage = (data) => ({
  type: RECEIVE_READ_MESSAGE,
  data,
});

export const sendReadAllMessageRequest = (interlocutor) => ({
  type: SEND_READ_ALL_MESSAGE_REQUEST,
  interlocutor,
});

export const sendReadAllMessageSuccess = (data) => ({
  type: SEND_READ_ALL_MESSAGE_SUCCESS,
  data,
});

export const sendReadAllMessageFailure = (data) => ({
  type: SEND_READ_ALL_MESSAGE_FAILURE,
  data,
});

export const sendReadAllMessageCC = (data) => ({
  type: SEND_READ_ALL_MESSAGE_CC,
  data,
});

export const sendDeleteMessagesRequest = (payload) => ({
  type: SEND_DELETE_MESSAGES_REQUEST,
  payload,
});

export const sendDeleteMessagesSuccess = (data) => ({
  type: SEND_DELETE_MESSAGES_SUCCESS,
  data,
});

export const sendDeleteMessagesFailure = (data) => ({
  type: SEND_DELETE_MESSAGES_FAILURE,
  data,
});

export const sendDeleteMessagesCC = (data) => ({
  type: SEND_DELETE_MESSAGES_CC,
  data,
});

export const sendDeleteAllMessageRequest = (interlocutor) => ({
  type: SEND_DELETE_ALL_MESSAGES_REQUEST,
  interlocutor,
});

export const sendDeleteAllMessageSuccess = (data) => ({
  type: SEND_DELETE_ALL_MESSAGES_SUCCESS,
  data,
});

export const sendDeleteAllMessageFailure = (data) => ({
  type: SEND_DELETE_ALL_MESSAGES_FAILURE,
  data,
});

export const sendDeleteAllMessageCC = (data) => ({
  type: SEND_DELETE_ALL_MESSAGES_CC,
  data,
});

export const sendArchiveConversationRequest = (interlocutor) => ({
  type: SEND_ARCHIVE_CONVERSATION_REQUEST,
  interlocutor,
});

export const sendArchiveConversationSuccess = (data) => ({
  type: SEND_ARCHIVE_CONVERSATION_SUCCESS,
  data,
});

export const sendArchiveConversationFailure = (data) => ({
  type: SEND_ARCHIVE_CONVERSATION_FAILURE,
  data,
});

export const sendArchiveConversationCC = (data) => ({
  type: SEND_ARCHIVE_CONVERSATION_CC,
  data,
});

export const sendUnarchiveConversationRequest = (interlocutor) => ({
  type: SEND_UNARCHIVE_CONVERSATION_REQUEST,
  interlocutor,
});

export const sendUnarchiveConversationSuccess = (data) => ({
  type: SEND_UNARCHIVE_CONVERSATION_SUCCESS,
  data,
});

export const sendUnarchiveConversationFailure = (data) => ({
  type: SEND_UNARCHIVE_CONVERSATION_FAILURE,
  data,
});

export const sendUnarchiveConversationCC = (data) => ({
  type: SEND_UNARCHIVE_CONVERSATION_CC,
  data,
});

export const openChatConversation = (payload) => ({
  type: OPEN_CHAT_CONVERSATION,
  payload,
});

export const minimizeChatConversation = (conversationId) => ({
  type: MINIMIZE_CHAT_CONVERSATION,
  conversationId,
});

export const normalizeChatConversation = (conversationId) => ({
  type: NORMALIZE_CHAT_CONVERSATION,
  conversationId,
});

export const maximizeChatConversation = (conversationId) => ({
  type: MAXIMIZE_CHAT_CONVERSATION,
  conversationId,
});

export const closeChatConversation = (conversationId) => ({
  type: CLOSE_CHAT_CONVERSATION,
  conversationId,
});

export const fetchConversationHistoryRequest = (payload) => ({
  type: FETCH_CONVERSATION_HISTORY_REQUEST,
  payload,
});

export const fetchConversationHistorySuccess = (data) => ({
  type: FETCH_CONVERSATION_HISTORY_SUCCESS,
  data,
});

export const fetchConversationHistoryFailure = (conversationId) => ({
  type: FETCH_CONVERSATION_HISTORY_FAILURE,
  conversationId,
});

export const setMaxOpenChatWindows = (max) => ({
  type: SET_MAX_OPEN_CHAT_WINDOWS,
  max,
});

export const setConversationsListFilter = (data) => ({
  type: SET_CONVERSATIONS_LIST_FILTER,
  data,
});

export const sendComposingStatus = (recipient) => ({
  type: SEND_COMPOSING_STATUS,
  recipient,
});

export const sendPausedStatus = (recipient) => ({
  type: SEND_PAUSED_STATUS,
  recipient,
});

export const receiveComposingStatus = (payload) => ({
  type: RECEIVE_COMPOSING_STATUS,
  payload,
});

export const receivePausedStatus = (payload) => ({
  type: RECEIVE_PAUSED_STATUS,
  payload,
});

export const fetchConversationFilesRequest = (payload) => ({
  type: FETCH_CONVERSATION_FILES_REQUEST,
  payload,
});

export const fetchConversationFilesSuccess = (payload) => ({
  type: FETCH_CONVERSATION_FILES_SUCCESS,
  payload,
});

export const fetchConversationFilesFailure = (errors) => ({
  type: FETCH_CONVERSATION_FILES_FAILURE,
  errors,
});

export const setActiveChatWindow = (conversationId) => ({
  type: SET_ACTIVE_CHAT_WINDOW,
  conversationId,
});

export const unsetActiveChatWindow = () => ({
  type: UNSET_ACTIVE_CHAT_WINDOW,
});
export const changeConversationFilesPageRequest = (payload) => ({
  type: CHANGE_CONVERSATION_FILES_PAGE_REQUEST,
  payload,
});
export const removeTimedMessageSuccess = (data) => ({
  type: REMOVE_TIMED_MESSAGE_SUCCESS,
  data,
});
export const readSelfTimedMessageRequest = (data) => ({
  type: READ_SELF_TIMED_MESSAGE,
  data,
});
export const refreshConversationSuccess = (payload) => ({
  type: REFRESH_CONVERSATION_SUCCESS,
  payload,
});
export const refreshConversationFailure = (conversationId) => ({
  type: REFRESH_CONVERSATION_FAILURE,
  conversationId,
});
export const receiveChatInfoMessage = (data) => ({
  type: RECEIVE_CHAT_INFO_MESSAGE,
  data,
});
export const fetchChatSearchSuccess = (payload) => ({
  type: FETCH_CHAT_SEARCH_SUCCESS,
  payload,
});
export const fetchChatSearchFailure = () => ({
  type: FETCH_CHAT_SEARCH_FAILURE,
});
export const fetchOtherChatResultsRequest = (timestamp) => ({
  type: FETCH_OTHER_CHAT_RESULTS_REQUEST,
  timestamp,
});
export const fetchOtherChatResultsSuccess = (payload) => ({
  type: FETCH_OTHER_CHAT_RESULTS_SUCCESS,
  payload,
});
export const fetchOtherChatResultsFailure = () => ({
  type: FETCH_OTHER_CHAT_RESULTS_FAILURE,
});
export const openChatSearchResultRequest = (payload) => ({
  type: OPEN_CHAT_SEARCH_RESULT_REQUEST,
  payload,
});
export const openChatSearchResultSuccess = (payload) => ({
  type: OPEN_CHAT_SEARCH_RESULT_SUCCESS,
  payload,
});
export const openChatSearchResultFailure = (payload) => ({
  type: OPEN_CHAT_SEARCH_RESULT_FAILURE,
  payload,
});
export const setChatSearchResult = (messageId) => ({
  type: SET_CHAT_SEARCH_RESULT,
  messageId,
});
export const unsetChatSearchResult = () => ({
  type: UNSET_CHAT_SEARCH_RESULT,
});
export const sendChatFilePreview = (data) => ({
  type: SEND_CHAT_FILE_PREVIEW,
  data,
});
export const sendChatFileRequest = (data) => ({
  type: SEND_CHAT_FILE_REQUEST,
  data,
});

export const fetchImportantMessagesRequest = (payload) => ({
  type: FETCH_IMPORTANT_MESSAGES_REQUEST,
  payload,
});

export const fetchImportantMessagesSuccess = (data) => ({
  type: FETCH_IMPORTANT_MESSAGES_SUCCESS,
  data,
});

export const fetchImportantMessagesFailure = (error) => ({
  type: FETCH_IMPORTANT_MESSAGES_FAILURE,
  error,
});
//
export const markImportantMessageRequest = (payload) => ({
  type: MARK_IMPORTANT_MESSAGE_REQUEST,
  payload,
});

export const markImportantMessageCc = (payload) => ({
  type: MARK_IMPORTANT_MESSAGE_CC,
  payload,
});

export const markImportantMessageSuccess = (payload) => ({
  type: MARK_IMPORTANT_MESSAGE_SUCCESS,
  payload,
});

export const markImportantMessageFailure = (error) => ({
  type: MARK_IMPORTANT_MESSAGE_FAILURE,
  error,
});
//
export const markUnImportantMessageRequest = (payload) => ({
  type: MARK_UNIMPORTANT_MESSAGE_REQUEST,
  payload,
});

export const markUnImportantMessageCc = (payload) => ({
  type: MARK_UNIMPORTANT_MESSAGE_CC,
  payload,
});

export const markUnImportantMessageSuccess = (payload) => ({
  type: MARK_UNIMPORTANT_MESSAGE_SUCCESS,
  payload,
});

export const markUnImportantMessageFailure = (error) => ({
  type: MARK_UNIMPORTANT_MESSAGE_FAILURE,
  error,
});
//
export const replyMessageRequest = (payload) => ({
  type: REPLY_MESSAGE_REQUEST,
  payload,
});

export const removeReplyMessageRequest = (payload) => ({
  type: REMOVE_REPLY_MESSAGE_REQUEST,
  payload,
});
export const fetchScheduledMessagesRequest = () => ({
  type: FETCH_SCHEDULED_MESSAGES_REQUEST,
});
export const fetchScheduledMessagesSuccess = (payload) => ({
  type: FETCH_SCHEDULED_MESSAGES_SUCCESS,
  payload,
});
export const fetchScheduledMessagesFailure = () => ({
  type: FETCH_SCHEDULED_MESSAGES_FAILURE,
});

export const updateScheduledMessageRequest = (data) => ({
  type: SAVE_SCHEDULED_MESSAGE_REQUEST,
  data,
});
export const updateScheduledMessageSuccess = (payload) => ({
  type: SAVE_SCHEDULED_MESSAGE_SUCCESS,
  payload,
});
export const updateScheduledMessageFailure = () => ({
  type: SAVE_SCHEDULED_MESSAGE_FAILURE,
});

export const deleteScheduledMessageRequest = (id) => ({
  type: DELETE_SCHEDULED_MESSAGE_REQUEST,
  id,
});
export const deleteScheduledMessageSuccess = (id) => ({
  type: DELETE_SCHEDULED_MESSAGE_SUCCESS,
  id,
});
export const deleteScheduledMessageFailure = () => ({
  type: DELETE_SCHEDULED_MESSAGE_FAILURE,
});

export const getMutedChatsRequest = () => ({
  type: GET_MUTED_CHATS_REQUEST,
});
export const getMutedChatsSuccess = (data) => ({
  type: GET_MUTED_CHATS_SUCCESS,
  data,
});
export const getMutedChatsFailure = (error) => ({
  type: GET_MUTED_CHATS_FAILURE,
  error,
});

export const muteUnmuteChatRequest = (payload, conversationId) => ({
  type: MUTE_UNMUTE_CHAT_REQUEST,
  payload,
  conversationId,
});
export const muteUnmuteChatSuccess = (conversationId) => ({
  type: MUTE_UNMUTE_CHAT_SUCCESS,
  conversationId,
});
export const muteUnmuteChatFailure = (error, conversationId) => ({
  type: MUTE_UNMUTE_CHAT_FAILURE,
  error,
  conversationId,
});

export const clearChatErrors = (conversationId) => ({
  type: CLEAR_CHAT_ERROR,
  conversationId,
});
