/* eslint-disable max-classes-per-file */
import { WsEnums } from '../websocket/WsUtils';
import WsManager from '../websocket/WsManager';
import {
  userUpdateNotification,
  userStatusChangeNotification,
} from '../users/actions';
import {
  groupUpdateNotification,
  groupInsertNotification,
  groupDeleteNotification,
} from '../groups/actions';
import {
  calendarReminderNotification,
  calendarInsertNotification,
  calendarUpdateNotification,
  calendarDeleteNotification,
  calendarDragNotification,
  calendarAddedParticipantNotification,
  calendarRemovedParticipantNotification,
} from '../calendar/actions';
import {
  abookUpdateNotification,
  abookInsertNotification,
  abookDeleteNotification,
  abookImportNotification,
  abookBulkDeleteNotification,
  abookImportBatchNotification,
} from '../contacts/actions';
import {
  faxSentNotification,
  faxReceivedNotification,
  faxErrorNotification,
} from '../fax/actions';
import {
  mailofficePriceNotification,
  mailofficeErrorNotification,
  mailofficeCompleteNotification,
} from '../mailoffice/actions';
import {
  meReceivedVboxNotification,
  pbxReceivedVboxNotification,
  deliveredTvoxNotification,
} from '../vbox/actions';
import {
  insertVacationNotification,
  deleteVacationNotification,
  askDeletingVacationNotification,
  confirmVacationNotification,
  rejectVacationNotification,
  confirmDeletingVacationNotification,
  rejectDeletingVacationNotification,
  insertPunchingNotification,
  confirmPunchingNotification,
  rejectPunchingNotification,
} from '../presence/actions';
import {
  insertTicketNotification,
  deleteTicketNotification,
  closeTicketNotification,
  assignTicketNotification,
  takeTicketNotification,
  workTicketNotification,
  changeStatusTicketNotification,
  suspendTicketNotification,
  shareTicketNotification,
  unshareTicketNotification,
  deadlineTicketNotification,
  reopenTicketNotification,
  insertAssigningTicketNotification,
  editTicketNotification,
  unassignTicketNotification,
} from '../tickets/actions';
import ynConf from '../../conf';
import {
  newFileCommentFileboxNotification,
  newFileFileboxNotification,
  unshareFolderFileboxNotification,
  shareFolderFileboxNotification,
} from '../filebox/actions';
import {
  deleteStickyNotification,
  editStickyNotification,
  shareStickyNotification,
} from '../sticky/actions';
import { videocallReminderNotification } from '../videocalls/actions';

export class NotificationEnums {
  static NotificationGroups = {
    TEST: 'TEST',
    INCOMING_CALLS: 'INCOMING_CALLS',
    LOST_CALLS: 'LOST_CALLS',
    ONLINE_USERS: 'ONLINE_USERS',
    CALENDAR_REMINDER: 'CALENDAR_REMINDER',
    RECEIVED_MESSAGE: 'RECEIVED_MESSAGE',
    INCOMING_VIDEOCALLS: 'INCOMING_VIDEOCALLS',
    LOST_VIDEOCALLS: 'LOST_VIDEOCALLS',
    PRICE_MAILOFFICE: 'PRICE_MAILOFFICE',
    ERROR_MAILOFFICE: 'ERROR_MAILOFFICE',
    COMPLETED_MAILOFFICE: 'COMPLETED_MAILOFFICE',
    RECEIVED_VBOX: 'RECEIVED_VBOX',
    DELIVERED_TVOX: 'DELIVERED_TVOX',
    SENT_FAX: 'SENT_FAX',
    ERROR_FAX: 'ERROR_FAX',
    RECEIVED_FAX: 'RECEIVED_FAX',
    INSERT_VACATION: 'INSERT_VACATION',
    DELETE_VACATION: 'DELETE_VACATION',
    ASK_DELETING_VACATION: 'ASK_DELETING_VACATION',
    CONFIRM_VACATION: 'CONFIRM_VACATION',
    REJECT_VACATION: 'REJECT_VACATION',
    CONFIRM_DELETING_VACATION: 'CONFIRM_DELETING_VACATION',
    REJECT_DELETING_VACATION: 'REJECT_DELETING_VACATION',
    INSERT_PUNCHING: 'INSERT_PUNCHING',
    CONFIRM_PUNCHING: 'CONFIRM_PUNCHING',
    REJECT_PUNCHING: 'REJECT_PUNCHING',
    ASSIGN_TICKET: 'ASSIGN_TICKET',
    CLOSE_TICKET: 'CLOSE_TICKET',
    VIDEOCALL_REMINDER: 'VIDEOCALL_REMINDER',
  };

  static NotificationImages = {
    CALL_INCOMING: `${ynConf.publicFolderPath}/notifications/incomingCall.jpg`,
    CALL_MISSED: `${ynConf.publicFolderPath}/notifications/missedCall.jpg`,
    FAX_SENT: `${ynConf.publicFolderPath}/notifications/sentFax.jpg`,
    FAX_ERROR: `${ynConf.publicFolderPath}/notifications/sentFax.jpg`,
    FAX_RECEIVED: `${ynConf.publicFolderPath}/notifications/receivedFax.jpg`,
    VBOX_RECEIVED: `${ynConf.publicFolderPath}/notifications/vbox.jpg`,
    PBX_TVOX_RECEIVED: `${ynConf.publicFolderPath}/notifications/switchboard.jpg`,
    PBX_VBOX_RECEIVED: `${ynConf.publicFolderPath}/notifications/switchboard.jpg`,
    CHAT_PUBLIC_MESSAGE: `${ynConf.publicFolderPath}/notifications/publicChatMessage.jpg`,
    CHAT_GROUP_MESSAGE: `${ynConf.publicFolderPath}/notifications/publicGroupMessage.jpg`,
    MAILOFFICE_SENT: `${ynConf.publicFolderPath}/notifications/mailoffice.jpg`,
    MAILOFFICE_PRICED: `${ynConf.publicFolderPath}/notifications/mailoffice.jpg`,
    MAILOFFICE_ERROR: `${ynConf.publicFolderPath}/notifications/mailoffice.jpg`,
    CALENDAR_REMINDER: `${ynConf.publicFolderPath}/notifications/calendar.jpg`,
    VIDEOCALL_REMINDER: `${ynConf.publicFolderPath}/notifications/videocall.jpg`,
    VACATION: `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`,
    PUNCHING: `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`,
    VALIDATION: `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`,
    TICKET: `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`,
    FILEBOX: `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`,
  };

  static NotificationPermissions = {
    ALLOW: 'ALLOW',
    DEFAULT: 'DEFAULT',
    DENY: 'DENY',
  };

  static NotificationsTopics = {
    CALENDAR: 'CALENDAR',
    ABOOK: 'ABOOK',
    GROUPS: 'GROUPS',
    USERS: 'USERS',
    FAX: 'FAX',
    MEVBOX: 'MEVBOX',
    PBXVBOX: 'PBXVBOX',
    MAILOFFICE: 'MAILOFFICE',
    VACATION: 'VACATION',
    VALIDATION: 'VALIDATION',
    PUNCHING: 'PUNCHING',
    TICKET: 'TICKET',
    FILEBOX: 'FILEBOX',
    STICKY: 'STICKY',
    VIDEOCALL: 'VIDEOCALL',
  };

  static NotificationsSections = {
    CALENDAR: 'CALENDAR',
    ABOOK: 'ABOOK',
    ADMIN: 'ADMIN',
    FAX: 'FAX',
    MEVBOX: 'MEVBOX',
    PBXVBOX: 'PBXVBOX',
    MAILOFFICE: 'MAILOFFICE',
    VALIDATION: 'VALIDATION',
    PUNCHING: 'PUNCHING',
    TICKET: 'TICKET',
    FILEBOX: 'FILEBOX',
    STICKY: 'STICKY',
  };

  static NotificationsActions = {
    CHANGE_STATUS: 'CHANGE_STATUS',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
    CHANGE_TOPIC_STATUS: 'CHANGE_TOPIC_STATUS',
    DELETE_TOPIC: 'DELETE_TOPIC',
  };

  static NotificationsGroupsActions = {
    INSERT: 'INSERT',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
  };

  static NotificationsAbookActions = {
    INSERT: 'INSERT',
    DELETE: 'DELETE',
    UPDATE: 'UPDATE',
    IMPORT: 'IMPORT',
    BULK_DELETE: 'BULK_DELETE',
    IMPORT_BATCH_COMPLETED: 'IMPORT_BATCH_COMPLETED',
  };

  static NotificationsCalendarActions = {
    INSERT: 'INSERT',
    DELETE: 'DELETE',
    DRAG: 'DRAG',
    REMOVED_FROM_EVENT: 'REMOVED_FROM_EVENT',
    ADDED_TO_EVENT: 'ADDED_TO_EVENT',
    UPDATE: 'UPDATE',
    REMINDER: 'REMINDER',
    INSERT_APPOINTMENT: 'INSERT_APPOINTMENT',
    UPDATE_APPOINTMENT: 'UPDATE_APPOINTMENT',
    DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
  };

  static NotificationsFaxActions = {
    RECEIVED_FAX: 'RECEIVED_FAX',
    SENT_FAX: 'SENT_FAX',
    ERROR_FAX: 'ERROR_FAX',
  };

  static NotificationsMailofficeActions = {
    PRICE: 'PRICE',
    ERROR: 'ERROR',
    COMPLETED: 'COMPLETED',
  };

  static NotificationsVboxActions = {
    RECEIVED_VBOX: 'RECEIVED_VBOX',
    DELIVERED_TVOX: 'DELIVERED_TVOX',
  };

  static NotificationsValidationActions = {
    INSERT_VACATION: 'INSERT_VACATION',
    DELETE_VACATION: 'DELETE_VACATION',
    ASK_DELETING_VACATION: 'ASK_DELETING_VACATION',
    INSERT_PUNCHING: 'INSERT_PUNCHING',
  };

  static NotificationsVacationActions = {
    CONFIRM: 'CONFIRM',
    REJECT: 'REJECT',
    CONFIRM_DELETING: 'CONFIRM_DELETING',
    REJECT_DELETING: 'REJECT_DELETING',
  };

  static NotificationsPunchingActions = {
    CONFIRM: 'CONFIRM',
    REJECT: 'REJECT',
  };

  static NotificationsTicketActions = {
    INSERT: 'INSERT',
    INSERT_ASSIGNING: 'INSERT_ASSIGNING',
    INSERT_ASSIGNING_YOU: 'INSERT_ASSIGNING_YOU',
    INSERT_ASSIGNING_USER: 'INSERT_ASSIGNING_USER',
    INSERT_ASSIGNING_GROUP: 'INSERT_ASSIGNING_GROUP',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
    CLOSE: 'CLOSE',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    TAKE: 'TAKE',
    WORK: 'WORK',
    CHANGE_STATUS: 'CHANGE_STATUS',
    SUSPEND: 'SUSPEND',
    SHARE: 'SHARE',
    UNSHARE: 'UNSHARE',
    DEADLINE: 'DEADLINE',
    REOPEN: 'REOPEN',
  };

  static NotificationsFileboxActions = {
    NEW_FILE: 'NEW_FILE',
    NEW_FILE_COMMENT: 'NEW_FILE_COMMENT',
    SHARE_FOLDER: 'SHARE_FOLDER',
    UNSHARE_FOLDER: 'UNSHARE_FOLDER',
  };

  static NotificationsStickyActions = {
    SHARE: 'SHARE',
    EDIT: 'EDIT',
    DELETE: 'DELETE',
  };

  static NotificationsVideocallActions = {
    REMINDER: 'REMINDER',
  };

  static NotificationsStatus = {
    TO_BE_SENT: 0,
    SENT: 1,
    RECEIVED: 2,
    READ: 3,
  };
}

export class NotificationSettings {
  static DEFAULT_TITLE = 'YouNeed';

  static DEFAULT_IMAGE = `${ynConf.publicFolderPath}/notifications/defaultImage.jpg`;

  static DEFAULT_VOLUME = 50;

  static NOTIFICATION_SOUND = `${ynConf.publicFolderPath}/notifications/notify.mp3`;
}

export const sendDeleteNotification = (notificationId, topic) => {
  WsManager.sendMessage({
    channel: WsEnums.Channels.NOTIFICATIONS,
    action: NotificationEnums.NotificationsActions.DELETE_NOTIFICATION,
    notificationId,
    topic,
  });
};

const manageCalendarNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.CALENDAR,
    idUserAction: data.idUserAction,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsCalendarActions.REMINDER:
      sendDeleteNotification(data.notificationId, data.topic);
      action = calendarReminderNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.INSERT:
    case NotificationEnums.NotificationsCalendarActions.INSERT_APPOINTMENT:
      action = calendarInsertNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.UPDATE:
    case NotificationEnums.NotificationsCalendarActions.UPDATE_APPOINTMENT:
      action = calendarUpdateNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.DELETE:
    case NotificationEnums.NotificationsCalendarActions.DELETE_APPOINTMENT:
      action = calendarDeleteNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.DRAG:
      action = calendarDragNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.ADDED_TO_EVENT:
      action = calendarAddedParticipantNotification;
      break;
    case NotificationEnums.NotificationsCalendarActions.REMOVED_FROM_EVENT:
      action = calendarRemovedParticipantNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageAbookNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.ABOOK,
    idUserAction: data.idUserAction,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsAbookActions.INSERT:
      action = abookInsertNotification;
      break;
    case NotificationEnums.NotificationsAbookActions.UPDATE:
      action = abookUpdateNotification;
      break;
    case NotificationEnums.NotificationsAbookActions.DELETE:
      action = abookDeleteNotification;
      break;
    case NotificationEnums.NotificationsAbookActions.IMPORT:
      payload.number = payload.contactsImported;
      action = abookImportNotification;
      break;
    case NotificationEnums.NotificationsAbookActions.BULK_DELETE:
      payload.number = payload.contactsDeleted;
      action = abookBulkDeleteNotification;
      break;
    case NotificationEnums.NotificationsAbookActions.IMPORT_BATCH_COMPLETED:
      action = abookImportBatchNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageGroupsNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.ADMIN,
    idUserAction: data.idUserAction,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsGroupsActions.INSERT:
      action = groupInsertNotification;
      break;
    case NotificationEnums.NotificationsGroupsActions.UPDATE:
      action = groupUpdateNotification;
      break;
    case NotificationEnums.NotificationsGroupsActions.DELETE:
      action = groupDeleteNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageUsersNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.ADMIN,
    idUserAction: data.idUserAction,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  return {
    action:
      data.action === 'STATUS_CHANGE'
        ? userStatusChangeNotification
        : userUpdateNotification,
    data: payload,
  };
};

const manageFaxNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.FAX,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsFaxActions.SENT_FAX:
      action = faxSentNotification;
      break;
    case NotificationEnums.NotificationsFaxActions.ERROR_FAX:
      action = faxErrorNotification;
      break;
    case NotificationEnums.NotificationsFaxActions.RECEIVED_FAX:
      action = faxReceivedNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageMeVboxNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.MEVBOX,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsVboxActions.RECEIVED_VBOX:
      action = meReceivedVboxNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const managePbxVboxNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.PBXVBOX,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsVboxActions.RECEIVED_VBOX:
      action = pbxReceivedVboxNotification;
      break;
    case NotificationEnums.NotificationsVboxActions.DELIVERED_TVOX:
      action = deliveredTvoxNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageMailofficeNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.MAILOFFICE,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsMailofficeActions.PRICE:
      action = mailofficePriceNotification;
      break;
    case NotificationEnums.NotificationsMailofficeActions.ERROR:
      action = mailofficeErrorNotification;
      break;
    case NotificationEnums.NotificationsMailofficeActions.COMPLETED:
      action = mailofficeCompleteNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageValidationNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.VALIDATION,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsValidationActions.INSERT_VACATION:
      action = insertVacationNotification;
      break;
    case NotificationEnums.NotificationsValidationActions.DELETE_VACATION:
      action = deleteVacationNotification;
      break;
    case NotificationEnums.NotificationsValidationActions.ASK_DELETING_VACATION:
      action = askDeletingVacationNotification;
      break;
    case NotificationEnums.NotificationsValidationActions.INSERT_PUNCHING:
      action = insertPunchingNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageVacationNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.CALENDAR,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsVacationActions.CONFIRM:
      action = confirmVacationNotification;
      break;
    case NotificationEnums.NotificationsVacationActions.REJECT:
      action = rejectVacationNotification;
      break;
    case NotificationEnums.NotificationsVacationActions.CONFIRM_DELETING:
      action = confirmDeletingVacationNotification;
      break;
    case NotificationEnums.NotificationsVacationActions.REJECT_DELETING:
      action = rejectDeletingVacationNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const managePunchingNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.PUNCHING,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsVacationActions.CONFIRM:
      action = confirmPunchingNotification;
      break;
    case NotificationEnums.NotificationsVacationActions.REJECT:
      action = rejectPunchingNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageTicketNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.TICKET,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsTicketActions.INSERT:
      action = insertTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.INSERT_ASSIGNING:
    case NotificationEnums.NotificationsTicketActions.INSERT_ASSIGNING_YOU:
    case NotificationEnums.NotificationsTicketActions.INSERT_ASSIGNING_USER:
    case NotificationEnums.NotificationsTicketActions.INSERT_ASSIGNING_GROUP:
      action = insertAssigningTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.EDIT:
      action = editTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.DELETE:
      action = deleteTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.CLOSE:
      action = closeTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.ASSIGN:
      action = assignTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.UNASSIGN:
      action = unassignTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.TAKE:
      action = takeTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.WORK:
      action = workTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.CHANGE_STATUS:
      action = changeStatusTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.SUSPEND:
      action = suspendTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.SHARE:
      action = shareTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.UNSHARE:
      action = unshareTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.DEADLINE:
      action = deadlineTicketNotification;
      break;
    case NotificationEnums.NotificationsTicketActions.REOPEN:
      action = reopenTicketNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageStickyNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    section: NotificationEnums.NotificationsSections.STICKY,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsStickyActions.SHARE:
      action = shareStickyNotification;
      break;
    case NotificationEnums.NotificationsStickyActions.EDIT:
      action = editStickyNotification;
      break;
    case NotificationEnums.NotificationsStickyActions.DELETE:
      action = deleteStickyNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageFileboxNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    dateTime: data.dateTime,
    section: NotificationEnums.NotificationsSections.FILEBOX,
    idUserAction: data.idUserAction,
    idEntity: data.idEntity,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsFileboxActions.NEW_FILE:
      action = newFileFileboxNotification;
      break;
    case NotificationEnums.NotificationsFileboxActions.NEW_FILE_COMMENT:
      action = newFileCommentFileboxNotification;
      break;
    case NotificationEnums.NotificationsFileboxActions.SHARE_FOLDER:
      action = shareFolderFileboxNotification;
      break;
    case NotificationEnums.NotificationsFileboxActions.UNSHARE_FOLDER:
      action = unshareFolderFileboxNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const manageVideocallNotificationEvent = (data) => {
  const payload = {
    ...data.payload,
    notificationId: data.notificationId,
    topic: data.topic,
    dateTime: data.dateTime,
    idEntity: data.idEntity,
    idUserAction: data.idUserAction,
    nameEntity: data.nameEntity,
    archived: data.archived,
    action: data.action,
  };
  let action;
  switch (data.action) {
    case NotificationEnums.NotificationsVideocallActions.REMINDER:
      action = videocallReminderNotification;
      break;
    default:
      return null;
  }
  return {
    action,
    data: payload,
  };
};

const sendReceivedStatus = (notificationId, topic) => {
  WsManager.sendMessage({
    channel: WsEnums.Channels.NOTIFICATIONS,
    action: NotificationEnums.NotificationsActions.CHANGE_STATUS,
    notificationId,
    topic,
    payload: {
      status: NotificationEnums.NotificationsStatus.RECEIVED,
    },
  });
};

export const manageNotificationEvent = (data) => {
  const { topic, notificationId } = data;
  sendReceivedStatus(notificationId, topic);
  switch (topic) {
    case NotificationEnums.NotificationsTopics.CALENDAR:
      return manageCalendarNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.ABOOK:
      return manageAbookNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.GROUPS:
      return manageGroupsNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.USERS:
      return manageUsersNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.FAX:
      return manageFaxNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.MEVBOX:
      return manageMeVboxNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.PBXVBOX:
      return managePbxVboxNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.MAILOFFICE:
      return manageMailofficeNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.VACATION:
      return manageVacationNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.VALIDATION:
      return manageValidationNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.PUNCHING:
      return managePunchingNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.TICKET:
      return manageTicketNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.FILEBOX:
      return manageFileboxNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.STICKY:
      return manageStickyNotificationEvent(data);
    case NotificationEnums.NotificationsTopics.VIDEOCALL:
      return manageVideocallNotificationEvent(data);
    default:
      return null;
  }
};

export const sendReadStatus = (notificationId, topic) => {
  WsManager.sendMessage({
    channel: WsEnums.Channels.NOTIFICATIONS,
    action: NotificationEnums.NotificationsActions.CHANGE_STATUS,
    notificationId,
    topic,
    payload: {
      status: NotificationEnums.NotificationsStatus.READ,
    },
  });
};

export const sendReadAll = (section) => {
  if (section === NotificationEnums.NotificationsSections.ADMIN) {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.CHANGE_TOPIC_STATUS,
      topic: NotificationEnums.NotificationsTopics.GROUPS,
      payload: {
        status: NotificationEnums.NotificationsStatus.READ,
      },
    });
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.CHANGE_TOPIC_STATUS,
      topic: NotificationEnums.NotificationsTopics.USERS,
      payload: {
        status: NotificationEnums.NotificationsStatus.READ,
      },
    });
  } else if (section === NotificationEnums.NotificationsSections.CALENDAR) {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.CHANGE_TOPIC_STATUS,
      topic: NotificationEnums.NotificationsTopics.VACATION,
      payload: {
        status: NotificationEnums.NotificationsStatus.READ,
      },
    });
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.CHANGE_TOPIC_STATUS,
      topic: NotificationEnums.NotificationsTopics.CALENDAR,
      payload: {
        status: NotificationEnums.NotificationsStatus.READ,
      },
    });
  } else {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.CHANGE_TOPIC_STATUS,
      topic: section,
      payload: {
        status: NotificationEnums.NotificationsStatus.READ,
      },
    });
  }
};

export const sendDeleteAll = (section) => {
  if (section === NotificationEnums.NotificationsSections.ADMIN) {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.DELETE_TOPIC,
      topic: NotificationEnums.NotificationsTopics.GROUPS,
    });
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.DELETE_TOPIC,
      topic: NotificationEnums.NotificationsTopics.USERS,
    });
  } else if (section === NotificationEnums.NotificationsSections.CALENDAR) {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.DELETE_TOPIC,
      topic: NotificationEnums.NotificationsTopics.CALENDAR,
    });
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.DELETE_TOPIC,
      topic: NotificationEnums.NotificationsTopics.VACATION,
    });
  } else {
    WsManager.sendMessage({
      channel: WsEnums.Channels.NOTIFICATIONS,
      action: NotificationEnums.NotificationsActions.DELETE_TOPIC,
      topic: section,
    });
  }
};
