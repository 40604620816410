import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';

const messages = defineMessages({
  title: {
    id: 'Wakeup.title',
    defaultMessage: 'Wakeup Service',
  },
  scheduling: {
    id: 'Wakeup.scheduling.navLabel',
    defaultMessage: 'Alarms scheduling',
  },
  archive: {
    id: 'Wakeup.archive.navLabel',
    defaultMessage: 'Alarms archive',
  },
  settings: {
    id: 'Wakeup.settings.navLabel',
    defaultMessage: 'Settings',
  },
});

const nav = [
  {
    id: 'Wakeup.scheduling.linkLabel',
    href: '/hotel/wakeup/scheduling',
    label: messages.scheduling,
  },
  {
    id: 'Wakeup.archive.linkLabel',
    href: '/hotel/wakeup/archive',
    label: messages.archive,
  },
  {
    id: 'Wakeup.settings.linkLabel',
    href: '/hotel/wakeup/settings',
    label: messages.settings,
  },
];

const Wakeup = ({ intl: { formatMessage }, children, scrolling }) => (
  <div className="border rounded h-100 bg-white">
    <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
    <SecondaryNav tabs={nav} />
    <div
      className={
        scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
      }
    >
      {children}
    </div>
  </div>
);

export default injectIntl(Wakeup);
