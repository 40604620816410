import {
  FETCH_PINNED_GROUPS_REQUEST,
  FETCH_PINNED_GROUPS_SUCCESS,
  FETCH_PINNED_GROUPS_FAILURE,
  FETCH_ADMIN_GROUPS_REQUEST,
  FETCH_ADMIN_GROUPS_SUCCESS,
  FETCH_ADMIN_GROUPS_FAILURE,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILURE,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPDATE_GROUP_SETTINGS_SUCCESS,
  UPDATE_GROUP_SETTINGS_FAILURE,
  UPDATE_PINNED_GROUPS_ORDER_REQUEST,
  UPDATE_PINNED_GROUPS_ORDER_SUCCESS,
  UPDATE_PINNED_GROUPS_ORDER_FAILURE,
  UPDATE_PINNED_GROUP_SETTINGS_REQUEST,
  UPDATE_PINNED_GROUP_SETTINGS_SUCCESS,
  UPDATE_PINNED_GROUP_SETTINGS_FAILURE,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE,
  SET_PINNED_GROUPS_FILTER,
  SET_GROUPS_FILTER,
  FETCH_GROUP_MEMBERS_REQUEST,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_FAILURE,
  GROUP_INSERT_NOTIFICATION,
  GROUP_UPDATE_NOTIFICATION,
  GROUP_DELETE_NOTIFICATION,
  UPDATE_CHAT_GROUP_REQUEST,
  UPDATE_CHAT_GROUP_SUCCESS,
  UPDATE_CHAT_GROUP_FAILURE,
  CREATE_CHAT_GROUP_REQUEST,
  CREATE_CHAT_GROUP_SUCCESS,
  CREATE_CHAT_GROUP_FAILURE,
  SET_FAVORITES_GROUP_SCROLL,
} from './types';

export const fetchPinnedGroupsRequest = () => ({
  type: FETCH_PINNED_GROUPS_REQUEST,
});
export const fetchPinnedGroupsSuccess = (groups) => ({
  type: FETCH_PINNED_GROUPS_SUCCESS,
  groups,
});
export const fetchPinnedGroupsFailure = (errors) => ({
  type: FETCH_PINNED_GROUPS_FAILURE,
  errors,
});
export const fetchAdminGroupsRequest = (params) => ({
  type: FETCH_ADMIN_GROUPS_REQUEST,
  payload: params,
});
export const fetchAdminGroupsSuccess = (payload) => ({
  type: FETCH_ADMIN_GROUPS_SUCCESS,
  payload,
});
export const fetchAdminGroupsFailure = (errors) => ({
  type: FETCH_ADMIN_GROUPS_FAILURE,
  errors,
});
export const setGroupsFilter = (filter) => ({
  type: SET_GROUPS_FILTER,
  filter,
});
export const fetchGroupRequest = (groupId) => ({
  type: FETCH_GROUP_REQUEST,
  groupId,
});
export const fetchGroupSuccess = (group) => ({
  type: FETCH_GROUP_SUCCESS,
  group,
});
export const fetchGroupFailure = (errors) => ({
  type: FETCH_GROUP_FAILURE,
  errors,
});
export const saveGroupRequest = (group) => ({
  type: SAVE_GROUP_REQUEST,
  group,
});
export const saveGroupSuccess = (group) => ({
  type: SAVE_GROUP_SUCCESS,
  group,
});
export const saveGroupFailure = (errors) => ({
  type: SAVE_GROUP_FAILURE,
  errors,
});
export const deleteGroupRequest = (groupId) => ({
  type: DELETE_GROUP_REQUEST,
  groupId,
});
export const deleteGroupSuccess = (groupId) => ({
  type: DELETE_GROUP_SUCCESS,
  groupId,
});
export const deleteGroupFailure = (errors) => ({
  type: DELETE_GROUP_FAILURE,
  errors,
});
export const updateGroupSettingsRequest = ({ id, settings }) => ({
  type: UPDATE_GROUP_SETTINGS_REQUEST,
  payload: {
    id,
    settings,
  },
});
export const updateGroupSettingsSuccess = (group) => ({
  type: UPDATE_GROUP_SETTINGS_SUCCESS,
  group,
});
export const updateGroupSettingsFailure = (errors) => ({
  type: UPDATE_GROUP_SETTINGS_FAILURE,
  errors,
});
export const updatePinnedGroupsOrderRequest = (groups) => ({
  type: UPDATE_PINNED_GROUPS_ORDER_REQUEST,
  groups,
});
export const updatePinnedGroupsOrderSuccess = (groups) => ({
  type: UPDATE_PINNED_GROUPS_ORDER_SUCCESS,
  groups,
});
export const updatePinnedGroupsOrderFailure = (errors) => ({
  type: UPDATE_PINNED_GROUPS_ORDER_FAILURE,
  errors,
});
export const updatePinnedGroupSettingsRequest = ({ id, settings }) => ({
  type: UPDATE_PINNED_GROUP_SETTINGS_REQUEST,
  payload: {
    id,
    settings,
  },
});
export const updatePinnedGroupSettingsSuccess = (group) => ({
  type: UPDATE_PINNED_GROUP_SETTINGS_SUCCESS,
  group,
});
export const updatePinnedGroupSettingsFailure = (errors) => ({
  type: UPDATE_PINNED_GROUP_SETTINGS_FAILURE,
  errors,
});
export const updatePinnedGroupMembersOrderRequest = (group) => ({
  type: UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST,
  group,
});
export const updatePinnedGroupMembersOrderSuccess = (group) => ({
  type: UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS,
  group,
});
export const updatePinnedGroupMembersOrderFailure = (errors, groupId) => ({
  type: UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE,
  payload: {
    errors,
    groupId,
  },
});
export const setPinnedGroupsFilter = (filter) => ({
  type: SET_PINNED_GROUPS_FILTER,
  filter,
});
export const fetchGroupMembersRequest = (groupId) => ({
  type: FETCH_GROUP_MEMBERS_REQUEST,
  groupId,
});
export const fetchGroupMembersSuccess = (group) => ({
  type: FETCH_GROUP_MEMBERS_SUCCESS,
  group,
});
export const fetchGroupMembersFailure = (errors) => ({
  type: FETCH_GROUP_MEMBERS_FAILURE,
  errors,
});
export const groupInsertNotification = (payload) => ({
  type: GROUP_INSERT_NOTIFICATION,
  payload,
});
export const groupUpdateNotification = (payload) => ({
  type: GROUP_UPDATE_NOTIFICATION,
  payload,
});
export const groupDeleteNotification = (payload) => ({
  type: GROUP_DELETE_NOTIFICATION,
  payload,
});
export const updateChatGroupRequest = (payload) => ({
  type: UPDATE_CHAT_GROUP_REQUEST,
  payload,
});
export const updateChatGroupSuccess = (payload) => ({
  type: UPDATE_CHAT_GROUP_SUCCESS,
  payload,
});
export const updateChatGroupFailure = (groupId) => ({
  type: UPDATE_CHAT_GROUP_FAILURE,
  groupId,
});
export const createChatGroupRequest = (members) => ({
  type: CREATE_CHAT_GROUP_REQUEST,
  members,
});
export const createChatGroupSuccess = (payload) => ({
  type: CREATE_CHAT_GROUP_SUCCESS,
  payload,
});
export const createChatGroupFailure = (errors) => ({
  type: CREATE_CHAT_GROUP_FAILURE,
  errors,
});
export const setFavoritesGroupScroll = (filter) => ({
  type: SET_FAVORITES_GROUP_SCROLL,
  filter,
});
