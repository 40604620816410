import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import LoginForm from '../forms/LoginForm';
import ynConf from '../../conf';
import Loading from '../../components/Loading';
import { checkVideocallAuthorizationRequest } from '../../js/videocalls/actions';
import {
  checkVideocallAuthorizationAcceptGuests,
  isCheckVideocallAuthorizationError,
  isCheckVideocallAuthorizationLoaded,
} from '../../js/videocalls/selectors';
import GlobalErrorPage from './GlobalErrorPage';
import Button from '../../components/formControls/Button';
import VideocallGuestPage from './VideocallGuestPage';

const messages = defineMessages({
  altTextLogo: {
    id: 'LoginPage.altTextLogo',
    defaultMessage: 'Logo YouNeed',
  },
  altTextAmbrosia: {
    id: 'LoginPage.altTextAmbrosia',
    defaultMessage:
      'Ambrosia, YouNeed vocal assistant and mascot, showing a thumb up',
  },
  chooseTitle: {
    id: 'LoginPage.chooseTitle',
    defaultMessage: 'Welcome!',
  },
  chooseSubtitle: {
    id: 'LoginPage.chooseSubtitle',
    defaultMessage: 'Choose how you want to enter in the videocall room',
  },
  chooseUser: {
    id: 'LoginPage.button.chooseUser',
    defaultMessage: 'Enter as YouNeed user',
  },
  chooseGuest: {
    id: 'LoginPage.button.chooseGuest',
    defaultMessage: 'Enter as guest',
  },
  loginTitle: {
    id: 'LoginPage.loginTitle',
    defaultMessage: 'Welcome!',
  },
  loginSubtitle: {
    id: 'LoginPage.loginSubtitle',
    defaultMessage: 'Insert your data and start using YouNeed',
  },
  forgotPasswordText: {
    id: 'LoginPage.forgotPasswordText',
    defaultMessage: 'Forgot Password? ',
  },
  forgotPasswordLink: {
    id: 'LoginPage.forgotPasswordLink',
    defaultMessage: 'Click here',
  },
});

class VideocallLoginPage extends Component {
  state = {
    mode: null,
  };

  componentDidMount() {
    const videocallRoom = sessionStorage
      .getItem('videocallRoom')
      .substring(sessionStorage.getItem('videocallRoom').lastIndexOf('/') + 1);
    this.props.check(videocallRoom);
  }

  handleChoose = (mode) => {
    this.setState({
      mode,
    });
  };

  render() {
    const {
      loaded,
      error,
      acceptGuests,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        {acceptGuests && this.state.mode === 'G' ? (
          <VideocallGuestPage />
        ) : (
          <div className="bg-primary p-0 pt-sm-4 h-100">
            <div className="yn-login-form bg-white p-3 m-auto text-center">
              {error ? (
                <GlobalErrorPage type="danger" />
              ) : (
                <>
                  <img
                    src={`${ynConf.publicFolderPath}/logo.png`}
                    className="img-fluid"
                    style={{
                      height: '50px',
                    }}
                    alt={formatMessage(messages.altTextLogo)}
                  />
                  <img
                    src={`${ynConf.publicFolderPath}/yn20.png`}
                    className="img-fluid"
                    style={{
                      height: '250px',
                    }}
                    alt={formatMessage(messages.altTextAmbrosia)}
                  />
                  {loaded ? (
                    <>
                      {acceptGuests && !this.state.mode && (
                        <>
                          <h5>
                            <strong>
                              {formatMessage(messages.chooseTitle)}
                            </strong>
                          </h5>
                          <h6>
                            <strong>
                              {formatMessage(messages.chooseSubtitle)}
                            </strong>
                          </h6>
                          <div className="pt-3 form-row">
                            <Button
                              className="btn btn-primary btn-block col-4 offset-md-1"
                              text={formatMessage(messages.chooseUser)}
                              onClick={() => this.handleChoose('U')}
                            />
                            <Button
                              className="btn btn-primary btn-block col-4 offset-md-2"
                              text={formatMessage(messages.chooseGuest)}
                              onClick={() => this.handleChoose('G')}
                            />
                          </div>
                        </>
                      )}
                      {(!acceptGuests || this.state.mode === 'U') && (
                        <>
                          <h5>
                            <strong>
                              {formatMessage(messages.loginTitle)}
                            </strong>
                          </h5>
                          <h6>
                            <strong>
                              {formatMessage(messages.loginSubtitle)}
                            </strong>
                          </h6>
                          <LoginForm />
                          {formatMessage(messages.forgotPasswordText)}
                          <Link to="/forgot-password">
                            {formatMessage(messages.forgotPasswordLink)}
                          </Link>
                        </>
                      )}
                    </>
                  ) : (
                    <Loading />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: isCheckVideocallAuthorizationError(state),
    loaded: isCheckVideocallAuthorizationLoaded(state),
    acceptGuests: checkVideocallAuthorizationAcceptGuests(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    check: checkVideocallAuthorizationRequest,
  })(VideocallLoginPage)
);
