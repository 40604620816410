export class PhoneRulesEnums {
  static SettingsActions = {
    none: 'NONE',
    vbox: 'VM',
    forward: 'CF',
    dnd: 'DND',
    responder: 'RE',
  };

  static SettingsEvents = {
    always: 'ALWAYS',
    busy: 'BUSY',
    unanswered: 'NOANSWER',
  };

  static SettingsContext = {
    users: 'users',
    pbx: 'pbx',
    me: 'me',
  };

  static SettingsWhen = {
    weekdays: 'weekdays',
    period: 'period',
    festivity: 'festivity',
  };

  static SettingsFestivities = {
    saturdays: 'saturdays',
    sundays: 'sundays',
    holidays: 'holidays',
  };
}
