import React from 'react';
import Mailoffice from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const MailofficeNotifications = React.lazy(() =>
  import('./MailofficeNotifications')
);

const MailofficeNotificationsPage = (props) => (
  <Mailoffice scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-postal-service" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <MailofficeNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Mailoffice>
);

export default MailofficeNotificationsPage;
