import React from 'react';
import PropTypes from 'prop-types';

export const circleStyle = {
  width: '300px',
  height: '300px',
  maxFontSize: 26,
  menuStyle: {
    position: 'absolute',
    top: '38px',
    left: '22px',
    width: '66%',
    height: '0',
    borderBottom: '23px solid rgba(76, 175, 80, 0.1)',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    marginBottom: '0',
    padding: '4px 4px 0 8px',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '65px',
    left: '32px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '59%',
    height: '44%',
    padding: '6px',
    top: '65px',
    left: '31px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '205px',
    left: '90px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '203px',
    left: '124px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '44px',
    left: '180px',
  },
};
export const CircleShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 200 200">
      <circle
        style={{ fill: `${color}`, padding: '10px' }}
        filter={`url(#sticky-filter-${id})`}
        cx="80"
        cy="80"
        r="75"
      />
      <filter
        id={`sticky-filter-${id}`}
        x="-1px"
        y="-1px"
        width="210%"
        height="210%"
      >
        <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </svg>
  </>
);

CircleShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
