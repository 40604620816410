import React from 'react';
import PropTypes from 'prop-types';

export const heartStyle = {
  width: '300px',
  height: '300px',
  maxFontSize: 30,
  menuStyle: {
    position: 'absolute',
    top: '35px',
    left: '15px',
    width: '45%',
    height: '0',
    borderBottom: '23px solid rgba(76, 175, 80, 0.1)',
    borderLeft: '19px solid transparent',
    borderRight: '22px solid transparent',
    marginBottom: '0',
    padding: '4px 0px 0 16px',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '62px',
    left: '17px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '70%',
    height: '40%',
    padding: '6px',
    top: '67px',
    left: '46px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '236px',
    left: '120px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '234px',
    left: '154px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '44px',
    left: '210px',
  },
};

export const HeartShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 200 200">
      <g transform="translate(0,-852.36218)">
        <g transform="matrix(5.8613591,0,0,6.3477288,6.2182544,850.79852)">
          <g transform="matrix(0.01203588,0,0,-0.01111369,-2.05382,32.695005)">
            <path
              filter={`url(#sticky-filter-${id})`}
              style={{ fill: `${color}`, padding: '10px' }}
              d="m 700,2699 c -282,-64 -479,-276 -521,-559 -12,-88 -7,-261 12,-380 10,-63 45,-185 59,-205 4,-5 13,-26 20,-45 57,-145 258,-366 599,-660 93,-79 245,-213 325,-284 38,-33 74,-65 80,-71 6,-5 60,-54 119,-108 l 107,-99 107,99 c 59,54 113,103 119,108 6,6 42,38 80,71 80,71 232,205 325,284 341,294 542,515 599,660 7,19 16,40 20,45 14,20 49,142 59,205 19,119 24,292 12,380 -43,289 -243,499 -532,560 -162,34 -299,16 -456,-62 -98,-48 -237,-171 -293,-258 -18,-28 -36,-50 -40,-50 -4,0 -22,22 -40,50 -17,27 -71,87 -119,132 -180,171 -412,239 -641,187 z"
            />
            <defs>
              <filter
                id={`sticky-filter-${id}`}
                x="-1px"
                y="-1px"
                width="210%"
                height="210%"
              >
                <feOffset result="offOut" in="SourceAlpha" dx="8" dy="-5" />
                <feGaussianBlur
                  result="blurOut"
                  in="offOut"
                  stdDeviation="18"
                />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
          </g>
        </g>
      </g>
    </svg>
  </>
);

HeartShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
