import React, { Component } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Transition } from 'react-transition-group';
import { injectIntl, defineMessages } from 'react-intl';

const duration = 100;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: {
    opacity: 1,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    opacity: 0,
  },
  exited: {
    opacity: 0,
  },
};

const messages = defineMessages({
  200: {
    id: 'ToastMessage.200',
    defaultMessage: 'OK',
  },
  400: {
    id: 'ToastMessage.400',
    defaultMessage: 'Bad request',
  },
  401: {
    id: 'ToastMessage.401',
    defaultMessage: 'Unauthorized',
  },
  403: {
    id: 'ToastMessage.403',
    defaultMessage: 'Forbidden',
  },
  404: {
    id: 'ToastMessage.404',
    defaultMessage: 'Page not found',
  },
  500: {
    id: 'ToastMessage.500',
    defaultMessage: 'Internal Server Error',
  },
  504: {
    id: 'ToastMessage.504',
    defaultMessage: 'Gateway Timeout',
  },
  NETWORK_ERROR: {
    id: 'ToastMessage.NETWORK_ERROR',
    defaultMessage: 'Network error',
  },
});
class ToastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    const { disableAutoClose, onClose, closeTimeout } = this.props;
    this.show = setTimeout(() => {
      this.setState({ visible: true });
    }, 100);
    if (!disableAutoClose) {
      this.close = setTimeout(() => {
        this.setState({ visible: false }, () => onClose && onClose());
      }, closeTimeout);
    }
  }

  componentWillUnmount() {
    const { disableAutoClose } = this.props;
    clearTimeout(this.show);
    if (!disableAutoClose) {
      clearTimeout(this.close);
    }
  }

  handleClose() {
    const { onClose } = this.props;
    this.setState({ visible: false }, () => onClose && onClose());
  }

  render() {
    const {
      text,
      messageCode,
      intl: { formatMessage },
      type,
      className,
      dismissable,
      ref,
    } = this.props;

    const { visible } = this.state;

    const messageCodes = () => {
      if (messageCode && Array.isArray(messageCode)) return messageCode;
      if (messageCode) return [messageCode];
      return null;
    };
    const errorText =
      text ||
      messageCodes().map((error) => (
        <div key={error}>
          {messages[error] && formatMessage(messages[error])
            ? formatMessage(messages[error])
            : formatMessage(messages[400])}
        </div>
      )) ||
      '';

    return (
      <Transition in={visible} timeout={duration} unmountOnExit>
        {(state) => (
          <div
            className={`alert alert-${type} ${className}`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={ref}
          >
            <div className="d-inline-block">{errorText}</div>
            {dismissable && (
              <button
                onClick={() => this.handleClose()}
                type="button"
                className="close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
        )}
      </Transition>
    );
  }
}

ToastMessage.propTypes = {
  messageCode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  disableAutoClose: PropTypes.bool,
  dismissable: PropTypes.bool,
  closeTimeout: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

ToastMessage.defaultProps = {
  messageCode: 200,
  type: 'primary',
  disableAutoClose: false,
  dismissable: true,
  closeTimeout: 3000,
  text: null,
};

export default injectIntl(ToastMessage);
