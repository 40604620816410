import VocalAssistantMessages from './VocalAssistantMessages';
import VocalAssistantUtils from './VocalAssistantUtils';

export default class Navigator {
  constructor() {
    this.params = {};
    this.steps = [];
    this.operationInterval = null;
    this.operationPending = false;
    this.operationWait = false;
    this.active = false;
    this.language = null;
    this.userFullname = null;
  }

  init = (steps, command, language, userFullname) => {
    this.reset();
    this.steps = steps.slice();
    this.language = language;
    this.userFullname = userFullname;
  };

  execute = (text, language, callback) => {
    // / if text, put it in params
    if (text !== null) {
      this.params.text = text;
    }
    if (!this.params.data) {
      this.params.data = {};
    }
    this.params = {
      ...this.params,
      lang: language,
    };
    this.steps[0](this.params, (error, output) => {
      let data = {
        ...output,
        message: output.messages
          ? VocalAssistantUtils.retrieveMessage(
              output.messages,
              this.userFullname,
              this.language
            )
          : null,
      };
      if (output.end) {
        this.steps = [];
        callback(data);
        return;
      }
      if (error) {
        // / keep a reference for this message
        // / after timeoutMessage seconds without any message from user, say again output message
        // / after other timeoutMessage seconds, reject the operation
        let firstWarning = false;
        data.additionalTask = (completeCallback) => {
          this.operationInterval && clearInterval(this.operationInterval);
          this.operationPending = false;
          if (this.active) {
            this.operationInterval = setInterval(() => {
              if (this.operationWait) {
                return;
              }
              if (firstWarning) {
                data = {
                  end: true,
                  messages: [
                    VocalAssistantMessages.voiceCommandDeletedTimeoutOperation,
                  ],
                };
                this.reset();
                callback(data);
                return;
              }
              firstWarning = true;
              callback(data);
            }, 5000);
            this.operationPending = true;
            completeCallback && completeCallback();
          }
        };
        callback(data);
      } else {
        // / operation completed succesfully
        // / put this step out from array to go to next step
        this.steps.shift();
        // / retrieve data and text from the operation
        this.params.data = output.data;
        // / retrieved text is the input text for the next operation
        this.params.text = output.text;

        // / execute next step
        this.execute(null, this.language, callback);
      }
    });
  };

  isActive = () => {
    return this.steps.length > 0;
  };

  suspendIntervalFunction = () => {
    if (this.operationPending) {
      this.operationWait = true;
    }
  };

  resumeIntervalFunction = () => {
    this.operationWait = false;
  };

  isOperationPending = () => {
    return this.operationPending;
  };

  reset = () => {
    this.active = false;
    this.steps = [];
    this.params = {};
    this.operationInterval && clearInterval(this.operationInterval);
    this.operationPending = false;
    this.operationWait = false;
  };
}
