import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';

const messages = defineMessages({
  title: {
    id: 'Wiki.title',
    defaultMessage: 'Wiki',
  },
});

const Wiki = ({ intl: { formatMessage }, children, scrolling }) => (
  <div className="border rounded bg-white h-100">
    <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
    <div className={scrolling ? 'yn-section-scrolling' : 'yn-section'}>
      {children}
    </div>
  </div>
);

export default injectIntl(Wiki);
