import { request } from '../request';

export default {
  retrievePbxRules: (params) =>
    request({
      url: `/phone/rules`,
      method: 'GET',
      params,
    }),
  insertRule: (data) =>
    request({
      url: `/phone/rules`,
      method: 'POST',
      data,
    }),
  updateRule: (data, id) =>
    request({
      url: `/phone/rules/${id}`,
      method: 'POST',
      data,
    }),
  deleteRule: (id) =>
    request({
      url: `/phone/rules/${id}`,
      method: 'DELETE',
    }),
};
