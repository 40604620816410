export class ExternalUrlUtils {
  static Event = {
    INCOMING: 'INCOMING',
    ANSWERED: 'ANSWERED',
    HANGUP: 'HANGUP',
    ALWAYS: 'ALWAYS',
  };

  static Action = {
    LINK: 'LINK',
    API: 'API',
    ICON: 'ICON',
  };

  static Constraint = {
    EXTENSION: 'EXTENSION',
    EXTERNAL: 'EXTERNAL',
    ALL: 'ALL',
  };

  static Parameters = {
    CALLER: 'CALLER',
    CALLERNAME: 'CALLERNAME',
    EXT: 'EXT',
    CALLID: 'CALLID',
    DURATION: 'DURATION',
    DATE: 'DATE',
    CLIENTNUMBER: 'CLIENTNUMBER',
  };
}
