import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Select2 from '../../../components/formControls/Select2';
import { fetchAbookTagsRequest } from '../../../js/contacts/actions';

const messages = defineMessages({
  placeholder: {
    id: 'AbookTagsSelect.label.placeholder',
    defaultMessage: 'Select a tag',
  },
  noOptionsMessage: {
    id: 'AbookTagsSelect.label.noOptionsMessage',
    defaultMessage: 'No tags found',
  },
});

class AbookTagsSelect extends Component {
  componentDidMount() {
    this.props.fetchAbookTagsRequest('');
  }

  handleInputChange = (tag) => {
    if (tag.length > 0) {
      this.props.fetchAbookTagsRequest(tag);
    }
  };

  render() {
    const {
      selected,
      onSelect,
      tags,
      placeholder,
      isMulti,
      intl: { formatMessage },
    } = this.props;

    const options = tags
      ? tags.map((tag) => ({ value: tag.id, label: tag.tag }), ['label'])
      : [];

    return (
      <Select2
        id="tags"
        name="tags"
        value={selected}
        isMulti={isMulti}
        // onInputChange={e => this.handleInputChange(e)}
        onChange={onSelect}
        options={options}
        isClearable
        noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
        placeholder={placeholder || formatMessage(messages.placeholder)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: state.contacts.filter,
    tags: state.contacts.tags.data,
  };
}

AbookTagsSelect.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

AbookTagsSelect.defaultProps = {
  selected: null,
  placeholder: null,
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchAbookTagsRequest,
  })(AbookTagsSelect)
);
