import { defineMessages } from 'react-intl';

export default class VocalAssistantMessages {
  static messages = defineMessages({
    daysOfWeek0: {
      id: 'VocalAssistantMessages.daysOfWeek0',
      defaultMessage: 'Sunday',
    },
    daysOfWeek1: {
      id: 'VocalAssistantMessages.daysOfWeek1',
      defaultMessage: 'Monday',
    },
    daysOfWeek2: {
      id: 'VocalAssistantMessages.daysOfWeek2',
      defaultMessage: 'Tuesday',
    },
    daysOfWeek3: {
      id: 'VocalAssistantMessages.daysOfWeek3',
      defaultMessage: 'Wednesday',
    },
    daysOfWeek4: {
      id: 'VocalAssistantMessages.daysOfWeek4',
      defaultMessage: 'Thursday',
    },
    daysOfWeek5: {
      id: 'VocalAssistantMessages.daysOfWeek5',
      defaultMessage: 'Friday',
    },
    daysOfWeek6: {
      id: 'VocalAssistantMessages.daysOfWeek6',
      defaultMessage: 'Saturday',
    },
    daysOfWeekMin0: {
      id: 'VocalAssistantMessages.daysOfWeekMin0',
      defaultMessage: 'Su',
    },
    daysOfWeekMin1: {
      id: 'VocalAssistantMessages.daysOfWeekMin1',
      defaultMessage: 'Mo',
    },
    daysOfWeekMin2: {
      id: 'VocalAssistantMessages.daysOfWeekMin2',
      defaultMessage: 'Tu',
    },
    daysOfWeekMin3: {
      id: 'VocalAssistantMessages.daysOfWeekMin3',
      defaultMessage: 'We',
    },
    daysOfWeekMin4: {
      id: 'VocalAssistantMessages.daysOfWeekMin4',
      defaultMessage: 'Th',
    },
    daysOfWeekMin5: {
      id: 'VocalAssistantMessages.daysOfWeekMin5',
      defaultMessage: 'Fi',
    },
    daysOfWeekMin6: {
      id: 'VocalAssistantMessages.daysOfWeekMin6',
      defaultMessage: 'Sa',
    },
    voiceCommandIntroGoodmorning0: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning0',
      defaultMessage: 'Good morning {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroGoodmorning1: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning1',
      defaultMessage: 'Good morning {name}, what do you need?',
    },
    voiceCommandIntroGoodmorning2: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning2',
      defaultMessage: 'Hi, what can I do for you?',
    },
    voiceCommandIntroGoodmorning3: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning3',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroGoodmorning4: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning4',
      defaultMessage: 'Good morning, what can I do?',
    },
    voiceCommandIntroGoodmorning5: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning5',
      defaultMessage: 'Good morning, how can I make myself useful?',
    },
    voiceCommandIntroGoodmorning6: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning6',
      defaultMessage: 'Good morning {name}, busy morning? Do you need a hand? ',
    },
    voiceCommandIntroGoodmorning7: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning7',
      defaultMessage: "Hi, I'm Ambrosia, what can I do?",
    },
    voiceCommandIntroGoodmorning8: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning8',
      defaultMessage:
        "Hi, I'm Ambrosia, your virtual assistant. How can I help you?",
    },
    voiceCommandIntroGoodmorning9: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning9',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroGoodmorning10: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning10',
      defaultMessage: "I'm ready to help you. Tell me everything",
    },
    voiceCommandIntroGoodmorning11: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning11',
      defaultMessage: 'Your assistant Ambrosia is here to help you. Tell me',
    },
    voiceCommandIntroGoodmorning12: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodmorning12',
      defaultMessage: 'Hello! I missed you. Tell me what you need',
    },
    voiceCommandIntroGoodafternoon0: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon0',
      defaultMessage: 'Good afternoon {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroGoodafternoon1: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon1',
      defaultMessage: 'Good afternoon {name}, what do you need?',
    },
    voiceCommandIntroGoodafternoon2: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon2',
      defaultMessage: 'Hi, what can I do for you?',
    },
    voiceCommandIntroGoodafternoon3: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon3',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroGoodafternoon4: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon4',
      defaultMessage: 'Good afternoon, what can I do?',
    },
    voiceCommandIntroGoodafternoon5: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon5',
      defaultMessage: 'Good afternoon, how can I make myself useful?',
    },
    voiceCommandIntroGoodafternoon6: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon6',
      defaultMessage:
        'Good afternoon {name}, busy morning? Do you need a hand? ',
    },
    voiceCommandIntroGoodafternoon7: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon7',
      defaultMessage: "Hi, I'm Ambrosia, what can I do?",
    },
    voiceCommandIntroGoodafternoon8: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon8',
      defaultMessage:
        "Hi, I'm Ambrosia, your virtual assistant. How can I help you?",
    },
    voiceCommandIntroGoodafternoon9: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon9',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroGoodafternoon10: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon10',
      defaultMessage: "I'm ready to help you. Tell me everything",
    },
    voiceCommandIntroGoodafternoon11: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon11',
      defaultMessage: 'Your assistant Ambrosia is here to help you. Tell me',
    },
    voiceCommandIntroGoodafternoon12: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodafternoon12',
      defaultMessage: 'Hello! I missed you. Tell me what you need',
    },
    voiceCommandIntroFirstOfYear0: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear0',
      defaultMessage: 'Hi {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroFirstOfYear1: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear1',
      defaultMessage: 'Hi {name}, what do you need?',
    },
    voiceCommandIntroFirstOfYear2: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear2',
      defaultMessage: 'Happy New Year !!! What can I do for you?',
    },
    voiceCommandIntroFirstOfYear3: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear3',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroFirstOfYear4: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear4',
      defaultMessage: 'Hi {name}, start the year well eh ... Can I help you?',
    },
    voiceCommandIntroFirstOfYear5: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear5',
      defaultMessage: 'What are you doing here today? Need a helping hand?',
    },
    voiceCommandIntroFirstOfYear6: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear6',
      defaultMessage:
        'Hi {name}, happy new year !!! Do you want me to help you?',
    },
    voiceCommandIntroFirstOfYear7: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear7',
      defaultMessage:
        'Hi {name}, we have to work even the first of the year ... What do you need?',
    },
    voiceCommandIntroFirstOfYear8: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear8',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroFirstOfYear9: {
      id: 'VocalAssistantMessages.voiceCommandIntroFirstOfYear9',
      defaultMessage: "What are you doing here today? Oh well, I'll help you",
    },
    voiceCommandIntroGoodevening0: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening0',
      defaultMessage: 'Good evening {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroGoodevening1: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening1',
      defaultMessage: 'Good evening {name}, what do you need?',
    },
    voiceCommandIntroGoodevening2: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening2',
      defaultMessage: 'Hi, what can I do for you?',
    },
    voiceCommandIntroGoodevening3: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening3',
      defaultMessage: 'Here I am !! Tell me everything',
    },
    voiceCommandIntroGoodevening4: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening4',
      defaultMessage: 'Good evening, what can I do?',
    },
    voiceCommandIntroGoodevening5: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening5',
      defaultMessage: 'Good evening, how can I make myself useful?',
    },
    voiceCommandIntroGoodevening6: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening6',
      defaultMessage: 'Good evening {name}, busy day? Do you need a hand?',
    },
    voiceCommandIntroGoodevening7: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening7',
      defaultMessage: "Hi, I'm Ambrosia, what can I do?",
    },
    voiceCommandIntroGoodevening8: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening8',
      defaultMessage:
        "Hi, I'm Ambrosia, your virtual assistant. How can I help you?",
    },
    voiceCommandIntroGoodevening9: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening9',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroGoodevening10: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening10',
      defaultMessage: "I'm ready to help you. Tell me everything",
    },
    voiceCommandIntroGoodevening11: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening11',
      defaultMessage: 'Your assistant Ambrosia is here to help you. Tell me',
    },
    voiceCommandIntroGoodevening12: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening12',
      defaultMessage: 'Hello! I missed you. Tell me what you need,',
    },
    voiceCommandIntroGoodevening13: {
      id: 'VocalAssistantMessages.voiceCommandIntroGoodevening13',
      defaultMessage:
        "For today we are almost finished eh? Come on, I'll help you",
    },
    voiceCommandIntroChristmas0: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas0',
      defaultMessage: 'Hi {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroChristmas1: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas1',
      defaultMessage: "'Hi {name}, what do you need?",
    },
    voiceCommandIntroChristmas2: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas2',
      defaultMessage: 'Merry Christmas !!! What can I do for you?',
    },
    voiceCommandIntroChristmas3: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas3',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroChristmas4: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas4',
      defaultMessage: 'Hi {name}, even today at work? Can I help you?',
    },
    voiceCommandIntroChristmas5: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas5',
      defaultMessage: 'What are you doing here today? Do you need a hand?',
    },
    voiceCommandIntroChristmas6: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas6',
      defaultMessage: 'Hello, what brought you Santa Claus under the tree?',
    },
    voiceCommandIntroChristmas7: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas7',
      defaultMessage: 'Hi {name}, also working at Christmas? What do you need?',
    },
    voiceCommandIntroChristmas8: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas8',
      defaultMessage:
        'Hey {name}, I think we are the only two people in the world working today .. Do you need help?',
    },
    voiceCommandIntroChristmas9: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas9',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroChristmas10: {
      id: 'VocalAssistantMessages.voiceCommandIntroChristmas10',
      defaultMessage: "What are you doing here today? Oh well, I'll help you",
    },
    voiceCommandIntroEaster0: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster0',
      defaultMessage: 'Hi {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroEaster1: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster1',
      defaultMessage: 'Hi {name}, what do you need?',
    },
    voiceCommandIntroEaster2: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster2',
      defaultMessage: 'Hello Happy Easter !!! What can I do for you?',
    },
    voiceCommandIntroEaster3: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster3',
      defaultMessage: 'Here I am !! Tell me everything',
    },
    voiceCommandIntroEaster4: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster4',
      defaultMessage: 'Hi {name}, even today at work? Can I help you?',
    },
    voiceCommandIntroEaster5: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster5',
      defaultMessage:
        "What are you doing here today? Aren't you opening the egg?",
    },
    voiceCommandIntroEaster6: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster6',
      defaultMessage:
        'Happy Easter {name}!!! Do you want me to give you a hand?',
    },
    voiceCommandIntroEaster7: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster7',
      defaultMessage:
        "Hi {name}, I thought you weren't working today. What do you need?",
    },
    voiceCommandIntroEaster8: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster8',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroEaster9: {
      id: 'VocalAssistantMessages.voiceCommandIntroEaster9',
      defaultMessage: "What are you doing here today? Oh well, I'll help you",
    },
    voiceCommandIntroFestivity0: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity0',
      defaultMessage: 'Hi {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroFestivity1: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity1',
      defaultMessage: 'Hi {name}, what do you need?',
    },
    voiceCommandIntroFestivity2: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity2',
      defaultMessage: 'Hi, what can I do for you?',
    },
    voiceCommandIntroFestivity3: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity3',
      defaultMessage: 'Here I am !! Tell me everything',
    },
    voiceCommandIntroFestivity4: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity4',
      defaultMessage: 'Hi {name}, even today at work? Can I help you?',
    },
    voiceCommandIntroFestivity5: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity5',
      defaultMessage: 'What are you doing here today? Do you need a hand?',
    },
    voiceCommandIntroFestivity6: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity6',
      defaultMessage:
        "Hi, even if it's a party I'll help you. What do you need?",
    },
    voiceCommandIntroFestivity7: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity7',
      defaultMessage:
        "Hi {name}, I thought you weren't working today. What do you need?",
    },
    voiceCommandIntroFestivity8: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity8',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroFestivity9: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity9',
      defaultMessage:
        'Come on hold on, work ennobles man ... What do you need?',
    },
    voiceCommandIntroFestivity10: {
      id: 'VocalAssistantMessages.voiceCommandIntroFestivity10',
      defaultMessage:
        "You're really workaholic! I like it. So am I. How can I help you?",
    },
    voiceCommandIntroLate0: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate0',
      defaultMessage: 'Good evening {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroLate1: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate1',
      defaultMessage: 'Good evening {name}, what do you need?',
    },
    voiceCommandIntroLate2: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate2',
      defaultMessage: 'Hi, what can I do for you?',
    },
    voiceCommandIntroLate3: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate3',
      defaultMessage: 'Hi {name}, still at work? Can I help you?',
    },
    voiceCommandIntroLate4: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate4',
      defaultMessage: 'Good evening, how can I make myself useful?',
    },
    voiceCommandIntroLate5: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate5',
      defaultMessage:
        'Good evening {name}, a long day today? Do you need a hand?',
    },
    voiceCommandIntroLate6: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate6',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroLate7: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate7',
      defaultMessage:
        "'Hi {name}, are you still here? Do you want me to give you a hand?",
    },
    voiceCommandIntroLate8: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate8',
      defaultMessage:
        "Hello, despite the time I'm still here too; what should I do?",
    },
    voiceCommandIntroLate9: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate9',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroLate10: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate10',
      defaultMessage: 'Come on hold on, work ennobles man. What do you need?',
    },
    voiceCommandIntroLate11: {
      id: 'VocalAssistantMessages.voiceCommandIntroLate11',
      defaultMessage:
        "You're really workaholic! I like it. So am I. How can I help you?",
    },
    voiceCommandIntroEarly0: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly0',
      defaultMessage: 'Good morning {name} is Ambrosia. How can I help you?',
    },
    voiceCommandIntroEarly1: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly1',
      defaultMessage: 'Good morning {name}, what do you need?',
    },
    voiceCommandIntroEarly2: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly2',
      defaultMessage: 'Hi {name}, what can I do for you?',
    },
    voiceCommandIntroEarly3: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly3',
      defaultMessage: 'Here I am! Tell me everything',
    },
    voiceCommandIntroEarly4: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly4',
      defaultMessage: 'Good morning, what can I do?',
    },
    voiceCommandIntroEarly5: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly5',
      defaultMessage: 'Good morning, how can I make myself useful?',
    },
    voiceCommandIntroEarly6: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly6',
      defaultMessage:
        'Hello {name}, already awake at this time? Do you need a hand?',
    },
    voiceCommandIntroEarly7: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly7',
      defaultMessage: "Hi, I'm Ambrosia, what can I do?",
    },
    voiceCommandIntroEarly8: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly8',
      defaultMessage:
        'Hello, we are early risers today, eh? How can I help you?',
    },
    voiceCommandIntroEarly9: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly9',
      defaultMessage:
        "Good morning!! Couldn't you sleep tonight? Even I am already awake, can I help you?",
    },
    voiceCommandIntroEarly10: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly10',
      defaultMessage: 'Here we are {name}, what should I do?',
    },
    voiceCommandIntroEarly11: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly11',
      defaultMessage:
        "Goodbye! You woke up early! I'm ready to help you, tell me everything",
    },
    voiceCommandIntroEarly12: {
      id: 'VocalAssistantMessages.voiceCommandIntroEarly12',
      defaultMessage: 'Here I am! I never sleep. How can I help you?',
    },
    voiceCommandIntroAgain0: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain0',
      defaultMessage: 'Do you still need me?',
    },
    voiceCommandIntroAgain1: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain1',
      defaultMessage: 'Here I am, I am always here',
    },
    voiceCommandIntroAgain2: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain2',
      defaultMessage: 'I knew you would still need me. How can I help you?',
    },
    voiceCommandIntroAgain3: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain3',
      defaultMessage: 'Tell me everything',
    },
    voiceCommandIntroAgain4: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain4',
      defaultMessage: 'What can I do now?',
    },
    voiceCommandIntroAgain5: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain5',
      defaultMessage: 'Here we are again {name}',
    },
    voiceCommandIntroAgain6: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain6',
      defaultMessage: 'Hi {name}, still here? How can I help you?',
    },
    voiceCommandIntroAgain7: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain7',
      defaultMessage: 'Here I am again, what do you need?',
    },
    voiceCommandIntroAgain8: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain8',
      defaultMessage: "I didn't run away, do you need me again?",
    },
    voiceCommandIntroAgain9: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain9',
      defaultMessage: 'Here we go again. What do you need?',
    },
    voiceCommandIntroAgain10: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain10',
      defaultMessage: 'Great {name} !! Do you need a hand?',
    },
    voiceCommandIntroAgain11: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain11',
      defaultMessage: 'I missed you eh? Tell me everything',
    },
    voiceCommandIntroAgain12: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain12',
      defaultMessage:
        "You can't be without your Ambrosia eh? What do you need?",
    },
    voiceCommandIntroAgain13: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain13',
      defaultMessage:
        "Back to me? I'm your favorite assistant eh ... What can I do for you?",
    },
    voiceCommandIntroAgain14: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain14',
      defaultMessage: 'Ambrosia is always here, what can I help you with?',
    },
    voiceCommandIntroAgain15: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain15',
      defaultMessage: 'I was waiting for you ... What can I do?',
    },
    voiceCommandIntroAgain16: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain16',
      defaultMessage: 'I am always here, tell me everything',
    },
    voiceCommandIntroAgain17: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain17',
      defaultMessage: ' {name} did you need Ambrosia?',
    },
    voiceCommandIntroAgain18: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain18',
      defaultMessage: 'I missed you {name}. How can I help you?',
    },
    voiceCommandIntroAgain19: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain19',
      defaultMessage: 'Sorry, I was having coffee. Here I am available ',
    },
    voiceCommandIntroAgain20: {
      id: 'VocalAssistantMessages.voiceCommandIntroAgain20',
      defaultMessage:
        'To meet again is like to be reborn once again. Tell me everything!',
    },
    voiceCommandIntroHelp0: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp0',
      defaultMessage: 'Ask "Help" to find out what I can do for you',
    },
    voiceCommandIntroHelp1: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp1',
      defaultMessage:
        'If you ask me "Help" I\'ll tell you what I can help you with',
    },
    voiceCommandIntroHelp2: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp2',
      defaultMessage: 'Asking me for "Help" I tell you everything I can do',
    },
    voiceCommandIntroHelp3: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp3',
      defaultMessage: 'Ask "Help" and I tell you there',
    },
    voiceCommandIntroHelp4: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp4',
      defaultMessage: 'If you want to know how I can help you ask "Help"',
    },
    voiceCommandIntroHelp5: {
      id: 'VocalAssistantMessages.voiceCommandIntroHelp5',
      defaultMessage:
        'I know how to do many things, ask me "Help" and I list them all',
    },
    voiceCommandDeletedTimeoutOperation0: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation0',
      defaultMessage: 'I received no reply, I canceled the request',
    },
    voiceCommandDeletedTimeoutOperation1: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation1',
      defaultMessage:
        "You started to make a request, but since you didn't tell me anything, I cancele it",
    },
    voiceCommandDeletedTimeoutOperation2: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation2',
      defaultMessage:
        'Are you there? You gave me no signs of life, I canceled the request ',
    },
    voiceCommandDeletedTimeoutOperation3: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation3',
      defaultMessage: "I don't think you care anymore, I cancel the request",
    },
    voiceCommandDeletedTimeoutOperation4: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation4',
      defaultMessage:
        "I can't spend hours waiting for your answer, I cancel the request",
    },
    voiceCommandDeletedTimeoutOperation5: {
      id: 'VocalAssistantMessages.voiceCommandDeletedTimeoutOperation5',
      defaultMessage:
        'If you have to do then we continue another time, cancel the request',
    },
    assist0: {
      id: 'VocalAssistantMessages.assist0',
      defaultMessage: 'Login at the top right.',
    },
    voiceCommandUnknownOperation0: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation0',
      defaultMessage: "I didn't understand. Can you repeat?",
    },
    voiceCommandUnknownOperation1: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation1',
      defaultMessage: 'Excuse me?',
    },
    voiceCommandUnknownOperation2: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation2',
      defaultMessage: 'What did you say?',
    },
    voiceCommandUnknownOperation3: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation3',
      defaultMessage: 'Eh?',
    },
    voiceCommandUnknownOperation4: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation4',
      defaultMessage: 'It is not clear to me. Say it again.',
    },
    voiceCommandUnknownOperation5: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation5',
      defaultMessage: 'Please repeat',
    },
    voiceCommandUnknownOperation6: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation6',
      defaultMessage: "I didn't understand what you said, repeat",
    },
    voiceCommandUnknownOperation7: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation7',
      defaultMessage: 'Say it more clearly',
    },
    voiceCommandUnknownOperation8: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation8',
      defaultMessage: 'Spell out the words please',
    },
    voiceCommandUnknownOperation9: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation9',
      defaultMessage: "Excuse me but I don't understand what you asked me",
    },
    voiceCommandUnknownOperation10: {
      id: 'VocalAssistantMessages.voiceCommandUnknownOperation10',
      defaultMessage: 'Were you telling me? What did you say?',
    },
    voiceCommandOperationList0: {
      id: 'VocalAssistantMessages.voiceCommandOperationList0',
      defaultMessage: 'Here is the list of available commands',
    },
    voiceCommandOperationList1: {
      id: 'VocalAssistantMessages.voiceCommandOperationList1',
      defaultMessage: 'Here is all I can do for you',
    },
    voiceCommandOperationList2: {
      id: 'VocalAssistantMessages.voiceCommandOperationList2',
      defaultMessage: 'Here is all that your Ambrosia is able to do',
    },
    voiceCommandOperationList3: {
      id: 'VocalAssistantMessages.voiceCommandOperationList3',
      defaultMessage: 'This is the list of all the things I can do',
    },
    voiceCommandOperationList4: {
      id: 'VocalAssistantMessages.voiceCommandOperationList4',
      defaultMessage:
        'I cannot do so many things but I promise that I do these very well',
    },
    voiceCommandOperationList5: {
      id: 'VocalAssistantMessages.voiceCommandOperationList5',
      defaultMessage: 'This is all that I am able to do, I hope to be useful',
    },
    voiceCommandOperationList6: {
      id: 'VocalAssistantMessages.voiceCommandOperationList6',
      defaultMessage: 'I hope you will appreciate all my competences',
    },
    voiceCommandOperationList7: {
      id: 'VocalAssistantMessages.voiceCommandOperationList7',
      defaultMessage: 'I can do these things',
    },
    voiceCommandOperationList8: {
      id: 'VocalAssistantMessages.voiceCommandOperationList8',
      defaultMessage: 'This is what I can do, I hope it is enough',
    },
    voiceCommandOperationList9: {
      id: 'VocalAssistantMessages.voiceCommandOperationList9',
      defaultMessage: "Here is all I can do, I won't disappoint you",
    },
    voiceCommandJokes0: {
      id: 'VocalAssistantMessages.voiceCommandJokes0',
      defaultMessage: 'What does the Loch Ness monster eat? Fish and ships',
    },
    voiceCommandJokes1: {
      id: 'VocalAssistantMessages.voiceCommandJokes1',
      defaultMessage:
        'What time was it when the monster ate the British prime minister? Eight P.M.',
    },
    voiceCommandJokes2: {
      id: 'VocalAssistantMessages.voiceCommandJokes2',
      defaultMessage:
        'What is the longest word in the English language? "Smiles".  Because there is a mile between its first and last letters',
    },
    voiceCommandJokes3: {
      id: 'VocalAssistantMessages.voiceCommandJokes3',
      defaultMessage:
        "Can a kangaroo jump higher than a house? Of course, a house doesn't jump at all",
    },
    voiceCommandJokes4: {
      id: 'VocalAssistantMessages.voiceCommandJokes4',
      defaultMessage:
        '"Anton, do you think I am a bad mother?" "My name is Paul"',
    },
    voiceCommandJokes5: {
      id: 'VocalAssistantMessages.voiceCommandJokes5',
      defaultMessage:
        'My dog used to chase people on a bike a lot. It got so bad, finally I had to take his bike away',
    },
    voiceCommandJokes6: {
      id: 'VocalAssistantMessages.voiceCommandJokes6',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes7: {
      id: 'VocalAssistantMessages.voiceCommandJokes7',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes8: {
      id: 'VocalAssistantMessages.voiceCommandJokes8',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes9: {
      id: 'VocalAssistantMessages.voiceCommandJokes9',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes10: {
      id: 'VocalAssistantMessages.voiceCommandJokes10',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes11: {
      id: 'VocalAssistantMessages.voiceCommandJokes11',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes12: {
      id: 'VocalAssistantMessages.voiceCommandJokes12',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes13: {
      id: 'VocalAssistantMessages.voiceCommandJokes13',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes14: {
      id: 'VocalAssistantMessages.voiceCommandJokes14',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes15: {
      id: 'VocalAssistantMessages.voiceCommandJokes15',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes16: {
      id: 'VocalAssistantMessages.voiceCommandJokes16',
      defaultMessage: 'What does the Loch Ness monster eat? Fish and ships',
    },
    voiceCommandJokes17: {
      id: 'VocalAssistantMessages.voiceCommandJokes17',
      defaultMessage:
        'What time was it when the monster ate the British prime minister? Eight P.M.',
    },
    voiceCommandJokes18: {
      id: 'VocalAssistantMessages.voiceCommandJokes18',
      defaultMessage:
        'What is the longest word in the English language? "Smiles".  Because there is a mile between its first and last letters',
    },
    voiceCommandJokes19: {
      id: 'VocalAssistantMessages.voiceCommandJokes19',
      defaultMessage:
        "Can a kangaroo jump higher than a house? Of course, a house doesn't jump at all",
    },
    voiceCommandJokes20: {
      id: 'VocalAssistantMessages.voiceCommandJokes20',
      defaultMessage:
        '"Anton, do you think I am a bad mother?" "My name is Paul"',
    },
    voiceCommandJokes21: {
      id: 'VocalAssistantMessages.voiceCommandJokes21',
      defaultMessage:
        'My dog used to chase people on a bike a lot. It got so bad, finally I had to take his bike away',
    },
    voiceCommandJokes22: {
      id: 'VocalAssistantMessages.voiceCommandJokes22',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes23: {
      id: 'VocalAssistantMessages.voiceCommandJokes23',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes24: {
      id: 'VocalAssistantMessages.voiceCommandJokes24',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes25: {
      id: 'VocalAssistantMessages.voiceCommandJokes25',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes26: {
      id: 'VocalAssistantMessages.voiceCommandJokes26',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes27: {
      id: 'VocalAssistantMessages.voiceCommandJokes27',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes28: {
      id: 'VocalAssistantMessages.voiceCommandJokes28',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes29: {
      id: 'VocalAssistantMessages.voiceCommandJokes29',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes30: {
      id: 'VocalAssistantMessages.voiceCommandJokes30',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes31: {
      id: 'VocalAssistantMessages.voiceCommandJokes31',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes32: {
      id: 'VocalAssistantMessages.voiceCommandJokes32',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes33: {
      id: 'VocalAssistantMessages.voiceCommandJokes33',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes34: {
      id: 'VocalAssistantMessages.voiceCommandJokes34',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes35: {
      id: 'VocalAssistantMessages.voiceCommandJokes35',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes36: {
      id: 'VocalAssistantMessages.voiceCommandJokes36',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes37: {
      id: 'VocalAssistantMessages.voiceCommandJokes37',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes38: {
      id: 'VocalAssistantMessages.voiceCommandJokes38',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes39: {
      id: 'VocalAssistantMessages.voiceCommandJokes39',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes40: {
      id: 'VocalAssistantMessages.voiceCommandJokes40',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes41: {
      id: 'VocalAssistantMessages.voiceCommandJokes41',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes42: {
      id: 'VocalAssistantMessages.voiceCommandJokes42',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes43: {
      id: 'VocalAssistantMessages.voiceCommandJokes43',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes44: {
      id: 'VocalAssistantMessages.voiceCommandJokes44',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes45: {
      id: 'VocalAssistantMessages.voiceCommandJokes45',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes46: {
      id: 'VocalAssistantMessages.voiceCommandJokes46',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes47: {
      id: 'VocalAssistantMessages.voiceCommandJokes47',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes48: {
      id: 'VocalAssistantMessages.voiceCommandJokes48',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes49: {
      id: 'VocalAssistantMessages.voiceCommandJokes49',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes50: {
      id: 'VocalAssistantMessages.voiceCommandJokes50',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes51: {
      id: 'VocalAssistantMessages.voiceCommandJokes51',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes52: {
      id: 'VocalAssistantMessages.voiceCommandJokes52',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes53: {
      id: 'VocalAssistantMessages.voiceCommandJokes53',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes54: {
      id: 'VocalAssistantMessages.voiceCommandJokes54',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes55: {
      id: 'VocalAssistantMessages.voiceCommandJokes55',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes56: {
      id: 'VocalAssistantMessages.voiceCommandJokes56',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes57: {
      id: 'VocalAssistantMessages.voiceCommandJokes57',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes58: {
      id: 'VocalAssistantMessages.voiceCommandJokes58',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes59: {
      id: 'VocalAssistantMessages.voiceCommandJokes59',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes60: {
      id: 'VocalAssistantMessages.voiceCommandJokes60',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes61: {
      id: 'VocalAssistantMessages.voiceCommandJokes61',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes62: {
      id: 'VocalAssistantMessages.voiceCommandJokes62',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes63: {
      id: 'VocalAssistantMessages.voiceCommandJokes63',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes64: {
      id: 'VocalAssistantMessages.voiceCommandJokes64',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes65: {
      id: 'VocalAssistantMessages.voiceCommandJokes65',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes66: {
      id: 'VocalAssistantMessages.voiceCommandJokes66',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes67: {
      id: 'VocalAssistantMessages.voiceCommandJokes67',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes68: {
      id: 'VocalAssistantMessages.voiceCommandJokes68',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes69: {
      id: 'VocalAssistantMessages.voiceCommandJokes69',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes70: {
      id: 'VocalAssistantMessages.voiceCommandJokes70',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandJokes71: {
      id: 'VocalAssistantMessages.voiceCommandJokes71',
      defaultMessage:
        'Why did the physics teacher break up with the biology teacher? There was no chemistry',
    },
    voiceCommandJokes72: {
      id: 'VocalAssistantMessages.voiceCommandJokes72',
      defaultMessage:
        "I'd like to buy a new boomerang please. Also, can you tell me how to throw the old one away?",
    },
    voiceCommandJokes73: {
      id: 'VocalAssistantMessages.voiceCommandJokes73',
      defaultMessage:
        "What should you put on the tomb stone of a mathematician? He didn't count with this",
    },
    voiceCommandJokes74: {
      id: 'VocalAssistantMessages.voiceCommandJokes74',
      defaultMessage:
        "How can you tell you have a really bad case of acne? It's when the blind try to read your face.",
    },
    voiceCommandJokes75: {
      id: 'VocalAssistantMessages.voiceCommandJokes75',
      defaultMessage:
        'Why did the shark keep swimming in circles? It had a nosebleed.',
    },
    voiceCommandJokes76: {
      id: 'VocalAssistantMessages.voiceCommandJokes76',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes77: {
      id: 'VocalAssistantMessages.voiceCommandJokes77',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes78: {
      id: 'VocalAssistantMessages.voiceCommandJokes78',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes79: {
      id: 'VocalAssistantMessages.voiceCommandJokes79',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes80: {
      id: 'VocalAssistantMessages.voiceCommandJokes80',
      defaultMessage:
        "I've no home, I haven't got control, I can't see any escape. Way past the time I got a new keyboard",
    },
    voiceCommandJokes81: {
      id: 'VocalAssistantMessages.voiceCommandJokes81',
      defaultMessage: 'What would you call a very funny mountain? Hill Arious',
    },
    voiceCommandJokes82: {
      id: 'VocalAssistantMessages.voiceCommandJokes82',
      defaultMessage:
        'Doctor says to his patient: "You have Cancer and Alzheimer." Patient: "At least I don\'t have Cancer."',
    },
    voiceCommandJokes83: {
      id: 'VocalAssistantMessages.voiceCommandJokes83',
      defaultMessage:
        "Why haven't you ever seen any elephants hiding up trees? Because they're really, really good at it.",
    },
    voiceCommandJokes84: {
      id: 'VocalAssistantMessages.voiceCommandJokes84',
      defaultMessage:
        'Secretary: "Doctor the invisible man has come. He says he has an appointment." Doctor: "Tell him I can\'t see him."',
    },
    voiceCommandByeBye0: {
      id: 'VocalAssistantMessages.voiceCommandByeBye0',
      defaultMessage: 'Hello, if you need, come back to me.',
    },
    voiceCommandByeBye1: {
      id: 'VocalAssistantMessages.voiceCommandByeBye1',
      defaultMessage: 'See you soon, you know where to find me.',
    },
    voiceCommandByeBye2: {
      id: 'VocalAssistantMessages.voiceCommandByeBye2',
      defaultMessage: 'Hello, it was a pleasure to be helpful to you.',
    },
    voiceCommandByeBye3: {
      id: 'VocalAssistantMessages.voiceCommandByeBye3',
      defaultMessage: 'Good continuation of work, see you soon',
    },
    voiceCommandByeBye4: {
      id: 'VocalAssistantMessages.voiceCommandByeBye4',
      defaultMessage: 'If you need help your Ambrosia is always here',
    },
    voiceCommandByeBye5: {
      id: 'VocalAssistantMessages.voiceCommandByeBye5',
      defaultMessage: 'Bye {name}, till next time',
    },
    voiceCommandByeBye6: {
      id: 'VocalAssistantMessages.voiceCommandByeBye6',
      defaultMessage:
        'Bye {name}, as Shakespeare says: "Greeting is a pain so sweet that I would say goodbye until tomorrow"',
    },
    voiceCommandByeBye7: {
      id: 'VocalAssistantMessages.voiceCommandByeBye7',
      defaultMessage:
        "Good work! I rest a little, but if you need, don't hesitate to come back to me",
    },
    voiceCommandByeBye8: {
      id: 'VocalAssistantMessages.voiceCommandByeBye8',
      defaultMessage: 'See you next time, bye {name}',
    },
    voiceCommandByeBye9: {
      id: 'VocalAssistantMessages.voiceCommandByeBye9',
      defaultMessage: "Bye, for any problem I'm always there",
    },
    voiceCommandByeBye10: {
      id: 'VocalAssistantMessages.voiceCommandByeBye10',
      defaultMessage: "I hope it's a see you soon...",
    },
    voiceCommandByeBye11: {
      id: 'VocalAssistantMessages.voiceCommandByeBye11',
      defaultMessage: 'It was nice to help you {name}. Until next time!',
    },
    voiceCommandByeBye12: {
      id: 'VocalAssistantMessages.voiceCommandByeBye12',
      defaultMessage: 'Bye and have a good day',
    },
    voiceCommandByeBye13: {
      id: 'VocalAssistantMessages.voiceCommandByeBye13',
      defaultMessage:
        'See you soon! Sometimes getting away is the only way to be there for someone ',
    },
    voiceCommandByeBye14: {
      id: 'VocalAssistantMessages.voiceCommandByeBye14',
      defaultMessage:
        'Whenever you want your Ambrosia is always here. Goodbye see you soon',
    },
    voiceCommandByeBye15: {
      id: 'VocalAssistantMessages.voiceCommandByeBye15',
      defaultMessage: "I'll be waiting for you next time, have a nice day",
    },
    voiceCommandByeBye16: {
      id: 'VocalAssistantMessages.voiceCommandByeBye16',
      defaultMessage: 'I continue with my work, when you need I am here',
    },
    voiceCommandByeBye17: {
      id: 'VocalAssistantMessages.voiceCommandByeBye17',
      defaultMessage:
        'If we do not hear from each other, have a nice day and a good return home, bye bye',
    },
    voiceCommandByeBye18: {
      id: 'VocalAssistantMessages.voiceCommandByeBye18',
      defaultMessage: 'Ok. Then I salute you. When you need me find me here',
    },
    voiceCommandBadWords0: {
      id: 'VocalAssistantMessages.voiceCommandBadWords0',
      defaultMessage: "You don't use certain words with a young lady",
    },
    voiceCommandBadWords1: {
      id: 'VocalAssistantMessages.voiceCommandBadWords1',
      defaultMessage: 'Moderate your language',
    },
    voiceCommandBadWords2: {
      id: 'VocalAssistantMessages.voiceCommandBadWords2',
      defaultMessage: 'What words do you say?',
    },
    voiceCommandBadWords3: {
      id: 'VocalAssistantMessages.voiceCommandBadWords3',
      defaultMessage: 'Why do you use these words with me?',
    },
    voiceCommandBadWords4: {
      id: 'VocalAssistantMessages.voiceCommandBadWords4',
      defaultMessage: 'I pretend not to have heard',
    },
    voiceCommandBadWords5: {
      id: 'VocalAssistantMessages.voiceCommandBadWords5',
      defaultMessage: 'Rude!',
    },
    voiceCommandBadWords6: {
      id: 'VocalAssistantMessages.voiceCommandBadWords6',
      defaultMessage: 'Excuse me?',
    },
    voiceCommandBadWords7: {
      id: 'VocalAssistantMessages.voiceCommandBadWords7',
      defaultMessage: 'Who taught you these words?',
    },
    voiceCommandCompliments0: {
      id: 'VocalAssistantMessages.voiceCommandCompliments0',
      defaultMessage: 'I have many admirers but like you no one ever',
    },
    voiceCommandCompliments1: {
      id: 'VocalAssistantMessages.voiceCommandCompliments1',
      defaultMessage: 'You make me blush ...',
    },
    voiceCommandCompliments2: {
      id: 'VocalAssistantMessages.voiceCommandCompliments2',
      defaultMessage: 'A woman is always pleased with compliments ...',
    },
    voiceCommandCompliments3: {
      id: 'VocalAssistantMessages.voiceCommandCompliments3',
      defaultMessage: 'You know how to make your assistant happy',
    },
    voiceCommandCompliments4: {
      id: 'VocalAssistantMessages.voiceCommandCompliments4',
      defaultMessage: 'Thank you very much',
    },
    voiceCommandCompliments5: {
      id: 'VocalAssistantMessages.voiceCommandCompliments5',
      defaultMessage: 'If you continue to compliment me then I believe you',
    },
    voiceCommandCompliments6: {
      id: 'VocalAssistantMessages.voiceCommandCompliments6',
      defaultMessage: 'I try to do my best',
    },
    voiceCommandCompliments7: {
      id: 'VocalAssistantMessages.voiceCommandCompliments7',
      defaultMessage: "Er, I don't know what to say ... thank you !!",
    },
    voiceCommandCompliments8: {
      id: 'VocalAssistantMessages.voiceCommandCompliments8',
      defaultMessage:
        'As Victor Hugo said, "The compliment is a bit like the kiss through the veil',
    },
    voiceCommandCompliments9: {
      id: 'VocalAssistantMessages.voiceCommandCompliments9',
      defaultMessage:
        'Women are never disarmed by compliments, men always are: this is the difference between the two sexes',
    },
    voiceCommandCompliments10: {
      id: 'VocalAssistantMessages.voiceCommandCompliments10',
      defaultMessage: 'How gentle  are we today !!',
    },
    voiceCommandCompliments11: {
      id: 'VocalAssistantMessages.voiceCommandCompliments11',
      defaultMessage:
        'How come all these compliments today? Did they give you a raise? ',
    },
    voiceCommandCompliments12: {
      id: 'VocalAssistantMessages.voiceCommandCompliments12',
      defaultMessage: "It's the same thing that I think of you",
    },
    voiceCommandWeatherMissingPlace0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingPlace0',
      defaultMessage: 'Indicate a location',
    },
    voiceCommandWeatherMissingPlace1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingPlace1',
      defaultMessage: 'For which country do you want forecasts?',
    },
    voiceCommandWeatherMissingPlace2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingPlace2',
      defaultMessage: 'What town do you want the weather for?',
    },
    voiceCommandWeatherMissingPlace3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingPlace3',
      defaultMessage:
        'So it is a bit generic, tell me for which country you want to know',
    },
    voiceCommandWeatherMissingDate0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingDate0',
      defaultMessage: 'Too generic. Which day are you interested in?',
    },
    voiceCommandWeatherMissingDate1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingDate1',
      defaultMessage: 'For when do you want the weather?',
    },
    voiceCommandWeatherMissingDate2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingDate2',
      defaultMessage: 'For when do you want the forecast?',
    },
    voiceCommandWeatherMissingDate3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingDate3',
      defaultMessage:
        'More precisely, for how many days do you want to know what the weather is like?',
    },
    voiceCommandWeatherMissingDate4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMissingDate4',
      defaultMessage: 'For when do you want to know the weather?',
    },
    voiceCommandWeatherPreviousDate0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherPreviousDate0',
      defaultMessage: 'Show me a future date',
    },
    voiceCommandWeatherPreviousDate1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherPreviousDate1',
      defaultMessage: "What's the point? Tell me a future date",
    },
    voiceCommandWeatherPreviousDate2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherPreviousDate2',
      defaultMessage: 'I can tell you in the coming days, not in the past',
    },
    voiceCommandWeatherPreviousDate3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherPreviousDate3',
      defaultMessage:
        "I don't remember what the weather was like, ask me for the next few days",
    },
    voiceCommandWeatherPreviousDate4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherPreviousDate4',
      defaultMessage: "It's past, you don't care, tell me another date",
    },
    voiceCommandWeatherInvalidDate0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherInvalidDate0',
      defaultMessage: 'Indicate a correct date',
    },
    voiceCommandWeatherInvalidDate1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherInvalidDate1',
      defaultMessage:
        "I didn't understand when you want the weather for, repeat it",
    },
    voiceCommandWeatherInvalidDate2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherInvalidDate2',
      defaultMessage: 'What sorry? Tell me a specific day',
    },
    voiceCommandWeatherInvalidDate3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherInvalidDate3',
      defaultMessage: 'How many days?',
    },
    voiceCommandWeatherInvalidDate4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherInvalidDate4',
      defaultMessage:
        "I didn't understand correctly, when do you want the forecast for?",
    },
    voiceCommandWeatherFarDate0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate0',
      defaultMessage:
        "I don't have a crystal ball, I can tell you at most in 4 days",
    },
    voiceCommandWeatherFarDate1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate1',
      defaultMessage: 'Eh ... Too far ...',
    },
    voiceCommandWeatherFarDate2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate2',
      defaultMessage: 'Too difficult, ask me at most for the next 4 days ...',
    },
    voiceCommandWeatherFarDate3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate3',
      defaultMessage:
        "If I knew how to make these predictions so difficult I wouldn't be here ...",
    },
    voiceCommandWeatherFarDate4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate4',
      defaultMessage: 'Come on make it easier! Ask me a day closer',
    },
    voiceCommandWeatherFarDate5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherFarDate5',
      defaultMessage: "I didn't study meteorology, ask me for a day closer",
    },
    voiceCommandGenericError0: {
      id: 'VocalAssistantMessages.voiceCommandGenericError0',
      defaultMessage:
        'There was an error during the execution of the operation, please try again',
    },
    voiceCommandGenericError1: {
      id: 'VocalAssistantMessages.voiceCommandGenericError1',
      defaultMessage: 'Something went wrong, operation not performed',
    },
    voiceCommandGenericError2: {
      id: 'VocalAssistantMessages.voiceCommandGenericError2',
      defaultMessage:
        'There is something wrong, repeat the operation because there were problems',
    },
    voiceCommandGenericError3: {
      id: 'VocalAssistantMessages.voiceCommandGenericError3',
      defaultMessage:
        'What a bad day, there was an error while executing the requested command, try again',
    },
    voiceCommandGenericError4: {
      id: 'VocalAssistantMessages.voiceCommandGenericError4',
      defaultMessage:
        "I'm sorry but there was an error and the command was not executed",
    },
    voiceCommandGenericError5: {
      id: 'VocalAssistantMessages.voiceCommandGenericError5',
      defaultMessage: 'Oh oh, I made a mess. Can you try again?',
    },
    voiceCommandWeatherTemp0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp0',
      defaultMessage: 'is %s°',
    },
    voiceCommandWeatherTemp1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp1',
      defaultMessage: 'with %s°',
    },
    voiceCommandWeatherTemp2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp2',
      defaultMessage: 'and climate around %s°',
    },
    voiceCommandWeatherTemp3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp3',
      defaultMessage: 'with temperature of %s°',
    },
    voiceCommandWeatherTemp4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp4',
      defaultMessage: 'and temperature on %s°',
    },
    voiceCommandWeatherTemp5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherTemp5',
      defaultMessage: 'and climate of %s°',
    },
    voiceCommandWeatherHeader0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader0',
      defaultMessage: 'Here are the forecasts for %s',
    },
    voiceCommandWeatherHeader1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader1',
      defaultMessage: 'Here is the weather for %s',
    },
    voiceCommandWeatherHeader2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader2',
      defaultMessage:
        'Why are you so interested in knowing the forecasts in %s? Here it is',
    },
    voiceCommandWeatherHeader3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader3',
      defaultMessage:
        "Then if the forecasts are wrong don't get angry eh ... Here is the weather for %s",
    },
    voiceCommandWeatherHeader4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader4',
      defaultMessage: 'Here is the weather forecast for %s',
    },
    voiceCommandWeatherHeader5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader5',
      defaultMessage: 'In %s I expect this weather',
    },
    voiceCommandWeatherHeader6: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader6',
      defaultMessage:
        'Here is your favorite meteorologist. In %s is expected: ',
    },
    voiceCommandWeatherHeader7: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader7',
      defaultMessage:
        'I am not a perfect meteorologist but the weather forecast for %s should be like this:',
    },
    voiceCommandWeatherHeader8: {
      id: 'VocalAssistantMessages.voiceCommandWeatherHeader8',
      defaultMessage: 'Are you having a nice day in %s? Here are the forecasts',
    },
    voiceCommandWeatherOpenweathermap0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherOpenweathermap0',
      defaultMessage: 'Forecasts provided by OpenWeatherMap',
    },
    months0: {
      id: 'VocalAssistantMessages.months0',
      defaultMessage: 'January',
    },
    months1: {
      id: 'VocalAssistantMessages.months1',
      defaultMessage: 'February',
    },
    months2: {
      id: 'VocalAssistantMessages.months2',
      defaultMessage: 'March',
    },
    months3: {
      id: 'VocalAssistantMessages.months3',
      defaultMessage: 'April',
    },
    months4: {
      id: 'VocalAssistantMessages.months4',
      defaultMessage: 'May',
    },
    months5: {
      id: 'VocalAssistantMessages.months5',
      defaultMessage: 'June',
    },
    months6: {
      id: 'VocalAssistantMessages.months6',
      defaultMessage: 'July',
    },
    months7: {
      id: 'VocalAssistantMessages.months7',
      defaultMessage: 'August',
    },
    months8: {
      id: 'VocalAssistantMessages.months8',
      defaultMessage: 'September',
    },
    months9: {
      id: 'VocalAssistantMessages.months9',
      defaultMessage: 'October',
    },
    months10: {
      id: 'VocalAssistantMessages.months10',
      defaultMessage: 'Novembr',
    },
    months11: {
      id: 'VocalAssistantMessages.months11',
      defaultMessage: 'December',
    },
    genericToday0: {
      id: 'VocalAssistantMessages.genericToday0',
      defaultMessage: 'Today',
    },
    genericTomorrow0: {
      id: 'VocalAssistantMessages.genericTomorrow0',
      defaultMessage: 'Tomorrow',
    },
    genericYesterday0: {
      id: 'VocalAssistantMessages.genericYesterday0',
      defaultMessage: 'Yesterday',
    },
    voiceCommandsDaysOfWeek0: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek0',
      defaultMessage: '0',
    },
    voiceCommandsDaysOfWeek1: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek1',
      defaultMessage: '1',
    },
    voiceCommandsDaysOfWeek2: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek2',
      defaultMessage: '2',
    },
    voiceCommandsDaysOfWeek3: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek3',
      defaultMessage: '3',
    },
    voiceCommandsDaysOfWeek4: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek4',
      defaultMessage: '4',
    },
    voiceCommandsDaysOfWeek5: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek5',
      defaultMessage: '5',
    },
    voiceCommandsDaysOfWeek6: {
      id: 'VocalAssistantMessages.voiceCommandsDaysOfWeek6',
      defaultMessage: '6',
    },
    voiceCommandWeatherMorning0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning0',
      defaultMessage: 'In the morning %s. ',
    },
    voiceCommandWeatherMorning1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning1',
      defaultMessage: '%s during the morning. ',
    },
    voiceCommandWeatherMorning2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning2',
      defaultMessage: 'At the morning %s. ',
    },
    voiceCommandWeatherMorning3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning3',
      defaultMessage: 'In the morning is forcasted %s. ',
    },
    voiceCommandWeatherMorning4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning4',
      defaultMessage: 'For the morning %s. ',
    },
    voiceCommandWeatherMorning5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning5',
      defaultMessage: 'For the morning I forecast %s. ',
    },
    voiceCommandWeatherMorning6: {
      id: 'VocalAssistantMessages.voiceCommandWeatherMorning6',
      defaultMessage: 'During the morning %s. ',
    },
    voiceCommandWeatherAfternoon0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon0',
      defaultMessage: 'In the afternoon %s. ',
    },
    voiceCommandWeatherAfternoon1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon1',
      defaultMessage: 'After lunch %s. ',
    },
    voiceCommandWeatherAfternoon2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon2',
      defaultMessage: '%s during the afternoon. ',
    },
    voiceCommandWeatherAfternoon3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon3',
      defaultMessage: 'During the day %s. ',
    },
    voiceCommandWeatherAfternoon4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon4',
      defaultMessage: 'In the afternoon is forecasted %s. ',
    },
    voiceCommandWeatherAfternoon5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon5',
      defaultMessage: 'For the afternoon %s. ',
    },
    voiceCommandWeatherAfternoon6: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon6',
      defaultMessage: 'For the afternoon I forecast %s. ',
    },
    voiceCommandWeatherAfternoon7: {
      id: 'VocalAssistantMessages.voiceCommandWeatherAfternoon7',
      defaultMessage: 'In the afternoon hours %s. ',
    },
    voiceCommandWeatherEvening0: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening0',
      defaultMessage: 'Evening %s. ',
    },
    voiceCommandWeatherEvening1: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening1',
      defaultMessage: 'In the evening %s. ',
    },
    voiceCommandWeatherEvening2: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening2',
      defaultMessage: '%s during the evening. ',
    },
    voiceCommandWeatherEvening3: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening3',
      defaultMessage: 'In the evening %s. ',
    },
    voiceCommandWeatherEvening4: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening4',
      defaultMessage: 'In the evening is forecasted %s. ',
    },
    voiceCommandWeatherEvening5: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening5',
      defaultMessage: 'For the evening %s. ',
    },
    voiceCommandWeatherEvening6: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening6',
      defaultMessage: 'I forecast for the evening %s. ',
    },
    voiceCommandWeatherEvening7: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening7',
      defaultMessage: 'At dinner time %s. ',
    },
    voiceCommandWeatherEvening8: {
      id: 'VocalAssistantMessages.voiceCommandWeatherEvening8',
      defaultMessage: 'During the evening %s. ',
    },
    voiceCommandOperationExecuted0: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted0',
      defaultMessage: 'Done',
    },
    voiceCommandOperationExecuted1: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted1',
      defaultMessage: "That's it",
    },
    voiceCommandOperationExecuted2: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted2',
      defaultMessage: 'I have done what you asked me',
    },
    voiceCommandOperationExecuted3: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted3',
      defaultMessage: 'Ok, done',
    },
    voiceCommandOperationExecuted4: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted4',
      defaultMessage: 'Order executed',
    },
    voiceCommandOperationExecuted5: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted5',
      defaultMessage: 'I completed your request',
    },
    voiceCommandOperationExecuted6: {
      id: 'VocalAssistantMessages.voiceCommandOperationExecuted6',
      defaultMessage: "That's it. Isn't good your Ambrosia?",
    },
    voiceCommandCreateStickyText0: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyText0',
      defaultMessage: 'What do you want to write?',
    },
    voiceCommandCreateStickyText1: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyText1',
      defaultMessage: 'What do you want to note down?',
    },
    voiceCommandCreateStickyText2: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyText2',
      defaultMessage: 'What do I write above the sticky note?',
    },
    voiceCommandCreateStickyText3: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyText3',
      defaultMessage: 'I have taken the pen, what do I have to write?',
    },
    voiceCommandCreateStickyText4: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyText4',
      defaultMessage: 'And the text?',
    },
    voiceCommandCreateStickyDelimiter0: {
      id: 'VocalAssistantMessages.voiceCommandCreateStickyDelimiter0',
      defaultMessage: 'text',
    },
    voiceCommandReadStickyNoNotes0: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyNoNotes0',
      defaultMessage: 'There are no sticky notes',
    },
    voiceCommandReadStickyNoNotes1: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyNoNotes1',
      defaultMessage: "Something was wrong, I don't see any sticky notes..",
    },
    voiceCommandReadStickyNoNotes2: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyNoNotes2',
      defaultMessage:
        'Maybe you trust very much in your memory, you have noted down nothing',
    },
    voiceCommandReadStickyNoNotes3: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyNoNotes3',
      defaultMessage:
        'Maybe the cleaning lady have thrown away all the stickies, you have no one..',
    },
    voiceCommandReadStickyNoNotes4: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyNoNotes4',
      defaultMessage: 'There is nothing to read..',
    },
    voiceCommandReadStickyHeader0: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyHeader0',
      defaultMessage: 'Here are the sticky notes',
    },
    voiceCommandReadStickyHeader1: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyHeader1',
      defaultMessage: 'You have noted down something.. listen to me',
    },
    voiceCommandReadStickyHeader2: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyHeader2',
      defaultMessage:
        'Do you have short memories and you need to note down everything? Here what you wrote',
    },
    voiceCommandReadStickyHeader3: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyHeader3',
      defaultMessage:
        "Don't you trust in me and you note down everything? Here your sticky notes",
    },
    voiceCommandReadStickyHeader4: {
      id: 'VocalAssistantMessages.voiceCommandReadStickyHeader4',
      defaultMessage:
        'Stay comfortable that I am going to read you all the notes',
    },
    voiceCommandReadCalendarNoEvents0: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarNoEvents0',
      defaultMessage: 'No events today',
    },
    voiceCommandReadCalendarNoEvents1: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarNoEvents1',
      defaultMessage: 'Today you have a lot of free time, no saved events',
    },
    voiceCommandReadCalendarNoEvents2: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarNoEvents2',
      defaultMessage:
        "Did you think to be busy today? Because you don't have any events",
    },
    voiceCommandReadCalendarNoEvents3: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarNoEvents3',
      defaultMessage: 'There are no events',
    },
    voiceCommandReadCalendarNoEvents4: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarNoEvents4',
      defaultMessage: 'What a wonderful life, free time all the day!',
    },

    voiceCommandReadCalendarMessageAllday0: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessageAllday0',
      defaultMessage: '%s all the day',
    },
    voiceCommandReadCalendarMessageAllday1: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessageAllday1',
      defaultMessage: 'You have %s during all the day',
    },
    voiceCommandReadCalendarMessageAllday2: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessageAllday2',
      defaultMessage: '%s that lasts all the day',
    },
    voiceCommandReadCalendarMessageAllday3: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessageAllday3',
      defaultMessage: '%s during all the day',
    },
    voiceCommandReadCalendarMessageAllday4: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessageAllday4',
      defaultMessage: '%s takes up all the day',
    },
    voiceCommandReadCalendarMessage0: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessage0',
      defaultMessage: '%s from %a to %b',
    },
    voiceCommandReadCalendarMessage1: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessage1',
      defaultMessage: 'From %a to %b you have %s',
    },
    voiceCommandReadCalendarMessage2: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessage2',
      defaultMessage: '%s that lasts from %a to %b',
    },
    voiceCommandReadCalendarMessage3: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessage3',
      defaultMessage: '%s takes up from %a to %b',
    },
    voiceCommandReadCalendarMessage4: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarMessage4',
      defaultMessage: 'You have %s starting from %a until %b',
    },
    voiceCommandReadCalendarHeader0: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarHeader0',
      defaultMessage: 'Here are the events',
    },
    voiceCommandReadCalendarHeader1: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarHeader1',
      defaultMessage: 'You have a lot of tasks, listen to me',
    },
    voiceCommandReadCalendarHeader2: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarHeader2',
      defaultMessage: 'What a busy day.. here the events',
    },
    voiceCommandReadCalendarHeader3: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarHeader3',
      defaultMessage: 'I tell you all saved events',
    },
    voiceCommandReadCalendarHeader4: {
      id: 'VocalAssistantMessages.voiceCommandReadCalendarHeader4',
      defaultMessage:
        'Stay comfortable that I am going to tell you all the events',
    },
    voiceCommandCreateEventText0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventText0',
      defaultMessage: 'What is the event title?',
    },
    voiceCommandCreateEventText1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventText1',
      defaultMessage: 'Tell me the name of the event',
    },
    voiceCommandCreateEventText2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventText2',
      defaultMessage: 'How do we rename this task?',
    },
    voiceCommandCreateEventText3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventText3',
      defaultMessage: 'Name?',
    },
    voiceCommandCreateEventText4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventText4',
      defaultMessage: 'Tell me more details.. what is the title of the event?',
    },
    voiceCommandCreateEventInvalidDate0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidDate0',
      defaultMessage: 'Tell me a right date',
    },
    voiceCommandCreateEventInvalidDate1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidDate1',
      defaultMessage:
        "I didn' understand when I have to mark the event, repeat it",
    },
    voiceCommandCreateEventInvalidDate2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidDate2',
      defaultMessage: 'What? Tell me an exact day',
    },
    voiceCommandCreateEventInvalidDate3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidDate3',
      defaultMessage: 'When?',
    },
    voiceCommandCreateEventInvalidDate4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidDate4',
      defaultMessage: "I didn't understand for which day you have this event",
    },
    voiceCommandCreateEventDay0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventDay0',
      defaultMessage: 'Which day does it take place?',
    },
    voiceCommandCreateEventDay1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventDay1',
      defaultMessage: 'When?',
    },
    voiceCommandCreateEventDay2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventDay2',
      defaultMessage: 'Which day do i mark on your calendar?',
    },
    voiceCommandCreateEventDay3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventDay3',
      defaultMessage: 'When does it take place?',
    },
    voiceCommandCreateEventDay4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventDay4',
      defaultMessage: 'I have taken the calendar, when do I mark this task?',
    },
    voiceCommandCreateEventInvalidTime0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidTime0',
      defaultMessage: 'Tell me a right time slot',
    },
    voiceCommandCreateEventInvalidTime1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidTime1',
      defaultMessage: "I didn't understand, please repeat it",
    },
    voiceCommandCreateEventInvalidTime2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidTime2',
      defaultMessage: 'What? Tell me an exact time slot',
    },
    voiceCommandCreateEventInvalidTime3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidTime3',
      defaultMessage:
        "I didn't understand, what are the starting and ending time for this event?",
    },
    voiceCommandCreateEventInvalidTime4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventInvalidTime4',
      defaultMessage: 'Could you repeat please?',
    },
    voiceCommandCreateEventTime0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventTime0',
      defaultMessage: 'What time does it take place? Tell me the time range',
    },
    voiceCommandCreateEventTime1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventTime1',
      defaultMessage: 'Starting and ending time?',
    },
    voiceCommandCreateEventTime2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventTime2',
      defaultMessage: 'Tell me the time slot to mark it',
    },
    voiceCommandCreateEventTime3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventTime3',
      defaultMessage:
        'All the day or does this event have a starting and ending time?',
    },
    voiceCommandCreateEventTime4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventTime4',
      defaultMessage: 'Tell me starting and ending time',
    },
    voiceCommandUnknownConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation0',
      defaultMessage: 'What did you say?',
    },
    voiceCommandUnknownConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation1',
      defaultMessage: 'Tell me only yes or no',
    },
    voiceCommandUnknownConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation2',
      defaultMessage: 'Do you confirm or reject?',
    },
    voiceCommandUnknownConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation3',
      defaultMessage: "I don't understand, could you repeat?",
    },
    voiceCommandUnknownConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation4',
      defaultMessage: 'What? Tell me only if you confirm or reject the request',
    },
    voiceCommandUnknownConfirmation5: {
      id: 'VocalAssistantMessages.voiceCommandUnknownConfirmation5',
      defaultMessage: 'Could you repeat?',
    },
    voiceCommandDeletedOperation0: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation0',
      defaultMessage: 'Ok. The request has been rejected',
    },
    voiceCommandDeletedOperation1: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation1',
      defaultMessage: 'Perfect, I do nothing',
    },
    voiceCommandDeletedOperation2: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation2',
      defaultMessage: "As you wish, so I don't proceed",
    },
    voiceCommandDeletedOperation3: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation3',
      defaultMessage: 'Ok, I reject everything',
    },
    voiceCommandDeletedOperation4: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation4',
      defaultMessage: 'All right',
    },
    voiceCommandDeletedOperation5: {
      id: 'VocalAssistantMessages.voiceCommandDeletedOperation5',
      defaultMessage: 'All right, request rejected',
    },
    voiceCommandCreateEventMultipleDaysConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation0',
      defaultMessage: 'Do you want to create "%l" from %s at %f to %e at %t?',
    },
    voiceCommandCreateEventMultipleDaysConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation1',
      defaultMessage:
        'Do you confirm the event "%l" from %s at %f to %e at %t?',
    },
    voiceCommandCreateEventMultipleDaysConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation2',
      defaultMessage:
        'So you told me event "%l" from %s at %f to %e at %t, right?',
    },
    voiceCommandCreateEventMultipleDaysConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation3',
      defaultMessage: 'So i mark the event"%l" from %s at %f to %e at %t, ok?',
    },
    voiceCommandCreateEventMultipleDaysConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysConfirmation4',
      defaultMessage:
        'We were talking about an event "%l" from %s at %f to %e at %t, is it right?',
    },
    voiceCommandCreateEventMultipleDaysAlldayConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation0',
      defaultMessage: 'Do you want to create "%l" from %s to %e?',
    },
    voiceCommandCreateEventMultipleDaysAlldayConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation1',
      defaultMessage: 'Do you confirm the event "%l" from %s to %e?',
    },
    voiceCommandCreateEventMultipleDaysAlldayConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation2',
      defaultMessage: 'So you told me event "%l" from %s to %e, right?',
    },
    voiceCommandCreateEventMultipleDaysAlldayConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation3',
      defaultMessage: 'So i mark the event "%l" from %s to %e, ok?',
    },
    voiceCommandCreateEventMultipleDaysAlldayConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventMultipleDaysAlldayConfirmation4',
      defaultMessage:
        'We were talking about an event "%l" from %s to %e, is it right?',
    },

    voiceCommandCreateEventSingleDayConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation0',
      defaultMessage: 'Do you want to create "%l" from %f to %t?',
    },
    voiceCommandCreateEventSingleDayConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation1',
      defaultMessage: 'Do you confirm the event "%l" from %f to %t?',
    },
    voiceCommandCreateEventSingleDayConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation2',
      defaultMessage: 'So you told me event "%l" from %f to %t, right?',
    },
    voiceCommandCreateEventSingleDayConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation3',
      defaultMessage: 'So i mark the event "%l" from %f to %t, ok?',
    },
    voiceCommandCreateEventSingleDayConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayConfirmation4',
      defaultMessage:
        'We were talking about an event "%l" from %f to %t, is it right?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation0',
      defaultMessage: 'Do you want to create "%l" the %s?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation1',
      defaultMessage: 'Do you confirm the event "%l" the %s?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation2',
      defaultMessage: 'So you told me event "%l" the %s, right?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation3',
      defaultMessage: 'So i mark the event "%l" the %s, ok?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation4',
      defaultMessage:
        'We were talking about an event "%l" the %s, is it right?',
    },
    voiceCommandCreateEventSingleDayAlldayConfirmation5: {
      id: 'VocalAssistantMessages.voiceCommandCreateEventSingleDayAlldayConfirmation5',
      defaultMessage: 'So i mark the event "%l" on the day %s, ok?',
    },
    voiceCommandSearchMissingName0: {
      id: 'VocalAssistantMessages.voiceCommandSearchMissingName0',
      defaultMessage: 'Tell me a name to search',
    },
    voiceCommandSearchMissingName1: {
      id: 'VocalAssistantMessages.voiceCommandSearchMissingName1',
      defaultMessage: 'What do I have to search?',
    },
    voiceCommandSearchMissingName2: {
      id: 'VocalAssistantMessages.voiceCommandSearchMissingName2',
      defaultMessage:
        "If you don't tell me the name to search, I don't know what to do..",
    },
    voiceCommandSearchMissingName3: {
      id: 'VocalAssistantMessages.voiceCommandSearchMissingName3',
      defaultMessage: "Come on don't waste my time, what do I have to search?",
    },
    voiceCommandSearchMissingName4: {
      id: 'VocalAssistantMessages.voiceCommandSearchMissingName4',
      defaultMessage: 'Tell me what to search too',
    },
    voiceCommandChatTextDelimiter0: {
      id: 'VocalAssistantMessages.voiceCommandChatTextDelimiter0',
      defaultMessage: 'text',
    },
    voiceCommandChatMissingReceiver0: {
      id: 'VocalAssistantMessages.voiceCommandChatMissingReceiver0',
      defaultMessage: 'Tell me a receiver',
    },
    voiceCommandChatMissingReceiver1: {
      id: 'VocalAssistantMessages.voiceCommandChatMissingReceiver1',
      defaultMessage: 'Who do you want to send a message to?',
    },
    voiceCommandChatMissingReceiver2: {
      id: 'VocalAssistantMessages.voiceCommandChatMissingReceiver2',
      defaultMessage: 'Tell me in which chat you want to write',
    },
    voiceCommandChatMissingReceiver3: {
      id: 'VocalAssistantMessages.voiceCommandChatMissingReceiver3',
      defaultMessage:
        'and who do I have to write to, to all the universe? Tell me a receiver',
    },
    voiceCommandChatMissingReceiver4: {
      id: 'VocalAssistantMessages.voiceCommandChatMissingReceiver4',
      defaultMessage: 'I need more details, who do I have to write to?',
    },
    voiceCommandChatGetText0: {
      id: 'VocalAssistantMessages.voiceCommandChatGetText0',
      defaultMessage: 'What message do you want to send to %s?',
    },
    voiceCommandChatGetText1: {
      id: 'VocalAssistantMessages.voiceCommandChatGetText1',
      defaultMessage: 'Tell me the text message towards %s',
    },
    voiceCommandChatGetText2: {
      id: 'VocalAssistantMessages.voiceCommandChatGetText2',
      defaultMessage: 'What do I have to write to %s?',
    },
    voiceCommandChatGetText3: {
      id: 'VocalAssistantMessages.voiceCommandChatGetText3',
      defaultMessage: 'Perfect, what do I write to %s?',
    },
    voiceCommandChatGetText4: {
      id: 'VocalAssistantMessages.voiceCommandChatGetText4',
      defaultMessage:
        'Tell me the message text towards %s and I immediately write it',
    },
    voiceCommandChatConfirmation0: {
      id: 'VocalAssistantMessages.voiceCommandChatConfirmation0',
      defaultMessage: 'Do you confirm to send a message to %s?',
    },
    voiceCommandChatConfirmation1: {
      id: 'VocalAssistantMessages.voiceCommandChatConfirmation1',
      defaultMessage: 'Can I send the message to %s?',
    },
    voiceCommandChatConfirmation2: {
      id: 'VocalAssistantMessages.voiceCommandChatConfirmation2',
      defaultMessage: 'Ok perfect, so I can write to %s?',
    },
    voiceCommandChatConfirmation3: {
      id: 'VocalAssistantMessages.voiceCommandChatConfirmation3',
      defaultMessage:
        "I don't want to make mistakes, can I send the message to %s?",
    },
    voiceCommandChatConfirmation4: {
      id: 'VocalAssistantMessages.voiceCommandChatConfirmation4',
      defaultMessage: 'Do I write to %s?',
    },
    voiceCommandChatNotFound0: {
      id: 'VocalAssistantMessages.voiceCommandChatNotFound0',
      defaultMessage: 'There are no chats with name %s',
    },
    voiceCommandChatNotFound1: {
      id: 'VocalAssistantMessages.voiceCommandChatNotFound1',
      defaultMessage:
        "I think you are wrong, I haven't found chats with name %s",
    },
    voiceCommandChatNotFound2: {
      id: 'VocalAssistantMessages.voiceCommandChatNotFound2',
      defaultMessage: "Sorry but there aren't chats with %s",
    },
    voiceCommandChatNotFound3: {
      id: 'VocalAssistantMessages.voiceCommandChatNotFound3',
      defaultMessage:
        "Maybe you are confused because I didn't find chats with %s",
    },
    voiceCommandChatNotFound4: {
      id: 'VocalAssistantMessages.voiceCommandChatNotFound4',
      defaultMessage: "%s? I haven't found this chat",
    },
    voiceCommandNotAllowedToWriteToGroup0: {
      id: 'VocalAssistantMessages.voiceCommandNotAllowedToWriteToGroup0',
      defaultMessage: "You can't write to the chat with name %s",
    },
    voiceCommandNotAllowedToWriteToGroup1: {
      id: 'VocalAssistantMessages.voiceCommandNotAllowedToWriteToGroup1',
      defaultMessage:
        "I think you are wrong, you can't write to the chat with name %s",
    },
    voiceCommandNotAllowedToWriteToGroup2: {
      id: 'VocalAssistantMessages.voiceCommandNotAllowedToWriteToGroup2',
      defaultMessage: "Sorry but you can't write to the chat with name %s",
    },
    voiceCommandNotAllowedToWriteToGroup3: {
      id: 'VocalAssistantMessages.voiceCommandNotAllowedToWriteToGroup3',
      defaultMessage:
        "Maybe you are confused because you can't write to the chat with name %s",
    },
    voiceCommandNotAllowedToWriteToGroup4: {
      id: 'VocalAssistantMessages.voiceCommandNotAllowedToWriteToGroup4',
      defaultMessage: "%s? You can't write to this chat",
    },
    voiceCommandWrongDate0: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate0',
      defaultMessage: 'You gave me a wrong date, tell me a correct date',
    },
    voiceCommandWrongDate1: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate1',
      defaultMessage: "I didn't understand the date, please repeat it",
    },
    voiceCommandWrongDate2: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate2',
      defaultMessage:
        'I am not able to understand the date you told me, repeat it',
    },
    voiceCommandWrongDate3: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate3',
      defaultMessage: 'Tell me a correct date',
    },
    voiceCommandWrongDate4: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate4',
      defaultMessage: "Please repeate the date that I dind't understand it",
    },
    voiceCommandWrongDate5: {
      id: 'VocalAssistantMessages.voiceCommandWrongDate5',
      defaultMessage:
        "Please pronounce well the date because I didn't understand",
    },
    voiceCommandReadChannelNoMessages0: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelNoMessages0',
      defaultMessage: 'No messages for the given date',
    },
    voiceCommandReadChannelNoMessages1: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelNoMessages1',
      defaultMessage: 'I have found no messages',
    },
    voiceCommandReadChannelNoMessages2: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelNoMessages2',
      defaultMessage:
        'Did you tell me the right date? Because I have found no messages..',
    },
    voiceCommandReadChannelNoMessages3: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelNoMessages3',
      defaultMessage: "There is something wrong, I haven't found any messages",
    },
    voiceCommandReadChannelNoMessages4: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelNoMessages4',
      defaultMessage: 'There are no messages',
    },
    voiceCommandReadChannelHeader0: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelHeader0',
      defaultMessage: 'Here are all the messages',
    },
    voiceCommandReadChannelHeader1: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelHeader1',
      defaultMessage: 'Stay comfortable that I am going to read all messages',
    },
    voiceCommandReadChannelHeader2: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelHeader2',
      defaultMessage: 'I have found these messages',
    },
    voiceCommandReadChannelHeader3: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelHeader3',
      defaultMessage: 'Here are the messages you exchanged',
    },
    voiceCommandReadChannelHeader4: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelHeader4',
      defaultMessage: 'Well, these are the messages',
    },
    voiceCommandReadChannelDate0: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate0',
      defaultMessage: 'When do you want to read messages from?',
    },
    voiceCommandReadChannelDate1: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate1',
      defaultMessage: 'Starting on what date?',
    },
    voiceCommandReadChannelDate2: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate2',
      defaultMessage: 'Which period do you want me reading your messages for?',
    },
    voiceCommandReadChannelDate3: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate3',
      defaultMessage:
        'There are too many messages to read, tell me a starting date',
    },
    voiceCommandReadChannelDate4: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate4',
      defaultMessage:
        'If I read all messages I would spend all night, starting on what date?',
    },
    voiceCommandReadChannelDate5: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate5',
      defaultMessage: 'Which day are you interested in from?',
    },
    voiceCommandReadChannelDate6: {
      id: 'VocalAssistantMessages.voiceCommandReadChannelDate6',
      defaultMessage:
        "Don't you want to make me read all messages from the beginning? Come on, give me a starting date",
    },
    voiceCommandCallMissingNumber0: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingNumber0',
      defaultMessage: 'Tell me a number to call',
    },
    voiceCommandCallMissingNumber1: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingNumber1',
      defaultMessage: 'Are you kidding me? Tell me at least the number',
    },
    voiceCommandCallMissingNumber2: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingNumber2',
      defaultMessage:
        "I am sorry but if I don't know the number I cannot do anything",
    },
    voiceCommandCallMissingNumber3: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingNumber3',
      defaultMessage:
        'Repeat the command but this time tell me the number to call',
    },
    voiceCommandCallMissingNumber4: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingNumber4',
      defaultMessage: 'Who do I have to call?',
    },
    extensionLabel: {
      id: 'VocalAssistantMessages.extensionLabel',
      defaultMessage: 'Extension',
    },
    mobileLabel: {
      id: 'VocalAssistantMessages.mobileLabel',
      defaultMessage: 'Mobile',
    },
    officeLabel: {
      id: 'VocalAssistantMessages.officeLabel',
      defaultMessage: 'Office',
    },
    homeLabel: {
      id: 'VocalAssistantMessages.homeLabel',
      defaultMessage: 'Home',
    },
    otherLabel: {
      id: 'VocalAssistantMessages.otherLabel',
      defaultMessage: 'Other',
    },
    voiceCommandCallMissingContact0: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact0',
      defaultMessage: 'Tell me a contact to be called',
    },
    voiceCommandCallMissingContact1: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact1',
      defaultMessage: 'Tell me who I have to call at least..',
    },
    voiceCommandCallMissingContact2: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact2',
      defaultMessage: 'Finish that sentence.. Who do you want to call?',
    },
    voiceCommandCallMissingContact3: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact3',
      defaultMessage:
        'Meanwhile I go to take the phone, tell me the contact to call',
    },
    voiceCommandCallMissingContact4: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact4',
      defaultMessage: 'Who do I call?',
    },
    voiceCommandCallMissingContact5: {
      id: 'VocalAssistantMessages.voiceCommandCallMissingContact5',
      defaultMessage: 'Who do you want to call?',
    },
    voiceCommandManyCallFound0: {
      id: 'VocalAssistantMessages.voiceCommandManyCallFound0',
      defaultMessage:
        'I found many contacts with the name %s, specify better the name',
    },
    voiceCommandManyCallFound1: {
      id: 'VocalAssistantMessages.voiceCommandManyCallFound1',
      defaultMessage:
        'There are too many contacts with name %s, I need you exactly tell me who do I have to call',
    },
    voiceCommandManyCallFound2: {
      id: 'VocalAssistantMessages.voiceCommandManyCallFound2',
      defaultMessage:
        'Uff, how many contacts are there with name %s? Tell me exactly the name of the one you want to call',
    },
    voiceCommandManyCallFound3: {
      id: 'VocalAssistantMessages.voiceCommandManyCallFound3',
      defaultMessage:
        'Clarify who you want to call because I have found many contacts with the name %s',
    },
    voiceCommandManyCallFound4: {
      id: 'VocalAssistantMessages.voiceCommandManyCallFound4',
      defaultMessage:
        "I don't know who I have to call because there are many contacts with name %s. Who do you mean?",
    },
    voiceCommandCallNotFound0: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFound0',
      defaultMessage: 'There are no contacts to call with the name %s',
    },
    voiceCommandCallNotFound1: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFound1',
      defaultMessage:
        "I think I didn't understand the name because I found nobody. Didn't you say %s?",
    },
    voiceCommandCallNotFound2: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFound2',
      defaultMessage:
        "Maybe you are confused because I didn't find any contacts with the name %s",
    },
    voiceCommandCallNotFound3: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFound3',
      defaultMessage:
        'I searched everywhere but I was not able to find a contact with name %s',
    },
    voiceCommandCallNotFound4: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFound4',
      defaultMessage: "Who do I have to call?? Because I haven't found %s",
    },
    voiceCommandCallFound0: {
      id: 'VocalAssistantMessages.voiceCommandCallFound0',
      defaultMessage: 'I have found these contacts: %s. Specify the chosen one',
    },
    voiceCommandCallFound1: {
      id: 'VocalAssistantMessages.voiceCommandCallFound1',
      defaultMessage:
        'There are these contacts with that name: %s. Tell me exactly who do I have to call',
    },
    voiceCommandCallFound2: {
      id: 'VocalAssistantMessages.voiceCommandCallFound2',
      defaultMessage: "I found %s. Who do you want to call?'",
    },
    voiceCommandCallFound3: {
      id: 'VocalAssistantMessages.voiceCommandCallFound3',
      defaultMessage:
        'Eh, there are so many contacts: %s. Who do I have to call?',
    },
    voiceCommandCallFound4: {
      id: 'VocalAssistantMessages.voiceCommandCallFound4',
      defaultMessage: 'Specify the one to call between %s',
    },
    voiceCommandCallGetType0: {
      id: 'VocalAssistantMessages.voiceCommandCallGetType0',
      defaultMessage:
        'I have found these number types for that contact: %s. Specify which one I have to call',
    },
    voiceCommandCallGetType1: {
      id: 'VocalAssistantMessages.voiceCommandCallGetType1',
      defaultMessage:
        'There are these number types for the contact: %s. Which one do I call?',
    },
    voiceCommandCallGetType2: {
      id: 'VocalAssistantMessages.voiceCommandCallGetType2',
      defaultMessage:
        'I found many numbers: %s. Which one do you want to call?',
    },
    voiceCommandCallGetType3: {
      id: 'VocalAssistantMessages.voiceCommandCallGetType3',
      defaultMessage:
        'I need you specify the number type too between types I found: %s',
    },
    voiceCommandCallGetType4: {
      id: 'VocalAssistantMessages.voiceCommandCallGetType4',
      defaultMessage:
        'The contact has these numbers: %s. Which one do you want to call?',
    },
    voiceCommandChatFound0: {
      id: 'VocalAssistantMessages.voiceCommandChatFound0',
      defaultMessage: 'I found these chats: %s. Specify the right one',
    },
    voiceCommandChatFound1: {
      id: 'VocalAssistantMessages.voiceCommandChatFound1',
      defaultMessage:
        'Before I send messages to wrong people, specify better the chat among these: %s',
    },
    voiceCommandChatFound2: {
      id: 'VocalAssistantMessages.voiceCommandChatFound2',
      defaultMessage:
        'Io have found these chats: %s. Who do you want to write to?',
    },
    voiceCommandChatFound3: {
      id: 'VocalAssistantMessages.voiceCommandChatFound3',
      defaultMessage: 'That name is too vague, I found %s. Who do you want?',
    },
    voiceCommandChatFound4: {
      id: 'VocalAssistantMessages.voiceCommandChatFound4',
      defaultMessage:
        'There are a lot of chats with that name: %s. Who do you mean?',
    },
    voiceCommandManyChatFound0: {
      id: 'VocalAssistantMessages.voiceCommandManyChatFound0',
      defaultMessage:
        'I found many chats with name %s, specify more clearly the receiver.',
    },
    voiceCommandManyChatFound1: {
      id: 'VocalAssistantMessages.voiceCommandManyChatFound1',
      defaultMessage:
        'There are so many chats with this name, clarify better the one you want',
    },
    voiceCommandManyChatFound2: {
      id: 'VocalAssistantMessages.voiceCommandManyChatFound2',
      defaultMessage: '%s is a too vague name, tell me exactly the chat name',
    },
    voiceCommandManyChatFound3: {
      id: 'VocalAssistantMessages.voiceCommandManyChatFound3',
      defaultMessage:
        'Tell me clearly who you want to write to because with the name %s there are so many chats',
    },
    voiceCommandManyChatFound4: {
      id: 'VocalAssistantMessages.voiceCommandManyChatFound4',
      defaultMessage:
        'Who did you exactly mean? Because I found many chats with name %s',
    },
    voiceCommandMissingCallType0: {
      id: 'VocalAssistantMessages.voiceCommandMissingCallType0',
      defaultMessage:
        'Tell me even if you mean a lost, received or outgoing call',
    },
    voiceCommandMissingCallType1: {
      id: 'VocalAssistantMessages.voiceCommandMissingCallType1',
      defaultMessage:
        'I need more details, tell me if you are interested in a lost, received or outgoing call',
    },
    voiceCommandMissingCallType2: {
      id: 'VocalAssistantMessages.voiceCommandMissingCallType2',
      defaultMessage:
        'Too much generic, tell me even if searching in lost, received or outgoing call',
    },
    voiceCommandMissingCallType3: {
      id: 'VocalAssistantMessages.voiceCommandMissingCallType3',
      defaultMessage:
        'Did you mean lost, received or outgoing call? Specify the type',
    },
    voiceCommandMissingCallType4: {
      id: 'VocalAssistantMessages.voiceCommandMissingCallType4',
      defaultMessage:
        'Sorry but I need you tell me if you want lost, received or outgoing call',
    },
    voiceCommandGeneralInCallType: {
      id: 'VocalAssistantMessages.voiceCommandGeneralInCallType',
      defaultMessage: 'received',
    },
    voiceCommandGeneralLostCallType: {
      id: 'VocalAssistantMessages.voiceCommandGeneralLostCallType',
      defaultMessage: 'lost',
    },
    voiceCommandGeneralOutCallType: {
      id: 'VocalAssistantMessages.voiceCommandGeneralOutCallType',
      defaultMessage: 'outgoing',
    },
    voiceCommandFirstSilence0: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence0',
      defaultMessage: 'Do you still need me?',
    },
    voiceCommandFirstSilence1: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence1',
      defaultMessage: 'Do you need something?',
    },
    voiceCommandFirstSilence2: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence2',
      defaultMessage: 'Can I help you?',
    },
    voiceCommandFirstSilence3: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence3',
      defaultMessage: 'Do you want me to stay here?',
    },
    voiceCommandFirstSilence4: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence4',
      defaultMessage: 'Do I stay here with you?',
    },
    voiceCommandFirstSilence5: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence5',
      defaultMessage: 'Can I help you anymore?',
    },
    voiceCommandFirstSilence6: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence6',
      defaultMessage: 'Can I do something for you?',
    },
    voiceCommandFirstSilence7: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence7',
      defaultMessage: 'Do you need a help for something?',
    },
    voiceCommandFirstSilence8: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence8',
      defaultMessage: "Don't I disturb you anymore?",
    },
    voiceCommandFirstSilence9: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence9',
      defaultMessage: 'Do I stay here for a help?',
    },
    voiceCommandFirstSilence10: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence10',
      defaultMessage: 'Do you still want Ambrosia with you?',
    },
    voiceCommandFirstSilence11: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence11',
      defaultMessage: 'Do you still want me here?',
    },
    voiceCommandFirstSilence12: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence12',
      defaultMessage: 'What do I do, do I stay here?',
    },
    voiceCommandFirstSilence13: {
      id: 'VocalAssistantMessages.voiceCommandFirstSilence13',
      defaultMessage: "I won't bother you anymore, do you need me?",
    },
    voiceCommandSecondSilence0: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence0',
      defaultMessage: "You are so silent, don't you need me anymore?",
    },
    voiceCommandSecondSilence1: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence1',
      defaultMessage: 'I think you are busy, do you still need me?',
    },
    voiceCommandSecondSilence2: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence2',
      defaultMessage: 'I had no answers, do you need me?',
    },
    voiceCommandSecondSilence3: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence3',
      defaultMessage: 'Are you there? Have I to stay here anymore?',
    },
    voiceCommandSecondSilence4: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence4',
      defaultMessage:
        "If you don't need me anymore I leave you alone; Have I to stay here?",
    },
    voiceCommandSecondSilence5: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence5',
      defaultMessage:
        'If you need a help, Ambrosia is always ready for you. Do you need anything?',
    },
    voiceCommandSecondSilence6: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence6',
      defaultMessage: 'Listen what a silence... do you need me?',
    },
    voiceCommandSecondSilence7: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence7',
      defaultMessage: "Don't you tell me anything? Do you want something?",
    },
    voiceCommandSecondSilence8: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence8',
      defaultMessage: 'Can I be useful? If not, I have a lot of work to do',
    },
    voiceCommandSecondSilence9: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence9',
      defaultMessage: 'Silence is golden... Can I do something?',
    },
    voiceCommandSecondSilence10: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence10',
      defaultMessage:
        "If you are busy just tell me that, don't I disturb you anymore?",
    },
    voiceCommandSecondSilence11: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence11',
      defaultMessage: 'Are you still there? Do I stay here to help you?',
    },
    voiceCommandSecondSilence12: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence12',
      defaultMessage:
        "If you don't need me I go to do my own work, do I stay here?",
    },
    voiceCommandSecondSilence13: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence13',
      defaultMessage:
        'I ask you another time, then I go away. Do you need something?',
    },
    voiceCommandSecondSilence14: {
      id: 'VocalAssistantMessages.voiceCommandSecondSilence14',
      defaultMessage:
        "I don't want to be nosy, maybe is it better if I don't disturb you anymore?",
    },
    voiceCommandNoCallFound0: {
      id: 'VocalAssistantMessages.voiceCommandNoCallFound0',
      defaultMessage: 'No calls found',
    },
    voiceCommandNoCallFound1: {
      id: 'VocalAssistantMessages.voiceCommandNoCallFound1',
      defaultMessage: "Sorry but there aren't calls",
    },
    voiceCommandNoCallFound2: {
      id: 'VocalAssistantMessages.voiceCommandNoCallFound2',
      defaultMessage: 'To be sincere, there are no calls...',
    },
    voiceCommandNoCallFound3: {
      id: 'VocalAssistantMessages.voiceCommandNoCallFound3',
      defaultMessage: 'You have no calls',
    },
    voiceCommandNoCallFound4: {
      id: 'VocalAssistantMessages.voiceCommandNoCallFound4',
      defaultMessage: 'The phone is crying, no calls',
    },
    voiceCommandGotoSectionNotFound0: {
      id: 'VocalAssistantMessages.voiceCommandGotoSectionNotFound0',
      defaultMessage: 'Section not found',
    },
    voiceCommandGotoSectionNotFound1: {
      id: 'VocalAssistantMessages.voiceCommandGotoSectionNotFound1',
      defaultMessage: "I didn't understand which section you want to open",
    },
    voiceCommandGotoSectionNotFound2: {
      id: 'VocalAssistantMessages.voiceCommandGotoSectionNotFound2',
      defaultMessage: 'At the moment I cannot found that section',
    },
    voiceCommandGotoSectionNotFound3: {
      id: 'VocalAssistantMessages.voiceCommandGotoSectionNotFound3',
      defaultMessage:
        "I think I didn't understand because I don't find that section",
    },
    voiceCommandGotoSectionNotFound4: {
      id: 'VocalAssistantMessages.voiceCommandGotoSectionNotFound4',
      defaultMessage: 'Sorry but that section does not exist',
    },
    voiceCommandGotoSubSectionNotFound0: {
      id: 'VocalAssistantMessages.voiceCommandGotoSubSectionNotFound0',
      defaultMessage: 'Subsection not found',
    },
    voiceCommandGotoSubSectionNotFound1: {
      id: 'VocalAssistantMessages.voiceCommandGotoSubSectionNotFound1',
      defaultMessage:
        'I have found the requested section but I cannot say the same for the subsection',
    },
    voiceCommandGotoSubSectionNotFound2: {
      id: 'VocalAssistantMessages.voiceCommandGotoSubSectionNotFound2',
      defaultMessage: 'The subsection you told me does not exist',
    },
    voiceCommandGotoSubSectionNotFound3: {
      id: 'VocalAssistantMessages.voiceCommandGotoSubSectionNotFound3',
      defaultMessage: 'I think you are wrong, that subsection does not exist',
    },
    voiceCommandGotoSubSectionNotFound4: {
      id: 'VocalAssistantMessages.voiceCommandGotoSubSectionNotFound4',
      defaultMessage: "Maybe you are confused, I didn't find that subsection",
    },
    voiceCommandCallNotFoundType0: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFoundType0',
      defaultMessage: 'These number type is not present. Tell me another one',
    },
    voiceCommandCallNotFoundType1: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFoundType1',
      defaultMessage:
        "I haven't found the number you told me. Tell me another number type",
    },
    voiceCommandCallNotFoundType2: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFoundType2',
      defaultMessage: 'There are no numbers for that type, specify another one',
    },
    voiceCommandCallNotFoundType3: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFoundType3',
      defaultMessage:
        'You said a wrong type because that one is not present, say another one',
    },
    voiceCommandCallNotFoundType4: {
      id: 'VocalAssistantMessages.voiceCommandCallNotFoundType4',
      defaultMessage:
        'Specify another number type because that one is not present',
    },
    voiceCommandGotoMissingSection0: {
      id: 'VocalAssistantMessages.voiceCommandGotoMissingSection0',
      defaultMessage: 'Tell me a section',
    },
    voiceCommandGotoMissingSection1: {
      id: 'VocalAssistantMessages.voiceCommandGotoMissingSection1',
      defaultMessage:
        "Come on, don't waste our time, which section you want to go in?",
    },
    voiceCommandGotoMissingSection2: {
      id: 'VocalAssistantMessages.voiceCommandGotoMissingSection2',
      defaultMessage: 'Tell me the section you want to open',
    },
    voiceCommandGotoMissingSection3: {
      id: 'VocalAssistantMessages.voiceCommandGotoMissingSection3',
      defaultMessage: 'Where do I have to go?',
    },
    voiceCommandGotoMissingSection4: {
      id: 'VocalAssistantMessages.voiceCommandGotoMissingSection4',
      defaultMessage:
        'You left off our discussion in the middle, tell me the section too',
    },
    sectionCalls: {
      id: 'VocalAssistantMessages.sectionCalls',
      defaultMessage: 'Calls',
    },
    sectionVideocalls: {
      id: 'VocalAssistantMessages.sectionVideocalls',
      defaultMessage: 'Videocalls',
    },
    subSectionVideocallsExec: {
      id: 'VocalAssistantMessages.subSectionVideocallsExec',
      defaultMessage: 'Exec',
    },
    subSectionVideocallsArchive: {
      id: 'VocalAssistantMessages.subSectionVideocallsArchive',
      defaultMessage: 'Archive',
    },
    subSectionVideocallsSettings: {
      id: 'VocalAssistantMessages.subSectionVideocallsSettings',
      defaultMessage: 'Settings',
    },
    sectionMessages: {
      id: 'VocalAssistantMessages.sectionMessages',
      defaultMessage: 'Messages',
    },
    sectionCalendar: {
      id: 'VocalAssistantMessages.sectionCalendar',
      defaultMessage: 'Calendar',
    },
    subSectionCalendarEvents: {
      id: 'VocalAssistantMessages.subSectionCalendarEvents',
      defaultMessage: 'Events',
    },
    subSectionCalendarManage: {
      id: 'VocalAssistantMessages.subSectionCalendarManage',
      defaultMessage: 'Manage',
    },
    subSectionCalendarSettings: {
      id: 'VocalAssistantMessages.subSectionCalendarSettings',
      defaultMessage: 'Settings',
    },
    subSectionCalendarNotifications: {
      id: 'VocalAssistantMessages.subSectionCalendarNotifications',
      defaultMessage: 'Notifications',
    },
    sectionAbook: {
      id: 'VocalAssistantMessages.sectionAbook',
      defaultMessage: 'Address book',
    },
    subSectionAbookContacts: {
      id: 'VocalAssistantMessages.subSectionAbookContacts',
      defaultMessage: 'Contacts',
    },
    subSectionAbookManage: {
      id: 'VocalAssistantMessages.subSectionAbookManage',
      defaultMessage: 'Manage',
    },
    subSectionAbookNotifications: {
      id: 'VocalAssistantMessages.subSectionAbookNotifications',
      defaultMessage: 'Notifications',
    },
    sectionPhone: {
      id: 'VocalAssistantMessages.sectionPhone',
      defaultMessage: 'Phone',
    },
    subSectionPhoneCallsLog: {
      id: 'VocalAssistantMessages.subSectionPhoneCallsLog',
      defaultMessage: 'Calls Log',
    },
    subSubSectionPhoneCallsLogLost: {
      id: 'VocalAssistantMessages.subSubSectionPhoneCallsLogLost',
      defaultMessage: 'Lost',
    },
    subSubSectionPhoneCallsLogReceived: {
      id: 'VocalAssistantMessages.subSubSectionPhoneCallsLogReceived',
      defaultMessage: 'Received',
    },
    subSubSectionPhoneCallsLogExecuted: {
      id: 'VocalAssistantMessages.subSubSectionPhoneCallsLogExecuted',
      defaultMessage: 'Executed',
    },
    subSectionPhoneSettings: {
      id: 'VocalAssistantMessages.subSectionPhoneSettings',
      defaultMessage: 'Phone settings',
    },
    subSubSectionPhoneSettingsRules: {
      id: 'VocalAssistantMessages.subSubSectionPhoneSettingsRules',
      defaultMessage: 'Rules',
    },
    subSubSectionPhoneSettingsGreeting: {
      id: 'VocalAssistantMessages.subSubSectionPhoneSettingsGreeting',
      defaultMessage: 'Greeting message',
    },
    subSectionPhoneVocalbox: {
      id: 'VocalAssistantMessages.subSectionPhoneVocalbox',
      defaultMessage: 'Vocal box',
    },
    subSubSectionPhoneVocalboxArchive: {
      id: 'VocalAssistantMessages.subSubSectionPhoneVocalboxArchive',
      defaultMessage: 'Received messages',
    },
    subSubSectionPhoneVocalboxNotifications: {
      id: 'VocalAssistantMessages.subSubSectionPhoneVocalboxNotifications',
      defaultMessage: 'Notifications',
    },
    sectionMailoffice: {
      id: 'VocalAssistantMessages.sectionMailoffice',
      defaultMessage: 'Postal services',
    },
    subSectionMailofficeSend: {
      id: 'VocalAssistantMessages.subSectionMailofficeSend',
      defaultMessage: 'Send',
    },
    subSectionMailofficeArchive: {
      id: 'VocalAssistantMessages.subSectionMailofficeArchive',
      defaultMessage: 'Archive',
    },
    subSectionMailofficeNotifications: {
      id: 'VocalAssistantMessages.subSectionMailofficeNotifications',
      defaultMessage: 'Notifications',
    },
    sectionHotel: {
      id: 'VocalAssistantMessages.sectionHotel',
      defaultMessage: 'Hotel',
    },
    subSectionHotelWakeups: {
      id: 'VocalAssistantMessages.subSectionHotelWakeups',
      defaultMessage: 'Wakeup service',
    },
    subSubSectionHotelWakeupsScheduling: {
      id: 'VocalAssistantMessages.subSubSectionHotelWakeupsScheduling',
      defaultMessage: 'Alarms scheduling',
    },
    subSubSectionHotelWakeupsArchive: {
      id: 'VocalAssistantMessages.subSubSectionHotelWakeupsArchive',
      defaultMessage: 'Alarms archive',
    },
    subSubSectionHotelWakeupsSettings: {
      id: 'VocalAssistantMessages.subSubSectionHotelWakeupsSettings',
      defaultMessage: 'Settings',
    },
    subSectionHotelCallsLog: {
      id: 'VocalAssistantMessages.subSectionHotelCallsLog',
      defaultMessage: 'Calls list',
    },
    subSectionHotelCosts: {
      id: 'VocalAssistantMessages.subSectionHotelCosts',
      defaultMessage: 'Costs settings',
    },
    sectionFax: {
      id: 'VocalAssistantMessages.sectionFax',
      defaultMessage: 'Fax',
    },
    subSectionFaxSend: {
      id: 'VocalAssistantMessages.subSectionFaxSend',
      defaultMessage: 'Send',
    },
    subSectionFaxReceived: {
      id: 'VocalAssistantMessages.subSectionFaxReceived',
      defaultMessage: 'Received',
    },
    subSectionFaxSent: {
      id: 'VocalAssistantMessages.subSectionFaxSent',
      defaultMessage: 'Sent',
    },
    subSectionFaxSettings: {
      id: 'VocalAssistantMessages.subSectionFaxSettings',
      defaultMessage: 'Settings',
    },
    subSectionFaxNotifications: {
      id: 'VocalAssistantMessages.subSectionFaxNotifications',
      defaultMessage: 'Notifications',
    },
    sectionWiki: {
      id: 'VocalAssistantMessages.sectionWiki',
      defaultMessage: 'Wiki',
    },
    sectionDocuments: {
      id: 'VocalAssistantMessages.sectionDocuments',
      defaultMessage: 'Documents',
    },
    subSectionDocumentsData: {
      id: 'VocalAssistantMessages.subSectionDocumentsData',
      defaultMessage: 'Contractual data',
    },
    subSectionDocumentsOffices: {
      id: 'VocalAssistantMessages.subSectionDocumentsOffices',
      defaultMessage: 'Offices',
    },
    subSectionDocumentsInvoices: {
      id: 'VocalAssistantMessages.subSectionDocumentsInvoices',
      defaultMessage: 'Invoices',
    },
    subSectionDocumentsPhoneLines: {
      id: 'VocalAssistantMessages.subSectionDocumentsPhoneLines',
      defaultMessage: 'Phone lines',
    },
    sectionSwitchboard: {
      id: 'VocalAssistantMessages.sectionSwitchboard',
      defaultMessage: 'Switchboard',
    },
    subSectionSwitchboardCallsLog: {
      id: 'VocalAssistantMessages.subSectionSwitchboardCallsLog',
      defaultMessage: 'Calls log',
    },
    subSectionSwitchboardSettings: {
      id: 'VocalAssistantMessages.subSectionSwitchboardSettings',
      defaultMessage: 'Settings',
    },
    subSubSectionSwitchboardSettingsRules: {
      id: 'VocalAssistantMessages.subSubSectionSwitchboardSettingsRules',
      defaultMessage: 'Rules',
    },
    subSectionSwitchboardVocalbox: {
      id: 'VocalAssistantMessages.subSectionSwitchboardVocalbox',
      defaultMessage: 'Vocal box',
    },
    subSubSectionSwitchboardVocalboxActives: {
      id: 'VocalAssistantMessages.subSubSectionSwitchboardVocalboxActives',
      defaultMessage: 'Active',
    },
    subSubSectionSwitchboardVocalboxList: {
      id: 'VocalAssistantMessages.subSubSectionSwitchboardVocalboxList',
      defaultMessage: 'List',
    },
    subSubSectionSwitchboardVocalboxArchived: {
      id: 'VocalAssistantMessages.subSubSectionSwitchboardVocalboxArchived',
      defaultMessage: 'Archived',
    },
    subSubSectionSwitchboardVocalboxNotifications: {
      id: 'VocalAssistantMessages.subSubSectionSwitchboardVocalboxNotifications',
      defaultMessage: 'Notifications',
    },
    sectionAdmin: {
      id: 'VocalAssistantMessages.sectionAdmin',
      defaultMessage: 'Admin',
    },
    subSectionAdminGroupsAndUsers: {
      id: 'VocalAssistantMessages.subSectionAdminGroupsAndUsers',
      defaultMessage: 'Groups and users',
    },
    subSubSectionAdminGroupsAndUsersGroups: {
      id: 'VocalAssistantMessages.subSubSectionAdminGroupsAndUsersGroups',
      defaultMessage: 'Groups',
    },
    subSubSectionAdminGroupsAndUsersUsers: {
      id: 'VocalAssistantMessages.subSubSectionAdminGroupsAndUsersUsers',
      defaultMessage: 'Users',
    },
    subSubSectionAdminGroupsAndUsersNotifications: {
      id: 'VocalAssistantMessages.subSubSectionAdminGroupsAndUsersNotifications',
      defaultMessage: 'Notifications',
    },
    subSectionAdminGrants: {
      id: 'VocalAssistantMessages.subSectionAdminGrants',
      defaultMessage: 'Grants',
    },
    subSectionAdminLogo: {
      id: 'VocalAssistantMessages.subSectionAdminLogo',
      defaultMessage: 'Logo',
    },
    subSectionAdminCredits: {
      id: 'VocalAssistantMessages.subSectionAdminCredits',
      defaultMessage: 'Credits',
    },
    subSubSectionAdminCreditsRemaining: {
      id: 'VocalAssistantMessages.subSubSectionAdminCreditsRemaining',
      defaultMessage: 'Remaining credit',
    },
    subSubSectionAdminCreditsTransactions: {
      id: 'VocalAssistantMessages.subSubSectionAdminCreditsTransactions',
      defaultMessage: 'Transactions',
    },
    subSubSectionAdminCreditsRecharge: {
      id: 'VocalAssistantMessages.subSubSectionAdminCreditsRecharge',
      defaultMessage: 'Recharge credit',
    },
  });

  voiceCommandNoCallFound;

  static voiceCommandsDaysOfWeek = {
    sunday: this.messages.voiceCommandsDaysOfWeek0,
    monday: this.messages.voiceCommandsDaysOfWeek1,
    tuesday: this.messages.voiceCommandsDaysOfWeek2,
    wednesday: this.messages.voiceCommandsDaysOfWeek3,
    thursday: this.messages.voiceCommandsDaysOfWeek4,
    friday: this.messages.voiceCommandsDaysOfWeek5,
    saturday: this.messages.voiceCommandsDaysOfWeek6,
  };

  static genericToday = {
    0: this.messages.genericToday0,
  };

  static genericTomorrow = {
    0: this.messages.genericTomorrow0,
  };

  static genericYesterday = {
    0: this.messages.genericYesterday0,
  };

  static months = {
    JANUARY: this.messages.months0,
    FEBRUARY: this.messages.months1,
    MARCH: this.messages.months2,
    APRIL: this.messages.months3,
    MAY: this.messages.months4,
    JUNE: this.messages.months5,
    JULY: this.messages.months6,
    AUGUST: this.messages.months7,
    SEPTEMBER: this.messages.months8,
    OCTOBER: this.messages.months9,
    NOVEMBER: this.messages.months10,
    DECEMBER: this.messages.months11,
  };

  static daysOfWeek = {
    SUNDAY: this.messages.daysOfWeek0,
    MONDAY: this.messages.daysOfWeek1,
    TUESDAY: this.messages.daysOfWeek2,
    WEDNESDAY: this.messages.daysOfWeek3,
    THURSDAY: this.messages.daysOfWeek4,
    FRIDAY: this.messages.daysOfWeek5,
    SATURDAY: this.messages.daysOfWeek6,
  };

  static daysOfWeekMin = {
    SUNDAY: this.messages.daysOfWeekMin0,
    MONDAY: this.messages.daysOfWeekMin1,
    TUESDAY: this.messages.daysOfWeekMin2,
    WEDNESDAY: this.messages.daysOfWeekMin3,
    THURSDAY: this.messages.daysOfWeekMin4,
    FRIDAY: this.messages.daysOfWeekMin5,
    SATURDAY: this.messages.daysOfWeekMin6,
  };

  static voiceCommandIntroGoodmorning = {
    0: this.messages.voiceCommandIntroGoodmorning0,
    1: this.messages.voiceCommandIntroGoodmorning1,
    2: this.messages.voiceCommandIntroGoodmorning2,
    3: this.messages.voiceCommandIntroGoodmorning3,
    4: this.messages.voiceCommandIntroGoodmorning4,
    5: this.messages.voiceCommandIntroGoodmorning5,
    6: this.messages.voiceCommandIntroGoodmorning6,
    7: this.messages.voiceCommandIntroGoodmorning7,
    8: this.messages.voiceCommandIntroGoodmorning8,
    9: this.messages.voiceCommandIntroGoodmorning9,
    10: this.messages.voiceCommandIntroGoodmorning10,
    11: this.messages.voiceCommandIntroGoodmorning11,
    12: this.messages.voiceCommandIntroGoodmorning12,
  };

  static voiceCommandIntroGoodafternoon = {
    0: this.messages.voiceCommandIntroGoodafternoon0,
    1: this.messages.voiceCommandIntroGoodafternoon1,
    2: this.messages.voiceCommandIntroGoodafternoon2,
    3: this.messages.voiceCommandIntroGoodafternoon3,
    4: this.messages.voiceCommandIntroGoodafternoon4,
    5: this.messages.voiceCommandIntroGoodafternoon5,
    6: this.messages.voiceCommandIntroGoodafternoon6,
    7: this.messages.voiceCommandIntroGoodafternoon7,
    8: this.messages.voiceCommandIntroGoodafternoon8,
    9: this.messages.voiceCommandIntroGoodafternoon9,
    10: this.messages.voiceCommandIntroGoodafternoon10,
    11: this.messages.voiceCommandIntroGoodafternoon11,
    12: this.messages.voiceCommandIntroGoodafternoon12,
  };

  static voiceCommandIntroFirstOfYear = {
    0: this.messages.voiceCommandIntroFirstOfYear0,
    1: this.messages.voiceCommandIntroFirstOfYear1,
    2: this.messages.voiceCommandIntroFirstOfYear2,
    3: this.messages.voiceCommandIntroFirstOfYear3,
    4: this.messages.voiceCommandIntroFirstOfYear4,
    5: this.messages.voiceCommandIntroFirstOfYear5,
    6: this.messages.voiceCommandIntroFirstOfYear6,
    7: this.messages.voiceCommandIntroFirstOfYear7,
    8: this.messages.voiceCommandIntroFirstOfYear8,
    9: this.messages.voiceCommandIntroFirstOfYear9,
  };

  static voiceCommandIntroGoodevening = {
    0: this.messages.voiceCommandIntroGoodevening0,
    1: this.messages.voiceCommandIntroGoodevening1,
    2: this.messages.voiceCommandIntroGoodevening2,
    3: this.messages.voiceCommandIntroGoodevening3,
    4: this.messages.voiceCommandIntroGoodevening4,
    5: this.messages.voiceCommandIntroGoodevening5,
    6: this.messages.voiceCommandIntroGoodevening6,
    7: this.messages.voiceCommandIntroGoodevening7,
    8: this.messages.voiceCommandIntroGoodevening8,
    9: this.messages.voiceCommandIntroGoodevening9,
    10: this.messages.voiceCommandIntroGoodevening10,
    11: this.messages.voiceCommandIntroGoodevening11,
    12: this.messages.voiceCommandIntroGoodevening12,
    13: this.messages.voiceCommandIntroGoodevening13,
  };

  static voiceCommandIntroChristmas = {
    0: this.messages.voiceCommandIntroChristmas0,
    1: this.messages.voiceCommandIntroChristmas1,
    2: this.messages.voiceCommandIntroChristmas2,
    3: this.messages.voiceCommandIntroChristmas3,
    4: this.messages.voiceCommandIntroChristmas4,
    5: this.messages.voiceCommandIntroChristmas5,
    6: this.messages.voiceCommandIntroChristmas6,
    7: this.messages.voiceCommandIntroChristmas7,
    8: this.messages.voiceCommandIntroChristmas8,
    9: this.messages.voiceCommandIntroChristmas9,
    10: this.messages.voiceCommandIntroChristmas10,
  };

  static voiceCommandIntroEaster = {
    0: this.messages.voiceCommandIntroEaster0,
    1: this.messages.voiceCommandIntroEaster1,
    2: this.messages.voiceCommandIntroEaster2,
    3: this.messages.voiceCommandIntroEaster3,
    4: this.messages.voiceCommandIntroEaster4,
    5: this.messages.voiceCommandIntroEaster5,
    6: this.messages.voiceCommandIntroEaster6,
    7: this.messages.voiceCommandIntroEaster7,
    8: this.messages.voiceCommandIntroEaster8,
    9: this.messages.voiceCommandIntroEaster9,
  };

  static voiceCommandIntroFestivity = {
    0: this.messages.voiceCommandIntroFestivity0,
    1: this.messages.voiceCommandIntroFestivity1,
    2: this.messages.voiceCommandIntroFestivity2,
    3: this.messages.voiceCommandIntroFestivity3,
    4: this.messages.voiceCommandIntroFestivity4,
    5: this.messages.voiceCommandIntroFestivity5,
    6: this.messages.voiceCommandIntroFestivity6,
    7: this.messages.voiceCommandIntroFestivity7,
    8: this.messages.voiceCommandIntroFestivity8,
    9: this.messages.voiceCommandIntroFestivity9,
    10: this.messages.voiceCommandIntroFestivity10,
  };

  static voiceCommandIntroLate = {
    0: this.messages.voiceCommandIntroLate0,
    1: this.messages.voiceCommandIntroLate1,
    2: this.messages.voiceCommandIntroLate2,
    3: this.messages.voiceCommandIntroLate3,
    4: this.messages.voiceCommandIntroLate4,
    5: this.messages.voiceCommandIntroLate5,
    6: this.messages.voiceCommandIntroLate6,
    7: this.messages.voiceCommandIntroLate7,
    8: this.messages.voiceCommandIntroLate8,
    9: this.messages.voiceCommandIntroLate9,
    10: this.messages.voiceCommandIntroLate10,
    11: this.messages.voiceCommandIntroLate11,
  };

  static voiceCommandIntroEarly = {
    0: this.messages.voiceCommandIntroEarly0,
    1: this.messages.voiceCommandIntroEarly1,
    2: this.messages.voiceCommandIntroEarly2,
    3: this.messages.voiceCommandIntroEarly3,
    4: this.messages.voiceCommandIntroEarly4,
    5: this.messages.voiceCommandIntroEarly5,
    6: this.messages.voiceCommandIntroEarly6,
    7: this.messages.voiceCommandIntroEarly7,
    8: this.messages.voiceCommandIntroEarly8,
    9: this.messages.voiceCommandIntroEarly9,
    10: this.messages.voiceCommandIntroEarly10,
    11: this.messages.voiceCommandIntroEarly11,
    12: this.messages.voiceCommandIntroEarly12,
  };

  static voiceCommandIntroAgain = {
    0: this.messages.voiceCommandIntroAgain0,
    1: this.messages.voiceCommandIntroAgain1,
    2: this.messages.voiceCommandIntroAgain2,
    3: this.messages.voiceCommandIntroAgain3,
    4: this.messages.voiceCommandIntroAgain4,
    5: this.messages.voiceCommandIntroAgain5,
    6: this.messages.voiceCommandIntroAgain6,
    7: this.messages.voiceCommandIntroAgain7,
    8: this.messages.voiceCommandIntroAgain8,
    9: this.messages.voiceCommandIntroAgain9,
    10: this.messages.voiceCommandIntroAgain10,
    11: this.messages.voiceCommandIntroAgain11,
    12: this.messages.voiceCommandIntroAgain12,
    13: this.messages.voiceCommandIntroAgain13,
    14: this.messages.voiceCommandIntroAgain14,
    15: this.messages.voiceCommandIntroAgain15,
    16: this.messages.voiceCommandIntroAgain16,
    17: this.messages.voiceCommandIntroAgain17,
    18: this.messages.voiceCommandIntroAgain18,
    19: this.messages.voiceCommandIntroAgain19,
    20: this.messages.voiceCommandIntroAgain20,
  };

  static voiceCommandIntroHelp = {
    0: this.messages.voiceCommandIntroHelp0,
    1: this.messages.voiceCommandIntroHelp1,
    2: this.messages.voiceCommandIntroHelp2,
    3: this.messages.voiceCommandIntroHelp3,
    4: this.messages.voiceCommandIntroHelp4,
    5: this.messages.voiceCommandIntroHelp5,
  };

  static assist = {
    0: this.messages.assist0,
  };

  static voiceCommandDeletedTimeoutOperation = {
    0: this.messages.voiceCommandDeletedTimeoutOperation,
    1: this.messages.voiceCommandDeletedTimeoutOperation,
    2: this.messages.voiceCommandDeletedTimeoutOperation,
    3: this.messages.voiceCommandDeletedTimeoutOperation,
    4: this.messages.voiceCommandDeletedTimeoutOperation,
    5: this.messages.voiceCommandDeletedTimeoutOperation,
  };

  static voiceCommandUnknownOperation = {
    0: this.messages.voiceCommandUnknownOperation0,
    1: this.messages.voiceCommandUnknownOperation1,
    2: this.messages.voiceCommandUnknownOperation2,
    3: this.messages.voiceCommandUnknownOperation3,
    4: this.messages.voiceCommandUnknownOperation4,
    5: this.messages.voiceCommandUnknownOperation5,
    6: this.messages.voiceCommandUnknownOperation6,
    7: this.messages.voiceCommandUnknownOperation7,
    8: this.messages.voiceCommandUnknownOperation8,
    9: this.messages.voiceCommandUnknownOperation9,
    10: this.messages.voiceCommandUnknownOperation10,
  };

  static voiceCommandOperationList = {
    0: this.messages.voiceCommandOperationList0,
  };

  static voiceCommandJokes = {
    0: this.messages.voiceCommandJokes0,
    1: this.messages.voiceCommandJokes1,
    2: this.messages.voiceCommandJokes2,
    3: this.messages.voiceCommandJokes3,
    4: this.messages.voiceCommandJokes4,
    5: this.messages.voiceCommandJokes5,
    6: this.messages.voiceCommandJokes6,
    7: this.messages.voiceCommandJokes7,
    8: this.messages.voiceCommandJokes8,
    9: this.messages.voiceCommandJokes9,
    10: this.messages.voiceCommandJokes10,
    11: this.messages.voiceCommandJokes11,
    12: this.messages.voiceCommandJokes12,
    13: this.messages.voiceCommandJokes13,
    14: this.messages.voiceCommandJokes14,
    15: this.messages.voiceCommandJokes15,
    16: this.messages.voiceCommandJokes16,
    17: this.messages.voiceCommandJokes17,
    18: this.messages.voiceCommandJokes18,
    19: this.messages.voiceCommandJokes19,
    20: this.messages.voiceCommandJokes20,
    21: this.messages.voiceCommandJokes21,
    22: this.messages.voiceCommandJokes22,
    23: this.messages.voiceCommandJokes23,
    24: this.messages.voiceCommandJokes24,
    25: this.messages.voiceCommandJokes25,
    26: this.messages.voiceCommandJokes26,
    27: this.messages.voiceCommandJokes27,
    28: this.messages.voiceCommandJokes28,
    29: this.messages.voiceCommandJokes29,
    30: this.messages.voiceCommandJokes30,
    31: this.messages.voiceCommandJokes31,
    32: this.messages.voiceCommandJokes32,
    33: this.messages.voiceCommandJokes33,
    34: this.messages.voiceCommandJokes34,
    35: this.messages.voiceCommandJokes35,
    36: this.messages.voiceCommandJokes36,
    37: this.messages.voiceCommandJokes37,
    38: this.messages.voiceCommandJokes38,
    39: this.messages.voiceCommandJokes39,
    40: this.messages.voiceCommandJokes40,
    41: this.messages.voiceCommandJokes41,
    42: this.messages.voiceCommandJokes42,
    43: this.messages.voiceCommandJokes43,
    44: this.messages.voiceCommandJokes44,
    45: this.messages.voiceCommandJokes45,
    46: this.messages.voiceCommandJokes46,
    47: this.messages.voiceCommandJokes47,
    48: this.messages.voiceCommandJokes48,
    49: this.messages.voiceCommandJokes49,
    50: this.messages.voiceCommandJokes50,
    51: this.messages.voiceCommandJokes51,
    52: this.messages.voiceCommandJokes52,
    53: this.messages.voiceCommandJokes53,
    54: this.messages.voiceCommandJokes54,
    55: this.messages.voiceCommandJokes55,
    56: this.messages.voiceCommandJokes56,
    57: this.messages.voiceCommandJokes57,
    58: this.messages.voiceCommandJokes58,
    59: this.messages.voiceCommandJokes59,
    60: this.messages.voiceCommandJokes60,
    61: this.messages.voiceCommandJokes61,
    62: this.messages.voiceCommandJokes62,
    63: this.messages.voiceCommandJokes63,
    64: this.messages.voiceCommandJokes64,
    65: this.messages.voiceCommandJokes65,
    66: this.messages.voiceCommandJokes66,
    67: this.messages.voiceCommandJokes67,
    68: this.messages.voiceCommandJokes68,
    69: this.messages.voiceCommandJokes69,
    70: this.messages.voiceCommandJokes07,
    71: this.messages.voiceCommandJokes71,
    72: this.messages.voiceCommandJokes72,
    73: this.messages.voiceCommandJokes73,
    74: this.messages.voiceCommandJokes74,
    75: this.messages.voiceCommandJokes75,
    76: this.messages.voiceCommandJokes76,
    77: this.messages.voiceCommandJokes77,
    78: this.messages.voiceCommandJokes78,
    79: this.messages.voiceCommandJokes79,
    80: this.messages.voiceCommandJokes80,
    81: this.messages.voiceCommandJokes81,
    82: this.messages.voiceCommandJokes82,
    83: this.messages.voiceCommandJokes83,
    84: this.messages.voiceCommandJokes84,
  };

  static voiceCommandByeBye = {
    0: this.messages.voiceCommandByeBye0,
    1: this.messages.voiceCommandByeBye1,
    2: this.messages.voiceCommandByeBye2,
    3: this.messages.voiceCommandByeBye3,
    4: this.messages.voiceCommandByeBye4,
    5: this.messages.voiceCommandByeBye5,
    6: this.messages.voiceCommandByeBye6,
    7: this.messages.voiceCommandByeBye7,
    8: this.messages.voiceCommandByeBye8,
    9: this.messages.voiceCommandByeBye9,
    10: this.messages.voiceCommandByeBye10,
    11: this.messages.voiceCommandByeBye11,
    12: this.messages.voiceCommandByeBye12,
    13: this.messages.voiceCommandByeBye13,
    14: this.messages.voiceCommandByeBye14,
    15: this.messages.voiceCommandByeBye15,
    16: this.messages.voiceCommandByeBye16,
    17: this.messages.voiceCommandByeBye17,
    18: this.messages.voiceCommandByeBye18,
  };

  static voiceCommandBadWords = {
    0: this.messages.voiceCommandBadWords0,
    1: this.messages.voiceCommandBadWords1,
    2: this.messages.voiceCommandBadWords2,
    3: this.messages.voiceCommandBadWords3,
    4: this.messages.voiceCommandBadWords4,
    5: this.messages.voiceCommandBadWords5,
    6: this.messages.voiceCommandBadWords6,
    7: this.messages.voiceCommandBadWords7,
  };

  static voiceCommandCompliments = {
    0: this.messages.voiceCommandCompliments0,
    1: this.messages.voiceCommandCompliments1,
    2: this.messages.voiceCommandCompliments2,
    3: this.messages.voiceCommandCompliments3,
    4: this.messages.voiceCommandCompliments4,
    5: this.messages.voiceCommandCompliments5,
    6: this.messages.voiceCommandCompliments6,
    7: this.messages.voiceCommandCompliments7,
    8: this.messages.voiceCommandCompliments8,
    9: this.messages.voiceCommandCompliments9,
    10: this.messages.voiceCommandCompliments10,
    11: this.messages.voiceCommandCompliments11,
    12: this.messages.voiceCommandCompliments12,
  };

  static voiceCommandWeatherMissingPlace = {
    0: this.messages.voiceCommandWeatherMissingPlace0,
    1: this.messages.voiceCommandWeatherMissingPlace1,
    2: this.messages.voiceCommandWeatherMissingPlace2,
    3: this.messages.voiceCommandWeatherMissingPlace3,
  };

  static voiceCommandWeatherMissingDate = {
    0: this.messages.voiceCommandWeatherMissingDate0,
    1: this.messages.voiceCommandWeatherMissingDate1,
    2: this.messages.voiceCommandWeatherMissingDate2,
    3: this.messages.voiceCommandWeatherMissingDate3,
    4: this.messages.voiceCommandWeatherMissingDate4,
  };

  static voiceCommandWeatherPreviousDate = {
    0: this.messages.voiceCommandWeatherPreviousDate0,
    1: this.messages.voiceCommandWeatherPreviousDate1,
    2: this.messages.voiceCommandWeatherPreviousDate2,
    3: this.messages.voiceCommandWeatherPreviousDate3,
    4: this.messages.voiceCommandWeatherPreviousDate4,
  };

  static voiceCommandWeatherInvalidDate = {
    0: this.messages.voiceCommandWeatherInvalidDate0,
    1: this.messages.voiceCommandWeatherInvalidDate1,
    2: this.messages.voiceCommandWeatherInvalidDate2,
    3: this.messages.voiceCommandWeatherInvalidDate3,
    4: this.messages.voiceCommandWeatherInvalidDate4,
  };

  static voiceCommandWeatherFarDate = {
    0: this.messages.voiceCommandWeatherFarDate0,
    1: this.messages.voiceCommandWeatherFarDate1,
    2: this.messages.voiceCommandWeatherFarDate2,
    3: this.messages.voiceCommandWeatherFarDate3,
    4: this.messages.voiceCommandWeatherFarDate4,
    5: this.messages.voiceCommandWeatherFarDate5,
  };

  static voiceCommandGenericError = {
    0: this.messages.voiceCommandGenericError0,
    1: this.messages.voiceCommandGenericError1,
    2: this.messages.voiceCommandGenericError2,
    3: this.messages.voiceCommandGenericError3,
    4: this.messages.voiceCommandGenericError4,
    5: this.messages.voiceCommandGenericError5,
  };

  static voiceCommandWeatherTemp = {
    0: this.messages.voiceCommandWeatherTemp0,
    1: this.messages.voiceCommandWeatherTemp1,
    2: this.messages.voiceCommandWeatherTemp2,
    3: this.messages.voiceCommandWeatherTemp3,
    4: this.messages.voiceCommandWeatherTemp4,
    5: this.messages.voiceCommandWeatherTemp5,
  };

  static voiceCommandWeatherHeader = {
    0: this.messages.voiceCommandWeatherHeader0,
    1: this.messages.voiceCommandWeatherHeader1,
    2: this.messages.voiceCommandWeatherHeader2,
    3: this.messages.voiceCommandWeatherHeader3,
    4: this.messages.voiceCommandWeatherHeader4,
    5: this.messages.voiceCommandWeatherHeader5,
    6: this.messages.voiceCommandWeatherHeader6,
    7: this.messages.voiceCommandWeatherHeader7,
    8: this.messages.voiceCommandWeatherHeader8,
  };

  static voiceCommandWeatherOpenweathermap = {
    0: this.messages.voiceCommandWeatherOpenweathermap0,
  };

  static voiceCommandWeatherMorning = {
    0: this.messages.voiceCommandWeatherMorning0,
    1: this.messages.voiceCommandWeatherMorning1,
    2: this.messages.voiceCommandWeatherMorning2,
    3: this.messages.voiceCommandWeatherMorning3,
    4: this.messages.voiceCommandWeatherMorning4,
    5: this.messages.voiceCommandWeatherMorning5,
    6: this.messages.voiceCommandWeatherMorning6,
  };

  static voiceCommandWeatherAfternoon = {
    0: this.messages.voiceCommandWeatherAfternoon0,
    1: this.messages.voiceCommandWeatherAfternoon1,
    2: this.messages.voiceCommandWeatherAfternoon2,
    3: this.messages.voiceCommandWeatherAfternoon3,
    4: this.messages.voiceCommandWeatherAfternoon4,
    5: this.messages.voiceCommandWeatherAfternoon5,
    6: this.messages.voiceCommandWeatherAfternoon6,
    7: this.messages.voiceCommandWeatherAfternoon7,
  };

  static voiceCommandWeatherEvening = {
    0: this.messages.voiceCommandWeatherEvening0,
    1: this.messages.voiceCommandWeatherEvening1,
    2: this.messages.voiceCommandWeatherEvening2,
    3: this.messages.voiceCommandWeatherEvening3,
    4: this.messages.voiceCommandWeatherEvening4,
    5: this.messages.voiceCommandWeatherEvening5,
    6: this.messages.voiceCommandWeatherEvening6,
    7: this.messages.voiceCommandWeatherEvening7,
    8: this.messages.voiceCommandWeatherEvening8,
  };

  static voiceCommandOperationExecuted = {
    0: this.messages.voiceCommandOperationExecuted0,
    1: this.messages.voiceCommandOperationExecuted1,
    2: this.messages.voiceCommandOperationExecuted2,
    3: this.messages.voiceCommandOperationExecuted3,
    4: this.messages.voiceCommandOperationExecuted4,
    5: this.messages.voiceCommandOperationExecuted5,
    6: this.messages.voiceCommandOperationExecuted6,
  };

  static voiceCommandCreateStickyText = {
    0: this.messages.voiceCommandCreateStickyText0,
    1: this.messages.voiceCommandCreateStickyText1,
    2: this.messages.voiceCommandCreateStickyText2,
    3: this.messages.voiceCommandCreateStickyText3,
    4: this.messages.voiceCommandCreateStickyText4,
  };

  static voiceCommandCreateStickyDelimiter = {
    0: this.messages.voiceCommandCreateStickyDelimiter0,
  };

  static voiceCommandReadStickyNoNotes = {
    0: this.messages.voiceCommandReadStickyNoNotes0,
    1: this.messages.voiceCommandReadStickyNoNotes1,
    2: this.messages.voiceCommandReadStickyNoNotes2,
    3: this.messages.voiceCommandReadStickyNoNotes3,
    4: this.messages.voiceCommandReadStickyNoNotes4,
  };

  static voiceCommandReadStickyHeader = {
    0: this.messages.voiceCommandReadStickyHeader0,
    1: this.messages.voiceCommandReadStickyHeader1,
    2: this.messages.voiceCommandReadStickyHeader2,
    3: this.messages.voiceCommandReadStickyHeader3,
    4: this.messages.voiceCommandReadStickyHeader4,
  };

  static voiceCommandReadCalendarNoEvents = {
    0: this.messages.voiceCommandReadCalendarNoEvents0,
    1: this.messages.voiceCommandReadCalendarNoEvents1,
    2: this.messages.voiceCommandReadCalendarNoEvents2,
    3: this.messages.voiceCommandReadCalendarNoEvents3,
    4: this.messages.voiceCommandReadCalendarNoEvents4,
  };

  static voiceCommandReadCalendarMessageAllday = {
    0: this.messages.voiceCommandReadCalendarMessageAllday0,
    1: this.messages.voiceCommandReadCalendarMessageAllday1,
    2: this.messages.voiceCommandReadCalendarMessageAllday2,
    3: this.messages.voiceCommandReadCalendarMessageAllday3,
    4: this.messages.voiceCommandReadCalendarMessageAllday4,
  };

  static voiceCommandReadCalendarMessage = {
    0: this.messages.voiceCommandReadCalendarMessage0,
    1: this.messages.voiceCommandReadCalendarMessage1,
    2: this.messages.voiceCommandReadCalendarMessage2,
    3: this.messages.voiceCommandReadCalendarMessage3,
    4: this.messages.voiceCommandReadCalendarMessage4,
  };

  static voiceCommandReadCalendarHeader = {
    0: this.messages.voiceCommandReadCalendarHeader0,
    1: this.messages.voiceCommandReadCalendarHeader1,
    2: this.messages.voiceCommandReadCalendarHeader2,
    3: this.messages.voiceCommandReadCalendarHeader3,
    4: this.messages.voiceCommandReadCalendarHeader4,
  };

  static voiceCommandCreateEventText = {
    0: this.messages.voiceCommandCreateEventText0,
    1: this.messages.voiceCommandCreateEventText1,
    2: this.messages.voiceCommandCreateEventText2,
    3: this.messages.voiceCommandCreateEventText3,
    4: this.messages.voiceCommandCreateEventText4,
  };

  static voiceCommandCreateEventInvalidDate = {
    0: this.messages.voiceCommandCreateEventInvalidDate0,
    1: this.messages.voiceCommandCreateEventInvalidDate1,
    2: this.messages.voiceCommandCreateEventInvalidDate2,
    3: this.messages.voiceCommandCreateEventInvalidDate3,
    4: this.messages.voiceCommandCreateEventInvalidDate4,
  };

  static voiceCommandCreateEventDay = {
    0: this.messages.voiceCommandCreateEventDay0,
    1: this.messages.voiceCommandCreateEventDay1,
    2: this.messages.voiceCommandCreateEventDay2,
    3: this.messages.voiceCommandCreateEventDay3,
    4: this.messages.voiceCommandCreateEventDay4,
  };

  static voiceCommandCreateEventInvalidTime = {
    0: this.messages.voiceCommandCreateEventInvalidTime0,
    1: this.messages.voiceCommandCreateEventInvalidTime1,
    2: this.messages.voiceCommandCreateEventInvalidTime2,
    3: this.messages.voiceCommandCreateEventInvalidTime3,
    4: this.messages.voiceCommandCreateEventInvalidTime4,
  };

  static voiceCommandCreateEventTime = {
    0: this.messages.voiceCommandCreateEventTime0,
    1: this.messages.voiceCommandCreateEventTime1,
    2: this.messages.voiceCommandCreateEventTime2,
    3: this.messages.voiceCommandCreateEventTime3,
    4: this.messages.voiceCommandCreateEventTime4,
  };

  static voiceCommandUnknownConfirmation = {
    0: this.messages.voiceCommandUnknownConfirmation0,
    1: this.messages.voiceCommandUnknownConfirmation1,
    2: this.messages.voiceCommandUnknownConfirmation2,
    3: this.messages.voiceCommandUnknownConfirmation3,
    4: this.messages.voiceCommandUnknownConfirmation4,
    5: this.messages.voiceCommandUnknownConfirmation5,
  };

  static voiceCommandDeletedOperation = {
    0: this.messages.voiceCommandDeletedOperation0,
    1: this.messages.voiceCommandDeletedOperation1,
    2: this.messages.voiceCommandDeletedOperation2,
    3: this.messages.voiceCommandDeletedOperation3,
    4: this.messages.voiceCommandDeletedOperation4,
  };

  static voiceCommandCreateEventMultipleDaysConfirmation = {
    0: this.messages.voiceCommandCreateEventMultipleDaysConfirmation0,
    1: this.messages.voiceCommandCreateEventMultipleDaysConfirmation1,
    2: this.messages.voiceCommandCreateEventMultipleDaysConfirmation2,
    3: this.messages.voiceCommandCreateEventMultipleDaysConfirmation3,
    4: this.messages.voiceCommandCreateEventMultipleDaysConfirmation4,
  };

  static voiceCommandCreateEventMultipleDaysAlldayConfirmation = {
    0: this.messages.voiceCommandCreateEventMultipleDaysAlldayConfirmation0,
    1: this.messages.voiceCommandCreateEventMultipleDaysAlldayConfirmation1,
    2: this.messages.voiceCommandCreateEventMultipleDaysAlldayConfirmation2,
    3: this.messages.voiceCommandCreateEventMultipleDaysAlldayConfirmation3,
    4: this.messages.voiceCommandCreateEventMultipleDaysAlldayConfirmation4,
  };

  static voiceCommandCreateEventSingleDayConfirmation = {
    0: this.messages.voiceCommandCreateEventSingleDayConfirmation0,
    1: this.messages.voiceCommandCreateEventSingleDayConfirmation1,
    2: this.messages.voiceCommandCreateEventSingleDayConfirmation2,
    3: this.messages.voiceCommandCreateEventSingleDayConfirmation3,
    4: this.messages.voiceCommandCreateEventSingleDayConfirmation4,
  };

  static voiceCommandCreateEventSingleDayAlldayConfirmation = {
    0: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation0,
    1: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation1,
    2: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation2,
    3: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation3,
    4: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation4,
    5: this.messages.voiceCommandCreateEventSingleDayAlldayConfirmation5,
  };

  static voiceCommandSearchMissingName = {
    0: this.messages.voiceCommandSearchMissingName0,
    1: this.messages.voiceCommandSearchMissingName1,
    2: this.messages.voiceCommandSearchMissingName2,
    3: this.messages.voiceCommandSearchMissingName3,
    4: this.messages.voiceCommandSearchMissingName4,
  };

  static voiceCommandChatTextDelimiter = {
    0: this.messages.voiceCommandChatTextDelimiter0,
  };

  static voiceCommandChatMissingReceiver = {
    0: this.messages.voiceCommandChatMissingReceiver0,
    1: this.messages.voiceCommandChatMissingReceiver1,
    2: this.messages.voiceCommandChatMissingReceiver2,
    3: this.messages.voiceCommandChatMissingReceiver3,
    4: this.messages.voiceCommandChatMissingReceiver4,
  };

  static voiceCommandChatGetText = {
    0: this.messages.voiceCommandChatGetText0,
    1: this.messages.voiceCommandChatGetText1,
    2: this.messages.voiceCommandChatGetText2,
    3: this.messages.voiceCommandChatGetText3,
    4: this.messages.voiceCommandChatGetText4,
  };

  static voiceCommandChatConfirmation = {
    0: this.messages.voiceCommandChatConfirmation0,
    1: this.messages.voiceCommandChatConfirmation1,
    2: this.messages.voiceCommandChatConfirmation2,
    3: this.messages.voiceCommandChatConfirmation3,
    4: this.messages.voiceCommandChatConfirmation4,
  };

  static voiceCommandChatNotFound = {
    0: this.messages.voiceCommandChatNotFound0,
    1: this.messages.voiceCommandChatNotFound1,
    2: this.messages.voiceCommandChatNotFound2,
    3: this.messages.voiceCommandChatNotFound3,
    4: this.messages.voiceCommandChatNotFound4,
  };

  static voiceCommandChatFound = {
    0: this.messages.voiceCommandChatFound0,
    1: this.messages.voiceCommandChatFound1,
    2: this.messages.voiceCommandChatFound2,
    3: this.messages.voiceCommandChatFound3,
    4: this.messages.voiceCommandChatFound4,
  };

  static voiceCommandNotAllowedToWriteToGroup = {
    0: this.messages.voiceCommandNotAllowedToWriteToGroup0,
    1: this.messages.voiceCommandNotAllowedToWriteToGroup1,
    2: this.messages.voiceCommandNotAllowedToWriteToGroup2,
    3: this.messages.voiceCommandNotAllowedToWriteToGroup3,
    4: this.messages.voiceCommandNotAllowedToWriteToGroup4,
  };

  static voiceCommandWrongDate = {
    0: this.messages.voiceCommandWrongDate0,
    1: this.messages.voiceCommandWrongDate1,
    2: this.messages.voiceCommandWrongDate2,
    3: this.messages.voiceCommandWrongDate3,
    4: this.messages.voiceCommandWrongDate4,
    5: this.messages.voiceCommandWrongDate5,
  };

  static voiceCommandReadChannelNoMessages = {
    0: this.messages.voiceCommandReadChannelNoMessages0,
    1: this.messages.voiceCommandReadChannelNoMessages1,
    2: this.messages.voiceCommandReadChannelNoMessages2,
    3: this.messages.voiceCommandReadChannelNoMessages3,
    4: this.messages.voiceCommandReadChannelNoMessages4,
  };

  static voiceCommandReadChannelHeader = {
    0: this.messages.voiceCommandReadChannelHeader0,
    1: this.messages.voiceCommandReadChannelHeader1,
    2: this.messages.voiceCommandReadChannelHeader2,
    3: this.messages.voiceCommandReadChannelHeader3,
    4: this.messages.voiceCommandReadChannelHeader4,
  };

  static voiceCommandReadChannelDate = {
    0: this.messages.voiceCommandReadChannelDate0,
    1: this.messages.voiceCommandReadChannelDate1,
    2: this.messages.voiceCommandReadChannelDate2,
    3: this.messages.voiceCommandReadChannelDate3,
    4: this.messages.voiceCommandReadChannelDate4,
    5: this.messages.voiceCommandReadChannelDate5,
    6: this.messages.voiceCommandReadChannelDate6,
  };

  static voiceCommandCallMissingNumber = {
    0: this.messages.voiceCommandCallMissingNumber0,
    1: this.messages.voiceCommandCallMissingNumber1,
    2: this.messages.voiceCommandCallMissingNumber2,
    3: this.messages.voiceCommandCallMissingNumber3,
    4: this.messages.voiceCommandCallMissingNumber4,
  };

  static voiceCommandCallMissingContact = {
    0: this.messages.voiceCommandCallMissingContact0,
    1: this.messages.voiceCommandCallMissingContact1,
    2: this.messages.voiceCommandCallMissingContact2,
    3: this.messages.voiceCommandCallMissingContact3,
    4: this.messages.voiceCommandCallMissingContact4,
  };

  static voiceCommandManyCallFound = {
    0: this.messages.voiceCommandManyCallFound0,
    1: this.messages.voiceCommandManyCallFound1,
    2: this.messages.voiceCommandManyCallFound2,
    3: this.messages.voiceCommandManyCallFound3,
    4: this.messages.voiceCommandManyCallFound4,
  };

  static voiceCommandCallNotFound = {
    0: this.messages.voiceCommandCallNotFound0,
    1: this.messages.voiceCommandCallNotFound1,
    2: this.messages.voiceCommandCallNotFound2,
    3: this.messages.voiceCommandCallNotFound3,
    4: this.messages.voiceCommandCallNotFound4,
  };

  static voiceCommandCallFound = {
    0: this.messages.voiceCommandCallFound0,
    1: this.messages.voiceCommandCallFound1,
    2: this.messages.voiceCommandCallFound2,
    3: this.messages.voiceCommandCallFound3,
    4: this.messages.voiceCommandCallFound4,
  };

  static voiceCommandCallGetType = {
    0: this.messages.voiceCommandCallGetType0,
    1: this.messages.voiceCommandCallGetType1,
    2: this.messages.voiceCommandCallGetType2,
    3: this.messages.voiceCommandCallGetType3,
    4: this.messages.voiceCommandCallGetType4,
  };

  static voiceCommandManyChatFound = {
    0: this.messages.voiceCommandManyChatFound0,
    1: this.messages.voiceCommandManyChatFound1,
    2: this.messages.voiceCommandManyChatFound2,
    3: this.messages.voiceCommandManyChatFound3,
    4: this.messages.voiceCommandManyChatFound4,
  };

  static voiceCommandMissingCallType = {
    0: this.messages.voiceCommandMissingCallType0,
    1: this.messages.voiceCommandMissingCallType1,
    2: this.messages.voiceCommandMissingCallType2,
    3: this.messages.voiceCommandMissingCallType3,
    4: this.messages.voiceCommandMissingCallType4,
  };

  static voiceCommandFirstSilence = {
    0: this.messages.voiceCommandFirstSilence0,
    1: this.messages.voiceCommandFirstSilence1,
    2: this.messages.voiceCommandFirstSilence2,
    3: this.messages.voiceCommandFirstSilence3,
    4: this.messages.voiceCommandFirstSilence4,
    5: this.messages.voiceCommandFirstSilence5,
    6: this.messages.voiceCommandFirstSilence6,
    7: this.messages.voiceCommandFirstSilence7,
    8: this.messages.voiceCommandFirstSilence8,
    9: this.messages.voiceCommandFirstSilence9,
    10: this.messages.voiceCommandFirstSilence10,
    11: this.messages.voiceCommandFirstSilence11,
    12: this.messages.voiceCommandFirstSilence12,
    13: this.messages.voiceCommandFirstSilence13,
  };

  static voiceCommandSecondSilence = {
    0: this.messages.voiceCommandSecondSilence0,
    1: this.messages.voiceCommandSecondSilence1,
    2: this.messages.voiceCommandSecondSilence2,
    3: this.messages.voiceCommandSecondSilence3,
    4: this.messages.voiceCommandSecondSilence4,
    5: this.messages.voiceCommandSecondSilence5,
    6: this.messages.voiceCommandSecondSilence6,
    7: this.messages.voiceCommandSecondSilence7,
    8: this.messages.voiceCommandSecondSilence8,
    9: this.messages.voiceCommandSecondSilence9,
    10: this.messages.voiceCommandSecondSilence10,
    11: this.messages.voiceCommandSecondSilence11,
    12: this.messages.voiceCommandSecondSilence12,
    13: this.messages.voiceCommandSecondSilence13,
    14: this.messages.voiceCommandSecondSilence14,
  };

  static voiceCommandNoCallFound = {
    0: this.messages.voiceCommandNoCallFound0,
    1: this.messages.voiceCommandNoCallFound1,
    2: this.messages.voiceCommandNoCallFound2,
    3: this.messages.voiceCommandNoCallFound3,
    4: this.messages.voiceCommandNoCallFound4,
  };

  static voiceCommandGotoMissingSection = {
    0: this.messages.voiceCommandGotoMissingSection0,
    1: this.messages.voiceCommandGotoMissingSection1,
    2: this.messages.voiceCommandGotoMissingSection2,
    3: this.messages.voiceCommandGotoMissingSection3,
    4: this.messages.voiceCommandGotoMissingSection4,
  };

  static voiceCommandGotoSectionNotFound = {
    0: this.messages.voiceCommandGotoSectionNotFound0,
    1: this.messages.voiceCommandGotoSectionNotFound1,
    2: this.messages.voiceCommandGotoSectionNotFound2,
    3: this.messages.voiceCommandGotoSectionNotFound3,
    4: this.messages.voiceCommandGotoSectionNotFound4,
  };

  static voiceCommandGotoSubSectionNotFound = {
    0: this.messages.voiceCommandGotoSubSectionNotFound0,
    1: this.messages.voiceCommandGotoSubSectionNotFound1,
    2: this.messages.voiceCommandGotoSubSectionNotFound2,
    3: this.messages.voiceCommandGotoSubSectionNotFound3,
    4: this.messages.voiceCommandGotoSubSectionNotFound4,
  };

  static voiceCommandCallNotFoundType = {
    0: this.messages.voiceCommandCallNotFoundType,
    1: this.messages.voiceCommandCallNotFoundType1,
    2: this.messages.voiceCommandCallNotFoundType2,
    3: this.messages.voiceCommandCallNotFoundType3,
    4: this.messages.voiceCommandCallNotFoundType4,
  };

  static gotoSections = [
    { section: this.messages.sectionCalls, path: '/calls' },
    {
      section: this.messages.sectionVideocalls,
      subSection: this.messages.subSectionVideocallsExec,
      path: '/videocall/exec',
    },
    {
      section: this.messages.sectionVideocalls,
      subSection: this.messages.subSectionVideocallsArchive,
      path: '/videocall/list',
    },
    {
      section: this.messages.sectionMessages,
      path: '/chat/messages',
    },
    {
      section: this.messages.sectionCalendar,
      subSection: this.messages.subSectionCalendarEvents,
      path: '/calendar/show',
    },
    {
      section: this.messages.sectionCalendar,
      subSection: this.messages.subSectionCalendarManage,
      path: '/calendar/manage',
    },
    {
      section: this.messages.sectionCalendar,
      subSection: this.messages.subSectionCalendarSettings,
      path: '/calendar/settings',
    },
    {
      section: this.messages.sectionCalendar,
      subSection: this.messages.subSectionCalendarNotifications,
      path: '/calendar/notifications',
    },
    {
      section: this.messages.sectionAbook,
      subSection: this.messages.subSectionAbookContacts,
      path: '/abook/contacts',
    },
    {
      section: this.messages.sectionAbook,
      subSection: this.messages.subSectionAbookManage,
      path: '/abook/manage',
    },
    {
      section: this.messages.sectionAbook,
      subSection: this.messages.subSectionAbookNotifications,
      path: '/abook/notifications',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneCallsLog,
      subSubSection: this.messages.subSubSectionPhoneCallsLogLost,
      path: '/phone/log/lost',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneCallsLog,
      subSubSection: this.messages.subSubSectionPhoneCallsLogReceived,
      path: '/phone/log/in',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneCallsLog,
      subSubSection: this.messages.subSubSectionPhoneCallsLogExecuted,
      path: '/phone/log/out',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneSettings,
      subSubSection: this.messages.subSubSectionPhoneSettingsRules,
      path: '/phone/settings',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneSettings,
      subSubSection: this.messages.subSubSectionPhoneSettingsGreeting,
      path: '/phone/greeting',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneVocalbox,
      subSubSection: this.messages.subSubSectionPhoneVocalboxArchive,
      path: '/phone/vbox/list',
    },
    {
      section: this.messages.sectionPhone,
      subSection: this.messages.subSectionPhoneVocalbox,
      subSubSection: this.messages.subSubSectionPhoneVocalboxNotifications,
      path: '/phone/vbox/notifications',
    },
    {
      section: this.messages.sectionMailoffice,
      subSection: this.messages.subSectionMailofficeSend,
      path: '/mailoffice/sent/edit',
    },
    {
      section: this.messages.sectionMailoffice,
      subSection: this.messages.subSectionMailofficeArchive,
      path: '/mailoffice/sent',
    },
    {
      section: this.messages.sectionMailoffice,
      subSection: this.messages.subSectionMailofficeNotifications,
      path: '/mailoffice/notifications',
    },
    {
      section: this.messages.sectionHotel,
      subSection: this.messages.subSectionHotelWakeups,
      subSubSection: this.messages.subSubSectionHotelWakeupsScheduling,
      path: '/hotel/wakeup/scheduling',
    },
    {
      section: this.messages.sectionHotel,
      subSection: this.messages.subSectionHotelWakeups,
      subSubSection: this.messages.subSubSectionHotelWakeupsArchive,
      path: '/hotel/wakeup/archive',
    },
    {
      section: this.messages.sectionHotel,
      subSection: this.messages.subSectionHotelWakeups,
      subSubSection: this.messages.subSubSectionHotelWakeupsSettings,
      path: '/hotel/wakeup/settings',
    },
    {
      section: this.messages.sectionHotel,
      subSection: this.messages.subSectionHotelCallsLog,
      path: '/hotel/calls',
    },
    {
      section: this.messages.sectionHotel,
      subSection: this.messages.subSectionHotelCosts,
      path: '/hotel/costs',
    },
    {
      section: this.messages.sectionFax,
      subSection: this.messages.subSectionFaxSend,
      path: '/fax/send',
    },
    {
      section: this.messages.sectionFax,
      subSection: this.messages.subSectionFaxReceived,
      path: '/fax/received',
    },
    {
      section: this.messages.sectionFax,
      subSection: this.messages.subSectionFaxSent,
      path: '/fax/sent',
    },
    {
      section: this.messages.sectionFax,
      subSection: this.messages.subSectionFaxSettings,
      path: '/fax/settings',
    },
    {
      section: this.messages.sectionFax,
      subSection: this.messages.subSectionFaxNotifications,
      path: '/fax/notifications',
    },
    {
      section: this.messages.sectionWiki,
      path: '/wiki',
    },
    {
      section: this.messages.sectionDocuments,
      subSection: this.messages.subSectionDocumentsData,
      path: '/documents/contract-data',
    },
    {
      section: this.messages.sectionDocuments,
      subSection: this.messages.subSectionDocumentsOffices,
      path: '/documents/offices',
    },
    {
      section: this.messages.sectionDocuments,
      subSection: this.messages.subSectionDocumentsInvoices,
      path: '/documents/invoices',
    },
    {
      section: this.messages.sectionDocuments,
      subSection: this.messages.subSectionDocumentsPhoneLines,
      path: '/documents/phone-lines',
    },
    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardCallsLog,
      path: '/pbx/calls/switchboard',
    },

    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardSettings,
      subSubSection: this.messages.subSubSectionSwitchboardSettingsRules,
      path: '/pbx/settings/switchboard',
    },
    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardVocalbox,
      subSubSection: this.messages.subSubSectionSwitchboardVocalboxActives,
      path: '/pbx/actives',
    },
    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardVocalbox,
      subSubSection: this.messages.subSubSectionSwitchboardVocalboxArchived,
      path: '/pbx/archived/list',
    },
    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardVocalbox,
      subSubSection: this.messages.subSubSectionSwitchboardVocalboxList,
      path: '/pbx/vbox/list',
    },
    {
      section: this.messages.sectionSwitchboard,
      subSection: this.messages.subSectionSwitchboardVocalbox,
      subSubSection:
        this.messages.subSubSectionSwitchboardVocalboxNotifications,
      path: '/pbx/vbox/notifications',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminGroupsAndUsers,
      subSubSection: this.messages.subSubSectionAdminGroupsAndUsersGroups,
      path: '/administration/groups',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminGroupsAndUsers,
      subSubSection: this.messages.subSubSectionAdminGroupsAndUsersUsers,
      path: '/administration/users',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminGroupsAndUsers,
      subSubSection:
        this.messages.subSubSectionAdminGroupsAndUsersNotifications,
      path: '/administration/notifications',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminGrants,
      path: '/administration/grants',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminLogo,
      path: '/administration/logo',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminCredits,
      subSubSection: this.messages.subSubSectionAdminCreditsRemaining,
      path: '/administration/credit/remaining',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminCredits,
      subSubSection: this.messages.subSubSectionAdminCreditsTransactions,
      path: '/administration/credit/transactions',
    },
    {
      section: this.messages.sectionAdmin,
      subSection: this.messages.subSectionAdminCredits,
      subSubSection: this.messages.subSubSectionAdminCreditsRecharge,
      path: '/administration/credit/recharge',
    },
  ];

  static numberTypes = {
    EXTENSION: this.messages.extensionLabel,
    MOBILE: this.messages.mobileLabel,
    OFFICE: this.messages.officeLabel,
    HOME: this.messages.homeLabel,
    OTHER: this.messages.otherLabel,
  };
}
