import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';
import OtherPhoneButton from './buttons/OtherPhoneButton';
import { fetchYoutubeInfoRequest } from '../js/ext/actions';
import YoutubeLink from './YoutubeLink';
import EmojiImg from './EmojiImg';
import { emojis } from '../js/chat/emojis';
import Utils from '../js/lib/utils';
import VideocallLink from './VideocallLink';

class ParsedText extends Component {
  CANDIDATE_PATTERNS = [
    {
      type: 'url',
      pattern: Utils.URL_REGEX, // url pattern
      retrieveElement: (match) => this.parseUrl(match),
    },
    {
      type: 'url',
      pattern: Utils.PSEUDO_URL_REGEX, // pseudo url pattern
      retrieveElement: (match) => this.parseUrl(match),
    },
    {
      type: 'email',
      pattern: Utils.EMAIL_REGEX, // email address
      retrieveElement: (match) =>
        React.createElement(
          'a',
          {
            href: `mailto:${match[0]}`,
            target: '_blank',
            key: uuidv1(),
            className: this.props.className,
            style: this.props.style,
          },
          match[0]
        ),
    },
    {
      type: 'number',
      pattern: /(^|[\s\n\r])(([038+][h\d]{6,}))/im, // phone number
      retrieveElement: (match) =>
        React.createElement(React.Fragment, { key: uuidv1() }, [
          React.createElement(
            'span',
            { className: this.props.className, style: this.props.style },
            `${match[0]} `
          ),
          React.createElement(OtherPhoneButton, {
            elementId: `phone_sticky_${match[2].replace('+', '')}`,
            numbers: [{ number: match[2] }],
          }),
        ]),
    },
    {
      type: 'emoji',
      pattern:
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/im, // emoji
      patternFunc: (text) => {
        const match = text.match(
          /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/im
        );
        if (!match) {
          return null;
        }
        let emojiCandidate = null;
        let addedChars = 10;
        const emojiKeys = Object.keys(emojis);
        while (addedChars > 0) {
          const largeEmoji = text.substring(
            match.index,
            match.index + addedChars
          );
          let found = false;
          for (let i = 0; i < emojiKeys.length; i += 1) {
            for (let j = 0; j < emojis[emojiKeys[i]].length; j += 1) {
              if (emojis[emojiKeys[i]][j] === largeEmoji) {
                emojiCandidate = largeEmoji;
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
          if (found) {
            break;
          }
          addedChars -= 1;
        }
        if (!emojiCandidate) {
          return null;
        }
        match[0] = emojiCandidate;
        return match;
      },
      retrieveElement: (match) =>
        React.createElement(EmojiImg, {
          key: uuidv1(),
          emoji: match[0],
          big: this.props.big || this.props.isSingleEmoji,
          small: this.props.small,
        }),
    },
  ];

  shouldComponentUpdate(nextProps) {
    return (
      this.props.text !== nextProps.text ||
      this.props.className !== nextProps.className
    );
  }

  parseUrl = (match) => {
    let url;
    if (match[2].indexOf('://') < 0) {
      url = `http://${match[2]}`;
    } else {
      url = match[2];
    }
    const domain = url.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i)
      ? url.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i)[1]
      : null;
    if (
      domain &&
      (domain.indexOf('youtube.com') > 0 || domain.indexOf('youtu.be') > 0)
    ) {
      const youtubeCode = match[2].match('v=([a-zA-Z0-9_-]+)&?')
        ? match[2].match('v=([a-zA-Z0-9_-]+)&?')[1]
        : null;
      if (youtubeCode) {
        this.props.fetchYoutubeInfo(youtubeCode);
        return React.createElement(YoutubeLink, {
          key: uuidv1(),
          code: youtubeCode,
          className: this.props.className,
          style: this.props.style,
          url,
          text: match[0],
        });
      }
      return React.createElement(
        'a',
        {
          target: '_blank',
          href: url,
          key: uuidv1(),
          className: this.props.className,
          style: this.props.style,
        },
        match[0]
      );
    }
    if (
      domain &&
      domain.indexOf('youneed.it') >= 0 &&
      url.indexOf('filebox/ext/') > 0
    ) {
      return React.createElement(
        'a',
        {
          target: '_blank',
          href: url,
          key: uuidv1(),
          className: this.props.className,
          style: this.props.style,
          download: '',
        },
        match[0]
      );
    }
    if (
      domain &&
      domain.indexOf('youneed.it') >= 0 &&
      url.indexOf('videocall/exec/') > 0
    ) {
      return React.createElement(VideocallLink, {
        target: '_self',
        url,
        key: uuidv1(),
        className: this.props.className,
        style: this.props.style,
      });
    }
    return React.createElement(
      'a',
      {
        target: '_blank',
        href: url,
        key: uuidv1(),
        className: this.props.className,
        style: this.props.style,
      },
      match[0]
    );
  };

  render() {
    const { text } = this.props;
    const output = [];
    const parseText = (text, index) => {
      if (!this.CANDIDATE_PATTERNS[index]) {
        output.push(
          React.createElement(
            'span',
            {
              className: this.props.className,
              style: this.props.style,
              key: uuidv1(),
            },
            text
          )
        );
        return;
      }
      if (!this.props[this.CANDIDATE_PATTERNS[index].type]) {
        parseText(text, index + 1);
        return;
      }
      while (text && text.length > 0) {
        const match = this.CANDIDATE_PATTERNS[index].patternFunc
          ? this.CANDIDATE_PATTERNS[index].patternFunc(text)
          : text.match(this.CANDIDATE_PATTERNS[index].pattern);
        if (match == null) {
          parseText(text, index + 1);
          break;
        } else if (match.index > 0) {
          parseText(text.substr(0, match.index), index + 1);
        }
        output.push(this.CANDIDATE_PATTERNS[index].retrieveElement(match));
        text = text.substr(match.index + match[0].length);
        if (this.CANDIDATE_PATTERNS[index].type === 'emoji') {
          if (text.indexOf('🏻') === 0) {
            text = text.replace('🏻', '');
          }
          if (text.indexOf('🏼') === 0) {
            text = text.replace('🏼', '');
          }
          if (text.indexOf('🏽') === 0) {
            text = text.replace('🏽', '');
          }
          if (text.indexOf('🏾') === 0) {
            text = text.replace('🏾', '');
          }
          if (text.indexOf('🏿') === 0) {
            text = text.replace('🏿', '');
          }
        }
      }
      return output;
    };
    parseText(text, 0);
    return output;
  }
}

ParsedText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  url: PropTypes.bool,
  email: PropTypes.bool,
  number: PropTypes.bool,
  emoji: PropTypes.bool,
};

ParsedText.defaultProps = {
  url: true,
  email: true,
  number: true,
  emoji: true,
};

export default connect(null, {
  fetchYoutubeInfo: fetchYoutubeInfoRequest,
})(ParsedText);
