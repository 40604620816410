import * as React from 'react';
import { NavItem } from 'reactstrap';
import { DragSource } from 'react-dnd';
import { injectIntl, defineMessages } from 'react-intl';
import { getEmptyImage } from 'react-dnd-html5-backend';

import Icon from '../../../components/icons/Icon';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';

const messages = defineMessages({
  blindTransfer: {
    id: 'CallToolbar.tooltip.blindTransfer',
    defaultMessage: 'Direct transfer',
  },
});

const blindTransferSource = {
  beginDrag(props) {
    return {
      callId: props.callId,
    };
  },
};

class BlindTransferButton extends React.Component {
  componentDidMount() {
    const { connectDragPreview } = this.props;
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    connectDragPreview(getEmptyImage());
  }
  render() {
    const {
      intl: { formatMessage },
      handleBlindTransferClick,
      connectDragSource,
    } = this.props;

    return connectDragSource(
      <div>
        <NavItem>
          <span onClick={handleBlindTransferClick} className="mx-1">
            <Icon name="phone-call-forward" color="var(--yn-blue-dark)">
              {' '}
              {formatMessage(messages.blindTransfer)}{' '}
            </Icon>
          </span>
        </NavItem>
      </div>
    );
  }
}

export default DragSource(
  PhoneEnums.DndTypes.blindTransfer,
  blindTransferSource,
  (connect) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  })
)(injectIntl(BlindTransferButton));
