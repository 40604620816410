import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getInterlocutorName,
  getInterlocutorAvatar,
  getConversationById,
  getActiveConversation,
  isInterlocutorRemoved,
  isMainChat,
  getChatMessageById,
  getRepliedMessage,
  getBigConversationId,
} from '../../../js/chat/selectors';
import {
  retrieveInterlocutorIdFromConversationId,
  ChatEnums,
  MESSAGE_COMPOSING_TIMEOUT,
  CHAT_TOOLBAR_HEIGHT,
  CHAT_TEXTAREA_HEIGHTS,
  CHAT_FILE_MAXSIZE,
  CHAT_EMOJI_PANEL_HEIGHTS,
  CHAT_WINDOWS_HEIGHTS,
  CHAT_PANEL_HEIGHTS,
  CHAT_REPLY_TEXTAREA_HEIGHTS,
} from '../../../js/chat/ChatUtils';
import {
  closeChatConversation,
  minimizeChatConversation,
  sendChatMessageRequest,
  maximizeChatConversation,
  sendReadAllMessageRequest,
  sendDeleteAllMessageRequest,
  normalizeChatConversation,
  sendArchiveConversationRequest,
  sendDeleteMessagesRequest,
  sendComposingStatus,
  sendPausedStatus,
  setActiveChatWindow,
  unsetActiveChatWindow,
  sendChatFilePreview,
  removeReplyMessageRequest,
  clearChatErrors,
} from '../../../js/chat/actions';
import ChatHistory from './ChatHistory';
import { generateAckUid } from '../../../js/websocket/WsUtils';
import { getMeId, isPublicChatAlert } from '../../../js/me/selectors';
import ChatWindowHeader from './ChatWindowHeader';
import ChatWindowToolbarTop from './ChatWindowToolbarTop';
import ChatWindowToolbarBottom from './ChatWindowToolbarBottom';
import {
  updateChatGroupRequest,
  fetchGroupMembersRequest,
} from '../../../js/groups/actions';
import { showConfirmModal } from '../../../js/confirm/actions';
import { resize } from '../../../js/files/FileUtils';
import { uploadProgressFilesRequest } from '../../../js/files/actions';
import {
  isMeMember,
  getGroupMembersLoaded,
} from '../../../js/groups/selectors';
import ChatInputArea from './ChatInputArea';
import ReplyMessageArea from './ReplyMessageArea';
import { getYnPageHeight } from '../../../js/ui/selectors';
import ErrorBoundaryChat from '../../../components/ErrorBoundaryChat';
import { getOnlineUsers } from '../../../js/users/selectors';

const messages = defineMessages({
  deleteHistory: {
    id: 'ChatWindow.deleteHistory',
    defaultMessage: 'Are you sure to remove all messages?',
  },
  publicChatAlertNoUsers: {
    id: 'ChatWindow.publicChatAlertNoUsers',
    defaultMessage: 'Are you sure to send the message in public chat?',
  },
  publicChatAlert: {
    id: 'ChatWindow.publicChatAlert',
    defaultMessage:
      'Are you sure to send the message in public chat where there are {count} online users?',
  },
});

const style = {
  outer: {
    paddingRight: '0.1em',
    paddingLeft: '0.1em',
    position: 'absolute',
    bottom: 0,
  },
  inner: {
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    boxShadow: '-0.25rem -0.5rem 1rem rgba(0, 0, 0, 0.175)',
  },
  dropzone: {
    position: 'absolute',
    top: '62px',
    left: '0',
    width: '100%',
    height: '100%',
  },
  panel: {
    position: 'relative',
    padding: '0.35em',
  },
};

class ChatWindow extends React.Component {
  chatWindow = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      deletingEnabled: false,
      deletingIds: [],
      composingTimeout: null,
      timedMessage: null,
      renamingEnabled: false,
      emojiPanelOpen: false,
      dropZoneHover: false,
      filesRejected: [],
      fetchedMembers: false,
      lastEmoji: '',
    };
    this.enableRenameGroup = this.enableRenameGroup.bind(this);
    this.handleRenameGroup = this.handleRenameGroup.bind(this);
    this.retrySendFile = this.retrySendFile.bind(this);
    this.retrySendMessage = this.retrySendMessage.bind(this);
    this.handleEmoji = this.handleEmoji.bind(this);
    this.handleEmojiPanel = this.handleEmojiPanel.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOuter, false);
    if (!this.props.isRemoved) {
      if (
        !this.props.fetchedMembers &&
        retrieveInterlocutorIdFromConversationId(this.props.conversationId)
          .groupId
      ) {
        this.fetchMembers();
      }
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.isActive && !this.props.isActive) {
      this.setState({ renamingEnabled: false });
    }
    if (
      prevprops.fetchedMembers !== this.props.fetchedMembers &&
      !this.props.fetchedMembers &&
      retrieveInterlocutorIdFromConversationId(this.props.conversationId)
        .groupId
    ) {
      this.fetchMembers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOuter, false);
  }

  handleInputChange = (text) => {
    this.state.composingTimeout && clearTimeout(this.state.composingTimeout);
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      this.props.conversationId
    );
    const recipient = {
      type: interlocutor.userId ? 'USER' : 'GROUP',
      id: interlocutor.userId || interlocutor.groupId,
    };
    let composingTimeout;
    if (text) {
      composingTimeout = setTimeout(() => {
        if (!this.state.composingTimeout) {
          return;
        }
        this.setState({
          composingTimeout: null,
        });
        this.props.sendPaused(recipient);
      }, MESSAGE_COMPOSING_TIMEOUT);
    }
    if (text && !this.state.composingTimeout) {
      this.props.sendComposing(recipient);
    }
    if (!text && this.state.composingTimeout) {
      this.props.sendPaused(recipient);
    }
    this.setState({
      text,
      composingTimeout,
    });
  };

  minimizeWindow = () => {
    this.props.minimizeWindow(this.props.conversationId);
    this.props.unsetActiveChat();
  };

  normalizeWindow = () => {
    this.props.normalizeWindow(this.props.conversationId);
  };

  maximizeWindow = () => {
    this.props.maximizeWindow(this.props.conversationId);
  };

  closeWindow = () => {
    this.props.closeWindow(this.props.conversationId);
    this.props.clearErrors(this.props.conversationId);
  };

  readAllMessages = () => {
    this.props.readAllMessages(
      retrieveInterlocutorIdFromConversationId(this.props.conversationId)
    );
  };

  deleteAllMessages = () => {
    const {
      confirm,
      intl: { formatMessage },
    } = this.props;

    confirm({
      message: formatMessage(messages.deleteHistory),
      modalAction: this.props.deleteAllMessages,
      actionParams: retrieveInterlocutorIdFromConversationId(
        this.props.conversationId
      ),
    });
  };

  archiveConversation = () => {
    this.props.archiveConversation(
      retrieveInterlocutorIdFromConversationId(this.props.conversationId)
    );
  };

  onEnterClick = () => {
    if (!this.state.text.replace(/&nbsp;/g, ' ').trim()) {
      return;
    }
    let text = this.state.text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&quot;/g, "'");
    text = text.replace(/&apos;/g, '"');
    text = text.replace(/&amp;/g, '&');
    this.state.composingTimeout && clearTimeout(this.state.composingTimeout);
    this.props.removeReplyMessage({
      conversationId: this.props.conversationId,
    });
    // const params = !isEmpty(this.props.repliedMessage)
    //   ? {
    //       text,
    //       duration: this.state.timedMessage,
    //       replyTo: {
    //         senderId: this.props.repliedMessage.senderId,
    //         text: this.props.repliedMessage.text,
    //       },
    //     }
    //   : {
    //       text,
    //       duration: this.state.timedMessage,
    //     };

    const params = this.props.repliedMessageId
      ? {
          text,
          duration: this.state.timedMessage,
          replyTo: {
            senderId: this.props.repliedMessage.senderId,
            message: {
              text: this.props.repliedMessage.text
                ? this.props.repliedMessage.text
                : null,
              file: this.props.repliedMessage.file
                ? this.props.repliedMessage.file
                : null,
            },
          },
        }
      : {
          text,
          duration: this.state.timedMessage,
        };
    this.sendMessage(params);
    this.setState({
      text: '',
      composingTimeout: null,
      emojiPanelOpen: false,
    });
  };

  sendMessage = (message) => {
    const ackUid = generateAckUid(this.props.myId);
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      this.props.conversationId
    );
    this.props.sendPaused({
      type: interlocutor.userId ? 'USER' : 'GROUP',
      id: interlocutor.userId || interlocutor.groupId,
    });
    if (this.props.isMain && this.props.publicChatAlert) {
      this.props.confirm({
        message: this.props.intl.formatMessage(
          this.props.onlineUsers.length
            ? messages.publicChatAlert
            : messages.publicChatAlertNoUsers,
          {
            count: this.props.onlineUsers.length,
          }
        ),
        modalAction: this.props.sendMessage,
        actionParams: {
          ackUid,
          recipient: {
            type: interlocutor.userId ? 'USER' : 'GROUP',
            id: interlocutor.userId || interlocutor.groupId,
          },
          message,
        },
      });
    } else {
      this.props.sendMessage({
        ackUid,
        recipient: {
          type: interlocutor.userId ? 'USER' : 'GROUP',
          id: interlocutor.userId || interlocutor.groupId,
        },
        message,
      });
    }
  };

  retrySendFile = (file) => {
    this.props.uploadFiles({ ...file.data, scope: 'chat' });
  };

  retrySendMessage = (message) => {
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      message.conversationId
    );
    this.props.sendMessage({
      ackUid: message.id,
      recipient: {
        type: interlocutor.userId ? 'USER' : 'GROUP',
        id: interlocutor.userId || interlocutor.groupId,
      },
      message: { text: message.text, duration: message.duration },
    });
  };

  uploadFiles = (files) => {
    files.forEach((file) => {
      const upload = (preview) => {
        const formData = new FormData();
        formData.append('file', file);
        const ackUid = generateAckUid(this.props.myId);
        const params = {
          formData,
          scope: 'chat',
          file: {
            preview,
          },
          filename: file.name,
          id: ackUid,
          replyTo: {
            senderId: this.props.repliedMessage.senderId,
            message: {
              text: this.props.repliedMessage.text
                ? this.props.repliedMessage.text
                : null,
              file: this.props.repliedMessage.file
                ? this.props.repliedMessage.file
                : null,
            },
          },
        };
        this.props.uploadFiles(params);
        if (this.props.repliedMessageId)
          this.props.removeReplyMessage({
            conversationId: this.props.conversationId,
          });
        this.sendFilePreview(
          {
            ackUid,
            name: file.name,
            size: file.size,
            mime: file.type,
            thumbnail: preview,
          },
          this.props.repliedMessageId && {
            senderId: this.props.repliedMessage.senderId,
            message: {
              text: this.props.repliedMessage.text
                ? this.props.repliedMessage.text
                : null,
              file: this.props.repliedMessage.file
                ? this.props.repliedMessage.file
                : null,
            },
          }
        );
      };

      if (file.size >= CHAT_FILE_MAXSIZE) {
        this.setState({ filesRejected: [...this.state.filesRejected, file] });
      } else if (file.type === 'image/png' || file.type === 'image/jpeg') {
        resize(file, (dataUrl) => {
          upload(dataUrl);
        });
      } else {
        upload();
      }
    });
  };

  sendFilePreview = (file, replyTo) => {
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      this.props.conversationId
    );
    let payload = {
      ackUid: file.ackUid,
      recipient: {
        type: interlocutor.userId ? 'USER' : 'GROUP',
        id: interlocutor.userId || interlocutor.groupId,
      },
      message: { file },
    };
    if (replyTo) {
      payload = {
        ...payload,
        message: {
          ...payload.message,
          replyTo,
        },
      };
    }
    this.props.sendFilePreview(payload);
  };

  handleEmoji = (emoji) => {
    this.setState({ lastEmoji: emoji });
  };

  handleEnableDeleting = (enable) => {
    this.setState({
      deletingEnabled: enable,
    });
  };

  onChangeDeleting = (data) => {
    if (data.value) {
      this.setState({
        deletingIds: [...this.state.deletingIds, data.id],
      });
    } else {
      this.setState({
        deletingIds: this.state.deletingIds.filter((id) => id !== data.id),
      });
    }
  };

  deleteMessages = () => {
    this.props.deleteMessages({
      messagesIds: this.state.deletingIds,
      conversationId: this.props.conversationId,
    });
    this.setState({
      deletingEnabled: false,
      deletingIds: [],
    });
  };

  handleClick = () => {
    this.setState({ filesRejected: [] });
    if (this.props.isActive) return;
    this.props.setActiveChat(this.props.conversationId);
  };

  handleClickOuter = (e) => {
    if (!this.props.isActive) {
      return;
    }

    if (
      e.target &&
      e.target.attributes &&
      e.target.attributes.class &&
      e.target.attributes.class.value.indexOf('chat-button') > -1
    ) {
      return;
    }

    const node = this.chatWindow.current;
    if (node.contains(e.target)) {
      return;
    }
    this.props.unsetActiveChat();
  };

  onChangeTimedMessage = (value) => {
    this.setState({
      timedMessage: value,
    });
  };

  enableRenameGroup = (e) => {
    this.setState({
      renamingEnabled: true,
    });
    e.nativeEvent.stopImmediatePropagation();
  };

  handleRenameGroup = (name) => {
    this.setState({
      renamingEnabled: false,
    });
    const interlocutor = retrieveInterlocutorIdFromConversationId(
      this.props.conversationId
    );
    if (name && interlocutor.groupId) {
      this.props.updateChatGroup({
        groupId: interlocutor.groupId,
        name,
      });
    }
  };

  handleEmojiPanel = (open) => {
    this.setState({
      emojiPanelOpen: open,
    });
  };

  handleEnterDragFiles = () => {
    this.setState({ filesRejected: [], dropZoneHover: true });
  };

  handleLeaveDragFiles = () => {
    this.setState({ dropZoneHover: false });
  };

  handleDropFiles = (filesAccepted, filesRejected) => {
    const rejected = filesRejected.filter((file) => file instanceof File);
    this.setState({ filesRejected: rejected, dropZoneHover: false });
    if (filesAccepted && filesAccepted.length > 0) {
      this.uploadFiles(filesAccepted);
    }
  };

  fetchMembers = () => {
    const { fetchMembers, membersFetched } = this.props;
    const { fetchedMembers } = this.state;
    if (!fetchedMembers && !membersFetched) {
      this.setState({ fetchedMembers: true });
      const { groupId } = retrieveInterlocutorIdFromConversationId(
        this.props.conversationId
      );
      if (groupId) fetchMembers(groupId);
    }
  };

  handleScroll = () => {
    if (this.props.isActive) return;
    this.props.setActiveChat(this.props.conversationId);
  };

  resetEmoji = () => {
    this.setState({ lastEmoji: '' });
  };

  render() {
    const {
      myId,
      isMember,
      conversation,
      conversationId,
      isActive,
      isMain,
      position,
      width,
      isRemoved,
      fetchedMembers,
      repliedMessageId,
      pageHeight,
      hide,
    } = this.props;

    const CHAT_WINDOWS_MAX_HEIGHTS = {
      BIG: pageHeight - 5,
      NORMAL: pageHeight - 5,
      MIN: 35,
    };

    const CHAT_PANEL_MAX_HEIGHTS = {
      BIG: CHAT_WINDOWS_MAX_HEIGHTS.BIG - 65,
      NORMAL: CHAT_WINDOWS_MAX_HEIGHTS.NORMAL - 65,
      MIN: 35,
    };

    const isGroup = retrieveInterlocutorIdFromConversationId(
      this.props.conversationId
    ).groupId;

    const isMeOut = isGroup && !isMember;

    const historyHeight = () => {
      const height = this.state.emojiPanelOpen
        ? repliedMessageId
          ? CHAT_PANEL_HEIGHTS[conversation.size] -
            CHAT_EMOJI_PANEL_HEIGHTS[conversation.size] -
            CHAT_TOOLBAR_HEIGHT -
            CHAT_REPLY_TEXTAREA_HEIGHTS[conversation.size]
          : CHAT_PANEL_HEIGHTS[conversation.size] -
            CHAT_EMOJI_PANEL_HEIGHTS[conversation.size] -
            CHAT_TOOLBAR_HEIGHT
        : repliedMessageId
        ? CHAT_PANEL_HEIGHTS[conversation.size] -
          CHAT_REPLY_TEXTAREA_HEIGHTS[conversation.size]
        : CHAT_PANEL_HEIGHTS[conversation.size];

      return isRemoved || isMeOut
        ? height
        : height -
            10 -
            CHAT_TOOLBAR_HEIGHT -
            CHAT_TEXTAREA_HEIGHTS[conversation.size];
    };
    const dropzoneStyle = {
      ...style.dropzone,
      opacity: this.state.dropZoneHover ? '0.5' : '1',
    };

    const historyMaxHeight =
      historyHeight() -
      (CHAT_WINDOWS_HEIGHTS[conversation.size] -
        CHAT_WINDOWS_MAX_HEIGHTS[conversation.size]);

    return (
      conversation && (
        <div
          key={conversationId}
          id={`chatWindow-${conversationId}`}
          ref={this.chatWindow}
          onMouseDown={this.handleClick}
          onWheel={this.handleScroll}
          onFocus={this.handleFocus}
          style={{
            ...style.outer,
            width: `${width}px`,
            right: position,
            display: hide ? 'none' : 'inline-block',
          }}
        >
          <div
            className="rounded-top bg-white"
            style={{
              ...style.inner,
              height: `${CHAT_WINDOWS_HEIGHTS[conversation.size]}px`,
              maxHeight: `${CHAT_WINDOWS_MAX_HEIGHTS[conversation.size]}px`,
            }}
          >
            <ChatWindowHeader
              conversationId={conversationId}
              size={conversation.size}
              width={width}
              maximizeWindow={this.maximizeWindow}
              normalizeWindow={(e) => this.normalizeWindow(e)}
              minimizeWindow={this.minimizeWindow}
              closeWindow={this.closeWindow}
              isActive={isActive}
              isMain={isMain}
              renamingEnabled={this.state.renamingEnabled}
              handleRenameGroup={this.handleRenameGroup}
            />
            <div
              style={{
                display:
                  conversation.size !== ChatEnums.ChatWindowSize.MIN
                    ? 'unset'
                    : 'none',
              }}
            >
              <ChatWindowToolbarTop
                conversationId={conversationId}
                readAllMessages={this.readAllMessages}
                deleteAllMessages={this.deleteAllMessages}
                archiveConversation={this.archiveConversation}
                enableRenameGroup={this.enableRenameGroup}
                width={width}
                isMain={isMain}
              />
              <Dropzone
                disabled={isGroup && (isRemoved || !isMember)}
                multiple
                onDrop={(acceptedFiles, rejectedFiles) =>
                  this.handleDropFiles(acceptedFiles, rejectedFiles)
                }
                onDragEnter={this.handleEnterDragFiles}
                onDragLeave={this.handleLeaveDragFiles}
                maxSize={CHAT_FILE_MAXSIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({
                      style: dropzoneStyle,
                      onClick: (event) => event.stopPropagation(),
                    })}
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        ...style.panel,
                        height: `${CHAT_PANEL_HEIGHTS[conversation.size]}px`,
                        maxHeight: `${
                          CHAT_PANEL_MAX_HEIGHTS[conversation.size]
                        }px`,
                      }}
                    >
                      <ChatHistory
                        conversationId={conversationId}
                        conversationSize={conversation.size}
                        deletingEnabled={this.state.deletingEnabled}
                        onChangeDeleting={this.onChangeDeleting}
                        onRetrySendFile={this.retrySendFile}
                        onRetrySendMessage={this.retrySendMessage}
                        isMeOut={isMeOut}
                        isRemoved={isRemoved}
                        fetchingMembers={!fetchedMembers && isGroup}
                        errors={{
                          files: this.state.filesRejected.length > 0,
                        }}
                        height={historyHeight()}
                        maxHeight={historyMaxHeight}
                        emojiPanelOpen={this.state.emojiPanelOpen}
                        windowPosition={position}
                      />
                      {!isRemoved &&
                        !isMeOut &&
                        (fetchedMembers || !isGroup) && (
                          <>
                            <ChatWindowToolbarBottom
                              myId={myId}
                              conversationId={conversationId}
                              deletingEnabled={this.state.deletingEnabled}
                              handleEnableDeleting={this.handleEnableDeleting}
                              deleteMessages={this.deleteMessages}
                              onChangeTimedMessage={this.onChangeTimedMessage}
                              timedMessage={this.state.timedMessage}
                              onEmojiSelect={this.handleEmoji}
                              emojiPanelOpen={this.state.emojiPanelOpen}
                              onToggleEmojiPanel={this.handleEmojiPanel}
                              size={conversation.size}
                              onSelectFiles={(files) => this.uploadFiles(files)}
                            />
                            {repliedMessageId && (
                              <ReplyMessageArea
                                size={conversation.size}
                                repliedMessageId={repliedMessageId}
                                conversationId={conversationId}
                              />
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '5px',
                                right: '4px',
                              }}
                            >
                              <ErrorBoundaryChat
                                height={
                                  CHAT_TEXTAREA_HEIGHTS[conversation.size]
                                }
                                bottom={0}
                              >
                                <ChatInputArea
                                  height={
                                    CHAT_TEXTAREA_HEIGHTS[conversation.size]
                                  }
                                  onChange={this.handleInputChange}
                                  onEnterClick={this.onEnterClick}
                                  emoji={this.state.lastEmoji}
                                  onNewEmoji={this.resetEmoji}
                                  isActive={isActive}
                                  isReplying={this.props.repliedMessageId}
                                />
                              </ErrorBoundaryChat>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
      )
    );
  }
}

ChatWindow.propTypes = {
  conversationId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { groupId } = retrieveInterlocutorIdFromConversationId(
    ownProps.conversationId
  );
  return {
    myId: getMeId(state),
    conversation: getConversationById(state, ownProps.conversationId),
    interlocutorName: getInterlocutorName(state, ownProps.conversationId),
    interlocutorAvatar: getInterlocutorAvatar(state, ownProps.conversationId),
    isActive: getActiveConversation(state) === ownProps.conversationId,
    isRemoved: isInterlocutorRemoved(state, ownProps.conversationId),
    isMember: isMeMember(state, groupId),
    isMain: isMainChat(state, groupId),
    fetchedMembers: groupId ? getGroupMembersLoaded(state, groupId) : false,
    repliedMessageId: getRepliedMessage(state, ownProps.conversationId),
    repliedMessage: getChatMessageById(
      state,
      getRepliedMessage(state, ownProps.conversationId)
    ),
    pageHeight: getYnPageHeight(state),
    hide:
      getBigConversationId(state) !== null &&
      getBigConversationId(state) !== ownProps.conversationId,
    publicChatAlert: isPublicChatAlert(state),
    onlineUsers: getOnlineUsers(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    closeWindow: closeChatConversation,
    minimizeWindow: minimizeChatConversation,
    normalizeWindow: normalizeChatConversation,
    maximizeWindow: maximizeChatConversation,
    sendMessage: sendChatMessageRequest,
    readAllMessages: sendReadAllMessageRequest,
    deleteMessages: sendDeleteMessagesRequest,
    deleteAllMessages: sendDeleteAllMessageRequest,
    archiveConversation: sendArchiveConversationRequest,
    sendComposing: sendComposingStatus,
    sendPaused: sendPausedStatus,
    setActiveChat: setActiveChatWindow,
    unsetActiveChat: unsetActiveChatWindow,
    updateChatGroup: updateChatGroupRequest,
    sendFilePreview: sendChatFilePreview,
    confirm: showConfirmModal,
    uploadFiles: uploadProgressFilesRequest,
    fetchMembers: fetchGroupMembersRequest,
    removeReplyMessage: removeReplyMessageRequest,
    clearErrors: clearChatErrors,
  })(ChatWindow)
);
