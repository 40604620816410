import React from 'react';
import PropTypes from 'prop-types';
import { Popover as RSPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import Loading from './Loading';

const style = {
  header: {
    color: 'var(--yn-blue-dark)',
    fontWeight: 'bold',
    borderColor: 'var(--yn-border-color)',
  },
  body: {
    maxHeight: '25rem',
    overflowY: 'auto',
  },
  label: {
    color: 'var(--yn-blue-dark)',
    fontWeight: 'bold',
  },
};

const Popover = ({
  isOpen,
  target,
  toggle,
  placement,
  header,
  loading,
  children,
  popoverMinWidth,
  popoverMinHeight,
  popoverWidth,
  popoverHeight,
  bodyPadding,
  hideArrow,
  modifiers,
  popoverMaxWidth,
}) => (
  <RSPopover
    placement={placement}
    isOpen={isOpen}
    target={target}
    toggle={toggle}
    style={{
      minWidth: popoverMinWidth || '16rem',
      minHeight: popoverMinHeight || '8rem',
      width: popoverWidth,
      height: popoverHeight,
    }}
    trigger="legacy"
    hideArrow={hideArrow}
    modifiers={modifiers}
  >
    {header && <PopoverHeader style={style.header}>{header}</PopoverHeader>}
    {loading ? (
      <Loading />
    ) : (
      <PopoverBody
        style={{
          maxHeight: '25rem',
          overflowY: 'auto',
          padding: bodyPadding,
          maxWidth: popoverMaxWidth,
        }}
      >
        {children}
      </PopoverBody>
    )}
  </RSPopover>
);

Popover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string,
  loading: PropTypes.bool,
  placement: PropTypes.string,
  popoverMinWidth: PropTypes.string,
  popoverMinHeight: PropTypes.string,
  popoverWidth: PropTypes.string,
  popoverHeight: PropTypes.string,
  popoverMaxWidth: PropTypes.string,
  bodyPadding: PropTypes.string,
  hideArrow: PropTypes.bool,
  modifiers: PropTypes.object,
};

Popover.defaultProps = {
  header: '',
  loading: false,
  placement: 'auto',
  popoverMinWidth: null,
  popoverMinHeight: null,
  popoverWidth: null,
  popoverHeight: null,
  popoverMaxWidth: null,
  bodyPadding: null,
  hideArrow: false,
  modifiers: null,
};

export default Popover;
