import find from 'lodash.find';
import remove from 'lodash.remove';
import pull from 'lodash.pull';
import keyBy from 'lodash.keyby';
import sortBy from 'lodash.sortby';
import moment from 'moment';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  WEBSOCKET_DISCONNECTION,
  WEBSOCKET_CONNECTION,
} from '../websocket/types';
import {
  FETCH_CONVERSATIONS_LIST_SUCCESS,
  FETCH_CONVERSATIONS_LIST_FAILURE,
  SEND_CHAT_MESSAGE_REQUEST,
  SEND_CHAT_MESSAGE_SUCCESS,
  SEND_CHAT_MESSAGE_FAILURE,
  SEND_CHAT_MESSAGE_CC,
  RECEIVE_CHAT_MESSAGE,
  RECEIVE_DELIVERY_MESSAGE,
  SEND_READ_MESSAGE_SUCCESS,
  RECEIVE_READ_MESSAGE_CC,
  RECEIVE_READ_MESSAGE,
  SEND_READ_ALL_MESSAGE_REQUEST,
  SEND_READ_ALL_MESSAGE_SUCCESS,
  SEND_READ_ALL_MESSAGE_FAILURE,
  SEND_READ_ALL_MESSAGE_CC,
  SEND_DELETE_MESSAGES_REQUEST,
  SEND_DELETE_MESSAGES_SUCCESS,
  SEND_DELETE_MESSAGES_FAILURE,
  SEND_DELETE_MESSAGES_CC,
  SEND_ARCHIVE_CONVERSATION_REQUEST,
  SEND_ARCHIVE_CONVERSATION_SUCCESS,
  SEND_ARCHIVE_CONVERSATION_FAILURE,
  SEND_ARCHIVE_CONVERSATION_CC,
  SEND_UNARCHIVE_CONVERSATION_REQUEST,
  SEND_UNARCHIVE_CONVERSATION_SUCCESS,
  SEND_UNARCHIVE_CONVERSATION_FAILURE,
  SEND_UNARCHIVE_CONVERSATION_CC,
  SEND_DELETE_ALL_MESSAGES_REQUEST,
  SEND_DELETE_ALL_MESSAGES_SUCCESS,
  SEND_DELETE_ALL_MESSAGES_FAILURE,
  SEND_DELETE_ALL_MESSAGES_CC,
  MINIMIZE_CHAT_CONVERSATION,
  MAXIMIZE_CHAT_CONVERSATION,
  CLOSE_CHAT_CONVERSATION,
  OPEN_CHAT_CONVERSATION,
  FETCH_CONVERSATION_HISTORY_REQUEST,
  FETCH_CONVERSATION_HISTORY_SUCCESS,
  FETCH_CONVERSATION_HISTORY_FAILURE,
  SET_CONVERSATIONS_LIST_FILTER,
  SET_MAX_OPEN_CHAT_WINDOWS,
  NORMALIZE_CHAT_CONVERSATION,
  RECEIVE_COMPOSING_STATUS,
  RECEIVE_PAUSED_STATUS,
  SET_ACTIVE_CHAT_WINDOW,
  UNSET_ACTIVE_CHAT_WINDOW,
  FETCH_CONVERSATION_FILES_REQUEST,
  FETCH_CONVERSATION_FILES_SUCCESS,
  FETCH_CONVERSATION_FILES_FAILURE,
  CHANGE_CONVERSATION_FILES_PAGE_REQUEST,
  REMOVE_TIMED_MESSAGE_SUCCESS,
  REFRESH_CONVERSATION_SUCCESS,
  FETCH_CHAT_SEARCH_SUCCESS,
  FETCH_CHAT_SEARCH_FAILURE,
  FETCH_OTHER_CHAT_RESULTS_SUCCESS,
  FETCH_OTHER_CHAT_RESULTS_REQUEST,
  FETCH_OTHER_CHAT_RESULTS_FAILURE,
  OPEN_CHAT_SEARCH_RESULT_REQUEST,
  SET_CHAT_SEARCH_RESULT,
  UNSET_CHAT_SEARCH_RESULT,
  SEND_CHAT_FILE_PREVIEW,
  FETCH_IMPORTANT_MESSAGES_REQUEST,
  FETCH_IMPORTANT_MESSAGES_FAILURE,
  FETCH_IMPORTANT_MESSAGES_SUCCESS,
  MARK_IMPORTANT_MESSAGE_REQUEST,
  MARK_IMPORTANT_MESSAGE_SUCCESS,
  MARK_IMPORTANT_MESSAGE_FAILURE,
  REPLY_MESSAGE_REQUEST,
  REMOVE_REPLY_MESSAGE_REQUEST,
  FETCH_SCHEDULED_MESSAGES_REQUEST,
  FETCH_SCHEDULED_MESSAGES_SUCCESS,
  FETCH_SCHEDULED_MESSAGES_FAILURE,
  SAVE_SCHEDULED_MESSAGE_REQUEST,
  SAVE_SCHEDULED_MESSAGE_SUCCESS,
  SAVE_SCHEDULED_MESSAGE_FAILURE,
  DELETE_SCHEDULED_MESSAGE_REQUEST,
  DELETE_SCHEDULED_MESSAGE_SUCCESS,
  DELETE_SCHEDULED_MESSAGE_FAILURE,
  MARK_UNIMPORTANT_MESSAGE_REQUEST,
  MARK_UNIMPORTANT_MESSAGE_SUCCESS,
  MARK_UNIMPORTANT_MESSAGE_FAILURE,
  MARK_UNIMPORTANT_MESSAGE_CC,
  MARK_IMPORTANT_MESSAGE_CC,
  GET_MUTED_CHATS_REQUEST,
  GET_MUTED_CHATS_SUCCESS,
  GET_MUTED_CHATS_FAILURE,
  MUTE_UNMUTE_CHAT_REQUEST,
  MUTE_UNMUTE_CHAT_SUCCESS,
  MUTE_UNMUTE_CHAT_FAILURE,
  CLEAR_CHAT_ERROR,
} from './types';
import {
  generateConversationId,
  generateSenderFullname,
  ChatEnums,
  DAYMESSAGEFORMAT,
  generateSenderMessageId,
  retrieveMessageId,
  CHAT_HISTORY_CHUNK,
} from './ChatUtils';

import { FETCH_ME_SUCCESS } from '../me/types';
import {
  DELETE_GROUP_REQUEST,
  UPDATE_CHAT_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  UPDATE_CHAT_GROUP_SUCCESS,
  UPDATE_CHAT_GROUP_FAILURE,
  CREATE_CHAT_GROUP_REQUEST,
  CREATE_CHAT_GROUP_SUCCESS,
  CREATE_CHAT_GROUP_FAILURE,
} from '../groups/types';
import {
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_CANCEL,
} from '../files/types';

const conversationHeaderErrors = {
  readAllError: false,
  deleteMessagesError: false,
  deleteAllError: false,
  deleteGroupError: false,
  updateChatGroupError: false,
};

const conversationState = {
  id: null,
  archived: false,
  messagesAllIds: [],
  size: ChatEnums.ChatWindowSize.NORMAL,
  scrollToBottom: true,
  fetchHistoryCompleted: false,
  fetchRecentHistoryCompleted: true,
  firstHistoryLoaded: false,
  fetchHistoryLoaded: true,
  fetchRecentHistoryLoaded: false,
  fetchOldHistoryLoaded: false,
  fetchHistoryError: false,
  sendMessageError: false,
  archiveError: false,
  unarchiveError: false,
  toBeRead: 0,
  composing: [],
  operationLoading: false,
  ...conversationHeaderErrors,
};

const messageState = {
  conversationId: null,
  senderId: null,
  senderFullname: null,
  timestamp: null,
  sent: false,
  errorSending: false,
  read: null,
  delivery: null,
  duration: null,
  text: null,
  file: null,
  info: null,
};

const initialState = {
  inited: false,
  myId: null,
  myFullname: null,
  maxOpenChatWindows: 0,
  fetchConversationLoaded: false,
  fetchConversationError: false,
  createChatGroupLoaded: true,
  createChatGroupError: false,
  conversationsById: {
    /* id: 'user-4110', // 'group-11000',
    fullname: 'Patrizia Fusi',
    color: null,
    archived: false,
    messagesAllIds: [
      {
        day: '20180101',
        messages: []
      }
    ],
    size: null,
    fetchHistoryCompleted: false,
    fetchRecentHistoryCompleted: true,
    firstHistoryLoaded: true,
    fetchRecentHistoryLoaded: false
    fetchHistoryLoaded: true,
    fetchHistoryError: false,
    sendMessageError: false,
    readAllError: false,
    deleteMessagesError: false,
    deleteAllError: false,
    archiveError: false,
    unarchiveError: false,
    deleteGroupError: false,
    updateChatGroupError: false,
    toBeRead: 0,
    composing: [],
    operationLoading: false
    */
  },
  conversationAllIds: [],
  messagesById: {
    /* conversationId: 'user-4110', // 'group-11000',
    senderId: 4110, // null
    senderFullname: 'Latini Andrea', // used if user is deleted
    senderHistoryId: null,
    timestamp: 1110202002,
    read: 1110202004,
    delivery: 1110202003,
    sent: true,
    errorSending: false,
    duration: null,
    text: 'Ciao',
    file: {
      stored: 'asdafas.jpg',
      name: 'prova.jpg',
      size: 1000,
      mime: 'image/jpeg',
      thumbnail: 'base64sdfsdfsdsdfsd'
    },
    info: {
      type: string,
      user: {}
    } */
  },
  openConversationsIds: [],
  activeConversation: null,
  bigConversation: null,
  conversationsFilter: {
    text: '',
    users: true,
    groups: true,
    archived: false,
  },
  textSearch: {
    loaded: true,
    otherLoaded: true,
    error: false,
    otherError: false,
    results: [],
    completed: false,
  },
  fetchFiles: {
    total: 0,
    currentPage: 0,
    loaded: true,
    error: false,
    files: [],
  },
  searchedMessage: null,
  deletedUsersById: {},
  importantMessages: [],
  scheduledMessagesLoaded: true,
  scheduledMessagesError: false,
  scheduledMessagesById: {},
  scheduledMessagesAllIds: [],
  saveScheduledMessageLoaded: true,
  saveScheduledMessageError: false,
  saveScheduledMessageSuccess: false,
  deleteScheduledMessageError: false,
  mutedChats: {},
  mutedChatsLoaded: true,
  mutedChatsError: false,
};

export default function chat(state = initialState, action = {}) {
  switch (action.type) {
    case WEBSOCKET_CONNECTION:
    case WEBSOCKET_DISCONNECTION:
      return {
        ...state,
        inited: true,
        conversationsFilter: {
          text: '',
          users: true,
          groups: true,
          archived: false,
        },
      };
    case FETCH_ME_SUCCESS:
      return {
        ...state,
        myFullname: action.me.fullname,
        myId: action.me.id,
      };
    case FETCH_CONVERSATIONS_LIST_SUCCESS: {
      const conversationsById = {};
      const conversationAllIds = [];
      const messagesById =
        Object.keys(state.messagesById).length > 0
          ? { ...state.messagesById }
          : {};
      action.conversations.forEach((conversation) => {
        const conversationId = generateConversationId({
          userId: conversation.interlocutor.user
            ? conversation.interlocutor.user.userId
            : null,
          groupId: conversation.interlocutor.group
            ? conversation.interlocutor.group.groupId
            : null,
        });
        if (conversationId) {
          let wasHistoryLoaded = false;
          if (
            state.conversationsById[conversationId] &&
            state.conversationsById[conversationId].messagesAllIds &&
            state.conversationsById[conversationId].messagesAllIds[0] &&
            state.conversationsById[conversationId].messagesAllIds[0]
              .messages &&
            state.conversationsById[conversationId].messagesAllIds[0]
              .messages[0] ===
              generateSenderMessageId(
                conversation.lastHistory.sender.userId,
                conversation.lastHistory.historyId,
                conversation.lastHistory.type === ChatEnums.ChatTopics.INFO
              )
          ) {
            wasHistoryLoaded = true;
          }
          conversationsById[conversationId] = {
            ...(state.conversationsById[conversationId] || conversationState),
            fullname: conversation.interlocutor.user
              ? generateSenderFullname(
                  conversation.interlocutor.user.name,
                  conversation.interlocutor.user.surname
                )
              : conversation.interlocutor.group.name,
            color: conversation.interlocutor.user
              ? null
              : conversation.interlocutor.group.color,
            id: conversationId,
            archived:
              conversation.interlocutor.lastArchivedTime >
              conversation.lastHistory.timestamp,
            messagesAllIds: wasHistoryLoaded
              ? state.conversationsById[conversationId].messagesAllIds
              : state.openConversationsIds.indexOf(conversationId) < 0
              ? [
                  {
                    day: moment(conversation.lastHistory.timestamp).format(
                      DAYMESSAGEFORMAT
                    ),
                    messages: [
                      generateSenderMessageId(
                        conversation.lastHistory.sender.userId,
                        conversation.lastHistory.historyId,
                        conversation.lastHistory.type ===
                          ChatEnums.ChatTopics.INFO
                      ),
                    ],
                  },
                ]
              : [...state.conversationsById[conversationId].messagesAllIds],
            toBeRead: conversation.toBeRead ? conversation.toBeRead : 0,
          };
          conversationAllIds.push(conversationId);
          if (!messagesById[conversation.lastHistory.historyId]) {
            messagesById[conversation.lastHistory.historyId] = {
              id: conversation.lastHistory.historyId,
              conversationId,
              type: conversation.lastHistory.type,
              senderId: conversation.lastHistory.sender.userId,
              senderFullname: generateSenderFullname(
                conversation.lastHistory.sender.name,
                conversation.lastHistory.sender.surname
              ),
              timestamp: conversation.lastHistory.timestamp,
              duration: conversation.lastHistory.message
                ? conversation.lastHistory.message.duration
                : null,
              text: conversation.lastHistory.message
                ? conversation.lastHistory.message.text
                : null,
              file: conversation.lastHistory.message
                ? conversation.lastHistory.message.file
                : null,
              info: conversation.lastHistory.info,
              sent: true,
            };
          }
        }
      });
      return {
        ...state,
        fetchConversationLoaded: true,
        fetchConversationError: false,
        conversationsById,
        conversationAllIds,
        messagesById,
      };
    }
    case FETCH_CONVERSATIONS_LIST_FAILURE:
      return {
        ...state,
        fetchConversationLoaded: true,
        fetchConversationError: true,
      };
    case SEND_CHAT_FILE_PREVIEW:
    case SEND_CHAT_MESSAGE_REQUEST: {
      const conversationId = generateConversationId({
        userId:
          action.data.recipient.type === 'USER'
            ? action.data.recipient.id
            : null,
        groupId:
          action.data.recipient.type === 'GROUP'
            ? action.data.recipient.id
            : null,
      });
      const conversation =
        state.conversationsById && state.conversationsById[conversationId];
      if (!conversation) return state;
      if (
        state.messagesById[action.data.ackUid] ||
        (conversation.messagesAllIds[0] &&
          conversation.messagesAllIds[0].messages.indexOf(action.data.ackUid) >
            -1)
      ) {
        return {
          ...state,
          messagesById: {
            ...state.messagesById,
            [action.data.ackUid]: {
              ...state.messagesById[action.data.ackUid],
              errorSending: false,
            },
          },
        };
      }
      let { conversationAllIds } = state;
      let conversationById;
      const messageSenderId = generateSenderMessageId(
        state.myId,
        action.data.ackUid
      );
      if (conversation) {
        let { messagesAllIds } = state.conversationsById[conversationId];
        const messageTimestamp = new Date().getTime();
        if (
          messagesAllIds[0] &&
          messagesAllIds[0].day ===
            moment(messageTimestamp).format(DAYMESSAGEFORMAT)
        ) {
          messagesAllIds[0].messages = [
            messageSenderId,
            ...messagesAllIds[0].messages,
          ];
        } else {
          messagesAllIds = [
            {
              day: moment(messageTimestamp).format(DAYMESSAGEFORMAT),
              messages: [messageSenderId],
            },
            ...messagesAllIds,
          ];
        }
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...state.conversationsById[conversationId],
          messagesAllIds: [...messagesAllIds],
          archived: false,
          ...conversationHeaderErrors,
        };
      } else {
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...conversationState,
          id: conversationId,
          messagesAllIds: [
            {
              day: moment(action.data.timestamp).format(DAYMESSAGEFORMAT),
              messages: [messageSenderId],
            },
          ],
          size: ChatEnums.ChatWindowSize.NORMAL,
          toBeRead: action.data.type === ChatEnums.ChatTopics.INFO ? 0 : 1,
          ...conversationHeaderErrors,
        };
      }
      return {
        ...state,
        conversationAllIds,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: conversationById,
        },
        messagesById: {
          ...state.messagesById,
          [action.data.ackUid]: {
            ...messageState,
            id: action.data.ackUid,
            conversationId,
            type: ChatEnums.ChatTopics.MESSAGES,
            senderId: state.myId,
            senderFullname: state.myFullname,
            timestamp: new Date().getTime(),
            duration: action.data.message.duration,
            text: action.data.message.text,
            file: action.data.message.file,
            forwarded: action.data.message.forwarded,
            isImportant: action.data.message.important,
            replyTo: action.data.message.replyTo,
          },
        },
      };
    }
    case SEND_CHAT_MESSAGE_SUCCESS: {
      if (!state.messagesById[action.data.ackUid]) return state;
      const { conversationId } = state.messagesById[action.data.ackUid];
      const messagesById = { ...state.messagesById };
      messagesById[action.data.id] = {
        ...messagesById[action.data.ackUid],
        sent: true,
        file: messagesById[action.data.ackUid].file
          ? { ...messagesById[action.data.ackUid].file, thumbnail: null }
          : null,
        id: action.data.id,
      };
      delete messagesById[action.data.ackUid];
      const messagesAllIds = [
        ...state.conversationsById[conversationId].messagesAllIds,
      ];
      for (let i = 0; i < messagesAllIds.length; i += 1) {
        const found = find(
          messagesAllIds[i].messages,
          (item) => retrieveMessageId(item) === action.data.ackUid
        );
        /* TO SHOW TO ANDREW */
        if (found !== undefined) {
          const { messages } = messagesAllIds[i];
          messages.splice(
            messagesAllIds[i].messages.indexOf(found),
            1,
            generateSenderMessageId(state.myId, action.data.id)
          );
          messagesAllIds[i] = {
            ...messagesAllIds[i],
            messages: [...messages],
          };
          break;
        }
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            messagesAllIds,
            scrollToBottom: true,
          },
        },
        messagesById,
      };
    }

    case SEND_CHAT_MESSAGE_FAILURE: {
      const conversationId = state.messagesById[action.ackUid]
        ? state.messagesById[action.ackUid].conversationId
        : null;
      if (!conversationId) {
        return state;
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            sendMessageError: true,
          },
        },
        messagesById: {
          ...state.messagesById,
          [action.ackUid]: {
            ...state.messagesById[action.ackUid],
            errorSending: true,
          },
        },
      };
    }

    case SEND_CHAT_MESSAGE_CC: {
      const conversationId = generateConversationId({
        userId: action.data.interlocutor.user
          ? action.data.interlocutor.user.userId
          : null,
        groupId: action.data.interlocutor.group
          ? action.data.interlocutor.group.groupId
          : null,
      });
      const conversation = state.conversationsById[conversationId];
      let { conversationAllIds } = state;
      let conversationById;
      const senderMessageId = generateSenderMessageId(
        state.myId,
        action.data.historyId
      );
      if (conversation) {
        let { messagesAllIds } = state.conversationsById[conversationId];

        if (
          messagesAllIds &&
          messagesAllIds[0] &&
          messagesAllIds[0].day ===
            moment(action.data.timestamp).format(DAYMESSAGEFORMAT)
        ) {
          messagesAllIds[0].messages = [
            senderMessageId,
            ...messagesAllIds[0].messages,
          ];
        } else {
          messagesAllIds = [
            {
              day: moment(action.data.timestamp).format(DAYMESSAGEFORMAT),
              messages: [senderMessageId],
            },
            ...messagesAllIds,
          ];
        }
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...state.conversationsById[conversationId],
          messagesAllIds: [...messagesAllIds],
          archived: false,
          ...conversationHeaderErrors,
        };
      } else {
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...conversationState,
          id: conversationId,
          messagesAllIds: [
            {
              day: moment(action.data.timestamp).format(DAYMESSAGEFORMAT),
              messages: [senderMessageId],
            },
          ],
          size: ChatEnums.ChatWindowSize.NORMAL,
          toBeRead: action.data.type === ChatEnums.ChatTopics.INFO ? 0 : 1,
          ...conversationHeaderErrors,
        };
      }
      return {
        ...state,
        conversationAllIds,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: conversationById,
        },
        messagesById: {
          ...state.messagesById,
          [action.data.historyId]: {
            ...messageState,
            id: action.data.historyId,
            type: ChatEnums.ChatTopics.MESSAGES,
            conversationId,
            senderId: state.myId,
            senderFullname: state.myFullname,
            timestamp: action.data.timestamp,
            sent: true,
            duration: action.data.message ? action.data.message.duration : null,
            text: action.data.message ? action.data.message.text : null,
            file: action.data.message ? action.data.message.file : null,
            info: action.data.info,
            forwarded: action.data.message.forwarded,
            replyTo: action.data.message ? action.data.message.replyTo : null,
          },
        },
      };
    }

    case RECEIVE_CHAT_MESSAGE: {
      const conversationId = generateConversationId({
        userId: action.data.interlocutor.user
          ? action.data.interlocutor.user.userId
          : null,
        groupId: action.data.interlocutor.group
          ? action.data.interlocutor.group.groupId
          : null,
      });
      const conversation = state.conversationsById[conversationId];
      let { conversationAllIds } = state;
      let conversationById;
      const senderMessageId = generateSenderMessageId(
        action.data.sender.userId,
        action.data.historyId,
        action.data.type === ChatEnums.ChatTopics.INFO
      );
      if (conversation) {
        let { messagesAllIds } = state.conversationsById[conversationId];
        if (
          messagesAllIds[0] &&
          messagesAllIds[0].day ===
            moment(action.data.timestamp).format(DAYMESSAGEFORMAT)
        ) {
          messagesAllIds[0].messages = [
            senderMessageId,
            ...messagesAllIds[0].messages,
          ];
        } else {
          messagesAllIds = [
            {
              day: moment(action.data.timestamp).format(DAYMESSAGEFORMAT),
              messages: [senderMessageId],
            },
            ...messagesAllIds,
          ];
        }
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...state.conversationsById[conversationId],
          messagesAllIds: [...messagesAllIds],
          archived: false,
          toBeRead:
            action.data.type === ChatEnums.ChatTopics.INFO
              ? state.conversationsById[conversationId].toBeRead
              : state.conversationsById[conversationId].toBeRead + 1,
          ...conversationHeaderErrors,
        };
      } else {
        conversationAllIds = [conversationId, ...conversationAllIds];
        conversationById = {
          ...conversationState,
          id: conversationId,
          messagesAllIds: [
            {
              day: moment(action.data.timestamp).format(DAYMESSAGEFORMAT),
              messages: [senderMessageId],
            },
          ],
          // size: ChatEnums.ChatWindowSize.NORMAL,
          toBeRead: action.data.type === ChatEnums.ChatTopics.INFO ? 0 : 1,
          ...conversationHeaderErrors,
        };
      }

      conversationById = {
        ...conversationById,
        fullname: action.data.interlocutor.user
          ? generateSenderFullname(
              action.data.interlocutor.user.name,
              action.data.interlocutor.user.surname
            )
          : action.data.interlocutor.group.name,
      };
      return {
        ...state,
        conversationAllIds,
        totalUnreadMessages:
          state.totalUnreadMessages !== null
            ? action.data.type === ChatEnums.ChatTopics.INFO
              ? state.totalUnreadMessages
              : state.totalUnreadMessages + 1
            : null,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...conversationById,
            scrollToBottom: true,
          },
        },
        messagesById: {
          ...state.messagesById,
          [action.data.historyId]: {
            ...messageState,
            id: action.data.historyId,
            conversationId,
            type: action.data.type,
            senderId: action.data.sender.userId,
            senderFullname: generateSenderFullname(
              action.data.sender.name,
              action.data.sender.surname
            ),
            senderHistoryId: action.data.senderHistoryId,
            timestamp: action.data.timestamp,
            sent: true,
            duration: action.data.message ? action.data.message.duration : null,
            text: action.data.message ? action.data.message.text : null,
            file: action.data.message ? action.data.message.file : null,
            info: action.data.info,
            replyTo: action.data.message ? action.data.message.replyTo : null,
            forwarded: action.data.message
              ? action.data.message.forwarded
              : false,
          },
        },
      };
    }

    case RECEIVE_DELIVERY_MESSAGE: {
      const { messagesById } = state;
      action.data.forEach((delivered) => {
        if (messagesById[delivered.historyId]) {
          messagesById[delivered.historyId] = {
            ...messagesById[delivered.historyId],
            delivery: delivered.statusTimestamp,
          };
        }
      });
      return {
        ...state,
        messagesById: {
          ...messagesById,
        },
      };
    }

    case SEND_READ_MESSAGE_SUCCESS:
    case RECEIVE_READ_MESSAGE_CC: {
      const message = state.messagesById[action.data.historyId];
      let conversationId;
      let { messagesById } = state;
      if (message) {
        ({ conversationId } = messagesById[action.data.historyId]);
        messagesById = {
          ...state.messagesById,
          [action.data.historyId]: {
            ...state.messagesById[action.data.historyId],
            read: action.data.statusTimestamp,
          },
        };
      } else {
        conversationId = generateConversationId(action.data.interlocutor);
      }
      return {
        ...state,
        totalUnreadMessages:
          state.totalUnreadMessages !== null
            ? state.totalUnreadMessages - 1 >= 0
              ? state.totalUnreadMessages - 1
              : 0
            : null,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            toBeRead:
              state.conversationsById[conversationId] &&
              state.conversationsById[conversationId].toBeRead - 1 >= 0
                ? state.conversationsById[conversationId].toBeRead - 1
                : 0,
          },
        },
        messagesById,
      };
    }

    case RECEIVE_READ_MESSAGE: {
      const { messagesById } = state;
      action.data.forEach((read) => {
        if (messagesById[read.historyId]) {
          messagesById[read.historyId] = {
            ...messagesById[read.historyId],
            read: read.statusTimestamp,
          };
        }
      });
      return {
        ...state,
        messagesById: {
          ...messagesById,
        },
      };
    }

    case SEND_READ_ALL_MESSAGE_REQUEST: {
      const conversationId = generateConversationId(action.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            operationLoading: true,
            ...conversationHeaderErrors,
          },
        },
      };
    }

    case SEND_READ_ALL_MESSAGE_SUCCESS:
    case SEND_READ_ALL_MESSAGE_CC: {
      const conversationId = generateConversationId(action.data.interlocutor);
      if (!state.conversationsById[conversationId]) {
        return state;
      }
      const { messagesById } = state;
      let readCount = 0;
      state.conversationsById[conversationId].messagesAllIds.forEach(
        (dayGroup) => {
          dayGroup.messages.forEach((senderMessageId) => {
            const messageId = retrieveMessageId(senderMessageId);
            if (
              !messagesById[messageId].duration &&
              !messagesById[messageId].read
            ) {
              readCount += 1;
            }
            messagesById[messageId] = {
              ...messagesById[messageId],
              read: messagesById[messageId].duration
                ? messagesById[messageId].read
                : messagesById[messageId].read || action.data.statusTimestamp,
            };
          });
        }
      );
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            toBeRead:
              state.conversationsById[conversationId].toBeRead - readCount >= 0
                ? state.conversationsById[conversationId].toBeRead - readCount
                : 0,
            operationLoading: false,
          },
        },
        messagesById: {
          ...messagesById,
        },
      };
    }

    case SEND_READ_ALL_MESSAGE_FAILURE: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            readAllError: true,
            operationLoading: true,
          },
        },
      };
    }

    case SEND_DELETE_MESSAGES_REQUEST: {
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.payload.conversationId]: {
            ...state.conversationsById[action.payload.conversationId],
            operationLoading: true,
            ...conversationHeaderErrors,
          },
        },
      };
    }

    case SEND_DELETE_MESSAGES_SUCCESS:
    case SEND_DELETE_MESSAGES_CC: {
      const deletedMessagesIds = action.data.ids;
      const { conversationId } = state.messagesById[deletedMessagesIds[0]];
      const { messagesById } = state;
      const { messagesAllIds } = state.conversationsById[conversationId];
      for (let i = 0; i < messagesAllIds.length; i += 1) {
        const { messages } = messagesAllIds[i];
        const found = find(
          messages,
          (item) => deletedMessagesIds.indexOf(retrieveMessageId(item)) >= 0
        );
        if (found !== undefined) {
          remove(
            messages,
            (item) => deletedMessagesIds.indexOf(retrieveMessageId(item)) >= 0
          );
          messagesAllIds[i].messages = [...messages];
          if (messages.length === 0) {
            messagesAllIds.splice(i, 1);
          }
          break;
        }
      }
      const { conversationsById, conversationAllIds } = state;
      conversationsById[conversationId] = {
        ...conversationsById[conversationId],
        messagesAllIds: [...messagesAllIds],
        operationLoading: false,
      };
      if (messagesAllIds.length === 0) {
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
      }
      return {
        ...state,
        conversationAllIds: [...conversationAllIds],
        conversationsById: {
          ...conversationsById,
        },
        messagesById: {
          ...messagesById,
          [action.data.ids[0]]: {
            read: moment().utc().valueOf(),
          },
        },
      };
    }

    case REMOVE_TIMED_MESSAGE_SUCCESS:
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
        },
      };

    case SEND_DELETE_MESSAGES_FAILURE: {
      const deletedMessagesIds = action.data.ids;
      const { conversationId } = state.messagesById[deletedMessagesIds[0]];
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            deleteMessagesError: true,
            operationLoading: false,
          },
        },
      };
    }

    case SEND_DELETE_ALL_MESSAGES_REQUEST: {
      const conversationId = generateConversationId(action.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            ...conversationHeaderErrors,
          },
        },
      };
    }

    case SEND_DELETE_ALL_MESSAGES_SUCCESS:
    case SEND_DELETE_ALL_MESSAGES_CC: {
      const conversationId = generateConversationId(action.data.interlocutor);
      const { messagesAllIds } = state.conversationsById[conversationId];
      const conversationAllIds = [...state.conversationAllIds];
      const messagesById = { ...state.messagesById };
      const remainingMessages = [];
      let deleteCount = 0;
      messagesAllIds.forEach((dayGroup) => {
        const messages = dayGroup.messages.filter((senderMessageId) => {
          const messageId = retrieveMessageId(senderMessageId);
          const message = messagesById[messageId];
          if (!message.info && (!message.duration || message.read)) {
            delete messagesById[messageId];
            deleteCount += 1;
            return false;
          }
          return true;
        });
        if (messages && messages.length > 0) {
          remainingMessages.push({
            day: dayGroup.day,
            messages,
          });
        }
      });
      if (
        state.conversationsById[conversationId].fetchHistoryCompleted &&
        remainingMessages.length === 0
      ) {
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
      }
      return {
        ...state,
        conversationAllIds: [...conversationAllIds],
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            messagesAllIds: remainingMessages,
            toBeRead:
              state.conversationsById[conversationId].toBeRead - deleteCount >=
              0
                ? state.conversationsById[conversationId].toBeRead - deleteCount
                : 0,
          },
        },
        messagesById,
      };
    }

    case SEND_DELETE_ALL_MESSAGES_FAILURE: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            deleteAllError: true,
          },
        },
      };
    }

    case SEND_ARCHIVE_CONVERSATION_REQUEST: {
      const conversationId = generateConversationId(action.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            ...conversationHeaderErrors,
          },
        },
      };
    }

    case SEND_ARCHIVE_CONVERSATION_SUCCESS:
    case SEND_ARCHIVE_CONVERSATION_CC: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            archived: true,
          },
        },
      };
    }

    case SEND_ARCHIVE_CONVERSATION_FAILURE: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            archiveError: true,
          },
        },
      };
    }

    case SEND_UNARCHIVE_CONVERSATION_REQUEST: {
      const conversationId = generateConversationId(action.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            ...conversationHeaderErrors,
          },
        },
      };
    }

    case SEND_UNARCHIVE_CONVERSATION_SUCCESS:
    case SEND_UNARCHIVE_CONVERSATION_CC: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            archived: false,
          },
        },
      };
    }

    case SEND_UNARCHIVE_CONVERSATION_FAILURE: {
      const conversationId = generateConversationId(action.data.interlocutor);
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...state.conversationsById[conversationId],
            unarchiveError: true,
          },
        },
      };
    }

    case OPEN_CHAT_CONVERSATION:
    case OPEN_CHAT_SEARCH_RESULT_REQUEST: {
      const { conversationsById } = state;

      if (!conversationsById[action.payload.conversationId]) {
        conversationsById[action.payload.conversationId] = {
          ...conversationState,
          id: action.payload.conversationId,
          scrollToBottom: true,
        };
      } else {
        conversationsById[action.payload.conversationId] = {
          ...conversationsById[action.payload.conversationId],
          size:
            action.payload.conversationId === state.bigConversation
              ? ChatEnums.ChatWindowSize.BIG
              : ChatEnums.ChatWindowSize.NORMAL,
          scrollToBottom: true,
          ...conversationHeaderErrors,
        };
      }
      if (
        action.payload.byMe &&
        state.bigConversation &&
        action.payload.conversationId !== state.bigConversation
      ) {
        conversationsById[state.bigConversation] = {
          ...conversationsById[state.bigConversation],
          size: ChatEnums.ChatWindowSize.NORMAL,
          scrollToBottom: true,
          ...conversationHeaderErrors,
        };
      }

      let openConversationsIds = [];

      const conversationIsClosed =
        state.openConversationsIds.indexOf(action.payload.conversationId) < 0;

      /*const conversationIsLastOpen =
        state.openConversationsIds.indexOf(action.payload.conversationId) >=
          state.maxOpenChatWindows ||
        state.bigConversation === action.payload.conversationId;*/

      const conversationIsHidden = state.bigConversation
        ? state.openConversationsIds.indexOf(action.payload.conversationId) > 0
        : state.openConversationsIds.indexOf(action.payload.conversationId) >=
          state.maxOpenChatWindows;

      if (
        (conversationIsClosed || conversationIsHidden) &&
        action.payload.byMe
      ) {
        openConversationsIds = [
          action.payload.conversationId,
          ...state.openConversationsIds.filter(
            (c) => c !== action.payload.conversationId
          ),
        ];
      } else if (
        (conversationIsClosed || conversationIsHidden) &&
        !action.payload.byMe
      ) {
        openConversationsIds = [
          ...state.openConversationsIds.filter(
            (c) => c !== action.payload.conversationId
          ),
          action.payload.conversationId,
        ];
      } else {
        openConversationsIds = [...state.openConversationsIds];
      }

      return {
        ...state,
        openConversationsIds,
        conversationsById: {
          ...conversationsById,
          [action.payload.conversationId]: {
            ...conversationsById[action.payload.conversationId],
            fetchRecentHistoryCompleted: true,
          },
        },
        bigConversation:
          state.bigConversation === action.payload.conversationId ||
          !action.payload.byMe
            ? state.bigConversation
            : null,
        activeConversation: action.payload.byMe
          ? action.payload.conversationId
          : state.activeConversation,
      };
    }

    case MINIMIZE_CHAT_CONVERSATION:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            size: ChatEnums.ChatWindowSize.MIN,
            ...conversationHeaderErrors,
          },
        },
        bigConversation:
          state.bigConversation === action.conversationId
            ? null
            : state.bigConversation,
      };

    case NORMALIZE_CHAT_CONVERSATION:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            size: ChatEnums.ChatWindowSize.NORMAL,
            ...conversationHeaderErrors,
          },
        },
        bigConversation:
          state.bigConversation === action.conversationId
            ? null
            : state.bigConversation,
        activeConversation: action.conversationId,
      };

    case MAXIMIZE_CHAT_CONVERSATION:
      if (!action.conversationId) {
        return state;
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            size: ChatEnums.ChatWindowSize.BIG,
            ...conversationHeaderErrors,
          },
        },
        openConversationsIds: [
          action.conversationId,
          ...state.openConversationsIds.filter(
            (id) => id !== action.conversationId
          ),
        ],
        bigConversation: action.conversationId,
      };

    case CLOSE_CHAT_CONVERSATION: {
      let messagesAllIds = [
        ...state.conversationsById[action.conversationId].messagesAllIds,
      ];
      if (!state.conversationsById[action.conversationId].firstHistoryLoaded) {
        if (messagesAllIds.length > 1) {
          messagesAllIds = [messagesAllIds[0]];
        }
        if (messagesAllIds[0] && messagesAllIds[0].messages.length > 1) {
          messagesAllIds[0].messages = [messagesAllIds[0].messages[0]];
        }
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            size: ChatEnums.ChatWindowSize.NORMAL,
            ...conversationHeaderErrors,
            messagesAllIds,
          },
        },
        activeConversation:
          action.conversationId === state.activeConversation
            ? null
            : state.activeConversation,
        openConversationsIds: state.openConversationsIds.filter(
          (id) => id !== action.conversationId
        ),
        bigConversation:
          state.bigConversation === action.conversationId
            ? null
            : state.bigConversation,
      };
    }

    case FETCH_CONVERSATION_HISTORY_REQUEST:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.payload.conversationId]: {
            ...state.conversationsById[action.payload.conversationId],
            fetchHistoryLoaded: false,
            fetchHistoryError: false,
            fetchRecentHistoryLoaded: false,
            fetchOldHistoryLoaded: false,
            ...conversationHeaderErrors,
          },
        },
      };

    case FETCH_CONVERSATION_HISTORY_SUCCESS: {
      let { messagesAllIds } =
        state.conversationsById[action.data.conversationId];
      const { messagesById, deletedUsersById } = state;
      const foundMessages = action.data.messages;
      if (
        !state.conversationsById[action.data.conversationId]
          .firstHistoryLoaded &&
        !action.data.searchedMessage &&
        !action.data.afterMessage
      ) {
        if (
          !messagesAllIds ||
          !messagesAllIds.length ||
          (messagesAllIds.length === 1 &&
            messagesAllIds[0].messages.length === 1)
        ) {
          messagesAllIds = [];
        } else {
          // there was a new sent message while loading the history
          if (messagesAllIds.length > 1) {
            messagesAllIds = [messagesAllIds[0]];
          }
          messagesAllIds[0].messages = messagesAllIds[0].messages.filter(
            (messageId) =>
              !foundMessages.some(
                (message) => message.historyId === retrieveMessageId(messageId)
              )
          );
        }
      } else if (action.data.searchedMessage) {
        if (messagesAllIds.length > 1) {
          messagesAllIds = [messagesAllIds[0]];
        }
        if (messagesAllIds[0] && messagesAllIds[0].messages.length > 1) {
          messagesAllIds[0].messages = [messagesAllIds[0].messages[0]];
        }
      }
      if (
        (action.data.searchedMessage || action.data.afterMessage) &&
        action.data.messages.length < CHAT_HISTORY_CHUNK
      ) {
        // remove most recent message because it will be overwritten
        if (messagesAllIds[0]) {
          if (messagesAllIds[0].messages.length > 1) {
            messagesAllIds[0].messages.shift();
          } else {
            messagesAllIds.shift();
          }
        }
      }
      if (action.data.refresh || action.data.afterMessage) {
        foundMessages.reverse();
      }
      foundMessages.forEach((message) => {
        messagesById[message.historyId] = {
          id: message.historyId,
          conversationId: action.data.conversationId,
          type: message.type,
          senderId: message.sender.userId,
          senderFullname: generateSenderFullname(
            message.sender.name,
            message.sender.surname
          ),
          senderHistoryId: message.senderHistoryId,
          timestamp: message.timestamp,
          read: message.message
            ? message.sender.userId === state.myId
              ? message.message.interlocutorReadTime
              : message.message.readTime
            : null,
          delivery: message.message
            ? message.sender.userId === state.myId
              ? message.interlocutorDeliveryTime
              : message.deliveryTime
            : null,
          sent: true,
          errorSending: false,
          duration: message.message ? message.message.duration : null,
          text: message.message ? message.message.text : null,
          file: message.message ? message.message.file : null,
          info: message.info,
          forwarded: message.message ? message.message.forwarded : false,
          replyTo: message.message ? message.message.replyTo : null,
          isImportant: message.message ? message.message.important : false,
        };
        if (action.data.refresh) {
          if (
            messagesAllIds[0] &&
            messagesAllIds[0].day ===
              moment(message.timestamp).format(DAYMESSAGEFORMAT)
          ) {
            messagesAllIds[0].messages = [
              generateSenderMessageId(
                message.sender.userId,
                message.historyId,
                message.type === ChatEnums.ChatTopics.INFO
              ),
              ...messagesAllIds[0].messages,
            ];
          } else {
            messagesAllIds = [
              {
                day: moment(message.timestamp).format(DAYMESSAGEFORMAT),
                messages: [
                  generateSenderMessageId(
                    message.sender.userId,
                    message.historyId,
                    message.type === ChatEnums.ChatTopics.INFO
                  ),
                ],
              },
              ...messagesAllIds,
            ];
          }
        } else if (action.data.afterMessage) {
          if (
            messagesAllIds[0] &&
            messagesAllIds[0].day ===
              moment(message.timestamp).format(DAYMESSAGEFORMAT)
          ) {
            if (action.data.messages.length < CHAT_HISTORY_CHUNK) {
              messagesAllIds[0].messages = [
                generateSenderMessageId(
                  message.sender.userId,
                  message.historyId,
                  message.type === ChatEnums.ChatTopics.INFO
                ),
                ...messagesAllIds[0].messages,
              ];
            } else {
              messagesAllIds[0].messages = [
                messagesAllIds[0].messages[0],
                generateSenderMessageId(
                  message.sender.userId,
                  message.historyId,
                  message.type === ChatEnums.ChatTopics.INFO
                ),
                ...messagesAllIds[0].messages.slice(1),
              ];
            }
          } else if (
            messagesAllIds[1] &&
            messagesAllIds[1].day ===
              moment(message.timestamp).format(DAYMESSAGEFORMAT)
          ) {
            messagesAllIds[1].messages = [
              generateSenderMessageId(
                message.sender.userId,
                message.historyId,
                message.type === ChatEnums.ChatTopics.INFO
              ),
              ...messagesAllIds[1].messages,
            ];
          } else {
            messagesAllIds = [
              messagesAllIds[0],
              {
                day: moment(message.timestamp).format(DAYMESSAGEFORMAT),
                messages: [
                  generateSenderMessageId(
                    message.sender.userId,
                    message.historyId,
                    message.type === ChatEnums.ChatTopics.INFO
                  ),
                ],
              },
              ...messagesAllIds.slice(1),
            ];
          }
        } else if (
          messagesAllIds[messagesAllIds.length - 1] &&
          messagesAllIds[messagesAllIds.length - 1].day ===
            moment(message.timestamp).format(DAYMESSAGEFORMAT)
        ) {
          messagesAllIds[messagesAllIds.length - 1].messages = [
            ...messagesAllIds[messagesAllIds.length - 1].messages,
            generateSenderMessageId(
              message.sender.userId,
              message.historyId,
              message.type === ChatEnums.ChatTopics.INFO
            ),
          ];
        } else {
          messagesAllIds = [
            ...messagesAllIds,
            {
              day: moment(message.timestamp).format(DAYMESSAGEFORMAT),
              messages: [
                generateSenderMessageId(
                  message.sender.userId,
                  message.historyId,
                  message.type === ChatEnums.ChatTopics.INFO
                ),
              ],
            },
          ];
        }
        if (message.sender.deleted) {
          deletedUsersById[message.sender.userId] = {
            id: message.sender.userId,
            fullname: generateSenderFullname(
              message.sender.name,
              message.sender.surname
            ),
          };
        }
      });

      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.data.conversationId]: {
            ...state.conversationsById[action.data.conversationId],
            messagesAllIds: [...messagesAllIds],
            firstHistoryLoaded:
              !action.data.searchedMessage &&
              !action.data.afterMessage &&
              !action.data.beforeMessage
                ? true
                : !action.data.searchedMessage
                ? state.conversationsById[action.data.conversationId]
                    .firstHistoryLoaded
                : action.data.messages.length < CHAT_HISTORY_CHUNK,
            fetchHistoryLoaded: true,
            fetchHistoryCompleted:
              (action.data.searchedMessage || action.data.afterMessage) &&
              !action.data.beforeMessage
                ? false
                : action.data.messages.length < CHAT_HISTORY_CHUNK,
            fetchRecentHistoryCompleted:
              (action.data.searchedMessage || action.data.afterMessage) &&
              !action.data.beforeMessage
                ? action.data.messages.length < CHAT_HISTORY_CHUNK
                : state.conversationsById[action.data.conversationId]
                    .fetchRecentHistoryCompleted,
            scrollToBottom:
              !action.data.searchedMessage && !action.data.beforeMessage,
            fetchRecentHistoryLoaded: !!action.data.afterMessage,
            fetchOldHistoryLoaded: !!action.data.beforeMessage,
          },
        },
        messagesById: {
          ...messagesById,
        },
        searchedMessage: action.data.searchedMessage || null,
        deletedUsersById: { ...deletedUsersById },
      };
    }

    case FETCH_CONVERSATION_HISTORY_FAILURE:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            fetchHistoryLoaded: true,
            fetchHistoryError: true,
          },
        },
      };
    case SET_MAX_OPEN_CHAT_WINDOWS:
      return {
        ...state,
        maxOpenChatWindows: action.max,
      };
    case SET_CONVERSATIONS_LIST_FILTER:
      return {
        ...state,
        conversationsFilter: action.data.filter,
        textSearch: {
          loaded: false,
          error: false,
        },
      };
    case RECEIVE_COMPOSING_STATUS: {
      const conversationId = generateConversationId({
        userId: !action.payload.group ? action.payload.user.userId : null,
        groupId: action.payload.group ? action.payload.group.groupId : null,
      });
      const conversation = state.conversationsById[conversationId];
      if (!conversation) {
        return state;
      }
      if (conversation.composing.indexOf(action.payload.user.userId) < 0) {
        conversation.composing = [
          ...conversation.composing,
          action.payload.user.userId,
        ];
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...conversation,
          },
        },
      };
    }
    case RECEIVE_PAUSED_STATUS: {
      const conversationId = generateConversationId({
        userId: !action.payload.group ? action.payload.user.userId : null,
        groupId: action.payload.group ? action.payload.group.groupId : null,
      });
      const conversation = state.conversationsById[conversationId];
      if (!conversation) {
        return state;
      }
      let composing = [...conversation.composing];
      const index = conversation.composing.indexOf(action.payload.user.userId);
      if (index >= 0) {
        composing = [
          ...conversation.composing.slice(0, index),
          ...conversation.composing.slice(index + 1),
        ];
      }
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [conversationId]: {
            ...conversation,
            composing,
          },
        },
      };
    }
    case SET_ACTIVE_CHAT_WINDOW:
      return {
        ...state,
        activeConversation: action.conversationId,
      };
    case UNSET_ACTIVE_CHAT_WINDOW:
      return {
        ...state,
        activeConversation: state.activeConversation === null,
      };

    case FETCH_CONVERSATION_FILES_REQUEST:
      return {
        ...state,
        fetchFiles: {
          ...state.fetchFiles,
          currentPage: 0,
          total: 0,
          loaded: false,
          files: [],
        },
      };

    case FETCH_CONVERSATION_FILES_SUCCESS:
      return {
        ...state,
        fetchFiles: {
          ...state.fetchFiles,
          loaded: true,
          error: false,
          total: action.payload.total,
          files: action.payload.data,
        },
      };

    case FETCH_CONVERSATION_FILES_FAILURE:
      return {
        ...state,
        fetchFiles: {
          ...state.fetchFiles,
          loaded: true,
          error: true,
          total: 0,
          files: [],
        },
      };

    case CHANGE_CONVERSATION_FILES_PAGE_REQUEST:
      return {
        ...state,
        fetchFiles: {
          ...state.fetchFiles,
          currentPage: action.payload.pageNumber,
          loaded: false,
          error: null,
        },
      };

    case REFRESH_CONVERSATION_SUCCESS: {
      const { conversationId } = action.payload;
      const { conversationAllIds, conversationsById, messagesById } = state;
      if (!action.payload.data) {
        if (conversationAllIds.indexOf(conversationId) >= 0) {
          conversationAllIds.splice(
            conversationAllIds.indexOf(conversationId),
            1
          );
        }
      } else {
        let { messagesAllIds } = conversationsById[conversationId];
        if (messagesAllIds.length === 0) {
          messagesAllIds = [
            {
              day: moment(action.payload.data.lastHistory.timestamp).format(
                DAYMESSAGEFORMAT
              ),
              messages: [
                generateSenderMessageId(
                  action.payload.data.lastHistory.sender.userId,
                  action.payload.data.lastHistory['_id'],
                  action.payload.data.lastHistory.type ===
                    ChatEnums.ChatTopics.INFO
                ),
              ],
            },
          ];
          conversationsById[conversationId].messagesAllIds = messagesAllIds;
          messagesById[action.payload.data.lastHistory['_id']] = {
            id: action.payload.data.lastHistory['_id'],
            conversationId,
            senderId: action.payload.data.lastHistory.sender.userId,
            senderFullname: generateSenderFullname(
              action.payload.data.lastHistory.sender.name,
              action.payload.data.lastHistory.sender.surname
            ),
            timestamp: action.payload.data.lastHistory.timestamp,
            duration: action.payload.data.lastHistory.message
              ? action.payload.data.lastHistory.message.duration
              : null,
            text: action.payload.data.lastHistory.message
              ? action.payload.data.lastHistory.message.text
              : null,
            file: action.payload.data.lastHistory.message
              ? action.payload.data.lastHistory.message.file
              : null,
            info: action.payload.data.lastHistory.info,
            sent: true,
          };
        }
        conversationsById[conversationId].toBeRead =
          action.payload.data.toBeRead;
      }
      return {
        ...state,
        conversationAllIds: [...conversationAllIds],
        conversationsById: { ...conversationsById },
        messagesById: { ...messagesById },
      };
    }

    case DELETE_GROUP_REQUEST: {
      const conversationId = generateConversationId({
        groupId: action.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: true,
              ...conversationHeaderErrors,
            },
          },
        };
      }
      return state;
    }

    case DELETE_GROUP_SUCCESS: {
      const conversationId = generateConversationId({
        groupId: action.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: false,
            },
          },
        };
      }
      return state;
    }

    case DELETE_GROUP_FAILURE: {
      const conversationId = generateConversationId({
        groupId: action.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: false,
              deleteGroupError: true,
            },
          },
        };
      }
      return state;
    }

    case UPDATE_CHAT_GROUP_REQUEST: {
      const conversationId = generateConversationId({
        groupId: action.payload.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: true,
              ...conversationHeaderErrors,
            },
          },
        };
      }
      return state;
    }

    case UPDATE_CHAT_GROUP_SUCCESS: {
      const conversationId = generateConversationId({
        groupId: action.payload.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: false,
            },
          },
        };
      }
      return state;
    }

    case UPDATE_CHAT_GROUP_FAILURE: {
      const conversationId = generateConversationId({
        groupId: action.groupId,
      });
      if (state.conversationsById[conversationId]) {
        return {
          ...state,
          conversationsById: {
            ...state.conversationsById,
            [conversationId]: {
              ...state.conversationsById[conversationId],
              operationLoading: false,
              updateChatGroupError: true,
            },
          },
        };
      }
      return state;
    }

    case FETCH_CHAT_SEARCH_SUCCESS:
      return {
        ...state,
        textSearch: {
          loaded: true,
          error: false,
          otherLoaded: true,
          otherError: false,
          results: action.payload.data,
          completed: action.payload.data.length === action.payload.total,
        },
      };

    case FETCH_CHAT_SEARCH_FAILURE:
      return {
        ...state,
        textSearch: {
          ...state.textSearch,
          loaded: true,
          error: true,
          otherLoaded: true,
          otherError: false,
          results: [],
        },
      };

    case FETCH_OTHER_CHAT_RESULTS_REQUEST:
      return {
        ...state,
        textSearch: {
          ...state.textSearch,
          otherLoaded: false,
          otherError: false,
        },
      };

    case FETCH_OTHER_CHAT_RESULTS_SUCCESS:
      return {
        ...state,
        textSearch: {
          ...state.textSearch,
          otherLoaded: true,
          otherError: false,
          results: [...state.textSearch.results, ...action.payload.data],
          completed: action.payload.data.length === action.payload.total,
        },
      };

    case FETCH_OTHER_CHAT_RESULTS_FAILURE:
      return {
        ...state,
        textSearch: {
          ...state.textSearch,
          otherLoaded: true,
          otherError: true,
        },
      };

    case SET_CHAT_SEARCH_RESULT:
      return {
        ...state,
        searchedMessage: action.messageId,
      };

    case UNSET_CHAT_SEARCH_RESULT:
      return {
        ...state,
        searchedMessage: null,
      };

    case CREATE_CHAT_GROUP_REQUEST:
      return {
        ...state,
        createChatGroupLoaded: false,
        createChatGroupError: false,
      };
    case CREATE_CHAT_GROUP_SUCCESS: {
      const { conversationsById } = state;
      conversationsById[action.payload.conversationId] = {
        ...conversationState,
        id: action.payload.conversationId,
        size: ChatEnums.ChatWindowSize.NORMAL,
        fetchHistoryCompleted: true,
        ...conversationHeaderErrors,
      };
      if (state.bigConversation) {
        conversationsById[state.bigConversation] = {
          ...conversationsById[state.bigConversation],
          size: ChatEnums.ChatWindowSize.NORMAL,
          ...conversationHeaderErrors,
        };
      }
      return {
        ...state,
        createChatGroupLoaded: true,
        createChatGroupError: false,
        openConversationsIds: [
          action.payload.conversationId,
          ...state.openConversationsIds,
        ],
        conversationsById: {
          ...conversationsById,
        },
        bigConversation: null,
        activeConversation: action.payload.byMe
          ? action.payload.conversationId
          : state.activeConversation,
      };
    }
    case CREATE_CHAT_GROUP_FAILURE:
      return {
        ...state,
        createChatGroupLoaded: true,
        createChatGroupError: true,
      };

    case UPLOAD_FILES_SUCCESS: {
      if (action.payload.scope !== 'chat') return state;
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [action.payload.id]: {
            ...state.messagesById[action.payload.id],
            file: {
              ...state.messagesById[action.payload.id].file,
              stored: action.payload.data.stored,
            },
          },
        },
      };
    }
    case UPLOAD_FILES_FAILURE:
    case UPLOAD_FILES_CANCEL: {
      if (action.payload.scope !== 'chat') return state;
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [action.payload.id]: {
            ...state.messagesById[action.payload.id],
            file: {
              ...state.messagesById[action.payload.id].file,
              error: action.payload.error,
            },
          },
        },
      };
    }
    case FETCH_IMPORTANT_MESSAGES_REQUEST:
      return {
        ...state,
        importantMessagesLoading: true,
        importantMessagesError: null,
      };
    case FETCH_IMPORTANT_MESSAGES_SUCCESS:
      return {
        ...state,
        importantMessages: action.data,
        importantMessagesLoading: false,
        importantMessagesError: null,
      };
    case FETCH_IMPORTANT_MESSAGES_FAILURE:
      return {
        ...state,
        importantMessagesLoading: false,
        importantMessagesError: action.error,
      };
    case MARK_IMPORTANT_MESSAGE_REQUEST:
      return {
        ...state,
        isMarkAsImportantError: false,
      };
    case MARK_IMPORTANT_MESSAGE_SUCCESS:
    case MARK_IMPORTANT_MESSAGE_CC:
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [action.payload.historyId]: {
            ...state.messagesById[action.payload.historyId],
            isImportant: true,
          },
        },
        isMarkAsImportantError: false,
      };
    case MARK_IMPORTANT_MESSAGE_FAILURE:
      return {
        ...state,
        isMarkAsImportantError: true,
      };

    case MARK_UNIMPORTANT_MESSAGE_REQUEST:
      return {
        ...state,
        isUnmarkAsImportantError: false,
      };
    case MARK_UNIMPORTANT_MESSAGE_SUCCESS:
    case MARK_UNIMPORTANT_MESSAGE_CC:
      return {
        ...state,
        messagesById: {
          ...state.messagesById,
          [action.payload.historyId]: {
            ...state.messagesById[action.payload.historyId],
            isImportant: false,
          },
        },
        importantMessages: [
          ...state.importantMessages.filter(
            (msg) => msg.historyId !== action.payload.historyId
          ),
        ],
        isUnmarkAsImportantError: false,
      };
    case MARK_UNIMPORTANT_MESSAGE_FAILURE:
      return {
        ...state,
        isUnmarkAsImportantError: true,
      };
    case REPLY_MESSAGE_REQUEST:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.payload.conversationId]: {
            ...state.conversationsById[action.payload.conversationId],
            repliedMessageId: action.payload.messageId,
          },
        },
      };
    case REMOVE_REPLY_MESSAGE_REQUEST:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.payload.conversationId]: {
            ...state.conversationsById[action.payload.conversationId],
            repliedMessageId: null,
          },
        },
      };
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      if (newRoute[0] !== 'chat') {
        return {
          ...state,
          conversationsFilter: {
            text: '',
            users: true,
            groups: true,
            archived: false,
          },
        };
      }
      return state;
    }
    case FETCH_SCHEDULED_MESSAGES_REQUEST: {
      return {
        ...state,
        scheduledMessagesLoaded: false,
        scheduledMessagesError: null,
      };
    }
    case FETCH_SCHEDULED_MESSAGES_SUCCESS:
      return {
        ...state,
        scheduledMessagesLoaded: true,
        scheduledMessagesError: null,
        scheduledMessagesById: keyBy(action.payload, 'id'),
        scheduledMessagesAllIds: action.payload.map((obj) => obj.id),
      };
    case FETCH_SCHEDULED_MESSAGES_FAILURE:
      return {
        ...state,
        scheduledMessagesLoaded: true,
        scheduledMessagesError: true,
      };
    case SAVE_SCHEDULED_MESSAGE_REQUEST: {
      return {
        ...state,
        saveScheduledMessageLoaded: false,
        saveScheduledMessageError: null,
        saveScheduledMessageSuccess: false,
      };
    }
    case SAVE_SCHEDULED_MESSAGE_SUCCESS: {
      const newScheduledMessage = {
        ...state.scheduledMessagesById,
        [action.payload.id]: {
          ...action.payload,
        },
      };
      const orderedScheduledMessages = sortBy(
        newScheduledMessage,
        (o) => {
          return new Date(o.scheduled);
        },
        'asc'
      );
      const byIds = keyBy(orderedScheduledMessages, 'id');
      const allIds = orderedScheduledMessages.map((obj) => obj.id);
      return {
        ...state,
        saveScheduledMessageLoaded: true,
        saveScheduledMessageError: false,
        scheduledMessagesById: byIds,
        scheduledMessagesAllIds: allIds,
        saveScheduledMessageSuccess: true,
      };
    }
    case SAVE_SCHEDULED_MESSAGE_FAILURE:
      return {
        ...state,
        saveScheduledMessageLoaded: true,
        saveScheduledMessageError: true,
        saveScheduledMessageSuccess: false,
      };
    case DELETE_SCHEDULED_MESSAGE_REQUEST:
      return {
        ...state,
      };
    case DELETE_SCHEDULED_MESSAGE_SUCCESS: {
      const updatedScheduledMessages = {
        ...state.scheduledMessagesById,
      };
      delete updatedScheduledMessages[action.id];
      const updatedScheduledMessagesAllIds = [...state.scheduledMessagesAllIds];
      pull(updatedScheduledMessagesAllIds, action.id);
      return {
        ...state,
        scheduledMessagesById: updatedScheduledMessages,
        scheduledMessagesAllIds: updatedScheduledMessagesAllIds,
      };
    }
    case DELETE_SCHEDULED_MESSAGE_FAILURE:
      return {
        ...state,
        deleteScheduledMessageError: true,
      };
    case GET_MUTED_CHATS_REQUEST:
      return {
        ...state,
        mutedChatsLoaded: false,
        mutedChatsError: false,
      };
    case GET_MUTED_CHATS_SUCCESS: {
      return {
        ...state,
        mutedChatsLoaded: true,
        mutedChatsError: false,
        mutedChats: keyBy(
          action.data.map((el) => ({
            conversationId: generateConversationId({
              groupId: el.idGroup,
              userId: el.idUser,
            }),
            ...el,
          })),
          'conversationId'
        ),
      };
    }
    case GET_MUTED_CHATS_FAILURE:
      return {
        ...state,
        mutedChatsLoaded: true,
        mutedChatsError: action.error,
      };
    case MUTE_UNMUTE_CHAT_REQUEST:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            muteUnmuteChatLoaded: false,
            muteUnmuteChatError: false,
          },
        },
      };
    case MUTE_UNMUTE_CHAT_SUCCESS: {
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            muteUnmuteChatLoaded: true,
            muteUnmuteChatError: false,
          },
        },
      };
    }
    case MUTE_UNMUTE_CHAT_FAILURE:
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            muteUnmuteChatLoaded: true,
            muteUnmuteChatError: action.error,
          },
        },
      };
    case CLEAR_CHAT_ERROR: {
      return {
        ...state,
        conversationsById: {
          ...state.conversationsById,
          [action.conversationId]: {
            ...state.conversationsById[action.conversationId],
            muteUnmuteChatLoaded: true,
            muteUnmuteChatError: false,
          },
        },
      };
    }
    case LOGOUT_SUCCESS:
      return { ...initialState, openConversationsIds: [] };
    default:
      return state;
  }
}
