import { createSelector } from 'reselect';
import { ExternalUrlUtils } from './ExternalUrlUtils';

const defaultObject = {};

export const getPhoneExternalUrlFromId = (state, id) =>
  state.externalUrl.externalUrlsById[id] || defaultObject;
export const getPhoneExternalUrlById = (state) =>
  state.externalUrl.externalUrlsById;
export const getPhoneExternalUrlIds = (state) =>
  state.externalUrl.externalUrlsAllIds;
export const getPhoneExternalUrls = createSelector(
  [getPhoneExternalUrlById, getPhoneExternalUrlIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getPhoneExternalUrlsIncoming = (state) =>
  getPhoneExternalUrls(state).filter(
    (item) => item.event === ExternalUrlUtils.Event.INCOMING
  );
export const getPhoneExternalUrlsAnswered = (state) =>
  getPhoneExternalUrls(state).filter(
    (item) => item.event === ExternalUrlUtils.Event.ANSWERED
  );
export const getPhoneExternalUrlsHangup = (state) =>
  getPhoneExternalUrls(state).filter(
    (item) => item.event === ExternalUrlUtils.Event.HANGUP
  );
export const getPhoneExternalUrlsAlways = (state) =>
  getPhoneExternalUrls(state).filter(
    (item) => item.event === ExternalUrlUtils.Event.ALWAYS
  );
export const getInsertPhoneExternalUrlLoaded = (state) =>
  state.externalUrl.insertExternalUrlLoaded;
export const getInsertPhoneExternalUrlError = (state) =>
  state.externalUrl.insertExternalUrlError;
export const getPhoneExternalUrlLoaded = (state) =>
  state.externalUrl.fetchExternalUrlLoaded;
export const getPhoneExternalUrlError = (state) =>
  state.externalUrl.fetchExternalUrlError;
export const getLoadedDeletePhoneExternalUrl = (state) =>
  state.externalUrl.loadedDeleteExternalUrl;
export const getErrorDeletePhoneExternalUrl = (state) =>
  state.externalUrl.errorDeleteExternalUrl;
