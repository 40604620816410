import { request } from '../request';

export default {
  getStickyNotes: () =>
    request({
      url: '/sticky/notes',
      method: 'GET',
    }),
  addStickyNote: (params) =>
    request({
      url: '/sticky/notes',
      method: 'POST',
      data: params,
    }),
  deleteSticky: (id) =>
    request({
      url: `/sticky/notes/${id}`,
      method: 'DELETE',
    }),
  updateStickyVisibility: (show) =>
    request({
      url: '/sticky/visibility',
      method: 'POST',
      data: {
        show,
      },
    }),
  updateSticky: (id, sticky) =>
    request({
      url: `/sticky/notes/${id}`,
      method: 'POST',
      data: sticky,
    }),
  shareSticky: (id, users) =>
    request({
      url: `/sticky/notes/${id}/share`,
      method: 'POST',
      data: users,
    }),
  /* Static Notes */
  getStaticNotes: () =>
    request({
      url: '/notes/static',
      method: 'GET',
    }),
  createStaticNote: (params) =>
    request({
      url: '/notes/static',
      method: 'POST',
      data: params,
    }),
  updateStaticNotesOrder: (id, data) =>
    request({
      url: `/notes/static/${id}/reorder`,
      method: 'POST',
      data,
    }),
  editStaticNotes: (params) =>
    request({
      url: `/notes/static/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  removeStaticNote: (id) =>
    request({
      url: `/notes/static/${id}`,
      method: 'DELETE',
    }),
};
