import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UsersSelect from '../../../components/formControls/UsersSelect';
import Label from '../../../components/formControls/Label';
import Button from '../../../components/formControls/Button';
import {
  getShareStickyError,
  getShareStickyLoaded,
} from '../../../js/sticky/selectors';
import ToastMessage from '../../../components/messages/ToastMessage';
import { getMeId } from '../../../js/me/selectors';

const messages = defineMessages({
  modalHeader: {
    id: 'ShareStickyModal.title.modalHeader',
    defaultMessage: 'Share sticky',
  },
  users: {
    id: 'ShareCalendar.label.users',
    defaultMessage: 'Users',
  },
  save: {
    id: 'ShareCalendar.label.save',
    defaultMessage: 'Save',
  },
  close: {
    id: 'ShareCalendar.label.close',
    defaultMessage: 'Close',
  },
  shareStickyError: {
    id: 'ShareCalendar.shareStickyError',
    defaultMessage: 'Sorry, there has been a server error.',
  },
});

const ShareStickyModal = ({
  isOpen,
  onClose,
  onSave,
  myId,
  shareStickyLoading,
  shareStickyError,
  sharingUsers,
  intl: { formatMessage },
}) => {
  const [selectedUsers, setSelectedUsers] = useState(undefined);

  const handleUsersSelection = (selected) => {
    setSelectedUsers(selected);
  };

  const arrangeSelectedUsers = () =>
    selectedUsers !== undefined ? selectedUsers : sharingUsers || [];

  const save = () => {
    onSave(selectedUsers);
  };

  const excludedUsers = [myId];

  return (
    <Modal
      id="sticky-modal"
      size="md"
      backdrop="static"
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader toggle={onClose}>
        {formatMessage(messages.modalHeader)}
      </ModalHeader>
      <ModalBody>
        {shareStickyError && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.shareStickyError)}
            closeTimeout={5000}
          />
        )}
        <div className="col-12 my-2">
          <Label for="users">{formatMessage(messages.users)}</Label>
          <UsersSelect
            id="users"
            isClearable
            isMulti
            onSelect={handleUsersSelection}
            selected={arrangeSelectedUsers()}
            excluded={excludedUsers}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          text={formatMessage(messages.save)}
          onClick={save}
          loading={shareStickyLoading}
        />
        <Button
          className="btn-outline-primary"
          onClick={onClose}
          text={formatMessage(messages.close)}
        />
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    shareStickyLoading: !getShareStickyLoaded(state),
    shareStickyError: getShareStickyError(state),
    myId: getMeId(state),
  };
}

ShareStickyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default injectIntl(connect(mapStateToProps)(ShareStickyModal));
