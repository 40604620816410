import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../../components/formControls/Button';
import ToastMessage from '../../../components/messages/ToastMessage';
import {
  getActiveSelectedFolderId,
  getFileboxActiveActualPath,
  getFileboxActiveFilter,
  getFileboxItemById,
  getMoveFileLoaded,
  getMoveFolderLoaded,
  getFileboxActiveItemsLoaded,
  getFileboxActiveItemsError,
  getCopyFolderLoaded,
  getCopyFileLoaded,
  getFileboxActiveOrder,
  getActiveOrderedFoldersIds,
  getActiveOrderedFilesIds,
} from '../../../js/filebox/selectors';
import { getMeId } from '../../../js/me/selectors';
import { getMainGroupId } from '../../../js/groups/selectors';
import Icon from '../../../components/icons/Icon';
import {
  clearFileboxErrors,
  fetchFileboxActiveItemsRequest,
  setFileboxActiveFilter,
  setFileboxActiveOrder,
} from '../../../js/filebox/actions';
import Loading from '../../../components/Loading';
import {
  FileboxEnums,
  retrieveFileIcon,
} from '../../../js/filebox/FileboxUtils';
import {
  getImportFileToFileboxError,
  getImportFileToFileboxLoaded,
} from '../../../js/files/selectors';
import { getUserById } from '../../../js/users/selectors';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';

const messages = defineMessages({
  home: {
    id: 'FileboxTreeModal.home',
    defaultMessage: 'Home',
  },
  moveModalHeader: {
    id: 'FileboxTreeModal.title.moveModalHeader',
    defaultMessage: 'Move file',
  },
  copyModalHeader: {
    id: 'FileboxTreeModal.title.copyModalHeader',
    defaultMessage: 'Copy file',
  },
  copyFolderModalHeader: {
    id: 'FileboxTreeModal.title.copyFolderModalHeader',
    defaultMessage: 'Copy folder',
  },
  moveFolderModalHeader: {
    id: 'FileboxTreeModal.title.moveFolderModalHeader',
    defaultMessage: 'Move folder',
  },
  move: {
    id: 'FileboxTreeModal.move',
    defaultMessage: 'Move',
  },
  copy: {
    id: 'FileboxTreeModal.copy',
    defaultMessage: 'Copy',
  },
  fetchItemsError: {
    id: 'FileboxTreeModal.fetchItemsError',
    defaultMessage: 'Sorry, there was a server error.',
  },
  cancelButton: {
    id: 'FileboxTreeModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  name: {
    id: 'FileboxTreeModal.name',
    defaultMessage: 'Name',
  },
  noRow: {
    id: 'FileboxTreeModal.noRow',
    defaultMessage: 'There are no files or folders',
  },
  upload: {
    id: 'FileboxTreeModal.upload',
    defaultMessage: 'Upload',
  },
  uploadToFileboxHeader: {
    id: 'FileboxTreeModal.title.uploadToFileboxHeader',
    defaultMessage: 'Upload to filebox file',
  },
  uploadFileToFileboxError: {
    id: 'FileboxTreeModal.uploadFileToFileboxError',
    defaultMessage: "Sorry, the file hasn't uploaded due to an error.",
  },
  sortByName: {
    id: 'FileboxTreeModal.sortByName',
    defaultMessage: 'Sort by name',
  },
  public: {
    id: 'FileboxTreeModal.public',
    defaultMessage: 'Public',
  },
  reserved: {
    id: 'FileboxTreeModal.reserved',
    defaultMessage: 'Reserved',
  },
  shared: {
    id: 'FileboxTreeModal.tooltip.shared',
    defaultMessage: 'Shared by {creator}',
  },
  select: {
    id: 'FileboxTreeModal.select',
    defaultMessage: 'Select',
  },
  EXISTING_FILENAME: {
    id: 'FileboxTreeModal.EXISTING_FILENAME',
    defaultMessage: 'Existing file',
  },
});

class FileboxTreeModal extends Component {
  componentDidMount() {
    const { fetchItems, isOpen, savingContext } = this.props;
    if (!isOpen) return;
    fetchItems({ context: savingContext });
  }

  componentDidUpdate(prevprops) {
    const {
      isOpen,
      filter,
      fetchItems,
      loading,
      serverError,
      setFilter,
      toggle,
      clearErrors,
      savingContext,
    } = this.props;
    if (!isOpen) return;

    if (isOpen && !prevprops.isOpen) {
      setFilter({ parent: null, context: savingContext });
      clearErrors();
      fetchItems({ context: savingContext });
    }
    if (isOpen && filter.parent !== prevprops.filter.parent) {
      fetchItems({ ...filter, context: savingContext });
    }
    if (prevprops.loading && !loading && !serverError) {
      toggle();
    }
  }

  handlePathClick = (id) => {
    const { folderDetails, setFilter, savingContext } = this.props;

    if (folderDetails.id === id) return;
    setFilter({ parent: id, context: savingContext });
  };

  changeOrder = (orderType) => {
    const { order, setActiveOrder } = this.props;
    const data = {
      type: orderType,
      direction:
        order.type !== orderType
          ? FileboxEnums.orderDirection.ASC
          : order.direction === FileboxEnums.orderDirection.ASC
          ? FileboxEnums.orderDirection.DESC
          : FileboxEnums.orderDirection.ASC,
    };

    setActiveOrder(data);
  };

  arrangeIsPublic = (sharing) => {
    const { mainGroupId } = this.props;
    return (
      sharing &&
      sharing.length === 1 &&
      sharing[0].idGroup &&
      sharing[0].idGroup === mainGroupId
    );
  };

  render() {
    const {
      filter,
      isOpen,
      toggle,
      onAction,
      loading,
      serverError,
      folders,
      files,
      fileboxItemById,
      path,
      folderDetails,
      action,
      folder,
      setFilter,
      savingContext,
      movingFile,
      loadingItems,
      serverErrorItems,
      context,
      data,
      name,
      itemId,
      order,
      userById,
      myId,
      onlyPrivate,
      intl: { formatMessage },
    } = this.props;

    const actionData = context
      ? { ...data, parent: folderDetails.id, path }
      : folderDetails.id;

    if (!isOpen) return null;

    return (
      <Modal size="md" isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span
            className="text-truncate d-inline-block"
            style={{ maxWidth: '400px' }}
          >
            {action === 'copyFile'
              ? formatMessage(messages.copyModalHeader)
              : action === 'moveFile'
              ? formatMessage(messages.moveModalHeader)
              : action === 'moveFolder'
              ? formatMessage(messages.moveFolderModalHeader)
              : action === 'copyFolder'
              ? formatMessage(messages.copyFolderModalHeader)
              : formatMessage(messages.uploadToFileboxHeader)}
            {' : '}
            {name || movingFile.name}
          </span>
        </ModalHeader>
        <ModalBody>
          {serverError && (
            <div>
              <ToastMessage
                type="danger"
                text={
                  messages[serverError]
                    ? formatMessage(messages[serverError])
                    : formatMessage(messages.uploadFileToFileboxError)
                }
                closeTimeout={5000}
              />
            </div>
          )}
          {serverErrorItems && (
            <div>
              <ToastMessage
                type="danger"
                text={formatMessage(messages.fetchItemsError)}
                closeTimeout={5000}
              />
            </div>
          )}
          <>
            {path &&
              path.map((id, idx) => (
                <span key={idx}>
                  {idx > 0 && idx < path.length && (
                    <span style={{ verticalAlign: 'bottom' }}>{'>'}</span>
                  )}
                  <span
                    className={`mx-1 p-0 ${
                      folderDetails.id !== id
                        ? 'btn btn-link font-weight-bold'
                        : ''
                    }`}
                    onClick={() => this.handlePathClick(id)}
                    style={{ verticalAlign: 'middle' }}
                  >
                    {id ? folder(id).name : formatMessage(messages.home)}
                  </span>
                </span>
              ))}
            <div className="rounded border mt-1 pt-2 pb-0 h-100">
              <table className="table table-borderless table-hover yn-table">
                <thead>
                  <tr>
                    <th />
                    <th>
                      {formatMessage(messages.name)}
                      <span
                        onClick={() =>
                          this.changeOrder(FileboxEnums.orderType.NAME)
                        }
                      >
                        <Icon
                          name={
                            order.type === FileboxEnums.orderType.NAME &&
                            order.direction === FileboxEnums.orderDirection.ASC
                              ? 'arrow-down-10-filled'
                              : 'arrow-up-10-filled'
                          }
                          width={10}
                          height={10}
                          style={{ top: '1px', left: '2px' }}
                          clickable
                        >
                          {formatMessage(messages.sortByName)}
                        </Icon>
                      </span>
                    </th>
                    <th />
                  </tr>
                </thead>
                {loadingItems && (
                  <tbody
                    style={{
                      height: '50vh',
                    }}
                  >
                    <tr
                      style={{
                        display: 'flex',
                        height: '100%',
                        background: 'white',
                      }}
                    >
                      <td colSpan="3" style={{ flex: 1 }}>
                        <Loading />
                      </td>
                    </tr>
                  </tbody>
                )}
                {(folders.length > 0 || files.length > 0) && !loadingItems && (
                  <tbody
                    style={{
                      overflowY: 'scroll',
                      height: '50vh',
                    }}
                  >
                    {folders.length > 0 &&
                      folders.map((id) =>
                        !onlyPrivate || !fileboxItemById(id).isShared ? (
                          <tr key={id}>
                            <td className="pl-2">
                              <span
                                onClick={() =>
                                  setFilter({
                                    parent: id,
                                    context: savingContext,
                                  })
                                }
                              >
                                <Icon
                                  name="folder-1"
                                  color="var(--yn-blue-dark)"
                                  width={24}
                                  height={24}
                                  clickable
                                  style={{ top: '1px' }}
                                />
                              </span>
                            </td>
                            <td>
                              <span
                                className="d-inline-block text-truncate"
                                style={{ cursor: 'pointer', maxWidth: '300px' }}
                                onClick={() =>
                                  setFilter({
                                    parent: id,
                                    context: savingContext,
                                  })
                                }
                              >
                                {fileboxItemById(id).name}
                              </span>
                            </td>
                            <td>
                              {this.arrangeIsPublic(
                                fileboxItemById(id).sharing
                              ) ? (
                                <ToolboxIcon
                                  name="network-share"
                                  clickable={false}
                                >
                                  {formatMessage(messages.public)}
                                </ToolboxIcon>
                              ) : fileboxItemById(id).isShared ? (
                                <ToolboxIcon name="share" clickable={false}>
                                  {formatMessage(messages.shared, {
                                    creator:
                                      folder.creator === myId
                                        ? formatMessage(messages.you)
                                        : userById(folder.creator)
                                            .departmentFullname,
                                  })}
                                </ToolboxIcon>
                              ) : (
                                <ToolboxIcon
                                  name="account-circle-1"
                                  clickable={false}
                                >
                                  {formatMessage(messages.reserved)}
                                </ToolboxIcon>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    {(!onlyPrivate || filter.parent) &&
                      files.length > 0 &&
                      files.map((id) => (
                        <tr key={id}>
                          <td className="pl-2">
                            <Icon
                              name={
                                retrieveFileIcon(fileboxItemById(id).mime).name
                              }
                              color="var(--yn-blue-dark)"
                              width={24}
                              height={24}
                              clickable={false}
                              style={{ top: '1px' }}
                            />
                          </td>
                          <td>
                            <span
                              className="d-inline-block text-truncate"
                              style={{ maxWidth: '300px' }}
                            >
                              {fileboxItemById(id).name}
                            </span>
                          </td>
                          <td />
                        </tr>
                      ))}
                  </tbody>
                )}
                {files.length === 0 && folders.length === 0 && (
                  <tfoot
                    style={{
                      overflowY: 'scroll',
                      height: '50vh',
                    }}
                  >
                    <tr>
                      <td colSpan="2">{formatMessage(messages.noRow)}</td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            text={
              action === 'copyFile' || action === 'copyFolder'
                ? formatMessage(messages.copy)
                : action === 'moveFile' || action === 'moveFolder'
                ? formatMessage(messages.move)
                : action === 'selectFolder'
                ? formatMessage(messages.select)
                : formatMessage(messages.upload)
            }
            onClick={() => onAction(actionData)}
            loading={loading}
            disabled={
              (action !== 'selectFolder' &&
                !context &&
                ((!folderDetails.id && !movingFile.parent) ||
                  folderDetails.id === movingFile.parent)) ||
              (itemId && path.indexOf(itemId) > -1) ||
              (onlyPrivate && !filter.parent)
            }
          />

          <Button
            className="btn-outline-primary"
            onClick={toggle}
            text={formatMessage(messages.cancelButton)}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

FileboxTreeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  name: PropTypes.string,
  context: PropTypes.string,
  savingContext: PropTypes.string.isRequired,
  data: PropTypes.object,
  itemId: PropTypes.number,
  action: PropTypes.oneOf([
    'copyFile',
    'moveFile',
    'copyFolder',
    'moveFolder',
    'import',
    'selectFolder',
  ]).isRequired,
  onlyPrivate: PropTypes.bool,
};

FileboxTreeModal.defaultProps = {
  name: null,
  itemId: null,
  context: '',
  data: null,
  onlyPrivate: false,
};

function mapStateToProps(state, ownProps) {
  return {
    loading:
      !getMoveFileLoaded(state) ||
      !getMoveFolderLoaded(state) ||
      !getCopyFolderLoaded(state) ||
      !getCopyFileLoaded(state) ||
      !getImportFileToFileboxLoaded(state),
    serverError: getImportFileToFileboxError(state),
    loadingItems: !getFileboxActiveItemsLoaded(state),
    serverErrorItems: getFileboxActiveItemsError(state),
    folders: getActiveOrderedFoldersIds(state),
    files: getActiveOrderedFilesIds(state),
    fileboxItemById: (id) => getFileboxItemById(state, id),
    path: getFileboxActiveActualPath(state, ownProps.savingContext),
    folderDetails:
      getActiveSelectedFolderId(state) === 0
        ? {}
        : getFileboxItemById(state, getActiveSelectedFolderId(state)),
    folder: (id) => getFileboxItemById(state, id),
    filter: getFileboxActiveFilter(state, ownProps.savingContext),
    movingFile: getFileboxItemById(state, ownProps.itemId),
    order: getFileboxActiveOrder(state),
    userById: (id) => getUserById(state, id),
    myId: getMeId(state),
    mainGroupId: getMainGroupId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchItems: fetchFileboxActiveItemsRequest,
    setFilter: setFileboxActiveFilter,
    setActiveOrder: setFileboxActiveOrder,
    clearErrors: clearFileboxErrors,
  })(FileboxTreeModal)
);
