import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import { NavItem, NavLink, Tooltip } from 'reactstrap';
import { injectIntl } from 'react-intl';
import NotificationBadge from './NotificationBadge';

class SecondaryNavItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      offsetWidth: null,
      scrollWidth: null,
    };
    this.targetRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.targetRef.current &&
      this.targetRef.current.offsetWidth &&
      this.targetRef.current.offsetWidth !== prevState.offsetWidth
    ) {
      this.setState({ offsetWidth: this.targetRef.current.offsetWidth });
    }
    if (
      this.targetRef.current &&
      this.targetRef.current.scrollWidth &&
      this.targetRef.current.scrollWidth !== prevState.scrollWidth
    ) {
      this.setState({ scrollWidth: this.targetRef.current.scrollWidth });
    }
  }

  render() {
    const { tab, width } = this.props;

    const tabId = `tab_${tab.id.replace(/\./g, '_')}`;

    return (
      <NavItem
        id={tabId}
        className={tab.notifications ? 'position-relative' : ''}
      >
        <NavLink
          activeClassName="active"
          tag={Link}
          to={tab.href}
          disabled={tab.disabled}
        >
          <span
            className="text-truncate d-inline-block"
            ref={this.targetRef}
            style={{
              maxWidth: width,
            }}
          >
            {tab.label}
          </span>
        </NavLink>
        {tab.notifications && tab.notifications > 0 ? (
          <NotificationBadge
            number={tab.notifications}
            top="0.3rem"
            right="0.15rem"
          />
        ) : (
          <span />
        )}
        {this.state.offsetWidth < this.state.scrollWidth && (
          <Tooltip
            isOpen={this.state.tooltipOpen}
            toggle={() => {
              this.setState({ tooltipOpen: !this.state.tooltipOpen });
            }}
            target={tabId}
          >
            {tab.label}
          </Tooltip>
        )}
      </NavItem>
    );
  }
}

SecondaryNavItem.propTypes = {
  tab: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default injectIntl(SecondaryNavItem);
