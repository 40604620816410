import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Fade } from 'reactstrap';
import history from '../../../history';
import Button from '../../../components/formControls/Button';
import PropTypes from 'prop-types';
import {
  answerVideocallRequest,
  declineVideocallRequest,
} from '../../../js/videocalls/actions';
import UserAvatar from '../../../components/UserAvatar';
import { getUsersById } from '../../../js/users/selectors';
import {
  ALERTING_SOUND,
  BUSY_SOUND,
  RING_SOUND,
  VIDEOCALL_NO_ANSWER_HANGUP_TIMEOUT,
} from '../../../js/videocalls/VideocallsUtils';
import { NotificationSettings } from '../../../js/notifications/NotificationUtils';
import { getCurrentVideocallRoom } from '../../../js/videocalls/selectors';
import { getMeActiveCallId, getMeId } from '../../../js/me/selectors';

const messages = defineMessages({
  titleOutgoing: {
    id: 'CallToolbar.titleOutgoing',
    defaultMessage: 'Outgoing Videocall',
  },
  titleIncoming: {
    id: 'CallToolbar.titleIncoming',
    defaultMessage: 'Incoming Videocall',
  },
  answer: {
    id: 'CallToolbar.answer',
    defaultMessage: 'Answer',
  },
  hangup: {
    id: 'CallToolbar.hangup',
    defaultMessage: 'Hang up',
  },
});

class VideocallPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio: null,
    };
  }

  componentDidMount() {
    if (this.props.currentActivity) {
      return;
    }
    const audio = new Audio(this.props.incoming ? RING_SOUND : ALERTING_SOUND);
    audio.volume =
      (this.props.platformVolume === null ||
      this.props.platformVolume === undefined
        ? NotificationSettings.DEFAULT_VOLUME
        : this.props.platformVolume) / 100;
    audio.loop = true;
    audio.play();
    this.setState({ audio });

    if (this.props.incoming) {
      this.callTimeout = setTimeout(() => {
        if (this.state.audio) {
          this.state.audio.pause();
        }
      }, VIDEOCALL_NO_ANSWER_HANGUP_TIMEOUT);
    }
  }

  componentDidUpdate() {
    if (this.props.currentActivity) {
      if (this.state.audio) {
        this.state.audio.pause();
      }
      clearTimeout(this.callTimeout);
    }
    if (
      !this.props.incoming &&
      this.props.videocall.busy &&
      !this.callTimeout
    ) {
      if (this.state.audio) {
        this.state.audio.pause();
      }
      const audio = new Audio(BUSY_SOUND);
      audio.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audio.loop = true;
      audio.play();
      this.setState({ audio });

      this.callTimeout = setTimeout(() => {
        if (this.state.audio) {
          this.state.audio.pause();
        }
        this.props.declineVideocall({
          ...this.props.videocall,
          caller: this.props.incoming
            ? this.props.videocall.user
            : this.props.myId,
        });
      }, 3000);
    }
  }

  componentWillUnmount() {
    if (this.state.audio) {
      this.state.audio.pause();
    }
    clearTimeout(this.callTimeout);
  }

  answer = () => {
    const { videocall, answerVideocall } = this.props;
    answerVideocall(videocall);
    history.push('/videocall/exec');
  };

  hangup = () => {
    const { videocall, declineVideocall, incoming, myId } = this.props;
    if (!videocall) {
      return;
    }
    declineVideocall({
      ...videocall,
      caller: incoming ? videocall.user : myId,
    });
  };

  render() {
    const {
      videocall,
      incoming,
      users,
      intl: { formatMessage },
    } = this.props;

    const interlocutor = users[videocall.user];

    return (
      <div id="VideocallPanel">
        <Fade className="card mt-2">
          <div className="card-header text-left small p-1">
            <div className="row">
              <div className="col-7">
                {formatMessage(
                  incoming ? messages.titleIncoming : messages.titleOutgoing
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-1 text-left small p-1">
            <div className="media">
              {interlocutor && (
                <UserAvatar
                  src={interlocutor.avatar}
                  alt={interlocutor.departmentFullname}
                  size="md"
                  className="mr-2"
                  id={interlocutor.id}
                />
              )}
              <div
                className="media-body"
                style={{
                  width: '83%',
                }}
              >
                {interlocutor && interlocutor.departmentFullname && (
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <b>{interlocutor.departmentFullname}</b>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-1">
            <div className="btn-group btn-block">
              {incoming && (
                <Button
                  text={formatMessage(messages.answer)}
                  className="btn-success"
                  style={{ width: '50%' }}
                  onClick={this.answer}
                />
              )}
              <Button
                text={formatMessage(messages.hangup)}
                className="btn-danger"
                style={{ width: '50%' }}
                onClick={this.hangup}
              />
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myId: getMeId(state),
    users: getUsersById(state),
    currentActivity: getCurrentVideocallRoom(state) || getMeActiveCallId(state),
  };
}

VideocallPanel.propTypes = {
  videocall: PropTypes.object.isRequired,
  incoming: PropTypes.bool.isRequired,
};

export default injectIntl(
  connect(mapStateToProps, {
    answerVideocall: answerVideocallRequest,
    declineVideocall: declineVideocallRequest,
  })(VideocallPanel)
);
