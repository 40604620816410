import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { getWebNotificationsMailofficeNumber } from '../../../js/notifications/selectors';

const messages = defineMessages({
  title: {
    id: 'Mailoffice.title',
    defaultMessage: 'Postal services',
  },
  send: {
    id: 'Mailoffice.send.navLabel',
    defaultMessage: 'Send',
  },
  archive: {
    id: 'Mailoffice.archive.navLabel',
    defaultMessage: 'Archive',
  },
  notifications: {
    id: 'Mailoffice.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
});

const Mailoffice = ({
  intl: { formatMessage },
  notificationsQuantity,
  children,
  scrolling,
}) => {
  const nav = [
    /*{
      id: 'Mailoffice.send.linkLabel',
      href: '/mailoffice/sent/edit',
      label: messages.send,
    },*/
    {
      id: 'Mailoffice.archive.linkLabel',
      href: '/mailoffice/sent',
      label: messages.archive,
    },
    {
      id: 'Mailoffice.notifications.linkLabel',
      href: '/mailoffice/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    },
  ];
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsMailofficeNumber(state),
  };
}
export default injectIntl(connect(mapStateToProps)(Mailoffice));
