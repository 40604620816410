import {
  select,
  call,
  put,
  all,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { push } from 'connected-react-router';
import moment from 'moment';
import history from '../../history';
import {
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENT_REQUEST,
  SAVE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_REQUEST,
  SAVE_CALENDAR_SETTINGS_REQUEST,
  FETCH_CALENDAR_SYNCOUT_REQUEST,
  SAVE_CALENDAR_SYNCOUT_REQUEST,
  DELETE_CALENDAR_SYNCOUT_REQUEST,
  ENABLE_DISABLE_SYNCOUT_REQUEST,
  IMPORT_CALENDAR_EVENTS_REQUEST,
  CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST,
  CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_SYNCIN_REQUEST,
  SAVE_CALENDAR_SYNCIN_REQUEST,
  DELETE_CALENDAR_SYNCIN_REQUEST,
  SAVE_CALENDAR_SYNCIN_SUCCESS,
  DELETE_CALENDAR_SYNCIN_SUCCESS,
  FETCH_CALENDAR_SLOTS_REQUEST,
  CALENDAR_REMINDER_NOTIFICATION,
  FETCH_CALENDAR_EVENT_CONTACTS_REQUEST,
  TOGGLE_CALENDAR_SYNCIN_REQUEST,
  TOGGLE_CALENDAR_SYNCIN_SUCCESS,
  FETCH_VACATION_PLAN_REQUEST,
  APPROVE_VACATION_REQUEST,
  REJECT_VACATION_REQUEST,
  APPROVE_DELETION_VACATION_REQUEST,
  REJECT_DELETION_VACATION_REQUEST,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  FETCH_VACATION_RESUME_REQUEST,
  FETCH_TASK_PLAN_REQUEST,
  FETCH_APPOINTMENT_RESULTS_REQUEST,
  DELETE_APPOINTMENT_RESULT_REQUEST,
  INSERT_APPOINTMENT_RESULT_REQUEST,
  EDIT_APPOINTMENT_RESULT_REQUEST,
  FETCH_APPOINTMENT_TYPES_REQUEST,
  DELETE_APPOINTMENT_TYPE_REQUEST,
  INSERT_APPOINTMENT_TYPE_REQUEST,
  EDIT_APPOINTMENT_TYPE_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST,
  FETCH_APPOINTMENTS_REQUEST,
  SAVE_CALENDAR_APPOINTMENT_REQUEST,
  FETCH_CALENDAR_APPOINTMENT_REQUEST,
  DELETE_CALENDAR_APPOINTMENT_REQUEST,
  REGISTER_CALENDAR_APPOINTMENT_REQUEST,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST,
  FETCH_LINKED_CALENDAR_EVENTS_REQUEST,
  FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST,
  FETCH_APPOINTMENTS_MAP_REQUEST,
} from './types';
import { INIT_MAIN_SUCCESS } from '../me/types';
import {
  fetchCalendarSettingsSuccess,
  fetchCalendarSettingsFailure,
  fetchCalendarEventsSuccess,
  fetchCalendarEventsFailure,
  fetchCalendarEventSuccess,
  fetchCalendarEventFailure,
  saveCalendarEventSuccess,
  saveCalendarEventFailure,
  deleteCalendarEventSuccess,
  deleteCalendarEventFailure,
  saveCalendarSettingsSuccess,
  saveCalendarSettingsFailure,
  importCalendarEventsSuccess,
  importCalendarEventsFailure,
  cancelImportCalendarEventsSuccess,
  cancelImportCalendarEventsFailure,
  confirmImportCalendarEventsSuccess,
  confirmImportCalendarEventsFailure,
  fetchCalendarSyncoutSuccess,
  fetchCalendarSyncoutFailure,
  saveCalendarSyncoutSuccess,
  saveCalendarSyncoutFailure,
  deleteCalendarSyncoutSuccess,
  deleteCalendarSyncoutFailure,
  enableDisableCalendarSyncoutSuccess,
  enableDisableCalendarSyncoutFailure,
  fetchCalendarSyncinSuccess,
  fetchCalendarSyncinFailure,
  saveCalendarSyncinSuccess,
  saveCalendarSyncinFailure,
  deleteCalendarSyncinSuccess,
  deleteCalendarSyncinFailure,
  fetchCalendarSlotsSuccess,
  fetchCalendarSlotsFailure,
  fetchCalendarEventContactsSuccess,
  fetchCalendarEventContactsFailure,
  toggleCalendarSyncinSuccess,
  toggleCalendarSyncinFailure,
  fetchVacationPlanSuccess,
  fetchVacationPlanFailure,
  approveVacationSuccess,
  approveVacationFailure,
  rejectVacationSuccess,
  rejectVacationFailure,
  approveDeletionVacationSuccess,
  approveDeletionVacationFailure,
  rejectDeletionVacationSuccess,
  rejectDeletionVacationFailure,
  fetchCalendarSyncinRequest,
  fetchCalendarListOfEmailSuggestionsSuccess,
  fetchCalendarListOfEmailSuggestionsFailure,
  fetchCalendarFestivitiesSettingsSuccess,
  fetchCalendarFestivitiesSettingsFailure,
  saveCalendarFestivitiesSettingsSuccess,
  saveCalendarFestivitiesSettingsFailure,
  fetchCalendarFestivitiesSettingsRequest,
  fetchVacationResumeSuccess,
  fetchVacationResumeFailure,
  fetchTaskPlanSuccess,
  fetchTaskPlanFailure,
  fetchAppointmentResultsSuccess,
  fetchAppointmentResultsFailure,
  deleteAppointmentResultSuccess,
  fetchAppointmentResultsRequest,
  deleteAppointmentResultFailure,
  insertAppointmentResultSuccess,
  insertAppointmentResultFailure,
  editAppointmentResultSuccess,
  editAppointmentResultFailure,
  fetchAppointmentTypesSuccess,
  fetchAppointmentTypesFailure,
  deleteAppointmentTypeSuccess,
  fetchAppointmentTypesRequest,
  deleteAppointmentTypeFailure,
  insertAppointmentTypeSuccess,
  insertAppointmentTypeFailure,
  editAppointmentTypeSuccess,
  editAppointmentTypeFailure,
  fetchAppointmentDashboardHeaderSuccess,
  fetchAppointmentDashboardHeaderFailure,
  fetchAppointmentDashboardGraphStatisticsSuccess,
  fetchAppointmentDashboardGraphStatisticsFailure,
  fetchAppointmentDashboardUsersStatisticsSuccess,
  fetchAppointmentDashboardUsersStatisticsFailure,
  fetchAppointmentsListShortcutSuccess,
  fetchAppointmentsListShortcutFailure,
  fetchAppointmentsSuccess,
  fetchAppointmentsFailure,
  saveCalendarAppointmentSuccess,
  saveCalendarAppointmentFailure,
  fetchCalendarAppointmentSuccess,
  fetchCalendarAppointmentFailure,
  deleteCalendarAppointmentSuccess,
  deleteCalendarAppointmentFailure,
  registerCalendarAppointmentSuccess,
  registerCalendarAppointmentFailure,
  fetchLinkedCalendarAppointmentsSuccess,
  fetchLinkedCalendarAppointmentsFailure,
  fetchLinkedCalendarEventsSuccess,
  fetchLinkedCalendarEventsFailure,
  fetchAppointmentsForExcelSuccess,
  fetchAppointmentsForExcelFailure,
  fetchAppointmentsMapSuccess,
  fetchAppointmentsMapFailure,
} from './actions';
import api from '../api';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import { getDatetimeFormat } from '../settings/selectors';
import {
  fetchAbookContactSuccess,
  fetchAbookContactFailure,
} from '../contacts/actions';
import { checkApiResponse, checkApiError } from '../rootSaga';
import {
  getCalendarFilter,
  isVacationEnabled,
  getVacationPlanParams,
  showSyncinCalendars,
} from './selectors';
import { getMeId } from '../me/selectors';
import { CalendarEnums } from './CalendarUtils';
import {
  getVacationsFilter,
  getCurrentPage,
} from '../presence/selectorsVacations';
import { fetchVacationsToValidateRequest } from '../presence/actions';
import { VACATIONS_VALIDATION_PAGE_SIZE } from '../presence/PresenceUtils';
import Utils from '../lib/utils';

const intlStrings = defineMessages({
  calendarReminder: {
    id: 'calendarReminder',
    defaultMessage: 'Event "{title}" in date {day}',
  },
});

export function* fetchCalendarSettings() {
  try {
    const meId = yield select(getMeId);
    const vacationEnabled = yield select(isVacationEnabled);
    const res = yield call(api.calendar.getSettings);
    yield call(checkApiResponse, res);
    const settings = res.data;
    if (res.data.onlyMine) {
      settings.participants = [meId];
    } else {
      settings.participants = [];
    }
    settings.types = [];
    if (settings.events) settings.types.push(CalendarEnums.CalendarTypes.EVENT);
    if (vacationEnabled && settings.vacations)
      settings.types.push(CalendarEnums.CalendarTypes.VACATION);
    if (settings.appointments)
      settings.types.push(CalendarEnums.CalendarTypes.APPOINTMENT);
    yield put(fetchCalendarSettingsSuccess(settings));
    yield put(fetchCalendarSyncinRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarSettingsFailure(error));
  }
}

export function* fetchCalendarEvents(action) {
  try {
    const fetchSyncin = yield select(showSyncinCalendars);
    const { params } = action;
    if (!fetchSyncin) {
      params.sync = [];
    }
    if (params.types.length === 0) {
      yield put(
        fetchCalendarEventsSuccess({
          data: [],
          total: 0,
          page: params.page,
        })
      );
    } else {
      const res = yield call(api.calendar.getEvents, params);
      yield call(checkApiResponse, res);
      if (res.status === 204) {
        yield put(
          fetchCalendarEventsSuccess({
            data: [],
            total: 0,
            page: params.page,
          })
        );
      } else {
        yield put(
          fetchCalendarEventsSuccess({
            data: res.data,
            total: res.data.length,
            page: params.page,
          })
        );
      }
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarEventsFailure(error));
  }
}
export function* fetchCalendarEvent(action) {
  try {
    const res = yield call(api.calendar.getEvent, action.eventId);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarEventSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        fetchCalendarEventFailure({
          eventId: action.eventId,
          errors: error,
        })
      );
  }
}
export function* saveCalendarEvent(action) {
  try {
    const res = yield call(
      action.event.id ? api.calendar.updateEvent : api.calendar.createEvent,
      action.event
    );
    yield call(checkApiResponse, res);
    yield put(saveCalendarEventSuccess(action.event));
    if (!action.event.addPhoneRule) yield put(push('/calendar/show'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarEventFailure(error));
  }
}

export function* deleteCalendarEvent(action) {
  try {
    const id = action.event.key.split('_')[0];
    let res;
    if (action.event.occurrence) {
      const date = moment(
        new Date(+action.event.key.split('_')[1] * 1000)
      ).format('YYYY-MM-DD');
      res = yield call(api.calendar.deleteOccurrence, { id, date });
    } else {
      res = yield call(api.calendar.deleteEvent, id);
    }
    yield call(checkApiResponse, res);
    yield put(deleteCalendarEventSuccess(action.event));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteCalendarEventFailure(error));
  }
}

export function* saveCalendarSettings(action) {
  try {
    const res = yield call(api.calendar.updateSettings, action.settings);
    yield call(checkApiResponse, res);
    const meId = yield select(getMeId);
    const vacationEnabled = yield select(isVacationEnabled);
    const { settings } = action;
    if (action.settings.onlyMine) {
      settings.participants = [meId];
    } else {
      settings.participants = [];
    }
    settings.types = [];
    if (settings.events) settings.types.push(CalendarEnums.CalendarTypes.EVENT);
    if (settings.appointments)
      settings.types.push(CalendarEnums.CalendarTypes.APPOINTMENT);
    if (vacationEnabled && settings.vacations)
      settings.types.push(CalendarEnums.CalendarTypes.VACATION);
    yield put(saveCalendarSettingsSuccess(settings));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarSettingsFailure(error));
  }
}
export function* importCalendarEvents(action) {
  try {
    const res = yield call(api.calendar.importCalendarEvents, action.file);

    yield call(checkApiResponse, res);
    yield put(importCalendarEventsSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(importCalendarEventsFailure(error));
  }
}
export function* confirmImportCalendarEvents(action) {
  try {
    const res = yield call(api.calendar.importCalendarEvents, action.data);

    yield call(checkApiResponse, res);
    yield put(confirmImportCalendarEventsSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(confirmImportCalendarEventsFailure(error));
  }
}
export function* cancelImportCalendarEvents() {
  try {
    const res = yield call(api.calendar.cancelImportCalendarEvents);

    yield call(checkApiResponse, res);
    yield put(cancelImportCalendarEventsSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(cancelImportCalendarEventsFailure(error));
  }
}

export function* fetchCalendarSync(action) {
  try {
    const res = yield call(api.calendar.fetchCalendarSync, action.data);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarSyncoutSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarSyncoutFailure(error));
  }
}
export function* saveCalendarSync(action) {
  try {
    const res = yield call(api.calendar.saveCalendarSync, action.data);
    yield call(checkApiResponse, res);
    yield put(saveCalendarSyncoutSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarSyncoutFailure(error));
  }
}
export function* deleteCalendarSync(action) {
  try {
    const res = yield call(api.calendar.deleteCalendarSync, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteCalendarSyncoutSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteCalendarSyncoutFailure(error));
  }
}

export function* enableCalendarSync(action) {
  try {
    const res = yield call(api.calendar.enableCalendarSync, action.status);
    yield call(checkApiResponse, res);
    yield put(enableDisableCalendarSyncoutSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(enableDisableCalendarSyncoutFailure(error));
  }
}
export function* fetchCalendarSyncin(action) {
  try {
    const res = yield call(api.calendar.fetchCalendarSyncin, action.data);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarSyncinSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarSyncinFailure(error));
  }
}
export function* saveCalendarSyncin(action) {
  try {
    const res = yield call(api.calendar.saveCalendarSyncin, action.data);
    yield call(checkApiResponse, res);
    yield put(saveCalendarSyncinSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarSyncinFailure(error));
  }
}

export function* deleteCalendarSyncin(action) {
  try {
    const res = yield call(api.calendar.deleteCalendarSyncin, action.data);
    yield call(checkApiResponse, res);
    yield put(deleteCalendarSyncinSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteCalendarSyncinFailure(error));
  }
}
export function* toggleCalendarSyncin(action) {
  try {
    const res = yield call(api.calendar.toggleCalendarSyncin, action.payload);
    yield call(checkApiResponse, res);
    yield put(toggleCalendarSyncinSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        toggleCalendarSyncinFailure({ error, syncId: action.payload.syncId })
      );
  }
}
export function* manageToggleCalendarSyncin() {
  const calendarFilter = yield select(getCalendarFilter);
  yield call(fetchCalendarEvents, { params: calendarFilter });
}

export function* fetchCalendarSlots(action) {
  try {
    const res = yield call(api.calendar.fetchCalendarSlots, action.params);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarSlotsSuccess(res));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarSlotsFailure(error));
  }
}

export function* fetchVacationPlan(action) {
  try {
    const res = yield call(api.calendar.fetchVacationPlan, {
      start: moment(action.params.start).format('YYYYMMDD'),
      end: moment(action.params.end).format('YYYYMMDD'),
      users: action.params.users,
    });
    yield call(checkApiResponse, res);
    yield put(fetchVacationPlanSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVacationPlanFailure(error));
  }
}

export function* sendReminder(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (
    !action.payload.archived ||
    moment(action.payload.end).isSameOrAfter(moment())
  ) {
    const datetimeFormat = yield select(getDatetimeFormat);
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.CALENDAR_REMINDER}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.calendarReminder, {
        title: action.payload.title,
        day: moment(action.payload.start).format(datetimeFormat),
      }),
      group: NotificationEnums.NotificationGroups.CALENDAR_REMINDER,
      image: NotificationEnums.NotificationImages.CALENDAR_REMINDER,
      onclick: () => {
        history.push('/calendar/show');
      },
    });
  }
}

export function* fetchCalendarEventContacts(action) {
  try {
    const response = yield all(
      action.contacts.map((id) => call(api.contacts.getContact, id))
    );
    for (let i = 0; i < response.length; i += 1) {
      if (response[i].status < 200 || response[i].status >= 400) {
        yield put(fetchAbookContactFailure(response[i].status));
      } else {
        yield put(fetchAbookContactSuccess(response[i].data));
      }
    }
    yield put(fetchCalendarEventContactsSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarEventContactsFailure());
  }
}

function* refetchValidations() {
  const filter = yield select(getVacationsFilter);
  const page = yield select(getCurrentPage);
  yield put(
    fetchVacationsToValidateRequest({
      page,
      pageSize: VACATIONS_VALIDATION_PAGE_SIZE,
      name: filter.name,
    })
  );
}

export function* confirmVacation(action) {
  try {
    const res = yield call(api.calendar.confirmVacation, action.params);
    yield call(checkApiResponse, res);
    yield put(approveVacationSuccess(action.id));
    if (action.params.refetch === 'validations') {
      yield call(refetchValidations);
    } else if (action.params.refetch === 'calendar') {
      const params = yield select(getVacationPlanParams);
      yield call(fetchVacationPlan, { params });
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(approveVacationFailure(error));
  }
}

export function* rejectVacation(action) {
  try {
    const res = yield call(api.calendar.rejectVacation, action.params);
    yield call(checkApiResponse, res);
    yield put(rejectVacationSuccess(action.params.id));
    if (action.params.refetch === 'validations') {
      yield call(refetchValidations);
    } else if (action.params.refetch === 'calendar') {
      const params = yield select(getVacationPlanParams);
      yield call(fetchVacationPlan, { params });
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(rejectVacationFailure(error));
  }
}

export function* confirmDeletionVacation(action) {
  try {
    const res = yield call(api.calendar.confirmDeletingVacation, action.params);
    yield call(checkApiResponse, res);
    yield put(approveDeletionVacationSuccess(action.id));
    if (action.params.refetch === 'validations') {
      yield call(refetchValidations);
    } else if (action.params.refetch === 'calendar') {
      const params = yield select(getVacationPlanParams);
      yield call(fetchVacationPlan, { params });
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(approveDeletionVacationFailure(error));
  }
}

export function* rejectDeletionVacation(action) {
  try {
    const res = yield call(api.calendar.rejectDeletingVacation, action.params);
    yield call(checkApiResponse, res);
    yield put(rejectDeletionVacationSuccess(action.params.id));
    if (action.params.refetch === 'validations') {
      yield call(refetchValidations);
    } else if (action.params.refetch === 'calendar') {
      const params = yield select(getVacationPlanParams);
      yield call(fetchVacationPlan, { params });
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(rejectDeletionVacationFailure(error));
  }
}

export function* fetchCalendarEmailsSuggestions() {
  try {
    const res = yield call(api.calendar.getListOfEmails);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchCalendarListOfEmailSuggestionsSuccess({ data: [] }));
    } else {
      yield put(
        fetchCalendarListOfEmailSuggestionsSuccess({
          data: res.data,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarListOfEmailSuggestionsFailure(error));
  }
}

export function* fetchCalendarFestivitiesSettings() {
  try {
    const res = yield call(api.calendar.getFestivitiesSettings);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarFestivitiesSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCalendarFestivitiesSettingsFailure(error));
  }
}

export function* saveCalendarFestivitiesSettings(action) {
  try {
    const res = yield call(
      api.calendar.updateFestivitiesSettings,
      action.settings
    );
    yield call(checkApiResponse, res);
    yield put(saveCalendarFestivitiesSettingsSuccess());
    yield put(fetchCalendarFestivitiesSettingsRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarFestivitiesSettingsFailure(error));
  }
}

export function* fetchVacationResume(action) {
  try {
    const res = yield call(api.calendar.getVacationResume, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchVacationResumeSuccess(res.data.vacations));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchVacationResumeFailure(error));
  }
}

export function* fetchTaskPlan(action) {
  try {
    const resVacation = yield call(api.calendar.fetchVacationPlan, {
      start: moment(action.params.start).format('YYYYMMDD'),
      end: moment(action.params.end).format('YYYYMMDD'),
      users: action.params.users,
    });
    yield call(checkApiResponse, resVacation);
    const resEvent = yield call(api.calendar.getEvents, {
      start: moment(action.params.start).format('YYYYMMDD'),
      end: moment(action.params.end).format('YYYYMMDD'),
      participants: action.params.users,
      types: ['EVENT'],
      extractSync: action.params.extractSync,
      taskSearch: true,
    });
    yield call(checkApiResponse, resEvent);
    const resAppointment = yield call(api.calendar.getAppointments, {
      start: moment(action.params.start).format('YYYYMMDDTHHmmss'),
      end: moment(action.params.end).format('YYYYMMDDTHHmmss'),
      participants: action.params.users,
      taskSearch: true,
    });
    yield call(checkApiResponse, resAppointment);
    yield put(
      fetchTaskPlanSuccess({
        events: resEvent.data,
        vacations: resVacation.data,
        appointments: resAppointment.data.data,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchTaskPlanFailure(error));
  }
}

export function* fetchAppointmentResults() {
  try {
    const res = yield call(api.calendar.getAppointmentResults);
    yield call(checkApiResponse, res);

    yield put(fetchAppointmentResultsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentResultsFailure(error));
  }
}

export function* deleteAppointmentResult(action) {
  try {
    const res = yield call(api.calendar.deleteAppointmentResult, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteAppointmentResultSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentResultsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteAppointmentResultFailure(error));
  }
}

export function* insertAppointmentResult(action) {
  try {
    const res = yield call(
      api.calendar.insertAppointmentResult,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(insertAppointmentResultSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentResultsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertAppointmentResultFailure(error));
  }
}

export function* editAppointmentResult(action) {
  try {
    const res = yield call(api.calendar.editAppointmentResult, action.payload);
    yield call(checkApiResponse, res);
    yield put(editAppointmentResultSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentResultsRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editAppointmentResultFailure(error));
  }
}

export function* fetchAppointmentTypes() {
  try {
    const res = yield call(api.calendar.getAppointmentTypes);
    yield call(checkApiResponse, res);

    yield put(fetchAppointmentTypesSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentTypesFailure(error));
  }
}

export function* deleteAppointmentType(action) {
  try {
    const res = yield call(api.calendar.deleteAppointmentType, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteAppointmentTypeSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentTypesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteAppointmentTypeFailure(error));
  }
}

export function* insertAppointmentType(action) {
  try {
    const res = yield call(api.calendar.insertAppointmentType, action.payload);
    yield call(checkApiResponse, res);
    yield put(insertAppointmentTypeSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentTypesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertAppointmentTypeFailure(error));
  }
}

export function* editAppointmentType(action) {
  try {
    const res = yield call(api.calendar.editAppointmentType, action.payload);
    yield call(checkApiResponse, res);
    yield put(editAppointmentTypeSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchAppointmentTypesRequest());
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editAppointmentTypeFailure(error));
  }
}

export function* fetchDashboardHeader(action) {
  try {
    const res = yield call(
      api.calendar.getDashboardHeader,
      action.appointmentType
    );
    yield call(checkApiResponse, res);
    yield put(fetchAppointmentDashboardHeaderSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentDashboardHeaderFailure(error));
  }
}

export function* getDashboardGraphStatistics(action) {
  try {
    const res = yield call(
      api.calendar.getDashboardGraphStatistics,
      action.params
    );
    yield call(checkApiResponse, res);

    yield put(fetchAppointmentDashboardGraphStatisticsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(fetchAppointmentDashboardGraphStatisticsFailure(error));
  }
}

export function* getDashboardUsersStatistics(action) {
  try {
    const res = yield call(
      api.calendar.getDashboardUsersStatistics,
      action.appointmentType
    );
    yield call(checkApiResponse, res);

    yield put(fetchAppointmentDashboardUsersStatisticsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(fetchAppointmentDashboardUsersStatisticsFailure(error));
  }
}

export function* getAppointmentsListShortcut() {
  try {
    const res = {}; //yield call(api.calendar.getAppointmentsListShortcut);
    yield call(checkApiResponse, res);

    yield put(fetchAppointmentsListShortcutSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentsListShortcutFailure(error));
  }
}

export function* fetchAppointments(action) {
  try {
    const res = yield call(api.calendar.getAppointments, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchAppointmentsSuccess({
        data:
          res.status === 200
            ? res.data.data.map((item) => ({
                ...item,
                type: CalendarEnums.CalendarTypes.APPOINTMENT,
                id: `A${item.id}`,
              }))
            : [],
        page: action.params.page ? action.params.page : 0,
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentsFailure(error));
  }
}

export function* fetchAppointmentsMap(action) {
  try {
    const res = yield call(api.calendar.getAppointments, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchAppointmentsMapSuccess({
        data:
          res.status === 200
            ? res.data.data.map((item) => ({
                ...item,
                type: CalendarEnums.CalendarTypes.APPOINTMENT,
                id: `A${item.id}`,
              }))
            : [],
        page: action.params.page ? action.params.page : 0,
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentsMapFailure(error));
  }
}

export function* saveCalendarAppointment(action) {
  try {
    const res = yield call(
      action.appointment.id
        ? api.calendar.updateAppointment
        : api.calendar.createAppointment,
      action.appointment
    );
    yield call(checkApiResponse, res);
    yield put(saveCalendarAppointmentSuccess(action.appointment));
    yield put(push('/calendar/show'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveCalendarAppointmentFailure(error));
  }
}

export function* fetchCalendarAppointment(action) {
  try {
    const res = yield call(api.calendar.getAppointment, action.appointmentId);
    yield call(checkApiResponse, res);
    yield put(fetchCalendarAppointmentSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        fetchCalendarAppointmentFailure({
          eventId: action.appointmentId,
          errors: error,
        })
      );
  }
}

export function* deleteCalendarAppointment(action) {
  try {
    const res = yield call(api.calendar.deleteAppointment, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteCalendarAppointmentSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteCalendarAppointmentFailure(error));
  }
}

export function* registerAppointment(action) {
  try {
    const res = yield call(
      api.calendar.registerAppointment,
      action.payload.id,
      action.payload.data
    );
    yield call(checkApiResponse, res);
    yield put(registerCalendarAppointmentSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(registerCalendarAppointmentFailure(error));
  }
}

export function* fetchLinkedAppointments(action) {
  try {
    const appointments = [];
    for (let i = 0; i < action.appointmentIds.length; i += 1) {
      const res = yield call(
        api.calendar.getAppointment,
        action.appointmentIds[i]
      );
      yield call(checkApiResponse, res);
      appointments.push(res.data);
    }
    yield put(fetchLinkedCalendarAppointmentsSuccess(appointments));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchLinkedCalendarAppointmentsFailure(error));
  }
}

export function* fetchLinkedEvents(action) {
  try {
    const appointments = [];
    for (let i = 0; i < action.eventIds.length; i += 1) {
      const res = yield call(api.calendar.getEvent, action.eventIds[i]);
      yield call(checkApiResponse, res);
      appointments.push(res.data);
    }
    yield put(fetchLinkedCalendarEventsSuccess(appointments));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchLinkedCalendarEventsFailure(error));
  }
}

export function* fetchAppointmentsForExcel(action) {
  try {
    const res = yield call(api.calendar.getAppointments, action.params);
    yield call(checkApiResponse, res);
    yield put(
      fetchAppointmentsForExcelSuccess({
        data: res.status === 200 ? res.data.data : [],
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchAppointmentsForExcelFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, fetchCalendarSettings);
  yield takeEvery(CALENDAR_REMINDER_NOTIFICATION, sendReminder);
  yield takeLatest(FETCH_CALENDAR_EVENTS_REQUEST, fetchCalendarEvents);
  yield takeLatest(FETCH_CALENDAR_EVENT_REQUEST, fetchCalendarEvent);
  yield takeLatest(SAVE_CALENDAR_EVENT_REQUEST, saveCalendarEvent);
  yield takeLatest(DELETE_CALENDAR_EVENT_REQUEST, deleteCalendarEvent);
  yield takeLatest(SAVE_CALENDAR_SETTINGS_REQUEST, saveCalendarSettings);
  yield takeLatest(FETCH_CALENDAR_SYNCOUT_REQUEST, fetchCalendarSync);
  yield takeLatest(SAVE_CALENDAR_SYNCOUT_REQUEST, saveCalendarSync);
  yield takeLatest(DELETE_CALENDAR_SYNCOUT_REQUEST, deleteCalendarSync);
  yield takeLatest(ENABLE_DISABLE_SYNCOUT_REQUEST, enableCalendarSync);
  yield takeLatest(IMPORT_CALENDAR_EVENTS_REQUEST, importCalendarEvents);
  yield takeLatest(
    CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST,
    confirmImportCalendarEvents
  );
  yield takeLatest(
    CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST,
    cancelImportCalendarEvents
  );
  yield takeLatest(FETCH_CALENDAR_SYNCIN_REQUEST, fetchCalendarSyncin);
  yield takeLatest(SAVE_CALENDAR_SYNCIN_REQUEST, saveCalendarSyncin);
  yield takeLatest(DELETE_CALENDAR_SYNCIN_REQUEST, deleteCalendarSyncin);
  yield takeLatest(SAVE_CALENDAR_SYNCIN_SUCCESS, fetchCalendarSyncin);
  yield takeLatest(DELETE_CALENDAR_SYNCIN_SUCCESS, fetchCalendarSyncin);
  yield takeLatest(TOGGLE_CALENDAR_SYNCIN_REQUEST, toggleCalendarSyncin);
  yield takeLatest(TOGGLE_CALENDAR_SYNCIN_SUCCESS, manageToggleCalendarSyncin);
  yield takeLatest(FETCH_CALENDAR_SLOTS_REQUEST, fetchCalendarSlots);
  yield takeLatest(FETCH_VACATION_PLAN_REQUEST, fetchVacationPlan);
  yield takeLatest(
    FETCH_CALENDAR_EVENT_CONTACTS_REQUEST,
    fetchCalendarEventContacts
  );
  yield takeLatest(APPROVE_VACATION_REQUEST, confirmVacation);
  yield takeLatest(REJECT_VACATION_REQUEST, rejectVacation);
  yield takeLatest(APPROVE_DELETION_VACATION_REQUEST, confirmDeletionVacation);
  yield takeLatest(REJECT_DELETION_VACATION_REQUEST, rejectDeletionVacation);
  yield takeLatest(
    FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST,
    fetchCalendarEmailsSuggestions
  );
  yield takeLatest(
    FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
    fetchCalendarFestivitiesSettings
  );
  yield takeLatest(INIT_MAIN_SUCCESS, fetchCalendarFestivitiesSettings);
  yield takeLatest(
    SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
    saveCalendarFestivitiesSettings
  );
  yield takeLatest(FETCH_VACATION_RESUME_REQUEST, fetchVacationResume);
  yield takeLatest(FETCH_TASK_PLAN_REQUEST, fetchTaskPlan);
  yield takeLatest(FETCH_APPOINTMENT_RESULTS_REQUEST, fetchAppointmentResults);
  yield takeLatest(DELETE_APPOINTMENT_RESULT_REQUEST, deleteAppointmentResult);
  yield takeLatest(INSERT_APPOINTMENT_RESULT_REQUEST, insertAppointmentResult);
  yield takeLatest(EDIT_APPOINTMENT_RESULT_REQUEST, editAppointmentResult);
  yield takeLatest(FETCH_APPOINTMENT_TYPES_REQUEST, fetchAppointmentTypes);
  yield takeLatest(DELETE_APPOINTMENT_TYPE_REQUEST, deleteAppointmentType);
  yield takeLatest(INSERT_APPOINTMENT_TYPE_REQUEST, insertAppointmentType);
  yield takeLatest(EDIT_APPOINTMENT_TYPE_REQUEST, editAppointmentType);
  yield takeLatest(
    FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST,
    fetchDashboardHeader
  );
  yield takeLatest(
    FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST,
    getDashboardGraphStatistics
  );
  yield takeLatest(
    FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST,
    getDashboardUsersStatistics
  );
  yield takeLatest(
    FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST,
    getAppointmentsListShortcut
  );
  yield takeLatest(FETCH_APPOINTMENTS_REQUEST, fetchAppointments);
  yield takeLatest(FETCH_APPOINTMENTS_MAP_REQUEST, fetchAppointmentsMap);
  yield takeLatest(SAVE_CALENDAR_APPOINTMENT_REQUEST, saveCalendarAppointment);
  yield takeLatest(
    FETCH_CALENDAR_APPOINTMENT_REQUEST,
    fetchCalendarAppointment
  );
  yield takeLatest(
    DELETE_CALENDAR_APPOINTMENT_REQUEST,
    deleteCalendarAppointment
  );
  yield takeLatest(REGISTER_CALENDAR_APPOINTMENT_REQUEST, registerAppointment);
  yield takeLatest(
    FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST,
    fetchLinkedAppointments
  );
  yield takeLatest(FETCH_LINKED_CALENDAR_EVENTS_REQUEST, fetchLinkedEvents);
  yield takeLatest(
    FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST,
    fetchAppointmentsForExcel
  );
}
