import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { getYnPageWidth } from '../js/ui/selectors';

const Fullname = ({ uniqueId, fullname, pageWidth }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const maxChars =
    pageWidth < 1000 ? 15 : pageWidth < 1250 ? 20 : pageWidth < 1600 ? 30 : 50;

  return (
    <>
      <span id={`Fullname${uniqueId}`}>
        {fullname.length < maxChars
          ? fullname
          : `${fullname.substr(0, maxChars - 3)}...`}
      </span>
      {fullname.length >= maxChars && uniqueId && (
        <Tooltip
          placement="auto"
          isOpen={tooltipOpen}
          target={`Fullname${uniqueId}`}
          toggle={toggle}
        >
          {fullname}
        </Tooltip>
      )}
    </>
  );
};

Fullname.propTypes = {
  uniqueId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  fullname: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    pageWidth: getYnPageWidth(state),
  };
}

export default connect(mapStateToProps)(Fullname);
