import React from 'react';
import { connect } from 'react-redux';

import StickyNote from './StickyNote';
import { getStickyNotes } from '../../../js/sticky/selectors';

class StickyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { stickyNotes, visibility } = this.props;
    return (
      <React.Fragment>
        {visibility && (
          <div>
            {stickyNotes.map((sticky) => (
              <StickyNote stickyNote={sticky} key={sticky.id} />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    stickyNotes: getStickyNotes(state),
    visibility: state.settings.stickyShow,
  };
}

export default connect(mapStateToProps)(StickyList);
