import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Alert extends Component {
  render() {
    const { text, type, style } = this.props;
    return (
      <div className={`alert alert-${type}`} style={style}>
        {text}
      </div>
    );
  }
}

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  style: PropTypes.object,
};

Alert.defaultProps = {
  type: 'danger',
  style: null,
};

export default Alert;
