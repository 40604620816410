import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { Fade } from 'reactstrap';
import { getGroupsById, getFavoritesGroupId } from '../js/groups/selectors';
import {
  getFetchUserLoaded,
  getFetchUserError,
  getUserById,
  isUserOnlineWeb,
  isUserOnlineApp,
} from '../js/users/selectors';
import ChatButton from './buttons/ChatButton';
import FavoriteButton from './buttons/FavoriteButton';
import ExtensionPhoneButton from './buttons/ExtensionPhoneButton';
import Label from './formControls/Label';
import GroupLabel from './GroupLabel';
import PhoneLabel from './PhoneLabel';
import { AVATAR_TYPES } from '../js/files/FileUtils';
import { PuncherEnums } from '../js/presence/PresenceUtils';
import { hasGrants, isSMSenabled } from '../js/me/selectors';
import UserAvatar from './UserAvatar';
import Popover from './Popover';
import VideoButton from './buttons/VideoButton';

const messages = defineMessages({
  extension: {
    id: 'UserAvatar.label.extension',
    defaultMessage: 'Extension ',
  },
  email: {
    id: 'UserAvatar.label.email',
    defaultMessage: 'Email: ',
  },
  notes: {
    id: 'UserAvatar.label.notes',
    defaultMessage: 'Additional notes: ',
  },
  groups: {
    id: 'UserAvatar.label.groups',
    defaultMessage: 'Groups: ',
  },
  online: {
    id: 'UserAvatar.label.online',
    defaultMessage: 'Online on: ',
  },
  onlineWeb: {
    id: 'UserAvatar.label.onlineWeb',
    defaultMessage: 'Web',
  },
  onlineApp: {
    id: 'UserAvatar.label.onlineApp',
    defaultMessage: 'App',
  },
  lastOnline: {
    id: 'UserAvatar.label.lastOnline',
    defaultMessage: 'Last time online: ',
  },
  neverOnline: {
    id: 'UserAvatar.label.neverOnline',
    defaultMessage: 'Never',
  },
  registryYes: {
    id: 'UserDetailsPopover.registryYes',
    defaultMessage: 'Yes',
  },
  registryNo: {
    id: 'UserDetailsPopover.registryNo',
    defaultMessage: 'No',
  },
  username: {
    id: 'UserAvatar.label.username',
    defaultMessage: 'Username',
  },
});

const UserDetailsPopover = ({
  canSeeRegistry,
  user,
  groups,
  favoritesGroupId,
  online,
  loading,
  isOpen,
  target,
  toggle,
  className,
  isOnlineWeb,
  isOnlineApp,
  dateFormat,
  timeFormat,
  canSendSMS,
  intl: { formatMessage },
}) => {
  const userGroups = user.groups
    ? user.groups.filter((id) => id !== favoritesGroupId)
    : [];
  const onlineDevices = [];
  if (isOnlineWeb) {
    onlineDevices.push(formatMessage(messages.onlineWeb));
  }
  if (isOnlineApp) {
    onlineDevices.push(formatMessage(messages.onlineApp));
  }
  let lastOnline;
  if (user.lastOnlineApp || user.lastOnlineWeb) {
    lastOnline =
      user.lastOnlineApp > user.lastOnlineWeb
        ? user.lastOnlineApp
        : user.lastOnlineWeb;
  }

  const retrieveRegistryValue = useCallback(
    (registry) => {
      switch (registry.type) {
        case PuncherEnums.RegistryTemplateFieldType.BOOLEAN:
          return registry.value
            ? formatMessage(messages.registryYes)
            : formatMessage(messages.registryNo);
        case PuncherEnums.RegistryTemplateFieldType.DATE:
          return moment(registry.value).format(dateFormat);
        default:
          return registry.value;
      }
    },
    [dateFormat, formatMessage]
  );

  return (
    <Popover
      isOpen={isOpen}
      toggle={toggle}
      target={target}
      header={user.departmentFullname}
      loading={loading}
      modifiers={{
        preventOverflow: {
          enabled: false,
        },
        flip: {
          enabled: false,
        },
      }}
    >
      <Fade tag="div">
        <div className="row no-gutters mb-2">
          <div className="col-4">
            <UserAvatar
              size="lg"
              src={user.avatar}
              alt={user.departmentFullname}
              offline={!online}
              className={className}
              type={AVATAR_TYPES.USER}
              id={user.id}
              showStatus
              statusPosition={{ bottom: -8, left: -8 }}
              context="userDetailsPopover"
            />
          </div>
          <div className="col">
            <div
              className="text-right"
              style={{ fontSize: '0.9rem', fontWeight: 'bold' }}
            >
              {`${formatMessage(messages.extension)} ${
                user.mainExtensionNumber || ''
              }`}
            </div>
            <div className="text-right">
              <Label
                for="username"
                style={{ fontSize: '0.7875rem', fontWeight: 'bold' }}
              >
                {formatMessage(messages.username)}
              </Label>
              {user.username || ''}
            </div>
            <ul className="nav justify-content-end">
              <li className="nav-item p-1">
                <ExtensionPhoneButton
                  userId={user.id}
                  number={user.mainExtensionNumber}
                />
              </li>
              <li className="nav-item p-1">
                <VideoButton user={user.id} />
              </li>
              <li className="nav-item p-1">
                <ChatButton user={user.id} />
              </li>
              <li className="nav-item p-1">
                <FavoriteButton type="U" id={user.id} />
              </li>
            </ul>
          </div>
        </div>
        {user.numbers &&
          user.numbers.length > 0 &&
          user.numbers.map((number, idx) => (
            <PhoneLabel
              key={idx}
              number={number}
              showType
              showSMS={canSendSMS}
            />
          ))}
        {user.email && (
          <div id="email" className="mb-1">
            <Label for="email">{formatMessage(messages.email)}</Label>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
        )}
        {userGroups.length > 0 && (
          <div id="groups" className="mb-1">
            <Label for="groups">{formatMessage(messages.groups)}</Label>
            {userGroups.map(
              (id, idx) =>
                groups[id] &&
                !groups[id].main && (
                  <GroupLabel
                    key={idx}
                    name={groups[id].name}
                    color={groups[id].color}
                  />
                )
            )}
          </div>
        )}
        {user.notes && (
          <div id="notes" className="mb-1">
            <Label for="notes">{formatMessage(messages.notes)}</Label>
            {user.notes}
          </div>
        )}
        <div id="isOnline" className="mb-1">
          {onlineDevices.length > 0 ? (
            <>
              <Label for="isOnline">{formatMessage(messages.online)}</Label>
              <span>{onlineDevices.join(', ')}</span>
            </>
          ) : (
            <>
              <Label for="isOnline">{formatMessage(messages.lastOnline)}</Label>
              <span>
                {lastOnline
                  ? moment(+lastOnline).format(`${dateFormat} ${timeFormat}`)
                  : formatMessage(messages.neverOnline)}
              </span>
            </>
          )}
        </div>
        {canSeeRegistry &&
          user.currentRegistry &&
          Object.keys(user.currentRegistry).length && (
            <div className="border-top pt-2">
              {Object.keys(user.currentRegistry).map((key) => (
                <div id={key} key={key} className="mb-1">
                  <Label for={key}>
                    {`${user.currentRegistry[key].label}:`}
                  </Label>
                  <span>
                    {retrieveRegistryValue(user.currentRegistry[key])}
                  </span>
                </div>
              ))}
            </div>
          )}
      </Fade>
    </Popover>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    groups: getGroupsById(state),
    user: getUserById(state, ownProps.id),
    loading: !getFetchUserLoaded(state, ownProps.id),
    error: getFetchUserError(state, ownProps.id),
    favoritesGroupId: getFavoritesGroupId(state),
    isOnlineWeb: isUserOnlineWeb(state, ownProps.id),
    isOnlineApp: isUserOnlineApp(state, ownProps.id),
    dateFormat: state.settings.dateFormat,
    timeFormat: state.settings.timeFormat,
    canSeeRegistry: hasGrants(state, 'MANAGE_PRESENCES'),
    canSendSMS: isSMSenabled(state) && hasGrants(state, 'SMS_SEND'),
  };
}

UserDetailsPopover.propTypes = {
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  online: PropTypes.bool,
};

UserDetailsPopover.defaultProps = {
  online: false,
};

export default injectIntl(connect(mapStateToProps)(UserDetailsPopover));
