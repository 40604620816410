import React from 'react';
import { FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import { injectIntl, defineMessages } from 'react-intl';
import Select2 from '../../../components/formControls/Select2';
import Label from '../../../components/formControls/Label';
import DatePickerInput from '../../../components/formControls/DatePickerInput';
import Button from '../../../components/formControls/Button';
import Filters from '../../../components/Filters';
import UsersSelect from '../../../components/formControls/UsersSelect';
import {
  getFetchSMSCampaignsLoaded,
  getSMSFilter,
  usingSMSAlias,
} from '../../../js/sms/selectors';
import { setSMSListFilter } from '../../../js/sms/actions';
import { SMSEnums } from '../../../js/sms/SMSUtils';
import SelectPhoneNumbers from '../../../components/SelectPhoneNumbers';
import AliasSelect from './AliasSelect';
import CampaignSelect from './CampaignSelect';

const messages = defineMessages({
  sender: {
    id: 'SMSListFilters.label.sender',
    defaultMessage: 'Sender',
  },
  user: {
    id: 'SMSListFilters.user',
    defaultMessage: 'User',
  },
  contacts: {
    id: 'SMSListFilters.label.contacts',
    defaultMessage: 'Receivers',
  },
  status: {
    id: 'SMSListFilters.label.status',
    defaultMessage: 'Status',
  },
  statusPlaceholder: {
    id: 'SMSListFilters.statusPlaceholder',
    defaultMessage: 'Select a status',
  },
  from: {
    id: 'SMSListFilters.label.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'SMSListFilters.label.to',
    defaultMessage: 'To',
  },
  excludeCampaigns: {
    id: 'SMSListFilters.label.excludeCampaigns',
    defaultMessage: 'Exclude campaigns',
  },
  campaign: {
    id: 'SMSListFilters.campaign',
    defaultMessage: 'Campaign',
  },
  search: {
    id: 'SMSListFilters.button.search',
    defaultMessage: 'Apply filters',
  },
  errorWrongDate: {
    id: 'SMSListFilters.error.errorWrongDate',
    defaultMessage: 'Start date cannot be after end date',
  },
  PENDING: {
    id: 'SMSListFilters.PENDING',
    defaultMessage: 'Sending',
  },
  SENT: {
    id: 'SMSListFilters.SENT',
    defaultMessage: 'Sent',
  },
  ERROR: {
    id: 'SMSListFilters.ERROR',
    defaultMessage: 'Error',
  },
  DELIVERED: {
    id: 'SMSListFilters.DELIVERED',
    defaultMessage: 'Delivered',
  },
});

class SMSListFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      errors: {},
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    if (queryParams && queryParams.campaign) {
      this.setState({
        filter: {
          ...this.state.filter,
          showCampaigns: true,
        },
      });
    } else {
      this.onSubmit(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { location, campaignsLoaded } = this.props;
    const queryParams = queryString.parse(location.search);
    if (
      queryParams &&
      queryParams.campaign &&
      campaignsLoaded &&
      !prevProps.campaignsLoaded
    ) {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            showCampaigns: true,
            campaign: queryParams.campaign,
          },
        },
        () => {
          this.onSubmit();
        }
      );
    }
  }

  arrangeUser = () => {
    const {
      filter: { user: newUser },
    } = this.state;
    const {
      filter: { user: oldUser },
    } = this.props;
    return newUser !== undefined ? newUser : oldUser;
  };

  arrangeSender = () => {
    const {
      filter: { sender: newSender },
    } = this.state;
    const {
      filter: { sender: oldSender },
    } = this.props;
    return newSender !== undefined ? newSender : oldSender;
  };

  arrangeCampaign = () => {
    const {
      filter: { campaign: newCampaign },
    } = this.state;
    const {
      filter: { campaign: oldCampaign },
    } = this.props;
    return newCampaign !== undefined ? newCampaign : oldCampaign;
  };

  arrangeRecipients = () => {
    const {
      filter: { recipients: newRecipients },
    } = this.state;
    const {
      filter: { recipients: oldRecipients },
    } = this.props;
    return newRecipients !== undefined ? newRecipients : oldRecipients;
  };

  arrangeStatus = () => {
    const {
      filter: { status: newStatus },
    } = this.state;
    const {
      filter: { status: oldStatus },
    } = this.props;
    return newStatus !== undefined
      ? newStatus !== null
        ? newStatus.value
        : ''
      : oldStatus;
  };

  arrangeStart = () => {
    const {
      filter: { start: newStart },
    } = this.state;
    const {
      filter: { start: oldStart },
    } = this.props;
    return newStart !== undefined ? newStart : oldStart;
  };

  arrangeEnd = () => {
    const {
      filter: { end: newEnd },
    } = this.state;
    const {
      filter: { end: oldEnd },
    } = this.props;
    return newEnd !== undefined ? newEnd : oldEnd;
  };

  arrangeShowCampaigns = () => {
    const {
      filter: { showCampaigns: newShowCampaigns },
    } = this.state;
    const {
      filter: { showCampaigns: oldShowCampaigns },
    } = this.props;
    return newShowCampaigns !== undefined ? newShowCampaigns : oldShowCampaigns;
  };

  handleUserChange = (user) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, user },
    });
  };

  handleRecipients = (recipients) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        recipients,
      },
    });
  };

  handleSenderSelect = (selected) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        sender: selected?.value || null,
      },
    });
  };

  handleCampaignSelect = (selected) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        campaign: selected?.value || null,
      },
    });
  };

  handleStatusChange = (status) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, status },
    });
  };

  handleEndDay = (end) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, end },
      errors: {},
    });
  };

  handleStartDay = (start) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, start },
      errors: {},
    });
  };

  handleShowCampaigns = (e) => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, showCampaigns: !e.target.checked },
    });
  };

  onSubmit = (keepShowFilters) => {
    const { filter } = this.state;
    const { setFilter, onApply } = this.props;
    const start = this.arrangeStart()
      ? moment(this.arrangeStart()).format('x')
      : null;
    const end = this.arrangeEnd()
      ? moment(this.arrangeEnd()).format('x')
      : null;
    const status = this.arrangeStatus();
    const user = this.arrangeUser();
    const recipients = this.arrangeRecipients();
    const showCampaigns = this.arrangeShowCampaigns();
    const sender = this.arrangeSender();
    const campaign = showCampaigns ? this.arrangeCampaign() : null;

    const params = {
      status,
      start,
      end,
      user,
      recipients,
      showCampaigns,
      sender,
      campaign,
    };
    const errors = this.validate(filter);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      setFilter(params);
      if (!keepShowFilters) {
        onApply();
      }
    }
  };

  validate = (data) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    if (data.start && data.end) {
      if (moment(data.start).format('x') > moment(data.end).format('x')) {
        errors.wrongDate = formatMessage(messages.errorWrongDate);
      }
    }
    return errors;
  };

  render() {
    const {
      useAlias,
      isOpen,
      intl: { formatMessage },
    } = this.props;

    const { filter, errors } = this.state;

    const statusOptions = Object.values(SMSEnums.Status).map((k) => ({
      value: k,
      label: messages[k] ? formatMessage(messages[k]) : k,
    }));

    const selectedStatus = this.arrangeStatus()
      ? statusOptions.filter((o) => o.value === this.arrangeStatus())
      : [];

    return (
      <Filters isOpen={isOpen}>
        <div className="row pt-2">
          <div className="col-lg-6">
            <FormGroup>
              <Label for="contacts">{formatMessage(messages.contacts)}</Label>
              <SelectPhoneNumbers
                phoneNumbers={this.arrangeRecipients()}
                onChange={this.handleRecipients}
              />
            </FormGroup>
          </div>
          {useAlias && (
            <div className="col-lg-2 mt-2 pt-lg-4">
              <FormGroup>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    id="showCampaigns"
                    name="showCampaigns"
                    checked={!this.arrangeShowCampaigns()}
                    className="custom-control-input"
                    onChange={this.handleShowCampaigns}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="showCampaigns"
                  >
                    {formatMessage(messages.excludeCampaigns)}
                  </label>
                </div>
              </FormGroup>
            </div>
          )}
          {this.arrangeShowCampaigns() && (
            <div className="col-lg-4">
              <Label for="user">{formatMessage(messages.campaign)}</Label>
              <CampaignSelect
                campaign={this.arrangeCampaign()}
                onSelect={this.handleCampaignSelect}
                // showDefault
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            <FormGroup>
              <Label for="user">{formatMessage(messages.user)}</Label>
              <UsersSelect
                isMulti={false}
                isClearable
                onSelect={this.handleUserChange}
                selected={this.arrangeUser()}
                placeholder=" "
              />
            </FormGroup>
          </div>
          {useAlias ? (
            <div className="col">
              <FormGroup>
                <Label for="sender">{formatMessage(messages.sender)}</Label>
                <AliasSelect
                  alias={this.arrangeSender()}
                  onSelect={this.handleSenderSelect}
                  // showDefault
                />
              </FormGroup>
            </div>
          ) : (
            <div className="col-lg-4 mt-2 pt-lg-4">
              <FormGroup>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    id="showCampaigns"
                    name="showCampaigns"
                    checked={!this.arrangeShowCampaigns()}
                    className="custom-control-input"
                    onChange={this.handleShowCampaigns}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="showCampaigns"
                  >
                    {formatMessage(messages.excludeCampaigns)}
                  </label>
                </div>
              </FormGroup>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xl-6">
            <FormGroup>
              <Label for="status">{formatMessage(messages.status)}</Label>
              <Select2
                id="status"
                name="status"
                options={statusOptions}
                isClearable
                value={selectedStatus}
                onChange={this.handleStatusChange}
                placeholder="" //{formatMessage(messages.statusPlaceholder)}
              />
            </FormGroup>
          </div>
          <div className="col-xl-3 col-lg-6">
            <FormGroup>
              <Label for="start">{formatMessage(messages.from)}</Label>
              <DatePickerInput
                onChange={(sday) => this.handleStartDay(sday, 'start')}
                day={this.arrangeStart()}
                error={errors.wrongDate}
                clearable
              />
            </FormGroup>
          </div>
          <div className="col-xl-3 col-lg-6">
            <FormGroup>
              <Label for="end">{formatMessage(messages.to)}</Label>
              <DatePickerInput
                onChange={(day) => this.handleEndDay(day, 'end')}
                day={filter.end}
                error={errors.wrongDate}
                clearable
              />
            </FormGroup>
          </div>
        </div>

        <div className="pt-3 pb-3 text-center">
          <Button
            onClick={this.onSubmit}
            className="btn btn-primary"
            text={formatMessage(messages.search)}
          />
        </div>
      </Filters>
    );
  }
}

function mapStateToProps(state) {
  return {
    filter: getSMSFilter(state),
    useAlias: usingSMSAlias(state),
    campaignsLoaded: getFetchSMSCampaignsLoaded(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    setFilter: setSMSListFilter,
  })(SMSListFilters)
);
