import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../Spinner';

const Button = ({ id, className, text, loading, style, onClick, ...props }) => {
  const classes = classnames('btn', className);
  return (
    <button
      // type="button"
      style={style}
      onClick={onClick}
      className={classes}
      id={id}
      {...props}
    >
      {loading && <Spinner />}
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: 'btn btn-primary',
  loading: false,
  onClick: null,
};

export default Button;
