import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { fetchUsersRequest } from '../../js/users/actions';
import {
  getUsersSelectOptions,
  getFetchUsersLoaded,
} from '../../js/users/selectors';
import Select2 from './Select2';

const PAGE_SIZE = 20;

const messages = defineMessages({
  placeholder: {
    id: 'UsersSelect.placeholder',
    defaultMessage: 'Select a user',
  },
  placeholderMulti: {
    id: 'UsersSelect.placeholderMulti',
    defaultMessage: 'Select one or more users',
  },
  noOptionsMessage: {
    id: 'UsersSelect.noOptionsMessage',
    defaultMessage: 'No users found',
  },
});

class UsersSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: undefined,
    };
  }

  componentDidMount() {
    if (this.props.toBeFetched && !this.props.fetching) {
      this.props.fetch();
    }
  }

  handleSelect = (selected) => {
    this.setState(
      { selected: selected || (this.props.isMulti ? [] : null) },
      () => {
        const user = () => {
          if (this.props.isMulti && this.state.selected)
            return this.state.selected.map((o) => o.value);
          if (this.state.selected) return this.state.selected.value;
          return null;
        };
        this.props.onSelect(user());
      }
    );
  };

  arrangeOptions = () => {
    const { options, excluded, notEnabled, notRemovable } = this.props;

    const optionsAfterExclusion =
      excluded.length > 0
        ? options.filter((o) => excluded.indexOf(o.value) < 0)
        : options;

    const optionsAfterDisabling =
      notEnabled.length > 0
        ? optionsAfterExclusion.map((o) => ({
            ...o,
            disabled: notEnabled.indexOf(o.value) > -1,
          }))
        : optionsAfterExclusion;

    const optionsAfterUnremoving =
      notRemovable.length > 0
        ? optionsAfterDisabling.map((o) => ({
            ...o,
            isNotRemovable: notRemovable.indexOf(o.value) > -1,
          }))
        : optionsAfterDisabling;
    return optionsAfterUnremoving;
  };

  arrangeValue = () => {
    if (!this.props.selected && this.props.isMulti) return [];
    if (!this.props.selected && !this.props.isMulti) return null;
    if (this.props.isMulti) {
      return this.arrangeOptions().filter(
        (option) => this.props.selected.indexOf(option.value) > -1
      );
    }
    return this.arrangeOptions().filter(
      (option) => option.value === this.props.selected
    );
  };

  render() {
    const {
      error,
      intl: { formatMessage },
      isMulti,
      isClearable,
      id,
      name,
      style,
      placeholder,
      isDisabled,
    } = this.props;

    return (
      <Select2
        id={id}
        name={name}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isClearable={isClearable}
        pageSize={PAGE_SIZE}
        value={this.arrangeValue()}
        onChange={this.handleSelect}
        options={this.arrangeOptions()}
        error={error}
        isOptionDisabled={(option) => option.disabled}
        noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
        placeholder={
          placeholder ||
          (isMulti && formatMessage(messages.placeholderMulti)) ||
          formatMessage(messages.placeholder)
        }
        style={style}
      />
    );
  }
}

UsersSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]), // from redux
  excluded: PropTypes.arrayOf(PropTypes.number),
  notEnabled: PropTypes.arrayOf(PropTypes.number),
  notRemovable: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  toBeFetched: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

UsersSelect.defaultProps = {
  selected: [],
  excluded: [],
  notEnabled: [],
  notRemovable: [],
  error: null,
  toBeFetched: false,
  isMulti: false,
  isClearable: false,
  id: null,
  name: null,
  placeholder: null,
  isDisabled: false,
};

function mapStateToProps(state) {
  return {
    options: getUsersSelectOptions(state),
    fetching: !getFetchUsersLoaded(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { fetch: fetchUsersRequest })(UsersSelect)
);
