import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import User from '../../js/lib/User';
import { isAuthenticated } from '../../js/auth/selectors';
import GlobalErrorPage from '../pages/GlobalErrorPage';

const UserRoute = ({
  isLogged,
  error,
  loaded,
  selfGrants,
  grants,
  component: Component,
  ...rest
}) => (
  <>
    {User.isAuthorized(selfGrants, grants) ? (
      <Route
        {...rest}
        render={(props) =>
          isLogged ? <Component {...props} /> : <Redirect to="/login" />
        }
      />
    ) : (
      <>
        <Route
          {...rest}
          render={(props) => <GlobalErrorPage {...props} code={403} />}
        />
      </>
    )}
  </>
);

function mapStateToProps(state) {
  return { isLogged: isAuthenticated(state), selfGrants: state.me.grants };
}

UserRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isLogged: PropTypes.bool.isRequired,
  selfGrants: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps)(UserRoute);
