import { request } from '../request';

export default {
  getCustomerContractData: () =>
    request({
      url: '/bridge/customer',
      method: 'GET',
    })
      .then((res) => res)
      .catch((err) => err),
  getCustomerBills: (params) =>
    request({
      url: 'bridge/bills',
      method: 'GET',
      params,
    })
      .then((res) => res)
      .catch((err) => err),
  getPhoneLines: (params) =>
    request({
      url: 'bridge/phone-lines',
      method: 'GET',
      params,
    })
      .then((res) => res)
      .catch((err) => err),
  getContrats: (params) =>
    request({
      url: 'bridge/contracts',
      method: 'GET',
      params,
    })
      .then((res) => res)
      .catch((err) => err),
  getContractNumbers: (id) =>
    request({
      url: `bridge/contract/${id}/numbers`,
      method: 'GET',
    }),
  getContractTraffic: (id, params) =>
    request({
      url: `bridge/contract/${id}/traffic`,
      method: 'GET',
      params,
    }),
  getCustomerServices: (id, params) =>
    request({
      url: `bridge/contract/${id}/services`,
      method: 'GET',
      params,
    }),
};
