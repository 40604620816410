import React from 'react';
import PresencesManagement from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const WorkingHoursEdit = React.lazy(() => import('./WorkingHoursEdit'));

const WorkingHoursEditPage = (props) => (
  <PresencesManagement scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-3">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="working-hours-edit" />
          </div>
        </div>
        <WorkingHoursEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PresencesManagement>
);

export default WorkingHoursEditPage;
