import React from 'react';
import Abook from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const AbookShow = React.lazy(() => import('./AbookShow'));

const AbookShowPage = (props) => (
  <Abook scrolling filter>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AbookShow {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookShowPage;
