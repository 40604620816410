import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
const AdminValidationsNotifications = React.lazy(() =>
  import('./AdminValidationsNotifications')
);
const AdminValidationsNotificationsPage = (props) => (
  <Groups scrolling>
    <div className="text-right">
      <HelpButton fileName="" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AdminValidationsNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminValidationsNotificationsPage;
