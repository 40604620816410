import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getGroupsSelectOptions,
  getMeGroupsSelectOptions,
  meChatGroupsSelectOptions,
  fileboxSharingGroupsSelectOptions,
} from '../../js/groups/selectors';
import Select2 from './Select2';

const PAGE_SIZE = 10;

const messages = defineMessages({
  placeholder: {
    id: 'GroupsSelect.placeholder',
    defaultMessage: 'Select a group',
  },
  noOptionsMessage: {
    id: 'GroupsSelect.noOptionsMessage',
    defaultMessage: 'No group found',
  },
  mainChatName: {
    id: 'GroupsSelect.mainChatName',
    defaultMessage: 'All users',
  },
});

class GroupsSelect extends Component {
  state = {
    selected: undefined,
  };

  handleSelect = (selected) => {
    this.setState(
      { selected: selected || (this.props.isMulti ? [] : null) },
      () => {
        const selected = () => {
          if (this.props.isMulti)
            return this.state.selected.map((o) => o.value);
          if (this.state.selected) return this.state.selected.value;
          return null;
        };
        this.props.onSelect(selected());
      }
      /* () =>
      this.props.onSelect(this.state.selected.map(o => o.value) */
    );
  };

  /* arrangeValue = () =>
    this.state.selected !== undefined
      ? this.state.selected
      : (this.props.selected.length > 0 &&
          this.props.options.filter(
            option => this.props.selected.indexOf(option.value) > -1
          )) ||
        []; */

  arrangeValue = () => {
    if (!this.props.selected && this.props.isMulti) return [];
    if (!this.props.selected && !this.props.isMulti) return null;
    const options = this.props.meChatGroups
      ? this.props.meChatGroupsOptions
      : this.props.fileboxSharing
      ? this.props.fileboxSharingGroupsOptions
      : this.props.options;
    if (this.props.isMulti) {
      return options.filter(
        (option) => this.props.selected.indexOf(option.value) > -1
      );
    }
    return options.filter((option) => {
      return option.value === this.props.selected;
    });
  };

  render() {
    const {
      options,
      error,
      intl: { formatMessage },
      isMulti,
      isClearable,
      id,
      name,
      isDisabled,
      meChatGroups,
      meChatGroupsOptions,
      fileboxSharing,
      fileboxSharingGroupsOptions,
    } = this.props;
    return (
      <Select2
        id={id}
        name={name}
        isMulti={isMulti}
        isDisabled={isDisabled}
        isClearable={isClearable}
        pageSize={PAGE_SIZE}
        value={this.arrangeValue()}
        onChange={this.handleSelect}
        options={
          meChatGroups
            ? meChatGroupsOptions.map((group) => ({
                value: group.value,
                label: group.main
                  ? formatMessage(messages.mainChatName)
                  : group.label,
              }))
            : fileboxSharing
            ? fileboxSharingGroupsOptions
            : options
        }
        error={error}
        noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
        placeholder={formatMessage(messages.placeholder)}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    options: ownProps.isProfile
      ? getMeGroupsSelectOptions(state)
      : getGroupsSelectOptions(state),
    meChatGroupsOptions: meChatGroupsSelectOptions(state),
    fileboxSharingGroupsOptions: fileboxSharingGroupsSelectOptions(state),
  };
}

GroupsSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isProfile: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fileboxSharing: PropTypes.bool,
};

GroupsSelect.defaultProps = {
  selected: [],
  error: null,
  isProfile: false,
  isMulti: true,
  isClearable: false,
  isDisabled: false,
  fileboxSharing: false,
};

export default injectIntl(connect(mapStateToProps)(GroupsSelect));
