import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getInterlocutorName,
  isMainGroupConversation,
} from '../../../js/chat/selectors';
import Icon from '../../../components/icons/Icon';
import ChatWindowHeaderAvatar from './ChatWindowHeaderAvatar';
import {
  openChatConversation,
  closeChatConversation,
} from '../../../js/chat/actions';

const messages = defineMessages({
  close: {
    id: 'OtherChatHeader.tooltip.minimize',
    defaultMessage: 'Close',
  },
  mainChatName: {
    id: 'OtherChatHeader.mainChatName',
    defaultMessage: 'All users',
  },
});

class OtherChatHeader extends React.Component {
  openWindow = (e) => {
    const { openConversation, conversationId, toggle } = this.props;
    e.nativeEvent.stopImmediatePropagation();
    openConversation({
      conversationId,
      byMe: true,
    });
    toggle();
  };

  closeWindow = (e) => {
    const { closeWindow, conversationId } = this.props;
    e.nativeEvent.stopImmediatePropagation();
    closeWindow(conversationId);
  };

  render = () => {
    const {
      conversationId,
      interlocutorName,
      isMain,
      intl: { formatMessage },
    } = this.props;

    return (
      <div
        className="rounded navbar p-1"
        style={{
          background: 'var(--yn-blue)',
          height: '36px',
          cursor: 'pointer',
        }}
      >
        <span
          className="text-truncate d-inline-block position-relative"
          style={{ width: `110px`, color: 'var(--white)' }}
          onClick={(e) => this.openWindow(e)}
        >
          <ChatWindowHeaderAvatar conversationId={conversationId} />
          <span className="ml-2">
            {isMain ? formatMessage(messages.mainChatName) : interlocutorName}
          </span>
        </span>
        <span className="pr-1" onClick={(e) => this.closeWindow(e)}>
          <Icon width={16} name="close-filled" color="var(--white)">
            {formatMessage(messages.close)}
          </Icon>
        </span>
      </div>
    );
  };
}

OtherChatHeader.propTypes = {
  conversationId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    interlocutorName: getInterlocutorName(state, ownProps.conversationId),
    isMain: isMainGroupConversation(state, ownProps.conversationId),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    openConversation: openChatConversation,
    closeWindow: closeChatConversation,
  })(OtherChatHeader)
);
