/** Archive */
export const FETCH_MAILOFFICES_REQUEST = 'FETCH_MAILOFFICES_REQUEST';
export const FETCH_MAILOFFICES_SUCCESS = 'FETCH_MAILOFFICES_SUCCESS';
export const FETCH_MAILOFFICES_FAILURE = 'FETCH_MAILOFFICES_FAILURE';
export const FETCH_PAGED_MAILOFFICES_REQUEST =
  'FETCH_PAGED_MAILOFFICES_REQUEST';
export const FETCH_PAGED_MAILOFFICES_SUCCESS =
  'FETCH_PAGED_MAILOFFICES_SUCCESS';
export const FETCH_PAGED_MAILOFFICES_FAILURE =
  'FETCH_PAGED_MAILOFFICES_FAILURE';
export const FETCH_MAILOFFICE_REQUEST = 'FETCH_MAILOFFICE_REQUEST';
export const FETCH_MAILOFFICE_SUCCESS = 'FETCH_MAILOFFICE_SUCCESS';
export const FETCH_MAILOFFICE_FAILURE = 'FETCH_MAILOFFICE_FAILURE';
export const DELETE_MAILOFFICE_REQUEST = 'DELETE_MAILOFFICE_REQUEST';
export const DELETE_MAILOFFICE_SUCCESS = 'DELETE_MAILOFFICE_SUCCESS';
export const DELETE_MAILOFFICE_FAILURE = 'DELETE_MAILOFFICE_FAILURE';
export const SET_MAILOFFICES_FILTER = 'SET_MAILOFFICES_FILTER';

export const SAVE_MAILOFFICE_REQUEST = 'SAVE_MAILOFFICE_REQUEST';
export const SAVE_MAILOFFICE_SUCCESS = 'SAVE_MAILOFFICE_SUCCESS';
export const SAVE_MAILOFFICE_FAILURE = 'SAVE_MAILOFFICE_FAILURE';

export const FETCH_MAILOFFICE_CREDIT_REQUEST =
  'FETCH_MAILOFFICE_CREDIT_REQUEST';
export const FETCH_MAILOFFICE_CREDIT_SUCCESS =
  'FETCH_MAILOFFICE_CREDIT_SUCCESS';
export const FETCH_MAILOFFICE_CREDIT_FAILURE =
  'FETCH_MAILOFFICE_CREDIT_FAILURE';

export const MAILOFFICE_PRICE_NOTIFICATION = 'MAILOFFICE_PRICE_NOTIFICATION';
export const MAILOFFICE_ERROR_NOTIFICATION = 'MAILOFFICE_ERROR_NOTIFICATION';
export const MAILOFFICE_COMPLETE_NOTIFICATION =
  'MAILOFFICE_COMPLETE_NOTIFICATION';

export const CONFIRM_MAILOFFICE_REQUEST = 'CONFIRM_MAILOFFICE_REQUEST';
export const CONFIRM_MAILOFFICE_SUCCESS = 'CONFIRM_MAILOFFICE_SUCCESS';
export const CONFIRM_MAILOFFICE_FAILURE = 'CONFIRM_MAILOFFICE_FAILURE';
export const REJECT_MAILOFFICE_REQUEST = 'REJECT_MAILOFFICE_REQUEST';
export const REJECT_MAILOFFICE_SUCCESS = 'REJECT_MAILOFFICE_SUCCESS';
export const REJECT_MAILOFFICE_FAILURE = 'REJECT_MAILOFFICE_FAILURE';

export const DELETE_MAILOFFICE_RR_REQUEST = 'DELETE_MAILOFFICE_RR_REQUEST';
export const DELETE_MAILOFFICE_RR_SUCCESS = 'DELETE_MAILOFFICE_RR_SUCCESS';
export const DELETE_MAILOFFICE_RR_FAILURE = 'DELETE_MAILOFFICE_RR_FAILURE';
