export const retrieveApiEndpoint = (context, id, entityId) => {
  switch (context) {
    case 'fax':
      return '/fax/sent';
    case 'wiki':
      return '/wiki';
    case 'events':
      return '/calendar';
    case 'appointments':
      return '/calendar/appointments';
    case 'mailoffice':
      return '/mailoffice';
    case 'mailofficeRR':
      return `/mailoffice/${entityId}/return-receipt`;
    case 'logo':
      return '/logo';
    case 'chat':
      return '/chat';
    case 'greetingAbilis':
      return '/vbox/greeting-abilis';
    case 'greetingNethesis':
      return '/vbox/greeting-nethesis';
    case 'greetingNethesisPbx':
      return '/vbox/greeting-nethesis-pbx';
    case 'tickets':
    case 'ticketscustom':
      return '/ticket';
    case 'filebox':
      return id ? `/filebox/files/${id}` : '/filebox/upload';
    case 'notes':
      return '/notes';

    default:
      if (context.indexOf('ticketscustom') === 0) {
        return '/ticket';
      }
      return '';
  }
};

export const AVATAR_TYPES = {
  ME: 'ME',
  USER: 'USER',
  CONTACT: 'CONTACT',
};

const IMAGE_MAX_WIDTH = 760;
const IMAGE_MAX_HEIGHT = 600;
const RESIZE_QUALITY = 0.7;

const resizeImage = (image, maxWidth, maxHeight, quality) => {
  const canvas = document.createElement('canvas');

  let { width, height } = image;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  }
  if (height > maxHeight) {
    width = Math.round((width * maxHeight) / height);
    height = maxHeight;
  }

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL('image/png', quality).split(',')[1];
};

export const resize = (file, onResize) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const dataUrl = event.target.result;
    const image = new Image();
    image.src = dataUrl;
    image.onload = () => {
      const resizedDataUrl = resizeImage(
        image,
        IMAGE_MAX_WIDTH,
        IMAGE_MAX_HEIGHT,
        RESIZE_QUALITY
      );
      onResize(resizedDataUrl);
    };
  };
};

export const GREETING_FILES_CONTEXT = {
  ABILIS: 'greetingAbilis',
  NETHESIS_USER: 'greetingNethesis',
  NETHESIS_PBX: 'greetingNethesisPbx',
};
