export const FETCH_HOTEL_AREA_PREFIXES_REQUEST =
  'FETCH_HOTEL_AREA_PREFIXES_REQUEST';
export const FETCH_HOTEL_AREA_PREFIXES_SUCCESS =
  'FETCH_HOTEL_AREA_PREFIXES_SUCCESS';
export const FETCH_HOTEL_AREA_PREFIXES_FAILURE =
  'FETCH_HOTEL_AREA_PREFIXES_FAILURE';
export const FETCH_HOTEL_PRICES_REQUEST = 'FETCH_HOTEL_PRICES_REQUEST';
export const FETCH_HOTEL_PRICES_SUCCESS = 'FETCH_HOTEL_PRICES_SUCCESS';
export const FETCH_HOTEL_PRICES_FAILURE = 'FETCH_HOTEL_PRICES_FAILURE';
export const SAVE_HOTEL_PRICES_REQUEST = 'SAVE_HOTEL_PRICES_REQUEST';
export const SAVE_HOTEL_PRICES_SUCCESS = 'SAVE_HOTEL_PRICES_SUCCESS';
export const SAVE_HOTEL_PRICES_FAILURE = 'SAVE_HOTEL_PRICES_FAILURE';
export const DELETE_HOTEL_PRICE_REQUEST = 'DELETE_HOTEL_PRICE_REQUEST';
export const DELETE_HOTEL_PRICE_SUCCESS = 'DELETE_HOTEL_PRICE_SUCCESS';
export const DELETE_HOTEL_PRICE_FAILURE = 'DELETE_HOTEL_PRICE_FAILURE';
export const RESET_HOTEL_PRICES_REQUEST = 'RESET_HOTEL_PRICES_REQUEST';
export const RESET_HOTEL_PRICES_SUCCESS = 'RESET_HOTEL_PRICES_SUCCESS';
export const RESET_HOTEL_PRICES_FAILURE = 'RESET_HOTEL_PRICES_FAILURE';
export const FETCH_HOTEL_CALLS_REQUEST = 'FETCH_HOTEL_CALLS_REQUEST';
export const FETCH_HOTEL_CALLS_SUCCESS = 'FETCH_HOTEL_CALLS_SUCCESS';
export const FETCH_HOTEL_CALLS_FAILURE = 'FETCH_HOTEL_CALLS_FAILURE';
export const SET_HOTEL_CALLS_FILTER = 'SET_HOTEL_CALLS_FILTER';
export const TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST =
  'TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST';
export const TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS =
  'TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS';
export const TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE =
  'TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE';
export const FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST =
  'FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST';
export const FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS =
  'FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS';
export const FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE =
  'FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE';
export const UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST =
  'UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST';
export const UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS =
  'UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS';
export const UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE =
  'UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE';
export const FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST =
  'FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST';
export const FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS =
  'FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS';
export const FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE =
  'FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE';
export const FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST =
  'FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST';
export const FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS =
  'FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS';
export const FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE =
  'FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE';
export const SAVE_HOTEL_WAKEUP_REQUEST = 'SAVE_HOTEL_WAKEUP_REQUEST';
export const SAVE_HOTEL_WAKEUP_SUCCESS = 'SAVE_HOTEL_WAKEUP_SUCCESS';
export const SAVE_HOTEL_WAKEUP_FAILURE = 'SAVE_HOTEL_WAKEUP_FAILURE';
export const DELETE_HOTEL_WAKEUP_REQUEST = 'DELETE_HOTEL_WAKEUP_REQUEST';
export const DELETE_HOTEL_WAKEUP_SUCCESS = 'DELETE_HOTEL_WAKEUP_SUCCESS';
export const DELETE_HOTEL_WAKEUP_FAILURE = 'DELETE_HOTEL_WAKEUP_FAILURE';
export const SET_HOTEL_ACTIVE_WAKEUPS_FILTER =
  'SET_HOTEL_ACTIVE_WAKEUPS_FILTER';
export const SET_HOTEL_ARCHIVE_WAKEUPS_FILTER =
  'SET_HOTEL_ARCHIVE_WAKEUPS_FILTER';
export const FETCH_HOTEL_WAKEUP_ANSWER_REQUEST =
  'FETCH_HOTEL_WAKEUP_ANSWER_REQUEST';
export const FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS =
  'FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS';
export const FETCH_HOTEL_WAKEUP_ANSWER_FAILURE =
  'FETCH_HOTEL_WAKEUP_ANSWER_FAILURE';
