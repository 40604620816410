import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import MainNavGuest from '../panels/Header/MainNavGuest';
import Logo from '../panels/Header/Logo';
import Footer from '../panels/Footer';
import PhonePanelGuest from '../panels/LeftColumn/PhonePanelGuest';
import { getPlatformLanguage } from '../../js/settings/selectors';
import ynConf from '../../conf';

const BANNERS = ['ambrosia.png', 'chat.png', 'poste.png', 'transfer.png'];
const BANNERS_TIMEOUT = 2 * 60 * 1000; // milliseconds

class VideocallGuestTemplate extends Component {
  state = {
    bannerIndex: Math.floor(Math.random() * BANNERS.length),
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), BANNERS_TIMEOUT);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    this.setState({ bannerIndex: Math.floor(Math.random() * BANNERS.length) });
  };

  render() {
    const { children, language } = this.props;

    const { bannerIndex } = this.state;

    return (
      <div className="yn-page">
        <div className="bg-white yn-header">
          <Navbar color="faded" light expand="sm" className="w-100">
            <div className="yn-left-width">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="collapse navbar-collapse">
              <MainNavGuest />
            </div>
          </Navbar>
        </div>
        <div className="yn-left rounded text-center">
          <PhonePanelGuest />
        </div>
        <div className="yn-main p-0">{children}</div>
        <div className="yn-right bg-white rounded border p-2">
          <img
            src={`${ynConf.publicFolderPath}/banners/${language}/${BANNERS[bannerIndex]}`}
            className="img-fluid"
            alt="Youneed banner"
          />
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: getPlatformLanguage(state),
  };
}

export default connect(mapStateToProps)(VideocallGuestTemplate);
