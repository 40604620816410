import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import Password from './formControls/Password';
import Button from './formControls/Button';
import { techLoginRequest } from '../js/auth/actions';

const messages = defineMessages({
  title: {
    id: 'TechLoginModal.title',
    defaultMessage: 'Enter password',
  },
  ok: {
    id: 'TechLoginModal.ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'TechLoginModal.cancel',
    defaultMessage: 'Cancel',
  },
  error: {
    id: 'TechLoginModal.error',
    defaultMessage: 'You must insert a password',
  },
});

class TechLoginModal extends Component {
  state = {
    password: '',
    error: '',
    cancel: false,
  };

  handleCancel = () => {
    this.setState({
      password: '',
      error: '',
    });
    this.props.onCancel();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin();
    }
  };

  handleChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      error: '',
    });
  };

  handleLogin = () => {
    const { password } = this.state;
    const {
      submit,
      intl: { formatMessage },
    } = this.props;
    if (!password || password === '') {
      this.setState({ error: formatMessage(messages.error) });
      return;
    }
    submit({ password });
  };

  render() {
    const {
      show,
      intl: { formatMessage },
    } = this.props;
    const { password, cancel, error } = this.state;
    return (
      <div onKeyDown={this.handleKeyDown}>
        <Modal isOpen={show && !cancel}>
          <ModalHeader>{formatMessage(messages.title)}</ModalHeader>
          <ModalBody>
            <Password
              name="TechPassword"
              id="TechPassword"
              error={error}
              onChange={this.handleChangePassword}
              onKeyDown={this.handleKeyDown}
              value={password}
            />
            <div className="pt-3 text-center">
              <Button
                className="btn btn-primary"
                color="primary"
                onClick={this.handleLogin}
                text={formatMessage(messages.ok)}
                // loading={loading}
              />

              <Button
                className="btn btn-outline-primary ml-2"
                color="primary"
                onClick={this.handleCancel}
                text={formatMessage(messages.cancel)}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

TechLoginModal.propTypes = {
  show: PropTypes.bool,
};

TechLoginModal.defaultProps = {
  show: false,
};

export default injectIntl(
  connect(null, {
    submit: techLoginRequest,
  })(TechLoginModal)
);
