import React from 'react';
import Payments from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const RemainingCredit = React.lazy(() => import('./RemainingCredit'));
const RemainingCreditPage = (props) => (
  <Payments>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <RemainingCredit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Payments>
);

export default RemainingCreditPage;
