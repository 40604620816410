import {
  FETCH_MEDIADEVICES_REQUEST,
  FETCH_MEDIADEVICES_SUCCESS,
  FETCH_MEDIADEVICES_FAILURE,
  GET_USERMEDIA_SUCCESS,
  GET_USERMEDIA_FAILURE,
} from './types';

export const fetchMediadevicesRequest = (payload) => ({
  type: FETCH_MEDIADEVICES_REQUEST,
  payload,
});
export const fetchMediadevicesSuccess = (data) => ({
  type: FETCH_MEDIADEVICES_SUCCESS,
  data,
});
export const fetchMediadevicesFailure = (errors) => ({
  type: FETCH_MEDIADEVICES_FAILURE,
  errors,
});
export const getUsermediaSuccess = (data) => ({
  type: GET_USERMEDIA_SUCCESS,
  data,
});
export const getUsermediaFailure = (error) => ({
  type: GET_USERMEDIA_FAILURE,
  error,
});
