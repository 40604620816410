import React from 'react';
import Document from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const Invoices = React.lazy(() => import('./Invoices'));

const InvoicesPage = (props) => (
  <Document>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <Invoices {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Document>
);

export default InvoicesPage;
