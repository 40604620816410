import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const AdminUsersEditUserValidations = React.lazy(() =>
  import('./AdminUsersEditUserValidations')
);

const AdminUsersEditUserValidationsPage = (props) => (
  <Groups scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="admin-edit-user-validations" />
          </div>
        </div>
        <AdminUsersEditUserValidations {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminUsersEditUserValidationsPage;
