import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'DateFormatModal.title',
    defaultMessage: 'Valid date and time formats',
  },
  text1: {
    id: 'DateFormatModal.text1',
    defaultMessage:
      'It is possible to customize date and time format using and combining following codes:',
  },
  day: {
    id: 'DateFormatModal.day',
    defaultMessage: 'Day',
  },
  month: {
    id: 'DateFormatModal.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'DateFormatModal.year',
    defaultMessage: 'Year',
  },
  hour: {
    id: 'DateFormatModal.hour',
    defaultMessage: 'Hour',
  },
  minutes: {
    id: 'DateFormatModal.minutes',
    defaultMessage: 'Minutes',
  },
  text2: {
    id: 'DateFormatModal.text2',
    defaultMessage: 'For example',
  },
  text3: {
    id: 'DateFormatModal.text3',
    defaultMessage: 'will be ',
  },
});

const DateFormatModal = (props) => {
  return (
    <>
      <div className="mb-4">{props.intl.formatMessage(messages.text1)}</div>
      <table className="w-100">
        <tbody>
          <tr>
            <th className="w-25" scope="row">
              {props.intl.formatMessage(messages.day)}
            </th>
            <td>D</td>
            <td>1 2 ... 30 31</td>
          </tr>
          <tr>
            <th className="w-25 mb-2" />
            <td>DD</td>
            <td>01 02 ... 30 31</td>
          </tr>
          <tr style={{ height: '10px' }}>
            <th />
            <td />
            <td />
          </tr>
          <tr>
            <th className="w-25" scope="row">
              {props.intl.formatMessage(messages.month)}
            </th>
            <td>M</td>
            <td>1 2 ... 11 12</td>
          </tr>
          <tr>
            <th className="w-25" />
            <td>MM</td>
            <td>01 02 ... 11 12</td>
          </tr>
          <tr style={{ height: '10px' }}>
            <th />
            <td />
            <td />
          </tr>
          <tr>
            <th className="w-25" scope="row">
              {props.intl.formatMessage(messages.year)}
            </th>
            <td>YY</td>
            <td>70 71 ... 18 19</td>
          </tr>
          <tr>
            <th className="w-25" />
            <td>YYYY</td>
            <td>1970 1971 ... 2018 2019</td>
          </tr>
          <tr style={{ height: '10px' }}>
            <th />
            <td />
            <td />
          </tr>
          <tr>
            <th className="w-25" scope="row">
              {props.intl.formatMessage(messages.hour)}
            </th>
            <td>H</td>
            <td>0 1 ... 22 23</td>
          </tr>
          <tr>
            <th className="w-25" />
            <td>HH</td>
            <td>00 01 ... 22 23</td>
          </tr>
          <tr style={{ height: '10px' }}>
            <th />
            <td />
            <td />
          </tr>
          <tr>
            <th className="w-25" scope="row">
              {props.intl.formatMessage(messages.minutes)}
            </th>
            <td>m</td>
            <td>0 1 ... 58 59</td>
          </tr>
          <tr>
            <th className="w-25" />
            <td>mm</td>
            <td>00 01 ... 58 59</td>
          </tr>
          <tr>
            <th className="w-25" />
            <td>cc</td>
            <td>00 02 ... 98 99</td>
          </tr>
        </tbody>
      </table>
      <div className="mt-4">{props.intl.formatMessage(messages.text2)}</div>
      <div className="mt-2">
        <strong>D/M/YY</strong> {props.intl.formatMessage(messages.text3)}
        <strong>3/5/19</strong>
      </div>
      <div>
        <strong>YYYY-MM-DD hh:mm</strong>{' '}
        {props.intl.formatMessage(messages.text3)}
        <strong>2019-05-03 13:00</strong>
      </div>
    </>
  );
};

export default injectIntl(DateFormatModal);
