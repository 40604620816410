import { createSelector } from 'reselect';

export const getContractNumbers = (state) =>
  state.documents.contractNumbers.numbers;
export const getCustomerAddress = (state) =>
  state.documents.contractData.documents;
export const getContractNumbersSelectOptions = createSelector(
  getContractNumbers,
  (numbers) =>
    numbers && numbers.map((number) => ({ value: number, label: number }))
);
