import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
  handlePage = (page) => {
    this.props.handlePage(page);
  };

  render() {
    const { pages, currentPage } = this.props;
    const delta = 2;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    const pageItemsDots = [];
    const pageItems = [];
    let pagesItem = pages;

    for (let i = 1; i <= pages; i += 1) {
      if (i === 1 || i === pages || (i >= left && i < right)) {
        pageItems.push(i);
      }
    }

    pageItems.forEach((i) => {
      if (pagesItem) {
        if (i - pagesItem === 2) {
          pageItemsDots.push(
            <li
              key={`page-item-${i}`}
              className={
                i === currentPage + 1 ? 'page-item active' : 'page-item'
              }
            >
              <span
                onClick={() => this.handlePage(i - 1)}
                className="page-link"
                style={{ cursor: 'pointer' }}
              >
                {pagesItem + 1}
              </span>
            </li>
          );
        } else if (i === 1) {
          pageItemsDots.push();
        } else if (i - pagesItem !== 1) {
          pageItemsDots.push(
            <li key={`page-item-with-dots-${i}`} className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          );
        }
        pageItemsDots.push(
          <li
            key={`page-items-${i}`}
            className={i === currentPage + 1 ? 'page-item active' : 'page-item'}
          >
            <span
              onClick={() => this.handlePage(i - 1)}
              className="page-link"
              style={{ cursor: 'pointer' }}
            >
              {i}
            </span>
          </li>
        );
        pagesItem = i;
      }
    });

    return (
      <nav className="mt-3">
        <ul className="pagination justify-content-end">
          <li
            className={currentPage === 0 ? 'page-item disabled' : 'page-item'}
          >
            <span
              onClick={() => this.handlePage(currentPage - 1)}
              className="page-link"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </span>
          </li>
          {pageItemsDots}
          <li
            className={
              currentPage === pages - 1 ? 'page-item disabled' : 'page-item'
            }
          >
            <span
              className="page-link"
              onClick={() => this.handlePage(currentPage + 1)}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </span>
          </li>
        </ul>
      </nav>
    );
  }
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Pagination;
