export default class StickyUtils {
  static STICKY_ROTATIONS = [-15, -12, -9, -6, -3, 3, 6, 9, 12, 15];

  static stickyText = '';

  static stickyDetails = {
    top: '200',
    left: '200',
    color: '#faf6bd',
    font: 'Coming Soon, cursive',
    shape: 'CLASSIC',
    rotation:
      this.STICKY_ROTATIONS[
        Math.floor(Math.random() * this.STICKY_ROTATIONS.length)
      ],
  };

  static errorMessages = {
    LOCKED_FILE: 'LOCKED_FILE',
    MOVE_FILE_LOCKED: 'MOVE_FILE_LOCKED',
    MOVE_FOLDER_LOCKED: 'MOVE_FOLDER_LOCKED',
    MOVE_FILE_ERROR: 'MOVE_FILE_ERROR',
    MOVE_FOLDER_ERROR: 'MOVE_FOLDER_ERROR',
    COPY_FILE_ERROR: 'COPY_FILE_ERROR',
    COPY_FOLDER_ERROR: 'COPY_FOLDER_ERROR',
    EXISTING_FILE: 'EXISTING_FILE',
    FILE_TOO_BIG: 'FILE_TOO_BIG',
    MASSIVE_FOLDER_DELETION_ERROR: 'MASSIVE_FOLDER_DELETION_ERROR',
    MASSIVE_FILE_FOLDER_DELETION_ERROR: 'MASSIVE_FILE_FOLDER_DELETION_ERROR',
    TOO_MANY_FILES: 'TOO_MANY_FILES',
    REQUIRED_FILE_FOLDER: 'REQUIRED_FILE_FOLDER',
    CANNOT_UPLOAD_FOLDER: 'CANNOT_UPLOAD_FOLDER',
  };

  static STATIC_NOTE_COLORS = [
    '#ffffff',
    '#ffb4b4',
    '#ffdaad',
    '#fffaaf',
    '#e2ffb4',
    '#d3fad3',
    '#99FDF0',
    '#BED6FC',
    '#f9dcf9',
  ];
}
