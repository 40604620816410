import {
  FETCH_PAYMENTS_CREDITS_REQUEST,
  FETCH_PAYMENTS_CREDITS_SUCCESS,
  FETCH_PAYMENTS_CREDITS_FAILURE,
  UPDATE_PAYMENTS_EMAILS_REQUEST,
  UPDATE_PAYMENTS_EMAILS_SUCCESS,
  UPDATE_PAYMENTS_EMAILS_FAILURE,
  /** Transactions */
  FETCH_PURCHASES_REQUEST,
  FETCH_PURCHASES_SUCCESS,
  FETCH_PURCHASES_FAILURE,
  FETCH_ALL_PURCHASES_REQUEST,
  FETCH_ALL_PURCHASES_SUCCESS,
  FETCH_ALL_PURCHASES_FAILURE,
  /** PayPal */
  PAYPAL_PAY_REQUEST,
  PAYPAL_PAY_SUCCESS,
  PAYPAL_PAY_FAILURE,
  /** Denominations */
  FETCH_DENOMINATIONS_REQUEST,
  FETCH_DENOMINATIONS_SUCCESS,
  FETCH_DENOMINATIONS_FAILURE,
} from './types';

export const fetchPaymentsCreditsRequest = () => ({
  type: FETCH_PAYMENTS_CREDITS_REQUEST,
});
export const fetchPaymentsCreditsSuccess = (credits) => ({
  type: FETCH_PAYMENTS_CREDITS_SUCCESS,
  credits,
});
export const fetchPaymentsCreditsFailure = (errors) => ({
  type: FETCH_PAYMENTS_CREDITS_FAILURE,
  errors,
});

export const updatePaymentsEmailsRequest = (params) => ({
  type: UPDATE_PAYMENTS_EMAILS_REQUEST,
  params,
});
export const updatePaymentsEmailsSuccess = (emails, success) => ({
  type: UPDATE_PAYMENTS_EMAILS_SUCCESS,
  emails,
  success,
});
export const updatePaymentsEmailsFailure = (errors) => ({
  type: UPDATE_PAYMENTS_EMAILS_FAILURE,
  errors,
});

/** Transactions */

export const fetchPurchasesRequest = (params) => ({
  type: FETCH_PURCHASES_REQUEST,
  payload: params,
});
export const fetchPurchasesSuccess = (payload, success) => ({
  type: FETCH_PURCHASES_SUCCESS,
  payload,
  success,
});
export const fetchPurchasesFailure = (errors) => ({
  type: FETCH_PURCHASES_FAILURE,
  errors,
});

export const fetchAllPurchasesRequest = (params) => ({
  type: FETCH_ALL_PURCHASES_REQUEST,
  payload: params,
});
export const fetchAllPurchasesSuccess = (payload, success) => ({
  type: FETCH_ALL_PURCHASES_SUCCESS,
  payload,
  success,
});
export const fetchAllPurchasesFailure = (errors) => ({
  type: FETCH_ALL_PURCHASES_FAILURE,
  errors,
});

/** PayPal */
export const payPalPayRequest = (payment) => ({
  type: PAYPAL_PAY_REQUEST,
  payment,
});
export const payPalPaySuccess = (payload, success) => ({
  type: PAYPAL_PAY_SUCCESS,
  payload,
  success,
});
export const payPalPayFailure = (errors) => ({
  type: PAYPAL_PAY_FAILURE,
  errors,
});

/** Denominations */
export const fetchDenominationsRequest = () => ({
  type: FETCH_DENOMINATIONS_REQUEST,
});
export const fetchDenominationsSuccess = (denominations) => ({
  type: FETCH_DENOMINATIONS_SUCCESS,
  denominations,
});
export const fetchDenominationsFailure = (errors) => ({
  type: FETCH_DENOMINATIONS_FAILURE,
  errors,
});
