import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';
import { hasGrants, isMePbxAdmin } from '../../../js/me/selectors';
import { getSupplier } from '../../../js/phone/selectors';

const messages = defineMessages({
  title: {
    id: 'AdminGrants.title',
    defaultMessage: 'Grants and permissions',
  },
  grants: {
    id: 'AdminGrants.grants.navLabel',
    defaultMessage: 'Grants',
  },
  advancedSettings: {
    id: 'AdminGrants.advancedSettings.navLabel',
    defaultMessage: 'Advanced settings',
  },
  usersProfiles: {
    id: 'AdminGrants.userProfiles.navLabel',
    defaultMessage: 'Users profiles',
  },
});

const AdminGrants = ({
  intl: { formatMessage },
  children,
  scrolling,
  supplier,
  isPbxAdmin,
}) => {
  const nav = [
    {
      id: 'AdminGrants.userProfiles.navLabel',
      href: '/administration/settings/profiles',
      label: messages.usersProfiles,
    },
    {
      id: 'AdminGrants.advancedSettings.navLabel',
      href: '/administration/settings/grants',
      label: messages.grants,
    },
  ];

  if (supplier !== PhoneEnums.PbxType.ABILIS && isPbxAdmin) {
    nav.push({
      id: 'AdminGrants.grants.navLabel',
      href: '/administration/settings/advanced',
      label: messages.advancedSettings,
    });
  }

  return (
    <div className="border rounded bg-white h-100">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isPbxAdmin: isMePbxAdmin(state),
    supplier: getSupplier(state),
    canManageUsersProfiles: hasGrants(state, 'USERS_PROFILES'),
  };
}

export default injectIntl(connect(mapStateToProps)(AdminGrants));
