import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE,
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS,
  CREATE_WORKING_HOURS_PLAN_FAILURE,
  CREATE_WORKING_HOURS_PLAN_REQUEST,
  CREATE_WORKING_HOURS_PLAN_SUCCESS,
  DELETE_WORKING_HOURS_PLAN_FAILURE,
  DELETE_WORKING_HOURS_PLAN_REQUEST,
  DELETE_WORKING_HOURS_PLAN_SUCCESS,
  GET_WORKING_HOURS_PLANS_FAILURE,
  GET_WORKING_HOURS_PLANS_REQUEST,
  GET_WORKING_HOURS_PLANS_SUCCESS,
  GET_WORKING_HOURS_PLAN_FAILURE,
  GET_WORKING_HOURS_PLAN_REQUEST,
  GET_WORKING_HOURS_PLAN_SUCCESS,
  UPDATE_WORKING_HOURS_PLAN_FAILURE,
  UPDATE_WORKING_HOURS_PLAN_REQUEST,
  UPDATE_WORKING_HOURS_PLAN_SUCCESS,
  WORKING_PLANS_CLEAR_ERRORS,
  ASSIGN_WORKING_HOURS_PLAN_SUCCESS,
  ASSIGN_WORKING_HOURS_PLAN_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_FAILURE,
  SAVE_WORKING_ROUND_REQUEST,
  SAVE_WORKING_ROUND_SUCCESS,
  SAVE_WORKING_ROUND_FAILURE,
  SAVE_WORKING_BREAK_REQUEST,
  SAVE_WORKING_BREAK_SUCCESS,
  SAVE_WORKING_BREAK_FAILURE,
} from './types';
import { FETCH_CUSTOMER_SETTINGS_SUCCESS } from '../me/types';

const initialstate = {
  createWorkingHoursPlanLoaded: true,
  createWorkingHoursPlanError: false,
  updateWorkingHoursPlanLoaded: true,
  updateWorkingHoursPlanError: false,
  getWorkingHoursPlansLoaded: true,
  getWorkingHoursPlansError: false,
  byId: {},
  allIds: [],
  total: null,
  currentPage: 0,
  getWorkingHoursPlanLoaded: true,
  getWorkingHoursPlanError: false,
  assignWorkingHoursPlanToUserLoaded: true,
  assignWorkingHoursPlanToUserSuccess: false,
  assignWorkingHoursPlanToUserError: false,
  deleteWorkingHoursPlanLoaded: true,
  deleteWorkingHoursPlanError: false,
  assignWorkingHoursPlanToAllUsersLoaded: true,
  assignWorkingHoursPlanToAllUsersSuccess: false,
  assignWorkingHoursPlanToAllUsersError: false,
  assignWorkingHoursPlanLoaded: true,
  assignWorkingHoursPlanSuccess: false,
  assignWorkingHoursPlanError: false,
  roundLoading: false,
  roundError: false,
  roundSuccess: false,
  breakLoading: false,
  breakError: false,
  breakSuccess: false,
  lastRoute: [],
};

export default function workingPlans(state = initialstate, action = {}) {
  switch (action.type) {
    case WORKING_PLANS_CLEAR_ERRORS:
      return {
        ...state,
        assignWorkingHoursPlanToAllUsersSuccess: false,
        assignWorkingHoursPlanToAllUsersError: false,
      };
    case CREATE_WORKING_HOURS_PLAN_REQUEST:
      return {
        ...state,
        createWorkingHoursPlanLoaded: false,
        createWorkingHoursPlanError: false,
      };
    case CREATE_WORKING_HOURS_PLAN_SUCCESS:
      return {
        ...state,
        createWorkingHoursPlanLoaded: true,
        createWorkingHoursPlanError: false,
      };
    case CREATE_WORKING_HOURS_PLAN_FAILURE:
      return {
        ...state,
        createWorkingHoursPlanLoaded: true,
        createWorkingHoursPlanError: action.errors,
      };

    case UPDATE_WORKING_HOURS_PLAN_REQUEST:
      return {
        ...state,
        updateWorkingHoursPlanLoaded: false,
        updateWorkingHoursPlanError: false,
      };
    case UPDATE_WORKING_HOURS_PLAN_SUCCESS:
      return {
        ...state,
        updateWorkingHoursPlanLoaded: true,
        updateWorkingHoursPlanError: false,
      };
    case UPDATE_WORKING_HOURS_PLAN_FAILURE:
      return {
        ...state,
        updateWorkingHoursPlanLoaded: true,
        updateWorkingHoursPlanError: action.errors,
      };
    case GET_WORKING_HOURS_PLANS_REQUEST:
      return {
        ...state,
        deleteWorkingHoursPlanError: false,
        getWorkingHoursPlansLoaded: false,
        getWorkingHoursPlansError: false,
      };
    case GET_WORKING_HOURS_PLANS_SUCCESS:
      return {
        ...state,
        getWorkingHoursPlansLoaded: true,
        getWorkingHoursPlansError: false,
        byId: keyBy(action.payload.data, (plan) => plan.id),
        allIds: action.payload.data.map((plan) => plan.id),
        total: action.payload.total,
        currentPage: action.payload.page,
      };
    case GET_WORKING_HOURS_PLANS_FAILURE:
      return {
        ...state,
        getWorkingHoursPlansLoaded: true,
        getWorkingHoursPlansError: action.errors,
      };
    case GET_WORKING_HOURS_PLAN_REQUEST:
      return {
        ...state,

        getWorkingHoursPlanLoaded: false,
        getWorkingHoursPlanError: false,
      };
    case GET_WORKING_HOURS_PLAN_SUCCESS:
      return {
        ...state,
        getWorkingHoursPlanLoaded: true,
        getWorkingHoursPlanError: false,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        allIds: [...state.allIds, action.payload.id],
      };
    case GET_WORKING_HOURS_PLAN_FAILURE:
      return {
        ...state,
        getWorkingHoursPlanLoaded: true,
        getWorkingHoursPlanError: action.errors,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST:
      return {
        ...state,
        assignWorkingHoursPlanToUserLoaded: false,
        assignWorkingHoursPlanToUserSuccess: false,
        assignWorkingHoursPlanToUserError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS:
      return {
        ...state,
        assignWorkingHoursPlanToUserLoaded: true,
        assignWorkingHoursPlanToUserSuccess: true,
        assignWorkingHoursPlanToUserError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE:
      return {
        ...state,
        assignWorkingHoursPlanToUserLoaded: true,
        assignWorkingHoursPlanToUserSuccess: false,
        assignWorkingHoursPlanToUserError: action.errors,
      };
    case DELETE_WORKING_HOURS_PLAN_REQUEST:
      return {
        ...state,
        deleteWorkingHoursPlanLoaded: false,
        deleteWorkingHoursPlanError: false,
      };
    case DELETE_WORKING_HOURS_PLAN_SUCCESS:
      return {
        ...state,

        deleteWorkingHoursPlanLoaded: true,
        deleteWorkingHoursPlanError: false,
      };
    case DELETE_WORKING_HOURS_PLAN_FAILURE:
      return {
        ...state,
        deleteWorkingHoursPlanLoaded: true,
        deleteWorkingHoursPlanError: action.errors,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST:
      return {
        ...state,
        assignWorkingHoursPlanToAllUsersLoaded: false,
        assignWorkingHoursPlanToAllUsersSuccess: false,
        assignWorkingHoursPlanToAllUsersError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS:
      return {
        ...state,
        assignWorkingHoursPlanToAllUsersLoaded: true,
        assignWorkingHoursPlanToAllUsersSuccess: true,
        assignWorkingHoursPlanToAllUsersError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE:
      return {
        ...state,
        assignWorkingHoursPlanToAllUsersLoaded: true,
        assignWorkingHoursPlanToAllUsersSuccess: false,
        assignWorkingHoursPlanToAllUsersError: action.errors,
      };
    case ASSIGN_WORKING_HOURS_PLAN_REQUEST:
      return {
        ...state,
        assignWorkingHoursPlanLoaded: false,
        assignWorkingHoursPlanSuccess: false,
        assignWorkingHoursPlanError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_SUCCESS:
      return {
        ...state,
        assignWorkingHoursPlanLoaded: true,
        assignWorkingHoursPlanSuccess: true,
        assignWorkingHoursPlanError: false,
      };
    case ASSIGN_WORKING_HOURS_PLAN_FAILURE:
      return {
        ...state,
        assignWorkingHoursPlanLoaded: true,
        assignWorkingHoursPlanTSuccess: false,
        assignWorkingHoursPlanError: action.errors,
      };
    case FETCH_CUSTOMER_SETTINGS_SUCCESS:
      return {
        ...state,
        roundIn: action.customerSettings.roundIn,
        roundOut: action.customerSettings.roundOut,
        roundUsers: action.customerSettings.roundUsers,
        workingBreak: action.customerSettings.workingBreak,
      };
    case SAVE_WORKING_ROUND_REQUEST:
      return {
        ...state,
        roundLoading: true,
        roundError: false,
        roundSuccess: false,
      };
    case SAVE_WORKING_ROUND_SUCCESS:
      return {
        ...state,
        roundIn: action.payload.roundIn,
        roundOut: action.payload.roundOut,
        roundUsers: action.payload.roundUsers,
        roundLoading: false,
        roundError: false,
        roundSuccess: true,
      };
    case SAVE_WORKING_ROUND_FAILURE:
      return {
        ...state,
        roundLoading: false,
        roundError: true,
        roundSuccess: false,
      };
    case SAVE_WORKING_BREAK_REQUEST:
      return {
        ...state,
        breakLoading: true,
        breakError: false,
        breakSuccess: false,
      };
    case SAVE_WORKING_BREAK_SUCCESS:
      return {
        ...state,
        workingBreak: action.payload,
        breakLoading: false,
        breakError: false,
        breakSuccess: true,
      };
    case SAVE_WORKING_BREAK_FAILURE:
      return {
        ...state,
        breakLoading: false,
        breakError: true,
        breakSuccess: false,
      };
    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      const newState =
        lastRoute[0] === newRoute[0] && lastRoute[1] === newRoute[1]
          ? state
          : initialstate;
      return {
        ...state,
        ...newState,
        lastRoute: newRoute,
      };
    }
    default:
      return state;
  }
}
