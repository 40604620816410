import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { getSMSAliasById } from '../../../js/sms/selectors';
import { getDatetimeFormat } from '../../../js/settings/selectors';
import { aliasStatusColor } from '../../../js/sms/SMSUtils';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';

const messages = defineMessages({
  PENDING: {
    id: 'SMSAliasRow.PENDING',
    defaultMessage: 'Sending',
  },
  GATEWAY_CONFIRMED: {
    id: 'SMSAliasRow.GATEWAY_CONFIRMED',
    defaultMessage: 'Gateway confirmed',
  },
  AGCOM_CONFIRMED: {
    id: 'SMSAliasRow.AGCOM_CONFIRMED',
    defaultMessage: 'AGCOM confirmed',
  },
  GATEWAY_BLOCKED: {
    id: 'SMSAliasRow.GATEWAY_BLOCKED',
    defaultMessage: 'Gateway blocked',
  },
  AGCOM_BLOCKED: {
    id: 'SMSAliasRow.AGCOM_BLOCKED',
    defaultMessage: 'AGCOM blocked',
  },
  showText: {
    id: 'SMSAliasRow.tooltip.showText',
    defaultMessage: 'Show text',
  },
  dateYestarday: {
    id: 'SMSAliasRow.dateYestarday',
    defaultMessage: 'yestarday',
  },
});

const SMSAliasRow = ({
  aliasData,
  users,
  dateTimeFormat,
  intl: { formatMessage },
}) => {
  const user = users[aliasData.user];
  return (
    <tr>
      <td>{moment(+aliasData.creationDate).format(dateTimeFormat)}</td>
      <td>
        {user ? (
          <>
            <UserAvatar
              size="sm"
              alt={user.departmentFullname}
              src={user.avatar}
              id={aliasData.user}
              withPopover
            />
            {user.departmentFullname}
          </>
        ) : (
          aliasData.historyUser
        )}
      </td>
      <td>{aliasData.alias}</td>
      <td className={aliasStatusColor(aliasData.status)}>
        {messages[aliasData.status]
          ? formatMessage(messages[aliasData.status])
          : aliasData.status}
      </td>
      <td></td>
    </tr>
  );
};

SMSAliasRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    aliasData: getSMSAliasById(state, ownProps.id),
    dateTimeFormat: getDatetimeFormat(state),
    users: getUsersById(state),
  };
}

export default injectIntl(connect(mapStateToProps)(SMSAliasRow));
