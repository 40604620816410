import {
  AUTH_SUCCESS,
  SET_ME_GRANTS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_SUCCESS,
  GUEST_LOGIN_FAILURE,
  GUEST_LOGOUT_REQUEST,
  GUEST_LOGOUT_SUCCESS,
  GUEST_LOGOUT_FAILURE,
  LOGIN_REQUEST,
  TECH_LOGIN_REQUEST,
  TECH_LOGIN_SUCCESS,
  TECH_LOGIN_FAILURE,
  SHOW_CHANGED_GRANTS_MODAL,
  HIDE_CHANGED_GRANTS_MODAL,
} from './types';

export const loginRequest = (credentials) => ({
  type: LOGIN_REQUEST,
  credentials,
});
export const loginSuccess = (me) => ({ type: LOGIN_SUCCESS, me });
export const loginFailure = (errors) => ({ type: LOGIN_FAILURE, errors });
export const authSuccess = () => ({ type: AUTH_SUCCESS });
export const updateGrants = (grants) => ({ type: SET_ME_GRANTS, grants });
export const showChangedGrantsModal = () => ({
  type: SHOW_CHANGED_GRANTS_MODAL,
});
export const hideChangedGrantsModal = () => ({
  type: HIDE_CHANGED_GRANTS_MODAL,
});

export const resetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});
export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });
export const resetPasswordFailure = (errors) => ({
  type: RESET_PASSWORD_FAILURE,
  errors,
});
export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailure = (errors) => ({ type: LOGOUT_FAILURE, errors });
export const guestLoginRequest = (credentials) => ({
  type: GUEST_LOGIN_REQUEST,
  credentials,
});
export const guestLoginSuccess = (guest) => ({
  type: GUEST_LOGIN_SUCCESS,
  guest,
});
export const guestLoginFailure = (errors) => ({
  type: GUEST_LOGIN_FAILURE,
  errors,
});
export const guestLogoutRequest = () => ({ type: GUEST_LOGOUT_REQUEST });
export const guestLogoutSuccess = () => ({ type: GUEST_LOGOUT_SUCCESS });
export const guestLogoutFailure = (errors) => ({
  type: GUEST_LOGOUT_FAILURE,
  errors,
});
export const techLoginRequest = (credentials) => ({
  type: TECH_LOGIN_REQUEST,
  credentials,
});
export const techLoginSuccess = (guest) => ({
  type: TECH_LOGIN_SUCCESS,
  guest,
});
export const techLoginFailure = (errors) => ({
  type: TECH_LOGIN_FAILURE,
  errors,
});
