import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import {
  answerCallRequest,
  declineCallRequest,
} from '../../../js/phone/actions';

import { PbxSettings } from '../../../js/phone/PbxSettingsUtils';
import Button from '../../../components/formControls/Button';
import { NotificationSettings } from '../../../js/notifications/NotificationUtils';
import InterlocutorPanel from '../../../components/InterlocutorPanel';

const messages = defineMessages({
  header: {
    id: 'CallSpyPanel.header',
    defaultMessage: 'External call incoming',
  },
  body: {
    id: 'CallSpyPanel.body',
    defaultMessage: 'External number',
  },
  answer: {
    id: 'CallSpyPanel.answer',
    defaultMessage: 'Answer',
  },
  hangup: {
    id: 'CallSpyPanel.hangup',
    defaultMessage: 'Hang up',
  },
});

class CallSpyPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioIn: null,
    };
  }

  componentDidMount() {
    if (!this.state.audioIn) {
      const audioIn = new Audio(PbxSettings.RING_SOUND);
      audioIn.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audioIn.loop = true;
      audioIn.play();
      this.setState({ audioIn });
    }
  }

  componentWillUnmount() {
    if (this.state.audioIn) {
      this.state.audioIn.pause();
    }
  }

  handleClose = () => {
    this.props.decline();
  };

  handleAnswer = () => this.props.answer();

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Fade id="ConferencePanelMember" className="card mt-2">
        <div className="card-header text-left small p-1">
          <div className="row">
            <div className="col-12">
              {this.props.intl.formatMessage(messages.header)}
            </div>
          </div>
        </div>
        <div className="card-body p-1 text-left small border-bottom">
          <InterlocutorPanel
            ellipsis
            interlocutor={{
              fullname: this.props.intl.formatMessage(messages.body),
              number: ' ',
            }}
          />
        </div>
        <div className="card-body p-1">
          <div className="btn-group btn-block">
            <Button
              text={formatMessage(messages.answer)}
              className="btn-success"
              style={{ width: '50%' }}
              onClick={this.handleAnswer}
            />
            <Button
              text={formatMessage(messages.hangup)}
              className="btn-danger"
              style={{ width: '50%' }}
              onClick={this.handleClose}
            />
          </div>
        </div>
      </Fade>
    );
  }
}

export default injectIntl(
  connect(null, {
    answer: answerCallRequest,
    decline: declineCallRequest,
  })(CallSpyPanel)
);
