import { takeLatest, call, put } from 'redux-saga/effects';
import { saveLogoSuccess, saveLogoFailure } from './actions';
import { SAVE_LOGO_REQUEST } from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

export function* saveLogo(action) {
  try {
    const res = yield call(api.logo.updateLogo, action.payload);
    yield call(checkApiResponse, res);
    yield put(saveLogoSuccess(action.payload, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveLogoFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(SAVE_LOGO_REQUEST, saveLogo);
}
