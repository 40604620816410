export const FETCH_ME_CALLS_REQUEST = 'FETCH_ME_CALLS_REQUEST';
export const FETCH_ME_CALLS_SUCCESS = 'FETCH_ME_CALLS_SUCCESS';
export const FETCH_ME_CALLS_FAILURE = 'FETCH_ME_CALLS_FAILURE';
export const FETCH_PBX_CALLS_REQUEST = 'FETCH_PBX_CALLS_REQUEST';
export const FETCH_PBX_CALLS_SUCCESS = 'FETCH_PBX_CALLS_SUCCESS';
export const FETCH_PBX_CALLS_FAILURE = 'FETCH_PBX_CALLS_FAILURE';
export const SET_PBX_CALLS_FILTER = 'SET_PBX_CALLS_FILTER';
export const DELETE_RECORDED_CALL_REQUEST = 'DELETE_RECORDED_CALL_REQUEST';
export const DELETE_RECORDED_CALL_SUCCESS = 'DELETE_RECORDED_CALL_SUCCESS';
export const DELETE_RECORDED_CALL_FAILURE = 'DELETE_RECORDED_CALL_FAILURE';
export const DELETE_OWN_CALL_REQUEST = 'DELETE_OWN_CALL_REQUEST';
export const DELETE_OWN_CALL_SUCCESS = 'DELETE_OWN_CALL_SUCCESS';
export const DELETE_OWN_CALL_FAILURE = 'DELETE_OWN_CALL_FAILURE';
export const DELETE_OTHER_CALL_REQUEST = 'DELETE_OTHER_CALL_REQUEST';
export const DELETE_OTHER_CALL_SUCCESS = 'DELETE_OTHER_CALL_SUCCESS';
export const DELETE_OTHER_CALL_FAILURE = 'DELETE_OTHER_CALL_FAILURE';
export const INSERT_CALLS_NOTES_REQUEST = 'INSERT_CALLS_NOTES_REQUEST';
export const INSERT_CALLS_NOTES_SUCCESS = 'INSERT_CALLS_NOTES_SUCCESS';
export const INSERT_CALLS_NOTES_FAILURE = 'INSERT_CALLS_NOTES_FAILURE';
export const REFRESH_ME_CALLS = 'REFRESH_ME_CALLS';
export const FETCH_QUEUES_CALLS_REQUEST = 'FETCH_QUEUES_CALLS_REQUEST';
export const FETCH_QUEUES_CALLS_SUCCESS = 'FETCH_QUEUES_CALLS_SUCCESS';
export const FETCH_QUEUES_CALLS_FAILURE = 'FETCH_QUEUES_CALLS_FAILURE';
export const SET_ME_CALLS_FILTER = 'SET_ME_CALLS_FILTER';
export const SET_QUEUES_CALLS_FILTER = 'SET_QUEUES_CALLS_FILTER';
