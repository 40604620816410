import React from 'react';
import Abook from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const AbookContactInfo = React.lazy(() => import('./AbookContactInfo'));

const AbookContactInfoPage = (props) => (
  <Abook scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="abook-info" />
          </div>
        </div>
        <AbookContactInfo {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookContactInfoPage;
