import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { getChatMessageById } from '../../../js/chat/selectors';
import { getInfoMessageText } from '../../../js/chat/ChatUtils';
import { getMeId } from '../../../js/me/selectors';

const messages = defineMessages({
  WELCOME: {
    id: 'ChatMessageInfo.message.welcome',
    defaultMessage: 'Welcome aboard! 🚀😀 ',
  },
  GROUP_CREATED: {
    id: 'ChatMessageInfo.message.groupCreated',
    defaultMessage: '{sender} created this group',
  },
  GROUP_UPDATED: {
    id: 'ChatMessageInfo.message.groupUpdated',
    defaultMessage: '{sender} modified the name of this group',
  },
  USER_ADDED: {
    id: 'ChatMessageInfo.message.userAdded',
    defaultMessage: '{sender} added {user} to this group',
  },
  USER_REMOVED: {
    id: 'ChatMessageInfo.message.userRemoved',
    defaultMessage: '{sender} removed {user} from this group',
  },
  GROUP_CREATED_BY_YOU: {
    id: 'ChatMessageInfo.message.groupCreatedByYou',
    defaultMessage: '{sender} created this group',
  },
  GROUP_UPDATED_BY_YOU: {
    id: 'ChatMessageInfo.message.groupUpdatedByYou',
    defaultMessage: '{sender} modified the name of this group',
  },
  USER_ADDED_BY_YOU: {
    id: 'ChatMessageInfo.message.userAddedByYou',
    defaultMessage: '{sender} added {user} to this group',
  },
  USER_REMOVED_BY_YOU: {
    id: 'ChatMessageInfo.message.userRemovedByYou',
    defaultMessage: '{sender} removed {user} from this group',
  },
  userJoined: {
    id: 'ChatMessageInfo.message.userJoined',
    defaultMessage: '{user} joined this group',
  },
  userLeft: {
    id: 'ChatMessageInfo.message.userLeft',
    defaultMessage: '{user} left this group',
  },
  default: {
    id: 'ChatMessageInfo.message.default',
    defaultMessage: 'Something happen',
  },
  YOU: { id: 'ChatMessageInfo.message.you', defaultMessage: 'you' },
  YOURSELF: {
    id: 'ChatMessageInfo.message.yourself',
    defaultMessage: 'yourself',
  },
});

const ChatMessageInfo = ({ message, myId, intl: { formatMessage } }) => {
  const interlocutors = getInfoMessageText({
    type: message.info ? message.info.type : null,
    sender: {
      id: message.senderId,
      fullname: message.senderFullname,
    },
    user: {
      id: message.info && message.info.user && message.info.user.userId,
      name: message.info && message.info.user && message.info.user.name,
      surname: message.info && message.info.user && message.info.user.surname,
    },
    myId,
  });

  let msg = messages.default;
  const you = messages.YOU ? formatMessage(messages.YOU) : '';
  const yourself = messages.YOURSELF ? formatMessage(messages.YOURSELF) : '';
  if (interlocutors) {
    if (interlocutors.sender === 'YOU') {
      interlocutors.sender = you.charAt(0).toUpperCase() + you.slice(1);
      if (interlocutors.user === 'YOU') {
        interlocutors.user = yourself;
      }
      if (message.info && message.info.type) {
        msg =
          messages[`${message.info.type}_BY_YOU`] ||
          messages[message.info.type] ||
          messages.default;
      }
    } else if (interlocutors.user === 'YOU') {
      interlocutors.user = yourself;
      if (message.info && message.info.type) {
        msg = messages[message.info.type] || messages.default;
      }
    } else {
      msg = messages[message.info.type] || messages.default;
    }
  }
  return formatMessage(msg, interlocutors);
};

ChatMessageInfo.propTypes = {
  messageId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    myId: getMeId(state),
    message: getChatMessageById(state, ownProps.messageId),
  };
}

export default injectIntl(connect(mapStateToProps)(ChatMessageInfo));
