import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_USERS_PUNCHINGS_REQUEST,
  FETCH_USERS_PUNCHINGS_SUCCESS,
  FETCH_USERS_PUNCHINGS_FAILURE,
  SET_MANAGE_PUNCHINGS_FILTER,
  FETCH_WORKING_USERS_REQUEST,
  FETCH_WORKING_USERS_SUCCESS,
  FETCH_WORKING_USERS_FAILURE,
  SET_WORKING_USERS_FILTER,
  FETCH_PUNCHINGS_PLAN_REQUEST,
  FETCH_PUNCHINGS_PLAN_SUCCESS,
  FETCH_PUNCHINGS_PLAN_FAILURE,
  SET_ABSENCE_FILTER,
  FETCH_ABSENCE_REQUEST,
  FETCH_ABSENCE_SUCCESS,
  FETCH_ABSENCE_FAILURE,
  SAVE_OTHER_PUNCHING_REQUEST,
  SAVE_OTHER_PUNCHING_SUCCESS,
  SAVE_OTHER_PUNCHING_FAILURE,
  DELETE_ABSENCE_REQUEST,
  DELETE_ABSENCE_FAILURE,
  SAVE_ABSENCE_REQUEST,
  SAVE_ABSENCE_SUCCESS,
  SAVE_ABSENCE_FAILURE,
  FETCH_QRCODE_SETTINGS_REQUEST,
  FETCH_QRCODE_SETTINGS_SUCCESS,
  FETCH_QRCODE_SETTINGS_FAILURE,
  SAVE_QRCODE_SETTINGS_REQUEST,
  SAVE_QRCODE_SETTINGS_SUCCESS,
  SAVE_QRCODE_SETTINGS_FAILURE,
  FETCH_GUEST_DATA_REQUEST,
  FETCH_GUEST_DATA_SUCCESS,
  FETCH_GUEST_DATA_FAILURE,
  SAVE_GUEST_VISIT_REQUEST,
  SAVE_GUEST_VISIT_SUCCESS,
  SAVE_GUEST_VISIT_FAILURE,
  FETCH_GUEST_VISITS_REQUEST,
  FETCH_GUEST_VISITS_SUCCESS,
  FETCH_GUEST_VISITS_FAILURE,
  FETCH_PUNCHING_JUSTIFICATIONS_REQUEST,
  FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS,
  FETCH_PUNCHING_JUSTIFICATIONS_FAILURE,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE,
  FETCH_PRESENCE_SETTINGS_SUCCESS,
  FETCH_WORKING_USERS_EXCEL_SUCCESS,
  FETCH_WORKING_USERS_EXCEL_FAILURE,
  SET_CASSA_INTEGRAZIONE_FILTER,
  FETCH_CASSA_INTEGRAZIONE_REQUEST,
  FETCH_CASSA_INTEGRAZIONE_SUCCESS,
  FETCH_CASSA_INTEGRAZIONE_FAILURE,
  SAVE_CASSA_INTEGRAZIONE_REQUEST,
  SAVE_CASSA_INTEGRAZIONE_SUCCESS,
  SAVE_CASSA_INTEGRAZIONE_FAILURE,
  DELETE_CASSA_INTEGRAZIONE_REQUEST,
  DELETE_CASSA_INTEGRAZIONE_FAILURE,
  SAVE_EXTRA_WORKINGTIME_REQUEST,
  SAVE_EXTRA_WORKINGTIME_SUCCESS,
  SAVE_EXTRA_WORKINGTIME_FAILURE,
  DELETE_EXTRA_WORKINGTIME_SUCCESS,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialstate = {
  fetchPunchingsLoaded: true,
  fetchPunchingsError: null,
  currentPage: 0,
  totalPunchings: 0,
  filter: {},
  allById: {},
  allIds: [],
  fetchWorkingUsersLoaded: null,
  fetchWorkingUsersError: null,
  workingUsersCurrentPage: 0,
  totalWorkingUsers: 0,
  workingUsersFilter: {},
  workingUsersAllById: {},
  workingUsersAllIds: [],
  fetchWorkingUsersExcelLoaded: true,
  fetchWorkingUsersExcelError: false,
  workingUsersExcel: [],
  punchingPlan: {
    loaded: true,
    error: false,
    punchings: [],
    vacations: [],
    absences: [],
    casse: [],
    extra: [],
  },
  fetchFirstAbsences: false,
  fetchAbsenceLoaded: true,
  fetchAbsenceError: null,
  saveAbsenceLoaded: true,
  saveAbsenceError: null,
  deleteAbsenceError: null,
  saveOtherPunchingLoaded: true,
  saveOtherPunchingError: null,
  absence: {
    filter: {},
    allById: {},
    allIds: [],
    currentPage: 0,
    total: 0,
  },
  fetchQRCodeSettingsLoaded: true,
  fetchQRCodeSettingsError: null,
  saveQRCodeSettingsLoaded: true,
  saveQRCodeSettingsError: null,
  QRCodeSettings: {},
  fetchGuestDataLoaded: true,
  fetchGuestDataError: null,
  saveGuestVisitLoaded: true,
  saveGuestVisitError: null,
  saveGuestVisitSuccess: false,
  guestData: {},
  fetchGuestVisitsLoaded: true,
  fetchGuestVisitsError: null,
  guestVisits: {
    byId: {},
    pagedIds: [],
    allIds: [],
    currentPage: 0,
    total: 0,
  },
  fetchJustificationsLoaded: true,
  fetchJustificationsError: false,
  justifications: [],
  deleteJustificationLoaded: true,
  deleteJustificationError: false,
  deleteJustificationSuccess: false,
  insertJustificationLoaded: true,
  insertJustificationError: false,
  insertJustificationSuccess: false,
  fetchFirstCassaIntegrazione: false,
  fetchCassaIntegrazioneLoaded: true,
  fetchCassaIntegrazioneError: null,
  saveCassaIntegrazioneLoaded: true,
  saveCassaIntegrazioneError: null,
  deleteCassaIntegrazioneError: null,
  cassaIntegrazione: {
    filter: {},
    allById: {},
    allIds: [],
    currentPage: 0,
    total: 0,
  },
  saveExtraWorkingTimeLoaded: true,
  saveExtraWorkingTimeError: null,
  lastRoute: [],
};

export default function presences(state = initialstate, action = {}) {
  switch (action.type) {
    case SET_MANAGE_PUNCHINGS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case FETCH_USERS_PUNCHINGS_REQUEST:
      return {
        ...state,
        fetchPunchingsLoaded: false,
        fetchPunchingsError: null,
      };

    case FETCH_USERS_PUNCHINGS_SUCCESS:
      return {
        ...state,
        fetchPunchingsLoaded: true,
        fetchPunchingsError: null,
        totalPunchings: action.data.total,
        currentPage: action.data.page,
        allById: keyBy(action.data.punchings, (punching) => punching.id),
        allIds: action.data.punchings.map((punching) => punching.id),
      };

    case FETCH_USERS_PUNCHINGS_FAILURE:
      return {
        ...state,
        fetchPunchingsLoaded: true,
        fetchPunchingsError: action.errors,
      };
    case FETCH_WORKING_USERS_REQUEST:
      if (action.payload.excelSearch) {
        return {
          ...state,
          fetchWorkingUsersExcelLoaded: false,
          fetchWorkingUsersExcelError: false,
        };
      }
      return {
        ...state,
        fetchWorkingUsersLoaded: false,
        fetchWorkingUsersError: null,
      };

    case FETCH_WORKING_USERS_EXCEL_SUCCESS:
      return {
        ...state,
        fetchWorkingUsersExcelLoaded: true,
        fetchWorkingUsersExcelError: false,
        workingUsersExcel: action.payload,
      };

    case FETCH_WORKING_USERS_EXCEL_FAILURE:
      return {
        ...state,
        fetchWorkingUsersExcelError: true,
        fetchWorkingUsersExcelLoaded: true,
      };

    case FETCH_WORKING_USERS_SUCCESS:
      return {
        ...state,
        fetchWorkingUsersLoaded: true,
        fetchWorkingUsersError: null,
        totalWorkingUsers: action.payload.total,
        workingUsersCurrentPage: action.payload.page,
        workingUsersAllById: keyBy(action.payload.data, (data) => data.id),
        workingUsersAllIds: action.payload.data.map((data) => data.id),
      };
    case FETCH_WORKING_USERS_FAILURE:
      return {
        ...state,
        fetchWorkingUsersLoaded: true,
        fetchWorkingUsersError: action.errors,
      };
    case SET_WORKING_USERS_FILTER:
      return {
        ...state,
        workingUsersFilter: action.payload,
      };
    case FETCH_PUNCHINGS_PLAN_REQUEST:
      return {
        ...state,
        punchingPlan: {
          loaded: false,
          error: false,
          punchings: [],
          vacations: [],
          absences: [],
          casse: [],
          extra: [],
        },
      };
    case FETCH_PUNCHINGS_PLAN_SUCCESS:
      return {
        ...state,
        punchingPlan: {
          loaded: true,
          error: false,
          punchings: action.data.punchings,
          vacations: action.data.vacations,
          absences: action.data.absences,
          casse: action.data.casse,
          extra: action.data.extra,
        },
      };
    case FETCH_PUNCHINGS_PLAN_FAILURE:
      return {
        ...state,
        punchingPlan: {
          loaded: true,
          error: true,
          punchings: [],
          vacations: [],
          absences: [],
          casse: [],
          extra: [],
        },
      };
    case SAVE_OTHER_PUNCHING_REQUEST:
      return {
        ...state,
        saveOtherPunchingLoaded: false,
        saveOtherPunchingError: null,
      };
    case SAVE_OTHER_PUNCHING_SUCCESS:
      return {
        ...state,
        saveOtherPunchingLoaded: true,
        saveOtherPunchingError: null,
        punchingPlan: {
          ...state.punchingPlan,
          punchings: [
            ...state.punchingPlan.punchings,
            {
              id: action.data.id,
              userId: action.data.user,
              validated: true,
              confirmed: true,
              date: action.data.date,
              isIn: action.data.isIn,
              isBreak: action.data.isBreak,
              isMeal: action.data.isMeal,
              isAvailability: action.data.isAvailability,
              isSmartworking: action.data.isSmartworking,
            },
          ],
        },
      };
    case SAVE_OTHER_PUNCHING_FAILURE:
      return {
        ...state,
        saveOtherPunchingLoaded: true,
        saveOtherPunchingError: action.errors,
      };
    case SET_ABSENCE_FILTER:
      return {
        ...state,
        absence: {
          ...state.absence,
          filter: action.payload,
        },
      };
    case FETCH_ABSENCE_REQUEST:
      return {
        ...state,
        fetchAbsenceLoaded: false,
        fetchAbsenceError: null,
      };

    case FETCH_ABSENCE_SUCCESS:
      return {
        ...state,
        fetchFirstAbsences: true,
        fetchAbsenceLoaded: true,
        fetchAbsenceError: null,
        absence: {
          ...state.absence,
          allById: keyBy(action.absence.data, (absence) => absence.id),
          allIds: action.absence.data.map((absence) => absence.id),
          currentPage: action.absence.page,
          total: action.absence.total,
        },
      };

    case FETCH_ABSENCE_FAILURE:
      return {
        ...state,
        fetchAbsenceLoaded: true,
        fetchAbsenceError: action.errors,
      };
    case SAVE_ABSENCE_REQUEST:
      return {
        ...state,
        saveAbsenceLoaded: false,
        saveAbsenceError: null,
      };
    case SAVE_ABSENCE_SUCCESS:
      return {
        ...state,
        saveAbsenceLoaded: true,
        saveAbsenceError: null,
      };
    case SAVE_ABSENCE_FAILURE:
      return {
        ...state,
        saveAbsenceLoaded: true,
        saveAbsenceError: action.errors,
      };
    case DELETE_ABSENCE_REQUEST:
      return {
        ...state,
        deleteAbsenceError: null,
      };

    case DELETE_ABSENCE_FAILURE:
      return {
        ...state,
        deleteAbsenceError: action.errors,
      };
    case FETCH_QRCODE_SETTINGS_REQUEST:
      return {
        ...state,
        fetchQRCodeSettingsLoaded: false,
        fetchQRCodeSettingsError: null,
      };

    case FETCH_QRCODE_SETTINGS_SUCCESS:
      return {
        ...state,
        QRCodeSettings: action.payload,
        fetchQRCodeSettingsLoaded: true,
        fetchQRCodeSettingsError: null,
      };
    case FETCH_QRCODE_SETTINGS_FAILURE:
      return {
        ...state,
        fetchQRCodeSettingsLoaded: true,
        fetchQRCodeSettingsError: action.errors,
      };
    case SAVE_QRCODE_SETTINGS_REQUEST:
      return {
        ...state,
        saveQRCodeSettingsLoaded: false,
        saveQRCodeSettingsError: null,
      };
    case SAVE_QRCODE_SETTINGS_SUCCESS:
      return {
        ...state,
        saveQRCodeSettingsLoaded: true,
        saveQRCodeSettingsError: null,
      };
    case SAVE_QRCODE_SETTINGS_FAILURE:
      return {
        ...state,
        saveQRCodeSettingsLoaded: true,
        saveQRCodeSettingsError: action.errors,
      };
    case FETCH_GUEST_DATA_REQUEST:
      return {
        ...state,
        fetchGuestDataLoaded: false,
        fetchGuestDataError: null,
      };

    case FETCH_GUEST_DATA_SUCCESS:
      return {
        ...state,
        guestData: action.payload,
        fetchGuestDataLoaded: true,
        fetchGuestDataError: null,
      };
    case FETCH_GUEST_DATA_FAILURE:
      return {
        ...state,
        fetchGuestDataLoaded: true,
        fetchGuestDataError: action.errors,
      };
    case SAVE_GUEST_VISIT_REQUEST:
      return {
        ...state,
        saveGuestVisitLoaded: false,
        saveGuestVisitError: null,
        saveGuestVisitSuccess: false,
      };
    case SAVE_GUEST_VISIT_SUCCESS:
      return {
        ...state,
        saveGuestVisitLoaded: true,
        saveGuestVisitError: null,
        saveGuestVisitSuccess: true,
      };
    case SAVE_GUEST_VISIT_FAILURE:
      return {
        ...state,
        saveGuestVisitLoaded: true,
        saveGuestVisitError: action.errors,
        saveGuestVisitSuccess: false,
      };
    case FETCH_GUEST_VISITS_REQUEST:
      return {
        ...state,
        fetchGuestVisitsLoaded: false,
        fetchGuestVisitsError: null,
      };
    case FETCH_GUEST_VISITS_SUCCESS:
      return {
        ...state,
        fetchGuestVisitsLoaded: true,
        fetchGuestVisitsaError: null,
        guestVisits: {
          ...state.guestVisits,
          byId: keyBy(action.payload.data, (o) => o.id),
          pagedIds:
            action.payload.page !== null
              ? action.payload.data.map((o) => o.id)
              : state.guestVisits.pagedIds,
          allIds:
            action.payload.page !== null
              ? state.guestVisits.allIds
              : action.payload.data.map((o) => o.id),
          currentPage: action.payload.page || state.guestVisits.currentPage,
          total: action.payload.total,
        },
      };
    case FETCH_GUEST_VISITS_FAILURE:
      return {
        ...state,
        fetchGuestVisitsLoaded: true,
        fetchGuestVisitsError: action.errors,
      };
    case FETCH_PRESENCE_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchJustificationsLoaded: true,
        fetchJustificationsError: false,
        justifications: action.settings.justifications,
      };
    case FETCH_PUNCHING_JUSTIFICATIONS_REQUEST:
      return {
        ...state,
        fetchJustificationsLoaded: false,
        fetchJustificationsError: false,
      };
    case FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetchJustificationsLoaded: true,
        fetchJustificationsError: false,
        justifications: action.data,
        errorDeleteJustification: false,
      };
    case FETCH_PUNCHING_JUSTIFICATIONS_FAILURE:
      return {
        ...state,
        fetchJustificationsLoaded: true,
        fetchJustificationsError: true,
      };
    case EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST:
      return {
        ...state,
        insertJustificationLoaded: false,
        insertJustificationError: false,
        insertJustificationSuccess: false,
      };
    case EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS:
      return {
        ...state,
        insertJustificationLoaded: true,
        insertJustificationError: false,
        insertJustificationSuccess: true,
      };
    case EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE:
      return {
        ...state,
        insertJustificationLoaded: true,
        insertJustificationError: true,
        insertJustificationSuccess: false,
      };
    case DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST:
      return {
        ...state,
        deleteJustificationLoaded: false,
        deleteJustificationError: false,
        deleteJustificationSuccess: false,
      };
    case DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS:
      return {
        ...state,
        deleteJustificationLoaded: true,
        deleteJustificationError: false,
        deleteJustificationSuccess: true,
      };
    case DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE:
      return {
        ...state,
        deleteJustificationLoaded: true,
        deleteJustificationError: true,
        deleteJustificationSuccess: false,
      };
    case SET_CASSA_INTEGRAZIONE_FILTER:
      return {
        ...state,
        cassaIntegrazione: {
          ...state.cassaIntegrazione,
          filter: action.payload,
        },
      };
    case FETCH_CASSA_INTEGRAZIONE_REQUEST:
      return {
        ...state,
        fetchCassaIntegrazioneLoaded: false,
        fetchCassaIntegrazioneError: null,
      };

    case FETCH_CASSA_INTEGRAZIONE_SUCCESS: 
      return {
        ...state,
        fetchFirstCassaIntegrazione: true,
        fetchCassaIntegrazioneLoaded: true,
        fetchCassaIntegrazioneError: null,
        cassaIntegrazione: {
          ...state.cassaIntegrazione,
          allById: keyBy(action.cassaIntegrazione.data, (cassaIntegrazione) => cassaIntegrazione.id),
          allIds: action.cassaIntegrazione.data.map((cassaIntegrazione) => cassaIntegrazione.id),
          currentPage: action.cassaIntegrazione.page,
          total: action.cassaIntegrazione.total,
        },
      };

    case FETCH_CASSA_INTEGRAZIONE_FAILURE:
      return {
        ...state,
        fetchCassaIntegrazioneLoaded: true,
        fetchCassaIntegrazioneError: action.errors,
      };
    case SAVE_CASSA_INTEGRAZIONE_REQUEST:
      return {
        ...state,
        saveCassaIntegrazioneLoaded: false,
        saveCassaIntegrazioneError: null,
      };
    case SAVE_CASSA_INTEGRAZIONE_SUCCESS:
      return {
        ...state,
        saveCassaIntegrazioneLoaded: true,
        saveCassaIntegrazioneError: null,
      };
    case SAVE_CASSA_INTEGRAZIONE_FAILURE:
      return {
        ...state,
        saveCassaIntegrazioneLoaded: true,
        saveCassaIntegrazioneError: action.errors,
      };
    case DELETE_CASSA_INTEGRAZIONE_REQUEST:
      return {
        ...state,
        deleteCassaIntegrazioneError: null,
      };

    case DELETE_CASSA_INTEGRAZIONE_FAILURE:
      return {
        ...state,
        deleteCassaIntegrazioneError: action.errors,
      };
    case SAVE_EXTRA_WORKINGTIME_REQUEST:
      return {
        ...state,
        saveExtraWorkingTimeLoaded: false,
        saveExtraWorkingTimeError: null,
      };
    case SAVE_EXTRA_WORKINGTIME_SUCCESS: {
      let extra = state.punchingPlan.extra;
      extra.push(action.data)
      return {
        ...state,
        punchingPlan: {
          ...state.punchingPlan,
          extra: [...extra],
        },
        saveExtraWorkingTimeLoaded: true,
        saveExtraWorkingTimeError: null,
      };
    }
    case SAVE_EXTRA_WORKINGTIME_FAILURE:
      return {
        ...state,
        saveExtraWorkingTimeLoaded: true,
        saveExtraWorkingTimeError: action.errors,
      };
    case DELETE_EXTRA_WORKINGTIME_SUCCESS: {
      let extra = state.punchingPlan.extra;
      const newExtra = extra.filter(item => item.id !== action.id);
      return {
        ...state,
        punchingPlan: {
          ...state.punchingPlan,
          extra: newExtra,
        },
      };
    }
    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      const newState =
        lastRoute[0] === newRoute[0] && lastRoute[1] === newRoute[1]
          ? state
          : initialstate;
      return {
        ...newState,
        justifications: state.justifications,
        filter: state.filter,
        lastRoute: newRoute,
      };
    }
    default:
      return state;
  }
}
