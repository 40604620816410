import React from 'react';
import Abook from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const RemoveAbookContacts = React.lazy(() => import('./RemoveAbookContacts'));

const AbookManageRemovePage = () => (
  <Abook scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <RemoveAbookContacts />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookManageRemovePage;
