import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { hasGrants } from '../../../js/me/selectors';

const messages = defineMessages({
  title: {
    id: 'Payments.title',
    defaultMessage: 'Credit',
  },
  remainingCredit: {
    id: 'Payments.remainingCredit.navLabel',
    defaultMessage: 'Remaining Credit',
  },
  transactions: {
    id: 'Payments.transactions.navLabel',
    defaultMessage: 'Transactions',
  },
  rechargeCredit: {
    id: 'Payments.rechargeCredit.navLabel',
    defaultMessage: 'Recharge Credit',
  },
});

const nav = [
  {
    id: 'Payments.remainingCredit.linkLabel',
    href: '/administration/credit/remaining',
    label: messages.remainingCredit,
  },
  {
    id: 'Payments.transactions.linkLabel',
    href: '/administration/credit/transactions',
    label: messages.transactions,
  },
];

const rechargeTab = {
  id: 'Payments.rechargeCredit.linkLabel',
  href: '/administration/credit/recharge',
  label: messages.rechargeCredit,
};

const Payments = ({ canRecharge, intl: { formatMessage }, children }) => {
  const tabs = canRecharge ? [...nav, rechargeTab] : nav;
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={tabs} />
      <div className="yn-section-with-nav-scrolling">{children}</div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    canRecharge: hasGrants(state, 'PAYMENTS_PURCHASE'),
  };
}
export default injectIntl(connect(mapStateToProps)(Payments));
