export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_PAGED_USERS_REQUEST = 'FETCH_PAGED_USERS_REQUEST';
export const FETCH_PAGED_USERS_FAILURE = 'FETCH_PAGED_USERS_FAILURE';
export const FETCH_PAGED_USERS_SUCCESS = 'FETCH_PAGED_USERS_SUCCESS';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const USER_PRESENCE_CHANGE = 'USER_PRESENCE_CHANGE';
export const USER_STATUS_CHANGE = 'USER_STATUS_CHANGE';
export const USER_STATUS_CHANGE_NOTIFICATION =
  'USER_STATUS_CHANGE_NOTIFICATION';
export const USER_UPDATE_NOTIFICATION = 'USER_UPDATE_NOTIFICATION';
export const FETCH_USER_VACATIONS_DATA_REQUEST =
  'FETCH_USER_VACATIONS_DATA_REQUEST';
export const FETCH_USER_VACATIONS_DATA_FAILURE =
  'FETCH_USER_VACATIONS_DATA_FAILURE';
export const FETCH_USER_VACATIONS_DATA_SUCCESS =
  'FETCH_USER_VACATIONS_DATA_SUCCESS';
export const SAVE_USER_VACATIONS_DATA_REQUEST =
  'SAVE_USER_VACATIONS_DATA_REQUEST';
export const SAVE_USER_VACATIONS_DATA_FAILURE =
  'SAVE_USER_VACATIONS_DATA_FAILURE';
export const SAVE_USER_VACATIONS_DATA_SUCCESS =
  'SAVE_USER_VACATIONS_DATA_SUCCESS';
export const FETCH_USER_PUNCHINGS_DATA_REQUEST =
  'FETCH_USER_PUNCHINGS_DATA_REQUEST';
export const FETCH_USER_PUNCHINGS_DATA_FAILURE =
  'FETCH_USER_PUNCHINGS_DATA_FAILURE';
export const FETCH_USER_PUNCHINGS_DATA_SUCCESS =
  'FETCH_USER_PUNCHINGS_DATA_SUCCESS';
export const SAVE_USER_PUNCHINGS_DATA_REQUEST =
  'SAVE_USER_PUNCHINGS_DATA_REQUEST';
export const SAVE_USER_PUNCHINGS_DATA_FAILURE =
  'SAVE_USER_PUNCHINGS_DATA_FAILURE';
export const SAVE_USER_PUNCHINGS_DATA_SUCCESS =
  'SAVE_USER_PUNCHINGS_DATA_SUCCESS';
export const FETCH_USER_REGISTRY_DATA_REQUEST =
  'FETCH_USER_REGISTRY_DATA_REQUEST';
export const FETCH_USER_REGISTRY_DATA_FAILURE =
  'FETCH_USER_REGISTRY_DATA_FAILURE';
export const FETCH_USER_REGISTRY_DATA_SUCCESS =
  'FETCH_USER_REGISTRY_DATA_SUCCESS';
export const SAVE_USER_REGISTRY_DATA_REQUEST =
  'SAVE_USER_REGISTRY_DATA_REQUEST';
export const SAVE_USER_REGISTRY_DATA_FAILURE =
  'SAVE_USER_REGISTRY_DATA_FAILURE';
export const SAVE_USER_REGISTRY_DATA_SUCCESS =
  'SAVE_USER_REGISTRY_DATA_SUCCESS';
export const FETCH_USER_SUPERVIEWERS_REQUEST =
  'FETCH_USER_SUPERVIEWERS_REQUEST';
export const FETCH_USER_SUPERVIEWERS_FAILURE =
  'FETCH_USER_SUPERVIEWERS_FAILURE';
export const FETCH_USER_SUPERVIEWERS_SUCCESS =
  'FETCH_USER_SUPERVIEWERS_SUCCESS';
export const SAVE_USER_SUPERVIEWERS_REQUEST = 'SAVE_USER_SUPERVIEWERS_REQUEST';
export const SAVE_USER_SUPERVIEWERS_FAILURE = 'SAVE_USER_SUPERVIEWERS_FAILURE';
export const SAVE_USER_SUPERVIEWERS_SUCCESS = 'SAVE_USER_SUPERVIEWERS_SUCCESS';
export const FETCH_USER_GROUPS_REQUEST = 'FETCH_USER_GROUPS_REQUEST';
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS';
