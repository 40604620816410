/* eslint react/prefer-stateless-function: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getUserPhoneStatus,
  getSupplier,
  getUserPhoneDnd,
  isPhoneOffline,
  isWebrtcActive,
  isWebrtcInUse,
} from '../../js/phone/selectors';
import {
  execCallRequest,
  startConferenceRequest,
  aidTransferRequest,
} from '../../js/phone/actions';
import {
  getMeConferenceCallId,
  isMeInConference,
  getMeId,
  getMeCallsAndConferences,
} from '../../js/me/selectors';
import { PhoneEnums } from '../../js/phone/PhoneUtils';
import { isPhoneAvailable, statusColor } from '../../js/phone/PbxSettingsUtils';
import Icon from '../icons/Icon';

const messages = defineMessages({
  defaultTooltip: {
    id: 'ExtensionPhoneButton.tooltip.defaultTooltip',
    defaultMessage: 'Call',
  },
  unavailable: {
    id: 'ExtensionPhoneButton.tooltip.unavailable',
    defaultMessage: 'Unavailable',
  },
  dnd: {
    id: 'ExtensionPhoneButton.tooltip.dnd',
    defaultMessage: 'Do not disturb',
  },
});

class ExtensionPhoneButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    const {
      clickable,
      phoneStatus,
      phoneDnd,
      supplier,
      meInConference,
      conferenceCallId,
      addMember,
      number,
      activeCalls,
      aidTransfer,
      call,
    } = this.props;
    if (!clickable || !isPhoneAvailable(phoneStatus, phoneDnd, supplier)) {
      return;
    }
    if (meInConference) {
      if (conferenceCallId) {
        addMember({
          callId: conferenceCallId,
          number,
        });
      }
    } else if (activeCalls && activeCalls.length) {
      if (activeCalls.length === 1 && !activeCalls[0].recording) {
        aidTransfer({
          number,
          callId: activeCalls[0].callid,
        });
      }
    } else {
      call({ number, addExitCode: false });
    }
  };

  render() {
    const {
      isMeOffline,
      myPhoneStatus,
      phoneStatus,
      phoneDnd,
      userId,
      className,
      supplier,
      width,
      height,
      clickable,
      webrtcActive,
      webrtcUsing,
      intl: { formatMessage },
    } = this.props;

    const isMyPhoneAvailable =
      myPhoneStatus !== PhoneEnums.UserStatuses.unavailable && !isMeOffline;

    const isExtensionOffline =
      phoneStatus === PhoneEnums.UserStatuses.unavailable ||
      phoneStatus === PhoneEnums.PhoneStatuses.error ||
      phoneStatus === PhoneEnums.PhoneStatuses.nophone;

    const isExtensionPhoneAvailable = isPhoneAvailable(
      phoneStatus,
      phoneDnd,
      supplier
    );

    const isButtonDisabled =
      !isMyPhoneAvailable ||
      !isExtensionPhoneAvailable ||
      (webrtcActive === 'ON' && !webrtcUsing);

    const tooltip =
      phoneDnd === PhoneEnums.DndStatus.ON
        ? formatMessage(messages.dnd)
        : isButtonDisabled
        ? formatMessage(messages.unavailable)
        : formatMessage(messages.defaultTooltip);

    const iconName =
      !isMyPhoneAvailable ||
      isExtensionOffline ||
      (webrtcActive === 'ON' && !webrtcUsing)
        ? 'phone-5'
        : 'phone-5-filled';

    return (
      userId &&
      phoneStatus && (
        <span onClick={this.handleClick}>
          <Icon
            name={iconName}
            color={statusColor(
              isMeOffline ? PhoneEnums.PhoneStatuses.offline : phoneStatus,
              phoneDnd
            )}
            width={width}
            height={height}
            className={className}
            clickable={clickable}
            disabled={clickable && isButtonDisabled}
          >
            {tooltip}
          </Icon>
        </span>
      )
    );
  }
}

ExtensionPhoneButton.propTypes = {
  userId: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  clickable: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ExtensionPhoneButton.defaultProps = {
  width: 20,
  height: 20,
  clickable: true,
};

function mapStateToProps(state, ownProps) {
  return {
    isMeOffline: isPhoneOffline(state),
    meInConference: isMeInConference(state),
    myPhoneStatus: getMeId(state) && getUserPhoneStatus(state, getMeId(state)),
    phoneStatus: ownProps.userId && getUserPhoneStatus(state, ownProps.userId),
    phoneDnd: ownProps.userId && getUserPhoneDnd(state, ownProps.userId),
    conferenceCallId: getMeConferenceCallId(state),
    supplier: getSupplier(state),
    activeCalls: getMeCallsAndConferences(state),
    webrtcActive: isWebrtcActive(state),
    webrtcUsing: isWebrtcInUse(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    call: execCallRequest,
    aidTransfer: aidTransferRequest,
    addMember: startConferenceRequest,
  })(ExtensionPhoneButton)
);
