import React from 'react';
import ynConf from '../../../conf';

const JitsiVideocallPlayerBackground = ({ main, children }) => {
  return (
    <div
      className="rounded"
      style={{
        border: '1px dashed var(--yn-border-color)',
        background: 'var(--yn-gray-200)',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
      }}
    >
      <div
        style={{
          height: '60%',
          width: '100%',
          position: 'absolute',
          top: '4em',
          backgroundImage: `url(${ynConf.publicFolderPath}/videocall-empty.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 25%',
          backgroundSize: '40% auto',
        }}
      />
      <div
        style={{
          height: main ? '35%' : '70%',
          width: '100%',
          position: 'absolute',
          bottom: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default JitsiVideocallPlayerBackground;
