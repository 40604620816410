import React, { Component } from 'react';
import { connect } from 'react-redux';
import uniqueid from 'lodash.uniqueid';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import Input from './Input';
import Utils from '../../js/lib/utils';
import { getDateFormat } from '../../js/settings/selectors';
import Popover from '../Popover';

class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    this.inputId = uniqueid('DateInput-');
    this.state = {
      popoverOpen: false,
      selectedDay: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.day !== prevProps.day) {
      this.setState({ selectedDay: null });
    }
  }

  togglePopover = (popoverOpen) => {
    this.setState({
      popoverOpen,
    });
  };

  handleDayChange = (e) => {
    this.setState({
      popoverOpen: false,
      selectedDay: e.target.value || '',
    });
  };

  handleBlur = () => {
    const { selectedDay } = this.state;
    const { day, format, clearable } = this.props;
    if (!moment(selectedDay, format, true).isValid()) {
      this.setState(
        {
          selectedDay: clearable
            ? ''
            : moment(day).isValid()
            ? moment(day).format(format)
            : moment().format(format),
        },
        () => this.sendDay()
      );
    } else {
      this.sendDay();
    }
  };

  handleDayClick = (selectedDay) => {
    const { popoverOpen } = this.state;
    const { format } = this.props;
    this.setState(
      {
        popoverOpen: !popoverOpen,
        selectedDay: moment(selectedDay).format(format),
      },
      () => this.sendDay()
    );
  };

  sendDay = () => {
    const { selectedDay } = this.state;
    const { format, onChange } = this.props;
    if (moment(selectedDay, format, true).isValid()) {
      onChange(moment(selectedDay, format, true).startOf('day').toDate());
    } else {
      onChange(null);
    }
  };

  arrangeSelectedDay = () => {
    const { day, clearable, format } = this.props;
    const { selectedDay } = this.state;
    if (moment(selectedDay, format, true).isValid())
      return moment(selectedDay, format, true).toDate();
    if (day) {
      if (moment(day).isValid()) return moment(day).toDate();
      if (clearable) return null;
      return moment().toDate();
    }
    return null;
  };

  arrangeInputValue = () => {
    const { day, format, clearable } = this.props;
    const { selectedDay } = this.state;

    if (selectedDay !== null) return selectedDay;
    if (day !== null) {
      if (moment(day).isValid()) return moment(day).format(format);
      if (clearable) return '';
      return moment().format(format);
    }
    if (clearable) return '';
    return moment().format(format);
  };

  render() {
    const { disabled, error, disablePastDays, disableFutureDays, language } =
      this.props;
    const { popoverOpen } = this.state;

    return (
      <>
        <Input
          type="text"
          id={this.inputId}
          disabled={disabled}
          name={this.inputId}
          onClick={() => this.togglePopover(true)}
          error={error}
          onChange={this.handleDayChange}
          onBlur={this.handleBlur}
          value={this.arrangeInputValue()}
        />
        <Popover
          isOpen={popoverOpen}
          target={this.inputId}
          toggle={() => this.togglePopover(false)}
          hideArrow
          bodyPadding="0"
          popoverMinWidth="fit-content"
          popoverMinHeight="fit-content"
        >
          <DayPicker
            month={this.arrangeSelectedDay()}
            selectedDays={this.arrangeSelectedDay()}
            firstDayOfWeek={1}
            disabledDays={
              disablePastDays
                ? { before: new Date() }
                : disableFutureDays
                ? { after: new Date() }
                : null
            }
            onDayClick={this.handleDayClick}
            localeUtils={MomentLocaleUtils}
            locale={language}
          />
        </Popover>
      </>
    );
  }
}

DatePickerInput.propTypes = {
  day: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  disablePastDays: PropTypes.bool,
  disableFutureDays: PropTypes.bool,
};
DatePickerInput.defaultProps = {
  day: null,
  disabled: false,
  clearable: false,
  disablePastDays: false,
  disableFutureDays: false,
  error: null,
};
function mapStateToProps(state) {
  return {
    format: Utils.getDatepickerFormat(getDateFormat(state)),
    language: state.settings.language,
  };
}
export default connect(mapStateToProps)(DatePickerInput);
