import { SET_UI_DIMENSIONS } from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialstate = {
  ynPage: {
    width: null,
    height: null,
  },
};

export default function ui(state = initialstate, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialstate;
    case SET_UI_DIMENSIONS:
      return {
        ...state,
        ynPage: {
          width: action.payload.ynPage.width,
          height: action.payload.ynPage.height,
        },
      };
    default:
      return state;
  }
}
