export const getPaymentsCredits = (state) =>
  state.payments.paymentsCredits.credits;
export const getFetchPaymentsCreditsLoaded = (state) =>
  state.payments.paymentsCredits.loaded;
export const getFetchPaymentsCreditsError = (state) =>
  state.payments.paymentsCredits.error;
export const getSavePaypalPaymentError = (state) =>
  state.payments.payPalPayment.error;
export const getSavePaypalPaymentLoaded = (state) =>
  state.payments.payPalPayment.loaded;
