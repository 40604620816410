import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from '../../js/auth/selectors';

const GuestRoute = ({ isLogged, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isLogged ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isLogged: isAuthenticated(state),
  };
}

export default connect(mapStateToProps)(GuestRoute);
