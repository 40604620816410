/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';
import { fetchHelpRequest } from '../js/me/actions';
import history from '../history';

class HelpModal extends Component {
  componentDidMount() {
    const { fetch, isOpen, fileName } = this.props;
    if (isOpen) fetch(fileName);
  }

  componentDidUpdate(prevProps) {
    const { fetch, isOpen, fileName } = this.props;
    if (isOpen && fileName && !prevProps.isOpen) fetch(fileName);
  }

  render() {
    let purifiedHtml = DOMPurify.sanitize(this.props.html, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['target'],
    });

    const { remote } = window;
    if (remote) {
      purifiedHtml = purifiedHtml.replace(
        /src="\/help\//g,
        `src="${remote.app.getAppPath()}/build/help/`
      );
      purifiedHtml = purifiedHtml.replace(
        /src='\/help\//g,
        `src='${remote.app.getAppPath()}/build/help/`
      );
      purifiedHtml = purifiedHtml.replace(
        /href="#/g,
        `href="#${history.location.pathname}#/`
      );
      purifiedHtml = purifiedHtml.replace(
        /href='#/g,
        `href='#${history.location.pathname}#/`
      );
    }

    const { isOpen, onToggle, title } = this.props;
    return (
      <Modal
        modalTransition={{
          timeout: 20,
        }}
        backdropTransition={{
          timeout: 10,
        }}
        isOpen={isOpen}
        toggle={onToggle}
        className="modal-lg"
      >
        <ModalHeader toggle={onToggle}>
          {title && title !== '' ? title : 'Help'}
        </ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: purifiedHtml }} />
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    html: state.me.help,
  };
}

export default connect(mapStateToProps, {
  fetch: fetchHelpRequest,
})(HelpModal);
