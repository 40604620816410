import React from 'react';
import Fax from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const FaxReceiptModal = React.lazy(() => import('./FaxReceiptModal'));

const FaxReceiptPage = (props) => (
  <Fax>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <FaxReceiptModal match={props.match} />
      </React.Suspense>
    </ErrorBoundary>
  </Fax>
);

export default FaxReceiptPage;
