import React from 'react';
import Wakeup from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const WakeupSettings = React.lazy(() => import('./WakeupSettings'));
const WakeupSettingsPage = (props) => (
  <Wakeup>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <WakeupSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wakeup>
);

export default WakeupSettingsPage;
