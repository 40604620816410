import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import uniqueid from 'lodash.uniqueid';
import { Tooltip } from 'reactstrap';
import ynConf from '../conf';
import { charsToCode } from '../js/chat/charsToCode';

class UserStatusEmoji extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      tooltipTarget: uniqueid(`Tooltip-`),
    };
  }

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  arrangeImgStyle = () => {
    const { avatarSize } = this.props;
    switch (avatarSize) {
      case 'md':
      default:
        return {
          height: 16,
          width: 16,
          top: '2px',
          left: '2px',
        };
      case 'lg':
        return {
          height: 28,
          width: 28,
          top: '3px',
          left: '3px',
        };
    }
  };

  arrangeOuterStyle = () => {
    const { avatarSize } = this.props;
    switch (avatarSize) {
      case 'md':
      default:
        return {
          height: 22,
          width: 22,
        };
      case 'lg':
        return {
          height: 36,
          width: 36,
        };
    }
  };

  render() {
    const { emoji, label, statusPosition, offline } = this.props;
    const { tooltipOpen, tooltipTarget } = this.state;
    const imgStyle = this.arrangeImgStyle();
    const outerStyle = this.arrangeOuterStyle();

    return (
      emoji && (
        <span
          className="border rounded-circle bg-white"
          style={{
            position: 'absolute',
            padding: '1px',
            overflow: 'hidden',
            bottom: statusPosition && `${statusPosition.bottom}px`,
            left: statusPosition && `${statusPosition.left}px`,
            ...outerStyle,
          }}
        >
          <img
            id={`${tooltipTarget}`}
            src={`${ynConf.publicFolderPath}/emojis/${charsToCode[emoji]}.png`}
            alt={`${emoji}`}
            style={{
              position: 'absolute',
              ...imgStyle,
              opacity: offline ? 0.4 : 1,
            }}
          />
          {label.trim() && (
            <Tooltip
              isOpen={tooltipOpen}
              toggle={() => {
                this.setState({ tooltipOpen: !tooltipOpen });
              }}
              target={`${tooltipTarget}`}
            >
              {label}
            </Tooltip>
          )}
        </span>
      )
    );
  }
}

UserStatusEmoji.propTypes = {
  emoji: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  avatarSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  statusPosition: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  offline: PropTypes.bool,
};

UserStatusEmoji.defaultProps = {
  avatarSize: 'md',
  statusPosition: { bottom: -4, left: -4 },
  offline: false,
};

export default injectIntl(UserStatusEmoji);
