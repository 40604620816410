import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { fetchCompaniesRequest } from '../../../js/contacts/actions';
import Select2 from '../../../components/formControls/Select2';

import {
  getCompanies,
  getCompaniesSearched,
  areCompaniesLoaded,
} from '../../../js/contacts/selectors';

const PAGE_SIZE = 30;

const messages = defineMessages({
  placeholder: {
    id: 'CompaniesSelect.placeholder',
    defaultMessage: 'Search...',
  },
  searchingMessage: {
    id: 'CompaniesSelect.searchingMessage',
    defaultMessage: 'Searching...',
  },
});

class CompaniesSelect extends Component {
  handleInputChange = (filter, { action }) => {
    if (action === 'input-change') {
      this.props.fetch(filter);
      this.props.onChange(filter);
    }
  };

  handleSelect = (selected) => {
    this.props.onChange(selected ? selected.value : '');
  };

  render() {
    const {
      value,
      companies,
      loaded,
      intl: { formatMessage },
      id,
      name,
    } = this.props;

    const options = companies.map((company) => ({
      label: company.description,
      value: company.description,
    }));

    return (
      <Select2
        id={id}
        name={name}
        pageSize={PAGE_SIZE}
        isClearable
        value={{ value, label: value }}
        onInputChange={this.handleInputChange}
        onChange={this.handleSelect}
        options={options}
        noOptionsMessage={() =>
          loaded ? null : formatMessage(messages.searchingMessage)
        }
        placeholder={formatMessage(messages.placeholder)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: getCompanies(state),
    searched: getCompaniesSearched(state),
    loaded: areCompaniesLoaded(state),
  };
}

CompaniesSelect.propTypes = {};

CompaniesSelect.defaultProps = {};

export default injectIntl(
  connect(mapStateToProps, { fetch: fetchCompaniesRequest })(CompaniesSelect)
);
