import { LOCATION_CHANGE } from 'connected-react-router';
import {
  SAVE_LOGO_REQUEST,
  SAVE_LOGO_SUCCESS,
  SAVE_LOGO_FAILURE,
  SET_LOGO_PREVIEW,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialstate = {
  saveLogoError: false,
  saveLogoSuccess: null,
  saveLogoLoaded: true,
};

export default function logo(state = initialstate, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialstate;
    case SAVE_LOGO_REQUEST: {
      return {
        ...state,
        saveLogoLoaded: false,
        saveLogoSuccess: null,
        saveLogoError: false,
      };
    }
    case SAVE_LOGO_SUCCESS:
      return {
        ...state,
        saveLogoLoaded: true,
        saveLogoSuccess: action.success,
        saveLogoError: false,
      };
    case SAVE_LOGO_FAILURE:
      return {
        ...state,
        saveLogoLoaded: true,
        saveLogoSuccess: null,
        saveLogoError: true,
      };
    case SET_LOGO_PREVIEW:
      return {
        ...state,
        ...action.data,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        saveLogoError: false,
        saveLogoSuccess: false,
        saveLogoLoaded: true,
      };
    default:
      return state;
  }
}
