import { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL } from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialState = {
  isOpen: false,
  modalAction: null,
  modalCallback: null,
  message: '',
  actionParams: null,
};

export default function confirm(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case SHOW_CONFIRM_MODAL:
      return {
        isOpen: true,
        modalAction: action.modalAction,
        modalCallback: action.modalCallback,
        actionParams: action.actionParams,
        message: action.message,
      };
    case HIDE_CONFIRM_MODAL:
      return initialState;
    default:
      return state;
  }
}
