export default class VocalAssistantSystemUtils {
  static browserType = {
    CHROME: 0,
    SAFARI: 1,
    FIREFOX: 2,
    IE: 4,
    OPERA: 5,
    EDGE: 6,
    UNKNOWN: 99,
  };

  static systemOs = {
    WINDOWS: 0,
    LINUX: 1,
    MAC: 2,
    ANDROID: 10,
    IPHONE: 11,
    IPAD: 12,
    IPOD: 13,
    WINDOWSMOBILE: 14,
    SOLARIS: 15,
    UNIX: 16,
    UNKNOWN: 99,
  };

  static type = this.browserType.UNKNOWN;

  static canReadFile = false;

  static canUseFile = false;

  static canUseBlob = false;

  static canUseFS =
    window.webkitRequestFileSystem || window.requestFileSystem || false;

  static canUseMedia = !!(
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  );

  static type = this.browserType.UNKNOWN;

  static versionFull = '';

  static version = -1;

  static language = 'Unknown';

  static os = this.systemOs.UNKNOWN;

  static width = -1;

  static height = -1;

  static hasTouch = 'ontouchstart' in window;

  static voices = null;

  /**
   * Init configurations
   * @function
   */
  static init = () => {
    // / load all speech voices
    if (window.speechSynthesis) {
      // / chrome load voices asynchronously
      window.speechSynthesis.onvoiceschanged = () => {
        this.voices = window.speechSynthesis.getVoices();
      };
      this.voices = window.speechSynthesis.getVoices();
    }
    try {
      this.canReadFile = (File && FileReader) || false;
    } catch (err) {}
    try {
      this.canUseFile = File || false;
    } catch (err) {}
    try {
      this.canUseBlob = Blob || false;
    } catch (err) {}

    try {
      const testCSS = (prop) => {
        return prop in document['documentElement']['style'];
      };

      this.os = this.detectOs();

      // Opera 8.0+
      if (navigator.userAgent.toLowerCase().indexOf('op') > -1) {
        this.type = this.browserType.OPERA;
        this.versionFull = navigator.userAgent.match(/op(?:\w)*\/(.*)/i)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.language;
        // this.canReadFile = false; // BUG ? blob.slice seems to work in strange way, returning whole file when send by formData
      }
      // FF 0.8+
      else if (testCSS('MozBoxSizing')) {
        this.type = this.browserType.FIREFOX;
        this.versionFull = navigator.userAgent.match(/Firefox\/(.*)/)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.language;
      }
      // At least Safari 3+: "[object HTMLElementConstructor]"
      // else if (Object['prototype']['toString'].call(window['HTMLElement']).indexOf('Constructor') > 0) {
      else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        this.type = this.browserType.SAFARI;
        this.versionFull = navigator.appVersion.match(/Version\/(.*?) /)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.language;
      }
      // EDGE
      else if (/Edge\/\d./i.test(navigator.userAgent)) {
        this.type = this.browserType.EDGE;
        this.versionFull = navigator.appVersion.match(/Edge\/(.*)/)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.userLanguage;
      }
      // Chrome 1+
      else if (testCSS('WebkitTransform')) {
        this.type = this.browserType.CHROME;
        this.versionFull = navigator.appVersion.match(/Chrome\/(.*?) /)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.language;
      }
      // At least IE6
      else if (/* @cc_on!@ */ false || testCSS('msTransform')) {
        this.type = this.browserType.IE;
        this.versionFull = navigator.appVersion.match(/MSIE (.*?) /)[1];
        this.version = parseInt(this.versionFull, 10);
        this.language = navigator.userLanguage;
      }
    } catch (error) {}
  };

  static detectOs = () => {
    const navigatorPlatform = navigator.platform.toLowerCase();

    const navigatorUserAgent = navigator.userAgent.toLowerCase();

    if (navigatorPlatform.indexOf('win') !== -1) {
      if (navigatorUserAgent.indexOf('mobile') !== -1)
        return this.systemOs.WINDOWSMOBILE || this.systemOs.WINDOWS;
    } else if (navigatorPlatform.indexOf('mac') !== -1) return this.os.MAC;
    else if (navigatorUserAgent.indexOf('iphone') !== -1)
      return this.systemOs.IPHONE;
    else if (navigatorUserAgent.indexOf('ipad') !== -1) return this.os.IPAD;
    else if (navigatorUserAgent.indexOf('ipod') !== -1) return this.os.IPOD;
    else if (navigatorPlatform.indexOf('linux') !== -1) {
      if (
        navigatorUserAgent.indexOf('android') !== -1 ||
        navigatorPlatform.indexOf('arm') !== -1
      )
        return this.systemOs.ANDROID || this.systemOs.LINUX;
    } else if (navigatorPlatform.indexOf('sun') !== -1)
      return this.systemOs.SOLARIS;
    else if (navigatorPlatform.indexOf('x11') !== -1) return this.systemOs.UNIX;

    return this.systemOs.UNKNOWN;
  };

  /**
   * Check if the browser is chrome
   * @function
   * @return {boolean}
   */
  static isChrome = () => {
    return this.type === this.browserType.CHROME;
  };

  /**
   * Check if the browser is firefox
   * @function
   * @return {boolean}
   */
  static isFirefox = () => {
    return this.type === this.browserType.FIREFOX;
  };

  /**
   * Check if the browser is safari
   * @function
   * @return {boolean}
   */
  static isSafari = () => {
    return this.type === this.browserType.SAFARI;
  };

  /**
   * Check if the browser is opera
   * @function
   * @return {boolean}
   */
  static isOpera = () => {
    return this.type === this.browserType.OPERA;
  };

  /**
   * Check if the browser is internet explorer
   * @function
   * @return {boolean}
   */
  static isIE = () => {
    return this.type === this.browserType.IE;
  };

  /**
   * Check if the browser is edge
   * @function
   * @return {boolean}
   */
  static isEdge = () => {
    return this.type === this.browserType.EDGE;
  };

  /**
   * Check if the OS is windows
   * @function
   * @return {boolean}
   */
  static isWindowsOs = () => {
    return this.os === this.systemOs.WINDOWS;
  };

  /**
   * Check if the OS is a mobile OS
   * @function
   * @return {boolean}
   */
  static isMobileOs = () => {
    return (
      this.os === this.systemOs.ANDROID ||
      this.os === this.systemOs.IPHONE ||
      this.os === this.systemOs.IPAD ||
      this.os === this.systemOs.IPOD ||
      this.os === this.systemOs.WINDOWSMOBILE
    );
  };

  static getSpeechVoice = (language) => {
    switch (this.os) {
      case this.systemOs.LINUX:
        return this.voices.filter((voice) => {
          return (
            voice.lang === language &&
            (language === 'it-IT' || voice.name.indexOf('Female') >= 0)
          );
        })[0];
      case this.systemOs.MAC:
        return this.voices.filter((voice) => {
          return (
            voice.lang === language &&
            (language === 'it-IT' || voice.name.indexOf('Female') >= 0)
          );
        })[0];
      default:
        return this.voices.filter((voice) => {
          return (
            voice.lang === language &&
            (language === 'it-IT' || voice.name.indexOf('Female') >= 0)
          );
        })[0];
    }
  };

  static isTtsSupported = () => {
    return this.isChrome() || this.isSafari();
  };

  static isSpeechRecognitionSupported = () => {
    return (
      typeof SpeechRecognition !== 'undefined' ||
      typeof webkitSpeechRecognition !== 'undefined'
    );
  };
}

VocalAssistantSystemUtils.init();
