import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';

const messages = defineMessages({
  title: {
    id: 'Chat.title',
    defaultMessage: 'Chat',
  },
  messages: {
    id: 'Chat.messages',
    defaultMessage: 'Messages',
  },
  scheduledMessages: {
    id: 'Chat.scheduledMessages',
    defaultMessage: 'Scheduled messages',
  },
});

const Chat = ({ intl: { formatMessage }, children }) => {
  const nav = [
    {
      id: 'Chat.messages',
      href: '/chat/messages',
      label: messages.messages,
    },
    {
      id: 'Chat.scheduledMessages',
      href: '/chat/scheduled',
      label: messages.scheduledMessages,
    },
  ];
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div className="yn-section-with-nav-scrolling">{children}</div>
    </div>
  );
};

export default injectIntl(Chat);
