import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { getSupplier } from '../../../../js/phone/selectors';
import MePhoneSettings from './MePhoneSettings';
import { loadOwnGreetingRequest } from '../../../../js/vbox/actions';
import { PhoneEnums } from '../../../../js/phone/PhoneUtils';
import { getAbilisVboxGreeting } from '../../../../js/me/selectors';
import { getNethesisVboxGreeting } from '../../../../js/vbox/selectors';

const MeCurrentGreetingMessage = React.lazy(() =>
  import('./MeCurrentGreetingMessage')
);
const MeUploadGreetingMessage = React.lazy(() =>
  import('./MeUploadGreetingMessage')
);
const HelpButton = React.lazy(() =>
  import('../../../../components/HelpButton')
);
class MePhoneGreetingMessagePage extends Component {
  componentDidMount() {
    const { supplier } = this.props;
    if (supplier === PhoneEnums.PbxType.NETHESIS) {
      this.props.loadGreetingMessage();
    }
  }

  componentDidUpdate(prevprops) {
    const { supplier } = this.props;
    if (
      prevprops.supplier !== supplier &&
      supplier === PhoneEnums.PbxType.NETHESIS
    ) {
      this.props.loadGreetingMessage();
    }
  }

  render() {
    const { greeting, supplier } = this.props;
    return (
      <MePhoneSettings scrolling>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <>
              <div className="text-right">
                <HelpButton fileName="vbox-rec" />
              </div>
              <div className="border rounded p-2">
                <MeCurrentGreetingMessage
                  supplier={supplier}
                  message={greeting}
                />
                <MeUploadGreetingMessage
                  supplier={supplier}
                  /*onSave={supplier && supplier.toLowerCase() === PhoneEnums.PbxType.NETHESIS.toLowerCase() ? this.saveNethesis}*/
                />
              </div>
            </>
          </React.Suspense>
        </ErrorBoundary>
      </MePhoneSettings>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
    greeting:
      getSupplier(state) === PhoneEnums.PbxType.ABILIS
        ? getAbilisVboxGreeting(state)
        : getNethesisVboxGreeting(state),
  };
}

export default connect(mapStateToProps, {
  loadGreetingMessage: loadOwnGreetingRequest,
})(MePhoneGreetingMessagePage);
