import moment from 'moment';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_ME_CALLS_REQUEST,
  FETCH_ME_CALLS_SUCCESS,
  FETCH_ME_CALLS_FAILURE,
  SET_PBX_CALLS_FILTER,
  FETCH_PBX_CALLS_REQUEST,
  FETCH_PBX_CALLS_SUCCESS,
  FETCH_PBX_CALLS_FAILURE,
  DELETE_RECORDED_CALL_FAILURE,
  DELETE_RECORDED_CALL_REQUEST,
  DELETE_RECORDED_CALL_SUCCESS,
  DELETE_OWN_CALL_REQUEST,
  DELETE_OWN_CALL_SUCCESS,
  DELETE_OWN_CALL_FAILURE,
  DELETE_OTHER_CALL_REQUEST,
  DELETE_OTHER_CALL_SUCCESS,
  DELETE_OTHER_CALL_FAILURE,
  INSERT_CALLS_NOTES_REQUEST,
  INSERT_CALLS_NOTES_SUCCESS,
  INSERT_CALLS_NOTES_FAILURE,
  SET_ME_CALLS_FILTER,
  SET_QUEUES_CALLS_FILTER,
  FETCH_QUEUES_CALLS_REQUEST,
  FETCH_QUEUES_CALLS_SUCCESS,
  FETCH_QUEUES_CALLS_FAILURE,
} from './types';

const setFilterStart = () => {
  const start = moment().toDate();
  start.setDate(start.getDate() - 1);
  start.setHours(0);
  start.setMinutes(0);
  start.setSeconds(0);
  start.setMilliseconds(0);
  return start;
};

const setFilterEnd = () => {
  return moment().endOf('day').toDate();
};

const getInitialState = () => ({
  me: {
    filterLost: {
      start: setFilterStart(),
      end: setFilterEnd(),
    },
    filterIn: {
      start: setFilterStart(),
      end: setFilterEnd(),
    },
    filterOut: {
      start: setFilterStart(),
      end: setFilterEnd(),
    },
    loaded: true,
    error: null,
    errorDeleting: false,
    calls: [],
  },
  pbx: {
    filter: {
      start: setFilterStart(),
      end: setFilterEnd(),
      extensions: [],
      in: true,
      lost: true,
      out: true,
    },
    loaded: true,
    error: null,
    userErrors: [],
    calls: [],
    errorDeleting: false,
  },
  queues: {
    filter: {
      start: setFilterStart(),
      end: setFilterEnd(),
      queues: [],
      in: true,
      lost: true,
    },
    loaded: true,
    error: null,
    calls: [],
    errorDeleting: false,
  },
  insertNotesLoading: false,
  insertNotesError: false,
});
export function cdr(state = getInitialState(), action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return getInitialState();
    case SET_ME_CALLS_FILTER: {
      const context = action.filter.in
        ? 'filterIn'
        : action.filter.out
        ? 'filterOut'
        : 'filterLost';

      return {
        ...state,
        me: {
          ...state.me,
          [context]: {
            start: action.filter.start,
            end: action.filter.end,
          },
        },
      };
    }
    case FETCH_ME_CALLS_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: false,
          error: false,
          errorDeleting: false,
        },
      };

    case FETCH_ME_CALLS_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          calls: action.calls,
          error: false,
        },
      };
    case FETCH_ME_CALLS_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          loaded: true,
          calls: [],
          error: true,
        },
      };
    case SET_PBX_CALLS_FILTER:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          filter: action.filter,
        },
      };
    case FETCH_PBX_CALLS_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: false,
          userErrors: [],
          error: false,
          errorDeleting: false,
        },
      };
    case FETCH_PBX_CALLS_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: true,
          calls: action.payload.data,
          userErrors: action.payload.userErrors,
          error: false,
        },
      };
    case FETCH_PBX_CALLS_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          loaded: true,
          userErrors: [],
          calls: [],
          error: true,
        },
      };
    case DELETE_RECORDED_CALL_REQUEST:
    case DELETE_OWN_CALL_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          errorDeleting: false,
        },
      };
    case DELETE_RECORDED_CALL_SUCCESS: {
      return {
        ...state,
        me: {
          ...state.me,
          calls: state.me.calls.map((call) => {
            if (call.recordedFile === action.filename) {
              return {
                ...call,
                recordedFile: null,
              };
            }
            return call;
          }),
          errorDeleting: false,
        },
      };
    }
    case DELETE_OWN_CALL_SUCCESS: {
      return {
        ...state,
        me: {
          ...state.me,
          calls: state.me.calls.filter((call) => call.id !== action.callId),
          errorDeleting: false,
        },
      };
    }
    case DELETE_RECORDED_CALL_FAILURE:
    case DELETE_OWN_CALL_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          errorDeleting: true,
        },
      };
    case DELETE_OTHER_CALL_REQUEST:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          errorDeleting: false,
        },
      };
    case DELETE_OTHER_CALL_SUCCESS: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          calls: state.pbx.calls.filter((call) => call.id !== action.callId),
          errorDeleting: false,
        },
      };
    }
    case DELETE_OTHER_CALL_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          errorDeleting: true,
        },
      };
    case INSERT_CALLS_NOTES_REQUEST:
      return {
        ...state,
        insertNotesLoading: true,
        insertNotesError: false,
      };
    case INSERT_CALLS_NOTES_SUCCESS: {
      if (action.context === 'abook' || action.context === 'hotel') {
        return {
          ...state,
          insertNotesLoading: false,
          insertNotesError: false,
        };
      }
      return {
        ...state,
        insertNotesLoading: false,
        insertNotesError: false,
        [action.context]: {
          ...state[action.context],
          calls: state[action.context].calls.map((call) => {
            if (call.id === action.params.call) {
              call.notes = call.notes
                ? [
                    ...call.notes,
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ]
                : [
                    {
                      user: action.params.user,
                      text: action.params.text,
                      time: moment().utc().valueOf(),
                    },
                  ];
              return { ...call };
            }
            return call;
          }),
          errorDeleting: false,
        },
      };
    }
    case INSERT_CALLS_NOTES_FAILURE:
      return {
        ...state,
        insertNotesLoading: false,
        insertNotesError: true,
      };
    case SET_QUEUES_CALLS_FILTER:
      return {
        ...state,
        queues: {
          ...state.queues,
          filter: action.filter,
        },
      };
    case FETCH_QUEUES_CALLS_REQUEST:
      return {
        ...state,
        queues: {
          ...state.queues,
          loaded: false,
          error: false,
          errorDeleting: false,
        },
      };

    case FETCH_QUEUES_CALLS_SUCCESS:
      return {
        ...state,
        queues: {
          ...state.queues,
          loaded: true,
          calls: action.calls,
          error: false,
        },
      };
    case FETCH_QUEUES_CALLS_FAILURE:
      return {
        ...state,
        queues: {
          ...state.queues,
          loaded: true,
          calls: [],
          error: true,
        },
      };
    default:
      return state;
  }
}
