import {
  FETCH_SMS_LIST_REQUEST,
  FETCH_SMS_LIST_SUCCESS,
  FETCH_SMS_LIST_FAILURE,
  SET_SMS_LIST_FILTER,
  SEND_SMS_REQUEST,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  FETCH_SMS_CREDITS_REQUEST,
  FETCH_SMS_CREDITS_FAILURE,
  FETCH_SMS_CREDITS_SUCCESS,
  FETCH_SMS_SCHEDULED_REQUEST,
  FETCH_SMS_SCHEDULED_SUCCESS,
  FETCH_SMS_SCHEDULED_FAILURE,
  SAVE_SMS_SCHEDULED_REQUEST,
  SAVE_SMS_SCHEDULED_SUCCESS,
  SAVE_SMS_SCHEDULED_FAILURE,
  DELETE_SMS_SCHEDULED_REQUEST,
  DELETE_SMS_SCHEDULED_SUCCESS,
  DELETE_SMS_SCHEDULED_FAILURE,
  FETCH_SMS_ALIAS_REQUEST,
  FETCH_SMS_ALIAS_SUCCESS,
  FETCH_SMS_ALIAS_FAILURE,
  SAVE_SMS_ALIAS_SUCCESS,
  SAVE_SMS_ALIAS_REQUEST,
  SAVE_SMS_ALIAS_FAILURE,
  FETCH_SMS_CAMPAIGNS_REQUEST,
  FETCH_SMS_CAMPAIGNS_SUCCESS,
  FETCH_SMS_CAMPAIGNS_FAILURE,
  FETCH_SMS_CAMPAIGN_REQUEST,
  FETCH_SMS_CAMPAIGN_SUCCESS,
  FETCH_SMS_CAMPAIGN_FAILURE,
  SAVE_SMS_CAMPAIGN_REQUEST,
  SAVE_SMS_CAMPAIGN_SUCCESS,
  SAVE_SMS_CAMPAIGN_FAILURE,
  DELETE_SMS_CAMPAIGN_SUCCESS,
  DELETE_SMS_CAMPAIGN_REQUEST,
  DELETE_SMS_CAMPAIGN_FAILURE,
  BUY_SMS_PACKAGES_REQUEST,
  BUY_SMS_PACKAGES_SUCCESS,
  BUY_SMS_PACKAGES_FAILURE,
} from './types';

export const setSMSListFilter = (payload) => ({
  type: SET_SMS_LIST_FILTER,
  payload,
});
export const fetchSMSListRequest = (payload) => ({
  type: FETCH_SMS_LIST_REQUEST,
  payload,
});
export const fetchSMSListSuccess = (payload) => ({
  type: FETCH_SMS_LIST_SUCCESS,
  payload,
});
export const fetchSMSListFailure = (payload) => ({
  type: FETCH_SMS_LIST_FAILURE,
  payload,
});
export const sendSMSRequest = (payload) => ({
  type: SEND_SMS_REQUEST,
  payload,
});
export const sendSMSSuccess = () => ({
  type: SEND_SMS_SUCCESS,
});
export const sendSMSFailure = (payload) => ({
  type: SEND_SMS_FAILURE,
  payload,
});
export const fetchSMSCreditsRequest = () => ({
  type: FETCH_SMS_CREDITS_REQUEST,
});
export const fetchSMSCreditsSuccess = (payload) => ({
  type: FETCH_SMS_CREDITS_SUCCESS,
  payload,
});
export const fetchSMSCreditsFailure = (payload) => ({
  type: FETCH_SMS_CREDITS_FAILURE,
  payload,
});
export const fetchSMSScheduledRequest = () => ({
  type: FETCH_SMS_SCHEDULED_REQUEST,
});
export const fetchSMSScheduledSuccess = (payload) => ({
  type: FETCH_SMS_SCHEDULED_SUCCESS,
  payload,
});
export const fetchSMSScheduledFailure = (payload) => ({
  type: FETCH_SMS_SCHEDULED_FAILURE,
  payload,
});
export const saveSMSScheduledRequest = (payload) => ({
  type: SAVE_SMS_SCHEDULED_REQUEST,
  payload,
});
export const saveSMSScheduledSuccess = () => ({
  type: SAVE_SMS_SCHEDULED_SUCCESS,
});
export const saveSMSScheduledFailure = (payload) => ({
  type: SAVE_SMS_SCHEDULED_FAILURE,
  payload,
});
export const deleteSMSScheduledRequest = (payload) => ({
  type: DELETE_SMS_SCHEDULED_REQUEST,
  payload,
});
export const deleteSMSScheduledSuccess = () => ({
  type: DELETE_SMS_SCHEDULED_SUCCESS,
});
export const deleteSMSScheduledFailure = (payload) => ({
  type: DELETE_SMS_SCHEDULED_FAILURE,
  payload,
});
export const fetchSMSAliasRequest = () => ({
  type: FETCH_SMS_ALIAS_REQUEST,
});
export const fetchSMSAliasSuccess = (payload) => ({
  type: FETCH_SMS_ALIAS_SUCCESS,
  payload,
});
export const fetchSMSAliasFailure = (payload) => ({
  type: FETCH_SMS_ALIAS_FAILURE,
  payload,
});
export const saveSMSAliasRequest = (payload) => ({
  type: SAVE_SMS_ALIAS_REQUEST,
  payload,
});
export const saveSMSAliasSuccess = () => ({
  type: SAVE_SMS_ALIAS_SUCCESS,
});
export const saveSMSAliasFailure = (payload) => ({
  type: SAVE_SMS_ALIAS_FAILURE,
  payload,
});
export const fetchSMSCampaignsRequest = (payload) => ({
  type: FETCH_SMS_CAMPAIGNS_REQUEST,
  payload,
});
export const fetchSMSCampaignsSuccess = (payload) => ({
  type: FETCH_SMS_CAMPAIGNS_SUCCESS,
  payload,
});
export const fetchSMSCampaignsFailure = (payload) => ({
  type: FETCH_SMS_CAMPAIGNS_FAILURE,
  payload,
});
export const fetchSMSCampaignRequest = (payload) => ({
  type: FETCH_SMS_CAMPAIGN_REQUEST,
  payload,
});
export const fetchSMSCampaignSuccess = (payload) => ({
  type: FETCH_SMS_CAMPAIGN_SUCCESS,
  payload,
});
export const fetchSMSCampaignFailure = (payload) => ({
  type: FETCH_SMS_CAMPAIGN_FAILURE,
  payload,
});
export const saveSMSCampaignRequest = (payload) => ({
  type: SAVE_SMS_CAMPAIGN_REQUEST,
  payload,
});
export const saveSMSCampaignSuccess = () => ({
  type: SAVE_SMS_CAMPAIGN_SUCCESS,
});
export const saveSMSCampaignFailure = (payload) => ({
  type: SAVE_SMS_CAMPAIGN_FAILURE,
  payload,
});
export const deleteSMSCampaignRequest = (payload) => ({
  type: DELETE_SMS_CAMPAIGN_REQUEST,
  payload,
});
export const deleteSMSCampaignSuccess = () => ({
  type: DELETE_SMS_CAMPAIGN_SUCCESS,
});
export const deleteSMSCampaignFailure = (payload) => ({
  type: DELETE_SMS_CAMPAIGN_FAILURE,
  payload,
});
export const buySMSPackagesRequest = (payload) => ({
  type: BUY_SMS_PACKAGES_REQUEST,
  payload,
});
export const buySMSPackagesSuccess = () => ({
  type: BUY_SMS_PACKAGES_SUCCESS,
});
export const buySMSPackagesFailure = (errors) => ({
  type: BUY_SMS_PACKAGES_FAILURE,
  errors,
});
