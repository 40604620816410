import moment from 'moment';
import AckHandler from '../websocket/AckHandler';
import WsManager from '../websocket/WsManager';
import { generateAckUid, WsEnums } from '../websocket/WsUtils';
import { ChatEnums } from './ChatUtils';
import {
  fetchConversationsListSuccess,
  fetchConversationsListFailure,
  receiveChatMessage,
  sendChatMessageCC,
  sendChatMessageSuccess,
  sendChatMessageFailure,
  receiveDeliveryMessage,
  sendDeliveryMessageCC,
  sendDeliveryMessageSuccess,
  sendDeliveryMessageFailure,
  receiveReadMessage,
  receiveReadMessageCC,
  sendReadMessageSuccess,
  sendReadMessageFailure,
  sendReadAllMessageCC,
  sendReadAllMessageSuccess,
  sendReadAllMessageFailure,
  sendArchiveConversationSuccess,
  sendArchiveConversationFailure,
  sendUnarchiveConversationCC,
  sendUnarchiveConversationSuccess,
  sendUnarchiveConversationFailure,
  sendDeleteMessagesCC,
  sendDeleteMessagesSuccess,
  sendDeleteMessagesFailure,
  sendArchiveConversationCC,
  sendDeleteAllMessageCC,
  sendDeleteAllMessageSuccess,
  sendDeleteAllMessageFailure,
  receiveComposingStatus,
  receivePausedStatus,
  markImportantMessageSuccess,
  markImportantMessageFailure,
  markUnImportantMessageSuccess,
  markUnImportantMessageFailure,
  markImportantMessageCc,
  markUnImportantMessageCc,
} from './actions';

export default class ChatManager {
  static myId;

  static retrieveConversationsList = (oldAckUid) => {
    if (!ChatManager.myId) {
      return;
    }
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.CONVERSATIONS_LIST,
      ackUid,
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.retrieveConversationsList(ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} data - Message data
   * @param {Object} data.recipient - Data about recipient
   * @param {string} data.recipient.type - Type of recipient (USER | GROUP)
   * @param {string} data.recipient.id - Id of the recipient
   * @param {Object} data.message - Data about message
   * @param {string?} data.text - Text of the message
   * @param {Object?} data.file - Data about sending file
   * @param {string} data.file.stored - How the file is stored in fileserver
   * @param {string} data.file.name - Original name of the file
   * @param {number} data.file.size - Size of the file
   * @param {string} data.file.mime - Mime of the file
   * @param {string} data.file.thumbnail - Base64 thumbnail of the file
   * @param {number?} data.duration - Duration in milliseconds of the message (if timed message)
   * @param {string} data.ackUid - The ackUid (it is the temp id of the message till we receive its ack with real message id)
   */
  static sendChatMessage = (data) => {
    const timestamp = new Date().getTime();
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.NEW_MESSAGE,
      ackUid: data.ackUid,
      recipient: data.recipient,
      payload: {
        timestamp,
        message: { ...data.message, timestamp },
      },
    };
    AckHandler.addPendingAck({
      ackUid: data.ackUid,
      message,
      retry: () => {
        ChatManager.sendChatMessage(data, data.ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string} messageId - Id of the delivered message
   * @param {string} messageId - Id of the read message
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendDeliveryMessage = (senderHistoryId, messageId, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.DELIVERY,
      ackUid,
      payload: {
        historyId: messageId,
        senderHistoryId,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendDeliveryMessage(senderHistoryId, messageId, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string} timestamp - timestamp
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendDeliveryMessagesTillTime = (timestamp, oldAckUid) => {
    const beforeTimestamp = timestamp || moment().valueOf();
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.DELIVERY,
      ackUid,
      payload: {
        beforeTimestamp,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendDeliveryMessagesTillTime(beforeTimestamp, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string} senderHistoryId - Id of the read message for the sender
   * @param {string} messageId - Id of the read message
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendReadMessage = (senderHistoryId, messageId, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.READ,
      ackUid,
      payload: {
        historyId: messageId,
        senderHistoryId,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message: {
        ...message,
        payload: {
          ...message.payload,
          historyId: messageId,
        },
      },
      retry: () => {
        ChatManager.sendReadMessage(senderHistoryId, messageId, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} interlocutor - Conversation interlocutor (userId and groupId are mutually exclusive)
   * @param {number} interlocutor.userId - Id of the other user in conversation
   * @param {number} interlocutor.groupd - Id of the conversation group
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendReadAllMessages = (interlocutor, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.READ_ALL,
      ackUid,
      payload: {
        interlocutor,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendReadAllMessages(interlocutor, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string[]} messagesIds - Ids of the deleting messages
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendDeleteMessages = (messagesIds, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.DELETE,
      ackUid,
      payload: {
        ids: messagesIds,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendDeleteMessages(messagesIds, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} interlocutor - Conversation interlocutor (userId and groupId are mutually exclusive)
   * @param {number} interlocutor.userId - Id of the other user in conversation
   * @param {number} interlocutor.groupd - Id of the conversation group
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendDeleteAllMessages = (interlocutor, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.DELETE_ALL,
      ackUid,
      payload: {
        interlocutor,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendDeleteAllMessages(interlocutor, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} interlocutor - Conversation interlocutor (userId and groupId are mutually exclusive)
   * @param {number} interlocutor.userId - Id of the other user in conversation
   * @param {number} interlocutor.groupd - Id of the conversation group
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendArchiveConversation = (interlocutor, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.ARCHIVE,
      ackUid,
      payload: {
        interlocutor,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendArchiveConversation(interlocutor, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} interlocutor - Conversation interlocutor (userId and groupId are mutually exclusive)
   * @param {number} interlocutor.userId - Id of the other user in conversation
   * @param {number} interlocutor.groupd - Id of the conversation group
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendUnarchiveConversation = (interlocutor, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.UNARCHIVE,
      ackUid,
      payload: {
        interlocutor,
        statusTimestamp: new Date().getTime(),
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.sendArchiveConversation(interlocutor, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  static sendComposingStatus = (recipient) => {
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.STATUS,
      action: ChatEnums.ChatActions.COMPOSING,
      recipient,
    };
    WsManager.sendMessage(message);
  };

  static sendPausedStatus = (recipient) => {
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.STATUS,
      action: ChatEnums.ChatActions.PAUSED,
      recipient,
    };
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string} messageId - Id of the message that is being marked
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static markImportantMessage = (messageId, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.MARK_IMPORTANT,
      ackUid,
      payload: {
        historyId: messageId,
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.markImportantMessage(messageId, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {string} messageId - Id of the message that is being unmarked
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static markUnImportantMessage = (messageId, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(ChatManager.myId);
    const message = {
      channel: WsEnums.Channels.CHAT,
      topic: ChatEnums.ChatTopics.MESSAGES,
      action: ChatEnums.ChatActions.MARK_UNIMPORTANT,
      ackUid,
      payload: {
        historyId: messageId,
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        ChatManager.markUnImportantMessage(messageId, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  static manageMessagesEvent = (data) => {
    let action;
    let payload;
    let oldPayload;
    if (data.action.endsWith('_ACK')) {
      oldPayload = AckHandler.checkMessage(data);
    }
    switch (data.action) {
      case ChatEnums.ChatActions.CONVERSATIONS_LIST_ACK:
        if (data.payload.success) {
          action = fetchConversationsListSuccess;
          payload = data.payload.conversations.map((c) => ({
            ...c,
            lastHistory: c.lastHistory
              ? {
                  ...c.lastHistory,
                  message: c.lastHistory.message
                    ? {
                        ...c.lastHistory.message,
                        file: c.lastHistory.message.file
                          ? { ...c.lastHistory.message.file, thumbnail: null }
                          : null,
                      }
                    : null,
                }
              : null,
          }));
        } else {
          action = fetchConversationsListFailure;
        }
        break;
      case ChatEnums.ChatActions.NEW_MESSAGE:
        action = receiveChatMessage;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.NEW_MESSAGE_CC:
        action = sendChatMessageCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.NEW_MESSAGE_ACK:
        if (data.payload.success) {
          action = sendChatMessageSuccess;
          payload = {
            ackUid: data.ackUid,
            id: data.payload.historyId,
          };
        } else {
          action = sendChatMessageFailure;
          payload = data.ackUid;
        }
        break;
      case ChatEnums.ChatActions.DELIVERY:
        action = receiveDeliveryMessage;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.DELIVERY_CC:
        action = sendDeliveryMessageCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.DELIVERY_ACK:
        if (data.payload.success) {
          action = sendDeliveryMessageSuccess;
        } else {
          action = sendDeliveryMessageFailure;
        }
        break;
      case ChatEnums.ChatActions.READ:
        action = receiveReadMessage;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.READ_CC:
        action = receiveReadMessageCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.READ_ACK: {
        if (data.payload.success) {
          action = sendReadMessageSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendReadMessageFailure;
        }
        break;
      }
      case ChatEnums.ChatActions.READ_ALL_CC:
        action = sendReadAllMessageCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.READ_ALL_ACK: {
        if (data.payload.success) {
          action = sendReadAllMessageSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendReadAllMessageFailure;
          ({ payload } = oldPayload);
        }
        break;
      }
      case ChatEnums.ChatActions.DELETE_CC:
        action = sendDeleteMessagesCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.DELETE_ACK: {
        if (data.payload.success) {
          action = sendDeleteMessagesSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendDeleteMessagesFailure;
          payload = oldPayload;
        }
        break;
      }
      case ChatEnums.ChatActions.DELETE_ALL_CC:
        action = sendDeleteAllMessageCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.DELETE_ALL_ACK: {
        if (data.payload.success) {
          action = sendDeleteAllMessageSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendDeleteAllMessageFailure;
          ({ payload } = oldPayload);
        }
        break;
      }
      case ChatEnums.ChatActions.ARCHIVE_CC:
        action = sendArchiveConversationCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.ARCHIVE_ACK: {
        if (data.payload.success) {
          action = sendArchiveConversationSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendArchiveConversationFailure;
          payload = oldPayload;
        }
        break;
      }
      case ChatEnums.ChatActions.UNARCHIVE_CC:
        action = sendUnarchiveConversationCC;
        ({ payload } = data);
        break;
      case ChatEnums.ChatActions.UNARCHIVE_ACK: {
        if (data.payload.success) {
          action = sendUnarchiveConversationSuccess;
          ({ payload } = oldPayload);
        } else {
          action = sendUnarchiveConversationFailure;
          payload = oldPayload;
        }
        break;
      }
      case ChatEnums.ChatActions.MARK_IMPORTANT_CC: {
        action = markImportantMessageCc;
        ({ payload } = data);
        break;
      }
      case ChatEnums.ChatActions.MARK_IMPORTANT_ACK: {
        if (data.payload.success) {
          action = markImportantMessageSuccess;
          ({ payload } = oldPayload);
        } else {
          action = markImportantMessageFailure;
          payload = oldPayload;
        }
        break;
      }
      case ChatEnums.ChatActions.MARK_UNIMPORTANT_CC: {
        action = markUnImportantMessageCc;
        ({ payload } = data);
        break;
      }
      case ChatEnums.ChatActions.MARK_UNIMPORTANT_ACK: {
        if (data.payload.success) {
          action = markUnImportantMessageSuccess;
          ({ payload } = oldPayload);
        } else {
          action = markUnImportantMessageFailure;
          payload = oldPayload;
        }
        break;
      }
      default:
        return null;
    }
    return {
      action,
      data: payload,
    };
  };

  static manageInfoEvent = (data) => {
    return {
      action: receiveChatMessage,
      data: data.payload,
    };
  };

  static manageStatusEvent = (data) => {
    const payload = {
      ...data.payload,
    };
    let action;
    switch (data.action) {
      case ChatEnums.ChatActions.COMPOSING:
        action = receiveComposingStatus;
        break;
      case ChatEnums.ChatActions.PAUSED:
        action = receivePausedStatus;
        break;
      default:
        return null;
    }
    return {
      action,
      data: payload,
    };
  };

  static manageChatEvent = (data) => {
    const { topic } = data;
    switch (topic) {
      case ChatEnums.ChatTopics.MESSAGES:
        return ChatManager.manageMessagesEvent(data);
      case ChatEnums.ChatTopics.INFO:
        return ChatManager.manageInfoEvent(data);
      case ChatEnums.ChatTopics.STATUS:
        return ChatManager.manageStatusEvent(data);
      default:
        return null;
    }
  };
}
