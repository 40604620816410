import {
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE,
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS,
  CREATE_WORKING_HOURS_PLAN_FAILURE,
  CREATE_WORKING_HOURS_PLAN_REQUEST,
  CREATE_WORKING_HOURS_PLAN_SUCCESS,
  DELETE_WORKING_HOURS_PLAN_FAILURE,
  DELETE_WORKING_HOURS_PLAN_REQUEST,
  DELETE_WORKING_HOURS_PLAN_SUCCESS,
  GET_WORKING_HOURS_PLANS_FAILURE,
  GET_WORKING_HOURS_PLANS_REQUEST,
  GET_WORKING_HOURS_PLANS_SUCCESS,
  GET_WORKING_HOURS_PLAN_FAILURE,
  GET_WORKING_HOURS_PLAN_REQUEST,
  GET_WORKING_HOURS_PLAN_SUCCESS,
  UPDATE_WORKING_HOURS_PLAN_FAILURE,
  UPDATE_WORKING_HOURS_PLAN_REQUEST,
  UPDATE_WORKING_HOURS_PLAN_SUCCESS,
  WORKING_PLANS_CLEAR_ERRORS,
  ASSIGN_WORKING_HOURS_PLAN_REQUEST,
  ASSIGN_WORKING_HOURS_PLAN_SUCCESS,
  ASSIGN_WORKING_HOURS_PLAN_FAILURE,
  SAVE_WORKING_ROUND_REQUEST,
  SAVE_WORKING_ROUND_SUCCESS,
  SAVE_WORKING_ROUND_FAILURE,
  SAVE_WORKING_BREAK_REQUEST,
  SAVE_WORKING_BREAK_SUCCESS,
  SAVE_WORKING_BREAK_FAILURE,
} from './types';

export const createWorkingHoursPlanRequest = (payload) => ({
  type: CREATE_WORKING_HOURS_PLAN_REQUEST,
  payload,
});
export const createWorkingHoursPlanSuccess = (id) => ({
  type: CREATE_WORKING_HOURS_PLAN_SUCCESS,
  id,
});
export const createWorkingHoursPlanFailure = (errors) => ({
  type: CREATE_WORKING_HOURS_PLAN_FAILURE,
  errors,
});

export const updateWorkingHoursPlanRequest = (payload) => ({
  type: UPDATE_WORKING_HOURS_PLAN_REQUEST,
  payload,
});
export const updateWorkingHoursPlanSuccess = () => ({
  type: UPDATE_WORKING_HOURS_PLAN_SUCCESS,
});
export const updateWorkingHoursPlanFailure = (errors) => ({
  type: UPDATE_WORKING_HOURS_PLAN_FAILURE,
  errors,
});

export const getWorkingHoursPlanRequest = (id) => ({
  type: GET_WORKING_HOURS_PLAN_REQUEST,
  id,
});
export const getWorkingHoursPlanSuccess = (payload) => ({
  type: GET_WORKING_HOURS_PLAN_SUCCESS,
  payload,
});
export const getWorkingHoursPlanFailure = (errors) => ({
  type: GET_WORKING_HOURS_PLAN_FAILURE,
  errors,
});

export const getWorkingHoursPlansRequest = (params) => ({
  type: GET_WORKING_HOURS_PLANS_REQUEST,
  params,
});
export const getWorkingHoursPlansSuccess = (payload) => ({
  type: GET_WORKING_HOURS_PLANS_SUCCESS,
  payload,
});
export const getWorkingHoursPlansFailure = (errors) => ({
  type: GET_WORKING_HOURS_PLANS_FAILURE,
  errors,
});

export const assignWorkingHoursPlanToUserRequest = (id, userId) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST,
  id,
  userId,
});
export const assignWorkingHoursPlanToUserSuccess = () => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS,
});
export const assignWorkingHoursPlanToUserFailure = (errors) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE,
  errors,
});

export const deleteWorkingHoursPlanRequest = (id) => ({
  type: DELETE_WORKING_HOURS_PLAN_REQUEST,
  id,
});
export const deleteWorkingHoursPlanSuccess = () => ({
  type: DELETE_WORKING_HOURS_PLAN_SUCCESS,
});
export const deleteWorkingHoursPlanFailure = (errors) => ({
  type: DELETE_WORKING_HOURS_PLAN_FAILURE,
  errors,
});

export const assignWorkingHoursPlanToAllUsersRequest = (id) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST,
  id,
});
export const assignWorkingHoursPlanToAllUsersSuccess = () => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS,
});
export const assignWorkingHoursPlanToAllUsersFailure = (errors) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE,
  errors,
});
export const clearWorkingHoursErrors = () => ({
  type: WORKING_PLANS_CLEAR_ERRORS,
});

export const assignWorkingHoursPlanRequest = (payload) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_REQUEST,
  payload,
});
export const assignWorkingHoursPlanSuccess = () => ({
  type: ASSIGN_WORKING_HOURS_PLAN_SUCCESS,
});
export const assignWorkingHoursPlanFailure = (errors) => ({
  type: ASSIGN_WORKING_HOURS_PLAN_FAILURE,
  errors,
});
export const saveWorkingRoundRequest = (payload) => ({
  type: SAVE_WORKING_ROUND_REQUEST,
  payload,
});
export const saveWorkingRoundSuccess = (payload) => ({
  type: SAVE_WORKING_ROUND_SUCCESS,
  payload,
});
export const saveWorkingRoundFailure = () => ({
  type: SAVE_WORKING_ROUND_FAILURE,
});
export const saveWorkingBreakRequest = (payload) => ({
  type: SAVE_WORKING_BREAK_REQUEST,
  payload,
});
export const saveWorkingBreakSuccess = (payload) => ({
  type: SAVE_WORKING_BREAK_SUCCESS,
  payload,
});
export const saveWorkingBreakFailure = () => ({
  type: SAVE_WORKING_BREAK_FAILURE,
});
