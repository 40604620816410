export const FETCH_VBOX_MESSAGES_REQUEST = 'FETCH_VBOX_MESSAGES_REQUEST';
export const FETCH_VBOX_MESSAGES_SUCCESS = 'FETCH_VBOX_MESSAGES_SUCCESS';
export const FETCH_VBOX_MESSAGES_FAILURE = 'FETCH_VBOX_MESSAGES_FAILURE';
export const SET_VBOX_MESSAGES_FILTER = 'SET_VBOX_MESSAGES_FILTER';
export const DELETE_VBOX_MESSAGES_REQUEST = 'DELETE_VBOX_MESSAGES_REQUEST';
export const DELETE_VBOX_MESSAGES_SUCCESS = 'DELETE_VBOX_MESSAGES_SUCCESS';
export const DELETE_VBOX_MESSAGES_FAILURE = 'DELETE_VBOX_MESSAGES_FAILURE';
export const FETCH_TVOX_SOUNDS_REQUEST = 'FETCH_TVOX_SOUNDS_REQUEST';
export const FETCH_TVOX_SOUNDS_SUCCESS = 'FETCH_TVOX_SOUNDS_SUCCESS';
export const FETCH_TVOX_SOUNDS_FAILURE = 'FETCH_TVOX_SOUNDS_FAILURE';
export const FETCH_TVOX_PRICE_REQUEST = 'FETCH_TVOX_PRICE_REQUEST';
export const FETCH_TVOX_PRICE_SUCCESS = 'FETCH_TVOX_PRICE_SUCCESS';
export const FETCH_TVOX_PRICE_FAILURE = 'FETCH_TVOX_PRICE_FAILURE';
export const PURCHASE_TVOX_MESSAGE_REQUEST = 'PURCHASE_TVOX_MESSAGE_REQUEST';
export const PURCHASE_TVOX_MESSAGE_SUCCESS = 'PURCHASE_TVOX_MESSAGE_SUCCESS';
export const PURCHASE_TVOX_MESSAGE_FAILURE = 'PURCHASE_TVOX_MESSAGE_FAILURE';
export const FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST =
  'FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST';
export const FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS =
  'FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS';
export const FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE =
  'FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE';
export const LOAD_OWN_GREETING_REQUEST = 'LOAD_OWN_GREETING_REQUEST';
export const LOAD_OWN_GREETING_SUCCESS = 'LOAD_OWN_GREETING_SUCCESS';
export const LOAD_OWN_GREETING_FAILURE = 'LOAD_OWN_GREETING_FAILURE';
export const SAVE_OWN_GREETING_REQUEST = 'SAVE_OWN_GREETING_REQUEST';
export const SAVE_OWN_GREETING_SUCCESS = 'SAVE_OWN_GREETING_SUCCESS';
export const SAVE_OWN_GREETING_FAILURE = 'SAVE_OWN_GREETING_FAILURE';
export const FETCH_ACTIVE_GREETING_MESSAGES_REQUEST =
  'FETCH_ACTIVE_GREETING_MESSAGES_REQUEST';
export const FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS =
  'FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS';
export const FETCH_ACTIVE_GREETING_MESSAGES_FAILURE =
  'FETCH_ACTIVE_GREETING_MESSAGES_FAILURE';
export const ACTIVATE_PBX_GREETING_REQUEST = 'ACTIVATE_PBX_GREETING_REQUEST';
export const ACTIVATE_PBX_GREETING_SUCCESS = 'ACTIVATE_PBX_GREETING_SUCCESS';
export const ACTIVATE_PBX_GREETING_FAILURE = 'ACTIVATE_PBX_GREETING_FAILURE';
export const DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST =
  'DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST';
export const DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS =
  'DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS';
export const DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE =
  'DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE';
export const SAVE_PBX_GREETING_REQUEST = 'SAVE_PBX_GREETING_REQUEST';
export const SAVE_PBX_GREETING_SUCCESS = 'SAVE_PBX_GREETING_SUCCESS';
export const SAVE_PBX_GREETING_FAILURE = 'SAVE_PBX_GREETING_FAILURE';
export const ME_RECEIVED_VBOX_NOTIFICATION = 'ME_RECEIVED_VBOX_NOTIFICATION';
export const PBX_RECEIVED_VBOX_NOTIFICATION = 'PBX_RECEIVED_VBOX_NOTIFICATION';
export const DELIVERED_TVOX_NOTIFICATION = 'DELIVERED_TVOX_NOTIFICATION';
export const LISTEN_VBOX_REQUEST = 'LISTEN_VBOX_REQUEST';
export const LISTEN_VBOX_SUCCESS = 'LISTEN_VBOX_SUCCESS';
export const LISTEN_VBOX_FAILURE = 'LISTEN_VBOX_FAILURE';
