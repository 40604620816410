import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const AbsenceManagementList = React.lazy(() =>
  import('./AbsenceManagementList')
);

const AbsenceManagementPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <AbsenceManagementList {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default AbsenceManagementPage;
