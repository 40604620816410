import React, { Component } from 'react';
import uniqueid from 'lodash.uniqueid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from './Avatar';
import { fetchAbookContactRequest } from '../js/contacts/actions';
import ContactDetailsPopover from './ContactDetailsPopover';
import { AVATAR_TYPES } from '../js/files/FileUtils';

class ContactAvatar extends Component {
  state = {
    popoverOpen: false,
    popoverTarget: uniqueid(`ContactAvatar-`),
  };

  toggle = () => {
    const { popoverOpen } = this.state;
    this.setState({ popoverOpen: !popoverOpen });
  };

  handleFetchData = () => {
    const { id, fetch } = this.props;
    this.setState({ popoverOpen: true });
    fetch(id);
  };

  render() {
    const {
      id,
      size,
      alt,
      src,
      imageSize,
      className,
      withPopover,
      clickable,
      error,
      type,
    } = this.props;
    const { popoverOpen, popoverTarget } = this.state;

    return (
      <>
        <div
          id={popoverTarget}
          onClick={withPopover && id ? this.handleFetchData : null}
          className={`d-inline-block ml-1 mb-1 ${className || ''}`}
        >
          <Avatar
            size={size}
            src={src}
            alt={alt}
            type={type}
            clickable={clickable || withPopover}
            imageSize={imageSize}
          />
        </div>
        {withPopover && !error && popoverOpen && (
          <ContactDetailsPopover
            id={id}
            isOpen={popoverOpen}
            target={popoverTarget}
            toggle={this.toggle}
          />
        )}
      </>
    );
  }
}

ContactAvatar.propTypes = {
  id: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  withPopover: PropTypes.bool,
  clickable: PropTypes.bool,
  imageSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.string,
};

ContactAvatar.defaultProps = {
  className: null,
  src: '',
  size: '',
  withPopover: false,
  clickable: false,
  imageSize: 'lg',
  type: AVATAR_TYPES.CONTACT,
};

export default connect(null, { fetch: fetchAbookContactRequest })(
  ContactAvatar
);
