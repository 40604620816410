import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { SMS_CREDITS_WARNING_LIMIT } from '../../../js/sms/SMSUtils';
import { fetchSMSCreditsRequest } from '../../../js/sms/actions';
import Alert from '../../../components/messages/Alert';

const messages = defineMessages({
  creditsWarningText: {
    id: 'SMSCreditsAlert.creditsWarningText',
    defaultMessage: 'Hei! You have only {credits} more available SMS!',
  },
  creditsAlertText: {
    id: 'SMSCreditsAlert.creditsAlertText',
    defaultMessage: 'Oh no! You have no more available SMS!',
  },
  creditsSuccessText: {
    id: 'SMSCreditsAlert.creditsSuccessText',
    defaultMessage: 'You have {credits} available SMS',
  },
});

const SMSCreditsAlert = ({ intl: { formatMessage }, credits, fetch }) => {
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <>
      {credits === 0 ? (
        <Alert text={formatMessage(messages.creditsAlertText)} type="danger" />
      ) : credits < SMS_CREDITS_WARNING_LIMIT ? (
        <Alert
          text={formatMessage(messages.creditsWarningText, { credits })}
          type="warning"
        />
      ) : (
        <Alert
          text={formatMessage(messages.creditsSuccessText, { credits })}
          type="success"
        />
      )}
    </>
  );
};

SMSCreditsAlert.propTypes = {
  credits: PropTypes.number.isRequired,
};

export default injectIntl(
  connect(null, { fetch: fetchSMSCreditsRequest })(SMSCreditsAlert)
);
