import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Fade } from 'reactstrap';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { isMePbxAdmin } from '../../../js/me/selectors';

const messages = defineMessages({
  title: {
    id: 'Documents.title',
    defaultMessage: 'Documents',
  },
  contract: {
    id: 'Documents.contract.navLabel',
    defaultMessage: 'Contractual data',
  },
  offices: {
    id: 'Documents.offices.navLabel',
    defaultMessage: 'Offices',
  },
  invoices: {
    id: 'Documents.invoices.navLabel',
    defaultMessage: 'Invoices',
  },
  phone: {
    id: 'Documents.phone.navLabel',
    defaultMessage: 'Phone Lines',
  },
});

const Documents = ({ intl: { formatMessage }, children, isPbxAdmin }) => {
  const nav = [
    {
      id: 'Documents.contract.linkLabel',
      href: '/documents/contract-data',
      label: messages.contract,
    },
  ];
  if (isPbxAdmin) {
    nav.push({
      id: 'Documents.offices.linkLabel',
      href: '/documents/offices',
      label: messages.offices,
    });
  }
  nav.push({
    id: 'Documents.invoices.linkLabel',
    href: '/documents/invoices',
    label: messages.invoices,
  });
  if (isPbxAdmin) {
    nav.push({
      id: 'Documents.phone.linkLabel',
      href: '/documents/phone-lines',
      label: messages.phone,
    });
  }
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <Fade className="yn-section-with-nav-scrolling">{children}</Fade>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isPbxAdmin: isMePbxAdmin(state),
  };
}

export default injectIntl(connect(mapStateToProps)(Documents));
