import React, { Component } from 'react';
import {
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Button from './formControls/Button';
import Label from './formControls/Label';
import Input from './formControls/Input';
import ToastMessage from './messages/ToastMessage';
import Form from './Form';
import CompaniesSelect from '../containers/sections/Abook/CompaniesSelect';
import PhoneNumbers from './PhoneNumbers';
import { saveAbookContactRequest } from '../js/contacts/actions';
import {
  getSaveContactLoaded,
  getSaveContactError,
  getSavedContactId,
} from '../js/contacts/selectors';
import Utils from '../js/lib/utils';

const messages = defineMessages({
  title: {
    id: 'CreateAbookModal.title',
    defaultMessage: 'Insert new contact',
  },
  titleTicketContact: {
    id: 'CreateAbookModal.titleTicketContact',
    defaultMessage: 'Add contact',
  },
  name: {
    id: 'CreateAbookModal.label.name',
    defaultMessage: 'Name',
  },
  surname: {
    id: 'CreateAbookModal.label.surname',
    defaultMessage: 'Surname',
  },
  company: {
    id: 'CreateAbookModal.label.company',
    defaultMessage: 'Company',
  },
  email: {
    id: 'CreateAbookModal.label.email',
    defaultMessage: 'Email',
  },
  number: {
    id: 'CreateAbookModal.label.number',
    defaultMessage: 'Number',
  },
  errorName: {
    id: 'CreateAbookModal.error.name',
    defaultMessage: 'Name missing',
  },
  errorEmail: {
    id: 'CreateAbookModal.error.email',
    defaultMessage: 'You must enter a valid email address',
  },
  emptyNumber: {
    id: 'CreateAbookModal.error.emptyNumber',
    defaultMessage: 'Please provide at least one number',
  },
  errorNumber: {
    id: 'CreateAbookModal.error.errorNumber',
    defaultMessage: 'Please provide a valid number!',
  },
  existingNumber: {
    id: 'CreateAbookModal.error.existingNumber',
    defaultMessage: 'Number already inserted',
  },
  400: {
    id: 'CreateAbookModal.serverError.400',
    defaultMessage: 'An internal error occured',
  },
  alreadyExistingNumber: {
    id: 'CreateAbookModal.serverError.alreadyExistingNumber',
    defaultMessage: 'A number is already used by another contact',
  },
  saveButton: {
    id: 'CreateAbookModal.saveButton',
    defaultMessage: 'Save',
  },
  addButton: {
    id: 'CreateAbookModal.addButton',
    defaultMessage: 'Add',
  },
  cancelButton: {
    id: 'CreateAbookModal.cancelButton',
    defaultMessage: 'Cancel',
  },
});

const serverErrors = {
  400: messages[400],
  EXISTING_NUMBER: messages.alreadyExistingNumber,
};

class CreateAbookModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: null,
        surname: null,
        email: null,
        company: null,
        numbers: [],
      },
      errors: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      !this.props.loadingSubmit &&
      prevProps.loadingSubmit &&
      !this.props.errorSubmit
    ) {
      this.props.onClose(
        this.props.savedContactId,
        Utils.getAbookFullname(this.state.data.name, this.state.data.surname)
      );
    }
  };

  onSave = () => {
    const contactData = {
      name: this.state.data.name,
      surname: this.state.data.surname,
      email: this.state.data.email,
      company: this.state.data.company,
      numbers: this.arrangeContactNumbers().filter(
        (number) => number.number !== null && number.number !== ''
      ),
    };
    if (!this.props.isTicketContact) {
      contactData.private = false;
    }
    const errors = this.validate(contactData);
    if (Object.keys(errors.numbersErrors).length === 0) {
      delete errors.numbersErrors;
    }
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (this.props.isTicketContact) {
        this.props.onClose(contactData);
      } else {
        this.props.save({ contactData, fromModal: true });
      }
    }
  };

  validate = (data) => {
    const errors = {
      numbersErrors: {},
    };
    if (!data.name) {
      errors.name = this.props.intl.formatMessage(messages.errorName);
    }
    if (data.email && !isEmail(data.email)) {
      errors.email = this.props.intl.formatMessage(messages.errorEmail);
    }
    const usedNumbers = [];
    /* Validation of the phone number */
    if (data.numbers && data.numbers.length > 0) {
      data.numbers.forEach((number, idx) => {
        if (!number.number) {
          if (idx === 0 && this.props.isTicketContact) {
            return;
          }
          errors.numbersErrors[idx] = this.props.intl.formatMessage(
            messages.emptyNumber
          );
        }
        if (number.number && !isNumeric(number.number)) {
          errors.numbersErrors[idx] = this.props.intl.formatMessage(
            messages.errorNumber
          );
        } else if (usedNumbers.indexOf(number.number) >= 0) {
          errors.numbersErrors[idx] = this.props.intl.formatMessage(
            messages.existingNumber
          );
        } else {
          usedNumbers.push(number.number);
        }
      });
    }
    if (
      !this.props.isTicketContact &&
      (!data.numbers || data.numbers.length <= 0)
    ) {
      errors.emptyNumber = this.props.intl.formatMessage(messages.emptyNumber);
    }
    return errors;
  };

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      errors,
    });
  };

  handleCompanySelect = (selected) => {
    if (selected) {
      this.setState({
        data: {
          ...this.state.data,
          company: selected.value,
        },
      });
    }
  };

  handleCompanyChange = (company) => {
    this.setState({
      data: {
        ...this.state.data,
        company,
      },
    });
  };

  handleNumbersChange = (numbers) => {
    this.setState({
      data: {
        ...this.state.data,
        numbers,
      },
      errors: { ...this.state.errors, numbersErrors: '' },
    });
  };

  arrangeContactNumbers = () => {
    if (this.state.data.numbers.length) return this.state.data.numbers;
    return [{ type: 'MOBILE', number: '' }];
  };

  render() {
    const {
      onClose,
      loadingSubmit,
      errorSubmit,
      isTicketContact,
      intl: { formatMessage },
    } = this.props;
    const {
      errors,
      data: { name, surname, company, email },
    } = this.state;

    return (
      <Modal isOpen>
        <ModalHeader>
          {formatMessage(
            isTicketContact ? messages.titleTicketContact : messages.title
          )}
        </ModalHeader>
        <ModalBody>
          {errorSubmit && (
            <ToastMessage
              type="danger"
              text={
                serverErrors[errorSubmit]
                  ? formatMessage(serverErrors[errorSubmit])
                  : errorSubmit
              }
              messageCode={errorSubmit}
            />
          )}
          <Form>
            <div className="form-row mt-3">
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="name" mandatory>
                    {formatMessage(messages.name)}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                    id="name"
                    error={errors.name}
                  />
                </FormGroup>
              </div>
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="surname ">
                    {formatMessage(messages.surname)}
                  </Label>
                  <Input
                    type="text"
                    value={surname}
                    onChange={this.handleChange}
                    name="surname"
                    id="surname"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="company">{formatMessage(messages.company)}</Label>
                  <CompaniesSelect
                    value={company}
                    onSelect={this.handleCompanySelect}
                    onChange={(description) =>
                      this.handleCompanyChange(description)
                    }
                  />
                </FormGroup>
              </div>
              <div className="col-xl-6">
                <FormGroup>
                  <Label for="email">{formatMessage(messages.email)}</Label>
                  <Input
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                    name="email"
                    id="email"
                    error={errors.email}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <FormGroup>
                  <Label for="number" mandatory={!isTicketContact}>
                    {formatMessage(messages.number)}
                  </Label>
                  <PhoneNumbers
                    numbers={this.arrangeContactNumbers()}
                    onChange={(numbers) => this.handleNumbersChange(numbers)}
                    errors={this.state.errors.numbersErrors || {}}
                  />
                </FormGroup>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-primary"
            loading={loadingSubmit}
            text={formatMessage(
              isTicketContact ? messages.addButton : messages.saveButton
            )}
            onClick={this.onSave}
          />
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.cancelButton)}
            onClick={() => onClose()}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

CreateAbookModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isTicketContact: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    loadingSubmit: !getSaveContactLoaded(state),
    errorSubmit: getSaveContactError(state),
    savedContactId: getSavedContactId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    save: saveAbookContactRequest,
  })(CreateAbookModal)
);
