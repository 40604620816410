/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { Progress } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import { emojis } from '../../../js/chat/emojis';
import {
  getChatMessageById,
  getChatMessageSender,
  getActiveConversation,
  isSearchedChatMessage,
  canScrollToBottom,
  getConversationById,
} from '../../../js/chat/selectors';
import {
  sendReadMessageRequest,
  sendChatFileRequest,
  readSelfTimedMessageRequest,
  removeTimedMessageSuccess,
} from '../../../js/chat/actions';
import { getMeId } from '../../../js/me/selectors';
import Icon from '../../../components/icons/Icon';
import ParsedText from '../../../components/ParsedText';
import Utils from '../../../js/lib/utils';
import ynConf from '../../../conf';
import ChatFilePreviewModal from './ChatFilePreviewModal';
import {
  CHAT_SEARCHED_TEXT_HIGHLIGHT_TIMEOUT,
  TIMED_MESSAGES_SECONDS,
} from '../../../js/chat/ChatUtils';
import { getFileById } from '../../../js/files/selectors';
import { stopUploadFiles } from '../../../js/files/actions';
import { isWsAvailable } from '../../../js/auth/selectors';
import MessageActionsDropdown from './MessageActionsDropdown';
import ReplyMessageArea from './ReplyMessageArea';

const messages = defineMessages({
  pendingSend: {
    id: 'ChatMessage.tooltip.pendingSend',
    defaultMessage: 'Sending',
  },
  sent: {
    id: 'ChatMessage.tooltip.sent',
    defaultMessage: 'Sent',
  },
  deliveredOn: {
    id: 'ChatMessage.tooltip.deliveredOn',
    defaultMessage: 'Delivered on {day} at {time}',
  },
  readOn: {
    id: 'ChatMessage.tooltip.readOn',
    defaultMessage: 'Read on {day} at {time}',
  },
  delivered: {
    id: 'ChatMessage.tooltip.delivered',
    defaultMessage: 'Delivered at {time}',
  },
  read: {
    id: 'ChatMessage.tooltip.read',
    defaultMessage: 'Read at {time}',
  },
  readTimedMessage: {
    id: 'ChatMessage.tooltip.readTimedMessage',
    defaultMessage: 'This timed message has been read',
  },
  deliveredToAll: {
    id: 'ChatMessage.tooltip.deliveredToAll',
    defaultMessage: 'Delivered to all',
  },
  readByAll: {
    id: 'ChatMessage.tooltip.readByAll',
    defaultMessage: 'Read by all',
  },
  altImage: {
    id: 'ChatMessage.label.altImage',
    defaultMessage: 'File preview',
  },
  timedMessage: {
    id: 'ChatMessage.tooltip.timedMessage',
    defaultMessage: 'Timed message: {value} seconds',
  },
  readingTimedMessage: {
    id: 'ChatMessage.label.readingTimedMessage',
    defaultMessage: 'Read',
  },
  sendFileError: {
    id: 'ChatMessage.error.sendFileError',
    defaultMessage: 'Error sending: retry',
  },
  sendMessageError: {
    id: 'ChatMessage.error.sendMessageError',
    defaultMessage: 'Error sending: retry',
  },
  stopFileUpload: {
    id: 'ChatMessage.error.stopFileUpload',
    defaultMessage: 'Stop uploading',
  },
  forwardedMessage: {
    id: 'ChatMessage.forwardedMessage',
    defaultMessage: 'Forwarded',
  },
  important: {
    id: 'ChatMessage.important',
    defaultMessage: 'Important',
  },
  499: {
    id: 'ChatMessage.error.499',
    defaultMessage: 'Canceled sending: retry',
  },
});

class ChatMessage extends React.Component {
  messageDiv = React.createRef();

  textDiv = React.createRef();

  constructor(props) {
    super(props);
    this.readMessage = this.readMessage.bind(this);
    this.onChangeDeletingCheck = this.onChangeDeletingCheck.bind(this);
    this.openFilePreview = this.openFilePreview.bind(this);
    this.toggleFilePreview = this.toggleFilePreview.bind(this);
    this.readTimedMessage = this.readTimedMessage.bind(this);
    this.timedMessageTimeout = null;
    this.state = {
      deletingChecked: false,
      filePreviewOpen: false,
      remainingTime: null,
      higlightMessage: false,
      hovered: false,
    };
    this.isReadingMessage = false;
  }

  componentDidMount() {
    const {
      message,
      messageId,
      offsetSender,
      isSearchedMessage,
      scrollToSearchedMessage,
      readSelfTimedMessage,
    } = this.props;
    if (this.checkReadingMessage()) {
      this.isReadingMessage = true;
      this.readMessage();
    }
    if (
      message.read &&
      message.duration &&
      message.read + message.duration * 1000 > moment().utc().valueOf()
    ) {
      const remainingTime =
        message.duration - Math.round(moment().valueOf() - message.read) / 1000;
      this.updateRemainingTimedMessage(remainingTime);
      readSelfTimedMessage({
        duration: remainingTime,
        messageId,
      });
    }
    if (isSearchedMessage && offsetSender) {
      scrollToSearchedMessage(offsetSender + this.messageDiv.current.offsetTop);
      this.setState({ higlightMessage: isSearchedMessage }, () => {
        this.higlightTimeout = setTimeout(() => {
          this.setState({ higlightMessage: false });
        }, CHAT_SEARCHED_TEXT_HIGHLIGHT_TIMEOUT);
      });
    }
  }

  componentDidUpdate(prevprops) {
    const {
      message,
      sender,
      myId,
      offsetSender,
      isSearchedMessage,
      scrollToSearchedMessage,
    } = this.props;
    if (this.checkReadingMessage()) {
      this.isReadingMessage = true;
      this.readMessage();
    }
    if (
      message &&
      sender &&
      sender.id === myId &&
      message.duration &&
      message.read &&
      !prevprops.message.read
    ) {
      this.readSelfTimedMessage();
    }
    if (
      message &&
      (!sender || sender.id !== myId) &&
      message.duration &&
      message.read &&
      !prevprops.message.read
    ) {
      const remainingTime =
        message.duration -
        Math.round((moment().valueOf() - message.read) / 1000);
      this.updateRemainingTimedMessage(remainingTime);
    }
    if (message && message.read && !prevprops.message.read) {
      this.isReadingMessage = false;
    }

    if (isSearchedMessage) {
      // eslint-disable-next-line
      this.setState({ higlightMessage: true }, () => {
        scrollToSearchedMessage(
          offsetSender + this.messageDiv.current.offsetTop
        );
        this.higlightTimeout = setTimeout(() => {
          this.setState({ higlightMessage: false });
        }, CHAT_SEARCHED_TEXT_HIGHLIGHT_TIMEOUT);
      });
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line
    this.timedMessageTimeout && clearTimeout(this.timedMessageTimeout);
    // eslint-disable-next-line
    this.higlightTimeout && clearTimeout(this.higlightTimeout);
  }

  checkReadingMessage = () => {
    const {
      message,
      sender,
      myId,
      activeChat,
      offsetSender,
      historyVisibleTop,
      historyVisibleHeight,
    } = this.props;
    if (
      message &&
      (!sender || sender.id !== myId) &&
      !message.read &&
      !message.duration &&
      this.messageDiv.current &&
      this.textDiv.current &&
      !message.info &&
      activeChat === message.conversationId &&
      offsetSender + this.textDiv.current.offsetTop > historyVisibleTop &&
      offsetSender + this.textDiv.current.offsetTop <
        historyVisibleTop + historyVisibleHeight &&
      !this.isReadingMessage
    ) {
      return true;
    }
    return false;
  };

  readMessage = () => {
    const { message, messageId, readMessage } = this.props;
    readMessage({
      senderHistoryId: message.senderHistoryId,
      messageId,
    });
  };

  updateRemainingTimedMessage = (time) => {
    const { messageId, removeTimedMessage } = this.props;
    if (time > 0) {
      this.timedMessageTimeout = setTimeout(() => {
        this.updateRemainingTimedMessage(time - 1);
      }, 1000);
    } else {
      removeTimedMessage({ ids: [messageId] });
    }
    this.setState({
      remainingTime: time,
    });
  };

  readTimedMessage = () => {
    const { message, messageId, readMessage, chatUnavailable } = this.props;
    if (chatUnavailable) {
      return;
    }
    this.updateRemainingTimedMessage(message.duration);
    readMessage({
      senderHistoryId: message.senderHistoryId,
      messageId,
      duration: message.duration,
    });
  };

  readSelfTimedMessage = () => {
    const { message, messageId, readSelfTimedMessage } = this.props;
    this.updateRemainingTimedMessage(message.duration);
    readSelfTimedMessage({
      duration: message.duration,
      messageId,
    });
  };

  onChangeDeletingCheck = (e) => {
    const { messageId, onChangeDeleting } = this.props;
    this.setState({
      deletingChecked: e.target.checked,
    });
    onChangeDeleting({
      id: messageId,
      value: e.target.checked,
    });
  };

  openFilePreview = () => {
    const { message } = this.props;
    if (message.file.thumbnail || message.file.stored) {
      this.setState({
        filePreviewOpen: true,
      });
    }
  };

  toggleFilePreview = () => {
    this.setState({
      filePreviewOpen: false,
    });
  };

  handleMouseEnter = () => {
    this.setState({
      hovered: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hovered: false,
    });
  };

  render() {
    const {
      intl: { formatMessage },
      isChatGroup,
      sender,
      myId,
      messageId,
      message,
      file,
      dateFormat,
      timeFormat,
      deletingEnabled,
      onRetrySendFile,
      onRetrySendMessage,
      onPreviewLoaded,
      scrollToBottom,
      cancelUpload,
      chatUnavailable,
      conversationId,
      conversation,
    } = this.props;

    const {
      deletingChecked,
      remainingTime,
      higlightMessage,
      filePreviewOpen,
      hovered,
    } = this.state;
    const outgoingMessage = sender && sender.id === myId;
    const timestamp = moment(message.timestamp).format(timeFormat);
    const readDay =
      moment(message.read).format(dateFormat) !== moment().format(dateFormat)
        ? moment(message.read).format(dateFormat)
        : null;
    const readTooltip =
      message.read &&
      formatMessage(
        isChatGroup
          ? messages.readByAll
          : readDay
          ? messages.readOn
          : messages.read,
        {
          day: readDay,
          time: moment(message.read).format(timeFormat),
        }
      );
    const deliveryDay =
      moment(message.delivery).format(dateFormat) !==
      moment().format(dateFormat)
        ? moment(message.delivery).format(dateFormat)
        : null;
    const deliveryTooltip =
      message.delivery &&
      formatMessage(
        isChatGroup
          ? messages.deliveredToAll
          : deliveryDay
          ? messages.deliveredOn
          : messages.delivered,
        {
          day: deliveryDay,
          time: moment(message.delivery).format(timeFormat),
        }
      );
    const sentTooltip = message.sent && formatMessage(messages.sent);
    let isSingleEmoji = false;
    if (message.text && !message.text.match(/\w/gi)) {
      let found = false;
      // eslint-disable-next-line
      for (const category in emojis) {
        for (let i = 0; i < emojis[category].length; i += 1) {
          if (emojis[category][i] === message.text) {
            isSingleEmoji = true;
            found = true;
            break;
          }
        }
        if (found) {
          break;
        }
      }
    }

    return (
      <div
        className="px-1 pb-1 clearfix"
        id={`chatMessage-${messageId}`}
        ref={this.messageDiv}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={{
          backgroundColor: higlightMessage ? 'yellow' : null,
          borderTop: `1px solid ${
            outgoingMessage ? 'var(--yn-blue-light)' : 'var(--yn-gray-400)'
          }`,
        }}
      >
        <div className="clearfix" style={{ height: '18px' }}>
          <div
            className="float-left position-relative mr-1"
            style={{ lineHeight: 1 }}
          >
            {message.duration &&
              outgoingMessage &&
              !remainingTime &&
              !message.read && (
                <Icon
                  name="stopwatch"
                  color="var(--yn-blue-dark)"
                  width={14}
                  height={14}
                >
                  {formatMessage(messages.timedMessage, {
                    value: message.duration,
                  })}
                </Icon>
              )}
            {message.isImportant && (
              <span>
                <Icon
                  name="rank-army-star-2-filled"
                  color="var(--yn-gray-500)"
                  width={14}
                  height={14}
                  style={{ top: '1px' }}
                  clickable={false}
                >
                  {formatMessage(messages.important)}
                </Icon>
              </span>
            )}

            {message.forwarded && (
              <span className="ml-1">
                <span
                  style={{
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    color: 'var(--yn-gray-600)',
                  }}
                >
                  {formatMessage(messages.forwardedMessage)}
                </span>
                <Icon
                  name="navigation-next-1-filled"
                  color="var(--yn-gray-500)"
                  width={16}
                  height={16}
                  style={{ top: '3px' }}
                  clickable={false}
                  className="ml-1"
                >
                  {formatMessage(messages.forwardedMessage)}
                </Icon>
              </span>
            )}
          </div>
          {remainingTime !== null && remainingTime > 0 && (
            <div
              className="float-left position-relative mr-1 mt-1"
              style={{
                top: 0,
                fontSize: '0.8rem',
                color: 'var(--yn-blue-dark)',
              }}
            >
              {moment.utc(remainingTime * 1000).format('mm:ss')}
            </div>
          )}
          <div className="float-right position-relative mr-1">
            {!message.duration && (
              <MessageActionsDropdown show={hovered} message={message} />
            )}
          </div>
        </div>
        <div
          className="float-left w-100"
          style={{ position: 'relative' }}
          ref={this.textDiv}
        >
          {deletingEnabled && !message.duration && (
            <div
              className="custom-control custom-checkbox custom-control-inline m-0"
              style={{ minHeight: '1rem' }}
            >
              <input
                type="checkbox"
                id={`${messageId}_deletingCheckbox`}
                name={`${messageId}_deletingCheckbox`}
                className="mt-2 custom-control-input"
                onChange={this.onChangeDeletingCheck}
                checked={deletingChecked}
              />
              {/* eslint-disable-next-line */}
              <label
                className="custom-control-label"
                htmlFor={`${messageId}_deletingCheckbox`}
              />
            </div>
          )}
          {message.file && (
            <>
              {!isEmpty(message.replyTo) && (
                <div className="mb-2">
                  <ReplyMessageArea
                    replyTo={message.replyTo}
                    size={conversation.size}
                  />
                </div>
              )}
              <div
                className="border rounded p-1 bg-white small"
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  width: deletingEnabled && !message.duration ? '85%' : '100%',
                }}
              >
                {message.file.thumbnail && (
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={this.openFilePreview}
                  >
                    <img
                      className="img-fluid"
                      src={`data:image/png;base64, ${message.file.thumbnail}`}
                      alt={formatMessage(messages.altImage)}
                      onLoad={() => scrollToBottom && onPreviewLoaded()}
                    />
                  </div>
                )}
                {!message.file.thumbnail &&
                  message.file.mime.indexOf('image') === 0 &&
                  message.file.mime !== 'image/gif' && (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={this.openFilePreview}
                    >
                      <img
                        className="img-fluid"
                        src={`${ynConf.fileBaseUrl}/chat/download/${message.file.stored}`}
                        alt={formatMessage(messages.altImage)}
                        onLoad={() => scrollToBottom && onPreviewLoaded()}
                      />
                    </div>
                  )}
                {!message.file.thumbnail &&
                  message.file.mime === 'image/gif' && (
                    <div
                      style={{
                        cursor: 'default',
                      }}
                    >
                      <img
                        className="img-fluid"
                        src={`${ynConf.fileBaseUrl}/chat/download/${message.file.stored}`}
                        alt={formatMessage(messages.altImage)}
                        onLoad={() => scrollToBottom && onPreviewLoaded()}
                      />
                    </div>
                  )}

                {message.file.stored ? (
                  <>
                    {!message.file.thumbnail && (
                      <>
                        <Icon
                          name="download-box-4"
                          className="mr-1"
                          width={16}
                          height={16}
                          color="var(--yn-blue-dark)"
                          style={{ float: 'left' }}
                        />
                      </>
                    )}
                    <a
                      href={
                        message.file.thumbnail
                          ? // eslint-disable-next-line
                            'javascript:;'
                          : `${ynConf.fileBaseUrl}/chat/download/${message.file.stored}`
                      }
                      onClick={
                        message.file.thumbnail ? this.openFilePreview : null
                      }
                      download={!message.file.thumbnail}
                    >
                      <span
                        className={
                          !outgoingMessage && !message.read
                            ? 'font-weight-bold'
                            : null
                        }
                        style={{ wordBreak: 'break-word' }}
                      >
                        {`${message.file.name} (${Utils.getBytes(
                          message.file.size
                        )})`}
                      </span>
                    </a>
                  </>
                ) : (
                  <span
                    className={
                      !outgoingMessage && !message.read
                        ? 'font-weight-bold'
                        : null
                    }
                    style={{ wordBreak: 'break-word' }}
                  >
                    {`${message.file.name} (${Utils.getBytes(
                      message.file.size
                    )})`}
                  </span>
                )}
                {file && file.progress && file.progress >= 0 && (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flexGrow: 1 }}>
                      <Progress className="mt-1" value={file.progress} />
                    </div>
                    <div style={{ flexGrow: 0 }}>
                      <button
                        type="button"
                        className="btn btn-primary rounded ml-1"
                        onClick={() => cancelUpload(messageId)}
                        style={{
                          lineHeight: 0.4,
                          padding: '0.2rem',
                        }}
                      >
                        <Icon
                          name="close-filled"
                          width={8}
                          height={8}
                          color="var(--white)"
                        >
                          {formatMessage(messages.stopFileUpload)}
                        </Icon>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {message.file && filePreviewOpen && (
                <ChatFilePreviewModal
                  open={filePreviewOpen}
                  toggle={this.toggleFilePreview}
                  messageId={messageId}
                />
              )}
            </>
          )}
          {(message.text ||
            (message.duration &&
              message.read &&
              (!remainingTime || remainingTime < 0))) && (
            <>
              {message.duration &&
              !outgoingMessage &&
              !remainingTime &&
              !message.read ? (
                <div style={{ position: 'relative' }}>
                  <Icon
                    name="stopwatch"
                    color="var(--yn-blue)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.timedMessage, {
                      value: message.duration,
                    })}
                  </Icon>
                  <span
                    style={{
                      position: 'absolute',
                      height: '10px',
                      width: '20px',
                      fontSize: '10px',
                      left: '7px',
                      bottom: '2px',
                      lineHeight: '1',
                      background: 'var(--yn-gray-200)',
                      color: 'var(--yn-blue)',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {
                      TIMED_MESSAGES_SECONDS.filter(
                        (item) =>
                          item.value ===
                          (message.read ? null : message.duration)
                      )[0].label
                    }
                  </span>
                  <span
                    style={{ cursor: chatUnavailable ? 'default' : 'pointer' }}
                    className={`ml-3 font-weight-bold ${
                      chatUnavailable ? '' : 'clickable'
                    }`}
                    onClick={this.readTimedMessage}
                  >
                    {formatMessage(messages.readingTimedMessage)}
                  </span>
                </div>
              ) : message.duration &&
                (!outgoingMessage || message.read) &&
                (!remainingTime || remainingTime < 0) ? (
                <>
                  <Icon
                    name="stopwatch"
                    color="var(--yn-gray-600)"
                    width={14}
                    height={14}
                  />
                  <span
                    className="font-italic"
                    style={{ fontSize: '0.8rem', color: 'var(--yn-gray-600)' }}
                  >
                    {formatMessage(messages.readTimedMessage)}
                  </span>
                </>
              ) : (
                <>
                  {!isEmpty(message.replyTo) && (
                    <ReplyMessageArea
                      expandable
                      replyTo={message.replyTo}
                      size={conversation.size}
                    />
                  )}
                  <span>
                    {message.isForwarded && (
                      <Icon
                        name="forward-box"
                        color="var(--yn-gray-600)"
                        width={16}
                        height={16}
                        className="mr-2"
                      >
                        {formatMessage(messages.forwardedMessage)}
                      </Icon>
                    )}
                    <ParsedText
                      text={message.text}
                      className={
                        !outgoingMessage && !message.read
                          ? 'font-weight-bold'
                          : null
                      }
                      isSingleEmoji={isSingleEmoji}
                      style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                      }}
                      relId={conversationId}
                    />
                  </span>
                </>
              )}
            </>
          )}
          {((message.file && message.file.error && file && file.progress < 0) ||
            message.errorSending) && (
            <button
              type="button"
              className="btn  btn-danger rounded-pill"
              onClick={() =>
                message.errorSending
                  ? onRetrySendMessage(message)
                  : onRetrySendFile(file)
              }
              style={{
                position: 'absolute',
                right: '-35px',
                bottom: 0,
                padding: '0.1rem',
                lineHeight: 0.4,
              }}
            >
              <Icon
                name="synchronize-warning-1"
                width={20}
                height={20}
                color="var(--white)"
              >
                {message.errorSending
                  ? formatMessage(messages.sendMessageError)
                  : messages[message.file.error]
                  ? formatMessage(messages[message.file.error])
                  : formatMessage(messages.sendFileError)}
              </Icon>
            </button>
          )}
        </div>
        <div
          className="float-right position-relative"
          style={{ top: 0, fontSize: '0.7rem', lineHeight: 1 }}
        >
          {outgoingMessage &&
            (message.read ? (
              <Icon
                name="check-double-3-filled"
                color="var(--green)"
                width={12}
                height={10}
              >
                {readTooltip}
              </Icon>
            ) : message.delivery ? (
              <Icon
                name="check-double-3-filled"
                color="var(--yn-gray-600)"
                width={12}
                height={10}
              >
                {deliveryTooltip}
              </Icon>
            ) : message.sent ? (
              <Icon
                name="check-1-filled"
                color="var(--yn-gray-600)"
                width={10}
                height={10}
              >
                {sentTooltip}
              </Icon>
            ) : (
              <Icon
                name="clock-2"
                color="var(--yn-blue-dark)"
                strokeWidth={2.2}
                width={10}
                height={10}
              >
                {formatMessage(messages.pendingSend)}
              </Icon>
            ))}
          <span className="ml-1" style={{ color: 'var(--yn-gray-600)' }}>
            {timestamp}
          </span>
        </div>
      </div>
    );
  }
}

ChatMessage.propTypes = {
  conversationId: PropTypes.string.isRequired,
  isChatGroup: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  deletingEnabled: PropTypes.bool.isRequired,
  onChangeDeleting: PropTypes.func.isRequired,
  scrollToSearchedMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    chatUnavailable: !isWsAvailable(state),
    message: getChatMessageById(state, ownProps.messageId),
    sender: getChatMessageSender(state, ownProps.messageId),
    myId: getMeId(state),
    dateFormat: state.settings.dateFormat,
    timeFormat: state.settings.timeFormat,
    activeChat: getActiveConversation(state),
    isSearchedMessage: isSearchedChatMessage(state, ownProps.messageId),
    file: getChatMessageById(state, ownProps.messageId).file
      ? getFileById(state, 'chat', ownProps.messageId)
      : null,
    scrollToBottom: canScrollToBottom(
      state,
      getChatMessageById(state, ownProps.messageId).conversationId
    ),
    conversation: getConversationById(state, ownProps.conversationId),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    readMessage: sendReadMessageRequest,
    readSelfTimedMessage: readSelfTimedMessageRequest,
    sendFileMessage: sendChatFileRequest,
    cancelUpload: stopUploadFiles,
    removeTimedMessage: removeTimedMessageSuccess,
  })(ChatMessage)
);
