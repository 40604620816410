import React from 'react';
import PropTypes from 'prop-types';

const Svg = ({ name, width, height, color, strokeWidth }) => {
  const glyph = (glyphname) => {
    switch (glyphname) {
      case 'arrow-left':
        return (
          <g>
            <polyline
              points="5.5,17.5
                                  0.5,12.5 5.5,7.5 		"
            />
            <line x1="0.5" y1="12.5" x2="23.5" y2="12.5" />
          </g>
        );
      case 'arrow-right':
        return (
          <g>
            <polyline
              points="18.5,7.5
                                  23.5,12.5 18.5,17.5 		"
            />
            <line x1="23.5" y1="12.5" x2="0.5" y2="12.5" />
          </g>
        );
      case 'arrow-return':
        return (
          <g>
            <path
              d="M12,4.5h5
                                  c3.59,0,6.5,2.91,6.5,6.5s-2.91,6.5-6.5,6.5H0.5"
            />
            <polyline
              points="5.5,22.5
                                  0.5,17.5 5.5,12.5 		"
            />
          </g>
        );
      case 'arrow-right-10':
        return (
          <g id="Filled_Icons">
            <path
              d="M21.759,11.577L2.786,0.077C2.631-0.017,2.439-0.02,2.281,0.069C2.124,0.158,2.027,0.324,2.027,0.505v23
                                                                                                                                                                                                                          c0,0.181,0.097,0.347,0.254,0.435c0.077,0.043,0.161,0.065,0.246,0.065c0.09,0,0.18-0.024,0.259-0.072l18.973-11.5
                                                                                                                                                                                                                          C21.909,12.342,22,12.18,22,12.005S21.909,11.668,21.759,11.577z"
            />
          </g>
        );
      case 'arrow-down-10':
        return (
          <g id="Filled_Icons">
            <path
              d="M23.936,2.255C23.848,2.098,23.681,2,23.5,2h-23C0.32,2,0.153,2.098,0.065,2.255c-0.089,0.157-0.085,0.35,0.008,0.504
                                                                                                                                                                                                                              l11.5,19C11.663,21.908,11.826,22,12,22s0.337-0.092,0.428-0.241l11.5-19C24.021,2.605,24.025,2.412,23.936,2.255z"
            />
          </g>
        );
      case 'arrow-down-10-filled':
        return (
          <g>
            <path
              d="M23.936,2.255C23.848,2.098,23.681,2,23.5,2h-23C0.32,2,0.153,2.098,0.065,2.255c-0.089,0.157-0.085,0.35,0.008,0.504
                                                                                                                                                                                                                                                                l11.5,19C11.663,21.908,11.826,22,12,22s0.337-0.092,0.428-0.241l11.5-19C24.021,2.605,24.025,2.412,23.936,2.255z"
            />
          </g>
        );
      case 'arrow-left-10-filled':
        return (
          <g>
            <g>
              <path
                d="M21.746,0.064c-0.156-0.088-0.349-0.084-0.504,0.008l-19,11.5C2.091,11.663,2,11.825,2,12s0.091,0.337,0.241,0.428l19,11.5
                                                                                                                                                                                                                                        		C21.321,23.976,21.41,24,21.5,24c0.084,0,0.169-0.021,0.246-0.065C21.903,23.847,22,23.681,22,23.5v-23
                                                                                                                                                                                                                                        		C22,0.319,21.903,0.153,21.746,0.064z"
              />
            </g>
          </g>
        );
      case 'arrow-right-10-filled':
        return (
          <g>
            <g>
              <path
                d="M21.759,11.577L2.786,0.077C2.631-0.017,2.439-0.02,2.281,0.069C2.124,0.158,2.027,0.324,2.027,0.505v23
                                                                                                                                                                                                                                        		c0,0.181,0.097,0.347,0.254,0.435c0.077,0.043,0.161,0.065,0.246,0.065c0.09,0,0.18-0.024,0.259-0.072l18.973-11.5
                                                                                                                                                                                                                                        		C21.909,12.342,22,12.18,22,12.005S21.909,11.668,21.759,11.577z"
              />
            </g>
          </g>
        );
      case 'bin-1':
        return (
          <g>
            <rect x="3.5" y="3.5" width="16" height="20" />
            <rect x="7.5" y="0.5" width="8" height="3" />
            <line x1="1" y1="3.5" x2="23" y2="3.5" />
            <line x1="7.5" y1="7" x2="7.5" y2="19" />
            <line x1="11.5" y1="7" x2="11.5" y2="19" />
            <line x1="15.5" y1="7" x2="15.5" y2="19" />
          </g>
        );
      case 'bin-1-filled':
        return (
          <g id="Filled_Icons">
            <g>
              <path
                d="M23,3h-7v-2.5c0-0.275-0.224-0.5-0.5-0.5h-8c-0.276,0-0.5,0.225-0.5,0.5V3H1C0.724,3,0.5,3.225,0.5,3.5
                                                                                                                                                                                                            c0,0.276,0.224,0.5,0.5,0.5h2v19.5C3,23.777,3.224,24,3.5,24h16c0.276,0,0.5-0.224,0.5-0.5v-19.5h3c0.276,0,0.5-0.224,0.5-0.5
                                                                                                                                                                                                            C23.5,3.225,23.276,3,23,3z M8,18.5C8,18.777,7.776,19,7.5,19S7,18.777,7,18.5v-11c0-0.275,0.224-0.5,0.5-0.5S8,7.225,8,7.501
                                                                                                                                                                                                            V18.5z M8,1h7v2H8V1z M12,18.5c0,0.276-0.224,0.5-0.5,0.5S11,18.777,11,18.5v-11c0-0.275,0.224-0.5,0.5-0.5s0.5,0.225,0.5,0.5
                                                                                                                                                                                                            V18.5z M16,18.5c0,0.276-0.224,0.5-0.5,0.5S15,18.777,15,18.5v-11c0-0.275,0.224-0.5,0.5-0.5s0.5,0.225,0.5,0.5V18.5z"
              />
            </g>
          </g>
        );
      case 'book-address':
        return (
          <g>
            <rect x="3.5" y="1.5" width="15" height="21" />
            <rect x="8.5" y="5.5" width="6" height="4" />
            <line x1="2.5" y1="4.5" x2="4.5" y2="4.5" />
            <line x1="2.5" y1="7.5" x2="4.5" y2="7.5" />
            <line x1="2.5" y1="10.5" x2="4.5" y2="10.5" />
            <line x1="2.5" y1="13.5" x2="4.5" y2="13.5" />
            <line x1="2.5" y1="16.5" x2="4.5" y2="16.5" />
            <line x1="2.5" y1="19.5" x2="4.5" y2="19.5" />
            <rect x="18.5" y="3.5" width="3" height="4" />
            <rect x="18.5" y="7.5" width="3" height="4" />
            <rect x="18.5" y="11.5" width="3" height="4" />
            <rect x="18.5" y="15.5" width="3" height="4" />
          </g>
        );

      case 'book-address-filled':
        return (
          <g>
            <path
              d="M21.5,3H19V1.5C19,1.224,18.775,1,18.5,1h-15C3.224,1,3,1.224,3,1.5v2h1.5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H3v1
                                                                                                                                                                                                                                      				h1.5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H3v1h1.5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H3v1h1.5c0.551,0,1,0.449,1,1
                                                                                                                                                                                                                                      				c0,0.552-0.449,1-1,1H3v1h1.5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H3v1h1.5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1H3v2
                                                                                                                                                                                                                                      				C3,22.776,3.224,23,3.5,23h15c0.275,0,0.5-0.224,0.5-0.5V20h2.5c0.275,0,0.5-0.224,0.5-0.5v-16C22,3.224,21.775,3,21.5,3z
                                                                                                                                                                                                                                      				 M15,9.5c0,0.276-0.225,0.5-0.5,0.5h-6C8.224,10,8,9.776,8,9.5v-4C8,5.224,8.224,5,8.5,5h6C14.775,5,15,5.224,15,5.5V9.5z M21,19
                                                                                                                                                                                                                                      				h-2v-3h2V19z M21,15h-2v-3h2V15z M21,11h-2V8h2V11z M21,7h-2V4h2V7z"
            />
            <path d="M2.5,5h2C4.775,5,5,4.775,5,4.5C5,4.225,4.775,4,4.5,4h-2C2.224,4,2,4.225,2,4.5C2,4.775,2.224,5,2.5,5z" />
            <path d="M2.5,8h2C4.775,8,5,7.775,5,7.5C5,7.225,4.775,7,4.5,7h-2C2.224,7,2,7.225,2,7.5C2,7.775,2.224,8,2.5,8z" />
            <path d="M2.5,11h2C4.775,11,5,10.775,5,10.5C5,10.225,4.775,10,4.5,10h-2C2.224,10,2,10.225,2,10.5C2,10.775,2.224,11,2.5,11z" />
            <path d="M2.5,14h2C4.775,14,5,13.775,5,13.5C5,13.225,4.775,13,4.5,13h-2C2.224,13,2,13.225,2,13.5C2,13.775,2.224,14,2.5,14z" />
            <path d="M2.5,17h2C4.775,17,5,16.775,5,16.5C5,16.225,4.775,16,4.5,16h-2C2.224,16,2,16.225,2,16.5C2,16.775,2.224,17,2.5,17z" />
            <path d="M5,19.5C5,19.225,4.775,19,4.5,19h-2C2.224,19,2,19.225,2,19.5C2,19.775,2.224,20,2.5,20h2C4.775,20,5,19.775,5,19.5z" />
          </g>
        );

      case 'box-2':
        return (
          <g>
            <polygon
              points="
                                                                                                                                                                                                                                                16.5,14.5 16.5,16.5 7.5,16.5 7.5,14.5 0.5,14.5 0.5,22.5 23.5,22.5 23.5,14.5"
            />
            <polyline
              points="
                                                                                                                                                                                                                                                9.5,16.5 9.5,1.5 20.5,1.5 20.5,14.5"
            />
            <polyline
              points="
                                                                                                                                                                                                                                                3.5,14.5 3.5,3.5 9.5,3.5 			"
            />
            <line x1="13.5" y1="4.5" x2="18.5" y2="4.5" />
            <line x1="11.5" y1="6.5" x2="18.5" y2="6.5" />
            <line x1="11.5" y1="8.5" x2="18.5" y2="8.5" />
            <line x1="11.5" y1="10.5" x2="18.5" y2="10.5" />
            <line x1="11.5" y1="12.5" x2="18.5" y2="12.5" />
          </g>
        );

      case 'box-2-filled':
        return (
          <g>
            <path
              d="M4,3h5v12h6v-1.5c0-0.276,0.224-0.5,0.5-0.5H21V0.5C21,0.224,20.776,0,20.5,0h-11C9.224,0,9,0.224,9,0.5V2H3.5
                                                                                                                                                                                                                                      				C3.224,2,3,2.224,3,2.5V13h1V3z M13.5,3h5C18.776,3,19,3.224,19,3.5C19,3.776,18.776,4,18.5,4h-5C13.224,4,13,3.776,13,3.5
                                                                                                                                                                                                                                      				C13,3.224,13.224,3,13.5,3z M11.5,5h7C18.776,5,19,5.224,19,5.5C19,5.776,18.776,6,18.5,6h-7C11.224,6,11,5.776,11,5.5
                                                                                                                                                                                                                                      				C11,5.224,11.224,5,11.5,5z M11.5,7h7C18.776,7,19,7.224,19,7.5C19,7.776,18.776,8,18.5,8h-7C11.224,8,11,7.776,11,7.5
                                                                                                                                                                                                                                      				C11,7.224,11.224,7,11.5,7z M11.5,9h7C18.776,9,19,9.224,19,9.5c0,0.276-0.224,0.5-0.5,0.5h-7C11.224,10,11,9.776,11,9.5
                                                                                                                                                                                                                                      				C11,9.224,11.224,9,11.5,9z M11.5,11h7c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5h-7c-0.276,0-0.5-0.224-0.5-0.5
                                                                                                                                                                                                                                      				C11,11.224,11.224,11,11.5,11z"
            />
            <path
              d="M0.5,23C0.224,23,0,22.776,0,22.5v-8C0,14.224,0.224,14,0.5,14h7C7.776,14,8,14.224,8,14.5V16h8v-1.5
                                                                                                                                                                                                                                      				c0-0.276,0.224-0.5,0.5-0.5h7c0.276,0,0.5,0.224,0.5,0.5v8c0,0.276-0.224,0.5-0.5,0.5H0.5z"
            />
          </g>
        );

      case 'bubble-chat-2':
        return (
          <path
            d="M23.5,10.883
                                                                                                                                                                                                                                      				c0,5.181-5.148,9.383-11.5,9.383c-1.418,0-2.775-0.21-4.029-0.593l-6.608,3.076l2.588-5.166c-2.129-1.703-3.45-4.076-3.45-6.7
                                                                                                                                                                                                                                      				C0.5,5.701,5.648,1.5,12,1.5S23.5,5.701,23.5,10.883z"
          />
        );

      case 'bubble-chat-2-filled':
        return (
          <path
            stroke="none"
            d="M11.997,0.903c-6.617,0-12,4.434-12,9.883c0,2.573,1.177,4.985,3.325,6.831l-2.41,4.811
                                                                                                                                                                                                                                              c-0.214,0.426,0.236,0.877,0.658,0.677l6.437-2.995c1.278,0.37,2.619,0.558,3.99,0.558c6.617,0,12-4.433,12-9.882
                                                                                                                                                                                                                                              C23.997,5.337,18.613,0.903,11.997,0.903z"
          />
        );

      case 'calendar-2':
        return (
          <g>
            <polyline
              points="4.5,2.5
                                                                                                                                                                                                                                      				0.5,2.5 0.5,23.5 23.5,23.5 23.5,2.5 19.5,2.5"
            />
            <rect x="4.5" y="0.5" width="3" height="4" />
            <rect x="16.5" y="0.5" width="3" height="4" />
            <line x1="7.5" y1="2.5" x2="16.5" y2="2.5" />
            <line x1="0.5" y1="7.5" x2="23.5" y2="7.5" />
            <line x1="6.5" y1="9.5" x2="6.5" y2="21.5" />
            <line x1="11.5" y1="9.5" x2="11.5" y2="21.5" />
            <line x1="16.5" y1="9.5" x2="16.5" y2="21.5" />
            <line x1="2.5" y1="11.5" x2="21.5" y2="11.5" />
            <line x1="2.5" y1="15.5" x2="21.5" y2="15.5" />
            <line x1="2.5" y1="19.5" x2="21.5" y2="19.5" />
          </g>
        );

      case 'calendar-2-filled':
        return (
          <g>
            <rect x="12" y="16" width="4" height="3" />
            <rect x="7" y="16" width="4" height="3" />
            <rect x="12" y="12" width="4" height="3" />
            <rect x="7" y="12" width="4" height="3" />
            <path
              d="M0,23.5C0,23.776,0.224,24,0.5,24h23c0.276,0,0.5-0.224,0.5-0.5V8H0V23.5z M2.5,16C2.224,16,2,15.776,2,15.5
                                                                                                                                                                                                                                                S2.224,15,2.5,15H6v-3H2.5C2.224,12,2,11.776,2,11.5S2.224,11,2.5,11H6V9.5C6,9.224,6.224,9,6.5,9S7,9.224,7,9.5V11h4V9.5
                                                                                                                                                                                                                                                C11,9.224,11.224,9,11.5,9S12,9.224,12,9.5V11h4V9.5C16,9.224,16.224,9,16.5,9S17,9.224,17,9.5V11h4.5c0.276,0,0.5,0.224,0.5,0.5
                                                                                                                                                                                                                                                S21.776,12,21.5,12H17v3h4.5c0.276,0,0.5,0.224,0.5,0.5S21.776,16,21.5,16H17v3h4.5c0.276,0,0.5,0.224,0.5,0.5S21.776,20,21.5,20
                                                                                                                                                                                                                                                H17v1.5c0,0.276-0.224,0.5-0.5,0.5S16,21.776,16,21.5V20h-4v1.5c0,0.276-0.224,0.5-0.5,0.5S11,21.776,11,21.5V20H7v1.5
                                                                                                                                                                                                                                                C7,21.776,6.776,22,6.5,22S6,21.776,6,21.5V20H2.5C2.224,20,2,19.776,2,19.5S2.224,19,2.5,19H6v-3H2.5z"
            />
            <path
              d="M23.5,2H20V0.5C20,0.224,19.776,0,19.5,0h-3C16.224,0,16,0.224,16,0.5V2H8V0.5C8,0.224,7.776,0,7.5,0h-3
                                                                                                                                                                                                                                                C4.224,0,4,0.224,4,0.5V2H0.5C0.224,2,0,2.224,0,2.5V7h24V2.5C24,2.224,23.776,2,23.5,2z M7,4H5V1h2V4z M19,4h-2V1h2V4z"
            />
          </g>
        );

      case 'chat-double-bubble-3':
        return (
          <g>
            <path
              d="M14.5,1.5h-10
                                                                                                                                                                                                                                                c-2.209,0-4,1.791-4,4v4c0,2.21,1.791,4,4,4h1v4l4.5-4h4.5c2.209,0,4-1.79,4-4v-4C18.5,3.291,16.709,1.5,14.5,1.5z"
            />
            <path
              d="M11.5,17
                                                                                                                                                                                                                                                c0,1.381,1.119,2.5,2.5,2.5h2.5l3,3v-3H21c1.381,0,2.5-1.119,2.5-2.5v-2c0-1.381-1.119-2.5-2.5-2.5"
            />
          </g>
        );

      case 'chat-double-bubble-3-filled':
        return (
          <g>
            <path
              d="M21,12h-1.607c-0.912,1.778-2.761,3-4.893,3H11v2c0,1.654,1.346,3,3,3h2.293l2.854,2.854
                                                                                                                                                                                                                                                C19.459,23.166,20,22.945,20,22.5V20h1c1.654,0,3-1.346,3-3v-2C24,13.346,22.654,12,21,12z"
            />
            <path
              d="M19,9.5v-4C19,3.019,16.981,1,14.5,1h-10C2.019,1,0,3.019,0,5.5v4C0,11.981,2.019,14,4.5,14H5v3.5
                                                                                                                                                                                                                                                c0,0.444,0.54,0.668,0.853,0.354L9.707,14H14.5C16.981,14,19,11.981,19,9.5z"
            />
          </g>
        );

      case 'close-filled':
        return (
          <path
            d="M23.707,22.293L13.414,12L23.706,1.707c0.391-0.39,0.391-1.023,0-1.414c-0.391-0.391-1.023-0.391-1.414,0L12,10.586
                                                                                                                                                                                                                                      			L1.706,0.292c-0.391-0.39-1.023-0.39-1.414,0c-0.391,0.391-0.391,1.023,0,1.414L10.586,12L0.292,22.294
                                                                                                                                                                                                                                      			c-0.39,0.39-0.391,1.024,0,1.414c0.391,0.391,1.024,0.39,1.414,0L12,13.414l10.293,10.292c0.39,0.391,1.023,0.391,1.414,0
                                                                                                                                                                                                                                            C24.098,23.316,24.097,22.683,23.707,22.293z"
          />
        );

      case 'expand-horizontal-7-filled':
        return (
          <g>
            <path
              d="M21.207,8.794l-8.5-8.5c-0.391-0.392-1.023-0.392-1.414,0l-8.5,8.5c-0.391,0.39-0.391,1.023,0,1.413
                                                                                                                                                                                                                                            c0.391,0.392,1.023,0.392,1.414,0L12,2.415l7.793,7.792c0.391,0.392,1.023,0.392,1.414,0C21.598,9.816,21.598,9.184,21.207,8.794
                                                                                                                                                                                                                                            z"
            />
            <path
              d="M21.207,15.207l-8.5,8.5c-0.391,0.392-1.023,0.392-1.414,0l-8.5-8.5c-0.391-0.39-0.391-1.022,0-1.413
                                                                                                                                                                                                                                            c0.391-0.392,1.023-0.392,1.414,0L12,21.586l7.793-7.792c0.391-0.392,1.023-0.392,1.414,0
                                                                                                                                                                                                                                            C21.598,14.185,21.598,14.817,21.207,15.207z"
            />
          </g>
        );

      case 'fax-machine':
        return (
          <g>
            <polyline
              points="11.5,6.5 11.5,0.5 19,0.5
                                                                                                                                                                                                                                      				21.5,2.5 21.5,6.5"
            />
            <line x1="13" y1="2.5" x2="17.5" y2="2.5" />
            <line x1="13" y1="4.5" x2="20" y2="4.5" />
            <path
              d="M9.5,21.5c0,1.104-0.896,2-2,2h-3
                                                                                                                                                                                                                                      				c-1.104,0-2-0.896-2-2v-16c0-1.104,0.896-2,2-2h3c1.104,0,2,0.896,2,2V21.5z"
            />
            <path
              d="M9.5,6.5h13c0.55,0,1,0.45,1,1v13
                                                                                                                                                                                                                                      				c0,0.55-0.45,1-1,1h-13"
            />
            <path
              d="M2.5,21.5h-1c-0.55,0-1-0.45-1-1v-13
                                                                                                                                                                                                                                      				c0-0.55,0.45-1,1-1h1"
            />
            <rect x="13.5" y="8.5" width="6" height="2" />
            <line x1="4.5" y1="3.5" x2="4.5" y2="0.5" />
          </g>
        );

      case 'fax-machine-filled':
        return (
          <g>
            <path d="M0,7.502v13c0,0.827,0.673,1.5,1.5,1.5H2v-16H1.5C0.673,6.002,0,6.675,0,7.502z" />
            <path
              d="M7.5,3.002H6v-2.5c0-0.276-0.224-0.5-0.5-0.5S5,0.226,5,0.502v2.551C3.86,3.285,3,4.294,3,5.502v16
                                                                                                                                                                                                                                                c0,1.379,1.122,2.5,2.5,2.5h2c1.378,0,2.5-1.121,2.5-2.5v-16C10,4.123,8.878,3.002,7.5,3.002z"
            />
            <path
              d="M22,6.094V2.502c0-0.152-0.069-0.296-0.188-0.391l-2.5-2C19.224,0.041,19.114,0.002,19,0.002h-6.5
                                                                                                                                                                                                                                                c-0.276,0-0.5,0.224-0.5,0.5v5.5h-1v16h10.5c0.827,0,1.5-0.673,1.5-1.5v-13C23,6.851,22.581,6.301,22,6.094z M13,1.002h5.825
                                                                                                                                                                                                                                                L21,2.742v3.26h-8V1.002z M14,18.002h-1v-1h1V18.002z M14,16.002h-1v-1h1V16.002z M14,14.002h-1v-1h1V14.002z M17,18.002h-1v-1h1
                                                                                                                                                                                                                                                V18.002z M17,16.002h-1v-1h1V16.002z M17,14.002h-1v-1h1V14.002z M20,18.002h-1v-1h1V18.002z M20,16.002h-1v-1h1V16.002z
                                                                                                                                                                                                                                                 M20,14.002h-1v-1h1V14.002z M20,10.502c0,0.276-0.224,0.5-0.5,0.5h-6c-0.276,0-0.5-0.224-0.5-0.5v-2c0-0.276,0.224-0.5,0.5-0.5
                                                                                                                                                                                                                                                h6c0.276,0,0.5,0.224,0.5,0.5V10.502z"
            />
            <rect x="14" y="2.002" width="3.5" height="1" />
            <rect x="14" y="4.002" width="6" height="1" />
          </g>
        );

      case 'headphone':
        return (
          <g>
            <path
              d="M2.5,14.5
                                                                                                                                                                                                                                                c-1.104,0-2-0.896-2-2s0.896-2,2-2"
            />
            <path
              d="M14.5,21.5h2
                                                                                                                                                                                                                                                c1.934,0,3.5-1.066,3.5-3v-1.064"
            />
            <circle cx="13" cy="21.5" r="1.5" />
            <path
              d="M6.5,17.5h-2
                                                                                                                                                                                                                                                c-1.104,0-2-0.896-2-2v-6c0-1.104,0.896-2,2-2h2V17.5z"
            />
            <path
              d="M21.5,14.5
                                                                                                                                                                                                                                                c1.104,0,2-0.896,2-2s-0.896-2-2-2"
            />
            <path
              d="M17.5,17.5h2
                                                                                                                                                                                                                                                c1.104,0,2-0.896,2-2v-6c0-1.104-0.896-2-2-2h-2V17.5z"
            />
            <path
              d="M5,7.5c0-3.866,3.134-7,7-7
                                                                                                                                                                                                                                                c3.865,0,7,3.134,7,7"
            />
          </g>
        );

      case 'headphone-filled':
        return (
          <path
            d="M22,10.051V9.5C22,8.121,20.878,7,19.5,7h-0.025C19.216,3.098,15.967,0,12,0S4.784,3.098,4.525,7H4.5
                                                                                                                                                                                                                                              C3.122,7,2,8.121,2,9.5v0.551c-1.14,0.232-2,1.241-2,2.449s0.86,2.217,2,2.449V15.5C2,16.879,3.122,18,4.5,18h2
                                                                                                                                                                                                                                              C6.776,18,7,17.776,7,17.5v-10C7,7.224,6.776,7,6.5,7H5.525C5.782,3.649,8.585,1,12,1s6.218,2.649,6.475,6H17.5
                                                                                                                                                                                                                                              C17.224,7,17,7.224,17,7.5v10c0,0.276,0.224,0.5,0.5,0.5h2c0,1.654-1.346,3-3,3h-1.05c-0.232-1.142-1.24-2-2.45-2
                                                                                                                                                                                                                                              c-1.381,0-2.5,1.119-2.5,2.5S11.619,24,13,24c1.21,0,2.218-0.858,2.45-2h1.05c2.044,0,4-1.56,4-4.212
                                                                                                                                                                                                                                              c0.881-0.387,1.5-1.266,1.5-2.288v-0.551c1.14-0.232,2-1.241,2-2.449S23.14,10.283,22,10.051z"
          />
        );

      case 'help-circle-filled':
        return (
          <path
            d="M11.5,1C5.148,1,0,6.148,0,12.5S5.148,24,11.5,24S23,18.852,23,12.5S17.852,1,11.5,1z M11.5,19c-0.553,0-1-0.447-1-1
                                                                                                                                                                                                                                                c0-0.552,0.447-1,1-1c0.551,0,1,0.448,1,1C12.5,18.553,12.051,19,11.5,19z M12,12.965V15.5c0,0.276-0.225,0.5-0.5,0.5
                                                                                                                                                                                                                                                c-0.276,0-0.5-0.224-0.5-0.5v-3c0-0.276,0.224-0.5,0.5-0.5c1.378,0,2.5-1.121,2.5-2.5S12.878,7,11.5,7C10.121,7,9,8.121,9,9.5
                                                                                                                                                                                                                                                C9,9.776,8.775,10,8.5,10C8.224,10,8,9.776,8,9.5C8,7.57,9.569,6,11.5,6C13.43,6,15,7.57,15,9.5C15,11.26,13.693,12.721,12,12.965
                                                                                                                                                                                                                                                z"
          />
        );

      case 'hide':
        return (
          <g>
            <path
              d="M19.518,8.288
                                                                                                                                                                                                                                      				C21.957,10.052,23.5,12,23.5,12s-5.148,6.5-11.5,6.5c-1.039,0-2.045-0.173-3.002-0.464"
            />
            <path
              d="M4.468,15.701
                                                                                                                                                                                                                                      				C2.037,13.94,0.5,12,0.5,12S5.648,5.5,12,5.5c0.859,0,1.698,0.119,2.504,0.325"
            />
            <path
              d="M8,12
                                                                                                                                                                                                                                      				c0-2.21,1.791-4,4-4"
            />
            <path
              d="M16,12
                                                                                                                                                                                                                                      				c0,2.21-1.791,4-4,4"
            />
            <line x1="21.75" y1="2.25" x2="2.25" y2="21.75" />
          </g>
        );

      case 'key-1':
        return (
          <g>
            <circle cx="5.5" cy="18.5" r="1.5" />
            <path
              d="M20.5,0.5
                                                                                                                                                                                                                                                  l-9.782,9.783C8.082,8.918,4.761,9.339,2.55,11.55c-2.734,2.733-2.734,7.165,0,9.899s7.166,2.734,9.899,0
                                                                                                                                                                                                                                                  c2.21-2.211,2.632-5.531,1.268-8.166L17,10h1.5V8.5L19,8h1.5V6.5L21,6h1.5V4.5l1-1v-3H20.5z"
            />
          </g>
        );

      case 'key-1-filled':
        return (
          <path
            d="M23.497,0h-3c-0.133,0-0.26,0.053-0.354,0.147l-9.537,9.538c-2.766-1.257-6.142-0.759-8.413,1.512
                                                                                                                                                                                                                                              c-2.925,2.925-2.925,7.683,0,10.607c2.924,2.924,7.682,2.924,10.607,0c2.272-2.272,2.77-5.646,1.513-8.412l2.891-2.892h1.293
                                                                                                                                                                                                                                              c0.276,0,0.5-0.224,0.5-0.5V8.707L19.204,8.5h1.293c0.276,0,0.5-0.224,0.5-0.5V6.707L21.204,6.5h1.293c0.276,0,0.5-0.224,0.5-0.5
                                                                                                                                                                                                                                              V4.707l0.853-0.853c0.094-0.093,0.147-0.221,0.147-0.353v-3C23.997,0.224,23.773,0,23.497,0z M4.083,19.914
                                                                                                                                                                                                                                              c-0.78-0.78-0.78-2.047,0-2.828c0.78-0.78,2.049-0.78,2.829-0.001c0.78,0.782,0.78,2.049,0,2.83
                                                                                                                                                                                                                                              C6.132,20.694,4.863,20.694,4.083,19.914z"
          />
        );

      case 'information-circle':
        return (
          <g>
            <circle cx="11.5" cy="12.5" r="11" />
            <line x1="8.5" y1="19.5" x2="14.5" y2="19.5" />
            <polyline
              points="
                                                                                                                                                                                                                                                9.5,10.5 11.5,10.5 11.5,19 			"
            />
            <g>
              <path
                d="M11,6c-0.277,0-0.5,0.225-0.5,0.5
                                                                                                                                                                                                                                                  C10.5,6.777,10.723,7,11,7c0.275,0,0.5-0.223,0.5-0.5C11.5,6.225,11.275,6,11,6L11,6z"
              />
            </g>
          </g>
        );

      case 'luggage-key-1':
        return (
          <g>
            <path
              d="M20.5,15.5
                                                                                                                                                                                                                                      			c0-1.649-1.35-3-3-3h-11c-1.65,0-3,1.351-3,3v5c0,1.65,1.35,3,3,3h11c1.65,0,3-1.35,3-3V15.5z"
            />
            <path
              d="M9.5,12.5v-1
                                                                                                                                                                                                                                      			c0-1.1,0.9-2,2-2h1c1.1,0,2,0.9,2,2v1"
            />
            <line x1="17.5" y1="12.5" x2="17.5" y2="23.5" />
            <line x1="14.5" y1="12.5" x2="14.5" y2="23.5" />
            <line x1="9.5" y1="12.5" x2="9.5" y2="23.5" />
            <line x1="6.5" y1="12.5" x2="6.5" y2="23.5" />
            <circle cx="5" cy="4.516" r="1.5" />
            <path
              d="M8.873,5.5
                                                                                                                                                                                                                                      			C8.428,7.224,6.862,8.516,5,8.516c-2.209,0-4-1.791-4-4s1.791-4,4-4c1.873,0,3.444,1.247,3.88,2.984H22.5c0.552,0,1,0.448,1,1
                                                                                                                                                                                                                                            s-0.448,1-1,1h-2v2h-2v-2h-2v2h-2v-2H8.873z"
            />
          </g>
        );

      case 'luggage-key-1-filled':
        return (
          <g>
            <path d="M18,12.057v11.898c1.691-0.244,3-1.69,3-3.449v-5C21,13.747,19.691,12.302,18,12.057z" />
            <path d="M3,15.506v5c0,1.759,1.309,3.205,3,3.449V12.057C4.309,12.302,3,13.747,3,15.506z" />
            <path
              d="M15,11.506c0-1.378-1.122-2.5-2.5-2.5h-1c-1.378,0-2.5,1.122-2.5,2.5v0.5H8V24h8V12.006h-1V11.506z M14,12.006h-4v-0.5
                                                                                                                                                                                                                                      			c0-0.827,0.673-1.5,1.5-1.5h1c0.827,0,1.5,0.673,1.5,1.5V12.006z"
            />
            <path
              d="M22.5,3H9.252C8.634,1.209,6.939,0,5,0C2.519,0,0.5,2.019,0.5,4.5S2.519,9,5,9c1.919,0,3.611-1.222,4.243-3.016H14v1.5
                                                                                                                                                                                                                                      			c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5v-1.5h1v1.5c0,0.276,0.224,0.5,0.5,0.5h2c0.276,0,0.5-0.224,0.5-0.5v-1.5
                                                                                                                                                                                                                                      			h1.5c0.827,0,1.5-0.673,1.5-1.5C24,3.657,23.327,2.984,22.5,3z M5,6.5c-1.103,0-2-0.897-2-2c0-1.102,0.897-2,2-2
                                                                                                                                                                                                                                            c1.102,0,2,0.898,2,2C7,5.603,6.102,6.5,5,6.5z"
            />
          </g>
        );

      case 'logo-circle-wikipedia':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" />
            <polyline
              points="6.004,8.5 9.539,17.188
                                                                                                                                                                                                                                      			13.777,8.5 		"
            />
            <line x1="8" y1="8.5" x2="4" y2="8.5" />
            <line x1="15" y1="8.5" x2="13" y2="8.5" />
            <polyline
              points="10.379,8.5 13.914,17.188
                                                                                                                                                                                                                                      			18.152,8.5 		"
            />
            <line x1="11" y1="8.5" x2="9" y2="8.5" />
            <line x1="20" y1="8.5" x2="16" y2="8.5" />
          </g>
        );

      case 'logo-circle-wikipedia-filled':
        return (
          <path
            d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M20,9h-1.535l-4.101,8.406
                                                                                                                                                                                                                                              c-0.084,0.172-0.259,0.281-0.449,0.281c-0.006,0-0.011,0-0.017-0.001c-0.197-0.007-0.372-0.128-0.446-0.312l-1.568-3.852
                                                                                                                                                                                                                                              l-1.894,3.884c-0.084,0.172-0.259,0.281-0.449,0.281c-0.006,0-0.011,0-0.017-0.001c-0.197-0.007-0.372-0.128-0.446-0.312L5.668,9H4
                                                                                                                                                                                                                                              V8h4v1H6.747l2.833,6.962l1.797-3.683L10.043,9H9V8h2v0.7l0.966,2.373L13,8.953V8h2v1h-0.91l-1.618,3.316l1.483,3.646L17.353,9H16
                                                                                                                                                                                                                                              V8h4V9z"
          />
        );

      case 'new-notepad':
        return (
          <g>
            <g>
              <polygon
                points="16.5,17.5 16.5,23.5 1.5,23.5
                                                                                                                                                                                                                                      					1.5,2.5 22.5,2.5 22.5,17.5 				"
              />
              <line x1="22.5" y1="17.5" x2="16.5" y2="23.5" />
            </g>
            <line x1="4.5" y1="0.5" x2="4.5" y2="5" />
            <line x1="9.5" y1="0.5" x2="9.5" y2="5" />
            <line x1="14.5" y1="0.5" x2="14.5" y2="5" />
            <line x1="19.5" y1="0.5" x2="19.5" y2="5" />
          </g>
        );

      case 'new-notepad-filled':
        return (
          <g>
            <g>
              <path
                d="M22.5,2H21v3c0,0.829-0.672,1.5-1.5,1.5S18,5.829,18,5V2h-2v3c0,0.829-0.672,1.5-1.5,1.5S13,5.829,13,5V2h-2v3
                                                                                                                                                                                                                                      					c0,0.829-0.672,1.5-1.5,1.5S8,5.829,8,5V2H6v3c0,0.829-0.672,1.5-1.5,1.5S3,5.829,3,5V2H1.5C1.224,2,1,2.224,1,2.5v21
                                                                                                                                                                                                                                      					C1,23.776,1.224,24,1.5,24h15c0.139,0,0.264-0.056,0.354-0.147l5.998-5.999C22.943,17.764,23,17.639,23,17.5v-15
                                                                                                                                                                                                                                      					C23,2.224,22.776,2,22.5,2z M17,22.293V18h4.293L17,22.293z"
              />
            </g>
            <g>
              <path d="M4.5,5.5C4.224,5.5,4,5.276,4,5V0.5C4,0.224,4.224,0,4.5,0S5,0.224,5,0.5V5C5,5.276,4.776,5.5,4.5,5.5z" />
            </g>
            <g>
              <path d="M9.5,5.5C9.224,5.5,9,5.276,9,5V0.5C9,0.224,9.224,0,9.5,0S10,0.224,10,0.5V5C10,5.276,9.776,5.5,9.5,5.5z" />
            </g>
            <g>
              <path d="M14.5,5.5C14.224,5.5,14,5.276,14,5V0.5C14,0.224,14.224,0,14.5,0S15,0.224,15,0.5V5C15,5.276,14.776,5.5,14.5,5.5z" />
            </g>
            <g>
              <path d="M19.5,5.5C19.224,5.5,19,5.276,19,5V0.5C19,0.224,19.224,0,19.5,0S20,0.224,20,0.5V5C20,5.276,19.776,5.5,19.5,5.5z" />
            </g>
          </g>
        );
      case 'paperclip-1-filled':
        return (
          <path
            d="M21.613,1.487c-2.773-2.28-6.869-1.879-9.148,0.894L1.688,15.491c-1.754,2.133-1.446,5.283,0.688,7.038
                                                                                                                                                                                                                            			c2.132,1.753,5.283,1.445,7.037-0.688L19.736,9.283c1.052-1.28,0.868-3.17-0.412-4.223c-1.279-1.053-3.17-0.868-4.223,0.412
                                                                                                                                                                                                                            			l-7.621,9.27c-0.351,0.427-0.289,1.058,0.138,1.408c0.426,0.35,1.056,0.288,1.407-0.138l7.62-9.27
                                                                                                                                                                                                                            			c0.35-0.426,0.981-0.488,1.408-0.138c0.427,0.351,0.488,0.982,0.137,1.409C18.188,8.016,7.868,20.571,7.868,20.571
                                                                                                                                                                                                                            			c-1.052,1.279-2.943,1.464-4.222,0.413c-1.28-1.052-1.465-2.943-0.413-4.223L14.01,3.651c1.578-1.92,4.413-2.197,6.333-0.619
                                                                                                                                                                                                                            			c1.92,1.579,2.198,4.414,0.619,6.335l-7.652,9.307c-0.353,0.426-0.291,1.058,0.136,1.41c0.424,0.352,1.052,0.291,1.404-0.135
                                                                                                                                                                                                                            			l7.656-9.312C24.787,7.865,24.387,3.767,21.613,1.487z"
          />
        );

      case 'pencil-write-2':
        return (
          <g>
            <polyline
              points="
                                                                                                                                                                                                                                                  19.499,11.478 19.499,23.478 0.499,23.478 0.499,2.478 14.499,2.478 			"
            />
            <g>
              <polygon
                points="
                                                                                                                                                                                                                                                    12.823,14.029 7.874,16.15 9.995,11.2 20.673,0.522 23.501,3.351 				"
              />
              <line x1="21.666" y1="5.187" x2="18.837" y2="2.358" />
              <line x1="12.823" y1="14.029" x2="9.995" y2="11.2" />
            </g>
          </g>
        );

      case 'phone-5':
        return (
          <path
            d="M8.586,7.902
                                                                                                                                                                                                                                                c0.902-0.903,0.902-2.368,0-3.271L6.132,2.178c-0.903-0.903-2.368-0.903-3.271,0L1.516,3.523
                                                                                                                                                                                                                                                C0.348,4.691,0.167,6.512,1.077,7.889c3.909,5.91,9.124,11.125,15.034,15.034c1.375,0.909,3.201,0.728,4.365-0.438l1.346-1.346
                                                                                                                                                                                                                                                c0.903-0.903,0.903-2.368,0-3.271l-2.453-2.453c-0.902-0.904-2.367-0.904-3.271,0l-0.817,0.817
                                                                                                                                                                                                                                                c-2.69-2.204-5.309-4.823-7.513-7.514L8.586,7.902z"
          />
        );

      case 'phone-5-filled':
        return (
          <path
            d="M21.999,17.691l-2.453-2.453c-1.001-1.002-2.622-1.002-3.625,0l-0.657,0.657c-2.57-2.126-5.038-4.594-7.159-7.16
                                                                                                                                                                                                                                              l0.657-0.657c0.999-0.999,0.999-2.625,0-3.624L6.309,2.001c-1.001-1.002-2.623-1.002-3.625,0L1.338,3.347
                                                                                                                                                                                                                                              c-1.243,1.242-1.441,3.211-0.47,4.68c3.934,5.947,9.157,11.17,15.105,15.104c1.45,0.959,3.409,0.803,4.68-0.47l1.346-1.346
                                                                                                                                                                                                                                              C23.003,20.314,22.999,18.69,21.999,17.691z"
          />
        );

      case 'rank-army-star-2':
        return (
          <polygon
            points="12,0.5 15,8.5 23.5,8.5
                                                                                                                                                                                                                                      				16.5,14.5 19.5,23.5 12,18 4.5,23.5 7.5,14.5 0.5,8.5 9,8.5"
          />
        );

      case 'rank-army-star-2-filled':
        return (
          <path
            d="M23.973,8.336C23.903,8.135,23.713,8,23.5,8h-8.146l-2.882-7.666C12.401,0.134,12.213,0,12,0s-0.401,0.134-0.472,0.334
                                                                                                                                                                                                                                      			L8.646,8H0.5C0.287,8,0.097,8.135,0.027,8.336c-0.07,0.202-0.004,0.425,0.164,0.557l6.723,5.783l-2.889,8.666
                                                                                                                                                                                                                                      			c-0.069,0.206,0.004,0.435,0.18,0.562c0.176,0.128,0.414,0.128,0.59-0.001L12,18.62l7.205,5.283
                                                                                                                                                                                                                                      			c0.176,0.129,0.414,0.129,0.59,0.001c0.176-0.128,0.249-0.356,0.18-0.562l-2.889-8.666l6.723-5.783
                                                                                                                                                                                                                                      			C23.977,8.761,24.043,8.538,23.973,8.336z"
          />
        );

      case 'search-filled':
        return (
          <path
            d="M9,18c2.131,0,4.089-0.749,5.633-1.992l7.658,7.697c0.389,0.392,1.021,0.393,1.414,0.003
                                                                                                                                                                                                                                      				c0.392-0.39,0.393-1.023,0.004-1.414l-7.668-7.706C17.264,13.052,18,11.111,18,9c0-4.963-4.037-9-9-9S0,4.037,0,9
                                                                                                                                                                                                                                      				C0,13.962,4.037,18,9,18z M9,2c3.859,0,7,3.14,7,7c0,3.859-3.141,7-7,7c-3.86,0-7-3.141-7-7C2,5.14,5.14,2,9,2z"
          />
        );

      case 'server-network-1':
        return (
          <g>
            <rect x="0.5" y="5.5" width="23" height="6" />
            <polyline
              points="0.5,5.5
                                                                                                                                                                                                                                      				6.5,0.5 17.5,0.5 23.5,5.5"
            />
            <circle cx="14" cy="8.5" r="0.5" />
            <circle cx="17" cy="8.5" r="0.5" />
            <circle cx="20" cy="8.5" r="0.5" />
            <circle cx="3.5" cy="8.5" r="1" />
            <path
              d="M23.5,11.5v4
                                                                                                                                                                                                                                      				c0,1.104-0.895,2-2,2h-19c-1.104,0-2-0.896-2-2v-4"
            />
            <circle cx="14" cy="14.5" r="0.5" />
            <circle cx="17" cy="14.5" r="0.5" />
            <circle cx="20" cy="14.5" r="0.5" />
            <circle cx="3.5" cy="14.5" r="1" />
            <line x1="5.5" y1="23.5" x2="18.5" y2="23.5" />
            <line x1="12.5" y1="17.5" x2="12.5" y2="23.5" />
            <g>
              <g>
                <g>
                  <path d="M1.5,23C1.224,23,1,23.224,1,23.5S1.224,24,1.5,24S2,23.776,2,23.5S1.776,23,1.5,23L1.5,23z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path d="M3.5,23C3.224,23,3,23.224,3,23.5S3.224,24,3.5,24S4,23.776,4,23.5S3.776,23,3.5,23L3.5,23z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path d="M20.5,23c-0.275,0-0.5,0.224-0.5,0.5s0.225,0.5,0.5,0.5c0.276,0,0.5-0.224,0.5-0.5S20.776,23,20.5,23L20.5,23z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path d="M22.5,23c-0.275,0-0.5,0.224-0.5,0.5s0.225,0.5,0.5,0.5c0.276,0,0.5-0.224,0.5-0.5S22.776,23,22.5,23L22.5,23z" />
                </g>
              </g>
            </g>
          </g>
        );

      case 'server-network-1-filled':
        return (
          <g>
            <circle cx="3.5" cy="14.5" r="0.5" />
            <circle cx="3.5" cy="8.5" r="0.5" />
            <path
              d="M0,12v3.5C0,16.879,1.121,18,2.5,18H12v5H5.5C5.224,23,5,23.225,5,23.5C5,23.776,5.224,24,5.5,24h13
                                                                                                                                                                                                                                                  c0.276,0,0.5-0.224,0.5-0.5c0-0.275-0.224-0.5-0.5-0.5H13v-5h8.5c1.379,0,2.5-1.121,2.5-2.5V12H0z M3.5,16
                                                                                                                                                                                                                                                  C2.673,16,2,15.327,2,14.5S2.673,13,3.5,13S5,13.673,5,14.5S4.327,16,3.5,16z M14,15.5c-0.552,0-1-0.448-1-1s0.448-1,1-1
                                                                                                                                                                                                                                                  c0.551,0,1,0.448,1,1S14.551,15.5,14,15.5z M17,15.5c-0.552,0-1-0.448-1-1s0.448-1,1-1c0.551,0,1,0.448,1,1S17.551,15.5,17,15.5z
                                                                                                                                                                                                                                                   M20,15.5c-0.551,0-1-0.448-1-1s0.449-1,1-1c0.552,0,1,0.448,1,1S20.552,15.5,20,15.5z"
            />
            <path d="M17.82,0.116C17.729,0.041,17.617,0,17.5,0h-11C6.383,0,6.269,0.041,6.18,0.116L0.318,5h23.363L17.82,0.116z" />
            <path
              d="M24,6H0v5h24V6z M3.5,10C2.673,10,2,9.327,2,8.5S2.673,7,3.5,7S5,7.673,5,8.5S4.327,10,3.5,10z M14,9.5
                                                                                                                                                                                                                                                  c-0.552,0-1-0.448-1-1s0.448-1,1-1c0.551,0,1,0.448,1,1S14.551,9.5,14,9.5z M17,9.5c-0.552,0-1-0.448-1-1s0.448-1,1-1
                                                                                                                                                                                                                                                  c0.551,0,1,0.448,1,1S17.551,9.5,17,9.5z M20,9.5c-0.551,0-1-0.448-1-1s0.449-1,1-1c0.552,0,1,0.448,1,1S20.552,9.5,20,9.5z"
            />
            <circle cx="1.5" cy="23.5" r="0.5" />
            <circle cx="3.5" cy="23.5" r="0.5" />
            <circle cx="20.5" cy="23.5" r="0.5" />
            <circle cx="22.5" cy="23.5" r="0.5" />
          </g>
        );

      case 'shrink-horizontal-1-filled':
        return (
          <g>
            <path
              d="M21.207,22.294l-8.5-8.5c-0.391-0.392-1.023-0.392-1.414,0l-8.5,8.5c-0.391,0.39-0.391,1.023,0,1.413
                                                                                                                                                                                                                                      				c0.391,0.392,1.023,0.392,1.414,0L12,15.915l7.793,7.792c0.391,0.392,1.023,0.392,1.414,0
                                                                                                                                                                                                                                      				C21.598,23.316,21.598,22.684,21.207,22.294z"
            />
            <path
              d="M21.207,1.706l-8.5,8.5c-0.391,0.392-1.023,0.392-1.414,0l-8.5-8.5c-0.391-0.39-0.391-1.023,0-1.413
                                                                                                                                                                                                                                      				c0.391-0.392,1.023-0.392,1.414,0L12,8.085l7.793-7.792c0.391-0.392,1.023-0.392,1.414,0C21.598,0.684,21.598,1.316,21.207,1.706
                                                                                                                                                                                                                                      				z"
            />
          </g>
        );

      case 'video-camera':
        return (
          <g>
            <path
              d="M15.5,17.5c0,0.55-0.45,1-1,1h-13
                                                                                                                                                                                                                                                c-0.55,0-1-0.45-1-1v-11c0-0.55,0.45-1,1-1h13c0.55,0,1,0.45,1,1V17.5z"
            />
            <polygon
              points="23.5,17.5 17.5,14.5 17.5,9.5
                                                                                                                                                                                                                                                23.5,6.5"
            />
          </g>
        );
      case 'video-camera-filled':
        return (
          <g>
            <path
              d="M14.5,5.001h-13c-0.827,0-1.5,0.672-1.5,1.5v11c0,0.826,0.673,1.5,1.5,1.499h13c0.827,0.001,1.5-0.673,1.5-1.499v-11
                                                                                                                                                                                                                                                C16,5.673,15.327,5.001,14.5,5.001z"
            />
            <path
              d="M23.276,6.053l-6,3C17.107,9.138,17,9.311,17,9.501v5c0,0.188,0.107,0.361,0.276,0.447l6,3
                                                                                                                                                                                                                                                C23.611,18.114,24,17.87,24,17.501v-11C24,6.129,23.609,5.887,23.276,6.053z"
            />
          </g>
        );
      case 'video-meeting-group':
        return (
          <g>
            <g>
              <path
                d="M7,12.5V12
                                      c0-0.55,0.433-1.123,0.961-1.274L10.5,10V8.604"
              />
              <path
                d="M13.5,8.604V10l2.538,0.726
                                      C16.567,10.877,17,11.45,17,12v0.5"
              />
              <ellipse cx="12" cy="5.771" rx="3" ry="3.272" />
              <path
                d="M14.958,5.213
                                      c-0.5,0.5-1.919,0.461-2.458-0.542c-1,1-2.625,1-3.434,0.429"
              />
            </g>
            <circle cx="4.5" cy="17.015" r="2.5" />
            <circle cx="12" cy="17.015" r="2.5" />
            <circle cx="19.5" cy="17.015" r="2.5" />
            <path
              d="M19.5,19.507
                                      c-1.733,0-3.194,1.112-3.75,2.654c-0.556-1.542-2.017-2.648-3.75-2.648s-3.194,1.109-3.75,2.65
                                      c-0.556-1.541-2.017-2.656-3.75-2.656c-2.21,0-4,1.993-4,3.993h23C23.5,21.5,21.71,19.507,19.5,19.507z"
            />
            <path
              d="M20.5,10.5
                                      c0,1.101-0.9,2-2,2h-13c-1.1,0-2-0.899-2-2v-8c0-1.1,0.9-2,2-2h13c1.1,0,2,0.9,2,2V10.5z"
            />
          </g>
        );
      case 'view-2':
        return (
          <g>
            <path
              d="M23.5,12
                                                                                                                                                                                                                                              c0,0-5.148,6.5-11.5,6.5S0.5,12,0.5,12S5.648,5.5,12,5.5S23.5,12,23.5,12z"
            />
            <path
              d="M23.5,12
                                                                                                                                                                                                                                              c0,0-5.148,6.5-11.5,6.5S0.5,12,0.5,12S5.648,5.5,12,5.5S23.5,12,23.5,12z"
            />
            <circle cx="12" cy="12" r="4" />
          </g>
        );
      case 'add-plus':
        return (
          <g id="Filled_Icons">
            <path
              d="M23,11H13V1.001C13,0.448,12.574,0,12.021,0l0,0c-0.553,0-1,0.447-1,0.999L11.012,11H1c-0.553,0-1,0.447-1,1
                                                                                                                                                                                                                                      			c0,0.552,0.447,1,1,1h10.01L11,22.999c-0.001,0.552,0.446,1,0.998,1.001h0.001c0.552,0,1-0.447,1.001-0.999V13h10
                                                                                                                                                                                                                                      			c0.552,0,1-0.448,1-1C24,11.447,23.552,11,23,11z"
            />
          </g>
        );

      case 'remove-minus':
        return (
          <g id="Filled_Icons">
            <path
              d="M23.002,11.021L1,11l0,0c-0.553,0-1,0.447-1,0.999c-0.001,0.552,0.446,1,0.998,1.001H23c0.553,0,1-0.426,1-0.977
                                                                                                                                                                                                                                      C24.001,11.471,23.554,11.023,23.002,11.021z"
            />
          </g>
        );

      case 'plus':
        return (
          <g id="Filled_Icons">
            <path
              d="M23,11H13V1.001C13,0.448,12.574,0,12.021,0l0,0c-0.553,0-1,0.447-1,0.999L11.012,11H1c-0.553,0-1,0.447-1,1
                                                                                                                                                                                                                                                  c0,0.552,0.447,1,1,1h10.01L11,22.999c-0.001,0.552,0.446,1,0.998,1.001h0.001c0.552,0,1-0.447,1.001-0.999V13h10
                                                                                                                                                                                                                                                  c0.552,0,1-0.448,1-1C24,11.447,23.552,11,23,11z"
            />
          </g>
        );
      case 'volume-low-1':
        return (
          <g>
            <path
              d="M12.146,1.146L5.293,8H2c-1.102,0-2,0.898-2,2v3c0,1.104,0.897,2,2,2h3.293l6.853,6.854C12.46,22.166,13,21.944,13,21.5
                                                                                                                                                                                                                                                    v-20C13,1.056,12.46,0.831,12.146,1.146z"
            />
            <path
              d="M15.794,7.596c-0.224-0.162-0.536-0.112-0.698,0.11c-0.162,0.224-0.113,0.536,0.11,0.698
                                                                                                                                                                                                                                                    c2.387,1.737,2.413,4.437,0.001,6.191c-0.225,0.162-0.273,0.475-0.111,0.698c0.165,0.228,0.48,0.271,0.697,0.111
                                                                                                                                                                                                                                                    C18.739,13.268,18.739,9.736,15.794,7.596z"
            />
          </g>
        );
      case 'volume-max-1':
        return (
          <g>
            <path
              d="M12.146,1.146L5.293,8H2c-1.102,0-2,0.898-2,2v3c0,1.104,0.897,2,2,2h3.293l6.853,6.854C12.46,22.167,13,21.947,13,21.5
                                                                                                                                                                                                                                                    v-20C13,1.056,12.46,0.831,12.146,1.146z"
            />
            <path
              d="M20.329,3.124c-0.208-0.182-0.524-0.161-0.706,0.047c-0.182,0.208-0.161,0.524,0.047,0.706
                                                                                                                                                                                                                                                    c4.437,3.878,4.443,11.363,0,15.247c-0.208,0.182-0.229,0.497-0.047,0.705c0.183,0.21,0.501,0.228,0.706,0.048
                                                                                                                                                                                                                                                    C25.229,15.593,25.22,7.398,20.329,3.124z"
            />
            <path
              d="M17.818,5.114c-0.212-0.175-0.528-0.145-0.704,0.067c-0.176,0.213-0.146,0.528,0.067,0.704
                                                                                                                                                                                                                                                    c3.717,3.067,3.73,8.14,0,11.229c-0.213,0.176-0.242,0.491-0.066,0.704c0.175,0.212,0.492,0.241,0.704,0.066
                                                                                                                                                                                                                                                    C22.039,14.393,22.008,8.572,17.818,5.114z"
            />
            <path
              d="M15.794,7.596c-0.223-0.162-0.536-0.112-0.699,0.11c-0.162,0.224-0.113,0.536,0.111,0.698
                                                                                                                                                                                                                                                    c2.428,1.768,2.373,4.467,0,6.191c-0.224,0.162-0.273,0.475-0.111,0.698c0.165,0.226,0.48,0.272,0.699,0.11
                                                                                                                                                                                                                                                    C18.734,13.269,18.743,9.74,15.794,7.596z"
            />
          </g>
        );
      case 'download-box-4':
        return (
          <g>
            <g>
              <line x1="11.5" y1="0.5" x2="11.5" y2="17.115" />
              <polyline
                points="
                                                                                                                                                                                                        					15.5,12.115 11.5,17.115 7.5,12.115"
              />
            </g>
            <polyline
              points="9,7.5
                                                                                                                                                                                                        				3.5,7.5 3.5,23.5 19.5,23.5 19.5,7.5 14,7.5"
            />
          </g>
        );
      case 'file-view-1':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="4" />
              <line x1="20.328" y1="20.328" x2="23.5" y2="23.5" />
            </g>
            <polyline
              points="11,21.5
                                                                                                                                                                                                                    0.5,21.5 0.5,0.5 11.5,0.5 16.5,5.5 16.5,10"
            />
            <polyline
              points="11.5,0.5
                                                                                                                                                                                                                    11.5,5.5 16.5,5.5"
            />
          </g>
        );
      case 'arrow-double-left':
        return (
          <g>
            <polygon points=" 12.768,18.5 6.634,18.5 0.501,11.967 6.634,5.5 12.768,5.5 6.634,11.967 		" />
            <polygon points=" 23.501,18.5 17.368,18.5 11.234,11.967 17.368,5.5 23.501,5.5 17.368,11.967 		" />
          </g>
        );
      case 'arrow-double-right':
        return (
          <g>
            <polygon points=" 11.233,18.5 17.367,18.5 23.5,11.967 17.367,5.5 11.233,5.5 17.367,11.967 		" />
            <polygon points="0.5,18.5 6.633,18.5 12.767,11.967 6.633,5.5 0.5,5.5 6.633,11.967 		" />
          </g>
        );
      case 'phone-call-forward':
        return (
          <g>
            <g>
              <path d="M7.492,10.012 c0.78-0.781,0.78-2.047,0-2.828L5.37,5.062c-0.781-0.781-2.047-0.781-2.828,0L1.378,6.226C0.369,7.234,0.212,8.81,1,10 c3.381,5.111,7.89,9.62,13,13c1.188,0.786,2.767,0.629,3.774-0.379l1.163-1.163c0.782-0.781,0.782-2.048,0-2.828l-2.12-2.121 c-0.782-0.781-2.048-0.781-2.829,0l-0.707,0.707c-2.327-1.907-4.59-4.171-6.496-6.497L7.492,10.012z" />
            </g>
            <path d="M18.5,7c0,0,0-0.375,0-1.5 c-7,0-6.935,5.317-6.935,5.317S10.5,2,18.5,2c0-0.375,0-1.5,0-1.5l4.315,3.192L18.5,7z" />
          </g>
        );
      case 'phone-call-split':
        return (
          <g>
            <path d="M7.491,10.012 c0.781-0.781,0.781-2.047,0-2.828L5.37,5.062c-0.781-0.78-2.048-0.78-2.829,0L1.378,6.226C0.368,7.234,0.213,8.81,1,10 c3.38,5.11,7.889,9.62,13,13c1.188,0.786,2.768,0.629,3.773-0.379l1.164-1.163c0.781-0.781,0.781-2.047,0-2.828l-2.121-2.122 c-0.781-0.78-2.048-0.78-2.829,0l-0.706,0.707c-2.327-1.906-4.591-4.17-6.496-6.496L7.491,10.012z" />
            <polyline points=" 11.5,4.5 11.5,0.5 15.5,0.5 			" />
            <polyline points=" 11.5,0.5 17.5,6 17.5,12.473 			" />
            <polyline points=" 23.5,4.5 23.5,0.5 19.5,0.5 			" />
            <line x1="23.5" y1="0.5" x2="19.315" y2="4.316" />
          </g>
        );
      case 'phone-call-pause':
        return (
          <g>
            <g>
              <path d="M7.492,10.014 c0.78-0.781,0.78-2.047,0-2.828L5.37,5.064c-0.781-0.781-2.047-0.781-2.828,0L1.378,6.228C0.369,7.236,0.212,8.812,1,10.002 c3.381,5.111,7.89,9.62,13,13c1.188,0.786,2.767,0.629,3.774-0.379l1.163-1.163c0.782-0.781,0.782-2.048,0-2.828l-2.12-2.121 c-0.781-0.781-2.048-0.781-2.829,0l-0.707,0.707c-2.327-1.907-4.59-4.171-6.496-6.497L7.492,10.014z" />
            </g>
            <rect x="14.5" y="1.5" width="3" height="9" />
            <rect x="19.5" y="1.5" width="3" height="9" />
          </g>
        );
      case 'phone-call-timeout':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="6.5" r="6" />
              <polyline points="20,7.5 17.5,7.5 17.5,4 				" />
            </g>
            <g>
              <path d="M7.491,10.013 c0.78-0.781,0.78-2.047,0-2.828L5.37,5.062c-0.781-0.78-2.048-0.78-2.829,0L1.378,6.227c-1.01,1.009-1.165,2.584-0.379,3.774 c3.381,5.11,7.89,9.619,13,13c1.188,0.785,2.768,0.629,3.774-0.379l1.164-1.163c0.781-0.781,0.781-2.048,0-2.829l-2.121-2.121 c-0.781-0.781-2.048-0.781-2.829,0l-0.707,0.707c-2.326-1.906-4.59-4.17-6.496-6.496L7.491,10.013z" />
            </g>
          </g>
        );
      case 'phone-call-merge':
        return (
          <g>
            <path d="M7.492,10.014 c0.78-0.781,0.78-2.047,0-2.828L5.37,5.064c-0.78-0.781-2.047-0.781-2.828,0L1.378,6.228C0.368,7.236,0.213,8.812,1,10.002 c3.38,5.111,7.89,9.62,13,13c1.188,0.786,2.768,0.629,3.774-0.378l1.164-1.164c0.78-0.781,0.78-2.047,0-2.828l-2.122-2.122 c-0.781-0.78-2.048-0.78-2.828,0l-0.707,0.707c-2.326-1.906-4.591-4.17-6.496-6.496L7.492,10.014z" />
            <polyline points=" 14.5,3.5 17.5,0.5 20.5,3.5 			" />
            <polyline points=" 17.5,0.5 17.5,7.979 13.5,12 			" />
            <line x1="19.023" y1="9.49" x2="21.5" y2="12" />
          </g>
        );
      case 'phone-call-refresh':
        return (
          <g>
            <g>
              <path d="M7.676,10.014 c0.78-0.781,0.78-2.047,0-2.828L5.554,5.064c-0.781-0.781-2.047-0.781-2.828,0L1.562,6.228 c-1.009,1.009-1.166,2.584-0.378,3.774c3.381,5.111,7.89,9.62,13,13c1.188,0.786,2.767,0.629,3.774-0.379l1.163-1.163 c0.782-0.781,0.782-2.048,0-2.828l-2.12-2.121c-0.781-0.781-2.048-0.781-2.829,0l-0.707,0.707 c-2.327-1.907-4.59-4.171-6.496-6.497L7.676,10.014z" />
            </g>
            <g>
              <polyline points=" 10.959,3.701 13.246,6.436 15.875,4.014 				" />
              <path d="M13.249,6.436 c-0.262-3.454,2.105-5.829,4.936-5.934c2.829-0.104,5.208,2.105,5.312,4.936s-2.105,5.208-4.936,5.312" />
            </g>
          </g>
        );
      case 'phone-ringing':
        return (
          <g>
            <path d="M21,14 c1.381,0.282,2.5,1.591,2.5,3v1.5c0,1.093-0.907,2-2,2h-3c-1.092,0-2-0.907-2-2v-1c-2.965-0.294-6.033-0.294-9,0v1 c0,1.093-0.907,2-2,2h-3c-1.093,0-2-0.907-2-2V17c0-1.409,1.119-2.718,2.5-3C8.751,12.828,14.848,12.747,21,14z" />
            <line x1="11.5" y1="2.5" x2="11.5" y2="8.5" />
            <line x1="20.402" y1="5.842" x2="16.159" y2="10.085" />
            <line x1="2.598" y1="5.842" x2="6.841" y2="10.085" />
          </g>
        );
      case 'list-bullets-1':
        return (
          <g>
            <circle cx="2.5" cy="4.5" r="2" />
            <line x1="8.5" y1="4.5" x2="23.5" y2="4.5" />
            <circle cx="2.5" cy="12.5" r="2" />
            <line x1="8.5" y1="12.5" x2="23.5" y2="12.5" />
            <circle cx="2.5" cy="20.5" r="2" />
            <line x1="8.5" y1="20.5" x2="23.5" y2="20.5" />
          </g>
        );
      case 'list-bullets-1-filled':
        return (
          <g id="Filled_Icon">
            <g>
              <circle cx="2.715" cy="4.573" r="2.5" />
              <path d="M8.784,6H23c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H8.784c-0.552,0-1,0.448-1,1C7.784,5.553,8.232,6,8.784,6z" />
              <circle cx="2.715" cy="12.573" r="2.5" />
              <path d="M23,12H8.784c-0.552,0-1,0.448-1,1c0,0.553,0.448,1,1,1H23c0.552,0,1-0.447,1-1C24,12.448,23.552,12,23,12z" />
              <circle cx="2.715" cy="20.573" r="2.5" />
              <path d="M23,20H8.784c-0.552,0-1,0.448-1,1c0,0.553,0.448,1,1,1H23c0.552,0,1-0.447,1-1C24,20.448,23.552,20,23,20z" />
            </g>
          </g>
        );
      case 'list-numbers':
        return (
          <g>
            <line x1="6.5" y1="4.5" x2="23.5" y2="4.5" />
            <line x1="6.5" y1="12.5" x2="23.5" y2="12.5" />
            <line x1="6.5" y1="20.5" x2="23.5" y2="20.5" />
            <polyline points="2,6.5 2,2.573 0.5,4.037 		" />
            <path d="M2.569,14.5H0.5 c1.48-2,2-1.836,2-2.963c0-0.552-0.449-0.982-1-0.982c-0.553,0-0.965,0.422-0.965,0.974" />
            <path d="M0.5,18.5h1.931 l-1,1.537c0.826,0,1,0.481,1,0.981S2.257,22,1.431,22H0.5" />
          </g>
        );
      case 'list-numbers-filled':
        return (
          <g>
            <path d="M7.069,6H23c0.552,0,1-0.447,1-1s-0.448-1-1-1H7.069c-0.553,0-1,0.447-1,1S6.517,6,7.069,6z" />
            <path d="M23,12H7.069c-0.553,0-1,0.447-1,1s0.447,1,1,1H23c0.552,0,1-0.447,1-1S23.552,12,23,12z" />
            <path d="M23,20H7.069c-0.553,0-1,0.447-1,1s0.447,1,1,1H23c0.552,0,1-0.447,1-1S23.552,20,23,20z" />
            <path
              d="M1.523,4.635L2.25,4.082V6.5c0,0.414,0.336,0.75,0.75,0.75S3.75,6.914,3.75,6.5V2.573c0-0.622-0.713-0.972-1.203-0.598
                                                                                                                                                                                                                                                                                                                                                                                                                          			L0.616,3.44C0.286,3.69,0.222,4.16,0.472,4.49C0.722,4.818,1.192,4.883,1.523,4.635z"
            />
            <path
              d="M3.069,13.75h-0.48c0.731-0.798,1.161-1.102,1.161-2.213c0-0.955-0.785-1.732-1.75-1.732
                                                                                                                                                                                                                                                                                                                                                                                                                          			c-0.962,0-1.716,0.756-1.716,1.723c0,0.414,0.336,0.75,0.75,0.75c0.414,0,0.75-0.336,0.75-0.75c0-0.142,0.079-0.223,0.216-0.223
                                                                                                                                                                                                                                                                                                                                                                                                                          			c0.143,0,0.25,0.1,0.25,0.232c0,0.391-0.019,0.41-0.482,0.897c-0.325,0.343-0.772,0.81-1.371,1.619
                                                                                                                                                                                                                                                                                                                                                                                                                          			C0.031,14.549,0.384,15.25,1,15.25h2.069c0.414,0,0.75-0.336,0.75-0.75S3.483,13.75,3.069,13.75z"
            />
            <path
              d="M3.628,18.909C3.952,18.41,3.594,17.75,3,17.75H1.069c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75h0.547
                                                                                                                                                                                                                                                                                                                                                                                                                          			l-0.245,0.377C1.047,20.124,1.404,20.785,2,20.785c0.152,0,0.222,0.027,0.222,0.02c0.059,0.107,0.01,0.411,0.011,0.412
                                                                                                                                                                                                                                                                                                                                                                                                                          			c-0.1,0.052-1.04,0.033-1.163,0.033c-0.414,0-0.75,0.336-0.75,0.75s0.336,0.75,0.75,0.75H2c1.096,0,1.75-0.648,1.75-1.732
                                                                                                                                                                                                                                                                                                                                                                                                                          			c0-0.615-0.211-1.09-0.593-1.385L3.628,18.909z"
            />
          </g>
        );
      case 'text-bold':
        return (
          <g>
            <line x1="6.5" y1="0.5" x2="6.5" y2="23.5" />
            <path d="M6.5,11.5H13 c3.037,0,5.5-2.462,5.5-5.5c0-3.037-2.463-5.5-5.5-5.5H3.5" />
            <path d="M13,11.5h1.5 c3.312,0,6,2.687,6,6s-2.688,6-6,6h-11" />
          </g>
        );
      case 'text-bold-filled':
        return (
          <g id="Filled_Icon">
            <path
              d="M17.685,11.465C19.099,10.271,20,7.99,20,6c0-3.477-2.734-6-6.5-6H4C3.447,0,3,0.448,3,1c0,0.553,0.447,1,1,1h2v20H4
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        		c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h11c3.859,0,7-3.14,7-7C22,14.189,20.266,12.405,17.685,11.465z M8,2h5.5
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        		C16.149,2,18,3.645,18,6c0,2.481-2.019,5-4.5,5H8V2z M15,22H8v-9h7c2.896,0,5,1.393,5,4C20,19.757,17.757,22,15,22z"
            />
          </g>
        );
      case 'text-italic':
        return (
          <g>
            <line x1="19.5" y1="0.5" x2="4.5" y2="23.5" />
            <line x1="0.5" y1="23.5" x2="8.5" y2="23.5" />
            <line x1="15.5" y1="0.5" x2="23.5" y2="0.5" />
          </g>
        );
      case 'text-italic-filled':
        return (
          <g id="Filled_Icon">
            <path
              d="M23,0h-8c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h2.178L4.45,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h8
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          		c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H6.821L19.549,2H23c0.552,0,1-0.447,1-1C24,0.448,23.552,0,23,0z"
            />
          </g>
        );
      case 'text-underline':
        return (
          <g>
            <line x1="0.5" y1="23.5" x2="23.5" y2="23.5" />
            <path d="M4.5,0.5V12 c0,4.142,3.357,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V0.5" />
            <line x1="1.5" y1="0.5" x2="7.5" y2="0.5" />
            <line x1="16.5" y1="0.5" x2="22.5" y2="0.5" />
          </g>
        );
      case 'text-underline-filled':
        return (
          <g>
            <path d="M23,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,22.448,23.552,22,23,22z" />
            <path
              d="M1,2h2v9.5c0,4.688,3.812,8.5,8.5,8.5c4.687,0,8.5-3.812,8.5-8.5V2h2c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1h-6
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      			c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h2v9.5c0,3.584-2.916,6.5-6.5,6.5S5,15.084,5,11.5V2h2c0.552,0,1-0.447,1-1
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      			c0-0.552-0.448-1-1-1H1C0.447,0,0,0.448,0,1C0,1.553,0.447,2,1,2z"
            />
          </g>
        );
      case 'text-strikethrough':
        return (
          <g>
            <path d="M15,6 c0-1.648,0.363-4.5-5-4.5c-2.764,0-5,1.238-5,4c0,6,11,6.5,11,13c0,3.313-2.688,5-6,5c-4.031,0-6.375-1.719-6-5.5" />
            <line x1="0.5" y1="13.5" x2="23.5" y2="13.5" />
          </g>
        );
      case 'text-strikethrough-filled':
        return (
          <g id="Filled_Icon">
            <path
              d="M23,12h-9.626C10.1,9.611,6,8.199,6,5c0-2.707,2.797-3,4-3c3.524,0,4,1.264,4,2.5v1c0,0.553,0.447,1,1,1
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  		c0.552,0,1-0.447,1-1v-1C16,2.447,14.959,0,10,0C6.354,0,4,1.963,4,5c0,3.458,2.883,5.268,5.708,7H1c-0.553,0-1,0.448-1,1
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  		c0,0.553,0.447,1,1,1h11.705c4.005,3.209,2.637,8-2.705,8c-3.318,0-5-1.178-5-3.5v-1c0-0.552-0.448-1-1-1c-0.553,0-1,0.448-1,1v1
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  		c0,3.495,2.551,5.5,7,5.5c6.765,0,8.693-6.102,5.546-10H23c0.552,0,1-0.447,1-1C24,12.448,23.552,12,23,12z"
            />
          </g>
        );
      case 'align-center':
        return (
          <g>
            <line x1="0.5" y1="23.5" x2="23.5" y2="23.5" />
            <line x1="2.5" y1="2.5" x2="21.5" y2="2.5" />
            <line x1="6" y1="5.5" x2="18" y2="5.5" />
            <line x1="3.5" y1="8.5" x2="20.5" y2="8.5" />
            <line x1="0.5" y1="11.5" x2="23.5" y2="11.5" />
            <line x1="4.5" y1="14.5" x2="19.5" y2="14.5" />
            <line x1="7" y1="17.5" x2="17" y2="17.5" />
            <line x1="4.5" y1="20.5" x2="19.5" y2="20.5" />
          </g>
        );
      case 'align-center-filled':
        return (
          <g>
            <path d="M23,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,22.448,23.552,22,23,22z" />
            <path d="M3,3h18c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H3C2.447,1,2,1.448,2,2C2,2.553,2.447,3,3,3z" />
            <path d="M17.5,6c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1h-11c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1H17.5z" />
            <path d="M3,8c0,0.553,0.447,1,1,1h16c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H4C3.447,7,3,7.448,3,8z" />
            <path d="M1,12h22c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1c-0.553,0-1,0.448-1,1C0,11.553,0.447,12,1,12z" />
            <path d="M19,13H5c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h14c0.552,0,1-0.447,1-1C20,13.448,19.552,13,19,13z" />
            <path d="M16.5,18c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1h-9c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1H16.5z" />
            <path d="M4,20c0,0.553,0.447,1,1,1h14c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H5C4.447,19,4,19.448,4,20z" />
          </g>
        );
      case 'align-left':
        return (
          <g>
            <line x1="0.5" y1="23.5" x2="23.5" y2="23.5" />
            <line x1="0.5" y1="2.5" x2="20.5" y2="2.5" />
            <line x1="0.5" y1="5.5" x2="16.5" y2="5.5" />
            <line x1="0.5" y1="8.5" x2="18.5" y2="8.5" />
            <line x1="0.5" y1="11.5" x2="23.5" y2="11.5" />
            <line x1="0.5" y1="14.5" x2="19.5" y2="14.5" />
            <line x1="0.5" y1="17.5" x2="16.5" y2="17.5" />
            <line x1="0.5" y1="20.5" x2="19.5" y2="20.5" />
          </g>
        );
      case 'align-left-filled':
        return (
          <g>
            <path d="M23,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,22.448,23.552,22,23,22z" />
            <path d="M1,3h19c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1C0.447,1,0,1.448,0,2C0,2.553,0.447,3,1,3z" />
            <path d="M1,6h15c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1C0.447,4,0,4.448,0,5C0,5.553,0.447,6,1,6z" />
            <path d="M1,9h17c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1C0.447,7,0,7.448,0,8C0,8.553,0.447,9,1,9z" />
            <path d="M1,12h22c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1c-0.553,0-1,0.448-1,1C0,11.553,0.447,12,1,12z" />
            <path d="M1,15h18c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1c-0.553,0-1,0.448-1,1C0,14.553,0.447,15,1,15z" />
            <path d="M1,18h15c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1c-0.553,0-1,0.448-1,1C0,17.553,0.447,18,1,18z" />
            <path d="M1,21h18c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1c-0.553,0-1,0.448-1,1C0,20.553,0.447,21,1,21z" />
          </g>
        );
      case 'align-justify':
        return (
          <g>
            <line x1="0.5" y1="23.5" x2="17.5" y2="23.5" />
            <line x1="0.5" y1="2.5" x2="23.5" y2="2.5" />
            <line x1="0.5" y1="5.5" x2="23.5" y2="5.5" />
            <line x1="0.5" y1="8.5" x2="23.5" y2="8.5" />
            <line x1="0.5" y1="11.5" x2="23.5" y2="11.5" />
            <line x1="0.5" y1="14.5" x2="23.5" y2="14.5" />
            <line x1="0.5" y1="17.5" x2="23.5" y2="17.5" />
            <line x1="0.5" y1="20.5" x2="23.5" y2="20.5" />
          </g>
        );
      case 'align-justify-filled':
        return (
          <g>
            <path d="M17.5,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h16.5c0.552,0,1-0.447,1-1C18.5,22.448,18.052,22,17.5,22z" />
            <path d="M1,3h22c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H1C0.447,1,0,1.448,0,2C0,2.553,0.447,3,1,3z" />
            <path d="M23,4H1C0.447,4,0,4.448,0,5c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,4.448,23.552,4,23,4z" />
            <path d="M23,7H1C0.447,7,0,7.448,0,8c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,7.448,23.552,7,23,7z" />
            <path d="M23,10H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,10.448,23.552,10,23,10z" />
            <path d="M23,13H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,13.448,23.552,13,23,13z" />
            <path d="M23,16H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,16.448,23.552,16,23,16z" />
            <path d="M23,19H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,19.448,23.552,19,23,19z" />
          </g>
        );
      case 'align-right':
        return (
          <g>
            <line x1="0.5" y1="23.5" x2="23.5" y2="23.5" />
            <line x1="4.5" y1="2.5" x2="23.5" y2="2.5" />
            <line x1="7.5" y1="5.5" x2="23.5" y2="5.5" />
            <line x1="4.5" y1="8.5" x2="23.5" y2="8.5" />
            <line x1="0.5" y1="11.5" x2="23.5" y2="11.5" />
            <line x1="4.5" y1="14.5" x2="23.5" y2="14.5" />
            <line x1="7.5" y1="17.5" x2="23.5" y2="17.5" />
            <line x1="4.5" y1="20.5" x2="23.5" y2="20.5" />
          </g>
        );
      case 'align-right-filled':
        return (
          <g>
            <path d="M23,22H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,22.448,23.552,22,23,22z" />
            <path d="M5,3h18c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H5C4.447,1,4,1.448,4,2C4,2.553,4.447,3,5,3z" />
            <path d="M23,4H8C7.447,4,7,4.448,7,5c0,0.553,0.447,1,1,1h15c0.552,0,1-0.447,1-1C24,4.448,23.552,4,23,4z" />
            <path d="M23,7H5C4.447,7,4,7.448,4,8c0,0.553,0.447,1,1,1h18c0.552,0,1-0.447,1-1C24,7.448,23.552,7,23,7z" />
            <path d="M23,10H1c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h22c0.552,0,1-0.447,1-1C24,10.448,23.552,10,23,10z" />
            <path d="M23,13H5c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h18c0.552,0,1-0.447,1-1C24,13.448,23.552,13,23,13z" />
            <path d="M23,16H8c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h15c0.552,0,1-0.447,1-1C24,16.448,23.552,16,23,16z" />
            <path d="M23,19H5c-0.553,0-1,0.448-1,1c0,0.553,0.447,1,1,1h18c0.552,0,1-0.447,1-1C24,19.448,23.552,19,23,19z" />
          </g>
        );
      case 'font-size':
        return (
          <g>
            <polyline points=" 15.979,22.5 8.979,2 2,22.5 		" />
            <line x1="4.383" y1="15.5" x2="13.59" y2="15.5" />
            <line x1="0.5" y1="22.5" x2="3.521" y2="22.5" />
            <line x1="14.479" y1="22.5" x2="17.5" y2="22.5" />
            <polyline points=" 22.501,22.5 17.903,9.035 15.543,15.967 		" />
            <line x1="16.92" y1="17.5" x2="20.794" y2="17.5" />
            <line x1="21.516" y1="22.5" x2="23.5" y2="22.5" />
          </g>
        );
      case 'undo':
        return (
          <g>
            <polyline points="1.5,0.5 1.5,7.5 8.5,7.5 		" />
            <path d="M1.5,7.5 c3.689-5.17,11.009-6.336,16.179-2.646c6.371,4.547,6.436,14.016,0.11,18.646" />
          </g>
        );
      case 'redo':
        return (
          <g>
            <polyline points="22.5,0.5 22.5,7.5 15.5,7.5 		" />
            <path d="M22.5,7.5 C18.811,2.33,11.491,1.164,6.321,4.854C-0.05,9.401-0.114,18.87,6.211,23.5" />
          </g>
        );
      case 'indent-left':
        return (
          <g>
            <line x1="0.5" y1="11.5" x2="6.5" y2="11.5" />
            <polyline points="4.5,13.5 6.5,11.5 4.5,9.5 		" />
            <line x1="8.5" y1="0.5" x2="8.5" y2="23.5" />
            <line x1="22.5" y1="22.5" x2="12.5" y2="22.5" />
            <line x1="23.5" y1="1.5" x2="12.5" y2="1.5" />
            <line x1="20.5" y1="4.5" x2="12.5" y2="4.5" />
            <line x1="23.5" y1="7.5" x2="12.5" y2="7.5" />
            <line x1="22.5" y1="10.5" x2="12.5" y2="10.5" />
            <line x1="23.5" y1="13.5" x2="12.5" y2="13.5" />
            <line x1="20.5" y1="16.5" x2="12.5" y2="16.5" />
            <line x1="23.5" y1="19.5" x2="12.5" y2="19.5" />
          </g>
        );
      case 'link':
        return (
          <g>
            <path d="M14.5,12.5 l0.086,0.086c0.781,0.781,2.047,0.781,2.828,0l3.965-3.964c1.166-1.167,1.166-3.075,0-4.243l-1.758-1.757 c-1.166-1.167-3.076-1.167-4.242,0l-3.965,3.964c-0.781,0.781-0.781,2.047,0,2.829L11.5,9.5" />
            <path d="M9.5,11.5 l-0.086-0.085c-0.781-0.781-2.047-0.781-2.828,0l-3.965,3.964c-1.166,1.167-1.166,3.076,0,4.243l1.758,1.757 c1.166,1.167,3.076,1.167,4.242,0l3.965-3.964c0.781-0.781,0.781-2.047,0-2.829L12.5,14.5" />
            <line x1="8.111" y1="15.889" x2="15.889" y2="8.111" />
          </g>
        );
      case 'link-broken':
        return (
          <g>
            <line x1="6.418" y1="5.077" x2="4.418" y2="3.077" />
            <line x1="9.487" y1="4" x2="9.487" y2="1.5" />
            <line x1="5.393" y1="8.5" x2="2.893" y2="8.5" />
            <path d="M10.918,19.077 l-3.403,3.401c-1.361,1.362-3.588,1.362-4.949,0l-1.061-1.06c-1.361-1.361-1.361-3.589,0-4.95l3.402-3.403" />
            <path d="M15.393,8.5h4.5 c1.925,0,3.623,1.652,3.623,3.577v2c0,1.925-1.724,3.423-3.647,3.423h-4.476" />
          </g>
        );
      case 'picture':
        return (
          <g>
            <rect x="0.5" y="0.5" />
            <line x1="23.5" y1="17.5" x2="0.5" y2="17.5" />
            <path d="M9.5,3.5c-1.245,0-2.27,0.914-2.46,2.104 C6.027,5.215,5,5.973,5,7c0,0.828,0.672,1.5,1.5,1.5h3C10.881,8.5,12,7.381,12,6S10.881,3.5,9.5,3.5z" />
            <path d="M3.5,17.5c0,0,2-5,4.5-5s4.5,3,4.5,3 l3.015-5.168c0.672-1.152,2.356-1.093,2.948,0.096L22,17.5" />
          </g>
        );
      case 'color-bucket':
        return (
          <g>
            <path
              d="M20,12.5l-9-9
                                                                                                                                                                                                          L2.5,12c-0.781,0.781-0.781,2.047,0,2.829L7.172,19.5c0.781,0.781,2.047,0.781,2.828,0l7-7H20z"
            />
            <path
              d="M10.5,10.5v-8
                                                                                                                                                                                                          c0-1.101-0.9-2-2-2l0,0c-1.1,0-2,0.899-2,2v5.516"
            />
            <path
              d="M23.5,21
                                                                                                                                                                                                          c0,1.381-1.119,2.5-2.5,2.5s-2.5-1.119-2.5-2.5s2.5-5.5,2.5-5.5S23.5,19.619,23.5,21z"
            />
          </g>
        );
      case 'color-bucket-filled':
        return (
          <g>
            <path
              d="M20.354,12.146l-9-9C11.256,3.048,11.128,3,11,3V2.5C11,1.121,9.878,0,8.5,0S6,1.121,6,2.5v5.293l-3.853,3.852
                                                                                                                                                                                                          c-0.975,0.976-0.975,2.561,0,3.536l4.671,4.672c0.98,0.979,2.56,0.976,3.536,0L17.207,13H20
                                                                                                                                                                                                          C20.442,13,20.669,12.463,20.354,12.146z M10,3.793l-3,3V2.5C7,1.672,7.673,1,8.5,1S10,1.672,10,2.5V3.793z"
            />
            <path
              d="M21.427,15.24c-0.181-0.298-0.673-0.298-0.854,0C20.31,15.674,18,19.522,18,21c0,1.654,1.346,3,3,3s3-1.346,3-3
                                                                                                                                                                                                          C24,19.522,21.691,15.674,21.427,15.24z"
            />
          </g>
        );
      case 'text':
        return (
          <g>
            <polyline points="2.5,3.5 2.5,0.5 20.5,0.5 20.5,3.5" />
            <line x1="11.5" y1="0.5" x2="11.5" y2="23.5" />
            <line x1="8" y1="23.5" x2="15" y2="23.5" />
          </g>
        );
      case 'text-filled':
        return (
          <g id="Filled_Icon">
            <path
              d="M20,0H4C3.447,0,3,0.448,3,1v3c0,0.553,0.447,1,1,1c0.552,0,1-0.447,1-1V2h6v20H8.5c-0.553,0-1,0.448-1,1
                                                                                                                                                                                              		c0,0.553,0.447,1,1,1h7c0.552,0,1-0.447,1-1c0-0.552-0.448-1-1-1H13V2h6v2c0,0.553,0.447,1,1,1c0.552,0,1-0.447,1-1V1
                                                                                                                                                                                              		C21,0.448,20.552,0,20,0z"
            />
          </g>
        );
      case 'HEART':
        return (
          <svg viewBox="0 0 200 200">
            <g transform="translate(0,-852.36218)">
              <g transform="matrix(5.8613591,0,0,6.3477288,6.2182544,850.79852)">
                <g transform="matrix(0.01203588,0,0,-0.01111369,-2.05382,32.695005)">
                  <path
                    style={{ fill: 'black' }}
                    d="m 700,2699 c -282,-64 -479,-276 -521,-559 -12,-88 -7,-261 12,-380 10,-63 45,-185 59,-205 4,-5 13,-26 20,-45 57,-145 258,-366 599,-660 93,-79 245,-213 325,-284 38,-33 74,-65 80,-71 6,-5 60,-54 119,-108 l 107,-99 107,99 c 59,54 113,103 119,108 6,6 42,38 80,71 80,71 232,205 325,284 341,294 542,515 599,660 7,19 16,40 20,45 14,20 49,142 59,205 19,119 24,292 12,380 -43,289 -243,499 -532,560 -162,34 -299,16 -456,-62 -98,-48 -237,-171 -293,-258 -18,-28 -36,-50 -40,-50 -4,0 -22,22 -40,50 -17,27 -71,87 -119,132 -180,171 -412,239 -641,187 z"
                  />
                  <defs>
                    <filter x="-1px" y="-1px" width="210%" height="210%">
                      <feOffset
                        result="offOut"
                        in="SourceAlpha"
                        dx="8"
                        dy="-5"
                      />
                      <feGaussianBlur
                        result="blurOut"
                        in="offOut"
                        stdDeviation="18"
                      />
                      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                  </defs>
                </g>
              </g>
            </g>
          </svg>
        );
      case 'CHRISTMASTREE':
        return (
          <svg viewBox="0 0 195 198">
            <g transform="translate(0,-852.36218)">
              <path
                style={{ fill: 'black' }}
                d="m 100.44221,861.25721 c -0.586404,-0.003 -1.156547,0.26282 -1.608011,0.75421 l -54.18995,58.9619 c -0.90294,0.98279 -0.894417,2.55697 0,3.54925 l 17.728314,0.0443 -39.275652,42.85718 c -1.254679,1.36977 -1.242834,3.58598 0,4.96895 l 22.954347,0.0443 -39.1550526,42.8128 c -1.5189508,1.6605 -1.5046138,4.3127 0,5.9893 l 67.4560356,0.09 0,20.0089 c 0,1.1799 0.860607,2.1297 1.929612,2.1297 l 47.436307,0 c 1.06901,0 1.92962,-0.9498 1.92962,-2.1297 l 0,-19.9201 67.45603,0.089 c 1.51893,-1.6607 1.5046,-4.3574 0,-6.0337 l -38.51186,-42.90141 22.31115,0.0443 c 1.25466,-1.36988 1.24283,-3.58612 0,-4.96896 l -38.59226,-42.99027 17.04492,0.0443 c 0.90287,-0.98286 0.89441,-2.60143 0,-3.5936 l -53.26534,-59.09574 c -0.44721,-0.49607 -1.0618,-0.75115 -1.64821,-0.7542 z"
              />
              <defs>
                <filter x="-1px" y="-1px" width="210%" height="210%">
                  <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
                  <feGaussianBlur
                    result="blurOut"
                    in="offOut"
                    stdDeviation="2"
                  />
                  <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
              </defs>
            </g>
          </svg>
        );
      case 'CLASSIC':
        return (
          <svg viewBox="0 0 150 150">
            <rect
              style={{ fill: 'black' }}
              width="120"
              height="120"
              x="7"
              y="7"
            />
            <filter x="-1px" y="-1px" width="210%" height="210%">
              <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </svg>
        );
      case 'CIRCLE':
        return (
          <svg viewBox="0 0 200 200">
            <circle style={{ fill: 'black' }} cx="93" cy="93" r="85" />
            <filter x="-1px" y="-1px" width="210%" height="210%">
              <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
              <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
              <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
          </svg>
        );
      case 'BALLOON':
        return (
          <svg viewBox="0 0 200 200">
            <g transform="translate(0,-852.36218)">
              <path
                style={{ fill: 'black' }}
                d="m 100.74271,858.86087 c -51.674735,0 -93.5686207,37.0958 -93.5686207,82.86435 0,23.20055 10.7986397,44.1574 28.1558597,59.19998 0,26.9849 -24.253733,40.3142 -29.6412623,45.0023 24.8863953,6.6961 59.2314373,-9.5962 75.3901143,-23.2084 6.342441,1.2024 12.916721,1.8705 19.663909,1.8705 51.6708,0 93.5686,-37.09972 93.5686,-82.86438 0,-45.76855 -41.8978,-82.86435 -93.5686,-82.86435 z"
              />
            </g>
            <defs>
              <filter x="-1px" y="-1px" width="210%" height="210%">
                <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
          </svg>
        );
      case 'STAR':
        return (
          <svg viewBox="0 0 200 200">
            <g transform="translate(0,-852.36218)">
              <path
                style={{ fill: 'black' }}
                d="M 94.999998,135 12.093561,87.052427 -74.405419,128.16656 -54.424069,34.501237 -120.25559,-35.059217 -24.999996,-45 20.81287,-129.10485 59.702712,-41.583276 153.84814,-24.002481 82.627791,40.029612 z"
                transform="matrix(0.72690142,-0.02678522,0.02678705,0.72685168,88.581808,951.77077)"
              />
              <filter x="-1px" y="-1px" width="210%" height="210%">
                <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </g>
          </svg>
        );
      case 'copy':
        return (
          <g>
            <path
              d="M4.5,21C4.224,21,4,20.776,4,20.5V5V4H3.5C3.224,4,3,4.224,3,4.5v17C3,21.776,3.224,22,3.5,22h13
                                                                                                                                                                                                          c0.276,0,0.5-0.224,0.5-0.5V21h-1H4.5z"
            />
            <path
              d="M19,7.5c0-0.133-0.053-0.26-0.146-0.353l-5-5C13.76,2.053,13.632,2,13.5,2h-8C5.224,2,5,2.224,5,2.5v17
                                                                                                                                                                                                          C5,19.776,5.224,20,5.5,20h13c0.276,0,0.5-0.224,0.5-0.5V7.5z M13,8V3l5,5H13z"
            />
          </g>
        );

      case 'settings':
        return (
          <g>
            <path
              fill="none"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M18.5,13.5v-1.729 c1-0.222,1.403-0.601,1.937-1.089l1.562,0.868l1.499-2.598l-1.503-0.866c0.168-0.76,0.151-1.469-0.003-2.17l1.492-0.866 L21.97,2.451l-1.534,0.868C19.903,2.83,19.5,2.452,18.5,2.229V0.5h-3v1.729c-1,0.223-1.403,0.601-1.936,1.09L12.03,2.451 l-1.515,2.598l1.492,0.866c-0.154,0.702-0.171,1.411-0.003,2.17l-1.503,0.866l1.499,2.598l1.562-0.868 c0.533,0.489,0.937,0.868,1.937,1.089V13.5H18.5z"
            />
            <path
              fill="none"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M8.5,23.5v-1.729 c1-0.222,1.403-0.601,1.937-1.089l1.562,0.868l1.499-2.598l-1.503-0.866c0.168-0.76,0.151-1.469-0.003-2.17l1.492-0.866 l-1.515-2.598l-1.534,0.868C9.903,12.83,9.5,12.452,8.5,12.229V10.5h-3v1.729c-1,0.223-1.403,0.601-1.936,1.09L2.03,12.451 l-1.515,2.598l1.492,0.866c-0.154,0.702-0.171,1.411-0.003,2.17l-1.503,0.866l1.499,2.598l1.562-0.868 C4.097,21.17,4.5,21.549,5.5,21.771V23.5H8.5z"
            />
            <circle
              fill="none"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              cx="17"
              cy="7"
              r="2"
            />
            <circle
              fill="none"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              cx="7"
              cy="17"
              r="2"
            />
          </g>
        );
      case 'cancel':
        return (
          <g>
            <path
              d="M17.5,11c-3.584,0-6.5,2.915-6.5,6.5c0,3.584,2.916,6.5,6.5,6.5s6.5-2.916,6.5-6.5C24,13.915,21.084,11,17.5,11z
                                                                                                                                                                                                                                                                                                          M18.207,17.5l2.347,2.346c0.195,0.196,0.195,0.513,0,0.707c-0.195,0.195-0.512,0.195-0.707,0L17.5,18.206l-2.347,2.347
                                                                                                                                                                                                                                                                                                         c-0.195,0.195-0.512,0.195-0.707,0c-0.195-0.194-0.195-0.511,0-0.707l2.347-2.346l-2.347-2.346c-0.195-0.195-0.195-0.512,0-0.707
                                                                                                                                                                                                                                                                                                         c0.195-0.195,0.512-0.195,0.707,0l2.347,2.347l2.347-2.347c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707
                                                                                                                                                                                                                                                                                                         L18.207,17.5z"
            />
            <path
              d="M10,17.5c0-3.967,3.098-7.215,7-7.474V5.5c0-0.133-0.053-0.26-0.146-0.353l-5-5C11.76,0.053,11.632,0,11.5,0h-11
                                                                                                                                                                                                                                                                                                         C0.224,0,0,0.224,0,0.5v21C0,21.776,0.224,22,0.5,22h11.015C10.568,20.745,10,19.19,10,17.5z M11,1l5,5h-5V1z M3.5,6H8
                                                                                                                                                                                                                                                                                                         c0.276,0,0.5,0.224,0.5,0.5S8.276,7,8,7H3.5C3.224,7,3,6.776,3,6.5S3.224,6,3.5,6z M3.5,9h8C11.776,9,12,9.224,12,9.5
                                                                                                                                                                                                                                                                                                         S11.776,10,11.5,10h-8C3.224,10,3,9.776,3,9.5S3.224,9,3.5,9z M8.5,16h-5C3.224,16,3,15.776,3,15.5S3.224,15,3.5,15h5
                                                                                                                                                                                                                                                                                                         C8.776,15,9,15.224,9,15.5S8.776,16,8.5,16z M9.5,13h-6C3.224,13,3,12.776,3,12.5S3.224,12,3.5,12h6c0.276,0,0.5,0.224,0.5,0.5
                                                                                                                                                                                                                                                                                                         S9.776,13,9.5,13z"
            />
          </g>
        );
      case 'file-save':
        return (
          <g>
            <path
              d="M10,17.5c0-3.967,3.098-7.215,7-7.474V5.5c0-0.133-0.053-0.26-0.146-0.353l-5-5C11.76,0.053,11.632,0,11.5,0h-11
                                                                                                                                                                                                                                                                                                         C0.224,0,0,0.224,0,0.5v21C0,21.776,0.224,22,0.5,22h11.015C10.568,20.745,10,19.19,10,17.5z M11,1l5,5h-5V1z M3.5,6H8
                                                                                                                                                                                                                                                                                                         c0.276,0,0.5,0.224,0.5,0.5S8.276,7,8,7H3.5C3.224,7,3,6.776,3,6.5S3.224,6,3.5,6z M3.5,9h8C11.776,9,12,9.224,12,9.5
                                                                                                                                                                                                                                                                                                         S11.776,10,11.5,10h-8C3.224,10,3,9.776,3,9.5S3.224,9,3.5,9z M8.5,16h-5C3.224,16,3,15.776,3,15.5S3.224,15,3.5,15h5
                                                                                                                                                                                                                                                                                                         C8.776,15,9,15.224,9,15.5S8.776,16,8.5,16z M9.5,13h-6C3.224,13,3,12.776,3,12.5S3.224,12,3.5,12h6c0.276,0,0.5,0.224,0.5,0.5
                                                                                                                                                                                                                                                                                                         S9.776,13,9.5,13z"
            />
            <path
              d="M17.5,11c-3.584,0-6.5,2.915-6.5,6.5c0,3.584,2.916,6.5,6.5,6.5s6.5-2.916,6.5-6.5C24,13.915,21.084,11,17.5,11z
                                                                                                                                                                                                                                                                                                          M20.227,18H18v2.228c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V18h-2.229c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H17
                                                                                                                                                                                                                                                                                                         v-2.227c0-0.275,0.224-0.5,0.5-0.5s0.5,0.225,0.5,0.5V17h2.227c0.276,0,0.5,0.224,0.5,0.5S20.503,18,20.227,18z"
            />
          </g>
        );

      case 'phone-call-2-filled':
        return (
          <g>
            <path
              d="M12,0.001c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5c6.065,0,11,4.935,11,11c0,0.276,0.224,0.5,0.5,0.5
                                                                                                                                                                                                                                                				s0.5-0.224,0.5-0.5C24,5.385,18.617,0.001,12,0.001z"
            />
            <path
              d="M12,5.001c3.86,0,7,3.141,7,7c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5c0-4.411-3.589-8-8-8
                                                                                                                                                                                                                                                				c-0.276,0-0.5,0.225-0.5,0.5C11.5,4.777,11.724,5.001,12,5.001z"
            />
            <path
              d="M12,9.001c1.654,0,3,1.346,3,3c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5c0-2.205-1.794-4-4-4
                                                                                                                                                                                                                                                				c-0.276,0-0.5,0.225-0.5,0.5C11.5,8.777,11.724,9.001,12,9.001z"
            />
            <path
              d="M0.581,10.285c3.423,5.184,7.955,9.719,13.146,13.145c1.353,0.898,3.201,0.76,4.404-0.445l1.163-1.164
                                                                                                                                                                                                                                                				c0.98-0.981,0.972-2.562-0.003-3.535l-2.121-2.121c-0.98-0.974-2.751-1.051-3.922,0.389c-2.019-1.688-3.999-3.647-5.789-5.789
                                                                                                                                                                                                                                                				c1.439-1.171,1.362-2.941,0.389-3.922L5.725,4.722C4.753,3.746,3.172,3.739,2.191,4.718L1.027,5.882
                                                                                                                                                                                                                                                				C-0.178,7.083-0.316,8.932,0.581,10.285z"
            />
          </g>
        );
      case 'phone-ringing-filled':
        return (
          <g>
            <g>
              <path d="M11.5,9C11.776,9,12,8.776,12,8.5v-6C12,2.224,11.776,2,11.5,2S11,2.224,11,2.5v6C11,8.776,11.224,9,11.5,9z" />
              <path
                d="M16.513,10.439l4.243-4.243c0.195-0.195,0.195-0.512,0-0.707c-0.195-0.195-0.512-0.195-0.707,0l-4.243,4.243
                                                                                                                                                                                                                                                    c-0.195,0.195-0.195,0.512,0,0.707C16.001,10.634,16.317,10.634,16.513,10.439z"
              />
              <path
                d="M6.487,10.439c0.195,0.195,0.512,0.195,0.707,0c0.195-0.195,0.195-0.512,0-0.707L2.951,5.488
                                                                                                                                                                                                                                                    c-0.195-0.195-0.512-0.195-0.707,0C2.049,5.684,2.049,6,2.244,6.195L6.487,10.439z"
              />
            </g>
            <g>
              <path
                d="M24,17v1.5c0,1.378-1.122,2.499-2.5,2.5h-3c-1.378,0-2.687-1.205-2.5-3.045c-2.632-0.233-5.366-0.233-8,0
                                                                                                                                                                                                                                                    C8.188,19.795,6.878,21,5.501,21H2.5C1.122,20.999,0,19.878,0,18.5V17c-0.001-1.625,1.273-3.157,2.9-3.49
                                                                                                                                                                                                                                                    c5.989-1.22,12.206-1.221,18.2,0C22.726,13.843,24,15.375,24,17z"
              />
            </g>
          </g>
        );
      case 'record-button':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" />
            <circle cx="12" cy="12" r="4.5" />
          </g>
        );
      case 'record-button-filled':
        return (
          <path
            d="M12,24c6.617,0,12-5.383,12-12c0-6.616-5.383-12-12-12S0,5.384,0,12C0,18.617,5.383,24,12,24z M12,7c2.757,0,5,2.243,5,5
                                                                                                                                                                                                                  		s-2.243,5-5,5s-5-2.243-5-5S9.243,7,12,7z"
          />
        );
      case 'person-add-2':
        return (
          <g>
            <g>
              <circle cx="18.5" cy="18.5" r="5" />
              <line x1="18.5" y1="16" x2="18.5" y2="21" />
              <line x1="21" y1="18.5" x2="16" y2="18.5" />
            </g>
            <path d="M13.966,13.282 c-0.777-0.257-1.615-0.533-2.466-0.854v-2.5c0,0,1.5-0.619,1.5-3c0.782,0,0.782-2.071,0-2.071c0-0.233,0.829-1.607,0.5-2.929 c-0.474-1.904-6.026-1.904-6.5,0c-2.369-0.477-1,2.681-1,3c-0.783,0-0.783,2,0,2c0,2.381,1.5,3,1.5,3v2.5 c-2.778,1.056-6.088,1.756-6.5,3C0.526,16.857,0.5,19.5,0.5,19.5H11" />
          </g>
        );
      case 'arrow-double-right-filled':
        return (
          <g>
            <path
              d="M23.863,11.623L17.73,5.156C17.636,5.057,17.504,5,17.367,5h-6.134c-0.2,0-0.38,0.119-0.46,0.303
                                                                                                                                                                                                          c-0.079,0.184-0.041,0.397,0.097,0.541l5.809,6.125l-5.811,6.189c-0.136,0.145-0.173,0.357-0.094,0.54
                                                                                                                                                                                                          C10.854,18.882,11.034,19,11.233,19h6.134c0.138,0,0.27-0.057,0.365-0.158l6.133-6.533C24.046,12.116,24.045,11.814,23.863,11.623
                                                                                                                                                                                                          z"
            />
            <path
              d="M13.129,11.623L6.996,5.156C6.901,5.057,6.77,5,6.633,5H0.5c-0.2,0-0.38,0.119-0.46,0.303
                                                                                                                                                                                                          c-0.079,0.184-0.041,0.397,0.097,0.541l5.808,6.125l-5.81,6.189c-0.136,0.145-0.173,0.357-0.094,0.54C0.12,18.882,0.301,19,0.5,19
                                                                                                                                                                                                          h6.133c0.138,0,0.27-0.057,0.365-0.158l6.134-6.533C13.312,12.116,13.311,11.814,13.129,11.623z"
            />
          </g>
        );
      case 'arrow-double-left-filled':
        return (
          <g>
            <path
              d="M7.321,11.969l5.809-6.125c0.138-0.144,0.175-0.357,0.097-0.541C13.148,5.119,12.967,5,12.767,5H6.633
                                                                                                                                                                                    			C6.496,5,6.365,5.057,6.271,5.156l-6.133,6.467c-0.182,0.191-0.183,0.493-0.002,0.686l6.133,6.533C6.363,18.943,6.495,19,6.633,19
                                                                                                                                                                                    			h6.134c0.006-0.001,0.013-0.001,0.02,0c0.276,0,0.5-0.224,0.5-0.5c0-0.176-0.091-0.331-0.228-0.42L7.321,11.969z"
            />
            <path
              d="M18.055,11.969l5.808-6.125c0.138-0.144,0.175-0.357,0.097-0.541C23.881,5.119,23.7,5,23.5,5h-6.133
                                                                                                                                                                                    			c-0.137,0-0.269,0.057-0.363,0.156l-6.134,6.467c-0.182,0.191-0.183,0.493-0.002,0.686l6.134,6.533
                                                                                                                                                                                    			C17.098,18.943,17.229,19,17.368,19H23.5c0.007-0.001,0.013-0.001,0.02,0c0.276,0,0.5-0.224,0.5-0.5
                                                                                                                                                                                    			c0-0.176-0.091-0.331-0.229-0.42L18.055,11.969z"
            />
          </g>
        );
      case 'group-information':
        return (
          <g>
            <g>
              <circle cx="18.5" cy="18.5" r="5" />
              <line x1="18.5" y1="17.5" x2="18.5" y2="21.5" />
              <g>
                <g>
                  <g>
                    <path d="M18.5,15c-0.276,0-0.5,0.225-0.5,0.5s0.224,0.5,0.5,0.5c0.275,0,0.5-0.225,0.5-0.5S18.775,15,18.5,15L18.5,15z" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <polyline points=" 12.998,13.441 10,12.367 10,10.322 				" />
              <path d="M6,10.322v2.045 l-3.941,1.412C1.115,14.117,0.5,15.012,0.5,16.012V17.5H11" />
              <ellipse cx="8" cy="6.236" rx="3.947" ry="4.74" />
              <path d="M11.916,5.811 c-0.132,0.018-0.241,0.006-0.374,0.033c-1.345,0.258-2.208-0.229-2.949-1.525C8.149,5.17,6.762,5.844,5.621,5.844 c-0.562,0-1.045-0.115-1.528-0.369" />
              <path d="M14.5,1.5 c2.18,0,3.947,2.121,3.947,4.736c0,2.617-1.768,4.74-3.947,4.74" />
              <path d="M18.416,5.811 c-0.132,0.018-0.241,0.006-0.374,0.033c-1.345,0.258-2.208-0.229-2.949-1.525" />
              <line x1="16.5" y1="12" x2="16.5" y2="10.322" />
            </g>
          </g>
        );
      case 'check-2-filled':
        return (
          <path
            d="M23.854,7.146l-3.5-3.5c-0.195-0.195-0.512-0.195-0.707,0L7.5,15.792l-3.147-3.146c-0.195-0.195-0.512-0.195-0.707,0
                                                                                                                                                                          			l-3.5,3.5c-0.195,0.195-0.195,0.512,0,0.707l7,7c0.195,0.196,0.512,0.196,0.707,0l16-16C24.049,7.658,24.049,7.341,23.854,7.146z"
          />
        );
      case 'floppy-disk':
        return (
          <g>
            <polygon
              points="23.5,0.5
                                                                                                                                                                				3.5,0.5 0.5,3.5 0.5,23.5 23.5,23.5 			"
            />
            <rect x="4.5" y="0.5" width="15" height="5" />
            <rect x="4.5" y="20.5" width="15" height="3" />
            <circle cx="12" cy="11.5" r="4" />
            <line x1="14.5" y1="18.5" x2="19.5" y2="18.5" />
            <line x1="10.5" y1="10.5" x2="10.5" y2="12.5" />
            <g>
              <g>
                <g>
                  <path d="M12,11c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5C12.5,11.225,12.276,11,12,11L12,11z" />
                </g>
              </g>
            </g>
          </g>
        );
      case 'microphone-3':
        return (
          <g>
            <path d="M18.5,12.5c0,3.314-2.688,6-6,6 c-3.314,0-6-2.686-6-6v-6c0-3.313,2.686-6,6-6c3.312,0,6,2.687,6,6V12.5z" />
            <path d="M4.5,10.5v2 c0,4.474,3.525,8,8,8c4.473,0,8-3.526,8-8v-2" />
            <line x1="12.5" y1="20.5" x2="12.5" y2="23.5" />
            <line x1="12.5" y1="0.5" x2="12.5" y2="3.5" />
            <line x1="14.501" y1="0.842" x2="14.5" y2="3.5" />
            <line x1="10.5" y1="0.842" x2="10.5" y2="3.5" />
            <line x1="4.5" y1="12.5" x2="20.5" y2="12.5" />
            <line x1="6.5" y1="6.5" x2="10.5" y2="6.5" />
            <line x1="6.5" y1="8.5" x2="10.5" y2="8.5" />
            <line x1="18.5" y1="6.5" x2="14.5" y2="6.5" />
            <line x1="18.5" y1="8.5" x2="14.5" y2="8.5" />
            <line x1="6.5" y1="10.5" x2="10.5" y2="10.5" />
            <line x1="18.5" y1="10.5" x2="14.5" y2="10.5" />
          </g>
        );
      case 'microphone-off':
        return (
          <g>
            <path d="M14.414,18.188 C13.812,18.391,13.169,18.5,12.5,18.5c-3.314,0-6-2.687-6-6V10" />
            <path d="M7.044,4 c0.948-2.065,3.034-3.5,5.456-3.5c3.312,0,6,2.687,6,6v6c0,0.893-0.195,1.739-0.545,2.5" />
            <path d="M19.46,16.492 c0.663-1.169,1.04-2.53,1.04-3.992v-2" />
            <path d="M4.5,10.5v2 c0,4.474,3.525,8,8,8c1.455,0,2.811-0.373,3.977-1.031" />
            <line x1="12.5" y1="20.5" x2="12.5" y2="24" />
            <line x1="1" y1="1" x2="23" y2="23" />
          </g>
        );
      case 'account-group':
        return (
          <g>
            <path d="M7.919,21.5H15.5c0,0,0-3,0-4.5 c0-0.5-3-2-5.5-3v-2c0,0,1-0.35,1-2.5c0.695,0,1-2,0.032-2c0-0.212,0.766-1.308,0.468-2.5C11,3,6,3,5.5,5 C3.395,4.569,5,7.212,5,7.5c-1,0-0.696,2,0,2C5,11.65,6,12,6,12v2c-2.5,1-5.5,2.5-5.5,3c0,1,0,4.5,0,4.5H7.919z" />
            <path d="M18,21.5h5.5 c0,0,0-3.301,0-4.5c0-0.5-2.5-1.2-4.5-2v-1.5c0,0,1-0.28,1-2c0.557,0,0.774-2,0-2c0-0.169,0.811-1.067,0.5-2C20,6,16,6,15.5,7.5 c-1.685-0.345-0.5,1.77-0.5,2c-0.8,0-0.557,2,0,2c0,1.72,1,2,1,2v1" />
          </g>
        );
      case 'person-download-2':
        return (
          <g>
            <g>
              <line x1="18.5" y1="15.5" x2="18.5" y2="21.5" />
              <polyline points=" 16.5,19.5 18.5,21.5 20.5,19.5 				" />
              <circle cx="18.5" cy="18.5" r="5" />
            </g>
            <path d="M13.966,13.355 c-0.777-0.258-1.615-0.534-2.466-0.855V10c0,0,1.5-0.619,1.5-3c0.782,0,0.782-2.07,0-2.07c0-0.235,0.828-1.608,0.5-2.93 C13.026,0.096,7.474,0.096,7,2C4.631,1.525,6,4.681,6,5C5.217,5,5.217,7,6,7c0,2.381,1.5,3,1.5,3v2.5 c-2.778,1.057-6.088,1.756-6.5,3c-0.474,1.43-0.5,4-0.5,4H11" />
          </g>
        );
      case 'arrow-double-right-1-filled':
        return (
          <g>
            <polygon
              fill="none"
              stroke={color}
              points="0.5,18.5 6.633,18.5 12.767,11.967 6.633,5.5 0.5,5.5 6.633,11.967 		"
            />
            <path
              fill={color}
              stroke="none"
              d="M23.863,11.623L17.73,5.156C17.636,5.057,17.504,5,17.367,5h-6.134c-0.2,0-0.38,0.119-0.46,0.303
                                                                                                    			c-0.079,0.184-0.041,0.397,0.097,0.541l5.809,6.125l-5.811,6.189c-0.136,0.145-0.173,0.357-0.094,0.54
                                                                                                    			C10.854,18.882,11.034,19,11.233,19h6.134c0.138,0,0.27-0.057,0.365-0.158l6.133-6.533C24.046,12.116,24.045,11.814,23.863,11.623
                                                                                                    			z"
            />
          </g>
        );
      case 'arrow-double-right-2-filled':
        return (
          <g>
            <path
              fill={color}
              stroke="none"
              d="M13.129,11.623L6.996,5.156C6.901,5.057,6.77,5,6.633,5H0.5c-0.2,0-0.38,0.119-0.46,0.303
                                                                      			c-0.079,0.184-0.041,0.397,0.097,0.541l5.808,6.125l-5.81,6.189c-0.136,0.145-0.173,0.357-0.094,0.54C0.12,18.882,0.301,19,0.5,19
                                                                      			h6.133c0.138,0,0.27-0.057,0.365-0.158l6.134-6.533C13.312,12.116,13.311,11.814,13.129,11.623z"
            />
            <polygon
              fill="none"
              stroke={color}
              points="
                                                                      			11.233,18.5 17.367,18.5 23.5,11.967 17.367,5.5 11.233,5.5 17.367,11.967 		"
            />
          </g>
        );
      case 'check-circle-1-filled':
        return (
          <g>
            <path
              d="M23.623,0.217c-0.433-0.344-1.061-0.271-1.405,0.161L9.415,16.5l-4.207-4.207c-0.391-0.391-1.024-0.391-1.414,0
                                                                        c-0.391,0.392-0.391,1.025,0,1.413l5,5c0.202,0.202,0.477,0.308,0.762,0.292c0.284-0.016,0.552-0.155,0.728-0.377l13.5-17
                                                                        C24.127,1.19,24.056,0.561,23.623,0.217z"
            />
            <path
              d="M18.551,11.019c-0.538,0.123-0.875,0.659-0.752,1.197c0.134,0.585,0.202,1.186,0.202,1.783c0,4.411-3.589,8-8,8
                                                                        c-4.411,0-8-3.589-8-8s3.589-8,8-8c1.247,0,2.444,0.281,3.555,0.832c0.494,0.247,1.095,0.044,1.341-0.451
                                                                        c0.246-0.494,0.044-1.095-0.451-1.34C13.056,4.35,11.561,4,10.002,4c-5.514,0-10,4.486-10,10c0,5.514,4.486,10,10,10
                                                                        c5.514,0,10-4.486,10-10c0-0.748-0.085-1.498-0.252-2.229C19.626,11.233,19.091,10.894,18.551,11.019z"
            />
          </g>
        );
      case 'navigation-next-4':
        return (
          <g>
            <g>
              <path d="M23.113,9.5H12.5 c-2.209,0-4,1.792-4,4v2" />
              <polyline points=" 19.113,13.5 23.113,9.5 19.113,5.5 				" />
            </g>
            <polyline points="14.5,13 14.5,19.5 0.5,19.5 0.5,5.5 14.5,5.5 14.5,7 			" />
          </g>
        );
      case 'alarm-check':
        return (
          <g>
            <polyline points=" 21.232,6.057 17.137,10.415 14.212,7.575 			" />
            <circle cx="17.5" cy="8" r="6" />
            <path d="M9,5 C7.617,5,6.5,6.119,6.5,7.5c-2.351,0.979-4,3.297-4,6V18c0,1.104-0.896,2-2,2h17c-1.105,0-2-0.896-2-2v-2" />
            <path d="M11,20 c0,1.104-0.896,2-2,2c-1.105,0-2-0.896-2-2" />
          </g>
        );
      case 'mailbox-3':
        return (
          <g>
            <path d="M6.5,17.5h-6v-9c0-1.657,1.344-3,3-3s3,1.343,3,3V17.5z" />
            <path d="M3.5,5.5h17c1.656,0,3,1.343,3,3v9h-17" />
            <line x1="13.5" y1="17.5" x2="13.5" y2="23.5" />
            <line x1="2.5" y1="15.5" x2="4.5" y2="15.5" />
            <line x1="4.5" y1="5.5" x2="4.5" y2="2.5" />
            <rect x="4.5" y="0.5" width="3" height="2" />
          </g>
        );
      case 'mailbox-3-filled':
        return (
          <g>
            <path d="M4.5,15h-2C2.224,15,2,15.224,2,15.5C2,15.776,2.224,16,2.5,16h2C4.776,16,5,15.776,5,15.5C5,15.224,4.776,15,4.5,15z" />
            <path
              d="M20.5,5H5V3h2.5C7.776,3,8,2.776,8,2.5v-2C8,0.224,7.776,0,7.5,0h-3C4.224,0,4,0.224,4,0.5V5H3.5C1.57,5,0,6.57,0,8.5v9
				C0,17.776,0.224,18,0.5,18H13v5.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V18h9.5c0.276,0,0.5-0.224,0.5-0.5v-9
				C24,6.57,22.43,5,20.5,5z M6,17H1V8.5C1,7.122,2.122,6,3.5,6S6,7.122,6,8.5V17z"
            />
          </g>
        );
      case 'mobile-phone-2':
        return (
          <g>
            <path
              d="M17.5,16.5
                                    c0,0.55-0.45,1-1,1h-9c-0.55,0-1-0.45-1-1v-11c0-0.55,0.45-1,1-1h9c0.55,0,1,0.45,1,1V16.5z"
            />
            <path
              d="M19.5,20.5
                                    c0,1.65-1.35,3-3,3h-9c-1.65,0-3-1.35-3-3v-17c0-1.649,1.35-3,3-3h9c1.65,0,3,1.351,3,3V20.5z"
            />
            <line x1="9.5" y1="2.5" x2="14.5" y2="2.5" />
            <g>
              <g>
                <g>
                  <polygon points="9,20 8,20 8,21 9,21 9,20 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="16,20 15,20 15,21 16,21 16,20 						" />
                </g>
              </g>
            </g>
            <rect x="10.5" y="19.5" />
          </g>
        );
      case 'globe-search':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="4" />
              <line x1="20.328" y1="20.328" x2="23.5" y2="23.5" />
            </g>
            <g>
              <path d="M12,23.5 C5.648,23.5,0.5,18.352,0.5,12C0.5,5.65,5.648,0.5,12,0.5c6.351,0,11.5,5.15,11.5,11.5" />
              <polyline points=" 9.5,17.5 9.5,15 4.5,11 6.752,7.5 12.5,7.5 12.5,4.5 11,3.5 15.363,1 			" />
              <polyline points=" 20.5,4.255 18.752,7.5 17.002,7.5 15.252,9.25 			" />
            </g>
          </g>
        );

      case 'badge-exclamation':
        return (
          <g>
            <path
              d="M23.5,12c0-0.979-0.704-1.79-1.633-1.962
                        c0.791-0.516,1.131-1.534,0.759-2.438c-0.376-0.905-1.337-1.385-2.262-1.188c0.535-0.778,0.459-1.851-0.233-2.542
                        c-0.691-0.692-1.764-0.768-2.542-0.234c0.196-0.924-0.284-1.884-1.189-2.259c-0.904-0.375-1.923-0.035-2.438,0.757
                        C13.789,1.204,12.98,0.5,12,0.5s-1.79,0.704-1.964,1.633C9.522,1.341,8.503,1.001,7.598,1.376
                        C6.693,1.75,6.214,2.711,6.409,3.635C5.631,3.102,4.56,3.177,3.867,3.869C3.175,4.561,3.1,5.633,3.633,6.411
                        C2.71,6.215,1.752,6.695,1.377,7.6S1.34,9.522,2.131,10.037C1.202,10.21,0.5,11.021,0.5,12s0.703,1.79,1.631,1.963
                        c-0.791,0.516-1.129,1.534-0.755,2.439c0.374,0.903,1.334,1.383,2.257,1.188c-0.533,0.778-0.457,1.85,0.234,2.542
                        C4.56,20.825,5.63,20.9,6.409,20.367c-0.195,0.923,0.285,1.883,1.189,2.258s1.924,0.036,2.438-0.756
                        C10.21,22.797,11.022,23.5,12,23.5c0.98,0,1.789-0.703,1.962-1.631c0.515,0.792,1.533,1.13,2.438,0.756
                        c0.904-0.375,1.384-1.335,1.188-2.26c0.778,0.535,1.851,0.46,2.542-0.232c0.692-0.692,0.769-1.764,0.233-2.542
                        c0.924,0.195,1.886-0.285,2.262-1.188c0.374-0.905,0.033-1.924-0.759-2.439C22.795,13.791,23.5,12.979,23.5,12z"
            />
            <line x1="12.5" y1="13.748" x2="12.5" y2="6.748" />
            <path
              d="M13,17.249c0,0.277-0.224,0.5-0.5,0.5l0,0
                        c-0.276,0-0.5-0.223-0.5-0.5l0,0c0-0.275,0.224-0.5,0.5-0.5l0,0C12.776,16.749,13,16.974,13,17.249L13,17.249z"
            />
          </g>
        );

      case 'print-text':
        return (
          <g>
            <path
              d="M5.5,17.5h-3
                        c-1.1,0-2-0.9-2-2v-6c0-1.1,0.9-2,2-2h19c1.101,0,2,0.9,2,2v6c0,1.1-0.899,2-2,2h-3"
            />
            <polyline
              points="5.5,5.5
                        5.5,1.5 15.5,1.5 18.5,4.5 18.5,5.5 			"
            />
            <rect x="5.5" y="14.5" />
            <circle cx="3.5" cy="10.5" r="1" />
            <line y1="16.5" x2="16.5" y2="16.5" />
            <line x1="7.5" y1="18.5" x2="16.5" y2="18.5" />
            <line x1="7.5" y1="20.5" x2="16.5" y2="20.5" />
            <polyline
              points="
                        15.5,1.5 15.5,4.5 18.5,4.5 			"
            />
          </g>
        );

      case 'file-refresh-2':
        return (
          <g>
            <g>
              <g>
                <polyline
                  points="
                  23.5,15 23.5,17.5 21,17.5 				"
                />
                <path
                  d="M14.5,17.5
                  c0.617-1.747,2.541-3,4.5-3s3.883,1.253,4.5,3"
                />
              </g>
              <g>
                <polyline
                  points="
                  14.5,23 14.5,20.5 17,20.5 				"
                />
                <path
                  d="M23.5,20.5
                  c-0.617,1.748-2.541,3-4.5,3s-3.883-1.252-4.5-3"
                />
              </g>
            </g>
            <line x1="3.496" y1="6.5" x2="7.996" y2="6.5" />
            <line x1="3.496" y1="9.5" x2="11.496" y2="9.5" />
            <line x1="3.496" y1="12.5" x2="9.496" y2="12.5" />
            <line x1="3.496" y1="15.5" x2="8.496" y2="15.5" />
            <g>
              <polyline
                points="11,21.5 
                0.5,21.5 0.5,0.5 11.5,0.5 16.5,5.5 16.5,11 			"
              />
              <polyline
                points="
                11.5,0.5 11.5,5.5 16.5,5.5 			"
              />
            </g>
          </g>
        );
      case 'add-circle-1-filled':
        return (
          <g id="Filled_Icons">
            <path
              d="M11.5,0C5.148,0,0,5.148,0,11.5S5.148,23,11.5,23S23,17.852,23,11.5S17.852,0,11.5,0z M17.5,12H12v5.5
            c0,0.276-0.224,0.5-0.5,0.5S11,17.776,11,17.5V12H5.5C5.224,12,5,11.776,5,11.5S5.224,11,5.5,11H11V5.5C11,5.224,11.224,5,11.5,5
            S12,5.224,12,5.5V11h5.5c0.276,0,0.5,0.224,0.5,0.5S17.776,12,17.5,12z"
            />
          </g>
        );

      case 'window-minimize':
        return (
          <g>
            <line x1="0.5" y1="4.5" x2="20.5" y2="4.5" />
            <g>
              <g>
                <path d="M3.5,2C3.225,2,3,2.224,3,2.5S3.225,3,3.5,3C3.776,3,4,2.776,4,2.5S3.776,2,3.5,2L3.5,2z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M5.5,2C5.225,2,5,2.224,5,2.5S5.225,3,5.5,3C5.776,3,6,2.776,6,2.5S5.776,2,5.5,2L5.5,2z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M7.5,2C7.224,2,7,2.224,7,2.5S7.224,3,7.5,3S8,2.776,8,2.5S7.776,2,7.5,2L7.5,2z" />
              </g>
            </g>
            <path
              d="M8.979,15.5H2.5
                c-1.104,0-2-0.896-2-2v-11c0-1.105,0.896-2,2-2h16c1.104,0,2,0.895,2,2v11c0,1.104-0.896,2-2,2"
            />
            <g>
              <line x1="15.5" y1="18.5" x2="6" y2="9" />
              <polyline
                points="15.5,14 
                  15.5,18.5 11,18.5"
              />
            </g>
            <rect x="14.5" y="21.5" />
          </g>
        );

      case 'window-maximize':
        return (
          <g>
            <g>
              <line x1="16.5" y1="9.5" x2="7.086" y2="18.898" />
              <polyline
                points="
                    16.5,13.458 16.5,9.5 12,9.5"
              />
            </g>
            <rect x="1.5" y="21.5" />
            <line x1="3.5" y1="4.5" x2="23.5" y2="4.5" />
            <g>
              <g>
                <path d="M6.5,2C6.225,2,6,2.224,6,2.5S6.225,3,6.5,3C6.777,3,7,2.776,7,2.5S6.777,2,6.5,2L6.5,2z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M8.5,2C8.225,2,8,2.224,8,2.5S8.225,3,8.5,3C8.776,3,9,2.776,9,2.5S8.776,2,8.5,2L8.5,2z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M10.5,2C10.224,2,10,2.224,10,2.5S10.224,3,10.5,3S11,2.776,11,2.5S10.776,2,10.5,2L10.5,2z" />
              </g>
            </g>
            <path
              d="M6.97,15.5H5.5
                  c-1.104,0-2-0.896-2-2v-11c0-1.105,0.896-2,2-2h16c1.104,0,2,0.895,2,2v11c0,1.104-0.896,2-2,2h-7.531"
            />
          </g>
        );

      case 'check-1':
        return (
          <g>
            <polyline points="23.5,0.5 7,23.5 0.5,17" />
          </g>
        );

      case 'check-double-2':
        return (
          <g>
            <polyline points="6.993,18.615 5.547,20.631 0.554,15.636" />
            <line x1="17.931" y1="3.369" x2="9.062" y2="15.73" />
            <polyline points="23.446,3.369 11.062,20.631 6.069,15.636" />
          </g>
        );

      case 'clock-2':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" />
            <polyline points="11.5,6.5 11.5,12 17.5,17.5" />
          </g>
        );
      case 'expand-1-filled':
        return (
          <g>
            <path
              d="M9.707,8.293L3.414,2H6c0.553,0,1-0.447,1-1S6.553,0,6,0H1C0.447,0,0,0.447,0,1v5c0,0.553,0.447,1,1,1s1-0.447,1-1V3.414
				l6.293,6.293c0.391,0.391,1.023,0.391,1.414,0C10.098,9.316,10.098,8.684,9.707,8.293z"
            />
            <path
              d="M14.293,8.293L20.586,2H18c-0.553,0-1-0.447-1-1s0.447-1,1-1h5c0.553,0,1,0.447,1,1v5c0,0.553-0.447,1-1,1s-1-0.447-1-1
				V3.414l-6.293,6.293c-0.391,0.391-1.023,0.391-1.414,0C13.902,9.316,13.902,8.684,14.293,8.293z"
            />
            <path
              d="M8.293,14.293L2,20.586V18c0-0.553-0.447-1-1-1s-1,0.447-1,1v5c0,0.553,0.447,1,1,1h5c0.553,0,1-0.447,1-1s-0.447-1-1-1
				H3.414l6.293-6.293c0.391-0.391,0.391-1.023,0-1.414C9.316,13.902,8.684,13.902,8.293,14.293z"
            />
            <path
              d="M15.707,14.293L22,20.586V18c0-0.553,0.447-1,1-1s1,0.447,1,1v5c0,0.553-0.447,1-1,1h-5c-0.553,0-1-0.447-1-1
				s0.447-1,1-1h2.586l-6.293-6.293c-0.391-0.391-0.391-1.023,0-1.414C14.684,13.902,15.316,13.902,15.707,14.293z"
            />
          </g>
        );
      case 'subtract-1-filled':
        return (
          <path
            d="M23.002,11.021L1,11l0,0c-0.553,0-1,0.447-1,0.999c-0.001,0.552,0.446,1,0.998,1.001H23c0.553,0,1-0.426,1-0.977
			C24.001,11.471,23.554,11.023,23.002,11.021z"
          />
        );

      case 'frame-1-filled':
        return (
          <g>
            <path d="M8,3H3.02c-0.553,0-1,0.448-1,1v5c0,0.552,0.447,1,1,1s1-0.448,1-1V5H8c0.553,0,1-0.448,1-1C9,3.448,8.553,3,8,3z" />
            <path
              d="M20.021,3h-4.979c-0.553,0-1,0.448-1,1c0,0.552,0.447,1,1,1h3.979v4c0,0.552,0.447,1,1,1s1-0.448,1-1V4
				C21.021,3.448,20.573,3,20.021,3z"
            />
            <path d="M8,20H4.021v-4c0-0.552-0.447-1-1-1s-1,0.448-1,1v5c0,0.552,0.447,1,1,1H8c0.553,0,1-0.448,1-1C9,20.448,8.553,20,8,20z" />
            <path
              d="M20.021,15c-0.553,0-1,0.448-1,1v4h-3.979c-0.553,0-1,0.448-1,1c0,0.552,0.447,1,1,1h4.979c0.553,0,1-0.448,1-1v-5
				C21.021,15.448,20.573,15,20.021,15z"
            />
          </g>
        );

      case 'cog-filled':
        return (
          <path
            d="M23.5,10h-2.855c-0.2-0.79-0.454-1.667-0.778-2.332l2.032-2.032c0.195-0.195,0.195-0.512,0-0.707L19.07,2.102
			c-0.195-0.196-0.512-0.196-0.707,0l-2.033,2.031C15.665,3.809,14.789,3.555,14,3.356V0.5C14,0.224,13.776,0,13.5,0h-3
			C10.224,0,10,0.224,10,0.5v2.856C9.21,3.555,8.333,3.809,7.669,4.133L5.636,2.102c-0.195-0.196-0.512-0.196-0.707,0L2.1,4.929
			c-0.195,0.195-0.195,0.512,0,0.707l2.033,2.033C3.81,8.332,3.555,9.209,3.355,10H0.5C0.224,10,0,10.224,0,10.5v3
			C0,13.776,0.224,14,0.5,14h2.855c0.201,0.791,0.456,1.668,0.779,2.331L2.1,18.365c-0.195,0.195-0.195,0.511,0,0.707l2.829,2.828
			c0.188,0.188,0.52,0.188,0.707,0l2.033-2.033c0.663,0.323,1.54,0.578,2.332,0.779V23.5c0,0.276,0.224,0.5,0.5,0.5h3
			c0.276,0,0.5-0.224,0.5-0.5v-2.854c0.79-0.201,1.668-0.456,2.331-0.778l2.034,2.032c0.195,0.195,0.512,0.195,0.707,0l2.828-2.828
			c0.195-0.195,0.195-0.512,0-0.707l-2.032-2.033c0.323-0.663,0.578-1.54,0.778-2.331H23.5c0.276,0,0.5-0.224,0.5-0.5v-3
			C24,10.224,23.776,10,23.5,10z M12,16c-2.206,0-4-1.795-4-4s1.794-4,4-4s4,1.795,4,4S14.206,16,12,16z"
          />
        );

      case 'bubble-chat-remove-2':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="6" />

              <line x1="15" y1="20" x2="20" y2="15" />

              <line x1="20" y1="20" x2="15" y2="15" />
            </g>
            <g>
              <path
                d="M9.5,17.436
					C8.613,17.361,7.719,17.219,7,17l-5.5,2.5l2-4.5c-1.93-1.543-3-3.623-3-6c0-4.695,4.664-8.5,10.418-8.5S21.5,4.306,21.5,9
					c0,0.339-0.024,0.674-0.072,1.002"
              />
            </g>
          </g>
        );
      case 'paperclip-1':
        return (
          <path
            d="M7.701,15.202
          l8.501-8.5c0.78-0.781,2.047-0.781,2.828,0c0.781,0.781,0.78,2.046,0,2.828L7.548,21.048c-1.562,1.562-4.095,1.562-5.657,0
          c-1.562-1.563-1.562-4.095,0-5.658l12-11.999c2.148-2.149,5.631-2.149,7.778,0c2.148,2.147,2.148,5.63,0,7.776l-8.5,8.5"
          />
        );

      case 'stopwatch':
        return (
          <g>
            <circle cx="12" cy="14" r="9.5" />

            <line x1="9" y1="0.5" x2="15" y2="0.5" />

            <line x1="18.717" y1="7.283" x2="20.5" y2="5.5" />
            <polyline
              points="
          10.5,4.547 10.5,0.5 13.5,0.5 13.5,4.547 			"
            />

            <line x1="20" y1="5" x2="21" y2="6" />

            <line x1="12" y1="14" x2="7.5" y2="9.5" />
          </g>
        );

      case 'smiley-smile-1':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" />
            <path
              d="M19.5,13.5
			c0,3.643-3.358,7-7.5,7s-7.5-3.357-7.5-7H19.5z"
            />
            <path
              d="M5.5,10
			c0-2.643,4-2.646,4,0"
            />
            <path
              d="M18.5,10
			c0-2.643-4-2.646-4,0"
            />
          </g>
        );

      case 'folder-upload':
        return (
          <g>
            <g>
              <polyline
                points="20,17 
                    17.5,14.5 15,17"
              />
              <circle cx="17.5" cy="17.5" r="6" />
              <line x1="17.5" y1="20.5" x2="17.5" y2="14.5" />
            </g>
            <g>
              <path
                d="M18.5,4.5v-2h-13
                    v-2h-5V16c0,0.829,0.67,1.5,1.5,1.5c0.828,0,1.5-0.671,1.5-1.5V4.5h17V9"
              />
              <line x1="2" y1="17.5" x2="9" y2="17.5" />
            </g>
          </g>
        );

      case 'folder-download':
        return (
          <g>
            <g>
              <polyline
                points="15,18 
                    17.5,20.5 20,18"
              />
              <circle cx="17.5" cy="17.5" r="6" />
              <line x1="17.5" y1="14.5" x2="17.5" y2="20.5" />
            </g>
            <g>
              <path
                d="M18.5,4.5v-2h-13
                    v-2h-5V16c0,0.829,0.67,1.5,1.5,1.5c0.828,0,1.5-0.671,1.5-1.5V4.5h17V9"
              />
              <line x1="2" y1="17.5" x2="9" y2="17.5" />
            </g>
          </g>
        );

      case 'files-1':
        return (
          <g>
            <polygon
              points="20.5,18.5 
                    7.5,18.5 7.5,1.5 15.5,1.5 20.5,6.5"
            />
            <polyline
              points="
                    18.5,18.5 18.5,20.5 5.5,20.5 5.5,3.5 7.5,3.5"
            />
            <polyline
              points="
                    16.5,20.5 16.5,22.5 3.5,22.5 3.5,5.5 5.5,5.5"
            />
            <polyline
              points="15.5,1.5 
                    15.5,6.5 20.5,6.5"
            />
          </g>
        );
      case 'bubble-chat-text-2':
        return (
          <g>
            <path
              d="M23.5,10.759
				c0,5.181-5.148,9.383-11.5,9.383c-1.418,0-2.775-0.21-4.029-0.593l-6.608,3.076l2.588-5.166c-2.129-1.703-3.45-4.076-3.45-6.7
				c0-5.182,5.148-9.383,11.5-9.383S23.5,5.577,23.5,10.759z"
            />

            <line x1="7.5" y1="8.5" x2="14" y2="8.5" />

            <line x1="7.5" y1="11.5" x2="17" y2="11.5" />

            <line x1="7.5" y1="14.5" x2="17" y2="14.5" />
          </g>
        );

      case 'control-play-filled':
        return (
          <g>
            <polygon
              points="0.5,0.5 
			23.5,12 0.5,23.5 		"
            />
          </g>
        );
      case 'control-stop-filled':
        return (
          <g>
            <rect x="2.5" y="2.5" width="19" height="19" />
          </g>
        );
      case 'person-add-2-filled':
        return (
          <g>
            <path
              d="M12,18.5c0-2.275,1.177-4.277,2.952-5.439C14.158,12.774,13.189,12.434,12,12v-1.782
				c0.486-0.325,1.381-1.158,1.489-2.928c0.367-0.23,0.597-0.727,0.597-1.361c0-0.558-0.178-1.009-0.471-1.266
				c0.243-0.636,0.645-1.749,0.369-2.854C13.653,0.474,11.766,0,10.25,0C8.908,0,7.273,0.372,6.687,1.384
				c-0.7-0.04-1.088,0.26-1.277,0.511C4.891,2.585,5.132,3.668,5.412,4.64C5.083,4.889,4.88,5.361,4.88,5.946
				c0,0.643,0.244,1.117,0.63,1.344C5.62,9.06,6.514,9.893,7,10.218V12c-4.748,1.732-6,1.979-6.475,3.234
				C0.032,16.722,0,19.361,0,19.5C0,19.776,0.224,20,0.5,20h11.682C12.067,19.518,12,19.018,12,18.5z"
            />
            <path
              d="M18.5,13c-3.033,0-5.5,2.467-5.5,5.5c0,3.032,2.467,5.493,5.5,5.493c3.032,0,5.5-2.461,5.5-5.493
				C24,15.467,21.532,13,18.5,13z M21,19h-2v2c0,0.275-0.225,0.5-0.5,0.5c-0.276,0-0.5-0.225-0.5-0.5v-2h-2
				c-0.276,0-0.5-0.225-0.5-0.5c0-0.276,0.224-0.5,0.5-0.507h2V16c0-0.276,0.224-0.5,0.5-0.5c0.275,0,0.5,0.224,0.5,0.5v1.993h2
				c0.275,0.007,0.5,0.23,0.5,0.507C21.5,18.775,21.275,19,21,19z"
            />
          </g>
        );
      case 'check-1-filled':
        return (
          <path
            d="M23.576,0.183c-0.452-0.318-1.075-0.21-1.393,0.242L7.367,21.452l-5.66-5.659c-0.39-0.391-1.024-0.391-1.414,0
			c-0.391,0.392-0.391,1.025,0,1.413l6.5,6.5C6.981,23.896,7.236,24,7.5,24c0.318,0,0.627-0.153,0.817-0.424l15.5-22
			C24.135,1.123,24.027,0.5,23.576,0.183z"
          />
        );

      case 'check-double-2-filled':
        return (
          <g>
            <g>
              <path
                d="M5.101,21.999c-0.264,0-0.519-0.104-0.707-0.293l-4.05-4.05c-0.391-0.39-0.391-1.024,0-1.412
					c0.391-0.393,1.023-0.393,1.414,0l3.217,3.216c0.322-0.444,0.945-0.544,1.392-0.224c0.449,0.322,0.551,0.946,0.229,1.395
					l-0.683,0.951C5.729,21.838,5.433,21.999,5.101,21.999z"
              />
            </g>
            <path
              d="M18.067,2.925c-0.45-0.322-1.074-0.219-1.396,0.23L8.333,14.777c-0.322,0.449-0.219,1.074,0.229,1.396
				c0.45,0.322,1.074,0.219,1.396-0.229L18.297,4.32C18.619,3.872,18.516,3.247,18.067,2.925z"
            />
            <g>
              <path
                d="M10.616,21.999c-0.264,0-0.519-0.104-0.707-0.293l-4.993-4.994c-0.391-0.391-0.391-1.024,0-1.413
					c0.391-0.392,1.023-0.392,1.414,0l4.16,4.161L22.188,3.154c0.321-0.449,0.946-0.552,1.396-0.23
					c0.449,0.322,0.551,0.947,0.229,1.396L11.429,21.581C11.246,21.837,10.949,21.999,10.616,21.999z"
              />
            </g>
          </g>
        );
      case 'check-double-3-filled':
        return (
          <path d="M 17.320312 2.75 C 17.067813 2.791625 16.832875 2.9297969 16.671875 3.1542969 L 10.345703 12.1875 C 10.023704 12.6365 10.018797 13.226828 10.466797 13.548828 C 10.916797 13.870828 11.578391 13.803469 11.900391 13.355469 L 18.296875 4.3203125 C 18.618875 3.8723125 18.515406 3.2477813 18.066406 2.9257812 C 17.841406 2.7647812 17.572812 2.708375 17.320312 2.75 z M 26.701172 2.75 C 26.448547 2.791625 26.213234 2.9297969 26.052734 3.1542969 L 14.353516 19.460938 L 10.195312 15.298828 C 9.8043129 14.906828 9.17225 14.906828 8.78125 15.298828 C 8.39025 15.687828 8.39025 16.321891 8.78125 16.712891 L 13.773438 21.705078 C 13.961438 21.894078 14.216469 21.998047 14.480469 21.998047 C 14.813469 21.998047 15.109969 21.836078 15.292969 21.580078 L 27.677734 4.3203125 C 27.999734 3.8713125 27.898219 3.2458281 27.449219 2.9238281 C 27.224219 2.7628281 26.953797 2.708375 26.701172 2.75 z M 1.0507812 15.949219 C 0.7950313 15.949219 0.53925 16.047639 0.34375 16.244141 C -0.04725 16.632141 -0.04725 17.26625 0.34375 17.65625 L 4.3945312 21.705078 C 4.5825314 21.894078 4.8375625 21.998047 5.1015625 21.998047 C 5.5993865 21.905137 5.9017696 21.533387 6.1445312 21.318359 L 7.7011719 19.09375 C 8.0216563 18.644006 7.9150744 18.018532 7.4648438 17.701172 C 7.0137792 17.378882 6.3922501 17.484797 6.0722656 17.9375 L 5.0351562 19.40625 C 5.0170062 19.42732 4.9912224 19.438027 4.9746094 19.460938 L 1.7578125 16.244141 C 1.5623125 16.047641 1.3065313 15.949219 1.0507812 15.949219 z " />
        );
      case 'synchronize-warning-2-filled':
        return (
          <g>
            <path d="M13,13.481V6.5c0-0.553-0.447-1-1-1s-1,0.447-1,1v6.981c0,0.553,0.447,1,1,1S13,14.033,13,13.481z" />
            <circle cx="12" cy="17" r="1.5" />
            <path
              d="M23.832,11.445c-0.306-0.46-0.927-0.583-1.387-0.277L21,12.131V6c0-2.206-1.794-4-4-4H7C4.794,2,3,3.794,3,6
				c0,0.553,0.447,1,1,1s1-0.447,1-1c0-1.102,0.898-2,2-2h10c1.103,0,2,0.898,2,2v6.131l-1.445-0.963
				c-0.462-0.306-1.082-0.183-1.387,0.277c-0.307,0.46-0.183,1.08,0.277,1.387l3,2c0.336,0.225,0.773,0.225,1.109,0l3-2
				C24.015,12.525,24.139,11.905,23.832,11.445z"
            />
            <path
              d="M20,17c-0.553,0-1,0.447-1,1c0,1.103-0.897,2-2,2H7c-1.102,0-2-0.897-2-2v-6.131l1.445,0.963
				c0.462,0.306,1.082,0.183,1.387-0.277c0.307-0.46,0.183-1.08-0.277-1.387l-3-2c-0.336-0.225-0.773-0.225-1.109,0l-3,2
				c-0.46,0.307-0.584,0.927-0.277,1.387c0.306,0.46,0.927,0.583,1.387,0.277L3,11.869V18c0,2.206,1.794,4,4,4h10
				c2.206,0,4-1.794,4-4C21,17.447,20.553,17,20,17z"
            />
          </g>
        );

      case 'synchronize-warning-1':
        return (
          <g>
            <g>
              <polyline
                points="
					0.714,10.137 3.501,14.289 6.706,10.451 				"
              />
              <polyline
                points="
					23.287,14.439 20.501,10.287 17.295,14.126 				"
              />
              <path
                d="M20.464,10.326
					c0.835,4.408-2.273,8.727-6.509,9.727c-2.954,0.699-5.916-0.238-7.93-2.223"
              />
              <path
                d="M3.506,14.264
					c-1.313-4.883,1.97-9.673,6.538-10.753c3.155-0.747,6.316,0.372,8.325,2.64"
              />
            </g>

            <line x1="11.5" y1="6.02" x2="11.5" y2="13" />

            <circle cx="11.5" cy="15.5" r="0.5" />
          </g>
        );
      case 'synchronize-warning-1-filled':
        return (
          <g>
            <path d="M13,7.019c0-0.553-0.447-1-1-1s-1,0.447-1,1V14c0,0.553,0.447,1,1,1s1-0.447,1-1V7.019z" />
            <circle cx="12" cy="16.5" r="1" />
            <g>
              <path
                d="M19.395,5.195c-1.803-2.032-4.394-3.197-7.108-3.197c-4.992,0-9.088,3.873-9.463,8.771L1.83,9.289
					C1.523,8.831,0.9,8.706,0.442,9.017c-0.458,0.308-0.58,0.929-0.273,1.388l2.909,4.301c0.391,0.391,1.023,0.391,1.414,0
					l3.267-3.904c0.354-0.424,0.297-1.054-0.127-1.408C7.209,9.038,6.579,9.095,6.225,9.519L4.8,11.226
					c0.146-4.009,3.442-7.228,7.486-7.228c2.144,0,4.188,0.92,5.612,2.525c0.367,0.413,0.999,0.449,1.412,0.084
					C19.724,6.241,19.761,5.609,19.395,5.195z"
              />
              <path
                d="M23.832,13.594l-2.909-4.301c-0.391-0.391-1.023-0.391-1.414,0l-3.267,3.904c-0.354,0.424-0.297,1.054,0.127,1.408
					c0.424,0.354,1.054,0.298,1.408-0.127l1.425-1.706C19.057,16.781,15.76,20,11.716,20c-2.144,0-4.189-0.92-5.612-2.525
					c-0.366-0.414-1-0.451-1.412-0.084c-0.413,0.366-0.45,0.998-0.084,1.412C6.41,20.835,9.001,22,11.716,22
					c4.992,0,9.088-3.873,9.463-8.771l0.993,1.479c0.307,0.458,0.93,0.583,1.388,0.272C24.018,14.674,24.14,14.053,23.832,13.594z"
              />
            </g>
          </g>
        );
      case 'keyboard-1':
        return (
          <g>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M23.5,15.5
            c0-1.1-0.899-2-2-2h-19c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h19c1.101,0,2-0.9,2-2V15.5z"
            />
            <g>
              <g>
                <g>
                  <polygon points="11,15 10,15 10,16 11,16 11,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="14,15 13,15 13,16 14,16 14,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="17,15 16,15 16,16 17,16 17,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="20,15 19,15 19,16 20,16 20,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="8,15 7,15 7,16 8,16 8,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="5,15 4,15 4,16 5,16 5,15 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="11,17 10,17 10,18 11,18 11,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="14,17 13,17 13,18 14,18 14,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="17,17 16,17 16,18 17,18 17,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="20,17 19,17 19,18 20,18 20,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="8,17 7,17 7,18 8,18 8,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="5,17 4,17 4,18 5,18 5,17 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="11,19 10,19 10,20 11,20 11,19 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="14,19 13,19 13,20 14,20 14,19 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="17,19 16,19 16,20 17,20 17,19 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="20,19 19,19 19,20 20,20 20,19 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="8,19 7,19 7,20 8,20 8,19 						" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <polygon points="5,19 4,19 4,20 5,20 5,19 						" />
                </g>
              </g>
            </g>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M3.5,13.5v-1
            c0-1.657,1.344-3,3-3H20c1.934,0,3.5-1.567,3.5-3.5c0-1.934-1.566-3.5-3.5-3.5h-6.5C12.672,2.5,12,1.828,12,1V0.5"
            />
            <line
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="5"
              y1="21.5"
              x2="19"
              y2="21.5"
            />
          </g>
        );
      case 'keyboard-1-filled':
        return (
          <g>
            <path
              d="M21.5,13H4v-0.5C4,11.122,5.121,10,6.5,10H20c2.205,0,4-1.794,4-4s-1.795-4-4-4h-6.5c-0.552,0-1-0.448-1-1V0.5
            C12.5,0.224,12.275,0,12,0c-0.276,0-0.5,0.224-0.5,0.5V1c0,1.102,0.896,2,2,2H20c1.654,0,3,1.346,3,3s-1.346,3-3,3H6.5
            C4.57,9,3,10.57,3,12.5V13H2.5C1.121,13,0,14.122,0,15.5v6C0,22.878,1.121,24,2.5,24h19c1.378,0,2.5-1.122,2.5-2.5v-6
            C24,14.122,22.878,13,21.5,13z M16,15h1v1h-1V15z M16,17h1v1h-1V17z M16,19h1v1h-1V19z M13,15h1v1h-1V15z M13,17h1v1h-1V17z
             M13,19h1v1h-1V19z M10,15h1v1h-1V15z M10,17h1v1h-1V17z M10,19h1v1h-1V19z M7,15h1v1H7V15z M7,17h1v1H7V17z M7,19h1v1H7V19z
             M4,15h1v1H4V15z M4,17h1v1H4V17z M4,19h1v1H4V19z M19,22H5v-1h14V22z M20,20h-1v-1h1V20z M20,18h-1v-1h1V18z M20,16h-1v-1h1V16z"
            />
          </g>
        );
      case 'microphone-3-filled':
        return (
          <g>
            <path d="M12.5,19c3.415,0,6.218-2.649,6.475-6H6.025C6.282,16.351,9.085,19,12.5,19z" />
            <path
              d="M19,11h-4.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H19V9h-4.5C14.224,9,14,8.776,14,8.5S14.224,8,14.5,8H19V7h-4.5
            C14.224,7,14,6.776,14,6.5S14.224,6,14.5,6h4.475C18.784,3.519,17.2,1.422,15,0.502V3.5C15,3.776,14.776,4,14.5,4S14,3.776,14,3.5
            V0.182c-0.325-0.077-0.658-0.13-1-0.156V3.5C13,3.776,12.776,4,12.5,4S12,3.776,12,3.5V0.025c-0.342,0.026-0.675,0.079-1,0.156
            V3.5C11,3.776,10.776,4,10.5,4S10,3.776,10,3.5V0.502C7.8,1.422,6.216,3.519,6.025,6H10.5C10.776,6,11,6.224,11,6.5
            S10.776,7,10.5,7H6v1h4.5C10.776,8,11,8.224,11,8.5S10.776,9,10.5,9H6v1h4.5c0.276,0,0.5,0.224,0.5,0.5S10.776,11,10.5,11H6v1h13
            V11z"
            />
            <path d="M5,10.5C5,10.224,4.776,10,4.5,10S4,10.224,4,10.5V12h1V10.5z" />
            <path d="M20.5,10c-0.276,0-0.5,0.224-0.5,0.5V12h1v-1.5C21,10.224,20.776,10,20.5,10z" />
            <path
              d="M12.5,20c-4.037,0-7.225-3.037-7.476-7h-1c0.243,4.356,3.619,7.732,7.976,7.976V24h1v-3.024
            c4.357-0.243,7.732-3.619,7.976-7.976h-1C19.725,16.963,16.537,20,12.5,20z"
            />
          </g>
        );
      case 'camera-off':
        return (
          <g>
            <path
              d="M8,7.5h7.5
			c0.55,0,1,0.45,1,1v6.499"
            />
            <path
              d="M12,18.502H4.5
			c-0.55,0-1-0.45-1-1V9.999"
            />
            <polygon
              points="23.5,18.5 18.5,15.5 18.5,10.5 
			23.5,7.5 		"
            />

            <line x1="0.5" y1="3.5" x2="18.5" y2="21.5" />
          </g>
        );
      case 'camera-2':
        return (
          <g>
            <path
              d="M2.5,6.5l0.028-1c0-0.522,0.449-1,0.972-1
			h0.944C5,4.5,5.5,4.978,5.5,5.5v1"
            />
            <path
              d="M19.5,6.5c-0.636-1.272-1.21-3-2.5-3h-5
			c-1,0-1.473,0.963-3,3H2.5c-1.5,0-2,0.679-2,1.723v10.388C0.5,19.654,1,20.5,2.556,20.5h18.889c1.556,0,2.056-0.846,2.056-1.889
			V8.223C23.5,7.179,23,6.5,21.444,6.5H19.5z"
            />
            <circle cx="14.5" cy="13" r="5.5" />
            <circle cx="14.5" cy="13" r="3" />
            <circle cx="4" cy="10" r="1.5" />
          </g>
        );
      case 'smiley-love':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" />
            <path
              d="M7.468,11.25c0,0,3-2.16,3-3.76
			c0-1.846-2.455-2.43-3-0.184c-0.544-2.246-3-1.662-3,0.184C4.468,9.09,7.468,11.25,7.468,11.25z"
            />
            <path
              d="M16.468,11.25c0,0,3-2.16,3-3.76
			c0-1.846-2.455-2.43-3-0.184c-0.544-2.246-3-1.662-3,0.184C13.468,9.09,16.468,11.25,16.468,11.25z"
            />
            <path
              d="M18.5,15
			c-1.293,2.301-3.708,3.73-6.471,3.73c-2.807,0-5.252-1.346-6.529-3.715"
            />
          </g>
        );
      case 'bubble-chat-typing-3':
        return (
          <g>
            <path
              d="M23.5,10.883
				c0,5.181-5.148,9.383-11.5,9.383c-1.418,0-2.775-0.21-4.029-0.593l-6.608,3.076l2.588-5.166c-2.13-1.703-3.45-4.076-3.45-6.7
				C0.5,5.701,5.648,1.5,12,1.5S23.5,5.701,23.5,10.883z"
            />
            <circle cx="6" cy="11" r="1.5" />
            <circle cx="12" cy="11" r="1.5" />
            <circle cx="18" cy="11" r="1.5" />
          </g>
        );
      case 'network-notebook-share':
        return (
          <g>
            <polygon
              points="19.5,2.5 
            19.5,14.5 12,14.5 3.5,14.5 3.5,2.5 		"
            />
            <path
              d="M1.5,16.5V2
            c0-0.828,0.672-1.5,1.5-1.5h17c0.828,0,1.5,0.672,1.5,1.5v14.5"
            />
            <path
              d="M13.5,16.5v1h-4v-1
            h-9v2c0,0.552,0.448,1,1,1h20c0.553,0,1-0.448,1-1v-2H13.5z"
            />

            <line x1="0.521" y1="23.5" x2="22.469" y2="23.5" />

            <line x1="11.5" y1="23.5" x2="11.5" y2="19.5" />

            <circle cx="15" cy="6" r="1.5" />

            <circle cx="15" cy="11" r="1.5" />

            <circle cx="8" cy="8.5" r="1.5" />

            <line x1="9.355" y1="7.86" x2="13.542" y2="6.208" />

            <line x1="9.356" y1="9.142" x2="13.542" y2="10.792" />
          </g>
        );
      case 'frame-1':
        return (
          <g>
            <polyline
              points="3.5,9.5 
				3.5,4.5 8.479,4.5 			"
            />
            <polyline
              points="
				19.5,9.5 19.5,4.5 14.521,4.5 			"
            />
            <polyline
              points="
				3.5,15.5 3.5,20.5 8.479,20.5 			"
            />
            <polyline
              points="
				19.5,15.5 19.5,20.5 14.521,20.5 			"
            />
          </g>
        );
      case 'frame-2':
        return (
          <g>
            <polyline
              points="
				9.479,3.5 9.479,8.5 4.5,8.5 			"
            />
            <polyline
              points="
				15.5,3.5 15.5,8.5 20.479,8.5 			"
            />
            <polyline
              points="
				9.479,19.5 9.479,14.5 4.5,14.5 			"
            />
            <polyline
              points="
				15.5,19.5 15.5,14.5 20.479,14.5 			"
            />
          </g>
        );
      case 'volume-medium-1':
        return (
          <g>
            <path
              d="M5.5,8.5H2
			c-0.828,0-1.5,0.672-1.5,1.5v3c0,0.828,0.672,1.5,1.5,1.5h3.5l7,7v-20L5.5,8.5z"
            />
            <path
              d="M17.5,5.5
			c3.959,3.268,3.98,8.707,0,12"
            />
            <path
              d="M15.5,8
			c2.677,1.947,2.669,5.061,0,7"
            />
          </g>
        );
      case 'volume-medium-1-filled':
        return (
          <g>
            <path
              d="M12.146,1.146L5.293,8H2c-1.102,0-2,0.898-2,2v3c0,1.104,0.897,2,2,2h3.293l6.853,6.854C12.46,22.166,13,21.944,13,21.5
			v-20C13,1.058,12.461,0.83,12.146,1.146z"
            />
            <path
              d="M17.818,5.114c-0.213-0.175-0.528-0.145-0.704,0.067c-0.176,0.213-0.146,0.528,0.067,0.704
			c3.717,3.067,3.73,8.14,0,11.229c-0.213,0.176-0.242,0.491-0.066,0.704c0.175,0.212,0.492,0.241,0.704,0.066
			C22.039,14.393,22.008,8.572,17.818,5.114z"
            />
            <path
              d="M15.794,7.596c-0.223-0.162-0.536-0.112-0.699,0.11c-0.162,0.224-0.113,0.536,0.111,0.698
			c2.428,1.768,2.373,4.467,0,6.191c-0.224,0.162-0.273,0.475-0.111,0.698c0.165,0.226,0.48,0.272,0.699,0.11
			C18.734,13.269,18.743,9.74,15.794,7.596z"
            />
          </g>
        );
      case 'brightness-increase':
        return (
          <g>
            <circle cx="12" cy="12" r="4" />
            <line x1="12.5" y1="0.5" x2="12.5" y2="4.5" />
            <line x1="12.5" y1="19.5" x2="12.5" y2="23.5" />
            <line x1="3.868" y1="3.868" x2="6.695" y2="6.697" />
            <line x1="17.303" y1="17.303" x2="20.132" y2="20.132" />
            <line x1="0.5" y1="12.5" x2="4.5" y2="12.5" />
            <line x1="19.5" y1="12.5" x2="23.5" y2="12.5" />
            <line x1="3.868" y1="20.132" x2="6.695" y2="17.303" />
            <line x1="17.303" y1="6.697" x2="20.132" y2="3.868" />
          </g>
        );
      case 'color-contrast-on':
        return (
          <g>
            <path
              d="M20,15c0,4.694-3.806,8.5-8.5,8.5
			S3,19.694,3,15s8.5-14.5,8.5-14.5S20,10.306,20,15z"
            />
            <line x1="11.5" y1="0.5" x2="11.5" y2="23.5" />
            <line x1="13.5" y1="2.954" x2="13.5" y2="23.264" />
            <line x1="15.5" y1="5.635" x2="15.5" y2="22.5" />
            <line x1="17.5" y1="8.659" x2="17.5" y2="21" />
          </g>
        );
      case 'phone-call-incoming':
        return (
          <g>
            <path
              d="M7.493,10.012
              c0.78-0.781,0.78-2.048,0-2.829L5.371,5.062c-0.78-0.78-2.047-0.78-2.828,0L1.379,6.226C0.37,7.234,0.213,8.81,1,10
              c3.381,5.11,7.891,9.619,13,13c1.189,0.785,2.769,0.629,3.775-0.379l1.164-1.164c0.781-0.78,0.781-2.047,0-2.828l-2.122-2.121
              c-0.78-0.781-2.047-0.781-2.828,0l-0.707,0.707c-2.326-1.906-4.591-4.17-6.497-6.496L7.493,10.012z"
            />
            <line x1="13.5" y1="10.5" x2="23.5" y2="0.5" />
            <polyline
              points="
              20.002,10.499 13.502,10.499 13.502,3.999"
            />
          </g>
        );
      case 'phone-call-outgoing':
        return (
          <g>
            <path
              d="M7.492,10.012
              c0.78-0.781,0.78-2.047,0-2.828L5.37,5.062c-0.78-0.781-2.048-0.781-2.829,0L1.378,6.226C0.368,7.235,0.213,8.81,1,10.001
              c3.38,5.11,7.889,9.619,13,13c1.188,0.785,2.768,0.628,3.773-0.379l1.164-1.164c0.781-0.78,0.781-2.047,0-2.828l-2.121-2.121
              c-0.781-0.781-2.048-0.781-2.828,0l-0.707,0.707c-2.326-1.906-4.591-4.171-6.496-6.497L7.492,10.012z"
            />
            <line x1="23.5" y1="0.501" x2="13.5" y2="10.501" />
            <polyline
              points="17,0.5 
              23.5,0.5 23.5,7"
            />
          </g>
        );
      case 'phone-miss-call':
        return (
          <g>
            <path
              d="M7.491,10.014
              c0.781-0.78,0.781-2.047,0-2.828L5.37,5.063c-0.781-0.78-2.048-0.78-2.829,0L1.378,6.228C0.368,7.237,0.213,8.812,1,10.002
              c3.38,5.111,7.889,9.62,13,13c1.188,0.786,2.768,0.629,3.773-0.378l1.164-1.164c0.781-0.781,0.781-2.047,0-2.828l-2.121-2.121
              c-0.781-0.781-2.048-0.781-2.829,0l-0.706,0.707c-2.327-1.906-4.591-4.171-6.497-6.497L7.491,10.014z"
            />
            <polyline
              points="11.5,9 
              11.5,3.5 17,3.5"
            />
            <polyline points="11.5,3.5 17.472,9.5 23.472,3.5" />
          </g>
        );
      case 'phone-call-reject-filled':
        return (
          <g>
            <path
              d="M17.5,0C13.916,0,11,2.916,11,6.5s2.916,6.5,6.5,6.5S24,10.084,24,6.5S21.084,0,17.5,0z M18.207,6.5l2.347,2.346
              c0.195,0.195,0.195,0.513,0,0.707c-0.195,0.195-0.512,0.195-0.707,0L17.5,7.206l-2.347,2.347c-0.195,0.195-0.512,0.195-0.707,0
              c-0.195-0.194-0.195-0.512,0-0.707L16.793,6.5l-2.347-2.346c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0
              L17.5,5.794l2.347-2.347c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707L18.207,6.5z"
            />
            <path
              d="M0.58,10.273c3.423,5.185,7.955,9.719,13.146,13.146c1.354,0.898,3.201,0.76,4.404-0.445l1.163-1.164
              c0.98-0.982,0.972-2.562-0.003-3.535l-2.121-2.121c-0.98-0.974-2.751-1.051-3.922,0.389c-2.019-1.687-3.999-3.646-5.789-5.789
              C8.896,9.582,8.82,7.812,7.847,6.831L5.724,4.71C4.752,3.734,3.171,3.728,2.19,4.706L1.026,5.87
              C-0.179,7.071-0.317,8.92,0.58,10.273z"
            />
          </g>
        );
      case 'camera-off-filled':
        return (
          <g>
            <path
              d="M23.243,7.071l-5,3C18.092,10.162,18,10.324,18,10.5v5c0,0.176,0.092,0.338,0.243,0.429l5,3
              C23.58,19.129,24,18.886,24,18.5v-11C24,7.113,23.577,6.871,23.243,7.071z"
            />
            <g>
              <path d="M3,8.162v9.34c0,0.827,0.673,1.5,1.5,1.498h9.382L3,8.162z" />
              <path d="M17,17.837V8.5C17,7.673,16.327,7,15.5,7H6.122L17,17.837z" />
            </g>
            <g>
              <path
                d="M18.042,21.748L0.251,3.957c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0l17.791,17.791
                c0.195,0.195,0.195,0.512,0,0.707C18.554,21.943,18.237,21.943,18.042,21.748z"
              />
            </g>
          </g>
        );
      case 'phone-1':
        return (
          <g>
            <path
              d="M21,2.5
            c1.381,0.282,2.5,1.591,2.5,3v2c0,1.093-0.907,2-2,2h-3c-1.092,0-2-0.907-2-2V6c-2.965-0.294-6.033-0.294-9,0v1.5
            c0,1.093-0.908,2-2,2h-3c-1.093,0-2-0.907-2-2v-2c0-1.409,1.119-2.718,2.5-3C8.751,1.328,14.848,1.247,21,2.5z"
            />
            <rect x="5.5" y="12.5" width="3" height="2" />
            <rect x="10.5" y="12.5" width="3" height="2" />
            <rect x="15.5" y="12.5" width="3" height="2" />
            <rect x="5.5" y="16.5" width="3" height="2" />
            <rect x="10.5" y="16.5" width="3" height="2" />
            <rect x="15.5" y="16.5" width="3" height="2" />
            <rect x="5.5" y="20.5" width="3" height="2" />
            <rect x="10.5" y="20.5" width="3" height="2" />
            <rect x="15.5" y="20.5" width="3" height="2" />
          </g>
        );
      case 'phone-1-filled':
        return (
          <g>
            <g>
              <path
                d="M24,6.002v1.5c0,1.378-1.122,2.499-2.5,2.5h-3c-1.378,0-2.687-1.205-2.5-3.045c-2.632-0.233-5.366-0.233-8,0
					c0.188,1.84-1.122,3.045-2.499,3.045H2.5C1.122,10.001,0,8.88,0,7.502v-1.5c-0.001-1.625,1.273-3.157,2.9-3.49
					c5.989-1.22,12.206-1.221,18.2,0C22.726,2.845,24,4.377,24,6.002z"
              />
            </g>
            <path
              d="M8.5,12.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C9,12.226,8.776,12.002,8.5,12.002z"
            />
            <path
              d="M13.5,12.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C14,12.226,13.776,12.002,13.5,12.002z"
            />
            <path
              d="M18.5,12.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C19,12.226,18.776,12.002,18.5,12.002z"
            />
            <path
              d="M8.5,16.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C9,16.226,8.776,16.002,8.5,16.002z"
            />
            <path
              d="M13.5,16.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C14,16.226,13.776,16.002,13.5,16.002z"
            />
            <path
              d="M18.5,16.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C19,16.226,18.776,16.002,18.5,16.002z"
            />
            <path
              d="M8.5,20.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C9,20.226,8.776,20.002,8.5,20.002z"
            />
            <path
              d="M13.5,20.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C14,20.226,13.776,20.002,13.5,20.002z"
            />
            <path
              d="M18.5,20.002h-3c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h3c0.276,0,0.5-0.224,0.5-0.5v-2
				C19,20.226,18.776,20.002,18.5,20.002z"
            />
          </g>
        );
      case 'phone-call-2':
        return (
          <g>
            <path
              d="M7.492,10.012
				c0.78-0.781,0.78-2.048,0-2.829L5.37,5.062c-0.78-0.78-2.048-0.78-2.828,0L1.378,6.226C0.369,7.234,0.213,8.81,1,10
				c3.38,5.11,7.889,9.619,13,13c1.188,0.785,2.768,0.629,3.773-0.379l1.165-1.164c0.78-0.78,0.78-2.047,0-2.828l-2.122-2.121
				c-0.781-0.781-2.047-0.781-2.828,0l-0.707,0.707c-2.326-1.906-4.591-4.17-6.496-6.496L7.492,10.012z"
            />
            <path
              d="M12,0.5
				c6.352,0,11.5,5.148,11.5,11.5"
            />
            <path
              d="M12,4.5
				c4.142,0,7.5,3.357,7.5,7.5"
            />
            <path
              d="M12,8.5
				c1.933,0,3.5,1.566,3.5,3.5"
            />
          </g>
        );
      case 'phone-call-list':
        return (
          <g>
            <path
              d="M7.492,10.014
				c0.779-0.783,0.779-2.049,0-2.83L5.369,5.062c-0.779-0.779-2.047-0.779-2.828,0L1.378,6.227c-1.01,1.01-1.165,2.584-0.379,3.775
				c3.38,5.109,7.89,9.619,13,13c1.188,0.785,2.768,0.629,3.774-0.379l1.164-1.164c0.781-0.781,0.781-2.047,0-2.828l-2.121-2.121
				c-0.781-0.783-2.048-0.783-2.829,0l-0.707,0.707c-2.326-1.906-4.59-4.17-6.496-6.498L7.492,10.014z"
            />

            <line x1="14.5" y1="2.5" x2="22.5" y2="2.5" />

            <line x1="14.5" y1="6.5" x2="22.5" y2="6.5" />

            <line x1="14.5" y1="10.5" x2="22.5" y2="10.5" />

            <line x1="11.5" y1="2.5" x2="12.5" y2="2.5" />

            <line x1="11.5" y1="6.5" x2="12.5" y2="6.5" />

            <line x1="11.5" y1="10.5" x2="12.5" y2="10.5" />
          </g>
        );
      case 'phone-call-list-filled':
        return (
          <g>
            <path
              d="M0.581,10.273c3.423,5.185,7.955,9.719,13.146,13.145c1.354,0.899,3.201,0.76,4.404-0.445l1.163-1.164
				c0.98-0.982,0.972-2.562-0.003-3.535l-2.121-2.121c-0.98-0.974-2.751-1.051-3.922,0.389c-2.019-1.687-3.999-3.646-5.789-5.789
				C8.897,9.582,8.82,7.812,7.847,6.831L5.725,4.71C4.752,3.734,3.172,3.728,2.19,4.706L1.026,5.87
				C-0.179,7.071-0.317,8.92,0.581,10.273z"
            />
            <g>
              <path d="M22.5,3h-8C14.224,3,14,2.776,14,2.5C14,2.224,14.224,2,14.5,2h8C22.776,2,23,2.224,23,2.5C23,2.776,22.776,3,22.5,3z" />
            </g>
            <g>
              <path d="M22.5,7h-8C14.224,7,14,6.776,14,6.5C14,6.224,14.224,6,14.5,6h8C22.776,6,23,6.224,23,6.5C23,6.776,22.776,7,22.5,7z" />
            </g>
            <g>
              <path
                d="M22.5,11h-8c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.224,0.5,0.5
					C23,10.776,22.776,11,22.5,11z"
              />
            </g>
            <g>
              <path d="M12.5,3h-1C11.224,3,11,2.776,11,2.5C11,2.224,11.224,2,11.5,2h1C12.776,2,13,2.224,13,2.5C13,2.776,12.776,3,12.5,3z" />
            </g>
            <g>
              <path d="M12.5,7h-1C11.224,7,11,6.776,11,6.5C11,6.224,11.224,6,11.5,6h1C12.776,6,13,6.224,13,6.5C13,6.776,12.776,7,12.5,7z" />
            </g>
            <g>
              <path
                d="M12.5,11h-1c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h1c0.276,0,0.5,0.224,0.5,0.5
					C13,10.776,12.776,11,12.5,11z"
              />
            </g>
          </g>
        );
      case 'help-circle':
        return (
          <g>
            <circle cx="11.5" cy="12.5" r="11" />
            <path
              d="M8.5,10
          c0-1.656,1.343-3,3-3c1.656,0,3,1.344,3,3c0,1.658-1.344,3-3,3v3"
            />
            <path
              d="M12,18.5c0,0.276-0.224,0.5-0.5,0.5l0,0
          c-0.276,0-0.5-0.224-0.5-0.5l0,0c0-0.276,0.224-0.5,0.5-0.5l0,0C11.776,18,12,18.224,12,18.5L12,18.5z"
            />
          </g>
        );
      case 'logo-pt':
        return (
          <g>
            <circle cx="12" cy="12" r="11.5" id="circle2" />
            <path d="M 15.874901,16.967524 V 8.9675255 m 3.5,-0.9725175 h -7" />
            <path d="m 5.6361818,12.802187 h 3.2168671 c 0.8878524,0 1.6084281,-1.080868 1.6084281,-2.41265 0,-1.3329782 -0.7205757,-2.4126328 -1.6084281,-2.4126328 H 5.6361818 Z m 0.0064,4.202805 V 9.0049915" />
          </g>
        );
      case 'logo-pt-filled':
        return (
          <g id="Outline_Icons">
            <circle cx="12" cy="12" r="11.5" />
            <path
              d="m 4.6367188,6.9765625 v 6.8261715 h 0.00586 v 3.203125 h 2 v -3.203125 h 2.2109375 c 0.8497249,0 1.5474957,-0.52046 1.9687507,-1.152343 0.421254,-0.631884 0.638672,-1.415474 0.638672,-2.261719 0,-0.8466811 -0.217287,-1.6279085 -0.638672,-2.2597658 C 10.40088,7.497049 9.702785,6.9765625 8.8535156,6.9765625 Z m 2,2 h 2.2167968 c 0.038583,0 0.1438217,0.020504 0.3046875,0.2617187 0.1608658,0.2412146 0.3027344,0.6640935 0.3027344,1.1503908 0,0.485537 -0.1417378,0.910848 -0.3027344,1.152344 -0.1609966,0.241495 -0.26656,0.261718 -0.3046875,0.261718 H 6.6425781 V 9.0058594 h -0.00586 z M 12.375,6.9941406 v 2 h 2.5 v 7.9726564 h 2 V 8.9941406 h 2.5 v -2 z M 23,12.000001 A 11,11 0 0 1 12,23 11,11 0 0 1 1.0000002,12.000001 11,11 0 0 1 12,1.0000003 11,11 0 0 1 23,12.000001 Z"
              id="path10"
              fill="#eedc00"
            />
          </g>
        );
      case 'id-picture-2':
        return (
          <g>
            <path
              d="M10.5,15.604V17l-3,0.726
				c-0.529,0.151-1,0.725-1,1.274v0.5"
            />
            <path
              d="M13.5,15.604V17l3,0.726
				c0.528,0.151,1.008,0.696,1,1.274v0.5"
            />
            <ellipse cx="12" cy="12.771" rx="3" ry="3.272" />
            <path
              d="M14.957,12.212
				c-0.5,0.5-1.918,0.461-2.457-0.542c-1,1-2.625,1-3.434,0.429"
            />
            <polyline
              points="14.5,4.5 20.5,4.5 20.5,23.5 
				3.5,23.5 3.5,4.5 9.5,4.5 			"
            />
            <path
              d="M14.5,7.5h-5V3c0-1.375,1.125-2.5,2.5-2.5
				s2.5,1.125,2.5,2.5V7.5z"
            />
            <line x1="3.5" y1="19.5" x2="20.5" y2="19.5" />
            <line x1="14" y1="21.5" x2="18" y2="21.5" />
            <path
              d="M12.5,2.995c0,0.276-0.224,0.5-0.5,0.5
				l0,0c-0.276,0-0.5-0.224-0.5-0.5l0,0c0-0.276,0.224-0.5,0.5-0.5l0,0C12.276,2.495,12.5,2.719,12.5,2.995L12.5,2.995z"
            />
          </g>
        );
      case 'calendar-check-1':
        return (
          <g>
            <polyline points="18,11.5 10.5,19.5 7,16" />
            <polyline points="4.5,2.5 0.5,2.5 0.5,23.5 23.5,23.5 23.5,2.5 19.5,2.5" />
            <rect x="4.5" y="0.5" width="3" height="4" />
            <rect x="16.5" y="0.5" width="3" height="4" />
            <line x1="7.5" y1="2.5" x2="16.5" y2="2.5" />
            <line x1="0.5" y1="7.5" x2="23.5" y2="7.5" />
          </g>
        );
      case 'calendar-check-2':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="6" />
              <polyline points="20.5,15.5 16.5,19.5 14.5,17.5" />
            </g>
            <g>
              <polyline points="20.5,10 20.5,2.5 16.5,2.5" />
              <polyline points="4.5,2.5 0.5,2.5 0.5,18.5 9.5,18.5" />
              <rect x="4.5" y="0.5" width="3" height="4" />
              <rect x="13.5" y="0.5" width="3" height="4" />
              <line x1="7.5" y1="2.5" x2="13.5" y2="2.5" />
              <line x1="0.5" y1="7.5" x2="20.5" y2="7.5" />
            </g>
          </g>
        );
      case 'calendar-close-1':
        return (
          <g>
            <line x1="7.5" y1="20" x2="16.5" y2="11" />
            <line x1="7.5" y1="11" x2="16.5" y2="20" />
            <polyline points="4.5,2.5 0.5,2.5 0.5,23.5 23.5,23.5 23.5,2.5 19.5,2.5" />
            <rect x="4.5" y="0.5" width="3" height="4" />
            <rect x="16.5" y="0.5" width="3" height="4" />
            <line x1="7.5" y1="2.5" x2="16.5" y2="2.5" />
            <line x1="0.5" y1="7.5" x2="23.5" y2="7.5" />
          </g>
        );
      case 'calendar-close-2':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="6" />
              <line x1="15" y1="20" x2="20" y2="15" />
              <line x1="20" y1="20" x2="15" y2="15" />
            </g>
            <g>
              <polyline points="20.5,10 20.5,2.5 16.5,2.5" />
              <polyline points="4.5,2.5 0.5,2.5 0.5,18.5 9.5,18.5" />
              <rect x="4.5" y="0.5" width="3" height="4" />
              <rect x="13.5" y="0.5" width="3" height="4" />
              <line x1="7.5" y1="2.5" x2="13.5" y2="2.5" />
              <line x1="0.5" y1="7.5" x2="20.5" y2="7.5" />
            </g>
          </g>
        );
      case 'calendar-edit-1':
        return (
          <g>
            <polyline
              points="4.5,2.5 
				0.5,2.5 0.5,23.5 23.5,23.5 23.5,2.5 19.5,2.5 			"
            />

            <rect x="4.5" y="0.5" width="3" height="4" />

            <rect x="16.5" y="0.5" width="3" height="4" />

            <line x1="7.5" y1="2.5" x2="16.5" y2="2.5" />

            <line x1="0.5" y1="7.5" x2="23.5" y2="7.5" />
            <g>
              <polygon
                points="10.5,20 
					7,21 8,17.5 15.502,9.998 18.002,12.498 				"
              />

              <line x1="13.502" y1="11.998" x2="16.002" y2="14.498" />

              <line x1="8" y1="17.5" x2="10.5" y2="20" />
            </g>
          </g>
        );
      case 'arrow-down-12':
        return (
          <g id="Outline_Icons">
            <polyline
              fill="none"
              points="0.501,5.5 
		12.001,18.5 23.501,5.5 	"
            />
          </g>
        );
      case 'arrow-down-12-filled':
        return (
          <g>
            <g id="Filled_Icons">
              <path
                d="M23.936,2.255C23.848,2.098,23.681,2,23.5,2h-23C0.32,2,0.153,2.098,0.065,2.255c-0.089,0.157-0.085,0.35,0.008,0.504
		l11.5,19C11.663,21.908,11.826,22,12,22s0.337-0.092,0.428-0.241l11.5-19C24.021,2.605,24.025,2.412,23.936,2.255z"
              />
            </g>
            <g id="Frames-24px">
              <rect fill="none" width="24" height="24" />
            </g>
          </g>
        );
      case 'forward-box':
        return (
          <g>
            <path
              fill="none"
              d="M23.5,23.5h-23v-7
				H8v1c0,1.1,0.9,2,2,2h4.5c1.1,0,2-0.9,2-2v-1h7V23.5z"
            />
            <polyline
              fill="none"
              points="
				14.5,10.5 20,10.5 23.5,16.5 			"
            />
            <polyline
              fill="none"
              points="
				0.5,16.5 4,10.5 6.5,10.5 			"
            />
            <path
              fill="none"
              d="M18.5,8.5c0,0,0-0.5,0-2
				c-8.5,0-10,7.5-10,7.5s0-11.5,10-11.5c0-0.5,0-2,0-2l5,4L18.5,8.5z"
            />
          </g>
        );
      case 'reply':
        return (
          <g>
            <path
              fill="none"
              d="M9.5,8.5c0,0,12.5-1.5,12.5,11
				c-4-7.5-12.5-5-12.5-5v4l-8.5-7l8.5-7V8.5z"
            />
          </g>
        );
      case 'arrow-bottom-left':
        return (
          <g>
            <polyline
              fill="none"
              points="7.5,23.5 
			0.5,23.5 0.5,16.5 		"
            />

            <line fill="none" x1="0.5" y1="23.5" x2="16.5" y2="7.5" />
          </g>
        );
      case 'remove-1':
        return (
          <g>
            <line fill="none" x1="23.5" y1="0.5" x2="0.5" y2="23.5" />
            <line fill="none" x1="23.5" y1="23.5" x2="0.5" y2="0.5" />
          </g>
        );

      case 'file-text-document':
        return (
          <g>
            <g>
              <polygon
                fill="none"
                points="
				21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5 			"
              />
              <polyline
                fill="none"
                points="
				15.5,0.5 15.5,6.5 21.5,6.5 			"
              />
            </g>

            <line fill="none" x1="7.5" y1="7.5" x2="12" y2="7.5" />

            <line fill="none" x1="7.5" y1="10.5" x2="16.5" y2="10.5" />

            <line fill="none" x1="7.5" y1="13.5" x2="10.5" y2="13.5" />

            <line fill="none" x1="7.5" y1="16.5" x2="10.5" y2="16.5" />

            <line fill="none" x1="7.5" y1="19.5" x2="10.5" y2="19.5" />
            <g>
              <polyline
                fill="none"
                points="
				12.5,14.5 12.5,13.5 16.5,13.5 16.5,14.5 			"
              />

              <line fill="none" x1="14.5" y1="13.5" x2="14.5" y2="19.5" />

              <line fill="none" x1="13" y1="19.5" x2="16" y2="19.5" />
            </g>
          </g>
        );

      case 'bubble-chat-view-2':
        return (
          <g>
            <g>
              <circle cx="17" cy="17" r="4" />

              <line x1="19.828" y1="19.828" x2="23" y2="23" />
            </g>
            <g>
              <path
                d="M9.5,18.436
					C8.614,18.361,7.718,18.219,7,18l-5.5,2.5l2-4.5c-1.929-1.543-3-3.623-3-6.002C0.5,5.306,5.164,1.5,10.917,1.5
					C16.672,1.5,21.5,5.306,21.5,10c0,0.339-0.024,0.673-0.073,1.002"
              />
            </g>
          </g>
        );

      case 'bubble-chat-edit':
        return (
          <g>
            <g>
              <polygon
                fill="none"
                points="
					      16.5,20.5 13,21.5 14,18 20.5,11.5 23,14 				"
              />
              <line fill="none" x1="18.5" y1="13.5" x2="21" y2="16" />

              <line fill="none" x1="14" y1="18" x2="16.5" y2="20.5" />
            </g>
            <g>
              <path
                fill="none"
                d="M13.5,14.5h-2
				      	l-4,4v-4H7c-3.575,0-6.5-2.925-6.5-6.5S3.425,1.5,7,1.5h8c3.575,0,6.5,2.925,6.5,6.5c0,0.34-0.026,0.674-0.077,1"
              />
            </g>
          </g>
        );
      case 'navigation-next-1':
        return (
          <g>
            <path
              fill="none"
              d="M10.5,18.5
				l3.914-3.914C8.164,11.301,0.5,11.307,0.5,18.5c0-10.79,9.461-12.959,19.738-9.761L23.5,5.5v13H10.5z"
            />
          </g>
        );
      case 'hierarchy-business':
        return (
          <g>
            <circle cx="12" cy="3.289" r="2.774" />
            <path
              d="M16.438,10.5
              H7.562c0-2.449,1.986-4.438,4.438-4.438C14.453,6.062,16.438,8.051,16.438,10.5z"
            />
            <circle cx="4.459" cy="17.958" r="2" />
            <path
              d="M8.004,23.502
              h-7.09c0-1.958,1.586-3.544,3.545-3.544C6.417,19.958,8.004,21.544,8.004,23.502z"
            />
            <circle cx="19.459" cy="17.958" r="2" />
            <path
              d="M23.004,23.502
              h-7.09c0-1.958,1.586-3.544,3.545-3.544C21.417,19.958,23.004,21.544,23.004,23.502z"
            />
            <circle cx="19.459" cy="17.958" r="2" />
            <path
              d="M23.004,23.502
              h-7.09c0-1.958,1.586-3.544,3.545-3.544C21.417,19.958,23.004,21.544,23.004,23.502z"
            />
            <circle cx="12" cy="17.958" r="2" />
            <path
              d="M15.545,23.502
              H8.456c0-1.958,1.586-3.544,3.544-3.544C13.959,19.958,15.545,21.544,15.545,23.502z"
            />
            <polyline
              points="
              4.5,13.479 4.5,12.5 19.5,12.5 19.5,13.479 			"
            />
            <line x1="12" y1="10.5" x2="12" y2="13.479" />
          </g>
        );
      case 'navigation-next-1-filled':
        return (
          <path
            d="M23.143,5.15l-2.951,3.014c-2.839-0.863-5.531-1.301-8.005-1.301c-7.681,0-12.093,4.225-12.107,11.591
			c-0.002,0.665,0.998,0.669,1,0.002c0.009-4.799,3.609-5.516,5.755-5.516c2.082,0,4.482,0.621,6.819,1.759l-3.504,3.444
			C9.833,18.452,10.049,19,10.5,19h13c0.275,0,0.5-0.224,0.5-0.5v-13C24,5.051,23.451,4.832,23.143,5.15z"
          />
        );
      case 'navigation-show-more-2-filled':
        return (
          <g>
            <circle cx="12" cy="3" r="3" />
            <circle cx="12" cy="12" r="3" />
            <circle cx="12" cy="21" r="3" />
          </g>
        );
      case 'arrow-down-8-filled':
        return (
          <path
            d="M23.825,6.333L21.243,4.12c-0.101-0.086-0.231-0.122-0.365-0.118c-0.132,0.01-0.255,0.073-0.341,0.175l-8.54,10.051
		L3.463,4.177C3.376,4.075,3.253,4.012,3.121,4.002C2.993,3.999,2.857,4.034,2.756,4.12L0.174,6.333
		C0.073,6.42,0.011,6.543,0.001,6.676c-0.01,0.133,0.033,0.264,0.121,0.364L11.62,20.327c0.095,0.109,0.233,0.173,0.378,0.173
		c0.145,0,0.283-0.063,0.378-0.173L23.878,7.04c0.087-0.101,0.13-0.231,0.121-0.364C23.989,6.543,23.926,6.42,23.825,6.333z"
          />
        );
      case 'arrow-up-8-filled':
        return (
          <path
            d="M23.878,17.46L12.376,4.173c-0.19-0.219-0.566-0.219-0.756,0L0.122,17.46c-0.087,0.101-0.13,0.231-0.121,0.364
            c0.01,0.133,0.072,0.256,0.173,0.343l2.582,2.213c0.091,0.078,0.206,0.12,0.325,0.12c0.013,0,0.026-0.001,0.04-0.002
            c0.132-0.01,0.255-0.073,0.342-0.175l8.535-10.051l8.54,10.051c0.086,0.102,0.209,0.165,0.341,0.175
            c0.134,0.007,0.264-0.031,0.365-0.118l2.582-2.213c0.101-0.087,0.164-0.21,0.173-0.343C24.009,17.691,23.966,17.56,23.878,17.46z"
          />
        );
      case 'navigation-next-2':
        return (
          <g>
            <circle cx="11.5" cy="12.5" r="11" />
            <path
              d="M7,17.5
				c-1,0-3-6,1-6h9.5"
            />
            <polyline
              points="
				14.5,14.5 17.5,11.5 14.5,8.5 			"
            />
          </g>
        );
      case 'navigation-circle-right-filled':
        return (
          <path
            d="M11.5,0C5.159,0,0,5.159,0,11.5S5.159,23,11.5,23S23,17.841,23,11.5S17.841,0,11.5,0z M19.207,12.207l-4.5,4.5
			c-0.391,0.392-1.023,0.392-1.414,0c-0.391-0.39-0.391-1.023,0-1.413l2.793-2.793H5.5c-0.553,0-1-0.448-1-1c0-0.552,0.447-1,1-1
			h10.586l-2.793-2.793c-0.391-0.39-0.391-1.023,0-1.413c0.391-0.391,1.023-0.391,1.414,0l4.5,4.5
			C19.598,11.184,19.598,11.816,19.207,12.207z"
          />
        );
      case 'checklist-pen':
        return (
          <g>
            <polyline points="7,5 4,8 2.5,6.5" />
            <polyline points="7,10.5 4,13.5 2.5,12" />
            <polygon points="9.5,21.5 0.5,21.5 0.5,2.5 14.5,2.5 14.5,16.5" />
            <polyline points="9.5,21.5 9.5,16.5 14.5,16.5" />
            <g>
              <rect x="17.499" y="8.5" width="4.001" height="10.5" />
              <path d="M21.498,1.501 c1.105,0,2,0.895,2,1.999l0.002,8" />
              <line x1="19.5" y1="21" x2="19.5" y2="23.5" />
              <rect x="17.499" y="0.5" width="3.999" height="8" />
              <rect x="18.5" y="19" width="2" height="2" />
            </g>
            <line x1="8.5" y1="7.5" x2="12" y2="7.5" />
            <line x1="8.5" y1="12.5" x2="12" y2="12.5" />
          </g>
        );
      case 'person-2-filled':
        return (
          <path
            d="M16.489,9.754c0.808-0.764,0.749-2.433,0.094-3c0.521-1.385,1.189-2.997,0.907-4.409C17.144,0.614,14.686,0,12.686,0
			c-1.551,0-3.435,0.391-4.029,1.461C8.06,1.527,7.599,1.774,7.284,2.198C6.416,3.37,7.009,5.169,7.436,6.735
			C6.781,7.303,6.703,8.99,7.511,9.754c0.111,1.588,1.05,2.561,1.489,2.934v2.408c-0.421,0.157-0.833,0.309-1.234,0.456
			c-3.471,1.276-5.979,2.199-6.713,3.668C0.012,21.301,0,23.411,0,23.5C0,23.776,0.224,24,0.5,24h23c0.276,0,0.5-0.224,0.5-0.5
			c0-0.089-0.012-2.199-1.053-4.28c-0.735-1.47-3.243-2.392-6.714-3.668c-0.4-0.148-0.813-0.299-1.233-0.456v-2.408
			C15.439,12.314,16.378,11.342,16.489,9.754z"
          />
        );
      case 'bubble-chat-favorite-star-3':
        return (
          <g>
            <polygon points="18.5,12 19.993,15.5 23.493,15.5 20.493,17.997 21.993,22 18.493,19.502 14.993,22 16.493,17.997 13.493,15.5 16.993,15.5" />
            <g>
              <path d="M12,14.5h-0.5l-4,4v-4H7c-3.575,0-6.5-2.925-6.5-6.5S3.425,1.5,7,1.5h8c3.575,0,6.5,2.925,6.5,6.5c0,0.885-0.18,1.729-0.503,2.5" />
            </g>
          </g>
        );

      case 'ticket-2':
        return (
          <g>
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M23.5,15
			c-1.657,0-3-1.343-3-3c0-1.656,1.343-3,3-3V4.5h-23V9c1.656,0,3,1.344,3,3c0,1.657-1.344,3-3,3v4.5h23V15z"
            />
            <g>
              <g>
                <polygon points="19,6 18,6 18,7 19,7 19,6 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,8 18,8 18,9 19,9 19,8 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,10 18,10 18,11 19,11 19,10 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,12 18,12 18,13 19,13 19,12 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,14 18,14 18,15 19,15 19,14 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,16 18,16 18,17 19,17 19,16 				" />
              </g>
            </g>
            <g>
              <g>
                <polygon points="19,18 18,18 18,19 19,19 19,18 				" />
              </g>
            </g>

            <rect
              x="6.5"
              y="7.5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              width="9"
              height="9"
            />

            <line
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="9.5"
              y1="10.5"
              x2="12.5"
              y2="10.5"
            />

            <line
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="9.5"
              y1="13.5"
              x2="12.5"
              y2="13.5"
            />
          </g>
        );
      case 'ticket-2-filled':
        return (
          <g>
            <path
              d="M7,16h8V8H7V16z M9.5,10h3c0.275,0,0.5,0.224,0.5,0.5S12.775,11,12.5,11h-3C9.224,11,9,10.776,9,10.5S9.224,10,9.5,10z
			 M9.5,13h3c0.275,0,0.5,0.224,0.5,0.5S12.775,14,12.5,14h-3C9.224,14,9,13.776,9,13.5S9.224,13,9.5,13z"
            />
            <path
              d="M23.5,9.5C23.775,9.5,24,9.276,24,9V4.5C24,4.224,23.775,4,23.5,4h-23C0.224,4,0,4.224,0,4.5V9c0,0.276,0.224,0.5,0.5,0.5
			C1.878,9.5,3,10.621,3,12c0,1.378-1.122,2.5-2.5,2.5C0.224,14.5,0,14.724,0,15v4.5C0,19.776,0.224,20,0.5,20h23
			c0.275,0,0.5-0.224,0.5-0.5V15c0-0.276-0.225-0.5-0.5-0.5c-1.379,0-2.5-1.122-2.5-2.5C21,10.621,22.121,9.5,23.5,9.5z M16,16.5
			c0,0.276-0.225,0.5-0.5,0.5h-9C6.224,17,6,16.776,6,16.5v-9C6,7.224,6.224,7,6.5,7h9C15.775,7,16,7.224,16,7.5V16.5z M19,19h-1v-1
			h1V19z M19,17h-1v-1h1V17z M19,15h-1v-1h1V15z M19,13h-1v-1h1V13z M19,11h-1v-1h1V11z M19,9h-1V8h1V9z M19,7h-1V6h1V7z"
            />
          </g>
        );
      case 'link-2-filled':
        return (
          <g>
            <path
              d="M20.397,4.756c0.444,0.409,0.8,1.096,0.931,1.793c0.124,0.665,0.031,1.228-0.264,1.547l-3.81,4.114
				c-0.374,0.407-1.024,0.464-1.501-0.027c-0.406-0.376-1.037-0.353-1.412,0.055c-0.378,0.405-0.352,1.036,0.053,1.413
				c1.142,1.131,3.093,1.229,4.329-0.082l3.807-4.117c0.741-0.797,1.012-1.96,0.764-3.273c-0.211-1.114-0.784-2.195-1.538-2.89
				l-1.821-1.687c-0.753-0.698-1.874-1.187-3.001-1.312c-1.328-0.146-2.468,0.213-3.205,1.015L9.916,5.418
				c-1.213,1.334-0.963,3.27,0.253,4.322c0.408,0.375,1.037,0.351,1.413-0.057c0.376-0.404,0.352-1.035-0.055-1.411
				C11,7.835,11.01,7.181,11.386,6.778l3.809-4.115c0.293-0.319,0.85-0.456,1.521-0.382c0.704,0.076,1.417,0.378,1.86,0.788
				L20.397,4.756z"
            />
            <path
              d="M3.601,19.245c-0.444-0.41-0.8-1.097-0.931-1.794c-0.124-0.664-0.031-1.228,0.264-1.547l3.81-4.114
				c0.374-0.407,1.024-0.464,1.501,0.027c0.406,0.376,1.037,0.352,1.412-0.055c0.378-0.405,0.353-1.036-0.053-1.413
				c-1.142-1.131-3.093-1.23-4.329,0.082l-3.807,4.117c-0.741,0.796-1.012,1.96-0.764,3.273c0.211,1.114,0.784,2.195,1.538,2.891
				l1.821,1.686c0.753,0.699,1.874,1.188,3.001,1.312c1.328,0.146,2.468-0.212,3.205-1.014l3.812-4.114
				c1.213-1.334,0.963-3.27-0.253-4.322c-0.408-0.375-1.037-0.351-1.413,0.058c-0.376,0.405-0.352,1.035,0.055,1.411
				c0.527,0.436,0.518,1.091,0.142,1.494l-3.809,4.115c-0.293,0.319-0.85,0.455-1.522,0.382c-0.704-0.077-1.417-0.377-1.86-0.789
				L3.601,19.245z"
            />
            <path
              d="M16.416,7.228c-0.404-0.375-1.037-0.35-1.413,0.055l-7.475,8.07c-0.375,0.406-0.351,1.038,0.055,1.414
				c0.404,0.375,1.037,0.352,1.413-0.054l7.475-8.07C16.846,8.237,16.821,7.603,16.416,7.228z"
            />
          </g>
        );
      case 'arrow-right-8-filled':
        return (
          <path
            d="M19.827,11.624L6.54,0.126c-0.101-0.087-0.232-0.13-0.364-0.121C6.043,0.016,5.92,0.077,5.833,0.179L3.62,2.761
		C3.534,2.861,3.491,2.993,3.501,3.125c0.011,0.133,0.074,0.256,0.175,0.342l10.051,8.535L3.676,20.541
		c-0.102,0.086-0.164,0.209-0.175,0.342c-0.01,0.132,0.032,0.264,0.119,0.364l2.213,2.582c0.087,0.102,0.21,0.163,0.343,0.174
		c0.012,0.001,0.025,0.001,0.037,0.001c0.12,0,0.236-0.043,0.327-0.122L19.827,12.38C19.937,12.285,20,12.148,20,12.002
		C20,11.856,19.937,11.719,19.827,11.624z"
          />
        );
      case 'task-check-1':
        return (
          <g>
            <g>
              <g>
                <polyline
                  points="16,3.5 
					21.5,3.5 21.5,23.5 2.5,23.5 2.5,3.5 8,3.5 				"
                />
                <path
                  d="M14,2.5
					c0-1.104-0.896-2-2-2s-2,0.896-2,2H8v4h8v-4H14z"
                />
                <polyline
                  points="8,5.5 
					4.5,5.5 4.5,21.5 16,21.501 19.5,17.501 19.5,5.5 16,5.5 				"
                />
              </g>
              <polyline
                points="
				15.5,10.217 10.875,16 8.375,13.5 			"
              />
            </g>
            <polyline
              points="
			16,21.501 16,17.001 19.5,17.001 		"
            />
          </g>
        );
      case 'task-check-1-filled':
        return (
          <g>
            <g>
              <path
                d="M10.521,16.354l-2.5-2.5c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0l2.105,2.106l4.276-5.348
				c0.173-0.215,0.488-0.25,0.703-0.077c0.215,0.171,0.25,0.486,0.078,0.702l-4.625,5.783C11.08,16.546,10.729,16.563,10.521,16.354
				z"
              />
            </g>
            <g>
              <path
                d="M16.5,2h-2.05C14.217,0.86,13.208,0,12,0S9.783,0.86,9.55,2H7.5C7.224,2,7,2.223,7,2.5v4C7,6.775,7.224,7,7.5,7h9
				C16.776,7,17,6.775,17,6.5v-4C17,2.223,16.776,2,16.5,2z"
              />
              <g>
                <path
                  d="M21.5,3H18v2h1.5C19.776,5,20,5.223,20,5.5v12.001c0,0.12-0.044,0.237-0.124,0.329l-3.5,4
					c-0.095,0.109-0.233,0.171-0.376,0.171L4.5,22C4.224,22,4,21.775,4,21.5v-16C4,5.223,4.224,5,4.5,5H6V3H2.5
					C2.224,3,2,3.223,2,3.5v20C2,23.775,2.224,24,2.5,24h19c0.276,0,0.5-0.225,0.5-0.5v-20C22,3.223,21.776,3,21.5,3z"
                />
              </g>
              <g>
                <path d="M19.3,17h-2.8c-0.276,0-0.5,0.223-0.5,0.5v3L19.3,17z" />
              </g>
            </g>
          </g>
        );
      case 'checklist':
        return (
          <g>
            <rect x="0.5" y="0.5" width="23" height="23" />
            <polyline
              points="3.5,7.5 
				5.5,9.5 10.5,4.5 			"
            />
            <polyline
              points="
				3.5,16.5 5.5,18.5 10.5,13.5 			"
            />

            <line x1="12.5" y1="8.5" x2="20.5" y2="8.5" />

            <line x1="12.5" y1="17.5" x2="20.5" y2="17.5" />
          </g>
        );
      case 'checklist-filled':
        return (
          <path
            d="M23.5-0.002h-23c-0.276,0-0.5,0.225-0.5,0.5v23c0,0.277,0.224,0.5,0.5,0.5h23c0.276,0,0.5-0.223,0.5-0.5v-23
			C24,0.223,23.776-0.002,23.5-0.002z M3.145,6.647c0.195-0.195,0.512-0.196,0.707-0.001L5.5,8.293l4.646-4.647
			c0.196-0.196,0.512-0.196,0.708-0.001c0.195,0.195,0.195,0.512,0,0.708l-5,5c-0.195,0.195-0.512,0.195-0.707,0l-2.001-2
			C2.95,7.159,2.95,6.842,3.145,6.647z M10.854,13.854l-5,5c-0.195,0.194-0.512,0.195-0.707,0l-2.001-2
			c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.196,0.512-0.197,0.707-0.001l1.647,1.647l4.647-4.646
			c0.196-0.197,0.512-0.197,0.707-0.001C11.05,13.342,11.05,13.659,10.854,13.854z M20.5,17.999h-8c-0.276,0-0.5-0.223-0.5-0.5
			c0-0.275,0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.225,0.5,0.5C21,17.775,20.776,17.999,20.5,17.999z M20.5,8.998h-8
			c-0.276,0-0.5-0.223-0.5-0.5c0-0.275,0.224-0.5,0.5-0.5h8c0.276,0,0.5,0.225,0.5,0.5C21,8.775,20.776,8.998,20.5,8.998z"
          />
        );
      case 'location-pin-user-1':
        return (
          <g>
            <circle cx="11.5" cy="6.013" r="2.5" />
            <path
              d="M15.5,12.513h-8
			c0-2.209,1.79-4,4-4S15.5,10.304,15.5,12.513z"
            />
            <path
              d="M19.5,8.5
			c0-4.418-3.582-8-8-8s-8,3.582-8,8c0,3.558,2.324,6.57,5.536,7.61L11.5,23.5l2.464-7.39C17.176,15.07,19.5,12.058,19.5,8.5z"
            />
          </g>
        );
      case 'location-pin-user-1-filled':
        return (
          <path
            d="M11.5,0.006c-4.963,0-9,4.038-9,9c0,4.125,2.856,7.75,6.832,8.735l1.687,5.902c0.062,0.215,0.258,0.363,0.481,0.363
		        c0.223,0,0.419-0.148,0.481-0.363l1.686-5.902c3.977-0.985,6.833-4.61,6.833-8.735C20.5,4.044,16.462,0.006,11.5,0.006z
		        M15.519,13.019c-0.006,0-0.013,0-0.019,0h-8c-0.276,0-0.5-0.224-0.5-0.5c0-1.84,1.112-3.42,2.697-4.117
		        C8.975,7.853,8.5,6.995,8.5,6.019c0-1.655,1.346-3,3-3s3,1.345,3,3c0,0.976-0.476,1.837-1.2,2.385
		        c1.691,0.743,2.72,2.429,2.72,4.115C16.02,12.796,15.796,13.019,15.519,13.019z"
          />
        );
      case 'box-1':
        return (
          <g>
            <rect x="0.5" y="3.5" width="23" height="5" />

            <rect x="1.5" y="8.5" width="21" height="13" />

            <rect x="8.5" y="11.5" width="7" height="2" />
          </g>
        );
      case 'box-1-filled':
        return (
          <g>
            <path d="M23.5,3h-23C0.224,3,0,3.224,0,3.5v4C0,7.776,0.224,8,0.5,8h23C23.776,8,24,7.776,24,7.5v-4C24,3.224,23.776,3,23.5,3z" />
            <path
              d="M22.5,9h-21C1.224,9,1,9.224,1,9.5v12C1,21.776,1.224,22,1.5,22h21c0.276,0,0.5-0.224,0.5-0.5v-12
				C23,9.224,22.776,9,22.5,9z M16,13.5c0,0.276-0.224,0.5-0.5,0.5h-7C8.224,14,8,13.776,8,13.5v-2C8,11.224,8.224,11,8.5,11h7
				c0.276,0,0.5,0.224,0.5,0.5V13.5z"
            />
          </g>
        );
      case 'folder-share':
        return (
          <g>
            <g>
              <circle cx="21.5" cy="14.5" r="2" />

              <circle cx="21.5" cy="21.5" r="2" />

              <circle cx="13.5" cy="18.499" r="2" />

              <line x1="15.288" y1="17.604" x2="19.713" y2="15.394" />

              <line x1="15.375" y1="19.202" x2="19.629" y2="20.796" />
            </g>
            <g>
              <path
                d="M18.5,4.5v-2h-13
				v-2h-5V16c0,0.829,0.67,1.5,1.5,1.5c0.828,0,1.5-0.671,1.5-1.5V4.5h17v6"
              />

              <line x1="2" y1="17.5" x2="9.5" y2="17.5" />
            </g>
          </g>
        );
      case 'folder-1':
        return (
          <g>
            <path
              d="M3,21.5h17.5
			c1.655,0,3-1.343,3-3v-12h-18V19C5.5,20.381,4.38,21.5,3,21.5c-1.382,0-2.5-1.119-2.5-2.5V2.5h7v2h13v2"
            />
          </g>
        );
      case 'file-picture':
        return (
          <g>
            <polygon
              points="19.5,23.5 
			0.5,23.5 0.5,0.5 13.5,0.5 19.5,6.5 		"
            />
            <polyline
              points="13.5,0.5 
			13.5,6.5 19.5,6.5 		"
            />
            <polygon
              points="13,11 
			10,16.5 8,14.5 5.5,19.5 15.5,19.5 		"
            />

            <circle cx="7" cy="9" r="1.5" />
          </g>
        );
      case 'share-filled':
        return (
          <path
            d="M19.512,15c-1.583,0-2.973,0.819-3.774,2.055l-7.005-3.503c0.371-0.994,0.375-2.098,0-3.104l7.005-3.502
			C16.539,8.182,17.929,9,19.512,9c2.484,0,4.5-2.015,4.5-4.5S21.996,0,19.512,0c-3.11,0-5.307,3.095-4.221,6.051L8.278,9.558
			C7.453,8.28,6.055,7.5,4.512,7.5c-2.481,0-4.5,2.019-4.5,4.5s2.019,4.5,4.5,4.5c1.543,0,2.941-0.779,3.767-2.058l7.013,3.507
			C14.206,20.907,16.402,24,19.512,24c2.484,0,4.5-2.015,4.5-4.5S21.996,15,19.512,15z"
          />
        );
      case 'share':
        return (
          <g>
            <path
              d="M8.074,10.213C7.418,8.902,6.066,8,4.5,8
				c-2.209,0-4,1.791-4,4s1.791,4,4,4c1.566,0,2.918-0.902,3.574-2.213"
            />
            <path
              d="M19.5,8.5c2.209,0,4-1.791,4-4
				s-1.791-4-4-4s-4,1.791-4,4c0,0.643,0.156,1.248,0.425,1.787"
            />
            <path
              d="M15.925,17.713
				C14.598,20.361,16.523,23.5,19.5,23.5c2.209,0,4-1.791,4-4s-1.791-4-4-4"
            />
            <path
              d="M19.5,8.5
				c-1.566,0-2.918-0.902-3.575-2.213l-7.851,3.926C8.344,10.75,8.5,11.356,8.5,12c0,0.643-0.156,1.248-0.426,1.787l7.851,3.926
				c0.657-1.311,2.009-2.213,3.575-2.213"
            />
          </g>
        );
      case 'link-filled':
        return (
          <g>
            <path
              d="M20.397,4.756c0.444,0.409,0.8,1.096,0.931,1.793c0.124,0.665,0.031,1.228-0.264,1.547l-3.81,4.114
				c-0.374,0.407-1.024,0.464-1.501-0.027c-0.406-0.376-1.037-0.353-1.412,0.055c-0.378,0.405-0.352,1.036,0.053,1.413
				c1.142,1.131,3.093,1.229,4.329-0.082l3.807-4.117c0.741-0.797,1.012-1.96,0.764-3.273c-0.211-1.114-0.784-2.195-1.538-2.89
				l-1.821-1.687c-0.753-0.698-1.874-1.187-3.001-1.312c-1.328-0.146-2.468,0.213-3.205,1.015L9.916,5.418
				c-1.213,1.334-0.963,3.27,0.253,4.322c0.408,0.375,1.037,0.351,1.413-0.057c0.376-0.404,0.352-1.035-0.055-1.411
				C11,7.835,11.01,7.181,11.386,6.778l3.809-4.115c0.293-0.319,0.85-0.456,1.521-0.382c0.704,0.076,1.417,0.378,1.86,0.788
				L20.397,4.756z"
            />
            <path
              d="M3.601,19.245c-0.444-0.41-0.8-1.097-0.931-1.794c-0.124-0.664-0.031-1.228,0.264-1.547l3.81-4.114
				c0.374-0.407,1.024-0.464,1.501,0.027c0.406,0.376,1.037,0.352,1.412-0.055c0.378-0.405,0.353-1.036-0.053-1.413
				c-1.142-1.131-3.093-1.23-4.329,0.082l-3.807,4.117c-0.741,0.796-1.012,1.96-0.764,3.273c0.211,1.114,0.784,2.195,1.538,2.891
				l1.821,1.686c0.753,0.699,1.874,1.188,3.001,1.312c1.328,0.146,2.468-0.212,3.205-1.014l3.812-4.114
				c1.213-1.334,0.963-3.27-0.253-4.322c-0.408-0.375-1.037-0.351-1.413,0.058c-0.376,0.405-0.352,1.035,0.055,1.411
				c0.527,0.436,0.518,1.091,0.142,1.494l-3.809,4.115c-0.293,0.319-0.85,0.455-1.522,0.382c-0.704-0.077-1.417-0.377-1.86-0.789
				L3.601,19.245z"
            />
            <path
              d="M16.416,7.228c-0.404-0.375-1.037-0.35-1.413,0.055l-7.475,8.07c-0.375,0.406-0.351,1.038,0.055,1.414
				c0.404,0.375,1.037,0.352,1.413-0.054l7.475-8.07C16.846,8.237,16.821,7.603,16.416,7.228z"
            />
          </g>
        );
      case 'navigation-show-more-2':
        return (
          <g>
            <circle cx="12" cy="3" r="2.5" />

            <circle cx="12" cy="12" r="2.5" />

            <circle cx="12" cy="21" r="2.5" />
          </g>
        );
      case 'lock-close-1':
        return (
          <g>
            <circle
              strokeLinejoin="round"
              strokeMiterlimit="10"
              cx="12"
              cy="15"
              r="0.5"
            />

            <line
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              x1="12"
              y1="15.5"
              x2="12"
              y2="18.5"
            />
            <rect
              x="3.5"
              y="9.5"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              width="17"
              height="14"
            />
            <path
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M6.5,6c0-3.038,2.462-5.5,5.5-5.5
        s5.5,2.462,5.5,5.5v3.5h-11V6z"
            />
          </g>
        );
      case 'lock-close-1-filled':
        return (
          <g id="Filled_Icons">
            <path
              d="M20.5,9H18V6c0-3.309-2.692-6-6-6C8.691,0,6,2.691,6,6v3H3.501c-0.276,0-0.5,0.224-0.5,0.5v14c0,0.276,0.224,0.5,0.5,0.5
      H20.5c0.276,0,0.5-0.224,0.5-0.5v-14C21,9.224,20.776,9,20.5,9z M7,6c0-2.757,2.242-5,5-5c2.757,0,5,2.243,5,5v3H7V6z M12.5,15.86
      v2.64c0,0.275-0.225,0.5-0.5,0.5c-0.276,0-0.5-0.225-0.5-0.5v-2.64C11.202,15.688,11,15.368,11,15c0-0.552,0.448-1,1-1
      c0.551,0,1,0.448,1,1C13,15.368,12.797,15.688,12.5,15.86z"
            />
          </g>
        );
      case 'arrow-up-12':
        return (
          <polyline
            points="
		23.501,18.5 12.001,5.5 0.501,18.5 	"
          />
        );

      case 'upload-box-4':
        return (
          <g>
            <polygon
              points="19,7.501 
				12,0.5 5,7.501 8.5,7.5 8.5,18.5 15.5,18.5 15.5,7.501 			"
            />
            <polyline
              points="
				23.5,18.5 23.5,23.5 0.5,23.5 0.5,18.5 			"
            />
          </g>
        );
      case 'file-pdf-1':
        return (
          <g id="Outline_Icons">
            <polyline points="15.5,0.5 15.5,6.5 21.5,6.5" />
            <g>
              <polygon points="21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5" />
              <path d="M11.5,18.5v-5h1 c1.152,0,2,1.068,2,2.5s-0.848,2.5-2,2.5H11.5z" />
              <polyline points="16.5,18.5 16.5,13.5 19,13.5" />
              <line x1="16.5" y1="15.5" x2="18" y2="15.5" />
              <path d="M6.5,18.499V13.5 h1.25C8.44,13.5,9,14.059,9,14.75C9,15.439,8.44,16,7.75,16H6.5" />
            </g>
          </g>
        );
      case 'file-text':
        return (
          <g>
            <g>
              <polyline points="6.5,13.5 6.5,10.5 16.5,10.5 16.5,13.5" />
              <line x1="11.5" y1="10.5" x2="11.5" y2="20.5" />
              <line x1="8.5" y1="20.5" x2="14.5" y2="20.5" />
            </g>
            <g>
              <polygon points="21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5" />
              <polyline points="15.5,0.5 15.5,6.5 21.5,6.5" />
            </g>
          </g>
        );
      case 'file-new-1':
        return (
          <g>
            <polygon points="20.5,23.5 3.5,23.5 3.5,0.5 14.5,0.5 20.5,6.5" />
            <polyline points="14.5,0.5 14.5,6.5 20.5,6.5" />
          </g>
        );
      case 'file-music-1':
        return (
          <g>
            <polygon points="19.5,23.5 2.5,23.5 2.5,0.5 13.5,0.5 19.5,6.5" />
            <polyline points="13.5,0.5 13.5,6.5 19.5,6.5" />
            <ellipse cx="9.563" cy="17.553" rx="3.001" ry="2" />
            <path d="M12.5,17.5 l0.711-7.757c0,0,3.556-0.231,3.289,2.757" />
          </g>
        );
      case 'file-video-1':
        return (
          <g>
            <polygon points="20.5,23.5 3.5,23.5 3.5,0.5 14.5,0.5 20.5,6.5" />
            <polyline points="14.5,0.5 14.5,6.5 20.5,6.5" />
            <polygon points="8.5,8.5 16.5,13.5 8.5,18.5" />
          </g>
        );
      case 'file-excel-1':
        return (
          <g>
            <g>
              <polygon points="21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5" />
              <polyline points="15.5,0.5 15.5,6.5 21.5,6.5" />
            </g>
            <polyline points="14,18.5 11.5,18.5 11.5,13.5" />
            <path
              d="M16.26,17.258
              c0.041,0.693,0.58,1.242,1.24,1.242c0.686,0,1.24-0.594,1.24-1.327c0-1.08-1.024-1.111-1.241-1.173
              c-0.217-0.064-1.241-0.096-1.241-1.176c0-0.731,0.557-1.324,1.242-1.324c0.658,0,1.197,0.549,1.238,1.24"
            />
            <g>
              <line x1="5.5" y1="13.5" x2="8.5" y2="18.5" />
              <line x1="8.5" y1="13.5" x2="5.5" y2="18.5" />
            </g>
          </g>
        );
      case 'file-words-1':
        return (
          <g>
            <g>
              <polygon points="21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5" />
              <polyline points="15.5,0.5 15.5,6.5 21.5,6.5" />
            </g>
            <path
              d="M18.207,14.738
              c-0.16-0.743-0.521-1.239-1.297-1.239c-1.201,0-1.41,1.036-1.41,2.501c0,1.464,0.209,2.5,1.41,2.5
              c0.775,0,1.137-0.496,1.297-1.241"
            />
            <path d="M13.5,16 c0,1.462-0.252,2.5-1.5,2.5c-1.249,0-1.5-1.038-1.5-2.5c0-1.465,0.251-2.5,1.5-2.5C13.248,13.5,13.5,14.535,13.5,16z" />
            <path d="M5.5,18.5v-5h1 c1.152,0,2,1.068,2,2.5s-0.848,2.5-2,2.5H5.5z" />
          </g>
        );
      case 'lock-open-1':
        return (
          <g>
            <circle cx="12" cy="15" r="0.5" />

            <line x1="12" y1="15.5" x2="12" y2="18.5" />

            <rect x="3.5" y="9.5" width="17" height="14" />
            <path
              d="M6.5,6
          c0-3.038,2.462-5.5,5.5-5.5c3.037,0,5.5,2.462,5.5,5.5v3.5"
            />
          </g>
        );
      case 'arrow-up-10':
        return (
          <g>
            <g id="Outline_Icons">
              <polygon
                points="
             23.501,21.5 0.501,21.5 12.001,2.5 	"
              />
            </g>
          </g>
        );
      case 'arrow-up-10-filled':
        return (
          <path
            d="M23.866,21.139L12.428,2.241c-0.181-0.299-0.675-0.299-0.855,0l-11.5,19c-0.093,0.154-0.097,0.347-0.008,0.504
          C0.153,21.902,0.32,22,0.501,22h23c0.007-0.001,0.013-0.001,0.02,0c0.276,0,0.5-0.224,0.5-0.5
          C24.021,21.358,23.961,21.23,23.866,21.139z"
          />
        );
      case 'network-share':
        return (
          <g>
            <g>
              <circle cx="15" cy="19.5" r="1.5" />

              <circle cx="22" cy="22" r="1.5" />

              <circle cx="22" cy="16" r="1.5" />

              <line x1="16.341" y1="18.829" x2="20.659" y2="16.67" />

              <line x1="16.417" y1="20.006" x2="20.591" y2="21.496" />
            </g>
            <g>
              <line x1="1.898" y1="17.5" x2="12.015" y2="17.5" />
              <line x1="2.512" y1="5.5" x2="21.496" y2="5.5" />

              <line x1="23.5" y1="11.5" x2="0.511" y2="11.5" />
              <path
                d="M11.378,23.484
				C5.315,23.16,0.5,18.144,0.5,12C0.5,5.648,5.648,0.5,12,0.5c6.185,0,11.237,4.879,11.5,11"
              />
              <path
                d="M11.27,0.526
				c-6,6.5-5.892,14.958,0.108,22.958"
              />
              <path
                d="M12.73,0.526
				c3.015,3.266,4.486,7.025,4.445,10.974"
              />
            </g>
          </g>
        );
      case 'account-circle-1':
        return (
          <g>
            <path
              d="M10,15c0,0-1.5-0.5-1.5-3
				c-0.826,0-0.826-2,0-2c0-0.335-1.5-4,1-3.5c0.5-2,6-2,6.5,0c0.347,1.388-0.5,3.254-0.5,3.5c0.826,0,0.826,2,0,2
				c0,2.5-1.5,3-1.5,3v2.5c2.477,0.929,4.93,1.697,6.186,2.575C22.234,17.999,23.5,15.147,23.5,12c0-6.352-5.148-11.5-11.5-11.5
				S0.5,5.648,0.5,12c0,3.153,1.27,6.009,3.325,8.087c1.33-0.92,3.952-1.746,6.175-2.587V15z"
            />
            <path
              d="M3.825,20.087
				C5.91,22.194,8.802,23.5,12,23.5c3.203,0,6.1-1.311,8.186-3.425"
            />
          </g>
        );
      case 'account-up-8-filled':
        return (
          <path
            d="M23.878,17.46L12.376,4.173c-0.19-0.219-0.566-0.219-0.756,0L0.122,17.46c-0.087,0.101-0.13,0.231-0.121,0.364
		c0.01,0.133,0.072,0.256,0.173,0.343l2.582,2.213c0.091,0.078,0.206,0.12,0.325,0.12c0.013,0,0.026-0.001,0.04-0.002
		c0.132-0.01,0.255-0.073,0.342-0.175l8.535-10.051l8.54,10.051c0.086,0.102,0.209,0.165,0.341,0.175
		c0.134,0.007,0.264-0.031,0.365-0.118l2.582-2.213c0.101-0.087,0.164-0.21,0.173-0.343C24.009,17.691,23.966,17.56,23.878,17.46z"
          />
        );
      case 'file-edit-1':
        return (
          <g>
            <g>
              <polygon
                points="16,22.5 
                12.5,23.5 13.5,20 21.002,12.498 23.502,14.998 			"
              />

              <line x1="19.002" y1="14.498" x2="21.502" y2="16.998" />

              <line x1="13.5" y1="20" x2="16" y2="22.5" />
            </g>
            <g>
              <polyline points="9.5,21.5 0.5,21.5 0.5,0.5 11.5,0.5 16.5,5.5 16.5,13 			" />
              <polyline
                points="
                11.5,0.5 11.5,5.5 16.5,5.5 			"
              />
            </g>
          </g>
        );
      case 'file-add-1':
        return (
          <g>
            <g>
              <circle cx="17.5" cy="17.5" r="6" />

              <line x1="15" y1="17.5" x2="20" y2="17.5" />

              <line x1="17.5" y1="20" x2="17.5" y2="15" />
            </g>
            <g>
              <polyline
                points="
              10.5,21.5 0.5,21.5 0.5,0.5 11.5,0.5 16.5,5.5 16.5,9.5 			"
              />
              <polyline
                points="
              11.5,0.5 11.5,5.5 16.5,5.5 			"
              />
            </g>
          </g>
        );
      case 'notepad-share':
        return (
          <g>
            <g>
              <circle cx="21.5" cy="14.501" r="2" />

              <circle cx="21.5" cy="21.501" r="2" />

              <circle cx="13.5" cy="18.5" r="2" />

              <line x1="15.288" y1="17.605" x2="19.713" y2="15.395" />

              <line x1="15.375" y1="19.203" x2="19.629" y2="20.798" />
            </g>
            <g>
              <polyline
                points="
              9.5,20.5 0.5,20.5 0.5,0.5 19.5,0.5 19.5,9.5 			"
              />
              <g>
                <g>
                  <path d="M4,1.5V3V1.5 M5,0.5H3V4h2V0.5L5,0.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M8,1.5V3V1.5 M9,0.5H7V4h2V0.5L9,0.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M16,1.5V3V1.5 M17,0.5h-2V4h2V0.5L17,0.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="M12,1.5V3V1.5 M13,0.5h-2V4h2V0.5L13,0.5z" />
                </g>
              </g>
            </g>
          </g>
        );
      case 'volume-off-1-filled':
        return (
          <g>
            <path d="M2,15h3.172L13,7.173V1.5c0-0.446-0.542-0.668-0.854-0.354L5.293,8H2c-1.102,0-2,0.898-2,2v3C0,14.104,0.897,15,2,15z" />
            <path d="M12.146,21.854C12.458,22.166,13,21.947,13,21.5v-8.672l-4.939,4.941L12.146,21.854z" />
            <path
              d="M16.322,9.506c1.107,1.519,0.952,3.586-1.115,5.09c-0.225,0.162-0.273,0.475-0.111,0.698
			c0.165,0.227,0.48,0.271,0.697,0.111c2.494-1.81,2.825-4.552,1.242-6.611L16.322,9.506z"
            />
            <path
              d="M22.207,0.793c-0.377-0.37-0.979-0.41-1.414,0l-20,20c-0.435,0.435-0.35,1.136,0.016,1.427
			c0.38,0.364,1.025,0.361,1.398-0.013l20-20C22.606,1.809,22.583,1.169,22.207,0.793z"
            />
          </g>
        );
      case 'arrow-up-down':
        return (
          <g>
            <g
              transform="matrix(0.90000209,0,0,0.90000209,1.1990749,-0.5119095)"
              id="Outline_Icons"
            >
              <polygon points="23.501,21.5 0.501,21.5 12.001,2.5 " />
            </g>
            <g
              id="Outline_Icons-3"
              transform="matrix(0.90000209,0,0,-0.90000209,1.1990749,42.545785)"
            >
              <polygon points="23.501,21.5 0.501,21.5 12.001,2.5 " />
            </g>
          </g>
        );
      case 'alarm-sound-filled':
        return (
          <g>
            <path
              d="M20.5,19c-0.827,0-1.5-0.673-1.5-1.5V14c0-2.726-1.567-5.18-4.018-6.333C14.815,6.169,13.542,5,12,5
				S9.185,6.169,9.018,7.666C6.567,8.819,5,11.271,5,14v3.5C5,18.327,4.327,19,3.5,19C3.224,19,3,19.224,3,19.5S3.224,20,3.5,20h17
				c0.276,0,0.5-0.224,0.5-0.5S20.776,19,20.5,19z"
            />
            <path d="M14,21h-4c-0.276,0-0.5,0.224-0.5,0.5c0,1.379,1.122,2.5,2.5,2.5s2.5-1.121,2.5-2.5C14.5,21.224,14.276,21,14,21z" />
            <path
              d="M9,3C5.691,3,3,5.691,3,9c0,0.276,0.224,0.5,0.5,0.5S4,9.276,4,9c0-2.757,2.243-5,5-5c0.276,0,0.5-0.224,0.5-0.5
				S9.276,3,9,3z"
            />
            <path
              d="M9,1c0.276,0,0.5-0.224,0.5-0.5S9.276,0,9,0C4.037,0,0,4.037,0,9c0,0.276,0.224,0.5,0.5,0.5S1,9.276,1,9
				C1,4.589,4.589,1,9,1z"
            />
            <path
              d="M15,3c-0.276,0-0.5,0.224-0.5,0.5S14.724,4,15,4c2.757,0,5,2.243,5,5c0,0.276,0.224,0.5,0.5,0.5S21,9.276,21,9
				C21,5.691,18.308,3,15,3z"
            />
            <path
              d="M15,0c-0.276,0-0.5,0.224-0.5,0.5S14.724,1,15,1c4.411,0,8,3.589,8,8c0,0.276,0.224,0.5,0.5,0.5S24,9.276,24,9
				C24,4.037,19.962,0,15,0z"
            />
          </g>
        );
      case 'bubble-chat-text-1':
        return (
          <g>
            <polygon
              points="
				23.5,17.482 11.5,17.482 7.5,21.482 7.5,17.482 0.5,17.482 0.5,1.482 23.5,1.482 			"
            />

            <line x1="5.5" y1="6.482" x2="14.5" y2="6.482" />

            <line x1="5.5" y1="9.482" x2="18.5" y2="9.482" />

            <line x1="5.5" y1="12.482" x2="18.5" y2="12.482" />
          </g>
        );
      case 'alarm-silent-filled':
        return (
          <g>
            <path
              d="M3.5,17H5L16.63,5.249c-0.488-0.431-1.041-0.796-1.648-1.082C14.815,2.669,13.542,1.5,12,1.5S9.185,2.669,9.018,4.166
				C6.567,5.319,5,7.772,5,10.5v4C5,15.327,4.327,16,3.5,16C3.224,16,3,16.224,3,16.5S3.224,17,3.5,17z"
            />
            <path
              d="M20.5,16c-0.827,0-1.5-0.673-1.5-1.5v-4c0-0.984-0.206-1.932-0.583-2.796L9,17h11.5c0.276,0,0.5-0.224,0.5-0.5
				S20.776,16,20.5,16z"
            />
            <path d="M14,18h-4c-0.276,0-0.5,0.224-0.5,0.5c0,1.379,1.122,2.5,2.5,2.5s2.5-1.121,2.5-2.5C14.5,18.224,14.276,18,14,18z" />
            <path
              d="M23.854,0.147c-0.194-0.194-0.511-0.196-0.707,0l-23,23c-0.196,0.196-0.194,0.513,0,0.707
				c0.001,0.002,0.004,0.002,0.005,0.004c0.191,0.184,0.505,0.184,0.696,0c0.002-0.002,0.004-0.002,0.005-0.004l23-23
				C24.049,0.659,24.05,0.342,23.854,0.147z"
            />
          </g>
        );
      case 'file-music-3':
        return (
          <g>
            <path
              d="M9,12.5H7.25
			c-0.414,0-0.75,0.336-0.75,0.75v1.5c0,0.414,0.336,0.75,0.75,0.75H9l3.5,3.5V9L9,12.5z"
            />
            <path
              d="M16,11
			c0.834,0.688,1.5,1.835,1.5,3c0,1.163-0.669,2.312-1.5,3"
            />
            <path
              d="M14,12.25
			c0.562,0.409,1,1.001,1,1.75c0,0.749-0.438,1.341-1,1.75"
            />
            <g>
              <polygon
                points="
				21.5,23.5 2.5,23.5 2.5,0.5 15.5,0.5 21.5,6.5 			"
              />
              <polyline
                points="
				15.5,0.5 15.5,6.5 21.5,6.5 			"
              />
            </g>
          </g>
        );
      case 'bubble-chat-view-1':
        return (
          <g>
            <g>
              <circle cx="17" cy="16" r="4" />

              <line x1="19.828" y1="18.828" x2="23" y2="22" />
            </g>
            <g>
              <polyline
                points="
					11,14.5 8.5,14.5 4.5,18.5 4.5,14.5 0.5,14.5 0.5,0.5 21.5,0.5 21.5,10.5 				"
              />
            </g>
          </g>
        );
      case 'mobile-phone-text-message-1':
        return (
          <g>
            <path
              d="M11.5,14.5v7
				c0,1.104-0.896,2-2,2h-7c-1.104,0-2-0.896-2-2v-15c0-1.104,0.896-2,2-2h3"
            />

            <line x1="5.5" y1="8.5" x2="0.5" y2="8.5" />

            <line x1="11.5" y1="19.5" x2="0.5" y2="19.5" />

            <line x1="4.5" y1="6.5" x2="5.5" y2="6.5" />
            <path
              d="M23.5,8
				c0,0.828-0.672,1.5-1.5,1.5h-5.5l-3,3v-3H10C9.172,9.5,8.5,8.828,8.5,8V2c0-0.828,0.672-1.5,1.5-1.5h12
				c0.828,0,1.5,0.672,1.5,1.5V8z"
            />
            <g>
              <g>
                <g>
                  <path d="M16.5,4C16.224,4,16,4.225,16,4.5C16,4.776,16.224,5,16.5,5S17,4.776,17,4.5C17,4.225,16.776,4,16.5,4L16.5,4z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path d="M19.5,4C19.224,4,19,4.225,19,4.5C19,4.776,19.224,5,19.5,5S20,4.776,20,4.5C20,4.225,19.776,4,19.5,4L19.5,4z" />
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    d="M13.531,4c-0.276,0-0.5,0.225-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5C14.031,4.225,13.808,4,13.531,4
							L13.531,4z"
                  />
                </g>
              </g>
            </g>
            <path
              d="M6.5,21.5C6.5,21.776,6.276,22,6,22l0,0
				c-0.276,0-0.5-0.224-0.5-0.5l0,0C5.5,21.224,5.724,21,6,21l0,0C6.276,21,6.5,21.224,6.5,21.5L6.5,21.5z"
            />
          </g>
        );
      case 'mobile-phone-text-message-1-filled':
        return (
          <g>
            <path d="M7.175,9H0v10h12v-8h-2C8.697,11,7.588,10.163,7.175,9z" />
            <path
              d="M0,21.5C0,22.878,1.122,24,2.5,24h7c1.378,0,2.5-1.122,2.5-2.5V20H0V21.5z M6,20.5c0.552,0,1,0.448,1,1
				c0,0.553-0.448,1-1,1s-1-0.447-1-1C5,20.948,5.448,20.5,6,20.5z"
            />
            <path
              d="M7,4H2.5C1.122,4,0,5.122,0,6.5V8h7V4z M5.5,7h-1C4.224,7,4,6.776,4,6.5C4,6.224,4.224,6,4.5,6h1C5.776,6,6,6.224,6,6.5
				C6,6.776,5.776,7,5.5,7z"
            />
            <path
              d="M22,0H10C8.898,0,8,0.898,8,2v6c0,1.103,0.898,2,2,2h3v2.5c0,0.445,0.54,0.667,0.854,0.354L16.707,10H22
				c1.103,0,2-0.897,2-2V2C24,0.898,23.103,0,22,0z M13.5,5.5C13.224,5.5,13,5.276,13,5c0-0.275,0.224-0.5,0.5-0.5S14,4.725,14,5
				C14,5.276,13.776,5.5,13.5,5.5z M16,5.5c-0.276,0-0.5-0.224-0.5-0.5c0-0.275,0.224-0.5,0.5-0.5s0.5,0.225,0.5,0.5
				C16.5,5.276,16.276,5.5,16,5.5z M18.5,5.5C18.224,5.5,18,5.276,18,5c0-0.275,0.224-0.5,0.5-0.5S19,4.725,19,5
				C19,5.276,18.776,5.5,18.5,5.5z"
            />
          </g>
        );
      case 'bubble-chat-1-filled':
        return (
          <path
            d="M23.5,1h-23C0.224,1,0,1.224,0,1.5v16C0,17.776,0.224,18,0.5,18H7v3.5c0,0.444,0.54,0.668,0.853,0.354L11.707,18H23.5
			c0.276,0,0.5-0.224,0.5-0.5v-16C24,1.224,23.776,1,23.5,1z"
          />
        );
      case 'id-card-4':
        return (
          <g>
            <path
              d="M0.5,3.5v17h4V19
				c0-0.962,0.787-1.75,1.75-1.75C7.212,17.25,8,18.038,8,19v1.5h8V19c0-0.962,0.787-1.75,1.75-1.75c0.962,0,1.75,0.788,1.75,1.75
				v1.5h4v-17H0.5z"
            />
            <line x1="13" y1="13.5" x2="21" y2="13.5" />
            <line x1="13" y1="11.5" x2="21" y2="11.5" />
            <line x1="13" y1="9.5" x2="21" y2="9.5" />
            <line x1="13" y1="7.5" x2="17" y2="7.5" />
            <path
              d="M7,14.5H2.5v-2
				C2.5,12.5,4,11,6,11c0-0.5,0-1,0-1S5,9.5,5,8c0-1,0-2,2-2s2,1,2,2c0,1.5-1,2-1,2s0,0,0,1c2,0,3.5,1.5,3.5,1.5v2H7z"
            />
          </g>
        );
      case 'mobile-phone-4-filled':
        return (
          <path
            d="M14.5,0h-6C6.019,0,4,2.019,4,4.5v16C4,22.43,5.57,24,7.5,24h8c1.93,0,3.5-1.57,3.5-3.5v-16C19,2.019,16.981,0,14.5,0z
			 M9.5,2h4C13.776,2,14,2.224,14,2.5C14,2.776,13.776,3,13.5,3h-4C9.224,3,9,2.776,9,2.5C9,2.224,9.224,2,9.5,2z M9,21H8v-1h1V21z
			 M9,19H8v-1h1V19z M9,17H8v-1h1V17z M12,21h-1v-1h1V21z M12,19h-1v-1h1V19z M12,17h-1v-1h1V17z M15,21h-1v-1h1V21z M15,19h-1v-1h1
			V19z M15,17h-1v-1h1V17z M17,13.5c0,0.276-0.224,0.5-0.5,0.5h-10C6.224,14,6,13.776,6,13.5v-9C6,4.224,6.224,4,6.5,4h10
			C16.776,4,17,4.224,17,4.5V13.5z"
          />
        );
      case 'door-enter':
        return (
          <g>
            <polyline
              points="9.5,2.5 
              17.5,2.5 17.5,5 			"
            />
            <polyline
              points="17.5,20 
              17.5,21.5 9.5,21.5 			"
            />
            <polygon
              points="0.5,21.5 
              9.5,23.5 9.5,0.5 0.5,2.5 			"
            />

            <circle cx="6" cy="12.5" r="1.5" />

            <line x1="23.5" y1="12.508" x2="12" y2="12.508" />
            <polyline
              points="16,8.5 
              12,12.5 16,16.5 			"
            />
          </g>
        );
      case 'calendar-view-2':
        return (
          <g>
            <polyline
              points="
				20.5,11.5 20.5,2.5 16.5,2.5 			"
            />
            <polyline
              points="4.5,2.5 
				0.5,2.5 0.5,18.5 10.5,18.5 			"
            />

            <rect x="4.5" y="0.5" width="3" height="4" />

            <rect x="13.5" y="0.5" width="3" height="4" />

            <line x1="7.5" y1="2.5" x2="13.5" y2="2.5" />

            <line x1="0.5" y1="7.5" x2="20.5" y2="7.5" />
            <circle cx="17" cy="17" r="4" />

            <line x1="19.828" y1="19.828" x2="23" y2="23" />
          </g>
        );
      case 'bubble-chat-share-2':
        return (
          <g>
            <g>
              <circle cx="21.5" cy="14.5" r="2" />
              <circle cx="21.5" cy="21.5" r="2" />
              <circle cx="13.5" cy="18.5" r="2" />
              <line x1="15.288" y1="17.605" x2="19.713" y2="15.393" />
              <line x1="15.375" y1="19.203" x2="19.629" y2="20.797" />
            </g>
            <g>
              <path d="M9.5,17.436 C8.614,17.361,7.718,17.219,7,17l-5.5,2.5l2-4.5c-1.929-1.543-3-3.623-3-6.002C0.5,4.305,5.164,0.5,10.917,0.5 C16.672,0.5,21.5,4.305,21.5,9c0,0.338-0.024,0.672-0.073,1.002" />
            </g>
          </g>
        );
      case 'camera-record':
        return (
          <g>
            <circle cx="17" cy="18" r="5.5" />
            <circle cx="17" cy="18" r="2.5" />
            <path d="M10.999,13.5H1.5 c-0.55,0-1-0.45-1-1v-11c0-0.55,0.45-1,1-1h13c0.55,0,1,0.45,1,1v8.462" />
            <polygon points="23.5,12.5 17.5,9.5 17.5,4.5 23.5,1.5 		" />
          </g>
        );
      case 'group-view':
        return (
          <g>
            <g>
              <circle cx="18" cy="18" r="3.5" />
              <line x1="23.5" y1="23.5" x2="20.474" y2="20.475" />
            </g>
            <g>
              <polyline points="13.941,13.779 10,12.367 10,10.322" />
              <path d="M6,10.323v2.044 l-3.941,1.412C1.115,14.117,0.5,15.012,0.5,16.012V17.5H11" />
              <path
                d="M11.947,6.236
                c0,2.618-1.768,4.741-3.947,4.741c-2.181,0-3.947-2.123-3.947-4.741C4.053,3.621,5.819,1.5,8,1.5
                C10.18,1.5,11.947,3.621,11.947,6.236z"
              />
              <path
                d="M11.916,5.811
                c-0.132,0.018-0.241,0.006-0.374,0.033c-1.345,0.258-2.208-0.229-2.949-1.525C8.149,5.17,6.762,5.844,5.621,5.844
                c-0.562,0-1.045-0.115-1.528-0.369"
              />
              <path d="M14.5,1.5 c2.18,0,3.947,2.121,3.947,4.736c0,2.618-1.768,4.741-3.947,4.741" />
              <path d="M18.416,5.811 c-0.132,0.018-0.241,0.006-0.374,0.033c-1.345,0.258-2.208-0.229-2.949-1.525" />
              <line x1="16.5" y1="12" x2="16.5" y2="10.322" />
            </g>
          </g>
        );
      case 'book-contact-1':
        return (
          <g>
            <polyline points="5.5,2.5 17.5,2.5 17.5,4.5" />
            <path d="M19.5,4.5v-4h-14 c-1.104,0-2,0.895-2,2c0,1.104,0.896,2,2,2h15v19h-15c-1.104,0-2-0.896-2-2v-19" />
            <g>
              <circle cx="12" cy="11" r="2.5" />
              <path d="M12,13.5c-1.657,0-4.5,1.134-4.5,5h9 C16.5,14.634,13.656,13.5,12,13.5z" />
            </g>
          </g>
        );
      case 'link-2':
        return (
          <g>
            <path
              d="M14.5,12.5
              l0.086,0.086c0.781,0.781,2.047,0.781,2.828,0l3.965-3.964c1.166-1.167,1.166-3.075,0-4.243l-1.758-1.757
              c-1.166-1.167-3.076-1.167-4.242,0l-3.965,3.964c-0.781,0.781-0.781,2.047,0,2.829L11.5,9.5"
            />
            <path
              d="M9.5,11.5
              l-0.086-0.085c-0.781-0.781-2.047-0.781-2.828,0l-3.965,3.964c-1.166,1.167-1.166,3.076,0,4.243l1.758,1.757
              c1.166,1.167,3.076,1.167,4.242,0l3.965-3.964c0.781-0.781,0.781-2.047,0-2.829L12.5,14.5"
            />

            <line x1="8.111" y1="15.889" x2="15.889" y2="8.111" />
          </g>
        );
      default:
        return null;
    }
  };

  const isFilled = (iconName) => {
    if (iconName.substring(iconName.length - 7) === '-filled') return true;
    return false;
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={isFilled(name) ? color : 'none'}
      stroke={isFilled(name) ? 'none' : color}
      strokeWidth={strokeWidth || 1}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      xmlSpace="preserve"
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      {glyph(name)}
    </svg>
  );
};

Svg.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  color: PropTypes.string,
};

Svg.defaultProps = {
  width: 24,
  height: 24,
  strokeWidth: 1.2,
  color: 'currentcolor',
};

export default Svg;
