import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';
import { getWebNotificationsPbxVboxNumber } from '../../../js/notifications/selectors';

const messages = defineMessages({
  title: {
    id: 'PbxVbox.title',
    defaultMessage: 'Switchboard vocal box',
  },
  list: {
    id: 'PbxVbox.list.navLabel',
    defaultMessage: 'Received messages',
  },
  notifications: {
    id: 'PbxVbox.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
});

const PbxVbox = ({
  intl: { formatMessage },
  children,
  notificationsQuantity,
}) => {
  const nav = [
    {
      id: 'PbxVbox.list.linkLabel',
      href: '/pbx/vbox/list',
      label: messages.list,
    },
    {
      id: 'PbxVbox.notifications.linkLabel',
      href: '/pbx/vbox/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    },
  ];
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div className="yn-section-with-nav-scrolling">{children}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsPbxVboxNumber(state),
  };
}
export default injectIntl(connect(mapStateToProps)(PbxVbox));
