import {
  FETCH_VBOX_MESSAGES_REQUEST,
  FETCH_VBOX_MESSAGES_SUCCESS,
  FETCH_VBOX_MESSAGES_FAILURE,
  SET_VBOX_MESSAGES_FILTER,
  DELETE_VBOX_MESSAGES_REQUEST,
  DELETE_VBOX_MESSAGES_SUCCESS,
  DELETE_VBOX_MESSAGES_FAILURE,
  FETCH_TVOX_SOUNDS_REQUEST,
  FETCH_TVOX_SOUNDS_SUCCESS,
  FETCH_TVOX_SOUNDS_FAILURE,
  FETCH_TVOX_PRICE_REQUEST,
  FETCH_TVOX_PRICE_SUCCESS,
  FETCH_TVOX_PRICE_FAILURE,
  PURCHASE_TVOX_MESSAGE_REQUEST,
  PURCHASE_TVOX_MESSAGE_SUCCESS,
  PURCHASE_TVOX_MESSAGE_FAILURE,
  FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST,
  FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS,
  FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE,
  LOAD_OWN_GREETING_REQUEST,
  LOAD_OWN_GREETING_SUCCESS,
  LOAD_OWN_GREETING_FAILURE,
  SAVE_OWN_GREETING_REQUEST,
  SAVE_OWN_GREETING_SUCCESS,
  SAVE_OWN_GREETING_FAILURE,
  FETCH_ACTIVE_GREETING_MESSAGES_REQUEST,
  FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS,
  FETCH_ACTIVE_GREETING_MESSAGES_FAILURE,
  ACTIVATE_PBX_GREETING_REQUEST,
  ACTIVATE_PBX_GREETING_SUCCESS,
  ACTIVATE_PBX_GREETING_FAILURE,
  DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST,
  DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS,
  DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE,
  SAVE_PBX_GREETING_REQUEST,
  SAVE_PBX_GREETING_SUCCESS,
  SAVE_PBX_GREETING_FAILURE,
  ME_RECEIVED_VBOX_NOTIFICATION,
  PBX_RECEIVED_VBOX_NOTIFICATION,
  DELIVERED_TVOX_NOTIFICATION,
  LISTEN_VBOX_REQUEST,
  LISTEN_VBOX_SUCCESS,
  LISTEN_VBOX_FAILURE,
} from './types';

export const fetchVboxMessagesRequest = (params) => ({
  type: FETCH_VBOX_MESSAGES_REQUEST,
  payload: params,
});
export const fetchVboxMessagesSuccess = (payload) => ({
  type: FETCH_VBOX_MESSAGES_SUCCESS,
  payload,
});
export const fetchVboxMessagesFailure = (errors, isPbx) => ({
  type: FETCH_VBOX_MESSAGES_FAILURE,
  errors,
  isPbx,
});

export const setVboxMessagesFilter = (isPbx, filter) => ({
  type: SET_VBOX_MESSAGES_FILTER,
  isPbx,
  filter,
});

export const deleteVboxMessagesRequest = (payload) => ({
  type: DELETE_VBOX_MESSAGES_REQUEST,
  payload,
});
export const deleteVboxMessagesSuccess = (payload) => ({
  type: DELETE_VBOX_MESSAGES_SUCCESS,
  payload,
});
export const deleteVboxMessagesFailure = (errors, isPbx) => ({
  type: DELETE_VBOX_MESSAGES_FAILURE,
  errors,
  isPbx,
});

export const fetchTvoxSoundsRequest = () => ({
  type: FETCH_TVOX_SOUNDS_REQUEST,
});
export const fetchTvoxSoundsSuccess = (payload) => ({
  type: FETCH_TVOX_SOUNDS_SUCCESS,
  payload,
});
export const fetchTvoxSoundsFailure = (errors) => ({
  type: FETCH_TVOX_SOUNDS_FAILURE,
  errors,
});

export const fetchTvoxPriceRequest = (payload) => ({
  type: FETCH_TVOX_PRICE_REQUEST,
  payload,
});
export const fetchTvoxPriceSuccess = (payload, success) => ({
  type: FETCH_TVOX_PRICE_SUCCESS,
  payload,
  success,
});
export const fetchTvoxPriceFailure = (errors) => ({
  type: FETCH_TVOX_PRICE_FAILURE,
  errors,
});

export const purchaseTvoxMessageRequest = (payload) => ({
  type: PURCHASE_TVOX_MESSAGE_REQUEST,
  payload,
});
export const purchaseTvoxMessageSuccess = (payload, success) => ({
  type: PURCHASE_TVOX_MESSAGE_SUCCESS,
  payload,
  success,
});
export const purchaseTvoxMessageFailure = (errors) => ({
  type: PURCHASE_TVOX_MESSAGE_FAILURE,
  errors,
});
export const fetchArchivedGreetingMessagesRequest = (payload) => ({
  type: FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST,
  payload,
});
export const fetchArchivedGreetingMessagesSuccess = (payload) => ({
  type: FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS,
  payload,
});
export const fetchArchivedGreetingMessagesFailure = (errors) => ({
  type: FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE,
  errors,
});
export const loadOwnGreetingRequest = () => ({
  type: LOAD_OWN_GREETING_REQUEST,
});
export const loadOwnGreetingSuccess = (greeting) => ({
  type: LOAD_OWN_GREETING_SUCCESS,
  greeting,
});
export const loadOwnGreetingFailure = () => ({
  type: LOAD_OWN_GREETING_FAILURE,
});
export const saveOwnGreetingRequest = (greeting) => ({
  type: SAVE_OWN_GREETING_REQUEST,
  greeting,
});
export const saveOwnGreetingSuccess = (greeting) => ({
  type: SAVE_OWN_GREETING_SUCCESS,
  greeting,
});
export const saveOwnGreetingFailure = () => ({
  type: SAVE_OWN_GREETING_FAILURE,
});
export const fetchActiveGreetingMessagesRequest = () => ({
  type: FETCH_ACTIVE_GREETING_MESSAGES_REQUEST,
});
export const fetchActiveGreetingMessagesSuccess = (payload) => ({
  type: FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS,
  payload,
});
export const fetchActiveGreetingMessagesFailure = (error) => ({
  type: FETCH_ACTIVE_GREETING_MESSAGES_FAILURE,
  error,
});
export const activatePbxGreetingRequest = (greeting) => ({
  type: ACTIVATE_PBX_GREETING_REQUEST,
  greeting,
});
export const activatePbxGreetingSuccess = () => ({
  type: ACTIVATE_PBX_GREETING_SUCCESS,
});
export const activatePbxGreetingFailure = (error) => ({
  type: ACTIVATE_PBX_GREETING_FAILURE,
  error,
});

export const deleteArchivedGreetingMessageRequest = (payload) => ({
  type: DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST,
  payload,
});
export const deleteArchivedGreetingMessageSuccess = (payload) => ({
  type: DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS,
  payload,
});
export const deleteArchivedGreetingMessageFailure = (error) => ({
  type: DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE,
  error,
});
export const savePbxGreetingRequest = (greeting) => ({
  type: SAVE_PBX_GREETING_REQUEST,
  greeting,
});
export const savePbxGreetingSuccess = (greeting) => ({
  type: SAVE_PBX_GREETING_SUCCESS,
  greeting,
});
export const savePbxGreetingFailure = (error) => ({
  type: SAVE_PBX_GREETING_FAILURE,
  error,
});
export const meReceivedVboxNotification = (payload) => ({
  type: ME_RECEIVED_VBOX_NOTIFICATION,
  payload,
});
export const pbxReceivedVboxNotification = (payload) => ({
  type: PBX_RECEIVED_VBOX_NOTIFICATION,
  payload,
});
export const deliveredTvoxNotification = (payload) => ({
  type: DELIVERED_TVOX_NOTIFICATION,
  payload,
});
export const listenVboxRequest = (vboxId, isPbx) => ({
  type: LISTEN_VBOX_REQUEST,
  vboxId,
  isPbx,
});
export const listenVboxSuccess = (vboxId, isPbx) => ({
  type: LISTEN_VBOX_SUCCESS,
  vboxId,
  isPbx,
});
export const listenVboxFailure = (errors) => ({
  type: LISTEN_VBOX_FAILURE,
  errors,
});
