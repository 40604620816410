import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Button from './formControls/Button';
import UsersSelect from './formControls/UsersSelect';
import GroupsSelect from './formControls/GroupsSelect';
import Label from './formControls/Label';
import { generateAckUid } from '../js/websocket/WsUtils';
import {
  sendChatMessageRequest,
  openChatConversation,
} from '../js/chat/actions';
import { getChatMessageById, getChatMessagesById } from '../js/chat/selectors';
import { generateConversationId } from '../js/chat/ChatUtils';
import ToastMessage from './messages/ToastMessage';
import { getUsers } from '../js/users/selectors';
import { getCustomerId, getMeId } from '../js/me/selectors';
import { enableDisableFileboxFileExternalLinkRequest } from '../js/filebox/actions';
import {
  getEnableDisableFileExternalLinkError,
  getFileboxItemById,
} from '../js/filebox/selectors';
import { generateFileExternalLink } from '../js/filebox/FileboxUtils';
import ynConf from '../conf';

const messages = defineMessages({
  users: {
    id: 'SelectMessageRecipientsBody.label.users',
    defaultMessage: 'User',
  },
  groups: {
    id: 'SelectMessageRecipientsBody.label.groups',
    defaultMessage: 'Group',
  },
  forward: {
    id: 'SelectMessageRecipientsBody.label.forward',
    defaultMessage: 'Forward',
  },
  close: {
    id: 'SelectMessageRecipientsBody.label.close',
    defaultMessage: 'Close',
  },
  sendError: {
    id: 'SelectMessageRecipientsBody.label.sendError',
    defaultMessage: 'Error sending message',
  },
  misingReceiver: {
    id: 'SelectMessageRecipientsBody.label.misingReceiver',
    defaultMessage: 'You must select a receiver',
  },
  share: {
    id: 'SelectMessageRecipientsBody.share',
    defaultMessage: 'Share',
  },
  invite: {
    id: 'SelectMessageRecipientsBody.invite',
    defaultMessage: 'Invite',
  },
  enableDisableFileboxFileError: {
    id: 'SelectMessageRecipientsBody.enableDisableFileboxFileError',
    defaultMessage:
      "Sorry, the changes haven't been saved due to a server error.",
  },
  fileboxFileShareMessage: {
    id: 'SelectMessageRecipientsBody.fileboxFileShareMessage',
    defaultMessage: 'Hey, look at this file:  ',
  },
  videocallInviteMessage: {
    id: 'SelectMessageRecipientsBody.videocallInviteMessage',
    defaultMessage: 'This is the videocall room:  ',
  },
});

class SelectMessageRecipientsBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: null,
      selectedGroup: null,
      errors: {},
    };
  }

  componentDidUpdate(prevprops) {
    const { chatMessagesById, onClose, file, sharingFileboxFileError } =
      this.props;
    if (
      chatMessagesById !== prevprops.chatMessagesById &&
      !sharingFileboxFileError
    ) {
      onClose();
      this.openConversation();
    }
    if (!prevprops.file.publicUrl && file.publicUrl) {
      this.sendMessage();
    }
  }

  handleUsersSelection = (selected) => {
    this.setState({
      selectedUser: selected,
    });
    const errors = {
      receiver: null,
    };
    delete errors.receiver;
    this.setState({ errors });
  };

  handleGroupsSelection = (selected) => {
    this.setState({
      selectedGroup: selected,
    });
    const errors = {
      receiver: null,
    };
    delete errors.receiver;
    this.setState({ errors });
  };

  openConversation = () => {
    const { selectedUser, selectedGroup } = this.state;
    const { openConversation } = this.props;
    openConversation({
      conversationId: generateConversationId({
        userId: selectedUser,
        groupId: selectedGroup,
      }),
    });
  };

  sendMessage = () => {
    const { selectedUser, selectedGroup } = this.state;
    const {
      message,
      selectedMessage,
      sendMessage,
      fileboxFileId,
      videocallRoom,
      myId,
      file,
      enableDisableFileLink,
      idCustomer,
      intl: { formatMessage },
    } = this.props;

    const ackUid =
      fileboxFileId || videocallRoom
        ? generateAckUid(myId)
        : generateAckUid(message.senderId);

    const arrangeMessage = fileboxFileId
      ? {
          text: `${formatMessage(
            messages.fileboxFileShareMessage
          )}  ${generateFileExternalLink(idCustomer, file.publicUrl)}`,
        }
      : videocallRoom
      ? {
          text: `${formatMessage(messages.videocallInviteMessage)} ${
            ynConf.clientBaseUrl
          }/videocall/exec/${ynConf.jitsi.appId}/${videocallRoom}`,
        }
      : selectedMessage && selectedMessage.file
      ? {
          file: {
            ...selectedMessage.file,
          },
          forwarded: true,
        }
      : {
          text: selectedMessage.text,
          forwarded: true,
        };

    const data = {
      ackUid,
      recipient: {
        type: selectedUser ? 'USER' : 'GROUP',
        id: selectedUser || selectedGroup,
      },
      message: arrangeMessage,
    };

    const errors = this.validate(this.state);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      if (fileboxFileId) {
        if (!file.publicUrl) {
          const enableDisableData = {
            id: fileboxFileId,
            data: {
              enabled: true,
            },
          };
          enableDisableFileLink(enableDisableData);
        } else {
          sendMessage(data);
        }
      } else {
        sendMessage(data);
      }
    }
  };

  validate = (data) => {
    const errors = {};
    const { intl } = this.props;
    if (!data.selectedGroup && !data.selectedUser) {
      errors.receiver = intl.formatMessage(messages.misingReceiver);
    }
    return errors;
  };

  render() {
    const {
      selectedMessage,
      myId,
      fileboxFileId,
      videocallRoom,
      sharingFileboxFileError,
      onClose,
      intl: { formatMessage },
    } = this.props;
    const { selectedUser, selectedGroup, errors } = this.state;

    const blockedUsers = this.props.users
      .filter((user) => user.blocked)
      .map((blockedUser) => blockedUser.id);
    const excludedUsers = [...blockedUsers, myId];

    return (
      <>
        <div className="form-row">
          <div className="col-12 my-2">
            {selectedMessage.errorSending && (
              <ToastMessage
                type="danger"
                closeTimeout={2000}
                text={formatMessage(messages.sendError)}
              />
            )}
            {sharingFileboxFileError && (
              <ToastMessage
                type="danger"
                closeTimeout={2000}
                text={formatMessage(messages.enableDisableFileboxFileError)}
              />
            )}
            <Label for="users">{formatMessage(messages.users)}</Label>
            <UsersSelect
              id="users"
              isClearable
              onSelect={this.handleUsersSelection}
              selected={selectedUser}
              isDisabled={selectedGroup !== null}
              excluded={excludedUsers}
              error={errors.receiver}
            />
          </div>
          <div className="col-12 my-2">
            <Label for="groups">{formatMessage(messages.groups)}</Label>
            <GroupsSelect
              name="groups"
              id="groups"
              isClearable
              onSelect={this.handleGroupsSelection}
              selected={selectedGroup}
              isMulti={false}
              isDisabled={selectedUser !== null}
              meChatGroups
              error={errors.receiver}
            />
          </div>
        </div>
        <div className="mt-2 pt-2 text-right">
          <Button
            className="btn-primary"
            onClick={this.sendMessage}
            text={
              fileboxFileId
                ? formatMessage(messages.share)
                : videocallRoom
                ? formatMessage(messages.invite)
                : formatMessage(messages.forward)
            }
          />
          {onClose && (
            <Button
              className="btn-outline-primary ml-1"
              onClick={onClose}
              text={formatMessage(messages.close)}
            />
          )}
        </div>
      </>
    );
  }
}

SelectMessageRecipientsBody.propTypes = {
  selectedMessage: PropTypes.object,
  message: PropTypes.object,
  fileboxFileId: PropTypes.number,
  videocallRoom: PropTypes.string,
  onClose: PropTypes.func,
};

SelectMessageRecipientsBody.defaultProps = {
  selectedMessage: {},
  message: {},
  fileboxFileId: null,
  videocallRoom: null,
  onClose: null,
};

function mapStateToProps(state, ownProps) {
  return {
    chatMessagesById: getChatMessagesById(state),
    selectedMessage: getChatMessageById(
      state,
      ownProps.message && ownProps.message.id
    ),
    users: getUsers(state),
    file: getFileboxItemById(state, ownProps.fileboxFileId),
    myId: getMeId(state),
    idCustomer: getCustomerId(state),
    sharingFileboxFileError: getEnableDisableFileExternalLinkError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    sendMessage: sendChatMessageRequest,
    openConversation: openChatConversation,
    enableDisableFileLink: enableDisableFileboxFileExternalLinkRequest,
  })(SelectMessageRecipientsBody)
);
