import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import uniqueid from 'lodash.uniqueid';
import { Navbar, Nav, NavItem } from 'reactstrap';
import classnames from 'classnames';
import { PhoneEnums } from '../js/phone/PhoneUtils';
import Icon from './icons/Icon';
import Button from './formControls/Button';
import Popover from './Popover';

const messages = defineMessages({
  pickup: {
    id: 'InterlocutorCallToolbar.tooltip.pickup',
    defaultMessage: 'Pickup call',
  },
  listen: {
    id: 'InterlocutorCallToolbar.tooltip.listen',
    defaultMessage: 'Listen conversation',
  },
  spyInfo: {
    id: 'InterlocutorCallToolbar.spyInfo',
    defaultMessage: 'You can listen this conversation',
  },
  intrudeInfo: {
    id: 'InterlocutorCallToolbar.intrudeInfo',
    defaultMessage:
      'You can listen this conversation or talk with this interlocutor',
  },
  spyButton: {
    id: 'InterlocutorCallToolbar.button.spy',
    defaultMessage: 'Listen',
  },
  intrudeButton: {
    id: 'InterlocutorCallToolbar.tooltip.intrude',
    defaultMessage: 'Talk',
  },
  externalNumber: {
    id: 'InterlocutorCallToolbar.externalNumber',
    defaultMessage: 'External number',
  },
  directionOut: {
    id: 'InterlocutorCallToolbar.tooltip.directionOut',
    defaultMessage: 'Outgoing call',
  },
  directionIn: {
    id: 'InterlocutorCallToolbar.tooltip.directionIn',
    defaultMessage: 'Incoming call',
  },
});

class InterlocutorCallToolbar extends Component {
  state = {
    listenPopoverOpen: false,
    id: uniqueid('SpyPopover-'),
  };

  toggle = () => {
    this.setState({ listenPopoverOpen: !this.state.listenPopoverOpen });
  };

  handleListenClick = () => {
    this.setState({ listenPopoverOpen: true });
  };

  handleSpyClick = () => {
    this.setState({ listenPopoverOpen: false }, this.props.onSpy);
  };

  handleIntrudeClick = () => {
    this.setState({ listenPopoverOpen: false }, this.props.onIntrude);
  };

  render() {
    const {
      interlocutor,
      align,
      onPickup,
      onSpy,
      onIntrude,
      fromQueueName,
      intl: { formatMessage },
    } = this.props;
    const navbarClassNames = classnames('p-0', {
      ' ml-auto': align === 'right',
    });
    const { id } = this.state;

    return (
      <>
        <Navbar
          color="faded"
          light
          expand="sm"
          className="p-0"
          style={{ height: fromQueueName ? '13px' : 'unset' }}
        >
          <Nav navbar className={navbarClassNames}>
            {align === 'left' && (
              <NavItem>
                <span
                  style={{
                    fontSize: '0.9rem',
                    color: 'var(--yn-blue-dark)',
                    lineHeight: 1,
                  }}
                >
                  {interlocutor.number
                    ? interlocutor.number !== '<unknown>'
                      ? interlocutor.number
                      : formatMessage(messages.externalNumber)
                    : '...'}
                </span>
              </NavItem>
            )}
            {align === 'left' &&
              interlocutor.number &&
              interlocutor.direction && (
                <NavItem>
                  <span className="mx-1">
                    <Icon
                      name={
                        interlocutor.direction === PhoneEnums.Direction.Out
                          ? 'arrow-double-right'
                          : 'arrow-double-left'
                      }
                      color="var(--yn-blue-dark)"
                      width={16}
                      height={16}
                      clickable={false}
                      style={{ top: '1px' }}
                    >
                      {interlocutor.direction === PhoneEnums.Direction.Out
                        ? formatMessage(messages.directionOut)
                        : formatMessage(messages.directionIn)}
                    </Icon>
                  </span>
                </NavItem>
              )}
            {(onSpy || onIntrude) && (
              <NavItem>
                <span onClick={this.handleListenClick} id={id}>
                  <Icon
                    name="headphone-filled"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.listen)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {onPickup && (
              <NavItem style={{ position: 'relative' }}>
                <span onClick={onPickup}>
                  <Icon
                    name="phone-ringing-filled"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.pickup)}
                  </Icon>
                </span>
              </NavItem>
            )}
            {align === 'right' &&
              interlocutor.number &&
              interlocutor.direction && (
                <NavItem>
                  <span className="mx-1">
                    <Icon
                      name={
                        interlocutor.direction === PhoneEnums.Direction.Out
                          ? 'arrow-double-left'
                          : 'arrow-double-right'
                      }
                      color="var(--yn-blue-dark)"
                      width={16}
                      height={16}
                      clickable={false}
                      style={{ top: '1px' }}
                    >
                      {interlocutor.direction === PhoneEnums.Direction.Out
                        ? formatMessage(messages.directionOut)
                        : formatMessage(messages.directionIn)}
                    </Icon>
                  </span>
                </NavItem>
              )}
            {align === 'right' && (
              <NavItem>
                <span
                  style={{
                    fontSize: '0.9rem',
                    color: 'var(--yn-blue-dark)',
                    lineHeight: 1,
                  }}
                >
                  {interlocutor.number
                    ? interlocutor.number !== '<unknown>'
                      ? interlocutor.number
                      : formatMessage(messages.externalNumber)
                    : '...'}
                </span>
              </NavItem>
            )}
          </Nav>
        </Navbar>
        {(onSpy || onIntrude) && this.state.listenPopoverOpen && (
          <Popover
            isOpen={this.state.listenPopoverOpen}
            toggle={this.toggle}
            target={id}
            header={
              onIntrude
                ? formatMessage(messages.intrudeInfo)
                : formatMessage(messages.spyInfo)
            }
          >
            <Button
              className="btn btn-primary"
              text={formatMessage(messages.spyButton)}
              onClick={this.handleSpyClick}
            />
            {onIntrude && (
              <Button
                className="btn btn-primary ml-2"
                text={formatMessage(messages.intrudeButton)}
                onClick={this.handleIntrudeClick}
              />
            )}
          </Popover>
        )}
      </>
    );
  }
}

InterlocutorCallToolbar.propTypes = {
  interlocutor: PropTypes.object.isRequired,
  align: PropTypes.string,
  onPickup: PropTypes.func,
};

InterlocutorCallToolbar.defaultProps = {
  align: 'left',
  onPickup: undefined,
};

export default injectIntl(InterlocutorCallToolbar);
