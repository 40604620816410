export const FETCH_PHONE_EXTERNAL_URL_REQUEST =
  'FETCH_PHONE_EXTERNAL_URL_REQUEST';
export const FETCH_PHONE_EXTERNAL_URL_FAILURE =
  'FETCH_PHONE_EXTERNAL_URL_FAILURE';
export const FETCH_PHONE_EXTERNAL_URL_SUCCESS =
  'FETCH_PHONE_EXTERNAL_URL_SUCCESS';
export const DELETE_PHONE_EXTERNAL_URL_REQUEST =
  'DELETE_PHONE_EXTERNAL_URL_REQUEST';
export const DELETE_PHONE_EXTERNAL_URL_SUCCESS =
  'DELETE_PHONE_EXTERNAL_URL_SUCCESS';
export const DELETE_PHONE_EXTERNAL_URL_FAILURE =
  'DELETE_PHONE_EXTERNAL_URL_FAILURE';
export const INSERT_PHONE_EXTERNAL_URL_REQUEST =
  'INSERT_PHONE_EXTERNAL_URL_REQUEST';
export const INSERT_PHONE_EXTERNAL_URL_SUCCESS =
  'INSERT_PHONE_EXTERNAL_URL_SUCCESS';
export const INSERT_PHONE_EXTERNAL_URL_FAILURE =
  'INSERT_PHONE_EXTERNAL_URL_FAILURE';
export const EDIT_PHONE_EXTERNAL_URL_REQUEST =
  'EDIT_PHONE_EXTERNAL_URL_REQUEST';
export const EDIT_PHONE_EXTERNAL_URL_SUCCESS =
  'EDIT_PHONE_EXTERNAL_URL_SUCCESS';
export const EDIT_PHONE_EXTERNAL_URL_FAILURE =
  'EDIT_PHONE_EXTERNAL_URL_FAILURE';
export const CLEAR_PHONE_EXTERNAL_URL_ERRORS =
  'CLEAR_PHONE_EXTERNAL_URL_ERRORS';
