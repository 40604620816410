import moment from 'moment';
import { PhoneRulesEnums } from '../phoneRules/PhoneRulesUtils';
import ynConf from '../../conf';
import { PhoneEnums } from './PhoneUtils';

export class PbxSettings {
  static PREFIX_PARK = '9999';

  static CTI_URL = '/sys/opc/CtiControl.xml';

  static DISABLED_PHONE_NUMBER = '*';

  static FORWARD_NO_ANSWER_TIMEOUT = 20;

  static MAX_PHONE_RULES = 10;

  static RING_SOUND = `${ynConf.publicFolderPath}/notifications/ring.mp3`;

  static AVAILABLE_SOUND = `${ynConf.publicFolderPath}/notifications/availablePhone.mp3`;

  static BUSY_SOUND = `${ynConf.publicFolderPath}/notifications/busytone.mp3`;

  static DEFAULT_RING_GROUPS = [81001, 81002, 81003, 81004, 81005];

  static RESPONDER_FORWARD_NUMBER = '44444';

  static CHECK_JANUS_ACTIVITY = 30000;

  static JANUS_CONFERENCE_CODE = 987;
}

export const PHONE_RECONNECT_TIMEOUT = 30 * 1000;

export const statusColor = (status, dnd, activeRule, supplier, parked, hex) => {
  if (parked) {
    return hex ? '#dc3545' : 'var(--red)';
  }
  switch (status) {
    case PhoneEnums.PhoneStatuses.offline:
    case PhoneEnums.PhoneStatuses.logging:
      return hex ? '#b3b7bc' : 'var(--yn-gray-500)';
    case PhoneEnums.PhoneStatuses.inited:
    case PhoneEnums.UserStatuses.available:
    case PhoneEnums.CallsStatuses.hold:
    case PhoneEnums.UserStatuses.ready: {
      if (
        supplier &&
        status === PhoneEnums.CallsStatuses.hold &&
        supplier === PhoneEnums.PbxType.NETHESIS
      ) {
        return hex ? '#dc3545' : 'var(--red)';
      }
      if (dnd === PhoneEnums.DndStatus.ON)
        return hex ? '#fd7e14' : 'var(--orange)';
      if (
        activeRule &&
        (activeRule === PhoneRulesEnums.SettingsActions.vbox ||
          activeRule === PhoneRulesEnums.SettingsActions.forward)
      ) {
        return hex ? '#dc3545' : 'var(--red)';
      }
      return hex ? '#28a745' : 'var(--green)';
    }
    case PhoneEnums.UserStatuses.alerting:
    case PhoneEnums.UserStatuses.ringing:
      return hex ? '#ffc107' : 'var(--yellow)';
    case PhoneEnums.PhoneStatuses.error:
    case PhoneEnums.UserStatuses.unavailable:
    case PhoneEnums.CallsStatuses.calling:
    case PhoneEnums.UserStatuses.calling:
    default:
      return hex ? '#dc3545' : 'var(--red)';
  }
};

export const statusColorBackground = (
  status,
  dnd,
  activeRule,
  supplier,
  parked
) => {
  if (parked) {
    return 'rgba(220, 53, 69, .4)';
  }
  switch (status) {
    case PhoneEnums.PhoneStatuses.initing:
      return 'rgba(179, 183, 188, .4)';
    case PhoneEnums.PhoneStatuses.logged:
    case PhoneEnums.UserStatuses.available:
    case PhoneEnums.CallsStatuses.hold:
    case PhoneEnums.UserStatuses.ready: {
      if (
        status === PhoneEnums.CallsStatuses.hold &&
        supplier === PhoneEnums.PbxType.NETHESIS
      ) {
        return 'rgba(220, 53, 69, .4)';
      }
      if (dnd === PhoneEnums.DndStatus.ON) return 'var(--orange)';
      if (
        activeRule &&
        (activeRule === PhoneRulesEnums.SettingsActions.vbox ||
          activeRule === PhoneRulesEnums.SettingsActions.forward)
      ) {
        return 'rgba(220, 53, 69, .4)';
      }
      return 'rgba(40, 167, 69, .4)';
    }
    case PhoneEnums.UserStatuses.alerting:
    case PhoneEnums.UserStatuses.ringing:
      return 'rgba(255, 193, 7, .4)';
    case PhoneEnums.PhoneStatuses.error:
    case PhoneEnums.UserStatuses.unavailable:
    case PhoneEnums.CallsStatuses.calling:
    case PhoneEnums.UserStatuses.calling:
    default:
      return 'rgba(220, 53, 69, .4)';
  }
};

export const isPhoneAvailable = (status, dnd, supplier) => {
  switch (status) {
    case PhoneEnums.PhoneStatuses.logged:
    case PhoneEnums.UserStatuses.available:
    case PhoneEnums.CallsStatuses.hold:
    case PhoneEnums.UserStatuses.ready: {
      if (
        status === PhoneEnums.CallsStatuses.hold &&
        supplier === PhoneEnums.PbxType.NETHESIS
      ) {
        return false;
      }
      if (dnd === PhoneEnums.DndStatus.ON) return false;
      return true;
    }
    default:
      return false;
  }
};

export const addUsernameToCallid = (id, username) => `${id}_${username}`;
export const removeUsernameToCallid = (id) =>
  id.substring(0, id.lastIndexOf('_'));
export const managePhoneStatus = (rules) => {
  const now = moment().toDate();
  const weekDay = now.getDay();
  const currentTime = moment(now, 'HH:mm');
  let pbxSettings;
  rules.forEach((rule) => {
    if (rule.enabled && rule.days[weekDay]) {
      const from = moment(
        `${rule.timeFromHour}:${rule.timeFromMinute}`,
        'HH:mm'
      );
      let unparsedTo = `${rule.timeToHour}:${rule.timeToMinute}`;
      if (unparsedTo === '00:00') {
        unparsedTo = '23:59';
      }
      const to = moment(unparsedTo, 'HH:mm');
      if (currentTime.isSameOrAfter(from) && currentTime.isSameOrBefore(to)) {
        if (rule.action === PhoneRulesEnums.SettingsActions.dnd) {
          pbxSettings = {
            active: true,
            status: PhoneRulesEnums.SettingsActions.dnd,
          };
        } else if (
          rule.event.indexOf(PhoneRulesEnums.SettingsEvents.always) >= 0
        ) {
          pbxSettings = {
            active: true,
            status: rule.action,
          };
        }
      }
    }
  });
  return pbxSettings;
};
