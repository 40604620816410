import React, { Component } from 'react';
import uniqueid from 'lodash.uniqueid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isUserOnline, getUserStatus } from '../js/users/selectors';
import { fetchUserRequest } from '../js/users/actions';
import { getMeId, getMeUserStatus } from '../js/me/selectors';
import Avatar from './Avatar';
import UserDetailsPopover from './UserDetailsPopover';
import { AVATAR_TYPES } from '../js/files/FileUtils';
import UserStatusEmoji from './UserStatusEmoji';

class UserAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      popoverTarget: uniqueid(`UserAvatar-`),
    };
  }

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  handleFetchData = () => {
    if (!this.props.id) return;
    if (this.state.popoverOpen) {
      this.setState({ popoverOpen: false });
      return;
    }
    this.setState({ popoverOpen: true });
    this.props.fetch(this.props.id);
  };

  render() {
    const {
      id,
      size,
      alt,
      src,
      className,
      withPopover,
      clickable,
      online,
      meId,
      showStatus,
      userStatus,
      statusPosition,
    } = this.props;

    const { popoverOpen, popoverTarget } = this.state;
    const isMe = id === meId;

    return (
      <div
        id={popoverTarget}
        onClick={id && withPopover && !isMe ? this.handleFetchData : null}
        className={`d-inline-block position-relative ${className || ''}`}
      >
        <Avatar
          size={size}
          src={src}
          alt={alt}
          offline={!online}
          clickable={!!id && !isMe && (clickable || withPopover)}
          type={AVATAR_TYPES.ME}
        />
        {showStatus && userStatus && Object.keys(userStatus).length > 0 && (
          <UserStatusEmoji
            emoji={userStatus.emoji}
            label={userStatus.label}
            avatarSize={size}
            statusPosition={statusPosition}
            offline={!online}
          />
        )}
        {!!id && !isMe && withPopover && popoverOpen && (
          <UserDetailsPopover
            id={id}
            isOpen={this.state.popoverOpen}
            target={this.state.popoverTarget}
            toggle={this.toggle}
            online={online}
          />
        )}
      </div>
    );
  }
}

UserAvatar.propTypes = {
  id: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  withPopover: PropTypes.bool,
  clickable: PropTypes.bool,
  showStatus: PropTypes.bool,
  statusPosition: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};

UserAvatar.defaultProps = {
  className: null,
  src: '',
  size: 'md',
  withPopover: false,
  clickable: false,
  showStatus: false,
  statusPosition: null,
};

function mapStateToProps(state, ownProps) {
  return {
    online: ownProps.id === getMeId(state) || isUserOnline(state, ownProps.id),
    meId: getMeId(state),
    userStatus:
      getMeId(state) === ownProps.id
        ? getMeUserStatus(state)
        : getUserStatus(state, ownProps.id),
  };
}

export default connect(mapStateToProps, { fetch: fetchUserRequest })(
  UserAvatar
);
