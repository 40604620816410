import { orderFileboxItems } from './FileboxUtils';

const defaultObject = {};
const defaultArray = [];

export const getFilesIds = (state) =>
  state.filebox.filter.name && state.filebox.filter.name.length > 0
    ? state.filebox.searchedFilesAllIds
    : state.filebox.filesAllIds;

export const getFoldersIds = (state) =>
  state.filebox.filter.name && state.filebox.filter.name.length > 0
    ? state.filebox.searchedFoldersAllIds
    : state.filebox.foldersAllIds;

export const getOrderedFilesIds = (state) => {
  const orderType = state.filebox.order.type;
  const orderDirection = state.filebox.order.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(getFilesIds(state), byId, orderType, orderDirection);
};

export const getOrderedFoldersIds = (state) => {
  const orderType = state.filebox.order.type;
  const orderDirection = state.filebox.order.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    getFoldersIds(state),
    byId,
    orderType,
    orderDirection
  );
};

export const getFileboxItemById = (state, id) =>
  state.filebox.byId[id] || defaultObject;
export const getFileboxItemVersions = (state, id) =>
  (state.filebox.byId[id] && state.filebox.byId[id].versions) || [];
export const getFileboxFileEmailRecipients = (state, id) =>
  (state.filebox.byId[id] && state.filebox.byId[id].recipients) || [];
export const getSelectedFolderId = (state) =>
  state.filebox.actualPath[state.filebox.actualPath.length - 1];
export const getFileboxActualPath = (state) => state.filebox.actualPath;
export const isParentFolderShared = (state) =>
  state.filebox.byId[getSelectedFolderId(state)] &&
  state.filebox.byId[getSelectedFolderId(state)].isShared;
export const getFolderSharings = (state, id) => {
  if (state.filebox.byId[id] && state.filebox.byId[id].sharing) {
    const sharing = [...state.filebox.byId[id].sharing];
    sharing.sort((a, b) => {
      if (
        a.deletionPermission &&
        a.writePermission &&
        !(b.deletionPermission && b.writePermission)
      )
        return -1;
      if (
        (a.deletionPermission || a.writePermission) &&
        !b.deletionPermission &&
        !b.writePermission
      )
        return -1;
      if (a.idUser && b.idGroup) return -1;
      return 1;
    });
    return sharing;
  }

  return defaultArray;
};
export const getFileboxFilter = (state) => state.filebox.filter;
export const getFileboxSpaceUsage = (state) => state.filebox.spaceUsage;
export const getFileboxSpaceUsageDatetime = (state) =>
  state.filebox.spaceUsageDatetime;
export const getFetchSpaceUsageLoaded = (state) =>
  state.filebox.fetchSpaceUsageLoaded;
export const getFetchSpaceUsageError = (state) =>
  state.filebox.fetchSpaceUsageError;
export const getFetchItemsLoaded = (state) => state.filebox.fetchItemsLoaded;
export const getFetchItemsError = (state) => state.filebox.fetchItemsError;
export const getDeleteFileError = (state) => state.filebox.deleteFileError;
export const getCreateFolderError = (state) => state.filebox.createFolderError;
export const getCreateFolderLoaded = (state) =>
  state.filebox.createFolderLoaded;
export const getDeleteFolderError = (state) => state.filebox.deleteFolderError;
export const getDeleteFolderLoaded = (state) =>
  state.filebox.deleteFolderLoaded;
export const getToggleLockingFileError = (state) =>
  state.filebox.toggleLockingFileError;
export const getToggleLockingFileLoaded = (state) =>
  state.filebox.toggleLockingFileLoaded;
export const getRenameFileError = (state) => state.filebox.renameFileError;
export const getRenameFileLoaded = (state) => state.filebox.renameFileLoaded;
export const getEditFolderError = (state) => state.filebox.editFolderError;
export const getEditFolderLoaded = (state) => state.filebox.editFolderLoaded;
export const getEnableDisableFileExternalLinkError = (state) =>
  state.filebox.enableDisableFileExternalLinkError;
export const getEnableDisableFileExternalLinkLoaded = (state) =>
  state.filebox.enableDisableFileExternalLinkLoaded;
export const getFetchFolderDetailsError = (state) =>
  state.filebox.fetchFolderDetailsError;
export const getFetchFolderDetailsLoaded = (state) =>
  state.filebox.fetchFolderDetailsLoaded;
export const getFavoriteFilesIds = (state) => {
  const orderType = state.filebox.order.type;
  const orderDirection = state.filebox.order.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    state.filebox.favoriteFilesAllIds,
    byId,
    orderType,
    orderDirection
  );
};
export const getFavoritesFilesLoaded = (state) =>
  state.filebox.fetchFavoriteFilesLoaded;
export const getFavoriteFilesError = (state) =>
  state.filebox.fetchFavoriteFilesError;
export const getFilesNames = (state) => state.filebox.filesAllNames;
export const getFileIdByName = (state, name) =>
  Object.values(state.filebox.byId).filter((f) => f.name === name)[0] &&
  Object.values(state.filebox.byId).filter((f) => f.name === name)[0].id;
export const getFileboxActiveFilter = (state, context) =>
  state.filebox.activeFilter[context] || defaultObject;
export const getFileboxActiveItemsLoaded = (state) =>
  state.filebox.fetchActiveItemsLoaded;
export const getFileboxActiveItemsError = (state) =>
  state.filebox.fetchActiveItemsError;
export const getActiveFilesIds = (state) => state.filebox.activeFilesAllIds;
export const getActiveOrderedFilesIds = (state) => {
  const orderType = state.filebox.activeOrder.type;
  const orderDirection = state.filebox.activeOrder.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    getActiveFilesIds(state),
    byId,
    orderType,
    orderDirection
  );
};
export const getActiveFoldersIds = (state) => state.filebox.activeFoldersAllIds;

export const getActiveOrderedFoldersIds = (state) => {
  const orderType = state.filebox.activeOrder.type;
  const orderDirection = state.filebox.activeOrder.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    getActiveFoldersIds(state),
    byId,
    orderType,
    orderDirection
  );
};
export const getActiveSelectedFolderId = (state) =>
  state.filebox.activeSelectedFolderId;
export const getFileboxActiveActualPath = (state, context) =>
  state.filebox.activeActualPath[context] || defaultArray;
export const getMoveFolderError = (state) => state.filebox.moveFolderError;
export const getMoveFolderLoaded = (state) => state.filebox.moveFolderLoaded;
export const getMoveFileError = (state) => state.filebox.moveFileError;
export const getMoveFileLoaded = (state) => state.filebox.moveFileLoaded;
export const getCopyFileError = (state) => state.filebox.copyFileError;
export const getCopyFileLoaded = (state) => state.filebox.copyFileLoaded;
export const getCopyFolderError = (state) => state.filebox.copyFolderError;
export const getCopyFolderLoaded = (state) => state.filebox.copyFolderLoaded;
export const getSearchFilesFoldersError = (state) =>
  state.filebox.searchFilesFoldersError;
export const getSearchFilesFoldersLoaded = (state) =>
  state.filebox.searchFilesFoldersLoaded;
export const getSearchedFilesIds = (state) => state.filebox.searchedFilesAllIds;
export const getSearchedFoldersIds = (state) =>
  state.filebox.searchedFoldersAllIds;
export const getNamedActualPath = (state) => state.filebox.namedActualPath;
export const getMarkFavoriteFileLoaded = (state) =>
  state.filebox.markFavoriteFileLoaded;
export const getMarkFavoriteFileError = (state) =>
  state.filebox.markFavoriteFileError;
export const getRemoveFavoriteFileLoaded = (state) =>
  state.filebox.removeFavoriteFileLoaded;
export const getRemoveFavoriteFileError = (state) =>
  state.filebox.removeFavoriteFileError;
export const getFileboxPlanExpirationDate = (state) =>
  state.filebox.planExpirationDate;
export const getDeleteMassiveFilesError = (state) =>
  state.filebox.deleteMassiveFilesError;
export const getDeleteMassiveFilesLoaded = (state) =>
  state.filebox.deleteMassiveFilesLoaded;
export const getFailedFoldersDeletion = (state) =>
  state.filebox.failedFoldersDeletion;
export const isFileboxBlocked = (state) => state.me.customer.fileboxBlocked;
export const getFileboxItemsOrder = (state) => state.filebox.order;
export const getFileboxDeletedOrder = (state) => state.filebox.deletedOrder;
export const getUpdateSpaceLoaded = (state) => state.filebox.updateSpaceLoaded;
export const getUpdateSpaceError = (state) => state.filebox.updateSpaceError;
export const getFileboxDeletedItemsLoaded = (state) =>
  state.filebox.fetchDeletedItemsLoaded;
export const getFileboxDeletedItemsError = (state) =>
  state.filebox.fetchDeletedItemsError;
export const getDeletedFilesIds = (state) => {
  const orderType = state.filebox.deletedOrder.type;
  const orderDirection = state.filebox.deletedOrder.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    state.filebox.deletedFilesAllIds,
    byId,
    orderType,
    orderDirection
  );
};
export const getDeletedFoldersIds = (state) => {
  const orderType = state.filebox.deletedOrder.type;
  const orderDirection = state.filebox.deletedOrder.direction;
  const { byId } = state.filebox;
  return orderFileboxItems(
    state.filebox.deletedFoldersAllIds,
    byId,
    orderType,
    orderDirection
  );
};
export const getDeletedSelectedFolderId = (state) =>
  state.filebox.deletedSelectedFolderId;
export const getFileboxDeletedActualPath = (state) =>
  state.filebox.deletedActualPath;
export const getRemovePermanentlyDeletedItemError = (state) =>
  state.filebox.removePermanentlyDeletedItemError;
export const getRestoreDeletedItemError = (state) =>
  state.filebox.restoreDeletedItemError;
export const getFileComments = (state, id) =>
  getFileboxItemById(state, id).comments;
export const isOperationFileCommentsLoaded = (state) =>
  state.filebox.operationFileCommentsLoaded;
export const isOperationFileCommentsError = (state) =>
  state.filebox.operationFileCommentsError;
export const isFileFavorite = (state, id) =>
  state.filebox.favoriteFilesAllIds.some((fileId) => fileId === id);
export const getFileboxActiveOrder = (state) => state.filebox.activeOrder;
export const getFileboxFileEmailRecipientsLoaded = (state) =>
  state.filebox.fetchFileboxFileEmailRecipientsLoaded;
export const getFileboxFileEmailRecipientsError = (state) =>
  state.filebox.fetchFileboxFileEmailRecipientsError;
export const isFileboxFileSharedByEmailLoaded = (state) =>
  state.filebox.shareFileboxFileLoaded;
export const isFileboxFileSharedByEmailError = (state) =>
  state.filebox.shareFileboxFileError;
