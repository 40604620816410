import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import { getMeId } from '../../../js/me/selectors';
import { getRemovedUserData } from '../../../js/chat/selectors';
import ynConf from '../../../conf';

const messages = defineMessages({
  deletedUser: {
    id: 'ChatHistorySender.label.deletedUser',
    defaultMessage: 'Unknown user',
  },
});

class ChatHistorySender extends React.Component {
  constructor(props) {
    super(props);
    this.senderDiv = React.createRef();
  }

  render() {
    const {
      intl: { formatMessage },
      children,
      senderData,
      senderDeletedData,
      myId,
      isRemoved,
    } = this.props;
    const mine = senderData && senderData.id ? senderData.id === myId : false;
    const style = {
      marginLeft: mine ? null : '30px',
      marginRight: mine ? '30px' : null,
      background: mine ? 'var(--yn-blue-lighter)' : 'var(--yn-gray-200)',
    };
    let senderAvatar;
    let senderName;
    if (senderData && Object.keys(senderData).length > 0) {
      senderAvatar = senderData.avatar;
      senderName = senderData.fullname;
    } else if (senderDeletedData && Object.keys(senderDeletedData).length > 0) {
      senderAvatar = senderDeletedData.avatar;
      senderName = senderDeletedData.fullname;
    } else {
      senderName = formatMessage(messages.deletedUser);
      senderAvatar = `${ynConf.publicFolderPath}/avatar.svg`;
    }
    const offsetSender = this.senderDiv.current
      ? this.senderDiv.current.offsetTop
      : null;
    const updatedChildren = React.Children.map(children, (child) =>
      React.cloneElement(child, { offsetSender })
    );

    return (
      <div
        className="m-1 position-relative"
        id={`chatSender-${senderName}`}
        ref={this.senderDiv}
      >
        <div
          className={mine ? 'float-right' : 'float-left'}
          style={{ marginLeft: mine ? '0' : '-0.25rem' }}
        >
          <UserAvatar
            size="sm"
            alt={`${senderName} avatar`}
            src={senderAvatar}
            id={senderData.id || 0}
            withPopover={!isRemoved}
          />
          {isRemoved && <div className="yn-chat-removed-sender" />}
        </div>
        <div
          className={`rounded clearfix ${
            mine ? 'yn-message-sent' : 'yn-message-received'
          }`}
          style={style}
        >
          <div
            className="px-1"
            style={{
              color: mine ? 'var(--yn-blue)' : 'var(--yn-gray-600)',
              fontSize: '0.8rem',
            }}
          >
            <strong>{senderData.fullname || senderName}</strong>
          </div>
          {updatedChildren}
        </div>
      </div>
    );
  }
}

ChatHistorySender.propTypes = {
  sender: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isRemoved: !getUserById(state, ownProps.sender),
    senderData: getUserById(state, ownProps.sender),
    senderDeletedData: getRemovedUserData(state, ownProps.sender),
    myId: getMeId(state),
  };
}

export default injectIntl(connect(mapStateToProps)(ChatHistorySender));
