import React from 'react';
import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from '../../../components/UserAvatar';
import ContactAvatar from '../../../components/ContactAvatar';
import ChatButton from '../../../components/buttons/ChatButton';
import ExtensionPhoneButton from '../../../components/buttons/ExtensionPhoneButton';
import OtherPhoneButton from '../../../components/buttons/OtherPhoneButton';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import {
  isPhoneAvailable,
  statusColorBackground,
} from '../../../js/phone/PbxSettingsUtils';
import {
  getUserPhoneStatus,
  getSupplier,
  getUserPhoneDnd,
} from '../../../js/phone/selectors';
import {
  aidTransferRequest,
  blindTransferRequest,
  queueTransferRequest,
} from '../../../js/phone/actions';
import { getUserById } from '../../../js/users/selectors';
import { getContactById } from '../../../js/contacts/selectors';
import Utils from '../../../js/lib/utils';
import Icon from '../../../components/icons/Icon';
import VideoButton from '../../../components/buttons/VideoButton';

const interlocutorTarget = {
  drop(props, monitor) {
    let number = null;
    if (props.member.type === 'A') {
      number = props.member.numbers[0].number;
    } else if (
      isPhoneAvailable(props.phoneStatus, props.phoneDnd, props.supplier)
    ) {
      number = props.member.mainExtensionNumber;
    }
    if (number) {
      if (monitor.getItemType() === Utils.DragAndDropTypes.attendedTransfer) {
        props.aidTransfer({
          callId: monitor.getItem().callId,
          number,
        });
      }
      if (monitor.getItemType() === Utils.DragAndDropTypes.blindTransfer) {
        props.blindTransfer({
          callId: monitor.getItem().callId,
          number,
        });
      }
      if (monitor.getItemType() === Utils.DragAndDropTypes.queueTransfer) {
        props.queueTransfer({
          callId: monitor.getItem().callId,
          number,
        });
      }
    }
  },
};

const MemberPanel = ({
  member,
  type,
  phoneStatus,
  phoneDnd,
  supplier,
  groupId,
  connectDropTarget,
  isOver,
  canDrop,
}) => {
  const isActive = isOver && canDrop;
  let style;
  if (isActive) {
    style = {
      background:
        member.type === 'U'
          ? statusColorBackground(phoneStatus, phoneDnd, supplier)
          : 'rgba(0, 118, 178, .4)',
    };
  } else {
    style = {
      background: 'white',
    };
  }

  if (member) {
    return connectDropTarget(
      <div className="p-1 border-top" style={style}>
        <div className="row m-0">
          <div className="col-2 pr-0 p-0 text-center">
            {type === 'U' ? (
              <UserAvatar
                size="md"
                alt={`${member.fullname} avatar`}
                src={member.avatar}
                id={member.id}
                withPopover
                showStatus
                statusPosition={{ bottom: -6, left: -6 }}
              />
            ) : (
              <ContactAvatar
                id={member.id}
                size="md"
                alt={`${member.fullname} avatar`}
                src={member.avatar}
                withPopover
              />
            )}
          </div>
          <div className="col-10 p-0">
            <div className="row m-0">
              <div
                className="col p-0 ml-2 d-inline-block text-truncate"
                style={{ color: 'var(--yn-blue)', fontSize: '0.8rem' }}
              >
                {member.mainExtensionNumber
                  ? `${member.mainExtensionNumber} - ${member.fullname}`
                  : member.fullname}
              </div>
            </div>
            <div className="row  m-0">
              <div className="col p-0 ml-2">
                {type === 'U' && member.mainExtensionNumber && (
                  <span className="p-1">
                    <ExtensionPhoneButton
                      userId={member.id}
                      number={member.mainExtensionNumber}
                    />
                  </span>
                )}
                {type === 'U' && (
                  <span className="p-1">
                    <VideoButton user={member.id} />
                  </span>
                )}
                {type === 'U' && (
                  <span className="p-1">
                    <ChatButton user={member.id} />
                  </span>
                )}
                {type === 'A' && (
                  <>
                    <span className="p-1">
                      <OtherPhoneButton
                        placementPopover="top"
                        elementId={`phone_groupspanel_${groupId}_${member.id}`}
                        numbers={member.numbers}
                      />
                    </span>
                    <Link
                      style={{
                        position: 'absolute',
                        top: '-2px',
                        right: '-30px',
                      }}
                      to={`/sms/sent/edit?phone=${
                        member.numbers && member.numbers[0].number
                      }`}
                    >
                      <Icon
                        name="bubble-chat-1-filled"
                        width={20}
                        height={20}
                        color="var(--yn-blue-dark)"
                        clickable
                      >
                        SMS
                      </Icon>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

function mapStateToProps(state, ownProps) {
  return {
    member:
      ownProps.type === 'U'
        ? getUserById(state, ownProps.id)
        : getContactById(state, ownProps.id),
    phoneStatus:
      ownProps.type === 'U' && getUserPhoneStatus(state, ownProps.id),
    phoneDnd: ownProps.type === 'U' && getUserPhoneDnd(state, ownProps.id),
    supplier: getSupplier(state),
  };
}

export default connect(mapStateToProps, {
  aidTransfer: aidTransferRequest,
  blindTransfer: blindTransferRequest,
  queueTransfer: queueTransferRequest,
})(
  DropTarget(
    [
      PhoneEnums.DndTypes.attendedTransfer,
      PhoneEnums.DndTypes.blindTransfer,
      PhoneEnums.DndTypes.queueTransfer,
    ],
    interlocutorTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  )(MemberPanel)
);
