import React, { Component } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash.omit';
import pickBy from 'lodash.pickby';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getComposingUsersNames,
  getComposingUsers,
} from '../../../js/chat/selectors';
import {
  retrieveInterlocutorIdFromConversationId,
  RESET_COMPOSING_TIMEOUT,
} from '../../../js/chat/ChatUtils';
import AnimatedDots from './AnimatedDots';
import { receivePausedStatus } from '../../../js/chat/actions';

const messages = defineMessages({
  privateChatComposing: {
    id: 'ChatWindowComposing.label.privateChatComposing',
    defaultMessage: 'Is writing',
  },
  singleGroupChatComposing: {
    id: 'ChatWindowComposing.label.singleComposing',
    defaultMessage: '{users} is writing',
  },
  multipleGroupChatComposing: {
    id: 'ChatWindowComposing.label.multipleComposing',
    defaultMessage: '{users} are writing',
  },
});

class ChatWindowComposing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      composingUsers: {},
    };
  }

  componentDidMount() {
    this.updateComposingUsers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.composingUsers !== prevProps.composingUsers) {
      this.updateComposingUsers();
    }
  }

  componentWillUnmount() {
    this.setState({
      composingUsers: {},
    });
  }

  updateComposingUsers() {
    const composingUsers = pickBy(this.state.composingUsers, (value, key) => {
      return this.props.composingUsers.indexOf(key) > -1;
    });

    this.setState({ composingUsers }, () => {
      this.props.composingUsers.forEach((id) => {
        if (!this.state.composingUsers[id]) {
          const interlocutor = retrieveInterlocutorIdFromConversationId(
            this.props.conversationId
          );
          this.setState({
            composingUsers: {
              [id]: setTimeout(() => {
                this.setState({
                  composingUsers: omit(this.state.composingUsers, id),
                });
                this.props.pauseInterlocutor(
                  interlocutor.userId
                    ? { user: interlocutor }
                    : { group: interlocutor, user: { userId: id } }
                );
              }, RESET_COMPOSING_TIMEOUT),
            },
          });
        }
      });
    });
  }

  render() {
    const {
      intl: { formatMessage },
      conversationId,
      composingNames,
      // composingUsers,
    } = this.props;

    const composingUsers = Object.keys(this.state.composingUsers);
    if (!composingUsers || composingUsers.length === 0) {
      return <></>;
    }

    let message;
    const interlocutor =
      retrieveInterlocutorIdFromConversationId(conversationId);
    if (interlocutor.groupId) {
      message =
        composingUsers.length > 1
          ? formatMessage(messages.multipleGroupChatComposing, {
              users: composingNames,
            })
          : formatMessage(messages.singleGroupChatComposing, {
              users: composingNames,
            });
    } else {
      message = formatMessage(messages.privateChatComposing);
    }

    return (
      <div className="m-1 font-italic" style={{ fontSize: '0.8em' }}>
        {message}
        <AnimatedDots />
      </div>
    );
  }
}

ChatWindowComposing.propTypes = {
  conversationId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    composingNames: getComposingUsersNames(state, ownProps.conversationId),
    composingUsers: getComposingUsers(state, ownProps.conversationId),
  };
}

export default injectIntl(
  connect(mapStateToProps, { pauseInterlocutor: receivePausedStatus })(
    ChatWindowComposing
  )
);
