export class ContactsEnums {
  static ContactsSharingTypes = {
    everybody: 'everybody',
    onlyMe: 'onlyMe',
    selected: 'selected',
  };
}

export const ABOOK_CONTACTS_PAGE_SIZE = 100;
export const ABOOK_LIST_CONTACTS_PAGE_SIZE = 500;
