import numeral from 'numeral';
import { select } from 'redux-saga/effects';
import moment from 'moment';
import { createIntl, createIntlCache } from 'react-intl';
import ynConf from '../../conf';
import { AVATAR_TYPES } from '../files/FileUtils';
import translations from '../../i18n/locales';
import { getPlatformLanguage } from '../settings/selectors';

export default class Utils {
  static getBrowserLocale() {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0].substr(0, 2);
    }
    if (navigator.userLanguage) {
      return navigator.userLanguage.substr(0, 2);
    }
    return navigator.language;
  }

  static reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  static retrieveFestivities = (excluded, added, year) => {
    excluded = excluded || [];
    added = added || [];
    year = year || Date.now().getFullYear();
    let festivities = [];
    if (excluded.indexOf('CAPODANNO') < 0) {
      festivities.push({ date: '01/01', description: 'CAPODANNO' });
    }
    if (excluded.indexOf('EPIFANIA') < 0) {
      festivities.push({ date: '06/01', description: 'EPIFANIA' });
    }
    if (excluded.indexOf('LIBERAZIONE') < 0) {
      festivities.push({ date: '25/04', description: 'LIBERAZIONE' });
    }
    if (excluded.indexOf('LAVORATORI') < 0) {
      festivities.push({ date: '01/05', description: 'LAVORATORI' });
    }
    if (excluded.indexOf('REPUBBLICA') < 0) {
      festivities.push({ date: '02/06', description: 'REPUBBLICA' });
    }
    if (excluded.indexOf('FERRAGOSTO') < 0) {
      festivities.push({ date: '15/08', description: 'FERRAGOSTO' });
    }
    if (excluded.indexOf('OGNISSANTI') < 0) {
      festivities.push({ date: '01/11', description: 'OGNISSANTI' });
    }
    if (excluded.indexOf('IMMACOLATA') < 0) {
      festivities.push({ date: '08/12', description: 'IMMACOLATA' });
    }
    if (excluded.indexOf('NATALE') < 0) {
      festivities.push({ date: '25/12', description: 'NATALE' });
    }
    if (excluded.indexOf('SANTO_STEFANO') < 0) {
      festivities.push({ date: '26/12', description: 'SANTO_STEFANO' });
    }
    // Add Easter day and Easter monday to festivity list
    const easter = this.getEasterDate(year);
    const easterMonday = moment(easter).add(1, 'days').toDate();
    if (excluded.indexOf('PASQUA') < 0) {
      festivities.push({
        date: `${numeral(easter.getDate()).format('00')}/${numeral(
          easter.getMonth() + 1
        ).format('00')}`,
        description: 'PASQUA',
      });
    }
    if (excluded.indexOf('PASQUETTA') < 0) {
      festivities.push({
        date: `${numeral(easterMonday.getDate()).format('00')}/${numeral(
          easterMonday.getMonth() + 1
        ).format('00')}`,
        description: 'PASQUETTA',
      });
    }
    festivities = festivities.concat(added);
    festivities.sort((a, b) =>
      moment(a.date, 'DD/MM').isBefore(moment(b.date, 'DD/MM')) ? -1 : 1
    );
    return festivities;
  };

  static isFestivity = (value, excluded, added) => {
    const festivities = this.retrieveFestivities(excluded, added, value.year());
    if (
      festivities
        .map((festivity) => festivity.date)
        .indexOf(
          `${numeral(value.date()).format('00')}/${numeral(
            value.month() + 1
          ).format('00')}`
        ) >= 0
    ) {
      return true;
    }
    return false;
  };

  static getEasterDate = (year) => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = h + l + 7 * m + 114;
    const n = Math.floor(n0 / 31) - 1;
    const p = (n0 % 31) + 1;
    const date = new Date(year, n, p);
    return date;
  };

  static approximatePunchingTime = (time, roundIn, roundOut) => {
    if (!roundIn && !roundOut) {
      return time;
    }
    const initialDate = moment(time);
    if (roundIn) {
      const missing = roundIn - (initialDate.minutes() % roundIn);
      if (missing !== roundIn) {
        return initialDate
          .add(missing, 'minutes')
          .seconds(0)
          .milliseconds(0)
          .valueOf();
      }
      return time;
    } else {
      const missing = initialDate.minutes() % roundOut;
      if (missing) {
        return initialDate
          .add(-missing, 'minutes')
          .seconds(0)
          .milliseconds(0)
          .valueOf();
      }
      return time;
    }
  };

  static getDatepickerFormat = (format) => {
    let datePickerFormat;
    datePickerFormat = format.replace(/ /gi, '/');
    datePickerFormat = datePickerFormat.replace('-', '/');
    datePickerFormat = datePickerFormat.replace(',', '');
    datePickerFormat = datePickerFormat.replace('mmmm', 'mm');
    datePickerFormat = datePickerFormat.replace('MMMM', 'MM');
    datePickerFormat = datePickerFormat.replace('mmm', 'mm');
    datePickerFormat = datePickerFormat.replace('MMM', 'MM');
    return datePickerFormat;
  };

  static getIntervalFormat = (milliseconds) => {
    const totalSeconds = Math.ceil(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds - minutes * 60;
    if (seconds < 10) seconds = `0${seconds}`;
    return `${minutes}' ${seconds}"`;
  };

  static getValorizedVariable = (
    higherPriorityValue,
    lowerPriorityValue,
    defaultValue
  ) => {
    if (
      typeof higherPriorityValue === 'undefined' ||
      higherPriorityValue === null
    ) {
      if (
        typeof lowerPriorityValue === 'undefined' ||
        lowerPriorityValue === null
      ) {
        return defaultValue;
      }
      return lowerPriorityValue;
    }
    return higherPriorityValue;
  };

  static isValidTime = (time) =>
    /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);

  static validatePhoneNumber = (value) => {
    if (!value) return false;
    const rex = /^\+*[\s0-9#*]+$/;
    return rex.test(value);
  };

  static adjustPhoneNumber = (num) =>
    num.replace(/^\+/, '00').replace(/[^*#\d]/g, '');

  static adjustAbilisPhoneNumber = (num) => Utils.adjustPhoneNumber(num) + '#';

  static adjustAbilisHtml = (html) =>
    html.replace(/(src)="([^"]*\/)*\/?([^"]*)"/gi, '$1=""');

  static retrieveAbilisTimezone = () => {
    const pad = (number, length) => {
      let str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    };

    let offset = new Date().getTimezoneOffset();
    offset =
      (offset < 0 ? '+' : '-') +
      pad(parseInt(Math.abs(offset / 60)), 2) +
      ':' +
      pad(Math.abs(offset % 60), 2);

    return offset;
  };

  static binarySearch = (array, f) => {
    if (!f) return null;

    let start = 0;
    let end = array.length - 1;
    let pos = 0;
    while (start <= end) {
      pos = Math.floor((start + end) / 2);
      const result = f(array, pos);
      if (result === 0) {
        return pos;
      }
      if (result < 0) {
        start = pos + 1;
      } else {
        end = pos - 1;
      }
    }
    return -1;
  };

  static DragAndDropTypes = {
    attendedTransfer: 'ATTENDED_TRANSFER',
    blindTransfer: 'BLIND_TRANSFER',
    queueTransfer: 'QUEUE_TRANSFER',
    stickyNote: 'STICKY_NOTE',
  };

  static checkApiError = (response) => {
    if (!response || !response.status) {
      // eslint-disable-next-line
      return 'NETWORK_ERROR';
    }
    if (response.status < 200 || response.status >= 400) {
      if (
        response.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        return response.data[0];
      }
      if (
        response.data &&
        response.data.length > 0 &&
        response.status === 400
      ) {
        return response.data;
      }
      return response.status;
    }
  };

  static getBytes = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes <= 0) {
      return '0';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  static bytesToMegabytes = (bytes) => bytes / (1024 * 1024);

  static bytesToGigabytes = (bytes) => bytes / (1024 * 1024 * 1024);

  static gigabytesToBytes = (giga) => giga * 1024 * 1024 * 1024;

  static delay = (ms) => new Promise((res) => setTimeout(res, ms));

  static asciiToHexa = (char) =>
    char ? Number(char.charCodeAt(0)).toString(16) : '';

  static getAbookFullname(name, surname, company) {
    let fullname = surname ? `${surname} ` : ' ';
    fullname += name ? `${name} ` : ' ';
    fullname += company ? `(${company})` : ' ';
    fullname = fullname.trim();

    return fullname;
  }

  static getAvatarUrl = (type, src, imageSize) =>
    `${ynConf.fileBaseUrl}/avatar/${
      type === AVATAR_TYPES.CONTACT ? 'contacts' : 'users'
    }/download/${src}?size=${imageSize}`;

  static isValidYnPassword = (password) => {
    return (
      password.length >= 8 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /\W|_/.test(password)
    );
  };

  static *retrieveIntl() {
    const lang = yield select(getPlatformLanguage);
    const messages = translations[lang];
    const intl = createIntl(
      {
        locale: lang,
        messages,
      },
      createIntlCache()
    );
    return intl;
  }

  static *getLanguage() {
    const lang = yield select(getPlatformLanguage);
    yield lang;
  }

  static getOSName = () => {
    // This script sets OSName variable as follows:
    // "Windows"    for all versions of Windows
    // "MacOS"      for all versions of Macintosh OS
    // "Linux"      for all versions of Linux
    // "UNIX"       for all other UNIX flavors
    // "iOS"        for all versions of iOS
    // "Android"    for all versions of Android
    // "Unknown OS" indicates failure to detect the OS

    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
    if (navigator.appVersion.indexOf('iPhone') !== -1) OSName = 'iOS';
    if (navigator.appVersion.indexOf('Android') !== -1) OSName = 'Android';
    return OSName;
  };

  static getFilenameExtension = (filename) => {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)[0];
  };

  static getFilenameWithoutExtension = (filename) => {
    const re = /(?:\.([^.]+))?$/;
    const { index } = re.exec(filename);
    return filename.substr(0, index);
  };

  static arrangeDate(datetime, dateFormat, timeFormat) {
    if (moment(datetime).isAfter(moment().startOf('day'))) {
      return { type: 'hour', when: moment(datetime).format(timeFormat) };
    }
    if (moment(datetime).isAfter(moment().startOf('day').subtract(1, 'day'))) {
      return { type: 'yesterday' };
    }
    return { type: 'day', when: moment(datetime).format(dateFormat) };
  }

  static getNameTruncated = (name, pageWidth) => {
    if (!name || !pageWidth) return;

    const columnWidth =
      pageWidth < 992
        ? pageWidth - 850
        : pageWidth < 1200
        ? pageWidth - 1060
        : pageWidth < 1400
        ? pageWidth - 1150
        : pageWidth - 1180;
    const maxChars = columnWidth / 8;

    if (name.length <= maxChars + 3) return name;

    return `${name.substr(0, maxChars)}...`;
  };

  static FISCAL_CODE_REGEX =
    '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$';

  static VAT_NUMBER_REGEX = '^[0-9]{11}$';

  static isThisFileAnImage = (filename) => {
    const extension = filename.split('.')[filename.split('.').length - 1];
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg')
      return true;
    return false;
  };

  static URL_REGEX =
    /([^\\"\\'])?((https?|ftps?|ed2k|file|fish|geo|git|sip|skype|sms|tag):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]+)/im;

  static PSEUDO_URL_REGEX = /(^|[^/])(www\.[\S]+(\b|$))/im;

  static EMAIL_REGEX = /(^|[\s\n\r])(([a-zA-Z0-9_\-.]+)@[a-zA-Z0-9_\-.]+)+/im;

  static GENERIC_PAGE_SIZE = 10;
}
