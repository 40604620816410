export class TicketEnums {
  static TicketQuestionTypes = {
    TEXT: 'TEXT',
    DATE: 'DATE',
    FILE: 'FILE',
    SELECT: 'SELECT',
    RADIO: 'RADIO',
    CHECKBOX: 'CHECKBOX',
    MAP: 'MAP',
  };

  static TicketStatuses = {
    OPEN: 'OPEN',
    ASSIGNED: 'ASSIGNED',
    TAKEN: 'TAKEN',
    SUSPENDED: 'SUSPENDED',
    CLOSED: 'CLOSED',
  };

  static TicketActions = {
    OPEN: 'OPEN',
    ASSIGN: 'ASSIGN',
    UNASSIGN: 'UNASSIGN',
    TAKE: 'TAKE',
    WORK: 'WORK',
    CHANGESTATUS: 'CHANGESTATUS',
    SUSPEND: 'SUSPEND',
    SHARE: 'SHARE',
    UNSHARE: 'UNSHARE',
    CLOSE: 'CLOSE',
    REOPEN: 'REOPEN',
    CHANGE_CATEGORY: 'CHANGE_CATEGORY',
  };

  static TicketPriority = {
    0: 'urgent',
    1: 'high',
    2: 'medium',
    3: 'low',
  };

  static PriorityBadgeBackgroundColors = {
    0: '#6610f2',
    1: '#dc3545',
    2: '#ffc107',
    3: '#28a745',
  };

  static PriorityBadgeBackgroundDarkenColors = {
    0: '#4D00D9',
    1: '#C31C2C',
    2: '#E6A800',
    3: '#0F8E2C',
  };

  static PriorityBadgeTextColors = {
    0: 'white',
    1: 'white',
    2: '#212529',
    3: 'white',
  };

  static orderType = {
    PRIORITY: 'priority',
    RESOLUTION: 'resolution',
    CREATION: 'creation',
  };

  static orderDirection = {
    ASC: 'asc',
    DESC: 'desc',
  };

  static TICKETS_PAGE_SIZE = 10;

  static defaultExportColumns = {
    INSERT_TIME: 'INSERT_TIME',
    OWNER: 'OWNER',
    ASSIGNEE: 'ASSIGNEE',
    NUMBER: 'NUMBER',
    OBJECT: 'OBJECT',
    STATUS: 'STATUS',
    LAST_UPDATE_TIME: 'LAST_UPDATE_TIME',
    DESCRIPTION: 'DESCRIPTION',
    PRIORITY: 'PRIORITY',
    CATEGORY: 'CATEGORY',
    RESOLUTION_DATE: 'RESOLUTION_DATE',
    CONTACT: 'CONTACT',
  };
}

export const existingTicketStatusError = 'EXISTING_STATUS';
export const existingTicketCategoryError = 'EXISTING_CATEGORY';
