/* eslint-disable jsx-a11y/label-has-for */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Label, FormGroup } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { loginRequest } from '../../js/auth/actions';
import ToastMessage from '../../components/messages/ToastMessage';
import Password from '../../components/formControls/Password';
import Button from '../../components/formControls/Button';
import Input from '../../components/formControls/Input';

const messages = defineMessages({
  username: {
    id: 'LoginForm.label.username',
    defaultMessage: 'Username or email:',
  },
  password: {
    id: 'LoginForm.label.password',
    defaultMessage: 'Password:',
  },
  persistent: {
    id: 'LoginForm.label.persistent',
    defaultMessage: 'Keep me logged in',
  },
  errorUsername: {
    id: 'LoginForm.error.username',
    defaultMessage: 'You must enter a valid username or email address',
  },
  errorPassword: {
    id: 'LoginForm.error.password',
    defaultMessage: 'You must enter a valid password',
  },
  buttonLogin: {
    id: 'LoginForm.button.login',
    defaultMessage: 'Login',
  },
  401: {
    id: 'LoginForm.serverError.401',
    defaultMessage: 'Invalid credentials',
  },
  403: {
    id: 'LoginForm.serverError.403',
    defaultMessage: 'You are blocked. Contact your admin',
  },
});

const serverErrors = {
  401: messages[401],
};

class LoginForm extends Component {
  state = {
    data: {
      username: '',
      password: '',
      persistent: false,
    },
    errors: {},
  };

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.type === 'checkbox'
            ? e.target.checked
            : e.target.value.trim(),
      },
    });
    const errors = {
      ...this.state.errors,
    };
    delete errors[e.target.name];
    this.setState({ errors });
  };

  onChangePassword = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        password: e.target.value.trim(),
      },
    });
    const errors = {
      ...this.state.errors,
    };
    delete errors.password;
    this.setState({ errors });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.props.submit(this.state.data);
    }
  };

  validate = (data) => {
    const errors = {};
    if (!data.username)
      errors.username = this.props.intl.formatMessage(messages.errorUsername);
    if (!data.password)
      errors.password = this.props.intl.formatMessage(messages.errorPassword);
    return errors;
  };

  render() {
    const {
      serverError,
      loading,
      intl: { formatMessage },
    } = this.props;
    const { data, errors } = this.state;

    return (
      <Form
        onSubmit={this.onSubmit}
        noValidate
        className="p-4 rounded text-left needs-validation"
      >
        {serverError && (
          <ToastMessage
            type="danger"
            closeTimeout={2000}
            text={
              serverErrors[serverError] &&
              formatMessage(serverErrors[serverError])
            }
            messageCode={serverError}
          />
        )}
        <FormGroup>
          <Label for="username">{formatMessage(messages.username)}</Label>
          <Input
            type="text"
            name="username"
            id="username"
            value={data.username}
            onChange={this.onChange}
            error={errors.username}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">{formatMessage(messages.password)}</Label>
          <Password
            name="loginPassword"
            id="loginPassword"
            onChange={this.onChangePassword}
            value={data.password}
            error={errors.password}
            loginPassword
          />
        </FormGroup>
        <div className="custom-control custom-checkbox custom-control-inline text-right">
          <input
            type="checkbox"
            id="persistent"
            name="persistent"
            checked={data.persistent}
            onChange={this.onChange}
            className="custom-control-input"
          />
          <label className="custom-control-label" htmlFor="persistent">
            {formatMessage(messages.persistent)}
          </label>
        </div>
        <div className="pt-3">
          <Button
            className="btn btn-primary btn-block"
            loading={loading}
            text={formatMessage(messages.buttonLogin)}
          />
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    serverError: state.auth.error.login && state.auth.error.login.status,
    loading: !state.auth.loaded,
  };
}

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default injectIntl(
  connect(mapStateToProps, {
    submit: loginRequest,
  })(LoginForm)
);
