import { request } from '../request';

export default {
  getVideocallsList: (params) =>
    request({
      url: '/videocalls/calls',
      method: 'GET',
      params,
    }),
  getGuestToken: (params) =>
    request({
      url: '/videocalls/guest-url',
      method: 'POST',
      data: params,
    }),
  sendGuestEmailAddress: (params) =>
    request({
      url: '/videocalls/guest-info',
      method: 'POST',
      data: params,
    }).catch(() => ({
      status: 200,
    })),
  createScheduledVideocall: (params) =>
    request({
      url: '/videocalls/scheduled',
      method: 'POST',
      data: params,
    }),
  editScheduledVideocall: (params) =>
    request({
      url: `/videocalls/scheduled/${params.room}`,
      method: 'POST',
      data: params,
    }),
  getScheduledVideocallsList: (params) =>
    request({
      url: '/videocalls/scheduled',
      method: 'GET',
      params,
    }),
  enterVideocall: (params) =>
    request({
      url: `/videocalls/scheduled/${params.room}/exec`,
      method: 'POST',
      data: params,
    }),
  checkVideocall: (room) =>
    request({
      url: `videocalls/scheduled/${room}/authorizations`,
      method: 'GET',
    }),
  deleteScheduledVideocall: (room) =>
    request({
      url: `/videocalls/scheduled/${room}`,
      method: 'DELETE',
    }),
  inviteVideocall: (params) =>
    request({
      url: `/videocalls/scheduled/${params.room}/invite`,
      method: 'POST',
      data: params.data,
    }),
  getVideocallSettings: () =>
    request({
      url: '/videocalls/settings',
      method: 'GET',
    }),
  saveVideocallSettings: (params) =>
    request({
      url: `/videocalls/settings`,
      method: 'POST',
      data: params,
    }),
  saveVideocallChat: (room) =>
    request(
      {
        url: `/videocalls/calls/${room}/chat`,
        method: 'POST',
      },
      {
        baseUrl: '/files/v1',
      }
    ),
  getVideocallNotifications: () =>
    request({
      url: '/videocalls/errors',
      method: 'GET',
    }),
  getVideocallInvitations: (id) =>
    request({
      url: `/videocalls/scheduled/${id}/invitations`,
      method: 'GET',
    }),
};
