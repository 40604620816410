import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { hasGrants } from '../../../js/me/selectors';
import { getWebNotificationsContactsNumber } from '../../../js/notifications/selectors';

const messages = defineMessages({
  title: {
    id: 'Abook.title',
    defaultMessage: 'Address book',
  },
  contacts: {
    id: 'Abook.contacts.navLabel',
    defaultMessage: 'Contacts',
  },
  lists: {
    id: 'Abook.lists.navLabel',
    defaultMessage: 'Lists',
  },
  manage: {
    id: 'Abook.manage.navLabel',
    defaultMessage: 'Manage',
  },
  notifications: {
    id: 'Abook.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
  import: {
    id: 'Abook.import.navLabel',
    defaultMessage: 'Import',
  },
  export: {
    id: 'Abook.export.navLabel',
    defaultMessage: 'Export',
  },
  delete: {
    id: 'Abook.delete.navLabel',
    defaultMessage: 'Delete',
  },
});
const Abook = ({
  intl: { formatMessage },
  children,
  scrolling,
  filter,
  notificationsQuantity,
  canImport,
  canExport,
}) => {
  const nav = [
    {
      id: 'Abook.contacts.navLabel',
      href: '/abook/contacts',
      label: messages.contacts,
    },
    {
      id: 'Abook.manage.navLabel',
      href: '/abook/manage',
      label: messages.manage,
      subNav: [
        canImport
          ? {
              id: 'Abook.import.navLabel',
              href: '/abook/manage/import',
              label: messages.import,
            }
          : {},
        canExport
          ? {
              id: 'Abook.export.navLabel',
              href: '/abook/manage/export',
              label: messages.export,
            }
          : {},
        {
          id: 'Abook.delete.navLabel',
          href: '/abook/manage/remove',
          label: messages.delete,
        },
      ],
    },
    {
      id: 'Abook.lists.navLabel',
      href: '/abook/lists',
      label: messages.lists,
    },
    {
      id: 'Abook.notifications.navLabel',
      href: '/abook/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    },
  ];
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling
            ? filter
              ? 'yn-section-with-nav-and-filter-scrolling'
              : 'yn-section-with-nav-scrolling'
            : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsContactsNumber(state),
    canImport: hasGrants(state, 'ADDRESSBOOK_IMPORT'),
    canExport: hasGrants(state, 'ADDRESSBOOK_EXPORT'),
  };
}
export default injectIntl(connect(mapStateToProps)(Abook));
