import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import equals from 'validator/lib/equals';
import { FormGroup } from 'reactstrap';
import Label from '../../components/formControls/Label';
import Button from '../../components/formControls/Button';
import Password from '../../components/formControls/Password';
import StrengthMeter from '../../components/passwordStrength/StrengthMeter';
import LinkButton from '../../components/LinkButton';
import ToastMessage from '../../components/messages/ToastMessage';
import Utils from '../../js/lib/utils';

const messages = defineMessages({
  currentPassword: {
    id: 'PasswordSettingsForm.label.currentPassword',
    defaultMessage: 'Current Password',
  },
  new: {
    id: 'PasswordSettingsForm.label.new',
    defaultMessage: 'New',
  },
  repeatNew: {
    id: 'PasswordSettingsForm.label.repeatNew',
    defaultMessage: 'Repeat New',
  },
  save: {
    id: 'PasswordSettingsForm.button.save',
    defaultMessage: 'Save',
  },
  logout: {
    id: 'PasswordSettingsForm.button.logout',
    defaultMessage: 'Logout',
  },
  chars: {
    id: 'PasswordSettingsForm.label.char',
    defaultMessage: 'Enter at least 8 characters, ',
  },
  errorCurrent: {
    id: 'PasswordSettingsForm.error.errorCurrent',
    defaultMessage: 'The current password is empty',
  },
  errorEmptyNew: {
    id: 'PasswordSettingsForm.error.errorEmptyNew',
    defaultMessage: 'The new password is empty',
  },
  errorEmptyReapeat: {
    id: 'PasswordSettingsForm.error.errorEmptyReapeat',
    defaultMessage: 'The reapeat new password is empty',
  },
  errorNew: {
    id: 'PasswordSettingsForm.error.errorNew',
    defaultMessage: "The password doesn't match with the confirmation",
  },
  errorRepeatNew: {
    id: 'PasswordSettingsForm.error.errorRepeatNew',
    defaultMessage: "The password doesn't match with the confirmation",
  },
  errorPattern: {
    id: 'PasswordSettingsForm.error.errorPattern',
    defaultMessage:
      "The password you entered doesn't meet password policy requirements",
  },
  403: {
    id: 'PasswordSettingsForm.error.403',
    defaultMessage: 'The current password is not correct',
  },
  200: {
    id: 'PasswordSettingsForm.success.200',
    defaultMessage: 'Your password has been reset successfully',
  },
  400: {
    id: 'PasswordSettingsForm.error.400',
    defaultMessage: 'Check that filled fields respect requirements',
  },
  genericError: {
    id: 'PasswordSettingsForm.error.genericError',
    defaultMessage: 'Error while updating the pasword',
  },
  modal: {
    id: 'PasswordSettingsForm.label.modal',
    defaultMessage: "Don't you remember the password?",
  },
  emailButton: {
    id: 'PasswordSettingsForm.button.emailButton',
    defaultMessage: 'Set up by email',
  },
});

const successes = {
  200: messages[200],
};

class PasswordSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        current: '',
        new: '',
      },
      repeatNew: '',
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.id]: e.target.value,
      },
    });
    const errors = {
      ...this.state.errors,
    };
    delete errors[e.target.id];
    this.setState({ errors });
  };

  onChangePassword = (e) => {
    this.setState({
      repeatNew: e.target.value,
    });
    const errors = {
      ...this.state.errors,
    };
    delete errors.repeatNew;
    this.setState({ errors });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data, this.state.repeatNew);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.props.onSubmit(this.state.data);
    }
  };

  validate = (data, repeatNew) => {
    const errors = {};
    if (!data.current) {
      errors.current = this.props.intl.formatMessage(messages.errorCurrent);
    }
    if (!data.new) {
      errors.new = this.props.intl.formatMessage(messages.errorEmptyNew);
    }
    if (!repeatNew) {
      errors.repeatNew = this.props.intl.formatMessage(
        messages.errorEmptyReapeat
      );
    }
    if (!equals(data.new, repeatNew)) {
      errors.new = this.props.intl.formatMessage(messages.errorNew);
      errors.repeatNew = this.props.intl.formatMessage(messages.errorRepeatNew);
    }
    if (!Utils.isValidYnPassword(data.new)) {
      errors.new = this.props.intl.formatMessage(messages.errorPattern);
    }
    return errors;
  };

  render() {
    const { data, errors } = this.state;
    const {
      email,
      error,
      success,
      loading,
      onLogout,
      onReset,
      intl: { formatMessage },
    } = this.props;
    return (
      <>
        <div className="form-row">
          <div className="col-xl-12">
            {success && (
              <ToastMessage
                type="success"
                text={successes[success] && formatMessage(successes[success])}
                messageCode={success}
              />
            )}
            {error && (
              <ToastMessage
                type="danger"
                text={
                  messages[error]
                    ? formatMessage(messages[error])
                    : formatMessage(messages.genericError)
                }
                messageCode={error}
              />
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-xl-12">
            <FormGroup>
              <Label for="current" mandatory>
                {formatMessage(messages.currentPassword)}
              </Label>
              <Password
                name="current"
                id="current"
                onChange={this.onChange}
                value={data.current}
                error={errors.current}
              />
              {email && email !== '' && (
                <div className="mt-1">
                  <span>{formatMessage(messages.modal)}</span>
                  <LinkButton onClick={onReset}>
                    {formatMessage(messages.emailButton)}
                  </LinkButton>
                </div>
              )}
            </FormGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="col-xl-12">
            <FormGroup>
              <Label for="new" mandatory>
                {formatMessage(messages.new)}
              </Label>
              <Password
                name="new"
                id="new"
                value={data.new}
                onChange={this.onChange}
                error={errors.new}
              />
              <StrengthMeter password={data.new} />
            </FormGroup>
          </div>
        </div>
        <div className="form-row">
          <div className="col-xl-12">
            <FormGroup>
              <Label for="repeatNew" mandatory>
                {formatMessage(messages.repeatNew)}
              </Label>
              <Password
                name="repeatNew"
                id="repeatNew"
                value={this.state.repeatNew}
                onChange={this.onChangePassword}
                error={errors.repeatNew}
              />
            </FormGroup>
          </div>
        </div>
        <div className="pt-3 text-center">
          <Button
            className="btn btn-primary"
            text={formatMessage(messages.save)}
            loading={loading}
            onClick={this.onSubmit}
          />
          {onLogout && (
            <Button
              className="btn btn-outline-primary ml-2"
              text={formatMessage(messages.logout)}
              onClick={onLogout}
            />
          )}
        </div>
      </>
    );
  }
}

PasswordSettingsForm.propTypes = {
  error: PropTypes.number,
  success: PropTypes.number,
  loading: PropTypes.bool,
  onLogout: PropTypes.func,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
};

PasswordSettingsForm.defaultProps = {
  error: null,
  success: null,
  loading: false,
  onLogout: null,
  onReset: null,
  email: null,
};

export default injectIntl(PasswordSettingsForm);
