export const showSticky = (state) => state.settings.stickyShow;
export const getPlatformVolume = (state) => state.settings.volume;
export const isCallNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifCalls;
export const isFaxNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifFax;
export const isCalendarNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifCalendar;
export const isUsersNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifUsers;
export const isDataNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifData;
export const isMailofficeNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifMailoffice;
export const isVideocallsNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifVideocalls;
export const isVboxNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifVbox;
export const isChatNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifMessages;
export const isPresencesNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifPresences;
export const isTicketNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifTicket;
export const isFileboxNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifFilebox;
export const isVideocallNotificationEnabled = (state) =>
  state.settings.notifEnabled && state.settings.notifVideocalls;
export const isAutoChatOpen = (state) => state.settings.autoChatOpen;
export const getPlatformLanguage = (state) => state.settings.language;
export const getDateFormat = (state) => state.settings.dateFormat;
export const getTimeFormat = (state) => state.settings.timeFormat;
export const getDatetimeFormat = (state) =>
  `${state.settings.dateFormat} ${state.settings.timeFormat}`;
export const isRestoreNotificationsSettingsSuccess = (state) =>
  state.settings.restoreNotificationsSettings.success;
export const isRestoreNotificationsSettingsError = (state) =>
  state.settings.restoreNotificationsSettings.error;
export const isNotificationsSettingsSuccess = (state) =>
  state.settings.notificationSettings.success;
export const isNotificationsSettingsError = (state) =>
  state.settings.notificationSettings.error;
export const isNotificationsSettingsLoaded = (state) =>
  state.settings.notificationSettings.loaded;
export const isRestoreNotificationsSettingsLoaded = (state) =>
  state.settings.restoreNotificationsSettings.loaded;
export const getSettings = (state) => state.settings;
export const getOwnViewedName = (state) => state.settings.ownViewedName;
export const getFileboxConfigurations = (state) =>
  state.settings.configurations.filebox;
export const getVideocallRecordingFolder = (state) =>
  state.videocalls.settings.videocallRecordingFolder;
export const getVideocallChatFolder = (state) =>
  state.videocalls.settings.videocallChatFolder;
