import React from 'react';
import Filebox from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const FileboxList = React.lazy(() => import('./FileboxList'));

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const FileboxListPage = (props) => (
  <Filebox>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="filebox" />
          </div>
          <FileboxList {...props} />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Filebox>
);

export default FileboxListPage;
