import React, { Component } from 'react';
import { Fade } from 'reactstrap';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { hangupCallRequest } from '../../../js/phone/actions';
import Button from '../../../components/formControls/Button';
import InterlocutorPanel from '../../../components/InterlocutorPanel';

const messages = defineMessages({
  header: {
    id: 'MemberConferencePanel.header',
    defaultMessage: 'On going conference',
  },
  body: {
    id: 'MemberConferencePanel.body',
    defaultMessage: 'Conference',
  },
});

class MemberConferencePanel extends Component {
  handleHangup = () => {
    this.props.hangup(this.props.callId);
  };

  render() {
    return (
      <Fade id="ConferencePanelMember" className="card mt-2">
        <div className="card-header text-left small p-1">
          <div className="row">
            <div className="col-12">
              {this.props.intl.formatMessage(messages.header)}
            </div>
          </div>
        </div>
        <div className="card-body p-1 text-left small border-bottom">
          <InterlocutorPanel
            ellipsis
            interlocutor={{
              fullname: this.props.intl.formatMessage(messages.body),
              avatar: 'conference.svg',
              number: ' ',
            }}
          />
        </div>
        <Button
          text="Hang up"
          className="bt btn-danger btn-block"
          onClick={this.handleHangup}
        />
      </Fade>
    );
  }
}
export default injectIntl(
  connect(null, {
    hangup: hangupCallRequest,
  })(MemberConferencePanel)
);
