import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  BUY_SMS_PACKAGES_FAILURE,
  BUY_SMS_PACKAGES_REQUEST,
  BUY_SMS_PACKAGES_SUCCESS,
  DELETE_SMS_CAMPAIGN_FAILURE,
  DELETE_SMS_CAMPAIGN_REQUEST,
  DELETE_SMS_CAMPAIGN_SUCCESS,
  DELETE_SMS_SCHEDULED_FAILURE,
  DELETE_SMS_SCHEDULED_REQUEST,
  DELETE_SMS_SCHEDULED_SUCCESS,
  FETCH_SMS_ALIAS_FAILURE,
  FETCH_SMS_ALIAS_REQUEST,
  FETCH_SMS_ALIAS_SUCCESS,
  FETCH_SMS_CAMPAIGNS_FAILURE,
  FETCH_SMS_CAMPAIGNS_REQUEST,
  FETCH_SMS_CAMPAIGNS_SUCCESS,
  FETCH_SMS_CAMPAIGN_FAILURE,
  FETCH_SMS_CAMPAIGN_REQUEST,
  FETCH_SMS_CAMPAIGN_SUCCESS,
  FETCH_SMS_CREDITS_FAILURE,
  FETCH_SMS_CREDITS_REQUEST,
  FETCH_SMS_CREDITS_SUCCESS,
  FETCH_SMS_LIST_FAILURE,
  FETCH_SMS_LIST_REQUEST,
  FETCH_SMS_LIST_SUCCESS,
  FETCH_SMS_SCHEDULED_FAILURE,
  FETCH_SMS_SCHEDULED_REQUEST,
  FETCH_SMS_SCHEDULED_SUCCESS,
  SAVE_SMS_ALIAS_FAILURE,
  SAVE_SMS_ALIAS_REQUEST,
  SAVE_SMS_ALIAS_SUCCESS,
  SAVE_SMS_CAMPAIGN_FAILURE,
  SAVE_SMS_CAMPAIGN_REQUEST,
  SAVE_SMS_CAMPAIGN_SUCCESS,
  SAVE_SMS_SCHEDULED_FAILURE,
  SAVE_SMS_SCHEDULED_REQUEST,
  SAVE_SMS_SCHEDULED_SUCCESS,
  SEND_SMS_FAILURE,
  SEND_SMS_REQUEST,
  SEND_SMS_SUCCESS,
  SET_SMS_LIST_FILTER,
} from './types';
import { SMS_LIST_FILTER } from './SMSUtils';

const initialBaseState = {
  fetchSMSListLoaded: true,
  fetchSMSListError: null,
  sendSMSLoaded: true,
  sendSMSError: null,
  fetchSMSCreditsLoaded: true,
  fetchSMSCreditsError: null,
  fetchSMSScheduledLoaded: true,
  fetchSMSScheduledError: null,
  saveSMSScheduledLoaded: true,
  saveSMSScheduledError: null,
  deleteSMSScheduledLoaded: true,
  deleteSMSScheduledError: null,
  fetchSMSAliasLoaded: true,
  fetchSMSAliasError: null,
  saveSMSAliasLoaded: true,
  saveSMSAliasError: null,
  fetchSMSCampaignsLoaded: true,
  fetchSMSCampaignsError: null,
  fetchSMSCampaignLoaded: true,
  fetchSMSCampaignError: null,
  saveSMSCampaignLoaded: true,
  saveSMSCampaignError: null,
  deleteSMSCampaignLoaded: true,
  deleteSMSCampaignError: null,
  buySMSPackagesLoaded: true,
  buySMSPackagesError: null,
};

const initialstate = {
  ...initialBaseState,
  filter: SMS_LIST_FILTER,
  SMSList: { byId: {}, allIds: [], currentPage: 0, total: 0 },
  SMSScheduled: { byId: {}, allIds: [] },
  SMSAlias: { byId: {}, allIds: [] },
  SMSCampaigns: { byId: {}, allIds: [], currentPage: 0, total: 0 },
  SMSCreditsFetched: false,
  SMSCredits: 0,
  nextMonthEventSms: 0,
  nextMonthScheduledSms: 0,
  packages: [],
  useAlias: false,
  registerAlias: false,
  lastRoute: [],
};

export default function sms(state = initialstate, action = {}) {
  switch (action.type) {
    case SET_SMS_LIST_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case FETCH_SMS_LIST_REQUEST:
      return {
        ...state,
        fetchSMSListLoaded: false,
        fetchSMSListError: false,
      };
    case FETCH_SMS_LIST_SUCCESS:
      return {
        ...state,
        fetchSMSListLoaded: true,
        fetchSMSListError: false,
        SMSList: {
          byId: keyBy(action.payload.data, 'id'),
          allIds: action.payload.data.map((row) => row.id),
          total: action.payload.total,
          currentPage: action.payload.page,
        },
      };
    case FETCH_SMS_LIST_FAILURE:
      return {
        ...state,
        fetchSMSListLoaded: true,
        fetchSMSListError: action.payload.error,
      };
    case SEND_SMS_REQUEST:
      return {
        ...state,
        sendSMSLoaded: false,
        sendSMSError: false,
      };
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        sendSMSLoaded: true,
        sendSMSError: false,
      };
    case SEND_SMS_FAILURE:
      return {
        ...state,
        sendSMSLoaded: true,
        sendSMSError: action.payload.error,
      };
    case FETCH_SMS_CREDITS_REQUEST:
      return {
        ...state,
        fetchSMSCreditsLoaded: false,
        fetchSMSCreditsError: false,
      };
    case FETCH_SMS_CREDITS_SUCCESS:
      return {
        ...state,
        fetchSMSCreditsLoaded: true,
        fetchSMSCreditsError: false,
        SMSCreditsFetched: true,
        SMSCredits: action.payload.total,
        nextMonthEventSms: action.payload.nextMonthEventSms,
        nextMonthScheduledSms: action.payload.nextMonthScheduledSms,
        packages: action.payload.packages,
        useAlias: action.payload.useAlias,
        registerAlias: action.payload.registerAlias,
        useLink: action.payload.useLink,
      };
    case FETCH_SMS_CREDITS_FAILURE:
      return {
        ...state,
        fetchSMSCreditsLoaded: true,
        fetchSMSCreditsError: action.payload.error,
      };
    case FETCH_SMS_SCHEDULED_REQUEST:
      return {
        ...state,
        fetchSMSScheduledLoaded: false,
        fetchSMSScheduledError: false,
      };
    case FETCH_SMS_SCHEDULED_SUCCESS:
      return {
        ...state,
        fetchSMSScheduledLoaded: true,
        fetchSMSScheduledError: false,
        SMSScheduled: {
          byId: keyBy(action.payload.data, 'id'),
          allIds: action.payload.data.map((row) => row.id),
        },
      };
    case FETCH_SMS_SCHEDULED_FAILURE:
      return {
        ...state,
        saveSMSScheduledLoaded: true,
        saveSMSScheduledError: action.payload.error,
      };
    case SAVE_SMS_SCHEDULED_REQUEST:
      return {
        ...state,
        saveSMSScheduledLoaded: false,
        saveSMSScheduledError: false,
      };
    case SAVE_SMS_SCHEDULED_SUCCESS:
      return {
        ...state,
        saveSMSScheduledLoaded: true,
        saveSMSScheduledError: false,
      };
    case SAVE_SMS_SCHEDULED_FAILURE:
      return {
        ...state,
        saveSMSScheduledLoaded: true,
        saveSMSScheduledError: action.payload.error,
      };
    case DELETE_SMS_SCHEDULED_REQUEST:
      return {
        ...state,
        deleteSMSScheduledLoaded: false,
        deleteSMSScheduledError: false,
      };
    case DELETE_SMS_SCHEDULED_SUCCESS:
      return {
        ...state,
        deleteSMSScheduledLoaded: true,
        deleteSMSScheduledError: false,
      };
    case DELETE_SMS_SCHEDULED_FAILURE:
      return {
        ...state,
        deleteSMSScheduledLoaded: true,
        deleteSMSScheduledError: action.payload.error,
      };
    case FETCH_SMS_ALIAS_REQUEST:
      return {
        ...state,
        fetchSMSAliasLoaded: false,
        fetchSMSAliasError: false,
      };
    case FETCH_SMS_ALIAS_SUCCESS:
      return {
        ...state,
        fetchSMSAliasLoaded: true,
        fetchSMSAliasError: false,
        SMSAlias: action.payload.data
          ? {
              byId: keyBy(action.payload.data, 'id'),
              allIds: action.payload.data.map((row) => row.id),
            }
          : [],
      };
    case FETCH_SMS_ALIAS_FAILURE:
      return {
        ...state,
        saveSMSAliasLoaded: true,
        saveSMSAliasError: action.payload.error,
      };
    case SAVE_SMS_ALIAS_REQUEST:
      return {
        ...state,
        saveSMSAliasLoaded: false,
        saveSMSAliasError: false,
      };
    case SAVE_SMS_ALIAS_SUCCESS:
      return {
        ...state,
        saveSMSAliasLoaded: true,
        saveSMSAliasError: false,
      };
    case SAVE_SMS_ALIAS_FAILURE:
      return {
        ...state,
        saveSMSAliasLoaded: true,
        saveSMSAliasError: action.payload.error,
      };
    case FETCH_SMS_CAMPAIGNS_REQUEST:
      return {
        ...state,
        fetchSMSCampaignsLoaded: false,
        fetchSMSCampaignsError: false,
      };
    case FETCH_SMS_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        fetchSMSCampaignsLoaded: true,
        fetchSMSCampaignsError: false,
        SMSCampaigns: {
          byId: keyBy(action.payload.data, 'id'),
          allIds: action.payload.data.map((row) => row.id),
          total: action.payload.total,
          currentPage: action.payload.page,
        },
      };
    case FETCH_SMS_CAMPAIGNS_FAILURE:
      return {
        ...state,
        fetchSMSCampaignsLoaded: true,
        fetchSMSCampaignsError: action.payload.error,
      };
    case FETCH_SMS_CAMPAIGN_REQUEST:
      return {
        ...state,
        fetchSMSCampaignLoaded: false,
        fetchSMSCampaignError: false,
      };
    case FETCH_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        fetchSMSCampaignLoaded: true,
        fetchSMSCampaignError: false,
        SMSCampaigns: {
          ...state.SMSCampaigns,
          byId: {
            ...state.SMSCampaigns.byId,
            [action.payload.id]: action.payload,
          },
        },
      };
    case FETCH_SMS_CAMPAIGN_FAILURE:
      return {
        ...state,
        fetchSMSCampaignLoaded: true,
        fetchSMSCampaignError: action.payload.error,
      };
    case SAVE_SMS_CAMPAIGN_REQUEST:
      return {
        ...state,
        saveSMSCampaignLoaded: false,
        saveSMSCampaignError: false,
      };
    case SAVE_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        saveSMSCampaignLoaded: true,
        saveSMSCampaignError: false,
      };
    case SAVE_SMS_CAMPAIGN_FAILURE:
      return {
        ...state,
        saveSMSCampaignLoaded: true,
        saveSMSCampaignError: action.payload.error,
      };
    case DELETE_SMS_CAMPAIGN_REQUEST:
      return {
        ...state,
        deleteSMSCampaignLoaded: false,
        deleteSMSCampaignError: false,
      };
    case DELETE_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        deleteSMSCampaignLoaded: true,
        deleteSMSCampaignError: false,
      };
    case DELETE_SMS_CAMPAIGN_FAILURE:
      return {
        ...state,
        deleteSMSCampaignLoaded: true,
        deleteSMSCampaignError: action.payload.error,
      };
    case BUY_SMS_PACKAGES_REQUEST:
      return {
        ...state,
        buySMSPackagesLoaded: false,
        buySMSPackagesError: null,
      };
    case BUY_SMS_PACKAGES_SUCCESS:
      return {
        ...state,
        buySMSPackagesLoaded: true,
        buySMSPackagesError: null,
      };
    case BUY_SMS_PACKAGES_FAILURE:
      return {
        ...state,
        buySMSPackagesLoaded: true,
        buySMSPackagesError: action.errors,
      };
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      const newState =
        lastRoute[0] === newRoute[0] && lastRoute[1] === newRoute[1]
          ? { ...state, ...initialBaseState }
          : {
              ...state,
              filter: SMS_LIST_FILTER,
              SMSScheduled: { byId: {}, allIds: [] },
              SMSList: { byId: {}, allIds: [], currentPage: 0, total: 0 },
              SMSCampaigns: { byId: {}, allIds: [], currentPage: 0, total: 0 },
            };
      return {
        ...newState,
        lastRoute: newRoute,
      };
    }
    case LOGOUT_SUCCESS:
      return initialstate;
    default:
      return state;
  }
}
