import React from 'react';
import Tickets from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const TicketsFieldsTree = React.lazy(() => import('./TicketsFieldsTree'));
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const TicketsFieldsPage = () => (
  <Tickets>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right mb-2">
            <HelpButton fileName="tickets-customized-fields" />
          </div>
          <TicketsFieldsTree />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Tickets>
);

export default TicketsFieldsPage;
