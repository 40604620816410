import pull from 'lodash.pull';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_VIDEOCALLS_LIST_REQUEST,
  FETCH_VIDEOCALLS_LIST_SUCCESS,
  FETCH_VIDEOCALLS_LIST_FAILURE,
  SEND_GUEST_EMAIL_ADDRESS_REQUEST,
  SEND_GUEST_EMAIL_ADDRESS_SUCCESS,
  SEND_GUEST_EMAIL_ADDRESS_FAILURE,
  SAVE_SCHEDULED_VIDEOCALL_REQUEST,
  SAVE_SCHEDULED_VIDEOCALL_SUCCESS,
  SAVE_SCHEDULED_VIDEOCALL_FAILURE,
  FETCH_SCHEDULED_VIDEOCALLS_REQUEST,
  FETCH_SCHEDULED_VIDEOCALLS_SUCCESS,
  FETCH_SCHEDULED_VIDEOCALLS_FAILURE,
  ENTER_VIDEOCALL_FAILURE,
  ENTER_VIDEOCALL_REQUEST,
  ENTER_VIDEOCALL_SUCCESS,
  LEAVE_VIDEOCALL_SUCCESS,
  JOIN_VIDEOCALL_SUCCESS,
  CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS,
  CHECK_VIDEOCALL_AUTHORIZATION_FAILURE,
  DELETE_SCHEDULED_VIDEOCALL_SUCCESS,
  DELETE_SCHEDULED_VIDEOCALL_FAILURE,
  DELETE_SCHEDULED_VIDEOCALL_REQUEST,
  INVITE_VIDEOCALL_REQUEST,
  INVITE_VIDEOCALL_SUCCESS,
  INVITE_VIDEOCALL_FAILURE,
  FETCH_VIDEOCALL_SETTINGS_REQUEST,
  FETCH_VIDEOCALL_SETTINGS_SUCCESS,
  FETCH_VIDEOCALL_SETTINGS_FAILURE,
  SAVE_VIDEOCALL_SETTINGS_REQUEST,
  SAVE_VIDEOCALL_SETTINGS_SUCCESS,
  SAVE_VIDEOCALL_SETTINGS_FAILURE,
  SAVE_VIDEOCALL_CHAT_REQUEST,
  SAVE_VIDEOCALL_CHAT_SUCCESS,
  SAVE_VIDEOCALL_CHAT_FAILURE,
  FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS,
  SET_VIDEOCALL_CHAT_FLAG,
  INCOMING_VIDEOCALL,
  ANSWER_VIDEOCALL_SUCCESS,
  DECLINE_VIDEOCALL_SUCCESS,
  START_USER_VIDEOCALL_SUCCESS,
  DECLINED_VIDEOCALL,
  ANSWERED_VIDEOCALL,
  START_USER_VIDEOCALL_FAILURE,
  DECLINE_VIDEOCALL_CC,
  ANSWER_VIDEOCALL_CC,
  FETCH_VIDEOCALL_INVITATIONS_REQUEST,
  FETCH_VIDEOCALL_INVITATIONS_SUCCESS,
  FETCH_VIDEOCALL_INVITATIONS_FAILURE,
} from './types';
import keyBy from 'lodash.keyby';

const initialState = {
  sendGuestEmailAddressLoaded: true,
  sendGuestEmailAddressError: false,
  sendGuestEmailAddressSuccess: false,
  fetchList: {
    filters: {
      startDay: null,
      endDay: null,
      startTime: null,
      endTime: null,
      incoming: null,
      outgoing: null,
      lost: null,
    },
    data: [],
    loaded: true,
    inited: false,
    error: false,
  },
  scheduleVideocallLoaded: true,
  scheduleVideocallError: false,
  scheduledVideocallsById: {},
  scheduledVideocallsAllIds: [],
  scheduledVideocallsLoaded: true,
  scheduledVideocallsError: false,
  deleteScheduledVideocallError: null,
  deleteScheduledVideocallSuccess: false,
  saveScheduledVideocallSuccess: false,
  enterVideocallError: null,
  currentVideocall: null,
  lastVideocallRoom: null,
  isCurrentVideocallModerator: false,
  videocallJoined: false,
  videocallJwt: null,
  checkAuthorizationLoaded: false,
  checkAuthorizationError: false,
  checkAuthorizationAcceptGuests: false,
  inviteVideocallLoaded: true,
  inviteVideocallError: false,
  settings: {
    videocallRecordingFolder: null,
    videocallRecordingFolderPath: null,
    videocallChatFolder: null,
    videocallChatFolderPath: null,
  },
  fetchSettingsLoaded: true,
  fetchSettingsError: false,
  saveSettingsLoaded: true,
  saveSettingsError: false,
  saveSettingsSuccess: false,
  saveChatLoaded: true,
  saveChatError: false,
  notificationsLoaded: false,
  notifications: [],
  usedVideocallChat: false,
  incomingVideocall: null,
  outgoingVideocall: null,
  fetchInvitationsLoaded: true,
  fetchInvitationsLError: false,
  invitations: [],
};

export default function videocalls(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;

    case FETCH_VIDEOCALLS_LIST_REQUEST:
      return {
        ...state,
        fetchList: {
          filters: {
            startDay: action.payload.startDay,
            endDay: action.payload.endDay,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime,
            incoming: action.payload.incoming,
            outgoing: action.payload.outgoing,
            lost: action.payload.lost,
          },
          data: [],
          loaded: false,
          inited: true,
          error: false,
        },
      };

    case FETCH_VIDEOCALLS_LIST_SUCCESS:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          data: action.data,
          loaded: true,
          error: false,
        },
      };

    case FETCH_VIDEOCALLS_LIST_FAILURE:
      return {
        ...state,
        fetchList: {
          ...state.fetchList,
          loaded: true,
          data: [],
          error: true,
        },
      };

    case SEND_GUEST_EMAIL_ADDRESS_REQUEST:
      return {
        ...state,
        sendGuestEmailAddressLoaded: false,
        sendGuestEmailAddressError: false,
        sendGuestEmailAddressSuccess: false,
      };
    case SEND_GUEST_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        sendGuestEmailAddressLoaded: true,
        sendGuestEmailAddressError: false,
        sendGuestEmailAddressSuccess: true,
      };
    case SEND_GUEST_EMAIL_ADDRESS_FAILURE:
      return {
        ...state,
        sendGuestEmailAddressLoaded: true,
        sendGuestEmailAddressError: true,
        sendGuestEmailAddressSuccess: false,
      };

    case SAVE_SCHEDULED_VIDEOCALL_REQUEST:
      return {
        ...state,
        scheduleVideocallLoaded: false,
        scheduleVideocallError: false,
        saveScheduledVideocallSuccess: false,
      };

    case SAVE_SCHEDULED_VIDEOCALL_SUCCESS:
      return {
        ...state,
        scheduleVideocallLoaded: true,
        scheduleVideocallError: false,
        saveScheduledVideocallSuccess: true,
      };

    case SAVE_SCHEDULED_VIDEOCALL_FAILURE:
      return {
        ...state,
        scheduleVideocallLoaded: true,
        scheduleVideocallError: true,
        saveScheduledVideocallSuccess: false,
      };

    case FETCH_SCHEDULED_VIDEOCALLS_REQUEST:
      return {
        ...state,
        scheduledVideocallsById: {},
        scheduledVideocallsAllIds: [],
        scheduledVideocallsLoaded: false,
        scheduledVideocallsError: false,
        enterVideocallError: null,
      };

    case FETCH_SCHEDULED_VIDEOCALLS_SUCCESS:
      return {
        ...state,
        scheduledVideocallsById: keyBy(action.data, 'id'),
        scheduledVideocallsAllIds: action.data.map((obj) => obj.id),
        scheduledVideocallsLoaded: true,
        scheduledVideocallsError: false,
      };

    case FETCH_SCHEDULED_VIDEOCALLS_FAILURE:
      return {
        ...state,
        scheduledVideocallsLoaded: true,
        scheduledVideocallsError: true,
      };

    case DELETE_SCHEDULED_VIDEOCALL_REQUEST:
      return {
        ...state,
        deleteScheduledVideocallError: null,
        deleteScheduledVideocallSuccess: false,
      };

    case DELETE_SCHEDULED_VIDEOCALL_SUCCESS: {
      const updatedScheduledVideocall = {
        ...state.scheduledVideocallsById,
      };
      delete updatedScheduledVideocall[action.id];
      const updatedScheduledVideocallAllIds = [
        ...state.scheduledVideocallsAllIds,
      ];
      pull(updatedScheduledVideocallAllIds, action.id);
      return {
        ...state,
        scheduledVideocallsById: updatedScheduledVideocall,
        scheduledVideocallsAllIds: updatedScheduledVideocallAllIds,
        deleteScheduledVideocallSuccess: true,
      };
    }

    case DELETE_SCHEDULED_VIDEOCALL_FAILURE:
      return {
        ...state,
        deleteScheduledVideocallError: action.error,
        deleteScheduledVideocallSuccess: false,
      };

    case ENTER_VIDEOCALL_REQUEST:
      return {
        ...state,
        enterVideocallError: null,
        saveChatError: false,
      };

    case ENTER_VIDEOCALL_SUCCESS:
      return {
        ...state,
        videocallJwt: action.payload.jwt,
        currentVideocall: action.payload,
        lastVideocallRoom: action.payload.room,
        isCurrentVideocallModerator: action.payload.isModerator,
        usedVideocallChat: false,
        outgoingVideocall: null,
        incomingVideocall: null,
      };

    case ENTER_VIDEOCALL_FAILURE:
      return {
        ...state,
        enterVideocallError: action.error,
        outgoingVideocall: null,
        incomingVideocall: null,
      };

    case LEAVE_VIDEOCALL_SUCCESS:
      return {
        ...state,
        videocallJwt: null,
        currentVideocall: null,
        videocallJoined: false,
      };

    case JOIN_VIDEOCALL_SUCCESS:
      return {
        ...state,
        videocallJoined: true,
        usedVideocallChat: false,
      };

    case CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        checkAuthorizationLoaded: true,
        checkAuthorizationError: false,
        checkAuthorizationAcceptGuests: action.payload.acceptGuests,
      };

    case CHECK_VIDEOCALL_AUTHORIZATION_FAILURE:
      return {
        ...state,
        checkAuthorizationLoaded: true,
        checkAuthorizationError: true,
      };

    case INVITE_VIDEOCALL_REQUEST:
      return {
        ...state,
        inviteVideocallLoaded: false,
        inviteVideocallError: false,
      };
    case INVITE_VIDEOCALL_SUCCESS:
      return {
        ...state,
        inviteVideocallLoaded: true,
        inviteVideocallError: false,
      };
    case INVITE_VIDEOCALL_FAILURE:
      return {
        ...state,
        inviteVideocallLoaded: true,
        inviteVideocallError: action.errors,
      };
    case FETCH_VIDEOCALL_SETTINGS_REQUEST:
      return {
        ...state,
        fetchSettingsLoaded: false,
        fetchSettingsError: false,
      };
    case FETCH_VIDEOCALL_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          videocallRecordingFolder: action.data.fileboxRecordingFolder,
          videocallRecordingFolderPath: action.data.fileboxRecordingFolderPath,
          videocallChatFolder: action.data.fileboxChatFolder,
          videocallChatFolderPath: action.data.fileboxChatFolderPath,
        },
        fetchSettingsLoaded: true,
        fetchSettingsError: false,
      };

    case FETCH_VIDEOCALL_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          videocallRecordingFolder: null,
          videocallRecordingFolderPath: null,
          videocallChatFolder: null,
          videocallChatFolderPath: null,
        },
        fetchSettingsLoaded: true,
        fetchSettingsError: true,
      };
    case SAVE_VIDEOCALL_SETTINGS_REQUEST:
      return {
        ...state,
        saveSettingsLoaded: false,
        saveSettingsError: false,
        saveSettingsSuccess: false,
      };
    case SAVE_VIDEOCALL_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          videocallRecordingFolder: action.data.fileboxRecordingFolder,
          videocallRecordingFolderPath: action.data.fileboxRecordingFolderPath
            ? action.data.fileboxRecordingFolderPath.split(' > ').slice(1)
            : null,
          videocallChatFolder: action.data.fileboxChatFolder,
          videocallChatFolderPath: action.data.fileboxChatFolderPath
            ? action.data.fileboxChatFolderPath.split(' > ').slice(1)
            : null,
        },
        saveSettingsLoaded: true,
        saveSettingsError: false,
        saveSettingsSuccess: true,
      };

    case SAVE_VIDEOCALL_SETTINGS_FAILURE:
      return {
        ...state,
        saveSettingsLoaded: true,
        saveSettingsError: true,
        saveSettingsSuccess: false,
      };

    case SAVE_VIDEOCALL_CHAT_REQUEST:
      return {
        ...state,
        saveChatLoaded: false,
        saveChatError: false,
      };

    case SAVE_VIDEOCALL_CHAT_SUCCESS:
      return {
        ...state,
        saveChatLoaded: true,
        saveChatError: false,
      };

    case SAVE_VIDEOCALL_CHAT_FAILURE:
      return {
        ...state,
        saveChatLoaded: true,
        saveChatError: true,
      };

    case FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoaded: true,
        notifications: action.data,
      };

    case SET_VIDEOCALL_CHAT_FLAG:
      return {
        ...state,
        usedVideocallChat: true,
      };

    case INCOMING_VIDEOCALL: {
      return {
        ...state,
        incomingVideocall: {
          user: action.data.caller,
          room: action.data.room,
          answered: false,
        },
      };
    }

    case START_USER_VIDEOCALL_SUCCESS: {
      return {
        ...state,
        outgoingVideocall: {
          user: action.data.recipient.id,
          room: action.data.channelExtension.rtcRoom,
          busy: false,
        },
      };
    }

    case START_USER_VIDEOCALL_FAILURE: {
      if (
        action.payload.error === 'BUSY_RECIPIENT' ||
        action.payload.error === 'DND_RECIPIENT'
      ) {
        return {
          ...state,
          outgoingVideocall: {
            user: action.payload.data.recipient.id,
            room: action.payload.data.channelExtension.rtcRoom,
            busy: true,
          },
        };
      }
      return {
        ...state,
        outgoingVideocall: null,
      };
    }

    case ANSWER_VIDEOCALL_SUCCESS: {
      return {
        ...state,
        incomingVideocall: {
          ...state.incomingVideocall,
          answered: true,
        },
      };
    }

    case DECLINE_VIDEOCALL_SUCCESS:
    case DECLINE_VIDEOCALL_CC:
    case ANSWER_VIDEOCALL_CC: {
      return {
        ...state,
        incomingVideocall: null,
        outgoingVideocall: null,
      };
    }

    case DECLINED_VIDEOCALL:
    case ANSWERED_VIDEOCALL: {
      return {
        ...state,
        incomingVideocall: null,
      };
    }

    case FETCH_VIDEOCALL_INVITATIONS_REQUEST: {
      return {
        ...state,
        fetchInvitationsLoaded: false,
        fetchInvitationsLError: false,
        invitations: [],
      };
    }

    case FETCH_VIDEOCALL_INVITATIONS_SUCCESS: {
      return {
        ...state,
        fetchInvitationsLoaded: true,
        fetchInvitationsLError: false,
        invitations: action.payload.items,
      };
    }

    case FETCH_VIDEOCALL_INVITATIONS_FAILURE: {
      return {
        ...state,
        fetchInvitationsLoaded: true,
        fetchInvitationsLError: true,
        invitations: [],
      };
    }

    default:
      return state;
  }
}
