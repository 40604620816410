import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_PAGED_USERS_REQUEST,
  FETCH_PAGED_USERS_SUCCESS,
  FETCH_PAGED_USERS_FAILURE,
  SET_USERS_FILTER,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
  USER_PRESENCE_CHANGE,
  USER_UPDATE_NOTIFICATION,
  FETCH_USER_VACATIONS_DATA_REQUEST,
  FETCH_USER_VACATIONS_DATA_SUCCESS,
  FETCH_USER_VACATIONS_DATA_FAILURE,
  SAVE_USER_VACATIONS_DATA_REQUEST,
  SAVE_USER_VACATIONS_DATA_SUCCESS,
  SAVE_USER_VACATIONS_DATA_FAILURE,
  FETCH_USER_REGISTRY_DATA_REQUEST,
  FETCH_USER_REGISTRY_DATA_SUCCESS,
  FETCH_USER_REGISTRY_DATA_FAILURE,
  SAVE_USER_REGISTRY_DATA_REQUEST,
  SAVE_USER_REGISTRY_DATA_SUCCESS,
  SAVE_USER_REGISTRY_DATA_FAILURE,
  FETCH_USER_PUNCHINGS_DATA_REQUEST,
  FETCH_USER_PUNCHINGS_DATA_SUCCESS,
  FETCH_USER_PUNCHINGS_DATA_FAILURE,
  SAVE_USER_PUNCHINGS_DATA_REQUEST,
  SAVE_USER_PUNCHINGS_DATA_SUCCESS,
  SAVE_USER_PUNCHINGS_DATA_FAILURE,
  USER_STATUS_CHANGE,
  USER_STATUS_CHANGE_NOTIFICATION,
  FETCH_USER_SUPERVIEWERS_REQUEST,
  FETCH_USER_SUPERVIEWERS_SUCCESS,
  FETCH_USER_SUPERVIEWERS_FAILURE,
  SAVE_USER_SUPERVIEWERS_REQUEST,
  SAVE_USER_SUPERVIEWERS_SUCCESS,
  SAVE_USER_SUPERVIEWERS_FAILURE,
  FETCH_USER_GROUPS_REQUEST,
  FETCH_USER_GROUPS_SUCCESS,
} from './types';

export const fetchUsersRequest = () => ({ type: FETCH_USERS_REQUEST });
export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  users,
});
export const fetchUsersFailure = (errors) => ({
  type: FETCH_USERS_FAILURE,
  errors,
});
export const fetchPagedUsersRequest = (params) => ({
  type: FETCH_PAGED_USERS_REQUEST,
  payload: params,
});
export const fetchPagedUsersSuccess = (payload) => ({
  type: FETCH_PAGED_USERS_SUCCESS,
  payload,
});
export const fetchPagedUsersFailure = (errors) => ({
  type: FETCH_PAGED_USERS_FAILURE,
  errors,
});
export const setUsersFilter = (filter) => ({ type: SET_USERS_FILTER, filter });
export const fetchUserRequest = (userId) => ({
  type: FETCH_USER_REQUEST,
  userId,
});
export const fetchUserSuccess = (user) => ({ type: FETCH_USER_SUCCESS, user });
export const fetchUserFailure = (errors) => ({
  type: FETCH_USER_FAILURE,
  errors,
});
export const saveUserRequest = (user) => ({ type: SAVE_USER_REQUEST, user });
export const saveUserSuccess = (user) => ({ type: SAVE_USER_SUCCESS, user });
export const saveUserFailure = (errors) => ({
  type: SAVE_USER_FAILURE,
  errors,
});
export const userPresenceChange = (payload) => ({
  type: USER_PRESENCE_CHANGE,
  payload,
});
export const userStatusChange = (payload) => ({
  type: USER_STATUS_CHANGE,
  payload,
});
export const userUpdateNotification = (payload) => ({
  type: USER_UPDATE_NOTIFICATION,
  payload,
});
export const userStatusChangeNotification = (payload) => ({
  type: USER_STATUS_CHANGE_NOTIFICATION,
  payload,
});
export const fetchUserVacationsDataRequest = (userId) => ({
  type: FETCH_USER_VACATIONS_DATA_REQUEST,
  userId,
});
export const fetchUserVacationsDataSuccess = (data) => ({
  type: FETCH_USER_VACATIONS_DATA_SUCCESS,
  data,
});
export const fetchUserVacationsDataFailure = (errors) => ({
  type: FETCH_USER_VACATIONS_DATA_FAILURE,
  errors,
});
export const saveUserVacationsDataRequest = (payload) => ({
  type: SAVE_USER_VACATIONS_DATA_REQUEST,
  payload,
});
export const saveUserVacationsDataSuccess = (data) => ({
  type: SAVE_USER_VACATIONS_DATA_SUCCESS,
  data,
});
export const saveUserVacationsDataFailure = (errors) => ({
  type: SAVE_USER_VACATIONS_DATA_FAILURE,
  errors,
});
export const fetchUserPunchingsDataRequest = (userId) => ({
  type: FETCH_USER_PUNCHINGS_DATA_REQUEST,
  userId,
});
export const fetchUserPunchingsDataSuccess = (data, userId) => ({
  type: FETCH_USER_PUNCHINGS_DATA_SUCCESS,
  data,
  userId,
});
export const fetchUserPunchingsDataFailure = (errors) => ({
  type: FETCH_USER_PUNCHINGS_DATA_FAILURE,
  errors,
});
export const saveUserPunchingsDataRequest = (payload) => ({
  type: SAVE_USER_PUNCHINGS_DATA_REQUEST,
  payload,
});
export const saveUserPunchingsDataSuccess = (data) => ({
  type: SAVE_USER_PUNCHINGS_DATA_SUCCESS,
  data,
});
export const saveUserPunchingsDataFailure = (errors) => ({
  type: SAVE_USER_PUNCHINGS_DATA_FAILURE,
  errors,
});
export const fetchUserRegistryDataRequest = (userId) => ({
  type: FETCH_USER_REGISTRY_DATA_REQUEST,
  userId,
});
export const fetchUserRegistryDataSuccess = (data, userId) => ({
  type: FETCH_USER_REGISTRY_DATA_SUCCESS,
  data,
  userId,
});
export const fetchUserRegistryDataFailure = (errors) => ({
  type: FETCH_USER_REGISTRY_DATA_FAILURE,
  errors,
});
export const saveUserRegistryDataRequest = (payload) => ({
  type: SAVE_USER_REGISTRY_DATA_REQUEST,
  payload,
});
export const saveUserRegistryDataSuccess = (data) => ({
  type: SAVE_USER_REGISTRY_DATA_SUCCESS,
  data,
});
export const saveUserRegistryDataFailure = (errors) => ({
  type: SAVE_USER_REGISTRY_DATA_FAILURE,
  errors,
});
export const fetchUserSuperviewersRequest = (userId) => ({
  type: FETCH_USER_SUPERVIEWERS_REQUEST,
  userId,
});
export const fetchUserSuperviewersSuccess = (data) => ({
  type: FETCH_USER_SUPERVIEWERS_SUCCESS,
  data,
});
export const fetchUserSuperviewersFailure = (errors) => ({
  type: FETCH_USER_SUPERVIEWERS_FAILURE,
  errors,
});
export const saveUserSuperviewersRequest = (payload) => ({
  type: SAVE_USER_SUPERVIEWERS_REQUEST,
  payload,
});
export const saveUserSuperviewersSuccess = (data) => ({
  type: SAVE_USER_SUPERVIEWERS_SUCCESS,
  data,
});
export const saveUserSuperviewersFailure = (errors) => ({
  type: SAVE_USER_SUPERVIEWERS_FAILURE,
  errors,
});
export const fetchUserGroupsRequest = (params) => ({ type: FETCH_USER_GROUPS_REQUEST, params });
export const fetchUserGroupsSuccess = (users) => ({
  type: FETCH_USER_GROUPS_SUCCESS,
  users,
});