import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { Fade } from 'reactstrap';
import { getGroupsById, getFavoritesGroupId } from '../js/groups/selectors';
import {
  getContactById,
  getFetchContactLoaded,
  getFetchContactError,
} from '../js/contacts/selectors';
import history from '../history';
import FavoriteButton from './buttons/FavoriteButton';
import Label from './formControls/Label';
import GroupLabel from './GroupLabel';
import PhoneLabel from './PhoneLabel';
import Avatar from './Avatar';
import { AVATAR_TYPES } from '../js/files/FileUtils';
import { getUsersById } from '../js/users/selectors';
import Popover from './Popover';
import { getMeId, hasGrants, isSMSenabled } from '../js/me/selectors';
import ToolboxIcon from './icons/ToolboxIcon';

const messages = defineMessages({
  email: {
    id: 'ContactDetailsPopover.label.email',
    defaultMessage: 'Email: ',
  },
  groups: {
    id: 'ContactDetailsPopover.label.groups',
    defaultMessage: 'Groups: ',
  },
  website: {
    id: 'ContactDetailsPopover.label.website',
    defaultMessage: 'Website: ',
  },
  customerNumber: {
    id: 'ContactDetailsPopover.label.customerNumber',
    defaultMessage: 'Customer number: ',
  },
  role: {
    id: 'ContactDetailsPopover.label.role',
    defaultMessage: 'Role: ',
  },
  vatFiscalCode: {
    id: 'ContactDetailsPopover.label.vatFiscalCode',
    defaultMessage: 'VAT number/fiscal code: ',
  },
  atecoCode: {
    id: 'ContactDetailsPopover.label.atecoCode',
    defaultMessage: 'Ateco code: ',
  },
  showTickets: {
    id: 'ContactDetailsPopover.label.showTickets',
    defaultMessage: 'Show its tickets',
  },
  shared: {
    id: 'ContactDetailsPopover.label.shared',
    defaultMessage: 'Shared',
  },
  reserved: {
    id: 'ContactDetailsPopover.label.reserved',
    defaultMessage: 'Reserved',
  },
  public: {
    id: 'ContactDetailsPopover.label.public',
    defaultMessage: 'Public',
  },
  insertBy: {
    id: 'ContactDetailsPopover.label.insertBy',
    defaultMessage: 'Added by: ',
  },
  you: {
    id: 'ContactDetailsPopover.you',
    defaultMessage: 'you',
  },
  on: {
    id: 'ContactDetailsPopover.on',
    defaultMessage: 'on',
  },
  sharedWith: {
    id: 'ContactDetailsPopover.label.sharedWith',
    defaultMessage: 'Shared with: ',
  },
  sharedBy: {
    id: 'ContactDetailsPopover.label.sharedBy',
    defaultMessage: 'Shared by: ',
  },
  notes: {
    id: 'ContactDetailsPopover.label.notes',
    defaultMessage: 'Notes: ',
  },
  infoButton: {
    id: 'ContactDetailsPopover.tooltip.infoButton',
    defaultMessage: 'Info',
  },
  editButton: {
    id: 'ContactDetailsPopover.tooltip.editButton',
    defaultMessage: 'Edit',
  },
  lists: {
    id: 'ContactDetailsPopover.label.lists',
    defaultMessage: 'Lists: ',
  },
});

const ContactDetailsPopover = ({
  id,
  contact,
  groups,
  users,
  favoritesGroupId,
  loading,
  isOpen,
  target,
  toggle,
  className,
  myId,
  hasPublicGrant,
  canSendSMS,
  intl: { formatMessage },
}) => {
  const contactGroups = contact.groups
    ? contact.groups.filter((groupId) => groupId !== favoritesGroupId)
    : [];

  const formatedWebsite =
    (contact.website && contact.website.includes('http://')) ||
    (contact.website && contact.website.includes('https://'))
      ? contact.website.replace(/(^\w+:|^)\/\//, '')
      : contact.website;

  const sharingWith =
    myId === contact.creator &&
    contact.sharing &&
    contact.sharing.length > 0 &&
    contact.sharing.map((userId) => users[userId].fullname);

  const sharingBy =
    contact.creator &&
    myId !== contact.creator &&
    contact.sharing &&
    contact.sharing.length > 0 &&
    users[contact.creator].fullname;

  const canEdit = contact.private || hasPublicGrant;

  return (
    <Popover
      isOpen={isOpen}
      toggle={toggle}
      target={target}
      header={contact.fullname}
      loading={loading}
    >
      <Fade tag="div">
        <div className="row no-gutters mb-2">
          <div className="col-4">
            <Avatar
              size="lg"
              src={contact.avatar}
              alt={contact.fullname}
              className={className}
              type={AVATAR_TYPES.CONTACT}
            />
          </div>
          <div className="col-8 text-right">
            <ul className="nav justify-content-end">
              <li className="nav-item">
                <Link to={`/abook/contacts/info/id/${contact.id}`}>
                  <ToolboxIcon name="id-card-4">
                    {formatMessage(messages.infoButton)}
                  </ToolboxIcon>
                </Link>
              </li>
              {canEdit && !contact.visibilityOnly && (
                <li className="nav-item pl-1">
                  <Link to={`/abook/contacts/edit/${contact.id}`}>
                    <ToolboxIcon name="pencil-write-2">
                      {formatMessage(messages.editButton)}
                    </ToolboxIcon>
                  </Link>
                </li>
              )}
              <li className="nav-item pl-1 pr-1">
                <FavoriteButton type="A" id={contact.id} onClick={toggle} />
              </li>
            </ul>
            {contact.private ? (
              contact.visibilityOnly ? (
                <span
                  className="badge"
                  style={{
                    color: 'white',
                    backgroundColor: 'var(--yn-gray-600)',
                  }}
                >
                  {formatMessage(messages.shared)}
                </span>
              ) : (
                <span
                  className="badge"
                  style={{
                    color: 'white',
                    backgroundColor: 'var(--yn-blue-darker)',
                  }}
                >
                  {formatMessage(messages.reserved)}
                </span>
              )
            ) : (
              <span
                className="badge"
                style={{
                  color: 'white',
                  backgroundColor: 'var(--yn-blue)',
                }}
              >
                {formatMessage(messages.public)}
              </span>
            )}
          </div>
        </div>
        <div id="phoneNumbers">
          {contact.numbers &&
            contact.numbers.length > 0 &&
            contact.numbers.map((number, idx) => (
              <PhoneLabel
                key={idx}
                number={number}
                showType
                showSMS={canSendSMS}
                contactName={contact.fullname}
                clientNumber={contact.customerNumber}
              />
            ))}
        </div>
        {contact.customerNumber && (
          <div id="customerNumber" className="mb-1">
            <Label for="customerNumber">
              {formatMessage(messages.customerNumber)}
            </Label>
            {contact.customerNumber}
          </div>
        )}
        {contact.email && (
          <div id="email" className="mb-1">
            <Label for="email">{formatMessage(messages.email)}</Label>
            {contact.email.split(',').map((address, index) => (
              <span key={index} className="pr-2">
                <a href={`mailto:${address}`}>{address}</a>
              </span>
            ))}
          </div>
        )}
        {contactGroups.length > 0 && (
          <div id="groups" className="mb-1">
            <Label for="groups">{formatMessage(messages.groups)}</Label>
            {contactGroups.map(
              (groupId) =>
                groups[groupId] && (
                  <GroupLabel
                    key={groupId}
                    name={groups[groupId].name}
                    color={groups[groupId].color}
                  />
                )
            )}
          </div>
        )}
        {contact.lists && contact.lists.length > 0 && (
          <div id="lists" className="mb-1">
            <Label for="lists">{formatMessage(messages.lists)}</Label>
            {contact.lists.map((l) => l.name).join(', ')}
          </div>
        )}
        {contact.website && (
          <div id="website" className="mb-1">
            <Label for="website">{formatMessage(messages.website)}</Label>

            <a
              href={`//${formatedWebsite}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contact.website}
            </a>
          </div>
        )}
        {contact.vatFiscalCode && (
          <div id="vatFiscalCode" className="mb-1">
            <Label for="vatFiscalCode">
              {formatMessage(messages.vatFiscalCode)}
            </Label>
            {contact.vatFiscalCode}
          </div>
        )}
        {contact.atecoCode && (
          <div id="atecoCode" className="mb-1">
            <Label for="atecoCode">{formatMessage(messages.atecoCode)}</Label>
            {`${contact.atecoCode.code} ${contact.atecoCode.description}`}
          </div>
        )}
        {contact.role && (
          <div id="role" className="mb-1">
            <Label for="role">{formatMessage(messages.role)}</Label>
            {contact.role}
          </div>
        )}
        {contact.hasTickets && (
          <div
            className="pr-1 mb-0 mt-2"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              toggle();
              history.push(`/tickets/tickets-list?abook=${id}`);
            }}
          >
            {formatMessage(messages.showTickets)}
          </div>
        )}
        {contact.notes && (
          <div id="notes" className="mb-1">
            <Label for="notes">{formatMessage(messages.notes)}</Label>
            {contact.notes}
          </div>
        )}

        {sharingWith && sharingWith.length > 0 && (
          <div id="sharingWith" className="mb-1">
            <Label for="sharingWith">
              {formatMessage(messages.sharedWith)}
            </Label>
            {sharingWith.join(', ')}
          </div>
        )}
        {sharingBy && (
          <div id="sharingBy" className="mb-1">
            <Label for="sharingBy">{formatMessage(messages.sharedBy)}</Label>
            {sharingBy}
          </div>
        )}
      </Fade>
    </Popover>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    groups: getGroupsById(state),
    users: getUsersById(state),
    contact: getContactById(state, ownProps.id),
    loading: !getFetchContactLoaded(state, ownProps.id),
    error: getFetchContactError(state, ownProps.id),
    favoritesGroupId: getFavoritesGroupId(state),
    myId: getMeId(state),
    hasPublicGrant: hasGrants(state, 'ADDRESSBOOK_CONTACT_UPDATE'),
    canSendSMS: isSMSenabled(state) && hasGrants(state, 'SMS_SEND'),
  };
}

ContactDetailsPopover.propTypes = {
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default injectIntl(connect(mapStateToProps)(ContactDetailsPopover));
