import {
  UPDATE_PLATFORM_SETTINGS_REQUEST,
  UPDATE_PLATFORM_SETTINGS_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_FAILURE,
  UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  FETCH_CONFIGURATIONS_REQUEST,
  FETCH_CONFIGURATIONS_SUCCESS,
  FETCH_CONFIGURATIONS_FAILURE,
  RESTORE_NOTIFICATIONS_SETTINGS_REQUEST,
  RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS,
  RESTORE_NOTIFICATIONS_SETTINGS_FAILURE,
  SAVE_HIDE_INTRO_REQUEST,
} from './types';
import {
  FETCH_SETTINGS_REQUEST,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
} from '../me/types';

export const fetchSettingsRequest = () => ({
  type: FETCH_SETTINGS_REQUEST,
});
export const fetchSettingsSuccess = (settings) => ({
  type: FETCH_SETTINGS_SUCCESS,
  settings,
});
export const fetchSettingsFailure = (errors) => ({
  type: FETCH_SETTINGS_FAILURE,
  errors,
});
export const updatePlatformSettingsRequest = (preferences) => ({
  type: UPDATE_PLATFORM_SETTINGS_REQUEST,
  preferences,
});
export const updatePlatformSettingsSuccess = (preferences, success) => ({
  type: UPDATE_PLATFORM_SETTINGS_SUCCESS,
  preferences,
  success,
});
export const updatePlatformSettingsFailure = (errors) => ({
  type: UPDATE_PLATFORM_SETTINGS_FAILURE,
  errors,
});
export const updateNotificationSettingsRequest = (notificationSettings) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_REQUEST,
  notificationSettings,
});
export const updateNotificationSettingsSuccess = (
  notificationSettings,
  success
) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
  notificationSettings,
  success,
});
export const updateNotificationSettingsFailure = (errors) => ({
  type: UPDATE_NOTIFICATION_SETTINGS_FAILURE,
  errors,
});
export const fetchConfigurationsRequest = () => ({
  type: FETCH_CONFIGURATIONS_REQUEST,
});
export const fetchConfigurationsSuccess = (payload) => ({
  type: FETCH_CONFIGURATIONS_SUCCESS,
  payload,
});
export const fetchConfigurationsFailure = (errors) => ({
  type: FETCH_CONFIGURATIONS_FAILURE,
  errors,
});
export const restoreNotificationsSettingsRequest = (payload) => ({
  type: RESTORE_NOTIFICATIONS_SETTINGS_REQUEST,
  payload,
});
export const restoreNotificationsSettingsSuccess = (payload, success) => ({
  type: RESTORE_NOTIFICATIONS_SETTINGS_SUCCESS,
  payload,
  success,
});
export const restoreNotificationsSettingsFailure = (error) => ({
  type: RESTORE_NOTIFICATIONS_SETTINGS_FAILURE,
  error,
});
export const saveHideIntroRequest = (payload) => ({
  type: SAVE_HIDE_INTRO_REQUEST,
  payload,
});
