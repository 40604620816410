import React from 'react';
import keyBy from 'lodash.keyby';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Select2 from '../formControls/Select2';

export const messages = defineMessages({
  MOBILE: {
    id: 'PhoneNumbersRow.label.MOBILE',
    defaultMessage: 'Mobile',
  },
  OFFICE: {
    id: 'PhoneNumbersRow.label.OFFICE',
    defaultMessage: 'Office',
  },
  HOME: {
    id: 'PhoneNumbersRow.label.HOME',
    defaultMessage: 'Home',
  },
  FAX: {
    id: 'PhoneNumbersRow.label.FAX',
    defaultMessage: 'Fax',
  },
  OTHERS: {
    id: 'PhoneNumbersRow.label.OTHER',
    defaultMessage: 'Others',
  },
});

const PhoneNumberTypeSeelect = ({
  selected,
  handleChange,
  intl: { formatMessage },
}) => {
  const options = [
    { value: 'MOBILE', label: formatMessage(messages.MOBILE) },
    { value: 'OFFICE', label: formatMessage(messages.OFFICE) },
    { value: 'HOME', label: formatMessage(messages.HOME) },
    { value: 'FAX', label: formatMessage(messages.FAX) },
    { value: 'OTHERS', label: formatMessage(messages.OTHERS) },
  ];
  const optionsByType = keyBy(options, 'value');

  return (
    <Select2
      id="PhoneNumberType"
      name="PhoneNumberType"
      value={optionsByType[selected]}
      onChange={handleChange}
      options={options}
      isSearchable={false}
      defaultValue={optionsByType.MOBILE}
    />
  );
};

PhoneNumberTypeSeelect.propTypes = {
  selected: PropTypes.oneOf(['MOBILE', 'OFFICE', 'HOME', 'FAX', 'OTHERS']),
  handleChange: PropTypes.func.isRequired,
};
PhoneNumberTypeSeelect.defaultProps = {
  selected: 'MOBILE',
};
export default injectIntl(PhoneNumberTypeSeelect);
