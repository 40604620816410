import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { isMePbxAdmin } from '../../../js/me/selectors';
import Platform from './index';

const PlatformSettingsPersonal = React.lazy(() =>
  import('./PlatformSettingsPersonal')
);

const messages = defineMessages({
  title: {
    id: 'PlatformSettingsPersonalPage.title',
    defaultMessage: 'Preferences',
  },
});

const PlatformSettingsPersonalPage = (props) =>
  props.isPbxAdmin ? (
    <Platform>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <PlatformSettingsPersonal {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Platform>
  ) : (
    <div className="border rounded bg-white h-100">
      <SectionTitle>{props.intl.formatMessage(messages.title)}</SectionTitle>
      <div className="yn-section">
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <PlatformSettingsPersonal {...props} />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );

function mapStateToProps(state) {
  return {
    isPbxAdmin: isMePbxAdmin(state),
  };
}

export default injectIntl(
  connect(mapStateToProps)(PlatformSettingsPersonalPage)
);
