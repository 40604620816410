const defaultList = [];

export const getAvatarUploaded = (state) => state.files.avatar;
export const getFilesById = (state, scope) => state.files[scope].byId;
const getFilesIds = (state, scope) =>
  state.files[scope] ? state.files[scope].allIds : defaultList;
export const getFiles = (state, scope) =>
  getFilesIds(state, scope).map((id) => getFilesById(state, scope)[id]);
export const isFilesLoading = (state, scope) =>
  getFiles(state, scope).some((file) => !file.loaded);
export const getChatFileStored = (state, id) =>
  state.files.chat.byId[id] && state.files.chat.byId[id].data; // && state.files.chat.byId[id].data.stored
export const getChatFileProgress = (state, id) =>
  state.files.chat.byId[id] ? state.files.chat.byId[id].progress : null;
export const getFileById = (state, scope, id) => state.files[scope].byId[id];
export const getImportFileToFileboxLoaded = (state) =>
  state.files.filebox.loaded;
export const getImportFileToFileboxError = (state) => state.files.filebox.error;
