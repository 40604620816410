import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import {
  fetchMailofficesSuccess,
  fetchMailofficesFailure,
  fetchPagedMailofficesSuccess,
  fetchPagedMailofficesFailure,
  fetchMailofficeSuccess,
  fetchMailofficeFailure,
  deleteMailofficeSuccess,
  deleteMailofficeFailure,
  saveMailofficeSuccess,
  saveMailofficeFailure,
  fetchMailofficeCreditSuccess,
  fetchMailofficeCreditFailure,
  confirmMailofficeSuccess,
  confirmMailofficeFailure,
  rejectMailofficeSuccess,
  rejectMailofficeFailure,
  deleteMailofficeRRSuccess,
  deleteMailofficeRRFailure,
  fetchPagedMailofficesRequest,
} from './actions';
import {
  FETCH_MAILOFFICES_REQUEST,
  FETCH_PAGED_MAILOFFICES_REQUEST,
  FETCH_MAILOFFICE_REQUEST,
  DELETE_MAILOFFICE_REQUEST,
  SAVE_MAILOFFICE_REQUEST,
  FETCH_MAILOFFICE_CREDIT_REQUEST,
  CONFIRM_MAILOFFICE_REQUEST,
  REJECT_MAILOFFICE_REQUEST,
  MAILOFFICE_PRICE_NOTIFICATION,
  MAILOFFICE_ERROR_NOTIFICATION,
  MAILOFFICE_COMPLETE_NOTIFICATION,
  DELETE_MAILOFFICE_RR_REQUEST,
} from './types';
import api from '../api';
import history from '../../history';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import Utils from '../lib/utils';
import { getMailofficeCurrentPage, getMailofficesFilter } from './selectors';

const intlStrings = defineMessages({
  pricedMailoffice: {
    id: 'pricedMailoffice',
    defaultMessage: 'A mailoffice has retrieved the price',
  },
  errorMailoffice: {
    id: 'errorMailoffice',
    defaultMessage: 'A mailoffice is in error',
  },
  completeMailoffice: {
    id: 'completeMailoffice',
    defaultMessage: 'A mailoffice has been completed',
  },
});

export function* fetchMailoffices(action) {
  try {
    const res = yield call(api.mailoffice.getMailoffices, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchMailofficesSuccess(res.status === 204 ? [] : res.data.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchMailofficesFailure(error));
  }
}

export function* fetchPagedMailoffices(action) {
  try {
    let dateEnd;
    if (action.payload.end) {
      dateEnd = new Date(action.payload.end);
      dateEnd.setDate(dateEnd.getDate() + 1);
    }
    const res = yield call(api.mailoffice.getMailoffices, {
      ...action.payload,
      end: dateEnd,
    });
    yield call(checkApiResponse, res);
    yield put(
      fetchPagedMailofficesSuccess({
        archive: res.status === 204 ? [] : res.data.data,
        total: res.data.total,
        page: action.payload.page,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPagedMailofficesFailure(error));
  }
}

export function* fetchMailoffice(action) {
  try {
    const res = yield call(api.mailoffice.getMailoffice, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchMailofficeSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchMailofficeFailure(error));
  }
}

export function* deleteMailoffice(action) {
  try {
    const res = yield call(api.mailoffice.deleteMailoffice, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteMailofficeSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteMailofficeFailure(error));
  }
}

export function* saveMailoffice(action) {
  try {
    const res = yield call(
      api.mailoffice.createMailoffice,
      action.mailofficeData
    );
    yield call(checkApiResponse, res);
    const mailofficeData = {
      ...action.mailofficeData,
      id: action.mailofficeData.id || res.data.id,
    };
    yield put(saveMailofficeSuccess(mailofficeData, res.status));
    yield put(push('/mailoffice/sent'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveMailofficeFailure(error));
  }
}

export function* fetchMailofficeCredit() {
  try {
    const res = yield call(api.mailoffice.getMailofficeCredits);
    yield call(checkApiResponse, res);
    yield put(fetchMailofficeCreditSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchMailofficeCreditFailure(error));
  }
}

export function* confirmMailoffice(action) {
  try {
    const res = yield call(api.mailoffice.confirmMailoffice, action.id);
    yield call(checkApiResponse, res);
    yield put(confirmMailofficeSuccess());
    const filter = yield select(getMailofficesFilter);
    const page = yield select(getMailofficeCurrentPage);
    yield put(fetchPagedMailofficesRequest({ filter, page }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(confirmMailofficeFailure(error));
  }
}

export function* rejectMailoffice(action) {
  try {
    const res = yield call(api.mailoffice.rejectMailoffice, action.id);
    yield call(checkApiResponse, res);
    yield put(rejectMailofficeSuccess());
    const filter = yield select(getMailofficesFilter);
    const page = yield select(getMailofficeCurrentPage);
    yield put(fetchPagedMailofficesRequest({ filter, page }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(rejectMailofficeFailure(error));
  }
}

export function* notifyPrice(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.PRICE_MAILOFFICE}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.pricedMailoffice),
      group: NotificationEnums.NotificationGroups.PRICE_MAILOFFICE,
      image: NotificationEnums.NotificationImages.MAILOFFICE_PRICED,
      onclick: () => {
        history.push('/mailoffice/notifications');
      },
    });
  }
}

export function* notifyError(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ERROR_MAILOFFICE}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.errorMailoffice),
      group: NotificationEnums.NotificationGroups.ERROR_MAILOFFICE,
      image: NotificationEnums.NotificationImages.MAILOFFICE_ERROR,
      onclick: () => {
        history.push('/mailoffice/notifications');
      },
    });
  }
}

export function* notifyComplete(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (!action.payload.archived) {
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.COMPLETED_MAILOFFICE}-${action.payload.idEntity}`,
      body: intl.formatMessage(intlStrings.completeMailoffice),
      group: NotificationEnums.NotificationGroups.COMPLETED_MAILOFFICE,
      image: NotificationEnums.NotificationImages.MAILOFFICE_SENT,
      onclick: () => {
        history.push('/mailoffice/notifications');
      },
    });
  }
}

export function* deleteMailofficeRR(action) {
  try {
    const res = yield call(api.files.deleteMailofficeRR, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteMailofficeRRSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteMailofficeRRFailure(error));
  }
}

export default function* rootSaga() {
  /** Archive */
  yield takeLatest(FETCH_MAILOFFICES_REQUEST, fetchMailoffices);
  yield takeLatest(FETCH_PAGED_MAILOFFICES_REQUEST, fetchPagedMailoffices);
  yield takeLatest(FETCH_MAILOFFICE_REQUEST, fetchMailoffice);
  yield takeLatest(DELETE_MAILOFFICE_REQUEST, deleteMailoffice);
  yield takeLatest(SAVE_MAILOFFICE_REQUEST, saveMailoffice);
  yield takeLatest(FETCH_MAILOFFICE_CREDIT_REQUEST, fetchMailofficeCredit);
  yield takeLatest(CONFIRM_MAILOFFICE_REQUEST, confirmMailoffice);
  yield takeLatest(REJECT_MAILOFFICE_REQUEST, rejectMailoffice);
  yield takeEvery(MAILOFFICE_PRICE_NOTIFICATION, notifyPrice);
  yield takeEvery(MAILOFFICE_ERROR_NOTIFICATION, notifyError);
  yield takeEvery(MAILOFFICE_COMPLETE_NOTIFICATION, notifyComplete);
  yield takeLatest(DELETE_MAILOFFICE_RR_REQUEST, deleteMailofficeRR);
}
