import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import Logo from '../panels/Header/Logo';
import Input from '../../components/formControls/Input';
import Button from '../../components/formControls/Button';
import JitsiVideocallPlayerBackground from '../sections/Videocall/JitsiVideocallPlayerBackground';

const messages = defineMessages({
  insertName: {
    id: 'VideocallGuestLogin.label.insertName',
    defaultMessage: 'Insert your name and enter the videocall room',
  },
  enterTheVideocall: {
    id: 'VideocallGuestLogin.button.enterTheVideocall',
    defaultMessage: 'Enter',
  },
  noAliasError: {
    id: 'VideocallGuestLogin.error.noAliasError',
    defaultMessage: 'You have to insert an alias',
  },
  devicesNotAvailable: {
    id: 'VideocallGuestLogin.errors.devicesNotAvailable',
    defaultMessage:
      'You must have video or audio input devices to use these services',
  },
  audioNotAuthorized: {
    id: 'VideocallGuestLogin.errors.audioNotAuthorized',
    defaultMessage: 'You must authorize the use of your audio device. ',
  },
  videoNotAuthorized: {
    id: 'VideocallGuestLogin.errors.videoNotAuthorized',
    defaultMessage: 'You must authorize the use of your video device. ',
  },
  changeSettings: {
    id: 'VideocallGuestLogin.errors.changeSettings',
    defaultMessage: 'Change your browser settings.',
  },
  devicesNotAuthorized: {
    id: 'VideocallGuestLogin.errors.devicesNotAuthorized',
    defaultMessage:
      'You must authorize the use of video or audio input devices. Change your browser settings.',
  },
});

class VideocallGuestLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.setState({ error: null });
    onChange(e);
  };

  enterRoom = () => {
    const {
      name,
      onSubmit,
      intl: { formatMessage },
    } = this.props;
    if (!name || name === '') {
      this.setState({ error: formatMessage(messages.noAliasError) });
      return;
    }
    onSubmit();
  };

  render() {
    const {
      intl: { formatMessage },
      name,
    } = this.props;
    return (
      <div className="h-100 bg-white p-4 text-center">
        <div className="w-75 m-auto" style={{ minWidth: '760px' }}>
          <Logo />
          <div
            style={{
              width: '100%',
              position: 'relative',
              paddingBottom: '65%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '1.2em',
                margin: '2% 11%',
              }}
            >
              <JitsiVideocallPlayerBackground main>
                <div
                  style={{
                    height: '60%',
                  }}
                  className="alert alert-info p-2 mx-3"
                >
                  <div>{formatMessage(messages.insertName)}</div>
                  <div
                    className="p-3 form-inline"
                    style={{ justifyContent: 'center' }}
                  >
                    <Input
                      type="text"
                      id="VideocallGuestAlias"
                      name="VideocallGuestAlias"
                      value={name}
                      onChange={this.onChange}
                      error={this.state.error}
                    />
                    <Button
                      className="btn btn-primary ml-2"
                      onClick={this.enterRoom}
                      text={formatMessage(messages.enterTheVideocall)}
                    />
                  </div>
                </div>
              </JitsiVideocallPlayerBackground>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(VideocallGuestLogin);
