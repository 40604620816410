import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Abook from '.';
import AbookLists from './AbookLists';

const AbookListsPage = () => (
  <Abook scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AbookLists />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookListsPage;
