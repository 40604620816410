import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../../components/icons/Icon';
import ChatButton from '../../../components/buttons/ChatButton';
import { isMeMember } from '../../../js/groups/selectors';
import FavoritesAddMembersDropdown from './FavoritesAddMembersDropdown';
import VideoButton from '../../../components/buttons/VideoButton';

const messages = defineMessages({
  reserved: {
    id: 'GroupToolbar.reserved.',
    defaultMessage: 'Reserved',
  },
  public: {
    id: 'GroupToolbar.public.',
    defaultMessage: 'Public',
  },
  ofOwner: {
    id: 'GroupToolbar.ofOwner.',
    defaultMessage: 'of',
  },
  addUsers: {
    id: 'GroupToolbar.addUsers',
    defaultMessage: 'Add users',
  },
  sendMessage: {
    id: 'GroupToolbar.sendMessage.',
    defaultMessage: 'Send group message',
  },
});

class GroupToolbar extends Component {
  state = {
    addUserPanelOpen: false,
  };

  addUserPanelToggle = () => {
    this.setState((prevState) => ({
      addUserPanelOpen: !prevState.addUserPanelOpen,
    }));
  };

  addFavorites = (selected) => {
    this.addUserPanelToggle();
    if (selected.length > 0) {
      const newMembers = selected.map((id) =>
        id.substring(0, 1) === 'U'
          ? { type: 'U', id: +id.substring(1) }
          : { type: 'A', id: +id.substring(1) }
      );
      this.props.addFavorites(newMembers);
    }
  };

  render() {
    const {
      intl: { formatMessage },
      isMember,
      chat,
      members,
      ...props
    } = this.props;

    return (
      <div className="row pt-1 pb-0 pl-2 pr-2 text-primary position-relative">
        <div className="col-6 text-left">
          <Icon
            name="information-circle"
            tooltipPosition="right"
            width={18}
            height={18}
            color="var(--yn-blue-dark)"
            clickable={false}
            strokeWidth={1.5}
          >
            {props.private
              ? formatMessage(messages.reserved)
              : formatMessage(messages.public)}
            {props.owner
              ? ` ${formatMessage(messages.ofOwner)}  ${props.owner}`
              : ''}
          </Icon>
        </div>
        {chat && !props.favorite && isMember && (
          <div className="col-6 text-right" onClick={this.openConversation}>
            <VideoButton
              className="mr-2"
              group={this.props.id}
              width={18}
              tooltipPosition="left"
              height={18}
            />
            <ChatButton
              group={this.props.id}
              width={18}
              tooltipPosition="left"
              height={18}
            />
          </div>
        )}
        {props.favorite && (
          <React.Fragment>
            <div className="col-6 text-right" onClick={this.addUserPanelToggle}>
              <Icon
                name="person-add-2-filled"
                width={20}
                height={20}
                color="var(--yn-blue-dark)"
              >
                {formatMessage(messages.addUsers)}
              </Icon>
            </div>
            {this.state.addUserPanelOpen && (
              <FavoritesAddMembersDropdown
                onSubmit={(selected) => this.addFavorites(selected)}
                members={members}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

GroupToolbar.propTypes = {
  private: PropTypes.bool.isRequired,
  chat: PropTypes.bool.isRequired,
  favorite: PropTypes.bool.isRequired,
  owner: PropTypes.string,
  id: PropTypes.number.isRequired,
};

GroupToolbar.defaultProps = {
  owner: null,
};

function mapStateToProps(state, ownProps) {
  return {
    isMember: isMeMember(state, ownProps.id),
  };
}

export default injectIntl(connect(mapStateToProps)(GroupToolbar));
