import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import ShareStickyModal from './ShareStickyModal';
import {
  getShareStickyError,
  getShareStickyLoaded,
} from '../../../js/sticky/selectors';
import Icon from '../../../components/icons/Icon';
import { clearNotesErrors } from '../../../js/sticky/actions';

const messages = defineMessages({
  share: {
    id: 'ShareSticky.label.share',
    defaultMessage: 'Share',
  },
});

class ShareSticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shareStickyLoading &&
      !this.props.shareStickyLoading &&
      !this.props.shareStickyError
    )
      this.toggleModal();
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
    if (!this.state.modalOpen) this.props.clearErrors();
  };

  render() {
    const {
      shareDivStyle,
      shareSticky,
      sharingUsers,
      intl: { formatMessage },
    } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <span style={shareDivStyle} onClick={this.toggleModal}>
          <Icon
            name="notepad-share"
            color="var(--gray-dark)"
            clickable
            width={18}
            height={18}
            className="ml-2"
          >
            {formatMessage(messages.share)}
          </Icon>
        </span>
        <ShareStickyModal
          isOpen={modalOpen}
          onClose={this.toggleModal}
          onSave={shareSticky}
          sharingUsers={sharingUsers}
        />
      </>
    );
  }
}

ShareSticky.propTypes = {
  shareSticky: PropTypes.func.isRequired,
  shareDivStyle: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    shareStickyLoading: !getShareStickyLoaded(state),
    shareStickyError: getShareStickyError(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    clearErrors: clearNotesErrors,
  })(ShareSticky)
);
