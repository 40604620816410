import moment from 'moment';
import {
  call,
  put,
  all,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import {
  fetchMeSuccess,
  fetchMeFailure,
  initMainSuccess,
  setStatusSuccess,
  setStatusFailure,
  updateEmailSuccess,
  updateEmailFailure,
  addFavoriteSuccess,
  addFavoriteFailure,
  removeFavoriteSuccess,
  removeFavoriteFailure,
  updateProfileSuccess,
  updateProfileFailure,
  fetchHelpSuccess,
  fetchHelpFailure,
  fetchCustomerSettingsSuccess,
  fetchExtensionsOrderSuccess,
  updateExtensionsOrderSuccess,
  updateExtensionsOrderFailure,
  updatePasswordSuccess,
  updatePasswordFailure,
  fetchPbxsSuccess,
  fetchCustomerRegistryFieldsSuccess,
  fetchCustomerRegistryFieldsFailure,
  setUserStatusSuccess,
  setUserStatusFailure,
  updatePlatformSettingsCustomerSuccess,
  updatePlatformSettingsCustomerFailure,
  checkReleaseRequest,
} from './actions';
import {
  fetchSettingsSuccess,
  fetchSettingsFailure,
} from '../settings/actions';
import { fetchUsersSuccess } from '../users/actions';
import {
  SET_STATUS_REQUEST,
  UPDATE_EMAIL_REQUEST,
  ADD_FAVORITE_REQUEST,
  REMOVE_FAVORITE_REQUEST,
  UPDATE_PROFILE_REQUEST,
  FETCH_HELP_REQUEST,
  UPDATE_EXTENSIONS_ORDER_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  INIT_MAIN_SUCCESS,
  FETCH_ME_SUCCESS,
  FETCH_SETTINGS_REQUEST,
  FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST,
  SET_USER_STATUS_REQUEST,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST,
  CHECK_RELEASE,
} from './types';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { getPlatformLanguage } from '../settings/selectors';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { getUserById } from '../users/selectors';
import { PbxSettings } from '../phone/PbxSettingsUtils';
import { getMeMainExtensionNumber } from '../phone/selectors';
import { getContactById } from '../contacts/selectors';
import { AUTH_SUCCESS } from '../auth/types';
import { fetchMediadevicesRequest } from '../media/actions';
import { UPDATE_PLATFORM_SETTINGS_SUCCESS } from '../settings/types';
import { getMutedChatsSuccess } from '../chat/actions';
import { fetchSMSAliasSuccess, fetchSMSCreditsRequest } from '../sms/actions';
import {
  fetchVideocallNotificationsSuccess,
  fetchVideocallSettingsSuccess,
} from '../videocalls/actions';
import { getMeRelease } from './selectors';
import { fetchAssociationsSuccess } from '../associations/actions';
import {
  fetchAppointmentResultsSuccess,
  fetchAppointmentTypesSuccess,
} from '../calendar/actions';
import { fetchPhoneExternalUrlSuccess } from '../externalUrl/actions';

export function* fetchMe() {
  try {
    const res = yield call(api.me.getMe);
    yield call(checkApiResponse, res);
    yield put(fetchMeSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchMeFailure(error));
  }
}

export function* fetchMain() {
  try {
    const [
      settings,
      users,
      customerSettings,
      pbxs,
      mutedChats,
      videocallSettings,
      videocallNotifications,
      associations,
      appointmentTypes,
      appointmentResults,
      externalUrls,
      smsAlias,
    ] = yield all([
      call(api.me.getSettings),
      call(api.users.getUsers),
      call(api.me.getCustomerSettings),
      call(api.me.getPbxs),
      call(api.chat.getMutedChats),
      call(api.videocalls.getVideocallSettings),
      call(api.videocalls.getVideocallNotifications),
      call(api.associations.getAssociations),
      call(api.calendar.getAppointmentTypes),
      call(api.calendar.getAppointmentResults),
      call(api.externalUrls.getExternalUrls),
      call(api.sms.getSMSAlias),
    ]);
    yield call(checkApiResponse, users);
    yield call(checkApiResponse, customerSettings);
    yield call(checkApiResponse, settings);
    yield call(checkApiResponse, pbxs);
    yield call(checkApiResponse, mutedChats);
    yield call(checkApiResponse, videocallSettings);
    yield call(checkApiResponse, videocallNotifications);
    yield call(checkApiResponse, associations);
    yield call(checkApiResponse, appointmentTypes);
    yield call(checkApiResponse, appointmentResults);
    yield call(checkApiResponse, externalUrls);
    yield call(checkApiResponse, smsAlias);
    yield put(fetchSettingsSuccess(settings.data));
    moment.locale(settings.data.language.toLowerCase());
    yield put(fetchCustomerSettingsSuccess(customerSettings.data));
    yield put(fetchUsersSuccess(users.data.data));
    yield put(fetchPbxsSuccess(pbxs.data));
    yield put(
      getMutedChatsSuccess(mutedChats.status === 204 ? [] : mutedChats.data)
    );
    yield put(fetchVideocallSettingsSuccess(videocallSettings.data));
    yield put(fetchVideocallNotificationsSuccess(videocallNotifications.data));
    yield put(
      fetchAssociationsSuccess(
        associations.status === 204 ? [] : associations.data
      )
    );
    yield put(fetchAppointmentResultsSuccess(appointmentResults.data));
    yield put(fetchAppointmentTypesSuccess(appointmentTypes.data));
    yield put(fetchPhoneExternalUrlSuccess(externalUrls.data));
    yield put(fetchSMSAliasSuccess(smsAlias.data));
    yield put(initMainSuccess());
    yield put(fetchMediadevicesRequest({ audio: true, reset: true }));
    yield put(fetchSMSCreditsRequest());
    const isNewRelease = yield select(getMeRelease);
    if (isNewRelease) {
      yield put(checkReleaseRequest());
    }
    DesktopNotificationManager.init(
      settings.data.volume,
      settings.data.notifSound,
      settings.data.notifTimeout
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchMeFailure(error));
  }
}

export function* fetchMeSettings() {
  try {
    const res = yield call(api.me.getSettings);
    yield call(checkApiResponse, res);
    yield put(fetchSettingsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    yield put(fetchSettingsFailure(error));
  }
}

export function updateSettings(action) {
  moment.locale(action.preferences.language.toLowerCase());
}

export function* getExtensionsOrder() {
  try {
    const res = yield call(api.me.getExtensionsOrder);
    yield call(checkApiResponse, res);
    const extensions = [];
    const meMainExtensionNumber = yield select(getMeMainExtensionNumber);
    for (let i = 0; i < res.data.users.length; i += 1) {
      const user = yield select(getUserById, res.data.users[i]);
      if (
        user.mainExtensionNumber &&
        user.mainExtensionNumber !== PbxSettings.DISABLED_PHONE_NUMBER &&
        user.mainExtensionNumber !== meMainExtensionNumber
      ) {
        extensions.push({
          id: user.id,
          fullname: user.fullname,
          avatar: user.avatar,
          number: user.mainExtensionNumber,
          orderBy: +user.mainExtensionNumber,
        });
      }
    }
    yield put(fetchExtensionsOrderSuccess(extensions));
  } catch (err) {}
}

export function* updateEmail(action) {
  try {
    const res = yield call(api.me.updateEmail, action.payload);
    yield call(checkApiResponse, res);
    yield put(updateEmailSuccess(action.payload, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error && err.data && err.data !== '' && err.data.length > 0) {
      yield put(updateEmailFailure({ status: error, messages: err.data }));
    } else {
      if (error) yield put(updateEmailFailure({ status: error }));
    }
  }
}

export function* updatePassword(action) {
  try {
    const res = yield call(api.me.updatePassword, action.password);
    yield call(checkApiResponse, res);
    yield put(updatePasswordSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePasswordFailure(error));
  }
}

export function* setStatus(action) {
  try {
    /** TODO */
    yield put(setStatusSuccess(action.statusType));
  } catch (err) {
    yield put(setStatusFailure(err));
  }
}

export function* addFavorite(action) {
  try {
    const res = yield call(
      action.member.type === 'U'
        ? api.me.addFavoriteUser
        : api.me.addFavoriteContact,
      { id: action.member.id }
    );
    let member = {};
    yield call(checkApiResponse, res);
    if (action.member.type === 'U') {
      member = yield select(getUserById, action.member.id);
    } else if (action.member.type === 'A') {
      member = yield select(getContactById, action.member.id);
    }
    yield put(addFavoriteSuccess({ ...action.member, ...member }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(addFavoriteFailure(error));
  }
}

export function* removeFavorite(action) {
  try {
    const res = yield call(
      action.member.type === 'U'
        ? api.me.removeFavoriteUser
        : api.me.removeFavoriteContact,
      { id: action.member.id }
    );
    yield call(checkApiResponse, res);
    yield put(removeFavoriteSuccess(action.member));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removeFavoriteFailure(error));
  }
}

export function* updateprofileSettings(action) {
  try {
    const res = yield call(api.me.updateProfileSettings, action.userData);
    yield call(checkApiResponse, res);
    yield put(updateProfileSuccess(action.userData, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) {
      if (err.data && err.data !== '' && err.data.length > 0) {
        yield put(updateProfileFailure({ status: error, messages: err.data }));
      } else {
        yield put(updateProfileFailure({ status: error }));
      }
    }
  }
}

export function* fetchHelp(action) {
  try {
    const language = yield select(getPlatformLanguage);
    const path = `help/${language}/${action.path}.html`;
    const res = yield call(api.getHelp, path);
    yield call(checkApiResponse, res);

    yield put(fetchHelpSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchHelpFailure(error));
  }
}

export function* updateExtensionsOrder(action) {
  try {
    const res = yield call(
      api.me.updateExtensionsOrder,
      action.extensions.map((extension) => extension.id)
    );
    yield call(checkApiResponse, res);
    yield put(updateExtensionsOrderSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateExtensionsOrderFailure(error));
  }
}

export function* fetchCustomerRegistryFields() {
  try {
    const res = yield call(api.me.getCustomerRegistryFields);
    yield call(checkApiResponse, res);
    yield put(
      fetchCustomerRegistryFieldsSuccess(res.status === 204 ? [] : res.data)
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchCustomerRegistryFieldsFailure(error));
  }
}

export function* setUserStatus(action) {
  try {
    const res = yield call(api.me.setUserStatus, action.payload);
    yield call(checkApiResponse, res);
    yield put(setUserStatusSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(setUserStatusFailure(error));
  }
}

export function* updateCustomerPlatformSettings(action) {
  try {
    const res = yield call(
      api.me.updatePlatformCustomerSettings,
      action.preferences
    );
    yield call(checkApiResponse, res);
    yield put(updatePlatformSettingsCustomerSuccess(action.preferences));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updatePlatformSettingsCustomerFailure(error));
  }
}

export function* checkRelease(action) {
  try {
    const res = yield call(api.me.checkRelease, action.payload);
    yield call(checkApiResponse, res);
  } catch (err) {
    yield call(checkApiError, err);
  }
}

export default function* rootSaga() {
  yield takeLatest(AUTH_SUCCESS, fetchMe);
  yield takeLatest(FETCH_ME_SUCCESS, fetchMain);
  yield takeLatest(INIT_MAIN_SUCCESS, getExtensionsOrder);
  yield takeLatest(SET_STATUS_REQUEST, setStatus);
  yield takeEvery(ADD_FAVORITE_REQUEST, addFavorite);
  yield takeLatest(REMOVE_FAVORITE_REQUEST, removeFavorite);
  yield takeLatest(UPDATE_EMAIL_REQUEST, updateEmail);
  yield takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateprofileSettings);
  yield takeLatest(FETCH_HELP_REQUEST, fetchHelp);
  yield takeLatest(UPDATE_EXTENSIONS_ORDER_REQUEST, updateExtensionsOrder);
  yield takeLatest(FETCH_SETTINGS_REQUEST, fetchMeSettings);
  yield takeLatest(UPDATE_PLATFORM_SETTINGS_SUCCESS, updateSettings);
  yield takeLatest(SET_USER_STATUS_REQUEST, setUserStatus);
  yield takeLatest(
    FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST,
    fetchCustomerRegistryFields
  );
  yield takeLatest(
    UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST,
    updateCustomerPlatformSettings
  );
  yield takeLatest(CHECK_RELEASE, checkRelease);
}
