import keyBy from 'lodash.keyby';
import union from 'lodash.union';
import forIn from 'lodash.forin';
import pull from 'lodash.pull';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_WIKI_ROOT_DOCUMENTS_REQUEST,
  FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS,
  FETCH_WIKI_ROOT_DOCUMENTS_FAILURE,
  FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST,
  FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS,
  FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE,
  FETCH_WIKI_SEARCH_REQUEST,
  FETCH_WIKI_SEARCH_SUCCESS,
  FETCH_WIKI_SEARCH_FAILURE,
  FETCH_WIKI_TAGS_REQUEST,
  FETCH_WIKI_TAGS_SUCCESS,
  FETCH_WIKI_TAGS_FAILURE,
  FETCH_WIKI_DOCUMENT_REQUEST,
  FETCH_WIKI_DOCUMENT_SUCCESS,
  FETCH_WIKI_DOCUMENT_FAILURE,
  SET_WIKI_CHILDREN_COLLAPSE,
  SET_WIKI_FILTER,
  SAVE_WIKI_DOCUMENT_REQUEST,
  SAVE_WIKI_DOCUMENT_SUCCESS,
  SAVE_WIKI_DOCUMENT_FAILURE,
  REMOVE_WIKI_DOCUMENT_REQUEST,
  REMOVE_WIKI_DOCUMENT_SUCCESS,
  REMOVE_WIKI_DOCUMENT_FAILURE,
} from './types';

const initialState = {
  treeById: {},
  treeIds: [],
  searchById: {},
  searchIds: [],
  tagsById: {},
  tagsIds: [],
  filter: {
    text: '',
    tags: null,
  },
  fetchWikiTags: {
    loaded: true,
    error: null,
  },
  fetchWikiDocuments: {
    loaded: true,
    error: null,
  },
  editDocument: {
    data: null,
    loaded: true,
    error: null,
  },
};

export default function wiki(state = initialState, action = {}) {
  const treeById = { ...state.treeById };
  const treeIds = [...state.treeIds];
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_WIKI_ROOT_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchWikiDocuments: {
          loaded: false,
          error: null,
        },
      };
    case FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS: {
      const rootById = keyBy(action.documents, 'id');
      forIn(rootById, (value, key) => {
        if (value.hasChildren)
          value['collapseChildren'] =
            treeById[key] &&
            typeof treeById[key].collapseChildren !== 'undefined'
              ? treeById[key].collapseChildren
              : true;
      });
      return {
        ...state,
        treeById: {
          ...treeById,
          ...rootById,
        },
        treeIds: state.treeIds
          ? action.documents &&
            action.documents.map((item) => {
              return item.id;
            })
          : [],
        fetchWikiDocuments: {
          loaded: true,
          error: null,
        },
      };
    }
    case FETCH_WIKI_ROOT_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchWikiDocuments: {
          loaded: true,
          error: action.errors,
        },
      };
    case FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST:
      return {
        ...state,
        treeById: {
          ...treeById,
          [action.parentId]: {
            ...treeById[action.parentId],
            loadingChildren: true,
          },
        },
      };
    case FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS: {
      const byId = keyBy(action.documents, 'id');
      forIn(byId, (value, key) => {
        if (value.hasChildren)
          value['collapseChildren'] =
            treeById[key] &&
            typeof treeById[key].collapseChildren !== 'undefined'
              ? treeById[key].collapseChildren
              : true;
      });
      return {
        ...state,
        treeById: {
          ...treeById,
          ...byId,
          [action.parentId]: {
            ...treeById[action.parentId],
            loadingChildren: undefined,
          },
        },
        treeIds: treeIds
          ? union(
              treeIds,
              action.documents &&
                action.documents.map((item) => {
                  return item.id;
                })
            )
          : [],
      };
    }
    case FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE:
      return {
        ...state,
      };
    case FETCH_WIKI_SEARCH_REQUEST:
      return {
        ...state,
        fetchWikiDocuments: {
          loaded: false,
          error: null,
        },
      };
    case FETCH_WIKI_SEARCH_SUCCESS:
      return {
        ...state,
        searchById: keyBy(action.documents, 'id'),
        searchIds: action.documents
          ? action.documents &&
            action.documents.map((item) => {
              return item.id;
            })
          : [],
        fetchWikiDocuments: {
          loaded: true,
          error: null,
        },
      };
    case FETCH_WIKI_SEARCH_FAILURE:
      return {
        ...state,
        fetchWikiDocuments: {
          loaded: true,
          error: action.errors,
        },
      };
    case FETCH_WIKI_TAGS_REQUEST:
      return {
        ...state,
        fetchWikiTags: {
          loaded: false,
          error: null,
        },
      };
    case FETCH_WIKI_TAGS_SUCCESS:
      return {
        ...state,
        tagsById: keyBy(action.tags, 'id'),
        tagsIds: action.tags
          ? action.tags.map((item) => {
              return item.id;
            })
          : [],
        fetchWikiTags: {
          loaded: true,
          error: null,
        },
      };
    case FETCH_WIKI_TAGS_FAILURE:
      return {
        ...state,
        fetchWikiTags: {
          loaded: true,
          error: action.errors,
        },
      };
    case FETCH_WIKI_DOCUMENT_REQUEST:
      return {
        ...state,
        editDocument: {
          data: null,
          loaded: false,
          error: null,
        },
      };
    case FETCH_WIKI_DOCUMENT_SUCCESS:
      action.document.parent = {};
      if (
        action.document.idParent &&
        action.document.path &&
        action.document.path.length
      ) {
        const parents = action.document.path.filter((item) => {
          return item.id === action.document.idParent;
        });
        if (parents.length) action.document.parent = parents[0];
      }
      return {
        ...state,
        editDocument: {
          data: action.document,
          loaded: true,
          error: null,
        },
      };
    case FETCH_WIKI_DOCUMENT_FAILURE:
      return {
        ...state,
        editDocument: {
          data: null,
          loaded: true,
          error: action.errors,
        },
      };
    case SET_WIKI_CHILDREN_COLLAPSE: {
      const collapse =
        typeof treeById[action.id].collapseChildren !== 'undefined'
          ? !treeById[action.id].collapseChildren
          : true;
      return {
        ...state,
        treeById: {
          ...treeById,
          [action.id]: {
            ...treeById[action.id],
            collapseChildren: collapse,
          },
        },
      };
    }
    case SET_WIKI_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case SAVE_WIKI_DOCUMENT_REQUEST:
      return {
        ...state,
        editDocument: {
          ...state.editDocument,
          loaded: false,
          error: null,
        },
      };
    case SAVE_WIKI_DOCUMENT_SUCCESS: {
      treeById[action.document.id] = {
        ...treeById[action.document.id],
        idParent: action.document.parent,
        title: action.document.title,
      };
      return {
        ...state,
        treeById,
        editDocument: {
          data: action.document,
          loaded: true,
          error: null,
        },
      };
    }
    case SAVE_WIKI_DOCUMENT_FAILURE:
      return {
        ...state,
        editDocument: {
          ...state.editDocument,
          loaded: true,
          error: action.errors,
        },
      };
    case REMOVE_WIKI_DOCUMENT_REQUEST:
      return {
        ...state,
        editDocument: {
          loaded: false,
          error: null,
        },
      };
    case REMOVE_WIKI_DOCUMENT_SUCCESS: {
      delete treeById[action.id];
      pull(treeIds, action.id);
      return {
        ...state,
        treeById,
        treeIds,
        editDocument: {
          data: null,
          loaded: true,
          error: null,
        },
      };
    }
    case REMOVE_WIKI_DOCUMENT_FAILURE:
      return {
        ...state,
        editDocument: {
          ...state.editDocument,
          loaded: true,
          error: action.errors,
        },
      };
    default:
      return state;
  }
}
