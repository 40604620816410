import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { getExtensionStatus, getExtensionDnd } from '../js/phone/selectors';
import { PhoneEnums } from '../js/phone/PhoneUtils';
import InterlocutorPanel from './InterlocutorPanel';

const style = {
  defaultElement: {
    background: 'transparent',
  },
  busyExtension: {
    background: 'var(--yn-danger-background-color)',
    cursor: 'not-allowed',
  },
  availableExtension: {
    background: 'var(--yn-success-background-color)',
    cursor: 'pointer',
  },
  availableContact: {
    background: 'var(--yn-info-background-color)',
    cursor: 'pointer',
  },
};

class PhoneNumberSearchElement extends Component {
  state = {
    hovered: null,
  };

  render() {
    const { contact, phoneStatus, phoneDnd } = this.props;
    const busyExtension =
      contact.type === 'EXTENSION' &&
      (phoneStatus === null ||
        (phoneStatus !== PhoneEnums.PhoneStatuses.inited &&
          phoneStatus !== PhoneEnums.UserStatuses.available &&
          phoneStatus !== PhoneEnums.UserStatuses.ready) ||
        phoneDnd === PhoneEnums.DndStatus.ON);

    const elementStyle = this.state.hovered
      ? contact.type === 'EXTENSION'
        ? busyExtension
          ? style.busyExtension
          : style.availableExtension
        : style.availableContact
      : busyExtension
      ? style.busyExtension
      : style.defaultElement;

    return (
      <li
        style={{ padding: '0.25em', ...elementStyle }}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        onClick={!busyExtension ? this.props.onClick : null}
      >
        <InterlocutorPanel
          ellipsis
          interlocutor={contact}
          avatarPopover={false}
        />
      </li>
    );
  }
}
PhoneNumberSearchElement.propTypes = {
  contact: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    phoneStatus:
      ownProps.contact.type === 'EXTENSION'
        ? getExtensionStatus(state, ownProps.contact.username)
        : null,
    phoneDnd:
      ownProps.contact.type === 'EXTENSION'
        ? getExtensionDnd(state, ownProps.contact.username)
        : null,
  };
}

export default connect(mapStateToProps)(PhoneNumberSearchElement);
