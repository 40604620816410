import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import {
  getGroupMembersLoaded,
  getGroupMembersById,
  getGroupById,
} from '../js/groups/selectors';
import { hasGrants } from '../js/me/selectors';
import UserAvatar from './UserAvatar';
import ContactAvatar from './ContactAvatar';
import ToolboxIcon from './icons/ToolboxIcon';
import Popover from './Popover';
import Label from './formControls/Label';

const messages = defineMessages({
  participants: {
    id: 'GroupMembersPopover.tooltip.participants',
    defaultMessage: '{name} members',
  },
  users: {
    id: 'GroupMembersPopover.tooltip.users',
    defaultMessage: 'Users:',
  },
  contacts: {
    id: 'GroupMembersPopover.tooltip.contacts',
    defaultMessage: 'Address book contacts:',
  },
  removeFromChat: {
    id: 'GroupMembersPopover.tooltip.removeFromChat',
    defaultMessage: 'Remove from chat',
  },
});

const GroupMembersPopover = ({
  group,
  canEditPublicGroups,
  name,
  members,
  loading,
  isOpen,
  target,
  toggle,
  removeMember,
  showContacts,
  intl: { formatMessage },
}) => {
  const users = members.filter((m) => m.type === 'U');
  const contacts = members.filter((m) => m.type === 'A');
  const canEdit =
    (group.private && group.mine) || (!group.private && canEditPublicGroups);

  return (
    <Popover
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      header={formatMessage(messages.participants, { name })}
      loading={loading}
    >
      {users && users.length > 0 && (
        <div className="mb-1">
          <Label for="users">{formatMessage(messages.users)}</Label>
          <ul id="users" className="list-unstyled">
            {users.map((user) => (
              <li key={user.id} style={{ position: 'relative' }}>
                <UserAvatar
                  id={user.id}
                  alt={user.departmentFullname}
                  size="md"
                  src={user.avatar}
                  showStatus
                  statusPosition={{ bottom: -4, left: -4 }}
                />
                <span
                  style={{
                    width: removeMember ? '8rem' : '10.5rem',
                    lineHeight: 1.1,
                  }}
                  className="ml-2 text-truncate d-inline-block"
                >
                  {user.departmentFullname}
                </span>
                {removeMember && canEdit && (
                  <div
                    className="float-right mt-1"
                    onClick={() => removeMember(user.id)}
                  >
                    <ToolboxIcon name="bin-1">
                      {formatMessage(messages.removeFromChat)}
                    </ToolboxIcon>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {showContacts && contacts && contacts.length > 0 && (
        <div className="mb-1">
          <Label for="contacts">{formatMessage(messages.contacts)}</Label>
          <ul className="list-unstyled" id="contacts">
            {contacts.map((contact) => (
              <li key={contact.id}>
                <ContactAvatar
                  id={contact.id}
                  alt={contact.fullname}
                  size="md"
                  src={contact.avatar}
                />
                {contact.fullname}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Popover>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    members: getGroupMembersById(state, ownProps.id),
    loading: !getGroupMembersLoaded(state, ownProps.id),
    group: getGroupById(state, ownProps.id),
    canEditPublicGroups: hasGrants(state, 'GROUP_PUBLIC'),
  };
}

GroupMembersPopover.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  removeMember: PropTypes.func,
  showContacts: PropTypes.bool,
};

export default injectIntl(connect(mapStateToProps)(GroupMembersPopover));
