import { NotificationSettings, NotificationEnums } from './NotificationUtils';

export class DesktopNotificationManager {
  static canShowNotifications;

  static activeNotifications;

  static notificationAudioElement;

  static notificationSoundEnabled;

  static notificationSoundDuration;

  static init = (volume, soundEnabled, soundDuration) => {
    if (!('Notification' in window)) {
      DesktopNotificationManager.canShowNotifications = false;
      return;
    }
    DesktopNotificationManager.canShowNotifications = true;
    // isAppDesktop
    const { platform } = window;
    if (
      DesktopNotificationManager.retrieveNotificationPermission() ===
      NotificationEnums.NotificationPermissions.DEFAULT
    ) {
      Notification.requestPermission();
    }
    DesktopNotificationManager.activeNotifications = {};
    DesktopNotificationManager.notificationSoundEnabled = soundEnabled;
    DesktopNotificationManager.notificationSoundDuration = soundDuration * 1000;
    DesktopNotificationManager.initAudio(volume);
    DesktopNotificationManager.isMac = platform === 'darwin';
    DesktopNotificationManager.isWin = platform === 'win32';
    DesktopNotificationManager.isLinux = platform === 'linux';
  };

  static initAudio = (volume) => {
    DesktopNotificationManager.notificationAudioElement = new Audio(
      NotificationSettings.NOTIFICATION_SOUND
    );
    DesktopNotificationManager.notificationAudioElement.volume =
      (volume || NotificationSettings.DEFAULT_VOLUME) / 100;
    DesktopNotificationManager.notificationAudioElement.loop = false;
    DesktopNotificationManager.notificationAudioElement.pause();
  };

  static retrieveNotificationPermission = () => {
    if (!Notification || !Notification.permission) {
      return null;
    }
    switch (Notification.permission) {
      case 'granted':
        return NotificationEnums.NotificationPermissions.ALLOW;
      case 'default':
        return NotificationEnums.NotificationPermissions.DEFAULT;
      case 'denied':
        return NotificationEnums.NotificationPermissions.DENY;
      default:
        return null;
    }
  };

  static changeVolume = (volume) => {
    DesktopNotificationManager.notificationAudioElement.volume = volume / 100;
  };

  static changeSoundSettings = (soundEnabled, soundDuration) => {
    DesktopNotificationManager.notificationSoundEnabled = soundEnabled;
    DesktopNotificationManager.notificationSoundDuration = soundDuration;
  };

  static createNotification = (params) => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    if (DesktopNotificationManager.activeNotifications[params.id]) {
      return;
    }
    // isAppDesktop
    const { remote } = window;

    const newNotification = new Notification(
      params.title || NotificationSettings.DEFAULT_TITLE,
      {
        icon: params.image || NotificationSettings.DEFAULT_IMAGE,
        requireInteraction: true,
        body: params.body,
      }
    );
    newNotification.onclose = () => {
      delete DesktopNotificationManager.activeNotifications[params.id];
      if (params.onclose) {
        params.onclose();
      }
    };
    newNotification.onclick = () => {
      // isAppDesktop
      if (remote) {
        const win = remote.getCurrentWindow();
        win.show();
      }
      window.focus();
      if (params.onclick) {
        params.onclick();
      }
      newNotification.close();
    };
    if (params.onshow) {
      newNotification.onshow = params.onshow;
    }
    if (
      params.timeout ||
      DesktopNotificationManager.notificationSoundDuration
    ) {
      setTimeout(
        newNotification.close.bind(newNotification),
        params.timeout || DesktopNotificationManager.notificationSoundDuration
      );
    }
    if (
      !params.mute &&
      DesktopNotificationManager.notificationAudioElement &&
      DesktopNotificationManager.notificationSoundEnabled
    ) {
      DesktopNotificationManager.notificationAudioElement.currentTime = 0;
      DesktopNotificationManager.notificationAudioElement.play();
    }
    DesktopNotificationManager.activeNotifications[params.id] = {
      notification: newNotification,
      group: params.group,
    };
  };

  static sendNotification = (params) => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    const permission =
      DesktopNotificationManager.retrieveNotificationPermission();

    if (
      !permission ||
      permission === NotificationEnums.NotificationPermissions.DENY
    ) {
      return;
    }

    const createNotification = () => {
      if (DesktopNotificationManager.isWin) {
        DesktopNotificationManager.createNotification(params);
      } else if (DesktopNotificationManager.isMac) {
        DesktopNotificationManager.createNotification(params);
      } else if (DesktopNotificationManager.isLinux) {
        DesktopNotificationManager.createNotification(params);
      } else {
        DesktopNotificationManager.createNotification(params);
      }
    };

    if (Notification) {
      if (permission === NotificationEnums.NotificationPermissions.DEFAULT) {
        Notification.requestPermission((newPermission) => {
          if (newPermission === 'granted') {
            createNotification();
            // DesktopNotificationManager.createNotification(params);
          }
        });
      }
      if (permission === NotificationEnums.NotificationPermissions.ALLOW) {
        createNotification();
        // DesktopNotificationManager.createNotification(params);
      }
    }
  };

  static hideNotification = (id) => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    if (
      DesktopNotificationManager.activeNotifications &&
      DesktopNotificationManager.activeNotifications[id]
    ) {
      DesktopNotificationManager.activeNotifications[id].notification.close();
      return true;
    }
    return false;
  };

  static hideAllNotifications = () => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    if (DesktopNotificationManager.activeNotifications) {
      Object.keys(DesktopNotificationManager.activeNotifications).forEach(
        (key) => {
          DesktopNotificationManager.activeNotifications[
            key
          ].notification.close();
        }
      );
    }
  };

  static hideNotificationsForGroup = (group) => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    if (
      DesktopNotificationManager.activeNotifications /* &&
      !DesktopNotificationManager.isWin */
    ) {
      Object.keys(DesktopNotificationManager.activeNotifications).forEach(
        (key) => {
          if (
            DesktopNotificationManager.activeNotifications[key].group === group
          ) {
            DesktopNotificationManager.activeNotifications[
              key
            ].notification.close();
          }
        }
      );
    }
  };

  static existNotificationGroup = (group) => {
    if (!DesktopNotificationManager.canShowNotifications) {
      return;
    }
    let found = false;
    if (DesktopNotificationManager.activeNotifications) {
      Object.keys(DesktopNotificationManager.activeNotifications).forEach(
        (key) => {
          if (
            DesktopNotificationManager.activeNotifications[key].group === group
          ) {
            found = true;
          }
        }
      );
    }
    return found;
  };
}
