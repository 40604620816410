import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import history from '../history';
import Button from './formControls/Button';
import ContactsSelect from './formControls/ContactsSelect';
import Label from './formControls/Label';

const messages = defineMessages({
  contacts: {
    id: 'ContactSelectModal.label.contacts',
    defaultMessage: 'Contacts',
  },
  next: {
    id: 'ContactSelectModal.label.next',
    defaultMessage: 'Next',
  },
  close: {
    id: 'ContactSelectModal.label.close',
    defaultMessage: 'Close',
  },
  selectContact: {
    id: 'ContactSelectModal.label.selectContact',
    defaultMessage: 'Select contact',
  },
  misingContact: {
    id: 'ContactSelectModal.label.misingContact',
    defaultMessage: 'You must select a contact',
  },
});

class ContactSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContact: null,
      errors: {},
    };
  }

  handleContactSelection = (selected) => {
    this.setState({
      selectedContact: selected,
      selectContactError: '',
    });
    const errors = {
      contact: null,
    };
    delete errors.contact;
    this.setState({ errors });
  };

  redirectToContact = () => {
    const {
      type,
      number,
      intl: { formatMessage },
    } = this.props;
    const { selectedContact } = this.state;

    return selectedContact
      ? history.push(
          `/abook/contacts/edit/${selectedContact}?type=${type}&number=${number}`
        )
      : this.setState({
          ...this.state,
          selectContactError: formatMessage(messages.misingContact),
        });
  };

  render() {
    const {
      isOpen,
      toggle,
      intl: { formatMessage },
    } = this.props;
    const { selectedContact, selectContactError } = this.state;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          {formatMessage(messages.selectContact)}
        </ModalHeader>
        <ModalBody>
          <div className="form-row">
            <div className="col-12 my-2">
              <Label for="users">{formatMessage(messages.contacts)}</Label>
              <ContactsSelect
                id="contacts"
                isClearable
                isMulti={false}
                onSelect={this.handleContactSelection}
                selected={selectedContact}
                error={selectContactError}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-primary"
            text={formatMessage(messages.next)}
            onClick={this.redirectToContact}
          />

          <Button
            className="btn-outline-primary"
            onClick={toggle}
            text={formatMessage(messages.close)}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

export default injectIntl(ContactSelectModal);
