export default class WsManager {
  static socket;

  static active = false;

  static initSocket = (socket) => {
    WsManager.socket = socket;
    WsManager.active = true;
  };

  static isActive = () => WsManager.active;

  static sendMessage = (data) => {
    if (WsManager.socket && WsManager.socket.readyState) {
      WsManager.socket.send(JSON.stringify(data));
    }
  };

  static connectionState = () =>
    WsManager.socket && WsManager.socket.readyState;

  static disconnect = () => {
    if (WsManager.socket) {
      WsManager.active = false;
      WsManager.socket.close();
      WsManager.socket = null;
    }
  };
}
