import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const CalendarAppointmentTypesList = React.lazy(() =>
  import('./CalendarAppointmentTypesList')
);
const CalendarAppointmentTypesPage = () => {
  return (
    <Calendar scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="appointments-types" />
            </div>
            <CalendarAppointmentTypesList />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </Calendar>
  );
};

export default CalendarAppointmentTypesPage;
