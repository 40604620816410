import { request } from '../request';

export default {
  getGrants: () =>
    request({
      url: '/grants',
      method: 'GET',
    }),
  getUserGrants: (id) =>
    request({
      url: `/users/${id}/grants`,
      method: 'GET',
    }),
  setUserGrants: (id, grants) =>
    request({
      url: `/users/${id}/grants`,
      method: 'POST',
      data: {
        grants,
      },
    }),
  setMeAdvancedGrants: (id, grants) =>
    request({
      url: `/me/advanced-grants`,
      method: 'POST',
      data: {
        grants,
      },
    }),
  getProfileGrants: (id) =>
    request({
      url: `/profiles/${id}/grants`,
      method: 'GET',
    }),
};
