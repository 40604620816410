import { request } from '../request';

export default {
  getFaxNumbers: () =>
    request({
      url: '/fax/numbers',
      method: 'GET',
    }),
  getFaxList: (params) =>
    request({
      url: '/fax/documents',
      method: 'GET',
      params,
    }),
  updateFax: (id, params) =>
    request({
      url: `/fax/documents/${id}`,
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((err) => err),
  loadFaxDetails: (id) =>
    request({
      url: `/fax/documents/${id}`,
      method: 'GET',
    }),
  saveFaxSettings: (id, params) =>
    request({
      url: `/fax/numbers/${id}`,
      method: 'POST',
      data: params,
    })
      .then((res) => res)
      .catch((err) => err),
  sendFax: (data) =>
    request({
      url: `/fax/documents`,
      method: 'POST',
      data,
    }),
  refreshFaxStatus: (id) =>
    request({
      url: `/fax/polling/${id}`,
      method: 'POST',
    }),
  getFaxesAbookList: (id, params) =>
    request({
      url: `/fax/abook/${id}`,
      method: 'GET',
      params,
    }),
};
