/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import Cookies from 'universal-cookie';
import authApi from '../auth/api';
import ynConf from '../../conf';

// isAppDesktop
const EStore = window.remote ? window.require('electron-store') : null;

export class YnSid {
  static getRemote() {
    return new Promise((resolve, reject) => {
      // isAppDesktop
      if (window.remote) {
        window.remote.session.defaultSession.cookies
          .get({ url: ynConf.clientBaseUrl })
          .then((cookies) => {
            const _cookies = cookies.filter(
              (item) => item.name === 'youneed-sid'
            );
            _cookies && _cookies.length
              ? resolve(_cookies[0].value)
              : resolve(null);
          })
          .catch((e) => reject(e));
      } else {
        resolve();
      }
    });
  }

  static get() {
    // isAppDesktop
    if (window.remote) {
      const localStore = new EStore();
      return localStore.get('youneed-sid');
    }
    return new Cookies().get('youneed-sid');
  }

  static set(value) {
    // isAppDesktop
    if (window.remote) {
      const localStore = new EStore();
      return localStore.set('youneed-sid', value);
    }
  }

  static refreshSid() {
    return new Promise((resolve) => {
      let stored = YnSid.get();
      // isAppDesktop
      if (window.remote) {
        if (stored) {
          authApi
            .desktopAutologin()
            .then(() => {
              return YnSid.getRemote();
            })
            .then((sid) => {
              if (sid && sid !== stored) {
                YnSid.set(sid);
                stored = sid;
              }
              return Promise.resolve();
            })
            .catch(() => {
              console.log('desktop autologin failed');
              resolve();
            })
            .finally(() => {
              resolve(stored);
            });
        } else {
          resolve();
        }
      } else {
        resolve(stored);
      }
    });
  }

  static remove() {
    // isAppDesktop
    if (window.remote) {
      const localStore = new EStore();
      localStore.delete('youneed-sid');
      window.remote.session.defaultSession.cookies
        .remove('file://', 'youneed-sid')
        .catch((e) => {
          console.log(e);
        });
    } else {
      new Cookies().remove('youneed-sid');
    }
  }
}
