import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import UsersSelect from '../../../components/formControls/UsersSelect';
import Button from '../../../components/formControls/Button';

const messages = defineMessages({
  ok: {
    id: 'ChatAddUserDropdown.button.ok',
    defaultMessage: 'Ok',
  },
});

class ChatAddUserDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  handleUsersSelection = (selected) => {
    this.setState({
      users: selected,
    });
  };

  render() {
    const {
      onSelect,
      excluded,
      width,
      intl: { formatMessage },
    } = this.props;
    const { users } = this.state;
    return (
      <div
        className="rounded-bottom p-1"
        style={{
          background: 'var(--white)',
          position: 'absolute',
          top: '66px',
          left: 3,
          right: 2,
          zIndex: 961,
          display: 'flex',
          flexflow: 'row wrap',
          borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          boxShadow: '0 0.05rem 0.25rem rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <UsersSelect
            isMulti
            isClearable
            onSelect={this.handleUsersSelection}
            excluded={excluded}
            selected={users}
            style={{ multiValueLabel: { maxWidth: `${width - 150}px` } }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0,
          }}
        >
          <Button
            text={formatMessage(messages.ok)}
            onClick={() => onSelect(users)}
            style={{ height: '35px' }}
          />
        </div>
      </div>
    );
  }
}

ChatAddUserDropdown.propTypes = {
  excluded: PropTypes.arrayOf(PropTypes.number),
  onSelect: PropTypes.func.isRequired,
};

ChatAddUserDropdown.defaultProps = {
  excluded: [],
};

export default injectIntl(ChatAddUserDropdown);
