import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  isChatConversationReadAllError,
  isChatConversationDeleteAllError,
  isChatConversationRenameGroupError,
  isChatConversationDeleteGroupError,
  isChatConversationDeleteMessagesError,
  isChatConversationMarkAsImportantError,
  isChatConversationUnmarkAsImportantError,
} from '../../../js/chat/selectors';

const messages = defineMessages({
  errorUpdatingGroup: {
    id: 'ChatHistory.error.errorUpdatingGroup',
    defaultMessage: 'Error updating the group',
  },
  errorDeletingGroup: {
    id: 'ChatHistory.error.errorDeletingGroup',
    defaultMessage: 'Error deleting the group',
  },
  errorReadingAll: {
    id: 'ChatHistory.error.errorReadingAll',
    defaultMessage: 'Error reading history',
  },
  errorDeletingAll: {
    id: 'ChatHistory.error.errorDeletingAll',
    defaultMessage: 'Error deleting history',
  },
  errorDeletingMessages: {
    id: 'ChatHistory.error.errorDeletingMessages',
    defaultMessage: 'Error deleting messages',
  },
  errorMarkingAsImportant: {
    id: 'ChatHistory.error.errorMarkingAsImportant',
    defaultMessage: 'Error marking message as important',
  },
  errorUnmarkingAsImportant: {
    id: 'ChatHistory.error.errorUnmarkingAsImportant',
    defaultMessage: 'Error unmarking important message',
  },
});

class ChatWindowToolbarErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.show = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !nextProps.error &&
      !nextProps.errorReadingAll &&
      !nextProps.errorDeletingAll &&
      !nextProps.errorDeletingMessages &&
      !nextProps.errorUpdatingGroup &&
      !nextProps.errorDeletingGroup &&
      !nextProps.errorMarkingAsImportant &&
      !nextProps.errorUnmarkingAsImportant &&
      this.state.visible === nextState.visible
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.error && !prevProps.error) ||
      (this.props.errorReadingAll && !prevProps.errorReadingAll) ||
      (this.props.errorDeletingAll && !prevProps.errorDeletingAll) ||
      (this.props.errorDeletingMessages && !prevProps.errorDeletingMessages) ||
      (this.props.errorUpdatingGroup && !prevProps.errorUpdatingGroup) ||
      (this.props.errorDeletingGroup && !prevProps.errorDeletingGroup) ||
      (this.props.errorMarkingAsImportant &&
        !prevProps.errorMarkingAsImportant) ||
      (this.props.errorUnmarkingAsImportant &&
        !prevProps.errorUnmarkingAsImportant)
    ) {
      clearTimeout(this.show);
      this.setState({
        visible: true,
      });
      this.show = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.show);
  }

  render() {
    const {
      intl: { formatMessage },
      error,
      errorReadingAll,
      errorDeletingAll,
      errorDeletingMessages,
      errorUpdatingGroup,
      errorDeletingGroup,
      errorMarkingAsImportant,
      errorUnmarkingAsImportant,
    } = this.props;

    let errorMessage;
    if (error) {
      errorMessage = error;
    }
    if (errorDeletingMessages) {
      errorMessage = formatMessage(messages.errorDeletingMessages);
    }
    if (errorReadingAll) {
      errorMessage = formatMessage(messages.errorReadingAll);
    }
    if (errorDeletingAll) {
      errorMessage = formatMessage(messages.errorDeletingAll);
    }
    if (errorUpdatingGroup) {
      errorMessage = formatMessage(messages.errorUpdatingGroup);
    }
    if (errorDeletingGroup) {
      errorMessage = formatMessage(messages.errorDeletingGroup);
    }
    if (errorMarkingAsImportant) {
      errorMessage = formatMessage(messages.errorMarkingAsImportant);
    }
    if (errorUnmarkingAsImportant) {
      errorMessage = formatMessage(messages.errorUnmarkingAsImportant);
    }
    return (
      <span className="ml-2" style={{ color: 'var(--red)' }}>
        {this.state.visible && errorMessage}
      </span>
    );
  }
}

ChatWindowToolbarErrorMessage.propTypes = {
  conversationId: PropTypes.string.isRequired,
  error: PropTypes.string,
};

ChatWindowToolbarErrorMessage.defaultProps = {
  error: null,
};

function mapStateToProps(state, ownProps) {
  return {
    errorReadingAll: isChatConversationReadAllError(
      state,
      ownProps.conversationId
    ),
    errorDeletingAll: isChatConversationDeleteAllError(
      state,
      ownProps.conversationId
    ),
    errorDeletingMessages: isChatConversationDeleteMessagesError(
      state,
      ownProps.conversationId
    ),
    errorUpdatingGroup: isChatConversationRenameGroupError(
      state,
      ownProps.conversationId
    ),
    errorDeletingGroup: isChatConversationDeleteGroupError(
      state,
      ownProps.conversationId
    ),
    errorMarkingAsImportant: isChatConversationMarkAsImportantError(
      state,
      ownProps.conversationId
    ),
    errorUnmarkingAsImportant: isChatConversationUnmarkAsImportantError(
      state,
      ownProps.conversationId
    ),
  };
}

export default injectIntl(
  connect(mapStateToProps)(ChatWindowToolbarErrorMessage)
);
