import React from 'react';
import Tickets from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';

const TicketEdit = React.lazy(() => import('./EditTicket'));

const TicketEditPage = (props) => (
  <Tickets>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
        </div>
        <TicketEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Tickets>
);

export default TicketEditPage;
