import { createSelector } from 'reselect';

const defaultList = [];
const defaultObject = {};

export const getFetchCalendarEventContactsLoaded = (state) =>
  state.calendar.fetchCalendarEventContactsLoaded;
export const getEventsById = (state) => state.calendar.byId;
export const getEventsIds = (state) => state.calendar.allIds;
export const getEvents = (state) =>
  getEventsIds(state).map((key) => getEventsById(state)[key]);
export const isCalendarEventsLoaded = (state) =>
  state.calendar.fetchCalendarEventsLoaded;
export const getCalendarInitialFilter = (state) => ({
  view: state.calendarSettings && state.calendarSettings.view,
  onlyMine: state.calendarSettings.onlyMine,
});
export const getEvent = (state) => state.calendar.fetchedEventDetails;
export const isCalendarSyncinFetched = (state) =>
  state.calendar.syncinCalendar.fetched;
export const getSyncinCalendars = (state) => state.calendar.syncinCalendars;
export const getFetchSyncinCalendarsLoaded = (state) =>
  state.calendar.fetchSyncinCalendarsLoaded;
export const getFetchSyncinCalendarsError = (state) =>
  state.calendar.fetchSyncinCalendarsError;
export const getCalendarFilter = (state) => state.calendar.filter;
export const getCalendarEventSaveError = (state) =>
  state.calendar.saveEventError;
export const getCalendarEventSaveLoaded = (state) =>
  state.calendar.saveEventLoaded;

export const isVacationEnabled = (state) => state.me.customer.vacationEnabled;
export const isVacationPlanLoaded = (state) =>
  state.calendar.vacationPlan.loaded;
export const getVacationPlanEvents = (state) =>
  state.calendar.vacationPlan.events || defaultList;
export const getVacationPlanParams = (state) =>
  state.calendar.vacationPlan.params || defaultObject;
export const isVacationPlanSuccess = (state) =>
  state.calendar.vacationPlan.success;
export const isVacationPlanError = (state) => state.calendar.vacationPlan.error;
export const isVacationsTaskError = (state) =>
  state.calendar.vacationsTaskError;
export const getFetchCalendarEventLoaded = (state) =>
  state.calendar.fetchCalendarEventLoaded;
export const getFetchCalendarEventError = (state) =>
  state.calendar.fetchCalendarEventError;
export const isCalendarEventsChanged = (state) =>
  state.calendar.calendarEventsChanged;
export const getCalendarSettingsLoaded = (state) =>
  state.calendar.fetchCalendarSettingsLoaded;
export const showSyncinCalendars = (state) => state.calendarSettings.sync;
export const getEmailSuggestions = (state) => state.calendar.emailSuggestions;
export const getFetchCalendarFestivitiesSettingsLoaded = (state) =>
  state.calendar.fetchCalendarFestivitiesSettingsLoaded;
export const getFetchCalendarFestivitiesSettingsError = (state) =>
  state.calendar.fetchCalendarFestivitiesSettingsError;
export const getSaveCalendarFestivitiesSettingsLoaded = (state) =>
  state.calendar.saveCalendarFestivitiesSettingsLoaded;
export const getSaveCalendarFestivitiesSettingsError = (state) =>
  state.calendar.saveCalendarFestivitiesSettingsError;
export const getSaveCalendarFestivitiesSettingsSuccess = (state) =>
  state.calendar.saveCalendarFestivitiesSettingsSuccess;
export const getCalendarFestivitiesSettings = (state) =>
  state.calendar.festivitiesSettings;
export const getFetchVacationResumeLoaded = (state) =>
  state.calendar.fetchVacationResumeLoaded;
export const getFetchVacationResumeError = (state) =>
  state.calendar.fetchVacationResumeError;
export const getVacationResume = (state) => state.calendar.vacationResume;
export const isTaskPlanLoaded = (state) => state.calendar.taskPlan.loaded;
export const getTaskPlanEvents = (state) =>
  state.calendar.taskPlan.events || defaultList;
export const getTaskPlanVacations = (state) =>
  state.calendar.taskPlan.vacations || defaultList;
export const getTaskPlanAppointments = (state) =>
  state.calendar.taskPlan.appointments || defaultList;
export const getTaskPlanParams = (state) =>
  state.calendar.taskPlan.params || defaultObject;
export const isTaskPlanSuccess = (state) => state.calendar.taskPlan.success;
export const isTaskPlanError = (state) => state.calendar.taskPlan.error;
export const getAppointmentResultFromId = (state, id) =>
  state.calendar.appointmentResultsById[id] || defaultObject;
export const getAppointmentResultsById = (state) =>
  state.calendar.appointmentResultsById;
export const getAppointmentResultsIds = (state) =>
  state.calendar.appointmentResultsAllIds;
export const getAppointmentResults = createSelector(
  [getAppointmentResultsById, getAppointmentResultsIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getAppointmentResultsForType = (state, type) => {
  return getAppointmentResults(state).filter(
    (result) => !result.type || result.type === type
  );
};
export const getInsertAppointmentResultLoaded = (state) =>
  state.calendar.insertAppointmentResultLoaded;
export const getInsertAppointmentResultError = (state) =>
  state.calendar.insertAppointmentResultError;
export const getAppointmentResultsLoaded = (state) =>
  state.calendar.fetchAppointmentResultsLoaded;
export const getAppointmentResultsError = (state) =>
  state.calendar.fetchAppointmentResultsError;
export const getLoadedDeleteResult = (state) =>
  state.calendar.loadedDeleteResult;
export const getErrorDeleteResult = (state) => state.calendar.errorDeleteResult;
export const getAppointmentTypeFromId = (state, id) =>
  state.calendar.appointmentTypesById[id] || defaultObject;
export const getAppointmentTypesById = (state) =>
  state.calendar.appointmentTypesById;
export const getAppointmentTypesIds = (state) =>
  state.calendar.appointmentTypesAllIds;
export const getAppointmentTypes = createSelector(
  [getAppointmentTypesById, getAppointmentTypesIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getAppointmentTypesViewOthers = (state) =>
  getAppointmentTypes(state).filter((item) => item.viewOthers);
export const getInsertAppointmentTypeLoaded = (state) =>
  state.calendar.insertAppointmentTypeLoaded;
export const getInsertAppointmentTypeError = (state) =>
  state.calendar.insertAppointmentTypeError;
export const getAppointmentTypesLoaded = (state) =>
  state.calendar.fetchAppointmentTypesLoaded;
export const getAppointmentTypesError = (state) =>
  state.calendar.fetchAppointmentTypesError;
export const getLoadedDeleteType = (state) => state.calendar.loadedDeleteType;
export const getErrorDeleteType = (state) => state.calendar.errorDeleteType;
export const getAppointmentDashboardHeader = (state) =>
  state.calendar.dashboardHeader;
export const getAppointmentDashboardHeaderLoaded = (state) =>
  state.calendar.dashboardHeaderLoaded;
export const getAppointmentDashboardHeaderError = (state) =>
  state.calendar.dashboardHeaderError;
export const getAppointmentDashboardGraphStatistics = (state) =>
  state.calendar.dashboardGraphStatistics;
export const getAppointmentDashboardGraphStatisticsLoaded = (state) =>
  state.calendar.dashboardGraphStatisticsLoaded;
export const getAppointmentDashboardGraphStatisticsError = (state) =>
  state.calendar.dashboardGraphStatisticsError;
export const getAppointmentDashboardUsersProgressBar = (state) =>
  state.calendar.dashboardUsersProgressBar;
export const getAppointmentDashboardUsersStatisticsLoaded = (state) =>
  state.calendar.dashboardUsersStatisticsLoaded;
export const getAppointmentDashboardUsersStatisticsError = (state) =>
  state.calendar.dashboardUsersStatisticsError;
export const getAppointmentsListShortcut = (state) =>
  state.calendar.appointmentsListShortcut;
export const getAppointmentsListShortcutLoaded = (state) =>
  state.calendar.appointmentsListShortcutLoaded;
export const getAppointmentsListShortcutError = (state) =>
  state.calendar.appointmentsListShortcutError;
export const getAppointmentsFilter = (state) =>
  state.calendar.appointmentsFilter;
export const isAppointmentsLoaded = (state) =>
  state.calendar.fetchAppointmentsLoaded;
export const isAppointmentsError = (state) =>
  state.calendar.fetchAppointmentsError;
export const getAppointmentsMapById = (state) => state.calendar.appointmentsMapById;
export const getAppointmentsMapIds = (state) => state.calendar.appointmentsMapIds;
export const getAppointmentsMap = createSelector(
  [getAppointmentsMapById, getAppointmentsMapIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getAppointmentsById = (state) => state.calendar.appointmentsById;
export const getAppointmentsIds = (state) => state.calendar.appointmentsIds;
export const getAppointments = createSelector(
  [getAppointmentsById, getAppointmentsIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getAppointmentsTotal = (state) => state.calendar.totalAppointments;
export const getCalendarAppointmentSaveError = (state) =>
  state.calendar.saveAppointmentError;
export const getCalendarAppointmentSaveLoaded = (state) =>
  state.calendar.saveAppointmeentLoaded;
export const getAppointment = (state) =>
  state.calendar.fetchedAppointmentDetails;
export const getFetchCalendarAppointmentLoaded = (state) =>
  state.calendar.fetchCalendarAppointmentLoaded;
export const getFetchCalendarAppointmentError = (state) =>
  state.calendar.fetchCalendarAppointmentError;
export const getEventsAppointments = (state) => [
  ...getEventsIds(state).map((key) => getEventsById(state)[key]),
  ...getAppointmentsIds(state).map((key) => getAppointmentsById(state)[key]),
];
export const getCalendarappointmentRegistered = (state) =>
  state.calendar.appointmentRegistered;
export const getregisterCalendarAppointmentError = (state) =>
  state.calendar.appointmentRegisterError;
export const getLinkedCalendarAppointments = (state) =>
  state.calendar.linkedCalendarAppointments;
export const getLinkedCalendarEvents = (state) =>
  state.calendar.linkedCalendarEvents;

export const getAppointmentsForExcelIds = (state) =>
  state.calendar.excelAppointmentsIds;
export const getExcelAppointmentsById = (state) =>
  state.calendar.excelAppointmentsById;

export const getAppointmentsForExcel = createSelector(
  [getExcelAppointmentsById, getAppointmentsForExcelIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getAppointmentsForExcelLoaded = (state) =>
  state.calendar.fetchAppointmentsForExcelLoaded;
export const getAppointmentsForExcelError = (state) =>
  state.calendar.fetchAppointmentsForExcelError;
