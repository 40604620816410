import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import Icon from './Icon';
import NotificationBadge from '../NotificationBadge';

class IconDropdown extends Component {
  state = {
    hovered: false,
    dropdownOpen: false,
  };

  mouseEnter = () => {
    this.setState({ hovered: true });
  };

  mouseLeave = () => {
    this.setState({ hovered: false });
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { name, filled, ...props } = this.props;
    const { hovered } = this.state;
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="yn-dropdown"
      >
        <DropdownToggle className="position-relative" nav>
          <Icon
            onMouseEnter={!filled ? this.mouseEnter : null}
            onMouseOut={!filled ? this.mouseLeave : null}
            name={hovered || filled ? `${name}-filled` : name}
            {...props}
          />
        </DropdownToggle>
        <DropdownMenu>
          {this.props.menu.map((item) => (
            <div key={item.path} className="position-relative">
              {item.show && item.notifications && item.notifications > 0 ? (
                <NotificationBadge
                  number={item.notifications}
                  top="0.3rem"
                  right="1rem"
                />
              ) : null}
              {item.show && (
                <DropdownItem
                  tag={Link}
                  to={item.path}
                  key={item.path}
                  onClick={item.onClick}
                >
                  {item.label}
                </DropdownItem>
              )}
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

IconDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  hoverable: PropTypes.bool,
  filled: PropTypes.bool,
};

IconDropdown.defaultProps = {
  hoverable: true,
  filled: false,
};

export default IconDropdown;
