import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ImportCalendarEvents = React.lazy(() => import('./ImportCalendarEvents'));

const CalendarManageImportPage = () => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <ImportCalendarEvents />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarManageImportPage;
