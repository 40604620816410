import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_EXPORT_TEMPLATES_REQUEST,
  FETCH_EXPORT_TEMPLATES_SUCCESS,
  FETCH_EXPORT_TEMPLATES_FAILURE,
  FETCH_EXPORT_TEMPLATE_REQUEST,
  FETCH_EXPORT_TEMPLATE_SUCCESS,
  FETCH_EXPORT_TEMPLATE_FAILURE,
  DELETE_EXPORT_TEMPLATE_REQUEST,
  DELETE_EXPORT_TEMPLATE_SUCCESS,
  DELETE_EXPORT_TEMPLATE_FAILURE,
  SAVE_EXPORT_TEMPLATE_REQUEST,
  SAVE_EXPORT_TEMPLATE_SUCCESS,
  SAVE_EXPORT_TEMPLATE_FAILURE,
} from './types';

const initialstate = {
  fetchTemplatesLoaded: true,
  fetchTemplatesError: null,
  fetchTemplateLoaded: true,
  fetchTemplateError: null,
  saveTemplateLoaded: true,
  saveTemplateError: null,
  deleteTemplateError: null,
  allIds: [],
  byId: {},
};

export default function exportTemplates(state = initialstate, action = {}) {
  switch (action.type) {
    case FETCH_EXPORT_TEMPLATES_REQUEST:
      return {
        ...state,
        fetchTemplatesLoaded: false,
        fetchTemplatesError: null,
      };
    case FETCH_EXPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        fetchTemplatesLoaded: true,
        fetchTemplatesError: null,
        byId: keyBy(action.data, (template) => template.id),
        allIds: action.data.map((template) => template.id),
      };
    case FETCH_EXPORT_TEMPLATES_FAILURE:
      return {
        ...state,
        fetchTemplatesLoaded: true,
        fetchTemplatesError: action.errors,
      };
    case FETCH_EXPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        fetchTemplateLoaded: false,
        fetchTemplateError: null,
      };
    case FETCH_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchTemplateLoaded: true,
        fetchTemplateError: null,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
      };
    case FETCH_EXPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        fetchTemplateLoaded: true,
        fetchTemplateError: action.errors,
      };
    case SAVE_EXPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        saveTemplateLoaded: false,
        fsaveTemplateError: null,
      };
    case SAVE_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        saveTemplateLoaded: true,
        saveTemplateError: null,
      };
    case SAVE_EXPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        saveTemplateLoaded: true,
        saveTemplateError: action.errors,
      };
    case DELETE_EXPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        deleteTemplateError: null,
      };
    case DELETE_EXPORT_TEMPLATE_SUCCESS: {
      const allIds = [...state.allIds];
      const byId = { ...state.byId };
      delete byId[action.templateId];
      pull(allIds, action.templateId);
      return {
        ...state,
        byId,
        allIds,
        deleteTemplateError: null,
      };
    }
    case DELETE_EXPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteTemplateError: action.errors,
      };
    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE:
      return {
        ...state,
        deleteTemplateError: null,
      };
    default:
      return state;
  }
}
