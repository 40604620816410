import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { showConfirmModal } from '../../../js/confirm/actions';
import Spinner from '../../../components/Spinner';
import { getUsersById } from '../../../js/users/selectors';
import { getLoadedDeletePhoneExternalUrl } from '../../../js/externalUrl/selectors';
import { deletePhoneExternalUrlRequest } from '../../../js/externalUrl/actions';

const messages = defineMessages({
  INCOMING: {
    id: 'PhoneExternalUrlRow.INCOMING',
    defaultMessage: 'On incoming call',
  },
  ANSWERED: {
    id: 'PhoneExternalUrlRow.ANSWERED',
    defaultMessage: 'On answered call',
  },
  HANGUP: {
    id: 'PhoneExternalUrlRow.HANGUP',
    defaultMessage: 'On hangup call',
  },
  LINK: {
    id: 'PhoneExternalUrlRow.LINK',
    defaultMessage: 'Open link in new tab',
  },
  ALWAYS: {
    id: 'PhoneExternalUrlRow.ALWAYS',
    defaultMessage: 'Every moment',
  },
  API: {
    id: 'PhoneExternalUrlRow.API',
    defaultMessage: 'Call API in background',
  },
  ICON: {
    id: 'PhoneExternalUrlRow.ICON',
    defaultMessage: 'Show clickable icon',
  },
  EXTERNAL: {
    id: 'PhoneExternalUrlRow.EXTERNAL',
    defaultMessage: 'Only external numbers',
  },
  EXTENSION: {
    id: 'PhoneExternalUrlRow.EXTENSION',
    defaultMessage: 'Only extensions',
  },
  ALL: {
    id: 'PhoneExternalUrlRow.ALL',
    defaultMessage: 'Every number',
  },
  editUrl: {
    id: 'PhoneExternalUrlRow.label.editUrl',
    defaultMessage: 'Edit Url',
  },
  deleteUrl: {
    id: 'PhoneExternalUrlRow.label.deleteUrl',
    defaultMessage: 'Delete Url',
  },
  deleteMessage: {
    id: 'PhoneExternalUrlRow.label.deleteMessage',
    defaultMessage: 'Would you want to continue with deleting?',
  },
});

class PhoneExternalUrlRow extends Component {
  handleDeleteClick = () => {
    this.props.confirm({
      message: this.props.intl.formatMessage(messages.deleteMessage),
      modalAction: this.deleteUrl,
    });
  };

  deleteUrl = () => {
    this.props.delete(this.props.url.id, this.props.user);
  };

  render() {
    const {
      url,
      deletePhoneExternalUrlLoading,
      handleEditClick,
      intl: { formatMessage },
    } = this.props;

    return (
      <tr>
        <td>{formatMessage(messages[url.event])}</td>
        <td>{formatMessage(messages[url.action])}</td>
        <td>{formatMessage(messages[url.constraint])}</td>
        <td>{url.link}</td>
        <td>
          <span onClick={() => handleEditClick(url.id)}>
            <ToolboxIcon name="pencil-write-2">
              {formatMessage(messages.editUrl)}
            </ToolboxIcon>
          </span>
          <span onClick={() => this.handleDeleteClick(url.id)}>
            <ToolboxIcon name="bin-1">
              {formatMessage(messages.deleteUrl)}
            </ToolboxIcon>
          </span>
          {deletePhoneExternalUrlLoading === url.id && <Spinner />}
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deletePhoneExternalUrlLoading: getLoadedDeletePhoneExternalUrl(state),
    users: getUsersById(state),
  };
};

PhoneExternalUrlRow.propTypes = {
  url: PropTypes.object.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  user: PropTypes.number,
};

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    delete: deletePhoneExternalUrlRequest,
  })(PhoneExternalUrlRow)
);
