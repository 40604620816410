import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ExportCalendarEvents = React.lazy(() => import('./ExportCalendarEvents'));

const CalendarManageExportPage = () => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <ExportCalendarEvents />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarManageExportPage;
