import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  fetchMediadevicesFailure,
  fetchMediadevicesSuccess,
  getUsermediaFailure,
  getUsermediaSuccess,
} from './actions';
import { FETCH_MEDIADEVICES_REQUEST } from './types';
import { getUsermedia } from '../videocalls/VideocallsUtils';
import { getMediastream } from './selectors';

function* fetchMediadevices(action) {
  try {
    if (
      !navigator.mediaDevices &&
      window.MediaStreamTrack &&
      window.MediaStreamTrack.getSources
    ) {
      navigator.mediaDevices = window.MediaStreamTrack.getSources.bind(
        window.MediaStreamTrack
      );
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      yield put(
        fetchMediadevicesSuccess({ ...action.payload, available: false })
      );
      return;
    }

    const result = yield call(() =>
      navigator.mediaDevices
        .enumerateDevices()
        .then((res) => res)
        .catch((err) => ({ error: err }))
    );
    if (result.error) {
      yield put(
        fetchMediadevicesFailure({ ...action.payload, error: result.error.err })
      );
    } else {
      const localStream = yield select(getMediastream);
      if (!localStream) {
        const audioIn = result.filter((d) => d.kind === 'audioinput');
        const videoIn = result.filter((d) => d.kind === 'videoinput');
        if (audioIn.length > 0 || videoIn.length > 0) {
          const devices = yield call(
            getUsermedia,
            audioIn.length > 0 && audioIn[0],
            action.payload.video && videoIn.length > 0 && videoIn[0]
          );
          if (devices && devices.stream && action.payload.reset) {
            devices.stream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          if (devices.error && devices.error.audio) {
            yield put(getUsermediaFailure(devices.error));
          } else {
            yield put(
              getUsermediaSuccess(
                action.payload.reset ? { ...devices, stream: null } : devices
              )
            );
          }
        }
      }
      const mediaDevices = result.map((d) => ({
        id: `${d.deviceId}_${d.kind}`,
        deviceId: d.deviceId,
        kind: d.kind,
        label: d.label,
        groupId: d.groupId,
      }));

      yield put(
        fetchMediadevicesSuccess({
          ...action.payload,
          available: true,
          devices: mediaDevices,
        })
      );
    }
  } catch (err) {
    yield put(fetchMediadevicesFailure({ ...action.payload, error: err }));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_MEDIADEVICES_REQUEST, fetchMediadevices);
}
