import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select2 from '../../../components/formControls/Select2';
import { getPbxRoutes } from '../../../js/phone/selectors';

const PbxRoutesSelect = ({
  routes,
  selected,
  onSelect,
  error,
  placeholder,
  isClearable,
  isMulti,
}) => {

  const routesOptions =
    routes &&
    routes.map((r) => ({
      value: r.route,
      label: `${r.route} ${r.name ? ` - ${r.name}` : ''}`,
    }));

  const selectedRoutes = 
    (!selected || !routesOptions)
      ? isMulti ?[]:null 
      : isMulti 
        ? routesOptions.filter((r) => selected.indexOf(r.value)>-1) 
        :routesOptions.filter((r) => r.value === selected); 

  return (
    <Select2
      id="pbxRoutes"
      name="pbxRoutes"
      value={selectedRoutes}
      onChange={onSelect}
      options={routesOptions}
      error={error}
      isClearable={isClearable}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
};

PbxRoutesSelect.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.string,
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

PbxRoutesSelect.defaultProps = {
  selected:[], 
  error: null,
  isClearable: false,
  placeholder: null,
  isMulti:false,
};

function mapStateToProps(state) {
  return {
    routes: getPbxRoutes(state) && getPbxRoutes(state).filter((r) => !r.hidden),
  };
}

export default connect(mapStateToProps)(PbxRoutesSelect);
