import React from 'react';
import Icon from './Icon';

const ToolboxIcon = ({ children, ...props }) => (
  <span
    style={{
      marginLeft: '0.5em',
      cursor: 'pointer',
      display: 'inline-block',
    }}
  >
    <Icon color="var(--yn-blue-dark)" width={20} height={20} {...props}>
      {children}
    </Icon>
  </span>
);

export default ToolboxIcon;
