import { LOCATION_CHANGE } from 'connected-react-router';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_REQUEST,
  AUTH_SUCCESS,
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_FAILURE,
  GUEST_LOGIN_SUCCESS,
  TECH_LOGIN_SUCCESS,
} from './types';
import {
  WEBSOCKET_CONNECTION,
  WEBSOCKET_DISCONNECTION,
} from '../websocket/types';

const initialErrorState = {
  login: null,
  guestLogin: null,
  techLogin: null,
  logout: null,
  reset: null,
};

const initialState = {
  logged: false,
  guest: false,
  tech: false,
  loaded: true,
  wsConnected: false,
  error: initialErrorState,
};

export default function auth(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        logged: false,
        loaded: false,
        error: initialErrorState,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        logged: false,
        loaded: true,
        error: {
          ...initialErrorState,
          login: action.errors,
        },
      };
    case GUEST_LOGIN_REQUEST:
      return {
        ...state,
        guest: false,
        loaded: false,
        error: initialErrorState,
      };
    case GUEST_LOGIN_FAILURE:
      return {
        ...state,
        guest: false,
        loaded: true,
        error: {
          ...initialErrorState,
          guestLogin: action.errors,
        },
      };
    case AUTH_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        logged: true,
        loaded: true,
        error: initialErrorState,
      };
    case GUEST_LOGIN_SUCCESS:
      return {
        ...state,
        guest: true,
        loaded: true,
        error: initialErrorState,
      };
    case LOGOUT_REQUEST:
    case LOCATION_CHANGE:
      return {
        ...state,
        error: initialErrorState,
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case LOGOUT_FAILURE:
      return {
        ...state,
        logged: true,
        loaded: true,
        error: {
          login: null,
          logout: action.errors,
          reset: null,
        },
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loaded: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loaded: true,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loaded: true,
        error: {
          login: null,
          logout: null,
          reset: true,
        },
      };
    case WEBSOCKET_CONNECTION:
      return {
        ...state,
        wsConnected: true,
      };
    case WEBSOCKET_DISCONNECTION:
      return {
        ...state,
        wsConnected: false,
      };
    case TECH_LOGIN_SUCCESS:
      return {
        ...state,
        tech: true,
      };
    default:
      return state;
  }
}
