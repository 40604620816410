import React from 'react';
import classNames from 'classnames';
import { Progress } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PrinciplesList from './PrinciplesList';

const SPECIAL_CHARS_REGEX = /\W|_/;
const DIGIT_REGEX = /[0-9]/;
const LOWERCASE_CHARS_REGEX = /[a-z]/;
const UPPERCASE_CHARS_REGEX = /[A-Z]/;

const messages = defineMessages({
  checkLength: {
    id: 'StrengthMeter.label.checkLength',
    defaultMessage: 'Enter at least 8 characters, ',
  },
  checkNumber: {
    id: 'StrengthMeter.label.checkNumber',
    defaultMessage: 'of which 1 number, ',
  },
  checkLowercase: {
    id: 'StrengthMeter.label.checkLowercase',
    defaultMessage: '1 lowercase letter, ',
  },
  checkUppercase: {
    id: 'StrengthMeter.label.checkUppercase',
    defaultMessage: '1 uppercase letter ',
  },
  checkSpecial: {
    id: 'StrengthMeter.label.checkSpecial',
    defaultMessage: 'and 1 special character',
  },
});

class StrengthMeter extends React.Component {
  goodPasswordPrinciples = [
    {
      label: this.props.intl.formatMessage(messages.checkLength),
      predicate: (password) => password.length >= 8,
    },
    {
      label: this.props.intl.formatMessage(messages.checkNumber),
      predicate: (password) => password.match(DIGIT_REGEX) !== null,
    },
    {
      label: this.props.intl.formatMessage(messages.checkLowercase),
      predicate: (password) => password.match(LOWERCASE_CHARS_REGEX) !== null,
    },
    {
      label: this.props.intl.formatMessage(messages.checkUppercase),
      predicate: (password) => password.match(UPPERCASE_CHARS_REGEX) !== null,
    },
    {
      label: this.props.intl.formatMessage(messages.checkSpecial),
      predicate: (password) => password.match(SPECIAL_CHARS_REGEX) !== null,
    },
  ];

  satisfiedPercent() {
    const { password } = this.props;

    const satisfiedCount = this.goodPasswordPrinciples
      .map((p) => p.predicate(password))
      .reduce((count, satisfied) => count + (satisfied ? 1 : 0), 0);

    const principlesCount = this.goodPasswordPrinciples.length;

    return (satisfiedCount / principlesCount) * 100.0;
  }

  progressColor() {
    const percentage = this.satisfiedPercent();

    return classNames({
      danger: percentage < 90,
      success: percentage >= 90,
      /* warning: (percentage >= 33.4 && percentage < 80) */
    });
  }

  render() {
    const percentage = this.satisfiedPercent();
    return (
      <div className="mb-3 mt-1">
        <Progress
          striped
          now={this.satisfiedPercent()}
          color={this.progressColor()}
          value={percentage}
          style={{ height: '25px' }}
        />
        <PrinciplesList
          password={this.props.password}
          principles={this.goodPasswordPrinciples}
          forLabel="progress"
        />
      </div>
    );
  }
}

export default injectIntl(StrengthMeter);
