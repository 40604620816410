import React from 'react';
import Wakeup from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const WakeupEdit = React.lazy(() => import('./WakeupEdit'));
const WakeupEditPage = (props) => (
  <Wakeup>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="wakeup-edit" />
          </div>
        </div>
        <WakeupEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wakeup>
);

export default WakeupEditPage;
