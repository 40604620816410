import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../js/lib/utils';
import ParsedText from '../../../components/ParsedText';

const MIN_FONT_SIZE = 8;

class StickyText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textFocus: false,
      text: null,
      fontSize: MIN_FONT_SIZE,
    };
    this.divTextCopyRef = React.createRef();
    this.divTextRef = React.createRef();
    this.textRef = React.createRef();
  }

  componentDidMount() {
    this.adjustFontSize();
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.stickyNote.detail.font !== nextProps.stickyNote.detail.font
    ) {
      this.divTextCopyRef.current.style.fontFamily =
        nextProps.stickyNote.detail.font;
      this.adjustFontSize();
    }
    if (this.props.rendering && !nextProps.rendering) {
      this.adjustFontSize();
    }
    if (this.props.stickyNote.text !== nextProps.stickyNote.text) {
      setImmediate(() => this.adjustFontSize());
    }
    if (
      this.props.stickyNote.detail.shape !== nextProps.stickyNote.detail.shape
    ) {
      setTimeout(() => this.adjustFontSize(), 1000);
    }
  };

  adjustFontSize = () => {
    let fontSize = this.props.maxFontSize;
    this.divTextCopyRef.current.style.fontSize = `${fontSize}px`;
    while (
      this.divTextCopyRef.current.offsetHeight >
        this.divTextRef.current.offsetHeight ||
      fontSize === MIN_FONT_SIZE
    ) {
      fontSize -= 1;
      this.divTextCopyRef.current.style.fontSize = `${fontSize}px`;
    }
    this.setState({
      ...this.state,
      fontSize,
    });
  };

  handleTextChange = (e) => {
    this.setState(
      {
        ...this.state,
        text: e.target.value,
      },
      () => {
        this.adjustFontSize();
      }
    );
  };

  handleTextBlur = () => {
    const updatedText = this.state.text;
    this.setState(
      {
        ...this.state,
        text: null,
        textFocus: false,
      },
      () => {
        this.props.updateSticky({
          ...this.props.stickyNote,
          text: Utils.getValorizedVariable(
            updatedText,
            this.props.stickyNote.text,
            ''
          ),
        });
        this.props.handleFocus(false);
      }
    );
  };

  handleTextClick = () => {
    this.setState(
      {
        ...this.state,
        textFocus: true,
      },
      () => {
        this.props.handleFocus(true);
        this.textRef.current.focus();
      }
    );
  };

  render() {
    const { textDivStyle, stickyNote } = this.props;
    const style = {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      lineHeight: '1',
      padding: '5px',
      textAlign: 'center',
      border: 'none',
      outline: '0',
      fontSize: `${this.state.fontSize}px`,
      resize: 'none',
      fontFamily: stickyNote.detail.font,
      backgroundColor: stickyNote.detail.color,
    };
    const text = Utils.getValorizedVariable(
      this.state.text,
      stickyNote.text,
      ''
    );
    return (
      <React.Fragment>
        <div
          ref={this.divTextCopyRef}
          style={{
            ...textDivStyle,
            textAlign: 'center',
            fontFamily: stickyNote.detail.font,
            minHeight: textDivStyle.height,
            opacity: this.state.textFocus ? '0' : '1',
            zIndex: this.state.textFocus ? '1000' : '1001',
            fontSize: `${this.state.fontSize}px`,
            wordBreak: 'break-word',
            height: 'auto',
            whiteSpace: 'pre-line',
          }}
          onClick={() => this.handleTextClick()}
        >
          <ParsedText text={text} />
        </div>
        <div
          style={{
            ...textDivStyle,
            zIndex: this.state.textFocus ? '1001' : '1000',
          }}
          ref={this.divTextRef}
        >
          <textarea
            id={`${stickyNote.id}sticky`}
            ref={this.textRef}
            style={{
              ...style,
              opacity: this.state.textFocus ? '1' : '0',
              lineHeight: 1.5,
              padding: 0,
            }}
            value={text}
            name="stickyText"
            onChange={this.handleTextChange}
            onBlur={this.handleTextBlur}
            maxLength="100"
          />
        </div>
      </React.Fragment>
    );
  }
}

StickyText.propTypes = {
  stickyNote: PropTypes.object.isRequired,
  handleFocus: PropTypes.func.isRequired,
  updateSticky: PropTypes.func.isRequired,
  textDivStyle: PropTypes.object.isRequired,
  maxFontSize: PropTypes.number.isRequired,
  rendering: PropTypes.bool.isRequired,
};

export default StickyText;
