import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import Button from './Button';

const messages = defineMessages({
  yes: {
    id: 'ReloadModal.button.confirm',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'ReloadModal.button.cancel',
    defaultMessage: 'No',
  },
});

class ReloadModal extends React.Component {
  render() {
    const {
      open,
      toggle,
      confirm,
      cancel,
      message,
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal isOpen={open}>
        <ModalBody dangerouslySetInnerHTML={{ __html: message }} />
        <ModalFooter>
          <Button
            className="btn btn-primary"
            text={formatMessage(messages.yes)}
            onClick={() => {toggle(!open);confirm && confirm();}}
          />
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.no)}
            onClick={() => {toggle(!open);cancel && cancel();}}
          />
        </ModalFooter>
      </Modal>
    );
  }
}
export default injectIntl(ReloadModal);
