export const PUT_AVATAR_REQUEST = 'PUT_AVATAR_REQUEST';
export const PUT_AVATAR_FAILURE = 'PUT_AVATAR_FAILURE';
export const PUT_AVATAR_SUCCESS = 'PUT_AVATAR_SUCCESS';
export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_PROGRESS_FILES_REQUEST = 'UPLOAD_PROGRESS_FILES_REQUEST';
export const UPLOAD_FILES_PROGRESS = 'UPLOAD_FILES_PROGRESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_CANCEL = 'UPLOAD_FILES_CANCEL';
export const REMOVE_FILES_REQUEST = 'REMOVE_FILES_REQUEST';
export const REMOVE_FILES_FAILURE = 'REMOVE_FILES_FAILURE';
export const REMOVE_FILES_SUCCESS = 'REMOVE_FILES_SUCCESS';
export const COPY_EXISTING_FILES = 'COPY_EXISTING_FILES';
export const RESET_AVATAR_STATUS = 'RESET_AVATAR_STATUS';
export const STOP_UPLOAD_FILES = 'STOP_UPLOAD_FILES';
export const RESET_TICKETS_FILES = 'RESET_TICKETS_FILES';
export const IMPORT_FILE_TO_FILEBOX_REQUEST = 'IMPORT_FILE_TO_FILEBOX_REQUEST';
export const IMPORT_FILE_TO_FILEBOX_SUCCESS = 'IMPORT_FILE_TO_FILEBOX_SUCCESS';
export const IMPORT_FILE_TO_FILEBOX_FAILURE = 'IMPORT_FILE_TO_FILEBOX_FAILURE';
export const CLEAR_FILEBOX_ERRORS = 'CLEAR_FILEBOX_ERRORS';
export const RESET_FILEBOX_UPLOADED_FILES = 'RESET_FILEBOX_UPLOADED_FILES';
export const RESET_STATIC_NOTES_UPLOADED_FILES =
  'RESET_STATIC_NOTES_UPLOADED_FILES';
