import { call } from 'redux-saga/effects';
import ynConf from '../../conf';

export class VideocallsEnums {
  static VideocallsTopics = {
    CALLS: 'CALLS',
    STATUS: 'STATUS',
  };

  static VideocallsActions = {
    CALL_START: 'CALL_START',
    CALL_START_ACK: 'CALL_START_ACK',
    CALL_INCOMING: 'CALL_INCOMING',
    CALL_ANSWER: 'CALL_ANSWER',
    CALL_ANSWER_ACK: 'CALL_ANSWER_ACK',
    CALL_ANSWER_CC: 'CALL_ANSWER_CC',
    HANGUP: 'HANGUP',
    HANGUP_CC: 'HANGUP_CC',
    HANGUP_ACK: 'HANGUP_ACK',
    ENABLE_DND: 'ENABLE_DND',
    ENABLE_DND_ACK: 'ENABLE_DND_ACK',
    ENABLE_DND_CC: 'ENABLE_DND_CC',
    DISABLE_DND: 'DISABLE_DND',
    DISABLE_DND_ACK: 'DISABLE_DND_ACK',
    DISABLE_DND_CC: 'DISABLE_DND_CC',
  };

  static VideocallsButtons = {
    CAMERA: 'camera',
    CHAT: 'chat',
    CLOSED_CAPTIONS: 'closedcaptions',
    DESKTOP: 'desktop',
    DOWNLOAD: 'download',
    EMBED_MEETING: 'embedmeeting',
    ETHERPAD: 'etherpad',
    FEEDBACK: 'feedback',
    FILMSTRIP: 'filmstrip',
    FULLSCREEN: 'fullscreen',
    HANGUP: 'hangup',
    HELP: 'help',
    HIGHLIGHT: 'highlight',
    INVITE: 'invite',
    SALESFORCE: 'linktosalesforce',
    LIVESTREAMING: 'livestreaming',
    MICROPHONE: 'microphone',
    MUTE: 'mute-everyone',
    MUTE_VIDEO: 'mute-video-everyone',
    PARTICIPANTS_PANE: 'participants-pane',
    PROFILE: 'profile',
    RAISE_HAND: 'raisehand',
    RECORDING: 'recording',
    SECURITY: 'security',
    BACKGROUND: 'select-background',
    SETTINGS: 'settings',
    SHARE_AUDIO: 'shareaudio',
    SHARE_VIDEO: 'sharedvideo',
    SHORTCUTS: 'shortcuts',
    STATS: 'stats',
    TILE_VIEW: 'tileview',
    TOGGLE_CAMERA: 'toggle-camera',
    VIDEO_QUALITY: 'videoquality',
  };
}

export function getCommonVideocallButton() {
  return [
    VideocallsEnums.VideocallsButtons.CAMERA,
    VideocallsEnums.VideocallsButtons.CHAT,
    VideocallsEnums.VideocallsButtons.DOWNLOAD,
    VideocallsEnums.VideocallsButtons.FULLSCREEN,
    VideocallsEnums.VideocallsButtons.HANGUP,
    VideocallsEnums.VideocallsButtons.HELP,
    VideocallsEnums.VideocallsButtons.MICROPHONE,
    VideocallsEnums.VideocallsButtons.MUTE,
    VideocallsEnums.VideocallsButtons.MUTE_VIDEO,
    VideocallsEnums.VideocallsButtons.PROFILE,
    VideocallsEnums.VideocallsButtons.RAISE_HAND,
    VideocallsEnums.VideocallsButtons.BACKGROUND,
    VideocallsEnums.VideocallsButtons.SETTINGS,
    VideocallsEnums.VideocallsButtons.SHORTCUTS,
    VideocallsEnums.VideocallsButtons.STATS,
    VideocallsEnums.VideocallsButtons.TILE_VIEW,
    VideocallsEnums.VideocallsButtons.TOGGLE_CAMERA,
    VideocallsEnums.VideocallsButtons.VIDEO_QUALITY,
  ];
}

export function* retrieveLocalStream(data) {
  const deviceRequest = {
    audio: true,
  };
  if (data.audio) {
    deviceRequest.audio = data.audio;
  }
  if (data.video) {
    deviceRequest.video = data.video;
  }

  try {
    const stream = yield call(() =>
      navigator.mediaDevices.getUserMedia(deviceRequest)
    );
    return {
      stream,
      videoEnabled: !!deviceRequest.video,
      audioEnabled: !!deviceRequest.audio,
    };
  } catch (error1) {
    try {
      const stream = yield call(() =>
        navigator.mediaDevices.getUserMedia({ audio: deviceRequest.audio })
      );
      return {
        error: { video: error1.name },
        stream,
        videoEnabled: false,
        audioEnabled: !!deviceRequest.audio,
      };
    } catch (error2) {
      // there was at least an audio error
      // try video
      try {
        const stream = yield call(() =>
          navigator.mediaDevices.getUserMedia({ video: data.video })
        );
        return {
          error: { audio: error1.name },
          stream,
          videoEnabled: !!data.video,
          audioEnabled: false,
        };
      } catch (error3) {
        return { error: { audio: error2.name, video: error3.name } };
      }
    }
  }
}

export function* getUsermedia(audio, video) {
  if (!audio && !video) {
    return {
      error: VideocallsEnums.VideocallsError.NO_DEVICES,
    };
  }
  const result = yield call(retrieveLocalStream, {
    audio,
    video,
  });
  return {
    error: result.error,
    audio: !!audio,
    video: result.videoEnabled,
    stream: result.stream,
  };
}

export const VIDEOCALL_NO_ANSWER_HANGUP_TIMEOUT = 60 * 1000;

export const RING_SOUND = `${ynConf.publicFolderPath}/notifications/ring.mp3`;

export const ALERTING_SOUND = `${ynConf.publicFolderPath}/notifications/outgoingVideocall.mp3`;

export const BUSY_SOUND = `${ynConf.publicFolderPath}/notifications/busyVideocall.mp3`;
