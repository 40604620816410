import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import queryString from 'query-string';
import history from '../../../history';
import {
  getMePhoneStatus,
  isWebrtcAvailable,
  getSearchedNumber,
  isWebrtcActive,
  getSupplier,
  getMePhoneDnd,
  getMePhoneActiveRule,
  isPhoneOffline,
  isPhonePlayingBusy,
  isWebrtcInUse,
  sentStopUseWebphoneLoaded,
  isOutgoingWebrtcCall,
  isActiveWebrtcCall,
  isWebphoneDetached,
  isWebphoneReactivating,
  isWebrtcSdpCall,
  isWebPhoneRegistered,
  receivedDefaultDevices,
} from '../../../js/phone/selectors';
import {
  execCallRequest,
  setSearchContactsFilter,
  sendDtmfRequest,
  sendStopUseWebphoneRequest,
  reactivateWebrtcPhone,
  traceWebrtcTrouble,
} from '../../../js/phone/actions';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { statusColor, PbxSettings } from '../../../js/phone/PbxSettingsUtils';
import PhoneModeSwitch from './PhoneModeSwitch';
import Button from '../../../components/formControls/Button';
import KeyPad from './KeyPad';
import PhoneNumberSearchInput from './PhoneNumberSearchInput';
import { PhoneRulesEnums } from '../../../js/phoneRules/PhoneRulesUtils';
import Utils from '../../../js/lib/utils';
import Icon from '../../../components/icons/Icon';
import {
  isMeInConference,
  isMeParked,
  otherSessions,
} from '../../../js/me/selectors';
import PhoneNumberSearchList from '../../../components/PhoneNumberSearchList';
import HelpButton from '../../../components/HelpButton';
import { NotificationSettings } from '../../../js/notifications/NotificationUtils';
import {
  isAudioInAvailable,
  getAudioMediadevicesError,
} from '../../../js/media/selectors';
import OtherSessionsModal from './OtherSessionsModal';

const messages = defineMessages({
  logging: {
    id: 'PhonePanel.title.logging',
    defaultMessage: 'Connecting',
  },
  unavailable: {
    id: 'PhonePanel.title.unavailable',
    defaultMessage: 'Unavailable',
  },
  online: {
    id: 'PhonePanel.title.online',
    defaultMessage: 'Available',
  },
  dnd: {
    id: 'PhonePanel.title.dnd',
    defaultMessage: "Don't disturb",
  },
  relogin: {
    id: 'PhonePanel.title.relogin',
    defaultMessage: 'Trying to reconnect',
  },
  ringing: {
    id: 'PhonePanel.title.ringing',
    defaultMessage: 'Ringing',
  },
  composing: {
    id: 'PhonePanel.title.composing',
    defaultMessage: 'Composing',
  },
  busy: {
    id: 'PhonePanel.title.busy',
    defaultMessage: 'Busy',
  },
  vbox: {
    id: 'PhonePanel.title.vbox',
    defaultMessage: 'Voicebox on',
  },
  forward: {
    id: 'PhonePanel.title.forward',
    defaultMessage: 'Forward on',
  },
  settingsLink: {
    id: 'PhonePanel.tooltip.settingsLink',
    defaultMessage: 'Go to phone settings',
  },
  call: {
    id: 'PhonePanel.button.call',
    defaultMessage: 'Call',
  },
  noMicrophone: {
    id: 'PhonePanel.noMicrophone',
    defaultMessage:
      'To use web phone you need to plug in a microphone and reaload page',
  },
  noMicrophoneAuth: {
    id: 'PhonePanel.noMicrophoneAuth',
    defaultMessage:
      'To use web phone you need to enable your microphone in the browser',
  },
  otherWebrtcSessions: {
    id: 'PhonePanel.otherWebrtcSessions',
    defaultMessage:
      'The web phone is used in other sessions. Click the button to use it here',
  },
  activateWebPhone: {
    id: 'PhonePanel.activateWebPhone',
    defaultMessage: 'Activate',
  },
  webphoneProblems: {
    id: 'PhonePanel.webphoneProblems',
    defaultMessage: 'Oh oh! There are problems with webphone',
  },
  tryReactivate: {
    id: 'PhonePanel.tryReactivate',
    defaultMessage: 'Restart webphone',
  },
  cancelButton: {
    id: 'PhonePanel.cancelButton',
    defaultMessage: 'Cancel',
  },
});

class PhonePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      audioBusy: null,
      audioOut: null,
      webphoneDetachedModalOpen: false,
      numberFromExtension: null,
    };
  }

  componentDidMount() {
    const { ipcRenderer } = window;
    if (ipcRenderer) {
      ipcRenderer.send('app-mounted');
    }
    this.execCall();
    window.addEventListener(
      'message',
      (event) => {
        // We only accept messages from ourselves
        if (event.source !== window) return;

        if (event.data.sender === 'YN_PHONE') {
          this.setState({ numberFromExtension: event.data.number });
        }
      },
      false
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.number !== this.props.number && this.state.error) {
      this.setState({ error: false });
    }
    /* NOT USED FOR NOW, COULD SOLVE WEBRTC OFFLINE TROUBLE?
    if (
      prevProps.phoneStatus !== this.props.phoneStatus &&
      this.props.phoneStatus === PhoneEnums.UserStatuses.unavailable
      && this.props.webrtcActive === 'ON'
    ) {
      this.props.activateWebrtc();
    } */

    if (this.props.playBusy && !prevProps.playBusy) {
      this.playAudioBusy();
    }
    if (!this.props.playBusy && prevProps.playBusy) {
      this.pauseAudioBusy();
    }
    if (this.props.webphoneDetached && !prevProps.webphoneDetached) {
      this.setState({ webphoneDetachedModalOpen: true });
    }
    if (this.props.outgoingWebrtcCall && !prevProps.outgoingWebrtcCall) {
      this.playAudioOut();
    }
    if (!this.props.outgoingWebrtcCall && prevProps.outgoingWebrtcCall) {
      this.pauseAudioOut();
    }
    if (this.props.sdpWebrtcCall && !prevProps.sdpWebrtcCall) {
      this.pauseAudioOut();
    }
    this.execCall();
  }

  execCall = () => {
    const { setFilter, location } = this.props;
    const { numberFromExtension } = this.state;

    const sessionNumber = sessionStorage.getItem('numberToCall');
    const execCallUrl = location.pathname === '/calls/exec';
    const urlNumber = queryString.parse(location.search ? location.search.replace('+', '00') : location.search).number;
    let numberToCall = null;

    if (numberFromExtension) {
      // console.log('sessionNumber', numberFromExtension);
      numberToCall = Utils.adjustPhoneNumber(numberFromExtension);
    } else if (execCallUrl && urlNumber) {
      // console.log('execCallUrl', execCallUrl);
      // console.log('urlNumber', urlNumber);
      numberToCall = Utils.adjustPhoneNumber(urlNumber);
    } else if (sessionNumber) {
      // console.log('sessionNumber', sessionNumber);
      numberToCall = Utils.adjustPhoneNumber(sessionNumber);
    } else {
      return;
    }
    setFilter({
      target: 'PhonePanel',
      number: numberToCall,
      selected: false,
      listOpen: false,
    });
    // console.log('there is a number to call!', numberToCall);
    if (this.canExecCall()) {
      // console.log('I can exec a call!', numberToCall);
      if (history.location.pathname === '/calls/exec') {
        // console.log('history push /calls');
        history.push('/calls');
      }
      this.startCall(numberToCall);
    }
  };

  startCall = (numberToCall) => {
    const { call } = this.props;
    // console.log('---------------> so i call!', numberToCall);
    setTimeout(() => {
      call({
        number: numberToCall,
        addExitCode: false,
        outOfPanel: true,
      });
      this.setState({ numberFromExtension: null });
    }, 1000);
  };

  canExecCall = () => {
    const {
      isMeOffline,
      phoneStatus,
      webrtcActive,
      audioAvailable,
      audioError,
      webrtcUsing,
      webPhoneRegistered,
      sendStopUseWebrtcPhone,
      otherSessionsNumber,
      defaultDevicesReceived,
    } = this.props;

    const webrtcWithNoMicrophone =
      webrtcActive && (!audioAvailable || audioError === 'NotAllowedError');

    /* console.log('is webrtc without microphone?', webrtcWithNoMicrophone);
    console.log('is webrtcActive?', webrtcActive);
    console.log('there are othe sessions?', otherSessionsNumber > 0);
    console.log('am i using webrtc?', webrtcUsing);
    console.log('is webphone registered?', webPhoneRegistered);
    console.log('is me offline?', isMeOffline);
    console.log(
      'is my phone available?',
      phoneStatus === PhoneEnums.UserStatuses.available
    );*/

    if (webrtcActive && otherSessionsNumber > 0 && !webrtcUsing) {
      // console.log('Try to activate web phone on this session');
      sendStopUseWebrtcPhone();
    } else {
      sessionStorage.removeItem('numberToCall');
    }

    return (
      defaultDevicesReceived &&
      !isMeOffline &&
      phoneStatus === PhoneEnums.UserStatuses.available &&
      (!webrtcActive ||
        (webrtcActive &&
          webrtcUsing &&
          webPhoneRegistered &&
          !webrtcWithNoMicrophone))
    );
  };

  playAudioBusy = () => {
    if (!this.state.audioBusy) {
      const audioBusy = new Audio(PbxSettings.BUSY_SOUND);
      audioBusy.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audioBusy.loop = true;
      audioBusy.play();
      this.setState({ audioBusy });
    }
  };

  pauseAudioBusy = () => {
    if (this.state.audioBusy) {
      this.state.audioBusy.pause();
      this.setState({ audioBusy: null });
    }
  };

  playAudioOut = () => {
    if (!this.state.audioOut) {
      const audioOut = new Audio(PbxSettings.AVAILABLE_SOUND);
      audioOut.volume =
        (this.props.platformVolume === null ||
        this.props.platformVolume === undefined
          ? NotificationSettings.DEFAULT_VOLUME
          : this.props.platformVolume) / 100;
      audioOut.loop = true;
      audioOut.play();
      this.setState({ audioOut });
    }
  };

  pauseAudioOut = () => {
    if (this.state.audioOut) {
      this.state.audioOut.pause();
      this.setState({ audioOut: null });
    }
  };

  handleReActivateWebphone = () => {
    this.props.reactivateWebphone();
    this.props.traceWebrtc({ context: 'Detached webphone' });
    this.toggleWebphoneDetachedModal();
  };

  toggleWebphoneDetachedModal = () => {
    this.setState({
      webphoneDetachedModalOpen: !this.state.webphoneDetachedModalOpen,
    });
  };

  handleCall = (number, addExitCode) => {
    const numberToCall = Utils.adjustPhoneNumber(number);
    if (Utils.validatePhoneNumber(numberToCall)) {
      this.props.call({ number: numberToCall, addExitCode });
      this.props.setFilter({
        target: 'PhonePanel',
        number: numberToCall,
        selected: false,
        listOpen: false,
      });
    } else {
      this.setState({ error: true });
    }
  };

  handleKey = (key) => {
    const number = this.props.number ? this.props.number + key : key;
    this.props.setFilter({
      target: 'PhonePanel',
      number,
      selected: false,
    });
    if (
      this.props.webrtcActive &&
      (this.props.phoneStatus === PhoneEnums.UserStatuses.calling ||
        // some green numbers calls always stays in alerting mode,
        // never pass to active, so we need to handle keys also in that cases
        this.props.phoneStatus === PhoneEnums.UserStatuses.alerting ||
        //
        this.props.phoneStatus === PhoneEnums.CallsStatuses.active)
    ) {
      this.props.sendDtmf(key);
    }
  };

  retrievePhoneTitleData = () => {
    const {
      phoneStatus: status,
      phoneDnd: dnd,
      phoneActiveRule: activeRule,
      isMeOffline,
      intl: { formatMessage },
      parked,
      supplier,
      inConference,
      webrtcActive,
      audioAvailable,
      audioError,
      webrtcUsing,
      outgoingWebrtcCall,
      activeWebrtcCall,
      webPhoneReactivating,
    } = this.props;

    // phone unavailable
    if (
      isMeOffline ||
      !status ||
      status === PhoneEnums.UserStatuses.unavailable ||
      (webrtcActive && webrtcUsing && (!audioAvailable || audioError)) ||
      (webrtcActive && !webrtcUsing)
    ) {
      return {
        title: formatMessage(messages.unavailable),
      };
    }

    // phone in error
    if (status === PhoneEnums.PhoneStatuses.error) {
      return {
        title: formatMessage(messages.relogin),
      };
    }

    // phone logging
    if (webPhoneReactivating || status === PhoneEnums.PhoneStatuses.logging) {
      return {
        title: formatMessage(messages.logging),
      };
    }

    // webrtc phone started outgoing call
    if (outgoingWebrtcCall) {
      return {
        title: formatMessage(messages.composing),
      };
    }

    // phone ringing
    if (
      !parked &&
      (status === PhoneEnums.UserStatuses.ringing ||
        status === PhoneEnums.UserStatuses.alerting)
    ) {
      return {
        title: formatMessage(messages.ringing),
      };
    }

    // phone busy
    if (
      status === PhoneEnums.UserStatuses.calling ||
      status === PhoneEnums.CallsStatuses.active ||
      status === PhoneEnums.CallsStatuses.calling ||
      inConference ||
      parked ||
      activeWebrtcCall ||
      (supplier === PhoneEnums.PbxType.NETHESIS &&
        status === PhoneEnums.CallsStatuses.hold)
    ) {
      return {
        title: formatMessage(messages.busy),
      };
    }

    // DND or phone rules active
    if (dnd === PhoneEnums.DndStatus.ON) {
      return {
        title: formatMessage(messages.dnd),
        settings:
          activeRule && activeRule === PhoneRulesEnums.SettingsActions.dnd,
      };
    }
    if (activeRule && activeRule === PhoneRulesEnums.SettingsActions.vbox) {
      return {
        title: formatMessage(messages.vbox),
        settings: true,
      };
    }
    if (activeRule && activeRule === PhoneRulesEnums.SettingsActions.forward) {
      return {
        title: formatMessage(messages.forward),
        settings: true,
      };
    }

    // phone available
    return {
      title: formatMessage(messages.online),
    };
  };

  takeWebPhone = () => {
    const { sendStopUseWebrtcPhone } = this.props;
    sendStopUseWebrtcPhone();
  };

  render() {
    const {
      isMeOffline,
      phoneStatus,
      phoneDnd,
      phoneActiveRule,
      webrtcAvailable,
      webrtcActive,
      inConference,
      parked,
      supplier,
      audioAvailable,
      audioError,
      otherSessionsNumber,
      webrtcUsing,
      webphoneLoading,
      outgoingWebrtcCall,
      activeWebrtcCall,
      webPhoneReactivating,
    } = this.props;

    const phoneError = phoneStatus === PhoneEnums.PhoneStatuses.error;
    const phoneTitle = this.retrievePhoneTitleData().title;
    const linkToSettings = this.retrievePhoneTitleData().settings;

    const available =
      !webPhoneReactivating &&
      !outgoingWebrtcCall &&
      !inConference &&
      (phoneStatus === PhoneEnums.PhoneStatuses.inited ||
        phoneStatus === PhoneEnums.UserStatuses.available ||
        phoneStatus === PhoneEnums.UserStatuses.ready ||
        (supplier === PhoneEnums.PbxType.ABILIS &&
          phoneStatus === PhoneEnums.CallsStatuses.hold));

    // if i am logged on other web devices with webrtc
    const otherWebrtcSessions = webrtcActive && otherSessionsNumber > 0;
    // if i have web phone active but, or i have no microphone,
    // or i have no auth to use it
    const webrtcWithNoMicrophone =
      webrtcActive && (!audioAvailable || audioError === 'NotAllowedError');

    const showSearchInput =
      !webPhoneReactivating &&
      !isMeOffline &&
      !phoneError &&
      (available ||
        activeWebrtcCall ||
        phoneStatus === PhoneEnums.CallsStatuses.hold) &&
      (!webrtcActive ||
        (webrtcActive && webrtcUsing && !webrtcWithNoMicrophone));

    const showKeypad =
      !webPhoneReactivating &&
      !isMeOffline &&
      !phoneError &&
      (webrtcActive || available || activeWebrtcCall) &&
      (!webrtcActive ||
        (webrtcActive && webrtcUsing && !webrtcWithNoMicrophone));

    const showStartCallButton =
      !webPhoneReactivating &&
      !isMeOffline &&
      !phoneError &&
      available &&
      (!webrtcActive ||
        (webrtcActive && webrtcUsing && !webrtcWithNoMicrophone));

    return (
      phoneStatus !== PhoneEnums.PhoneStatuses.nophone && (
        <>
          {webrtcActive && otherSessionsNumber > 0 && (
            <OtherSessionsModal isOpen={false} /* onCancel={} */ />
          )}

          <div className="card" id="PhonePanel">
            <div
              className="media p-1"
              style={{ height: 'var(--yn-section-title-height)' }}
            >
              {webrtcAvailable ? (
                <PhoneModeSwitch
                  webrtcActive={webrtcActive}
                  color={statusColor(
                    (webPhoneReactivating &&
                      PhoneEnums.PhoneStatuses.offline) ||
                      (!webphoneLoading &&
                        webrtcActive &&
                        webrtcUsing &&
                        (!audioAvailable || audioError) &&
                        PhoneEnums.PhoneStatuses.offline) ||
                      (webrtcActive &&
                        !webrtcUsing &&
                        PhoneEnums.PhoneStatuses.offline) ||
                      (isMeOffline && PhoneEnums.PhoneStatuses.offline) ||
                      (outgoingWebrtcCall && PhoneEnums.UserStatuses.ringing) ||
                      phoneStatus,
                    phoneDnd,
                    phoneActiveRule,
                    supplier,
                    parked,
                    true
                  )}
                  disabled={
                    //webCallWithoutJsep ||
                    webrtcActive && !webrtcUsing && otherSessionsNumber > 0
                  }
                />
              ) : (
                <span
                  className="rounded-circle align-self-center d-inline-block"
                  style={{
                    background: statusColor(
                      (isMeOffline && PhoneEnums.PhoneStatuses.offline) ||
                        phoneStatus,
                      phoneDnd,
                      phoneActiveRule,
                      supplier,
                      parked
                    ),
                    width: '30px',
                    height: '30px',
                  }}
                />
              )}

              <div className="media-body text-left row">
                <div
                  className="col-10 text-truncate"
                  style={{
                    padding: '5px',
                    paddingLeft: '18px',
                    lineHeight: 1.5,
                    maxWidth: '80%',
                  }}
                >
                  {phoneTitle}
                </div>
                {linkToSettings && (
                  <Link to="/phone/settings">
                    <Icon
                      name="settings"
                      width={20}
                      height={20}
                      color="var(--yn-blue-darker)"
                      style={{
                        textAlign: 'right',
                        position: 'absolute',
                        top: '7px',
                        right: '5px',
                      }}
                    >
                      {this.props.intl.formatMessage(messages.settingsLink)}
                    </Icon>
                  </Link>
                )}
              </div>
              <HelpButton
                fileName={`phone-panel-${
                  webrtcAvailable ? 'webrtc-' : ''
                }${supplier}`}
                style={{
                  textAlign: 'right',
                  position: 'absolute',
                  top: '7px',
                  right: '5px',
                }}
              />
            </div>
            {webrtcWithNoMicrophone && (
              <div className="p-1 border-top">
                {!audioAvailable && webrtcActive && (
                  <Alert
                    color="danger"
                    style={{
                      fontSize: '13px',
                      padding: '0.5rem',
                      marginBottom: '2px',
                    }}
                  >
                    {this.props.intl.formatMessage(messages.noMicrophone)}
                  </Alert>
                )}
                {audioError === 'NotAllowedError' && webrtcActive && (
                  <Alert
                    color="danger"
                    style={{
                      fontSize: '13px',
                      padding: '0.5rem',
                      marginBottom: '2px',
                    }}
                  >
                    {this.props.intl.formatMessage(messages.noMicrophoneAuth)}
                  </Alert>
                )}
              </div>
            )}
            {otherWebrtcSessions && !webrtcUsing && (
              <div className="p-1 border-top">
                <Alert
                  color="secondary mb-1"
                  style={{
                    fontSize: '13px',
                    padding: '0.5rem',
                  }}
                >
                  {this.props.intl.formatMessage(messages.otherWebrtcSessions)}
                </Alert>
                <Button
                  text={this.props.intl.formatMessage(
                    messages.activateWebPhone
                  )}
                  className="btn btn-primary btn-block"
                  onClick={this.takeWebPhone}
                />
              </div>
            )}
            {showSearchInput && (
              <>
                <PhoneNumberSearchInput
                  name="PhonePanelInput"
                  id="PhonePanelInput"
                  target="PhonePanel"
                  onEnter={() => this.handleCall(this.props.number, false)}
                  disabled={!available && !activeWebrtcCall}
                  readOnly={activeWebrtcCall}
                  error={this.state.error}
                />
                <PhoneNumberSearchList
                  target="PhonePanel"
                  placement="right-start"
                  onSelect={(number, isExtension) =>
                    this.handleCall(number, !isExtension)
                  }
                />
              </>
            )}
            {showKeypad && <KeyPad onClick={(key) => this.handleKey(key)} />}
            {showStartCallButton && (
              <div className="p-1">
                <Button
                  text={this.props.intl.formatMessage(messages.call)}
                  className="btn btn-success btn-block"
                  onClick={() => this.handleCall(this.props.number, false)}
                />
              </div>
            )}
          </div>
          <Modal
            isOpen={this.state.webphoneDetachedModalOpen}
            toggle={this.toggleWebphoneDetachedModal}
          >
            <ModalBody>
              <div>
                {this.props.intl.formatMessage(messages.webphoneProblems)}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={this.handleReActivateWebphone}
                text={this.props.intl.formatMessage(messages.tryReactivate)}
              />
              <button
                type="button"
                className="btn btn-outline-primary ml-2"
                onClick={this.toggleWebphoneDetachedModal}
              >
                {this.props.intl.formatMessage(messages.cancelButton)}
              </button>
            </ModalFooter>
          </Modal>
        </>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    isMeOffline: isPhoneOffline(state),
    phoneStatus: getMePhoneStatus(state),
    phoneDnd: getMePhoneDnd(state),
    phoneActiveRule: getMePhoneActiveRule(state),
    inConference: isMeInConference(state),
    parked: isMeParked(state),
    webrtcAvailable: isWebrtcAvailable(state), // if true is Nethesis and there are webrtc extensions
    webrtcActive: isWebrtcActive(state) === 'ON', // if true the phone is set on webrtc
    webrtcUsing: isWebrtcInUse(state), // if true the webrtc phone is used on this session
    number: getSearchedNumber(state, 'PhonePanel'),
    supplier: getSupplier(state),
    playBusy: isPhonePlayingBusy(state),
    audioAvailable: isAudioInAvailable(state),
    audioError: getAudioMediadevicesError(state),
    otherSessionsNumber: otherSessions(state), // how many other sessions exists for this user
    webphoneLoading: !sentStopUseWebphoneLoaded(state),
    outgoingWebrtcCall: isOutgoingWebrtcCall(state),
    activeWebrtcCall: isActiveWebrtcCall(state),
    webphoneDetached: isWebphoneDetached(state),
    webPhoneReactivating: isWebphoneReactivating(state),
    sdpWebrtcCall: isWebrtcSdpCall(state),
    webPhoneRegistered: isWebPhoneRegistered(state),
    defaultDevicesReceived: receivedDefaultDevices(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    call: execCallRequest,
    setFilter: setSearchContactsFilter,
    sendDtmf: sendDtmfRequest,
    sendStopUseWebrtcPhone: sendStopUseWebphoneRequest,
    reactivateWebphone: reactivateWebrtcPhone,
    traceWebrtc: traceWebrtcTrouble,
  })(PhonePanel)
);
