import { request } from '../request';

export default {
  createWorkingHoursPlan: (payload) =>
    request({
      url: `/working-plans`,
      method: 'POST',
      data: payload,
    }),
  updateWorkingHoursPlan: (data) =>
    request({
      url: `/working-plans/${data.id}`,
      method: 'POST',
      data: data.data,
    }),
  getWorkingHoursPlan: (id) =>
    request({
      url: `/working-plans/${id}`,
      method: 'GET',
    }),
  getWorkingHoursPlans: (params) =>
    request({
      url: `/working-plans`,
      method: 'GET',
      params,
    }),
  assignWorkingHoursPlan: (params) =>
    request({
      url: `/working-plans/${params.id}/assign`,
      method: 'POST',
      data: params.data,
    }),
  assignWorkingHoursPlanToUser: (id, userId) =>
    request({
      url: `/working-plans/${id}/assign/${userId}`,
      method: 'POST',
    }),
  removeWorkingHoursPlan: (id) =>
    request({
      url: `/working-plans/${id}`,
      method: 'DELETE',
    }),
  assignWorkingHoursPlanToAllUsers: (id) =>
    request({
      url: `/working-plans/${id}/assign-all`,
      method: 'POST',
    }),
  roundWorkingHours: (params) =>
    request({
      url: `/customer/round-working-hours`,
      method: 'POST',
      data: params,
    }),
  breakWorkingHours: (params) =>
    request({
      url: `/customer/break-working-hours`,
      method: 'POST',
      data: {
        data: params,
      },
    }),
};
