export const isVideoInAvailable = (state) =>
  state.media.devices.videoIn.list.length > 0;
export const isAudioInAvailable = (state) =>
  state.media.devices.audioIn.list.length > 0;
export const isVideoEnabled = (state) => state.media.videoEnabled;
export const isAudioEnabled = (state) => state.media.audioEnabled;
export const isMediaDevicesAvailable = (state) => state.media.available;
export const getMediaDevices = (state) => state.media.devices;
export const getDeviceSelected = (state, context) => {
  const deviceLocalstorage = JSON.parse(localStorage.getItem(context));
  const mediaDevices = getMediaDevices(state);
  if (!deviceLocalstorage) {
    return mediaDevices[context].list[0];
  }
  const selectedDevices = mediaDevices[context].list.filter(
    (device) => device.id === deviceLocalstorage.value
  );
  if (selectedDevices.length === 0) {
    return mediaDevices[context].list[0];
  }
  return selectedDevices[0];
};
export const getFetchAudioMediadevicesLoaded = (state) =>
  state.media.fetchAudioDevicesLoaded;
export const getFetchAudioMediadevicesError = (state) =>
  state.media.fetchAudioDevicesError;
export const getFetchVideoMediadevicesLoaded = (state) =>
  state.media.fetchVideoDevicesLoaded;
export const getFetchVideoMediadevicesError = (state) =>
  state.media.fetchVideoDevicesError;
export const getMediastream = (state) => state.media.mediastream;
export const getAudioMediadevicesLoaded = (state) =>
  state.media.getAudioDevicesLoaded;
export const getAudioMediadevicesError = (state) =>
  state.media.getAudioDevicesError;
export const getVideoMediadevicesLoaded = (state) =>
  state.media.getVideoDevicesLoaded;
export const getVideoMediadevicesError = (state) =>
  state.media.getVideoDevicesError;
export const noDeviceAvailable = (state) =>
  (!isAudioInAvailable(state) && !isVideoInAvailable(state)) ||
  getAudioMediadevicesError(state) === 'NotAllowedError';
export const getScreenSources = (state) => state.media.sources;
