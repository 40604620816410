import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../../components/SecondaryNav';
import SectionTitle from '../../../../components/SectionTitle';
import { getSupplier } from '../../../../js/phone/selectors';
import { PhoneEnums } from '../../../../js/phone/PhoneUtils';
import {
  fetchPbxQueuesRequest,
  fetchPbxRoutesRequest,
} from '../../../../js/phone/actions';

const messages = defineMessages({
  title: {
    id: 'PbxSettings.title',
    defaultMessage: 'Switchboard settings',
  },
  switchboardRules: {
    id: 'PbxSettings.switchboardRules.navLabel',
    defaultMessage: 'Switchboard rules',
  },
  archivedGreetingsMessages: {
    id: 'PbxSettings.archivedGreetingsMessages.navLabel',
    defaultMessage: 'Archived messages',
  },
  activeGreetingsMessages: {
    id: 'PbxSettings.activeGreetingsMessages.navLabel',
    defaultMessage: 'Actives messages',
  },
  switchboardRoutes: {
    id: 'PbxSettings.switchboardRoutes.navLabel',
    defaultMessage: 'Routes',
  },
  notifications: {
    id: 'PbxSettings.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
  switchboard: {
    id: 'PbxSettings.switchboard.navLabel',
    defaultMessage: 'Switchboard',
  },
  extensions: {
    id: 'PbxSettings.extensions.navLabel',
    defaultMessage: 'Extensions',
  },
  queues: {
    id: 'PbxSettings.queues.navLabel',
    defaultMessage: 'Queues',
  },
});

class PbxSettings extends Component {
  componentDidMount() {
    const { supplier, fetchRoutes, fetchQueues } = this.props;
    if (
      this.hasPbxManagementGrants() &&
      supplier === PhoneEnums.PbxType.NETHESIS
    ) {
      fetchRoutes();
      fetchQueues();
    }
  }

  componentDidUpdate(prevProps) {
    const { supplier, fetchRoutes, fetchQueues } = this.props;
    if (
      this.hasPbxManagementGrants() &&
      supplier === PhoneEnums.PbxType.NETHESIS &&
      !prevProps.supplier
    ) {
      fetchRoutes();
      fetchQueues();
    }
  }

  hasPbxManagementGrants = () => {
    const { grants } = this.props;
    return (
      grants.indexOf('PBX_HOLIDAYS_WRITE') > -1 ||
      grants.indexOf('PBX_HOLIDAYS_READ') > -1
    );
  };

  render() {
    const {
      intl: { formatMessage },
      grants,
      supplier,
      scrolling,
      notificationsQuantity,
      children,
    } = this.props;

    let nav = [];
    const pbxManagementGrants = this.hasPbxManagementGrants();

    const pbxAdminTvoxGrants = grants.indexOf('BACKEND_TVOX') > -1;

    if (pbxManagementGrants && supplier === PhoneEnums.PbxType.ABILIS) {
      nav = [
        {
          id: 'PbxSettings.receivedMessages.linkLabel',
          href: '/pbx/settings/switchboard',
          label: messages.switchboardRules,
        },
        {
          id: 'PbxSettings.activeGreetingsMessages.linkLabel',
          href: '/pbx/actives',
          label: messages.activeGreetingsMessages,
        },
        {
          id: 'PbxSettings.archivedGreetingsMessages.linkLabel',
          href: '/pbx/archived/list',
          label: messages.archivedGreetingsMessages,
        },
      ];
    } else if (
      pbxManagementGrants &&
      supplier === PhoneEnums.PbxType.NETHESIS
    ) {
      nav = [
        {
          id: 'PbxSettings.receivedMessages.linkLabel',
          href: '/pbx/settings/switchboard',
          label: messages.switchboardRules,
          /*subNav: [
            {
              id: 'PbxSettings.ticketFields.label',
              href: '/pbx/settings/switchboard',
              label: messages.switchboard,
            },
            {
              id: 'PbxSettings.customizedStatus.label',
              href: '/pbx/settings/extensions',
              label: messages.extensions,
            },
          ],*/
        },
        {
          id: 'PbxSettings.receivedMessages.linkLabel',
          href: '/pbx/settings/users',
          label: messages.extensions,
        },
        {
          id: 'PbxSettings.receivedMessages.linkLabel',
          href: '/pbx/routes',
          label: messages.switchboardRoutes,
        },
        {
          id: 'PbxSettings.queues.linkLabel',
          href: '/pbx/queues',
          label: messages.queues,
        },
        {
          id: 'PbxSettings.archivedGreetingsMessages.linkLabel',
          href: '/pbx/archived/list',
          label: messages.archivedGreetingsMessages,
        },
      ];
    } else if (pbxAdminTvoxGrants && !pbxManagementGrants) {
      nav = [
        {
          id: 'PbxSettings.archivedGreetingsMessages.linkLabel',
          href: '/pbx/archived/list',
          label: messages.archivedGreetingsMessages,
        },
        {
          id: 'PbxSettings.notifications.linkLabel',
          href: '/pbx/archived/notifications',
          label: messages.notifications,
          notifications: notificationsQuantity,
        },
      ];
    } else if (pbxAdminTvoxGrants && pbxManagementGrants) {
      nav = [
        {
          id: 'PbxSettings.archivedGreetingsMessages.linkLabel',
          href: '/pbx/archived/list',
          label: messages.archivedGreetingsMessages,
        },
      ];
    }

    return (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
        <SecondaryNav tabs={nav} />
        <div
          className={
            scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
          }
        >
          {children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    grants: state.me.grants,
    supplier: getSupplier(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchRoutes: fetchPbxRoutesRequest,
    fetchQueues: fetchPbxQueuesRequest,
  })(PbxSettings)
);
