import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import uniqueid from 'lodash.uniqueid';
import ContactSelectModal from '../ContactSelectModal';
import Icon from '../icons/Icon';
import Popover from '../Popover';
import LinkButton from '../LinkButton';

const messages = defineMessages({
  addContact: {
    id: 'AddContactButton.tooltip.addContact',
    defaultMessage: 'Add contact',
  },
  new: {
    id: 'AddContactButton.tooltip.new',
    defaultMessage: 'New',
  },
  existing: {
    id: 'AddContactButton.tooltip.existing',
    defaultMessage: 'Existing',
  },
});

class AddContactButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      modalOpen: false,
      popoverTarget: uniqueid('AddNumber-'),
    };
  }

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  toggleModal = () => {
    this.setState({
      popoverOpen: false,
      modalOpen: !this.state.modalOpen,
    });
  };

  render() {
    const { popoverOpen, modalOpen, popoverTarget } = this.state;
    const {
      number,
      type,
      intl: { formatMessage },
    } = this.props;

    return (
      <>
        <span
          className="p-1"
          id={popoverTarget}
          style={{ position: 'relative', top: '3px' }}
        >
          <Icon
            name="add-plus"
            width={16}
            height={16}
            color="var(--yn-blue-darker)"
          >
            {formatMessage(messages.addContact)}
          </Icon>
        </span>

        <Popover
          isOpen={popoverOpen}
          target={popoverTarget}
          toggle={this.togglePopover}
          popoverMinWidth="fit-content"
          popoverMinHeight="fit-content"
        >
          <Link to={`/abook/contacts/edit?type=${type}&number=${number}`}>
            <LinkButton onClick={() => this.togglePopover()}>
              {formatMessage(messages.new)}
            </LinkButton>
          </Link>
          |
          <LinkButton onClick={() => this.toggleModal()}>
            {formatMessage(messages.existing)}
          </LinkButton>
        </Popover>
        <ContactSelectModal
          isOpen={modalOpen}
          toggle={this.toggleModal}
          type={type}
          number={number}
        />
      </>
    );
  }
}

AddContactButton.propTypes = {
  number: PropTypes.string.isRequired,
  type: PropTypes.string,
};
AddContactButton.defaultProps = {
  type: 'MOBILE',
};

export default injectIntl(AddContactButton);
