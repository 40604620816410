import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../../components/icons/Icon';
import { deleteStickyNoteRequest } from '../../../js/sticky/actions';
import ColorSelector from '../../../components/ColorSelector';
import FontSelector from './FontSelector';
import ShapeSelector from './ShapeSelector';
import HelpButton from '../../../components/HelpButton';

const STICKY_COLORS = ['#faf6bd', '#ffc6c6', '#bce6fb', '#e9bcfb', '#bcfbc7'];
const STICKY_FONTS = [
  'Open Sans',
  'Kavivanar',
  'Patrick Hand SC',
  'La Belle Aurore',
  'Caveat',
];
const STICKY_SHAPE = [
  'CLASSIC',
  'CIRCLE',
  'STAR',
  'HEART',
  'BALLOON',
  'CHRISTMASTREE',
];

const messages = defineMessages({
  changeColor: {
    id: 'StickyMenu.label.changeColor',
    defaultMessage: 'Change color',
  },
  changeFont: {
    id: 'StickyMenu.label.changeFont',
    defaultMessage: 'Change font',
  },
  changeShape: {
    id: 'StickyMenu.label.changeShape',
    defaultMessage: 'Change shape',
  },
});

class StickyMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorIconName: 'color-bucket',
      fontIconName: 'text',
      shapeColorName: 'rank-army-star-2',
      showColorPicker: false,
      showFontPicker: false,
      showShapePicker: false,
    };
    this.handleColor = this.handleColor.bind(this);
    this.handleFont = this.handleFont.bind(this);
    this.handleShape = this.handleShape.bind(this);
  }

  componentDidUpdate(prevprops) {
    if (this.props.isOpen !== prevprops.isOpen && !this.props.isOpen) {
      this.setState({
        showColorPicker: false,
        showFontPicker: false,
        showShapePicker: false,
      });
    }
  }

  showColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker,
      showFontPicker: false,
      showShapePicker: false,
    });
    this.props.onOpen();
  };

  showFontPicker = () => {
    this.setState({
      showFontPicker: !this.state.showFontPicker,
      showColorPicker: false,
      showShapePicker: false,
    });
    this.props.onOpen();
  };

  showShapePicker = () => {
    this.setState({
      showShapePicker: !this.state.showShapePicker,
      showColorPicker: false,
      showFontPicker: false,
    });
    this.props.onOpen();
  };

  handleColor = (color) => {
    const detail = {
      ...this.props.stickyNote.detail,
      color,
    };
    this.setState({
      showColorPicker: false,
    });
    this.props.onClose();
    this.props.updateSticky({ ...this.props.stickyNote, detail });
  };

  handleFont = (font) => {
    const detail = {
      ...this.props.stickyNote.detail,
      font,
    };
    this.setState({
      showFontPicker: false,
    });
    this.props.onClose();
    this.props.updateSticky({ ...this.props.stickyNote, detail });
  };

  handleShape = (shape) => {
    const detail = {
      ...this.props.stickyNote.detail,
      shape,
    };
    this.setState({
      showShapePicker: false,
    });
    this.props.onClose();
    this.props.updateSticky({ ...this.props.stickyNote, detail });
  };

  render() {
    const {
      isOpen,
      stickyNote,
      menuDivStyle,
      changeMenuStyle,
      intl: { formatMessage },
    } = this.props;
    const { showColorPicker, showShapePicker, showFontPicker } = this.state;
    const colorIndex = STICKY_COLORS.indexOf(stickyNote.detail.color);

    return (
      <>
        <div style={{ position: 'absolute', top: '-12px', right: '18px' }}>
          <HelpButton fileName="sticky" />
        </div>
        <div style={menuDivStyle}>
          <span onClick={() => this.showColorPicker()}>
            <Icon
              name={showColorPicker ? 'color-bucket-filled' : 'color-bucket'}
              color="var(--gray-dark)"
              clickable
              width={16}
              height={16}
              tooltipPosition="top"
            >
              {formatMessage(messages.changeColor)}
            </Icon>
          </span>
          <span onClick={() => this.showFontPicker()}>
            <Icon
              name={showFontPicker ? 'text-filled' : 'text'}
              color="var(--gray-dark)"
              clickable
              width={13}
              height={13}
              className="ml-1"
              tooltipPosition="top"
            >
              {formatMessage(messages.changeFont)}
            </Icon>
          </span>
          <span onClick={() => this.showShapePicker()}>
            <Icon
              name={
                showShapePicker ? 'rank-army-star-2-filled' : 'rank-army-star-2'
              }
              color="var(--gray-dark)"
              clickable
              width={16}
              height={16}
              className="ml-1"
              tooltipPosition="top"
            >
              {formatMessage(messages.changeShape)}
            </Icon>
          </span>
        </div>
        <div
          className="rounded-bottom"
          style={{ ...changeMenuStyle, zIndex: '1010' }}
        >
          {showColorPicker && isOpen && (
            <ColorSelector
              id="colors"
              selected={colorIndex}
              colors={STICKY_COLORS}
              selectColor={this.handleColor}
              colorsWidth="1.2em"
              colorsHeight="1.2em"
            />
          )}
          {showFontPicker && isOpen && (
            <FontSelector fonts={STICKY_FONTS} selectFont={this.handleFont} />
          )}
          {showShapePicker && isOpen && (
            <ShapeSelector
              shapes={STICKY_SHAPE}
              selectShape={this.handleShape}
            />
          )}
        </div>
      </>
    );
  }
}

StickyMenu.propTypes = {
  stickyNote: PropTypes.object.isRequired,
  updateSticky: PropTypes.func.isRequired,
  menuDivStyle: PropTypes.object.isRequired,
  changeMenuStyle: PropTypes.object.isRequired,
};

export default injectIntl(
  connect(null, {
    deleteSticky: deleteStickyNoteRequest,
  })(StickyMenu)
);
