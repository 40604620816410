export const isAssociationsLoaded = (state) =>
  state.associations.associationsLoaded;
export const isAssociationsError = (state) =>
  state.associations.associationsError;
export const getAssociationsList = (state) =>
  state.associations.associationsIds;
export const getAssociationsListIsAdmin = (state) =>
  state.associations.associationsIds.filter((id) =>
    state.associations.associationsById[id].members.some(
      (member) => member.id === state.me.idCustomer && member.isAdmin
    )
  );
export const getAssociationsListIsNotAdmin = (state) =>
  state.associations.associationsIds.filter((id) =>
    state.associations.associationsById[id].members.some(
      (member) => member.id === state.me.idCustomer && !member.isAdmin
    )
  );
export const getAssociationsListForInteract = (state) =>
  state.associations.associationsIds.filter(
    (id) =>
      !state.associations.associationsById[id].isSupplier &&
      state.associations.associationsById[id].members.some(
        (member) =>
          member.id === state.me.idCustomer && member.mode !== 'RECEPTION_ONLY'
      )
  );
export const getAssociationsListSupplierForInteract = (state) =>
  state.associations.associationsIds.filter(
    (id) =>
      state.associations.associationsById[id].isSupplier &&
      state.associations.associationsById[id].members.some(
        (member) =>
          member.id === state.me.idCustomer &&
          !member.isAdmin &&
          member.mode !== 'RECEPTION_ONLY'
      )
  );
export const getAssociationById = (state, id) =>
  state.associations.associationsById[id];
export const getAssociationMemberById = (state, associationId, memberId) =>
  state.associations.associationsById[associationId].members.find(
    (member) => member.id === memberId
  );
export const isAssociationMemberOperationLoaded = (state) =>
  state.associations.memberOperationLoaded;
export const isAssociationMemberOperationError = (state) =>
  state.associations.memberOperationError;
export const isDeleteAssociationLoaded = (state) =>
  state.associations.deleteAssociationLoaded;
export const isDeleteAssociationError = (state) =>
  state.associations.deleteAssociationError;
export const isAssociationMemberDetailsLoaded = (state) =>
  state.associations.memberDetailsLoaded;
export const isAssociationMemberDetailsError = (state) =>
  state.associations.memberDetailsError;
export const getAssociationMemberData = (state) =>
  state.associations.memberData;
