export const FETCH_SMS_LIST_REQUEST = 'FETCH_SMS_LIST_REQUEST';
export const FETCH_SMS_LIST_SUCCESS = 'FETCH_SMS_LIST_SUCCESS';
export const FETCH_SMS_LIST_FAILURE = 'FETCH_SMS_LIST_FAILURE';
export const SET_SMS_LIST_FILTER = 'SET_SMS_LIST_FILTER';
export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';
export const FETCH_SMS_CREDITS_REQUEST = 'FETCH_SMS_CREDITS_REQUEST';
export const FETCH_SMS_CREDITS_SUCCESS = 'FETCH_SMS_CREDITS_SUCCESS';
export const FETCH_SMS_CREDITS_FAILURE = 'FETCH_SMS_CREDITS_FAILURE';
export const FETCH_SMS_SCHEDULED_REQUEST = 'FETCH_SMS_SCHEDULED_REQUEST';
export const FETCH_SMS_SCHEDULED_SUCCESS = 'FETCH_SMS_SCHEDULED_SUCCESS';
export const FETCH_SMS_SCHEDULED_FAILURE = 'FETCH_SMS_SCHEDULED_FAILURE';
export const SAVE_SMS_SCHEDULED_REQUEST = 'SAVE_SMS_SCHEDULED_REQUEST';
export const SAVE_SMS_SCHEDULED_SUCCESS = 'SAVE_SMS_SCHEDULED_SUCCESS';
export const SAVE_SMS_SCHEDULED_FAILURE = 'SAVE_SMS_SCHEDULED_FAILURE';
export const DELETE_SMS_SCHEDULED_REQUEST = 'DELETE_SMS_SCHEDULED_REQUEST';
export const DELETE_SMS_SCHEDULED_SUCCESS = 'DELETE_SMS_SCHEDULED_SUCCESS';
export const DELETE_SMS_SCHEDULED_FAILURE = 'DELETE_SMS_SCHEDULED_FAILURE';
export const FETCH_SMS_ALIAS_REQUEST = 'FETCH_SMS_ALIAS_REQUEST';
export const FETCH_SMS_ALIAS_SUCCESS = 'FETCH_SMS_ALIAS_SUCCESS';
export const FETCH_SMS_ALIAS_FAILURE = 'FETCH_SMS_ALIAS_FAILURE';
export const SAVE_SMS_ALIAS_REQUEST = 'SAVE_SMS_ALIAS_REQUEST';
export const SAVE_SMS_ALIAS_SUCCESS = 'SAVE_SMS_ALIAS_SUCCESS';
export const SAVE_SMS_ALIAS_FAILURE = 'SAVE_SMS_ALIAS_FAILURE';
export const FETCH_SMS_CAMPAIGNS_REQUEST = 'FETCH_SMS_CAMPAIGNS_REQUEST';
export const FETCH_SMS_CAMPAIGNS_SUCCESS = 'FETCH_SMS_CAMPAIGNS_SUCCESS';
export const FETCH_SMS_CAMPAIGNS_FAILURE = 'FETCH_SMS_CAMPAIGNS_FAILURE';
export const FETCH_SMS_CAMPAIGN_REQUEST = 'FETCH_SMS_CAMPAIGN_REQUEST';
export const FETCH_SMS_CAMPAIGN_SUCCESS = 'FETCH_SMS_CAMPAIGN_SUCCESS';
export const FETCH_SMS_CAMPAIGN_FAILURE = 'FETCH_SMS_CAMPAIGN_FAILURE';
export const SAVE_SMS_CAMPAIGN_REQUEST = 'SAVE_SMS_CAMPAIGN_REQUEST';
export const SAVE_SMS_CAMPAIGN_SUCCESS = 'SAVE_SMS_CAMPAIGN_SUCCESS';
export const SAVE_SMS_CAMPAIGN_FAILURE = 'SAVE_SMS_CAMPAIGN_FAILURE';
export const DELETE_SMS_CAMPAIGN_REQUEST = 'DELETE_SMS_CAMPAIGN_REQUEST';
export const DELETE_SMS_CAMPAIGN_SUCCESS = 'DELETE_SMS_CAMPAIGN_SUCCESS';
export const DELETE_SMS_CAMPAIGN_FAILURE = 'DELETE_SMS_CAMPAIGN_FAILURE';
export const BUY_SMS_PACKAGES_REQUEST = 'BUY_SMS_PACKAGES_REQUEST';
export const BUY_SMS_PACKAGES_SUCCESS = 'BUY_SMS_PACKAGES_SUCCESS';
export const BUY_SMS_PACKAGES_FAILURE = 'BUY_SMS_PACKAGES_FAILURE';
