import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  text: {
    id: 'FileboxBlockedAlert.info',
    defaultMessage: 'Your filebox is blocked. Please contact ',
  },
  mailto: {
    id: 'FileboxBlockedAlert.mailto',
    defaultMessage: 'info@ambrogio.com',
  },
});

const FileboxBlockedAlert = ({ intl: { formatMessage } }) => (
  <div className="alert alert-danger">
    {formatMessage(messages.text)}
    <a href="mailto:info@ambrogio.com" className="alert-link mx-2">
      {formatMessage(messages.mailto)}
    </a>
  </div>
);
export default injectIntl(FileboxBlockedAlert);
