export const FETCH_CALENDAR_SETTINGS_REQUEST =
  'FETCH_CALENDAR_SETTINGS_REQUEST';
export const FETCH_CALENDAR_SETTINGS_FAILURE =
  'FETCH_CALENDAR_SETTINGS_FAILURE';
export const FETCH_CALENDAR_SETTINGS_SUCCESS =
  'FETCH_CALENDAR_SETTINGS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST';
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENTS_FAILURE';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';
export const SET_CALENDAR_FILTER = 'SET_CALENDAR_FILTER';
export const FETCH_CALENDAR_EVENT_REQUEST = 'FETCH_CALENDAR_EVENT_REQUEST';
export const FETCH_CALENDAR_EVENT_FAILURE = 'FETCH_CALENDAR_EVENT_FAILURE';
export const FETCH_CALENDAR_EVENT_SUCCESS = 'FETCH_CALENDAR_EVENT_SUCCESS';
export const SAVE_CALENDAR_EVENT_REQUEST = 'SAVE_CALENDAR_EVENT_REQUEST';
export const SAVE_CALENDAR_EVENT_FAILURE = 'SAVE_CALENDAR_EVENT_FAILURE';
export const SAVE_CALENDAR_EVENT_SUCCESS = 'SAVE_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_REQUEST = 'DELETE_CALENDAR_EVENT_REQUEST';
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const SAVE_CALENDAR_SETTINGS_REQUEST = 'SAVE_CALENDAR_SETTINGS_REQUEST';
export const SAVE_CALENDAR_SETTINGS_SUCCESS = 'SAVE_CALENDAR_SETTINGS_SUCCESS';
export const SAVE_CALENDAR_SETTINGS_FAILURE = 'SAVE_CALENDAR_SETTINGS_FAILURE';
export const FETCH_CALENDAR_SYNCOUT_REQUEST = 'FETCH_CALENDAR_SYNCOUT_REQUEST';
export const FETCH_CALENDAR_SYNCOUT_SUCCESS = 'FETCH_CALENDAR_SYNCOUT_SUCCESS';
export const FETCH_CALENDAR_SYNCOUT_FAILURE = 'FETCH_CALENDAR_SYNCOUT_FAILURE';
export const SAVE_CALENDAR_SYNCOUT_REQUEST = 'SAVE_CALENDAR_SYNCOUT_REQUEST';
export const SAVE_CALENDAR_SYNCOUT_SUCCESS = 'SAVE_CALENDAR_SYNCOUT_SUCCESS';
export const SAVE_CALENDAR_SYNCOUT_FAILURE = 'SAVE_CALENDAR_SYNCOUT_FAILURE';
export const DELETE_CALENDAR_SYNCOUT_REQUEST =
  'DELETE_CALENDAR_SYNCOUT_REQUEST';
export const DELETE_CALENDAR_SYNCOUT_SUCCESS =
  'DELETE_CALENDAR_SYNCOUT_SUCCESS';
export const DELETE_CALENDAR_SYNCOUT_FAILURE =
  'DELETE_CALENDAR_SYNCOUT_FAILURE';
export const ENABLE_DISABLE_SYNCOUT_REQUEST = 'ENABLE_DISABLE_SYNCOUT_REQUEST';
export const ENABLE_DISABLE_SYNCOUT_SUCCESS = 'ENABLE_DISABLE_SYNCOUT_SUCCESS';
export const ENABLE_DISABLE_SYNCOUT_FAILURE = 'ENABLE_DISABLE_SYNCOUT_FAILURE';
export const IMPORT_CALENDAR_EVENTS_REQUEST = 'IMPORT_CALENDAR_EVENTS_REQUEST';
export const IMPORT_CALENDAR_EVENTS_SUCCESS = 'IMPORT_CALENDAR_EVENTS_SUCCESS';
export const IMPORT_CALENDAR_EVENTS_FAILURE = 'IMPORT_CALENDAR_EVENTS_FAILURE';
export const CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST =
  'CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST';
export const CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS =
  'CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS';
export const CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE =
  'CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE';
export const CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST =
  'CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST';
export const CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS =
  'CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS';
export const CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE =
  'CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE';
export const CLOSE_IMPORT_CALENDAR_EVENTS_MODAL =
  'CLOSE_IMPORT_CALENDAR_EVENTS_MODAL';
export const FETCH_CALENDAR_SYNCIN_REQUEST = 'FETCH_CALENDAR_SYNCIN_REQUEST';
export const FETCH_CALENDAR_SYNCIN_SUCCESS = 'FETCH_CALENDAR_SYNCIN_SUCCESS';
export const FETCH_CALENDAR_SYNCIN_FAILURE = 'FETCH_CALENDAR_SYNCIN_FAILURE';
export const SAVE_CALENDAR_SYNCIN_REQUEST = 'SAVE_CALENDAR_SYNCIN_REQUEST';
export const SAVE_CALENDAR_SYNCIN_SUCCESS = 'SAVE_CALENDAR_SYNCIN_SUCCESS';
export const SAVE_CALENDAR_SYNCIN_FAILURE = 'SAVE_CALENDAR_SYNCIN_FAILURE';
export const DELETE_CALENDAR_SYNCIN_REQUEST = 'DELETE_CALENDAR_SYNCIN_REQUEST';
export const DELETE_CALENDAR_SYNCIN_SUCCESS = 'DELETE_CALENDAR_SYNCIN_SUCCESS';
export const DELETE_CALENDAR_SYNCIN_FAILURE = 'DELETE_CALENDAR_SYNCIN_FAILURE';
export const TOGGLE_CALENDAR_SYNCIN_REQUEST = 'TOGGLE_CALENDAR_SYNCIN_REQUEST';
export const TOGGLE_CALENDAR_SYNCIN_SUCCESS = 'TOGGLE_CALENDAR_SYNCIN_SUCCESS';
export const TOGGLE_CALENDAR_SYNCIN_FAILURE = 'TOGGLE_CALENDAR_SYNCIN_FAILURE';
export const FETCH_CALENDAR_SLOTS_REQUEST = 'FETCH_CALENDAR_SLOTS_REQUEST';
export const FETCH_CALENDAR_SLOTS_SUCCESS = 'FETCH_CALENDAR_SLOTS_SUCCESS';
export const FETCH_CALENDAR_SLOTS_FAILURE = 'FETCH_CALENDAR_SLOTS_FAILURE';
export const FETCH_VACATION_PLAN_REQUEST = 'FETCH_VACATION_PLAN_REQUEST';
export const FETCH_VACATION_PLAN_SUCCESS = 'FETCH_VACATION_PLAN_SUCCESS';
export const FETCH_VACATION_PLAN_FAILURE = 'FETCH_VACATION_PLAN_FAILURE';
export const CALENDAR_REMINDER_NOTIFICATION = 'CALENDAR_REMINDER_NOTIFICATION';
export const CALENDAR_INSERT_NOTIFICATION = 'CALENDAR_INSERT_NOTIFICATION';
export const CALENDAR_UPDATE_NOTIFICATION = 'CALENDAR_UPDATE_NOTIFICATION';
export const CALENDAR_DELETE_NOTIFICATION = 'CALENDAR_DELETE_NOTIFICATION';
export const CALENDAR_DRAG_NOTIFICATION = 'CALENDAR_DRAG_NOTIFICATION';
export const CALENDAR_ADDED_PARTICIPANT_NOTIFICATION =
  'CALENDAR_ADDED_PARTICIPANT_NOTIFICATION';
export const CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION =
  'CALENDAR_REMOVED_PARTICIPANT_NOTIFICATION';
export const FETCH_CALENDAR_EVENT_CONTACTS_REQUEST =
  'FETCH_CALENDAR_EVENT_CONTACTS_REQUEST';
export const FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS =
  'FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS';
export const FETCH_CALENDAR_EVENT_CONTACTS_FAILURE =
  'FETCH_CALENDAR_EVENT_CONTACTS_FAILURE';
export const APPROVE_VACATION_REQUEST = 'APPROVE_VACATION_REQUEST';
export const APPROVE_VACATION_SUCCESS = 'APPROVE_VACATION_SUCCESS';
export const APPROVE_VACATION_FAILURE = 'APPROVE_VACATION_FAILURE';
export const REJECT_VACATION_REQUEST = 'REJECT_VACATION_REQUEST';
export const REJECT_VACATION_SUCCESS = 'REJECT_VACATION_SUCCESS';
export const REJECT_VACATION_FAILURE = 'REJECT_VACATION_FAILURE';
export const APPROVE_DELETION_VACATION_REQUEST =
  'APPROVE_DELETION_VACATION_REQUEST';
export const APPROVE_DELETION_VACATION_SUCCESS =
  'APPROVE_DELETION_VACATION_SUCCESS';
export const APPROVE_DELETION_VACATION_FAILURE =
  'APPROVE_DELETION_VACATION_FAILURE';
export const REJECT_DELETION_VACATION_REQUEST =
  'REJECT_DELETION_VACATION_REQUEST';
export const REJECT_DELETION_VACATION_SUCCESS =
  'REJECT_DELETION_VACATION_SUCCESS';
export const REJECT_DELETION_VACATION_FAILURE =
  'REJECT_DELETION_VACATION_FAILURE';
export const FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST =
  'FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST';
export const FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS =
  'FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS';
export const FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE =
  'FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE';
export const FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST =
  'FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST';
export const FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE =
  'FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE';
export const FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS =
  'FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS';
export const SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST =
  'SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST';
export const SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS =
  'SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS';
export const SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE =
  'SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE';
export const FETCH_VACATION_RESUME_REQUEST = 'FETCH_VACATION_RESUME_REQUEST';
export const FETCH_VACATION_RESUME_SUCCESS = 'FETCH_VACATION_RESUME_SUCCESS';
export const FETCH_VACATION_RESUME_FAILURE = 'FETCH_VACATION_RESUME_FAILURE';
export const FETCH_TASK_PLAN_REQUEST = 'FETCH_TASK_PLAN_REQUEST';
export const FETCH_TASK_PLAN_SUCCESS = 'FETCH_TASK_PLAN_SUCCESS';
export const FETCH_TASK_PLAN_FAILURE = 'FETCH_TASK_PLAN_FAILURE';
export const FETCH_APPOINTMENT_RESULTS_REQUEST =
  'FETCH_APPOINTMENT_RESULTS_REQUEST';
export const FETCH_APPOINTMENT_RESULTS_SUCCESS =
  'FETCH_APPOINTMENT_RESULTS_SUCCESS';
export const FETCH_APPOINTMENT_RESULTS_FAILURE =
  'FETCH_APPOINTMENT_RESULTS_FAILURE';
export const DELETE_APPOINTMENT_RESULT_REQUEST =
  'DELETE_APPOINTMENT_RESULT_REQUEST';
export const DELETE_APPOINTMENT_RESULT_SUCCESS =
  'DELETE_APPOINTMENT_RESULT_SUCCESS';
export const DELETE_APPOINTMENT_RESULT_FAILURE =
  'DELETE_APPOINTMENT_RESULT_FAILURE';
export const INSERT_APPOINTMENT_RESULT_REQUEST =
  'INSERT_APPOINTMENT_RESULT_REQUEST';
export const INSERT_APPOINTMENT_RESULT_SUCCESS =
  'INSERT_APPOINTMENT_RESULT_SUCCESS';
export const INSERT_APPOINTMENT_RESULT_FAILURE =
  'INSERT_APPOINTMENT_RESULT_FAILURE';
export const EDIT_APPOINTMENT_RESULT_REQUEST =
  'EDIT_APPOINTMENT_RESULT_REQUEST';
export const EDIT_APPOINTMENT_RESULT_SUCCESS =
  'EDIT_APPOINTMENT_RESULT_SUCCESS';
export const EDIT_APPOINTMENT_RESULT_FAILURE =
  'EDIT_APPOINTMENT_RESULT_FAILURE';
export const CLEAR_APPOINTMENT_RESULT_ERRORS =
  'CLEAR_APPOINTMENT_RESULT_ERRORS';
export const FETCH_APPOINTMENT_TYPES_REQUEST =
  'FETCH_APPOINTMENT_TYPES_REQUEST';
export const FETCH_APPOINTMENT_TYPES_SUCCESS =
  'FETCH_APPOINTMENT_TYPES_SUCCESS';
export const FETCH_APPOINTMENT_TYPES_FAILURE =
  'FETCH_APPOINTMENT_TYPES_FAILURE';
export const DELETE_APPOINTMENT_TYPE_REQUEST =
  'DELETE_APPOINTMENT_TYPE_REQUEST';
export const DELETE_APPOINTMENT_TYPE_SUCCESS =
  'DELETE_APPOINTMENT_TYPE_SUCCESS';
export const DELETE_APPOINTMENT_TYPE_FAILURE =
  'DELETE_APPOINTMENT_TYPE_FAILURE';
export const INSERT_APPOINTMENT_TYPE_REQUEST =
  'INSERT_APPOINTMENT_TYPE_REQUEST';
export const INSERT_APPOINTMENT_TYPE_SUCCESS =
  'INSERT_APPOINTMENT_TYPE_SUCCESS';
export const INSERT_APPOINTMENT_TYPE_FAILURE =
  'INSERT_APPOINTMENT_TYPE_FAILURE';
export const EDIT_APPOINTMENT_TYPE_REQUEST = 'EDIT_APPOINTMENT_TYPE_REQUEST';
export const EDIT_APPOINTMENT_TYPE_SUCCESS = 'EDIT_APPOINTMENT_TYPE_SUCCESS';
export const EDIT_APPOINTMENT_TYPE_FAILURE = 'EDIT_APPOINTMENT_TYPE_FAILURE';
export const CLEAR_APPOINTMENT_TYPE_ERRORS = 'CLEAR_APPOINTMENT_TYPE_ERRORS';
export const FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST =
  'FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST';
export const FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS =
  'FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS';
export const FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE =
  'FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE';
export const FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST =
  'FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST';
export const FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS =
  'FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS';
export const FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE =
  'FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE';
export const FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST =
  'FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST';
export const FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS =
  'FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS';
export const FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE =
  'FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE';
export const FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST =
  'FETCH_TICKETS_LIST_SHORTCUT_REQUEST';
export const FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS =
  'FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS';
export const FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE =
  'FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE';
export const SET_APPOINTMENTS_FILTER = 'SET_APPOINTMENTS_FILTER';
export const FETCH_APPOINTMENTS_REQUEST = 'FETCH_APPOINTMENTS_REQUEST';
export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE = 'FETCH_APPOINTMENTS_FAILURE';
export const FETCH_APPOINTMENTS_MAP_REQUEST = 'FETCH_APPOINTMENTS_MAP_REQUEST';
export const FETCH_APPOINTMENTS_MAP_SUCCESS = 'FETCH_APPOINTMENTS_MAP_SUCCESS';
export const FETCH_APPOINTMENTS_MAP_FAILURE = 'FETCH_APPOINTMENTS_MAP_FAILURE';
export const SAVE_CALENDAR_APPOINTMENT_REQUEST =
  'SAVE_CALENDAR_APPOINTMENT_REQUEST';
export const SAVE_CALENDAR_APPOINTMENT_FAILURE =
  'SAVE_CALENDAR_APPOINTMENT_FAILURE';
export const SAVE_CALENDAR_APPOINTMENT_SUCCESS =
  'SAVE_CALENDAR_APPOINTMENT_SUCCESS';
export const FETCH_CALENDAR_APPOINTMENT_REQUEST =
  'FETCH_CALENDAR_APPOINTMENT_REQUEST';
export const FETCH_CALENDAR_APPOINTMENT_FAILURE =
  'FETCH_CALENDAR_APPOINTMENT_FAILURE';
export const FETCH_CALENDAR_APPOINTMENT_SUCCESS =
  'FETCH_CALENDAR_APPOINTMENT_SUCCESS';
export const DELETE_CALENDAR_APPOINTMENT_REQUEST =
  'DELETE_CALENDAR_APPOINTMENT_REQUEST';
export const DELETE_CALENDAR_APPOINTMENT_FAILURE =
  'DELETE_CALENDAR_APPOINTMENT_FAILURE';
export const DELETE_CALENDAR_APPOINTMENT_SUCCESS =
  'DELETE_CALENDAR_APPOINTMENT_SUCCESS';
export const REGISTER_CALENDAR_APPOINTMENT_REQUEST =
  'REGISTER_CALENDAR_APPOINTMENT_REQUEST';
export const REGISTER_CALENDAR_APPOINTMENT_FAILURE =
  'REGISTER_CALENDAR_APPOINTMENT_FAILURE';
export const REGISTER_CALENDAR_APPOINTMENT_SUCCESS =
  'REGISTER_CALENDAR_APPOINTMENT_SUCCESS';
export const FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST =
  'FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST';
export const FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE =
  'FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE';
export const FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS =
  'FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS';
export const FETCH_LINKED_CALENDAR_EVENTS_REQUEST =
  'FETCH_LINKED_CALENDAR_EVENTS_REQUEST';
export const FETCH_LINKED_CALENDAR_EVENTS_FAILURE =
  'FETCH_LINKED_CALENDAR_EVENTS_FAILURE';
export const FETCH_LINKED_CALENDAR_EVENTS_SUCCESS =
  'FETCH_LINKED_CALENDAR_EVENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST =
  'FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST';
export const FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS =
  'FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS';
export const FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE =
  'FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE';
