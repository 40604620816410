import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import history from '../../history';

const messages = defineMessages({
  back: {
    id: 'BackButton.back',
    defaultMessage: 'Back',
  },
});

const BackButton = ({ intl: { formatMessage } }) => {
  return (
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={history.goBack}
    >
      {formatMessage(messages.back)}
    </button>
  );
};

export default injectIntl(BackButton);
