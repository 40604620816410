import keyBy from 'lodash.keyby';
import groupBy from 'lodash.groupby';
import pull from 'lodash.pull';
import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import {
  FETCH_VBOX_MESSAGES_REQUEST,
  FETCH_VBOX_MESSAGES_SUCCESS,
  FETCH_VBOX_MESSAGES_FAILURE,
  SET_VBOX_MESSAGES_FILTER,
  DELETE_VBOX_MESSAGES_REQUEST,
  DELETE_VBOX_MESSAGES_SUCCESS,
  DELETE_VBOX_MESSAGES_FAILURE,
  FETCH_TVOX_SOUNDS_REQUEST,
  FETCH_TVOX_SOUNDS_SUCCESS,
  FETCH_TVOX_SOUNDS_FAILURE,
  FETCH_TVOX_PRICE_REQUEST,
  FETCH_TVOX_PRICE_SUCCESS,
  FETCH_TVOX_PRICE_FAILURE,
  PURCHASE_TVOX_MESSAGE_REQUEST,
  PURCHASE_TVOX_MESSAGE_SUCCESS,
  PURCHASE_TVOX_MESSAGE_FAILURE,
  FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST,
  FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS,
  FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE,
  LOAD_OWN_GREETING_SUCCESS,
  LOAD_OWN_GREETING_FAILURE,
  SAVE_OWN_GREETING_REQUEST,
  SAVE_OWN_GREETING_SUCCESS,
  SAVE_OWN_GREETING_FAILURE,
  FETCH_ACTIVE_GREETING_MESSAGES_REQUEST,
  FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS,
  FETCH_ACTIVE_GREETING_MESSAGES_FAILURE,
  DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST,
  DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS,
  DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE,
  ACTIVATE_PBX_GREETING_REQUEST,
  ACTIVATE_PBX_GREETING_SUCCESS,
  ACTIVATE_PBX_GREETING_FAILURE,
  SAVE_PBX_GREETING_REQUEST,
  SAVE_PBX_GREETING_SUCCESS,
  SAVE_PBX_GREETING_FAILURE,
  LISTEN_VBOX_SUCCESS,
  ME_RECEIVED_VBOX_NOTIFICATION,
  PBX_RECEIVED_VBOX_NOTIFICATION,
} from './types';
import { FETCH_ME_SUCCESS } from '../me/types';
import {
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
} from '../files/types';
import { LOGOUT_SUCCESS } from '../auth/types';
import { PhoneEnums } from '../phone/PhoneUtils';

const initialstate = {
  me: {
    vboxMessagesChanged: false,
    fetchVboxMessagesLoaded: true,
    fetchVboxMessagesError: null,
    byId: {},
    allIds: [],
    pages: {},
    currentPage: 0,
    total: 0,
    filter: {
      caller: '',
      start: '',
      end: '',
    },
    greeting: {
      message: null,
      loadedGreeting: false,
      savedGreeting: true,
      errorSavingGreeting: false,
      errorLoadingGreeting: false,
    },
    deleteVboxMessagesError: null,
    deleteVboxMessagesLoaded: true,
  },
  pbx: {
    telephonevox: {
      fetchTvoxSoundsLoaded: true,
      fetchTvoxSoundsError: null,
      fetchTvoxPriceLoaded: true,
      fetchTvoxPriceError: null,
      fetchTvoxPriceSuccess: null,
      purchaseTvoxMessageLoaded: true,
      purchaseTvoxMessageSuccess: null,
      purchaseTvoxMessageError: null,
      musicByGenre: {},
      sounds: [],
      price: null,
    },
    greetingsArchived: {
      fetchArchivedGreetingMessagesLoaded: true,
      fetchArchivedGreetingMessagesError: null,
      saveArchivedGreetingMessagesLoaded: true,
      saveArchivedGreetingMessagesError: null,
      deleteArchivedGreetingMessagesLoaded: true,
      deleteArchivedGreetingMessagesError: null,
      deletingId: null,
      total: 0,
      currentPage: 0,
      pagesGreetingMessages: {},
      byId: {},
      allIds: [],
    },
    greetingsActives: {
      activateArchivedGreetingMessagesLoaded: true,
      activateArchivedGreetingMessagesError: null,
      fetchActiveGreetingMessagesLoaded: true,
      fetchActiveGreetingMessagesError: null,
      data: {},
    },
    fetchVboxMessagesLoaded: true,
    fetchVboxMessagesError: null,
    vboxMessagesChanged: false,
    doneSearching: false,
    byId: {},
    allIds: [],
    pages: {},
    currentPage: 0,
    total: 0,
    filter: {
      caller: '',
      start: '',
      end: '',
      pbx: null,
    },
    deleteVboxMessagesError: null,
    deleteVboxMessagesLoaded: true,
    activeMessagesById: {},
    activeMessagesAllIds: [],
  },
};

export default function vbox(state = initialstate, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialstate;
    case FETCH_ME_SUCCESS: {
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            message: action.me.greeting,
          },
        },
      };
    }
    case LOAD_OWN_GREETING_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            message: action.greeting,
            loadedGreeting: true,
            errorLoadingGreeting: false,
          },
        },
      };
    case LOAD_OWN_GREETING_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            loadedGreeting: true,
            errorLoadingGreeting: true,
          },
        },
      };
    case SAVE_OWN_GREETING_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            savedGreeting: false,
            errorSavingGreeting: false,
          },
        },
      };
    case SAVE_OWN_GREETING_SUCCESS:
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            message:
              action.greeting.supplier ===
                PhoneEnums.PbxType.NETHESIS.toLowerCase() &&
              action.greeting.fileName,
            savedGreeting: true,
          },
        },
      };
    case SAVE_OWN_GREETING_FAILURE:
      return {
        ...state,
        me: {
          ...state.me,
          greeting: {
            ...state.me.greeting,
            savedGreeting: true,
            errorSavingGreeting: true,
          },
        },
      };
    case UPLOAD_FILES_REQUEST: {
      if (action.payload.scope === 'nethesis') {
        return {
          ...state,
          me: {
            ...state.me,
            greeting: {
              ...state.me.greeting,
              savedGreeting: false,
              errorSavingGreeting: false,
            },
          },
        };
      }
      return state;
    }
    case UPLOAD_FILES_SUCCESS: {
      if (action.payload.scope === 'nethesis') {
        return {
          ...state,
          me: {
            ...state.me,
            greeting: {
              ...state.me.greeting,
              savedGreeting: true,
              errorSavingGreeting: false,
            },
          },
        };
      }
      return state;
    }
    case UPLOAD_FILES_FAILURE: {
      if (action.payload.scope === 'nethesis') {
        return {
          ...state,
          me: {
            ...state.me,
            greeting: {
              ...state.me.greeting,
              savedGreeting: true,
              errorSavingGreeting: true,
            },
          },
        };
      }
      return state;
    }
    case FETCH_VBOX_MESSAGES_REQUEST: {
      let { me } = state;
      let { pbx } = state;
      if (action.payload.pbx) {
        pbx = {
          ...pbx,
          vboxMessagesChanged: false,
          fetchVboxMessagesLoaded: false,
          fetchVboxMessagesError: null,
          doneSearching: true,
          /*filter: {
            ...pbx.filter,
            pbx: action.payload.pbx,
          },*/
        };
      } else {
        me = {
          ...me,
          vboxMessagesChanged: false,
          fetchVboxMessagesLoaded: false,
          fetchVboxMessagesError: null,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }
    case FETCH_VBOX_MESSAGES_SUCCESS: {
      const ids = action.payload.messages.map((obj) => obj.id);
      const pages = {
        ...state.pages,
        [action.payload.page]: ids,
      };
      let { me, pbx } = state;
      if (action.payload.isPbx) {
        pbx = {
          ...pbx,
          fetchVboxMessagesLoaded: true,
          fetchVboxMessagesError: null,
          byId: keyBy(action.payload.messages, 'id'),
          allIds: action.payload.messages.map((obj) => obj.id),
          pages,
          currentPage: action.payload.page,
          total: action.payload.total,
        };
      } else {
        me = {
          ...me,
          fetchVboxMessagesLoaded: true,
          fetchVboxMessagesError: null,
          byId: keyBy(action.payload.messages, 'id'),
          allIds: action.payload.messages.map((obj) => obj.id),
          pages,
          currentPage: action.payload.page,
          total: action.payload.total,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }
    case FETCH_VBOX_MESSAGES_FAILURE: {
      let { me, pbx } = state;
      if (action.isPbx) {
        pbx = {
          ...pbx,
          fetchVboxMessagesLoaded: true,
          fetchVboxMessagesError: action.errors,
        };
      } else {
        me = {
          ...me,
          fetchVboxMessagesLoaded: true,
          fetchVboxMessagesError: action.errors,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }
    case DELETE_VBOX_MESSAGES_REQUEST: {
      let { me, pbx } = state;
      if (action.payload.isPbx) {
        pbx = {
          ...pbx,
          deleteVboxMessagesError: null,
          deleteVboxMessagesLoaded: false,
        };
      } else {
        me = {
          ...me,
          deleteVboxMessagesError: null,
          deleteVboxMessagesLoaded: false,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }
    case DELETE_VBOX_MESSAGES_SUCCESS: {
      let { me, pbx } = state;
      if (action.payload.isPbx) {
        const byId = { ...state.pbx.byId };
        const allIds = [...state.pbx.allIds];
        const currentPageIds = [...state.pbx.pages[state.pbx.currentPage]];
        delete byId[action.payload.id];
        pull(allIds, action.payload.id);
        pull(currentPageIds, action.payload.id);
        pbx = {
          ...pbx,
          byId,
          allIds,
          pages: {
            ...pbx.pages,
            [pbx.currentPage]: currentPageIds,
          },
          deleteVboxMessagesError: null,
          deleteVboxMessagesLoaded: true,
        };
      } else {
        const byId = { ...state.me.byId };
        const allIds = [...state.me.allIds];
        const currentPageIds = [...state.me.pages[state.me.currentPage]];
        delete byId[action.payload.id];
        pull(allIds, action.payload.id);
        pull(currentPageIds, action.payload.id);
        me = {
          ...me,
          byId,
          allIds,
          pages: {
            ...me.pages,
            [me.currentPage]: currentPageIds,
          },
          deleteVboxMessagesError: null,
          deleteVboxMessagesLoaded: true,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }
    case DELETE_VBOX_MESSAGES_FAILURE: {
      let { me, pbx } = state;
      if (action.isPbx) {
        pbx = {
          ...pbx,
          deleteVboxMessagesError: action.errors,
          deleteVboxMessagesLoaded: true,
        };
      } else {
        me = {
          ...me,
          deleteVboxMessagesError: action.errors,
          deleteVboxMessagesLoaded: true,
        };
      }
      return {
        ...state,
        me,
        pbx,
      };
    }

    case SET_VBOX_MESSAGES_FILTER: {
      let meFilter = state.me.filter;
      let pbxFilter = state.pbx.filter;
      if (action.isPbx) {
        pbxFilter = {
          ...pbxFilter,
          ...action.filter,
        };
      } else {
        meFilter = {
          ...meFilter,
          ...action.filter,
        };
      }
      return {
        ...state,
        me: {
          ...state.me,
          filter: meFilter,
        },
        pbx: {
          ...state.pbx,
          filter: pbxFilter,
        },
      };
    }
    case FETCH_TVOX_SOUNDS_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxSoundsLoaded: false,
            fetchTvoxSoundsError: null,
          },
        },
      };
    }
    case FETCH_TVOX_SOUNDS_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxSoundsLoaded: true,
            fetchTvoxSoundsError: null,
            sounds: action.payload.voices,
            musicByGenre: groupBy(action.payload.music, 'genre'),
          },
        },
      };
    case FETCH_TVOX_SOUNDS_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxSoundsLoaded: true,
            fetchTvoxSoundsError: action.errors,
          },
        },
      };
    case FETCH_TVOX_PRICE_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxPriceLoaded: false,
            fetchTvoxPriceError: null,
            fetchTvoxPriceSuccess: null,
            purchaseTvoxMessageError: null,
          },
        },
      };
    }
    case FETCH_TVOX_PRICE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxPriceLoaded: true,
            fetchTvoxPriceError: null,
            fetchTvoxPriceSuccess: action.success,
            price: action.payload.price,
          },
        },
      };
    case FETCH_TVOX_PRICE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            fetchTvoxPriceLoaded: true,
            fetchTvoxPriceSuccess: null,
            fetchTvoxPriceError: action.errors,
          },
        },
      };
    case PURCHASE_TVOX_MESSAGE_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            purchaseTvoxMessageLoaded: false,
            purchaseTvoxMessageError: null,
            purchaseTvoxMessageSuccess: null,
          },
        },
      };
    }
    case PURCHASE_TVOX_MESSAGE_SUCCESS:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            purchaseTvoxMessageLoaded: true,
            purchaseTvoxMessageError: null,
            purchaseTvoxMessageSuccess: action.success,
          },
        },
      };
    case PURCHASE_TVOX_MESSAGE_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          telephonevox: {
            ...state.pbx.telephonevox,
            purchaseTvoxMessageLoaded: true,
            purchaseTvoxMessageSuccess: null,
            purchaseTvoxMessageError: action.errors,
          },
        },
      };
    case FETCH_ARCHIVED_GREETING_MESSAGES_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            fetchArchivedGreetingMessagesLoaded: false,
            fetchArchivedGreetingMessagesError: null,
          },
        },
      };
    }
    case FETCH_ARCHIVED_GREETING_MESSAGES_SUCCESS: {
      const greetingMessagesIds = action.payload.greetingMessages.map(
        (obj) => obj.id
      );
      const pagesGreetingMessages = {
        ...state.pages,
        [action.payload.page]: greetingMessagesIds,
      };
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            fetchArchivedGreetingMessagesLoaded: true,
            fetchArchivedGreetingMessagesError: null,
            byId: keyBy(action.payload.greetingMessages, 'id'),
            allIds: action.payload.greetingMessages.map((obj) => obj.id),
            pagesGreetingMessages,
            currentPage: action.payload.page,
            total: action.payload.total,
          },
        },
      };
    }
    case FETCH_ARCHIVED_GREETING_MESSAGES_FAILURE:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            fetchArchivedGreetingMessagesLoaded: true,
            fetchArchivedGreetingMessagesError: action.errors,
          },
        },
      };
    case FETCH_ACTIVE_GREETING_MESSAGES_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            fetchActiveGreetingMessagesLoaded: false,
            fetchActiveGreetingMessagesError: null,
          },
        },
      };
    }
    case FETCH_ACTIVE_GREETING_MESSAGES_SUCCESS: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            fetchActiveGreetingMessagesLoaded: true,
            fetchActiveGreetingMessagesError: null,
            data: action.payload.data,
          },
        },
      };
    }
    case FETCH_ACTIVE_GREETING_MESSAGES_FAILURE: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            fetchActiveGreetingMessagesLoaded: true,
            fetchActiveGreetingMessagesError: action.error,
          },
        },
      };
    }
    case ACTIVATE_PBX_GREETING_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            activateArchivedGreetingMessagesLoaded: false,
            activateArchivedGreetingMessagesError: null,
          },
        },
      };
    }
    case ACTIVATE_PBX_GREETING_SUCCESS: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            activateArchivedGreetingMessagesLoaded: true,
            activateArchivedGreetingMessagesError: null,
            // data: action.payload.data,
          },
        },
      };
    }
    case ACTIVATE_PBX_GREETING_FAILURE: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsActives: {
            ...state.pbx.greetingsActives,
            activateArchivedGreetingMessagesLoaded: true,
            activateArchivedGreetingMessagesError: action.error,
          },
        },
      };
    }
    case DELETE_ARCHIVED_GREETING_MESSAGE_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            deleteArchivedGreetingMessagesLoaded: false,
            deleteArchivedGreetingMessagesError: null,
            deletingId: action.payload.id,
          },
        },
      };
    }
    case DELETE_ARCHIVED_GREETING_MESSAGE_SUCCESS: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            deleteArchivedGreetingMessagesLoaded: true,
            deleteArchivedGreetingMessagesError: null,
            deletingId: null,
          },
        },
      };
    }
    case DELETE_ARCHIVED_GREETING_MESSAGE_FAILURE: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            deleteArchivedGreetingMessagesLoaded: true,
            deleteArchivedGreetingMessagesError: action.error.status,
            deletingId: null,
          },
        },
      };
    }
    case SAVE_PBX_GREETING_REQUEST: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            saveArchivedGreetingMessagesLoaded: false,
            saveArchivedGreetingMessagesError: null,
          },
        },
      };
    }
    case SAVE_PBX_GREETING_SUCCESS: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            saveArchivedGreetingMessagesLoaded: true,
            saveArchivedGreetingMessagesError: null,
          },
        },
      };
    }
    case SAVE_PBX_GREETING_FAILURE: {
      return {
        ...state,
        pbx: {
          ...state.pbx,
          greetingsArchived: {
            ...state.pbx.greetingsArchived,
            saveArchivedGreetingMessagesLoaded: true,
            saveArchivedGreetingMessagesError: action.error,
          },
        },
      };
    }
    case LISTEN_VBOX_SUCCESS: {
      const context = action.isPbx ? 'pbx' : 'me';
      return {
        ...state,
        [context]: {
          ...state[context],
          byId: {
            ...state[context].byId,
            [action.vboxId]: {
              ...state[context].byId[action.vboxId],
              listened: moment().toDate(),
            },
          },
        },
      };
    }
    case ME_RECEIVED_VBOX_NOTIFICATION:
      return {
        ...state,
        me: {
          ...state.me,
          vboxMessagesChanged: !action.payload.archived
            ? true
            : state.me.vboxMessagesChanged,
        },
      };
    case PBX_RECEIVED_VBOX_NOTIFICATION:
      return {
        ...state,
        pbx: {
          ...state.pbx,
          vboxMessagesChanged: !action.payload.archived
            ? true
            : state.pbx.vboxMessagesChanged,
        },
      };
    case LOCATION_CHANGE:
      if (
        state.pbx.greetingsArchived.total &&
        action.payload.location.pathname === '/pbx/archived/list'
      )
        return state;
      return initialstate;
    default:
      return state;
  }
}
