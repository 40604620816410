import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import SelectMessageRecipientsBody from './SelectMessageRecipientsBody';

const messages = defineMessages({
  selectReceiver: {
    id: 'SelectMessageRecipientsModal.label.selectReceiver',
    defaultMessage: 'Select receiver',
  },
});

const SelectMessageRecipientsModal = ({
  isOpen,
  toggle,
  selectedMessage,
  message,
  onClose,
  intl: { formatMessage },
}) => (
  <Modal isOpen={isOpen}>
    <ModalHeader toggle={toggle}>
      {formatMessage(messages.selectReceiver)}
    </ModalHeader>
    <ModalBody>
      <SelectMessageRecipientsBody
        selectedMessage={selectedMessage}
        message={message}
        onClose={onClose}
      />
    </ModalBody>
  </Modal>
);

SelectMessageRecipientsModal.propTypes = {
  selectedMessage: PropTypes.object,
  message: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

SelectMessageRecipientsModal.defaultProps = {
  selectedMessage: {},
  message: {},
};

export default injectIntl(SelectMessageRecipientsModal);
