import { createSelector } from 'reselect';
import sortBy from 'lodash.sortby';
import findKey from 'lodash.findkey';

const defaultList = [];
const defaultObject = {};

export const getFetchContactsLoaded = (state) =>
  state.contacts.fetchContactsLoaded;
export const getFetchContactsError = (state) =>
  state.contacts.fetchContactsError;
export const getFetchContactsEnd = (state) => state.contacts.fetchContactsEnd;
export const getFetchContactLoaded = (state) =>
  state.contacts.fetchContactLoaded;
export const getFetchContactError = (state) => state.contacts.fetchContactError;
export const getSaveContactLoaded = (state) => state.contacts.saveContactLoaded;
export const getSaveContactError = (state) => state.contacts.saveContactError;
export const getSavedContactId = (state) => state.contacts.savedContactId;
export const getToBeFetched = (state) => state.contacts.toBeFetched;
const getContactsIds = (state) => state.contacts.listContacts.allIds;
export const getContactsById = (state) => state.contacts.listContacts.byId;
export const getContacts = (state) =>
  getContactsIds(state)
    .map((o) => getContactsById(state)[o])
    .filter((o) => o.fullname);
export const getContactsCurrentPage = (state) =>
  state.contacts.listContacts.currentPage;
export const getContactsCurrentInitial = (state) =>
  state.contacts.listContacts.currentInitial;
export const getContactById = (state, id) =>
  state.contacts.listContacts.byId[id] || defaultObject;
export const getContactsSelectOptions = createSelector(
  getContacts,
  (contacts) =>
    (contacts &&
      contacts.length > 0 &&
      sortBy(
        contacts
          .filter((contact) => contact.type !== 'U')
          .map((contact) => ({
            value: contact.id,
            label: contact.fullname,
          })),
        [(option) => option.label.toLowerCase()]
      )) ||
    defaultList
);
export const getContactsSelectOptionsWithVatFiscalCode = createSelector(
  getContacts,
  (contacts) =>
    (contacts &&
      contacts.length > 0 &&
      sortBy(
        contacts
          .filter((contact) => contact.type !== 'U')
          .map((contact) => ({
            value: contact.id,
            label: `${contact.fullname}${
              contact.vatFiscalCode ? ` - ${contact.vatFiscalCode}` : ''
            }`,
          })),
        [(option) => option.label.toLowerCase()]
      )) ||
    defaultList
);
export const getContactsFilter = (state) => state.contacts.filter;

export const getContactsGroupMembers = (state) =>
  state.groups.editGroup.data &&
  state.groups.editGroup.data.contacts &&
  state.groups.editGroup.data.contacts.map((contact) => {
    return {
      value: contact.id,
      label: contact.name ? contact.name : contact.surname,
    };
  });

export const getContactsSelectedOptions = createSelector(
  [getContactsSelectOptions, getContactsGroupMembers],
  (options, selected) =>
    options &&
    selected &&
    options.filter((option) => selected.indexOf(option.value) > -1)
);
export const isContactFavorite = (state, idContact) =>
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')] &&
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')].members &&
  state.groups.fetchMembers[findKey(state.groups.byId, 'favorites')].members
    .map((member) => member.type === 'A' && member.id)
    .indexOf(idContact) > -1;

export const getCachedContacts = (state) => state.contacts.cachedContacts;
export const getCachedContact = (state, id) =>
  state.contacts.cachedContacts[id];
export const getCities = (state) => state.contacts.cities.data;
export const getCitiesSearched = (state) => state.contacts.cities.searched;
export const areCitiesLoaded = (state) => !state.contacts.cities.loading;
export const getNations = (state) => state.contacts.nations.data;
export const getNationsSearched = (state) => state.contacts.nations.searched;
export const areNationsLoaded = (state) => !state.contacts.nations.loading;
export const getAbookInitialsLoaded = (state) => state.contacts.initials.loaded;
export const getAbookInitials = (state) => state.contacts.initials.data;
export const getAbookInitialsQuantity = (state) =>
  state.contacts.initials.data.length;
export const getAbookTags = (state) => state.contacts.tags.data;
export const getCompanies = (state) => state.contacts.companies.data;
export const getCompaniesSearched = (state) =>
  state.contacts.companies.searched;
export const areCompaniesLoaded = (state) => !state.contacts.companies.loading;
export const getAtecoCodeById = (state, id) =>
  state.contacts.atecoCodes.byId[id] || defaultObject;
export const getAtecoCodeByIds = (state) => state.contacts.atecoCodes.byId;
export const getAtecoCodeAllIds = (state) => state.contacts.atecoCodes.allIds;
export const getAtecoCodes = createSelector(
  [getAtecoCodeAllIds, getAtecoCodeByIds],
  (ids, atecoCodes) =>
    ids &&
    atecoCodes &&
    ids.map((id) => {
      return {
        value: id,
        label: `${atecoCodes[id].code} ${atecoCodes[id].description}`,
      };
    })
);
export const getAbookListsById = (state) => state.contacts.lists.byId;
export const getAbookListById = (state, id) =>
  state.contacts.lists.byId[id] || defaultObject;
export const getAbookListsIds = (state) => state.contacts.lists.allIds;
export const getFetchAbookListsLoaded = (state) =>
  state.contacts.fetchAbookListsLoaded;
export const getFetchAbookListsError = (state) =>
  state.contacts.fetchAbookListsError;
export const getFetchAbookListLoaded = (state) =>
  state.contacts.fetchAbookListLoaded;
export const getFetchAbookListError = (state) =>
  state.contacts.fetchAbookListError;
export const getSaveAbookListLoaded = (state) =>
  state.contacts.saveAbookListLoaded;
export const getSaveAbookListError = (state) =>
  state.contacts.saveAbookListError;
export const getSavedAbookListId = (state) => state.contacts.savedAbookListId;
export const getDeleteAbookListLoaded = (state) =>
  state.contacts.deleteAbookListLoaded;
export const getDeleteAbookListError = (state) =>
  state.contacts.deleteAbookListError;
export const isAbookContactInfoCallsLoaded = (state) =>
  state.contacts.contactInfo.callsLoaded;
export const isAbookContactInfoCallsError = (state) =>
  state.contacts.contactInfo.callsError;
export const getAbookContactInfoCalls = (state) =>
  state.contacts.contactInfo.calls;
export const isAbookContactInfoTicketsLoaded = (state) =>
  state.contacts.contactInfo.ticketsLoaded;
export const isAbookContactInfoTicketsError = (state) =>
  state.contacts.contactInfo.ticketsError;
export const getAbookContactInfoTickets = (state) =>
  state.contacts.contactInfo.tickets;
export const isAbookContactInfoEventsLoaded = (state) =>
  state.contacts.contactInfo.eventsLoaded;
export const isAbookContactInfoEventsError = (state) =>
  state.contacts.contactInfo.eventsError;
export const getAbookContactInfoEvents = (state) =>
  state.contacts.contactInfo.events;
export const isAbookContactInfoFaxesLoaded = (state) =>
  state.contacts.contactInfo.faxesLoaded;
export const isAbookContactInfoFaxesError = (state) =>
  state.contacts.contactInfo.faxesError;
export const getAbookContactInfoFaxes = (state) =>
  state.contacts.contactInfo.faxes;
export const getEmails = (state) => state.contacts.emails;
