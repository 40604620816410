import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
import PbxVbox from './PbxVbox';

const PbxVboxNotifications = React.lazy(() => import('./PbxVboxNotifications'));

const PbxVboxNotificationsPage = (props) => (
  <PbxVbox>
    <div className="text-right">
      <HelpButton fileName="notifications-fax" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PbxVboxNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PbxVbox>
);

export default PbxVboxNotificationsPage;
