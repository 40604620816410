import { createSelector } from 'reselect';
import findKey from 'lodash.findkey';
import { getUserById } from '../users/selectors';
import {
  retrieveMessageId,
  retrieveSenderId,
  retrieveInterlocutorIdFromConversationId,
  ChatEnums,
  MIN_CHARS_FOR_SEARCH,
  generateConversationId,
} from './ChatUtils';
import { getMeId, hasGrants } from '../me/selectors';

const defaultList = [];
const defaultObject = {};

export const isChatServiceLoading = (state) => !state.chat.inited;
export const getConversationsAllIds = (state) =>
  state.chat.conversationsFilter.archived
    ? state.chat.conversationAllIds
    : state.chat.conversationAllIds.filter(
        (id) =>
          state.chat.conversationsById[id] &&
          !state.chat.conversationsById[id].archived
      );
export const getConversationById = (state, id) =>
  state.chat.conversationsById[id];
export const getChatMessageById = (state, id) =>
  state.chat.messagesById[id] || defaultObject;
export const isChatConversationsLoaded = (state) =>
  state.chat.fetchConversationLoaded;
export const isChatConversationsError = (state) =>
  state.chat.fetchConversationError;
export const getConversations = createSelector(
  [getConversationsAllIds, getConversationById],
  (conversationsAllIds, conversationById) =>
    conversationsAllIds.map((id) => conversationById(id))
);
export const getLastMessageByConversation = (state, id) => {
  const conversation = getConversationById(state, id);
  if (!conversation) {
    return defaultObject;
  }
  const { messagesAllIds } = conversation;
  const lastMessageId =
    messagesAllIds && messagesAllIds.length > 0
      ? messagesAllIds[0].messages &&
        messagesAllIds[0].messages.length > 0 &&
        messagesAllIds[0].messages[0]
      : null;
  if (!lastMessageId) {
    return defaultObject;
  }
  return getChatMessageById(state, retrieveMessageId(lastMessageId));
};
export const getDayGroupsMessagesByConversation = (state, id) =>
  getConversationById(state, id)
    ? getConversationById(state, id).messagesAllIds
    : defaultList;
export const getInterlocutorName = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.userId) {
    return state.users.byId[interlocutor.userId]
      ? state.users.byId[interlocutor.userId].fullname
      : getConversationById(state, conversationId).fullname;
  }
  if (interlocutor.groupId) {
    return state.groups.byId[interlocutor.groupId]
      ? state.groups.byId[interlocutor.groupId].name
      : getConversationById(state, conversationId).fullname;
  }
  return null;
};
export const getInterlocutorAvatar = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.userId) {
    return state.users.byId[interlocutor.userId]
      ? state.users.byId[interlocutor.userId].avatar
      : null;
  }
  return null;
};
export const getInterlocutorColor = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.groupId) {
    return state.groups.byId[interlocutor.groupId]
      ? state.groups.byId[interlocutor.groupId].color
      : getConversationById(state, conversationId).color;
  }
  return null;
};
export const isMainGroupConversation = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.groupId) {
    return state.groups.byId[interlocutor.groupId]
      ? state.groups.byId[interlocutor.groupId].main
      : false;
  }
  return false;
};
export const isInterlocutorRemoved = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.groupId) {
    return (
      !state.groups.byId[interlocutor.groupId] ||
      !state.groups.byId[interlocutor.groupId].xmpp
    );
  }
  if (interlocutor.userId) {
    return !state.users.byId[interlocutor.userId];
  }
  return false;
};
export const getRemovedUserData = (state, userId) => {
  return state.chat.deletedUsersById[userId];
};
export const getUnreadMessagesByConversation = (state, id) =>
  getConversationById(state, id) ? getConversationById(state, id).toBeRead : 0;
export const getTotalUnreadMessages = (state) =>
  getConversationsAllIds(state).length > 0
    ? getConversationsAllIds(state)
        .map((conversationId) =>
          getUnreadMessagesByConversation(state, conversationId)
        )
        .reduce((a, b) => a + b)
    : 0;
export const getUnreadFetchedMessagesByConversation = (state, id) => {
  const dayGroups = getDayGroupsMessagesByConversation(state, id);
  let unreadMessages = 0;
  dayGroups.forEach((dayGroup) => {
    dayGroup.messages.forEach((messageId) => {
      if (
        retrieveSenderId(messageId) !== ChatEnums.ChatTopics.INFO &&
        retrieveSenderId(messageId) !== getMeId(state) &&
        !getChatMessageById(state, retrieveMessageId(messageId)).read
      ) {
        unreadMessages += 1;
      }
    });
  });
  return unreadMessages;
};
export const isChatConversationArchived = (state, conversationId) =>
  getConversationById(state, conversationId).archived;
export const getUnreadUnfetchedMessagesByConversation = (state, id) =>
  getUnreadMessagesByConversation(state, id) -
  getUnreadFetchedMessagesByConversation(state, id);
export const getBigConversationId = (state) => state.chat.bigConversation;
export const getOpenConversationsIds = (state) =>
  state.chat.openConversationsIds;
export const getMaxOpenChatWindows = (state) => state.chat.maxOpenChatWindows;
export const isChatConversationHistoryLoaded = (state, conversationId) =>
  getConversationById(state, conversationId) &&
  getConversationById(state, conversationId).fetchHistoryLoaded;
export const isChatConversationHistoryError = (state, conversationId) =>
  getConversationById(state, conversationId) &&
  getConversationById(state, conversationId).fetchHistoryError;
export const isChatConversationHistoryCompleted = (state, conversationId) =>
  getConversationById(state, conversationId) &&
  getConversationById(state, conversationId).fetchHistoryCompleted;
export const isChatConversationFirstMessagesLoaded = (state, conversationId) =>
  getConversationById(state, conversationId) &&
  getConversationById(state, conversationId).fetchRecentHistoryCompleted;
export const getChatMessageSender = (state, messageId) =>
  getChatMessageById(state, messageId)
    ? getUserById(state, getChatMessageById(state, messageId).senderId)
    : null;
export const isFirstHistoryLoaded = (state, conversationId) =>
  getConversationById(state, conversationId).firstHistoryLoaded;
export const isFetchRecentHistoryLoaded = (state, conversationId) =>
  getConversationById(state, conversationId).fetchRecentHistoryLoaded;
export const isFetchOldHistoryLoaded = (state, conversationId) =>
  getConversationById(state, conversationId).fetchOldHistoryLoaded;
export const isChatConversationSendingError = (state, conversationId) =>
  getConversationById(state, conversationId).sendMessageError;
export const isChatConversationReadAllError = (state, conversationId) =>
  getConversationById(state, conversationId).readAllError;
export const isChatConversationDeleteAllError = (state, conversationId) =>
  getConversationById(state, conversationId).deleteAllError;
export const isChatConversationDeleteMessagesError = (state, conversationId) =>
  getConversationById(state, conversationId).deleteMessagesError;
export const isChatConversationRenameGroupError = (state, conversationId) =>
  getConversationById(state, conversationId).updateChatGroupError;
export const isChatConversationDeleteGroupError = (state, conversationId) =>
  getConversationById(state, conversationId).deleteGroupError;
export const getConversationsListFilter = (state) =>
  state.chat.conversationsFilter;
export const isChatTextSearchActive = (state) =>
  state.chat.conversationsFilter.text &&
  state.chat.conversationsFilter.text.length >= MIN_CHARS_FOR_SEARCH;
export const getComposingUsersNames = (state, conversationId) => {
  const conversation = getConversationById(state, conversationId);
  const users = [];
  conversation.composing.forEach((composingUser) => {
    const user = getUserById(state, composingUser);
    if (user) {
      users.push(user.name || user.surname);
    }
  });
  return users.join(', ');
};
export const getComposingUsers = (state, conversationId) => {
  const conversation = getConversationById(state, conversationId);
  return conversation.composing;
};
export const isConversationArchived = (state, conversationId) =>
  getConversationById(state, conversationId).archived;
export const getActiveConversation = (state) => state.chat.activeConversation;
export const isConversationFilesLoaded = (state) =>
  state.chat.fetchFiles.loaded;
export const isConversationFilesError = (state) => state.chat.fetchFiles.error;
export const getConversationFiles = (state) => state.chat.fetchFiles.files;
export const getConversationFilesTotal = (state) => state.chat.fetchFiles.total;
export const getConversationFilesCurrentPage = (state) =>
  state.chat.fetchFiles.currentPage;
export const isConversationOperationLoading = (state, conversationId) =>
  getConversationById(state, conversationId).operationLoading;
export const isInterlocutorGroupEditable = (state, conversationId) => {
  const interlocutor = retrieveInterlocutorIdFromConversationId(conversationId);
  if (interlocutor.groupId) {
    const group = state.groups.byId[interlocutor.groupId];
    if (!group) {
      return false;
    }
    return (
      (group.private && group.mine) ||
      (!group.private && hasGrants(state, 'GROUP_PUBLIC'))
    );
  }
  return false;
};
export const isChatTextSearchLoaded = (state) => state.chat.textSearch.loaded;
export const isChatTextSearchError = (state) => state.chat.textSearch.error;
export const isChatTextSearchOtherLoaded = (state) =>
  state.chat.textSearch.otherLoaded;
export const isChatTextSearchOtherError = (state) =>
  state.chat.textSearch.otherError;
export const getChatTextResults = (state) => state.chat.textSearch.results;
export const getCreateChatGroupLoaded = (state) =>
  state.chat.createChatGroupLoaded;
export const getCreateChatGroupError = (state) =>
  state.chat.createChatGroupError;
export const existsOtherChatTextResults = (state) =>
  !state.chat.textSearch.completed;
export const isSearchedChatMessage = (state, messageId) =>
  state.chat.searchedMessage && state.chat.searchedMessage === messageId;
export const canScrollToBottom = (state, conversationId) =>
  getConversationById(state, conversationId).scrollToBottom;
export const getMainGroupConversationId = (state) =>
  generateConversationId({ groupId: findKey(state.groups.byId, 'main') });
export const isMainChat = (state, groupId) =>
  state.groups.byId[groupId] ? state.groups.byId[groupId].main : false;
// export const getConversationImportantMessages = (state, conversationId) => {
//   return getConversationById(state, conversationId).messagesAllIds.map(obj => {
//     return {
//       ...obj,
//       messages: obj.messages
//         .filter(message => {
//           return (
//             getChatMessageById(state, retrieveMessageId(message)).important ||
//             true
//           );
//         })
//         .map(message => {
//           return retrieveMessageId(message);
//         }),
//     };
//   });
// };
export const getConversationImportantMessages = (state) =>
  state.chat.importantMessages;
export const getRepliedMessage = (state, conversationId) =>
  state.chat.conversationsById[conversationId].repliedMessageId;

export const getScheduledMessagesById = (state) =>
  state.chat.scheduledMessagesById;
export const getScheduledMessageById = (state, id) =>
  state.chat.scheduledMessagesById[id];
export const getScheduledMessagesIds = (state) =>
  state.chat.scheduledMessagesAllIds;
// export const getScheduledMessages = createSelector(
//   [getScheduledMessagesById, getScheduledMessagesIds],
//   (pById, pIds) => pIds.map(o => pById[o])
// );
export const getSaveScheduledMessageLoaded = (state) =>
  state.chat.saveScheduledMessageLoaded;
export const getSaveScheduledMessageError = (state) =>
  state.chat.saveScheduledMessageError;
export const getSaveScheduledMessageSuccess = (state) =>
  state.chat.saveScheduledMessageSuccess;
export const getScheduledMessagesLoaded = (state) =>
  state.chat.scheduledMessagesLoaded;
export const getScheduledMessagesError = (state) =>
  state.chat.scheduledMessagesError;
export const getDeleteScheduledMessageError = (state) =>
  state.chat.deleteScheduledMessageError;
export const getChatMessagesById = (state) => state.chat.messagesById;
export const getImportantMessagesLoading = (state) =>
  state.chat.importantMessagesLoading;
export const getImportantMessagesError = (state) =>
  state.chat.importantMessagesError;
export const isChatConversationMarkAsImportantError = (state) =>
  state.chat.isMarkAsImportantError;
export const isChatConversationUnmarkAsImportantError = (state) =>
  state.chat.isUnmarkAsImportantError;
export const getMutedChats = (state) => state.chat.mutedChats;
export const getMuteChatError = (state, conversationId) =>
  getConversationById(state, conversationId) &&
  getConversationById(state, conversationId).muteUnmuteChatError;
