import { request } from '../request';

export default {
  getAssociations: () =>
    request({
      url: '/associations',
      method: 'GET',
    }),
  deleteAssociation: (id) =>
    request({
      url: `/associations/${id}`,
      method: 'DELETE',
    }),
  deleteMember: (params) =>
    request({
      url: `/associations/${params.associationId}/members/${params.customerId}`,
      method: 'DELETE',
    }),
  editMember: (params) =>
    request({
      url: `/associations/${params.associationId}/members/${params.customerId}`,
      method: 'POST',
      data: params.data,
    }),
  getMemberDetails: (params) =>
    request({
      url: `/associations/${params.associationId}/members/${params.customerId}`,
      method: 'GET',
    }),
};
