import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_PAYMENTS_CREDITS_REQUEST,
  FETCH_PAYMENTS_CREDITS_SUCCESS,
  FETCH_PAYMENTS_CREDITS_FAILURE,
  UPDATE_PAYMENTS_EMAILS_REQUEST,
  UPDATE_PAYMENTS_EMAILS_SUCCESS,
  UPDATE_PAYMENTS_EMAILS_FAILURE,
  /** Transactions */
  FETCH_PURCHASES_REQUEST,
  FETCH_PURCHASES_SUCCESS,
  FETCH_PURCHASES_FAILURE,
  FETCH_ALL_PURCHASES_REQUEST,
  FETCH_ALL_PURCHASES_SUCCESS,
  FETCH_ALL_PURCHASES_FAILURE,
  /** PayPal */
  PAYPAL_PAY_REQUEST,
  PAYPAL_PAY_SUCCESS,
  PAYPAL_PAY_FAILURE,
  /** Denominations */
  FETCH_DENOMINATIONS_REQUEST,
  FETCH_DENOMINATIONS_SUCCESS,
  FETCH_DENOMINATIONS_FAILURE,
} from './types';

const initialErrorState = {
  error: '',
  success: '',
  loaded: true,
};

const initialState = {
  paymentsCredits: {
    error: '',
    success: '',
    loaded: true,
    credits: [],
  },
  paymentsEmails: {
    error: '',
    success: '',
    loaded: true,
  },
  purchases: {
    error: '',
    success: '',
    loaded: true,
    transactions: [],
    currentPage: 0,
    total: null,
  },
  allPurchases: {
    error: '',
    success: '',
    loaded: true,
    allPurchases: [],
    currentPage: 0,
  },
  payPalPayment: {
    error: '',
    loaded: true,
    paypalUrl: '',
  },
  allDenominations: {
    error: '',
    success: '',
    loaded: true,
    denominations: [],
  },
};

export default function payments(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_PAYMENTS_CREDITS_REQUEST: {
      return {
        ...state,
        paymentsCredits: {
          error: '',
          success: '',
          loaded: false,
          credits: [],
        },
      };
    }
    case FETCH_PAYMENTS_CREDITS_SUCCESS:
      return {
        ...state,
        paymentsCredits: {
          error: '',
          success: '',
          loaded: true,
          credits: action.credits,
        },
      };
    case FETCH_PAYMENTS_CREDITS_FAILURE:
      return {
        ...state,
        paymentsCredits: {
          error: '',
          success: '',
          loaded: true,
          credits: [],
        },
      };
    case UPDATE_PAYMENTS_EMAILS_REQUEST: {
      return {
        ...state,
        paymentsEmails: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    }
    case UPDATE_PAYMENTS_EMAILS_SUCCESS:
      return {
        ...state,
        paymentsEmails: {
          error: '',
          success: action.success,
          loaded: true,
        },
      };
    case UPDATE_PAYMENTS_EMAILS_FAILURE:
      return {
        ...state,
        paymentsEmails: {
          error: action.errors,
          success: '',
          loaded: true,
        },
      };
    /** Transactions */
    case FETCH_PURCHASES_REQUEST: {
      return {
        ...state,
        purchases: {
          ...state.purchases,
          error: '',
          success: '',
          loaded: false,
        },
      };
    }
    case FETCH_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: {
          error: '',
          success: '',
          loaded: true,
          transactions: action.payload.purchases,
          currentPage: action.payload.page,
          total: action.payload.total,
        },
      };
    case FETCH_PURCHASES_FAILURE:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          error: '',
          success: '',
          loaded: true,
        },
      };
    case FETCH_ALL_PURCHASES_REQUEST: {
      return {
        ...state,
        allPurchases: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    }
    case FETCH_ALL_PURCHASES_SUCCESS:
      return {
        ...state,
        allPurchases: {
          error: '',
          success: '',
          loaded: true,
          allPurchases: action.payload,
        },
      };
    case FETCH_ALL_PURCHASES_FAILURE:
      return {
        ...state,
        allPurchases: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    /** PayPal */
    case PAYPAL_PAY_REQUEST: {
      return {
        ...state,
        payPalPayment: {
          error: '',
          loaded: false,
        },
      };
    }
    case PAYPAL_PAY_SUCCESS:
      return {
        ...state,
        payPalPayment: {
          error: '',
          loaded: true,
          paypalUrl: action.payload,
        },
      };
    case PAYPAL_PAY_FAILURE:
      return {
        ...state,
        payPalPayment: {
          error: action.errors,
          loaded: true,
        },
      };
    /** Denominations */
    case FETCH_DENOMINATIONS_REQUEST: {
      return {
        ...state,
        allDenominations: {
          error: '',
          success: '',
          loaded: false,
          denominations: [],
        },
      };
    }
    case FETCH_DENOMINATIONS_SUCCESS:
      return {
        ...state,
        allDenominations: {
          error: '',
          success: '',
          loaded: true,
          denominations: action.denominations,
        },
      };
    case FETCH_DENOMINATIONS_FAILURE:
      return {
        ...state,
        allDenominations: {
          error: '',
          success: '',
          loaded: true,
          denominations: [],
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        paymentsEmails: initialErrorState,
      };
    default:
      return state;
  }
}
