import React from 'react';
import PropTypes from 'prop-types';

export const treeStyle = {
  width: '300px',
  height: '300px',
  maxFontSize: 26,
  menuStyle: {
    position: 'absolute',
    top: '82px',
    left: '67px',
    width: '58%',
    height: '0',
    borderBottom: '25px solid rgba(76, 175, 80, 0.1)',
    borderLeft: '19px solid transparent',
    borderRight: '20px solid transparent',
    marginBottom: '0',
    padding: '4px 4px 0 42px',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '111px',
    left: '91px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '55%',
    height: '43%',
    padding: '6px',
    top: '131px',
    left: '73px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '267px',
    left: '125px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '265px',
    left: '159px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '40px',
    left: '145px',
  },
};

export const TreeShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 195 198">
      <g transform="translate(0,-852.36218)">
        <path
          filter={`url(#sticky-filter-${id})`}
          style={{ fill: `${color}`, padding: '10px' }}
          d="m 100.44221,861.25721 c -0.586404,-0.003 -1.156547,0.26282 -1.608011,0.75421 l -54.18995,58.9619 c -0.90294,0.98279 -0.894417,2.55697 0,3.54925 l 17.728314,0.0443 -39.275652,42.85718 c -1.254679,1.36977 -1.242834,3.58598 0,4.96895 l 22.954347,0.0443 -39.1550526,42.8128 c -1.5189508,1.6605 -1.5046138,4.3127 0,5.9893 l 67.4560356,0.09 0,20.0089 c 0,1.1799 0.860607,2.1297 1.929612,2.1297 l 47.436307,0 c 1.06901,0 1.92962,-0.9498 1.92962,-2.1297 l 0,-19.9201 67.45603,0.089 c 1.51893,-1.6607 1.5046,-4.3574 0,-6.0337 l -38.51186,-42.90141 22.31115,0.0443 c 1.25466,-1.36988 1.24283,-3.58612 0,-4.96896 l -38.59226,-42.99027 17.04492,0.0443 c 0.90287,-0.98286 0.89441,-2.60143 0,-3.5936 l -53.26534,-59.09574 c -0.44721,-0.49607 -1.0618,-0.75115 -1.64821,-0.7542 z"
        />
        <defs>
          <filter
            id={`sticky-filter-${id}`}
            x="-1px"
            y="-1px"
            width="210%"
            height="210%"
          >
            <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
            <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
      </g>
    </svg>
  </>
);

TreeShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
