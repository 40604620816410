import React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from '../../../components/ErrorBoundary';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import Puncher from '.';
import { isMyPuncherEnabled } from '../../../js/me/selectors';

const PersonalPuncherList = React.lazy(() => import('./PersonalPuncherList'));

const PersonalPuncherPage = ({ puncherEnabled }) => (
  <Puncher>
    {puncherEnabled ? (
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <PersonalPuncherList />
        </React.Suspense>
      </ErrorBoundary>
    ) : (
      <GlobalErrorPage code={403} />
    )}
  </Puncher>
);

function mapStateToProps(state) {
  return {
    puncherEnabled: isPuncherEnabled(state) && isMyPuncherEnabled(state),
  };
}

export default connect(mapStateToProps)(PersonalPuncherPage);
