import React from 'react';
import PropTypes from 'prop-types';

const ColorBadge = ({ color, gradient, selected, width, height }) => (
  <span
    className="d-inline-block rounded-circle"
    style={{
      width,
      height,
      border: '1px solid var(--yn-gray-500)',
      background: `${gradient ? null : color}`,
      'background-image': `${
        gradient
          ? `linear-gradient(to bottom right, rgba(255,255,255,1), ${color}, rgba(255,255,255,1))`
          : null
      }`,
      boxShadow: selected && 'var(--yn-input-focus-box-shadow)',
    }}
  />
);

ColorBadge.propTypes = {
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};
ColorBadge.defaultProps = {
  selected: false,
  height: '1.7em',
  width: '1.7em',
};

export default ColorBadge;
