import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Filebox from './index';

const FileboxSettings = React.lazy(() => import('./FileboxSettings'));
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const FileboxSettingsPage = (props) => (
  <Filebox>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="settings-filebox" />
          </div>
          <FileboxSettings {...props} />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Filebox>
);

export default FileboxSettingsPage;
