import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import anime from 'animejs/lib/anime.es.js';
import IconDropdown from '../../../components/icons/IconDropdown';
import NotificationBadge from '../../../components/NotificationBadge';
import MenuIcon from '../../../components/icons/MenuIcon';
import {
  addStickyNotesRequest,
  updateStickyVisibilityRequest,
} from '../../../js/sticky/actions';
import { isPhoneAvailable, getSupplier } from '../../../js/phone/selectors';
import { getStickyNotes } from '../../../js/sticky/selectors';
import {
  isFaxActive,
  isFaxSettingsFetchLoaded,
  getFaxNumbers,
} from '../../../js/fax/selectors';
import {
  addressbookGrant,
  isHelpVisible,
  isMePunchingsValidator,
  isMeVacationsValidator,
  isTicketingEnabled,
} from '../../../js/me/selectors';
import { toggleShowHelp } from '../../../js/me/actions';
import Icon from '../../../components/icons/Icon';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import { isVacationEnabled } from '../../../js/calendar/selectors';
import { getPlatformLanguage } from '../../../js/settings/selectors';
import ynConf from '../../../conf';
import Popover from '../../../components/Popover';
import {
  getAssociationsListIsAdmin,
  getAssociationsListIsNotAdmin,
} from '../../../js/associations/selectors';

const messages = defineMessages({
  calls: {
    id: 'MainNav.calls.',
    defaultMessage: 'Calls',
  },
  videocall: {
    id: 'MainNav.videocall.',
    defaultMessage: 'Video call',
  },
  messages: {
    id: 'MainNav.messages.',
    defaultMessage: 'Messages',
  },
  sms: {
    id: 'MainNav.sms',
    defaultMessage: 'SMS',
  },
  calendar: {
    id: 'MainNav.calendar.',
    defaultMessage: 'Calendar',
  },
  abook: {
    id: 'MainNav.abook.',
    defaultMessage: 'Address book',
  },
  phone: {
    id: 'MainNav.phone.',
    defaultMessage: 'Phone',
  },
  callsLog: {
    id: 'MainNav.callsLog.label',
    defaultMessage: 'Calls log',
  },
  settings: {
    id: 'MainNav.settings.label',
    defaultMessage: 'Settings',
  },
  vbox: {
    id: 'MainNav.vbox.label',
    defaultMessage: 'Vocal box',
  },
  hotel: {
    id: 'MainNav.hotel.label',
    defaultMessage: 'Hotel',
  },
  fax: {
    id: 'MainNav.fax.label',
    defaultMessage: 'Fax',
  },
  wiki: {
    id: 'MainNav.wiki.label',
    defaultMessage: 'Wiki',
  },
  documents: {
    id: 'MainNav.documents.label',
    defaultMessage: 'Documents',
  },
  pbx: {
    id: 'MainNav.pbx.label',
    defaultMessage: 'Switchboard',
  },
  groups: {
    id: 'MainNav.groups.label',
    defaultMessage: 'Groups and users',
  },
  groupsAndAssociations: {
    id: 'MainNav.groupsAndAssociations.label',
    defaultMessage: 'Groups, users and associations',
  },
  grants: {
    id: 'MainNav.grants.label',
    defaultMessage: 'Grants and permissions',
  },
  logo: {
    id: 'MainNav.logo.label',
    defaultMessage: 'Logo',
  },
  credits: {
    id: 'MainNav.credits.label',
    defaultMessage: 'Credits',
  },
  admin: {
    id: 'MainNav.admin.label',
    defaultMessage: 'Admin',
  },
  notes: {
    id: 'MainNav.notes.label',
    defaultMessage: 'Notes',
  },
  staticNotes: {
    id: 'MainNav.staticNotes.label',
    defaultMessage: 'Static notes',
  },
  stickyShow: {
    id: 'MainNav.stickyShow.label',
    defaultMessage: 'Show sticky',
  },
  stickyHide: {
    id: 'MainNav.stickyHide.label',
    defaultMessage: 'Hide sticky',
  },
  stickyNew: {
    id: 'MainNav.stickyNew.label',
    defaultMessage: 'New sticky',
  },
  payments: {
    id: 'MainNav.payments.label',
    defaultMessage: 'Payments',
  },
  wakeups: {
    id: 'MainNav.wakeups.label',
    defaultMessage: 'Wakeups',
  },
  hotelCallsLogs: {
    id: 'MainNav.hotelCallsLogs.label',
    defaultMessage: 'Calls Log',
  },
  costSettings: {
    id: 'MainNav.costSettings.label',
    defaultMessage: 'Costs Settings',
  },
  mailoffice: {
    id: 'MainNav.mailoffice.label',
    defaultMessage: 'Postal services',
  },
  send: {
    id: 'MainNav.send.label',
    defaultMessage: 'Send',
  },
  archive: {
    id: 'MainNav.archive.label',
    defaultMessage: 'Archive',
  },
  showHelp: {
    id: 'MainNav.showHelp.label',
    defaultMessage: 'Show help',
  },
  hideHelp: {
    id: 'MainNav.hideHelp.label',
    defaultMessage: 'Hide help',
  },
  helpText1: {
    id: 'MainNav.helpText1',
    defaultMessage: 'Click on ',
  },
  helpText2: {
    id: 'MainNav.helpText2',
    defaultMessage: ' to discover all Youneed features',
  },
  validations: {
    id: 'MainNav.validations.label',
    defaultMessage: 'HR validations',
  },
  presencesManagement: {
    id: 'MainNav.presencesManagement.label',
    defaultMessage: 'Presences management',
  },
  helpText3: {
    id: 'MainNav.helpText3',
    defaultMessage: ' in .pdf format',
  },
  helpPdf: {
    id: 'MainNav.helpPdf',
    defaultMessage: 'manual',
  },
  tickets: {
    id: 'MainNav.tickets',
    defaultMessage: 'Tickets',
  },
  helpFilenameNETHESIS: {
    id: 'MainNav.helpFilenameNETHESIS',
    defaultMessage: 'help-cloud.pdf',
  },
  helpFilenameABILIS: {
    id: 'MainNav.helpFilenameABILIS',
    defaultMessage: 'help.pdf',
  },
  filebox: {
    id: 'MainNav.filebox.label',
    defaultMessage: 'File box',
  },
});

class MainNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyVisibility: !this.props.stickyShow,
      helpPopOverVisible: false,
    };
  }

  componentDidUpdate(prevprops) {
    if (this.props.stickyShow !== prevprops.stickyShow) {
      this.setState({
        stickyVisibility: !this.props.stickyShow,
      });
    }
  }

  toggleHelp = () => {
    this.props.toggleHelp();
    if (!this.props.helpVisible) {
      this.setState({ helpPopOverVisible: true });
    }
  };

  toggleHelpPopover = () => {
    this.setState({ helpPopOverVisible: !this.state.helpPopOverVisible });
  };

  render() {
    const {
      calendarWebNotifications,
      callsWebNotifications,
      chatWebNotifications,
      contactsWebNotifications,
      faxWebNotifications,
      groupsWebNotifications,
      mailofficeWebNotifications,
      vboxMeWebNotifications,
      vboxPbxWebNotifications,
      stickyWebNotifications,
      validationWebNotifications,
      ticketWebNotifications,
      fileboxWebNotifications,
      videocallWebNotifications,
      hasPhone,
      grants,
      isHotel,
      isFaxActive,
      isPunchingsValidator,
      isVacationsValidator,
      puncherEnabled,
      supplier,
      location,
      helpVisible,
      ticketingEnabled,
      language,
      associationsLength,
      intl: { formatMessage },
      contactGrant,
    } = this.props;
    const activeSection = location.pathname;
    const hotelGrants = grants.indexOf('CDR_ENABLED') > -1;
    const wakeupGrants = grants.indexOf('WAKEUP_ENABLED') > -1;
    const showHotel = isHotel && (hotelGrants || wakeupGrants);
    const mailofficeGrants =
      grants.indexOf('MAILOFFICE_VIEW_ALL') > -1 ||
      grants.indexOf("'MAILOFFICE_WRITE_ALL") > -1 ||
      grants.indexOf('MAILOFFICE_SEND') > -1;
    const pbxCallsGrants = grants.indexOf('PBX_VIEW_CDR') > -1;
    const pbxManagementGrants =
      grants.indexOf('PBX_HOLIDAYS_WRITE') > -1 ||
      grants.indexOf('PBX_HOLIDAYS_READ') > -1;
    const pbxAdminTvoxGrants = grants.indexOf('BACKEND_TVOX') > -1;
    const logoManagementGrant = grants.indexOf('PLATFORM_LOGO_MANAGEMENT') > -1;
    const vboxGrants = grants.indexOf('VBOX_ENABLED') > -1;
    const showDocuments = grants.indexOf('CUSTOMER_ENABLED') > -1;
    const wikiGrants = grants.indexOf('WIKI_ENABLED') > -1;
    const creditsGrants =
      grants.indexOf('PAYMENTS_VIEW') > -1 ||
      grants.indexOf('PAYMENTS_PURCHASE') > -1;
    const usersProfilesGrants = grants.indexOf('USERS_PROFILES') > -1;
    const managePresencesGrants = grants.indexOf('MANAGE_PRESENCES') > -1;
    const manageVisitorsGrants = grants.indexOf('MANAGE_GUEST_PRESENCES') > -1;
    const ticketsGrants =
      grants.indexOf('MANAGE_OWN_TICKETS') > -1 ||
      grants.indexOf('MANAGE_ALL_TICKETS') > -1 ||
      grants.indexOf('MANAGE_ASSOCIATION_TICKETS') > -1 ||
      grants.indexOf('MANAGE_ADMIN_TICKETS') > -1;
    const hasManageAdminTicketsGrant =
      grants.indexOf('MANAGE_ADMIN_TICKETS') > -1;
    const smsGrants =
      grants.indexOf('SMS_MANAGE') > -1 || grants.indexOf('SMS_SEND') > -1;
    const hasAssociationsGrant = grants.indexOf('MANAGE_ASSOCIATIONS') > -1;

    const isActive = (path) => {
      return activeSection.indexOf(path) === 0;
    };
    return (
      <Nav className="mr-auto yn-main-nav" navbar>
        {hasPhone && (
          <NavItem>
            <NavLink tag={Link} to="/calls">
              <MenuIcon
                name="phone-1"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/calls')}
              >
                {formatMessage(messages.calls)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            tag={Link}
            to="/videocall/exec"
            className="position-relative"
          >
            {videocallWebNotifications > 0 && (
              <NotificationBadge
                number={videocallWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <MenuIcon
              name="video-camera"
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/videocall')}
            >
              {formatMessage(messages.videocall)}
            </MenuIcon>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/chat/messages" className="position-relative">
            {chatWebNotifications > 0 && (
              <NotificationBadge
                number={chatWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <MenuIcon
              name="chat-double-bubble-3"
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/chat')}
            >
              {formatMessage(messages.messages)}
            </MenuIcon>
          </NavLink>
        </NavItem>
        {smsGrants && (
          <NavItem>
            <NavLink tag={Link} to="/sms/sent" className="position-relative">
              <MenuIcon
                name="mobile-phone-text-message-1"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/sms')}
              >
                {formatMessage(messages.sms)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        {contactGrant && (
          <NavItem>
            <NavLink
              tag={Link}
              to="/abook/contacts"
              className="position-relative"
            >
              {contactsWebNotifications > 0 && (
                <NotificationBadge
                  number={contactsWebNotifications}
                  top="0.3rem"
                  right="0.2rem"
                />
              )}
              <MenuIcon
                name="book-address"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/abook')}
              >
                {formatMessage(messages.abook)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        {hasPhone && (
          <NavItem className="position-relative">
            {(callsWebNotifications > 0 || vboxMeWebNotifications > 0) && (
              <NotificationBadge
                number={callsWebNotifications + vboxMeWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <IconDropdown
              name="phone-call-list"
              menu={[
                {
                  label: formatMessage(messages.callsLog),
                  path: '/phone/log/lost',
                  notifications: callsWebNotifications,
                  show: true,
                },
                {
                  label: formatMessage(messages.settings),
                  path: '/phone/settings',
                  show: true,
                },
                {
                  label: formatMessage(messages.vbox),
                  path: '/phone/vbox/list',
                  notifications: vboxMeWebNotifications,
                  show: vboxGrants,
                },
              ]}
              color="var(--yn-blue-dark)"
              filled={isActive('/phone')}
            >
              {formatMessage(messages.phone)}
            </IconDropdown>
          </NavItem>
        )}
        <NavItem>
          <NavLink tag={Link} to="/calendar/show" className="position-relative">
            {calendarWebNotifications > 0 && (
              <NotificationBadge
                number={calendarWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <MenuIcon
              name="calendar-2"
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/calendar')}
            >
              {formatMessage(messages.calendar)}
            </MenuIcon>
          </NavLink>
        </NavItem>
        {mailofficeGrants && (
          <NavItem>
            <NavLink
              tag={Link}
              to="/mailoffice/sent"
              className="position-relative"
            >
              {mailofficeWebNotifications > 0 && (
                <NotificationBadge
                  number={mailofficeWebNotifications}
                  top="0.3rem"
                  right="0.2rem"
                />
              )}
              <MenuIcon
                name="logo-pt"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/mailoffice')}
              >
                {formatMessage(messages.mailoffice)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink tag={Link} to="/filebox/list" className="position-relative">
            {fileboxWebNotifications > 0 && (
              <NotificationBadge
                number={fileboxWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <MenuIcon
              name="box-1"
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/filebox')}
            >
              {formatMessage(messages.filebox)}
            </MenuIcon>
          </NavLink>
        </NavItem>
        {showHotel && (
          <NavItem>
            <IconDropdown
              name="luggage-key-1"
              menu={[
                {
                  label: formatMessage(messages.wakeups),
                  path: '/hotel/wakeup/scheduling',
                  show: wakeupGrants,
                },
                {
                  label: formatMessage(messages.hotelCallsLogs),
                  path: '/hotel/calls',
                  show: hotelGrants,
                },
                {
                  label: formatMessage(messages.costSettings),
                  path: '/hotel/costs',
                  show: hotelGrants,
                },
              ]}
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/hotel')}
            >
              {formatMessage(messages.hotel)}
            </IconDropdown>
          </NavItem>
        )}
        {isFaxActive && (
          <NavItem>
            <NavLink tag={Link} to="/fax/send" className="position-relative">
              {faxWebNotifications > 0 && (
                <NotificationBadge
                  number={faxWebNotifications}
                  top="0.3rem"
                  right="0.2rem"
                />
              )}
              <MenuIcon
                name="fax-machine"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/fax')}
              >
                {formatMessage(messages.fax)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        {wikiGrants && (
          <NavItem>
            <NavLink tag={Link} to="/wiki">
              <MenuIcon
                name="logo-circle-wikipedia"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/wiki')}
              >
                {formatMessage(messages.wiki)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        {ticketsGrants && ticketingEnabled && (
          <NavItem className="position-relative">
            {ticketWebNotifications > 0 && (
              <NotificationBadge
                number={ticketWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <NavLink
              tag={Link}
              to={
                hasManageAdminTicketsGrant
                  ? '/tickets/dashboard'
                  : '/tickets/tickets-list'
              }
            >
              <MenuIcon
                name="task-check-1"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/tickets')}
              >
                {formatMessage(messages.tickets)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )}
        <NavItem className="position-relative">
          {stickyWebNotifications > 0 && (
            <NotificationBadge
              number={stickyWebNotifications}
              top="0.3rem"
              right="0.2rem"
            />
          )}
          <IconDropdown
            name="new-notepad"
            menu={[
              {
                label: formatMessage(messages.stickyNew),
                path: '/sticky-note',
                onClick: (e) => {
                  e.preventDefault();
                  this.props.addSticky();
                },
                show: true,
              },
              {
                label: this.state.stickyVisibility
                  ? formatMessage(messages.stickyShow)
                  : formatMessage(messages.stickyHide),
                path: '/000',
                notifications: stickyWebNotifications,
                onClick: (e) => {
                  e.preventDefault();
                  if (!this.state.stickyVisibility) {
                    const stickyTargets = this.props.stickyNotes.map((sticky) =>
                      document.getElementById(`stickynote_${sticky.id}`)
                    );
                    anime({
                      targets: stickyTargets,
                      scale: 0,
                      rotate: 720,
                      duration: 1000,
                      easing: 'linear',
                      complete: () => {
                        this.props.updateVisibility(
                          this.state.stickyVisibility,
                          e
                        );
                      },
                    });
                  } else {
                    this.props.updateVisibility(this.state.stickyVisibility, e);
                  }
                },
                show: true,
              },
              {
                label: formatMessage(messages.staticNotes),
                path: '/static-notes',
                show: true,
              },
            ]}
            color="var(--yn-blue-dark)"
            hoverable
          >
            {formatMessage(messages.notes)}
          </IconDropdown>
        </NavItem>
        {(pbxAdminTvoxGrants ||
          (hasPhone && (pbxCallsGrants || pbxManagementGrants))) && (
          <NavItem className="position-relative">
            {vboxPbxWebNotifications > 0 && (
              <NotificationBadge
                number={vboxPbxWebNotifications}
                top="0.3rem"
                right="0.2rem"
              />
            )}
            <IconDropdown
              name="server-network-1"
              menu={[
                {
                  label: formatMessage(messages.callsLog),
                  path: '/pbx/calls/switchboard',
                  show: pbxCallsGrants,
                },
                {
                  label: formatMessage(messages.settings),
                  path: pbxManagementGrants
                    ? '/pbx/settings/switchboard'
                    : '/pbx/archived/list',
                  show: pbxManagementGrants || pbxAdminTvoxGrants,
                  notifications: vboxPbxWebNotifications,
                },
                {
                  label: formatMessage(messages.vbox),
                  path: '/pbx/vbox/list',
                  notifications: vboxPbxWebNotifications,
                  show: pbxManagementGrants,
                },
              ]}
              color="var(--yn-blue-dark)"
              hoverable
              filled={isActive('/pbx')}
            >
              {formatMessage(messages.pbx)}
            </IconDropdown>
          </NavItem>
        )}
        <NavItem className="position-relative">
          {(groupsWebNotifications > 0 || validationWebNotifications > 0) && (
            <NotificationBadge
              number={
                (groupsWebNotifications || 0) +
                (validationWebNotifications || 0)
              }
              top="0.3rem"
              right="0.2rem"
            />
          )}
          <IconDropdown
            name="key-1"
            menu={[
              {
                label: formatMessage(
                  hasAssociationsGrant && associationsLength
                    ? messages.groupsAndAssociations
                    : messages.groups
                ),
                path: '/administration/groups',
                notifications: groupsWebNotifications,
                show: true,
              },
              {
                label: formatMessage(messages.grants),
                path: '/administration/settings/profiles',
                show: usersProfilesGrants,
              },
              {
                label: formatMessage(messages.validations),
                path: isPunchingsValidator
                  ? '/administration/validations/punchings'
                  : '/administration/validations/vacations',
                notifications: validationWebNotifications,
                show: isPunchingsValidator || isVacationsValidator,
              },
              {
                label: formatMessage(messages.presencesManagement),
                path: managePresencesGrants
                  ? '/administration/presences/punchings'
                  : '/administration/presences/visits-list',
                show:
                  puncherEnabled &&
                  (managePresencesGrants || manageVisitorsGrants),
              },
              {
                label: formatMessage(messages.logo),
                path: '/administration/logo',
                show: logoManagementGrant,
              },
              {
                label: formatMessage(messages.credits),
                path: '/administration/credit/remaining',
                show: creditsGrants,
              },
              {
                label: formatMessage(messages.documents),
                path: '/documents/contract-data',
                show: showDocuments,
              },
            ]}
            color="var(--yn-blue-dark)"
            hoverable
            filled={isActive('/administration')}
          >
            {formatMessage(messages.admin)}
          </IconDropdown>
        </NavItem>
        {/*showDocuments && (
          <NavItem>
            <NavLink tag={Link} to="/documents/contract-data">
              <MenuIcon
                name="box-2"
                color="var(--yn-blue-dark)"
                hoverable
                filled={isActive('/documents')}
              >
                {formatMessage(messages.documents)}
              </MenuIcon>
            </NavLink>
          </NavItem>
        )*/}
        <NavItem>
          <span
            id="help_menu_icon"
            className="p-2 d-block"
            onClick={this.toggleHelp}
          >
            <MenuIcon
              name="help-circle"
              color="var(--yn-blue-dark)"
              hoverable
              filled={helpVisible}
            >
              {helpVisible
                ? formatMessage(messages.hideHelp)
                : formatMessage(messages.showHelp)}
            </MenuIcon>
          </span>
          <Popover
            isOpen={this.state.helpPopOverVisible}
            toggle={this.toggleHelpPopover}
            target="help_menu_icon"
            popoverWidth="12rem"
            popoverMinHeight="4rem"
          >
            {formatMessage(messages.helpText1)}
            <span className="px-3">
              <Icon name="help-circle-filled" color="var(--yn-blue-dark)" />
            </span>
            {formatMessage(messages.helpText2)}
            <a
              className="px-2 font-weight-bold"
              href={
                supplier &&
                `${ynConf.clientBaseUrl}/help/${language}/${formatMessage(
                  messages[`helpFilename${supplier}`]
                )}`
              }
              download
            >
              {formatMessage(messages.helpPdf)}
            </a>
            {formatMessage(messages.helpText3)}
          </Popover>
        </NavItem>
      </Nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasPhone: isPhoneAvailable(state),
    stickyNotes: getStickyNotes(state),
    stickyShow: state.settings.stickyShow,
    isHotel: state.me.customer.hotelEnabled,
    isFaxActive:
      isFaxActive(state) &&
      isFaxSettingsFetchLoaded(state) &&
      !!getFaxNumbers(state).length,
    grants: state.me.grants,
    supplier: getSupplier(state),
    helpVisible: isHelpVisible(state),
    isPunchingsValidator:
      isPuncherEnabled(state) && isMePunchingsValidator(state),
    isVacationsValidator:
      isVacationEnabled(state) && isMeVacationsValidator(state),
    puncherEnabled: isPuncherEnabled(state),
    ticketingEnabled: isTicketingEnabled(state),
    associationsLength:
      getAssociationsListIsAdmin(state).length +
      getAssociationsListIsNotAdmin(state).length,
    location: state.router.location,
    language: getPlatformLanguage(state),
    contactGrant: addressbookGrant(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    addSticky: addStickyNotesRequest,
    updateVisibility: updateStickyVisibilityRequest,
    toggleHelp: toggleShowHelp,
  })(MainNav)
);
