import React from 'react';
import BackButton from '../../../../components/buttons/BackButton';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';
import PbxSettings from './PbxSettings';

const NewPbxMessageForm = React.lazy(() => import('./NewPbxMessageForm'));
const NewPbxMessagePage = () => (
  <PbxSettings scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="pbx-recorded-messages" />
          </div>
        </div>
        <NewPbxMessageForm />
      </React.Suspense>
    </ErrorBoundary>
  </PbxSettings>
);

export default NewPbxMessagePage;
