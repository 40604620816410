import { takeLatest, call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../api';
import { checkApiError, checkApiResponse } from '../rootSaga';
import {
  fetchFileboxSpaceUsageSuccess,
  fetchFileboxSpaceUsageFailure,
  fetchFileboxItemsSuccess,
  fetchFileboxItemsFailure,
  deleteFileboxFileSuccess,
  deleteFileboxFileFailure,
  createFileboxFolderSuccess,
  createFileboxFolderFailure,
  fetchFileboxItemsRequest,
  deleteFileboxFolderSuccess,
  deleteFileboxFolderFailure,
  renameFileboxFileSuccess,
  renameFileboxFileFailure,
  editFileboxFolderSuccess,
  editFileboxFolderFailure,
  enableDisableFileboxFileExternalLinkSuccess,
  enableDisableFileboxFileExternalLinkFailure,
  fetchFileboxFolderDetailsSuccess,
  fetchFileboxFolderDetailsFailure,
  toggleLockingFileSuccess,
  toggleLockingFileFailure,
  fetchFileboxFavoriteFilesSuccess,
  fetchFileboxFavoriteFilesFailure,
  moveFileboxFileSuccess,
  moveFileboxFileFailure,
  fetchFileboxActiveItemsSuccess,
  fetchFileboxActiveItemsFailure,
  removeFileboxFavoriteFileSuccess,
  removeFileboxFavoriteFileFailure,
  copyFileboxFileSuccess,
  copyFileboxFileFailure,
  copyFileboxFolderSuccess,
  copyFileboxFolderFailure,
  searchFileboxFilesFoldersSuccess,
  searchFileboxFilesFoldersFailure,
  addFileboxFavoriteFileSuccess,
  addFileboxFavoriteFileFailure,
  moveFileboxFolderSuccess,
  moveFileboxFolderFailure,
  updateFileboxSpaceSuccess,
  updateFileboxSpaceFailure,
  fetchFileboxSpaceUsageRequest,
  deleteFileboxMassiveFilesSuccess,
  deleteFileboxMassiveFilesFailure,
  searchFileboxFilesFoldersRequest,
  fetchFileboxFileCommentsSuccess,
  fetchFileboxFileCommentsFailure,
  createFileboxFileCommentFailure,
  deleteFileboxFileCommentFailure,
  fetchFileboxFileCommentsRequest,
  retrieveAllFileboxFilePreviousVersionsSuccess,
  retrieveAllFileboxFilePreviousVersionsFailure,
  removeFileboxFileVersionSuccess,
  removeFileboxFileVersionFailure,
  fetchFileboxDeletedItemsSuccess,
  fetchFileboxDeletedItemsFailure,
  removePermanentlyFileboxDeletedItemSuccess,
  removePermanentlyFileboxDeletedItemFailure,
  fetchFileboxDeletedItemsRequest,
  restoreFileboxDeletedItemSuccess,
  restoreFileboxDeletedItemFailure,
  fetchFileboxFolderDetailsRequest,
  fetchFileboxFavoriteFilesRequest,
  shareFileboxFileSuccess,
  shareFileboxFileFailure,
  fetchFileboxFileEmailRecipientsFailure,
  fetchFileboxFileEmailRecipientsSuccess,
} from './actions';
import {
  getFileboxFilter,
  getSelectedFolderId,
  getDeletedSelectedFolderId,
} from './selectors';
import { getMeId } from '../me/selectors';
import {
  CREATE_FILEBOX_FOLDER_REQUEST,
  DELETE_FILEBOX_FILE_REQUEST,
  DELETE_FILEBOX_FOLDER_REQUEST,
  EDIT_FILEBOX_FOLDER_REQUEST,
  ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST,
  FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST,
  FETCH_FILEBOX_FOLDER_DETAILS_REQUEST,
  FETCH_FILEBOX_ITEMS_REQUEST,
  FETCH_FILEBOX_SPACE_USAGE_REQUEST,
  MOVE_FILEBOX_FILE_REQUEST,
  RENAME_FILEBOX_FILE_REQUEST,
  TOGGLE_LOCKING_FILE_REQUEST,
  FETCH_FILEBOX_FAVORITE_FILES_REQUEST,
  REMOVE_FILEBOX_FAVORITE_FILE_REQUEST,
  COPY_FILEBOX_FILE_REQUEST,
  COPY_FILEBOX_FOLDER_REQUEST,
  SEARCH_FILEBOX_FILES_FOLDERS_REQUEST,
  ADD_FILEBOX_FAVORITE_FILE_REQUEST,
  MOVE_FILEBOX_FOLDER_REQUEST,
  UPDATE_FILEBOX_SPACE_REQUEST,
  DELETE_FILEBOX_MASSIVE_FILES_REQUEST,
  FETCH_FILEBOX_FILE_COMMENTS_REQUEST,
  CREATE_FILEBOX_FILE_COMMENT_REQUEST,
  DELETE_FILEBOX_FILE_COMMENT_REQUEST,
  RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST,
  REMOVE_FILEBOX_FILE_VERSION_REQUEST,
  FETCH_FILEBOX_DELETED_ITEMS_REQUEST,
  REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST,
  RESTORE_FILEBOX_DELETED_ITEM_REQUEST,
  FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST,
  SHARE_FILEBOX_FILE_REQUEST,
} from './types';
import { FileboxEnums } from './FileboxUtils';
import history from '../../history';

export function* fetchSpaceUsage() {
  try {
    const res = yield call(api.filebox.getFileboxSpaceUsage);
    yield call(checkApiResponse, res);
    yield put(fetchFileboxSpaceUsageSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxSpaceUsageFailure(error));
  }
}

export function* fetchItems(action) {
  try {
    const res = yield call(api.filebox.getFileboxItems, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchFileboxItemsSuccess({ items: [], folderId: action.payload.parent })
      );
    } else {
      yield put(
        fetchFileboxItemsSuccess({
          items: res.data,
          folderId: action.payload.parent,
        })
      );
      yield* res.data
        .filter((item) => item.isDirectory)
        .map((item) => put(fetchFileboxFolderDetailsRequest(item.id)));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxItemsFailure(error));
  }
}

export function* deleteFile(action) {
  try {
    const res = yield call(api.filebox.deleteFileboxFile, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteFileboxFileSuccess());
    const folderId = yield select(getSelectedFolderId);
    if (history.location.pathname.includes(`filebox/favourites`)) {
      yield put(fetchFileboxFavoriteFilesRequest());
    } else {
      yield put(fetchFileboxItemsRequest({ parent: folderId }));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteFileboxFileFailure(error));
  }
}

export function* createFileboxFolder(action) {
  try {
    const res = yield call(api.filebox.createFileboxFolder, action.payload);
    yield call(checkApiResponse, res);
    yield put(createFileboxFolderSuccess(res.data));
    const folderId = yield select(getSelectedFolderId);
    yield put(fetchFileboxItemsRequest({ parent: folderId }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(createFileboxFolderFailure(error));
  }
}

export function* deleteFileboxFolder(action) {
  try {
    const res = yield call(api.filebox.deleteFileboxFolder, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteFileboxFolderSuccess(res.data));

    const folderId = yield select(getSelectedFolderId);
    const filter = yield select(getFileboxFilter);
    if (filter.name && filter.name.length > 0)
      yield put(searchFileboxFilesFoldersRequest({ name: filter.name }));
    yield put(fetchFileboxItemsRequest({ parent: folderId }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        deleteFileboxFolderFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* renameFileboxFile(action) {
  try {
    const res = yield call(api.filebox.renameFileboxFile, action.payload);
    yield call(checkApiResponse, res);
    yield put(renameFileboxFileSuccess(action.payload));
    const folderId = yield select(getSelectedFolderId);
    if (history.location.pathname.includes(`filebox/favourites`)) {
      yield put(fetchFileboxFavoriteFilesRequest());
    } else {
      yield put(fetchFileboxItemsRequest({ parent: folderId }));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(renameFileboxFileFailure(error));
  }
}

export function* editFileboxFolder(action) {
  try {
    const res = yield call(api.filebox.editFileboxFolder, action.payload);
    yield call(checkApiResponse, res);
    yield put(editFileboxFolderSuccess());
    const folderId = yield select(getSelectedFolderId);
    yield put(fetchFileboxItemsRequest({ parent: folderId }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editFileboxFolderFailure(error));
  }
}
export function* toggleLockingFile(action) {
  try {
    yield call(api.filebox.toggleLockingFile, action.payload);
    const meId = yield select(getMeId);
    yield put(
      toggleLockingFileSuccess({ ...action.payload, blockingUser: meId })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        toggleLockingFileFailure({
          errors: err.data.error ? err.data.error : error,
          blockingUser: err.data && err.data.user,
          ...action.payload,
        })
      );
  }
}

export function* enableDisableFileboxFileExternalLink(action) {
  try {
    const res = yield call(
      api.filebox.enableDisableFileboxFileExternalLink,
      action.payload
    );
    yield call(checkApiResponse, res);
    yield put(
      enableDisableFileboxFileExternalLinkSuccess(
        action.payload.id,
        action.payload.data.enabled,
        res.data
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(enableDisableFileboxFileExternalLinkFailure(error));
  }
}

export function* fetchFolderDetails(action) {
  try {
    const res = yield call(api.filebox.getFileboxFolderDetails, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchFileboxFolderDetailsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxFolderDetailsFailure(error));
  }
}

export function* fetchFileboxFavoriteFiles() {
  try {
    const res = yield call(api.filebox.getFileboxFavoriteFiles);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchFileboxFavoriteFilesSuccess({ items: [] }));
    } else {
      yield put(
        fetchFileboxFavoriteFilesSuccess({
          items: res.data,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxFavoriteFilesFailure(error));
  }
}

export function* fetchActiveItems(action) {
  try {
    const res = yield call(api.filebox.getFileboxItems, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchFileboxActiveItemsSuccess({
          items: [],
          folderId: action.payload && action.payload.parent,
          context: action.payload.context,
        })
      );
    } else {
      yield put(
        fetchFileboxActiveItemsSuccess({
          items: res.data,
          folderId: action.payload && action.payload.parent,
          context: action.payload.context,
        })
      );
      yield* res.data
        .filter((item) => item.isDirectory)
        .map((item) => put(fetchFileboxFolderDetailsRequest(item.id)));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxActiveItemsFailure(error));
  }
}

export function* moveFileboxFile(action) {
  try {
    const res = yield call(api.filebox.moveFileboxFile, action.payload);
    yield call(checkApiResponse, res);
    yield put(moveFileboxFileSuccess());
    // const folderId = yield select(getSelectedFolderId);
    // yield put(fetchFileboxItemsRequest({ parent: folderId }));
    history.push(
      `/filebox/list/${action.payload.path.filter((f) => f !== null).join('/')}`
      //.join('/')}?preview=${res.data.id}`
    );
  } catch (err) {
    const error = yield call(checkApiError, err);

    if (error)
      yield put(
        moveFileboxFileFailure(
          err.data.error
            ? err.data.error === FileboxEnums.errorMessages.LOCKED_FILE
              ? FileboxEnums.errorMessages.MOVE_FILE_LOCKED
              : FileboxEnums.errorMessages.MOVE_FILE_ERROR
            : error
        )
      );
  }
}

export function* moveFileboxFolder(action) {
  try {
    const res = yield call(api.filebox.moveFileboxFolder, action.payload);
    yield call(checkApiResponse, res);
    yield put(moveFileboxFolderSuccess());
    history.push(
      `/filebox/list/${action.payload.path.filter((f) => f !== null).join('/')}`
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        moveFileboxFolderFailure(
          err.data.error
            ? err.data.error === FileboxEnums.errorMessages.LOCKED_FILE
              ? FileboxEnums.errorMessages.MOVE_FOLDER_LOCKED
              : FileboxEnums.errorMessages.MOVE_FOLDER_ERROR
            : error
        )
      );
  }
}
export function* copyFileboxFile(action) {
  try {
    const res = yield call(api.filebox.copyFileboxFile, action.payload);
    yield call(checkApiResponse, res);
    yield put(copyFileboxFileSuccess());
    history.push(
      `/filebox/list/${action.payload.path.filter((f) => f !== null).join('/')}`
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        copyFileboxFileFailure(
          err.data.error ? FileboxEnums.errorMessages.COPY_FILE_ERROR : error
        )
      );
  }
}
export function* copyFileboxFolder(action) {
  try {
    const res = yield call(api.filebox.copyFileboxFolder, action.payload);
    yield call(checkApiResponse, res);
    yield put(copyFileboxFolderSuccess());
    history.push(
      `/filebox/list/${action.payload.path.filter((f) => f !== null).join('/')}`
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        copyFileboxFolderFailure(
          err.data.error ? FileboxEnums.errorMessages.COPY_FOLDER_ERROR : error
        )
      );
  }
}
export function* searchFileboxFilesFolders(action) {
  try {
    const res = yield call(api.filebox.searchFileboxFilesFolders, {
      name: action.payload.name,
    });
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        searchFileboxFilesFoldersSuccess({
          items: [],
        })
      );
    } else {
      yield put(
        searchFileboxFilesFoldersSuccess({
          items: res.data.rows,
          total: res.data.count,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(searchFileboxFilesFoldersFailure(error));
  }
}
export function* addFileboxFavoriteFile(action) {
  try {
    yield call(api.filebox.addFileboxFavoriteFile, action.payload);
    yield call(checkApiResponse);
    yield put(addFileboxFavoriteFileSuccess(action.payload));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(addFileboxFavoriteFileFailure(error));
  }
}

export function* removeFileboxFavoriteFile(action) {
  try {
    const res = yield call(api.filebox.removeFileboxFavoriteFile, action.id);
    yield call(checkApiResponse, res);
    yield put(removeFileboxFavoriteFileSuccess(action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removeFileboxFavoriteFileFailure(error));
  }
}

export function* updateFileboxSpace(action) {
  try {
    const res = yield call(api.filebox.updateFileboxSpace, action.payload);
    yield call(checkApiResponse, res);
    yield put(updateFileboxSpaceSuccess());
    yield put(fetchFileboxSpaceUsageRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateFileboxSpaceFailure(error));
  }
}

export function* deleteMassiveFiles(action) {
  try {
    let multipleFilesError = false;
    if (action.files && action.files.length) {
      try {
        const res = yield call(
          api.filebox.deleteFileboxMassiveFiles,
          action.files
        );
        yield call(checkApiResponse, res);
      } catch (err) {
        multipleFilesError = true;
        const error = yield call(checkApiError, err);
        if (error) yield put(deleteFileboxMassiveFilesFailure(error));
      }
    }

    const notDeletedFolderIds = [];
    if (action.folders && action.folders.length) {
      for (let i = 0; i < action.folders.length; i += 1) {
        try {
          const result = yield call(
            api.filebox.deleteFileboxFolder,
            action.folders[i]
          );
          yield call(checkApiResponse, result);
        } catch (err) {
          notDeletedFolderIds.push(action.folders[i]);
        }
      }
    }

    if (notDeletedFolderIds.length && multipleFilesError) {
      yield put(
        deleteFileboxMassiveFilesFailure(
          FileboxEnums.errorMessages.MASSIVE_FILE_FOLDER_DELETION_ERROR,
          notDeletedFolderIds
        )
      );
    } else if (notDeletedFolderIds.length) {
      yield put(
        deleteFileboxMassiveFilesFailure(
          FileboxEnums.errorMessages.MASSIVE_FOLDER_DELETION_ERROR,
          notDeletedFolderIds
        )
      );
    } else if (!multipleFilesError) {
      yield put(deleteFileboxMassiveFilesSuccess());
    }
    const folderId = yield select(getSelectedFolderId);
    const filter = yield select(getFileboxFilter);
    if (filter.name && filter.name.length > 0)
      yield put(searchFileboxFilesFoldersRequest({ name: filter.name }));
    yield put(fetchFileboxItemsRequest({ parent: folderId }, true));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteFileboxMassiveFilesFailure(error));
  }
}
export function* fetchPreviousFileVersions(action) {
  try {
    const res = yield call(
      api.filebox.getFileboxPreviousVersionFiles,
      action.id
    );
    yield call(checkApiResponse, res);

    yield put(
      retrieveAllFileboxFilePreviousVersionsSuccess(
        res.status === 204 ? [] : res.data,
        action.id
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(retrieveAllFileboxFilePreviousVersionsFailure(error));
  }
}
export function* deleteFileboxOldFileVersion(action) {
  try {
    const res = yield call(
      api.filebox.removeFileboxOldFileVersion,
      action.fileId,
      action.id
    );
    yield call(checkApiResponse, res);
    yield put(removeFileboxFileVersionSuccess(action.fileId, action.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removeFileboxFileVersionFailure(error));
  }
}

export function* fetchFileComments(action) {
  try {
    const res = yield call(api.filebox.getFileComments, action.id);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchFileboxFileCommentsSuccess({ items: [], id: action.id }));
    } else {
      yield put(
        fetchFileboxFileCommentsSuccess({
          items: res.data,
          id: action.id,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxFileCommentsFailure(error));
  }
}

export function* fetchFileboxDeletedItems(action) {
  try {
    const res = yield call(api.filebox.getFileboxDeletedItems, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchFileboxDeletedItemsSuccess({
          items: [],
          folderId: action.payload && action.payload.parent,
        })
      );
    } else {
      yield put(
        fetchFileboxDeletedItemsSuccess({
          items: res.data,
          folderId: action.payload && action.payload.parent,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxDeletedItemsFailure(error));
  }
}

export function* createFileComment(action) {
  try {
    const res = yield call(api.filebox.addFileComment, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchFileboxFileCommentsRequest(action.payload.id));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(createFileboxFileCommentFailure(error));
  }
}

export function* deleteFileComment(action) {
  try {
    const res = yield call(api.filebox.deleteFileComment, action.payload);
    yield call(checkApiResponse, res);
    yield put(fetchFileboxFileCommentsRequest(action.payload.fileId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteFileboxFileCommentFailure(error));
    if (error) yield put(fetchFileboxDeletedItemsFailure(error));
  }
}

export function* removePermanentlyFileboxDeletedItem(action) {
  try {
    const res = yield call(
      api.filebox.removePermanentlyFileboxDeletedItem,
      action.id
    );
    yield call(checkApiResponse, res);
    yield put(removePermanentlyFileboxDeletedItemSuccess());
    const folderId = yield select(getDeletedSelectedFolderId);
    yield put(fetchFileboxDeletedItemsRequest({ parent: folderId }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removePermanentlyFileboxDeletedItemFailure(error));
  }
}

export function* restoreFileboxDeletedItem(action) {
  try {
    const res = yield call(api.filebox.restoreFileboxDeletedItem, action.id);
    yield call(checkApiResponse, res);
    yield put(restoreFileboxDeletedItemSuccess());
    const folderId = yield select(getDeletedSelectedFolderId);
    yield put(fetchFileboxDeletedItemsRequest({ parent: folderId }));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(restoreFileboxDeletedItemFailure(error));
  }
}
export function* fetchFileEmailRecipients(action) {
  try {
    const res = yield call(
      api.filebox.getFileboxFileEmailRecipientsDetails,
      action.id
    );
    yield call(checkApiResponse, res);
    yield put(
      fetchFileboxFileEmailRecipientsSuccess({
        items: res.data,
        id: action.id,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchFileboxFileEmailRecipientsFailure(error));
  }
}

export function* shareFileboxFile(action) {
  try {
    const res = yield call(api.filebox.shareFileboxFile, action.payload);
    yield call(checkApiResponse, res);
    yield put(shareFileboxFileSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(shareFileboxFileFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_FILEBOX_SPACE_USAGE_REQUEST, fetchSpaceUsage);
  yield takeLatest(FETCH_FILEBOX_ITEMS_REQUEST, fetchItems);
  yield takeLatest(DELETE_FILEBOX_FILE_REQUEST, deleteFile);
  yield takeLatest(CREATE_FILEBOX_FOLDER_REQUEST, createFileboxFolder);
  yield takeLatest(DELETE_FILEBOX_FOLDER_REQUEST, deleteFileboxFolder);
  yield takeLatest(RENAME_FILEBOX_FILE_REQUEST, renameFileboxFile);
  yield takeLatest(EDIT_FILEBOX_FOLDER_REQUEST, editFileboxFolder);
  yield takeLatest(
    ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST,
    enableDisableFileboxFileExternalLink
  );
  yield takeEvery(FETCH_FILEBOX_FOLDER_DETAILS_REQUEST, fetchFolderDetails);
  yield takeLatest(TOGGLE_LOCKING_FILE_REQUEST, toggleLockingFile);
  yield takeLatest(
    FETCH_FILEBOX_FAVORITE_FILES_REQUEST,
    fetchFileboxFavoriteFiles
  );
  yield takeLatest(FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST, fetchActiveItems);
  yield takeLatest(MOVE_FILEBOX_FILE_REQUEST, moveFileboxFile);
  yield takeLatest(MOVE_FILEBOX_FOLDER_REQUEST, moveFileboxFolder);
  yield takeLatest(
    REMOVE_FILEBOX_FAVORITE_FILE_REQUEST,
    removeFileboxFavoriteFile
  );
  yield takeLatest(COPY_FILEBOX_FILE_REQUEST, copyFileboxFile);
  yield takeLatest(COPY_FILEBOX_FOLDER_REQUEST, copyFileboxFolder);
  yield takeLatest(
    SEARCH_FILEBOX_FILES_FOLDERS_REQUEST,
    searchFileboxFilesFolders
  );
  yield takeLatest(ADD_FILEBOX_FAVORITE_FILE_REQUEST, addFileboxFavoriteFile);
  yield takeLatest(UPDATE_FILEBOX_SPACE_REQUEST, updateFileboxSpace);
  yield takeLatest(DELETE_FILEBOX_MASSIVE_FILES_REQUEST, deleteMassiveFiles);
  yield takeLatest(FETCH_FILEBOX_FILE_COMMENTS_REQUEST, fetchFileComments);
  yield takeLatest(CREATE_FILEBOX_FILE_COMMENT_REQUEST, createFileComment);
  yield takeLatest(DELETE_FILEBOX_FILE_COMMENT_REQUEST, deleteFileComment);
  yield takeLatest(
    RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST,
    fetchPreviousFileVersions
  );
  yield takeLatest(
    REMOVE_FILEBOX_FILE_VERSION_REQUEST,
    deleteFileboxOldFileVersion
  );
  yield takeLatest(
    FETCH_FILEBOX_DELETED_ITEMS_REQUEST,
    fetchFileboxDeletedItems
  );
  yield takeLatest(
    REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST,
    removePermanentlyFileboxDeletedItem
  );
  yield takeLatest(
    RESTORE_FILEBOX_DELETED_ITEM_REQUEST,
    restoreFileboxDeletedItem
  );
  yield takeLatest(
    FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST,
    fetchFileEmailRecipients
  );
  yield takeLatest(SHARE_FILEBOX_FILE_REQUEST, shareFileboxFile);
}
