import React from 'react';
import Wiki from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const WikiEdit = React.lazy(() => import('./WikiEdit'));

const WikiEditPage = (props) => (
  <Wiki>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col">
            <BackButton />
          </div>
          <div className="text-right col">
            <HelpButton fileName="wiki-new" />
          </div>
        </div>
        <WikiEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wiki>
);

export default WikiEditPage;
