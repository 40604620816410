import queryString from 'query-string';
import moment from 'moment';

export class CalendarEnums {
  static EVENT_COLORS = [
    '#ffb4b4',
    '#ffdaad',
    '#fffaaf',
    '#e2ffb4',
    '#d3fad3',
    '#99FDF0',
    '#BED6FC',
    '#f9dcf9',
    '#d6c9f1',
    '#c9f1ee',
    '#dddddd',
  ];

  static APPOINTMENT_COLORS = [
    '#ffb4b4',
    '#ffdaad',
    '#fffaaf',
    '#e2ffb4',
    '#d3fad3',
    '#99FDF0',
    '#BED6FC',
    '#f9dcf9',
    '#d6c9f1',
    '#c9f1ee',
    '#dddddd',
  ];

  static EventDateFormat = 'DD/MM/YY';

  static EventHourFormat = 'HH:mm';

  static EventDefaultColor = '#ffb4b4';

  static VacationDefaultColor = '#002a3f';

  static GetEventStartDateFormat = 'YYYYMMDDTHHmmss';

  static GetEventEndDateFormat = 'YYYYMMDDTHHmmss';

  static CalendarSyncStatus = {
    OK: 'OK',
    PENDING: 'PENDING',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
  };

  static CalendarTypes = {
    EVENT: 'EVENT',
    VACATION: 'VACATION',
    APPOINTMENT: 'APPOINTMENT',
  };

  static CalendarParticipantsTypes = {
    everybody: 'everybody',
    onlyMe: 'onlyMe',
    selected: 'selected',
  };

  static CalendarDefaultSettings = {
    header: false,
    firstDay: 1,
    navLinks: false,
    editable: true,
    eventLimit: 3,
    timezone: 'local',
    timeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
  };

  static CalendarViews = {
    MONTH: 'dayGridMonth',
    WEEK: 'timeGridWeek',
    DAY: 'timeGridDay',
    LIST: 'listMonth',
  };

  static CalendarDefaultFestivities = {
    CAPODANNO: 'CAPODANNO',
    EPIFANIA: 'EPIFANIA',
    PASQUA: 'PASQUA',
    PASQUETTA: 'PASQUETTA',
    LIBERAZIONE: 'LIBERAZIONE',
    LAVORATORI: 'LAVORATORI',
    REPUBBLICA: 'REPUBBLICA',
    FERRAGOSTO: 'FERRAGOSTO',
    OGNISSANTI: 'OGNISSANTI',
    IMMACOLATA: 'IMMACOLATA',
    NATALE: 'NATALE',
    SANTO_STEFANO: 'SANTO_STEFANO',
  };

  static CalendarRemindersTypes = {
    WEB: 'WEB',
    EMAIL: 'EMAIL',
    SMS: 'SMS',
  };

  static CalendarRemindersUnits = {
    MINUTES: 'MINUTES',
    HOURS: 'HOURS',
    DAYS: 'DAYS',
  };

  static CalendarRemindersRecipients = { ME: 'ME', ALL: 'ALL' };
}

export const generateFullEventId = (data) =>
  `${data.id}_${new Date(data.start).getTime()}`.slice(0, -3);

export const retriveShortEventId = (fullId) => fullId.split('_')[0];

export const getDefaultStartDay = (search, daysToAdd) => {
  const searchedDay = +queryString.parse(search).day;
  const searchedTime = +queryString.parse(search).time;

  return searchedDay
    ? new Date(searchedDay)
    : searchedTime
    ? new Date(searchedTime)
    : moment(moment())
        .add(daysToAdd || 0, 'days')
        .toDate();
};

export const getDefaultStartTime = (search, settings, startDay) => {
  const searchedTime = +queryString.parse(search).time;
  if (searchedTime) {
    return new Date(searchedTime);
  }
  if (settings.workingStart) {
    const date = startDay;
    date.setHours(settings.workingStart.split(':')[0]);
    date.setMinutes(settings.workingStart.split(':')[1]);
    return date;
  }
  return new Date();
};

export const getDefaultEndDay = (search, settings, startDay) => {
  const searchedDay = +queryString.parse(search).day;

  return (
    (searchedDay &&
      new Date(
        searchedDay +
          getDefaultStartTime(search, settings, startDay).getHours() *
            1000 *
            60 *
            60 +
          settings.eventDuration * 1000 * 60 * 60
      )) ||
    startDay
  );
};

export const getDefaultEndTime = (search, settings, startDay) => {
  const searchedDuration = +queryString.parse(search).duration;

  return getDefaultStartTime(search, settings, startDay).setHours(
    getDefaultStartTime(search, settings, startDay).getHours() +
      (searchedDuration / 60 || settings.eventDuration || 1),
    searchedDuration % 60 > 0 &&
      getDefaultStartTime(search, settings, startDay).getMinutes() +
        (searchedDuration % 60)
  );
};

export const getVacationDefaultStartDay = (search) =>
  getDefaultStartDay(search, 1);

export const getVacationDefaultStartTime = (search, settings, startDay) =>
  getDefaultStartTime(search, settings, startDay);

export const getVacationDefaultEndDay = (search, settings, startDay) =>
  getDefaultEndDay(search, settings, startDay);

export const getVacationDefaultEndTime = (search, settings, startDay) =>
  getDefaultEndTime(search, settings, startDay);

//export const CalendarRemindersRecipients = ['ME', 'ALL'];
