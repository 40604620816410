import React from 'react';
import PersonalSettings from './index';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';

const AdminUsersEditUserHRRegistry = React.lazy(() =>
  import('../../Groups/AdminUsersEditUserHRRegistry')
);

const HRSettingsPage = (props) => (
  <PersonalSettings scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-right">
            <HelpButton fileName="admin-edit-user-hr" />
          </div>
        </div>
        <AdminUsersEditUserHRRegistry {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PersonalSettings>
);

export default HRSettingsPage;
