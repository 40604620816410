import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import moment from 'moment';
import {
  READ_ALL_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  DELETE_NOTIFICATION,
  FETCH_ARCHIVED_NOTIFICATIONS_REQUEST,
} from './types';
import {
  NotificationEnums,
  sendDeleteNotification,
  sendReadStatus,
  sendReadAll,
  sendDeleteAll,
} from './NotificationUtils';
import {
  fetchArchivedNotificationsSuccess,
  fetchArchivedNotificationsFailure,
} from './actions';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

function readNotification(action) {
  sendReadStatus(action.payload.notificationId, action.payload.topic);
}

function readAllNotifications(action) {
  sendReadAll(action.section);
}

function deleteNotification(action) {
  sendDeleteNotification(action.payload.notificationId, action.payload.topic);
}

function deleteAllNotifications(action) {
  sendDeleteAll(action.section);
}

function* fetchArchivedNotifications(action) {
  try {
    // for ADMIN section transform to [USERS, GROUPS], for CALENDAR section transform to [CALENDAR, VACATION]
    const topics =
      action.payload.section === NotificationEnums.NotificationsSections.ADMIN
        ? [
            NotificationEnums.NotificationsTopics.GROUPS,
            NotificationEnums.NotificationsTopics.USERS,
          ]
        : action.payload.section ===
          NotificationEnums.NotificationsSections.CALENDAR
        ? [
            NotificationEnums.NotificationsTopics.CALENDAR,
            NotificationEnums.NotificationsTopics.VACATION,
          ]
        : [action.payload.section];
    const res = yield call(api.notifications.getArchivedNotifications, {
      topics,
      end: moment(action.payload.datetime).format('YYYYMMDDTHHmmss'),
      pageSize: 5,
    });
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchArchivedNotificationsSuccess({
          section: action.payload.section,
          notifications: [],
          count: 0,
        })
      );
    } else {
      yield put(
        fetchArchivedNotificationsSuccess({
          section: action.payload.section,
          notifications: res.data.data.map((data) => ({
            ...data.payload,
            notificationId: data.notificationId,
            topic: data.topic,
            idUserAction: data.idUserAction,
            dateTime: data.dateTime,
            idEntity: data.entityId,
            nameEntity: data.entityName,
            action: data.action,
            archived: true,
            section: action.payload.section,
          })),
          count: action.payload.datetime ? null : res.data.total,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        fetchArchivedNotificationsFailure({
          error,
          section: action.payload.section,
        })
      );
  }
}

export default function* rootSaga() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification);
  yield takeEvery(READ_NOTIFICATION, readNotification);
  yield takeEvery(READ_ALL_NOTIFICATIONS, readAllNotifications);
  yield takeEvery(DELETE_ALL_NOTIFICATIONS, deleteAllNotifications);
  yield takeLatest(
    FETCH_ARCHIVED_NOTIFICATIONS_REQUEST,
    fetchArchivedNotifications
  );
}
