import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Icon from '../../../components/icons/Icon';
import {
  TIMED_MESSAGES_SECONDS,
  retrieveInterlocutorIdFromConversationId,
  CHAT_TOOLBAR_HEIGHT,
  CHAT_TEXTAREA_HEIGHTS,
  CHAT_REPLY_TEXTAREA_HEIGHTS,
  CHAT_EMOJI_PANEL_HEIGHTS,
} from '../../../js/chat/ChatUtils';
import { getRepliedMessage } from '../../../js/chat/selectors';
import ErrorBoundaryChat from '../../../components/ErrorBoundaryChat';

const ChatEmojisTab = React.lazy(() => import('./ChatEmojisTab'));

const messages = defineMessages({
  enableDeletingTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.enableDeletingTooltip',
    defaultMessage: 'Enable deleting',
  },
  disableDeletingTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.disableDeletingTooltip',
    defaultMessage: 'Disable deleting',
  },
  deleteTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.deleteTooltip',
    defaultMessage: 'Delete selected messages',
  },
  attachmentTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.attachmentTooltip',
    defaultMessage: 'Send file',
  },
  timedMessageTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.timedMessageTooltip',
    defaultMessage: 'Timed message',
  },
  emojiTooltip: {
    id: 'ChatWindowToolbarBottom.tooltip.emojiTooltip',
    defaultMessage: 'Emoji',
  },
  timeout10: {
    id: 'ChatWindowToolbarBottom.tooltip.timeout10',
    defaultMessage: '10 seconds',
  },
  timeout30: {
    id: 'ChatWindowToolbarBottom.tooltip.timeout30',
    defaultMessage: '30 seconds',
  },
  timeout120: {
    id: 'ChatWindowToolbarBottom.tooltip.timeout120',
    defaultMessage: '2 minutes',
  },
  timeout300: {
    id: 'ChatWindowToolbarBottom.tooltip.timeout300',
    defaultMessage: '5 minutes',
  },
  noTimeout: {
    id: 'ChatWindowToolbarBottom.tooltip.noTimeout',
    defaultMessage: 'No timeout',
  },
});

class ChatWindowToolbarBottom extends React.Component {
  constructor(props) {
    super(props);
    this.toggleEmojiTab = this.toggleEmojiTab.bind(this);
    this.toggleTimedMessage = this.toggleTimedMessage.bind(this);
    this.state = {
      emojiPanelOpen: false,
      timedMessagePanelOpen: false,
    };
  }

  componentDidUpdate(prevprops) {
    if (
      prevprops.emojiPanelOpen !== this.props.emojiPanelOpen &&
      this.props.emojiPanelOpen !== this.state.emojiPanelOpen
    ) {
      this.setState({ emojiPanelOpen: this.props.emojiPanelOpen });
    }
  }

  toggleTimedMessage = () => {
    const { timedMessagePanelOpen } = this.state;
    this.setState({
      timedMessagePanelOpen: !timedMessagePanelOpen,
      emojiPanelOpen: false,
    });
    this.props.handleEnableDeleting(false);
  };

  selectTimedMessage = (value) => {
    this.props.onChangeTimedMessage(value);
    this.setState({ timedMessagePanelOpen: false });
  };

  toggleEmojiTab = () => {
    const { emojiPanelOpen } = this.state;
    this.props.handleEnableDeleting(false);
    this.setState(
      { emojiPanelOpen: !emojiPanelOpen, timedMessagePanelOpen: false },
      () => this.props.onToggleEmojiPanel(this.state.emojiPanelOpen)
    );
  };

  handleFileUploadChange = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    const { files } = e.target;
    this.props.onSelectFiles(Object.keys(files).map((key) => files[key]));
  };

  handleFileUploadClick = (e) => {
    e.target.value = null;
    this.setState({ emojiPanelOpen: false, timedMessagePanelOpen: false });
    this.props.handleEnableDeleting(false);
  };

  handleEnableDeleting = () => {
    this.setState({ emojiPanelOpen: false, timedMessagePanelOpen: false });
    this.props.handleEnableDeleting(!this.props.deletingEnabled);
  };

  render() {
    const {
      intl: { formatMessage },
      deletingEnabled,
      deleteMessages,
      conversationId,
      timedMessage,
      onEmojiSelect,
      size,
      repliedMessage,
    } = this.props;
    const { emojiPanelOpen, timedMessagePanelOpen } = this.state;
    const interlocutor =
      retrieveInterlocutorIdFromConversationId(conversationId);

    const bottom = repliedMessage
      ? CHAT_TEXTAREA_HEIGHTS[size] + CHAT_REPLY_TEXTAREA_HEIGHTS[size]
      : CHAT_TEXTAREA_HEIGHTS[size];

    return (
      <>
        {emojiPanelOpen && (
          <ErrorBoundaryChat
            height={CHAT_EMOJI_PANEL_HEIGHTS[size]}
            bottom={bottom + CHAT_TOOLBAR_HEIGHT + 5}
          >
            <React.Suspense fallback={<div />}>
              <ChatEmojisTab
                size={size}
                onSelect={onEmojiSelect}
                conversationId={conversationId}
              />
            </React.Suspense>
          </ErrorBoundaryChat>
        )}
        {timedMessagePanelOpen && (
          <div
            className="row no-gutters border bg-white"
            style={{
              position: 'absolute',
              bottom: `${bottom + CHAT_TOOLBAR_HEIGHT + 5}px`,
              right: '30px',
            }}
          >
            <ul style={{ listStyle: 'none' }} className="m-0 p-0">
              {TIMED_MESSAGES_SECONDS.map((item, idx) => (
                <li key={idx} style={{ padding: '2px', paddingRight: '7px' }}>
                  <span
                    style={{ cursor: 'pointer', position: 'relative' }}
                    onClick={() => this.selectTimedMessage(item.value)}
                  >
                    <Icon
                      name="stopwatch"
                      width={20}
                      height={20}
                      color="var(--yn-blue-dark)"
                    >
                      {item.value
                        ? formatMessage(messages[`timeout${item.value}`])
                        : formatMessage(messages.noTimeout)}
                    </Icon>
                    {item.value && (
                      <span
                        style={{
                          position: 'absolute',
                          height: '10px',
                          width: '20px',
                          fontSize: '10px',
                          right: '-6px',
                          bottom: '2px',
                          lineHeight: '1',
                          background: 'var(--white)',
                          color: 'var(--yn-blue-dark)',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {item.label}
                      </span>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div
          className="p-1"
          style={{
            position: 'absolute',
            bottom: `${bottom}px`,
            backgroundColor: 'white',
            left: 3,
            right: 2,
            height: `${CHAT_TOOLBAR_HEIGHT}px`,
          }}
        >
          <ul className="nav justify-content-end">
            {deletingEnabled && (
              <li className="nav-item  pr-1">
                <span onClick={deleteMessages}>
                  <Icon
                    name="bin-1"
                    width={20}
                    height={20}
                    color="var(--yn-blue-dark)"
                  >
                    {formatMessage(messages.deleteTooltip)}
                  </Icon>
                </span>
              </li>
            )}
            <li className="nav-item pr-1">
              <span onClick={this.handleEnableDeleting}>
                <Icon
                  name="bubble-chat-remove-2"
                  width={20}
                  height={20}
                  color="var(--yn-blue-dark)"
                >
                  {formatMessage(
                    deletingEnabled
                      ? messages.disableDeletingTooltip
                      : messages.enableDeletingTooltip
                  )}
                </Icon>
              </span>
            </li>
            <li className="nav-item pr-1">
              <label htmlFor={`FileInput-${conversationId}`}>
                <Icon
                  name="paperclip-1"
                  width={20}
                  height={20}
                  color="var(--yn-blue-dark)"
                >
                  {formatMessage(messages.attachmentTooltip)}
                </Icon>
              </label>
              <input
                type="file"
                id={`FileInput-${conversationId}`}
                multiple
                onChange={this.handleFileUploadChange}
                onClick={this.handleFileUploadClick}
                style={{ display: 'none' }}
              />
            </li>
            {interlocutor.userId && (
              <li className={`nav-item ${timedMessage ? 'pr-2' : 'pr-1'}`}>
                <span
                  onClick={this.toggleTimedMessage}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <Icon
                    name="stopwatch"
                    width={20}
                    height={20}
                    color="var(--yn-blue-dark)"
                  >
                    {timedMessage
                      ? formatMessage(messages[`timeout${timedMessage}`])
                      : formatMessage(messages.noTimeout)}
                  </Icon>
                  {timedMessage && (
                    <span
                      style={{
                        position: 'absolute',
                        height: '10px',
                        width: '20px',
                        fontSize: '10px',
                        right: '-6px',
                        bottom: '2px',
                        lineHeight: '1',
                        background: 'var(--white)',
                        color: 'var(--yn-blue-dark)',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {
                        TIMED_MESSAGES_SECONDS.filter(
                          (item) => item.value === timedMessage
                        )[0].label
                      }
                    </span>
                  )}
                </span>
              </li>
            )}
            <li className="nav-item  pr-1">
              <span onClick={this.toggleEmojiTab}>
                <Icon
                  name="smiley-smile-1"
                  width={20}
                  height={20}
                  color="var(--yn-blue-dark)"
                >
                  {formatMessage(messages.emojiTooltip)}
                </Icon>
              </span>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

ChatWindowToolbarBottom.propTypes = {
  deletingEnabled: PropTypes.bool.isRequired,
  handleEnableDeleting: PropTypes.func.isRequired,
  deleteMessages: PropTypes.func.isRequired,
  conversationId: PropTypes.string.isRequired,
  onChangeTimedMessage: PropTypes.func.isRequired,
  timedMessage: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => {
  return {
    repliedMessage: getRepliedMessage(state, ownProps.conversationId),
  };
};

export default injectIntl(connect(mapStateToProps)(ChatWindowToolbarBottom));
