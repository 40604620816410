import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import TextArea from '../../../components/formControls/Textarea';
import {
  calculateSMSNumber,
  SMS_MAX_SMS_SENT_LENGTH,
  SMS_TOTAL_CHARS_LENGTH,
} from '../../../js/sms/SMSUtils';

const messages = defineMessages({
  usedChars: {
    id: 'SMSTextArea.usedChars',
    defaultMessage: 'Used chars:',
  },
  messages: {
    id: 'SMSTextArea.messages',
    defaultMessage: 'Messages:',
  },
  totalChars: {
    id: 'SMSTextArea.totalChars',
    defaultMessage: 'Total chars:',
  },
});

const SMSTextArea = ({
  error,
  text,
  onChange,
  placeholderLength,
  onBlur,
  intl: { formatMessage },
}) => {
  return (
    <>
      <TextArea
        rows={6}
        onChange={onChange}
        onBlur={onBlur}
        value={text}
        error={error}
        id="SMStext"
        name="SMStext"
      />
      <div>
        <span>
          {` ${formatMessage(messages.usedChars)} ${
            text ? text.length + placeholderLength : 0
          } / ${SMS_TOTAL_CHARS_LENGTH}`}
        </span>
        <span className="ml-2">
          {` ${formatMessage(messages.messages)} ${
            text ? calculateSMSNumber(text, placeholderLength) : '1'
          } / ${SMS_MAX_SMS_SENT_LENGTH}`}
        </span>
      </div>
    </>
  );
};

SMSTextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  text: PropTypes.string,
  error: PropTypes.string,
  placeholderLength: PropTypes.number,
};

SMSTextArea.defaultProps = {
  onBlur: null,
  text: '',
  error: '',
  placeholderLength: 0,
};

export default injectIntl(SMSTextArea);
