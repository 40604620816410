export const FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';
export const FETCH_NATIONS_REQUEST = 'FETCH_NATIONS_REQUEST';
export const FETCH_NATIONS_SUCCESS = 'FETCH_NATIONS_SUCCESS';
export const FETCH_NATIONS_FAILURE = 'FETCH_NATIONS_FAILURE';
export const FETCH_ABOOK_CONTACTS_REQUEST = 'FETCH_ABOOK_CONTACTS_REQUEST';
export const FETCH_ABOOK_CONTACTS_SUCCESS = 'FETCH_ABOOK_CONTACTS_SUCCESS';
export const FETCH_ABOOK_CONTACTS_FAILURE = 'FETCH_ABOOK_CONTACTS_FAILURE';
export const SAVE_ABOOK_CONTACT_REQUEST = 'SAVE_ABOOK_CONTACT_REQUEST';
export const SAVE_ABOOK_CONTACT_SUCCESS = 'SAVE_ABOOK_CONTACT_SUCCESS';
export const SAVE_ABOOK_CONTACT_FAILURE = 'SAVE_ABOOK_CONTACT_FAILURE';
export const FETCH_ABOOK_INITIALS_REQUEST = 'FETCH_ABOOK_INITIALS_REQUEST';
export const FETCH_ABOOK_INITIALS_SUCCESS = 'FETCH_ABOOK_INITIALS_SUCCESS';
export const FETCH_ABOOK_INITIALS_FAILURE = 'FETCH_ABOOK_INITIALS_FAILURE';
export const SET_ABOOK_FILTER = 'SET_ABOOK_FILTER';
export const SET_ABOOK_INITIAL = 'SET_ABOOK_INITIAL';
export const FETCH_ABOOK_TAGS_REQUEST = 'FETCH_ABOOK_TAGS_REQUEST';
export const FETCH_ABOOK_TAGS_SUCCESS = 'FETCH_ABOOK_TAGS_SUCCESS';
export const FETCH_ABOOK_TAGS_FAILURE = 'FETCH_ABOOK_TAGS_FAILURE';
export const FETCH_ABOOK_CONTACT_REQUEST = 'FETCH_ABOOK_CONTACT_REQUEST';
export const FETCH_ABOOK_CONTACT_SUCCESS = 'FETCH_ABOOK_CONTACT_SUCCESS';
export const FETCH_ABOOK_CONTACT_FAILURE = 'FETCH_ABOOK_CONTACT_FAILURE';
export const DELETE_ABOOK_CONTACT_REQUEST = 'DELETE_ABOOK_CONTACT_REQUEST';
export const DELETE_ABOOK_CONTACT_SUCCESS = 'DELETE_ABOOK_CONTACT_SUCCESS';
export const DELETE_ABOOK_CONTACT_FAILURE = 'DELETE_ABOOK_CONTACT_FAILURE';
export const FETCH_PINNED_GROUPS_SUCCESS = 'FETCH_PINNED_GROUPS_SUCCESS';
export const DELETE_MASSIVE_CONTACTS_REQUEST =
  'DELETE_MASSIVE_CONTACTS_REQUEST';
export const DELETE_MASSIVE_CONTACTS_SUCCESS =
  'DELETE_MASSIVE_CONTACTS_SUCCESS';
export const DELETE_MASSIVE_CONTACTS_FAILURE =
  'DELETE_MASSIVE_CONTACTS_FAILURE';
export const FETCH_CACHED_CONTACT_SUCCESS = 'FETCH_CACHED_CONTACT_SUCCESS';
export const IMPORT_ABOOK_CONTACT_REQUEST = 'IMPORT_ABOOK_CONTACT_REQUEST';
export const IMPORT_ABOOK_CONTACT_SUCCESS = 'IMPORT_ABOOK_CONTACT_SUCCESS';
export const IMPORT_ABOOK_CONTACT_FAILURE = 'IMPORT_ABOOK_CONTACT_FAILURE';
export const CANCEL_IMPORT_ABOOK_CONTACT_REQUEST =
  'CANCEL_ABOOK_CONTACT_REQUEST';
export const CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS =
  'CANCEL_ABOOK_CONTACT_SUCCESS';
export const CANCEL_IMPORT_ABOOK_CONTACT_FAILURE =
  'CANCEL_ABOOK_CONTACT_FAILURE';
export const CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST =
  'CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST';
export const CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST =
  'CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST';
export const CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS =
  'CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS';
export const CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE =
  'CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE';
export const OPEN_IMPORT_ABOOK_CONTACT_MODAL =
  'OPEN_IMPORT_ABOOK_CONTACT_MODAL';
export const TOGGLE_IMPORT_ABOOK_CONTACT_MODAL =
  'TOGGLE_IMPORT_ABOOK_CONTACT_MODAL';
export const ABOOK_INSERT_NOTIFICATION = 'ABOOK_INSERT_NOTIFICATION';
export const ABOOK_UPDATE_NOTIFICATION = 'ABOOK_UPDATE_NOTIFICATION';
export const ABOOK_DELETE_NOTIFICATION = 'ABOOK_DELETE_NOTIFICATION';
export const ABOOK_IMPORT_NOTIFICATION = 'ABOOK_IMPORT_NOTIFICATION';
export const ABOOK_BULK_DELETE_NOTIFICATION = 'ABOOK_BULK_DELETE_NOTIFICATION';
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';
export const FETCH_IMPORT_STATUS_REQUEST = 'FETCH_IMPORT_STATUS_REQUEST';
export const FETCH_IMPORT_STATUS_SUCCESS = 'FETCH_IMPORT_STATUS_SUCCESS';
export const FETCH_IMPORT_STATUS_FAILURE = 'FETCH_IMPORT_STATUS_FAILURE';
export const IMPORT_ABOOK_BATCH_COMPLETED = 'IMPORT_ABOOK_BATCH_COMPLETED';
export const FETCH_ATECO_CODES_REQUEST = 'FETCH_ATECO_CODES_REQUEST';
export const FETCH_ATECO_CODES_SUCCESS = 'FETCH_ATECO_CODES_SUCCESS';
export const FETCH_ATECO_CODES_FAILURE = 'FETCH_ATECO_CODES_FAILURE';
export const FETCH_ABOOK_LISTS_REQUEST = 'FETCH_ABOOK_LISTS_REQUEST';
export const FETCH_ABOOK_LISTS_SUCCESS = 'FETCH_ABOOK_LISTS_SUCCESS';
export const FETCH_ABOOK_LISTS_FAILURE = 'FETCH_ABOOK_LISTS_FAILURE';
export const FETCH_ABOOK_LIST_REQUEST = 'FETCH_ABOOK_LIST_REQUEST';
export const FETCH_ABOOK_LIST_SUCCESS = 'FETCH_ABOOK_LIST_SUCCESS';
export const FETCH_ABOOK_LIST_FAILURE = 'FETCH_ABOOK_LIST_FAILURE';
export const SAVE_ABOOK_LIST_REQUEST = 'SAVE_ABOOK_LIST_REQUEST';
export const SAVE_ABOOK_LIST_SUCCESS = 'SAVE_ABOOK_LIST_SUCCESS';
export const SAVE_ABOOK_LIST_FAILURE = 'SAVE_ABOOK_LIST_FAILURE';
export const DELETE_ABOOK_LIST_REQUEST = 'DELETE_ABOOK_LIST_REQUEST';
export const DELETE_ABOOK_LIST_SUCCESS = 'DELETE_ABOOK_LIST_SUCCESS';
export const DELETE_ABOOK_LIST_FAILURE = 'DELETE_ABOOK_LIST_FAILURE';
export const FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST =
  'FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST';
export const FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS =
  'FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS';
export const FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE =
  'FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE';
export const FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST =
  'FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST';
export const FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS =
  'FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS';
export const FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE =
  'FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE';
export const FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST =
  'FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST';
export const FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS =
  'FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS';
export const FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE =
  'FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE';
export const FETCH_ABOOK_NUMBER_REQUEST = 'FETCH_ABOOK_NUMBER_REQUEST';
export const FETCH_ABOOK_NUMBER_SUCCESS = 'FETCH_ABOOK_NUMBER_SUCCESS';
export const FETCH_ABOOK_NUMBER_FAILURE = 'FETCH_ABOOK_NUMBER_FAILURE';
export const FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST =
  'FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST';
export const FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS =
  'FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS';
export const FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE =
  'FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE';
export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
