import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  getPbxPhoneRulesFilter,
  getRulesNumber,
  getSelectedPbxForRuleManagement,
} from '../../../../js/phoneRules/selectors';
import PhoneRulesShowList from '../PhoneRulesShowList';
import GlobalErrorPage from '../../../pages/GlobalErrorPage';
import ToastMessage from '../../../../components/messages/ToastMessage';
import {
  deletePbxPhoneRuleRequest,
  togglePbxEnabledPhoneRuleRequest,
  fetchPbxPhoneRulesRequest,
} from '../../../../js/phoneRules/actions';
import {
  getPbxs,
  getPbxSelectOptions,
  hasGrants,
} from '../../../../js/me/selectors';
import {
  getFetchPbxRoutesError,
  getFetchPbxRoutesLoaded,
  getMyPbxId,
  isPhoneInited,
} from '../../../../js/phone/selectors';
import { fetchPbxRoutesRequest } from '../../../../js/phone/actions';
import { PhoneRulesEnums } from '../../../../js/phoneRules/PhoneRulesUtils';
import PhoneRulesFilter from '../PhoneRulesFilter';
import HelpButton from '../../../../components/HelpButton';

const messages = defineMessages({
  pbx: {
    id: 'PhoneRulesShowPage.label.pbx',
    defaultMessage: 'Pbx',
  },
  saveError: {
    id: 'PhoneRulesShowPage.saveError',
    defaultMessage: 'Saving error',
  },
  deleteError: {
    id: 'PhoneRulesShowPage.deleteError',
    defaultMessage: 'Deleting error',
  },
  createRule: {
    id: 'PhoneRulesShowPage.createRule',
    defaultMessage: 'New rule',
  },
});

class PbxPhoneRulesShowNethesis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) this.fetch();
  }

  fetch = () => {
    const { fetchPbxRules, filter, context } = this.props;
    const params = {};
    if (context === PhoneRulesEnums.SettingsContext.users && filter.user) {
      params.user = filter.user;
    } else if (
      context === PhoneRulesEnums.SettingsContext.pbx &&
      filter.route
    ) {
      params.route = filter.route;
    }
    fetchPbxRules(params);
  };

  render() {
    const {
      context,
      intl: { formatMessage },
      loading,
      loadServerError,
      saveServerError,
      deleteServerError,
      deleteFunc,
      toggleEnabledFunc,
      canEdit,
      fetchingRoutes,
      fetchingRoutesError,
    } = this.props;

    return (
      <>
        <div className="text-right">
          <HelpButton fileName={`pbx-settings-${context}-NETHESIS`} />
        </div>
        {loadServerError || fetchingRoutesError ? (
          <GlobalErrorPage />
        ) : (
          <>
            {saveServerError && (
              <ToastMessage
                type="danger"
                text={formatMessage(messages.saveError)}
                messageCode={saveServerError}
              />
            )}
            <PhoneRulesFilter context={context} />
            {!loading && !fetchingRoutes && canEdit && (
              <div className="text-right mb-3">
                <Link
                  to={`/pbx/settings/${
                    context === PhoneRulesEnums.SettingsContext.pbx
                      ? 'switchboard'
                      : 'users'
                  }/new`}
                >
                  <button type="button" className="btn btn-primary">
                    {formatMessage(messages.createRule)}
                  </button>
                </Link>
              </div>
            )}
            {deleteServerError && (
              <ToastMessage
                type="danger"
                text={formatMessage(messages.deleteError)}
                messageCode={deleteServerError}
              />
            )}
            <PhoneRulesShowList
              context={context}
              deleteFunc={deleteFunc}
              loading={loading || fetchingRoutes}
              toggleEnabledFunc={toggleEnabledFunc}
            />
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    saveServerError: state.phoneRules.pbx.errorSaving,
    saveServerSuccess: state.phoneRules.pbx.successSaving,
    deleteServerError: state.phoneRules.pbx.errorDeleting,
    deleteServerSuccess: state.phoneRules.pbx.successDeleting,
    loadServerError: state.phoneRules.pbx.errorLoading,
    loading: !state.phoneRules.pbx.loaded,
    rulesNumber: getRulesNumber(state, PhoneRulesEnums.SettingsContext.pbx),
    pbxsList: getPbxs(state),
    pbxsSelectList: getPbxSelectOptions(state, true),
    selectedPbx: getSelectedPbxForRuleManagement(state),
    canEdit: hasGrants(state, 'PBX_HOLIDAYS_WRITE'),
    myPbxId: getMyPbxId(state),
    // supplier: getSupplier(state),
    phoneInited: isPhoneInited(state),
    fetchingRoutesError: getFetchPbxRoutesError(state),
    fetchingRoutes: !getFetchPbxRoutesLoaded(state),
    filter: getPbxPhoneRulesFilter(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    deleteFunc: deletePbxPhoneRuleRequest,
    toggleEnabledFunc: togglePbxEnabledPhoneRuleRequest,
    fetchPbxRules: fetchPbxPhoneRulesRequest,
    fetchRoutes: fetchPbxRoutesRequest,
  })(PbxPhoneRulesShowNethesis)
);
