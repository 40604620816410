import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PasswordSettingsForm from '../containers/forms/PasswordSettingsForm';
import { updatePasswordRequest } from '../js/me/actions';
import { logoutRequest } from '../js/auth/actions';

const messages = defineMessages({
  title: {
    id: 'UpdatePasswordModal.title',
    defaultMessage: 'Update password',
  },
  infoExpired: {
    id: 'UpdatePasswordModal.infoExpired',
    defaultMessage:
      'The password has remained the same for more than six months, you are advised to security reasons.',
  },
  infoUpdated: {
    id: 'UpdatePasswordModal.infoUpdated',
    defaultMessage:
      'Insert the temporary password and then choose a new one that match the rules.',
  },
  logoutText: {
    id: 'UpdatePasswordModal.logoutText',
    defaultMessage: 'or ',
  },
  logoutLink: {
    id: 'UpdatePasswordModal.logoutLink',
    defaultMessage: 'logout',
  },
});

class UpdatePasswordModal extends Component {
  onSubmit = (data) => {
    this.props.submit(data);
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    const {
      serverError,
      serverSuccess,
      loading,
      pwdExpired,
      pwdChanged,
      show,
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal isOpen={show}>
        <ModalHeader>{formatMessage(messages.title)}</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            {pwdChanged
              ? formatMessage(messages.infoUpdated)
              : pwdExpired
              ? formatMessage(messages.infoExpired)
              : null}
          </div>
          <PasswordSettingsForm
            onSubmit={(data) => this.onSubmit(data)}
            error={serverError}
            success={serverSuccess}
            loading={loading}
            onLogout={this.logout}
          />
        </ModalBody>
      </Modal>
    );
  }
}

UpdatePasswordModal.propTypes = {
  show: PropTypes.bool,
  pwdExpired: PropTypes.bool,
  pwdChanged: PropTypes.bool,
};

UpdatePasswordModal.defaultProps = {
  show: false,
  pwdExpired: false,
  pwdChanged: false,
};

function mapStateToProps(state) {
  return {
    serverError: state.me.updatePassword.passwordError,
    serverSuccess: state.me.updatePassword.passwordSuccess,
    loading: !state.me.updatePassword.loaded,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    submit: updatePasswordRequest,
    logout: logoutRequest,
  })(UpdatePasswordModal)
);
