import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Abook from '.';
import AbookListsEdit from './AbookListsEdit';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const AbookListsEditPage = ({
  match: {
    params: { id },
  },
}) => (
  <Abook>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="abook-lists-edit" />
          </div>
        </div>
        <AbookListsEdit id={+id} />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookListsEditPage;
