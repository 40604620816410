import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorBadge from './ColorBadge';

class ColorSelector extends Component {
  selectColor = (color) => {
    if (this.props.readOnly) {
      return;
    }
    this.props.selectColor(color);
  };

  render() {
    const {
      gradient,
      colors,
      selected,
      colorsWidth,
      colorsHeight,
      maxWidth,
      readOnly,
    } = this.props;
    const items = colors.map((color, index) => (
      <span
        onClick={() => this.selectColor(color)}
        key={color}
        className="p-1"
        style={{
          cursor: readOnly ? 'default' : 'pointer',
        }}
      >
        <ColorBadge
          color={color}
          gradient={gradient}
          selected={index === selected}
          width={colorsWidth}
          height={colorsHeight}
        />
      </span>
    ));
    return (
      <div
        style={{
          padding: '0.375rem 0.375rem',
          lineHeight: 1,
          maxWidth: maxWidth ? `${maxWidth}px` : 'unset',
        }}
      >
        {items}
      </div>
    );
  }
}

ColorSelector.propTypes = {
  selectColor: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  colorsWidth: PropTypes.string,
  colorsHeight: PropTypes.string,
  selected: PropTypes.number,
  maxWidth: PropTypes.number,
  readOnly: PropTypes.bool,
};

ColorSelector.defaultProps = {
  selected: 0,
};

export default ColorSelector;
