import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { hasGrants } from '../../../js/me/selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HotelCallsLog = React.lazy(() => import('./HotelCallsLog'));
const messages = defineMessages({
  title: {
    id: 'HotelCallsLog.title',
    defaultMessage: 'Calls list',
  },
});

const HotelCallsLogPage = (props) => (
  <React.Fragment>
    {!props.canSee ? (
      <GlobalErrorPage code={403} />
    ) : (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>{props.intl.formatMessage(messages.title)}</SectionTitle>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <HotelCallsLog />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    )}
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    canSee: hasGrants(state, 'CDR_ENABLED') && state.me.customer.hotelEnabled,
  };
}

HotelCallsLogPage.propTypes = {
  canSee: PropTypes.bool.isRequired,
};

export default injectIntl(connect(mapStateToProps)(HotelCallsLogPage));
