import { SMSEnums } from './SMSUtils';

export const getSMSFilter = (state) => state.sms.filter;
export const getSMSCurrentPage = (state) => state.sms.SMSList.currentPage;
export const getSMSTotal = (state) => state.sms.SMSList.total;
export const getSMSsById = (state) => state.sms.SMSList.byId;
export const getSMSById = (state, id) => state.sms.SMSList.byId[id];
export const getSMSIds = (state) => state.sms.SMSList.allIds;
export const getFetchSMSListLoaded = (state) => state.sms.fetchSMSListLoaded;
export const getFetchSMSListError = (state) => state.sms.fetchSMSListError;
export const getSendSMSLoaded = (state) => state.sms.sendSMSLoaded;
export const getSendSMSError = (state) => state.sms.sendSMSError;
export const getFetchSMSCreditsLoaded = (state) =>
  state.sms.fetchSMSCreditsLoaded;
export const getFetchSMSCreditsError = (state) =>
  state.sms.fetchSMSCreditsError;
export const getSMSCredits = (state) => state.sms.SMSCredits;
export const showIncomingSMSAlert = (state) =>
  state.sms.SMSCredits <
  state.sms.nextMonthEventSms + state.sms.nextMonthScheduledSms;
export const isSMSCreditsFetched = (state) => state.sms.SMSCreditsFetched;
export const getSMSPackages = (state) => state.sms.packages;
export const usingSMSAlias = (state) => state.sms.useAlias;
export const registerSMSAlias = (state) => state.sms.registerAlias;
export const getSMSCampaignsDisabled = (state) => state.sms.campaignsDisabled;
export const getSMSsScheduledById = (state) => state.sms.SMSScheduled.byId;
export const getSMSScheduledById = (state, id) =>
  state.sms.SMSScheduled.byId[id];
export const getSMSScheduledIds = (state) => state.sms.SMSScheduled.allIds;
export const getFetchSMSScheduledLoaded = (state) =>
  state.sms.fetchSMSScheduledLoaded;
export const getFetchSMSScheduledError = (state) =>
  state.sms.fetchSMSScheduledError;
export const getSaveSMSScheduledLoaded = (state) =>
  state.sms.saveSMSScheduledLoaded;
export const getSaveSMSScheduledError = (state) =>
  state.sms.saveSMSScheduledError;
export const getDeleteSMSScheduledLoaded = (state) =>
  state.sms.deleteSMSScheduledLoaded;
export const getDeleteSMSScheduledError = (state) =>
  state.sms.deleteSMSScheduledError;
export const getFetchSMSAliasLoaded = (state) => state.sms.fetchSMSAliasLoaded;
export const getFetchSMSAliasError = (state) => state.sms.fetchSMSAliasError;
export const getSaveSMSAliasLoaded = (state) => state.sms.saveSMSAliasLoaded;
export const getSaveSMSAliasError = (state) => state.sms.saveSMSAliasError;
export const getSMSsAliasById = (state) => state.sms.SMSAlias.byId;
export const getSMSAliasById = (state, id) => state.sms.SMSAlias.byId[id];
export const getSMSAliasIds = (state) => state.sms.SMSAlias.allIds;
export const getValidSMSAliasIds = (state) =>
  state.sms.SMSAlias.allIds
    ? state.sms.SMSAlias.allIds.filter(
        (id) =>
          state.sms.SMSAlias.byId[id].status ===
          SMSEnums.AliasStatus.AGCOM_CONFIRMED
      )
    : [];
export const getSMSCampaignsById = (state) => state.sms.SMSCampaigns.byId;
export const getSMSCampaignById = (state, id) =>
  state.sms.SMSCampaigns.byId[id];
export const getSMSCampaignsIds = (state) => state.sms.SMSCampaigns.allIds;
export const getFetchSMSCampaignsLoaded = (state) =>
  state.sms.fetchSMSCampaignsLoaded;
export const getFetchSMSCampaignsError = (state) =>
  state.sms.fetchSMSCampaignsError;
export const getFetchSMSCampaignLoaded = (state) =>
  state.sms.fetchSMSCampaignLoaded;
export const getFetchSMSCampaignError = (state) =>
  state.sms.fetchSMSCampaignError;
export const getSaveSMSCampaignLoaded = (state) =>
  state.sms.saveSMSCampaignLoaded;
export const getSaveSMSCampaignError = (state) =>
  state.sms.saveSMSCampaignError;
export const getSMSCampaignsCurrentPage = (state) =>
  state.sms.SMSCampaigns.currentPage;
export const getSMSCampaignsTotal = (state) => state.sms.SMSCampaigns.total;
export const getDeleteSMSCampaignLoaded = (state) =>
  state.sms.deleteSMSCampaignLoaded;
export const getDeleteSMSCampaignError = (state) =>
  state.sms.deleteSMSCampaignError;
export const getBuySMSPackagesLoaded = (state) =>
  state.sms.buySMSPackagesLoaded;
export const getBuySMSPackagesError = (state) => state.sms.buySMSPackagesError;
export const usingSMSLink = (state) => state.sms.useLink;
