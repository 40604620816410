import { request } from '../request';

export default {
  getVboxMessages: (params) =>
    request({
      url: '/vbox/messages',
      method: 'GET',
      params,
    }),
  deleteVboxMessage: (id) =>
    request({
      url: `/vbox/messages/${id}`,
      method: 'DELETE',
    }),
  getTvoxSounds: () =>
    request({
      url: 'vbox/tvox-sounds',
      method: 'GET',
    }),
  getTvoxPrice: (payload) =>
    request({
      url: '/vbox/tvox-price',
      method: 'POST',
      data: payload,
    }),
  purchaseTvoxMessage: (payload) =>
    request({
      url: '/vbox/tvox-purchase',
      method: 'POST',
      data: payload,
    }),
  getArchivedGreetingMessages: (params) =>
    request({
      url: '/vbox/archives',
      method: 'GET',
      params,
    }),
  getActiveGreetingMessages: (params) =>
    request({
      url: '/vbox/actives',
      method: 'GET',
      params,
    }),
  savePbxGreetingMessage: (params) =>
    request({
      url: '/vbox/pbx-greeting',
      method: 'POST',
      data: params,
    }),
  activatePbxGreetingMessage: (params) =>
    request({
      url: '/vbox/activate-pbx-greeting',
      method: 'POST',
      data: params,
    }),
  deleteArchivedGreetingMessage: (id) =>
    request({
      url: `/vbox/archives/${id}`,
      method: 'DELETE',
    }),
  saveOwnGreetingMessage: (payload) =>
    request({
      url: '/me/greeting',
      method: 'POST',
      data: payload,
    }),
  listenVboxMessage: (id) =>
    request({
      url: `/vbox/listen`,
      method: 'POST',
      data: {
        id,
      },
    }),
};
