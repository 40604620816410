export const FETCH_VIDEOCALLS_LIST_REQUEST = 'FETCH_VIDEOCALLS_LIST_REQUEST';
export const FETCH_VIDEOCALLS_LIST_SUCCESS = 'FETCH_VIDEOCALLS_LIST_SUCCESS';
export const FETCH_VIDEOCALLS_LIST_FAILURE = 'FETCH_VIDEOCALLS_LIST_FAILURE';
export const SEND_GUEST_EMAIL_ADDRESS_REQUEST =
  'SEND_GUEST_EMAIL_ADDRESS_REQUEST';
export const SEND_GUEST_EMAIL_ADDRESS_SUCCESS =
  'SEND_GUEST_EMAIL_ADDRESS_SUCCESS';
export const SEND_GUEST_EMAIL_ADDRESS_FAILURE =
  'SEND_GUEST_EMAIL_ADDRESS_FAILURE';

export const SAVE_SCHEDULED_VIDEOCALL_REQUEST =
  'SAVE_SCHEDULED_VIDEOCALL_REQUEST';
export const SAVE_SCHEDULED_VIDEOCALL_SUCCESS =
  'SAVE_SCHEDULED_VIDEOCALL_SUCCESS';
export const SAVE_SCHEDULED_VIDEOCALL_FAILURE =
  'SAVE_SCHEDULED_VIDEOCALL_FAILURE';
export const FETCH_SCHEDULED_VIDEOCALLS_REQUEST =
  'FETCH_SCHEDULED_VIDEOCALLS_REQUEST';
export const FETCH_SCHEDULED_VIDEOCALLS_SUCCESS =
  'FETCH_SCHEDULED_VIDEOCALLS_SUCCESS';
export const FETCH_SCHEDULED_VIDEOCALLS_FAILURE =
  'FETCH_SCHEDULED_VIDEOCALLS_FAILURE';
export const ENTER_VIDEOCALL_REQUEST = 'ENTER_VIDEOCALL_REQUEST';
export const ENTER_VIDEOCALL_SUCCESS = 'ENTER_VIDEOCALL_SUCCESS';
export const ENTER_VIDEOCALL_FAILURE = 'ENTER_VIDEOCALL_FAILURE';
export const LEAVE_VIDEOCALL_SUCCESS = 'LEAVE_VIDEOCALL_SUCCESS';
export const JOIN_VIDEOCALL_SUCCESS = 'JOIN_VIDEOCALL_SUCCESS';
export const CHECK_VIDEOCALL_AUTHORIZATION_REQUEST =
  'CHECK_VIDEOCALL_AUTHORIZATION_REQUEST';
export const CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS =
  'CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS';
export const CHECK_VIDEOCALL_AUTHORIZATION_FAILURE =
  'CHECK_VIDEOCALL_AUTHORIZATION_FAILURE';
export const DELETE_SCHEDULED_VIDEOCALL_REQUEST =
  'DELETE_SCHEDULED_VIDEOCALL_REQUEST';
export const DELETE_SCHEDULED_VIDEOCALL_SUCCESS =
  'DELETE_SCHEDULED_VIDEOCALL_SUCCESS';
export const DELETE_SCHEDULED_VIDEOCALL_FAILURE =
  'DELETE_SCHEDULED_VIDEOCALL_FAILURE';
export const INVITE_VIDEOCALL_REQUEST = 'INVITE_VIDEOCALL_REQUEST';
export const INVITE_VIDEOCALL_SUCCESS = 'INVITE_VIDEOCALL_SUCCESS';
export const INVITE_VIDEOCALL_FAILURE = 'INVITE_VIDEOCALL_FAILURE';
export const FETCH_VIDEOCALL_SETTINGS_REQUEST =
  'FETCH_VIDEOCALL_SETTINGS_REQUEST';
export const FETCH_VIDEOCALL_SETTINGS_SUCCESS =
  'FETCH_VIDEOCALL_SETTINGS_SUCCESS';
export const FETCH_VIDEOCALL_SETTINGS_FAILURE =
  'FETCH_VIDEOCALL_SETTINGS_FAILURE';
export const SAVE_VIDEOCALL_SETTINGS_REQUEST =
  'SAVE_VIDEOCALL_SETTINGS_REQUEST';
export const SAVE_VIDEOCALL_SETTINGS_SUCCESS =
  'SAVE_VIDEOCALL_SETTINGS_SUCCESS';
export const SAVE_VIDEOCALL_SETTINGS_FAILURE =
  'SAVE_VIDEOCALL_SETTINGS_FAILURE';
export const VIDEOCALL_REMINDER_NOTIFICATION =
  'VIDEOCALL_REMINDER_NOTIFICATION';
export const SAVE_VIDEOCALL_CHAT_REQUEST = 'SAVE_VIDEOCALL_CHAT_REQUEST';
export const SAVE_VIDEOCALL_CHAT_SUCCESS = 'SAVE_VIDEOCALL_CHAT_SUCCESS';
export const SAVE_VIDEOCALL_CHAT_FAILURE = 'SAVE_VIDEOCALL_CHAT_FAILURE';
export const FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS =
  'FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS';
export const SET_VIDEOCALL_CHAT_FLAG = 'SET_VIDEOCALL_CHAT_FLAG';
export const START_USER_VIDEOCALL_REQUEST = 'START_USER_VIDEOCALL_REQUEST';
export const START_USER_VIDEOCALL_SUCCESS = 'START_USER_VIDEOCALL_SUCCESS';
export const START_USER_VIDEOCALL_FAILURE = 'START_USER_VIDEOCALL_FAILURE';
export const INCOMING_VIDEOCALL = 'INCOMING_VIDEOCALL';
export const ANSWER_VIDEOCALL_REQUEST = 'ANSWER_VIDEOCALL_REQUEST';
export const ANSWER_VIDEOCALL_SUCCESS = 'ANSWER_VIDEOCALL_SUCCESS';
export const ANSWER_VIDEOCALL_FAILURE = 'ANSWER_VIDEOCALL_FAILURE';
export const ANSWER_VIDEOCALL_CC = 'ANSWER_VIDEOCALL_CC';
export const ANSWERED_VIDEOCALL = 'ANSWERED_VIDEOCALL';
export const DECLINE_VIDEOCALL_REQUEST = 'DECLINE_VIDEOCALL_REQUEST';
export const DECLINE_VIDEOCALL_SUCCESS = 'DECLINE_VIDEOCALL_SUCCESS';
export const DECLINE_VIDEOCALL_FAILURE = 'DECLINE_VIDEOCALL_FAILURE';
export const DECLINE_VIDEOCALL_CC = 'DECLINE_VIDEOCALL_CC';
export const DECLINED_VIDEOCALL = 'DECLINED_VIDEOCALL';
export const SET_DND_STATUS_REQUEST = 'SET_DND_STATUS_REQUEST';
export const SET_DND_STATUS_SUCCESS = 'SET_DND_STATUS_SUCCESS';
export const SET_DND_STATUS_FAILURE = 'SET_DND_STATUS_FAILURE';
export const RECEIVE_SET_DND_STATUS = 'RECEIVE_SET_DND_STATUS';
export const FETCH_VIDEOCALL_INVITATIONS_REQUEST =
  'FETCH_VIDEOCALL_INVITATIONS_REQUEST';
export const FETCH_VIDEOCALL_INVITATIONS_SUCCESS =
  'FETCH_VIDEOCALL_INVITATIONS_SUCCESS';
export const FETCH_VIDEOCALL_INVITATIONS_FAILURE =
  'FETCH_VIDEOCALL_INVITATIONS_FAILURE';
