import {
  FETCH_VIDEOCALLS_LIST_REQUEST,
  FETCH_VIDEOCALLS_LIST_SUCCESS,
  FETCH_VIDEOCALLS_LIST_FAILURE,
  SEND_GUEST_EMAIL_ADDRESS_REQUEST,
  SEND_GUEST_EMAIL_ADDRESS_SUCCESS,
  SEND_GUEST_EMAIL_ADDRESS_FAILURE,
  SAVE_SCHEDULED_VIDEOCALL_REQUEST,
  SAVE_SCHEDULED_VIDEOCALL_SUCCESS,
  SAVE_SCHEDULED_VIDEOCALL_FAILURE,
  FETCH_SCHEDULED_VIDEOCALLS_REQUEST,
  FETCH_SCHEDULED_VIDEOCALLS_SUCCESS,
  FETCH_SCHEDULED_VIDEOCALLS_FAILURE,
  ENTER_VIDEOCALL_REQUEST,
  ENTER_VIDEOCALL_SUCCESS,
  ENTER_VIDEOCALL_FAILURE,
  LEAVE_VIDEOCALL_SUCCESS,
  CHECK_VIDEOCALL_AUTHORIZATION_REQUEST,
  CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS,
  CHECK_VIDEOCALL_AUTHORIZATION_FAILURE,
  DELETE_SCHEDULED_VIDEOCALL_REQUEST,
  DELETE_SCHEDULED_VIDEOCALL_SUCCESS,
  DELETE_SCHEDULED_VIDEOCALL_FAILURE,
  INVITE_VIDEOCALL_REQUEST,
  INVITE_VIDEOCALL_SUCCESS,
  INVITE_VIDEOCALL_FAILURE,
  FETCH_VIDEOCALL_SETTINGS_REQUEST,
  FETCH_VIDEOCALL_SETTINGS_SUCCESS,
  FETCH_VIDEOCALL_SETTINGS_FAILURE,
  SAVE_VIDEOCALL_SETTINGS_REQUEST,
  SAVE_VIDEOCALL_SETTINGS_SUCCESS,
  SAVE_VIDEOCALL_SETTINGS_FAILURE,
  VIDEOCALL_REMINDER_NOTIFICATION,
  SAVE_VIDEOCALL_CHAT_REQUEST,
  SAVE_VIDEOCALL_CHAT_SUCCESS,
  SAVE_VIDEOCALL_CHAT_FAILURE,
  JOIN_VIDEOCALL_SUCCESS,
  FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS,
  SET_VIDEOCALL_CHAT_FLAG,
  START_USER_VIDEOCALL_REQUEST,
  START_USER_VIDEOCALL_SUCCESS,
  START_USER_VIDEOCALL_FAILURE,
  INCOMING_VIDEOCALL,
  ANSWER_VIDEOCALL_REQUEST,
  ANSWER_VIDEOCALL_SUCCESS,
  ANSWER_VIDEOCALL_CC,
  DECLINE_VIDEOCALL_SUCCESS,
  DECLINE_VIDEOCALL_REQUEST,
  DECLINE_VIDEOCALL_FAILURE,
  DECLINE_VIDEOCALL_CC,
  ANSWERED_VIDEOCALL,
  DECLINED_VIDEOCALL,
  SET_DND_STATUS_REQUEST,
  SET_DND_STATUS_SUCCESS,
  SET_DND_STATUS_FAILURE,
  RECEIVE_SET_DND_STATUS,
  FETCH_VIDEOCALL_INVITATIONS_REQUEST,
  FETCH_VIDEOCALL_INVITATIONS_SUCCESS,
  FETCH_VIDEOCALL_INVITATIONS_FAILURE,
} from './types';

export const fetchVideocallsListRequest = (payload) => ({
  type: FETCH_VIDEOCALLS_LIST_REQUEST,
  payload,
});
export const fetchVideocallsListSuccess = (data) => ({
  type: FETCH_VIDEOCALLS_LIST_SUCCESS,
  data,
});
export const fetchVideocallsListFailure = (errors) => ({
  type: FETCH_VIDEOCALLS_LIST_FAILURE,
  errors,
});
export const sendGuestEmailAddressRequest = (email) => ({
  type: SEND_GUEST_EMAIL_ADDRESS_REQUEST,
  email,
});
export const sendGuestEmailAddressSuccess = () => ({
  type: SEND_GUEST_EMAIL_ADDRESS_SUCCESS,
});
export const sendGuestEmailAddressFailure = (error) => ({
  type: SEND_GUEST_EMAIL_ADDRESS_FAILURE,
  error,
});

export const saveScheduledVideocallRequest = (payload, byButton) => ({
  type: SAVE_SCHEDULED_VIDEOCALL_REQUEST,
  payload,
  byButton,
});
export const saveScheduledVideocallSuccess = () => ({
  type: SAVE_SCHEDULED_VIDEOCALL_SUCCESS,
});
export const saveScheduledVideocallFailure = (error) => ({
  type: SAVE_SCHEDULED_VIDEOCALL_FAILURE,
  error,
});
export const fetchScheduledVideocallsRequest = () => ({
  type: FETCH_SCHEDULED_VIDEOCALLS_REQUEST,
});
export const fetchScheduledVideocallsSuccess = (data) => ({
  type: FETCH_SCHEDULED_VIDEOCALLS_SUCCESS,
  data,
});
export const fetchScheduledVideocallsFailure = (errors) => ({
  type: FETCH_SCHEDULED_VIDEOCALLS_FAILURE,
  errors,
});
export const enterVideocallRequest = (payload) => ({
  type: ENTER_VIDEOCALL_REQUEST,
  payload,
});
export const enterVideocallSuccess = (payload) => ({
  type: ENTER_VIDEOCALL_SUCCESS,
  payload,
});
export const enterVideocallFailure = (error) => ({
  type: ENTER_VIDEOCALL_FAILURE,
  error,
});
export const joinVideocallSuccess = () => ({
  type: JOIN_VIDEOCALL_SUCCESS,
});
export const leaveVideocallSuccess = () => ({
  type: LEAVE_VIDEOCALL_SUCCESS,
});
export const checkVideocallAuthorizationRequest = (room) => ({
  type: CHECK_VIDEOCALL_AUTHORIZATION_REQUEST,
  room,
});
export const checkVideocallAuthorizationSuccess = (payload) => ({
  type: CHECK_VIDEOCALL_AUTHORIZATION_SUCCESS,
  payload,
});
export const checkVideocallAuthorizationFailure = (error) => ({
  type: CHECK_VIDEOCALL_AUTHORIZATION_FAILURE,
  error,
});
export const deleteScheduledVideocallRequest = (id) => ({
  type: DELETE_SCHEDULED_VIDEOCALL_REQUEST,
  id,
});
export const deleteScheduledVideocallSuccess = (id) => ({
  type: DELETE_SCHEDULED_VIDEOCALL_SUCCESS,
  id,
});
export const deleteScheduledVideocallFailure = (error) => ({
  type: DELETE_SCHEDULED_VIDEOCALL_FAILURE,
  error,
});
export const inviteVideocallRequest = (payload) => ({
  type: INVITE_VIDEOCALL_REQUEST,
  payload,
});
export const inviteVideocallSuccess = () => ({
  type: INVITE_VIDEOCALL_SUCCESS,
});
export const inviteVideocallFailure = (errors) => ({
  type: INVITE_VIDEOCALL_FAILURE,
  errors,
});
export const fetchVideocallSettingsRequest = () => ({
  type: FETCH_VIDEOCALL_SETTINGS_REQUEST,
});
export const fetchVideocallSettingsSuccess = (data) => ({
  type: FETCH_VIDEOCALL_SETTINGS_SUCCESS,
  data,
});
export const fetchVideocallSettingsFailure = (errors) => ({
  type: FETCH_VIDEOCALL_SETTINGS_FAILURE,
  errors,
});
export const saveVideocallSettingsRequest = (payload) => ({
  type: SAVE_VIDEOCALL_SETTINGS_REQUEST,
  payload,
});
export const saveVideocallSettingsSuccess = (data) => ({
  type: SAVE_VIDEOCALL_SETTINGS_SUCCESS,
  data,
});
export const saveVideocallSettingsFailure = (error) => ({
  type: SAVE_VIDEOCALL_SETTINGS_FAILURE,
  error,
});
export const videocallReminderNotification = (payload) => ({
  type: VIDEOCALL_REMINDER_NOTIFICATION,
  payload,
});
export const saveVideocallChatRequest = (room) => ({
  type: SAVE_VIDEOCALL_CHAT_REQUEST,
  room,
});
export const saveVideocallChatSuccess = () => ({
  type: SAVE_VIDEOCALL_CHAT_SUCCESS,
});
export const saveVideocallChatFailure = (error) => ({
  type: SAVE_VIDEOCALL_CHAT_FAILURE,
  error,
});
export const fetchVideocallNotificationsSuccess = (data) => ({
  type: FETCH_VIDEOCALL_NOTIFICATIONS_SUCCESS,
  data,
});
export const setVideocallChatFlag = () => ({
  type: SET_VIDEOCALL_CHAT_FLAG,
});
export const startUserVideocallRequest = (payload) => ({
  type: START_USER_VIDEOCALL_REQUEST,
  payload,
});
export const startUserVideocallSuccess = (data) => ({
  type: START_USER_VIDEOCALL_SUCCESS,
  data,
});
export const startUserVideocallFailure = (payload) => ({
  type: START_USER_VIDEOCALL_FAILURE,
  payload,
});
export const incomingVideocall = (data) => ({
  type: INCOMING_VIDEOCALL,
  data,
});
export const answerVideocallRequest = (payload) => ({
  type: ANSWER_VIDEOCALL_REQUEST,
  payload,
});
export const answerVideocallSuccess = () => ({
  type: ANSWER_VIDEOCALL_SUCCESS,
});
export const answerVideocallFailure = (error) => ({
  type: ANSWER_VIDEOCALL_CC,
  error,
});
export const answerVideocallCc = (payload) => ({
  type: ANSWER_VIDEOCALL_CC,
  payload,
});
export const answeredVideocall = (payload) => ({
  type: ANSWERED_VIDEOCALL,
  payload,
});
export const declineVideocallRequest = (payload) => ({
  type: DECLINE_VIDEOCALL_REQUEST,
  payload,
});
export const declineVideocallSuccess = (payload) => ({
  type: DECLINE_VIDEOCALL_SUCCESS,
  payload,
});
export const declineVideocallFailure = (error) => ({
  type: DECLINE_VIDEOCALL_FAILURE,
  error,
});
export const declineVideocallCc = (payload) => ({
  type: DECLINE_VIDEOCALL_CC,
  payload,
});
export const declinedVideocall = (payload) => ({
  type: DECLINED_VIDEOCALL,
  payload,
});
export const setDndStatusRequest = (enabled) => ({
  type: SET_DND_STATUS_REQUEST,
  enabled,
});
export const setDndStatusSuccess = (enabled) => ({
  type: SET_DND_STATUS_SUCCESS,
  enabled,
});
export const setDndStatusFailure = (error) => ({
  type: SET_DND_STATUS_FAILURE,
  error,
});
export const receiveSetDndStatus = (payload) => ({
  type: RECEIVE_SET_DND_STATUS,
  payload,
});
export const fetchVideocallInvitationsRequest = (id) => ({
  type: FETCH_VIDEOCALL_INVITATIONS_REQUEST,
  id,
});
export const fetchVideocallInvitationsSuccess = (payload) => ({
  type: FETCH_VIDEOCALL_INVITATIONS_SUCCESS,
  payload,
});
export const fetchVideocallInvitationsFailure = (errors) => ({
  type: FETCH_VIDEOCALL_INVITATIONS_FAILURE,
  errors,
});
