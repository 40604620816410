import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import {
  LOGIN_WEBSOCKET,
  SEND_SUBSCRIPTION_FAILURE,
  WEBSOCKET_CONNECTION,
  LOGOUT_WEBSOCKET,
} from './types';
import { INIT_MAIN_SUCCESS } from '../me/types';
import { LOGOUT_SUCCESS, GUEST_LOGIN_SUCCESS } from '../auth/types';
import { UPDATE_NOTIFICATION_SETTINGS_SUCCESS } from '../settings/types';
import { initListener } from './listener';
import ynConf from '../../conf';
import WsManager from './WsManager';
import { WsEnums, generateAckUid } from './WsUtils';
import { NotificationEnums } from '../notifications/NotificationUtils';
import {
  isCalendarNotificationEnabled,
  isDataNotificationEnabled,
  isMailofficeNotificationEnabled,
  isVboxNotificationEnabled,
  isFaxNotificationEnabled,
  isPresencesNotificationEnabled,
  isTicketNotificationEnabled,
  isFileboxNotificationEnabled,
  isVideocallNotificationEnabled,
} from '../settings/selectors';
import { ChatEnums } from '../chat/ChatUtils';
import { PresenceEnums } from '../users/PresenceUtils';
import AckHandler from './AckHandler';
import {
  getMeId,
  isMeVacationsValidator,
  isMePunchingsValidator,
  addressbookGrant,
} from '../me/selectors';
import ChatManager from '../chat/ChatManager';
import { isAuthenticated } from '../auth/selectors';
import { isVacationEnabled } from '../calendar/selectors';
import { isPuncherEnabled } from '../presence/selectors';
import { VideocallsEnums } from '../videocalls/VideocallsUtils';
import VideocallsManager from '../videocalls/VideocallsManager';

export function* loginWebsocket() {
  // connect to the server
  const isLogged = yield select(isAuthenticated);
  const url = isLogged ? ynConf.wsUrl : ynConf.wsGuestUrl;
  const protocols = [];
  const socket = new WebSocket(url, protocols);
  WsManager.initSocket(socket);
  // then create a socket channel
  const socketChannel = yield call(initListener, socket);
  while (true) {
    const payload = yield take(socketChannel);
    if (WsManager.isActive()) {
      if (payload) {
        yield put(payload.action(payload.data));
      }
    } else {
      break;
    }
  }
}

export function* sendSubscription() {
  const userId = yield select(getMeId);
  let payload = {};
  const dataNotificationEnabled = yield select(isDataNotificationEnabled);
  const calendarNotificationEnabled = yield select(
    isCalendarNotificationEnabled
  );
  const faxNotificationEnabled = yield select(isFaxNotificationEnabled);
  const vboxNotificationEnabled = yield select(isVboxNotificationEnabled);
  const mailofficeNotificationEnabled = yield select(
    isMailofficeNotificationEnabled
  );
  const presencesNotificationEnabled = yield select(
    isPresencesNotificationEnabled
  );
  const vacationEnabled = yield select(isVacationEnabled);
  const punchingEnabled = yield select(isPuncherEnabled);
  const vacationsValidator = yield select(isMeVacationsValidator);
  const punchingsValidator = yield select(isMePunchingsValidator);
  const ticketNotificationEnabled = yield select(isTicketNotificationEnabled);
  const fileboxNotificationEnabled = yield select(isFileboxNotificationEnabled);
  const videocallNotificationEnabled = yield select(
    isVideocallNotificationEnabled
  );
  const contactGrant = yield select(addressbookGrant);

  const topics = [];
  if (dataNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.GROUPS,
      silent: false,
    });
    if (contactGrant) {
      topics.push({
        code: NotificationEnums.NotificationsTopics.ABOOK,
        silent: false,
      });
    }
    topics.push({
      code: NotificationEnums.NotificationsTopics.USERS,
      silent: false,
    });
  } else {
    topics.push({
      code: NotificationEnums.NotificationsTopics.GROUPS,
      silent: true,
    });
    if (contactGrant) {
      topics.push({
        code: NotificationEnums.NotificationsTopics.ABOOK,
        silent: true,
      });
    }
    topics.push({
      code: NotificationEnums.NotificationsTopics.USERS,
      silent: true,
    });
  }
  topics.push({
    code: NotificationEnums.NotificationsTopics.STICKY,
    silent: true,
  });
  if (calendarNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.CALENDAR,
      silent: false,
    });
  }
  if (faxNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.FAX,
      silent: false,
    });
  }
  if (vboxNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.MEVBOX,
      silent: false,
    });
    topics.push({
      code: NotificationEnums.NotificationsTopics.PBXVBOX,
      silent: false,
    });
  }
  if (mailofficeNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.MAILOFFICE,
      silent: false,
    });
  }
  if (presencesNotificationEnabled) {
    if (vacationEnabled) {
      topics.push({
        code: NotificationEnums.NotificationsTopics.VACATION,
        silent: false,
      });
    }
    if (punchingEnabled) {
      topics.push({
        code: NotificationEnums.NotificationsTopics.PUNCHING,
        silent: false,
      });
    }
    if (
      (vacationEnabled && vacationsValidator) ||
      (punchingEnabled && punchingsValidator)
    ) {
      topics.push({
        code: NotificationEnums.NotificationsTopics.VALIDATION,
        silent: false,
      });
    }
  }
  if (ticketNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.TICKET,
      silent: false,
    });
  }
  if (fileboxNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.FILEBOX,
      silent: false,
    });
  }
  if (videocallNotificationEnabled) {
    topics.push({
      code: NotificationEnums.NotificationsTopics.VIDEOCALL,
      silent: false,
    });
  }
  payload = {
    channels: [
      {
        code: WsEnums.Channels.PRESENCE,
        topics: [
          {
            code: PresenceEnums.PresenceTopics.PRESENCE_STATUS,
            silent: false,
          },
        ],
      },
      {
        code: WsEnums.Channels.NOTIFICATIONS,
        topics,
      },
      {
        code: WsEnums.Channels.CHAT,
        topics: [
          { code: ChatEnums.ChatTopics.MESSAGES },
          { code: ChatEnums.ChatTopics.INFO },
          { code: ChatEnums.ChatTopics.STATUS },
        ],
      },
      {
        code: WsEnums.Channels.WEBRTC,
        topics: [
          { code: VideocallsEnums.VideocallsTopics.CALLS },
          { code: VideocallsEnums.VideocallsTopics.STATUS },
        ],
      },
    ],
  };
  ChatManager.myId = userId;
  VideocallsManager.myId = userId;

  const ackUid = generateAckUid(userId);
  const message = {
    channel: WsEnums.Channels.PRESENCE,
    topic: PresenceEnums.PresenceTopics.SUBSCRIPTION,
    ackUid,
    payload,
  };
  AckHandler.addPendingAck({
    ackUid,
    message,
    retry: sendSubscription,
  });
  WsManager.sendMessage(message);
}

export function logoutWebsocket() {
  console.log('logout');
  WsManager.disconnect();
  AckHandler.setActive(false);
}

export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, loginWebsocket);
  yield takeLatest(GUEST_LOGIN_SUCCESS, loginWebsocket);
  yield takeLatest(LOGIN_WEBSOCKET, loginWebsocket);
  yield takeLatest(WEBSOCKET_CONNECTION, sendSubscription);
  yield takeLatest(SEND_SUBSCRIPTION_FAILURE, sendSubscription);
  yield takeLatest(LOGOUT_SUCCESS, logoutWebsocket);
  yield takeLatest(LOGOUT_WEBSOCKET, logoutWebsocket);
  yield takeLatest(UPDATE_NOTIFICATION_SETTINGS_SUCCESS, sendSubscription);
}
