import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import {
  fetchPagedUsersSuccess,
  fetchPagedUsersFailure,
  fetchUserSuccess,
  fetchUserFailure,
  fetchUsersSuccess,
  fetchUsersFailure,
  saveUserSuccess,
  saveUserFailure,
  fetchUserVacationsDataSuccess,
  fetchUserVacationsDataFailure,
  fetchUserRegistryDataSuccess,
  fetchUserRegistryDataFailure,
  saveUserVacationsDataFailure,
  saveUserVacationsDataSuccess,
  saveUserRegistryDataSuccess,
  saveUserRegistryDataFailure,
  fetchUserPunchingsDataSuccess,
  fetchUserPunchingsDataFailure,
  saveUserPunchingsDataSuccess,
  saveUserPunchingsDataFailure,
  fetchUserRegistryDataRequest,
  fetchUserSuperviewersSuccess,
  fetchUserSuperviewersFailure,
  saveUserSuperviewersSuccess,
  saveUserSuperviewersFailure,
  fetchUserGroupsSuccess,
} from './actions';
import {
  USER_PRESENCE_CHANGE,
  FETCH_PAGED_USERS_REQUEST,
  FETCH_USERS_REQUEST,
  FETCH_USER_REQUEST,
  SAVE_USER_REQUEST,
  FETCH_USER_VACATIONS_DATA_REQUEST,
  FETCH_USER_REGISTRY_DATA_REQUEST,
  SAVE_USER_VACATIONS_DATA_REQUEST,
  SAVE_USER_REGISTRY_DATA_REQUEST,
  FETCH_USER_PUNCHINGS_DATA_REQUEST,
  SAVE_USER_PUNCHINGS_DATA_REQUEST,
  USER_STATUS_CHANGE,
  FETCH_USER_SUPERVIEWERS_REQUEST,
  SAVE_USER_SUPERVIEWERS_REQUEST,
  FETCH_USER_GROUPS_REQUEST,
} from './types';
import api from '../api';
import { PresenceEnums } from './PresenceUtils';
import { isUsersNotificationEnabled } from '../settings/selectors';
import { getUserById, getUserFromMainExtensionUsername } from './selectors';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import { checkApiResponse, checkApiError } from '../rootSaga';
import Utils from '../lib/utils';
import { AVATAR_TYPES } from '../files/FileUtils';

const intlStrings = defineMessages({
  onlineUser: {
    id: 'onlineUser',
    defaultMessage: 'User {user} is online',
  },
});

export function* fetchPagedUsers(action) {
  try {
    const res = yield call(api.users.getUsers, action.payload);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(
        fetchPagedUsersSuccess({
          users: [],
          total: 0,
          page: action.payload.page,
        })
      );
    } else {
      yield put(
        fetchPagedUsersSuccess({
          users: res.data.data,
          page: action.payload.page,
          total: res.data.total,
        })
      );
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPagedUsersFailure(error));
  }
}
export function* fetchUsers() {
  try {
    const res = yield call(api.users.getUsers);
    yield call(checkApiResponse, res);
    yield put(fetchUsersSuccess(res.data.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUsersFailure(error));
  }
}

export function* fetchUser(action) {
  try {
    const res = yield call(api.users.getUser, action.userId);
    yield call(checkApiResponse, res);
    yield put(fetchUserSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserFailure(error));
  }
}

export function* saveUser(action) {
  try {
    const res = yield call(api.users.updateUser, action.user);
    yield call(checkApiResponse, res);
    yield put(saveUserSuccess(action.user));
    yield put(push('/administration/users'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error && err.data && err.data !== '' && err.data.length > 0) {
      yield put(saveUserFailure({ status: error, messages: err.data }));
    } else {
      if (error) yield put(saveUserFailure({ status: error }));
    }
  }
}

export function* notifyPresenceChange(action) {
  const intl = yield call(Utils.retrieveIntl);
  if (
    action.payload.status === PresenceEnums.Status.ONLINE &&
    !action.payload.init
  ) {
    const notificationEnabled = yield select(isUsersNotificationEnabled);
    if (notificationEnabled) {
      const user = yield select(getUserById, action.payload.idUser);
      if (!user) {
        return;
      }
      DesktopNotificationManager.sendNotification({
        id: `${NotificationEnums.NotificationGroups.ONLINE_USERS}-${action.payload.idUser}`,
        body: intl.formatMessage(intlStrings.onlineUser, {
          user: user.departmentFullname,
        }),
        group: NotificationEnums.NotificationGroups.ONLINE_USERS,
        image: Utils.getAvatarUrl(AVATAR_TYPES.USER, user.avatar, 'sm'),
      });
    }
  }
}

export function* fetchUserVacationsData(action) {
  try {
    const res = yield call(api.users.getUserVacationsData, action.userId);
    yield call(checkApiResponse, res);
    yield put(
      fetchUserVacationsDataSuccess({ ...res.data, userId: action.userId })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserVacationsDataFailure(error));
  }
}

export function* fetchUserPunchingsData(action) {
  try {
    const res = yield call(api.users.getUserPunchingsData, action.userId);
    yield call(checkApiResponse, res);
    yield put(
      fetchUserPunchingsDataSuccess({ ...res.data, userId: action.userId })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserPunchingsDataFailure(error));
  }
}

export function* fetchUserRegistryData(action) {
  try {
    const res = yield call(api.users.getUserRegistryData, action.userId);
    yield call(checkApiResponse, res);
    yield put(fetchUserRegistryDataSuccess(res.data, action.userId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserRegistryDataFailure(error));
  }
}

export function* saveUserVacationsData(action) {
  try {
    const res = yield call(api.users.updateUserVacationsData, {
      userId: action.payload.userId,
      validators: action.payload.validators || [],
      toBeValidated: action.payload.toBeValidated || [],
    });
    yield call(checkApiResponse, res);
    yield put(saveUserVacationsDataSuccess(action.userId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveUserVacationsDataFailure({ status: error }));
  }
}

export function* saveUserPunchingsData(action) {
  try {
    const res = yield call(
      api.users.updateUserPunchingsData,
      action.payload.userId,
      {
        validators: action.payload.validators || [],
        toBeValidated: action.payload.toBeValidated || [],
        allowedIp: action.payload.allowedIp,
        enabledPunching: action.payload.enabledPunching,
      }
    );
    yield call(checkApiResponse, res);
    yield put(saveUserPunchingsDataSuccess(action.userId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveUserPunchingsDataFailure({ status: error }));
  }
}

export function* saveUserRegistryData(action) {
  try {
    const res = yield call(
      api.users.updateUserRegistryData,
      action.payload.userId,
      {
        startValidity: action.payload.startValidity,
        registry: action.payload.registry,
      }
    );
    yield call(checkApiResponse, res);
    yield put(saveUserRegistryDataSuccess(action.payload.userId));
    yield put(fetchUserRegistryDataRequest(action.payload.userId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveUserRegistryDataFailure({ status: error }));
  }
}

export function* notifyStatusChange(action) {
  const intl = yield call(Utils.retrieveIntl);
  const notificationEnabled = yield select(isUsersNotificationEnabled);
  if (notificationEnabled) {
    const user = yield select(getUserById, action.payload.idUser);
    if (!user) {
      return;
    }
    DesktopNotificationManager.sendNotification({
      id: `${NotificationEnums.NotificationGroups.ONLINE_USERS}-${action.payload.idUser}`,
      body: intl.formatMessage(intlStrings.onlineUser, {
        user: user.departmentFullname,
      }),
      group: NotificationEnums.NotificationGroups.ONLINE_USERS,
      image: Utils.getAvatarUrl(AVATAR_TYPES.USER, user.avatar, 'sm'),
    });
  }
}

export function* fetchUserSuperviewers(action) {
  try {
    const res = yield call(api.users.getUserVacationsData, action.userId);
    yield call(checkApiResponse, res);
    yield put(
      fetchUserSuperviewersSuccess({ ...res.data, userId: action.userId })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserSuperviewersFailure(error));
  }
}

export function* saveUserSuperviewers(action) {
  try {
    const res = yield call(api.users.updateUserVacationsData, {
      userId: action.payload.userId,
      validators: action.payload.validators || [],
      toBeValidated: action.payload.toBeValidated || [],
    });
    yield call(checkApiResponse, res);
    yield put(saveUserSuperviewersSuccess(action.userId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveUserSuperviewersFailure({ status: error }));
  }
}

export function* fetchUserGroups(action) {
  try {
    const params = [];
    for (let i = 0; i < action.params.users.length; i += 1) {
      const foundUsers = yield select(
        getUserFromMainExtensionUsername,
        action.params.users[i]
      );
      if (foundUsers && foundUsers.length > 0) {
        params.push(foundUsers[0].id);
      }
    }
    const res = yield call(api.users.getUserGroups, {users: params});
    yield call(checkApiResponse, res);
    yield put(fetchUserGroupsSuccess(res.data));
  } catch (err) {console.log('------------>', err)
    const error = yield call(checkApiError, err);
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_PAGED_USERS_REQUEST, fetchPagedUsers);
  yield takeLatest(FETCH_USERS_REQUEST, fetchUsers);
  yield takeLatest(FETCH_USER_REQUEST, fetchUser);
  yield takeLatest(SAVE_USER_REQUEST, saveUser);
  yield takeLatest(FETCH_USER_VACATIONS_DATA_REQUEST, fetchUserVacationsData);
  yield takeLatest(FETCH_USER_PUNCHINGS_DATA_REQUEST, fetchUserPunchingsData);
  yield takeLatest(FETCH_USER_REGISTRY_DATA_REQUEST, fetchUserRegistryData);
  yield takeLatest(SAVE_USER_VACATIONS_DATA_REQUEST, saveUserVacationsData);
  yield takeLatest(SAVE_USER_PUNCHINGS_DATA_REQUEST, saveUserPunchingsData);
  yield takeLatest(SAVE_USER_REGISTRY_DATA_REQUEST, saveUserRegistryData);
  yield takeEvery(USER_PRESENCE_CHANGE, notifyPresenceChange);
  yield takeEvery(USER_STATUS_CHANGE, notifyStatusChange);
  yield takeLatest(FETCH_USER_SUPERVIEWERS_REQUEST, fetchUserSuperviewers);
  yield takeLatest(SAVE_USER_SUPERVIEWERS_REQUEST, saveUserSuperviewers);
  yield takeLatest(FETCH_USER_GROUPS_REQUEST, fetchUserGroups);
}
