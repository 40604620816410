import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import ErrorBoundary from '../../../components/ErrorBoundary';

const NotificationsSettings = React.lazy(() =>
  import('./NotificationsSettings')
);

const messages = defineMessages({
  title: {
    id: 'NotificationsSettingsPage.title',
    defaultMessage: 'Notification settings',
  },
});

const NotificationsSettingsPage = (props) => (
  <div className="border rounded bg-white h-100">
    <SectionTitle>{props.intl.formatMessage(messages.title)}</SectionTitle>
    <div className="yn-section">
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <NotificationsSettings {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  </div>
);

export default injectIntl(NotificationsSettingsPage);
