/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Fade, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { EventEmitter } from 'events';
import { injectIntl, defineMessages } from 'react-intl';
import VocalAssistantManager from '../../../js/vocalassistant/VocalAssistantManager';
import TextArea from '../../../components/formControls/Textarea';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import Avatar from '../../../components/Avatar';
import { AVATAR_TYPES } from '../../../js/files/FileUtils';
import {
  // getMeAlias,
  getMeAllCalls,
  getMeConferenceId,
  getMeConferenceCallId,
} from '../../../js/me/selectors';
import {
  execVocalassistantAction,
  findCallNumberRequest,
} from '../../../js/vocalassistant/actions';
import history from '../../../history';
import { setFavoritesGroupScroll } from '../../../js/groups/actions';
import { getPlatformLanguage } from '../../../js/settings/selectors';
import { fetchHelpRequest } from '../../../js/me/actions';
import { fetchMeCallsRequest } from '../../../js/cdr/actions';
import { getCallNumber } from '../../../js/vocalassistant/selectors';
import VocalAssistantUtils from '../../../js/vocalassistant/VocalAssistantUtils';
import VocalAssistantMessages from '../../../js/vocalassistant/VocalAssistantMessages';
import AdvBalloon from './AdvBalloon';
import ynConf from '../../../conf';

const classes = classnames('rounded-circle', 'border', 'border-gray-light');
const translateMessages = defineMessages({
  chatOn: {
    id: 'VocalAssistant.chatOn',
    defaultMessage: 'Chat ON',
  },
  voiceOn: {
    id: 'VocalAssistant.voiceOn',
    defaultMessage: 'Voice command ON',
  },
});

const outfits = [
  {
    startDay: 1,
    startMonth: 9,
    endDay: 15,
    endMonth: 11,
    folders: ['classic/outfit1', 'classic/outfit2', 'classic/outfit3'],
  },
  {
    startDay: 16,
    startMonth: 11,
    endDay: 8,
    endMonth: 12,
    folders: ['winter/outfit1', 'winter/outfit2'],
  },
  {
    startDay: 9,
    startMonth: 12,
    endDay: 6,
    endMonth: 1,
    folders: ['christmas/outfit1', 'christmas/outfit2', 'christmas/outfit3'],
  },
  {
    startDay: 7,
    startMonth: 1,
    endDay: 22,
    endMonth: 1,
    folders: ['winter/outfit1', 'winter/outfit2'],
  },
  {
    startDay: 23,
    startMonth: 1,
    endDay: 20,
    endMonth: 2,
    folders: ['carnival/outfit1', 'carnival/outfit2', 'carnival/outfit3'],
  },
  {
    startDay: 21,
    startMonth: 2,
    endDay: 31,
    endMonth: 3,
    folders: [
      'classic/outfit1',
      'classic/outfit2',
      'spring/outfit1',
      'spring/outfit2',
    ],
  },
  {
    startDay: 1,
    startMonth: 4,
    endDay: 8,
    endMonth: 4,
    folders: ['easter/outfit1'],
  },
  {
    startDay: 9,
    startMonth: 4,
    endDay: 31,
    endMonth: 5,
    folders: ['classic/outfit3', 'spring/outfit1', 'spring/outfit2'],
  },
  {
    startDay: 1,
    startMonth: 6,
    endDay: 31,
    endMonth: 8,
    folders: ['summer/outfit1', 'summer/outfit2'],
  },
];

const EYES_SHUT_INTERVAL = 3000;
const EYES_SHUT_MINIMUM = 1000;
const MOUTH_INTERVAL = 100;
const TYPING_INTERVAL = 70;
const SILENCE_INTERVAL = 5000;

class VocalAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      talking: false,
      chatEnabled: true,
      openEyes: true,
      openMouth: false,
      messages: [],
      speech: true,
      modalOpen: false,
      callsResultName: null,
      callsFetched: false,
    };
    this.chatWindow = React.createRef();

    this.startAssistant = this.startAssistant.bind(this);
    this.stopAssistant = this.stopAssistant.bind(this);

    const emit = this.initEventEmitter();
    this.VAManager = new VocalAssistantManager(emit);

    this.outfitFolder = 'classic/outfit1';
    const now = new Date();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    for (let i = 0; i < outfits.length; i += 1) {
      if (outfits[i].startMonth > outfits[i].endMonth) {
        if (month < outfits[i].startMonth && month > outfits[i].endMonth) {
          continue;
        }
      } else if (month < outfits[i].startMonth || month > outfits[i].endMonth) {
        continue;
      }
      if (
        (month === outfits[i].startMonth && day < outfits[i].startDay) ||
        (month === outfits[i].endMonth && day > outfits[i].endDay)
      ) {
        continue;
      }
      this.outfitFolder =
        outfits[i].folders[
          Math.floor(Math.random() * outfits[i].folders.length)
        ];
    }

    this.silenceTimeout = null;
    this.silenceCount = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.chatWindow.current &&
      this.chatWindow.current.scrollHeight &&
      this.state.messages !== prevState.messages
    ) {
      this.chatWindow.current.scrollTop = this.chatWindow.current.scrollHeight;
    }
    if (
      this.props.cdrCalls &&
      this.props.cdrCalls !== prevProps.cdrCalls &&
      this.state.callsResultName
    ) {
      this.findCallNumber();
    }
    if (
      this.props.cdrCalls &&
      this.props.cdrCalls !== prevProps.cdrCalls &&
      !this.state.callsResultName &&
      this.state.callsFetched
    ) {
      const command = `lost_calls ${this.props.cdrCalls
        .map((c) =>
          c.interlocutor && c.interlocutor.fullname
            ? c.interlocutor.fullname
            : c.interlocutor.number || ''
        )
        .join(', ')}`;
      this.VAManager.parseCommand(command);
    }
    if (
      this.props.callNumber !== null &&
      this.props.callNumber !== prevProps.callNumber
    ) {
      this.VAManager.parseCommand(`add_number ${this.props.callNumber}`);
    }
    if (
      prevProps.calls.length < 1 &&
      !prevProps.conferenceId &&
      !prevProps.conferenceCallId &&
      (this.props.calls.length > 0 ||
        this.props.conferenceId ||
        this.props.conferenceCallId)
    ) {
      this.stopAssistant();
    }
    if (
      prevProps.calls.length < 1 &&
      !prevProps.conferenceId &&
      !prevProps.conferenceCallId &&
      (this.props.calls.length > 0 ||
        this.props.conferenceId ||
        this.props.conferenceCallId)
    ) {
      this.stopAssistant();
    }
  }

  writeChars = (text, charIndex, messageIndex, additionalMessages) => {
    if (!text.charAt(charIndex)) {
      if (additionalMessages && additionalMessages.length > 0) {
        this.setState({
          messages: [...this.state.messages, ...additionalMessages],
        });
      }
      return;
    }

    setTimeout(() => {
      if (!this.state.active) {
        if (charIndex > 0) {
          const { messages } = this.state;
          messages[messageIndex] = {
            ...messages[messageIndex],
            text: `${messages[messageIndex].text}...`,
          };
        }
        return;
      }
      if (charIndex === 0) {
        this.setState({
          messages: [
            ...this.state.messages,
            {
              text: text.charAt(charIndex),
              in: true,
              date: moment().format('hh:mm'),
            },
          ],
        });
      } else {
        const { messages } = this.state;
        if (!messages[messageIndex]) return;
        messages[messageIndex] = {
          ...messages[messageIndex],
          text: messages[messageIndex].text + text.charAt(charIndex),
        };
        this.setState({ messages: [...messages] });
      }
      this.writeChars(text, charIndex + 1, messageIndex, additionalMessages);
    }, TYPING_INTERVAL);
  };

  simulateMoveMouth = (force) => {
    if (!this.state.active || !this.state.talking) {
      return;
    }
    const countMouth = force ? 1 : Math.round(Math.random() * 2);
    if (countMouth % 2 === 0) {
      this.setState({ openMouth: false });
    } else {
      this.setState({ openMouth: true });
    }
    setTimeout(() => {
      this.simulateMoveMouth();
    }, MOUTH_INTERVAL);
  };

  simulateEyesShut = (opened) => {
    if (!this.state.active) {
      return;
    }
    const randomInterval =
      Math.round(Math.random() * EYES_SHUT_INTERVAL) + EYES_SHUT_MINIMUM;
    if (opened) {
      this.setState({ openEyes: true });
    } else {
      this.setState({ openEyes: false });
    }
    setTimeout(
      () => {
        this.simulateEyesShut(!opened);
      },
      opened ? randomInterval : 50
    );
  };

  manageSilence = () => {
    if (!this.state.active) {
      return;
    }
    this.silenceTimeout = setTimeout(() => {
      if (!this.state.active) {
        return;
      }
      this.silenceCount += 1;
      if (this.silenceCount === 1) {
        this.talk(
          VocalAssistantUtils.retrieveMessage(
            {
              messages: VocalAssistantMessages.voiceCommandFirstSilence,
            },
            this.props.meFullname,
            this.props.language
          )
        );
      } else if (this.silenceCount === 2) {
        this.talk(
          VocalAssistantUtils.retrieveMessage(
            {
              messages: VocalAssistantMessages.voiceCommandSecondSilence,
            },
            this.props.meFullname,
            this.props.language
          )
        );
      } else if (this.silenceCount === 3) {
        this.VAManager.stopRecognition();
        this.setState({ chatEnabled: false });
        this.talk(
          VocalAssistantUtils.retrieveMessage(
            {
              messages: VocalAssistantMessages.voiceCommandByeBye,
            },
            this.props.meFullname,
            this.props.language
          ),
          null,
          () => {
            this.stopAssistant();
          }
        );
      }
    }, SILENCE_INTERVAL);
  };

  resetSilence = () => {
    this.silenceTimeout && clearTimeout(this.silenceTimeout);
    this.silenceTimeout = null;
    this.silenceCount = 0;
  };

  talk = (message, additionalMessages, endTask) => {
    this.VAManager.speak(message, () => {
      additionalMessages &&
        additionalMessages.map((msg) => this.VAManager.speak(msg));
      if (endTask) {
        endTask();
      } else {
        this.setState({ talking: false, openMouth: false });
        this.manageSilence();
      }
    });
    this.setState({ talking: true }, () => {
      this.simulateMoveMouth(true);
    });
    const moreMessages = additionalMessages
      ? additionalMessages.map((msg) => ({
          text: msg,
          in: true,
          date: moment().format('hh:mm'),
        }))
      : [];
    if (!message) return;
    if (message && message.length > 100) {
      this.setState({
        messages: [
          ...this.state.messages,
          {
            text: message,
            in: true,
            date: moment().format('hh:mm'),
          },
          ...moreMessages,
        ],
      });
    } else {
      // TODO moreMessages
      this.writeChars(message, 0, this.state.messages.length, moreMessages);
    }
  };

  initEventEmitter = () => {
    const emit = new EventEmitter();
    emit.on('start', () => {
      // do things
    });
    emit.on('message', (message, additionalMessages, path, scroll) => {
      this.talk(message, additionalMessages);
      if (path) {
        history.push(path);
      }
      if (scroll) {
        this.props.favoritesScroll(scroll);
      }
    });
    emit.on('user message', (message) => {
      // do things
      this.setState({
        messages: [
          ...this.state.messages,
          {
            text: message,
            in: false, // ambrosia message
            date: moment().format('hh:mm'),
          },
        ],
      });
    });
    emit.on('help', () => {
      this.setState({
        modalOpen: true,
      });
      this.props.openModal('vocal-assistant');
    });
    emit.on('exec action', (data) => {
      this.props.execAction(data);
    });
    emit.on('do task', (data) => {
      switch (data.task) {
        case 'LAST_CALL_GET':
          this.setState({
            callsResultName:
              data.params.filter.in || data.params.filter.lost
                ? 'calling'
                : 'called',
          });
          this.props.fetchCalls({ ...data.params.filter });
          break;
        case 'CALLS_GET':
          this.props.fetchCalls({ ...data.params.filter });
          this.setState({
            callsFetched: true,
          });
          break;
        case 'SECTION_GOTO':
          if (!data.params.path) return;
          history.push(data.params.path);
          break;
        default:
          break;
      }
    });
    emit.on('stop', (message) => {
      this.VAManager.stopRecognition();
      this.setState({ chatEnabled: false });
      this.talk(message, null, () => {
        this.stopAssistant();
      });
    });
    return emit;
  };

  startAssistant = () => {
    if (this.state.active) return;
    this.setState(
      {
        active: true,
        speech: true,
        chatEnabled: true,
      },
      () => {
        this.VAManager.startVocalAssistant(
          this.props.meFullname,
          this.props.language
        );
        this.simulateEyesShut(true);
      }
    );
  };

  stopAssistant = () => {
    if (!this.state.active) return;
    this.setState(
      {
        active: false,
      },
      () => {
        this.VAManager.stopVocalAssistant();
      }
    );
    this.resetSilence();
  };

  handleTextChange = (e) => {
    this.setState({
      ...this.state,
      text: e.target.value,
    });
  };

  onKeyPress = (e) => {
    this.resetSilence();
    const userMessage = this.state.text;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (userMessage.length > 0) {
        this.setState(
          {
            ...this.state,
            text: '',
            messages: [
              ...this.state.messages,
              {
                text: userMessage,
                in: false, // ambrosia message
                date: moment().format('hh:mm'),
              },
            ],
          },
          () => {
            this.VAManager.parseCommand(userMessage);
          }
        );
      }
    }
  };

  onTextChange = (e) => {
    this.setState({
      ...this.state,
      text: e.target.value,
    });
  };

  stopRecording = (e) => {
    e.preventDefault();
    if (this.state.speech) return;
    this.setState(
      {
        ...this.state,
        speech: true,
      },
      () => {
        this.VAManager.stopRecognition();
      }
    );
  };

  startRecording = (e) => {
    e.preventDefault();
    if (!this.state.speech) return;
    this.setState(
      {
        ...this.state,
        speech: false,
      },
      () => {
        this.VAManager.startRecording({
          onStart: () => {
            this.resetSilence();
          },
        });
      }
    );
  };

  toggle = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  findCallNumber = () => {
    const { callsResultName } = this.state;
    if (!callsResultName) return;
    const { cdrCalls, findNumber } = this.props;
    findNumber({ calls: cdrCalls, type: callsResultName });
    this.setState({ callsResultName: null });
  };

  render() {
    const { active, openMouth, openEyes, messages, text, speech, chatEnabled } =
      this.state;
    const {
      avatarSrc,
      intl: { formatMessage },
    } = this.props;
    const { ipcRenderer } = window;

    const style = {
      helpAssistant: {
        width: active ? '55px' : '200px',
        opacity: active ? '0.5' : '1',
        cursor: active && 'pointer',
        verticalAlign: 'bottom',
      },
      ambrosia: {
        width: active ? '150px' : '55px',
        opacity: active ? '1' : '0.5',
        cursor: !active && 'pointer',
        verticalAlign: 'bottom',
      },
    };
    const purifiedHtml = DOMPurify.sanitize(this.props.html);

    return (
      <Fade
        tag="div"
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: '300px',
        }}
      >
        <div
          id="vocalAssistantPanel"
          style={{ position: 'relative', bottom: '160px' }}
          className="pt-2"
        >
          {active && messages.length > 0 && (
            <div
              className="card"
              style={{
                minHeight: '300px',
              }}
            >
              <ul
                className="list-unstyled"
                style={{
                  overflowY: 'auto',
                  height: speech ? '210px' : '280px',
                  padding: '4px',
                  margin: 0,
                  marginTop: '1px',
                  marginBottom: '1px',
                }}
                ref={this.chatWindow}
              >
                {messages.map((message, idx) => (
                  <li key={`message-${idx}`}>
                    <span className={message.in ? 'float-left' : 'float-right'}>
                      {message.in ? (
                        <img
                          className={classes}
                          src={`${ynConf.publicFolderPath}/ambrosia/${this.outfitFolder}/vocal-assistant-inactive.png`}
                          alt="Ambrosia avatar"
                          width={25}
                          height={25}
                        />
                      ) : (
                        <Avatar
                          size="25"
                          src={avatarSrc}
                          alt="User avatar"
                          type={AVATAR_TYPES.ME}
                          id={`userAvatar-${avatarSrc}`}
                        />
                      )}
                    </span>
                    <div
                      className="border rounded p-1 clearfix text-left"
                      style={{
                        marginLeft: message.in ? '30px' : '0px',
                        marginRight: message.in ? '0px' : '30px',
                        background: message.in
                          ? 'var(--yn-gray-200)'
                          : 'var(--yn-blue-lighter)',
                        listStyleType: 'none',
                        marginTop: '5px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '10px',
                          textAlign: 'right',
                        }}
                      >
                        {message.date}
                      </div>
                      {message.text}
                    </div>
                  </li>
                ))}
              </ul>
              {speech && (
                <div>
                  <TextArea
                    onChange={this.onTextChange}
                    onKeyPress={this.onKeyPress}
                    style={{
                      height: '70px',
                    }}
                    disabled={!chatEnabled}
                    value={text}
                    name="chat"
                    id="chat"
                  />
                </div>
              )}
              {!ipcRenderer && (
                <div className="text-left pt-2" style={{ height: '40px' }}>
                  <span onClick={this.stopRecording}>
                    <ToolboxIcon
                      name={speech ? 'keyboard-1-filled' : 'keyboard-1'}
                      className="p-2"
                    >
                      {!speech && formatMessage(translateMessages.chatOn)}
                    </ToolboxIcon>
                  </span>
                  <span onClick={this.startRecording}>
                    <ToolboxIcon
                      name={speech ? 'microphone-3' : 'microphone-3-filled'}
                      className="p-2"
                    >
                      {speech && formatMessage(translateMessages.voiceOn)}
                    </ToolboxIcon>
                  </span>
                </div>
              )}
            </div>
          )}
        </div>

        <span
          onClick={this.stopAssistant}
          style={{ position: 'absolute', bottom: 0, left: 0 }}
        >
          <img
            src={
              active
                ? `${ynConf.publicFolderPath}/help-assistant-inactive.png`
                : `${ynConf.publicFolderPath}/help-assistant-small.png`
            }
            alt="Ambrosia"
            style={{ ...style.helpAssistant }}
          />
          {!active && <AdvBalloon />}
        </span>

        <span
          onClick={this.startAssistant}
          style={{ position: 'absolute', bottom: 0, right: 0 }}
        >
          <img
            src={`${ynConf.publicFolderPath}/ambrosia/${this.outfitFolder}/vocal-assistant-talking.png`}
            alt="Ambrosia"
            style={{
              ...style.ambrosia,
              display: active && openMouth ? 'block' : 'none',
            }}
          />
          <img
            src={`${ynConf.publicFolderPath}/ambrosia/${this.outfitFolder}/vocal-assistant.png`}
            alt="Ambrosia"
            style={{
              ...style.ambrosia,
              display: active && !openMouth && openEyes ? 'block' : 'none',
            }}
          />
          <img
            src={`${ynConf.publicFolderPath}/ambrosia/${this.outfitFolder}/vocal-assistant-eyesshut.png`}
            alt="Ambrosia"
            style={{
              ...style.ambrosia,
              display: active && !openMouth && !openEyes ? 'block' : 'none',
            }}
          />
          <img
            src={`${ynConf.publicFolderPath}/ambrosia/${this.outfitFolder}/vocal-assistant-inactive.png`}
            alt="Ambrosia"
            style={{ ...style.ambrosia, display: !active ? 'block' : 'none' }}
          />
        </span>
        <Modal
          modalTransition={{
            timeout: 20,
          }}
          backdropTransition={{
            timeout: 10,
          }}
          isOpen={this.state.modalOpen}
          toggle={this.toggle}
          className="modal-lg"
        >
          <ModalHeader toggle={this.toggle}>Help</ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: purifiedHtml }} />
          </ModalBody>
        </Modal>
      </Fade>
    );
  }
}

function mapStateToProps(state) {
  return {
    avatarSrc: state.me.avatar,
    meFullname: state.me.name, //getMeAlias(state),
    language: getPlatformLanguage(state),
    html: state.me.help,
    cdrCalls: state.cdr.me.calls,
    callNumber: getCallNumber(state),
    calls: getMeAllCalls(state),
    conferenceId: getMeConferenceId(state),
    conferenceCallId: getMeConferenceCallId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    execAction: execVocalassistantAction,
    favoritesScroll: setFavoritesGroupScroll,
    openModal: fetchHelpRequest,
    fetchCalls: fetchMeCallsRequest,
    findNumber: findCallNumberRequest,
  })(VocalAssistant)
);
