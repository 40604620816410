import React from 'react';
import PbxSettings from './PbxSettings';
import HelpButton from '../../../../components/HelpButton';
import ErrorBoundary from '../../../../components/ErrorBoundary';

const ActiveMessagesTable = React.lazy(() => import('./ActiveMessagesTable'));
const ActiveMessagesPage = () => {
  return (
    <PbxSettings scrolling>
      <div className="text-right mb-2">
        <HelpButton fileName="pbx-active-messages-archive" />
      </div>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <ActiveMessagesTable />
        </React.Suspense>
      </ErrorBoundary>
    </PbxSettings>
  );
};

export default ActiveMessagesPage;
