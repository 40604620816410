import {
  FETCH_GRANTS_REQUEST,
  FETCH_GRANTS_SUCCESS,
  FETCH_GRANTS_FAILURE,
  TOGGLE_GRANTS_CATEGORY,
  TOGGLE_GRANT,
  FETCH_USER_GRANTS_REQUEST,
  FETCH_USER_GRANTS_SUCCESS,
  FETCH_USER_GRANTS_FAILURE,
  TOGGLE_GRANT_COLLAPSE,
  GRANTS_COLLAPSE_ALL,
  GRANTS_EXPAND_ALL,
  SAVE_USER_GRANTS_REQUEST,
  SAVE_USER_GRANTS_SUCCESS,
  SAVE_USER_GRANTS_FAILURE,
  SAVE_ME_ADVANCED_GRANTS_REQUEST,
  SAVE_ME_ADVANCED_GRANTS_SUCCESS,
  SAVE_ME_ADVANCED_GRANTS_FAILURE,
  FETCH_PROFILE_GRANTS_REQUEST,
  FETCH_PROFILE_GRANTS_SUCCESS,
  FETCH_PROFILE_GRANTS_FAILURE,
} from './types';

export const fetchGrantsRequest = () => ({ type: FETCH_GRANTS_REQUEST });
export const fetchGrantsSuccess = (grants) => ({
  type: FETCH_GRANTS_SUCCESS,
  grants,
});
export const fetchGrantsFailure = (errors) => ({
  type: FETCH_GRANTS_FAILURE,
  errors,
});
export const toggleGrantsCategory = (category) => ({
  type: TOGGLE_GRANTS_CATEGORY,
  category,
});
export const toggleGrant = (category, code) => ({
  type: TOGGLE_GRANT,
  category,
  code,
});
export const fetchUserGrantsRequest = (id) => ({
  type: FETCH_USER_GRANTS_REQUEST,
  id,
});
export const fetchUserGrantsSuccess = (grants) => ({
  type: FETCH_USER_GRANTS_SUCCESS,
  grants,
});
export const fetchUserGrantsFailure = (errors) => ({
  type: FETCH_USER_GRANTS_FAILURE,
  errors,
});
export const toggleGrantCollapse = (category) => ({
  type: TOGGLE_GRANT_COLLAPSE,
  category,
});
export const collapseAll = () => ({ type: GRANTS_COLLAPSE_ALL });
export const expandAll = () => ({ type: GRANTS_EXPAND_ALL });
export const saveUserGrantsRequest = (payload) => ({
  type: SAVE_USER_GRANTS_REQUEST,
  payload,
});
export const saveUserGrantsSuccess = () => ({ type: SAVE_USER_GRANTS_SUCCESS });
export const saveUserGrantsFailure = (errors) => ({
  type: SAVE_USER_GRANTS_FAILURE,
  errors,
});
export const saveMeAdvancedGrantsRequest = (payload) => ({
  type: SAVE_ME_ADVANCED_GRANTS_REQUEST,
  payload,
});
export const saveMeAdvancedGrantsSuccess = () => ({
  type: SAVE_ME_ADVANCED_GRANTS_SUCCESS,
});
export const saveMeAdvancedGrantsFailure = (errors) => ({
  type: SAVE_ME_ADVANCED_GRANTS_FAILURE,
  errors,
});
export const fetchProfileGrantsRequest = (id) => ({
  type: FETCH_PROFILE_GRANTS_REQUEST,
  id,
});
export const fetchProfileGrantsSuccess = (grants) => ({
  type: FETCH_PROFILE_GRANTS_SUCCESS,
  grants,
});
export const fetchProfileGrantsFailure = (errors) => ({
  type: FETCH_PROFILE_GRANTS_FAILURE,
  errors,
});
