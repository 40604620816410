import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const CalendarEventEdit = React.lazy(() => import('./CalendarEventEdit'));

const CalendarEventEditPage = (props) => (
  <Calendar>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-3">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="calendar-edit" />
          </div>
        </div>
        <CalendarEventEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarEventEditPage;
