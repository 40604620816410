import moment from 'moment';
import Speech from './Speech';
import VocalAssistantMessages from './VocalAssistantMessages';
import VocalAssistantDateUtils from './VocalAssistantDateUtils';
import Navigator from './Navigator';
import VocalAssistantVoiceCommands from './VocalAssistantVoiceCommands';
import VocalAssistantUtils from './VocalAssistantUtils';

export default class VocalAssistantManager {
  constructor(emitter) {
    this.pendingSpeechRecognition = false;
    this.activeSpeech = false;
    this.activeChat = false;
    this.firstActivation = true;

    this.emitter = emitter;

    this.voiceCommands = VocalAssistantVoiceCommands.voiceCommands;
    this.navigator = new Navigator();
    this.wrongOperations = 0;

    this.silenceTimeout = null;
    this.helpTimeout = null;
    this.silenceCount = 0;
    this.language = null;
  }

  loadFirstMessageSet = () => {
    const today = new Date();
    const date = moment().format('DD/MM');

    // / check if it is first day of year
    if (date === VocalAssistantDateUtils.date.firstDayOfYear) {
      return VocalAssistantMessages.voiceCommandIntroFirstOfYear;
    }
    // / check if it is christmas day
    if (date === VocalAssistantDateUtils.date.christmasDay) {
      return VocalAssistantMessages.voiceCommandIntroChristmas;
    }
    // / check if it is easter day
    const easter = VocalAssistantDateUtils.getEasterDate(today.getFullYear());
    if (date === moment(easter).format('DD/MM')) {
      return VocalAssistantMessages.voiceCommandIntroEaster;
    }
    // / check if today is festivity or saturday/sunday
    if (
      VocalAssistantDateUtils.isFestivity(today) ||
      today.getDay() === 0 ||
      today.getDay() === 6
    ) {
      return VocalAssistantMessages.voiceCommandIntroFestivity;
    }
    // / check the time
    const hour = today.getHours();
    if (hour >= 19 || hour < 5) {
      return VocalAssistantMessages.voiceCommandIntroLate;
    }
    if (hour >= 5 && hour < 9) {
      return VocalAssistantMessages.voiceCommandIntroEarly;
    }
    if (hour >= 9 && hour < 14) {
      return VocalAssistantMessages.voiceCommandIntroGoodmorning;
    }
    if (hour >= 14 && hour < 17) {
      return VocalAssistantMessages.voiceCommandIntroGoodafternoon;
    }
    if (hour >= 17 && hour < 19) {
      return VocalAssistantMessages.voiceCommandIntroGoodevening;
    }
  };

  sendWelcomeMessage = () => {
    let message;
    // / if first message, send a welcome message
    if (this.firstActivation) {
      this.firstActivation = false;
      // / retrieve right first message
      const firstMessageSet = this.loadFirstMessageSet();
      message = VocalAssistantUtils.retrieveMessage(
        {
          messages: firstMessageSet,
        },
        this.userFullname,
        this.language
      );
    } else {
      // / send welcome message
      message = VocalAssistantUtils.retrieveMessage(
        {
          messages: VocalAssistantMessages.voiceCommandIntroAgain,
        },
        this.userFullname,
        this.language
      );
    }
    this.emitter.emit('message', message);
  };

  parseCommand = (input, shortcut) => {
    const exec = (text) => {
      this.navigator.execute(text, this.language, (data) => {
        // / there is an output message, write it
        if (data.message && data.message.length > 0 && !data.stop) {
          this.emitter.emit(
            'message',
            data.message,
            data.additionalMessages,
            data.path,
            data.scroll
          );
        }
        if (data.action) {
          this.emitter.emit('exec action', data);
        }
        if (data.helpModal) {
          this.emitter.emit('help');
        }
        if (data.task) {
          this.emitter.emit('do task', data);
        }
        if (data.stop) {
          this.emitter.emit('stop', data.message);
        }
      });
    };
    // / if there is a pending operation, execute it
    if (this.navigator.isActive()) {
      exec(input);
      return;
    }
    for (let i = 0; i < this.voiceCommands.length; i += 1) {
      // / check if there is a shortcut to force executing an operation
      if (shortcut && this.voiceCommands[i].shortcut === shortcut) {
        this.navigator.init(
          this.voiceCommands[i].steps,
          this.voiceCommands[i].command,
          this.language,
          this.userFullname
        );
        exec(input);
        return;
      }
      // / retrieve possible regexp templates
      const templates = this.voiceCommands[i].templates;
      if (!input || !templates) {
        continue;
      }

      for (let j = 0; j < templates.length; j += 1) {
        if (input.match(templates[j])) {
          // / init navigator with steps of matched operation and execute them
          this.navigator.init(
            this.voiceCommands[i].steps,
            this.voiceCommands[i].command,
            this.language,
            this.userFullname
          );

          // / if more regexp capturing groups, text is an array
          if (input.match(templates[j]).length > 2) {
            const capturingGroups = input.match(templates[j]);
            capturingGroups.shift();
            exec(capturingGroups);
          } else {
            exec(input.match(templates[j])[1]);
          }
          this.wrongOperations = 0;
          return;
        }
      }
    }
    // / no operation templates found
    this.wrongOperations += 1;
    // / retrieve random message
    // / if some consecutive wrong operations, tell user help message
    if (this.wrongOperations === 3) {
      this.emitter.emit(
        'message',
        VocalAssistantUtils.retrieveMessage(
          {
            messages: VocalAssistantMessages.voiceCommandIntroHelp,
          },
          this.userFullname,
          this.language
        )
      );
      this.wrongOperations = 0;
    } else {
      this.emitter.emit(
        'message',
        VocalAssistantUtils.retrieveMessage(
          {
            messages: VocalAssistantMessages.voiceCommandUnknownOperation,
          },
          this.userFullname,
          this.language
        )
      );
    }
  };

  stopActiveAssistant = () => {
    this.activeAssistant = false;
    this.speech && this.speech.stopSpeaking();
  };

  startRecording = (prm) => {
    // / start recognition
    this.speech.activateRecognition({
      onstart: () => {
        // / on start speeching event
        this.pendingSpeechRecognition = true;
        // / clear timeouts
        this.silenceTimeout && clearTimeout(this.silenceTimeout);
        // this.startSimulatingEyesShut();
        this.helpTimeout && clearTimeout(this.helpTimeout);
        prm.onStart && prm.onStart();
      },
      onresult: (text) => {
        // / on result event
        this.pendingSpeechRecognition = false;
        this.stopActiveAssistant();
        this.silenceCount = 0;
        // / if assistant is speaking, wait it has finished
        if (!this.speech.isSpeakActive()) {
          this.emitter.emit('user message', text);
          this.parseCommand(text);
        } else {
          // / wait for speaking finishing
          const activeSpeakingInterval = setInterval(() => {
            if (!this.speech.isSpeakActive()) {
              clearInterval(activeSpeakingInterval);
              this.emitter.emit('user message', text);
              this.parseCommand(text);
            }
          }, 1000);
        }
      },
      onend: (result) => {
        // / if no speech result and assistant is not speaking, restart silence timeout
        if (
          !this.speech.isSpeakActive() &&
          this.pendingSpeechRecognition &&
          !result
        ) {
          this.pendingSpeechRecognition = false;
          this.silenceTimeout && clearTimeout(this.silenceTimeout);
        } else {
          this.pendingSpeechRecognition = false;
        }
      },
    });
  };

  speak = (message, callback) => {
    this.speech.speak(message, callback);
  };

  stopRecognition = () => {
    this.speech.stopRecognition();
  };

  startVocalAssistant = (userFullname, language) => {
    this.speech = new Speech(language);
    this.activeSpeech = true;
    // / new speech recognition
    this.pendingSpeechRecognition = false;
    this.activeChat = true;
    this.userFullname = userFullname;
    this.language = language;

    // / send welcome message
    this.sendWelcomeMessage();
    // TODO
    // this.startRecording();
    this.emitter.emit('start');
  };

  stopVocalAssistant = () => {
    this.stopActiveAssistant();
    this.stopRecognition();
    this.emitter.emit('stop');
  };
}
