import keyBy from 'lodash.keyby';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  CREATE_USER_PROFILE_FAILURE,
  CREATE_USER_PROFILE_REQUEST,
  CREATE_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_FAILURE,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILES_FAILURE,
  FETCH_USER_PROFILES_REQUEST,
  FETCH_USER_PROFILES_SUCCESS,
  FETCH_USER_PROFILE_GRANTS_FAILURE,
  FETCH_USER_PROFILE_GRANTS_REQUEST,
  FETCH_USER_PROFILE_GRANTS_SUCCESS,
  REMOVE_USER_PROFILE_FAILURE,
  REMOVE_USER_PROFILE_REQUEST,
  REMOVE_USER_PROFILE_SUCCESS,
} from './types';

const initialstate = {
  userProfilesById: {},
  userProfilesAllIds: [],
  fetchUserProfileDetailsLoaded: true,
  fetchUserProfileDetailsError: false,
  total: null,
  page: 0,
  userProfilesLoaded: true,
  userProfilesError: false,
  deleteUserProfilesLoaded: true,
  deleteUserProfilesError: false,
  saveUserProfileLoaded: true,
  saveUserProfileError: false,
};

export default function usersProfiles(state = initialstate, action = {}) {
  switch (action.type) {
    case CREATE_USER_PROFILE_REQUEST:
    case EDIT_USER_PROFILE_REQUEST:
      return {
        ...state,
        saveUserProfileLoaded: false,
        saveUserProfileError: false,
      };
    case CREATE_USER_PROFILE_SUCCESS:
    case EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        saveUserProfileLoaded: true,
        saveUserProfileError: false,
      };
    case CREATE_USER_PROFILE_FAILURE:
    case EDIT_USER_PROFILE_FAILURE:
      return {
        ...state,
        saveUserProfileLoaded: true,
        saveUserProfileError: action.error,
      };
    case FETCH_USER_PROFILES_REQUEST:
      return {
        ...state,
        userProfilesLoaded: false,
        userProfilesError: false,
        saveUserProfileError: false,
      };
    case FETCH_USER_PROFILES_SUCCESS:
      return {
        ...state,
        userProfilesLoaded: true,
        userProfilesError: false,
        deleteUserProfilesError: false,
        userProfilesById: {
          ...keyBy(action.data.data, (userProfile) => userProfile.id),
        },
        userProfilesAllIds: action.data.data.map(
          (userProfile) => userProfile.id
        ),
        total: action.data.total,
        page: action.data.page,
      };
    case FETCH_USER_PROFILES_FAILURE:
      return {
        ...state,
        userProfilesLoaded: true,
        userProfilesError: action.errors,
      };
    case REMOVE_USER_PROFILE_REQUEST: {
      return {
        ...state,
        deleteUserProfilesLoaded: false,
        deleteUserProfilesError: false,
      };
    }
    case REMOVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        deleteUserProfilesLoaded: true,
        deleteUserProfilesError: false,
      };
    case REMOVE_USER_PROFILE_FAILURE:
      return {
        ...state,
        deleteUserProfilesLoaded: true,
        deleteUserProfilesError: action.errors,
      };
    case FETCH_USER_PROFILE_GRANTS_REQUEST:
      return {
        ...state,
        fetchUserProfileDetailsLoaded: false,
        fetchUserProfileDetailsError: false,
      };
    case FETCH_USER_PROFILE_GRANTS_SUCCESS: {
      return {
        ...state,
        fetchUserProfileDetailsLoaded: true,
        fetchUserProfileDetailsError: false,
        userProfilesById: {
          ...state.userProfilesById,

          [action.id]: {
            ...state.userProfilesById[action.id],
            grants: action.data,
          },
        },
      };
    }
    case FETCH_USER_PROFILE_GRANTS_FAILURE:
      return {
        ...state,
        fetchUserProfileDetailsLoaded: true,
        fetchUserProfileDetailsError: action.error,
      };

    case LOGOUT_SUCCESS:
      return initialstate;
    default:
      return state;
  }
}
