import React from 'react';
import { PropTypes } from 'prop-types';

const KeyPad = (props) => {
  const keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  return (
    <div className="yn-key-pad border-bottom">
      {keys.map((key) => (
        <div key={key} className="yn-key-button">
          <span
            className="yn-key-circle"
            style={{ cursor: props.disabled ? 'default' : 'pointer' }}
            onClick={props.disabled ? null : () => props.onClick(key)}
          >
            {key}
          </span>
        </div>
      ))}
    </div>
  );
};

KeyPad.propTypes = {
  disabled: PropTypes.bool,
};

KeyPad.defaultProps = {
  disabled: false,
};

export default KeyPad;
