import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ for: htmlFor, children, mandatory, ...props }) => (
  <label
    {...props}
    htmlFor={htmlFor}
    style={{
      color: 'var(--yn-blue-dark)',
      fontWeight: 'bold',
      marginBottom: 0,
      marginRight: '0.25rem',
      ...props.style,
    }}
  >
    {children}
    {mandatory && ' *'}
  </label>
);

Label.propTypes = {
  children: PropTypes.node,
  for: PropTypes.string,
  mandatory: PropTypes.bool,
};
Label.defaultProps = {
  children: undefined,
  mandatory: false,
  for: '',
};

export default Label;
