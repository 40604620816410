import {
  FETCH_FAX_LIST_REQUEST,
  FETCH_FAX_LIST_SUCCESS,
  FETCH_FAX_LIST_FAILURE,
  CHANGE_FAX_LIST_PAGE_REQUEST,
  DELETE_FAX_REQUEST,
  DELETE_FAX_SUCCESS,
  DELETE_FAX_FAILURE,
  RELOAD_FAX_LIST_REQUEST,
  READ_FAX_REQUEST,
  READ_FAX_SUCCESS,
  READ_FAX_FAILURE,
  VIEW_FAX_DETAILS_REQUEST,
  VIEW_FAX_DETAILS_SUCCESS,
  VIEW_FAX_DETAILS_FAILURE,
  FETCH_FAX_SETTINGS_SUCCESS,
  FETCH_FAX_SETTINGS_FAILURE,
  SAVE_FAX_SETTINGS_REQUEST,
  SAVE_FAX_SETTINGS_SUCCESS,
  SAVE_FAX_SETTINGS_FAILURE,
  CHANGE_FAX_SEND_PARAMS,
  SEND_FAX_REQUEST,
  SEND_FAX_SUCCESS,
  SEND_FAX_FAILURE,
  FAX_SENT_NOTIFICATION,
  FAX_RECEIVED_NOTIFICATION,
  REFRESH_FAX_STATUS_REQUEST,
  REFRESH_FAX_STATUS_SUCCESS,
  REFRESH_FAX_STATUS_FAILURE,
  FAX_ERROR_NOTIFICATION,
} from './types';

export const fetchFaxSettingsSuccess = (data) => ({
  type: FETCH_FAX_SETTINGS_SUCCESS,
  data,
});
export const fetchFaxSettingsFailure = (errors) => ({
  type: FETCH_FAX_SETTINGS_FAILURE,
  errors,
});
export const fetchFaxListRequest = (payload, context) => ({
  type: FETCH_FAX_LIST_REQUEST,
  payload,
  context,
});
export const fetchFaxListSuccess = (data, context) => ({
  type: FETCH_FAX_LIST_SUCCESS,
  data,
  context,
});
export const fetchFaxListFailure = (errors, context) => ({
  type: FETCH_FAX_LIST_FAILURE,
  errors,
  context,
});
export const changeFaxListPageRequest = (pageNumber, context) => ({
  type: CHANGE_FAX_LIST_PAGE_REQUEST,
  pageNumber,
  context,
});
export const deleteFaxRequest = (payload) => ({
  type: DELETE_FAX_REQUEST,
  payload,
});
export const deleteFaxSuccess = (context) => ({
  type: DELETE_FAX_SUCCESS,
  context,
});
export const deleteFaxFailure = (errors, context) => ({
  type: DELETE_FAX_FAILURE,
  errors,
  context,
});
export const reloadFaxListRequest = (context) => ({
  type: RELOAD_FAX_LIST_REQUEST,
  context,
});
export const readFaxRequest = (faxId) => ({
  type: READ_FAX_REQUEST,
  faxId,
});
export const readFaxSuccess = (faxId) => ({
  type: READ_FAX_SUCCESS,
  faxId,
});
export const readFaxFailure = (errors) => ({
  type: READ_FAX_FAILURE,
  errors,
});
export const viewFaxDetailsRequest = (faxId, context) => ({
  type: VIEW_FAX_DETAILS_REQUEST,
  faxId,
  context,
});
export const viewFaxDetailsSuccess = (data, context) => ({
  type: VIEW_FAX_DETAILS_SUCCESS,
  data,
  context,
});
export const viewFaxDetailsFailure = (errors, context) => ({
  type: VIEW_FAX_DETAILS_FAILURE,
  errors,
  context,
});
export const saveFaxSettingsRequest = (payload) => ({
  type: SAVE_FAX_SETTINGS_REQUEST,
  payload,
});
export const saveFaxSettingsSuccess = (payload) => ({
  type: SAVE_FAX_SETTINGS_SUCCESS,
  payload,
});
export const saveFaxSettingsFailure = (errors) => ({
  type: SAVE_FAX_SETTINGS_FAILURE,
  errors,
});
export const changeFaxSendParams = (payload) => ({
  type: CHANGE_FAX_SEND_PARAMS,
  payload,
});
export const sendFaxRequest = (payload) => ({
  type: SEND_FAX_REQUEST,
  payload,
});
export const sendFaxSuccess = () => ({
  type: SEND_FAX_SUCCESS,
});
export const sendFaxFailure = (errors) => ({
  type: SEND_FAX_FAILURE,
  errors,
});
export const faxSentNotification = (payload) => ({
  type: FAX_SENT_NOTIFICATION,
  payload,
});
export const faxErrorNotification = (payload) => ({
  type: FAX_ERROR_NOTIFICATION,
  payload,
});
export const faxReceivedNotification = (payload) => ({
  type: FAX_RECEIVED_NOTIFICATION,
  payload,
});
export const refreshFaxStatusRequest = (faxId) => ({
  type: REFRESH_FAX_STATUS_REQUEST,
  faxId,
});
export const refreshFaxStatusSuccess = (payload) => ({
  type: REFRESH_FAX_STATUS_SUCCESS,
  payload,
});
export const refreshFaxStatusFailure = (errors) => ({
  type: REFRESH_FAX_STATUS_FAILURE,
  errors,
});
