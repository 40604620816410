export const isFetchPbxCallsLoaded = (state) => state.cdr.pbx.loaded;
export const isFetchPbxCallsError = (state) => state.cdr.pbx.error;
export const getFetchPbxCallsUserError = (state) => state.cdr.pbx.userErrors;
export const getPbxCalls = (state) => state.cdr.pbx.calls;
export const isDeleteRecordingError = (state) => state.cdr.me.errorDeleting;
export const isInsertNotesLoading = (state) => state.cdr.insertNotesLoading;
export const isInsertNotesError = (state) => state.cdr.insertNotesError;
export const getMeCallsLostLogsFilter = (state) => state.cdr.me.filterLost;
export const getMeCallsInLogsFilter = (state) => state.cdr.me.filterIn;
export const getMeCallsOutLogsFilter = (state) => state.cdr.me.filterOut;
export const getQueuesCallsLogFilter = (state) => state.cdr.queues.filter;
export const getPbxCallsFilter = (state) => state.cdr.pbx.filter;
export const getQueuesCalls = (state) => state.cdr.queues.calls;
export const isFetchQueuesCallsLoaded = (state) => state.cdr.queues.loaded;
export const isFetchQueuesCallsError = (state) => state.cdr.queues.error;
