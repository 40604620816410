import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import {
  addressbookGrant,
  hasGrants,
  isSMSenabled,
} from '../../../js/me/selectors';
import { registerSMSAlias } from '../../../js/sms/selectors';
import ynConf from '../../../conf';

const messages = defineMessages({
  title: {
    id: 'SMS.title',
    defaultMessage: 'SMS',
  },
  messages: {
    id: 'SMS.messages',
    defaultMessage: 'Sent messages',
  },
  scheduledMessages: {
    id: 'SMS.scheduledMessages',
    defaultMessage: 'Scheduled messages',
  },
  campaigns: {
    id: 'SMS.campaigns',
    defaultMessage: 'Campaigns',
  },
  alias: {
    id: 'SMS.alias',
    defaultMessage: 'Alias',
  },
  packages: {
    id: 'SMS.packages',
    defaultMessage: 'Packages',
  },
  askForSms1: {
    id: 'SMS.askForSms1',
    defaultMessage: 'If you want to enable SMS service please read ',
  },
  askForSmsLink1: {
    id: 'SMS.askForSmsLink1',
    defaultMessage: 'our conditions',
  },
  askForSms2: {
    id: 'SMS.askForSms2',
    defaultMessage: ' and then fill ',
  },
  askForSmsLink2: {
    id: 'SMS.askForSmsLink2',
    defaultMessage: 'this module',
  },
  askForSms3: {
    id: 'SMS.askForSms3',
    defaultMessage: ' and send it via pec to ',
  },
  askForSmsEmail: {
    id: 'SMS.askForSmsEmail',
    defaultMessage: 'assistenza@ambrogiopec.com',
  },
});

const SMS = ({
  intl: { formatMessage },
  children,
  registerAlias,
  scrolling,
  canManage,
  smsEnabled,
  contactGrant,
}) => {
  const nav = [
    {
      id: 'SMS.messages',
      href: '/sms/sent',
      label: messages.messages,
    },
    {
      id: 'SMS.scheduledMessages',
      href: '/sms/scheduled',
      label: messages.scheduledMessages,
    },
  ];

  if (contactGrant) {
    nav.push({
      id: 'SMS.campaigns',
      href: '/sms/campaigns',
      label: messages.campaigns,
    });
  }

  if (registerAlias && canManage) {
    nav.push({
      id: 'SMS.alias',
      href: '/sms/alias',
      label: messages.alias,
    });
  }

  if (canManage) {
    nav.push({
      id: 'SMS.packages',
      href: '/sms/packages',
      label: messages.packages,
    });
  }

  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {!smsEnabled && (
          <div className="alert alert-danger">
            {formatMessage(messages.askForSms1)}
            <a
              href={`${ynConf.clientBaseUrl}/assets/modulo-richiesta-sms.pdf`}
              className="alert-link mx-1"
              download
            >
              {formatMessage(messages.askForSmsLink1)}
            </a>
            {formatMessage(messages.askForSms2)}
            <a
              href="mailto:assistenza@ambrogiopec.com"
              className="alert-link mx-1"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage(messages.askForSmsEmail)}
            </a>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    registerAlias: registerSMSAlias(state),
    canManage: hasGrants(state, 'SMS_MANAGE'),
    smsEnabled: isSMSenabled(state),
    contactGrant: addressbookGrant(state),
  };
};

export default injectIntl(connect(mapStateToProps)(SMS));
