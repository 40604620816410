import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';
import { generateAckUid } from '../../js/websocket/WsUtils';
import { getCurrentVideocallRoom } from '../../js/videocalls/selectors';
import { showConfirmModal } from '../../js/confirm/actions';
import {
  saveScheduledVideocallRequest,
  startUserVideocallRequest,
} from '../../js/videocalls/actions';
import { getMeId } from '../../js/me/selectors';
import moment from 'moment';
import ynConf from '../../conf';
import {
  openChatConversation,
  sendChatMessageRequest,
} from '../../js/chat/actions';
import { generateConversationId } from '../../js/chat/ChatUtils';
import { isWsAvailable } from '../../js/auth/selectors';
import { isUserOnlineWeb, isVideocallDnd } from '../../js/users/selectors';

const messages = defineMessages({
  defaultTitle: {
    id: 'VideoButton.defaultTitle',
    defaultMessage: 'New videocall',
  },
  startVideocall: {
    id: 'VideoButton.tooltip.startVideocall',
    defaultMessage: 'Start videocall',
  },
  unavailable: {
    id: 'VideoButton.tooltip.unavailable',
    defaultMessage: 'Unavailable',
  },
  dnd: {
    id: 'VideoButton.tooltip.dnd',
    defaultMessage: 'Do not disturb',
  },
  addToExistingRoom: {
    id: 'VideoButton.addToExistingRoom',
    defaultMessage:
      'You have already an active room, do you wanna invite the user into this room?',
  },
  videocallInviteMessage: {
    id: 'VideoButton.videocallInviteMessage',
    defaultMessage: 'This is the videocall room:  ',
  },
});

class VideoButton extends Component {
  startVideocall = () => {
    const {
      myId,
      confirm,
      intl: { formatMessage },
      scheduleVideoCall,
      startUserVideocall,
      videocallRoom,
      user,
      group,
      sendMessage,
      openConversation,
      unavailable,
      isDndUser,
      isOnlineUser,
    } = this.props;
    if (unavailable || (!group && (isDndUser || !isOnlineUser))) {
      return;
    }
    if (videocallRoom) {
      if (group) {
        const sendChatMessage = () => {
          const ackUid = generateAckUid(myId);
          const arrangeMessage = {
            text: `${formatMessage(messages.videocallInviteMessage)} ${
              ynConf.clientBaseUrl
            }/videocall/exec/${ynConf.jitsi.appId}/${videocallRoom}`,
          };
          const data = {
            ackUid,
            recipient: {
              type: user ? 'USER' : 'GROUP',
              id: user || group,
            },
            message: arrangeMessage,
          };
          sendMessage(data);
          openConversation({
            conversationId: generateConversationId({
              userId: user,
              groupId: group,
            }),
          });
        };
        confirm({
          message: formatMessage(messages.addToExistingRoom),
          modalAction: sendChatMessage,
          actionParams: {},
        });
      } else {
        confirm({
          message: formatMessage(messages.addToExistingRoom),
          modalAction: startUserVideocall,
          actionParams: { user, room: videocallRoom },
        });
      }
    } else {
      if (group) {
        scheduleVideoCall(
          {
            name: formatMessage(messages.defaultTitle),
            authorization: {
              allLoggedUsers: true,
              users: [],
              groups: [],
              acceptGuests: true,
            },
            start: moment().valueOf(),
            end: moment().add(4, 'hours').valueOf(),
          },
          {
            user,
            group,
          }
        );
      } else {
        startUserVideocall({
          user,
          videocallData: {
            name: formatMessage(messages.defaultTitle),
            authorization: {
              allLoggedUsers: true,
              users: [],
              groups: [],
              acceptGuests: true,
            },
            start: moment().valueOf(),
            end: moment().add(4, 'hours').valueOf(),
          },
        });
      }
    }
  };

  render() {
    const {
      isOnlineUser,
      unavailable,
      isDndUser,
      group,
      intl: { formatMessage },
    } = this.props;
    return (
      <span onClick={this.startVideocall}>
        <Icon
          name={isOnlineUser || group ? 'video-camera-filled' : 'video-camera'}
          width={this.props.width}
          height={this.props.height}
          color={
            unavailable
              ? 'var(--yn-gray-500)'
              : isDndUser
              ? 'var(--orange)'
              : 'var(--yn-blue-dark)'
          }
          className={this.props.className}
          clickable={!unavailable}
          disabled={unavailable || (!group && (isDndUser || !isOnlineUser))}
        >
          {unavailable || (!group && !isOnlineUser)
            ? formatMessage(messages.unavailable)
            : isDndUser
            ? formatMessage(messages.dnd)
            : formatMessage(messages.startVideocall)}
        </Icon>
      </span>
    );
  }
}

VideoButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  user: PropTypes.number,
  group: PropTypes.number,
};

VideoButton.defaultProps = {
  width: 20,
  height: 20,
};

function mapStateToProps(state, ownProps) {
  return {
    unavailable: !isWsAvailable(state),
    isOnlineUser: ownProps.user && isUserOnlineWeb(state, ownProps.user),
    isDndUser: ownProps.user && isVideocallDnd(state, ownProps.user),
    videocallRoom: getCurrentVideocallRoom(state),
    myId: getMeId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    scheduleVideoCall: saveScheduledVideocallRequest,
    startUserVideocall: startUserVideocallRequest,
    openConversation: openChatConversation,
    sendMessage: sendChatMessageRequest,
  })(VideoButton)
);
