import React from 'react';
import { connect } from 'react-redux';
import {
  getOpenConversationsIds,
  getMaxOpenChatWindows,
  getBigConversationId,
} from '../../../js/chat/selectors';
import {
  setMaxOpenChatWindows,
  unsetActiveChatWindow,
} from '../../../js/chat/actions';
import OtherChatsButton from './OtherChatsButton';
import ChatWindowPage from './ChatWindowPage';

const MAX_WINDOW_WIDTH = 220;
const OTHER_CHATS_BUTTON_WIDTH = 50;

class ChatWindowsList extends React.Component {
  windowsList = React.createRef();

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize.bind(this));
    /* IT SEEMS NOT USED
    const { ipcRenderer } = window;
    if (ipcRenderer) {
      ipcRenderer.on('chat-hidden', () => {
        this.props.unsetActiveChat();
      });
    }*/
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleWindowResize() {
    if (!this.windowsList.current) return;
    const { maxConversations, setMaxWindows } = this.props;
    const maxChatWindows = Math.floor(
      this.windowsList.current.clientWidth / MAX_WINDOW_WIDTH
    );
    if (maxConversations !== maxChatWindows) {
      setMaxWindows(maxChatWindows);
    }
  }

  render() {
    const { bigConversation, openConversationsIds, maxConversations } =
      this.props;

    const normalConversations = bigConversation
      ? openConversationsIds.slice(0, 1)
      : openConversationsIds.slice(0, maxConversations);
    const minimizedConversations = bigConversation
      ? openConversationsIds.filter((id) => id !== bigConversation)
      : openConversationsIds.slice(maxConversations);

    const chatListPanelWidth = this.windowsList.current
      ? this.windowsList.current.clientWidth
      : 0;
    const windowWidth = bigConversation
      ? chatListPanelWidth
      : Math.floor(chatListPanelWidth / maxConversations);
    return (
      <div className="yn-chat-windows-list" ref={this.windowsList}>
        <div className="row" style={{ margin: 0 }}>
          {normalConversations.reverse().map((conversationId, index) => (
            <ChatWindowPage
              key={conversationId}
              conversationId={conversationId}
              position={bigConversation ? 0 : index * windowWidth}
              width={windowWidth}
            />
          ))}
          {minimizedConversations && minimizedConversations.length > 0 && (
            <OtherChatsButton
              width={OTHER_CHATS_BUTTON_WIDTH}
              conversations={minimizedConversations}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bigConversation: getBigConversationId(state),
    openConversationsIds: getOpenConversationsIds(state),
    maxConversations: getMaxOpenChatWindows(state),
  };
}

export default connect(mapStateToProps, {
  setMaxWindows: setMaxOpenChatWindows,
  unsetActiveChat: unsetActiveChatWindow,
})(ChatWindowsList);
