import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { injectIntl } from 'react-intl';

class Filters extends Component {
  render() {
    return (
      <div id={this.props.id} className="rounded bg-light px-2 mb-2">
        <Collapse isOpen={this.props.isOpen}>
          <div className="p-1">{this.props.children}</div>
        </Collapse>
        {/* <div className="text-right">
          <LinkButton onClick={this.toggle}>
            {open ? (
              <Icon
                name="shrink-horizontal-1-filled"
                width="0.7rem"
                height="0.7rem"
              />
            ) : (
              <Icon
                name="expand-horizontal-7-filled"
                width="0.7rem"
                height="0.7rem"
              />
            )}{' '}
            {open ? formatMessage(messages.hide) : formatMessage(messages.show)}
          </LinkButton>
            </div> */}
      </div>
    );
  }
}

Filters.propTypes = {
  isOpen: PropTypes.bool,
};

Filters.defaultProps = {
  isOpen: true,
};
export default injectIntl(Filters);
