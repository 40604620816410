import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select2 from '../../../components/formControls/Select2';
import { fetchSMSCampaignsRequest } from '../../../js/sms/actions';
import {
  getSMSCampaignsById,
  getSMSCampaignsIds,
} from '../../../js/sms/selectors';

class CampaignSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchCampaigns } = this.props;
    fetchCampaigns({
      onlyExecuted: true,
    });
  }

  arrangeOptions = () => {
    const { campaignsIds, campaignById } = this.props;
    const options = campaignsIds.map((id) => ({
      label: campaignById[id].name,
      value: id,
    }));
    return options;
  };

  render() {
    const { campaign, placeholder, onSelect } = this.props;
    const value = this.arrangeOptions().filter(
      (o) => o.value === parseInt(campaign, 10)
    );

    return (
      <Select2
        type="text"
        name="campaign"
        id="campaign"
        value={value}
        onChange={onSelect}
        options={this.arrangeOptions()}
        isClearable
        placeholder={placeholder}
      />
    );
  }
}

CampaignSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  campaign: PropTypes.number,
  placeholder: PropTypes.string,
};

CampaignSelect.defaultProps = {
  campaign: null,
  placeholder: null,
};

const mapStateToProps = (state) => {
  return {
    campaignsIds: getSMSCampaignsIds(state),
    campaignById: getSMSCampaignsById(state),
  };
};

export default connect(mapStateToProps, {
  fetchCampaigns: fetchSMSCampaignsRequest,
})(CampaignSelect);
