import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select2 from '../../../components/formControls/Select2';
import { fetchSMSAliasRequest } from '../../../js/sms/actions';
import { getSMSAliasIds, getSMSsAliasById } from '../../../js/sms/selectors';
import { SMSEnums } from '../../../js/sms/SMSUtils';

const messages = defineMessages({
  waitingApproval: {
    id: 'AliasSelect.waitingApproval',
    defaultMessage: '(waiting approval)',
  },
  defaultNumber: {
    id: 'AliasSelect.defaultNumber',
    defaultMessage: 'Default number',
  },
});

class AliasSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchAlias } = this.props;
    fetchAlias();
  }

  arrangeOptions = () => {
    const {
      aliasIds,
      aliasById,
      showDefault,
      intl: { formatMessage },
    } = this.props;
    const options = aliasIds.map((id) => ({
      label: `${aliasById[id].alias} ${
        aliasById[id].status !== SMSEnums.AliasStatus.AGCOM_CONFIRMED
          ? formatMessage(messages.waitingApproval)
          : ''
      }`,
      value: id,
      disabled: aliasById[id].status !== SMSEnums.AliasStatus.AGCOM_CONFIRMED,
    }));
    if (showDefault) {
      const defaultOptions = [
        { label: formatMessage(messages.defaultNumber), value: null },
      ];
      defaultOptions.concat(options);
      return defaultOptions;
    }
    return options;
  };

  render() {
    const { alias, placeholder, onSelect, error } = this.props;
    const value = this.arrangeOptions().filter((o) => o.value === alias);

    return (
      <Select2
        type="text"
        name="sender"
        id="sender"
        value={value}
        onChange={onSelect}
        options={this.arrangeOptions()}
        isClearable
        placeholder={placeholder}
        error={error}
      />
    );
  }
}

AliasSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  alias: PropTypes.number,
  placeholder: PropTypes.string,
  showDefault: PropTypes.bool,
};

AliasSelect.defaultProps = {
  alias: null,
  placeholder: null,
  showDefault: false,
};

const mapStateToProps = (state) => {
  return {
    aliasIds: getSMSAliasIds(state),
    aliasById: getSMSsAliasById(state),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchAlias: fetchSMSAliasRequest,
  })(AliasSelect)
);
