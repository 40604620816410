import { request } from '../request';

export default {
  getHotelAreaPrefixes: (params) =>
    request({
      url: '/hotel/prefixes',
      method: 'GET',
      params,
    }),
  getHotelPrices: () =>
    request({
      url: '/hotel/prices',
      method: 'GET',
    }),
  createHotelPrice: (price) =>
    request({
      url: '/hotel/prices',
      method: 'POST',
      data: price,
    }),
  updateHotelPrice: (price) =>
    request({
      url: `/hotel/prices/${price.id}`,
      method: 'POST',
      data: price,
    }),
  deleteHotelPrice: (id) =>
    request({
      url: `/hotel/prices/${id}`,
      method: 'DELETE',
    }),
  resetHotelPrices: () =>
    request({
      url: '/hotel/reset-prices',
      method: 'POST',
    }),
  toggleHotelWakeupEnable: (service) =>
    request({
      url: '/hotel/wakeup-service',
      method: 'POST',
      data: service,
    }),
  getHotelWakeupSettings: () =>
    request({
      url: '/hotel/wakeup-settings',
      method: 'GET',
    }),
  updateHotelWakeupSettings: (settings) =>
    request({
      url: '/hotel/wakeup-settings',
      method: 'POST',
      data: settings,
    }),
  getHotelWakeups: (params) =>
    request({
      url: '/hotel/wakeups',
      method: 'GET',
      params,
    }),
  createHotelWakeup: (wakeup) =>
    request({
      url: '/hotel/wakeups',
      method: 'POST',
      data: wakeup,
    }),
  updateHotelWakeup: (wakeup) =>
    request({
      url: `/hotel/wakeups/${wakeup.id}`,
      method: 'POST',
      data: wakeup,
    }),
  deleteHotelWakeup: (id) =>
    request({
      url: `/hotel/wakeups/${id}`,
      method: 'DELETE',
    }),
};
