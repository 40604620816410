import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const CalendarFestivitiesSettings = React.lazy(() =>
  import('./CalendarFestivitiesSettings')
);

const CalendarFestivitiesSettingsPage = (props) => (
  <Calendar>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <CalendarFestivitiesSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarFestivitiesSettingsPage;
