import { request } from '../request';

export default {
  updateLogo: (payload) =>
    request({
      url: '/customer/logo',
      method: 'POST',
      data: payload,
    }),
};
