export class VboxEnums {
  static Sex = {
    MALE: 'male',
    FEMALE: 'female',
  };

  static Language = {
    ITALIAN: 'italian',
    ENGLISH: 'english',
    FRENCH: 'french',
    GERMAN: 'german',
    SPANISH: 'spanish',
    PORTUGUESE: 'portuguese',
    RUSSIAN: 'russian',
    JAPANESE: 'japanese',
    CHINESE: 'chinese',
    ARABIC: 'arabic',
    AMERICAN: 'american',
    GREEK: 'greek',
    INDIFFERENT: 'indifferent',
  };

  static Genre = {
    CLASSIC: 'classic',
    MODERN: 'modern',
    CHRISTMAS: 'christmas',
    NONE: 'none',
  };

  static GreetingMessageType = {
    SPEAKER: 'SPEAKER',
    UPLOAD: 'UPLOAD',
    TTS: 'TTS',
    REC: 'REC',
  };
}

const selectLanguage = (language) => {
  let languageFilter = '';
  switch (language) {
    case VboxEnums.Language.ITALIAN:
      languageFilter = 'Italiano';
      break;
    case VboxEnums.Language.ENGLISH:
      languageFilter = 'Inglese';
      break;
    case VboxEnums.Language.FRENCH:
      languageFilter = 'Francese';
      break;
    case VboxEnums.Language.GERMAN:
      languageFilter = 'Tedesco';
      break;
    case VboxEnums.Language.SPANISH:
      languageFilter = 'Spagnolo';
      break;
    case VboxEnums.Language.PORTUGUESE:
      languageFilter = 'Portoghese';
      break;
    case VboxEnums.Language.RUSSIAN:
      languageFilter = 'Russo';
      break;
    case VboxEnums.Language.JAPANESE:
      languageFilter = 'Giapponese';
      break;
    case VboxEnums.Language.CHINESE:
      languageFilter = 'Cinese';
      break;
    case VboxEnums.Language.ARABIC:
      languageFilter = 'Arabo';
      break;
    case VboxEnums.Language.AMERICAN:
      languageFilter = 'Americano';
      break;
    case VboxEnums.Language.GREEK:
      languageFilter = 'Greco';
      break;
    default:
      languageFilter = {};
  }
  return languageFilter;
};

export const voicesSelect = (allSounds, language) => {
  const sounds = {};
  const languageFilter = selectLanguage(language);
  sounds[language] = {};
  sounds[language].female = allSounds.filter((sound) =>
    sound.type.includes(`Voce ${languageFilter} femminile`)
  );
  sounds[language].male = allSounds.filter((sound) =>
    sound.type.includes(`Voce ${languageFilter} maschile`)
  );
  return sounds;
};

export const ARCHIVED_MESSAGES_PAGE_SIZE = 10;
