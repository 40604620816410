import React from 'react';
import PresencesManagement from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const PunchingJustificationsList = React.lazy(() =>
  import('./PunchingJustificationsList')
);
const PunchingJustificationsPage = () => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="punching-justifications" />
            </div>
            <PunchingJustificationsList />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default PunchingJustificationsPage;
