export const SET_FILEBOX_FILTER = 'SET_FILEBOX_FILTER';
export const FETCH_FILEBOX_ITEMS_REQUEST = 'FETCH_FILEBOX_ITEMS_REQUEST';
export const FETCH_FILEBOX_ITEMS_SUCCESS = 'FETCH_FILEBOX_ITEMS_SUCCESS';
export const FETCH_FILEBOX_ITEMS_FAILURE = 'FETCH_FILEBOX_ITEMS_FAILURE';
export const FETCH_FILEBOX_SPACE_USAGE_REQUEST =
  'FETCH_FILEBOX_SPACE_USAGE_REQUEST';
export const FETCH_FILEBOX_SPACE_USAGE_SUCCESS =
  'FETCH_FILEBOX_SPACE_USAGE_SUCCESS';
export const FETCH_FILEBOX_SPACE_USAGE_FAILURE =
  'FETCH_FILEBOX_SPACE_USAGE_FAILURE';
export const DELETE_FILEBOX_FILE_REQUEST = 'DELETE_FILEBOX_FILE_REQUEST';
export const DELETE_FILEBOX_FILE_SUCCESS = 'DELETE_FILEBOX_FILE_SUCCESS';
export const DELETE_FILEBOX_FILE_FAILURE = 'DELETE_FILEBOX_FILE_FAILURE';
export const CREATE_FILEBOX_FOLDER_REQUEST = 'CREATE_FILEBOX_FOLDER_REQUEST';
export const CREATE_FILEBOX_FOLDER_SUCCESS = 'CREATE_FILEBOX_FOLDER_SUCCESS';
export const CREATE_FILEBOX_FOLDER_FAILURE = 'CREATE_FILEBOX_FOLDER_FAILURE';
export const DELETE_FILEBOX_FOLDER_REQUEST = 'DELETE_FILEBOX_FOLDER_REQUEST';
export const DELETE_FILEBOX_FOLDER_SUCCESS = 'DELETE_FILEBOX_FOLDER_SUCCESS';
export const DELETE_FILEBOX_FOLDER_FAILURE = 'DELETE_FILEBOX_FOLDER_FAILURE';
export const RENAME_FILEBOX_FILE_REQUEST = 'RENAME_FILEBOX_FILE_REQUEST';
export const RENAME_FILEBOX_FILE_SUCCESS = 'RENAME_FILEBOX_FILE_SUCCESS';
export const RENAME_FILEBOX_FILE_FAILURE = 'RENAME_FILEBOX_FILE_FAILURE';
export const FILEBOX_CLEAR_ERRORS = 'FILEBOX_CLEAR_ERRORS';
export const EDIT_FILEBOX_FOLDER_REQUEST = 'EDIT_FILEBOX_FOLDER_REQUEST';
export const EDIT_FILEBOX_FOLDER_SUCCESS = 'EDIT_FILEBOX_FOLDER_SUCCESS';
export const EDIT_FILEBOX_FOLDER_FAILURE = 'EDIT_FILEBOX_FOLDER_FAILURE';
export const ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST =
  'ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_REQUEST';
export const ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS =
  'ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_SUCCESS';
export const ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE =
  'ENABLE_DISABLE_FILEBOX_FILE_EXTERNAL_LINK_FAILURE';
export const FETCH_FILEBOX_FOLDER_DETAILS_REQUEST =
  'FETCH_FILEBOX_FOLDER_DETAILS_REQUEST';
export const FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS =
  'FETCH_FILEBOX_FOLDER_DETAILS_SUCCESS';
export const FETCH_FILEBOX_FOLDER_DETAILS_FAILURE =
  'FETCH_FILEBOX_FOLDER_DETAILS_FAILURE';
export const TOGGLE_LOCKING_FILE_REQUEST = 'TOGGLE_LOCKING_FILE_REQUEST';
export const TOGGLE_LOCKING_FILE_SUCCESS = 'TOGGLE_LOCKING_FILE_SUCCESS';
export const TOGGLE_LOCKING_FILE_FAILURE = 'TOGGLE_LOCKING_FILE_FAILURE';
export const FETCH_FILEBOX_FAVORITE_FILES_REQUEST =
  'FETCH_FILEBOX_FAVORITE_FILES_REQUEST';
export const FETCH_FILEBOX_FAVORITE_FILES_SUCCESS =
  'FETCH_FILEBOX_FAVORITE_FILES_SUCCESS';
export const FETCH_FILEBOX_FAVORITE_FILES_FAILURE =
  'FETCH_FILEBOX_FAVORITE_FILES_FAILURE';
export const SET_FILEBOX_ACTIVE_FILTER = 'SET_FILEBOX_ACTIVE_FILTER';
export const FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST =
  'FETCH_FILEBOX_ACTIVE_ITEMS_REQUEST';
export const FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS =
  'FETCH_FILEBOX_ACTIVE_ITEMS_SUCCESS';
export const FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE =
  'FETCH_FILEBOX_ACTIVE_ITEMS_FAILURE';
export const MOVE_FILEBOX_FILE_REQUEST = 'MOVE_FILEBOX_FILE_REQUEST';
export const MOVE_FILEBOX_FILE_SUCCESS = 'MOVE_FILEBOX_FILE_SUCCESS';
export const MOVE_FILEBOX_FILE_FAILURE = 'MOVE_FILEBOX_FILE_FAILURE';
export const MOVE_FILEBOX_FOLDER_REQUEST = 'MOVE_FILEBOX_FOLDER_REQUEST';
export const MOVE_FILEBOX_FOLDER_SUCCESS = 'MOVE_FILEBOX_FOLDER_SUCCESS';
export const MOVE_FILEBOX_FOLDER_FAILURE = 'MOVE_FILEBOX_FOLDER_FAILURE';
export const SELECT_FILEBOX_FOLDER = 'SELECT_FILEBOX_FOLDER';
export const SET_FILEBOX_ACTUAL_PATH = 'SET_FILEBOX_ACTUAL_PATH';
export const COPY_FILEBOX_FILE_REQUEST = 'COPY_FILEBOX_FILE_REQUEST';
export const COPY_FILEBOX_FILE_SUCCESS = 'COPY_FILEBOX_FILE_SUCCESS';
export const COPY_FILEBOX_FILE_FAILURE = 'COPY_FILEBOX_FILE_FAILURE';
export const COPY_FILEBOX_FOLDER_REQUEST = 'COPY_FILEBOX_FOLDER_REQUEST';
export const COPY_FILEBOX_FOLDER_SUCCESS = 'COPY_FILEBOX_FOLDER_SUCCESS';
export const COPY_FILEBOX_FOLDER_FAILURE = 'COPY_FILEBOX_FOLDER_FAILURE';
export const SEARCH_FILEBOX_FILES_FOLDERS_REQUEST =
  'SEARCH_FILEBOX_FILES_FOLDERS_REQUEST';
export const SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS =
  'SEARCH_FILEBOX_FILES_FOLDERS_SUCCESS';
export const SEARCH_FILEBOX_FILES_FOLDERS_FAILURE =
  'SEARCH_FILEBOX_FILES_FOLDERS_FAILURE';
export const SET_FILEBOX_PATH_FROM_SEARCH = 'SET_FILEBOX_PATH_FROM_SEARCH';
export const ADD_FILEBOX_FAVORITE_FILE_REQUEST =
  'ADD_FILEBOX_FAVORITE_FILE_REQUEST';
export const ADD_FILEBOX_FAVORITE_FILE_SUCCESS =
  'ADD_FILEBOX_FAVORITE_FILE_SUCCESS';
export const ADD_FILEBOX_FAVORITE_FILE_FAILURE =
  'ADD_FILEBOX_FAVORITE_FILE_FAILURE';
export const NEW_FILE_FILEBOX_NOTIFICATION = 'NEW_FILE_FILEBOX_NOTIFICATION';
export const NEW_FILE_COMMENT_FILEBOX_NOTIFICATION =
  'NEW_FILE_COMMENT_FILEBOX_NOTIFICATION';
export const SHARE_FOLDER_FILEBOX_NOTIFICATION =
  'SHARE_FOLDER_FILEBOX_NOTIFICATION';
export const UNSHARE_FOLDER_FILEBOX_NOTIFICATION =
  'UNSHARE_FOLDER_FILEBOX_NOTIFICATION';
export const REMOVE_FILEBOX_FAVORITE_FILE_REQUEST =
  'REMOVE_FILEBOX_FAVORITE_FILE_REQUEST';
export const REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS =
  'REMOVE_FILEBOX_FAVORITE_FILE_SUCCESS';
export const REMOVE_FILEBOX_FAVORITE_FILE_FAILURE =
  'REMOVE_FILEBOX_FAVORITE_FILE_FAILURE';
export const UPDATE_FILEBOX_SPACE_REQUEST = 'UPDATE_FILEBOX_SPACE_REQUEST';
export const UPDATE_FILEBOX_SPACE_SUCCESS = 'UPDATE_FILEBOX_SPACE_SUCCESS';
export const UPDATE_FILEBOX_SPACE_FAILURE = 'UPDATE_FILEBOX_SPACE_FAILURE';
export const DELETE_FILEBOX_MASSIVE_FILES_REQUEST =
  'DELETE_FILEBOX_MASSIVE_FILES_REQUEST';
export const DELETE_FILEBOX_MASSIVE_FILES_SUCCESS =
  'DELETE_FILEBOX_MASSIVE_FILES_SUCCESS';
export const DELETE_FILEBOX_MASSIVE_FILES_FAILURE =
  'DELETE_FILEBOX_MASSIVE_FILES_FAILURE';
export const FETCH_FILEBOX_FILE_COMMENTS_REQUEST =
  'FETCH_FILEBOX_FILE_COMMENTS_REQUEST';
export const FETCH_FILEBOX_FILE_COMMENTS_SUCCESS =
  'FETCH_FILEBOX_FILE_COMMENTS_SUCCESS';
export const FETCH_FILEBOX_FILE_COMMENTS_FAILURE =
  'FETCH_FILEBOX_FILE_COMMENTS_FAILURE';
export const CREATE_FILEBOX_FILE_COMMENT_REQUEST =
  'CREATE_FILEBOX_FILE_COMMENT_REQUEST';
export const CREATE_FILEBOX_FILE_COMMENT_SUCCESS =
  'CREATE_FILEBOX_FILE_COMMENT_SUCCESS';
export const CREATE_FILEBOX_FILE_COMMENT_FAILURE =
  'CREATE_FILEBOX_FILE_COMMENT_FAILURE';
export const DELETE_FILEBOX_FILE_COMMENT_REQUEST =
  'DELETE_FILEBOX_FILE_COMMENT_REQUEST';
export const DELETE_FILEBOX_FILE_COMMENT_SUCCESS =
  'DELETE_FILEBOX_FILE_COMMENT_SUCCESS';
export const DELETE_FILEBOX_FILE_COMMENT_FAILURE =
  'DELETE_FILEBOX_FILE_COMMENT_FAILURE';
export const RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST =
  'RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_REQUEST';
export const RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS =
  'RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_SUCCESS';
export const RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE =
  'RETRIEVE_ALL_FILEBOX_FILE_PREVIOUS_VERSIONS_FAILURE';
export const REMOVE_FILEBOX_FILE_VERSION_REQUEST =
  'REMOVE_FILEBOX_FILE_VERSION_REQUEST';
export const REMOVE_FILEBOX_FILE_VERSION_SUCCESS =
  'REMOVE_FILEBOX_FILE_VERSION_SUCCESS';
export const REMOVE_FILEBOX_FILE_VERSION_FAILURE =
  'REMOVE_FILEBOX_FILE_VERSION_FAILURE';
export const SET_FILEBOX_LIST_ORDER = 'SET_FILEBOX_LIST_ORDER';
export const FETCH_FILEBOX_DELETED_ITEMS_REQUEST =
  'FETCH_FILEBOX_DELETED_ITEMS_REQUEST';
export const FETCH_FILEBOX_DELETED_ITEMS_SUCCESS =
  'FETCH_FILEBOX_DELETED_ITEMS_SUCCESS';
export const FETCH_FILEBOX_DELETED_ITEMS_FAILURE =
  'FETCH_FILEBOX_DELETED_ITEMS_FAILURE';
export const SELECT_FILEBOX_DELETED_FOLDER = 'SELECT_FILEBOX_DELETED_FOLDER';
export const REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST =
  'REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_REQUEST';
export const REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS =
  'REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_SUCCESS';
export const REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE =
  'REMOVE_PERMANENTLY_FILEBOX_DELETED_ITEM_FAILURE';
export const RESTORE_FILEBOX_DELETED_ITEM_REQUEST =
  'RESTORE_FILEBOX_DELETED_ITEM_REQUEST';
export const RESTORE_FILEBOX_DELETED_ITEM_SUCCESS =
  'RESTORE_FILEBOX_DELETED_ITEM_SUCCESS';
export const RESTORE_FILEBOX_DELETED_ITEM_FAILURE =
  'RESTORE_FILEBOX_DELETED_ITEM_FAILURE';
export const SET_FILEBOX_ACTIVE_ORDER = 'SET_FILEBOX_ACTIVE_ORDER';
export const SET_FILEBOX_DELETED_ORDER = 'SET_FILEBOX_DELETED_ORDER';
export const FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST =
  'FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_REQUEST';
export const FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS =
  'FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_SUCCESS';
export const FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE =
  'FETCH_FILEBOX_FILE_EMAIL_RECIPIENTS_FAILURE';
export const SHARE_FILEBOX_FILE_REQUEST = 'SHARE_FILEBOX_FILE_REQUEST';
export const SHARE_FILEBOX_FILE_SUCCESS = 'SHARE_FILEBOX_FILE_SUCCESS';
export const SHARE_FILEBOX_FILE_FAILURE = 'SHARE_FILEBOX_FILE_FAILURE';
