import React from 'react';
import PersonalSettings from './index';
import ErrorBoundary from '../../../../components/ErrorBoundary';

const EmailSettings = React.lazy(() => import('./EmailSettings'));

const EmailSettingsPage = (props) => (
  <PersonalSettings>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <EmailSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PersonalSettings>
);

export default EmailSettingsPage;
