import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import { SMS_CAMPAIGNS_PAGE_SIZE } from '../../../js/sms/SMSUtils';
import {
  fetchSMSAliasRequest,
  fetchSMSCampaignsRequest,
} from '../../../js/sms/actions';
import {
  getSMSCredits,
  showIncomingSMSAlert,
  getFetchSMSCreditsError,
  isSMSCreditsFetched,
  usingSMSAlias,
  getFetchSMSCampaignsLoaded,
  getFetchSMSCampaignsError,
  getSMSCampaignsIds,
  getSMSCampaignsTotal,
  getSMSCampaignsCurrentPage,
} from '../../../js/sms/selectors';
import Pagination from '../../../components/Pagination';
import Button from '../../../components/formControls/Button';
import Alert from '../../../components/messages/Alert';
import SMSCampaignsRow from './SMSCampaignsRow';
import { fetchAbookListsRequest } from '../../../js/contacts/actions';
import SMSCreditsAlert from './SMSCreditsAlert';
import HelpButton from '../../../components/HelpButton';
import { isSMSCampaignEnabled, isSMSenabled } from '../../../js/me/selectors';
import ynConf from '../../../conf';

const messages = defineMessages({
  newCampaignButton: {
    id: 'SMSCampaigns.newCampaignButton',
    defaultMessage: 'New campaign',
  },
  creditsWarningText: {
    id: 'SMSCampaigns.creditsWarningText',
    defaultMessage: 'Hei! You have only {credits} more available SMS!',
  },
  creditsAlertText: {
    id: 'SMSCampaigns.creditsAlertText',
    defaultMessage: 'You have no more available SMS',
  },
  incomingSMSAlertText: {
    id: 'SMSCampaigns.incomingSMSAlertText',
    defaultMessage:
      'In the next week some SMS have to be sent! Check if you have enough credits',
  },
  errorFetchingCredits: {
    id: 'SMSCampaigns.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  scheduledDate: {
    id: 'SMSCampaigns.label.scheduledDate',
    defaultMessage: 'Scheduled date',
  },
  user: {
    id: 'SMSCampaigns.label.user',
    defaultMessage: 'User',
  },
  lists: {
    id: 'SMSCampaigns.label.lists',
    defaultMessage: 'Lists',
  },
  sender: {
    id: 'SMSCampaigns.label.sender',
    defaultMessage: 'Sender',
  },
  noData: {
    id: 'SMSCampaigns.noData',
    defaultMessage: 'There is nothing to show',
  },
  name: {
    id: 'SMSCampaigns.label.name',
    defaultMessage: 'Name',
  },
  status: {
    id: 'SMSCampaigns.label.status',
    defaultMessage: 'Status',
  },
  askForCampaigns1: {
    id: 'SMSCampaigns.askForCampaigns1',
    defaultMessage: 'If you want to enable SMS campaign service please read ',
  },
  askForCampaignsLink1: {
    id: 'SMSCampaigns.askForCampaignsLink1',
    defaultMessage: 'our conditions',
  },
  askForCampaigns2: {
    id: 'SMSCampaigns.askForCampaigns2',
    defaultMessage: ' and then fill ',
  },
  askForCampaignsLink2: {
    id: 'SMSCampaigns.askForCampaignsLink2',
    defaultMessage: 'this module',
  },
  askForCampaigns3: {
    id: 'SMSCampaigns.askForCampaigns3',
    defaultMessage: ' and send it via pec to ',
  },
  askForCampaignsEmail: {
    id: 'SMSCampaigns.askForCampaignsEmail',
    defaultMessage: 'assistenza@ambrogiopec.com',
  },
});

class SMSCampaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchLists, fetchAlias, useAlias } = this.props;
    fetchLists();
    if (useAlias) fetchAlias();
    this.fetchItems(0);
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchAlias, useAlias } = this.props;
    if (filter !== prevProps.filter) this.fetchItems();
    if (useAlias && !prevProps.useAlias) fetchAlias();
  }

  fetchItems = (page) => {
    const { fetch } = this.props;
    const params = {
      pageSize: SMS_CAMPAIGNS_PAGE_SIZE,
      page,
    };
    fetch(params);
  };

  render() {
    const {
      intl: { formatMessage },
      fetching,
      fetchingError,
      pages,
      page,
      campaignsIds,
      credits,
      showAlert,
      creditsFetched,
      fetchingCreditsError,
      campaignEnabled,
      smsEnabled,
    } = this.props;

    return !smsEnabled ? (
      <></>
    ) : fetchingError ? (
      <GlobalErrorPage />
    ) : (
      <>
        <div className="text-right">
          <HelpButton fileName="sms-campaigns" />
        </div>
        {creditsFetched && campaignEnabled && (
          <SMSCreditsAlert credits={credits} />
        )}
        {showAlert && creditsFetched && (
          <Alert
            text={formatMessage(messages.incomingSMSAlertText)}
            type="danger"
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        {!campaignEnabled && (
          <div className="alert alert-danger">
            {formatMessage(messages.askForCampaigns1)}
            <a
              href={`${ynConf.clientBaseUrl}/assets/modulo-richiesta-sms.pdf`}
              className="alert-link mx-1"
              download
            >
              {formatMessage(messages.askForCampaignsLink1)}
            </a>
            {formatMessage(messages.askForCampaigns2)}
            <a
              href="mailto:assistenza@ambrogiopec.com"
              className="alert-link mx-1"
              target="_blank"
              rel="noreferrer"
            >
              {formatMessage(messages.askForCampaignsEmail)}
            </a>
          </div>
        )}
        {campaignEnabled && (
          <div className="text-right mb-2">
            <Link to="/sms/campaigns/edit">
              <Button
                className="btn btn-primary"
                text={formatMessage(messages.newCampaignButton)}
              />
            </Link>
          </div>
        )}
        <div className="rounded border p-2">
          {fetching ? (
            <Loading />
          ) : (
            <table className="table table-hover table-borderless">
              <thead>
                <tr>
                  <th>{formatMessage(messages.scheduledDate)}</th>
                  <th>{formatMessage(messages.name)}</th>
                  {/* <th>{formatMessage(messages.user)}</th>
                  {useAlias && <th>{formatMessage(messages.sender)}</th>}
          <th>{formatMessage(messages.lists)}</th> */}
                  <th>{formatMessage(messages.status)}</th>
                  <th />
                </tr>
              </thead>
              {campaignsIds && campaignsIds.length > 0 ? (
                <tbody>
                  {campaignsIds.map((id) => (
                    <SMSCampaignsRow
                      key={id}
                      id={id}
                      campaignsEnabled={campaignEnabled}
                    />
                  ))}
                </tbody>
              ) : (
                <tfoot>
                  <tr>
                    <td colSpan="7">{formatMessage(messages.noData)}</td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
        {pages > 1 && (
          <Pagination
            pages={pages}
            currentPage={page}
            handlePage={this.handlePage}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    fetching: !getFetchSMSCampaignsLoaded(state),
    fetchingError: getFetchSMSCampaignsError(state),
    campaignsIds: getSMSCampaignsIds(state),
    credits: getSMSCredits(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    creditsFetched: isSMSCreditsFetched(state),
    showAlert: showIncomingSMSAlert(state),
    pages: Math.ceil(getSMSCampaignsTotal(state) / SMS_CAMPAIGNS_PAGE_SIZE),
    page: getSMSCampaignsCurrentPage(state),
    useAlias: usingSMSAlias(state),
    campaignEnabled: isSMSCampaignEnabled(state),
    smsEnabled: isSMSenabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchSMSCampaignsRequest,
    fetchLists: fetchAbookListsRequest,
    fetchAlias: fetchSMSAliasRequest,
  })(SMSCampaigns)
);
