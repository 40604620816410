import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_CUSTOMER_CONTRACT_DATA_REQUEST,
  FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS,
  FETCH_CUSTOMER_CONTRACT_DATA_FAILURE,
  FETCH_CUSTOMER_BILLS_REQUEST,
  FETCH_CUSTOMER_BILLS_SUCCESS,
  FETCH_CUSTOMER_BILLS_FAILURE,
  FETCH_CUSTOMER_PHONE_LINES_REQUEST,
  FETCH_CUSTOMER_PHONE_LINES_SUCCESS,
  FETCH_CUSTOMER_PHONE_LINES_FAILURE,
  FETCH_CUSTOMER_CONTRACTS_REQUEST,
  FETCH_CUSTOMER_CONTRACTS_SUCCESS,
  FETCH_CUSTOMER_CONTRACTS_FAILURE,
  FETCH_CONTRACT_NUMBERS_REQUEST,
  FETCH_CONTRACT_NUMBERS_SUCCESS,
  FETCH_CONTRACT_NUMBERS_FAILURE,
  FETCH_CONTRACT_TRAFFIC_REQUEST,
  FETCH_CONTRACT_TRAFFIC_SUCCESS,
  FETCH_CONTRACT_TRAFFIC_FAILURE,
  DELETE_CONTRACT_TRAFFIC,
  FETCH_CUSTOMER_SERVICES_REQUEST,
  FETCH_CUSTOMER_SERVICES_SUCCESS,
  FETCH_CUSTOMER_SERVICES_FAILURE,
} from './types';
import { LOGOUT_SUCCESS } from '../auth/types';

const initialErrorState = {
  error: '',
  success: '',
  loaded: true,
};

const initialState = {
  contractData: {
    error: '',
    success: '',
    loaded: true,
    documents: null,
  },
  customerPhoneLines: {
    error: '',
    success: '',
    loaded: true,
    phoneLines: null,
    total: null,
    currentPage: 0,
  },
  customerBills: {
    error: '',
    success: '',
    loaded: true,
    bills: null,
    totalBills: null,
    currentPageBills: 0,
  },
  customerContract: {
    error: '',
    success: '',
    loaded: true,
  },
  contract: null,
  totalContracts: null,
  currentPageContract: 0,
  contractNumbers: {
    error: '',
    success: '',
    loaded: true,
    numbers: null,
  },
  customerTraffic: {
    error: '',
    success: '',
    loaded: true,
  },
  traffic: null,
  currentPageTraffic: 0,
  totalTraffic: null,
  customerServices: {
    error: '',
    success: '',
    loaded: true,
  },
  services: null,
  currentPageServices: 0,
  totalServices: null,
};

export default function documents(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_CUSTOMER_CONTRACT_DATA_REQUEST:
      return {
        ...state,
        contractData: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS: {
      return {
        ...state,
        contractData: {
          error: '',
          success: action.success,
          loaded: true,
          documents: action.payload,
        },
      };
    }
    case FETCH_CUSTOMER_CONTRACT_DATA_FAILURE:
      return {
        ...state,
        contractData: {
          error: action.failure,
          success: '',
          loaded: true,
        },
      };

    /** Phone lines */

    case FETCH_CUSTOMER_PHONE_LINES_REQUEST:
      return {
        ...state,
        customerPhoneLines: {
          ...state.customerPhoneLines,
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CUSTOMER_PHONE_LINES_SUCCESS: {
      return {
        ...state,
        customerPhoneLines: {
          ...state.customerPhoneLines,
          error: '',
          success: action.success,
          loaded: true,
          phoneLines: action.payload.phoneLines.rows,
          total: action.payload.total,
          currentPage: action.payload.page,
        },
      };
    }
    case FETCH_CUSTOMER_PHONE_LINES_FAILURE:
      return {
        ...state,
        customerPhoneLines: {
          ...state.customerPhoneLines,
          error: action.errors,
          success: '',
          loaded: true,
        },
      };

    /** Bills */
    case FETCH_CUSTOMER_BILLS_REQUEST:
      return {
        ...state,
        customerBills: {
          ...state.customerBills,
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CUSTOMER_BILLS_SUCCESS: {
      return {
        ...state,

        customerBills: {
          ...state.customerBills,
          error: '',
          success: action.success,
          loaded: true,
          bills: action.payload.bills.rows,
          totalBills: action.payload.total,
          currentPageBills: action.payload.page,
        },
      };
    }
    case FETCH_CUSTOMER_BILLS_FAILURE:
      return {
        ...state,
        customerBills: {
          ...state.customerBills,
          error: action.error,
          success: '',
          loaded: true,
        },
      };

    case FETCH_CUSTOMER_CONTRACTS_REQUEST:
      return {
        ...state,
        customerContract: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CUSTOMER_CONTRACTS_SUCCESS: {
      return {
        ...state,
        customerContract: {
          error: '',
          success: '',
          loaded: true,
        },
        currentPage: action.payload.page,
        total: action.payload.total,
        contract: action.payload.contract,
      };
    }
    case FETCH_CUSTOMER_CONTRACTS_FAILURE:
      return {
        ...state,
        customerContract: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    case FETCH_CONTRACT_NUMBERS_REQUEST:
      return {
        ...state,
        contractNumbers: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CONTRACT_NUMBERS_SUCCESS: {
      return {
        ...state,
        contractNumbers: {
          error: '',
          success: '',
          loaded: true,
          numbers: action.payload,
        },
      };
    }
    case FETCH_CONTRACT_NUMBERS_FAILURE:
      return {
        ...state,
        contractNumbers: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    case FETCH_CONTRACT_TRAFFIC_REQUEST:
      return {
        ...state,
        customerTraffic: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CONTRACT_TRAFFIC_SUCCESS: {
      return {
        ...state,
        currentPageTraffic: action.payload.page,
        totalTraffic: action.payload.total,
        traffic: action.payload.traffic.rows,
        customerTraffic: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    }
    case FETCH_CONTRACT_TRAFFIC_FAILURE:
      return {
        ...state,
        customerTraffic: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    case FETCH_CUSTOMER_SERVICES_REQUEST:
      return {
        ...state,
        customerServices: {
          error: '',
          success: '',
          loaded: false,
        },
      };
    case FETCH_CUSTOMER_SERVICES_SUCCESS: {
      return {
        ...state,
        currentPageServices: action.payload.page,
        totalServices: action.payload.total,
        services: action.payload.services.rows,
        customerServices: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    }
    case FETCH_CUSTOMER_SERVICES_FAILURE:
      return {
        ...state,
        customerServices: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    case DELETE_CONTRACT_TRAFFIC:
      return {
        ...state,
        traffic: null,
        customerTraffic: {
          error: '',
          success: '',
          loaded: true,
        },
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        customerContract: initialErrorState,
        traffic: null,
      };
    default:
      return state;
  }
}
