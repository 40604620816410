import React from 'react';
import Notes from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const StaticNotesList = React.lazy(() => import('./StaticNotesList'));

const StaticNotesPage = () => {
  return (
    <Notes>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="static-notes" />
            </div>
            <StaticNotesList />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </Notes>
  );
};

export default StaticNotesPage;
