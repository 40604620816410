import React, { Component } from 'react';
import moment from 'moment';

class Timer extends Component {
  state = {
    time: 0,
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentDidUpdate(prevprops) {
    if (this.props.time === 0 && prevprops.time !== this.props.time) {
      this.setState({ time: 0 });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    this.setState({ time: this.state.time + 1 });
  };

  render() {
    const milliseconds = moment
      .utc(this.props.time * 1000)
      .add(this.state.time, 'seconds')
      .valueOf();
    const time =
      milliseconds >= 3600000
        ? moment
            .utc(this.props.time * 1000)
            .add(this.state.time, 'seconds')
            .format('HH:mm:ss')
        : moment
            .utc(this.props.time * 1000)
            .add(this.state.time, 'seconds')
            .format('mm:ss');

    return <span>{time}</span>;
  }
}

export default Timer;
