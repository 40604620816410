import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import Loading from '../../../components/Loading';
import { getRulesIds } from '../../../js/phoneRules/selectors';
import PhoneRulesShowRow from './PhoneRulesShowRow';
import { hasGrants } from '../../../js/me/selectors';
import { getSupplier } from '../../../js/phone/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { PhoneRulesEnums } from '../../../js/phoneRules/PhoneRulesUtils';
import { fetchArchivedGreetingMessagesRequest } from '../../../js/vbox/actions';

const messages = defineMessages({
  enabled: {
    id: 'PhoneRulesShowList.label.enabled',
    defaultMessage: 'Enabled',
  },
  days: {
    id: 'PhoneRulesShowList.label.days',
    defaultMessage: 'Days',
  },
  hour: {
    id: 'PhoneRulesShowList.label.hour',
    defaultMessage: 'Hour',
  },
  status: {
    id: 'PhoneRulesShowList.label.status',
    defaultMessage: 'Status',
  },
  action: {
    id: 'PhoneRulesShowList.label.action',
    defaultMessage: 'Action',
  },
  noRules: {
    id: 'PhoneRulesShowList.label.noRules',
    defaultMessage: 'No Settings',
  },
  subject: {
    id: 'PhoneRulesShowList.label.subject',
    defaultMessage: 'Subject',
  },
  route: {
    id: 'PhoneRulesShowList.label.route',
    defaultMessage: 'Route',
  },
  extension: {
    id: 'PhoneRulesShowList.label.extension',
    defaultMessage: 'Extension',
  },
  details: {
    id: 'PhoneRulesShowList.label.details',
    defaultMessage: 'Details',
  },
});

class PhoneRulesShowList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchAudio, context } = this.props;
    if (context === PhoneRulesEnums.SettingsContext.pbx) fetchAudio();
  }

  render() {
    const {
      intl: { formatMessage },
      loading,
      context,
      deleteFunc,
      canEdit,
      toggleEnabledFunc,
      supplier,
      rules,
    } = this.props;

    return (
      <div className="rounded border p-2">
        {loading ? (
          <Loading />
        ) : (
          <table
            className={`table  table-borderless table-hover ${
              supplier === PhoneEnums.PbxType.NETHESIS &&
              context === PhoneRulesEnums.SettingsContext.pbx
                ? 'yn-table'
                : ''
            }`}
          >
            {supplier === PhoneEnums.PbxType.ABILIS && (
              <thead>
                <tr>
                  {canEdit && <th>{formatMessage(messages.enabled)}</th>}
                  <th>{formatMessage(messages.days)}</th>
                  {/*<th>{formatMessage(messages.hour)}</th>*/}
                  <th>{formatMessage(messages.status)}</th>
                  <th>{formatMessage(messages.action)}</th>
                  {canEdit && <th />}
                </tr>
              </thead>
            )}
            {supplier === PhoneEnums.PbxType.NETHESIS && (
              <thead>
                <tr style={{ display: 'flex', flex: 1 }}>
                  {canEdit && (
                    <th className="text-center" style={{ flex: `0 0 75px` }}>
                      {formatMessage(messages.enabled)}
                    </th>
                  )}
                  {context === PhoneRulesEnums.SettingsContext.pbx && (
                    <th style={{ flex: '0 0 100px' }}>
                      {formatMessage(messages.route)}
                    </th>
                  )}
                  {context === PhoneRulesEnums.SettingsContext.users && (
                    <th style={{ flex: '0 0 100px' }}>
                      {formatMessage(messages.extension)}
                    </th>
                  )}
                  <th
                    style={
                      context === PhoneRulesEnums.SettingsContext.me
                        ? { flex: '0 0 200px' }
                        : { flex: '0 0 150px' }
                    }
                  >
                    {formatMessage(messages.subject)}
                  </th>
                  <th style={{ flex: '1 1 40%' }}>
                    {formatMessage(messages.details)}
                  </th>
                  {canEdit && <th style={{ flex: '0 0 65px' }} />}
                </tr>
              </thead>
            )}

            <tbody>
              {rules.map((rule) => (
                <PhoneRulesShowRow
                  key={rule}
                  id={rule}
                  context={context}
                  deleteFunc={deleteFunc}
                  toggleEnabledFunc={toggleEnabledFunc}
                  canEdit={canEdit}
                />
              ))}
            </tbody>
            {rules.length === 0 && (
              <tfoot>
                <tr>
                  <td colSpan="5">{formatMessage(messages.noRules)}</td>
                </tr>
              </tfoot>
            )}
          </table>
        )}
      </div>
    );
  }
}

PhoneRulesShowList.propTypes = {
  loading: PropTypes.bool.isRequired,
  context: PropTypes.string.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  toggleEnabledFunc: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    supplier: getSupplier(state),
    rules: getRulesIds(state, ownProps.context),
    canEdit:
      (ownProps.context &&
        ownProps.context === PhoneRulesEnums.SettingsContext.me) ||
      (ownProps.context === PhoneRulesEnums.SettingsContext.pbx &&
        hasGrants(state, 'PBX_HOLIDAYS_WRITE')) ||
      (ownProps.context === PhoneRulesEnums.SettingsContext.users &&
        hasGrants(state, 'PBX_HOLIDAYS_WRITE')),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    fetchAudio: fetchArchivedGreetingMessagesRequest,
  })(PhoneRulesShowList)
);
