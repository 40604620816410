import {
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_NATIONS_REQUEST,
  FETCH_NATIONS_SUCCESS,
  FETCH_NATIONS_FAILURE,
  SAVE_ABOOK_CONTACT_REQUEST,
  SAVE_ABOOK_CONTACT_SUCCESS,
  SAVE_ABOOK_CONTACT_FAILURE,
  FETCH_ABOOK_CONTACTS_REQUEST,
  FETCH_ABOOK_CONTACTS_SUCCESS,
  FETCH_ABOOK_CONTACTS_FAILURE,
  FETCH_ABOOK_INITIALS_REQUEST,
  FETCH_ABOOK_INITIALS_SUCCESS,
  FETCH_ABOOK_INITIALS_FAILURE,
  SET_ABOOK_FILTER,
  FETCH_ABOOK_TAGS_REQUEST,
  FETCH_ABOOK_TAGS_SUCCESS,
  FETCH_ABOOK_TAGS_FAILURE,
  FETCH_ABOOK_CONTACT_REQUEST,
  FETCH_ABOOK_CONTACT_SUCCESS,
  FETCH_ABOOK_CONTACT_FAILURE,
  DELETE_ABOOK_CONTACT_REQUEST,
  DELETE_ABOOK_CONTACT_SUCCESS,
  DELETE_ABOOK_CONTACT_FAILURE,
  DELETE_MASSIVE_CONTACTS_REQUEST,
  DELETE_MASSIVE_CONTACTS_SUCCESS,
  DELETE_MASSIVE_CONTACTS_FAILURE,
  FETCH_CACHED_CONTACT_SUCCESS,
  IMPORT_ABOOK_CONTACT_REQUEST,
  IMPORT_ABOOK_CONTACT_SUCCESS,
  IMPORT_ABOOK_CONTACT_FAILURE,
  CANCEL_IMPORT_ABOOK_CONTACT_REQUEST,
  CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS,
  CANCEL_IMPORT_ABOOK_CONTACT_FAILURE,
  TOGGLE_IMPORT_ABOOK_CONTACT_MODAL,
  CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST,
  CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS,
  CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE,
  ABOOK_UPDATE_NOTIFICATION,
  ABOOK_DELETE_NOTIFICATION,
  ABOOK_INSERT_NOTIFICATION,
  ABOOK_IMPORT_NOTIFICATION,
  ABOOK_BULK_DELETE_NOTIFICATION,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_REQUEST,
  FETCH_IMPORT_STATUS_REQUEST,
  FETCH_IMPORT_STATUS_SUCCESS,
  FETCH_IMPORT_STATUS_FAILURE,
  FETCH_ATECO_CODES_REQUEST,
  FETCH_ATECO_CODES_SUCCESS,
  FETCH_ATECO_CODES_FAILURE,
  IMPORT_ABOOK_BATCH_COMPLETED,
  CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST,
  FETCH_ABOOK_LISTS_REQUEST,
  FETCH_ABOOK_LISTS_SUCCESS,
  FETCH_ABOOK_LISTS_FAILURE,
  FETCH_ABOOK_LIST_REQUEST,
  FETCH_ABOOK_LIST_SUCCESS,
  FETCH_ABOOK_LIST_FAILURE,
  SAVE_ABOOK_LIST_REQUEST,
  SAVE_ABOOK_LIST_SUCCESS,
  SAVE_ABOOK_LIST_FAILURE,
  DELETE_ABOOK_LIST_REQUEST,
  DELETE_ABOOK_LIST_SUCCESS,
  DELETE_ABOOK_LIST_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS,
  FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE,
  SET_ABOOK_INITIAL,
  FETCH_ABOOK_NUMBER_REQUEST,
  FETCH_ABOOK_NUMBER_SUCCESS,
  FETCH_ABOOK_NUMBER_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE,
  FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST,
  FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS,
  FETCH_EMAILS_REQUEST,
  FETCH_EMAILS_SUCCESS,
} from './types';

export const fetchContactsRequest = (params) => ({
  type: FETCH_ABOOK_CONTACTS_REQUEST,
  params,
});
export const fetchContactsSuccess = (contacts) => ({
  type: FETCH_ABOOK_CONTACTS_SUCCESS,
  contacts,
});
export const fetchContactsFailure = (errors) => ({
  type: FETCH_ABOOK_CONTACTS_FAILURE,
  errors,
});
export const fetchAbookContactRequest = (contactId) => ({
  type: FETCH_ABOOK_CONTACT_REQUEST,
  contactId,
});
export const fetchAbookContactSuccess = (contactData) => ({
  type: FETCH_ABOOK_CONTACT_SUCCESS,
  contactData,
});
export const fetchAbookContactFailure = (errors) => ({
  type: FETCH_ABOOK_CONTACT_FAILURE,
  errors,
});
export const fetchAbookTagsRequest = (tag) => ({
  type: FETCH_ABOOK_TAGS_REQUEST,
  tag,
});
export const fetchAbookTagsSuccess = (tags) => ({
  type: FETCH_ABOOK_TAGS_SUCCESS,
  tags,
});
export const fetchAbookTagsFailure = (errors) => ({
  type: FETCH_ABOOK_TAGS_FAILURE,
  errors,
});
export const setAbookFilter = (filter) => ({ type: SET_ABOOK_FILTER, filter });
export const setAbookInitial = (initial) => ({
  type: SET_ABOOK_INITIAL,
  initial,
});
export const fetchAbookInitialsRequest = (initials) => ({
  type: FETCH_ABOOK_INITIALS_REQUEST,
  initials,
});
export const fetchAbookInitialsSuccess = (initials, filters) => ({
  type: FETCH_ABOOK_INITIALS_SUCCESS,
  initials,
  filters
});
export const fetchAbookInitialsFailure = (errors) => ({
  type: FETCH_ABOOK_INITIALS_FAILURE,
  errors,
});
export const fetchCitiesRequest = (name) => ({
  type: FETCH_CITIES_REQUEST,
  name,
});
export const fetchCitiesSuccess = (cities) => ({
  type: FETCH_CITIES_SUCCESS,
  cities,
});
export const fetchCitiesFailure = (errors) => ({
  type: FETCH_CITIES_FAILURE,
  errors,
});
export const fetchNationsRequest = (name) => ({
  type: FETCH_NATIONS_REQUEST,
  name,
});
export const fetchNationsSuccess = (nations) => ({
  type: FETCH_NATIONS_SUCCESS,
  nations,
});
export const fetchNationsFailure = (errors) => ({
  type: FETCH_NATIONS_FAILURE,
  errors,
});
export const saveAbookContactRequest = (payload) => ({
  type: SAVE_ABOOK_CONTACT_REQUEST,
  payload,
});
export const saveAbookContactSuccess = (contactData) => ({
  type: SAVE_ABOOK_CONTACT_SUCCESS,
  contactData,
});
export const saveAbookContactFailure = (errors) => ({
  type: SAVE_ABOOK_CONTACT_FAILURE,
  errors,
});
export const deleteAbookContactRequest = (contactId) => ({
  type: DELETE_ABOOK_CONTACT_REQUEST,
  contactId,
});
export const deleteAbookContactSuccess = (contactId) => ({
  type: DELETE_ABOOK_CONTACT_SUCCESS,
  contactId,
});
export const fetchCachedContactSuccess = (contactData) => ({
  type: FETCH_CACHED_CONTACT_SUCCESS,
  contactData,
});
export const deleteAbookContactFailure = (errors) => ({
  type: DELETE_ABOOK_CONTACT_FAILURE,
  errors,
});
export const deleteMassiveContactsRequest = (contactData) => ({
  type: DELETE_MASSIVE_CONTACTS_REQUEST,
  contactData,
});
export const deleteMassiveContactsSuccess = (success) => ({
  type: DELETE_MASSIVE_CONTACTS_SUCCESS,
  success,
});
export const deleteMassiveContactsFailure = (errors) => ({
  type: DELETE_MASSIVE_CONTACTS_FAILURE,
  errors,
});
export const importAbookContactRequest = (data) => ({
  type: IMPORT_ABOOK_CONTACT_REQUEST,
  data,
});
export const importAbookContactSuccess = (success) => ({
  type: IMPORT_ABOOK_CONTACT_SUCCESS,
  success,
});
export const importAbookContactFailure = (errors) => ({
  type: IMPORT_ABOOK_CONTACT_FAILURE,
  errors,
});
export const cancelImportAbookContactRequest = (status) => ({
  type: CANCEL_IMPORT_ABOOK_CONTACT_REQUEST,
  status,
});
export const cancelImportAbookContactSuccess = (success) => ({
  type: CANCEL_IMPORT_ABOOK_CONTACT_SUCCESS,
  success,
});
export const cancelImportAbookContactFailure = (errors) => ({
  type: CANCEL_IMPORT_ABOOK_CONTACT_FAILURE,
  errors,
});
export const cancelImportAbookErrorContactsRequest = (status) => ({
  type: CANCEL_IMPORT_ABOOK_ERROR_CONTACTS_REQUEST,
  status,
});
export const confirmImportAbookContactRequest = (data) => ({
  type: CONFIRM_IMPORT_ABOOK_CONTACT_REQUEST,
  data,
});
export const confirmImportAbookContactSuccess = (data) => ({
  type: CONFIRM_IMPORT_ABOOK_CONTACT_SUCCESS,
  data,
});
export const confirmImportAbookContactFailure = (errors) => ({
  type: CONFIRM_IMPORT_ABOOK_CONTACT_FAILURE,
  errors,
});
export const toggleImportAbookModal = (state) => ({
  type: TOGGLE_IMPORT_ABOOK_CONTACT_MODAL,
  state,
});
export const abookInsertNotification = (payload) => ({
  type: ABOOK_INSERT_NOTIFICATION,
  payload,
});
export const abookUpdateNotification = (payload) => ({
  type: ABOOK_UPDATE_NOTIFICATION,
  payload,
});
export const abookDeleteNotification = (payload) => ({
  type: ABOOK_DELETE_NOTIFICATION,
  payload,
});
export const abookImportNotification = (payload) => ({
  type: ABOOK_IMPORT_NOTIFICATION,
  payload,
});
export const abookBulkDeleteNotification = (payload) => ({
  type: ABOOK_BULK_DELETE_NOTIFICATION,
  payload,
});
export const fetchCompaniesRequest = (name) => ({
  type: FETCH_COMPANIES_REQUEST,
  name,
});
export const fetchCompaniesSuccess = (companies) => ({
  type: FETCH_COMPANIES_SUCCESS,
  companies,
});
export const fetchCompaniesFailure = (errors) => ({
  type: FETCH_COMPANIES_FAILURE,
  errors,
});
export const fetchImportStatusRequest = () => ({
  type: FETCH_IMPORT_STATUS_REQUEST,
});
export const fetchImportStatusSuccess = (response) => ({
  type: FETCH_IMPORT_STATUS_SUCCESS,
  response,
});
export const fetchImportStatusFailure = (errors) => ({
  type: FETCH_IMPORT_STATUS_FAILURE,
  errors,
});
export const abookImportBatchNotification = (payload) => ({
  type: IMPORT_ABOOK_BATCH_COMPLETED,
  payload,
});
export const fetchAtecoCodesRequest = (data) => ({
  type: FETCH_ATECO_CODES_REQUEST,
  data,
});
export const fetchAtecoCodesSuccess = (payload) => ({
  type: FETCH_ATECO_CODES_SUCCESS,
  payload,
});
export const fetchAtecoCodesFailure = (errors) => ({
  type: FETCH_ATECO_CODES_FAILURE,
  errors,
});
export const fetchAbookListsRequest = (payload) => ({
  type: FETCH_ABOOK_LISTS_REQUEST,
  payload,
});
export const fetchAbookListsSuccess = (payload) => ({
  type: FETCH_ABOOK_LISTS_SUCCESS,
  payload,
});
export const fetchAbookListsFailure = (payload) => ({
  type: FETCH_ABOOK_LISTS_FAILURE,
  payload,
});
export const fetchAbookListRequest = (payload) => ({
  type: FETCH_ABOOK_LIST_REQUEST,
  payload,
});
export const fetchAbookListSuccess = (payload) => ({
  type: FETCH_ABOOK_LIST_SUCCESS,
  payload,
});
export const fetchAbookListFailure = (payload) => ({
  type: FETCH_ABOOK_LIST_FAILURE,
  payload,
});
export const saveAbookListRequest = (payload) => ({
  type: SAVE_ABOOK_LIST_REQUEST,
  payload,
});
export const saveAbookListSuccess = (id) => ({
  type: SAVE_ABOOK_LIST_SUCCESS,
  id,
});
export const saveAbookListFailure = (payload) => ({
  type: SAVE_ABOOK_LIST_FAILURE,
  payload,
});
export const deleteAbookListRequest = (payload) => ({
  type: DELETE_ABOOK_LIST_REQUEST,
  payload,
});
export const deleteAbookListSuccess = (payload) => ({
  type: DELETE_ABOOK_LIST_SUCCESS,
  payload,
});
export const deleteAbookListFailure = (payload) => ({
  type: DELETE_ABOOK_LIST_FAILURE,
  payload,
});
export const fetchAbookContactInfoCallsRequest = (params) => ({
  type: FETCH_ABOOK_CONTACT_INFO_CALLS_REQUEST,
  params,
});
export const fetchAbookContactInfoCallsSuccess = (calls) => ({
  type: FETCH_ABOOK_CONTACT_INFO_CALLS_SUCCESS,
  calls,
});
export const fetchAbookContactInfoCallsFailure = (error) => ({
  type: FETCH_ABOOK_CONTACT_INFO_CALLS_FAILURE,
  error,
});
export const fetchAbookContactInfoTicketsRequest = (params) => ({
  type: FETCH_ABOOK_CONTACT_INFO_TICKETS_REQUEST,
  params,
});
export const fetchAbookContactInfoTicketsSuccess = (data) => ({
  type: FETCH_ABOOK_CONTACT_INFO_TICKETS_SUCCESS,
  data,
});
export const fetchAbookContactInfoTicketsFailure = () => ({
  type: FETCH_ABOOK_CONTACT_INFO_TICKETS_FAILURE,
});
export const fetchAbookContactInfoEventsRequest = (params) => ({
  type: FETCH_ABOOK_CONTACT_INFO_EVENTS_REQUEST,
  params,
});
export const fetchAbookContactInfoEventsSuccess = (data) => ({
  type: FETCH_ABOOK_CONTACT_INFO_EVENTS_SUCCESS,
  data,
});
export const fetchAbookContactInfoEventsFailure = () => ({
  type: FETCH_ABOOK_CONTACT_INFO_EVENTS_FAILURE,
});
export const fetchAbookNumberRequest = (payload) => ({
  type: FETCH_ABOOK_NUMBER_REQUEST,
  payload,
});
export const fetchAbookNumberSuccess = (payload) => ({
  type: FETCH_ABOOK_NUMBER_SUCCESS,
  payload,
});
export const fetchAbookNumberFailure = (payload) => ({
  type: FETCH_ABOOK_NUMBER_FAILURE,
  payload,
});
export const fetchAbookContactInfoFaxesRequest = (params) => ({
  type: FETCH_ABOOK_CONTACT_INFO_FAXES_REQUEST,
  params,
});
export const fetchAbookContactInfoFaxesSuccess = (faxes) => ({
  type: FETCH_ABOOK_CONTACT_INFO_FAXES_SUCCESS,
  faxes,
});
export const fetchAbookContactInfoFaxesFailure = (error) => ({
  type: FETCH_ABOOK_CONTACT_INFO_FAXES_FAILURE,
  error,
});
export const fetchEmailsRequest = (params) => ({
  type: FETCH_EMAILS_REQUEST,
  params,
});
export const fetchEmailsSuccess = (emails) => ({
  type: FETCH_EMAILS_SUCCESS,
  emails,
});
