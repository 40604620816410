import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../../components/formControls/Button';
import Label from '../../../components/formControls/Label';
import Select2 from '../../../components/formControls/Select2';
import ToastMessage from '../../../components/messages/ToastMessage';
import Input from '../../../components/formControls/Input';
import {
  clearPhoneExternalUrlErrors,
  editPhoneExternalUrlRequest,
  insertPhoneExternalUrlRequest,
} from '../../../js/externalUrl/actions';
import {
  getInsertPhoneExternalUrlError,
  getInsertPhoneExternalUrlLoaded,
  getPhoneExternalUrlFromId,
} from '../../../js/externalUrl/selectors';
import { ExternalUrlUtils } from '../../../js/externalUrl/ExternalUrlUtils';
import DateFormatModal from '../../../components/DateFormatModal';

const messages = defineMessages({
  newUrlTitle: {
    id: 'EditPhoneExternalUrlModal.newUrlTitle',
    defaultMessage: 'Add new Url',
  },
  editUrlTitle: {
    id: 'EditPhoneExternalUrlModal.editUrlTitle',
    defaultMessage: 'Edit Url',
  },
  event: {
    id: 'EditPhoneExternalUrlModal.event',
    defaultMessage: 'Event',
  },
  action: {
    id: 'EditPhoneExternalUrlModal.action',
    defaultMessage: 'Action',
  },
  constraint: {
    id: 'EditPhoneExternalUrlModal.constraint',
    defaultMessage: 'Constraint',
  },
  link: {
    id: 'EditPhoneExternalUrlModal.link',
    defaultMessage: 'Link',
  },
  linkOptions: {
    id: 'EditPhoneExternalUrlModal.linkOptions',
    defaultMessage:
      'Click on these parameters if you want to insert them in the link',
  },
  INCOMING: {
    id: 'EditPhoneExternalUrlModal.INCOMING',
    defaultMessage: 'On incoming call',
  },
  ANSWERED: {
    id: 'EditPhoneExternalUrlModal.ANSWERED',
    defaultMessage: 'On answered call',
  },
  HANGUP: {
    id: 'EditPhoneExternalUrlModal.HANGUP',
    defaultMessage: 'On hangup call',
  },
  LINK: {
    id: 'EditPhoneExternalUrlModal.LINK',
    defaultMessage: 'Open link in new tab',
  },
  ALWAYS: {
    id: 'EditPhoneExternalUrlModal.ALWAYS',
    defaultMessage: 'Every moment',
  },
  API: {
    id: 'EditPhoneExternalUrlModal.API',
    defaultMessage: 'Call API in background',
  },
  ICON: {
    id: 'EditPhoneExternalUrlModal.ICON',
    defaultMessage: 'Show clickable icon',
  },
  EXTERNAL: {
    id: 'EditPhoneExternalUrlModal.EXTERNAL',
    defaultMessage: 'Only external numbers',
  },
  EXTENSION: {
    id: 'EditPhoneExternalUrlModal.EXTENSION',
    defaultMessage: 'Only extensions',
  },
  ALL: {
    id: 'EditPhoneExternalUrlModal.ALL',
    defaultMessage: 'Every number',
  },
  CALLER: {
    id: 'EditPhoneExternalUrlModal.CALLER',
    defaultMessage: 'Caller',
  },
  CALLERNAME: {
    id: 'EditPhoneExternalUrlModal.CALLERNAME',
    defaultMessage: 'Caller name',
  },
  EXT: {
    id: 'EditPhoneExternalUrlModal.EXT',
    defaultMessage: 'Extension',
  },
  CALLID: {
    id: 'EditPhoneExternalUrlModal.CALLID',
    defaultMessage: 'Call id',
  },
  DURATION: {
    id: 'EditPhoneExternalUrlModal.DURATION',
    defaultMessage: 'Duration',
  },
  DATE: {
    id: 'EditPhoneExternalUrlModal.DATE',
    defaultMessage: 'Call starting date',
  },
  CLIENTNUMBER: {
    id: 'EditPhoneExternalUrlModal.CLIENTNUMBER',
    defaultMessage: 'Client number',
  },
  placeholder: {
    id: 'EditPhoneExternalUrlModal.placeholder',
    defaultMessage: 'Ex. https://www.test.it?num=[CALLER]',
  },
  save: {
    id: 'EditPhoneExternalUrlModal.save',
    defaultMessage: 'Save',
  },
  clear: {
    id: 'EditPhoneExternalUrlModal.clear',
    defaultMessage: 'Clear fields',
  },
  cancel: {
    id: 'EditPhoneExternalUrlModal.cancel',
    defaultMessage: 'Cancel',
  },
  fieldRequired: {
    id: 'EditPhoneExternalUrlModal.fieldRequired',
    defaultMessage: 'This field is required.',
  },
  existingEventError: {
    id: 'EditPhoneExternalUrlModal.existingEventError',
    defaultMessage: 'You cannot insert more than one url for icons.',
  },
  urlError: {
    id: 'EditPhoneExternalUrlModal.urlError',
    defaultMessage: 'Sorry, there has been a server problem.',
  },
  modalDateTitle: {
    id: 'EditPhoneExternalUrlModal.modalDateTitle',
    defaultMessage: 'Select date format',
  },
  dateFormat: {
    id: 'EditPhoneExternalUrlModal.dateFormat',
    defaultMessage: 'Date format',
  },
  chooseDateFormat: {
    id: 'EditPhoneExternalUrlModal.chooseDateFormat',
    defaultMessage: 'Choose',
  },
});

class EditPhoneExternalUrlModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: undefined,
      action: undefined,
      constraint: undefined,
      openDateModal: false,
      dateFormat: undefined,
      link: '',
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.editingElement && this.props.editingElement) {
      this.setState({
        ...this.state,
        link: this.props.editingPhoneExternalUrl.link,
        event: {
          value: this.props.editingPhoneExternalUrl.event,
          label: this.props.intl.formatMessage(
            messages[this.props.editingPhoneExternalUrl.event]
          ),
        },
        action: {
          value: this.props.editingPhoneExternalUrl.action,
          label: this.props.intl.formatMessage(
            messages[this.props.editingPhoneExternalUrl.action]
          ),
        },
        constraint: {
          value: this.props.editingPhoneExternalUrl.constraint,
          label: this.props.intl.formatMessage(
            messages[this.props.editingPhoneExternalUrl.constraint]
          ),
        },
      });
    }
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState(
        {
          link: '',
          event: {
            value: null,
            label: '',
          },
          action: {
            value: null,
            label: '',
          },
          constraint: {
            value: null,
            label: '',
          },
          errors: {},
        },
        () => this.props.clearErrors()
      );
    }
    if (
      !this.props.insertPhoneExternalUrlLoading &&
      prevProps.insertPhoneExternalUrlLoading &&
      !this.props.insertPhoneExternalUrlError
    ) {
      this.props.toggle();
    }
  }

  handleEventSelection = (selected) => {
    this.setState({
      ...this.state,
      event: selected,
      errors: {},
    });
  };

  handleActionSelection = (selected) => {
    this.setState({
      ...this.state,
      action: selected,
      errors: {},
    });
  };

  handleConstraintSelection = (selected) => {
    this.setState({
      ...this.state,
      constraint: selected,
      errors: {},
    });
  };

  handleLink = (event) => {
    this.setState({
      ...this.state,
      link: event.target.value,
      errors: {},
    });
  };

  handleDateFormat = (event) => {
    this.setState({
      ...this.state,
      dateFormat: event.target.value,
      errors: {},
    });
  };

  clear = () => {
    this.setState({
      event: undefined,
      action: undefined,
      constraint: undefined,
      link: '',
      errors: {},
    });
  };

  save = () => {
    const { link, action, event, constraint } = this.state;
    if (
      !link ||
      !action ||
      !action.value ||
      !event ||
      !event.value ||
      !constraint ||
      !constraint.value
    ) {
      this.setState({
        ...this.state,
        errors: {
          link: link
            ? null
            : this.props.intl.formatMessage(messages.fieldRequired),
          action:
            action && action.value
              ? null
              : this.props.intl.formatMessage(messages.fieldRequired),
          event:
            event && event.value
              ? null
              : this.props.intl.formatMessage(messages.fieldRequired),
          constraint:
            constraint && constraint.value
              ? null
              : this.props.intl.formatMessage(messages.fieldRequired),
        },
      });
    } else {
      const { insertPhoneExternalUrl, editPhoneExternalUrl, editingElement } =
        this.props;
      if (editingElement) {
        editPhoneExternalUrl({
          id: editingElement,
          data: {
            link,
            action: action.value,
            event: event.value,
            constraint: constraint.value,
            user: this.props.user,
          },
        });
      } else {
        insertPhoneExternalUrl({
          link,
          action: action.value,
          event: event.value,
          constraint: constraint.value,
          user: this.props.user,
        });
      }
    }
  };

  openDateParameter = () => {
    this.setState({
      openDateModal: true,
    });
  };

  addParameter = (param) => {
    this.setState({
      link: this.state.link + '[' + param + ']',
    });
  };

  addDateParameter = () => {
    if (!this.state.dateFormat) {
      this.setState({
        errors: {
          dateFormat: this.props.intl.formatMessage(
            messages.fieldRequired
          ),
        },
      });
      return;
    }
    this.addParameter(`DATE,${this.state.dateFormat}`);
    this.toggleDateModal();
  };

  toggleDateModal = () => {
    this.setState({
      openDateModal: !this.state.openDateModal,
    });
  };

  render() {
    const {
      isOpen,
      editingElement,
      toggle,
      insertPhoneExternalUrlLoading,
      insertPhoneExternalUrlError,
      intl: { formatMessage },
    } = this.props;
    const { errors } = this.state;

    const eventOptions = [
      {
        value: null,
        label: '',
      },
    ];

    Object.keys(ExternalUrlUtils.Event).forEach((item) => {
      eventOptions.push({
        value: item,
        label: formatMessage(messages[item]),
      });
    });

    const actionOptions = [
      {
        value: null,
        label: '',
      },
    ];

    if (
      this.state.event &&
      this.state.event.value === ExternalUrlUtils.Event.ALWAYS
    ) {
      actionOptions.push({
        value: ExternalUrlUtils.Action.ICON,
        label: formatMessage(messages[ExternalUrlUtils.Action.ICON]),
      });
    } else {
      Object.keys(ExternalUrlUtils.Action)
        .filter((item) => item !== ExternalUrlUtils.Action.ICON)
        .forEach((item) => {
          actionOptions.push({
            value: item,
            label: formatMessage(messages[item]),
          });
        });
    }

    const constraintOptions = [
      {
        value: null,
        label: '',
      },
    ];

    Object.keys(ExternalUrlUtils.Constraint).forEach((item) => {
      constraintOptions.push({
        value: item,
        label: formatMessage(messages[item]),
      });
    });

    return (
      <>
        <Modal isOpen={isOpen}>
          <ModalHeader toggle={toggle}>
            {editingElement
              ? formatMessage(messages.editUrlTitle)
              : formatMessage(messages.newUrlTitle)}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-xl-12">
                {insertPhoneExternalUrlError && (
                  <ToastMessage
                    type="danger"
                    text={
                      insertPhoneExternalUrlError === 'EXISTING_EVENT'
                        ? formatMessage(messages.existingEventError)
                        : formatMessage(messages.urlError)
                    }
                    closeTimeout={10000}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 p-3">
                <Label for="type" mandatory>
                  {formatMessage(messages.event)}
                </Label>
                <Select2
                  id="event"
                  name="event"
                  options={eventOptions}
                  isClearable
                  onChange={this.handleEventSelection}
                  value={this.state.event}
                  error={errors.event}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 p-3">
                <Label for="result" mandatory>
                  {formatMessage(messages.action)}
                </Label>
                <Select2
                  id="action"
                  name="action"
                  options={actionOptions}
                  isClearable
                  onChange={this.handleActionSelection}
                  value={this.state.action}
                  error={errors.action}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 p-3">
                <Label for="result" mandatory>
                  {formatMessage(messages.constraint)}
                </Label>
                <Select2
                  id="constraint"
                  name="constraint"
                  options={constraintOptions}
                  isClearable
                  onChange={this.handleConstraintSelection}
                  value={this.state.constraint}
                  error={errors.constraint}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 p-3">
                <Label for="name" mandatory>
                  {formatMessage(messages.link)}
                </Label>
                <br />
                <span>{formatMessage(messages.linkOptions)}</span>
                <br />
                {Object.keys(ExternalUrlUtils.Parameters).map(
                  (param, index) => {
                    return (
                      <Button
                        key={index}
                        style={{
                          padding: '4px',
                          borderRadius: '1rem',
                          marginLeft: '10px',
                          marginTop: '10px',
                        }}
                        color="primary"
                        onClick={() => {
                          param === ExternalUrlUtils.Parameters.DATE
                            ? this.openDateParameter()
                            : this.addParameter(param);
                        }}
                        text={formatMessage(messages[param])}
                      />
                    );
                  }
                )}
                <Input
                  className={'mt-3'}
                  id="link"
                  name="link"
                  type="text"
                  onChange={this.handleLink}
                  value={this.state.link}
                  error={errors.link}
                  placeholder={formatMessage(messages.placeholder)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.save}
              loading={insertPhoneExternalUrlLoading}
              text={formatMessage(messages.save)}
            />
            <button
              type="button"
              className="btn btn-outline-primary ml-2"
              onClick={this.clear}
            >
              {formatMessage(messages.clear)}
            </button>
            <button
              type="button"
              className="btn btn-outline-primary ml-2"
              onClick={toggle}
            >
              {formatMessage(messages.cancel)}
            </button>
          </ModalFooter>
        </Modal>
        {this.state.openDateModal && (
          <Modal
            isOpen={this.state.openDateModal}
          >
            <ModalHeader toggle={() => this.toggleDateModal()}>
              {formatMessage(messages.modalDateTitle)}
            </ModalHeader>
            <ModalBody>
              <DateFormatModal />
              <Label for="dateFormat" className="mt-3" mandatory>
                {formatMessage(messages.dateFormat)}
              </Label>
              <Input
                className={'mt-3'}
                id="dateFormat"
                name="dateFormat"
                type="text"
                onChange={this.handleDateFormat}
                value={this.state.dateFormat}
                error={errors.dateFormat}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() =>
                  this.addDateParameter()
                }
                text={formatMessage(messages.chooseDateFormat)}
              />
              <button
                type="button"
                className="btn btn-outline-primary ml-2"
                onClick={() => this.toggleDateModal()}
              >
                {formatMessage(messages.cancel)}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    insertPhoneExternalUrlLoading: !getInsertPhoneExternalUrlLoaded(state),
    insertPhoneExternalUrlError: getInsertPhoneExternalUrlError(state),
    editingPhoneExternalUrl: getPhoneExternalUrlFromId(
      state,
      ownProps.editingElement
    ),
  };
};

EditPhoneExternalUrlModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  editingElement: PropTypes.number,
};

EditPhoneExternalUrlModal.defaultProps = {
  editingElement: null,
};

export default injectIntl(
  connect(mapStateToProps, {
    insertPhoneExternalUrl: insertPhoneExternalUrlRequest,
    editPhoneExternalUrl: editPhoneExternalUrlRequest,
    clearErrors: clearPhoneExternalUrlErrors,
  })(EditPhoneExternalUrlModal)
);
