import moment from 'moment';

export default class VocalAssistantDateUtils {
  static date = {
    firstDayOfYear: '01/01',
    christmasDay: '25/12',
  };

  static numberFormat = (value, fill, decimal) => {
    let format = `${value}' '`;
    decimal && (format = value.toFixed(decimal));
    while (format.length < fill) {
      format = `0${format}`;
    }
    return format;
  };

  static getEasterDate = (year) => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const n0 = h + l + 7 * m + 114;
    const n = Math.floor(n0 / 31) - 1;
    const p = (n0 % 31) + 1;
    const date = new Date(year, n, p);
    return date;
  };

  static isFestivity = (value) => {
    const festivities = [
      '01/01', // capodanno
      '06/01', // epifania
      '25/04', // festa della liberazione
      '01/05', // festa dei lavoratori
      '02/06', // festa della repubblica
      '15/08', // ferragosto
      '01/11', // ognissanti
      '08/12', // immacolata
      '25/12', // natale
      '26/12', // santo stefano
    ];
    // Add Easter day and Easter monday to festivity list
    const easter = this.getEasterDate(value.getFullYear());
    const easterMonday = new Date(easter.getTime());
    easterMonday.setDate(easterMonday.getDate() + 1);

    festivities.push(
      `${this.numberFormat(easter.getDate(), 2)}/${this.numberFormat(
        easter.getMonth() + 1,
        2
      )}`
    );
    festivities.push(
      `${this.numberFormat(easterMonday.getDate(), 2)}/${this.numberFormat(
        easterMonday.getMonth() + 1,
        2
      )}`
    );

    if (
      festivities.indexOf(
        `${this.numberFormat(value.getDate(), 2)}/${this.numberFormat(
          value.getMonth() + 1,
          2
        )}`
      ) >= 0
    )
      return true;
    return false;
  };

  static getLocalization = () => {
    const defaultLocalization = {
      language: 'en',
      time_format: 'HH:mm',
      date_format: 'DD/MM/YY',
    };
    return defaultLocalization;
  };

  static datetimeFormat = (format, value) => {
    switch (format) {
      case '+mm:ss':
        return `${VocalAssistantDateUtils.numberFormat(
          value.getMinutes() + value.getUTCHours() * 60,
          2
        )}:${VocalAssistantDateUtils.numberFormat(value.getSeconds(), 2)}`;
      case '+ss':
        return VocalAssistantDateUtils.numberFormat(
          value.getMinutes() +
            value.getUTCHours() * 60 * 60 +
            value.getSeconds() -
            1,
          2
        );

      case '+dd HH mm ss':
        return `${Math.floor(
          value.getTime() / (24 * 60 * 60 * 1000)
        )} ' days' ${
          value.getUTCHours() ? value.getUTCHours() : 24
        } ' h ' ${value.getMinutes()} ' min ' ${value.getSeconds()} ' sec '`;
      default:
        return moment(value)
          .locale(VocalAssistantDateUtils.getLocalization().language)
          .format(format);
    }
  };

  static stringToTime = (value) => {
    const time = value.split(':');
    const hours = parseInt(time[0]);
    const minutes = parseInt(time[1]);
    if (!isNaN(hours) && !isNaN(minutes))
      return new Date(0, 0, 0, hours, minutes, 0, 0);
    return null;
  };
}
