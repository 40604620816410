import React from 'react';
import { connect } from 'react-redux';
import {
  getMeMobileExtensionNumber,
  getMePhoneStatus,
  isPhoneAvailable,
  isWebrtcSpying,
} from '../../../js/phone/selectors';
import {
  getMeAllCalls,
  getMeConferenceId,
  getMeConferenceCallId,
  getMeActiveCallId,
} from '../../../js/me/selectors';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import PhonePanel from './PhonePanel';
import CallPanel from './CallPanel';
import ConferencePanel from './ConferencePanel';
import MemberConferencePanel from './MemberConferencePanel';
import VocalAssistant from './VocalAssistant';
import CallSpyPanel from './CallSpyPanel';
import ErrorBoundaryLeftCol from '../../../components/ErrorBoundaryLeftCol';
import {
  getIncomingVideocall,
  getOutgoingVideocall,
} from '../../../js/videocalls/selectors';
import VideocallPanel from './VideocallPanel';

const LeftColumn = (props) => {
  return (
    <div
      className="yn-left rounded text-center"
      style={{ position: 'relative' }}
    >
      <ErrorBoundaryLeftCol>
        {props.calls.length === 0 &&
          !props.conferenceId &&
          !props.conferenceCallId && <VocalAssistant />}
        {props.hasPhone && props.phone && (
          <>
            <PhonePanel
              callsQuantity={props.calls.length}
              location={props.location}
            />
            <div>
              {props.calls.length > 0 &&
                props.calls.map(
                  (call, idx) =>
                    call &&
                    (!call.number || call.number !== props.mobileNumber) && (
                      <CallPanel
                        key={idx}
                        call={call}
                        activeCalls={props.calls.length}
                      />
                    )
                )}
            </div>
            <div>{props.isSpying && <CallSpyPanel />}</div>
            <div>
              {props.conferenceId && (
                <ConferencePanel
                  conferenceId={props.conferenceId}
                  callId={props.conferenceCallId || props.activeCallId}
                />
              )}
            </div>
            <div>
              {!props.conferenceId && props.conferenceCallId && (
                <MemberConferencePanel callId={props.conferenceCallId} />
              )}
            </div>
          </>
        )}
        {props.incomingVideocall && (
          <VideocallPanel videocall={props.incomingVideocall} incoming />
        )}
        {props.outgoingVideocall && (
          <VideocallPanel videocall={props.outgoingVideocall} />
        )}
      </ErrorBoundaryLeftCol>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    hasPhone: isPhoneAvailable(state),
    phone: getMePhoneStatus(state) !== PhoneEnums.PhoneStatuses.nophone,
    calls: getMeAllCalls(state),
    conferenceId: getMeConferenceId(state),
    conferenceCallId: getMeConferenceCallId(state),
    activeCallId: getMeActiveCallId(state),
    isSpying: isWebrtcSpying(state),
    mobileNumber: getMeMobileExtensionNumber(state),
    incomingVideocall: getIncomingVideocall(state),
    outgoingVideocall: getOutgoingVideocall(state),
  };
}

export default connect(mapStateToProps)(LeftColumn);
