export const CREATE_USER_PROFILE_REQUEST = 'CREATE_USER_PROFILE_REQUEST';
export const CREATE_USER_PROFILE_SUCCESS = 'CREATE_USER_PROFILE_SUCCESS';
export const CREATE_USER_PROFILE_FAILURE = 'CREATE_USER_PROFILE_FAILURE';
export const EDIT_USER_PROFILE_REQUEST = 'EDIT_USER_PROFILE_REQUEST';
export const EDIT_USER_PROFILE_SUCCESS = 'EDIT_USER_PROFILE_SUCCESS';
export const EDIT_USER_PROFILE_FAILURE = 'EDIT_USER_PROFILE_FAILURE';
export const FETCH_USER_PROFILES_REQUEST = 'FETCH_USER_PROFILES_REQUEST';
export const FETCH_USER_PROFILES_SUCCESS = 'FETCH_USER_PROFILES_SUCCESS';
export const FETCH_USER_PROFILES_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const REMOVE_USER_PROFILE_REQUEST = 'REMOVE_USER_PROFILE_REQUEST';
export const REMOVE_USER_PROFILE_SUCCESS = 'REMOVE_USER_PROFILE_SUCCESS';
export const REMOVE_USER_PROFILE_FAILURE = 'REMOVE_USER_PROFILE_FAILURE';
export const FETCH_USER_PROFILE_GRANTS_REQUEST =
  'FETCH_USER_PROFILE_GRANTS_REQUEST';
export const FETCH_USER_PROFILE_GRANTS_SUCCESS =
  'FETCH_USER_PROFILE_GRANTS_SUCCESS';
export const FETCH_USER_PROFILE_GRANTS_FAILURE =
  'FETCH_USER_PROFILE_GRANTS_FAILURE';
