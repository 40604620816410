import React from 'react';
import Fax from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const FaxSettings = React.lazy(() => import('./FaxSettings'));

const FaxSettingsPage = (props) => (
  <Fax scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <FaxSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Fax>
);

export default FaxSettingsPage;
