import React from 'react';
import Document from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ContractualData = React.lazy(() => import('./ContractualData'));
const ContractualDataPage = (props) => (
  <Document>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <ContractualData {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Document>
);

export default ContractualDataPage;
