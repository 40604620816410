import { request } from '../request';
import ynConf from '../../conf';

export default {
  getYoutubeInfo: (youtubeCode) =>
    request({
      url: `https://www.googleapis.com/youtube/v3/videos?id=${youtubeCode}&key=${ynConf.youtubeKey}&part=snippet,contentDetails,statistics,status`,
      method: 'GET',
    }),
};
