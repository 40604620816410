import React, { Component } from 'react';
import sanitizeHtml from 'sanitize-html';
import ynConf from '../../../conf';
import { charsToCode } from '../../../js/chat/charsToCode';
import { codesToChars } from '../../../js/chat/codesToChars';

const sanitizeConf = {
  allowedTags: ['img', 'br'],
  allowedAttributes: { img: ['src', 'width', 'height'] },
};

/* const emojisShortcuts = {
  ':)': '🙂',
  ':-)': '🙂',
  ':(': '🙁',
  ':-(': '🙁',
  ';)': '😉',
  ';-)': '😉',
  ':p': '😛',
  ':P': '😛',
  ':D': '😁',
  ':o': '😮',
  ':O': '😮',
}; */

class ChatInputArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      pasted: false,
      htmlContent: null,
    };
    this.inputArea = React.createRef();
  }

  componentDidMount() {
    const node = this.inputArea.current;
    if (this.props.isActive) {
      node.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.emoji && prevProps.emoji !== this.props.emoji) {
      this.insertEmoji(this.props.emoji);
    }
    if (this.props.isReplying && !prevProps.isReplying) {
      const node = this.inputArea.current;
      node.focus();
    }
  }

  onChange = () => {
    let adjustedText = this.state.text.split('<br />').join('\n');
    /* Object.keys(emojisShortcuts).forEach(key => {
      adjustedText = adjustedText.split(key).join(emojisShortcuts[key]);
    }); */

    adjustedText.match(/<img\s[^>]*/gm) &&
      adjustedText.match(/<img\s[^>]*/gm).forEach((found) => {
        const matches = /emojis\/(.*).png/gm.exec(`${found}>`);
        if (matches && matches.length) {
          adjustedText = adjustedText.replace(
            `${found}>`,
            codesToChars[matches[1]]
          );
        }
      });
    this.props.onChange(adjustedText);
  };

  handleInput = (e) => {
    /* Object.keys(emojisShortcuts).forEach(key => {
      if (text.indexOf(key) > -1) {
        text.replace(key, emojisShortcuts[key]);
      }
    }); */
    const node = this.inputArea.current;
    let text;
    if (this.state.pasted) {
      text = e.target.innerText.replace(/\n/g, '<br />');
      node.innerHTML = text;
      node.focus();
      if (document.createRange) {
        const range = document.createRange();
        range.selectNodeContents(node);
        range.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else {
      text = sanitizeHtml(node.innerHTML, sanitizeConf);
    }
    this.setState(
      {
        text,
        htmlContent: this.getHtmlContent(),
        pasted: false,
      },
      () => {
        this.onChange();
      }
    );
  };

  handleClick = () => {
    this.setState({
      htmlContent: this.getHtmlContent(),
    });
  };

  handlePaste = () => {
    this.setState({ pasted: true });
  };

  getHtmlContent = () => {
    if (!document.createRange) return null;
    const node = this.inputArea.current;
    node.focus();
    return window.getSelection().getRangeAt(0);
  };

  replaceEmojiInInputArea = (emoji) => {
    if (document.createRange) {
      const node = this.inputArea.current;
      const newNode = document.createElement('img');
      newNode.src = `${ynConf.publicFolderPath}/emojis/${charsToCode[emoji]}.png`;
      newNode.width = 20;
      newNode.height = 20;

      const range = this.state.htmlContent
        ? this.state.htmlContent.cloneRange()
        : this.getHtmlContent().cloneRange();
      range.insertNode(newNode);
      node.focus();
      const sel = window.getSelection();
      sel.collapse(newNode);
      range.setStartAfter(newNode);
      range.setEndAfter(newNode);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  insertEmoji = (emoji) => {
    this.replaceEmojiInInputArea(emoji);
    const node = this.inputArea.current;
    this.setState(
      {
        text: sanitizeHtml(node.innerHTML, sanitizeConf),
        htmlContent: this.getHtmlContent(),
      },
      () => {
        this.onChange();
      }
    );
    this.props.onNewEmoji();
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.props.onEnterClick();
      this.setState({
        text: '',
        htmlContent: null,
      });
      const node = this.inputArea.current;
      node.innerHTML = '';
      node.innerText = '';
      node.focus();
    }
  };

  render() {
    const { height } = this.props;

    return (
      <div
        ref={this.inputArea}
        contentEditable
        className="form-control"
        style={{
          height: `${height}px`,
          position: 'absolute',
          bottom: 0,
          width: '100%',
          overflowY: 'auto',
        }}
        onClick={this.handleClick}
        onInput={this.handleInput}
        onPaste={this.handlePaste}
        onKeyUp={this.onKeyUp}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

export default ChatInputArea;
