import axios from 'axios';
import ynConf from '../conf';

export const request = (options, config) => {
  const clientOptions = {
    baseURL: (config && config.baseUrl) || ynConf.apiBaseUrl,
    headers: (config && config.headers) || {},
    withCredentials: (config && config.withCredentials) || false,
    onUploadProgress: (config && config.onUploadProgress) || null,
    cancelToken: (config && config.cancelToken) || null,
  };
  const client = axios.create(clientOptions);

  const onSuccess = (response) => response;
  const onError = (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(new Error('499'));
    }
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
