import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import MainNav from './MainNav';
import SelfDropdown from './SelfDropdown';

import history from '../../../history';
import {
  getWebNotificationsCallsNumber,
  getWebNotificationsCalendarNumber,
  getWebNotificationsContactsNumber,
  getWebNotificationsFaxNumber,
  getWebNotificationsAdminNumber,
  getWebNotificationsMailofficeNumber,
  getWebNotificationsMeVboxNumber,
  getWebNotificationsStickyNumber,
  getDesktopNotificationsLostCalls,
  getDesktopNotificationsIncomingCalls,
  getWebNotificationsPbxVboxNumber,
  getWebNotificationsValidationNumber,
  getWebNotificationsTicketNumber,
  getWebNotificationsFileboxNumber,
  getTotalWebNotificationsNumber,
  getLostVideocallNotifications,
} from '../../../js/notifications/selectors';
import { DesktopNotificationManager } from '../../../js/notifications/DesktopNotificationManager';
import { NotificationEnums } from '../../../js/notifications/NotificationUtils';
import { removeDesktopNotification } from '../../../js/notifications/actions';
import { getTotalUnreadMessages } from '../../../js/chat/selectors';
import { AVATAR_TYPES } from '../../../js/files/FileUtils';
import Utils from '../../../js/lib/utils';
import { isIncomingWebrtcCall, isValidJsep } from '../../../js/phone/selectors';
import { getVideocallNotifications } from '../../../js/videocalls/selectors';

const messages = defineMessages({
  incomingCallNotification: {
    id: 'CallToolbar.incomingCallNotification',
    defaultMessage: 'Call incoming: ',
  },
  lostCallNotification: {
    id: 'CallToolbar.lostCallNotification',
    defaultMessage: 'Missed call: ',
  },
});

class Notifications extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.desktopNotifications.incomingCalls.length > 0) {
      this.props.desktopNotifications.incomingCalls.forEach((incomingCall) => {
        if (!incomingCall.callingData) {
          return;
        }
        // in that case we have an incoming call from janus but without JSEP so we must not to show it
        /*if(this.props.incomingWebrtcCall && !this.props.validJsep){
          return
        }*/
        // if there is already an incoming call notification don't show it (to prevent double notifications for ring groups)
        if (
          DesktopNotificationManager.existNotificationGroup(
            NotificationEnums.NotificationGroups.INCOMING_CALLS
          )
        ) {
          return;
        }
        DesktopNotificationManager.sendNotification({
          id: `${NotificationEnums.NotificationGroups.INCOMING_CALLS}-${incomingCall.callid}`,
          body: `${this.props.intl.formatMessage(
            messages.incomingCallNotification
          )}${
            incomingCall.callingData && incomingCall.callingData.fullname
              ? incomingCall.callingData.departmentFullname ||
                incomingCall.callingData.fullname
              : incomingCall.calling
          }`,
          group: NotificationEnums.NotificationGroups.INCOMING_CALLS,
          image:
            incomingCall.callingData && incomingCall.callingData.avatar
              ? Utils.getAvatarUrl(
                  AVATAR_TYPES.USER,
                  incomingCall.callingData.avatar,
                  'sm'
                )
              : NotificationEnums.NotificationImages.CALL_INCOMING,
          mute: true,
          onclick: () => {
            this.props.removeDesktopNotification({
              context: NotificationEnums.NotificationGroups.INCOMING_CALLS,
              id: incomingCall.callid,
            });
          },
          onclose: () => {
            this.props.removeDesktopNotification({
              context: NotificationEnums.NotificationGroups.INCOMING_CALLS,
              id: incomingCall.callid,
            });
          },
        });
      });
    } else {
      DesktopNotificationManager.hideNotificationsForGroup(
        NotificationEnums.NotificationGroups.INCOMING_CALLS
      );
    }
    this.props.desktopNotifications.lostCalls.forEach((lostCall) => {
      if (!lostCall.callingData) {
        return;
      }
      DesktopNotificationManager.sendNotification({
        id: `${NotificationEnums.NotificationGroups.LOST_CALLS}-${lostCall.callid}`,
        body: `${this.props.intl.formatMessage(messages.lostCallNotification)}${
          lostCall.callingData && lostCall.callingData.fullname
            ? lostCall.callingData.departmentFullname ||
              lostCall.callingData.fullname
            : lostCall.calling
        }`,
        group: NotificationEnums.NotificationGroups.LOST_CALLS,
        image:
          lostCall.callingData && lostCall.callingData.avatar
            ? Utils.getAvatarUrl(
                AVATAR_TYPES.USER,
                lostCall.callingData.avatar,
                'sm'
              )
            : NotificationEnums.NotificationImages.CALL_MISSED,
        onclick: () => {
          history.push('/phone/log/lost');
          this.props.removeDesktopNotification({
            context: NotificationEnums.NotificationGroups.LOST_CALLS,
            id: lostCall.callid,
          });
        },
        onclose: () => {
          this.props.removeDesktopNotification({
            context: NotificationEnums.NotificationGroups.LOST_CALLS,
            id: lostCall.callid,
          });
        },
      });
    });
    const { ipcRenderer } = window;
    if (this.props.totalNotifications !== prevProps.totalNotifications) {
      document.title = `${
        this.props.totalNotifications
          ? `(${this.props.totalNotifications}) `
          : ''
      }YouNeed`;
      // isAppDesktop
      if (ipcRenderer) {
        ipcRenderer.send('notification-total', this.props.totalNotifications);
      } else {
        //pwa
        if (!!this.props.totalNotifications && !!navigator.setAppBadge) {
          navigator.setAppBadge(parseInt(this.props.totalNotifications));
        } else if (
          !this.props.totalNotifications &&
          !!navigator.clearAppBadge
        ) {
          navigator.clearAppBadge();
        }
      }
    }
  }

  render() {
    return (
      <>
        <MainNav
          calendarWebNotifications={this.props.calendarWebNotifications}
          callsWebNotifications={this.props.callsWebNotifications}
          chatWebNotifications={this.props.chatWebNotifications}
          contactsWebNotifications={this.props.contactsWebNotifications}
          faxWebNotifications={this.props.faxWebNotifications}
          groupsWebNotifications={this.props.groupsWebNotifications}
          mailofficeWebNotifications={this.props.mailofficeWebNotifications}
          vboxMeWebNotifications={this.props.vboxMeWebNotifications}
          vboxPbxWebNotifications={this.props.vboxPbxWebNotifications}
          stickyWebNotifications={this.props.stickyWebNotifications}
          validationWebNotifications={this.props.validationWebNotifications}
          ticketWebNotifications={this.props.ticketWebNotifications}
          fileboxWebNotifications={this.props.fileboxWebNotifications}
          videocallWebNotifications={
            this.props.videocallWebNotifications.length +
            this.props.lostVideocallWebNotifications.length
          }
        />
        <SelfDropdown />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    calendarWebNotifications: getWebNotificationsCalendarNumber(state),
    callsWebNotifications: getWebNotificationsCallsNumber(state),
    chatWebNotifications: getTotalUnreadMessages(state),
    contactsWebNotifications: getWebNotificationsContactsNumber(state),
    faxWebNotifications: getWebNotificationsFaxNumber(state),
    groupsWebNotifications: getWebNotificationsAdminNumber(state),
    mailofficeWebNotifications: getWebNotificationsMailofficeNumber(state),
    vboxMeWebNotifications: getWebNotificationsMeVboxNumber(state),
    vboxPbxWebNotifications: getWebNotificationsPbxVboxNumber(state),
    stickyWebNotifications: getWebNotificationsStickyNumber(state),
    validationWebNotifications: getWebNotificationsValidationNumber(state),
    ticketWebNotifications: getWebNotificationsTicketNumber(state),
    fileboxWebNotifications: getWebNotificationsFileboxNumber(state),
    videocallWebNotifications: getVideocallNotifications(state),
    lostVideocallWebNotifications: getLostVideocallNotifications(state),
    /* webNotifications: {
      calendar: getWebNotificationsCalendarNumber(state),
      calls: getWebNotificationsCallsNumber(state),
      chat: getTotalUnreadMessages(state),
      contacts: getWebNotificationsContactsNumber(state),
      fax: getWebNotificationsFaxNumber(state),
      groups: getWebNotificationsAdminNumber(state),
      mailoffice: getWebNotificationsMailofficeNumber(state),
      videocalls: getWebNotificationsVideocallsNumber(state),
      vbox: getWebNotificationsVboxNumber(state),
      sticky: getWebNotificationsStickyNumber(state),
    }, */
    desktopNotifications: {
      lostCalls: getDesktopNotificationsLostCalls(state),
      incomingCalls: getDesktopNotificationsIncomingCalls(state),
    },
    incomingWebrtcCall: isIncomingWebrtcCall(state),
    validJsep: isValidJsep(state),
    totalNotifications: getTotalWebNotificationsNumber(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    removeDesktopNotification,
  })(Notifications)
);
