import CallsPage from '../sections/Calls/CallsPage';
import ChatPage from '../sections/Chat/ChatPage';
import JitsiVideocallListPage from '../sections/Videocall/JitsiVideocallListPage';
import MeCallsLogPage from '../sections/CallsLog/MeCallsLogPage';
import MePhoneRulesShowPage from '../sections/PhoneRules/Me/MePhoneRulesShowPage';
import MePhoneRulesEditPage from '../sections/PhoneRules/Me/MePhoneRulesEditPage';
import AbookShowPage from '../sections/Abook/AbookShowPage';
import CalendarSettingsPage from '../sections/Calendar/CalendarSettingsPage';
import AdminGroupsListPage from '../sections/Groups/AdminGroupsListPage';
import AdminUsersListPage from '../sections/Groups/AdminUsersListPage';
import AdminGrantsEditPage from '../sections/Grants/AdminGrantsEditPage';
import WikiShowPage from '../sections/Wiki/WikiShowPage';
import WikiDetailPage from '../sections/Wiki/WikiDetailPage';
import WikiEditPage from '../sections/Wiki/WikiEditPage';
import ProfileSettingsPage from '../sections/Settings/PersonalSettings/ProfileSettingsPage';
import PasswordSettingsPage from '../sections/Settings/PersonalSettings/PasswordSettingsPage';
import EmailSettingsPage from '../sections/Settings/PersonalSettings/EmailSettingsPage';
import PlatformSettingsPersonalPage from '../sections/Settings/PlatformSettingsPersonalPage';
import NotificationsSettingsPage from '../sections/Settings/NotificationsSettingsPage';
import AdminGroupsEditGroupPage from '../sections/Groups/AdminGroupsEditGroupPage';
import AdminUsersEditUserPage from '../sections/Groups/AdminUsersEditUserPage';
import CalendarEventEditPage from '../sections/Calendar/CalendarEventEditPage';
import CalendarVacationEditPage from '../sections/Calendar/CalendarVacationEditPage';
import AbookContactEditPage from '../sections/Abook/AbookContactEditPage';
import AbookContactInfoPage from '../sections/Abook/AbookContactInfoPage';
import ContractualDataPage from '../sections/Documents/ContractualDataPage';
import OfficesPage from '../sections/Documents/OfficesPage';
import InvoicesPage from '../sections/Documents/InvoicesPage';
import PhoneLinesPage from '../sections/Documents/PhoneLinesPage';
import RemainingCreditPage from '../sections/Payments/RemainingCreditPage';
import TransactionsPage from '../sections/Payments/TransactionsPage';
import RechargeCreditPage from '../sections/Payments/RechargeCreditPage';
import HotelPricesPage from '../sections/Hotel/HotelPricesPage';
import CalendarNotificationsPage from '../sections/Calendar/CalendarNotificationsPage';
import AdminNotificationsPage from '../sections/Groups/AdminNotificationsPage';
import AbookNotificationsPage from '../sections/Abook/AbookNotificationsPage';
import MailofficeArchivePage from '../sections/Mailoffice/MailofficeArchivePage';
import MailofficeSendPage from '../sections/Mailoffice/MailofficeSendPage';
import MailofficeNotificationsPage from '../sections/Mailoffice/MailofficeNotificationsPage';
import FaxReceivedListPage from '../sections/Fax/FaxReceivedListPage';
import FaxSentListPage from '../sections/Fax/FaxSentListPage';
import FaxSendPage from '../sections/Fax/FaxSendPage';
import FaxSettingsPage from '../sections/Fax/FaxSettingsPage';
import HotelCallsLogPage from '../sections/Hotel/HotelCallsLogPage';
import VboxReceivedMessagesPage from '../sections/Vbox/VboxReceivedMessagesPage';
import MeVboxNotificationsPage from '../sections/Vbox/MeVboxNotificationsPage';
import MePhoneGreetingMessagePage from '../sections/PhoneRules/Me/MePhoneGreetingMessagePage';
import FaxNotificationsPage from '../sections/Fax/FaxNotificationsPage';
import PbxCallsLogPage from '../sections/CallsLog/PbxCallsLogPage';
import WakeupSchedulingPage from '../sections/Hotel/WakeupSchedulingPage';
import WakeupArchivePage from '../sections/Hotel/WakeupArchivePage';
import WakeupSettingsPage from '../sections/Hotel/WakeupSettingsPage';
import WakeupEditPage from '../sections/Hotel/WakeupEditPage';
import FaxReceiptPage from '../sections/Fax/FaxReceiptPage';
import NewPbxMessagePage from '../sections/PhoneRules/Pbx/NewPbxMessagePage';
import ArchivedMessagesPage from '../sections/PhoneRules/Pbx/ArchivedMessagesPage';
import LogoPage from '../sections/Logo/LogoPage';
import CalendarShowPage from '../sections/Calendar/CalendarShowPage';
import PbxReceivedMessagesPage from '../sections/Vbox/PbxReceivedMessagesPage';
import PbxPhoneRulesShowPage from '../sections/PhoneRules/Pbx/PbxPhoneRulesShowPage';
import PbxPhoneRulesEditPage from '../sections/PhoneRules/Pbx/PbxPhoneRulesEditPage';
import ActiveMessagesPage from '../sections/PhoneRules/Pbx/ActiveMessagesPage';
import AdminSettingsEditPage from '../sections/Grants/AdminSettingsEditPage';
import PbxVboxNotificationsPage from '../sections/Vbox/PbxVboxNotificationsPage';
import PersonalPuncherPage from '../sections/Puncher/PersonalPuncherPage';
import AdminUsersEditUserHRPage from '../sections/Groups/AdminUsersEditUserHRPage';
import HRSettingsPage from '../sections/Settings/PersonalSettings/HRSettingsPage';
import AdminValidationsVacationsPage from '../sections/Validations/AdminValidationsVacationsPage';
import AdminValidationsPunchingsPage from '../sections/Validations/AdminValidationsPunchingsPage';
import AdminValidationsNotificationsPage from '../sections/Validations/AdminValidationsNotificationsPage';
import UsersPunchingsPage from '../sections/Presences/UsersPunchingsPage';
import WorkingUsersPage from '../sections/Presences/WorkingUsersPage';
import RegistryManagementPage from '../sections/Presences/RegistryManagementPage';
import TemplateExportShowPage from '../sections/Presences/TemplateExportShowPage';
import TemplateExportEditPage from '../sections/Presences/TemplateExportEditPage';
import PersonalPuncherNotificationsPage from '../sections/Puncher/PersonalPuncherNotificationsPage';
import ScheduledMessagesPage from '../sections/Chat/ScheduledMessagesPage';
import AbsenceManagementPage from '../sections/Presences/AbsenceManagementPage';
import AdminUsersEditUserValidationsPage from '../sections/Groups/AdminUsersEditUserValidationsPage';
import ValidationsSettingsPage from '../sections/Settings/PersonalSettings/ValidationsSettingsPage';
import ArchivedMessagesNotificationsPage from '../sections/PhoneRules/Pbx/ArchivedMessagesNotificationsPage';
import TicketsListPage from '../sections/Tickets/TicketsListPage';
import TicketsNotificationsPage from '../sections/Tickets/TicketsNotificationsPage';
import TicketsFieldsPage from '../sections/Tickets/TicketsFieldsPage';
import TicketEditPage from '../sections/Tickets/TicketEditPage';
import TicketsCustomizedStatusPage from '../sections/Tickets/TicketsCustomizedStatusPage';
import TicketCategoriesPage from '../sections/Tickets/TicketCategoriesPage';
import TicketsSettingsPage from '../sections/Tickets/TicketsSettingsPage';
import TicketsDashboardPage from '../sections/Tickets/TicketsDashboardPage';
import FileboxListPage from '../sections/Filebox/FileboxListPage';
import FileboxNotificationsPage from '../sections/Filebox/FileboxNotificationsPage';
import FileboxSettingsPage from '../sections/Filebox/FileboxSettingsPage';
import FileboxFavoritesPage from '../sections/Filebox/FileboxFavoritesPage';
import FileboxDeletedPage from '../sections/Filebox/FileboxDeletedPage';
import StaticNotesPage from '../sections/StickyNote/StaticNotesPage';
import AdminUsersProfilesPage from '../sections/Grants/AdminUsersProfilesPage';
import AdminEditUsersProfilePage from '../sections/Grants/AdminEditUsersProfilePage';
import PbxPhoneRoutesPage from '../sections/PhoneRules/Pbx/PbxPhoneRoutesPage';
import WorkingHoursPage from '../sections/Presences/WorkingHoursPage';
import WorkingHoursEditPage from '../sections/Presences/WorkingHoursEditPage';
import QRCodeSettingsPage from '../sections/Presences/QRCodeSettingsPage';
import GuestVisitsListPage from '../sections/Presences/GuestVisitsListPage';
import CalendarFestivitiesSettingsPage from '../sections/Calendar/CalendarFestivitiesSettingsPage';
import SMSListPage from '../sections/SMS/SMSListPage';
import SMSEditPage from '../sections/SMS/SMSEditPage';
import SMSScheduledPage from '../sections/SMS/SMSScheduledPage';
import SMSScheduledEditPage from '../sections/SMS/SMSScheduledEditPage';
import SMSAliasPage from '../sections/SMS/SMSAliasPage';
import SMSAliasEditPage from '../sections/SMS/SMSAliasEditPage';
import SMSCampaignsPage from '../sections/SMS/SMSCampaignsPage';
import SMSCampaignEditPage from '../sections/SMS/SMSCampaignEditPage';
import AbookListsPage from '../sections/Abook/AbookListsPage';
import AbookListsEditPage from '../sections/Abook/AbookListsEditPage';
import SMSPackagesPage from '../sections/SMS/SMSPackagesPage';
import PlatformSettingsCustomerPage from '../sections/Settings/PlatformSettingsCustomerPage';
import AbookManageImportPage from '../sections/Abook/AbookManageImportPage';
import AbookManageExportPage from '../sections/Abook/AbookManageExportPage ';
import AbookManageRemovePage from '../sections/Abook/AbookManageRemovePage';
import CalendarManageImportPage from '../sections/Calendar/CalendarManageImportPage';
import CalendarManageExportPage from '../sections/Calendar/CalendarManageExportPage';
import CalendarManageSynchPage from '../sections/Calendar/CalendarManageSynchPage';
import CalendarManageSharePage from '../sections/Calendar/CalendarManageSharePage';
import PbxPhoneQueuesPage from '../sections/PhoneRules/Pbx/PbxPhoneQueuesPage';
import JitsiScheduledVideocallsPage from '../sections/Videocall/JitsiScheduledVideocallsPage';
import JitsiVideocallSettingsPage from '../sections/Videocall/JitsiVideocallSettingsPage';
import JitsiVideocallNotificationsPage from '../sections/Videocall/JitsiVideocallNotificationsPage';
import PunchingJustificationsPage from '../sections/Presences/PunchingJustificationsPage';
import AdminAssociationsListPage from '../sections/Groups/AdminAssociationsListPage';
import CalendarAppointmentResultsPage from '../sections/Calendar/CalendarAppointmentResultsPage';
import CalendarAppointmentTypesPage from '../sections/Calendar/CalendarAppointmentTypesPage';
import CalendarAppointmentEditPage from '../sections/Calendar/CalendarAppointmentEditPage';
import CalendarAppointmentReportUsersPage from '../sections/Calendar/CalendarAppointmentReportUsersPage';
import CalendarAppointmentReportDashboardPage from '../sections/Calendar/CalendarAppointmentReportDashboardPage';
import PhoneExternalUrlPage from '../sections/ExternalUrl/PhoneExternalUrlPage';
import AdminUsersEditCallSettingsPage from '../sections/Groups/AdminUsersEditCallSettingsPage';
import CassaIntegrazioneManagementPage from '../sections/Presences/CassaIntegrazioneManagementPage';

const routes = [
  {
    path: '/calls',
    component: CallsPage,
  },
  {
    path: '/news',
    component: CallsPage,
  },
  {
    path: '/calls/exec',
    component: CallsPage,
  },
  {
    path: '/videocall/list',
    component: JitsiVideocallListPage,
  },
  {
    path: '/videocall/scheduled',
    component: JitsiScheduledVideocallsPage,
  },
  {
    path: '/videocall/settings',
    component: JitsiVideocallSettingsPage,
  },
  {
    path: '/videocall/notifications',
    component: JitsiVideocallNotificationsPage,
  },
  {
    path: '/chat/messages',
    component: ChatPage,
  },
  {
    path: '/chat/messages/:id',
    component: ChatPage,
  },
  {
    path: '/chat/scheduled',
    component: ScheduledMessagesPage,
  },
  {
    path: '/calendar/show',
    component: CalendarShowPage,
  },
  {
    path: '/calendar/show/event/edit/:id',
    component: CalendarEventEditPage,
  },
  {
    path: '/calendar/show/event/new',
    component: CalendarEventEditPage,
  },
  {
    path: '/calendar/show/vacation/edit/:id',
    component: CalendarVacationEditPage,
  },
  {
    path: '/calendar/show/vacation/new',
    component: CalendarVacationEditPage,
  },
  {
    path: '/calendar/show/appointment/edit/:id',
    component: CalendarAppointmentEditPage,
  },
  {
    path: '/calendar/show/appointment/new',
    component: CalendarAppointmentEditPage,
  },
  {
    path: '/calendar/settings',
    component: CalendarSettingsPage,
  },
  {
    path: '/calendar/manage/festivities-settings',
    component: CalendarFestivitiesSettingsPage,
  },
  {
    path: '/calendar/manage/appointment-result',
    component: CalendarAppointmentResultsPage,
    grants: ['CUSTOMIZE_APPOINTMENTS'],
  },
  {
    path: '/calendar/manage/appointment-type',
    component: CalendarAppointmentTypesPage,
    grants: ['CUSTOMIZE_APPOINTMENTS'],
  },
  {
    path: '/calendar/report/users',
    component: CalendarAppointmentReportUsersPage,
  },
  {
    path: '/calendar/report/dashboard',
    component: CalendarAppointmentReportDashboardPage,
  },
  {
    path: '/calendar/sharing/import',
    component: CalendarManageImportPage,
  },
  {
    path: '/calendar/sharing/export',
    component: CalendarManageExportPage,
  },
  {
    path: '/calendar/sharing/synch',
    component: CalendarManageSynchPage,
  },
  {
    path: '/calendar/sharing/share',
    component: CalendarManageSharePage,
  },
  {
    path: '/calendar/notifications',
    component: CalendarNotificationsPage,
  },
  {
    path: '/abook/contacts',
    component: AbookShowPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/contacts/edit',
    component: AbookContactEditPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/contacts/edit/:id',
    component: AbookContactEditPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/lists',
    component: AbookListsPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/lists/edit',
    component: AbookListsEditPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/lists/edit/:id',
    component: AbookListsEditPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/contacts/info/id/:id',
    component: AbookContactInfoPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/contacts/info/number/:number',
    component: AbookContactInfoPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/manage/import',
    component: AbookManageImportPage,
    grants: ['ADDRESSBOOK_IMPORT'],
  },
  {
    path: '/abook/manage/export',
    component: AbookManageExportPage,
    grants: ['ADDRESSBOOK_EXPORT'],
  },
  {
    path: '/abook/manage/remove',
    component: AbookManageRemovePage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/abook/Notifications',
    component: AbookNotificationsPage,
    //grants: ['ADDRESSBOOK_USAGE'],
  },
  {
    path: '/phone/log/lost',
    component: MeCallsLogPage,
  },
  {
    path: '/phone/log/in',
    component: MeCallsLogPage,
  },
  {
    path: '/phone/log/out',
    component: MeCallsLogPage,
  },
  {
    path: '/phone/settings',
    component: MePhoneRulesShowPage,
  },
  {
    path: '/phone/settings/edit/:id',
    component: MePhoneRulesEditPage,
  },
  {
    path: '/phone/settings/copy/:id',
    component: MePhoneRulesEditPage,
  },
  {
    path: '/phone/settings/new',
    component: MePhoneRulesEditPage,
  },
  {
    path: '/phone/greeting',
    component: MePhoneGreetingMessagePage,
  },
  {
    path: '/phone/external-url',
    component: PhoneExternalUrlPage,
  },
  {
    path: '/settings/personal',
    component: ProfileSettingsPage,
  },
  {
    path: '/settings/validations',
    component: ValidationsSettingsPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/settings/hr',
    component: HRSettingsPage,
    grants: ['MANAGE_PRESENCES'],
  },
  /*{
    path: '/settings/superviewers',
    component: SuperviewersSettingsPage,
    // TODO grants: ['MANAGE_PRESENCES'],
  },*/
  {
    path: '/settings/email',
    component: EmailSettingsPage,
  },
  {
    path: '/settings/password',
    component: PasswordSettingsPage,
  },
  {
    path: '/settings/platform/personal',
    component: PlatformSettingsPersonalPage,
  },
  {
    path: '/settings/platform/customer',
    component: PlatformSettingsCustomerPage,
  },
  {
    path: '/settings/notifications',
    component: NotificationsSettingsPage,
  },
  {
    path: '/fax/send',
    component: FaxSendPage,
    grants: ['FAX_PUBLIC_ENABLED', 'FAX_PRIVATE_ENABLED'],
  },
  {
    path: '/fax/received',
    component: FaxReceivedListPage,
    grants: ['FAX_PUBLIC_ENABLED', 'FAX_PRIVATE_ENABLED'],
  },
  {
    path: '/fax/sent',
    component: FaxSentListPage,
    grants: ['FAX_PUBLIC_ENABLED', 'FAX_PRIVATE_ENABLED'],
  },
  {
    path: '/fax/print/:id',
    component: FaxReceiptPage,
    grants: ['FAX_PUBLIC_ENABLED', 'FAX_PRIVATE_ENABLED'],
  },
  {
    path: '/fax/settings',
    component: FaxSettingsPage,
    grants: ['FAX_PUBLIC_ENABLED', 'FAX_PRIVATE_ENABLED'],
  },
  {
    path: '/fax/notifications',
    component: FaxNotificationsPage,
  },
  {
    path: '/wiki',
    component: WikiShowPage,
    grants: ['WIKI_ENABLED'],
  },
  {
    path: '/wiki/edit',
    component: WikiEditPage,
    grants: ['WIKI_ENABLED'],
  },
  {
    path: '/wiki/edit/:id',
    component: WikiEditPage,
    grants: ['WIKI_ENABLED'],
  },
  {
    path: '/wiki/:id',
    component: WikiDetailPage,
    grants: ['WIKI_ENABLED'],
  },
  {
    path: '/administration/groups',
    component: AdminGroupsListPage,
  },
  {
    path: '/administration/groups/edit/:id',
    component: AdminGroupsEditGroupPage,
  },
  {
    path: '/administration/groups/edit/',
    component: AdminGroupsEditGroupPage,
  },
  {
    path: '/administration/users',
    component: AdminUsersListPage,
    grants: ['USERS_GRANTS', 'MANAGE_PRESENCES'],
  },
  {
    path: '/administration/users/edit/:id',
    component: AdminUsersEditUserPage,
    grants: ['USERS_GRANTS'],
  },
  {
    path: '/administration/users/edit-validations/:id',
    component: AdminUsersEditUserValidationsPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/users/edit-hr/:id',
    component: AdminUsersEditUserHRPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/users/edit-call-settings/:id',
    component: AdminUsersEditCallSettingsPage,
    grants: ['USERS_GRANTS'],
  },
  /*{
    path: '/administration/users/edit-superviewers/:id',
    component: AdminUsersEditUserSuperviewersPage,
    // TODO grants: ['MANAGE_PRESENCES'],
  },*/
  {
    path: '/administration/associations',
    component: AdminAssociationsListPage,
    grants: ['MANAGE_ASSOCIATIONS'],
  },
  {
    path: '/administration/notifications',
    component: AdminNotificationsPage,
  },
  {
    path: '/administration/validations/vacations',
    component: AdminValidationsVacationsPage,
  },
  {
    path: '/administration/validations/punchings',
    component: AdminValidationsPunchingsPage,
  },
  {
    path: '/administration/validations/notifications',
    component: AdminValidationsNotificationsPage,
  },
  {
    path: '/administration/settings/grants',
    component: AdminGrantsEditPage,
    grants: ['USERS_PROFILES'],
  },
  {
    path: '/administration/settings/advanced',
    component: AdminSettingsEditPage,
    grants: ['USERS_PROFILES'],
  },
  {
    path: '/administration/settings/profiles/edit/:id',
    component: AdminEditUsersProfilePage,
    grants: ['USERS_PROFILES'],
  },
  {
    path: '/administration/settings/profiles/edit',
    component: AdminEditUsersProfilePage,
    grants: ['USERS_PROFILES'],
  },
  {
    path: '/administration/settings/profiles',
    component: AdminUsersProfilesPage,
    grants: ['USERS_PROFILES'],
  },
  {
    path: '/documents/contract-data',
    component: ContractualDataPage,
    grants: ['CUSTOMER_ENABLED'],
  },
  {
    path: '/documents/offices',
    component: OfficesPage,
    grants: ['CUSTOMER_ENABLED'],
  },
  {
    path: '/documents/invoices',
    component: InvoicesPage,
    grants: ['CUSTOMER_ENABLED'],
  },
  {
    path: '/documents/phone-lines',
    component: PhoneLinesPage,
    grants: ['CUSTOMER_ENABLED'],
  },
  {
    path: '/administration/credit/remaining',
    component: RemainingCreditPage,
    grants: ['PAYMENTS_VIEW'],
  },
  {
    path: '/administration/credit/transactions',
    component: TransactionsPage,
    grants: ['PAYMENTS_VIEW'],
  },
  {
    path: '/administration/credit/recharge',
    component: RechargeCreditPage,
    grants: ['PAYMENTS_PURCHASE'],
  },
  {
    path: '/hotel/wakeup/scheduling',
    component: WakeupSchedulingPage,
    grants: ['WAKEUP_ENABLED'],
  },
  {
    path: '/hotel/wakeup/scheduling/edit',
    component: WakeupEditPage,
    grants: ['WAKEUP_ENABLED'],
  },
  {
    path: '/hotel/wakeup/scheduling/edit/:id',
    component: WakeupEditPage,
    grants: ['WAKEUP_ENABLED'],
  },
  {
    path: '/hotel/wakeup/archive',
    component: WakeupArchivePage,
    grants: ['WAKEUP_ENABLED'],
  },
  {
    path: '/hotel/wakeup/settings',
    component: WakeupSettingsPage,
    grants: ['WAKEUP_ENABLED'],
  },
  {
    path: '/hotel/calls',
    component: HotelCallsLogPage,
    grants: ['CDR_ENABLED'],
  },
  {
    path: '/hotel/costs',
    component: HotelPricesPage,
    grants: ['CDR_ENABLED'],
  },
  {
    path: '/phone/vbox/list',
    component: VboxReceivedMessagesPage,
  },
  {
    path: '/phone/vbox/notifications',
    component: MeVboxNotificationsPage,
  },
  {
    path: '/mailoffice/sent',
    component: MailofficeArchivePage,
    grants: ['MAILOFFICE_VIEW_ALL', 'MAILOFFICE_WRITE_ALL', 'MAILOFFICE_SEND'],
  },
  {
    path: '/mailoffice/sent/edit/:id?',
    component: MailofficeSendPage,
    grants: ['MAILOFFICE_SEND'],
  },
  {
    path: '/mailoffice/notifications',
    component: MailofficeNotificationsPage,
    grants: ['MAILOFFICE_VIEW_ALL', 'MAILOFFICE_WRITE_ALL', 'MAILOFFICE_SEND'],
  },
  {
    path: '/pbx/calls/switchboard',
    component: PbxCallsLogPage,
    grants: ['PBX_VIEW_CDR'],
  },
  {
    path: '/pbx/calls/queues',
    component: PbxCallsLogPage,
    grants: ['PBX_VIEW_CDR'],
  },
  {
    path: '/pbx/settings/switchboard',
    component: PbxPhoneRulesShowPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/settings/switchboard/edit/:id',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/settings/switchboard/copy/:id',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/settings/switchboard/new',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/settings/users',
    component: PbxPhoneRulesShowPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/settings/users/edit/:id',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/settings/users/copy/:id',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/settings/users/new',
    component: PbxPhoneRulesEditPage,
    grants: ['PBX_HOLIDAYS_WRITE'],
  },
  {
    path: '/pbx/archived/list',
    component: ArchivedMessagesPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ', 'BACKEND_TVOX'],
  },
  {
    path: '/pbx/routes',
    component: PbxPhoneRoutesPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/queues',
    component: PbxPhoneQueuesPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/actives',
    component: ActiveMessagesPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/archived/list/new',
    component: NewPbxMessagePage,
    grants: ['PBX_HOLIDAYS_WRITE', 'BACKEND_TVOX'],
  },
  {
    path: '/pbx/archived/notifications',
    component: ArchivedMessagesNotificationsPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ', 'BACKEND_TVOX'],
  },
  {
    path: '/pbx/vbox/list',
    component: PbxReceivedMessagesPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/pbx/vbox/notifications',
    component: PbxVboxNotificationsPage,
    grants: ['PBX_HOLIDAYS_WRITE', 'PBX_HOLIDAYS_READ'],
  },
  {
    path: '/administration/logo',
    component: LogoPage,
    grants: ['PLATFORM_LOGO_MANAGEMENT'],
  },
  {
    path: '/puncher/punchings',
    component: PersonalPuncherPage,
  },
  {
    path: '/puncher/notifications',
    component: PersonalPuncherNotificationsPage,
  },
  {
    path: '/administration/presences/punchings',
    component: UsersPunchingsPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/working-users',
    component: WorkingUsersPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/working-hours',
    component: WorkingHoursPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/working-hours/edit',
    component: WorkingHoursEditPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/working-hours/edit/:id',
    component: WorkingHoursEditPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/registry-management',
    component: RegistryManagementPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/export-template',
    component: TemplateExportShowPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/export-template/edit',
    component: TemplateExportEditPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/export-template/edit/:id',
    component: TemplateExportEditPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/management/punching-justifications',
    component: PunchingJustificationsPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/absence-management',
    component: AbsenceManagementPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/cassaintegrazione-management',
    component: CassaIntegrazioneManagementPage,
    grants: ['MANAGE_PRESENCES'],
  },
  {
    path: '/administration/presences/visits-list',
    component: GuestVisitsListPage,
    grants: ['MANAGE_GUEST_PRESENCES'],
  },
  {
    path: '/administration/presences/management/qrcode-settings',
    component: QRCodeSettingsPage,
    grants: ['MANAGE_GUEST_PRESENCES'],
  },
  {
    path: '/tickets/tickets-list',
    component: TicketsListPage,
    grants: [
      'MANAGE_OWN_TICKETS',
      'MANAGE_ALL_TICKETS',
      'MANAGE_ASSOCIATION_TICKETS',
    ],
  },
  {
    path: '/tickets/manage/tickets-fields',
    component: TicketsFieldsPage,
    grants: ['MANAGE_ADMIN_TICKETS'],
  },
  {
    path: '/tickets/notifications',
    component: TicketsNotificationsPage,
    grants: [
      'MANAGE_OWN_TICKETS',
      'MANAGE_ALL_TICKETS',
      'MANAGE_ASSOCIATION_TICKETS',
    ],
  },
  {
    path: '/tickets/tickets-list/edit',
    component: TicketEditPage,
    grants: [
      'MANAGE_OWN_TICKETS',
      'MANAGE_ALL_TICKETS',
      'MANAGE_ASSOCIATION_TICKETS',
    ],
  },
  {
    path: '/tickets/tickets-list/edit/:id',
    component: TicketEditPage,
    grants: [
      'MANAGE_OWN_TICKETS',
      'MANAGE_ALL_TICKETS',
      'MANAGE_ASSOCIATION_TICKETS',
    ],
  },
  {
    path: '/tickets/manage/customized-status',
    component: TicketsCustomizedStatusPage,
    grants: ['MANAGE_ADMIN_TICKETS'],
  },
  {
    path: '/tickets/manage/ticket-categories',
    component: TicketCategoriesPage,
    grants: ['MANAGE_ADMIN_TICKETS'],
  },
  {
    path: '/tickets/manage/settings',
    component: TicketsSettingsPage,
    grants: ['MANAGE_ADMIN_TICKETS'],
  },
  {
    path: '/tickets/dashboard',
    component: TicketsDashboardPage,
    grants: ['MANAGE_ADMIN_TICKETS'],
  },
  {
    path: '/filebox/list',
    component: FileboxListPage,
    notExact: true,
  },
  {
    path: '/filebox/settings',
    component: FileboxSettingsPage,
  },
  {
    path: '/filebox/notifications',
    component: FileboxNotificationsPage,
  },
  {
    path: '/filebox/favourites',
    component: FileboxFavoritesPage,
  },
  {
    path: '/filebox/deleted',
    component: FileboxDeletedPage,
  },
  {
    path: '/static-notes',
    component: StaticNotesPage,
  },
  {
    path: '/sms/sent',
    component: SMSListPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/sent/edit',
    component: SMSEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/sent/copy/:id',
    component: SMSEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/scheduled',
    component: SMSScheduledPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/scheduled/edit',
    component: SMSScheduledEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/scheduled/edit/:id',
    component: SMSScheduledEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/scheduled/copy/:id',
    component: SMSScheduledEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/alias',
    component: SMSAliasPage,
    grants: ['SMS_MANAGE'],
  },
  {
    path: '/sms/alias/edit',
    component: SMSAliasEditPage,
    grants: ['SMS_MANAGE'],
  },
  {
    path: '/sms/campaigns',
    component: SMSCampaignsPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/campaigns/edit',
    component: SMSCampaignEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/campaigns/edit/:id',
    component: SMSCampaignEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/campaigns/copy/:id',
    component: SMSCampaignEditPage,
    grants: ['SMS_SEND'],
  },
  {
    path: '/sms/packages',
    component: SMSPackagesPage,
    grants: ['SMS_MANAGE'],
  },
];
export default routes;
