import {
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILURE,
  SET_STATUS_REQUEST,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAILURE,
  UPDATE_EMAIL_REQUEST,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAILURE,
  REMOVE_FAVORITE_REQUEST,
  REMOVE_FAVORITE_SUCCESS,
  REMOVE_FAVORITE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  INIT_MAIN_SUCCESS,
  NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  FETCH_HELP_REQUEST,
  FETCH_HELP_SUCCESS,
  FETCH_HELP_FAILURE,
  FETCH_CUSTOMER_SETTINGS_REQUEST,
  FETCH_CUSTOMER_SETTINGS_SUCCESS,
  FETCH_CUSTOMER_SETTINGS_FAILURE,
  FETCH_EXTENSIONS_ORDER_SUCCESS,
  UPDATE_EXTENSIONS_ORDER_REQUEST,
  UPDATE_EXTENSIONS_ORDER_SUCCESS,
  UPDATE_EXTENSIONS_ORDER_FAILURE,
  FETCH_EXTENSIONS_ORDER_FAILURE,
  FETCH_PBXS_REQUEST,
  FETCH_PBXS_SUCCESS,
  FETCH_PBXS_FAILURE,
  TOGGLE_SHOW_HELP,
  FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST,
  FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS,
  FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE,
  SET_USER_STATUS_REQUEST,
  SET_USER_STATUS_SUCCESS,
  SET_USER_STATUS_FAILURE,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS,
  UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE,
  CHECK_RELEASE,
} from './types';

export const initMainSuccess = () => ({
  type: INIT_MAIN_SUCCESS,
});
export const fetchMeRequest = () => ({ type: FETCH_ME_REQUEST });
export const fetchMeSuccess = (me) => ({ type: FETCH_ME_SUCCESS, me });
export const fetchMeFailure = (errors) => ({ type: FETCH_ME_FAILURE, errors });
export const setStatusRequest = (statusType) => ({
  type: SET_STATUS_REQUEST,
  statusType,
});
export const setStatusSuccess = (statusType) => ({
  type: SET_STATUS_SUCCESS,
  statusType,
});
export const setStatusFailure = (errors) => ({
  type: SET_STATUS_FAILURE,
  errors,
});
export const updateProfileRequest = (userData) => ({
  type: UPDATE_PROFILE_REQUEST,
  userData,
});
export const updateProfileSuccess = (success, status) => ({
  type: UPDATE_PROFILE_SUCCESS,
  success,
  status,
});
export const updateProfileFailure = (errors) => ({
  type: UPDATE_PROFILE_FAILURE,
  errors,
});
export const updateEmailRequest = (payload) => ({
  type: UPDATE_EMAIL_REQUEST,
  payload,
});
export const updateEmailSuccess = (payload, success) => ({
  type: UPDATE_EMAIL_SUCCESS,
  payload,
  success,
});
export const updateEmailFailure = (errors) => ({
  type: UPDATE_EMAIL_FAILURE,
  errors,
});
export const updatePasswordRequest = (password) => ({
  type: UPDATE_PASSWORD_REQUEST,
  password,
});
export const updatePasswordSuccess = (success) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  success,
});
export const updatePasswordFailure = (errors) => ({
  type: UPDATE_PASSWORD_FAILURE,
  errors,
});
export const addFavoriteRequest = (member) => ({
  type: ADD_FAVORITE_REQUEST,
  member,
});
export const addFavoriteSuccess = (member) => ({
  type: ADD_FAVORITE_SUCCESS,
  member,
});
export const addFavoriteFailure = (errors) => ({
  type: ADD_FAVORITE_FAILURE,
  errors,
});
export const removeFavoriteRequest = (member) => ({
  type: REMOVE_FAVORITE_REQUEST,
  member,
});
export const removeFavoriteSuccess = (member) => ({
  type: REMOVE_FAVORITE_SUCCESS,
  member,
});
export const removeFavoriteFailure = (errors) => ({
  type: REMOVE_FAVORITE_FAILURE,
  errors,
});
export const fetchHelpRequest = (path) => ({ type: FETCH_HELP_REQUEST, path });
export const fetchHelpSuccess = (html) => ({ type: FETCH_HELP_SUCCESS, html });
export const fetchHelpFailure = (errors) => ({
  type: FETCH_HELP_FAILURE,
  errors,
});
export const getNethesisMePhonesStatusChangesSuccess = (status) => ({
  type: NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  status,
});
export const getAbilisMePhonesStatusChangesSuccess = (status) => ({
  type: ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS,
  status,
});
export const fetchCustomerSettingsRequest = () => ({
  type: FETCH_CUSTOMER_SETTINGS_REQUEST,
});
export const fetchCustomerSettingsSuccess = (customerSettings) => ({
  type: FETCH_CUSTOMER_SETTINGS_SUCCESS,
  customerSettings,
});
export const fetchCustomerSettingsFailure = (errors) => ({
  type: FETCH_CUSTOMER_SETTINGS_FAILURE,
  errors,
});
export const fetchExtensionsOrderSuccess = (extensions) => ({
  type: FETCH_EXTENSIONS_ORDER_SUCCESS,
  extensions,
});
export const fetchExtensionsOrderFailure = (errors) => ({
  type: FETCH_EXTENSIONS_ORDER_FAILURE,
  errors,
});
export const updateExtensionsOrderRequest = (extensions) => ({
  type: UPDATE_EXTENSIONS_ORDER_REQUEST,
  extensions,
});
export const updateExtensionsOrderSuccess = () => ({
  type: UPDATE_EXTENSIONS_ORDER_SUCCESS,
});
export const updateExtensionsOrderFailure = (errors) => ({
  type: UPDATE_EXTENSIONS_ORDER_FAILURE,
  errors,
});
export const fetchPbxsRequest = () => ({
  type: FETCH_PBXS_REQUEST,
});
export const fetchPbxsSuccess = (pbxs) => ({
  type: FETCH_PBXS_SUCCESS,
  pbxs,
});
export const fetchPbxsFailure = (errors) => ({
  type: FETCH_PBXS_FAILURE,
  errors,
});
export const toggleShowHelp = () => ({
  type: TOGGLE_SHOW_HELP,
});
export const fetchCustomerRegistryFieldsRequest = () => ({
  type: FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST,
});
export const fetchCustomerRegistryFieldsSuccess = (data) => ({
  type: FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS,
  data,
});
export const fetchCustomerRegistryFieldsFailure = (errors) => ({
  type: FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE,
  errors,
});
export const setUserStatusRequest = (payload) => ({
  type: SET_USER_STATUS_REQUEST,
  payload,
});
export const setUserStatusSuccess = (data) => ({
  type: SET_USER_STATUS_SUCCESS,
  data,
});
export const setUserStatusFailure = (errors) => ({
  type: SET_USER_STATUS_FAILURE,
  errors,
});
export const updatePlatformSettingsCustomerRequest = (preferences) => ({
  type: UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST,
  preferences,
});
export const updatePlatformSettingsCustomerSuccess = (preferences) => ({
  type: UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS,
  preferences,
});
export const updatePlatformSettingsCustomerFailure = (errors) => ({
  type: UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE,
  errors,
});
export const checkReleaseRequest = () => ({
  type: CHECK_RELEASE,
});
