import React from 'react';
import PropTypes from 'prop-types';

export const balloonStyle = {
  width: '300px',
  height: '300px',
  maxFontSize: 30,
  menuStyle: {
    position: 'absolute',
    top: '44px',
    left: '29px',
    width: '82%',
    height: '0',
    borderBottom: '23px solid rgba(76, 175, 80, 0.1)',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    marginBottom: '0',
    padding: '4px 4px 0 10px',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '71px',
    left: '47px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '73%',
    height: '47%',
    padding: '6px',
    top: '74px',
    left: '42px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '224px',
    left: '124px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '222px',
    left: '158px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '49px',
    left: '215px',
  },
};

export const BalloonShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 200 200">
      <g transform="translate(0,-852.36218)">
        <path
          filter={`url(#sticky-filter-${id})`}
          style={{ fill: `${color}`, padding: '10px' }}
          d="m 100.74271,858.86087 c -51.674735,0 -93.5686207,37.0958 -93.5686207,82.86435 0,23.20055 10.7986397,44.1574 28.1558597,59.19998 0,26.9849 -24.253733,40.3142 -29.6412623,45.0023 24.8863953,6.6961 59.2314373,-9.5962 75.3901143,-23.2084 6.342441,1.2024 12.916721,1.8705 19.663909,1.8705 51.6708,0 93.5686,-37.09972 93.5686,-82.86438 0,-45.76855 -41.8978,-82.86435 -93.5686,-82.86435 z"
        />
      </g>
      <defs>
        <filter
          id={`sticky-filter-${id}`}
          x="-1px"
          y="-1px"
          width="210%"
          height="210%"
        >
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
    </svg>
  </>
);

BalloonShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
