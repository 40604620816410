import React, { Component } from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PhoneExternalUrlList from './PhoneExternalUrlList';
import MePhoneSettings from '../PhoneRules/Me/MePhoneSettings';
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
class PhoneExternalUrlPage extends Component {
  render() {
    return (
      <MePhoneSettings scrolling>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <>
              <div className="text-right">
                <HelpButton fileName="external-url" />
              </div>
              <PhoneExternalUrlList />
            </>
          </React.Suspense>
        </ErrorBoundary>
      </MePhoneSettings>
    );
  }
}

export default PhoneExternalUrlPage;
