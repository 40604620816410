import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../components/icons/Icon';

class ShapeSelector extends Component {
  selectShape = (shape) => {
    this.props.selectShape(shape);
  };

  render() {
    const { shapes } = this.props;

    return (
      <div style={{ padding: '0.2em' }}>
        {shapes.map((shape) => (
          <span
            className="rounded-circle d-inline-block"
            onClick={() => this.selectShape(shape)}
            key={shape}
            style={{
              backgroundColor: 'white',
              width: '18px',
              height: '18px',
              border: '1px solid var(--yn-gray-500)',
              textAlign: 'center',
              margin: '1px',
              position: 'relative',
            }}
          >
            <Icon
              name={shape}
              color="var(--gray-dark)"
              clickable
              width={13}
              height={13}
              style={{ position: 'absolute', top: '2px', left: '2px' }}
            />
          </span>
        ))}
      </div>
    );
  }
}

ShapeSelector.propTypes = {
  selectShape: PropTypes.func.isRequired,
  shapes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ShapeSelector;
