import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import ynConf from '../../conf';

const messages = defineMessages({
  500: {
    id: 'GlobalErrorPage.error.500',
    defaultMessage: 'Oh oh! There is a server error',
  },
  403: {
    id: 'GlobalErrorPage.error.403',
    defaultMessage: 'Sorry.... You are not authorized!',
  },
  404: {
    id: 'GlobalErrorPage.error.404',
    defaultMessage: 'Sorry.... nothing interesting here!',
  },
  522: {
    id: 'GlobalErrorPage.error.522',
    defaultMessage: 'Oh no! Your network seems to be unavailable',
  },
  defaultError: {
    id: 'GlobalErrorPage.label.defaultError',
    defaultMessage: 'Oh oh! There is an ugly error',
  },
});

const GlobalErrorPage = ({
  text,
  code,
  type,
  image,
  intl: { formatMessage },
}) => {
  const message =
    text ||
    (code
      ? formatMessage(messages[code])
      : formatMessage(messages.defaultError));

  return (
    <div className="p-4 rounded bg-white text-center h-100 w-100">
      <img
        src={image}
        className="mt-4"
        alt="Error"
        height="40%"
        style={{ maxHeight: '20rem' }}
      />
      <div className={`alert alert-${type} m-4`} role="alert">
        {message}
      </div>
    </div>
  );
};

GlobalErrorPage.propTypes = {
  text: PropTypes.string,
  code: PropTypes.oneOf([500, 403, 404]),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  image: PropTypes.string,
};

GlobalErrorPage.defaultProps = {
  text: null,
  // code: 'defaultError',
  type: 'danger',
  image: `${ynConf.publicFolderPath}/404.svg`,
};

export default injectIntl(GlobalErrorPage);
