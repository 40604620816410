import React from 'react';

const style = {
  span: { position: 'relative', paddingLeft: '1.2em' },
  color: {
    width: '12px',
    height: '12px',
    border: '1px solid var(--yn-gray-500)',
    position: 'absolute',
    top: '2px',
    left: '0px',
  },
};
const GroupLabel = ({ color, name }) => (
  <span className="mr-2" style={style.span}>
    <span
      className="d-inline-block rounded-circle"
      style={{ ...style.color, background: color }}
    />
    <span style={{}}>{name}</span>
  </span>
);

export default GroupLabel;
