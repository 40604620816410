import React, { Component } from 'react';

class AnimatedDots extends Component {
  state = {
    count: 0,
  };

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 400);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick = () => {
    this.setState((prevState) => ({
      count: prevState.count < 3 ? prevState.count + 1 : 0,
    }));
  };

  render() {
    const { count } = this.state;

    return (
      <span>
        {count === 0 && <span />}
        {count > 0 && <span>.</span>}
        {count > 1 && <span>.</span>}
        {count > 2 && <span>.</span>}
      </span>
    );
  }
}

export default AnimatedDots;
