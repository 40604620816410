import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_CALENDAR_SETTINGS_SUCCESS,
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  SET_CALENDAR_FILTER,
  FETCH_CALENDAR_EVENT_REQUEST,
  FETCH_CALENDAR_EVENT_SUCCESS,
  FETCH_CALENDAR_EVENT_FAILURE,
  SAVE_CALENDAR_EVENT_REQUEST,
  SAVE_CALENDAR_EVENT_SUCCESS,
  SAVE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT_FAILURE,
  SAVE_CALENDAR_SETTINGS_REQUEST,
  SAVE_CALENDAR_SETTINGS_SUCCESS,
  SAVE_CALENDAR_SETTINGS_FAILURE,
  IMPORT_CALENDAR_EVENTS_REQUEST,
  IMPORT_CALENDAR_EVENTS_SUCCESS,
  IMPORT_CALENDAR_EVENTS_FAILURE,
  CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST,
  CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS,
  CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE,
  CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST,
  CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS,
  CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE,
  CLOSE_IMPORT_CALENDAR_EVENTS_MODAL,
  FETCH_CALENDAR_SYNCOUT_REQUEST,
  FETCH_CALENDAR_SYNCOUT_SUCCESS,
  FETCH_CALENDAR_SYNCOUT_FAILURE,
  SAVE_CALENDAR_SYNCOUT_REQUEST,
  SAVE_CALENDAR_SYNCOUT_SUCCESS,
  SAVE_CALENDAR_SYNCOUT_FAILURE,
  DELETE_CALENDAR_SYNCOUT_REQUEST,
  DELETE_CALENDAR_SYNCOUT_SUCCESS,
  DELETE_CALENDAR_SYNCOUT_FAILURE,
  ENABLE_DISABLE_SYNCOUT_REQUEST,
  ENABLE_DISABLE_SYNCOUT_SUCCESS,
  ENABLE_DISABLE_SYNCOUT_FAILURE,
  FETCH_CALENDAR_SYNCIN_REQUEST,
  FETCH_CALENDAR_SYNCIN_SUCCESS,
  FETCH_CALENDAR_SYNCIN_FAILURE,
  SAVE_CALENDAR_SYNCIN_REQUEST,
  SAVE_CALENDAR_SYNCIN_SUCCESS,
  SAVE_CALENDAR_SYNCIN_FAILURE,
  DELETE_CALENDAR_SYNCIN_REQUEST,
  DELETE_CALENDAR_SYNCIN_SUCCESS,
  DELETE_CALENDAR_SYNCIN_FAILURE,
  FETCH_CALENDAR_SLOTS_REQUEST,
  FETCH_CALENDAR_SLOTS_SUCCESS,
  FETCH_CALENDAR_SLOTS_FAILURE,
  FETCH_CALENDAR_EVENT_CONTACTS_REQUEST,
  FETCH_CALENDAR_EVENT_CONTACTS_FAILURE,
  FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS,
  TOGGLE_CALENDAR_SYNCIN_REQUEST,
  TOGGLE_CALENDAR_SYNCIN_FAILURE,
  FETCH_VACATION_PLAN_REQUEST,
  FETCH_VACATION_PLAN_SUCCESS,
  FETCH_VACATION_PLAN_FAILURE,
  REJECT_VACATION_SUCCESS,
  APPROVE_VACATION_SUCCESS,
  APPROVE_VACATION_FAILURE,
  REJECT_VACATION_FAILURE,
  APPROVE_VACATION_REQUEST,
  REJECT_VACATION_REQUEST,
  APPROVE_DELETION_VACATION_REQUEST,
  APPROVE_DELETION_VACATION_SUCCESS,
  APPROVE_DELETION_VACATION_FAILURE,
  REJECT_DELETION_VACATION_REQUEST,
  REJECT_DELETION_VACATION_SUCCESS,
  REJECT_DELETION_VACATION_FAILURE,
  CALENDAR_UPDATE_NOTIFICATION,
  CALENDAR_INSERT_NOTIFICATION,
  CALENDAR_DELETE_NOTIFICATION,
  CALENDAR_DRAG_NOTIFICATION,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS,
  FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS,
  SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE,
  FETCH_VACATION_RESUME_REQUEST,
  FETCH_VACATION_RESUME_SUCCESS,
  FETCH_VACATION_RESUME_FAILURE,
  FETCH_TASK_PLAN_REQUEST,
  FETCH_TASK_PLAN_SUCCESS,
  FETCH_TASK_PLAN_FAILURE,
  FETCH_APPOINTMENT_RESULTS_REQUEST,
  FETCH_APPOINTMENT_RESULTS_SUCCESS,
  FETCH_APPOINTMENT_RESULTS_FAILURE,
  DELETE_APPOINTMENT_RESULT_REQUEST,
  DELETE_APPOINTMENT_RESULT_SUCCESS,
  DELETE_APPOINTMENT_RESULT_FAILURE,
  INSERT_APPOINTMENT_RESULT_REQUEST,
  INSERT_APPOINTMENT_RESULT_SUCCESS,
  INSERT_APPOINTMENT_RESULT_FAILURE,
  EDIT_APPOINTMENT_RESULT_REQUEST,
  EDIT_APPOINTMENT_RESULT_SUCCESS,
  EDIT_APPOINTMENT_RESULT_FAILURE,
  CLEAR_APPOINTMENT_RESULT_ERRORS,
  FETCH_APPOINTMENT_TYPES_REQUEST,
  FETCH_APPOINTMENT_TYPES_SUCCESS,
  FETCH_APPOINTMENT_TYPES_FAILURE,
  DELETE_APPOINTMENT_TYPE_REQUEST,
  DELETE_APPOINTMENT_TYPE_SUCCESS,
  DELETE_APPOINTMENT_TYPE_FAILURE,
  INSERT_APPOINTMENT_TYPE_REQUEST,
  INSERT_APPOINTMENT_TYPE_SUCCESS,
  INSERT_APPOINTMENT_TYPE_FAILURE,
  EDIT_APPOINTMENT_TYPE_REQUEST,
  EDIT_APPOINTMENT_TYPE_SUCCESS,
  EDIT_APPOINTMENT_TYPE_FAILURE,
  CLEAR_APPOINTMENT_TYPE_ERRORS,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS,
  FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS,
  FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE,
  SET_APPOINTMENTS_FILTER,
  FETCH_APPOINTMENTS_REQUEST,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENTS_FAILURE,
  SAVE_CALENDAR_APPOINTMENT_REQUEST,
  SAVE_CALENDAR_APPOINTMENT_SUCCESS,
  SAVE_CALENDAR_APPOINTMENT_FAILURE,
  FETCH_CALENDAR_APPOINTMENT_REQUEST,
  FETCH_CALENDAR_APPOINTMENT_SUCCESS,
  FETCH_CALENDAR_APPOINTMENT_FAILURE,
  DELETE_CALENDAR_APPOINTMENT_REQUEST,
  DELETE_CALENDAR_APPOINTMENT_SUCCESS,
  DELETE_CALENDAR_APPOINTMENT_FAILURE,
  REGISTER_CALENDAR_APPOINTMENT_REQUEST,
  REGISTER_CALENDAR_APPOINTMENT_SUCCESS,
  REGISTER_CALENDAR_APPOINTMENT_FAILURE,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS,
  FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE,
  FETCH_LINKED_CALENDAR_EVENTS_REQUEST,
  FETCH_LINKED_CALENDAR_EVENTS_SUCCESS,
  FETCH_LINKED_CALENDAR_EVENTS_FAILURE,
  FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST,
  FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS,
  FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE,
  FETCH_APPOINTMENTS_MAP_SUCCESS,
} from './types';
import { generateFullEventId } from './CalendarUtils';
import {
  CONFIRM_VACATION_NOTIFICATION,
  REJECT_VACATION_NOTIFICATION,
  CONFIRM_DELETING_VACATION_NOTIFICATION,
  REJECT_DELETING_VACATION_NOTIFICATION,
} from '../presence/types';
import { INIT_MAIN_SUCCESS } from '../me/types';

const initialState = {
  calendarEventsChanged: false,
  fetchCalendarEventsLoaded: true,
  fetchCalendarEventsError: null,
  fetchCalendarEventContactsLoaded: true,
  fetchCalendarEventLoaded: true,
  fetchCalendarEventError: null,
  fetchedEventDetails: {},
  fetchCalendarSettingsLoaded: true,
  fetchCalendarSettingsError: false,
  byId: {},
  allIds: [],
  emailSuggestions: [],
  filter: {
    view: 'MONTH',
    text: '',
    participants: [],
    types: [],
    sync: [],
    weekend: true,
  },
  syncinCalendars: [],
  fetchSyncinCalendarsLoaded: true,
  fetchSyncinCalendarsError: false,
  saveSyncinCalendarsLoaded: true,
  saveSyncinCalendarsError: false,
  deleteSyncinCalendarsLoaded: true,
  deleteSyncinCalendarsError: false,
  saveEventLoaded: true,
  saveEventError: null,
  deleteEvent: {
    loaded: true,
    error: null,
  },
  syncCalendar: {
    loaded: true,
    error: null,
    success: null,
  },
  saveSyncCalendar: {
    loaded: true,
    success: null,
    error: null,
  },
  deleteSync: {
    loaded: true,
    error: null,
    success: null,
  },
  enableSync: {
    loaded: true,
    error: null,
    success: null,
  },
  importEvents: {
    loaded: true,
    success: null,
    error: null,
  },
  confirmImport: {
    error: null,
    loaded: true,
    success: null,
  },
  cancelImport: {
    loaded: true,
    success: null,
    error: null,
  },
  saveSyncin: {
    loaded: true,
    success: null,
    error: null,
  },
  deleteSyncin: {
    loaded: true,
    success: null,
    error: null,
  },
  freeSlots: {
    loaded: true,
    success: null,
    error: null,
  },
  vacationPlan: {
    loaded: true,
    success: false,
    error: false,
  },
  taskPlan: {
    loaded: true,
    success: false,
    error: false,
  },
  vacationsTaskError: false,
  vacationsConfirmingDone: false,
  saveCalendarChanges: {
    loaded: true,
    error: null,
    success: false,
  },
  fetchCalendarFestivitiesSettingsLoaded: true,
  fetchCalendarFestivitiesSettingsError: false,
  saveCalendarFestivitiesSettingsLoaded: true,
  saveCalendarFestivitiesSettingsError: false,
  saveCalendarFestivitiesSettingsSuccess: false,
  festivitiesSettings: {},
  fetchVacationResumeLoaded: true,
  fetchVacationResumeError: false,
  vacationResume: {},
  fetchAppointmentResultsLoaded: true,
  fetchAppointmentResultsError: true,
  appointmentResultsById: {},
  appointmentResultsAllIds: [],
  errorDeleteResult: false,
  insertAppointmentResultLoaded: true,
  insertAppointmentResultError: false,
  loadedDeleteResult: null,
  fetchAppointmentTypesLoaded: true,
  fetchAppointmentTypesError: true,
  appointmentTypesById: {},
  appointmentTypesAllIds: [],
  errorDeleteType: false,
  insertAppointmentTypeLoaded: true,
  insertAppointmentTypeError: null,
  loadedDeleteType: null,
  dashboardHeader: [],
  dashboardHeaderLoaded: true,
  dashboardHeaderError: false,
  dashboardGraphStatistics: {},
  dashboardGraphStatisticsLoaded: true,
  dashboardGraphStatisticsError: false,
  dashboardUsersProgressBar: {},
  dashboardUsersStatisticsLoaded: true,
  dashboardUsersStatisticsError: false,
  appointmentsListShortcut: {},
  appointmentsListShortcutLoaded: true,
  appointmentsListShortcutError: false,
  appointmentsFilter: {},
  appointmentsIds: [],
  appointmentsById: {},
  appointmentsMapIds: [],
  appointmentsMapById: {},
  fetchAppointmentsLoaded: true,
  fetchAppointmentsError: false,
  totalAppointments: null,
  saveAppointmentLoaded: true,
  saveAppointmentError: null,
  fetchCalendarAppointmentLoaded: true,
  fetchCalendarAppointmentError: null,
  fetchedAppointmentDetails: {},
  appointmentRegistered: true,
  appointmentRegisterError: null,
  linkedCalendarAppointments: [],
  linkedCalendarEvents: [],
  excelAppointmentsById: {},
  excelAppointmentsIds: [],
  fetchAppointmentsForExcelLoaded: true,
  fetchAppointmentsForExcelError: false,
  excelAppointmentsTotal: null,
};

export function calendar(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case INIT_MAIN_SUCCESS:
      return {
        ...state,
        fetchCalendarSettingsLoaded: false,
      };
    case FETCH_CALENDAR_SETTINGS_SUCCESS:
    case SAVE_CALENDAR_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchCalendarSettingsLoaded: true,
        filter: {
          ...state.filter,
          view: action.settings.view,
          participants: action.settings.participants,
          types: action.settings.types,
          sync: action.settings.sync ? state.filter.sync : [],
          weekend: action.settings.weekend,
        },
      };

    case SET_CALENDAR_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.filter,
        },
      };
    case FETCH_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        calendarEventsChanged: false,
        fetchCalendarEventsLoaded: false,
        fetchCalendarEventsError: null,
        byId: {},
        allIds: [],
        appointmentsById: {},
        appointmentsIds: [],
      };
    case FETCH_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        byId: keyBy(action.events.data, (o) => generateFullEventId(o)),
        allIds: action.events.data.map((o) => generateFullEventId(o)),
        fetchCalendarEventsLoaded: true,
        fetchCalendarEventsError: null,
      };
    case FETCH_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        fetchCalendarEventsLoaded: true,
        fetchCalendarEventsError: action.errors,
      };

    case FETCH_CALENDAR_EVENT_CONTACTS_REQUEST:
      return {
        ...state,
        fetchCalendarEventContactsLoaded: false,
      };
    case FETCH_CALENDAR_EVENT_CONTACTS_SUCCESS:
    case FETCH_CALENDAR_EVENT_CONTACTS_FAILURE:
      return {
        ...state,
        fetchCalendarEventContactsLoaded: true,
      };
    case FETCH_CALENDAR_EVENT_REQUEST:
      return {
        ...state,
        fetchCalendarEventLoaded: false,
        fetchCalendarEventError: null,
        fetchedEventDetails: {},
      };
    case FETCH_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        fetchCalendarEventLoaded: true,
        fetchCalendarEventError: null,
        fetchedEventDetails: action.event,
      };
    case FETCH_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        fetchCalendarEventLoaded: true,
        fetchCalendarEventError: action.payload.errors,
        fetchedEventDetails: {},
      };
    case SAVE_CALENDAR_EVENT_REQUEST:
      return {
        ...state,
        saveEventLoaded: false,
        saveEventError: null,
      };
    case SAVE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        saveEventLoaded: true,
        saveEventError: null,
      };
    case SAVE_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        saveEventLoaded: true,
        saveEventError: action.errors,
      };
    case DELETE_CALENDAR_EVENT_REQUEST:
      return {
        ...state,
        deleteEvent: {
          loaded: false,
          error: null,
        },
      };
    case DELETE_CALENDAR_EVENT_SUCCESS: {
      return {
        ...state,
        deleteEvent: {
          loaded: true,
          error: null,
        },
      };
    }
    case DELETE_CALENDAR_EVENT_FAILURE:
      return {
        ...state,
        delete: {
          deleted: null,
          loaded: true,
          error: action.errors,
        },
      };
    case IMPORT_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        importEvents: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case IMPORT_CALENDAR_EVENTS_SUCCESS: {
      const res = (action.success.data && true) || false;
      return {
        ...state,
        importEvents: {
          loaded: true,
          error: null,
          data: action.success.data,
          success: action.success.status,
        },
        calendarEventModal: res,
      };
    }
    case IMPORT_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        importEvents: {
          ...state.importEvents,
          loaded: true,
          error: action.errors,
          success: null,
        },
      };

    case CONFIRM_IMPORT_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        confirmImport: {
          error: null,
          loaded: false,
          success: null,
        },
      };
    case CONFIRM_IMPORT_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        confirmImport: {
          error: null,
          loaded: true,
          success: action.success,
        },
      };
    case CONFIRM_IMPORT_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        confirmImport: {
          error: action.errors,
          loaded: true,
          success: null,
        },
      };

    case CANCEL_IMPORT_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        cancelImport: {
          loaded: false,
          success: null,
          error: null,
        },
      };

    case CANCEL_IMPORT_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        cancelImport: {
          loaded: true,
          success: action.success,
          error: null,
        },
        calendarEventModal: false,
      };
    case CANCEL_IMPORT_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        cancelImport: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case CLOSE_IMPORT_CALENDAR_EVENTS_MODAL:
      return {
        ...state,
        calendarEventModal: action.state,
      };
    case FETCH_CALENDAR_SYNCOUT_REQUEST:
      return {
        ...state,
        syncCalendar: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case FETCH_CALENDAR_SYNCOUT_SUCCESS:
      return {
        ...state,
        syncCalendar: {
          loaded: true,
          success: action.data.status,
          error: null,
          syncData: action.data.data,
        },
      };
    case FETCH_CALENDAR_SYNCOUT_FAILURE:
      return {
        ...state,
        syncCalendar: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case SAVE_CALENDAR_SYNCOUT_REQUEST:
      return {
        ...state,
        saveSyncCalendar: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case SAVE_CALENDAR_SYNCOUT_SUCCESS:
      return {
        ...state,
        saveSyncCalendar: {
          loaded: true,
          success: action.data.status,
          error: null,
        },
        deleteSync: {
          loaded: true,
          error: null,
          success: null,
        },
      };
    case SAVE_CALENDAR_SYNCOUT_FAILURE:
      return {
        ...state,
        saveSyncCalendar: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case DELETE_CALENDAR_SYNCOUT_REQUEST:
      return {
        ...state,
        deleteSync: {
          loaded: false,
          error: null,
          success: null,
        },
      };
    case DELETE_CALENDAR_SYNCOUT_SUCCESS:
      return {
        ...state,
        deleteSync: {
          loaded: true,
          error: null,
          success: action.status,
        },
        syncCalendar: {
          loaded: true,
          error: null,
          success: null,
        },
      };
    case DELETE_CALENDAR_SYNCOUT_FAILURE:
      return {
        ...state,
        deleteSync: {
          loaded: true,
          error: action.errors,
          success: null,
        },
      };
    case ENABLE_DISABLE_SYNCOUT_REQUEST:
      return {
        ...state,
        enableSync: {
          loaded: false,
          error: null,
          success: null,
        },
      };
    case ENABLE_DISABLE_SYNCOUT_SUCCESS:
      return {
        ...state,
        enableSync: {
          loaded: true,
          error: null,
          success: action.success,
        },
      };
    case ENABLE_DISABLE_SYNCOUT_FAILURE:
      return {
        ...state,
        enableSync: {
          loaded: true,
          error: action.errors,
          success: null,
        },
      };
    case FETCH_CALENDAR_SYNCIN_REQUEST:
      return {
        ...state,
        fetchSyncinCalendarsLoaded: false,
        fetchSyncinCalendarsError: false,
      };

    case FETCH_CALENDAR_SYNCIN_SUCCESS:
      return {
        ...state,
        fetchSyncinCalendarsLoaded: true,
        fetchSyncinCalendarsError: false,
        syncinCalendars: action.success.data,
        filter: {
          ...state.filter,
          sync:
            action.success.data.length > 0
              ? action.success.data.map((s) => s.id)
              : [],
        },
      };

    case FETCH_CALENDAR_SYNCIN_FAILURE:
      return {
        ...state,
        fetchSyncinCalendarsLoaded: true,
        fetchSyncinCalendarsError: action.errors,
      };
    case SAVE_CALENDAR_SYNCIN_REQUEST:
      return {
        ...state,
        saveSyncin: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case SAVE_CALENDAR_SYNCIN_SUCCESS:
      return {
        ...state,
        saveSyncin: {
          loaded: true,
          success: action.success,
          error: null,
        },
      };
    case SAVE_CALENDAR_SYNCIN_FAILURE:
      return {
        ...state,
        saveSyncin: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case DELETE_CALENDAR_SYNCIN_REQUEST:
      return {
        ...state,
        deleteSyncin: {
          loaded: false,
          success: null,
          error: null,
        },
      };
    case DELETE_CALENDAR_SYNCIN_SUCCESS:
      return {
        ...state,
        deleteSyncin: {
          loaded: true,
          success: action.success,
          error: null,
        },
      };
    case DELETE_CALENDAR_SYNCIN_FAILURE:
      return {
        ...state,
        deleteSyncin: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };
    case TOGGLE_CALENDAR_SYNCIN_REQUEST: {
      const existingSyncin = state.syncinCalendar.data;
      const newSyncin = existingSyncin.map((syncin) => {
        if (syncin.id === action.payload.syncId) {
          return {
            ...syncin,
            // enabled: action.payload.enabled,
          };
        }
        return syncin;
      });
      return {
        ...state,
        syncinCalendar: {
          ...state.syncinCalendar,
          data: newSyncin,
        },
      };
    }

    case TOGGLE_CALENDAR_SYNCIN_FAILURE: {
      const existingSyncin = state.syncinCalendar.data;
      const newSyncin = existingSyncin.map((syncin) => {
        if (syncin.id === action.payload.syncId) {
          return {
            ...syncin,
            // enabled: !syncin.enabled,
          };
        }
        return syncin;
      });
      return {
        ...state,
        syncinCalendar: {
          ...state.syncinCalendar,
          error: action.payload.error,
          data: newSyncin,
        },
      };
    }

    case FETCH_CALENDAR_SLOTS_REQUEST:
      return {
        ...state,
        freeSlots: {
          loaded: false,
          success: null,
          error: null,
        },
      };

    case FETCH_CALENDAR_SLOTS_SUCCESS:
      return {
        ...state,
        freeSlots: {
          loaded: true,
          success: action.success.status,
          error: null,
          slots: action.success.data,
        },
      };

    case FETCH_CALENDAR_SLOTS_FAILURE:
      return {
        ...state,
        freeSlots: {
          loaded: true,
          success: null,
          error: action.errors,
        },
      };

    case FETCH_VACATION_PLAN_REQUEST:
      return {
        ...state,
        vacationPlan: {
          loaded: false,
          success: false,
          error: false,
          events: null,
          params: action.params,
        },
        vacationsTaskError: false,
      };

    case FETCH_VACATION_PLAN_SUCCESS:
      return {
        ...state,
        vacationPlan: {
          ...state.vacationPlan,
          loaded: true,
          success: true,
          error: false,
          events: action.data,
        },
      };

    case FETCH_VACATION_PLAN_FAILURE:
      return {
        ...state,
        vacationPlan: {
          loaded: true,
          success: false,
          error: false,
        },
      };

    case APPROVE_VACATION_REQUEST:
      return {
        ...state,
        vacationsTaskError: false,
        vacationsConfirmingDone: false,
      };

    case APPROVE_VACATION_SUCCESS:
      return {
        ...state,
        vacationsConfirmingDone: true,
      };

    case APPROVE_VACATION_FAILURE:
      return {
        ...state,
        vacationsTaskError: true,
      };

    case REJECT_VACATION_REQUEST:
      return {
        ...state,
        vacationsTaskError: false,
        vacationsConfirmingDone: false,
      };

    case REJECT_VACATION_SUCCESS: {
      return {
        ...state,
        vacationsConfirmingDone: true,
      };
    }

    case REJECT_VACATION_FAILURE:
      return {
        ...state,
        vacationsTaskError: true,
      };

    case APPROVE_DELETION_VACATION_REQUEST:
      return {
        ...state,
        vacationsTaskError: false,
        vacationsConfirmingDone: false,
      };

    case APPROVE_DELETION_VACATION_SUCCESS:
      return {
        ...state,
        vacationsConfirmingDone: true,
      };

    case APPROVE_DELETION_VACATION_FAILURE:
      return {
        ...state,
        vacationsTaskError: true,
      };

    case REJECT_DELETION_VACATION_REQUEST:
      return {
        ...state,
        vacationsTaskError: false,
        vacationsConfirmingDone: false,
      };

    case REJECT_DELETION_VACATION_SUCCESS: {
      return {
        ...state,
        vacationsConfirmingDone: true,
      };
    }

    case REJECT_DELETION_VACATION_FAILURE:
      return {
        ...state,
        vacationsTaskError: true,
      };

    case CALENDAR_UPDATE_NOTIFICATION:
    case CALENDAR_INSERT_NOTIFICATION:
    case CALENDAR_DELETE_NOTIFICATION:
    case CALENDAR_DRAG_NOTIFICATION:
    case CONFIRM_VACATION_NOTIFICATION:
    case REJECT_VACATION_NOTIFICATION:
    case CONFIRM_DELETING_VACATION_NOTIFICATION:
    case REJECT_DELETING_VACATION_NOTIFICATION:
      return {
        ...state,
        calendarEventsChanged: !action.payload.archived
          ? true
          : state.calendarEventsChanged,
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        deleteSync: {
          loaded: true,
          error: null,
          success: null,
        },
        enableSync: {
          loaded: true,
          error: null,
          success: null,
        },
        saveSyncCalendar: {
          loaded: true,
          success: null,
          error: null,
        },
        confirmImport: {
          error: null,
          loaded: true,
          success: null,
        },
        cancelImport: {
          loaded: true,
          success: null,
          error: null,
        },
        saveSyncin: {
          loaded: true,
          success: null,
          error: null,
        },
        deleteSyncin: {
          loaded: true,
          success: null,
          error: null,
        },
        vacationPlan: {
          loaded: true,
          success: false,
          error: false,
        },
        vacationsTaskError: false,
        vacationsConfirmingDone: false,
      };

    case FETCH_LIST_OF_EMAIL_SUGGESTIONS_REQUEST:
      return {
        ...state,
      };
    case FETCH_LIST_OF_EMAIL_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        emailSuggestions: action.payload.data,
      };
    case FETCH_LIST_OF_EMAIL_SUGGESTIONS_FAILURE:
      return {
        ...state,
      };
    case FETCH_CALENDAR_FESTIVITIES_SETTINGS_REQUEST:
      return {
        ...state,
        fetchCalendarFestivitiesSettingsLoaded: false,
        fetchCalendarFestivitiesSettingsError: false,
      };

    case FETCH_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchCalendarFestivitiesSettingsLoaded: true,
        fetchCalendarFestivitiesSettingsError: false,
        festivitiesSettings: action.settings,
      };

    case FETCH_CALENDAR_FESTIVITIES_SETTINGS_FAILURE:
      return {
        ...state,
        fetchCalendarFestivitiesSettingsLoaded: true,
        fetchCalendarFestivitiesSettingsError: true,
      };
    case SAVE_CALENDAR_FESTIVITIES_SETTINGS_REQUEST:
      return {
        ...state,
        saveCalendarFestivitiesSettingsLoaded: false,
        saveCalendarFestivitiesSettingsError: false,
        saveCalendarFestivitiesSettingsSuccess: false,
      };

    case SAVE_CALENDAR_FESTIVITIES_SETTINGS_SUCCESS:
      return {
        ...state,
        saveCalendarFestivitiesSettingsLoaded: true,
        saveCalendarFestivitiesSettingsError: false,
        saveCalendarFestivitiesSettingsSuccess: true,
      };

    case SAVE_CALENDAR_FESTIVITIES_SETTINGS_FAILURE:
      return {
        ...state,
        saveCalendarFestivitiesSettingsLoaded: true,
        saveCalendarFestivitiesSettingsError: true,
        saveCalendarFestivitiesSettingsSuccess: false,
      };
    case FETCH_VACATION_RESUME_REQUEST:
      return {
        ...state,
        fetchVacationResumeLoaded: false,
        fetchVacationResumeError: false,
      };

    case FETCH_VACATION_RESUME_SUCCESS:
      return {
        ...state,
        fetchVacationResumeLoaded: true,
        fetchVacationResumeError: false,
        vacationResume: action.data,
      };

    case FETCH_VACATION_RESUME_FAILURE:
      return {
        ...state,
        fetchVacationResumeLoaded: true,
        fetchVacationResumeError: true,
      };

    case FETCH_TASK_PLAN_REQUEST:
      return {
        ...state,
        taskPlan: {
          loaded: false,
          success: false,
          error: false,
          events: null,
          vacations: null,
          params: action.params,
        },
      };

    case FETCH_TASK_PLAN_SUCCESS:
      return {
        ...state,
        taskPlan: {
          ...state.taskPlan,
          loaded: true,
          success: true,
          error: false,
          events: action.data.events,
          vacations: action.data.vacations,
          appointments: action.data.appointments,
        },
      };

    case FETCH_TASK_PLAN_FAILURE:
      return {
        ...state,
        taskPlan: {
          loaded: true,
          success: false,
          error: false,
        },
      };
    case FETCH_APPOINTMENT_RESULTS_REQUEST:
      return {
        ...state,
        fetchAppointmentResultsLoaded: false,
        fetchAppointmentResultsError: false,
        linkedCalendarAppointments: [],
      };
    case FETCH_APPOINTMENT_RESULTS_SUCCESS:
      return {
        ...state,
        fetchAppointmentResultsLoaded: true,
        fetchAppointmentResultsError: false,
        appointmentResultsById: keyBy(action.data, (status) => status.id),
        appointmentResultsAllIds: action.data.map((status) => status.id),
        errorDeleteResult: false,
      };
    case FETCH_APPOINTMENT_RESULTS_FAILURE:
      return {
        ...state,
        fetchAppointmentResultsLoaded: true,
        fetchAppointmentResultsError: true,
      };
    case DELETE_APPOINTMENT_RESULT_REQUEST:
      return {
        ...state,
        loadedDeleteResult: action.id,
        errorDeleteResult: false,
      };
    case DELETE_APPOINTMENT_RESULT_SUCCESS:
      return {
        ...state,
        loadedDeleteResult: null,
        errorDeleteResult: false,
      };
    case DELETE_APPOINTMENT_RESULT_FAILURE:
      return {
        ...state,
        loadedDeleteResult: null,
        errorDeleteResult: action.error,
      };
    case EDIT_APPOINTMENT_RESULT_REQUEST:
    case INSERT_APPOINTMENT_RESULT_REQUEST:
      return {
        ...state,
        insertAppointmentResultLoaded: false,
        insertAppointmentResultError: false,
      };
    case EDIT_APPOINTMENT_RESULT_SUCCESS:
    case INSERT_APPOINTMENT_RESULT_SUCCESS:
      return {
        ...state,
        insertAppointmentResultLoaded: true,
        insertAppointmentResultError: false,
      };
    case EDIT_APPOINTMENT_RESULT_FAILURE:
    case INSERT_APPOINTMENT_RESULT_FAILURE:
      return {
        ...state,
        insertAppointmentResultLoaded: true,
        insertAppointmentResultError: action.error,
      };
    case CLEAR_APPOINTMENT_RESULT_ERRORS:
      return {
        ...state,
        insertAppointmentResultLoaded: true,
        insertAppointmentResultError: false,
      };

    case FETCH_APPOINTMENT_TYPES_REQUEST:
      return {
        ...state,
        fetchAppointmentTypesLoaded: false,
        fetchAppointmentTypesError: false,
      };
    case FETCH_APPOINTMENT_TYPES_SUCCESS:
      return {
        ...state,
        fetchAppointmentTypesLoaded: true,
        fetchAppointmentTypesError: false,
        appointmentTypesById: keyBy(action.data, (type) => type.id),
        appointmentTypesAllIds: action.data.map((type) => type.id),
        errorDeleteType: false,
      };
    case FETCH_APPOINTMENT_TYPES_FAILURE:
      return {
        ...state,
        fetchAppointmentTypesLoaded: true,
        fetchAppointmentTypesError: true,
      };
    case DELETE_APPOINTMENT_TYPE_REQUEST: {
      return {
        ...state,
        loadedDeleteType: action.id,
        errorDeleteType: false,
      };
    }
    case DELETE_APPOINTMENT_TYPE_SUCCESS:
      return {
        ...state,
        loadedDeleteType: null,
        errorDeleteType: false,
      };
    case DELETE_APPOINTMENT_TYPE_FAILURE:
      return {
        ...state,
        loadedDeleteType: null,
        errorDeleteType: action.error,
      };
    case EDIT_APPOINTMENT_TYPE_REQUEST:
    case INSERT_APPOINTMENT_TYPE_REQUEST:
      return {
        ...state,
        insertAppointmentTypeLoaded: false,
        insertAppointmentTypeError: null,
      };
    case EDIT_APPOINTMENT_TYPE_SUCCESS:
    case INSERT_APPOINTMENT_TYPE_SUCCESS:
      return {
        ...state,
        insertAppointmentTypeLoaded: true,
        insertAppointmentTypeError: null,
      };
    case EDIT_APPOINTMENT_TYPE_FAILURE:
    case INSERT_APPOINTMENT_TYPE_FAILURE:
      return {
        ...state,
        insertAppointmentTypeLoaded: true,
        insertAppointmentTypeError: action.error,
      };
    case CLEAR_APPOINTMENT_TYPE_ERRORS:
      return {
        ...state,
        insertAppointmentTypeLoaded: true,
        insertAppointmentTypeError: null,
      };
    case FETCH_APPOINTMENT_DASHBOARD_HEADER_REQUEST:
      return {
        ...state,
        dashboardHeaderLoaded: false,
        dashboardHeaderError: false,
      };
    case FETCH_APPOINTMENT_DASHBOARD_HEADER_SUCCESS:
      return {
        ...state,
        dashboardHeader: action.data,
        dashboardHeaderLoaded: true,
        dashboardHeaderError: false,
      };
    case FETCH_APPOINTMENT_DASHBOARD_HEADER_FAILURE:
      return {
        ...state,
        dashboardHeaderLoaded: true,
        dashboardHeaderError: action.error,
      };
    case FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_REQUEST:
      return {
        ...state,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };
    case FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_SUCCESS: {
      return {
        ...state,
        dashboardGraphStatistics: action.data,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };
    }
    case FETCH_APPOINTMENT_DASHBOARD_GRAPH_STATISTICS_FAILURE:
      return {
        ...state,
        dashboardGraphStatisticsLoaded: true,
        dashboardGraphStatisticsError: false,
      };
    case FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_REQUEST:
      return {
        ...state,
        dashboardUsersStatisticsLoaded: false,
        dashboardUsersStatisticsError: false,
      };
    case FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_SUCCESS: {
      const {
        totalAppointments,
        totalOkAppointments,
        totalPendingAppointments,
        totalNextAppointments,
      } = action.data;
      return {
        ...state,
        dashboardUsersProgressBar: {
          totalAppointments,
          totalOkAppointments,
          totalPendingAppointments,
          totalNextAppointments,
        },
        dashboardUsersStatisticsLoaded: true,
        dashboardUsersStatisticsError: false,
      };
    }
    case FETCH_APPOINTMENT_DASHBOARD_USERS_STATISTICS_FAILURE:
      return {
        ...state,
        dashboardUsersStatisticsLoaded: true,
        dashboardUsersStatisticsError: action.error,
      };
    case FETCH_APPOINTMENTS_LIST_SHORTCUT_REQUEST:
      return {
        ...state,
        appointmentsListShortcutLoaded: true,
        appointmentsListShortcutError: false,
      };
    case FETCH_APPOINTMENTS_LIST_SHORTCUT_SUCCESS: {
      return {
        ...state,
        appointmentsListShortcut: action.data,
        appointmentsListShortcutLoaded: true,
        appointmentsListShortcutError: false,
      };
    }
    case FETCH_APPOINTMENTS_LIST_SHORTCUT_FAILURE:
      return {
        ...state,
        appointmentsListShortcutLoaded: true,
        appointmentsListShortcutError: action.error,
      };
    case SET_APPOINTMENTS_FILTER:
      return {
        ...state,
        appointmentsFilter: {
          ...action.payload,
          page: 0,
        },
      };
    case FETCH_APPOINTMENTS_REQUEST:
      return {
        ...state,
        fetchAppointmentsLoaded: false,
        fetchAppointmentsError: false,
      };
    case FETCH_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        fetchAppointmentsLoaded: true,
        fetchAppointmentsError: false,
        totalAppointments: action.data.total,
        appointmentsById: keyBy(action.data.data, 'id'),
        appointmentsIds: action.data.data.map((appointment) => appointment.id),
        appointmentsFilter: {
          ...state.appointmentsFilter,
          page: action.data.page,
        },
      };
    }
    case FETCH_APPOINTMENTS_FAILURE:
      return {
        ...state,
        fetchAppointmentsLoaded: true,
        fetchAppointmentsError: true,
      };
    case FETCH_APPOINTMENTS_MAP_SUCCESS: {
      return {
        ...state,
        appointmentsMapById: keyBy(action.data.data, 'id'),
        appointmentsMapIds: action.data.data.map((appointment) => appointment.id)
      };
    }
    case SAVE_CALENDAR_APPOINTMENT_REQUEST:
      return {
        ...state,
        saveAppointmentLoaded: false,
        saveAppointmentError: null,
      };
    case SAVE_CALENDAR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        saveAppointmentLoaded: true,
        saveAppointmentError: null,
      };
    case SAVE_CALENDAR_APPOINTMENT_FAILURE:
      return {
        ...state,
        saveAppointmentLoaded: true,
        saveAppointmentError: action.errors,
      };
    case FETCH_CALENDAR_APPOINTMENT_REQUEST:
      return {
        ...state,
        fetchCalendarAppointmentLoaded: false,
        fetchCalendarAppointmentError: null,
        fetchedAppointmentDetails: {},
      };
    case FETCH_CALENDAR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        fetchCalendarAppointmentLoaded: true,
        fetchCalendarAppointmentError: null,
        fetchedAppointmentDetails: action.appointment,
      };
    case FETCH_CALENDAR_APPOINTMENT_FAILURE:
      return {
        ...state,
        fetchCalendarAppointmentLoaded: true,
        fetchCalendarAppointmentError: action.payload.errors,
        fetchedAppointmentDetails: {},
      };
    case DELETE_CALENDAR_APPOINTMENT_REQUEST:
      return {
        ...state,
        deleteEvent: {
          loaded: false,
          error: null,
        },
      };
    case DELETE_CALENDAR_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        deleteEvent: {
          loaded: true,
          error: null,
        },
      };
    }
    case DELETE_CALENDAR_APPOINTMENT_FAILURE:
      return {
        ...state,
        delete: {
          deleted: null,
          loaded: true,
          error: action.errors,
        },
      };
    case REGISTER_CALENDAR_APPOINTMENT_REQUEST:
      return {
        ...state,
        appointmentRegistered: false,
        appointmentRegisterError: null,
      };
    case REGISTER_CALENDAR_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentRegistered: true,
        appointmentRegisterError: null,
      };
    case REGISTER_CALENDAR_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointmentRegistered: true,
        appointmentRegisterError: action.errors,
      };
    case FETCH_LINKED_CALENDAR_APPOINTMENTS_REQUEST:
      return {
        ...state,
        linkedCalendarAppointments: [],
      };
    case FETCH_LINKED_CALENDAR_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        linkedCalendarAppointments: action.appointments,
      };
    case FETCH_LINKED_CALENDAR_APPOINTMENTS_FAILURE:
      return {
        ...state,
        fetchCalendarAppointmentError: action.error,
      };
    case FETCH_LINKED_CALENDAR_EVENTS_REQUEST:
      return {
        ...state,
        linkedCalendarEvents: [],
      };
    case FETCH_LINKED_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        linkedCalendarEvents: action.events,
      };
    case FETCH_LINKED_CALENDAR_EVENTS_FAILURE:
      return {
        ...state,
        fetchCalendarAppointmentError: action.error,
      };
    case FETCH_APPOINTMENTS_FOR_EXCEL_REQUEST:
      return {
        ...state,
        fetchAppointmentsForExcelLoaded: false,
        fetchAppointmentsForExcelError: false,
      };
    case FETCH_APPOINTMENTS_FOR_EXCEL_SUCCESS:
      return {
        ...state,
        fetchAppointmentsForExcelLoaded: true,
        fetchAppointmentsForExcelError: false,
        excelAppointmentsById: {
          ...state.byId,
          ...keyBy(action.data.data, 'id'),
        },
        excelAppointmentsIds: action.data.data.map(
          (appointment) => appointment.id
        ),
        excelAppointmentsTotal: action.data.total,
      };
    case FETCH_APPOINTMENTS_FOR_EXCEL_FAILURE:
      return {
        ...state,
        fetchAppointmentsForExcelLoaded: true,
        fetchAppointmentsForExcelError: true,
      };
    default:
      return state;
  }
}

const initialsettingsstate = {
  onlyMine: false,
  view: 'MONTH',
  workingStart: '09:00:00',
  workingEnd: '19:00:00',
  eventDuration: 1,
  summary: null,
  sync: false,
  types: ['EVENT'],
  weekend: true,
  saveSettings: {
    loaded: true,
    error: null,
    success: false,
  },
};

export function calendarSettings(state = initialsettingsstate, action = {}) {
  switch (action.type) {
    case FETCH_CALENDAR_SETTINGS_SUCCESS:
      return {
        ...state,
        onlyMine: action.settings.onlyMine,
        view: action.settings.view,
        workingStart: action.settings.workingStart,
        workingEnd: action.settings.workingEnd,
        eventDuration: action.settings.eventDuration,
        sync: action.settings.sync,
        types: action.settings.types,
        summary: action.settings.summary,
        weekend: action.settings.weekend,
        color: action.settings.color,
        eventVisibility: action.settings.eventVisibility,
        reminder: action.settings.reminder,
        vacationVisibility: action.settings.vacationVisibility,
        appointmentType: action.settings.appointmentType,
      };
    case SAVE_CALENDAR_SETTINGS_REQUEST:
      return {
        ...state,
        saveSettings: {
          loaded: false,
          error: null,
          success: false,
        },
      };
    case SAVE_CALENDAR_SETTINGS_SUCCESS:
      return {
        ...state,
        onlyMine: action.settings.onlyMine,
        view: action.settings.view,
        workingStart: action.settings.workingStart,
        workingEnd: action.settings.workingEnd,
        eventDuration: action.settings.eventDuration,
        sync: action.settings.sync,
        types: action.settings.types,
        summary: action.settings.summary,
        weekend: action.settings.weekend,
        color: action.settings.color,
        eventVisibility: action.settings.eventVisibility,
        reminder: action.settings.reminder,
        vacationVisibility: action.settings.vacationVisibility,
        saveSettings: {
          loaded: true,
          error: null,
          success: true,
        },
      };
    case SAVE_CALENDAR_SETTINGS_FAILURE:
      return {
        ...state,
        saveSettings: {
          loaded: true,
          error: action.errors,
          success: false,
        },
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        saveSettings: {
          loaded: true,
          error: null,
          success: false,
        },
      };

    default:
      return state;
  }
}
