import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const UsersPunchingsList = React.lazy(() => import('./UsersPunchingsList'));

const UsersPunchingsPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <UsersPunchingsList {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default UsersPunchingsPage;
