import React, { Component } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash.filter';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { getUsersSelectOptions } from '../../js/users/selectors';
import Select2 from './Select2';
import { getContactsSelectOptions } from '../../js/contacts/selectors';
import { fetchContactsRequest } from '../../js/contacts/actions';
import { getMeId } from '../../js/me/selectors';

const PAGE_SIZE = 20;

const messages = defineMessages({
  users: {
    id: 'UsersAndContacts.label.users',
    defaultMessage: 'Users',
  },
  contacts: {
    id: 'UsersAndContacts.label.contacts',
    defaultMessage: 'Contacts',
  },
  placeholder: {
    id: 'UsersAndContacts.placeholder',
    defaultMessage: 'Select someone to add',
  },
  noOptionsMessage: {
    id: 'UsersAndContacts.noOptionsMessage',
    defaultMessage: 'No data found',
  },
});

class UsersAndContacts extends Component {
  state = {
    selected: undefined,
  };

  componentDidMount() {
    const params = {
      page: 0,
      pageSize: 100,
      public: true,
      private: true,
    };
    this.props.fetch(params);
  }

  handleInputChange = (text) => {
    if (text.length > 0) {
      const params = {
        page: 0,
        pageSize: PAGE_SIZE,
        public: true,
        private: true,
        name: text,
      };
      this.props.fetch(params);
    }
  };

  handleSelect = (selected) => {
    this.setState(
      { selected: selected || (this.props.isMulti ? [] : null) },
      () => {
        const selected = () => {
          if (this.props.isMulti)
            return this.state.selected.map((o) => o.value);
          if (this.state.selected) return this.state.selected.value;
          return null;
        };
        this.props.onSelect(selected());
      }
    );
  };

  arrangeValue = () => {
    const members = [...this.props.users, ...this.props.contacts];
    if (!this.props.selected && this.props.isMulti) return [];
    if (!this.props.selected && !this.props.isMulti) return null;
    if (this.props.isMulti) {
      return members.filter(
        (option) => this.props.selected.indexOf(option.value) > -1
      );
    }
    return members.filter((option) => option.value === this.props.selected);
  };

  render() {
    const {
      error,
      intl: { formatMessage },
      isMulti,
      isClearable,
      id,
      name,
      excluded,
      myId,
      style,
      users,
      contacts,
    } = this.props;

    const excludedUsers = [
      ...excluded.filter((e) => e.type === 'U').map((e) => `U${e.id}`),
      `U${myId}`,
    ];
    const validUsers =
      excludedUsers.length > 0
        ? filter(users, (o) => excludedUsers.indexOf(o.value) < 0)
        : users;

    const excludedContacts = excluded
      .filter((e) => e.type === 'A')
      .map((e) => `A${e.id}`);
    const validContacts =
      excludedContacts.length > 0
        ? filter(contacts, (o) => excludedContacts.indexOf(o.value) < 0)
        : contacts;

    return (
      <Select2
        id={id}
        name={name}
        isMulti={isMulti}
        isClearable={isClearable}
        pageSize={PAGE_SIZE}
        value={this.arrangeValue()}
        onChange={this.handleSelect}
        onInputChange={(e) => this.handleInputChange(e)}
        options={[
          {
            label: formatMessage(messages.users),
            options: validUsers,
          },
          {
            label: formatMessage(messages.contacts),
            options: validContacts,
          },
        ]}
        error={error}
        noOptionsMessage={() => formatMessage(messages.noOptionsMessage)}
        placeholder={formatMessage(messages.placeholder)}
        style={style}
      />
    );
  }
}

UsersAndContacts.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  excluded: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.number,
    })
  ),
  onSelect: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
};

UsersAndContacts.defaultProps = {
  selected: [],
  excluded: [],
  error: null,
  isMulti: false,
  isClearable: false,
};

function mapStateToProps(state) {
  return {
    users: getUsersSelectOptions(state).map((u) => ({
      value: `U${u.value}`,
      label: u.label,
    })),
    contacts: getContactsSelectOptions(state).map((u) => ({
      value: `A${u.value}`,
      label: u.label,
    })),
    myId: getMeId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { fetch: fetchContactsRequest })(UsersAndContacts)
);
