import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import {
  createUserProfileFailure,
  createUserProfileSuccess,
  editUserProfileFailure,
  editUserProfileSuccess,
  fetchUserProfilesFailure,
  fetchUserProfilesSuccess,
  removeUserProfileFailure,
  removeUserProfileSuccess,
  fetchUserProfilesRequest,
  fetchUserProfileGrantsSuccess,
  fetchUserProfileGrantsFailure,
} from './actions';
import {
  CREATE_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILES_REQUEST,
  FETCH_USER_PROFILE_GRANTS_REQUEST,
  REMOVE_USER_PROFILE_REQUEST,
} from './types';

export function* createUserProfile(action) {
  try {
    const res = yield call(api.usersProfiles.createUserProfile, action.payload);
    yield call(checkApiResponse, res);
    yield put(createUserProfileSuccess());
    yield put(push('/administration/settings/profiles'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(createUserProfileFailure(err.data.error));
  }
}

export function* editUserProfile(action) {
  try {
    const res = yield call(api.usersProfiles.editUserProfile, action.payload);
    yield call(checkApiResponse, res);
    yield put(editUserProfileSuccess());
    yield put(push('/administration/settings/profiles'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(editUserProfileFailure(err.data.error ? err.data.error : err));
  }
}

export function* fetchUserProfilesList(action) {
  try {
    const res = yield call(
      api.usersProfiles.getUserProfilesList,
      action.params
    );
    yield call(checkApiResponse, res);
    yield put(
      fetchUserProfilesSuccess({
        data: res.status === 200 ? res.data.data : [],
        page: action.params ? action.params.page : 0,
        total: res.data.total,
      })
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserProfilesFailure(error));
  }
}

export function* removeUserProfile(action) {
  try {
    const res = yield call(api.usersProfiles.removeUserProfile, action.id);
    yield call(checkApiResponse, res);
    yield put(removeUserProfileSuccess(action.id));
    yield put(fetchUserProfilesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removeUserProfileFailure(error));
  }
}
export function* fetchUserProfileDetails(action) {
  try {
    const res = yield call(api.usersProfiles.getUserProfileGrants, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchUserProfileGrantsSuccess(action.id, res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchUserProfileGrantsFailure(error));
  }
}
export default function* rootSaga() {
  yield takeLatest(FETCH_USER_PROFILES_REQUEST, fetchUserProfilesList);
  yield takeLatest(REMOVE_USER_PROFILE_REQUEST, removeUserProfile);
  yield takeLatest(CREATE_USER_PROFILE_REQUEST, createUserProfile);
  yield takeLatest(EDIT_USER_PROFILE_REQUEST, editUserProfile);
  yield takeLatest(FETCH_USER_PROFILE_GRANTS_REQUEST, fetchUserProfileDetails);
}
