import { request } from '../request';

export default {
  getSMS: (params) =>
    request({
      url: '/sms',
      method: 'GET',
      params,
    }),
  sendSMS: (data) =>
    request({
      url: '/sms',
      method: 'POST',
      data,
    }),
  getSMScredits: () =>
    request({
      url: '/customer/sms',
      method: 'GET',
    }),
  getSMSScheduled: () =>
    request({
      url: '/sms/scheduled-messages',
      method: 'GET',
    }),
  createSMSScheduled: (data) =>
    request({
      url: '/sms/scheduled-messages',
      method: 'POST',
      data,
    }),
  updateSMSScheduled: (data) =>
    request({
      url: `/sms/scheduled-messages/${data.id}`,
      method: 'POST',
      data,
    }),
  deleteSMSScheduled: (id) =>
    request({
      url: `/sms/scheduled-messages/${id}`,
      method: 'DELETE',
    }),
  getSMSAlias: () =>
    request({
      url: '/sms/alias',
      method: 'GET',
    }),
  createSMSAlias: (data) =>
    request({
      url: '/sms/alias',
      method: 'POST',
      data,
    }),
  getSMSCampaigns: (params) =>
    request({
      url: '/sms/campaigns',
      method: 'GET',
      params,
    }),
  getSMSCampaign: (id) =>
    request({
      url: `/sms/campaigns/${id}`,
      method: 'GET',
    }),
  createSMSCampaign: (data) =>
    request({
      url: '/sms/campaigns',
      method: 'POST',
      data,
    }),
  updateSMSCampaign: (data) =>
    request({
      url: `/sms/campaigns/${data.id}`,
      method: 'POST',
      data,
    }),
  deleteSMSCampaign: (id) =>
    request({
      url: `/sms/campaigns/${id}`,
      method: 'DELETE',
    }),
  buySMSPackages: (data) =>
    request({
      url: `/sms/purchase-package`,
      method: 'POST',
      data,
    }),
};
