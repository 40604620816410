import React from 'react';
import Wakeup from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const WakeupScheduling = React.lazy(() => import('./WakeupScheduling'));
const WakeupSchedulingPage = (props) => (
  <Wakeup scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <WakeupScheduling {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wakeup>
);

export default WakeupSchedulingPage;
