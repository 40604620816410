import {
  FETCH_ASSOCIATIONS_REQUEST,
  FETCH_ASSOCIATIONS_SUCCESS,
  FETCH_ASSOCIATIONS_FAILURE,
  DELETE_ASSOCIATION_REQUEST,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_FAILURE,
  DELETE_MEMBER_ASSOCIATION_REQUEST,
  DELETE_MEMBER_ASSOCIATION_SUCCESS,
  DELETE_MEMBER_ASSOCIATION_FAILURE,
  EDIT_MEMBER_ASSOCIATION_REQUEST,
  EDIT_MEMBER_ASSOCIATION_SUCCESS,
  EDIT_MEMBER_ASSOCIATION_FAILURE,
  FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST,
  FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS,
  FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE,
} from './types';

export const fetchAssociationsRequest = () => ({
  type: FETCH_ASSOCIATIONS_REQUEST,
});
export const fetchAssociationsSuccess = (data) => ({
  type: FETCH_ASSOCIATIONS_SUCCESS,
  data,
});
export const fetchAssociationsFailure = (errors) => ({
  type: FETCH_ASSOCIATIONS_FAILURE,
  errors,
});
export const deleteAssociationRequest = (id) => ({
  type: DELETE_ASSOCIATION_REQUEST,
  id,
});
export const deleteAssociationSuccess = (id) => ({
  type: DELETE_ASSOCIATION_SUCCESS,
  id,
});
export const deleteAssociationFailure = (errors) => ({
  type: DELETE_ASSOCIATION_FAILURE,
  errors,
});
export const deleteAssociationMemberRequest = (payload) => ({
  type: DELETE_MEMBER_ASSOCIATION_REQUEST,
  payload,
});
export const deleteAssociationMemberSuccess = (payload) => ({
  type: DELETE_MEMBER_ASSOCIATION_SUCCESS,
  payload,
});
export const deleteAssociationMemberFailure = (errors) => ({
  type: DELETE_MEMBER_ASSOCIATION_FAILURE,
  errors,
});
export const editAssociationMemberRequest = (payload) => ({
  type: EDIT_MEMBER_ASSOCIATION_REQUEST,
  payload,
});
export const editAssociationMemberSuccess = (payload) => ({
  type: EDIT_MEMBER_ASSOCIATION_SUCCESS,
  payload,
});
export const editAssociationMemberFailure = (errors) => ({
  type: EDIT_MEMBER_ASSOCIATION_FAILURE,
  errors,
});
export const fetchAssociationMemberDetailsRequest = (payload) => ({
  type: FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST,
  payload,
});
export const fetchAssociationMemberDetailsSuccess = (payload) => ({
  type: FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS,
  payload,
});
export const fetchAssociationMemberDetailsFailure = (errors) => ({
  type: FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE,
  errors,
});
