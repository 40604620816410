import AckHandler from '../websocket/AckHandler';
import WsManager from '../websocket/WsManager';
import { generateAckUid, WsEnums } from '../websocket/WsUtils';
import { VideocallsEnums } from './VideocallsUtils';
import { MeEnums } from '../me/MeUtils';
import {
  answeredVideocall,
  answerVideocallCc,
  answerVideocallFailure,
  answerVideocallSuccess,
  declinedVideocall,
  declineVideocallCc,
  declineVideocallRequest,
  declineVideocallSuccess,
  incomingVideocall,
  receiveSetDndStatus,
  setDndStatusFailure,
  setDndStatusSuccess,
  startUserVideocallFailure,
  startUserVideocallSuccess,
} from './actions';
import { declineCallFailure } from '../phone/actions';

export default class VideocallsManager {
  static myId;

  /**
   * @function
   * @param {Object} data - Message data
   * @param {string?} data.room - Room id if there is already an existing room
   * @param {number} data.called - Id of the called user
   * @param {boolean} data.onlyAudio - Flag to indicates if it is an only audio videocall
   * @param {string} data.alias - Fullname of the logged user
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static startVideocall = (data, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(VideocallsManager.myId);
    const message = {
      channel: WsEnums.Channels.WEBRTC,
      topic: VideocallsEnums.VideocallsTopics.CALLS,
      action: VideocallsEnums.VideocallsActions.CALL_START,
      ackUid,
      channelExtension: {
        rtcRoom: data.room,
      },
      recipient: {
        type: MeEnums.MeTypes.USER,
        id: data.called,
      },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        VideocallsManager.startVideocall(data, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} data - Message data
   * @param {string?} data.user - Videocaller
   * @param {string} data.room - Room id of the call
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static answerVideocall = (data, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(VideocallsManager.myId);
    const message = {
      channel: WsEnums.Channels.WEBRTC,
      topic: VideocallsEnums.VideocallsTopics.CALLS,
      action: VideocallsEnums.VideocallsActions.CALL_ANSWER,
      ackUid,
      channelExtension: {
        rtcRoom: data.room,
      },
      payload: { caller: data.user },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        VideocallsManager.answerVideocall(data.room, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {Object} data - Message data
   * @param {string?} data.user - Videocaller
   * @param {string} data.room - Room id of the call
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static hangupVideocall = (data, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(VideocallsManager.myId);
    const message = {
      channel: WsEnums.Channels.WEBRTC,
      topic: VideocallsEnums.VideocallsTopics.CALLS,
      action: VideocallsEnums.VideocallsActions.HANGUP,
      ackUid,
      channelExtension: {
        rtcRoom: data.room,
      },
      payload: { recipient: data.user, caller: data.caller },
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        VideocallsManager.hangupVideocall(data, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  /**
   * @function
   * @param {boolean} enabled - Flag that indicates if dnd has to be enabled or not
   * @param {string?} oldAckUid - Used only when retrying because ack was not received
   */
  static sendDndStatus = (enabled, oldAckUid) => {
    const ackUid = oldAckUid || generateAckUid(VideocallsManager.myId);
    const message = {
      channel: WsEnums.Channels.WEBRTC,
      topic: VideocallsEnums.VideocallsTopics.STATUS,
      action: enabled
        ? VideocallsEnums.VideocallsActions.ENABLE_DND
        : VideocallsEnums.VideocallsActions.DISABLE_DND,
      ackUid,
    };
    AckHandler.addPendingAck({
      ackUid,
      message,
      retry: () => {
        VideocallsManager.sendDndStatus(enabled, ackUid);
      },
    });
    WsManager.sendMessage(message);
  };

  static manageCallsEvent = (data) => {
    let action;
    let payload;
    let oldPayload;
    if (data.action.endsWith('_ACK')) {
      oldPayload = AckHandler.checkMessage(data);
    }
    switch (data.action) {
      case VideocallsEnums.VideocallsActions.CALL_START_ACK: {
        if (data.payload.success) {
          action = startUserVideocallSuccess;
          payload = oldPayload;
          if (!payload.channelExtension || !payload.channelExtension.rtcRoom) {
            payload.channelExtension = {
              rtcRoom: data.payload.roomId,
            };
          }
        } else {
          action = startUserVideocallFailure;
          payload = { error: data.payload.code, data: oldPayload };
        }
        break;
      }
      case VideocallsEnums.VideocallsActions.CALL_INCOMING:
        action = incomingVideocall;
        payload = {
          room: data.channelExtension.rtcRoom,
          caller: data.payload.caller,
        };
        break;
      case VideocallsEnums.VideocallsActions.CALL_ANSWER:
        action = answeredVideocall;
        payload = {
          room: data.channelExtension.rtcRoom,
        };
        break;
      case VideocallsEnums.VideocallsActions.CALL_ANSWER_ACK: {
        if (data.payload.success) {
          action = answerVideocallSuccess;
          payload = {
            ...data.payload,
            room: oldPayload.channelExtension.rtcRoom,
          };
        } else {
          action = answerVideocallFailure;
          payload = data.payload.code;
        }
        break;
      }
      case VideocallsEnums.VideocallsActions.CALL_ANSWER_CC:
        action = answerVideocallCc;
        payload = data.channelExtension.rtcRoom;
        break;
      case VideocallsEnums.VideocallsActions.HANGUP:
        action = declinedVideocall;
        payload = {
          room: data.channelExtension.rtcRoom,
          caller: data.payload.caller,
        };
        break;
      case VideocallsEnums.VideocallsActions.HANGUP_CC:
        action = declineVideocallCc;
        payload = {
          room: data.channelExtension.rtcRoom,
          caller: data.payload.caller,
        };
        break;
      case VideocallsEnums.VideocallsActions.HANGUP_ACK: {
        if (data.payload.success) {
          action = declineVideocallSuccess;
          payload = {
            room: oldPayload.channelExtension
              ? oldPayload.channelExtension.rtcRoom
              : null,
            caller: data.payload.caller,
          };
        } else if (data.payload.code === 'USER_ALREADY_ANSWERED') {
          action = declineVideocallRequest;
          payload = {
            room: oldPayload.channelExtension
              ? oldPayload.channelExtension.rtcRoom
              : null,
          };
        } else {
          action = declineCallFailure;
        }
        break;
      }
      default:
        return null;
    }
    return {
      action,
      data: payload,
    };
  };

  static manageStatusEvent = (data) => {
    let action;
    let payload;
    if (data.action.endsWith('_ACK')) {
      AckHandler.checkMessage(data);
    }
    switch (data.action) {
      case VideocallsEnums.VideocallsActions.ENABLE_DND_ACK:
      case VideocallsEnums.VideocallsActions.DISABLE_DND_ACK:
        if (data.payload.success) {
          action = setDndStatusSuccess;
          payload =
            data.action === VideocallsEnums.VideocallsActions.ENABLE_DND_ACK;
        } else {
          action = setDndStatusFailure;
        }
        break;
      case VideocallsEnums.VideocallsActions.ENABLE_DND_CC:
      case VideocallsEnums.VideocallsActions.DISABLE_DND_CC:
        action = setDndStatusSuccess;
        payload =
          data.action === VideocallsEnums.VideocallsActions.ENABLE_DND_CC;
        break;
      case VideocallsEnums.VideocallsActions.ENABLE_DND:
        action = receiveSetDndStatus;
        payload = {
          user: data.payload.idUser,
          enabled: true,
        };
        break;
      case VideocallsEnums.VideocallsActions.DISABLE_DND:
        action = receiveSetDndStatus;
        payload = {
          user: data.payload.idUser,
          enabled: false,
        };
        break;
      default:
        return null;
    }
    return {
      action,
      data: payload,
    };
  };

  static manageVideocallsEvent = (data) => {
    const { topic } = data;
    switch (topic) {
      case VideocallsEnums.VideocallsTopics.CALLS:
        return VideocallsManager.manageCallsEvent(data);
      case VideocallsEnums.VideocallsTopics.STATUS:
        return VideocallsManager.manageStatusEvent(data);
      default:
        return null;
    }
  };
}
