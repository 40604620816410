import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { showConfirmModal } from '../../../js/confirm/actions';
import { PhoneEnums } from '../../../js/phone/PhoneUtils';
import { PhoneRulesEnums } from '../../../js/phoneRules/PhoneRulesUtils';
import Utils from '../../../js/lib/utils';
import { getPbxRoutes, getSupplier } from '../../../js/phone/selectors';
import { getDateFormat, getTimeFormat } from '../../../js/settings/selectors';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import { getRule } from '../../../js/phoneRules/selectors';
import { getPbxArchivedGreetingMessages } from '../../../js/vbox/selectors';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  day_0: {
    id: 'PhoneRulesShowRow.label.day_0',
    defaultMessage: 'Su',
  },
  day_1: {
    id: 'PhoneRulesShowRow.label.day_1',
    defaultMessage: 'Mo',
  },
  day_2: {
    id: 'PhoneRulesShowRow.label.day_2',
    defaultMessage: 'Tu',
  },
  day_3: {
    id: 'PhoneRulesShowRow.label.day_3',
    defaultMessage: 'We',
  },
  day_4: {
    id: 'PhoneRulesShowRow.label.day_4',
    defaultMessage: 'Th',
  },
  day_5: {
    id: 'PhoneRulesShowRow.label.day_5',
    defaultMessage: 'Fr',
  },
  day_6: {
    id: 'PhoneRulesShowRow.label.day_6',
    defaultMessage: 'Sa',
  },
  allDay: {
    id: 'PhoneRulesShowRow.label.allDay',
    defaultMessage: 'All day',
  },
  from: {
    id: 'PhoneRulesShowRow.label.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'PhoneRulesShowRow.label.to',
    defaultMessage: 'To',
  },
  occupied: {
    id: 'PhoneRulesShowRow.label.occupied',
    defaultMessage: 'Occupied/DND',
  },
  always: {
    id: 'PhoneRulesShowRow.label.always',
    defaultMessage: 'Always',
  },
  unanswered: {
    id: 'PhoneRulesShowRow.label.unanswered',
    defaultMessage: 'Unanswered',
  },
  deviation: {
    id: 'PhoneRulesShowRow.label.deviation',
    defaultMessage: 'Deviation to {number}',
  },
  dnd: {
    id: 'PhoneRulesShowRow.label.dnd',
    defaultMessage: "Don't disturb",
  },
  vbox: {
    id: 'PhoneRulesShowRow.label.vbox',
    defaultMessage: 'Voicemail',
  },
  responder: {
    id: 'PhoneRulesShowRow.label.responder',
    defaultMessage: 'Responder',
  },
  deleteMessage: {
    id: 'PhoneRulesShowRow.deleteMessage',
    defaultMessage: 'Are you sure you want to delete this rule?',
  },
  deleteRule: {
    id: 'PhoneRulesShowRow.tooltip.deleteRule',
    defaultMessage: 'Delete',
  },
  editRule: {
    id: 'PhoneRulesShowRow.tooltip.editRule',
    defaultMessage: 'Edit',
  },
  copyRule: {
    id: 'PhoneRulesShowRow.tooltip.copyRule',
    defaultMessage: 'Copy',
  },
  saturdays: {
    id: 'PhoneRulesShowRow.saturdays',
    defaultMessage: 'Saturdays',
  },
  sundays: {
    id: 'PhoneRulesShowRow.sundays',
    defaultMessage: 'Sundays',
  },
  holidays: {
    id: 'PhoneRulesShowRow.holidays',
    defaultMessage: 'Holidays',
  },
  onDays: {
    id: 'PhoneRulesShowRow.onDays',
    defaultMessage: 'On {days}',
  },
  fromWhenWithTime: {
    id: 'PhoneRulesShowRow.fromWhenWithTime',
    defaultMessage: 'From {date} at {time}',
  },
  toWhenWithTime: {
    id: 'PhoneRulesShowRow.toWhenWithTime',
    defaultMessage: 'To {date} at {time}',
  },
  fromWhenWithoutTime: {
    id: 'PhoneRulesShowRow.fromWhenWithoutTime',
    defaultMessage: 'From {date}',
  },
  toWhenWithoutTime: {
    id: 'PhoneRulesShowRow.toWhenWithoutTime',
    defaultMessage: 'To {date}',
  },
  fromTimeToTime: {
    id: 'PhoneRulesShowRow.fromTimeToTime',
    defaultMessage: 'From {from} to {to}',
  },
  and: {
    id: 'PhoneRulesShowRow.and',
    defaultMessage: 'and',
  },
  onFestivitiesMasculine: {
    id: 'PhoneRulesShowRow.onFestivitiesMasculine',
    defaultMessage: 'On {days}',
  },
  onFestivitiesFeminine: {
    id: 'PhoneRulesShowRow.onFestivitiesFeminine',
    defaultMessage: 'On {days}',
  },
});

class PhoneRulesShowRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.deleteRule = this.deleteRule.bind(this);
  }

  daysToString = () => {
    const {
      rule: { days },
      intl: { formatMessage },
    } = this.props;
    const selectedDays = Object.keys(days)
      .map((index) =>
        days[index] ? formatMessage(messages[`day_${index}`]) : null
      )
      .filter((day) => day != null);
    return selectedDays.join(', ');
  };

  festivityToString = () => {
    const {
      rule: { festivity },
      intl: { formatMessage },
    } = this.props;
    const festivities = Object.keys(PhoneRulesEnums.SettingsFestivities)
      .filter((f) => festivity[f])
      .map((v) => formatMessage(messages[v]));

    const festivitiesString =
      festivities.length === 3
        ? `${festivities[0]}, ${festivities[1]} ${formatMessage(
            messages.and
          )} ${festivities[2]}`
        : festivities.length === 2
        ? `${festivities[0]} ${formatMessage(messages.and)} ${festivities[1]}`
        : festivities[0];

    return festivities[0] === PhoneRulesEnums.SettingsFestivities.sundays
      ? formatMessage(messages.onFestivitiesFeminine, {
          days: festivitiesString,
        })
      : formatMessage(messages.onFestivitiesMasculine, {
          days: festivitiesString,
        });
  };

  eventToString = (event, formatMessage) => {
    const { supplier } = this.props;
    if (event.indexOf(PhoneRulesEnums.SettingsEvents.always) >= 0) {
      return (
        supplier === PhoneEnums.PbxType.ABILIS && formatMessage(messages.always)
      );
    }
    let msg = '';
    if (event.indexOf(PhoneRulesEnums.SettingsEvents.busy) >= 0) {
      msg += `, ${formatMessage(messages.occupied)}`;
    }
    if (event.indexOf(PhoneRulesEnums.SettingsEvents.unanswered) >= 0) {
      msg += `, ${formatMessage(messages.unanswered)}`;
    }
    return msg.substring(2);
  };

  arrangeWhen = () => {
    const {
      rule,
      dateFormat,
      intl: { formatMessage },
    } = this.props;

    if (!rule.days && !rule.festivity && !rule.startDate && !rule.endDate) {
      return null;
    }
    if (rule.days) {
      return (
        <>
          <div>
            {formatMessage(messages.onDays, { days: this.daysToString() })}
          </div>
          {this.arrangeTime() && (
            <div>
              {formatMessage(messages.fromTimeToTime, {
                from: this.arrangeTime().from,
                to: this.arrangeTime().to,
              })}
            </div>
          )}
        </>
      );
    }
    if (rule.festivity) {
      return (
        <>
          <div>{this.festivityToString()}</div>
          {this.arrangeTime() && (
            <div>
              {formatMessage(messages.fromTimeToTime, {
                from: this.arrangeTime().from,
                to: this.arrangeTime().to,
              })}
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div>
          {this.arrangeTime()
            ? formatMessage(messages.fromWhenWithTime, {
                date: moment(rule.startDate).format(dateFormat),
                time: this.arrangeTime().from,
              })
            : formatMessage(messages.fromWhenWithoutTime, {
                date: moment(rule.startDate).format(dateFormat),
              })}
        </div>
        <div>
          {this.arrangeTime()
            ? formatMessage(messages.toWhenWithTime, {
                date: moment(rule.endDate).format(dateFormat),
                time: this.arrangeTime().to,
              })
            : formatMessage(messages.toWhenWithoutTime, {
                date: moment(rule.endDate).format(dateFormat),
              })}
        </div>
      </>
    );
  };

  actionToString = (rule, formatMessage) => {
    // check if rule is Call Forward to 4444: in that case, if we are working on pbx settings,
    // we have to show it as a responder
    const { action, greetingId } = rule;
    const { audioMessages } = this.props;
    switch (action) {
      case PhoneRulesEnums.SettingsActions.forward:
        return formatMessage(messages.deviation, {
          number: rule.forwardCalled,
        });
      case PhoneRulesEnums.SettingsActions.dnd:
        return formatMessage(messages.dnd);
      case PhoneRulesEnums.SettingsActions.vbox:
        return formatMessage(messages.vbox);
      case PhoneRulesEnums.SettingsActions.responder:
        return (
          <>
            {formatMessage(messages.responder)}
            <Icon
              name="file-music-3"
              color="var(--yn-blue-dark)"
              width={20}
              height={20}
              clickable={false}
              style={{ top: '4px', left: '4px' }}
            >
              {greetingId &&
              audioMessages &&
              audioMessages.filter((m) => m.id === greetingId).length
                ? audioMessages.filter((m) => m.id === greetingId)[0].filename
                : ''}
            </Icon>
          </>
        );
      default:
        return '';
    }
  };

  arrangeTime = () => {
    const { rule } = this.props;
    if (
      (rule.timeFromHour === '00' &&
        rule.timeFromMinute === '00' &&
        rule.timeToHour === '00' &&
        rule.timeToMinute === '00') ||
      (rule.timeFromHour === '00' &&
        rule.timeFromMinute === '00' &&
        rule.timeToHour === '24' &&
        rule.timeToMinute === '00')
    ) {
      return null;
    }
    return {
      from: `${rule.timeFromHour}:${rule.timeFromMinute}`,
      to: `${rule.timeToHour}:${rule.timeToMinute}`,
    };
  };

  deleteRule = (e) => {
    e.preventDefault();
    this.props.confirm({
      message: this.props.intl.formatMessage(messages.deleteMessage),
      modalAction: this.props.deleteFunc,
      actionParams: this.props.rule,
    });
  };

  handleEnableRule = (e) => {
    this.setState({
      ruleEnabled: e.target.checked,
    });
    this.props.toggleEnabledFunc({
      ...this.props.rule,
      enabled: e.target.checked,
    });
  };

  render() {
    const {
      context,
      canEdit,
      intl: { formatMessage },
      rule,
      supplier,
      users,
      routes,
    } = this.props;
    const ruleEnabled = Utils.getValorizedVariable(
      this.state.ruleEnabled,
      this.props.rule.enabled
    );
    const isMeRule = context === PhoneRulesEnums.SettingsContext.me;
    const isPbxRule = context === PhoneRulesEnums.SettingsContext.pbx;
    // const isUsersRule = context === PhoneRulesEnums.SettingsContext.users;

    if (!this.arrangeWhen()) return null;
    if (supplier === PhoneEnums.PbxType.ABILIS)
      return (
        <tr>
          {canEdit && (
            <td className="text-center">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  id={`ruleEnabled_${rule.priority}`}
                  name={`ruleEnabled_${rule.priority}`}
                  checked={ruleEnabled}
                  className="custom-control-input"
                  onChange={this.handleEnableRule}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`ruleEnabled_${rule.priority}`}
                />
              </div>
            </td>
          )}
          <td>{this.arrangeWhen()}</td>
          {/*<td>{time}</td>*/}
          <td>{this.eventToString(rule.event, formatMessage)}</td>
          <td>{this.actionToString(rule, formatMessage)}</td>
          {canEdit && (
            <td style={{ textAlign: 'right', color: 'var(--yn-blue)' }}>
              <Link
                to={
                  isMeRule
                    ? `/phone/settings/edit/${rule.priority}`
                    : `/pbx/settings/switchboard/edit/${rule.priority}`
                }
              >
                <ToolboxIcon name="pencil-write-2">
                  {formatMessage(messages.editRule)}
                </ToolboxIcon>
              </Link>
              <span onClick={this.deleteRule}>
                <ToolboxIcon name="bin-1">
                  {formatMessage(messages.deleteRule)}
                </ToolboxIcon>
              </span>
            </td>
          )}
        </tr>
      );
    if (supplier === PhoneEnums.PbxType.NETHESIS) {
      const foundRoute = routes
        ? routes.find((route) => route.route === rule.route)
        : null;
      return (
        <tr style={{ display: 'flex', flex: 1 }}>
          {canEdit && (
            <td className="text-center" style={{ flex: `0 0 75px` }}>
              <div className="custom-control custom-checkbox custom-control-inline mr-0 ml-2">
                <input
                  type="checkbox"
                  id={`ruleEnabled_${rule.priority}`}
                  name={`ruleEnabled_${rule.priority}`}
                  checked={ruleEnabled}
                  className="custom-control-input"
                  onChange={this.handleEnableRule}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`ruleEnabled_${rule.priority}`}
                />
              </div>
            </td>
          )}
          {context === PhoneRulesEnums.SettingsContext.pbx && (
            <td style={{ flex: '0 0 100px' }}>{`${rule.route}${
              foundRoute && foundRoute.name ? ' - ' + foundRoute.name : ''
            }`}</td>
          )}
          {context === PhoneRulesEnums.SettingsContext.users && (
            <td style={{ flex: '0 0 100px' }}>
              {users[rule.user] && (
                <>
                  <UserAvatar
                    id={rule.user}
                    alt={users[rule.user].departmentFullname}
                    size="sm"
                    src={users[rule.user].avatar}
                  />
                  <span>{users[rule.user].departmentFullname}</span>
                </>
              )}
            </td>
          )}
          <td
            style={
              context === PhoneRulesEnums.SettingsContext.me
                ? { flex: '0 0 200px' }
                : { flex: '0 0 150px' }
            }
          >
            {rule.subject}
          </td>
          <td style={{ flex: '1 1 40%' }}>
            <div>{this.arrangeWhen()}</div>
            <div>{this.actionToString(rule, formatMessage)}</div>
            <div>{this.eventToString(rule.event, formatMessage)}</div>
          </td>

          {canEdit && (
            <td
              style={{
                textAlign: 'right',
                color: 'var(--yn-blue)',
                flex: '0 0 90px',
              }}
            >
              <Link
                to={
                  isMeRule
                    ? `/phone/settings/copy/${rule.priority}`
                    : isPbxRule
                    ? `/pbx/settings/switchboard/copy/${rule.priority}`
                    : `/pbx/settings/users/copy/${rule.priority}`
                }
              >
                <ToolboxIcon name="file-add-1">
                  {formatMessage(messages.copyRule)}
                </ToolboxIcon>
              </Link>
              <Link
                to={
                  isMeRule
                    ? `/phone/settings/edit/${rule.priority}`
                    : isPbxRule
                    ? `/pbx/settings/switchboard/edit/${rule.priority}`
                    : `/pbx/settings/users/edit/${rule.priority}`
                }
              >
                <ToolboxIcon name="pencil-write-2">
                  {formatMessage(messages.editRule)}
                </ToolboxIcon>
              </Link>
              <span onClick={this.deleteRule}>
                <ToolboxIcon name="bin-1">
                  {formatMessage(messages.deleteRule)}
                </ToolboxIcon>
              </span>
            </td>
          )}
        </tr>
      );
    }
    return null;
  }
}

PhoneRulesShowRow.propTypes = {
  rule: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  toggleEnabledFunc: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    supplier: getSupplier(state),
    dateFormat: getDateFormat(state),
    timeFormat: getTimeFormat(state),
    users: getUsersById(state),
    rule: getRule(state, ownProps.context, ownProps.id),
    audioMessages: getPbxArchivedGreetingMessages(state),
    routes: getPbxRoutes(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
  })(PhoneRulesShowRow)
);
