import React from 'react';
import Abook from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
import BackButton from '../../../components/buttons/BackButton';

const AbookContactEdit = React.lazy(() => import('./AbookContactEdit'));

const AbookContactEditPage = (props) => (
  <Abook>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-2">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="abook-new" />
          </div>
        </div>
        <AbookContactEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Abook>
);

export default AbookContactEditPage;
