import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Button from '../../../components/formControls/Button';
import AbookListsEdit from './AbookListsEdit';

const messages = defineMessages({
  title: {
    id: 'AbookListsCreateListModal.title',
    defaultMessage: 'Insert new list',
  },
  cancelButton: {
    id: 'AbookListsCreateListModal.cancelButton',
    defaultMessage: 'Cancel',
  },
});

class AbookListsCreateListModal extends Component {
  render() {
    const {
      onClose,
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal isOpen>
        <ModalHeader>{formatMessage(messages.title)}</ModalHeader>
        <ModalBody>
          <AbookListsEdit
            onSave={(savedId) => this.props.onClose(savedId)}
            fromModal
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn btn-outline-primary"
            text={formatMessage(messages.cancelButton)}
            onClick={onClose}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

AbookListsCreateListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default injectIntl(AbookListsCreateListModal);
