import {
  FETCH_YOUTUBE_INFO_REQUEST,
  FETCH_YOUTUBE_INFO_SUCCESS,
} from './types';

export const fetchYoutubeInfoRequest = (youtubeCode) => ({
  type: FETCH_YOUTUBE_INFO_REQUEST,
  youtubeCode,
});
export const fetchYoutubeInfoSuccess = (payload) => ({
  type: FETCH_YOUTUBE_INFO_SUCCESS,
  payload,
});
