import {
  LOGIN_WEBSOCKET,
  SEND_SUBSCRIPTION_SUCCESS,
  SEND_SUBSCRIPTION_FAILURE,
  WEBSOCKET_DISCONNECTION,
  WEBSOCKET_CONNECTION,
  LOGOUT_WEBSOCKET,
} from './types';

export const sendSubscriptionSuccess = () => ({
  type: SEND_SUBSCRIPTION_SUCCESS,
});
export const sendSubscriptionFailure = () => ({
  type: SEND_SUBSCRIPTION_FAILURE,
});
export const loginWebsocket = () => ({
  type: LOGIN_WEBSOCKET,
});
export const logoutWebsocket = () => ({
  type: LOGOUT_WEBSOCKET,
});
export const websocketConnection = () => ({
  type: WEBSOCKET_CONNECTION,
});
export const websocketDisconnection = () => ({
  type: WEBSOCKET_DISCONNECTION,
});
