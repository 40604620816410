import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Utils from '../js/lib/utils';
import ynConf from '../conf';

const Avatar = ({
  type,
  size,
  alt,
  src,
  className,
  offline,
  clickable,
  imageSize,
  base64,
}) => {
  const classes = classnames(
    'rounded-circle',
    'border',
    'border-gray-light',
    className
  );
  const style = {
    opacity: offline ? 0.4 : 1,
    cursor: clickable ? 'pointer' : 'default',
  };
  const urlSrc = Utils.getAvatarUrl(type, src, imageSize);
  const image =
    base64 ||
    (src
      ? src === 'parking.svg' || src === 'conference.svg'
        ? `${ynConf.publicFolderPath}/${src}`
        : urlSrc
      : `${ynConf.publicFolderPath}/avatar.svg`);

  const width =
    size === 'sm' ? 25 : size === 'md' ? 40 : size === 'lg' ? 80 : 40;
  const height = width;

  return (
    <div className="d-inline-block">
      <span
        className="rounded-circle d-inline-block bg-white"
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <img
          className={classes}
          style={style}
          src={image}
          alt={alt}
          width={width}
          height={height}
          onError={(e) => {
            e.target.src = `${ynConf.publicFolderPath}/avatar.svg`;
          }}
        />
      </span>
    </div>
  );
};

Avatar.propTypes = {
  type: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  offline: PropTypes.bool,
  clickable: PropTypes.bool,
  imageSize: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Avatar.defaultProps = {
  src: null,
  size: 'md',
  alt: 'YouNeed Unknown Image',
  className: 'd-inline-block',
  offline: false,
  clickable: false,
  imageSize: 'md',
};

export default Avatar;
