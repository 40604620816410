import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  isPhoneLogged,
  isPhoneStatusAutoChanged,
  getMeMainExtensionUsername,
  getSupplier,
} from '../phone/selectors';
import { getRules, getSelectedPbxForRuleManagement } from './selectors';
import { PhoneEnums } from '../phone/PhoneUtils';
import { managePhoneStatus } from '../phone/PbxSettingsUtils';
import {
  fetchMePhoneRulesSuccess,
  fetchMePhoneRulesFailure,
  deleteMePhoneRuleSuccess,
  deleteMePhoneRuleFailure,
  toggleMeEnabledPhoneRuleFailure,
  saveMePhoneRuleSuccess,
  saveMePhoneRuleFailure,
  fetchPbxPhoneRulesSuccess,
  fetchPbxPhoneRulesFailure,
  deletePbxPhoneRuleSuccess,
  deletePbxPhoneRuleFailure,
  savePbxPhoneRuleFailure,
  savePbxPhoneRuleSuccess,
  togglePbxEnabledPhoneRuleFailure,
  fetchPbxPhoneRulesRequest,
  fetchMePhoneRulesRequest,
  saveUserPhoneRuleSuccess,
  saveUserPhoneRuleFailure,
  togglePbxEnabledPhoneRuleSuccess,
  toggleMeEnabledPhoneRuleSuccess,
} from './actions';
import { changePhoneStatus } from '../phone/actions';
import {
  FETCH_ME_PHONERULES_REQUEST,
  DELETE_ME_PHONERULE_REQUEST,
  TOGGLE_ME_ENABLED_PHONERULE_REQUEST,
  SAVE_ME_PHONERULE_REQUEST,
  FETCH_PBX_PHONERULES_REQUEST,
  DELETE_PBX_PHONERULE_REQUEST,
  SAVE_PBX_PHONERULE_REQUEST,
  TOGGLE_PBX_ENABLED_PHONERULE_REQUEST,
  SAVE_USER_PHONERULE_REQUEST,
} from './types';
import { PbxManager } from '../phone/PbxManager';
import { getPbxs, getMeId } from '../me/selectors';

export function* fetchMePhoneRules() {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const supplier = yield select(getSupplier);
    let myId = null;
    if (supplier === PhoneEnums.PbxType.NETHESIS) {
      myId = yield select(getMeId);
    }
    const res = yield call(
      PbxManager.retrieveUserSettings.bind(PbxManager, myId)
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(fetchMePhoneRulesSuccess(res.rules));
    const autoChanged = yield select(isPhoneStatusAutoChanged);
    if (!autoChanged) {
      const mainUsername = yield select(getMeMainExtensionUsername);
      yield put(changePhoneStatus(managePhoneStatus(res.rules), mainUsername));
    }
  } catch (err) {
    yield put(fetchMePhoneRulesFailure(err));
  }
}

export function* deleteMePhoneRule(action) {
  try {
    const res = yield call(
      PbxManager.deleteUserSetting.bind(PbxManager, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(deleteMePhoneRuleSuccess(res.rules));
    yield put(fetchMePhoneRulesRequest());
  } catch (err) {
    yield put(deleteMePhoneRuleFailure(err));
  }
}

export function* toggleMeEnabledPhoneRule(action) {
  try {
    const res = yield call(
      PbxManager.saveUserSetting.bind(PbxManager, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res.message;
    }
    const autoChanged = yield select(isPhoneStatusAutoChanged);
    const rules = yield select(getRules, 'me');
    if (!autoChanged) {
      const mainUsername = yield select(getMeMainExtensionUsername);
      yield put(changePhoneStatus(managePhoneStatus(rules), mainUsername));
    }
    yield put(toggleMeEnabledPhoneRuleSuccess());
    yield put(fetchMePhoneRulesRequest());
  } catch (err) {
    yield put(toggleMeEnabledPhoneRuleFailure(err));
    yield put(fetchMePhoneRulesRequest());
  }
}

export function* saveMePhoneRule(action) {
  try {
    const res = yield call(
      PbxManager.saveUserSetting.bind(PbxManager, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res.message;
    }
    yield put(saveMePhoneRuleSuccess());
    yield put(fetchMePhoneRulesRequest());
    if (action.route) {
      yield put(push(action.route));
    }
  } catch (err) {
    yield put(saveMePhoneRuleFailure(err));
  }
}

export function* fetchPbxPhoneRules(action) {
  let params = null;
  let supplier = yield select(getSupplier);
  while (!supplier) {
    yield take();
    supplier = yield select(getSupplier);
  }
  if (supplier === PhoneEnums.PbxType.ABILIS) {
    let pbxs = yield select(getPbxs);
    while (!pbxs) {
      yield take();
      pbxs = yield select(getPbxs);
    }
    let selectedPbx = yield select(getSelectedPbxForRuleManagement);
    while (!selectedPbx) {
      yield take();
      selectedPbx = yield select(getSelectedPbxForRuleManagement);
    }
    for (let i = 0; i < pbxs.length; i += 1) {
      if (pbxs[i].id === selectedPbx) {
        params = pbxs[i];
        break;
      }
    }
    if (!params) {
      yield put(fetchPbxPhoneRulesFailure());
      return;
    }
  } else if (supplier === PhoneEnums.PbxType.NETHESIS) {
    params = action.payload;
  }

  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const myId = yield select(getMeId);
    const res = yield call(
      PbxManager.retrievePbxSettings.bind(PbxManager, params)
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(
      fetchPbxPhoneRulesSuccess(res.rules.filter((r) => r.user !== myId))
    );
  } catch (err) {
    yield put(fetchPbxPhoneRulesFailure(err));
  }
}

export function* savePbxPhoneRule(action) {
  const supplier = yield select(getSupplier);
  let params = null;
  let selectedPbx = null;
  if (supplier === PhoneEnums.PbxType.ABILIS) {
    selectedPbx = yield select(getSelectedPbxForRuleManagement);
    const pbxs = yield select(getPbxs);
    for (let i = 0; i < pbxs.length; i += 1) {
      if (pbxs[i].id === selectedPbx) {
        params = pbxs[i];
        break;
      }
    }
    if (!params) {
      yield put(savePbxPhoneRuleFailure());
      return;
    }
  } else if (supplier === PhoneEnums.PbxType.NETHESIS) {
    params = action.payload;
  }

  try {
    const res = yield call(
      PbxManager.savePbxSetting.bind(PbxManager, params, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res.message;
    }
    yield put(savePbxPhoneRuleSuccess(selectedPbx));
    yield put(push('/pbx/settings/switchboard'));
  } catch (err) {
    yield put(savePbxPhoneRuleFailure(err));
  }
}

export function* deletePbxPhoneRule(action) {
  const supplier = yield select(getSupplier);
  let params = null;
  let selectedPbx = null;
  if (supplier === PhoneEnums.PbxType.ABILIS) {
    selectedPbx = yield select(getSelectedPbxForRuleManagement);
    const pbxs = yield select(getPbxs);
    for (let i = 0; i < pbxs.length; i += 1) {
      if (pbxs[i].id === selectedPbx) {
        params = pbxs[i];
        break;
      }
    }
    if (!params) {
      yield put(deletePbxPhoneRuleFailure());
      return;
    }
  } else if (supplier === PhoneEnums.PbxType.NETHESIS) {
    params = action.payload;
  }
  try {
    const res = yield call(
      PbxManager.deletePbxSetting.bind(PbxManager, params, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(deletePbxPhoneRuleSuccess(selectedPbx));
    yield put(fetchPbxPhoneRulesRequest({ pbxId: selectedPbx }));
  } catch (err) {
    yield put(deletePbxPhoneRuleFailure(err));
  }
}

export function* togglePbxEnabledPhoneRule(action) {
  const supplier = yield select(getSupplier);
  let params = null;
  let selectedPbx = null;
  if (supplier === PhoneEnums.PbxType.ABILIS) {
    selectedPbx = yield select(getSelectedPbxForRuleManagement);
    const pbxs = yield select(getPbxs);
    for (let i = 0; i < pbxs.length; i += 1) {
      if (pbxs[i].id === selectedPbx) {
        params = pbxs[i];
        break;
      }
    }
    if (!params) {
      yield put(togglePbxEnabledPhoneRuleFailure(selectedPbx));
      return;
    }
  } else if (supplier === PhoneEnums.PbxType.NETHESIS) {
    params = action.payload;
  }
  try {
    const res = yield call(
      PbxManager.savePbxSetting.bind(PbxManager, params, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    yield put(togglePbxEnabledPhoneRuleSuccess());
    yield put(fetchPbxPhoneRulesRequest({ pbxId: selectedPbx }));
  } catch (err) {
    yield put(togglePbxEnabledPhoneRuleFailure(selectedPbx));
  }
}

export function* saveUserPhoneRule(action) {
  try {
    const res = yield call(
      PbxManager.saveUserSetting.bind(PbxManager, action.rule)
    );
    if (res === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res.message;
    }
    yield put(saveUserPhoneRuleSuccess());
    yield put(push('/pbx/settings/users'));
  } catch (err) {
    yield put(saveUserPhoneRuleFailure(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_ME_PHONERULES_REQUEST, fetchMePhoneRules);
  yield takeLatest(DELETE_ME_PHONERULE_REQUEST, deleteMePhoneRule);
  yield takeLatest(
    TOGGLE_ME_ENABLED_PHONERULE_REQUEST,
    toggleMeEnabledPhoneRule
  );
  yield takeLatest(SAVE_ME_PHONERULE_REQUEST, saveMePhoneRule);
  yield takeLatest(FETCH_PBX_PHONERULES_REQUEST, fetchPbxPhoneRules);
  yield takeLatest(DELETE_PBX_PHONERULE_REQUEST, deletePbxPhoneRule);
  yield takeLatest(SAVE_PBX_PHONERULE_REQUEST, savePbxPhoneRule);
  yield takeLatest(
    TOGGLE_PBX_ENABLED_PHONERULE_REQUEST,
    togglePbxEnabledPhoneRule
  );
  yield takeLatest(SAVE_USER_PHONERULE_REQUEST, saveUserPhoneRule);
}
