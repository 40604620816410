import React from 'react';
import JitsiVideocalls from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const JitsiVideocallNotifications = React.lazy(() =>
  import('./JitsiVideocallNotifications')
);

const JitsiVideocallNotificationsPage = (props) => (
  <JitsiVideocalls scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-videocall" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <JitsiVideocallNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </JitsiVideocalls>
);

export default JitsiVideocallNotificationsPage;
