import { request } from '../request';

export default {
  getEvents: (params) =>
    request({
      url: '/calendar/events',
      method: 'GET',
      params,
    }),
  getEvent: (eventId) =>
    request({
      url: `/calendar/events/${eventId}`,
      method: 'GET',
    }),
  createEvent: (event) =>
    request({
      url: '/calendar/events',
      method: 'POST',
      data: event,
    }),
  updateEvent: (event) =>
    request({
      url: `/calendar/events/${event.id}`,
      method: 'POST',
      data: event,
    }),
  deleteEvent: (eventId) =>
    request({
      url: `/calendar/events/${eventId}`,
      method: 'DELETE',
    }),
  deleteOccurrence: (params) =>
    request({
      url: `/calendar/delete-occurrence`,
      method: 'POST',
      data: params,
    }),
  getSettings: () =>
    request({
      url: '/calendar/settings',
      method: 'GET',
    }),
  updateSettings: (settings) =>
    request({
      url: `/calendar/settings`,
      method: 'POST',
      data: settings,
    }),
  importCalendarEvents: (events) =>
    request({
      url: '/calendar/import',
      method: 'POST',
      data: events,
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  cancelImportCalendarEvents: () =>
    request({
      url: '/calendar/import',
      method: 'DELETE',
    }),
  fetchCalendarSync: () =>
    request({
      url: '/calendar/syncout',
      method: 'GET',
    }),
  saveCalendarSync: (data) =>
    request({
      url: '/calendar/syncout',
      method: 'POST',
      data,
    }),
  deleteCalendarSync: (id) =>
    request({
      url: `/calendar/syncout/${id}`,
      method: 'DELETE',
    }),
  enableCalendarSync: (enable) =>
    request({
      url: `/calendar/syncout/${enable.id}`,
      method: 'POST',
      data: enable,
    }),
  fetchCalendarSyncin: () =>
    request({
      url: '/calendar/syncin',
      method: 'GET',
    }),
  saveCalendarSyncin: (data) =>
    request({
      url: '/calendar/syncin',
      method: 'POST',
      data,
    }),
  deleteCalendarSyncin: (id) =>
    request({
      url: `/calendar/syncin/${id}`,
      method: 'DELETE',
    }),
  toggleCalendarSyncin: (data) =>
    request({
      url: `/calendar/syncin/${data.syncId}`,
      method: 'POST',
      data: {
        enabled: data.enabled,
      },
    }),
  fetchCalendarSlots: (params) =>
    request({
      url: '/calendar/slots',
      method: 'GET',
      params,
    }),
  fetchVacationPlan: (params) =>
    request({
      url: '/calendar/vacation-plan',
      method: 'GET',
      params,
    }),
  confirmVacation: (params) =>
    request({
      url: `/calendar/events/${params.id}/confirmation`,
      method: 'POST',
      data: {
        approved: true,
        deletingRelated: false,
        emailForward: params.emailForward,
      },
    }),
  confirmDeletingVacation: (params) =>
    request({
      url: `/calendar/events/${params.id}/confirmation`,
      method: 'POST',
      data: {
        approved: true,
        deletingRelated: true,
        emailForward: params.emailForward,
      },
    }),
  rejectVacation: (params) =>
    request({
      url: `/calendar/events/${params.id}/confirmation`,
      method: 'POST',
      data: {
        approved: false,
        deletingRelated: false,
        notes: params.notes,
        emailForward: params.emailForward,
      },
    }),
  rejectDeletingVacation: (params) =>
    request({
      url: `/calendar/events/${params.id}/confirmation`,
      method: 'POST',
      data: {
        approved: false,
        deletingRelated: true,
        notes: params.notes,
        emailForward: params.emailForward,
      },
    }),
  getVacationsToValidate: (params) =>
    request({
      url: '/calendar/vacations-to-validate',
      method: 'GET',
      params,
    }),
  getListOfEmails: () =>
    request({
      url: '/calendar/suggestions',
      method: 'GET',
    }),
  getFestivitiesSettings: () =>
    request({
      url: '/calendar/festivities',
      method: 'GET',
    }),
  updateFestivitiesSettings: (params) =>
    request({
      url: `/calendar/festivities`,
      method: 'POST',
      data: params,
    }),
  getVacationResume: (params) =>
    request({
      url: '/calendar/vacation-resume',
      method: 'GET',
      params,
    }),
  getAppointmentResults: (params) =>
    request({
      url: '/calendar/appointments/outcomes',
      method: 'GET',
      params,
    }),
  deleteAppointmentResult: (id) =>
    request({
      url: `/calendar/appointments/outcomes/${id}`,
      method: 'DELETE',
    }),
  insertAppointmentResult: (params) =>
    request({
      url: `/calendar/appointments/outcomes`,
      method: 'POST',
      data: params,
    }),
  editAppointmentResult: (params) =>
    request({
      url: `/calendar/appointments/outcomes/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  getAppointmentTypes: () =>
    request({
      url: '/calendar/appointments/types',
      method: 'GET',
    }),
  deleteAppointmentType: (id) =>
    request({
      url: `/calendar/appointments/types/${id}`,
      method: 'DELETE',
    }),
  insertAppointmentType: (params) =>
    request({
      url: `/calendar/appointments/types`,
      method: 'POST',
      data: params,
    }),
  editAppointmentType: (params) =>
    request({
      url: `/calendar/appointments/types/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  getDashboardHeader: (appointmentType) =>
    request({
      url: `/calendar/appointments/dashboard-header`,
      method: 'GET',
      params: {
        type: appointmentType,
      },
    }),
  getDashboardGraphStatistics: (params) =>
    request({
      url: `/calendar/appointments/dashboard-graph-statistics`,
      method: 'GET',
      params,
    }),
  getDashboardUsersStatistics: (appointmentType) =>
    request({
      url: `/calendar/appointments/dashboard-users-statistics`,
      method: 'GET',
      params: {
        type: appointmentType,
      },
    }),
  getAppointmentsListShortcut: () =>
    request({
      url: `/calendar/appointments/list-header`,
      method: 'GET',
    }),
  getAppointments: (params) =>
    request({
      url: `/calendar/appointments`,
      method: 'GET',
      params,
    }),
  createAppointment: (appointment) =>
    request({
      url: '/calendar/appointments',
      method: 'POST',
      data: appointment,
    }),
  updateAppointment: (appointment) =>
    request({
      url: `/calendar/appointments/${appointment.id}`,
      method: 'POST',
      data: appointment,
    }),
  getAppointment: (appointmentId) =>
    request({
      url: `/calendar/appointments/${appointmentId}`,
      method: 'GET',
    }),
  deleteAppointment: (appointmentId) =>
    request({
      url: `/calendar/appointments/${appointmentId}`,
      method: 'DELETE',
    }),
  registerAppointment: (appointmentId, data) =>
    request({
      url: `/calendar/appointments/${appointmentId}/register`,
      method: 'POST',
      data,
    }),
};
