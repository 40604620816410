import React, { Component } from 'react';
import PropTypes from 'prop-types';

const defaultStyle = {
  fontSize: '1em',
  fontWeight: 'bold',
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '0 .25em',
  border: 0,
  boxShadow: 'none',
  marginBottom: '0.2rem',
  display: 'inline',
  verticalAlign: 'unset',
};

const style = {
  normal: {
    ...defaultStyle,
    color: 'var(--yn-blue)',
  },
  hover: {
    ...defaultStyle,
    color: 'var(--yn-blue-darker)',
  },
};

class LinkButton extends Component {
  state = {
    hover: false,
  };

  click = () => {
    this.props.onClick();
  };

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <span
        className={`btn btn-link ${this.props.className || ''}`}
        style={this.state.hover ? style.hover : style.normal}
        onClick={this.click}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
      >
        {this.props.children}
      </span>
    );
  }
}

LinkButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default LinkButton;
