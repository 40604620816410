import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Chat from './index';

const ScheduledMessagesList = React.lazy(() =>
  import('./ScheduledMessagesList')
);

const ScheduledMessagesPage = () => {
  return (
    <Chat>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <ScheduledMessagesList />
        </React.Suspense>
      </ErrorBoundary>
    </Chat>
  );
};

export default ScheduledMessagesPage;
