import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import {
  getExtensionsLoaded,
  isPhoneLogged,
  isRecordCallAvailable,
} from '../phone/selectors';
import { PhoneEnums } from '../phone/PhoneUtils';
import {
  fetchMeCallsSuccess,
  fetchMeCallsFailure,
  fetchPbxCallsSuccess,
  fetchPbxCallsFailure,
  deleteRecordedCallSuccess,
  deleteRecordedCallFailure,
  deleteOwnCallSuccess,
  deleteOwnCallFailure,
  deleteOtherCallSuccess,
  deleteOtherCallFailure,
  insertCallsNotesSuccess,
  insertCallsNotesFailure,
  fetchMeCallsRequest,
  fetchQueuesCallsSuccess,
  fetchQueuesCallsFailure,
} from './actions';
import api from '../api';
import {
  FETCH_ME_CALLS_REQUEST,
  FETCH_PBX_CALLS_REQUEST,
  DELETE_RECORDED_CALL_REQUEST,
  DELETE_OWN_CALL_REQUEST,
  DELETE_OTHER_CALL_REQUEST,
  INSERT_CALLS_NOTES_REQUEST,
  REFRESH_ME_CALLS,
  FETCH_QUEUES_CALLS_REQUEST,
} from './types';
import { getInterlocutorData } from '../phone/sagas';
import { PbxManager } from '../phone/PbxManager';
import { NotificationEnums } from '../notifications/NotificationUtils';
import { DesktopNotificationManager } from '../notifications/DesktopNotificationManager';
import { getUserFromMainExtensionUsername } from '../users/selectors';
import { checkApiResponse, checkApiError } from '../rootSaga';
import { getCustomerId, getMeId } from '../me/selectors';
import history from '../../history';
import {
  getMeCallsInLogsFilter,
  getMeCallsLostLogsFilter,
  getMeCallsOutLogsFilter,
} from './selectors';

export function* fetchMeCalls(action) {
  DesktopNotificationManager.hideNotificationsForGroup(
    NotificationEnums.NotificationGroups.LOST_CALLS
  );
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const recordingCallsGrant = yield select(isRecordCallAvailable);
    const customerId = yield select(getCustomerId);
    const respDeletedCalls = yield call(api.cdr.retrieveOwnDeletedCalls);
    yield call(checkApiResponse, respDeletedCalls);
    const respCallsNotes = yield call(api.cdr.retrieveCallsNotes);
    yield call(checkApiResponse, respCallsNotes);
    const res = yield call(
      PbxManager.retrieveMeCdr.bind(PbxManager, action.params, {
        recording: recordingCallsGrant,
        deletedCalls: respDeletedCalls.data,
        callsNotes: respCallsNotes.data,
        customerId,
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    // wait user extensions are loaded to retrieve interlocutor data
    let usersExtensionsLoaded = yield select(getExtensionsLoaded);

    while (!usersExtensionsLoaded) {
      yield take();
      usersExtensionsLoaded = yield select(getExtensionsLoaded);
    }
    // retrieve interlocutor for each call (in series)
    const calls = res.data.map((callData) =>
      call(
        getInterlocutorData,
        callData.type === 'out' ? callData.called : callData.calling,
        false
      )
    );

    for (let i = 0; i < calls.length; i += 1) {
      const response = yield calls[i];
      res.data[i].interlocutor = response;
    }
    yield put(fetchMeCallsSuccess(res.data));
  } catch (err) {
    yield put(fetchMeCallsFailure(err));
  }
}

export function* fetchPbxCalls(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const searchingUsers = [];
    if (action.params.extensions) {
      for (let i = 0; i < action.params.extensions.length; i += 1) {
        const foundUsers = yield select(
          getUserFromMainExtensionUsername,
          action.params.extensions[i]
        );
        if (foundUsers && foundUsers.length > 0) {
          const searchingUser = {
            username: action.params.extensions[i],
            number: foundUsers[0].mainExtensionNumber,
          };
          searchingUsers.push(searchingUser);
        }
      }
    }
    const respDeletedCalls = yield call(api.cdr.retrieveOtherDeletedCalls);
    yield call(checkApiResponse, respDeletedCalls);
    const respCallsNotes = yield call(api.cdr.retrieveCallsNotes);
    const customerId = yield select(getCustomerId);
    const res = yield call(
      PbxManager.retrieveOthersCdr.bind(
        PbxManager,
        {
          ...action.params,
          searchingUsers,
        },
        {
          deletedCalls: respDeletedCalls.data,
          callsNotes: respCallsNotes.data,
          customerId
        }
      )
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    // wait user extensions are loaded to retrieve interlocutor data
    let usersExtensionsLoaded = yield select(getExtensionsLoaded);

    while (!usersExtensionsLoaded) {
      yield take();
      usersExtensionsLoaded = yield select(getExtensionsLoaded);
    }
    for (let i = 0; i < res.data.length; i += 1) {
      const responseCalled = yield call(
        getInterlocutorData,
        res.data[i].called,
        false
      );
      const responseCalling = yield call(
        getInterlocutorData,
        res.data[i].calling,
        false
      );
      res.data[i].calledInterlocutor = responseCalled;
      res.data[i].callingInterlocutor = responseCalling;
      if (responseCalling.id) {
        delete res.data[i].callingCode;
      }
    }
    yield put(fetchPbxCallsSuccess(res));
  } catch (err) {
    yield put(fetchPbxCallsFailure(err));
  }
}

export function* deleteRecordedCall(action) {
  try {
    const res = yield call(api.cdr.deleteRecordedCall, action.filename);
    yield call(checkApiResponse, res);

    yield put(deleteRecordedCallSuccess(action.filename));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteRecordedCallFailure(error));
  }
}

export function* deleteOwnCall(action) {
  try {
    const res = yield call(api.cdr.deleteOwnCall, action.callId);
    yield call(checkApiResponse, res);

    yield put(deleteOwnCallSuccess(action.callId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteOwnCallFailure(error));
  }
}

export function* deleteOtherCall(action) {
  try {
    const res = yield call(api.cdr.deleteOtherCall, action.callId);
    yield call(checkApiResponse, res);

    yield put(deleteOtherCallSuccess(action.callId));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteOtherCallFailure(error));
  }
}

export function* insertCallsNotes(action) {
  try {
    const res = yield call(api.cdr.insertCallsNotes, action.params);
    yield call(checkApiResponse, res);
    const meId = yield select(getMeId);
    yield put(
      insertCallsNotesSuccess(
        {
          ...action.params,
          user: meId,
        },
        action.context
      )
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(insertCallsNotesFailure(error));
  }
}

export function* refreshMeCalls() {
  const {
    location: { pathname },
  } = history;
  if (pathname.indexOf('/phone/log/') < -1) return;
  const type = pathname.split('/')[3];
  const filterDates = yield select(
    type === 'lost'
      ? getMeCallsLostLogsFilter
      : type === 'in'
      ? getMeCallsInLogsFilter
      : getMeCallsOutLogsFilter
  );
  const filter =
    type === 'lost'
      ? {
          ...filterDates,
          lost: true,
          in: false,
          out: false,
        }
      : type === 'in'
      ? {
          ...filterDates,
          lost: false,
          in: true,
          out: false,
        }
      : {
          ...filterDates,
          lost: false,
          in: false,
          out: true,
        };
  yield put(fetchMeCallsRequest(filter));
}

export function* fetchQueuesCalls(action) {
  try {
    let phoneStatus = yield select(isPhoneLogged);
    while (!phoneStatus) {
      yield take();
      phoneStatus = yield select(isPhoneLogged);
    }
    const respCallsNotes = yield call(api.cdr.retrieveCallsNotes);
    yield call(checkApiResponse, respCallsNotes);
    const res = yield call(
      PbxManager.retrieveQueuesCdr.bind(PbxManager, action.params, {
        callsNotes: respCallsNotes.data,
      })
    );
    if (res.status === PhoneEnums.CallsActionsResults.ko) {
      throw res;
    }
    // wait user extensions are loaded to retrieve interlocutor data
    let usersExtensionsLoaded = yield select(getExtensionsLoaded);

    while (!usersExtensionsLoaded) {
      yield take();
      usersExtensionsLoaded = yield select(getExtensionsLoaded);
    }
    for (let i = 0; i < res.data.length; i += 1) {
      const responseCalled = yield call(
        getInterlocutorData,
        res.data[i].called,
        false
      );
      const responseCalling = yield call(
        getInterlocutorData,
        res.data[i].calling,
        false
      );
      res.data[i].calledInterlocutor = responseCalled;
      res.data[i].callingInterlocutor = responseCalling;
      if (responseCalling.id) {
        delete res.data[i].callingCode;
      }
    }
    yield put(fetchQueuesCallsSuccess(res.data));
  } catch (err) {
    yield put(fetchQueuesCallsFailure(err));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_ME_CALLS_REQUEST, fetchMeCalls);
  yield takeLatest(FETCH_PBX_CALLS_REQUEST, fetchPbxCalls);
  yield takeLatest(DELETE_RECORDED_CALL_REQUEST, deleteRecordedCall);
  yield takeLatest(DELETE_OWN_CALL_REQUEST, deleteOwnCall);
  yield takeLatest(DELETE_OTHER_CALL_REQUEST, deleteOtherCall);
  yield takeLatest(INSERT_CALLS_NOTES_REQUEST, insertCallsNotes);
  yield takeLatest(REFRESH_ME_CALLS, refreshMeCalls);
  yield takeLatest(FETCH_QUEUES_CALLS_REQUEST, fetchQueuesCalls);
}
