export const FETCH_CONVERSATIONS_LIST_SUCCESS =
  'FETCH_CONVERSATIONS_LIST_SUCCESS';
export const FETCH_CONVERSATIONS_LIST_FAILURE =
  'FETCH_CONVERSATIONS_LIST_FAILURE';
export const SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAILURE = 'SEND_CHAT_MESSAGE_FAILURE';
export const SEND_CHAT_MESSAGE_CC = 'SEND_CHAT_MESSAGE_CC';
export const RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE';
export const SEND_DELIVERY_MESSAGE_SUCCESS = 'SEND_DELIVERY_MESSAGE_SUCCESS';
export const SEND_DELIVERY_MESSAGE_FAILURE = 'SEND_DELIVERY_MESSAGE_FAILURE';
export const SEND_DELIVERY_MESSAGE_CC = 'SEND_DELIVERY_MESSAGE_CC';
export const RECEIVE_DELIVERY_MESSAGE = 'RECEIVE_DELIVERY_MESSAGE';
export const SEND_READ_MESSAGE_REQUEST = 'SEND_READ_MESSAGE_REQUEST';
export const SEND_READ_MESSAGE_SUCCESS = 'SEND_READ_MESSAGE_SUCCESS';
export const SEND_READ_MESSAGE_FAILURE = 'SEND_READ_MESSAGE_FAILURE';
export const RECEIVE_READ_MESSAGE_CC = 'RECEIVE_READ_MESSAGE_CC';
export const RECEIVE_READ_MESSAGE = 'RECEIVE_READ_MESSAGE';
export const SEND_READ_ALL_MESSAGE_REQUEST = 'SEND_READ_ALL_MESSAGE_REQUEST';
export const SEND_READ_ALL_MESSAGE_SUCCESS = 'SEND_READ_ALL_MESSAGE_SUCCESS';
export const SEND_READ_ALL_MESSAGE_FAILURE = 'SEND_READ_ALL_MESSAGE_FAILURE';
export const SEND_READ_ALL_MESSAGE_CC = 'SEND_READ_ALL_MESSAGE_CC';
export const SEND_DELETE_MESSAGES_REQUEST = 'SEND_DELETE_MESSAGES_REQUEST';
export const SEND_DELETE_MESSAGES_SUCCESS = 'SEND_DELETE_MESSAGES_SUCCESS';
export const SEND_DELETE_MESSAGES_FAILURE = 'SEND_DELETE_MESSAGES_FAILURE';
export const SEND_DELETE_MESSAGES_CC = 'SEND_DELETE_MESSAGES_CC';
export const SEND_DELETE_ALL_MESSAGES_REQUEST =
  'SEND_DELETE_ALL_MESSAGES_REQUEST';
export const SEND_DELETE_ALL_MESSAGES_SUCCESS =
  'SEND_DELETE_ALL_MESSAGES_SUCCESS';
export const SEND_DELETE_ALL_MESSAGES_FAILURE =
  'SEND_DELETE_ALL_MESSAGES_FAILURE';
export const SEND_DELETE_ALL_MESSAGES_CC = 'SEND_DELETE_ALL_MESSAGES_CC';
export const SEND_ARCHIVE_CONVERSATION_REQUEST =
  'SEND_ARCHIVE_CONVERSATION_REQUEST';
export const SEND_ARCHIVE_CONVERSATION_SUCCESS =
  'SEND_ARCHIVE_CONVERSATION_SUCCESS';
export const SEND_ARCHIVE_CONVERSATION_FAILURE =
  'SEND_ARCHIVE_CONVERSATION_FAILURE';
export const SEND_ARCHIVE_CONVERSATION_CC = 'SEND_ARCHIVE_CONVERSATION_CC';
export const SEND_UNARCHIVE_CONVERSATION_REQUEST =
  'SEND_UNARCHIVE_CONVERSATION_REQUEST';
export const SEND_UNARCHIVE_CONVERSATION_SUCCESS =
  'SEND_UNARCHIVE_CONVERSATION_SUCCESS';
export const SEND_UNARCHIVE_CONVERSATION_FAILURE =
  'SEND_UNARCHIVE_CONVERSATION_FAILURE';
export const SEND_UNARCHIVE_CONVERSATION_CC = 'SEND_UNARCHIVE_CONVERSATION_CC';
export const OPEN_CHAT_CONVERSATION = 'OPEN_CHAT_CONVERSATION';
export const MINIMIZE_CHAT_CONVERSATION = 'MINIMIZE_CHAT_CONVERSATION';
export const NORMALIZE_CHAT_CONVERSATION = 'NORMALIZE_CHAT_CONVERSATION';
export const MAXIMIZE_CHAT_CONVERSATION = 'MAXIMIZE_CHAT_CONVERSATION';
export const CLOSE_CHAT_CONVERSATION = 'CLOSE_CHAT_CONVERSATION';
export const FETCH_CONVERSATION_HISTORY_REQUEST =
  'FETCH_CONVERSATION_HISTORY_REQUEST';
export const FETCH_CONVERSATION_HISTORY_SUCCESS =
  'FETCH_CONVERSATION_HISTORY_SUCCESS';
export const FETCH_CONVERSATION_HISTORY_FAILURE =
  'FETCH_CONVERSATION_HISTORY_FAILURE';
export const SET_MAX_OPEN_CHAT_WINDOWS = 'SET_MAX_OPEN_CHAT_WINDOWS';
export const SET_CONVERSATIONS_LIST_FILTER = 'SET_CONVERSATIONS_LIST_FILTER';
export const SEND_COMPOSING_STATUS = 'SEND_COMPOSING_STATUS';
export const SEND_PAUSED_STATUS = 'SEND_PAUSED_STATUS';
export const RECEIVE_COMPOSING_STATUS = 'RECEIVE_COMPOSING_STATUS';
export const RECEIVE_PAUSED_STATUS = 'RECEIVE_PAUSED_STATUS';
export const FETCH_CONVERSATION_FILES_REQUEST =
  'FETCH_CONVERSATION_FILES_REQUEST';
export const FETCH_CONVERSATION_FILES_SUCCESS =
  'FETCH_CONVERSATION_FILES_SUCCESS';
export const FETCH_CONVERSATION_FILES_FAILURE =
  'FETCH_CONVERSATION_FILES_FAILURE';
export const SET_ACTIVE_CHAT_WINDOW = 'SET_ACTIVE_CHAT_WINDOW';
export const UNSET_ACTIVE_CHAT_WINDOW = 'UNSET_ACTIVE_CHAT_WINDOW';
export const CHANGE_CONVERSATION_FILES_PAGE_REQUEST =
  'CHANGE_CONVERSATION_FILES_PAGE_REQUEST';
export const REMOVE_TIMED_MESSAGE_SUCCESS = 'REMOVE_TIMED_MESSAGE_SUCCESS';
export const READ_SELF_TIMED_MESSAGE = 'READ_SELF_TIMED_MESSAGE';
export const REFRESH_CONVERSATION_SUCCESS = 'REFRESH_CONVERSATION_SUCCESS';
export const REFRESH_CONVERSATION_FAILURE = 'REFRESH_CONVERSATION_FAILURE';
export const RECEIVE_CHAT_INFO_MESSAGE = 'RECEIVE_CHAT_INFO_MESSAGE';
export const FETCH_CHAT_SEARCH_SUCCESS = 'FETCH_CHAT_SEARCH_SUCCESS';
export const FETCH_CHAT_SEARCH_FAILURE = 'FETCH_CHAT_SEARCH_FAILURE';
export const FETCH_OTHER_CHAT_RESULTS_REQUEST =
  'FETCH_OTHER_CHAT_RESULTS_REQUEST';
export const FETCH_OTHER_CHAT_RESULTS_SUCCESS =
  'FETCH_OTHER_CHAT_RESULTS_SUCCESS';
export const FETCH_OTHER_CHAT_RESULTS_FAILURE =
  'FETCH_OTHER_CHAT_RESULTS_FAILURE';
export const OPEN_CHAT_SEARCH_RESULT_REQUEST =
  'OPEN_CHAT_SEARCH_RESULT_REQUEST';
export const OPEN_CHAT_SEARCH_RESULT_SUCCESS =
  'OPEN_CHAT_SEARCH_RESULT_SUCCESS';
export const OPEN_CHAT_SEARCH_RESULT_FAILURE =
  'OPEN_CHAT_SEARCH_RESULT_FAILURE';
export const SET_CHAT_SEARCH_RESULT = 'SET_CHAT_SEARCH_RESULT';
export const UNSET_CHAT_SEARCH_RESULT = 'UNSET_CHAT_SEARCH_RESULT';
export const SEND_CHAT_FILE_PREVIEW = 'SEND_CHAT_FILE_PREVIEW';
export const SEND_CHAT_FILE_REQUEST = 'SEND_CHAT_FILE_REQUEST';
export const FETCH_IMPORTANT_MESSAGES_REQUEST =
  'FETCH_IMPORTANT_MESSAGES_REQUEST';
export const FETCH_IMPORTANT_MESSAGES_SUCCESS =
  'FETCH_IMPORTANT_MESSAGES_SUCCESS';
export const FETCH_IMPORTANT_MESSAGES_FAILURE =
  'FETCH_IMPORTANT_MESSAGES_FAILURE';

export const MARK_IMPORTANT_MESSAGE_REQUEST = 'MARK_IMPORTANT_MESSAGE_REQUEST';
export const MARK_IMPORTANT_MESSAGE_CC = 'MARK_IMPORTANT_MESSAGE_CC';
export const MARK_IMPORTANT_MESSAGE_SUCCESS = 'MARK_IMPORTANT_MESSAGE_SUCCESS';
export const MARK_IMPORTANT_MESSAGE_FAILURE = 'MARK_IMPORTANT_MESSAGE_FAILURE';

export const MARK_UNIMPORTANT_MESSAGE_REQUEST =
  'MARK_UNIMPORTANT_MESSAGE_REQUEST';
export const MARK_UNIMPORTANT_MESSAGE_CC = 'MARK_UNIMPORTANT_MESSAGE_CC';
export const MARK_UNIMPORTANT_MESSAGE_SUCCESS =
  'MARK_UNIMPORTANT_MESSAGE_SUCCESS';
export const MARK_UNIMPORTANT_MESSAGE_FAILURE =
  'MARK_UNIMPORTANT_MESSAGE_FAILURE';

export const REPLY_MESSAGE_REQUEST = 'REPLY_MESSAGE_REQUEST';
export const REMOVE_REPLY_MESSAGE_REQUEST = 'REMOVE_REPLY_MESSAGE_REQUEST';
export const FETCH_SCHEDULED_MESSAGES_REQUEST =
  'FETCH_SCHEDULED_MESSAGES_REQUEST';
export const FETCH_SCHEDULED_MESSAGES_SUCCESS =
  'FETCH_SCHEDULED_MESSAGES_SUCCESS';
export const FETCH_SCHEDULED_MESSAGES_FAILURE =
  'FETCH_SCHEDULED_MESSAGES_FAILURE';
export const SAVE_SCHEDULED_MESSAGE_REQUEST = 'SAVE_SCHEDULED_MESSAGE_REQUEST';
export const SAVE_SCHEDULED_MESSAGE_SUCCESS = 'SAVE_SCHEDULED_MESSAGE_SUCCESS';
export const SAVE_SCHEDULED_MESSAGE_FAILURE = 'SAVE_SCHEDULED_MESSAGE_FAILURE';
export const DELETE_SCHEDULED_MESSAGE_REQUEST =
  'DELETE_SCHEDULED_MESSAGE_REQUEST';
export const DELETE_SCHEDULED_MESSAGE_SUCCESS =
  'DELETE_SCHEDULED_MESSAGE_SUCCESS';
export const DELETE_SCHEDULED_MESSAGE_FAILURE =
  'DELETE_SCHEDULED_MESSAGE_FAILURE';

export const GET_MUTED_CHATS_REQUEST = 'GET_MUTED_CHATS_REQUEST';
export const GET_MUTED_CHATS_SUCCESS = 'GET_MUTED_CHATS_SUCCESS';
export const GET_MUTED_CHATS_FAILURE = 'GET_MUTED_CHATS_FAILURE';
export const MUTE_UNMUTE_CHAT_REQUEST = 'MUTE_UNMUTE_CHAT_REQUEST';
export const MUTE_UNMUTE_CHAT_SUCCESS = 'MUTE_UNMUTE_CHAT_SUCCESS';
export const MUTE_UNMUTE_CHAT_FAILURE = 'MUTE_UNMUTE_CHAT_FAILURE';
export const CLEAR_CHAT_ERROR = 'CLEAR_CHAT_ERROR';
