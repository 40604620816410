import * as React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { connect } from 'react-redux';
import anime from 'animejs/lib/anime.es.js';
import { injectIntl, defineMessages } from 'react-intl';
import { HeartShape, heartStyle } from './HeartShape';
import { BalloonShape, balloonStyle } from './BalloonShape';
import { CircleShape, circleStyle } from './CircleShape';
import { ClassicShape, classicStyle } from './ClassicShape';
import { StarShape, starStyle } from './StarShape';
import { TreeShape, treeStyle } from './TreeShape';
import StickyText from './StickyText';
import StickyMenu from './StickyMenu';
import DeleteSticky from './DeleteSticky';
import {
  deleteStickyNoteRequest,
  updateStickyRequest,
  shareStickyRequest,
} from '../../../js/sticky/actions';
import { getUsersById } from '../../../js/users/selectors';
import Utils from '../../../js/lib/utils';
import ShareSticky from './ShareSticky';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  shared: {
    id: 'StickyNote.label.shared',
    defaultMessage: 'Shared',
  },
  sharedMore: {
    id: 'StickyNote.label.sharedMore',
    defaultMessage: 'Shared with: {users} and others',
  },
});

const stickyDragSource = {
  beginDrag(props) {
    return {
      stickyNote: props.stickyNote,
    };
  },
};

const MAX_SHARED_USERS_IN_TOOLTIP = 5;

class StickyNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      textFocus: false,
      rendering: true,
      deleting: false,
      menuOpen: false,
    };
  }

  retrieveStyle = () => {
    switch (this.props.stickyNote.detail.shape) {
      case 'CIRCLE':
        return circleStyle;
      case 'HEART':
        return heartStyle;
      case 'STAR':
        return starStyle;
      case 'BALLOON':
        return balloonStyle;
      case 'CHRISTMASTREE':
        return treeStyle;
      case 'CLASSIC':
      default:
        return classicStyle;
    }
  };

  updateSticky = (data) => {
    this.props.updateSticky(this.props.stickyNote.id, data);
  };

  deleteSticky = () => {
    this.setState(
      {
        ...this.state,
        deleting: true,
      },
      () => {
        anime({
          targets: document.getElementById(
            `stickynote_${this.props.stickyNote.id}`
          ),
          top: '50%',
          position: 'fixed',
          translateX:
            window.innerWidth / 2 > this.props.stickyNote.detail.left
              ? -5000
              : window.innerWidth + 5000,
          duration: 1000,
          easing: 'linear',
          rotate: [360, 0],
          complete: () => {
            this.props.deleteSticky(this.props.stickyNote.id);
          },
        });
      }
    );
  };

  shareSticky = (users) => {
    const { stickyNote, shareSticky } = this.props;

    shareSticky(stickyNote.id, users);
  };

  onMouseLeave = () => {
    this.setState({
      active: false,
    });
  };

  onMouseOver = () => {
    this.setState({
      active: true,
    });
  };

  handleTextFocus = (focus) => {
    this.setState({
      textFocus: focus,
    });
  };

  handleMenuOpen = (open) => {
    this.setState({
      menuOpen: open,
    });
  };

  componentDidMount = () => {
    anime({
      targets: document.getElementById(
        `stickynote_${this.props.stickyNote.id}`
      ),
      scale: this.state.active || this.state.textFocus ? 1 : 0.75,
      rotate: [360, 0],
      duration: 1000,
      easing: 'linear',
      complete: () => {
        this.setState({
          ...this.state,
          rendering: false,
        });
      },
    });
  };

  render() {
    const {
      stickyNote,
      key,
      isDragging,
      connectDragSource,
      users,
      intl: { formatMessage },
    } = this.props;
    if (isDragging) {
      return null;
    }
    const sharedUsers =
      stickyNote &&
      stickyNote.sharingUsers &&
      stickyNote.sharingUsers.length > 0 &&
      stickyNote.sharingUsers
        .slice(0, MAX_SHARED_USERS_IN_TOOLTIP)
        .map(
          (idUser) => users && users[idUser] && users[idUser].departmentFullname
        );
    const sharedUsersTooltip =
      sharedUsers &&
      stickyNote.sharingUsers.length <= MAX_SHARED_USERS_IN_TOOLTIP
        ? `${formatMessage(messages.shared)} ${sharedUsers.join(', ')}`
        : formatMessage(messages.sharedMore, {
            users: sharedUsers && sharedUsers.join(', '),
          });
    const focus = this.state.active || this.state.textFocus;
    const style = this.retrieveStyle();
    let transform;
    if (this.state.rendering) {
      transform = 'scale(0)';
    } else if (this.state.deleting) {
      transform = null;
    } else if (focus) {
      transform = 'rotate(0)';
    } else {
      transform = `scale(0.75) rotate(${this.props.stickyNote.detail.rotation}deg)`;
    }
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const component = (
      <div
        id={`stickynote_${stickyNote.id}`}
        key={key}
        style={{
          left: `${
            parseInt(stickyNote.detail.left) < windowWidth - 250
              ? stickyNote.detail.left
              : windowWidth - 250
          }px`,
          top: `${
            parseInt(stickyNote.detail.top) < windowHeight - 250
              ? stickyNote.detail.top
              : windowHeight - 250
          }px`,
          width: style.width,
          height: style.height,
          // cursor: 'pointer',
          zIndex: this.state.active || this.state.textFocus ? '1001' : '1000',
          position: 'fixed',
          WebkitTransition:
            '-webkit-box-shadow 1.2s ease-in,visibility 1s,width 1s,height 1s,top 1s,left 1s,-webkit-transform .15s linear',
          MozTransition:
            '-moz-box-shadow 1.2s ease-in,visibility 1s,width 1s,height 1s,top 1s,left 1s,-moz-transform .15s linear',
          OTransition:
            '-o-box-shadow 1.2s ease-in,visibility 1s,width 1s,height 1s,top 1s,left 1s,-o-transform .15s linear',
          transition:
            'box-shadow 1.2s ease-in,visibility 1s,width 1s,height 1s,top 1s,left 1s,transform .15s linear',
          transform,
        }}
        onMouseLeave={() => this.onMouseLeave()}
        onMouseOver={() => this.onMouseOver()}
        //onClick={() => this.handleMenuOpen(false)}
      >
        {stickyNote.detail.shape === 'CLASSIC' && (
          <ClassicShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {stickyNote.detail.shape === 'CIRCLE' && (
          <CircleShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {stickyNote.detail.shape === 'HEART' && (
          <HeartShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {stickyNote.detail.shape === 'STAR' && (
          <StarShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {stickyNote.detail.shape === 'BALLOON' && (
          <BalloonShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {stickyNote.detail.shape === 'CHRISTMASTREE' && (
          <TreeShape id={stickyNote.id} color={stickyNote.detail.color} />
        )}
        {focus && (
          <StickyMenu
            stickyNote={stickyNote}
            updateSticky={this.updateSticky}
            menuDivStyle={style.menuStyle}
            changeMenuStyle={style.subMenuStyle}
            isOpen={this.state.menuOpen}
            onClose={() => this.handleMenuOpen(false)}
            onOpen={() => this.handleMenuOpen(true)}
            shared={stickyNote.sharingUsers}
          />
        )}
        {sharedUsers && (
          <div style={style.sharedStyle}>
            <Icon
              name="share"
              width={20}
              height={20}
              tooltipPosition="top"
              clickable={false}
            >
              {sharedUsersTooltip}
            </Icon>
          </div>
        )}
        <div onClick={() => this.handleMenuOpen(false)}>
          <StickyText
            stickyNote={stickyNote}
            handleFocus={this.handleTextFocus}
            updateSticky={this.updateSticky}
            textDivStyle={style.textDivStyle}
            maxFontSize={style.maxFontSize}
            rendering={this.state.rendering}
          />
          {focus && (
            <>
              {!stickyNote.referenceSticky && (
                <ShareSticky
                  shareSticky={this.shareSticky}
                  shareDivStyle={style.shareStyle}
                  sharingUsers={stickyNote.sharingUsers}
                />
              )}
              <DeleteSticky
                deleteSticky={this.deleteSticky}
                deleteDivStyle={style.deleteStyle}
              />
            </>
          )}
        </div>
      </div>
    );
    if (this.state.textFocus) {
      return component;
    }
    return connectDragSource(component);
  }
}

StickyNote.propTypes = {
  stickyNote: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    stickyShow: state.settings.stickyShow,
    users: getUsersById(state),
  };
}

export default DragSource(
  Utils.DragAndDropTypes.stickyNote,
  stickyDragSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })
)(
  injectIntl(
    connect(mapStateToProps, {
      deleteSticky: deleteStickyNoteRequest,
      updateSticky: updateStickyRequest,
      shareSticky: shareStickyRequest,
    })(StickyNote)
  )
);
