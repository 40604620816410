import {
  PUT_AVATAR_REQUEST,
  PUT_AVATAR_SUCCESS,
  PUT_AVATAR_FAILURE,
  RESET_AVATAR_STATUS,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_FAILURE,
  UPLOAD_FILES_SUCCESS,
  REMOVE_FILES_REQUEST,
  REMOVE_FILES_FAILURE,
  REMOVE_FILES_SUCCESS,
  COPY_EXISTING_FILES,
  UPLOAD_FILES_PROGRESS,
  STOP_UPLOAD_FILES,
  UPLOAD_FILES_CANCEL,
  UPLOAD_PROGRESS_FILES_REQUEST,
  RESET_TICKETS_FILES,
  IMPORT_FILE_TO_FILEBOX_REQUEST,
  IMPORT_FILE_TO_FILEBOX_SUCCESS,
  IMPORT_FILE_TO_FILEBOX_FAILURE,
  CLEAR_FILEBOX_ERRORS,
  RESET_FILEBOX_UPLOADED_FILES,
  RESET_STATIC_NOTES_UPLOADED_FILES,
} from './types';

export const putAvatarRequest = (avatar) => ({
  type: PUT_AVATAR_REQUEST,
  avatar,
});
export const putAvatarSuccess = (avatar) => ({
  type: PUT_AVATAR_SUCCESS,
  avatar,
});
export const putAvatarFailure = (errors) => ({
  type: PUT_AVATAR_FAILURE,
  errors,
});
export const uploadProgressFilesRequest = (payload) => ({
  type: UPLOAD_PROGRESS_FILES_REQUEST,
  payload,
});
export const uploadFilesRequest = (payload) => ({
  type: UPLOAD_FILES_REQUEST,
  payload,
});
export const uploadFilesProgress = (payload) => ({
  type: UPLOAD_FILES_PROGRESS,
  payload,
});
export const uploadFilesSuccess = (payload) => ({
  type: UPLOAD_FILES_SUCCESS,
  payload,
});
export const uploadFilesFailure = (payload) => ({
  type: UPLOAD_FILES_FAILURE,
  payload,
});
export const uploadFilesCancel = (payload) => ({
  type: UPLOAD_FILES_CANCEL,
  payload,
});
export const removeFilesRequest = (payload) => ({
  type: REMOVE_FILES_REQUEST,
  payload,
});
export const removeFilesSuccess = (payload) => ({
  type: REMOVE_FILES_SUCCESS,
  payload,
});
export const removeFilesFailure = (errors) => ({
  type: REMOVE_FILES_FAILURE,
  errors,
});
export const copyExistingFiles = (payload) => ({
  type: COPY_EXISTING_FILES,
  payload,
});
export const resetAvatarStatus = () => ({
  type: RESET_AVATAR_STATUS,
});
export const stopUploadFiles = (id) => ({
  type: STOP_UPLOAD_FILES,
  id,
});
export const resetTicketsFiles = () => ({
  type: RESET_TICKETS_FILES,
});
export const importFileToFileboxRequest = (payload) => ({
  type: IMPORT_FILE_TO_FILEBOX_REQUEST,
  payload,
});
export const importFileToFileboxSuccess = (payload) => ({
  type: IMPORT_FILE_TO_FILEBOX_SUCCESS,
  payload,
});
export const importFileToFileboxFailure = (errors) => ({
  type: IMPORT_FILE_TO_FILEBOX_FAILURE,
  errors,
});
export const clearFilesErrors = () => ({
  type: CLEAR_FILEBOX_ERRORS,
});
export const resetFileboxUploadedFiles = () => ({
  type: RESET_FILEBOX_UPLOADED_FILES,
});
export const resetStaticNotesUploadedFiles = () => ({
  type: RESET_STATIC_NOTES_UPLOADED_FILES,
});
