import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import SecondaryNav from '../../../components/SecondaryNav';
import {
  isMePunchingsValidator,
  isMeVacationsValidator,
} from '../../../js/me/selectors';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { getWebNotificationsValidationNumber } from '../../../js/notifications/selectors';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import { isVacationEnabled } from '../../../js/calendar/selectors';

const messages = defineMessages({
  title: {
    id: 'Validations.title',
    defaultMessage: 'Validations',
  },
  punchings: {
    id: 'Validations.punchings.navLabel',
    defaultMessage: 'Punchings',
  },
  vacations: {
    id: 'Validations.vacations.navLabel',
    defaultMessage: 'Vacations',
  },
  notifications: {
    id: 'Validations.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
});

class Validations extends Component {
  render() {
    const {
      intl: { formatMessage },
      children,
      notificationsQuantity,
      canManagePunchings,
      canManageVacations,
      scrolling,
    } = this.props;

    const nav = [];
    if (canManagePunchings) {
      nav.push({
        id: 'Validations.punchings.linkLabel',
        href: '/administration/validations/punchings',
        label: messages.punchings,
      });
    }
    if (canManageVacations) {
      nav.push({
        id: 'Validations.vacations.linkLabel',
        href: '/administration/validations/vacations',
        label: messages.vacations,
      });
    }
    nav.push({
      id: 'Validations.notifications.linkLabel',
      href: '/administration/validations/notifications',
      label: messages.notifications,
      notifications: notificationsQuantity,
    });
    if (!canManagePunchings && !canManageVacations)
      return <GlobalErrorPage code={403} />;
    return (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
        <SecondaryNav tabs={nav} />
        <div
          className={
            scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
          }
        >
          {children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsValidationNumber(state),
    canManagePunchings:
      isPuncherEnabled(state) && isMePunchingsValidator(state),
    canManageVacations:
      isVacationEnabled(state) && isMeVacationsValidator(state),
  };
}
export default injectIntl(connect(mapStateToProps)(Validations));
