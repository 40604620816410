import { request } from '../request';

export default {
  getUsers: (params) =>
    request({
      url: '/users',
      method: 'GET',
      params,
    }),
  getUser: (userId) =>
    request({
      url: `/users/${userId}`,
      method: 'GET',
    }),
  updateUser: (user) =>
    request({
      url: `/users/${user.id}`,
      method: 'POST',
      data: user,
    }),
  getUserVacationsData: (userId) =>
    request({
      url: `/users/${userId}/hr-vacations`,
      method: 'GET',
    }),
  updateUserVacationsData: (params) =>
    request({
      url: `/users/${params.userId}/hr-vacations`,
      method: 'POST',
      data: {
        validators: params.validators,
        toBeValidated: params.toBeValidated,
      },
    }),
  getUserPunchingsData: (userId) =>
    request({
      url: `/users/${userId}/hr-punchings`,
      method: 'GET',
    }),
  updateUserPunchingsData: (id, data) =>
    request({
      url: `/users/${id}/hr-punchings`,
      method: 'POST',
      data,
    }),
  getUserRegistryData: (userId) =>
    request({
      url: `/users/${userId}/hr-registry`,
      method: 'GET',
    }),
  updateUserRegistryData: (id, data) =>
    request({
      url: `/users/${id}/hr-registry`,
      method: 'POST',
      data,
    }),
  getUserGroups: (params) =>
    request({
      url: '/users/groups',
      method: 'GET',
      params,
    }),
};
