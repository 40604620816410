import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import BackButton from '../../../components/buttons/BackButton';
import HelpButton from '../../../components/HelpButton';

const CalendarAppointmentEdit = React.lazy(() =>
  import('./CalendarAppointmentEdit')
);

const CalendarAppointmentEditPage = (props) => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="row mb-3">
          <div className="col text-left">
            <BackButton />
          </div>
          <div className="col text-right">
            <HelpButton fileName="appointment-edit" />
          </div>
        </div>
        <CalendarAppointmentEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarAppointmentEditPage;
