import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  SET_PUNCHINGS_FILTER,
  FETCH_PUNCHINGS_REQUEST,
  FETCH_PUNCHINGS_FAILURE,
  FETCH_PUNCHINGS_SUCCESS,
  SAVE_PUNCHING_REQUEST,
  SAVE_PUNCHING_SUCCESS,
  SAVE_PUNCHING_FAILURE,
  FETCH_PRESENCE_SETTINGS_REQUEST,
  FETCH_PRESENCE_SETTINGS_SUCCESS,
  FETCH_PRESENCE_SETTINGS_FAILURE,
  FETCH_PUNCHING_DETAILS_REQUEST,
  FETCH_PUNCHING_DETAILS_SUCCESS,
  FETCH_PUNCHING_DETAILS_FAILURE,
  SET_PUNCHINGS_TO_VALIDATE_FILTER,
  FETCH_PUNCHINGS_TO_VALIDATE_REQUEST,
  FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS,
  FETCH_PUNCHINGS_TO_VALIDATE_FAILURE,
  VALIDATE_PUNCHING_REQUEST,
  VALIDATE_PUNCHING_SUCCESS,
  VALIDATE_PUNCHING_FAILURE,
  INSERT_PUNCHING_NOTIFICATION,
  CONFIRM_PUNCHING_NOTIFICATION,
  REJECT_PUNCHING_NOTIFICATION,
  DELETE_OTHER_PUNCHING_REQUEST,
  DELETE_OTHER_PUNCHING_SUCCESS,
  DELETE_OTHER_PUNCHING_FAILURE,
  EDIT_OTHER_PUNCHING_REQUEST,
  EDIT_OTHER_PUNCHING_SUCCESS,
  EDIT_OTHER_PUNCHING_FAILURE,
} from './types';

const initialstate = {
  timeDifference: undefined,
  lastPunchingIsIn: undefined,
  fetchSettingsLoaded: true,
  fetchSettingsError: null,
  punchingsChanged: false,
  fetchPunchingsLoaded: true,
  fetchPunchingsError: null,
  savePunchingLoaded: true,
  savePunchingError: null,
  fetchPunchingDetailsLoaded: true,
  fetchPunchingDetailsError: null,
  currentPage: 0,
  totalPunchings: 0,
  filter: {},
  allById: {},
  allIds: [],
  punchingValidationsFilter: {},
  punchingsToValidateChanged: false,
  fetchPunchingsToValidateLoaded: true,
  fetchPunchingsToValidateError: null,
  punchingsToValidateCurrentPage: 0,
  punchingsToValidatePages: {},
  punchingsToValidateTotal: 0,
  punchingsToValidateById: {},
  validatePunchingLoading: false,
  validatePunchingError: null,
  deletePunchingLoading: null,
  deletePunchingError: null,
  editPunchingLoading: false,
  editPunchingError: null,
};

export default function puncher(state = initialstate, action = {}) {
  switch (action.type) {
    case FETCH_PRESENCE_SETTINGS_REQUEST:
      return {
        ...state,
        timeDifference: 0,
        lastPunchingIsIn: true,
        fetchSettingsLoaded: false,
        fetchSettingsError: null,
      };
    case FETCH_PRESENCE_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchSettingsLoaded: true,
        fetchSettingsError: null,
        timeDifference: new Date().getTime() - action.settings.serverTime,
        lastPunchingIsIn: action.settings.lastPunchingIsIn,
      };
    case FETCH_PRESENCE_SETTINGS_FAILURE:
      return {
        ...state,
        timeDifference: 0,
        lastPunchingIsIn: true,
        fetchSettingsLoaded: true,
        fetchSettingsError: action.errors,
      };
    case SET_PUNCHINGS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case FETCH_PUNCHINGS_REQUEST:
      return {
        ...state,
        punchingsChanged: false,
        fetchPunchingsLoaded: false,
        fetchPunchingsError: null,
      };
    case FETCH_PUNCHINGS_SUCCESS: {
      return {
        ...state,
        fetchPunchingsLoaded: true,
        fetchPunchingsError: null,
        allById: keyBy(action.payload.punchings, (punching) => punching.id),
        allIds: action.payload.punchings.map((punching) => punching.id),
        totalPunchings: action.payload.total,
        currentPage: action.payload.page,
      };
    }
    case FETCH_PUNCHINGS_FAILURE:
      return {
        ...state,
        fetchPunchingsLoaded: true,
        fetchPunchingsError: action.errors,
      };
    case SAVE_PUNCHING_REQUEST:
      return {
        ...state,
        savePunchingLoaded: false,
        savePunchingError: null,
      };
    case SAVE_PUNCHING_SUCCESS:
      return {
        ...state,
        savePunchingLoaded: true,
        savePunchingError: null,
      };
    case SAVE_PUNCHING_FAILURE:
      return {
        ...state,
        savePunchingLoaded: true,
        savePunchingError: action.errors,
      };
    case FETCH_PUNCHING_DETAILS_REQUEST:
      return {
        ...state,
        fetchPunchingDetailsLoaded: false,
        fetchPunchingDetailsError: null,
      };
    case FETCH_PUNCHING_DETAILS_SUCCESS: {
      return {
        ...state,
        fetchPunchingDetailsLoaded: true,
        fetchPunchingDetailsError: null,
        allById: {
          ...state.allById,
          [action.payload.id]: {
            ...state.allById[action.payload.id],
            ...action.payload,
          },
        },
      };
    }
    case FETCH_PUNCHING_DETAILS_FAILURE:
      return {
        ...state,
        fetchPunchingDetailsLoaded: true,
        fetchPunchingDetailsError: action.errors,
      };
    case SET_PUNCHINGS_TO_VALIDATE_FILTER:
      return {
        ...state,
        punchingValidationsFilter: action.payload,
      };
    case FETCH_PUNCHINGS_TO_VALIDATE_REQUEST:
      return {
        ...state,
        punchingsToValidateChanged: false,
        fetchPunchingsToValidateLoaded: false,
        fetchPunchingsToValidateError: null,
      };
    case FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS:
      return {
        ...state,
        fetchPunchingsToValidateLoaded: true,
        fetchPunchingsToValidateError: null,
        punchingsToValidateTotal: action.punchings.total,
        punchingsToValidateCurrentPage: action.punchings.page,
        punchingsToValidatePages: {
          ...state.punchingsToValidatePages,
          [action.punchings.page]: action.punchings.data.map((obj) => obj.id),
        },
        punchingsToValidateById: {
          ...state.punchingsToValidateById,
          ...keyBy(action.punchings.data, 'id'),
        },
      };
    case FETCH_PUNCHINGS_TO_VALIDATE_FAILURE:
      return {
        ...state,
        fetchPunchingsToValidateLoaded: true,
        fetchPunchingsToValidateError: action.errors,
      };
    case VALIDATE_PUNCHING_REQUEST:
      return {
        ...state,
        validatePunchingLoading: true,
        validatePunchingError: null,
      };
    case VALIDATE_PUNCHING_SUCCESS:
      return {
        ...state,
        validatePunchingLoading: false,
        validatePunchingError: null,
      };
    case VALIDATE_PUNCHING_FAILURE:
      return {
        ...state,
        validatePunchingLoading: false,
        validatePunchingError: action.errors,
      };
    case INSERT_PUNCHING_NOTIFICATION:
      return {
        ...state,
        punchingsToValidateChanged: !action.payload.archived
          ? true
          : state.punchingsToValidateChanged,
      };
    case CONFIRM_PUNCHING_NOTIFICATION:
    case REJECT_PUNCHING_NOTIFICATION:
      return {
        ...state,
        punchingsChanged: !action.payload.archived
          ? true
          : state.punchingsChanged,
      };
    case DELETE_OTHER_PUNCHING_REQUEST:
      return {
        ...state,
        deletePunchingLoading: action.payload.id,
        deletePunchingError: false,
      };
    case DELETE_OTHER_PUNCHING_SUCCESS:
      return {
        ...state,
        deletePunchingLoading: null,
        deletePunchingError: false,
      };
    case DELETE_OTHER_PUNCHING_FAILURE:
      return {
        ...state,
        deletePunchingLoading: null,
        deletePunchingError: true,
      };
    case EDIT_OTHER_PUNCHING_REQUEST:
      return {
        ...state,
        editPunchingLoading: true,
        editPunchingError: false,
      };
    case EDIT_OTHER_PUNCHING_SUCCESS:
      return {
        ...state,
        deletePunchingLoading: false,
        editPunchingError: false,
      };
    case EDIT_OTHER_PUNCHING_FAILURE:
      return {
        ...state,
        editPunchingLoading: false,
        editPunchingError: true,
      };
    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE:
      return { ...state, filter: {} };
    default:
      return state;
  }
}
