import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import Filters from '../../../components/Filters';
import UsersSelect from '../../../components/formControls/UsersSelect';
import { PhoneRulesEnums } from '../../../js/phoneRules/PhoneRulesUtils';
import PbxRoutesSelect from './PbxRoutesSelect';
import { setPbxPhoneRulesFilter } from '../../../js/phoneRules/actions';
import { getPbxPhoneRulesFilter } from '../../../js/phoneRules/selectors';
import { getMeId } from '../../../js/me/selectors';

const messages = defineMessages({
  placeholderRoutes: {
    id: 'PhoneRulesFilter.placeholderRoutes',
    defaultMessage: 'All routes',
  },
  placeholderUsers: {
    id: 'PhoneRulesFilter.placeholderUsers',
    defaultMessage: 'All users',
  },
});

class PhoneRulesFilter extends Component {
  handleSelectUser = (user) => {
    const { setFilter } = this.props;
    setFilter({ user });
  };

  handleRouteSelect = (route) => {
    const { setFilter} = this.props;
    setFilter({ route: route ? route.map(r => r.value) : [] });
  };

  render() {
    const {
      context,
      filter,
      intl: { formatMessage },
      myId,
    } = this.props;

    return (
      <Filters>
        <div className="row py-3">
          <div className="col">
            {context === PhoneRulesEnums.SettingsContext.users && (
              <UsersSelect
                selected={filter.user}
                onSelect={this.handleSelectUser}
                isClearable
                placeholder={formatMessage(messages.placeholderUsers)}
                excluded={[myId]}
              />
            )}
            {context === PhoneRulesEnums.SettingsContext.pbx && (
              <PbxRoutesSelect
                onSelect={this.handleRouteSelect}
                selected={filter.route}
                isClearable
                placeholder={formatMessage(messages.placeholderRoutes)}
                isMulti
              />
            )}
          </div>
        </div>
      </Filters>
    );
  }
}

PhoneRulesFilter.propTypes = {
  context: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    filter: getPbxPhoneRulesFilter(state),
    myId: getMeId(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { setFilter: setPbxPhoneRulesFilter })(
    PhoneRulesFilter
  )
);
