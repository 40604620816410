import find from 'lodash.find';

export const MAX_COST = '4.00';
export const DEFAULT_PREFIX = '*';
export const WAKEUP_IDENTIFIER = 'Sveglia';
export const WAKEUP_SETTINGS = {
  retryTimesOptions: [1, 2, 3, 4, 5],
  retryAfterOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // minutes
  waitingTimeOptions: [10, 20, 30, 40, 50, 60], // seconds
};
export const ACTIVE_WAKEUPS_FILTER_OPTIONS = {
  today: 'TODAY',
  tomorrow: 'TOMORROW',
  other: 'OTHER',
  all: 'ALL',
};

export const findZone = (prefix, prefixes) => {
  if (!prefix || !prefixes || !/^\d+$/.test(prefix)) return null;
  if (prefix === DEFAULT_PREFIX || prefix === 0)
    return { prefix: DEFAULT_PREFIX, price: MAX_COST };
  let search = prefix;
  let zone;
  while (search.length > 0) {
    zone = find(prefixes, ['prefix', search]);
    if (zone)
      return {
        prefix: zone.prefix,
        description: zone.description,
        price: zone.price < 0 ? MAX_COST : null,
      };
    search = search.slice(0, -1);
  }
  return null;
};

export const findCost = (number, prices) => {
  if (!number || !prices) return null;
  let search = number;
  let cost;
  while (search.length > 0) {
    cost = find(prices, ['prefix', search]);
    if (cost) {
      if (!cost.price) delete cost.price;
      if (!cost.percentage) delete cost.percentage;
      return cost;
    }
    search = search.slice(0, -1);
  }
  const all = prices.find((row) => row.prefix === '*');
  if (all) {
    if (!all.price) delete all.price;
    if (!all.percentage) delete all.percentage;
    return all;
  }
  return { prefix: DEFAULT_PREFIX, price: MAX_COST };
};
