import React from 'react';
import Wiki from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const WikiShow = React.lazy(() => import('./WikiShow'));

const WikiShowPage = (props) => (
  <Wiki scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <WikiShow {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Wiki>
);

export default WikiShowPage;
