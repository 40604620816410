import React, { useState } from 'react';

const style = {
  container: {
    margin: '2px',
    backgroundColor: 'rgb(230, 230, 230)',
    borderRadius: '2px',
    color: 'rgb(51, 51, 51)',
    fontSize: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  label: { padding: '3px 3px 3px 6px', display: 'inline-block' },
  remover: {
    display: 'inline-block',
    padding: '3px',
    color: 'var(--yn-blue-darker)',
  },
  removerHover: {
    display: 'inline-block',
    backgroundColor: 'rgb(255, 189, 173)',
    color: 'rgb(222, 53, 11)',
    padding: '3px',
  },
  svg: {
    display: 'inline-block',
    fill: 'currentcolor',
    lineHeight: 1,
    stroke: 'currentcolor',
    strokeWidth: '0',
    paddingBottom: '2px',
  },
};

const Tag = ({ name, onRemove }) => {
  const [hover, setHover] = useState(false);

  return (
    <div style={style.container}>
      <span style={style.label}>{name}</span>
      <span
        style={hover ? style.removerHover : style.remover}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onRemove}
      >
        <svg
          height="14"
          width="14"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
          style={style.svg}
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
      </span>
    </div>
  );
};
export default Tag;
