import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSAlias from './SMSAlias';

const SMSAliasPage = () => (
  <SMS>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <SMSAlias />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSAliasPage;
