import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchWikiRootDocumentsSuccess,
  fetchWikiRootDocumentsFailure,
  fetchWikiChildrenDocumentsSuccess,
  fetchWikiChildrenDocumentsFailure,
  fetchWikiSearchSuccess,
  fetchWikiSearchFailure,
  fetchWikiTagsSuccess,
  fetchWikiTagsFailure,
  fetchWikiDocumentSuccess,
  fetchWikiDocumentFailure,
  saveWikiDocumentSuccess,
  saveWikiDocumentFailure,
  deleteWikiDocumentSuccess,
  deleteWikiDocumentFailure,
} from './actions';
import {
  /** wiki */
  FETCH_WIKI_ROOT_DOCUMENTS_REQUEST,
  FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST,
  FETCH_WIKI_SEARCH_REQUEST,
  FETCH_WIKI_TAGS_REQUEST,
  FETCH_WIKI_DOCUMENT_REQUEST,
  SAVE_WIKI_DOCUMENT_REQUEST,
  REMOVE_WIKI_DOCUMENT_REQUEST,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

function* fetchWikiRootDocuments() {
  try {
    const res = yield call(api.wiki.getDocumentsTree);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchWikiRootDocumentsSuccess([]));
    } else {
      yield put(fetchWikiRootDocumentsSuccess(res.data));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchWikiRootDocumentsFailure(error));
  }
}

function* fetchWikiChildrenDocuments(action) {
  try {
    const res = yield call(api.wiki.getDocumentsTree, action.parentId);
    yield call(checkApiResponse, res);
    if (res.status === 204) {
      yield put(fetchWikiChildrenDocumentsSuccess([], action.parentId));
    } else {
      yield put(fetchWikiChildrenDocumentsSuccess(res.data, action.parentId));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchWikiChildrenDocumentsFailure(error));
  }
}

function* fetchWikiSearch(action) {
  try {
    const res = yield call(api.wiki.getDocumentsSearch, action.params);
    yield call(checkApiResponse, res);
    yield put(fetchWikiSearchSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchWikiSearchFailure(error));
  }
}

function* fetchWikiTags() {
  try {
    const res = yield call(api.wiki.getTags);
    yield call(checkApiResponse, res);
    yield put(fetchWikiTagsSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchWikiTagsFailure(error));
  }
}

function* fetchWikiDocument(action) {
  try {
    const res = yield call(api.wiki.getDocument, action.id);
    yield call(checkApiResponse, res);
    yield put(fetchWikiDocumentSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchWikiDocumentFailure(error));
  }
}

function* saveWikiDocument(action) {
  try {
    const res = yield call(
      action.document.id ? api.wiki.updateDocument : api.wiki.createDocument,
      action.document
    );
    yield call(checkApiResponse, res);
    yield put(saveWikiDocumentSuccess(action.document));
    yield put(push('/wiki'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(saveWikiDocumentFailure(error));
  }
}

function* deleteWikiDocument(action) {
  try {
    const res = yield call(api.wiki.deleteDocument, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteWikiDocumentSuccess(action.id));
    yield put(push('/wiki'));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteWikiDocumentFailure(error));
  }
}

export default function* rootSaga() {
  /** Wiki */
  yield takeLatest(FETCH_WIKI_ROOT_DOCUMENTS_REQUEST, fetchWikiRootDocuments);
  yield takeLatest(
    FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST,
    fetchWikiChildrenDocuments
  );
  yield takeLatest(FETCH_WIKI_SEARCH_REQUEST, fetchWikiSearch);
  yield takeLatest(FETCH_WIKI_TAGS_REQUEST, fetchWikiTags);
  yield takeLatest(FETCH_WIKI_DOCUMENT_REQUEST, fetchWikiDocument);
  yield takeLatest(SAVE_WIKI_DOCUMENT_REQUEST, saveWikiDocument);
  yield takeLatest(REMOVE_WIKI_DOCUMENT_REQUEST, deleteWikiDocument);
}
