import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSCampaigns from './SMSCampaigns';

const SMSCampaignsPage = () => (
  <SMS scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <SMSCampaigns />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSCampaignsPage;
