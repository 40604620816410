import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import FileboxTreeModal from '../containers/sections/Filebox/FileboxTreeModal';
import ToolboxIcon from './icons/ToolboxIcon';
import {
  clearFilesErrors,
  importFileToFileboxRequest,
} from '../js/files/actions';
import Button from './formControls/Button';
import { getFileboxActiveActualPath } from '../js/filebox/selectors';

const messages = defineMessages({
  uploadToFilebox: {
    id: 'ImportFileToFileboxButton.tooltip.uploadToFilebox',
    defaultMessage: 'Upload to filebox',
  },
});

class ImportFileToFileboxButton extends Component {
  constructor() {
    super();
    this.state = { treeModalOpen: false };
  }

  toggleMoveFileModal = () => {
    const { treeModalOpen } = this.state;
    const { clearErrors } = this.props;

    this.setState({
      treeModalOpen: !treeModalOpen,
    });
    if (treeModalOpen) {
      clearErrors();
    }
  };

  render() {
    const {
      stored,
      context,
      name,
      importFileToFilebox,
      type,
      intl: { formatMessage },
    } = this.props;
    const { treeModalOpen } = this.state;

    const data = {
      stored,
      context,
      name,
    };

    return (
      <span>
        {type === 'ICON' ? (
          <span onClick={this.toggleMoveFileModal}>
            <ToolboxIcon name="upload-box-4">
              {formatMessage(messages.uploadToFilebox)}
            </ToolboxIcon>
          </span>
        ) : (
          <Button
            onClick={this.toggleMoveFileModal}
            className="btn btn-primary mr-0"
            text={formatMessage(messages.uploadToFilebox)}
          />
        )}
        {treeModalOpen && (
          <FileboxTreeModal
            isOpen={treeModalOpen}
            toggle={this.toggleMoveFileModal}
            onAction={importFileToFilebox}
            context={context}
            data={data}
            name={name}
            action="import"
            savingContext="import"
          />
        )}
      </span>
    );
  }
}

ImportFileToFileboxButton.propTypes = {
  stored: PropTypes.string.isRequired,
  context: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['BUTTON', 'ICON']).isRequired,
  name: PropTypes.string.isRequired,
};

ImportFileToFileboxButton.defaultProps = {};

function mapStateToProps(state) {
  return {
    activePath: getFileboxActiveActualPath(state, 'import'),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    importFileToFilebox: importFileToFileboxRequest,
    clearErrors: clearFilesErrors,
  })(ImportFileToFileboxButton)
);
