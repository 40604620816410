import React from 'react';
import AdminGrants from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const AdminGrantsEdit = React.lazy(() => import('./AdminGrantsEdit'));

const AdminGrantsEditPage = (props) => (
  <AdminGrants>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="text-right">
          <HelpButton fileName="admin-advanced" />
        </div>
        <AdminGrantsEdit {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </AdminGrants>
);

export default AdminGrantsEditPage;
