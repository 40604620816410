import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  askToDelete: {
    id: 'StickyNote.label.askToDelete',
    defaultMessage: 'Do you want to delete it?',
  },
  delete: {
    id: 'StickyNote.label.delete',
    defaultMessage: 'Move to trash',
  },
});

class DeleteSticky extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleting: false,
    };
    this.ondeleteClick = this.ondeleteClick.bind(this);
  }

  ondeleteClick = () => {
    if (!this.state.deleting) {
      this.setState({
        ...this.state,
        deleting: true,
      });
    } else {
      this.props.deleteSticky();
    }
  };

  render() {
    const {
      deleteDivStyle,
      intl: { formatMessage },
    } = this.props;
    const { deleting } = this.state;
    return (
      <span style={deleteDivStyle} onClick={this.ondeleteClick}>
        <Icon
          name={deleting ? 'bin-1-filled' : 'bin-1'}
          color="var(--gray-dark)"
          clickable
          width={18}
          height={18}
        >
          {deleting
            ? formatMessage(messages.delete)
            : formatMessage(messages.askToDelete)}
        </Icon>
      </span>
    );
  }
}

DeleteSticky.propTypes = {
  deleteSticky: PropTypes.func.isRequired,
  deleteDivStyle: PropTypes.object.isRequired,
};

export default injectIntl(DeleteSticky);
