import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Popover from '../../../components/Popover';
import Icon from '../../../components/icons/Icon';
import SelectMessageRecipientsModal from '../../../components/SelectMessageRecipientsModal';
import {
  markImportantMessageRequest,
  markUnImportantMessageRequest,
  replyMessageRequest,
} from '../../../js/chat/actions';

const messages = defineMessages({
  tooltip: {
    id: 'MessageActionsDropdown.tooltip.tooltip',
    defaultMessage: 'Actions',
  },
  addToImportant: {
    id: 'MessageActionsDropdown.tooltip.addToImportant',
    defaultMessage: 'Mark as important',
  },
  removeFromImportant: {
    id: 'MessageActionsDropdown.tooltip.removeFromImportant',
    defaultMessage: 'Unmark as important',
  },

  forward: {
    id: 'MessageActionsDropdown.tooltip.forward',
    defaultMessage: 'Forward',
  },
  reply: {
    id: 'MessageActionsDropdown.tooltip.reply',
    defaultMessage: 'Reply',
  },
});

class MessageActionsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isForwardModalOpen: false,
    };
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  toggleForwardModal = () => {
    const { isForwardModalOpen } = this.state;
    this.setState({
      isForwardModalOpen: !isForwardModalOpen,
    });
    if (!isForwardModalOpen) {
      this.toggle();
    }
  };

  markAsImportant = () => {
    const { markImportantMessage, markUnImportantMessage, message } =
      this.props;
    if (message.isImportant) {
      markUnImportantMessage({ messageId: message.id });
    } else {
      markImportantMessage({ messageId: message.id });
    }
    this.toggle();
  };

  closeForwardModal = () => {
    this.setState({
      isForwardModalOpen: false,
    });
  };

  replyMessage = () => {
    const { replyMessage, message } = this.props;
    replyMessage({
      messageId: message.id,
      conversationId: message.conversationId,
    });
    this.toggle();
  };

  render() {
    const { isOpen, isForwardModalOpen } = this.state;
    const {
      intl: { formatMessage },
      message,
    } = this.props;
    return (
      <>
        <div
          onClick={this.toggle}
          id={`Actions-${message.id}`}
          style={{
            lineHeight: 1,
            display: this.props.show || isOpen ? 'block' : 'none',
          }}
        >
          <Icon
            name="arrow-down-8-filled"
            color="var(--yn-blue-dark)"
            width={10}
            height={10}
            style={{ top: '2px' }}
          />
        </div>
        <Popover
          isOpen={isOpen}
          toggle={this.toggle}
          target={`Actions-${message.id}`}
          hideArrow
          popoverMinWidth="fit-content"
          popoverMinHeight="fit-content"
          bodyPadding="0"
        >
          <ul className="list-unstyled m-0">
            <li className="px-1 pt-1">
              <span onClick={this.markAsImportant}>
                {!message.isImportant && (
                  <Icon
                    name="rank-army-star-2"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.addToImportant)}
                  </Icon>
                )}
                {message.isImportant && (
                  <Icon
                    name="rank-army-star-2-filled"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.removeFromImportant)}
                  </Icon>
                )}
              </span>
            </li>
            <li className="px-1">
              <span onClick={this.toggleForwardModal}>
                <Icon
                  name="navigation-next-1"
                  color="var(--yn-blue-dark)"
                  width={18}
                  height={18}
                >
                  {formatMessage(messages.forward)}
                </Icon>
              </span>
            </li>
            <li className="px-1">
              <span>
                <span onClick={this.replyMessage}>
                  <Icon
                    name="reply"
                    color="var(--yn-blue-dark)"
                    width={20}
                    height={20}
                  >
                    {formatMessage(messages.reply)}
                  </Icon>
                </span>
              </span>
            </li>
          </ul>
        </Popover>

        <SelectMessageRecipientsModal
          isOpen={isForwardModalOpen}
          toggle={this.toggleForwardModal}
          onClose={this.closeForwardModal}
          {...this.props}
        />
      </>
    );
  }
}

export default injectIntl(
  connect(null, {
    markImportantMessage: markImportantMessageRequest,
    markUnImportantMessage: markUnImportantMessageRequest,
    replyMessage: replyMessageRequest,
  })(MessageActionsDropdown)
);
