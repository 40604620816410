import React from 'react';
import PersonalSettings from './index';
import ErrorBoundary from '../../../../components/ErrorBoundary';

const PasswordSettings = React.lazy(() => import('./PasswordSettings'));

const PasswordSettingsPage = (props) => (
  <PersonalSettings>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <PasswordSettings {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </PersonalSettings>
);

export default PasswordSettingsPage;
