export const getVideocallsListFilter = (state) =>
  state.videocalls.fetchList.filters;
export const getVideocallsListResult = (state) =>
  state.videocalls.fetchList.data;
export const isVideocallsListLoaded = (state) =>
  state.videocalls.fetchList.loaded;
export const isVideocallsListError = (state) =>
  state.videocalls.fetchList.error;
export const isVideocallsSearchInited = (state) =>
  state.videocalls.fetchList.inited;

export const getSendGuestEmailAddressLoaded = (state) =>
  state.videocalls.sendGuestEmailAddressLoaded;
export const getSendGuestEmailAddressSuccess = (state) =>
  state.videocalls.sendGuestEmailAddressSuccess;
export const getSendGuestEmailAddressError = (state) =>
  state.videocalls.sendGuestEmailAddressError;

export const isScheduleVideocallLoaded = (state) =>
  state.videocalls.scheduleVideocallLoaded;
export const isScheduleVideocallError = (state) =>
  state.videocalls.scheduleVideocallError;
export const getScheduledVideocallsResult = (state) =>
  state.videocalls.scheduledVideocallsAllIds.map(
    (id) => state.videocalls.scheduledVideocallsById[id]
  );
export const getScheduledVideocallById = (state, id) =>
  state.videocalls.scheduledVideocallsById[id];
export const isScheduledVideocallsLoaded = (state) =>
  state.videocalls.scheduledVideocallsLoaded;
export const isScheduledVideocallsError = (state) =>
  state.videocalls.scheduledVideocallsError;
export const getDeleteVideocallsError = (state) =>
  state.videocalls.deleteScheduledVideocallError;
export const getDeleteVideocallsSuccess = (state) =>
  state.videocalls.deleteScheduledVideocallSuccess;
export const getSaveVideocallsSuccess = (state) =>
  state.videocalls.saveScheduledVideocallSuccess;
export const getEnterVideocallError = (state) =>
  state.videocalls.enterVideocallError;
export const getVideocallJwt = (state) => state.videocalls.videocallJwt;
export const getCurrentVideocallRoom = (state) =>
  state.videocalls.currentVideocall
    ? state.videocalls.currentVideocall.room
    : null;
export const getLastVideocallRoom = (state) =>
  state.videocalls.lastVideocallRoom;
export const getCurrentVideocallId = (state) =>
  state.videocalls.currentVideocall
    ? state.videocalls.currentVideocall.id
    : null;
export const getCurrentVideocallAuthorizations = (state) =>
  state.videocalls.currentVideocall
    ? state.videocalls.currentVideocall.authorization
    : null;
export const isCurrentVideocallModerator = (state) =>
  state.videocalls.isCurrentVideocallModerator;
export const isCheckVideocallAuthorizationLoaded = (state) =>
  state.videocalls.checkAuthorizationLoaded;
export const isCheckVideocallAuthorizationError = (state) =>
  state.videocalls.checkAuthorizationError;
export const checkVideocallAuthorizationAcceptGuests = (state) =>
  state.videocalls.checkAuthorizationAcceptGuests;
export const isVideocallJoined = (state) => state.videocalls.videocallJoined;

export const isVideocallInviteLoaded = (state) =>
  state.videocalls.inviteVideocallLoaded;
export const isVideocallInviteError = (state) =>
  state.videocalls.inviteVideocallError;
export const getVideocallSettings = (state) => state.videocalls.settings;
export const isVideocallSettingsLoaded = (state) =>
  state.videocalls.fetchSettingsLoaded;
export const isVideocallSettingsError = (state) =>
  state.videocalls.fetchSettingsError;
export const isVideocallSettingsSaveLoaded = (state) =>
  state.videocalls.saveSettingsLoaded;
export const isVideocallSettingsSaveSuccess = (state) =>
  state.videocalls.saveSettingsSuccess;
export const isVideocallSettingsSaveError = (state) =>
  state.videocalls.saveSettingsError;
export const isVideocallSaveChatLoaded = (state) =>
  state.videocalls.saveChatLoaded;
export const isVideocallSaveChatError = (state) =>
  state.videocalls.saveChatError;
export const isVideocallNotificationsLoaded = (state) =>
  state.videocalls.notificationsLoaded;
export const getVideocallNotifications = (state) =>
  state.videocalls.notifications;
export const isVideocallChatUsed = (state) =>
  state.videocalls.usedVideocallChat;
export const getIncomingVideocall = (state) =>
  state.videocalls.incomingVideocall;
export const getOutgoingVideocall = (state) =>
  state.videocalls.outgoingVideocall;
export const isVideocallInvitationsLoaded = (state) =>
  state.videocalls.fetchInvitationsLoaded;
export const isVideocallInvitationsError = (state) =>
  state.videocalls.fetchInvitationsError;
export const getVideocallInvitations = (state) => state.videocalls.invitations;
