/* eslint react/prefer-stateless-function: 0 */

import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';

const messages = defineMessages({
  defaultTooltip: {
    id: 'FaxButton.tooltip.defaultTooltip',
    defaultMessage: 'Send a fax',
  },
});

class FaxButton extends Component {
  render() {
    const {
      tooltip,
      number,
      intl: { formatMessage },
    } = this.props;
    return (
      <Link to={`/fax/send?number=${number}`}>
        <Icon
          name="fax-machine-filled"
          color="var(--yn-blue-dark)"
          width={20}
          height={20}
          clickable
        >
          {tooltip || formatMessage(messages.defaultTooltip)}
        </Icon>
      </Link>
    );
  }
}

FaxButton.propTypes = {
  number: PropTypes.string.isRequired,
};

export default injectIntl(FaxButton);
