import { put, select, call } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';
import isEqual from 'lodash.isequal';
import { YnSid } from './lib/YnCookies';
import {
  updateGrants,
  logoutSuccess,
  showChangedGrantsModal,
} from './auth/actions';
import { getMeGrants } from './me/selectors';
import Utils from './lib/utils';

const { remote } = window;

export function* checkApiResponse() {
  const oldGrants = yield select(getMeGrants);
  let newGrants = [];
  let sid = null;
  // isAppDesktop
  if (remote) {
    try {
      sid = yield call(YnSid.getRemote);
    } catch (e) {
      console.error(e);
    }
    if (sid && sid !== YnSid.get()) {
      YnSid.set(sid);
    }
  } else {
    sid = YnSid.get();
  }
  if (sid) {
    newGrants = jwt.decode(sid).grants;
  }
  if (!isEqual(oldGrants, newGrants)) {
    yield put(updateGrants(newGrants));
    if (oldGrants) {
      yield put(showChangedGrantsModal());
    }
  }
}

export function* checkApiError(err) {
  if (err.status === 401) {
    YnSid.remove();
    yield put(logoutSuccess());
    return;
  }
  return Utils.checkApiError(err);
}
