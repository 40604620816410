import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import SMS from '.';
import SMSList from './SMSList';

const SMSListPage = (props) => (
  <SMS scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <SMSList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </SMS>
);

export default SMSListPage;
