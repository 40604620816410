import React, { Component } from 'react';
import ynConf from '../../../conf';

const style = {
  default: {
    height: '2.8em',
  },
  hovered: {
    height: '2.8m',
    WebkitTransform: 'scale(1.08, 1.08)',
    MozTransform: 'scale(1.08, 1.08)',
    MsTransform: 'scale(1.08, 1.08)',
    transform: 'scale(1.08, 1.08)',
  },
};

class Logo extends Component {
  state = {
    hovered: false,
  };

  toggleHover = () => {
    this.setState({
      hovered: !this.state.hovered,
    });
  };

  render() {
    const { hovered } = this.state;
    return (
      <img
        src={`${ynConf.publicFolderPath}/logo.svg`}
        style={hovered ? style.hovered : style.default}
        alt="YouNeed Logo"
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      />
    );
  }
}

export default Logo;
