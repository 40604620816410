import React, { Component } from 'react';
import Abook from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const ExportAbookContacts = React.lazy(() => import('./ExportAbookContact'));

class AbookManageExportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Abook scrolling>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <ExportAbookContacts />
          </React.Suspense>
        </ErrorBoundary>
      </Abook>
    );
  }
}
export default AbookManageExportPage;
