import React from 'react';
import Calendar from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const SynchCalendar = React.lazy(() => import('./SynchCalendar'));

const CalendarManageSynchPage = () => (
  <Calendar scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <SynchCalendar />
      </React.Suspense>
    </ErrorBoundary>
  </Calendar>
);

export default CalendarManageSynchPage;
