export class FaxEnums {
  static FaxType = {
    SEND: 'SEND',
    RECEIVE: 'RECEIVE',
  };

  static FaxStatus = {
    ADDED: 'ADDED',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
  };

  static FAX_PAGE_SIZE = 10;
  static DEFAULT_SHOWN_FAXES = 5;
}

export const isFaxPending = (status) =>
  [FaxEnums.FaxStatus.SUCCESS, FaxEnums.FaxStatus.ERROR].indexOf(status) < 0;
