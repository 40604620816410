import React from 'react';
import { connect } from 'react-redux';
import BackButton from '../../../../components/buttons/BackButton';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import HelpButton from '../../../../components/HelpButton';
import { getSupplier } from '../../../../js/phone/selectors';
import { PhoneRulesEnums } from '../../../../js/phoneRules/PhoneRulesUtils';
import PbxSettings from './PbxSettings';

const PhoneRulesEdit = React.lazy(() => import('../PhoneRulesEdit'));
const PbxPhoneRulesEditPage = (props) => {
  let context = '';
  const path = props.location.pathname.split('/');
  if (path.indexOf('users') > -1) {
    context = PhoneRulesEnums.SettingsContext.users;
  } else {
    context = PhoneRulesEnums.SettingsContext.pbx;
  }
  const childrenProps = {
    ...props,
    context,
  };
  return (
    <PbxSettings>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div className="row mb-3">
            <div className="col text-left">
              <BackButton />
            </div>
            <div className="col text-right">
              <HelpButton
                fileName={
                  context === 'pbx'
                    ? `pbx-settings-new-rule-${props.supplier}`
                    : `pbx-settings-new-rule-users-${props.supplier}`
                }
              />
            </div>
          </div>
          <PhoneRulesEdit {...childrenProps} />
        </React.Suspense>
      </ErrorBoundary>
    </PbxSettings>
  );
};

function mapStateToProps(state) {
  return {
    supplier: getSupplier(state),
  };
}

export default connect(mapStateToProps)(PbxPhoneRulesEditPage);
