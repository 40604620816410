import React from 'react';
import Tickets from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
const TicketsNotifications = React.lazy(() => import('./TicketsNotifications'));
const TicketNotificationsPage = (props) => (
  <Tickets scrolling>
    <div className="text-right">
      <HelpButton fileName="notifications-tickets" />
    </div>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <TicketsNotifications {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Tickets>
);

export default TicketNotificationsPage;
