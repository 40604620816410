const DEFAULT_INTERVAL = 30000;

export default class AckHandler {
  static pendingAcks = {};

  static active = false;

  static setActive = (active) => {
    AckHandler.active = active;
  };

  static checkMessage = (message) => {
    if (AckHandler.pendingAcks[message.ackUid]) {
      clearTimeout(AckHandler.pendingAcks[message.ackUid].timeout);
      const response = { ...AckHandler.pendingAcks[message.ackUid].message };
      delete AckHandler.pendingAcks[message.ackUid];
      return response;
    }
    return null;
  };

  /**
   * @function
   * @param {object} data
   * @param {string} data.ackUid - Uid of the sent message
   * @param {Object} data.message - Sent message
   * @param {number?} data.waitingTime - Time to wait before trying again
   * @param {function?} data.retry - Function to be executed if ack is not received
   * @param {number?} data.maxAttempts - Maximum number of attempts of retrying
   * @param {function?} data.failure - Function to be executed if maximum attempts limit is reached
   */
  static addPendingAck = (data) => {
    const timeoutId = setTimeout(() => {
      if (AckHandler.active && AckHandler.pendingAcks[data.ackUid]) {
        if (
          AckHandler.pendingAcks[data.ackUid].maxAttempts &&
          AckHandler.pendingAcks[data.ackUid].attempts ===
            AckHandler.pendingAcks[data.ackUid].maxAttempts
        ) {
          if (data.failure) {
            data.failure();
          }
          return;
        }
        if (data.retry) {
          data.retry();
        }
      }
    }, data.waitingTime || DEFAULT_INTERVAL);
    if (AckHandler.pendingAcks[data.ackUid]) {
      AckHandler.pendingAcks[data.ackUid].attempts += 1;
    } else {
      AckHandler.pendingAcks[data.ackUid] = {
        timeout: timeoutId,
        attempts: 0,
        message: data.message,
        maxAttempts: data.maxAttempts,
      };
    }
  };

  static disconnect = () => {
    AckHandler.active = false;
  };
}
