import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Button from '../../../components/formControls/Button';
import Label from '../../../components/formControls/Label';
import Input from '../../../components/formControls/Input';
import ToastMessage from '../../../components/messages/ToastMessage';
import Form from '../../../components/Form';
import {
  getSaveAbookListLoaded,
  getSaveAbookListError,
  getFetchAbookListLoaded,
  getFetchAbookListError,
  getAbookListById,
  getContacts,
  getFetchContactsEnd,
  getFetchContactsLoaded,
  getContactsCurrentPage,
  getSavedAbookListId,
} from '../../../js/contacts/selectors';
import {
  fetchAbookListRequest,
  fetchContactsRequest,
  saveAbookListRequest,
} from '../../../js/contacts/actions';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import Loading from '../../../components/Loading';
import Search from '../../../components/formControls/Search';
import AbookTagsSelect from './AbookTagsSelect';
import UsersSelect from '../../../components/formControls/UsersSelect';
import GroupsSelect from '../../../components/formControls/GroupsSelect';
import Tag from '../../../components/Tag';
import Icon from '../../../components/icons/Icon';
import FormFieldError from '../../../components/messages/FormFieldError';
import { ABOOK_LIST_CONTACTS_PAGE_SIZE } from '../../../js/contacts/ContactsUtils';
import { getMeId } from '../../../js/me/selectors';
import CreateAbookModal from '../../../components/CreateAbookModal';

const messages = defineMessages({
  buttonBack: {
    id: 'AbookListsEdit.buttonBack',
    defaultMessage: 'Back',
  },
  name: {
    id: 'AbookListsEdit.label.name',
    defaultMessage: 'Name',
  },
  contacts: {
    id: 'AbookListsEdit.label.contacts',
    defaultMessage: 'Contacts',
  },
  buttonSave: {
    id: 'AbookListsEdit.button.buttonSave',
    defaultMessage: 'Save',
  },
  buttonAddContacts: {
    id: 'AbookListsEdit.button.buttonAddContacts',
    defaultMessage: 'Add contacts',
  },
  buttonAddMoreContacts: {
    id: 'AbookListsEdit.button.buttonAddMoreContacts',
    defaultMessage: 'Add more contacts',
  },
  buttonRemoveAllContacts: {
    id: 'AbookListsEdit.button.buttonRemoveAllContacts',
    defaultMessage: 'Remove all',
  },
  buttonCreateAbook: {
    id: 'AbookListsEdit.button.buttonCreateAbook',
    defaultMessage: 'Create new contact',
  },
  buttonCancel: {
    id: 'AbookListsEdit.button.buttonCancel',
    defaultMessage: 'Cancel',
  },
  buttonProceed: {
    id: 'AbookListsEdit.button.buttonProceed',
    defaultMessage: 'Proceed',
  },
  errorMissingName: {
    id: 'AbookListsEdit.errorMissingName',
    defaultMessage: 'Please enter a name for the list',
  },
  errorMissingContacts: {
    id: 'AbookListsEdit.errorMissingContacts',
    defaultMessage: 'Please select almost one contact',
  },
  addContactsModalTitle: {
    id: 'AbookListsEdit.addContactsModalTitle',
    defaultMessage: 'Choose contacts to add to the list',
  },
  errorMessage: {
    id: 'AbookListsEdit.errorMessage',
    defaultMessage: 'There has been an error while saving the list',
  },
  publicCheckboxLabel: {
    id: 'AbookListsEdit.label.publicCheckboxLabel',
    defaultMessage: 'Public',
  },
  privateCheckboxLabel: {
    id: 'AbookListsEdit.label.privateCheckboxLabel',
    defaultMessage: 'Private',
  },
  users: {
    id: 'AbookListsEdit.label.users',
    defaultMessage: 'Share with users',
  },
  groups: {
    id: 'AbookListsEdit.label.groups',
    defaultMessage: 'Share with groups',
  },
  deleteTooltip: {
    id: 'AbookListsEdit.tooltip.deleteTooltip',
    defaultMessage: 'Remove',
  },
  modalHeaderName: {
    id: 'AbookListsEdit.modalHeaderName',
    defaultMessage: 'Name',
  },
  modalHeaderNumbers: {
    id: 'AbookListsEdit.modalHeaderNumbers',
    defaultMessage: 'Numbers',
  },
  modalHeaderEmail: {
    id: 'AbookListsEdit.modalHeaderEmail',
    defaultMessage: 'Email',
  },
  modalHeaderAddress: {
    id: 'AbookListsEdit.modalHeaderAddress',
    defaultMessage: 'Postal address',
  },
  noContact: {
    id: 'AbookListsEdit.noContact',
    defaultMessage: 'No contact selected',
  },
  showMore: {
    id: 'AbookListsEdit.showMore',
    defaultMessage: 'Show more contacts',
  },
  searchByNamePlaceholder: {
    id: 'AbookListsEdit.searchByNamePlaceholder',
    defaultMessage: 'Search by name, surname, company or vat/fiscal code',
  },
  searchByTagPlaceholder: {
    id: 'AbookListsEdit.searchByTagPlaceholder',
    defaultMessage: 'Search by tag',
  },
  noMatch: {
    id: 'AbookListsEdit.noMatch',
    defaultMessage: 'No contact matches these criteria',
  },
  MOBILE: {
    id: 'AbookListsEdit.label.MOBILE',
    defaultMessage: 'Mobile: ',
  },
  OFFICE: {
    id: 'AbookListsEdit.label.OFFICE',
    defaultMessage: 'Office: ',
  },
  HOME: {
    id: 'AbookListsEdit.label.HOME',
    defaultMessage: 'Home: ',
  },
  FAX: {
    id: 'AbookListsEdit.label.FAX',
    defaultMessage: 'Fax: ',
  },
  OTHERS: {
    id: 'AbookListsEdit.label.OTHERS',
    defaultMessage: 'Others: ',
  },
  hasEmail: {
    id: 'AbookListsEdit.label.hasEmail',
    defaultMessage: 'With email address',
  },
  hasAddress: {
    id: 'AbookListsEdit.label.hasAddress',
    defaultMessage: 'With postal address',
  },
});

class AbookListsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAbookModal: false,
      data: {
        contacts: [],
      },
      errors: {},
      addContactsModalOpen: false,
      filter: {
        name: null,
        tags: [],
        public: true,
        private: true,
        hasEmail: false,
        hasAddress: false,
      },
      selectedContacts: [],
      allChecked: false,
    };
  }

  componentDidMount() {
    const { fetchList, id } = this.props;
    if (id) fetchList({ id });
  }

  componentDidUpdate(prevProps) {
    const { useAlias, fetchAlias, listData, onSave, saving, savedListId } =
      this.props;
    if (useAlias && !prevProps.useAlias) fetchAlias();
    if (listData && prevProps.listData !== listData) {
      this.setState({
        data: { ...this.state.data, contacts: listData.contacts || [] },
      });
    }
    if (!saving && prevProps.saving && onSave) {
      onSave(savedListId);
    }
  }

  handleNameChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        name: e.target.value,
      },
      errors: {},
    });
  };

  handleUsersSelection = (users) => {
    this.setState({
      users,
      errors: {},
    });
  };

  handleGroupsSelection = (groups) => {
    this.setState({
      groups,
      errors: {},
    });
  };

  handleFilterNameChange = (name) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          name,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  fetchContacts = (newPage) => {
    const { fetchContacts, page } = this.props;
    const {
      filter: {
        name,
        tags,
        private: privateContacts,
        public: publicContacts,
        hasEmail,
        hasAddress,
      },
    } = this.state;

    const params = {
      name: name && name.trim() !== '' ? name : null,
      tags: tags.map((tag) => tag.label),
    };

    fetchContacts({
      ...params,
      page: newPage ? 0 : page + 1,
      public: publicContacts,
      private: privateContacts,
      hasEmail,
      hasAddress,
      pageSize: ABOOK_LIST_CONTACTS_PAGE_SIZE + this.state.data.contacts.length,
    });
  };

  handleSelectContactCheckbox = (e, id) => {
    e.preventDefault();
    this.setState({
      selectedContacts: e.target.checked
        ? [...this.state.selectedContacts, id]
        : this.state.selectedContacts.filter((c) => c !== id),
    });
  };

  handleSelectAll = (e) => {
    this.setState({
      selectedContacts: e.target.checked
        ? this.arrangeSearchedContacts().map((c) => c.id)
        : [],
      allChecked: e.target.checked,
    });
  };

  handleFilterTagsChange = (tags) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          tags,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  handleFilterPublicChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          public: e.target.checked,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  handleFilterPrivateChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          private: e.target.checked,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  handleFilterHasEmailChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          hasEmail: e.target.checked,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  handleFilterHasAddressChange = (e) => {
    this.setState(
      {
        filter: {
          ...this.state.filter,
          hasAddress: e.target.checked,
        },
        allChecked: false,
      },
      () => {
        this.fetchContacts(true);
      }
    );
  };

  toggleSelectContact = (e, id) => {
    e.preventDefault();
    this.setState(
      {
        selectedContacts:
          this.state.selectedContacts.indexOf(id) < 0
            ? [...this.state.selectedContacts, id]
            : this.state.selectedContacts.filter((c) => c !== id),
      },
      () => {
        this.setState({
          allChecked: isEqual(
            this.arrangeSearchedContacts()
              .map((c) => c.id)
              .sort(),
            this.state.selectedContacts.sort()
          ),
        });
      }
    );
  };

  handleSelectedContacts = () => {
    this.setState({
      data: {
        ...this.state.data,
        contacts: [
          ...(this.state.data.contacts || []),
          ...this.props.searchedContacts.filter(
            (c) => this.state.selectedContacts.indexOf(c.id) > -1
          ),
        ],
      },
      selectedContacts: [],
      addContactsModalOpen: false,
    });
  };

  removeAllContacts = () => {
    this.setState({
      data: {
        ...this.state.data,
        contacts: [],
      },
    });
  };

  removeContact = (id) => {
    this.setState({
      data: {
        ...this.state.data,
        contacts: this.state.data.contacts.filter((c) => c.id !== id),
      },
    });
  };

  validate = (data) => {
    // check that:
    // name is not empty
    // there is at least 1 contact selected
    const {
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    if (!data.name || !data.name.trim().length) {
      errors.name = formatMessage(messages.errorMissingName);
    }
    if (!data.contacts || !data.contacts.length) {
      errors.contacts = formatMessage(messages.errorMissingContacts);
    }
    return errors;
  };

  saveList = (e) => {
    e.preventDefault();
    const { saveList, id, fromModal } = this.props;
    const {
      data: { contacts },
    } = this.state;

    const params = {
      name: this.arrangeField('name'),
      contacts: contacts.map((c) => c.id),
      sharing: [],
    };
    if (this.arrangeSharingUsers() && this.arrangeSharingUsers().length) {
      params.sharing = params.sharing.concat(
        this.arrangeSharingUsers().map((idUser) => ({ idUser, idGroup: null }))
      );
    }
    if (this.arrangeSharingGroups()) {
      params.sharing = params.sharing.concat(
        this.arrangeSharingGroups().map((idGroup) => ({
          idGroup,
          idUser: null,
        }))
      );
    }

    const errors = this.validate(params);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (id) params.id = id;
      saveList({
        params,
        fromModal: !!fromModal,
      });
    }
  };

  arrangeField = (field) => {
    const { listData } = this.props;
    const { data } = this.state;
    if (data[field] !== undefined) return data[field];
    if (!listData) return null;
    return listData[field];
  };

  arrangeSharingUsers = () => {
    const { listData } = this.props;
    const { users } = this.state;
    if (users !== undefined) return users;
    if (!listData || !listData.sharing) return null;
    return listData.sharing.filter((s) => s.idUser).map((s) => s.idUser);
  };

  arrangeSharingGroups = () => {
    const { listData } = this.props;
    const { groups } = this.state;
    if (groups !== undefined) return groups;
    if (!listData || !listData.sharing) return null;
    return listData.sharing.filter((s) => s.idGroup).map((s) => s.idGroup);
  };

  toggleAddContactsModal = (e) => {
    e.preventDefault();
    this.setState(
      {
        addContactsModalOpen: !this.state.addContactsModalOpen,
        allChecked: false,
        errors: {},
        filter: this.state.addContactsModalOpen
          ? this.state.filter
          : {
              name: null,
              tags: [],
              public: true,
              private: true,
              hasEmail: false,
              hasAddress: false,
            },
      },
      () => {
        if (this.state.addContactsModalOpen) {
          this.fetchContacts(true);
        } else {
          this.setState({ selectedContacts: [] });
        }
      }
    );
  };

  arrangeSearchedContacts = () => {
    const { searchedContacts } = this.props;
    const {
      data: { contacts },
    } = this.state;

    const contactsToShow = searchedContacts.filter(
      (c) => contacts.map((o) => o.id).indexOf(c.id) < 0
    );

    return contactsToShow;
  };

  openAbookModal = () => {
    this.setState({
      openAbookModal: true,
    });
  };

  returnFromAbookModal = (idAbook, fullname) => {
    if (idAbook) {
      this.setState({
        openAbookModal: false,
        data: {
          contacts: [...this.state.data.contacts, { id: idAbook, fullname }],
        },
      });
    } else {
      this.setState({
        openAbookModal: false,
      });
    }
  };

  render() {
    const {
      fetching,
      errorFetching,
      saving,
      errorSaving,
      contactsEnded,
      searching,
      meId,
      intl: { formatMessage },
    } = this.props;

    const {
      errors,
      addContactsModalOpen,
      filter,
      data: { contacts },
      selectedContacts,
      allChecked,
    } = this.state;

    const name = this.arrangeField('name');
    const users = this.arrangeSharingUsers();
    const groups = this.arrangeSharingGroups();
    const searchedContacts = this.arrangeSearchedContacts();

    return (
      <>
        {errorSaving && (
          <ToastMessage
            type="danger"
            text={
              messages[errorSaving]
                ? formatMessage(messages[errorSaving])
                : formatMessage(messages.errorMessage)
            }
          />
        )}
        {errorFetching ? (
          <GlobalErrorPage />
        ) : fetching ? (
          <Loading />
        ) : (
          <Form
            className="col p-2 mt-2 rounded border"
            onSubmit={this.saveList}
            scrolling
            header
          >
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <Label mandatory for="name">
                    {formatMessage(messages.name)}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.handleNameChange}
                    value={name}
                    error={errors.name}
                  />
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col pt-2">
                <FormGroup>
                  <Label mandatory for="name">
                    {formatMessage(messages.contacts)}
                  </Label>
                  <div
                    className={`mt-2 border rounded${
                      errors.contacts ? ' is-invalid' : ''
                    }`}
                  >
                    {contacts.length > 0 && (
                      <div
                        className="p-2 border-bottom"
                        style={{ maxHeight: '250px', overflowY: 'auto' }}
                      >
                        {contacts.map((c) => (
                          <Tag
                            key={c.id}
                            name={c.fullname}
                            onRemove={() => this.removeContact(c.id)}
                          />
                        ))}
                      </div>
                    )}
                    <div className="p-2">
                      <Button
                        className="btn btn-primary"
                        onClick={this.toggleAddContactsModal}
                        text={
                          contacts.length > 0
                            ? formatMessage(messages.buttonAddMoreContacts)
                            : formatMessage(messages.buttonAddContacts)
                        }
                      />
                      <Button
                        className="btn btn-primary ml-2"
                        text={formatMessage(messages.buttonCreateAbook)}
                        type="button"
                        onClick={this.openAbookModal}
                      />
                      {contacts.length > 0 && (
                        <Button
                          className="btn btn-outline-primary ml-2"
                          onClick={this.removeAllContacts}
                          text={formatMessage(messages.buttonRemoveAllContacts)}
                        />
                      )}
                    </div>
                  </div>
                  {errors.contacts && <FormFieldError text={errors.contacts} />}
                </FormGroup>
                <Modal
                  isOpen={addContactsModalOpen}
                  toggle={this.toggleAddContactsModal}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggleAddContactsModal}>
                    {formatMessage(messages.addContactsModalTitle)}
                  </ModalHeader>
                  <ModalBody>
                    <div className="row pb-2">
                      <div className="col">
                        <Search
                          id="filterName"
                          name="filterName"
                          placeholder={formatMessage(
                            messages.searchByNamePlaceholder
                          )}
                          onChange={this.handleFilterNameChange}
                          text={filter.name}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <AbookTagsSelect
                          isMulti
                          selected={filter.tags}
                          onSelect={this.handleFilterTagsChange}
                          isClearable
                          placeholder={formatMessage(
                            messages.searchByTagPlaceholder
                          )}
                        />
                      </div>
                      <div className="col-auto pt-2">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="contactsFilterPublic"
                            name="contactsFilterPublic"
                            checked={filter.public}
                            className="custom-control-input"
                            onChange={this.handleFilterPublicChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="contactsFilterPublic"
                          >
                            {formatMessage(messages.publicCheckboxLabel)}
                          </label>
                        </div>
                      </div>
                      <div className="col-auto pt-2">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            checked={filter.private}
                            id="contactsFilterPrivate"
                            name="contactsFilterPrivate"
                            className="custom-control-input"
                            onChange={this.handleFilterPrivateChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="contactsFilterPrivate"
                          >
                            {formatMessage(messages.privateCheckboxLabel)}
                          </label>
                        </div>
                      </div>
                      <div className="col-auto pt-2">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="contactsFilterHasEmail"
                            name="contactsFilterHasEmail"
                            checked={filter.hasEmail}
                            className="custom-control-input"
                            onChange={this.handleFilterHasEmailChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="contactsFilterHasEmail"
                          >
                            {formatMessage(messages.hasEmail)}
                          </label>
                        </div>
                      </div>
                      <div className="col-auto pt-2">
                        <div className="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            id="contactsFilterHasAddress"
                            name="contactsFilterHasAddress"
                            checked={filter.hasAddress}
                            className="custom-control-input"
                            onChange={this.handleFilterHasAddressChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="contactsFilterHasAddress"
                          >
                            {formatMessage(messages.hasAddress)}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="p-2 mt-4 border rounded"
                      style={{ maxHeight: '500px', overflowY: 'auto' }}
                    >
                      {searchedContacts && searchedContacts.length ? (
                        <>
                          <table className="table table-borderless table-hover">
                            <thead>
                              <tr>
                                <th>
                                  <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                    <input
                                      type="checkbox"
                                      id="contactsSelectAll"
                                      name="contactsSelectAll"
                                      className="custom-control-input"
                                      onChange={this.handleSelectAll}
                                      checked={allChecked}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="contactsSelectAll"
                                    />
                                  </div>
                                </th>
                                <th>
                                  {formatMessage(messages.modalHeaderName)}
                                </th>
                                <th>
                                  {formatMessage(messages.modalHeaderNumbers)}
                                </th>
                                <th>
                                  {formatMessage(messages.modalHeaderEmail)}
                                </th>
                                <th>
                                  {formatMessage(messages.modalHeaderAddress)}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {searchedContacts.map((c) => {
                                return (
                                  <tr
                                    onClick={(e) =>
                                      this.toggleSelectContact(e, c.id)
                                    }
                                    style={{ cursor: 'default' }}
                                    key={c.id}
                                  >
                                    <td>
                                      <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                        <input
                                          type="checkbox"
                                          id={`contact_${c.id}`}
                                          name={`contact_${c.id}`}
                                          className="custom-control-input"
                                          onChange={(e) =>
                                            this.handleSelectContactCheckbox(
                                              e,
                                              c.id
                                            )
                                          }
                                          checked={
                                            selectedContacts.indexOf(+c.id) > -1
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`contact_${c.id}`}
                                        />
                                      </div>
                                    </td>
                                    <td>{c.fullname}</td>
                                    <td>
                                      {c.numbers &&
                                        c.numbers.map((n) => (
                                          <div key={n.number}>
                                            <Label>
                                              {messages[n.type]
                                                ? formatMessage(
                                                    messages[n.type]
                                                  )
                                                : n.type}
                                            </Label>
                                            {n.number}
                                          </div>
                                        ))}
                                    </td>
                                    <td>
                                      {c.email && c.email !== '' && (
                                        <Icon
                                          name="check-2-filled"
                                          color="var(--gray)"
                                          width={16}
                                          height={16}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {c.addresses &&
                                        c.addresses.length > 0 && (
                                          <Icon
                                            name="check-2-filled"
                                            color="var(--gray)"
                                            width={16}
                                            height={16}
                                          />
                                        )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {searching && <Loading />}
                          {!contactsEnded && (
                            <div className="text-center">
                              <Button
                                className="btn btn-outline-primary"
                                text={formatMessage(messages.showMore)}
                                onClick={() => this.fetchContacts(false)}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        formatMessage(messages.noMatch)
                      )}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="btn btn-primary ml-2"
                      text={formatMessage(messages.buttonProceed)}
                      onClick={this.handleSelectedContacts}
                      disabled={!this.state.selectedContacts.length}
                    />
                    <Button
                      className="btn btn-outline-primary ml-2"
                      text={formatMessage(messages.buttonCancel)}
                      onClick={this.toggleAddContactsModal}
                    />
                  </ModalFooter>
                </Modal>
              </div>
            </div>
            <div className="row">
              <div className="col pt-2">
                <Label for="users">{formatMessage(messages.users)}</Label>
                <UsersSelect
                  excluded={[meId]}
                  isMulti
                  isClearable
                  onSelect={this.handleUsersSelection}
                  error={errors.selectUser}
                  selected={users}
                />
              </div>
              <div className="col pt-2">
                <Label for="groups">{formatMessage(messages.groups)}</Label>
                <GroupsSelect
                  isMulti
                  id="groups"
                  name="groups"
                  onSelect={this.handleGroupsSelection}
                  selected={groups}
                  error={errors && errors.usersGroups}
                  fileboxSharing
                />
              </div>
            </div>
            <div className="pt-4 pb-4 text-right">
              <Button
                className="btn btn-primary"
                loading={saving}
                text={formatMessage(messages.buttonSave)}
              />
            </div>
          </Form>
        )}
        {this.state.openAbookModal && (
          <CreateAbookModal onClose={this.returnFromAbookModal} />
        )}
      </>
    );
  }
}

AbookListsEdit.propTypes = {
  id: PropTypes.number,
  onSave: PropTypes.func,
  fromModal: PropTypes.bool,
};

AbookListsEdit.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
  return {
    fetching: !getFetchAbookListLoaded(state),
    errorFetching: getFetchAbookListError(state),
    saving: !getSaveAbookListLoaded(state),
    errorSaving: getSaveAbookListError(state),
    savedListId: getSavedAbookListId(state),
    listData: ownProps.id && getAbookListById(state, ownProps.id),
    searchedContacts: getContacts(state).filter((o) => o.type === 'A'),
    contactsEnded: getFetchContactsEnd(state),
    page: getContactsCurrentPage(state),
    searching: !getFetchContactsLoaded(state),
    meId: getMeId(state),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchList: fetchAbookListRequest,
    saveList: saveAbookListRequest,
    fetchContacts: fetchContactsRequest,
  })(AbookListsEdit)
);
