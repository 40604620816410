import {
  FETCH_WIKI_ROOT_DOCUMENTS_REQUEST,
  FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS,
  FETCH_WIKI_ROOT_DOCUMENTS_FAILURE,
  FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST,
  FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS,
  FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE,
  FETCH_WIKI_SEARCH_REQUEST,
  FETCH_WIKI_SEARCH_SUCCESS,
  FETCH_WIKI_SEARCH_FAILURE,
  FETCH_WIKI_TAGS_REQUEST,
  FETCH_WIKI_TAGS_SUCCESS,
  FETCH_WIKI_TAGS_FAILURE,
  FETCH_WIKI_DOCUMENT_REQUEST,
  FETCH_WIKI_DOCUMENT_SUCCESS,
  FETCH_WIKI_DOCUMENT_FAILURE,
  SET_WIKI_CHILDREN_COLLAPSE,
  SET_WIKI_FILTER,
  SAVE_WIKI_DOCUMENT_REQUEST,
  SAVE_WIKI_DOCUMENT_SUCCESS,
  SAVE_WIKI_DOCUMENT_FAILURE,
  REMOVE_WIKI_DOCUMENT_REQUEST,
  REMOVE_WIKI_DOCUMENT_SUCCESS,
  REMOVE_WIKI_DOCUMENT_FAILURE,
} from './types';

export const fetchWikiRootDocumentsRequest = () => ({
  type: FETCH_WIKI_ROOT_DOCUMENTS_REQUEST,
});
export const fetchWikiRootDocumentsSuccess = (documents) => ({
  type: FETCH_WIKI_ROOT_DOCUMENTS_SUCCESS,
  documents,
});
export const fetchWikiRootDocumentsFailure = (errors) => ({
  type: FETCH_WIKI_ROOT_DOCUMENTS_FAILURE,
  errors,
});
export const fetchWikiChildrenDocumentsRequest = (parentId) => ({
  type: FETCH_WIKI_CHILDREN_DOCUMENTS_REQUEST,
  parentId,
});
export const fetchWikiChildrenDocumentsSuccess = (documents, parentId) => ({
  type: FETCH_WIKI_CHILDREN_DOCUMENTS_SUCCESS,
  documents,
  parentId,
});
export const fetchWikiChildrenDocumentsFailure = (errors) => ({
  type: FETCH_WIKI_CHILDREN_DOCUMENTS_FAILURE,
  errors,
});
export const fetchWikiSearchRequest = (params) => ({
  type: FETCH_WIKI_SEARCH_REQUEST,
  params,
});
export const fetchWikiSearchSuccess = (documents) => ({
  type: FETCH_WIKI_SEARCH_SUCCESS,
  documents,
});
export const fetchWikiSearchFailure = (errors) => ({
  type: FETCH_WIKI_SEARCH_FAILURE,
  errors,
});
export const fetchWikiTagsRequest = () => ({ type: FETCH_WIKI_TAGS_REQUEST });
export const fetchWikiTagsSuccess = (tags) => ({
  type: FETCH_WIKI_TAGS_SUCCESS,
  tags,
});
export const fetchWikiTagsFailure = (errors) => ({
  type: FETCH_WIKI_TAGS_FAILURE,
  errors,
});
export const fetchWikiDocumentRequest = (id) => ({
  type: FETCH_WIKI_DOCUMENT_REQUEST,
  id,
});
export const fetchWikiDocumentSuccess = (document) => ({
  type: FETCH_WIKI_DOCUMENT_SUCCESS,
  document,
});
export const fetchWikiDocumentFailure = (errors) => ({
  type: FETCH_WIKI_DOCUMENT_FAILURE,
  errors,
});
export const setWikiChildrenCollapse = (id, collapse) => ({
  type: SET_WIKI_CHILDREN_COLLAPSE,
  id,
  collapse,
});
export const setWikiFilters = (filter) => ({ type: SET_WIKI_FILTER, filter });
export const saveWikiDocumentRequest = (document) => ({
  type: SAVE_WIKI_DOCUMENT_REQUEST,
  document,
});
export const saveWikiDocumentSuccess = (document) => ({
  type: SAVE_WIKI_DOCUMENT_SUCCESS,
  document,
});
export const saveWikiDocumentFailure = (errors) => ({
  type: SAVE_WIKI_DOCUMENT_FAILURE,
  errors,
});
export const deleteWikiDocumentRequest = (id) => ({
  type: REMOVE_WIKI_DOCUMENT_REQUEST,
  id,
});
export const deleteWikiDocumentSuccess = (id) => ({
  type: REMOVE_WIKI_DOCUMENT_SUCCESS,
  id,
});
export const deleteWikiDocumentFailure = (errors) => ({
  type: REMOVE_WIKI_DOCUMENT_FAILURE,
  errors,
});
