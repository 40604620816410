import keyBy from 'lodash.keyby';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FETCH_VACATIONS_TO_VALIDATE_REQUEST,
  FETCH_VACATIONS_TO_VALIDATE_SUCCESS,
  FETCH_VACATIONS_TO_VALIDATE_FAILURE,
  SET_VACATIONS_FILTER,
  INSERT_VACATION_NOTIFICATION,
  DELETE_VACATION_NOTIFICATION,
  ASK_DELETING_VACATION_NOTIFICATION,
} from './types';

const initialstate = {
  vacationsChanged: false,
  fetchVacationsLoaded: true,
  fetchVacationsError: null,
  filter: {
    name: '',
  },
  currentPage: 0,
  pages: {},
  total: 0,
  byId: {},
  lastRoute: [],
};

export default function vacations(state = initialstate, action = {}) {
  switch (action.type) {
    case FETCH_VACATIONS_TO_VALIDATE_REQUEST:
      return {
        ...state,
        vacationsChanged: false,
        fetchVacationsLoaded: false,
        fetchVacationsError: null,
      };
    case FETCH_VACATIONS_TO_VALIDATE_SUCCESS:
      return {
        ...state,
        fetchVacationsLoaded: true,
        fetchVacationsError: null,
        total: action.vacations.total,
        currentPage: action.vacations.page,
        pages: {
          ...state.pages,
          [action.vacations.page]: action.vacations.data.map((obj) => obj.id),
        },
        byId: {
          ...state.byId,
          ...keyBy(action.vacations.data, 'id'),
        },
      };
    case FETCH_VACATIONS_TO_VALIDATE_FAILURE:
      return {
        ...state,
        fetchVacationsLoaded: true,
        fetchVacationsError: action.errors,
      };
    case SET_VACATIONS_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case INSERT_VACATION_NOTIFICATION:
    case DELETE_VACATION_NOTIFICATION:
    case ASK_DELETING_VACATION_NOTIFICATION:
      return {
        ...state,
        vacationsChanged: !action.payload.archived
          ? true
          : state.vacationsChanged,
      };

    case LOGOUT_SUCCESS:
      return initialstate;
    case LOCATION_CHANGE: {
      const newRoute = action.payload.location.pathname.substr(1).split('/');
      const { lastRoute } = state;
      if (
        lastRoute &&
        (lastRoute[0] !== newRoute[0] || lastRoute[1] !== newRoute[1])
      ) {
        return {
          ...initialstate,
          lastRoute: [newRoute[0], newRoute[1]],
        };
      }
      return state;
    }
    default:
      return state;
  }
}
