import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import MenuIcon from '../../../components/icons/MenuIcon';

const MainNavGuest = () => (
  <Nav className="mr-auto" navbar>
    <NavItem className="px-2">
      <MenuIcon
        name="headphone"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="video-camera-filled"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="chat-double-bubble-3"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="mobile-phone-text-message-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="book-address"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="phone-5"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="calendar-2"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="logo-pt"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="box-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="luggage-key-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="fax-machine"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="logo-circle-wikipedia"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="task-check-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="new-notepad"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    {/*<NavItem className="px-2">
      <MenuIcon
        name="box-2"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>*/}
    <NavItem className="px-2">
      <MenuIcon
        name="server-network-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
    <NavItem className="px-2">
      <MenuIcon
        name="key-1"
        color="var(--yn-blue-dark)"
        hoverable={false}
        clickable={false}
      />
    </NavItem>
  </Nav>
);

export default MainNavGuest;
