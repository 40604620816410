import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import Icon from '../../../components/icons/Icon';
import { deleteGroupRequest } from '../../../js/groups/actions';
import { retrieveInterlocutorIdFromConversationId } from '../../../js/chat/ChatUtils';
import { showConfirmModal } from '../../../js/confirm/actions';

const messages = defineMessages({
  tooltip: {
    id: 'ChatOptionsDropdown.label.tooltip',
    defaultMessage: 'Options',
  },
  readAll: {
    id: 'ChatOptionsDropdown.label.readAll',
    defaultMessage: 'Read all',
  },
  deleteAll: {
    id: 'ChatOptionsDropdown.label.deleteAll',
    defaultMessage: 'Delete all',
  },
  archiveConversation: {
    id: 'ChatOptionsDropdown.label.archiveConversation',
    defaultMessage: 'Archive',
  },
  renameGroup: {
    id: 'ChatOptionsDropdown.label.renameGroup',
    defaultMessage: 'Rename group',
  },
  addMeToGroup: {
    id: 'ChatOptionsDropdown.label.addMeToGroup',
    defaultMessage: 'Add me to group',
  },
  removeMeFromGroup: {
    id: 'ChatOptionsDropdown.label.removeMeFromGroup',
    defaultMessage: 'Remove me from group',
  },
  deleteGroup: {
    id: 'ChatOptionsDropdown.label.deleteGroup',
    defaultMessage: 'Delete group',
  },
  deleteConfirmation: {
    id: 'ChatOptionsDropdown.label.deleteConfirmation',
    defaultMessage: 'Do you really want to delete the group?',
  },
});

class ChatOptionsDropdown extends Component {
  constructor(props) {
    super(props);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {}

  deleteGroup = () => {
    const {
      conversationId,
      confirm,
      deleteGroup,
      intl: { formatMessage },
    } = this.props;
    const interlocutor =
      retrieveInterlocutorIdFromConversationId(conversationId);
    confirm({
      message: formatMessage(messages.deleteConfirmation),
      modalAction: deleteGroup,
      actionParams: interlocutor.groupId,
    });
  };

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const {
      operationLoading,
      isGroupEditable,
      readAllMessages,
      deleteAllMessages,
      archiveConversation,
      enableRenameGroup,
      addMeToGroup,
      removeMeFromGroup,
      membersLoading,
      intl: { formatMessage },
    } = this.props;
    const { isOpen } = this.state;
    return (
      <Dropdown isOpen={isOpen} toggle={this.toggle} className="yn-dropdown">
        <DropdownToggle className="position-relative p-0" nav>
          <span>
            <Icon name="cog-filled" width={20} height={20}>
              {formatMessage(messages.tooltip)}
            </Icon>
          </span>
        </DropdownToggle>
        {!membersLoading && (
          <DropdownMenu>
            {enableRenameGroup && (
              <DropdownItem
                disabled={operationLoading}
                onClick={enableRenameGroup}
              >
                {formatMessage(messages.renameGroup)}
              </DropdownItem>
            )}
            {isGroupEditable && (
              <DropdownItem
                disabled={operationLoading}
                onClick={this.deleteGroup}
              >
                {formatMessage(messages.deleteGroup)}
              </DropdownItem>
            )}
            {readAllMessages && (
              <DropdownItem
                disabled={operationLoading}
                onClick={readAllMessages}
              >
                {formatMessage(messages.readAll)}
              </DropdownItem>
            )}
            <DropdownItem onClick={deleteAllMessages}>
              {formatMessage(messages.deleteAll)}
            </DropdownItem>
            {archiveConversation && (
              <DropdownItem onClick={archiveConversation}>
                {formatMessage(messages.archiveConversation)}
              </DropdownItem>
            )}
            {addMeToGroup && (
              <DropdownItem onClick={addMeToGroup}>
                {formatMessage(messages.addMeToGroup)}
              </DropdownItem>
            )}
            {removeMeFromGroup && (
              <DropdownItem onClick={removeMeFromGroup}>
                {formatMessage(messages.removeMeFromGroup)}
              </DropdownItem>
            )}
          </DropdownMenu>
        )}
      </Dropdown>
    );
  }
}

ChatOptionsDropdown.propTypes = {
  conversationId: PropTypes.string.isRequired,
  isGroupEditable: PropTypes.bool.isRequired,
  operationLoading: PropTypes.bool.isRequired,
  deleteAllMessages: PropTypes.func.isRequired,
  membersLoading: PropTypes.bool,
  readAllMessages: PropTypes.func,
  archiveConversation: PropTypes.func,
  enableRenameGroup: PropTypes.func,
  addMeToGroup: PropTypes.func,
  removeMeFromGroup: PropTypes.func,
};

ChatOptionsDropdown.defaultProps = {
  readAllMessages: null,
  archiveConversation: null,
  enableRenameGroup: null,
  addMeToGroup: null,
  removeMeFromGroup: null,
  membersLoading: false,
};

export default injectIntl(
  connect(null, {
    deleteGroup: deleteGroupRequest,
    confirm: showConfirmModal,
  })(ChatOptionsDropdown)
);
