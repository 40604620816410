import React from 'react';
import { injectIntl } from 'react-intl';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Tickets from './index';
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const TicketsListShortcut = React.lazy(() => import('./TicketsListShortcut'));
const TicketsList = React.lazy(() => import('./TicketsList'));
const TicketsListFilter = React.lazy(() => import('./TicketsListFilter'));
const TicketsListPage = (props) => {
  return (
    <Tickets>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <section>
            <div className="text-right">
              <HelpButton fileName="tickets-list" />
            </div>
            <TicketsListShortcut />
            <TicketsListFilter {...props} />
            <TicketsList {...props} />
          </section>
        </React.Suspense>
      </ErrorBoundary>
    </Tickets>
  );
};

export default injectIntl(TicketsListPage);
