import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import {
  getGroupMembersLoaded,
  isNormalGroupsMembersLoaded,
  getGroupMembers,
  getPinnedGroupsFilter,
  getGroupOwnerAlias,
} from '../../../js/groups/selectors';
import {
  updatePinnedGroupSettingsRequest,
  updatePinnedGroupMembersOrderRequest,
  fetchGroupMembersRequest,
  saveGroupRequest,
} from '../../../js/groups/actions';
import GroupToolbar from './GroupToolbar';
import MemberPanel from './MemberPanel';
import Loading from '../../../components/Loading';
import { getMeId } from '../../../js/me/selectors';
import { addFavoriteRequest } from '../../../js/me/actions';

const messages = defineMessages({
  favoriteGroupName: {
    id: 'GroupPanel.favoriteGroupName.',
    defaultMessage: 'Favorites',
  },
  mainGroupName: {
    id: 'GroupPanel.mainGroupName.',
    defaultMessage: 'All users',
  },
});

const style = {
  groupPanelHeader: {
    color: 'var(--yn-blue)',
    padding: '4px 10px 0 10px',
    cursor: 'pointer',
  },
  groupPanelHeaderTitle: {
    width: '93%',
    fontSize: '1em',
    lineHeight: '1.7rem',
  },
};

const MemberItem = SortableElement(
  ({ key, index, member, groupId, disabled }) => (
    <div>
      <MemberPanel
        key={key}
        index={index}
        id={member.id}
        type={member.type}
        groupId={groupId}
        disabled={disabled}
      />
    </div>
  )
);

const MembersList = SortableContainer(({ items, group, myId, filter }) => (
  <div>
    {items.map(
      (member, index) =>
        myId !== member.id && (
          <MemberItem
            key={member.id}
            index={index}
            member={member}
            groupId={group.id}
            disabled={!!filter}
          />
        )
    )}
  </div>
));

class GroupPanel extends Component {
  constructor(props) {
    super(props);
    this.onExited = this.onExited.bind(this);
    this.state = {
      collapse: props.group.status === 'NORMAL',
      openedOnSearching: true,
    };
  }

  componentDidMount() {
    if (this.props.group.status === 'NORMAL' || this.props.normalGroupsLoaded) {
      this.props.fetch(this.props.group.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.normalGroupsLoaded && this.props.normalGroupsLoaded) {
      if (!this.props.fetchedMembers) {
        this.props.fetch(this.props.group.id);
      }
    }
    if (this.props.group.status !== prevProps.group.status) {
      const group = {
        id: this.props.group.id,
        settings: {
          status: this.props.group.status === 'NORMAL' ? 'NORMAL' : 'MIN',
          hidden: false,
        },
      };
      if (group.settings.status === 'NORMAL') {
        this.setState({ collapse: true });
      }
    }
    if (!!prevProps.filterText && !this.props.filterText) {
      this.setState({ openedOnSearching: true });
    }
  }

  onExited() {
    this.setState({ collapse: false });
  }

  toggle = () => {
    if (this.props.filterText) {
      this.setState({ openedOnSearching: !this.state.openedOnSearching });
    } else {
      const group = {
        id: this.props.group.id,
        settings: {
          status: this.props.group.status === 'NORMAL' ? 'MIN' : 'NORMAL',
          hidden: false,
        },
      };
      if (group.settings.status === 'NORMAL') {
        this.setState({ collapse: true });
      }
      this.props.updateSettings(group);
    }
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const groupId = this.props.group.id;
    const group = {
      id: groupId,
      settings: {
        membersOrder: arrayMove(this.props.members, oldIndex, newIndex),
      },
    };
    this.props.updateMembersOrder(group);
  };

  addFavorites = (newMembers) => {
    if (newMembers.length > 0) {
      newMembers.forEach((m) =>
        this.props.addFavorite({
          type: m.type,
          id: m.id,
        })
      );
    }
  };

  render() {
    const {
      myId,
      group,
      owner,
      members,
      filterText,
      fetchedMembers,
      intl: { formatMessage },
    } = this.props;

    if (group.favorites) group.name = formatMessage(messages.favoriteGroupName);
    if (group.main) group.name = formatMessage(messages.mainGroupName);
    let filteredMembers;
    if (group.name.toLowerCase().indexOf(filterText) !== -1) {
      filteredMembers = members;
    } else {
      filteredMembers = members.filter(
        (member) =>
          (member.fullname &&
            member.fullname.toLowerCase().indexOf(filterText.toLowerCase()) >
              -1) ||
          (member.mainExtensionNumber &&
            member.mainExtensionNumber.indexOf(filterText.toLowerCase()) > -1)
      );
    }
    const groupBorderColor = group.main
      ? '1px solid var(--yn-border-color)'
      : group.favorites
      ? '3px solid var(--yn-favorites-group-color)'
      : `3px solid ${group.color}`;

    const visible =
      group.name &&
      !group.hidden &&
      (group.name.toLowerCase().indexOf(filterText) !== -1 ||
        filteredMembers.length > 0);
    const open = group.status === 'NORMAL' || !!filterText;

    return (
      <div
        className="m-1 rounded border-top border-right border-bottom bg-white"
        style={{
          display: visible ? 'block' : 'none',
          borderLeft: groupBorderColor,
        }}
      >
        <div
          className="text-left"
          style={style.groupPanelHeader}
          onClick={this.toggle}
        >
          <span
            className="text-truncate d-inline-block"
            style={style.groupPanelHeaderTitle}
          >
            {group.name}
          </span>
        </div>

        <Collapse
          tag="div"
          className="text-left border-top"
          isOpen={open}
          onExited={this.onExited}
        >
          {!fetchedMembers && group.status === 'NORMAL' && <Loading />}
          {((!!filterText && this.state.openedOnSearching) ||
            (!filterText && this.state.collapse)) && (
            <div>
              {fetchedMembers && (
                <GroupToolbar
                  private={group.private}
                  owner={owner}
                  id={group.id}
                  favorite={group.favorites}
                  chat={group.xmpp}
                  members={
                    group.favorites
                      ? members.map((m) => ({ id: m.id, type: m.type }))
                      : []
                  }
                  addFavorites={
                    group.favorites
                      ? (selected) => this.addFavorites(selected)
                      : null
                  }
                />
              )}
              {fetchedMembers &&
                (filteredMembers.length > 0 ||
                  group.name.toLowerCase().indexOf(filterText) !== -1) && (
                  <MembersList
                    group={group}
                    items={filteredMembers}
                    onSortEnd={this.handleSortEnd}
                    distance={1}
                    myId={myId}
                    filter={filterText}
                  />
                )}
            </div>
          )}
        </Collapse>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    members: getGroupMembers(state, ownProps.group.id),
    fetchedMembers: getGroupMembersLoaded(state, ownProps.group.id),
    normalGroupsLoaded: isNormalGroupsMembersLoaded(state),
    filterText: getPinnedGroupsFilter(state),
    owner: getGroupOwnerAlias(state, ownProps.group.id),
    myId: getMeId(state),
  };
}

GroupPanel.propTypes = {
  members: PropTypes.array.isRequired,
  filterText: PropTypes.string,
};

GroupPanel.defaultProps = {
  filterText: '',
};

export default injectIntl(
  connect(mapStateToProps, {
    fetch: fetchGroupMembersRequest,
    updateSettings: updatePinnedGroupSettingsRequest,
    updateMembersOrder: updatePinnedGroupMembersOrderRequest,
    saveGroup: saveGroupRequest,
    addFavorite: addFavoriteRequest,
  })(GroupPanel)
);
