import { request } from '../request';
import ynConf from '../../conf';

export default {
  deleteRecordedCall: (filename) =>
    request(
      {
        url: `/delete/${filename}`,
        method: 'GET',
      },
      {
        baseUrl: ynConf.recCallBaseUrl,
      }
    ),
  deleteOwnCall: (callId) =>
    request({
      url: `/phone/own-deleted-calls/${callId}`,
      method: 'PUT',
    }),
  retrieveOwnDeletedCalls: () =>
    request({
      url: `/phone/own-deleted-calls`,
      method: 'GET',
    }),
  deleteOtherCall: (callId) =>
    request({
      url: `/phone/other-deleted-calls/${callId}`,
      method: 'PUT',
    }),
  retrieveOtherDeletedCalls: () =>
    request({
      url: `/phone/other-deleted-calls`,
      method: 'GET',
    }),
  retrieveCallsNotes: () =>
    request({
      url: `/phone/calls-notes`,
      method: 'GET',
    }),
  insertCallsNotes: (data) =>
    request({
      url: `/phone/calls-notes`,
      method: 'POST',
      data,
    }),
};
