import findKey from 'lodash.findkey';
import keyBy from 'lodash.keyby';
import pull from 'lodash.pull';
import merge from 'lodash.merge';
import { LOGOUT_SUCCESS } from '../auth/types';
import {
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAILURE,
  REMOVE_FAVORITE_REQUEST,
  REMOVE_FAVORITE_SUCCESS,
  REMOVE_FAVORITE_FAILURE,
} from '../me/types';
import {
  FETCH_PINNED_GROUPS_REQUEST,
  FETCH_PINNED_GROUPS_SUCCESS,
  FETCH_ADMIN_GROUPS_REQUEST,
  FETCH_ADMIN_GROUPS_SUCCESS,
  FETCH_ADMIN_GROUPS_FAILURE,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILURE,
  SAVE_GROUP_REQUEST,
  SAVE_GROUP_SUCCESS,
  SAVE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  UPDATE_PINNED_GROUPS_ORDER_REQUEST,
  UPDATE_PINNED_GROUPS_ORDER_SUCCESS,
  UPDATE_PINNED_GROUPS_ORDER_FAILURE,
  UPDATE_PINNED_GROUP_SETTINGS_REQUEST,
  UPDATE_PINNED_GROUP_SETTINGS_SUCCESS,
  UPDATE_PINNED_GROUP_SETTINGS_FAILURE,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS,
  UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPDATE_GROUP_SETTINGS_SUCCESS,
  UPDATE_GROUP_SETTINGS_FAILURE,
  SET_GROUPS_FILTER,
  SET_PINNED_GROUPS_FILTER,
  FETCH_GROUP_MEMBERS_REQUEST,
  FETCH_GROUP_MEMBERS_SUCCESS,
  FETCH_GROUP_MEMBERS_FAILURE,
  GROUP_INSERT_NOTIFICATION,
  GROUP_UPDATE_NOTIFICATION,
  GROUP_DELETE_NOTIFICATION,
  UPDATE_CHAT_GROUP_SUCCESS,
  SET_FAVORITES_GROUP_SCROLL,
} from './types';
import { RECEIVE_CHAT_MESSAGE } from '../chat/types';
import { USER_UPDATE_NOTIFICATION } from '../users/types';
import { ChatEnums } from '../chat/ChatUtils';
import { GroupsEnums } from './GroupsUtils';
import {
  SAVE_ABOOK_CONTACT_SUCCESS,
  ABOOK_UPDATE_NOTIFICATION,
  ABOOK_DELETE_NOTIFICATION,
} from '../contacts/types';

const initialState = {
  fetchPinnedGroupsLoaded: true,
  fetchPinnedGroupsError: null,
  fetchAdminGroupsLoaded: true,
  fetchAdminGroupsError: null,
  fetchGroupLoaded: true,
  fetchGroupError: null,
  saveGroupLoaded: true,
  saveGroupError: null,
  deleteGroupLoaded: true,
  deleteGroupError: null,
  updateGroupSettingsLoaded: true,
  updateGroupSettingsError: null,
  updatePinnedGroupsSettings: {
    loaded: true,
    error: null,
    old: [],
  },
  toggleFavorite: {
    id: null,
    loaded: true,
    error: null,
  },
  byId: {},
  allIds: [],
  pages: {},
  currentPage: 0,
  total: 0,
  filterAdmin: {
    public: true,
    private: true,
    name: '',
  },
  filterPinned: '',
  fetchMembers: {},
  favoritesGroupScroll: false,
};

export default function groups(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FETCH_PINNED_GROUPS_REQUEST:
      return {
        ...state,
        fetchPinnedGroupsLoaded: false,
        fetchPinnedGroupsError: null,
      };
    case FETCH_PINNED_GROUPS_SUCCESS:
      return {
        ...state,
        byId: keyBy(action.groups, 'id'),
        allIds: action.groups.map((obj) => obj.id),
        fetchPinnedGroupsLoaded: true,
        fetchPinnedGroupsError: null,
      };
    case FETCH_ADMIN_GROUPS_REQUEST:
      return {
        ...state,
        fetchAdminGroupsLoaded: false,
        fetchAdminGroupsError: null,
      };
    case FETCH_ADMIN_GROUPS_SUCCESS: {
      const newById = keyBy(
        action.payload.groups.map((group) => ({
          ...state.byId[group.id],
          ...group,
        })),
        'id'
      );
      const ids = action.payload.groups.map((obj) => obj.id);
      const pages = {
        ...state.pages,
        [action.payload.page]: ids,
      };
      return {
        ...state,
        byId: {
          ...state.byId,
          ...newById,
        },
        pages,
        currentPage: action.payload.page,
        total: action.payload.total,
        fetchAdminGroupsLoaded: true,
        fetchAdminGroupsError: null,
      };
    }
    case FETCH_ADMIN_GROUPS_FAILURE:
      return {
        ...state,
        fetchAdminGroupsLoaded: true,
        fetchAdminGroupsError: action.errors,
      };
    case SET_GROUPS_FILTER:
      return {
        ...state,
        filterAdmin: action.filter,
      };
    case FETCH_GROUP_REQUEST:
      return {
        ...state,
        fetchGroupLoaded: false,
        fetchGroupError: null,
      };
    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.group.id]: {
            ...state.byId[action.group.id],
            ...action.group,
          },
        },
        fetchGroupLoaded: true,
        fetchGroupError: null,
      };
    case FETCH_GROUP_FAILURE:
      return {
        ...state,
        fetchGroupLoaded: true,
        fetchGroupError: action.errors,
      };
    case SAVE_GROUP_REQUEST:
      return {
        ...state,
        saveGroupLoaded: false,
        saveGroupError: null,
      };
    case SAVE_GROUP_SUCCESS: {
      const group = { ...action.group };
      delete group.members;
      const allIds = [...state.allIds];
      if (allIds.indexOf(action.group.id) < 0) {
        allIds.splice(state.allIds.length - 1, 0, action.group.id);
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.group.id]: {
            ...state.byId[action.group.id],
            ...group,
          },
        },
        fetchMembers: {
          ...state.fetchMembers,
          [action.group.id]: {
            ...state.fetchMembers[action.group.id],
            members: action.group.members,
          },
        },
        allIds,
        saveGroupLoaded: true,
        saveGroupError: null,
      };
    }
    case SAVE_GROUP_FAILURE:
      return {
        ...state,
        saveGroupLoaded: true,
        saveGroupError: action.errors,
      };
    case UPDATE_CHAT_GROUP_SUCCESS: {
      const { members } = state.fetchMembers[action.payload.groupId];
      let newMembers = [];
      if (
        action.payload.addedMembers &&
        action.payload.addedMembers.length > 0
      ) {
        newMembers = [
          ...members,
          ...action.payload.addedMembers.filter(
            (addedMember) =>
              state.fetchMembers[action.payload.groupId].members.indexOf(
                addedMember
              ) < 0
          ),
        ];
      } else if (action.payload.removedMembers) {
        newMembers = members.filter(
          (member) => action.payload.removedMembers.indexOf(member.id) < 0
        );
      } else {
        newMembers = members;
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.groupId]: {
            ...state.byId[action.payload.groupId],
            name:
              action.payload.name || state.byId[action.payload.groupId].name,
          },
        },
        fetchMembers: {
          ...state.fetchMembers,
          [action.payload.groupId]: {
            ...state.fetchMembers[action.payload.groupId],
            members: newMembers,
          },
        },
      };
    }
    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroupLoaded: false,
        deleteGroupError: null,
      };
    case DELETE_GROUP_SUCCESS: {
      const byId = { ...state.byId };
      const allIds = [...state.allIds];
      let pages = { ...state.pages };
      delete byId[action.groupId];
      pull(allIds, action.groupId);
      if (pages[state.currentPage]) {
        const currentPageIds = pages[state.currentPage];
        pull(currentPageIds, action.groupId);
        pages = {
          ...pages,
          [state.currentPage]: [...currentPageIds],
        };
      }
      return {
        ...state,
        byId,
        allIds,
        pages,
        deleteGroupLoaded: true,
        deleteGroupError: null,
      };
    }
    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        deleteGroupLoaded: false,
        deleteGroupError: action.errors,
      };
    // changing pinned groups order, members order in a group and if a group is
    // open/closed
    case UPDATE_PINNED_GROUPS_ORDER_REQUEST:
      return {
        ...state,
        allIds: action.groups,
        updatePinnedGroupsSettings: {
          loaded: false,
          error: null,
          old: state.allIds,
        },
      };
    case UPDATE_PINNED_GROUP_SETTINGS_REQUEST:
      return {
        ...state,
        updatePinnedGroupsSettings: {
          loaded: false,
          error: null,
        },
      };
    case UPDATE_PINNED_GROUP_MEMBERS_ORDER_REQUEST:
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [action.group.id]: {
            ...state.fetchMembers[action.group.id],
            old: state.fetchMembers[action.group.id].members,
            members: action.group.settings.membersOrder,
          },
        },
        updatePinnedGroupsSettings: {
          loaded: false,
          error: null,
        },
      };
    // changing pinned groups order
    case UPDATE_PINNED_GROUPS_ORDER_SUCCESS:
      return {
        ...state,
        updatePinnedGroupsSettings: {
          loaded: true,
          error: null,
          old: null,
        },
      };
    // changing a pinned group members order in a group and if a group is
    // open/closed
    case UPDATE_PINNED_GROUP_SETTINGS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.group.id]: {
            ...state.byId[action.group.id],
            status: action.group.settings.status,
            hidden: action.group.settings.hidden,
          },
        },
        updatePinnedGroupsSettings: {
          loaded: true,
          error: null,
        },
      };
    case UPDATE_PINNED_GROUP_MEMBERS_ORDER_SUCCESS: {
      const fetchMembers = {
        ...state.fetchMembers,
        [action.group.id]: {
          ...state.fetchMembers[action.group.id],
          old: null,
        },
      };
      return {
        ...state,
        fetchMembers,
        updatePinnedGroupsSettings: {
          loaded: true,
          error: null,
        },
      };
    }
    case UPDATE_PINNED_GROUPS_ORDER_FAILURE:
      return {
        ...state,
        allIds: state.updatePinnedGroupsSettings.old,
        updatePinnedGroupsSettings: {
          loaded: true,
          error: action.errors,
          old: null,
        },
      };
    case UPDATE_PINNED_GROUP_SETTINGS_FAILURE:
      return {
        ...state,
        updatePinnedGroupsSettings: {
          loaded: true,
          error: action.errors,
        },
      };
    case UPDATE_PINNED_GROUP_MEMBERS_ORDER_FAILURE:
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [action.payload.groupId]: {
            ...state.fetchMembers[action.payload.groupId],
            old: null,
            members: state.fetchMembers[action.payload.groupId].old,
          },
        },
        updatePinnedGroupsSettings: {
          loaded: true,
          error: action.payload.errors,
        },
      };
    // changing if a group is pinned or not (in admin groups section)
    case UPDATE_GROUP_SETTINGS_REQUEST:
      return {
        ...state,
        updateGroupSettingsLoaded: false,
        updateGroupSettingsError: null,
      };
    case UPDATE_GROUP_SETTINGS_SUCCESS: {
      const byId = {
        ...state.byId,
        [action.group.id]: {
          ...state.byId[action.group.id],
          hidden: action.group.settings.hidden,
        },
      };
      return {
        ...state,
        byId,
        updateGroupSettingsLoaded: true,
        updateGroupSettingsError: null,
      };
    }
    case UPDATE_GROUP_SETTINGS_FAILURE:
      return {
        ...state,
        updateGroupSettingsLoaded: true,
        updateGroupSettingsError: action.errors,
      };
    case SET_PINNED_GROUPS_FILTER:
      return {
        ...state,
        filterPinned: action.filter,
      };
    case ADD_FAVORITE_REQUEST:
      return {
        ...state,
        toggleFavorite: {
          id: action.member.id,
          loaded: false,
          error: null,
        },
      };
    case ADD_FAVORITE_SUCCESS: {
      const favoritesId = findKey(state.byId, 'favorites');
      const members = state.fetchMembers[favoritesId].members.filter(
        (member) =>
          !(
            member.id === action.member.id && member.type === action.member.type
          )
      );
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [favoritesId]: {
            ...state.fetchMembers[favoritesId],
            members: [...members, action.member],
          },
        },
        toggleFavorite: {
          ...state.toggleFavorite,
          loaded: true,
          error: null,
        },
      };
    }
    case ADD_FAVORITE_FAILURE:
      return {
        ...state,
        toggleFavorite: {
          loaded: true,
          error: action.errors,
        },
      };
    case REMOVE_FAVORITE_REQUEST:
      return {
        ...state,
        toggleFavorite: {
          id: action.member.id,
          loaded: false,
          error: null,
        },
      };
    case REMOVE_FAVORITE_SUCCESS: {
      const favoritesId = findKey(state.byId, 'favorites');
      const members = state.fetchMembers[favoritesId].members.filter(
        (member) =>
          !(
            member.id === action.member.id && member.type === action.member.type
          )
      );
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [favoritesId]: {
            ...state.fetchMembers[favoritesId],
            members: [...members],
          },
        },
        toggleFavorite: {
          ...state.toggleFavorite,
          loaded: true,
          error: null,
        },
      };
    }
    case REMOVE_FAVORITE_FAILURE:
      return {
        ...state,
        toggleFavorite: {
          loaded: true,
          error: action.errors,
        },
      };
    case FETCH_GROUP_MEMBERS_REQUEST:
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [action.groupId]: {
            loaded: false,
            error: null,
            members: [],
          },
        },
      };
    case FETCH_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [action.group.id]: {
            ...state.fetchMembers[action.group.id],
            loaded: true,
            error: null,
            members: action.group.members,
          },
        },
      };
    case FETCH_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        fetchMembers: {
          ...state.fetchMembers,
          [action.groupId]: {
            ...state.fetchMembers[action.group.id],
            loaded: true,
            error: action.errors,
          },
        },
      };
    case GROUP_INSERT_NOTIFICATION: {
      const { allIds } = state;
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      if (allIds.indexOf(data.idEntity) < 0) {
        allIds.splice(allIds.length - 1, 0, data.idEntity);
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.idEntity]: {
            id: data.idEntity,
            name: data.name,
            color: data.color,
            xmpp: data.xmpp,
            private: data.private,
            owner: data.idUserAction,
            favorites: false,
            hidden: false,
            main: false,
            mine: false,
          },
        },
        allIds,
      };
    }
    case GROUP_UPDATE_NOTIFICATION: {
      const updatedFields = {};
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      if (data.color) {
        updatedFields.color = data.color;
      }
      if (data.name) {
        updatedFields.name = data.name;
      }
      if (data.xmpp) {
        updatedFields.xmpp = data.xmpp;
      }
      if (!state.fetchMembers[data.idEntity]) {
        return state;
      }
      let { members } = state.fetchMembers[data.idEntity];
      if (data.membersAdded && data.membersAdded.length > 0) {
        data.membersAdded.forEach((member) => {
          members
            .filter((m) => m.type === member.type || m.id === member.id)
            .push(member);
        });
      }
      if (data.membersRemoved && data.membersRemoved.length > 0) {
        data.membersRemoved.forEach((member) => {
          members = members.filter(
            (m) => m.type !== member.type || m.id !== member.id
          );
        });
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.idEntity]: merge(state.byId[data.idEntity], updatedFields),
        },
        fetchMembers: {
          ...state.fetchMembers,
          [data.idEntity]: {
            ...state.fetchMembers[data.idEntity],
            members,
          },
        },
      };
    }
    case GROUP_DELETE_NOTIFICATION: {
      if (action.payload.archived) {
        return state;
      }
      const byId = { ...state.byId };
      const allIds = [...state.allIds];
      const fetchedMembers = { ...state.fetchedMembers };
      delete byId[action.payload.idEntity];
      delete fetchedMembers[action.payload.idEntity];
      pull(allIds, action.payload.idEntity);
      const pages = { ...state.pages };
      if (state.pages[state.currentPage]) {
        const currentPageIds = [...state.pages[state.currentPage]];
        pull(currentPageIds, action.idEntity);
        pages[state.currentPage] = [...currentPageIds];
      }
      return {
        ...state,
        byId,
        allIds,
        pages,
      };
    }
    case ABOOK_UPDATE_NOTIFICATION: {
      const { fetchMembers } = state;
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      if (data.groupsAdded && data.groupsAdded.length > 0) {
        data.groupsAdded.forEach((groupId) => {
          fetchMembers[groupId] &&
            fetchMembers[groupId].members &&
            fetchMembers[groupId].members.push({
              type: 'A',
              id: action.payload.idEntity,
            });
        });
      }
      if (data.groupsRemoved && data.groupsRemoved.length > 0) {
        data.groupsRemoved.forEach((groupId) => {
          if (fetchMembers[groupId] && fetchMembers[groupId].members) {
            fetchMembers[groupId].members = fetchMembers[
              groupId
            ].members.filter((member) => {
              return (
                member.type !== 'A' || member.id !== action.payload.idEntity
              );
            });
          }
        });
      }
      return {
        ...state,
        fetchMembers: {
          ...fetchMembers,
        },
      };
    }
    case ABOOK_DELETE_NOTIFICATION: {
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      const fetchMembers = {};

      Object.keys(state.fetchMembers).forEach((key) => {
        fetchMembers[key] = {
          ...state.fetchMembers[key],
          members: state.fetchMembers[key].members.filter(
            (member) =>
              member.type === 'U' || member.id !== action.payload.idEntity
          ),
        };
      });
      return {
        ...state,
        fetchMembers,
      };
    }
    case USER_UPDATE_NOTIFICATION: {
      const { fetchMembers } = state;
      const data = action.payload;
      if (data.archived) {
        return state;
      }
      if (data.groupsAdded && data.groupsAdded.length > 0) {
        data.groupsAdded.forEach((groupId) => {
          fetchMembers[groupId] &&
            fetchMembers[groupId].members &&
            fetchMembers[groupId].members.push({
              type: 'U',
              id: action.payload.idEntity,
            });
        });
      }
      if (data.groupsRemoved && data.groupsRemoved.length > 0) {
        data.groupsRemoved.forEach((groupId) => {
          if (fetchMembers[groupId] && fetchMembers[groupId].members) {
            fetchMembers[groupId].members = fetchMembers[
              groupId
            ].members.filter((member) => {
              return (
                member.type !== 'U' || member.id !== action.payload.idEntity
              );
            });
          }
        });
      }
      return {
        ...state,
        fetchMembers: {
          ...fetchMembers,
        },
      };
    }
    case SET_FAVORITES_GROUP_SCROLL:
      return {
        ...state,
        favoritesGroupScroll: action.filter,
      };
    case RECEIVE_CHAT_MESSAGE: {
      if (
        !action.data.info ||
        (action.data.info.type !== ChatEnums.ChatInfoTypes.GROUP_CREATED &&
          action.data.info.type !== ChatEnums.ChatInfoTypes.USER_ADDED &&
          action.data.info.type !== ChatEnums.ChatInfoTypes.USER_REMOVED)
      ) {
        return state;
      }
      let { byId } = state;
      const allIds = [...state.allIds];
      let members = state.fetchMembers[action.data.interlocutor.group.groupId]
        ? state.fetchMembers[action.data.interlocutor.group.groupId].members
        : [];
      if (action.data.info.type === ChatEnums.ChatInfoTypes.GROUP_CREATED) {
        byId = {
          ...byId,
          [action.data.interlocutor.group.groupId]: {
            id: action.data.interlocutor.group.groupId,
            name: action.data.interlocutor.group.name,
            color: action.data.interlocutor.group.color,
            xmpp: true,
            private: action.data.interlocutor.group.private,
            owner: action.data.interlocutor.group.ownerId,
            hidden: false,
            favorites: false,
            main: false,
            mine: byId[action.data.interlocutor.group.groupId]
              ? byId[action.data.interlocutor.group.groupId].mine
              : false,
            status: GroupsEnums.GroupStatus.MIN,
          },
        };
        if (allIds.indexOf(action.data.interlocutor.group.groupId) < 0) {
          allIds.splice(
            allIds.length - 1,
            0,
            action.data.interlocutor.group.groupId
          );
        }
      }
      if (action.data.info.type === ChatEnums.ChatInfoTypes.USER_ADDED) {
        members = [
          ...members.filter(
            (m) => m.type !== 'U' || m.id !== action.data.info.user.userId
          ),
          { id: action.data.info.user.userId, type: 'U' },
        ];
      }
      if (action.data.info.type === ChatEnums.ChatInfoTypes.USER_REMOVED) {
        members = members.filter(
          (m) => m.type !== 'U' || m.id !== action.data.info.user.userId
        );
      }
      return {
        ...state,
        byId,
        allIds,
        fetchMembers: {
          ...state.fetchMembers,
          [action.data.interlocutor.group.groupId]: {
            ...state.fetchMembers[action.data.interlocutor.group.groupId],
            members,
          },
        },
      };
    }
    case SAVE_ABOOK_CONTACT_SUCCESS: {
      if (Object.keys(state.fetchMembers).length === 0) {
        return state;
      }
      const { fetchMembers } = state;
      Object.keys(fetchMembers).forEach((id) => {
        if (
          action.contactData.groups &&
          action.contactData.groups.length > 0 &&
          action.contactData.groups.indexOf(+id) > -1
        ) {
          if (
            fetchMembers[id].members.filter(
              (m) => m.type === 'A' && m.id === action.contactData.id
            ).length === 0
          ) {
            fetchMembers[id].members = [
              ...fetchMembers[id].members,
              { type: 'A', id: action.contactData.id },
            ];
          }
        } else {
          fetchMembers[id].members = fetchMembers[id].members.filter(
            (m) => !(m.type === 'A' && m.id === action.contactData.id)
          );
        }
      });
      return {
        ...state,
        fetchMembers: { ...fetchMembers },
      };
    }
    default:
      return state;
  }
}
