import React from 'react';
import JitsiVideocalls from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const JitsiVideocallExec = React.lazy(() => import('./JitsiVideocallExec'));

const JitsiVideocallExecPage = ({ location, isGuest }) => (
  <JitsiVideocalls>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="videocall-exec" />
          </div>
          <JitsiVideocallExec location={location} isGuest={isGuest} />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </JitsiVideocalls>
);

export default JitsiVideocallExecPage;
