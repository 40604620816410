import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import { FormGroup, Popover, PopoverBody } from 'reactstrap';
import matches from 'validator/lib/matches';
import isPostalCode from 'validator/lib/isPostalCode';
import Alert from '../../../components/messages/Alert';
import Button from '../../../components/formControls/Button';
import Label from '../../../components/formControls/Label';
import Input from '../../../components/formControls/Input';
import ToastMessage from '../../../components/messages/ToastMessage';
import Form from '../../../components/Form';
import {
  fetchSMSAliasRequest,
  saveSMSAliasRequest,
} from '../../../js/sms/actions';
import {
  getFetchSMSCreditsError,
  getFetchSMSCreditsLoaded,
  getSaveSMSAliasError,
  getSaveSMSAliasLoaded,
  getSMSAliasById,
  getSMSCredits,
} from '../../../js/sms/selectors';
import { fetchCustomerContractDataRequest } from '../../../js/documents/actions';
import { getCustomerAddress } from '../../../js/documents/selectors';
import Utils from '../../../js/lib/utils';
import CitiesSelect from '../../../components/formControls/addresses/CitiesSelect';
import { getCities } from '../../../js/contacts/selectors';
import Icon from '../../../components/icons/Icon';

const messages = defineMessages({
  alias: {
    id: 'SMSAliasEdit.label.alias',
    defaultMessage: 'Alias',
  },
  company: {
    id: 'SMSAliasEdit.label.company',
    defaultMessage: 'Company',
  },
  contactName: {
    id: 'SMSAliasEdit.label.contactName',
    defaultMessage: 'Contact name',
  },
  contactSurname: {
    id: 'SMSAliasEdit.label.contactSurname',
    defaultMessage: 'Contact surname',
  },
  fiscalCode: {
    id: 'SMSAliasEdit.label.fiscalCode',
    defaultMessage: 'Fiscal code',
  },
  vat: {
    id: 'SMSAliasEdit.label.vat',
    defaultMessage: 'Vat',
  },
  contactAddress: {
    id: 'SMSAliasEdit.label.contactAddress',
    defaultMessage: 'Address',
  },
  contactCity: {
    id: 'SMSAliasEdit.label.contactCity',
    defaultMessage: 'City',
  },
  contactPostalCode: {
    id: 'SMSAliasEdit.label.contactPostalCode',
    defaultMessage: 'Postal code',
  },
  contactPec: {
    id: 'SMSAliasEdit.label.contactPec',
    defaultMessage: 'PEC',
  },
  buttonSend: {
    id: 'SMSAliasEdit.button.send',
    defaultMessage: 'Send',
  },
  errorMessage: {
    id: 'SMSAliasEdit.errorMessage',
    defaultMessage: 'Error message',
  },
  NOT_ENOUGH_SMS: {
    id: 'SMSAliasEdit.NOT_ENOUGH_SMS',
    defaultMessage: 'Not enough SMS',
  },
  errorFetchingCredits: {
    id: 'SMSAliasEdit.errorFetchingCredits',
    defaultMessage:
      'There has been not possible to retrieve credits, so you cannot send SMS',
  },
  creditsAlertText: {
    id: 'SMSAliasEdit.creditsWarningText',
    defaultMessage: 'You have no more available SMS',
  },
  errorMissingField: {
    id: 'SMSAliasEdit.errorMissingAlias',
    defaultMessage: 'This field is mandatory',
  },
  errorWrongAlias: {
    id: 'SMSAliasEdit.errorWrongAlias',
    defaultMessage:
      'An alias can contain only letters (from a to z and from A to Z), numbers (from 0 to 9) and spaces',
  },
  errorWrongVat: {
    id: 'SMSAliasEdit.errorWrongVat',
    defaultMessage: 'Please insert a valid VAT code',
  },
  errorWrongPostalCode: {
    id: 'SMSAliasEdit.errorWrongPostalCode',
    defaultMessage: 'Please insert a valid postal code',
  },
  errorWrongPec: {
    id: 'SMSAliasEdit.errorWrongPec',
    defaultMessage: 'Please insert a valid pec address',
  },
  infoAlias: {
    id: 'SMSAliasEdit.infoAlias',
    defaultMessage:
      'An alias must be maximum 11 characters and must contain only letters (lowercase and uppercase), numbers ans spaces.',
  },
  infoAliasTooltip: {
    id: 'SMSAliasEdit.tooltip.infoAlias',
    defaultMessage: 'Info about alias',
  },
  info0: {
    id: 'SMSAliasEdit.info0',
    defaultMessage: 'ATTENTION:',
  },
  info1: {
    id: 'SMSAliasEdit.info1',
    defaultMessage: 'the Alias must be a string of maximum 11 characters',
  },
  info2: {
    id: 'SMSAliasEdit.info2',
    defaultMessage: 'the Alias cannot be too much "generic"',
  },
  info3: {
    id: 'SMSAliasEdit.info3',
    defaultMessage:
      'the Alias must be strictly referred to the brand of the applicant',
  },
  info4: {
    id: 'SMSAliasEdit.info4',
    defaultMessage:
      'the PEC address must be the one of the applicant fiscal code',
  },
});

class SMSAliasEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      showPopover: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { aliasData, fetchItems, fetchCustomerData } = this.props;
    if (!aliasData) fetchItems();
    fetchCustomerData();
  }

  handleChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleCitySelect = (selected) => {
    const { cities } = this.props;
    if (selected) {
      const city = cities.filter((o) => o.id === selected.value)[0];
      this.setState({
        data: {
          ...this.state.data,
          contactCity: city.locality,
          contactPostalCode: city.postalcode,
        },
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          contactCity: '',
          contactPostalCode: '',
        },
      });
    }
  };

  handleCityChange = (contactCity) => {
    this.setState({
      data: {
        ...this.state.data,
        contactCity,
      },
    });
  };

  validate = (data) => {
    // check that:

    const {
      intl: { formatMessage },
    } = this.props;
    const errors = {};
    if (!data.alias) {
      errors.alias = formatMessage(messages.errorMissingField);
    }
    if (!data.contactName) {
      errors.contactName = formatMessage(messages.errorMissingField);
    }
    if (!data.contactSurname) {
      errors.contactSurname = formatMessage(messages.errorMissingField);
    }
    if (!data.company) {
      errors.company = formatMessage(messages.errorMissingField);
    }
    /* if (!data.fiscalCode) {
      errors.fiscalCode = formatMessage(messages.errorMissingField);
    } */
    if (!data.vat) {
      errors.vat = formatMessage(messages.errorMissingField);
    }
    if (!data.contactAddress) {
      errors.contactAddress = formatMessage(messages.errorMissingField);
    }
    if (!data.contactCity) {
      errors.contactCity = formatMessage(messages.errorMissingField);
    }
    if (!data.contactPostalCode) {
      errors.contactPostalCode = formatMessage(messages.errorMissingField);
    }
    if (!data.contactPec) {
      errors.contactPec = formatMessage(messages.errorMissingField);
    }
    const aliasPattern = new RegExp(/^[A-Za-z0-9 ]{1,11}$/);
    if (data.alias && !aliasPattern.test(data.alias)) {
      errors.alias = formatMessage(messages.errorWrongAlias);
    }
    if (data.vat && !matches(data.vat, Utils.VAT_NUMBER_REGEX)) {
      errors.vat = formatMessage(messages.errorWrongVat);
    }
    if (
      data.contactPostalCode &&
      !isPostalCode(data.contactPostalCode, 'any')
    ) {
      errors.contactPostalCode = formatMessage(messages.errorWrongPostalCode);
    }
    if (data.contactPec && !isEmail(data.contactPec)) {
      errors.contactPec = formatMessage(messages.errorWrongPec);
    }
    return errors;
  };

  saveAlias = (e) => {
    e.preventDefault();
    const { saveAlias } = this.props;
    const {
      data: { alias, contactName, contactSurname },
    } = this.state;
    const params = {
      alias,
      contactName,
      contactSurname,
      company: this.arrangeField('company', 'name'),
      fiscalCode: this.arrangeField('vat', 'vatnumber'),
      vat: this.arrangeField('vat', 'vatnumber'),
      contactAddress: this.arrangeField('contactAddress', 'address'),
      contactCity: this.arrangeField('contactCity', 'town'),
      contactPostalCode: this.arrangeField('contactPostalCode', 'postalcode'),
      contactPec: this.arrangeField('contactPec', 'pec'),
    };

    const errors = this.validate(params);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      saveAlias(params);
    }
  };

  arrangeField = (fieldState, fieldProps) => {
    const { customerData } = this.props;
    const { data } = this.state;
    if (data[fieldState] !== undefined) return data[fieldState];
    if (!customerData) return null;
    return customerData[fieldProps];
  };

  toggleInfoPopover = () => {
    this.setState({
      showPopover: !this.state.showPopover,
    });
  };

  render() {
    const {
      saving,
      errorSending,
      credits,
      fetchingCreditsError,
      creditsFetched,
      intl: { formatMessage },
    } = this.props;
    const {
      errors,
      showPopover,
      data: { alias, contactName, contactSurname },
    } = this.state;

    const company = this.arrangeField('company', 'name');
    const vat = this.arrangeField('vat', 'vatnumber');
    const contactAddress = this.arrangeField('contactAddress', 'address');
    const contactCity = this.arrangeField('contactCity', 'town');
    const contactPostalCode = this.arrangeField(
      'contactPostalCode',
      'postalcode'
    );
    const contactPec = this.arrangeField('contactPec', 'pec');

    return (
      <>
        {errorSending && (
          <ToastMessage
            type="danger"
            text={
              messages[errorSending]
                ? formatMessage(messages[errorSending])
                : formatMessage(messages.errorMessage)
            }
          />
        )}
        {errors.NOT_ENOUGH_SMS && (
          <ToastMessage
            type="danger"
            text={formatMessage(messages.NOT_ENOUGH_SMS)}
          />
        )}
        {fetchingCreditsError && (
          <Alert
            text={formatMessage(messages.errorFetchingCredits)}
            type="danger"
          />
        )}
        {creditsFetched && credits === 0 && (
          <Alert
            text={formatMessage(messages.creditsAlertText)}
            type="danger"
          />
        )}
        <Form
          className="col p-2 mt-2 rounded border"
          scrolling
          header
          onSubmit={this.saveAlias}
        >
          <div>
            {formatMessage(messages.info0)}
            <ul>
              <li>{formatMessage(messages.info1)}</li>
              <li>{formatMessage(messages.info2)}</li>
              <li>{formatMessage(messages.info3)}</li>
              <li>{formatMessage(messages.info4)}</li>
            </ul>
          </div>
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="alias" mandatory>
                  {formatMessage(messages.alias)}
                </Label>
                <span
                  id="aliasInfo"
                  onClick={() => this.toggleInfoPopover('privacy')}
                  className="ml-2"
                >
                  <Icon
                    name="information-circle"
                    width={20}
                    height={20}
                    style={{ top: '4px' }}
                  >
                    {formatMessage(messages.infoAliasTooltip)}
                  </Icon>
                </span>
                <Popover
                  placement="auto"
                  isOpen={showPopover}
                  toggle={this.toggleInfoPopover}
                  target="aliasInfo"
                  trigger="legacy"
                >
                  <PopoverBody>
                    <div>{formatMessage(messages.infoAlias)}</div>
                  </PopoverBody>
                </Popover>
                <Input
                  type="text"
                  name="alias"
                  id="alias"
                  onChange={this.handleChange}
                  value={alias}
                  maxLength={11}
                  error={errors.alias}
                  style={{ maxWidth: '200px' }}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="company" mandatory>
                  {formatMessage(messages.company)}
                </Label>
                <Input
                  type="text"
                  name="company"
                  id="company"
                  onChange={this.handleChange}
                  value={company}
                  error={errors.company}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="contactName" mandatory>
                  {formatMessage(messages.contactName)}
                </Label>
                <Input
                  type="text"
                  name="contactName"
                  id="contactName"
                  onChange={this.handleChange}
                  value={contactName}
                  error={errors.contactName}
                />
              </FormGroup>
            </div>
            <div className="col pt-2">
              <FormGroup>
                <Label for="contactSurname" mandatory>
                  {formatMessage(messages.contactSurname)}
                </Label>
                <Input
                  type="text"
                  name="contactSurname"
                  id="contactSurname"
                  onChange={this.handleChange}
                  value={contactSurname}
                  error={errors.contactSurname}
                />
              </FormGroup>
            </div>
          </div>
          {/* <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="fiscalCode" mandatory>
                  {formatMessage(messages.fiscalCode)}
                </Label>
                <Input
                  type="text"
                  name="fiscalCode"
                  id="fiscalCode"
                  onChange={this.fiscalCode}
                  value={fiscalCode}
                  error={errors.fiscalCode}
                />
              </FormGroup>
            </div>
        </div> */}
          <div className="row">
            <div className="col-4 pt-2">
              <FormGroup>
                <Label for="vat" mandatory>
                  {formatMessage(messages.vat)}
                </Label>
                <Input
                  type="text"
                  name="vat"
                  id="vat"
                  onChange={this.handleChange}
                  value={vat}
                  error={errors.vat}
                />
              </FormGroup>
            </div>
            <div className="col pt-2">
              <FormGroup>
                <Label for="contactPec" mandatory>
                  {formatMessage(messages.contactPec)}
                </Label>
                <Input
                  type="text"
                  name="contactPec"
                  id="contactPec"
                  onChange={this.handleChange}
                  value={contactPec}
                  error={errors.contactPec}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="contactAddress" mandatory>
                  {formatMessage(messages.contactAddress)}
                </Label>
                <Input
                  type="text"
                  name="contactAddress"
                  id="contactAddress"
                  onChange={this.handleChange}
                  value={contactAddress}
                  error={errors.contactAddress}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col pt-2">
              <FormGroup>
                <Label for="contactCity" mandatory>
                  {formatMessage(messages.contactCity)}
                </Label>
                <CitiesSelect
                  selected={contactCity}
                  onSelect={this.handleCitySelect}
                  onChange={(city) => this.handleCityChange(city)}
                  error={errors !== undefined ? errors.contactCity : null}
                />
                {/*<Input
                  type="text"
                  name="contactCity"
                  id="contactCity"
                  onChange={this.handleChange}
                  value={contactCity}
                  error={errors.contactCity}
                />*/}
              </FormGroup>
            </div>
            <div className="col-3 pt-2">
              <FormGroup>
                <Label for="contactPostalCode" mandatory>
                  {formatMessage(messages.contactPostalCode)}
                </Label>
                <Input
                  type="text"
                  name="contactPostalCode"
                  id="contactPostalCode"
                  onChange={this.handleChange}
                  value={contactPostalCode}
                  error={errors.contactPostalCode}
                />
              </FormGroup>
            </div>
          </div>
          <div className="pt-4 pb-4 text-right">
            <Button
              className="btn btn-primary"
              loading={saving}
              text={formatMessage(messages.buttonSend)}
              disabled={credits === 0}
            />
          </div>
        </Form>
      </>
    );
  }
}

SMSAliasEdit.propTypes = {
  id: PropTypes.number,
  scheduled: PropTypes.bool,
};

SMSAliasEdit.defaultProps = {
  scheduled: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    saving: !getSaveSMSAliasLoaded(state),
    errorSending: getSaveSMSAliasError(state),
    fetchingCredits: !getFetchSMSCreditsLoaded(state),
    fetchingCreditsError: getFetchSMSCreditsError(state),
    credits: getSMSCredits(state),
    aliasData: ownProps.id && getSMSAliasById(state, ownProps.id),
    customerData: getCustomerAddress(state),
    cities: getCities(state),
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    saveAlias: saveSMSAliasRequest,
    fetchItems: fetchSMSAliasRequest,
    fetchCustomerData: fetchCustomerContractDataRequest,
  })(SMSAliasEdit)
);
