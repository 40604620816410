import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  message: {
    id: 'ErrorBoundaryChat.message',
    defaultMessage:
      'There has been an error. Please close chat window and reopen it.',
  },
});

class ErrorBoundaryChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="alert alert-danger p-1 m-0 text-center"
          style={{
            height: `${this.props.height}px`,
            position: 'absolute',
            bottom: this.props.bottom,
            left: 0,
            width: '100%',
            overflowY: 'auto',
            fontSize: '0.85em',
          }}
        >
          {this.props.intl.formatMessage(messages.message)}
        </div>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundaryChat);
