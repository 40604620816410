import { request } from '../request';

export default {
  getAdminGroups: (params) =>
    request({
      url: '/groups',
      method: 'GET',
      params,
    }),
  getGroup: (groupId) =>
    request({
      url: `/groups/${groupId}`,
      method: 'GET',
    }),
  createGroup: (group) =>
    request({
      url: '/groups',
      method: 'POST',
      data: group,
    }),
  updateGroup: (group) =>
    request({
      url: `/groups/${group.id}`,
      method: 'POST',
      data: group,
    }),
  updateChatGroup: (params) =>
    request({
      url: `/groups/${params.id}/chat-update`,
      method: 'POST',
      data: {
        name: params.name,
        addedMembers: params.addedMembers,
        removedMembers: params.removedMembers,
      },
    }),
  deleteGroup: (groupId) =>
    request({
      url: `/groups/${groupId}`,
      method: 'DELETE',
    }),
};
