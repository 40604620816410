/** Fetch Stickies */
export const FETCH_STICKY_NOTES_REQUEST = 'FETCH_STICKY_NOTES_REQUEST';
export const FETCH_STICKY_NOTES_SUCCESS = 'FETCH_STICKY_NOTES_SUCCESS';
export const FETCH_STICKY_NOTES_FAILURE = 'FETCH_STICKY_NOTES_FAILURE';
/** Add Stickies */
export const ADD_STICKY_NOTES_REQUEST = 'ADD_STICKY_NOTES_REQUEST';
export const ADD_STICKY_NOTES_SUCCESS = 'ADD_STICKY_NOTES_SUCCESS';
export const ADD_STICKY_NOTES_FAILURE = 'ADD_STICKY_NOTES_FAILURE';
/** Delete Sticky */
export const DELETE_STICKY_NOTE_REQUEST = 'DELETE_STICKY_NOTE_REQUEST';
export const DELETE_STICKY_NOTE_SUCCESS = 'DELETE_STICKY_NOTE_SUCCESS';
export const DELETE_STICKY_NOTE_FAILURE = 'DELETE_STICKY_NOTE_FAILURE';
/** Update Sticky */
export const UPDATE_STICKY_VISIBILITY_REQUEST =
  'UPDATE_STICKY_VISIBILITY_REQUEST';
export const UPDATE_STICKY_VISIBILITY_SUCCESS =
  'UPDATE_STICKY_VISIBILITY_SUCCESS';
export const UPDATE_STICKY_VISIBILITY_FAILURE =
  'UPDATE_STICKY_VISIBILITY_FAILURE';
/** Modify Sticky */
export const UPDATE_STICKY_REQUEST = 'UPDATE_STICKY_REQUEST';
export const UPDATE_STICKY_SUCCESS = 'UPDATE_STICKY_SUCCESS';
export const UPDATE_STICKY_FAILURE = 'UPDATE_STICKY_FAILURE';
/* Share Sticky */
export const SHARE_STICKY_REQUEST = 'SHARE_STICKY_REQUEST';
export const SHARE_STICKY_SUCCESS = 'SHARE_STICKY_SUCCESS';
export const SHARE_STICKY_FAILURE = 'SHARE_STICKY_FAILURE';
/* Fetch Static Notes */
export const FETCH_STATIC_NOTES_REQUEST = 'FETCH_STATIC_NOTES_REQUEST';
export const FETCH_STATIC_NOTES_SUCCESS = 'FETCH_STATIC_NOTES_SUCCESS';
export const FETCH_STATIC_NOTES_FAILURE = 'FETCH_STATIC_NOTES_FAILURE';
export const CREATE_STATIC_NOTE_REQUEST = 'CREATE_STATIC_NOTE_REQUEST';
export const CREATE_STATIC_NOTE_SUCCESS = 'CREATE_STATIC_NOTE_SUCCESS';
export const CREATE_STATIC_NOTE_FAILURE = 'CREATE_STATIC_NOTE_FAILURE';
export const UPDATE_STATIC_NOTES_ORDER_REQUEST =
  'UPDATE_STATIC_NOTES_ORDER_REQUEST';
export const UPDATE_STATIC_NOTES_ORDER_SUCCESS =
  'UPDATE_STATIC_NOTES_ORDER_SUCCESS';
export const UPDATE_STATIC_NOTES_ORDER_FAILURE =
  'UPDATE_STATIC_NOTES_ORDER_FAILURE';
export const NOTES_CLEAR_ERRORS = 'NOTES_CLEAR_ERRORS';
export const EDIT_STATIC_NOTE_REQUEST = 'EDIT_STATIC_NOTE_REQUEST';
export const EDIT_STATIC_NOTE_SUCCESS = 'EDIT_STATIC_NOTE_SUCCESS';
export const EDIT_STATIC_NOTE_FAILURE = 'EDIT_STATIC_NOTE_FAILURE';
export const REMOVE_STATIC_NOTE_REQUEST = 'REMOVE_STATIC_NOTE_REQUEST';
export const REMOVE_STATIC_NOTE_SUCCESS = 'REMOVE_STATIC_NOTE_SUCCESS';
export const REMOVE_STATIC_NOTE_FAILURE = 'REMOVE_STATIC_NOTE_FAILURE';
export const SHARE_STICKY_NOTIFICATION = 'SHARE_STICKY_NOTIFICATION';
export const EDIT_STICKY_NOTIFICATION = 'EDIT_STICKY_NOTIFICATION';
export const DELETE_STICKY_NOTIFICATION = 'DELETE_STICKY_NOTIFICATION';
