import {
  REMOVE_DESKTOP_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  ARCHIVE_NOTIFICATION,
  DELETE_NOTIFICATION,
  FETCH_ARCHIVED_NOTIFICATIONS_REQUEST,
  FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS,
  FETCH_ARCHIVED_NOTIFICATIONS_FAILURE,
  REMOVE_INCOMING_VIDEOCALL_NOTIFICATION,
  ADD_LOST_VIDEOCALL_NOTIFICATION,
  REMOVE_LOST_VIDEOCALL_NOTIFICATION,
} from './types';

export const removeDesktopNotification = (payload) => ({
  type: REMOVE_DESKTOP_NOTIFICATION,
  payload,
});
export const readNotification = (payload) => ({
  type: READ_NOTIFICATION,
  payload,
});
export const archiveNotification = (payload) => ({
  type: ARCHIVE_NOTIFICATION,
  payload,
});
export const deleteNotification = (payload) => ({
  type: DELETE_NOTIFICATION,
  payload,
});
export const readAllNotifications = (section) => ({
  type: READ_ALL_NOTIFICATIONS,
  section,
});
export const deleteAllNotifications = (section) => ({
  type: DELETE_ALL_NOTIFICATIONS,
  section,
});
export const fetchArchivedNotificationsRequest = (payload) => ({
  type: FETCH_ARCHIVED_NOTIFICATIONS_REQUEST,
  payload,
});
export const fetchArchivedNotificationsSuccess = (payload) => ({
  type: FETCH_ARCHIVED_NOTIFICATIONS_SUCCESS,
  payload,
});
export const fetchArchivedNotificationsFailure = (payload) => ({
  type: FETCH_ARCHIVED_NOTIFICATIONS_FAILURE,
  payload,
});
export const removeIncomingVideocallNotification = (notificationId) => ({
  type: REMOVE_INCOMING_VIDEOCALL_NOTIFICATION,
  notificationId,
});
export const addLostVideocallNotification = (payload) => ({
  type: ADD_LOST_VIDEOCALL_NOTIFICATION,
  payload,
});
export const removeLostVideocallNotification = (notificationId) => ({
  type: REMOVE_LOST_VIDEOCALL_NOTIFICATION,
  notificationId,
});
