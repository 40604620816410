import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../../components/formControls/Button';
import { getChatMessageById } from '../../../js/chat/selectors';
import ynConf from '../../../conf';
import ImportFileToFileboxButton from '../../../components/ImportFileToFileboxButton';

const messages = defineMessages({
  buttonClose: {
    id: 'ChatFilePreviewModal.button.buttonClose',
    defaultMessage: 'Close',
  },
  buttonDownload: {
    id: 'ChatFilePreviewModal.button.buttonDownload',
    defaultMessage: 'Download',
  },
  altImage: {
    id: 'ChatFilePreviewModal.label.altImage',
    defaultMessage: 'File preview',
  },
});

const ChatFilePreviewModal = ({
  open,
  toggle,
  message,
  intl: { formatMessage },
}) => (
  <div>
    <Modal size="lg" backdrop isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>{message.file.name}</ModalHeader>
      <ModalBody className="text-center bg-light">
        <img
          className="img-fluid border"
          src={
            message.file.thumbnail
              ? `data:image/png;base64, ${message.file.thumbnail}`
              : `${ynConf.fileBaseUrl}/chat/download/${message.file.stored}`
          }
          alt={formatMessage(messages.altImage)}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggle}
          className="btn btn-outline-primary"
          text={formatMessage(messages.buttonClose)}
        />
        <ImportFileToFileboxButton
          stored={message.file.stored}
          context="chat"
          name={message.file.name}
          type="BUTTON"
        />
        <a
          className="btn btn-primary ml-2"
          href={`${ynConf.fileBaseUrl}/chat/download/${message.file.stored}`}
          download={message.file.stored}
        >
          {formatMessage(messages.buttonDownload)}
        </a>
      </ModalFooter>
    </Modal>
  </div>
);

ChatFilePreviewModal.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
};

ChatFilePreviewModal.defaultProps = {
  open: false,
};

function mapStateToProps(state, ownProps) {
  return {
    message: getChatMessageById(state, ownProps.messageId),
  };
}

export default injectIntl(connect(mapStateToProps)(ChatFilePreviewModal));
