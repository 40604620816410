import React from 'react';
import Mailoffice from '.';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const MailofficeArchiveList = React.lazy(() =>
  import('./MailofficeArchiveList')
);

const MailofficeArchivePage = () => (
  <Mailoffice scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="mailoffice-archive" />
          </div>
          <MailofficeArchiveList />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Mailoffice>
);

export default MailofficeArchivePage;
