import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Filebox from './index';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const FileboxList = React.lazy(() => import('./FileboxList'));

const FileboxFavoritesPage = (props) => (
  <Filebox>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <div className="text-right">
          <HelpButton fileName="filebox-favourite-files" />
        </div>
        <FileboxList favorites {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Filebox>
);

export default FileboxFavoritesPage;
