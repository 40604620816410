import { takeEvery, call, put } from 'redux-saga/effects';
import {
  /** Fetch Youtube info */
  fetchYoutubeInfoSuccess,
} from './actions';

import { FETCH_YOUTUBE_INFO_REQUEST } from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';

function* fetchYoutubeInfo(action) {
  try {
    const res = yield call(api.ext.getYoutubeInfo, action.youtubeCode);
    yield call(checkApiResponse, res);
    yield put(
      fetchYoutubeInfoSuccess({ code: action.youtubeCode, info: res.data })
    );
  } catch (err) {
    yield call(checkApiError, err);
  }
}

export default function* rootSaga() {
  yield takeEvery(FETCH_YOUTUBE_INFO_REQUEST, fetchYoutubeInfo);
}
