export const FETCH_ASSOCIATIONS_REQUEST = 'FETCH_ASSOCIATIONS_REQUEST';
export const FETCH_ASSOCIATIONS_SUCCESS = 'FETCH_ASSOCIATIONS_SUCCESS';
export const FETCH_ASSOCIATIONS_FAILURE = 'FETCH_ASSOCIATIONS_FAILURE';
export const DELETE_ASSOCIATION_REQUEST = 'DELETE_ASSOCIATION_REQUEST';
export const DELETE_ASSOCIATION_SUCCESS = 'DELETE_ASSOCIATION_SUCCESS';
export const DELETE_ASSOCIATION_FAILURE = 'DELETE_ASSOCIATION_FAILURE';
export const DELETE_MEMBER_ASSOCIATION_REQUEST =
  'DELETE_MEMBER_ASSOCIATION_REQUEST';
export const DELETE_MEMBER_ASSOCIATION_SUCCESS =
  'DELETE_MEMBER_ASSOCIATION_SUCCESS';
export const DELETE_MEMBER_ASSOCIATION_FAILURE =
  'DELETE_MEMBER_ASSOCIATION_FAILURE';
export const EDIT_MEMBER_ASSOCIATION_REQUEST =
  'EDIT_MEMBER_ASSOCIATION_REQUEST';
export const EDIT_MEMBER_ASSOCIATION_SUCCESS =
  'EDIT_MEMBER_ASSOCIATION_SUCCESS';
export const EDIT_MEMBER_ASSOCIATION_FAILURE =
  'EDIT_MEMBER_ASSOCIATION_FAILURE';
export const FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST =
  'FETCH_MEMBER_ASSOCIATION_DETAILS_REQUEST';
export const FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS =
  'FETCH_MEMBER_ASSOCIATION_DETAILS_SUCCESS';
export const FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE =
  'FETCH_MEMBER_ASSOCIATION_DETAILS_FAILURE';
