/* eslint-disable no-undef */
import React, { Component } from 'react';
import 'moment/locale/it';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import translations from './i18n/locales';
import history from './history';
import './styles/fonts/fonts.css';
import './styles/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import GuestRoute from './containers/routes/GuestRoute';
import LoginPage from './containers/pages/LoginPage';
import ForgotPasswordPage from './containers/pages/ForgotPasswordPage';
import PasswordResetPage from './containers/pages/PasswordResetPage';
import ToastMessage from './components/messages/ToastMessage';
import MainPage from './containers/pages/MainPage';
import VideocallGuestLogin from './containers/pages/VideocallGuestLogin';
import VideocallGuestExec from './containers/pages/VideocallGuestExec';
import { isAuthenticated } from './js/auth/selectors';
import {
  websocketDisconnection,
  loginWebsocket,
  logoutWebsocket,
} from './js/websocket/actions';
import WsManager from './js/websocket/WsManager';
import VisitorPage from './containers/pages/VisitorPage';
import VideocallLoginPage from './containers/pages/VideocallLoginPage';

/* if (process.env.NODE_ENV !== 'production') {
  const {whyDidYouUpdate} = require('why-did-you-update');
  whyDidYouUpdate(React);
} */

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      systemSleeping: false,
      callToExecUniqueId: null,
    };
    const videocallRoom =
      location.pathname.indexOf('/videocall/exec/') === 0 &&
      location.pathname.replace('/videocall/exec/', '').length > 0
        ? location.pathname.replace('/videocall/exec/', '')
        : null;
    if (videocallRoom && !props.isLogged) {
      sessionStorage.setItem('videocallRoom', videocallRoom);
    }
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFocus);
    const { location, isLogged } = this.props;
    const { ipcRenderer } = window;
    if (ipcRenderer) {
      ipcRenderer.on('open-url', (e, url) => {
        // console.log('open-url in componentDidMount', url);
        const numberToCall = url.replace('calls/exec?number=', '');
        // console.log('numberToCall', numberToCall);
        // console.log('state', checkLogged);
        if (numberToCall) {
          if (!isLogged) {
            // console.log('not logged');
            sessionStorage.setItem('numberToCall', numberToCall);
          } else {
            // console.log('logged', url);
            history.push(`/${url}`);
          }
        }
      });
    } else {
      const execCall = location.pathname === '/calls/exec';
      const numberToCall =
        location.search && location.search.replace('?number=', '');
      if (execCall && numberToCall && !isLogged) {
        sessionStorage.setItem('numberToCall', numberToCall);
      }
    }
    this.script = document.createElement('script');
    this.script.src = `https://8x8.vc/external_api.js`;
    document.body.appendChild(this.script);

    /**
     * PROGRESSIVE_WEB_APP resize
     */
    document.addEventListener('DOMContentLoaded', () => {
      const isBrowser = matchMedia('(display-mode: browser)').matches;
      if (!isBrowser) {
        if (!localStorage.getItem('opened')) {
          window.resizeTo(
            window.screen.width / 1.5,
            window.screen.height / 1.3
          );
          localStorage.setItem('opened', 'true');
        }
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { networkOnline } = this.props;
    if (prevProps.networkOnline !== networkOnline && !prevProps.networkOnline) {
      this.reconnectWs();
    }

    const { ipcRenderer } = window;
    if (ipcRenderer) {
      ipcRenderer.send(
        'auto-updater-channel',
        this.props.isBetaUser ? 'beta' : 'latest'
      );
      // console.log('Ws connection state: ',WsManager.connectionState())
      ipcRenderer.on('system-sleeping', () => {
        if (!this.state.systemSleeping) {
          console.log('System going to sleep');
          this.setState({ systemSleeping: true });
        }
      });
      ipcRenderer.on('system-resumed', () => {
        if (this.state.systemSleeping) {
          console.log('System resuming');
          console.log('Ws connection state: ', WsManager.connectionState());
          this.setState({ systemSleeping: false });
        }
      });
      ipcRenderer.on('open-url', (e, url, callToExecUniqueId) => {
        /* console.log('open-url in componentDidUpdate', url, callToExecUniqueId);
        console.log(
          'prevState.callToExecUniqueId',
          prevState.callToExecUniqueId
        );
        console.log(
          'this.state.callToExecUniqueId',
          this.state.callToExecUniqueId
        ); */
        if (
          callToExecUniqueId !== prevState.callToExecUniqueId &&
          callToExecUniqueId !== this.state.callToExecUniqueId
        ) {
          // console.log('opened url updated app', url);
          this.setState({ callToExecUniqueId });
          // console.log('history push ', url);
          history.push(`/${url}`);
        }
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
  }

  reconnectWs = () => {
    // TODO reconnect ws ?
    // console.log('Ws connection state: ', WsManager.connectionState());
    // this.props.wsClose()
  };

  onFocus = () => {
    // TODO reconnect ws
  };

  render() {
    const {
      location,
      isLogged,
      language,
      logoutError,
      // networkOnline,
    } = this.props;
    const messages = translations[language];
    const linkToVideocall =
      location.pathname.indexOf('/videocall/exec/') === 0 &&
      location.pathname.replace('/videocall/exec/', '').length > 0;

    return (
      <IntlProvider locale={language} messages={messages}>
        <DndProvider backend={HTML5Backend}>
          <>
            {logoutError ? <ToastMessage text={logoutError} /> : null}
            <Switch>
              <Route path="/visitor/:url" component={VisitorPage} />
              <GuestRoute
                location={location}
                path="/login"
                exact
                component={LoginPage}
              />
              <GuestRoute
                location={location}
                path="/password-reset"
                exact
                component={PasswordResetPage}
              />
              <GuestRoute
                location={location}
                path="/forgot-password"
                exact
                component={ForgotPasswordPage}
              />
              <GuestRoute
                path="/videocall/guest/login"
                component={VideocallGuestLogin}
              />
              <GuestRoute
                path="/videocall/guest/exec"
                component={VideocallGuestExec}
              />
              {isLogged ? (
                <MainPage />
              ) : linkToVideocall ? (
                <GuestRoute component={VideocallLoginPage} />
              ) : (
                <GuestRoute component={LoginPage} />
              )}
            </Switch>
          </>
        </DndProvider>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
};

function mapStateToProps(state) {
  return {
    language: state.settings.language,
    isLogged: isAuthenticated(state),
    logoutError: state.auth.error.logout,
    isBetaUser: state.settings.betaWebEnabled,
  };
}
export default connect(mapStateToProps, {
  wsDisconnect: websocketDisconnection,
  wsLogin: loginWebsocket,
  wsClose: logoutWebsocket,
})(App);
