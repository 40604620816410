import { LOGOUT_SUCCESS } from '../auth/types';
import {
  FIND_CALL_NUMBER_REQUEST,
  FIND_CALL_NUMBER_SUCCESS,
  FIND_CALL_NUMBER_FAILURE,
} from './types';

const initialState = {
  findCallNumberLoaded: true,
  findCallNumberError: false,
  callNumber: null,
};

export default function vocalassistant(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return initialState;
    case FIND_CALL_NUMBER_REQUEST:
      return {
        ...state,
        findCallNumberLoaded: false,
        findCallNumberError: false,
        callNumber: null,
      };
    case FIND_CALL_NUMBER_SUCCESS:
      return {
        ...state,
        findCallNumberLoaded: true,
        findCallNumberError: false,
        callNumber: action.number,
      };
    case FIND_CALL_NUMBER_FAILURE:
      return {
        ...state,
        findCallNumberLoaded: true,
        findCallNumberError: true,
        callNumber: null,
      };

    default:
      return state;
  }
}
