import {
  FETCH_MAILOFFICES_REQUEST,
  FETCH_MAILOFFICES_SUCCESS,
  FETCH_MAILOFFICES_FAILURE,
  FETCH_PAGED_MAILOFFICES_REQUEST,
  FETCH_PAGED_MAILOFFICES_SUCCESS,
  FETCH_PAGED_MAILOFFICES_FAILURE,
  FETCH_MAILOFFICE_REQUEST,
  FETCH_MAILOFFICE_SUCCESS,
  FETCH_MAILOFFICE_FAILURE,
  DELETE_MAILOFFICE_REQUEST,
  DELETE_MAILOFFICE_SUCCESS,
  DELETE_MAILOFFICE_FAILURE,
  SET_MAILOFFICES_FILTER,
  SAVE_MAILOFFICE_REQUEST,
  SAVE_MAILOFFICE_SUCCESS,
  SAVE_MAILOFFICE_FAILURE,
  FETCH_MAILOFFICE_CREDIT_REQUEST,
  FETCH_MAILOFFICE_CREDIT_SUCCESS,
  FETCH_MAILOFFICE_CREDIT_FAILURE,
  MAILOFFICE_PRICE_NOTIFICATION,
  CONFIRM_MAILOFFICE_REQUEST,
  CONFIRM_MAILOFFICE_SUCCESS,
  REJECT_MAILOFFICE_REQUEST,
  REJECT_MAILOFFICE_SUCCESS,
  REJECT_MAILOFFICE_FAILURE,
  CONFIRM_MAILOFFICE_FAILURE,
  MAILOFFICE_ERROR_NOTIFICATION,
  MAILOFFICE_COMPLETE_NOTIFICATION,
  DELETE_MAILOFFICE_RR_REQUEST,
  DELETE_MAILOFFICE_RR_SUCCESS,
  DELETE_MAILOFFICE_RR_FAILURE,
} from './types';

export const fetchMailofficesRequest = (params) => ({
  type: FETCH_MAILOFFICES_REQUEST,
  payload: params,
});
export const fetchMailofficesSuccess = (payload) => ({
  type: FETCH_MAILOFFICES_SUCCESS,
  payload,
});
export const fetchMailofficesFailure = (errors) => ({
  type: FETCH_MAILOFFICES_FAILURE,
  errors,
});

export const fetchPagedMailofficesRequest = (params) => ({
  type: FETCH_PAGED_MAILOFFICES_REQUEST,
  payload: params,
});
export const fetchPagedMailofficesSuccess = (payload) => ({
  type: FETCH_PAGED_MAILOFFICES_SUCCESS,
  payload,
});
export const fetchPagedMailofficesFailure = (errors) => ({
  type: FETCH_PAGED_MAILOFFICES_FAILURE,
  errors,
});

export const fetchMailofficeRequest = (id) => ({
  type: FETCH_MAILOFFICE_REQUEST,
  id,
});
export const fetchMailofficeSuccess = (details) => ({
  type: FETCH_MAILOFFICE_SUCCESS,
  details,
});
export const fetchMailofficeFailure = (errors) => ({
  type: FETCH_MAILOFFICE_FAILURE,
  errors,
});

export const deleteMailofficeRequest = (id) => ({
  type: DELETE_MAILOFFICE_REQUEST,
  id,
});
export const deleteMailofficeSuccess = (id) => ({
  type: DELETE_MAILOFFICE_SUCCESS,
  id,
});
export const deleteMailofficeFailure = (errors) => ({
  type: DELETE_MAILOFFICE_FAILURE,
  errors,
});

export const setMailofficeFilter = (filter) => ({
  type: SET_MAILOFFICES_FILTER,
  filter,
});

export const saveMailofficeRequest = (mailofficeData) => ({
  type: SAVE_MAILOFFICE_REQUEST,
  mailofficeData,
});
export const saveMailofficeSuccess = (mailofficeData, success) => ({
  type: SAVE_MAILOFFICE_SUCCESS,
  mailofficeData,
  success,
});
export const saveMailofficeFailure = (errors) => ({
  type: SAVE_MAILOFFICE_FAILURE,
  errors,
});

export const fetchMailofficeCreditRequest = () => ({
  type: FETCH_MAILOFFICE_CREDIT_REQUEST,
});
export const fetchMailofficeCreditSuccess = (credit) => ({
  type: FETCH_MAILOFFICE_CREDIT_SUCCESS,
  credit,
});
export const fetchMailofficeCreditFailure = (errors) => ({
  type: FETCH_MAILOFFICE_CREDIT_FAILURE,
  errors,
});

export const mailofficePriceNotification = (payload) => ({
  type: MAILOFFICE_PRICE_NOTIFICATION,
  payload,
});
export const mailofficeErrorNotification = (payload) => ({
  type: MAILOFFICE_ERROR_NOTIFICATION,
  payload,
});
export const mailofficeCompleteNotification = (payload) => ({
  type: MAILOFFICE_COMPLETE_NOTIFICATION,
  payload,
});

export const confirmMailofficeRequest = (id) => ({
  type: CONFIRM_MAILOFFICE_REQUEST,
  id,
});

export const confirmMailofficeSuccess = () => ({
  type: CONFIRM_MAILOFFICE_SUCCESS,
});

export const confirmMailofficeFailure = (error) => ({
  type: CONFIRM_MAILOFFICE_FAILURE,
  error,
});

export const rejectMailofficeRequest = (id) => ({
  type: REJECT_MAILOFFICE_REQUEST,
  id,
});

export const rejectMailofficeSuccess = () => ({
  type: REJECT_MAILOFFICE_SUCCESS,
});

export const rejectMailofficeFailure = (error) => ({
  type: REJECT_MAILOFFICE_FAILURE,
  error,
});

export const deleteMailofficeRRRequest = (id) => ({
  type: DELETE_MAILOFFICE_RR_REQUEST,
  id,
});
export const deleteMailofficeRRSuccess = (id) => ({
  type: DELETE_MAILOFFICE_RR_SUCCESS,
  id,
});
export const deleteMailofficeRRFailure = (errors) => ({
  type: DELETE_MAILOFFICE_RR_FAILURE,
  errors,
});
