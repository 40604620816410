import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SecondaryNav from '../../../components/SecondaryNav';
import SectionTitle from '../../../components/SectionTitle';
import { getWebNotificationsCalendarNumber } from '../../../js/notifications/selectors';
import {
  hasGrants,
  isAppointmentEnabled,
  isMePbxAdmin,
} from '../../../js/me/selectors';
import { getAppointmentTypesViewOthers } from '../../../js/calendar/selectors';

const messages = defineMessages({
  title: {
    id: 'Calendar.title',
    defaultMessage: 'Calendar',
  },
  show: {
    id: 'Calendar.show.navLabel',
    defaultMessage: 'Events',
  },
  sharing: {
    id: 'Calendar.sharing.navLabel',
    defaultMessage: 'Sharing',
  },
  import: {
    id: 'Calendar.import.navLabel',
    defaultMessage: 'Import',
  },
  export: {
    id: 'Calendar.export.navLabel',
    defaultMessage: 'Export',
  },
  synch: {
    id: 'Calendar.synch.navLabel',
    defaultMessage: 'Synchronize',
  },
  share: {
    id: 'Calendar.share.navLabel',
    defaultMessage: 'Share',
  },
  settings: {
    id: 'Calendar.settings.navLabel',
    defaultMessage: 'Settings',
  },
  festivitiesSettings: {
    id: 'Calendar.festivitiesSettings.navLabel',
    defaultMessage: 'Festivities settings',
  },
  manage: {
    id: 'Calendar.manage.navLabel',
    defaultMessage: 'Manage',
  },
  notifications: {
    id: 'Calendar.notifications.navLabel',
    defaultMessage: 'Notifications',
  },
  report: {
    id: 'Calendar.settings.report',
    defaultMessage: 'Appointments report',
  },
  usersReport: {
    id: 'Calendar.settings.usersReport',
    defaultMessage: 'Single users',
  },
  dashboardReport: {
    id: 'Calendar.settings.dashboardReport',
    defaultMessage: 'Dashboard',
  },
  appointmentResults: {
    id: 'Calendar.notifications.appointmentResults',
    defaultMessage: 'Appointment results',
  },
  appointmentTypes: {
    id: 'Calendar.notifications.appointmentTypes',
    defaultMessage: 'Appointment types',
  },
});
const Calendar = ({
  intl: { formatMessage },
  scrolling,
  notificationsQuantity,
  children,
  isPbxAdmin,
  hasAppointmentCustomizationGrants,
  hasAppointmentReportGrants,
  appointmentEnabled,
  appointmentTypes,
}) => {
  const manageOperations = [];
  if (appointmentEnabled && hasAppointmentCustomizationGrants) {
    manageOperations.push({
      id: 'Calendar.appointmentResult.navLabel',
      href: '/calendar/manage/appointment-result',
      label: messages.appointmentResults,
    });
    manageOperations.push({
      id: 'Calendar.appointmentType.navLabel',
      href: '/calendar/manage/appointment-type',
      label: messages.appointmentTypes,
    });
  }
  if (isPbxAdmin) {
    manageOperations.push({
      id: 'Calendar.festivitiesSettings.navLabel',
      href: '/calendar/manage/festivities-settings',
      label: messages.festivitiesSettings,
    });
  }
  const nav = [
    {
      id: 'Calendar.show.navLabel',
      href: '/calendar/show',
      label: messages.show,
    },
  ];
  if (appointmentEnabled) {
    if (hasAppointmentReportGrants) {
      const subNav = [
        {
          id: 'Calendar.usersReport.navLabel',
          href: '/calendar/report/users',
          label: messages.usersReport,
        },
      ];
      if (appointmentTypes.length) {
        subNav.push({
          id: 'Calendar.dashboardReport.navLabel',
          href: '/calendar/report/dashboard',
          label: messages.dashboardReport,
        });
      }
      nav.push({
        id: 'Calendar.appointmentsReport.navLabel',
        href: '/calendar/report',
        label: messages.report,
        subNav,
      });
    } else {
      nav.push({
        id: 'Calendar.appointmentsReport.navLabel',
        href: '/calendar/report/users',
        label: messages.report,
      });
    }
  }
  nav.push(
    {
      id: 'Calendar.manage.navLabel',
      href: '/calendar/sharing',
      label: messages.sharing,
      subNav: [
        {
          id: 'Calendar.import.navLabel',
          href: '/calendar/sharing/import',
          label: messages.import,
        },
        {
          id: 'Calendar.export.navLabel',
          href: '/calendar/sharing/export',
          label: messages.export,
        },
        {
          id: 'Calendar.share.navLabel',
          href: '/calendar/sharing/share',
          label: messages.share,
        },
        {
          id: 'Calendar.synch.navLabel',
          href: '/calendar/sharing/synch',
          label: messages.synch,
        },
      ],
    },
    {
      id: 'Calendar.settings.navLabel',
      href: '/calendar/settings',
      label: messages.settings,
    }
  );
  if (manageOperations.length) {
    nav.push({
      id: 'Calendar.manage.navLabel',
      href: '/calendar/manage',
      label: messages.manage,
      subNav: manageOperations,
    });
  }
  nav.push({
    id: 'Calendar.notifications.navLabel',
    href: '/calendar/notifications',
    label: messages.notifications,
    notifications: notificationsQuantity,
  });
  return (
    <div className="border rounded h-100 bg-white">
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SecondaryNav tabs={nav} />
      <div
        className={
          scrolling ? 'yn-section-with-nav-scrolling' : 'yn-section-with-nav'
        }
      >
        {children}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    notificationsQuantity: getWebNotificationsCalendarNumber(state),
    isPbxAdmin: isMePbxAdmin(state),
    hasAppointmentCustomizationGrants: hasGrants(
      state,
      'CUSTOMIZE_APPOINTMENTS'
    ),
    hasAppointmentReportGrants: hasGrants(state, 'REPORT_APPOINTMENTS'),
    appointmentEnabled: isAppointmentEnabled(state),
    appointmentTypes: getAppointmentTypesViewOthers(state),
  };
}

Calendar.propTypes = {
  scrolling: PropTypes.bool,
};

Calendar.defaultProps = {
  scrolling: false,
};
export default injectIntl(connect(mapStateToProps)(Calendar));
