import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  getSMSCampaignById,
  getSMSsAliasById,
  usingSMSAlias,
} from '../../../js/sms/selectors';
import {
  getDatetimeFormat,
  getTimeFormat,
  getDateFormat,
} from '../../../js/settings/selectors';
import ToolboxIcon from '../../../components/icons/ToolboxIcon';
import { SMSStatusColor } from '../../../js/sms/SMSUtils';
import { getUsersById } from '../../../js/users/selectors';
import UserAvatar from '../../../components/UserAvatar';
import { showConfirmModal } from '../../../js/confirm/actions';
import {
  deleteSMSCampaignRequest,
  fetchSMSCampaignRequest,
} from '../../../js/sms/actions';
import { getAbookListsById } from '../../../js/contacts/selectors';
import SMSDetailsModal from './SMSDetailsModal';
import { getMeId, isSMSCampaignEnabled } from '../../../js/me/selectors';

const messages = defineMessages({
  PENDING: {
    id: 'SMSCampaignsRow.PENDING',
    defaultMessage: 'Scheduled',
  },
  DELIVERED: {
    id: 'SMSCampaignsRow.DELIVERED',
    defaultMessage: 'Sent',
  },
  ERROR: {
    id: 'SMSCampaignsRow.ERROR',
    defaultMessage: 'Error',
  },
  showText: {
    id: 'SMSCampaignsRow.tooltip.showText',
    defaultMessage: 'Show text',
  },
  dateYestarday: {
    id: 'SMSCampaignsRow.dateYestarday',
    defaultMessage: 'yestarday',
  },
  deleteConfirmationMessage: {
    id: 'SMSCampaignsRow.deleteConfirmationMessage',
    defaultMessage: 'Are you sure you want to delete this campaign?',
  },
  detailsTooltip: {
    id: 'SMSCampaignsRow.tooltip.detailsTooltip',
    defaultMessage: 'Details',
  },
  editTooltip: {
    id: 'SMSCampaignsRow.tooltip.editTooltip',
    defaultMessage: 'Edit',
  },
  deleteTooltip: {
    id: 'SMSCampaignsRow.tooltip.deleteTooltip',
    defaultMessage: 'Delete',
  },
  detailsModalTitle: {
    id: 'SMSCampaignsRow.detailsModalTitle',
    defaultMessage: 'Campaign details',
  },
  scheduled: {
    id: 'SMSCampaignsRow.label.scheduled',
    defaultMessage: 'Scheduled date',
  },
  user: {
    id: 'SMSCampaignsRow.label.user',
    defaultMessage: 'User',
  },
  sender: {
    id: 'SMSCampaignsRow.label.sender',
    defaultMessage: 'Sender',
  },
  text: {
    id: 'SMSCampaignsRow.label.text',
    defaultMessage: 'Text',
  },
  defaultSender: {
    id: 'SMSCampaignsRow.defaultSender',
    defaultMessage: 'Default',
  },
  copyAndResend: {
    id: 'SMSCampaignsRow.tooltip.copyAndResend',
    defaultMessage: 'Copy and resend',
  },
});

const SMSCampaignsRow = ({
  campaignData,
  useAlias,
  users,
  dateTimeFormat,
  confirm,
  deleteCampaign,
  listsById,
  fetchCampaign,
  aliasById,
  myId,
  campaignEnabled,
  intl: { formatMessage },
}) => {
  const user = users[campaignData.user];

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const handleDeleteSMS = () => {
    confirm({
      message: formatMessage(messages.deleteConfirmationMessage),
      modalAction: deleteCampaign,
      actionParams: { id: campaignData.id },
    });
  };

  const arrangedUser = user ? (
    <>
      <UserAvatar
        size="sm"
        alt={user.departmentFullname}
        src={user.avatar}
        id={campaignData.user}
        withPopover
      />
      {user.departmentFullname}
    </>
  ) : (
    campaignData.historyUser
  );

  const lists =
    campaignData.lists &&
    campaignData.lists
      .map((id) => listsById[id] && listsById[id].name)
      .join(', ');
  const scheduledDate = moment(+campaignData.scheduled).format(dateTimeFormat);
  const sender =
    (useAlias &&
      campaignData.sender &&
      aliasById[campaignData.sender] &&
      aliasById[campaignData.sender].alias) ||
    formatMessage(messages.defaultSender);
  const status =
    campaignData.executed && !campaignData.error
      ? 'DELIVERED'
      : campaignData.error
      ? 'ERROR'
      : 'PENDING';

  return (
    <tr>
      <td>{scheduledDate}</td>
      <td>{campaignData.name}</td>
      {/* <td>{arrangedUser}</td>
      {sender && <td>{sender}</td>}
  <td>{lists}</td> */}
      <td className={SMSStatusColor(status)}>
        {messages[status] ? formatMessage(messages[status]) : status}
      </td>
      <td style={{ minWidth: '120px', textAlign: 'right' }}>
        <span
          onClick={() => {
            setDetailsModalOpen(!detailsModalOpen);
            fetchCampaign({ id: campaignData.id });
          }}
        >
          <ToolboxIcon name="bubble-chat-view-1">
            {formatMessage(messages.detailsTooltip)}
          </ToolboxIcon>
        </span>
        {campaignEnabled && (
          <Link to={`/sms/campaigns/copy/${campaignData.id}`}>
            <ToolboxIcon name="file-add-1">
              {formatMessage(messages.copyAndResend)}
            </ToolboxIcon>
          </Link>
        )}
        {myId === campaignData.user && (
          <>
            {!campaignData.executed && (
              <Link to={`/sms/campaigns/edit/${campaignData.id}`}>
                <ToolboxIcon name="pencil-write-2">
                  {formatMessage(messages.editTooltip)}
                </ToolboxIcon>
              </Link>
            )}
            <span onClick={() => handleDeleteSMS()}>
              <ToolboxIcon name="bin-1">
                {formatMessage(messages.deleteTooltip)}
              </ToolboxIcon>
            </span>
          </>
        )}
      </td>
      <SMSDetailsModal
        id={campaignData.id}
        title={formatMessage(messages.detailsModalTitle)}
        isOpen={detailsModalOpen}
        onToggle={() => setDetailsModalOpen(!detailsModalOpen)}
        scheduledDate={scheduledDate}
        lists={lists}
        user={arrangedUser}
        text={campaignData.text}
        sender={sender}
        link={campaignData.link}
        name={campaignData.name}
        totalSent={campaignData.totalSent}
        totalDelivered={campaignData.totalDelivered}
        totalError={campaignData.totalError}
      />
    </tr>
  );
};

SMSCampaignsRow.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    campaignData: getSMSCampaignById(state, ownProps.id),
    useAlias: usingSMSAlias(state),
    dateTimeFormat: getDatetimeFormat(state),
    dateFormat: getDateFormat(state),
    timeFormat: getTimeFormat(state),
    users: getUsersById(state),
    listsById: getAbookListsById(state),
    aliasById: usingSMSAlias(state) && getSMSsAliasById(state),
    myId: getMeId(state),
    campaignEnabled: isSMSCampaignEnabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    confirm: showConfirmModal,
    deleteCampaign: deleteSMSCampaignRequest,
    fetchCampaign: fetchSMSCampaignRequest,
  })(SMSCampaignsRow)
);
