import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../../components/formControls/Button';
import Input from '../../../components/formControls/Input';
import Icon from '../../../components/icons/Icon';
import { defaultStatuses } from '../../../js/me/MeUtils';
import { setUserStatusRequest } from '../../../js/me/actions';
import {
  getSetStatusLoading,
  getSetStatusSuccess,
  getSetStatusError,
  getMeUserStatus,
} from '../../../js/me/selectors';
import ToastMessage from '../../../components/messages/ToastMessage';
import ynConf from '../../../conf';
import { charsToCode } from '../../../js/chat/charsToCode';

const searchStyle = {
  close: {
    height: '0.7rem',
    width: '0.7rem',
    display: 'block',
    position: 'absolute',
    top: '0.5rem',
    right: '0.6rem',
    color: 'var(--yn-blue)',
    cursor: 'pointer',
  },
};

const ChatEmojisTab = React.lazy(() => import('../Chat/ChatEmojisTab'));

const messages = defineMessages({
  emojiTooltip: {
    id: 'SetStatusModal.label.emojiTooltip',
    defaultMessage: 'Emoji',
  },
  save: {
    id: 'SetStatusModal.label.save',
    defaultMessage: 'Save',
  },
  close: {
    id: 'SetStatusModal.label.close',
    defaultMessage: 'Close',
  },
  setStatus: {
    id: 'SetStatusModal.label.setStatus',
    defaultMessage: 'Set a status',
  },
  atHome: {
    id: 'SetStatusModal.label.atHome',
    defaultMessage: 'At home',
  },
  atLunch: {
    id: 'SetStatusModal.label.atLunch',
    defaultMessage: 'At lunch',
  },
  coffeeBreak: {
    id: 'SetStatusModal.label.coffeeBreak',
    defaultMessage: 'Coffee break',
  },
  inMeeting: {
    id: 'SetStatusModal.label.inMeeting',
    defaultMessage: 'In meeting',
  },
  atHoliday: {
    id: 'SetStatusModal.label.atHoliday',
    defaultMessage: 'At holiday',
  },
  busy: {
    id: 'SetStatusModal.label.busy',
    defaultMessage: 'Busy',
  },
  statusPlaceholder: {
    id: 'SetStatusModal.label.statusPlaceholder',
    defaultMessage: `What's your status?`,
  },
  misingStatus: {
    id: 'SetStatusModal.label.misingStatus',
    defaultMessage: 'You must select an emoji and status!',
  },
  statusLengthError: {
    id: 'SetStatusModal.label.statusLengthError',
    defaultMessage: 'The status cannot be longer than this!',
  },
  error: {
    id: 'SetStatusModal.error',
    defaultMessage: 'Error while setting status',
  },
});

class SetStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.toggle();
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const emoji = this.arrangeEmoji();
    const label = this.arrangeLabel();
    const status = {
      emoji,
      label,
    };

    const errors = this.validate(status);
    this.setState({ ...this.state, errors });
    if (Object.keys(errors).length === 0) {
      this.props.setStatus({ status: emoji && label ? status : null });
      delete errors.status;
    }
  };

  validate = (data) => {
    const errors = {};
    if (
      (data.emoji && !data.label.trim()) ||
      (!data.emoji && data.label.trim())
    ) {
      errors.status = this.props.intl.formatMessage(messages.misingStatus);
    }
    return errors;
  };

  handleChange = (e) => {
    if (e.target.value.length < 50) {
      this.setState({
        ...this.state,
        label: e.target.value,
        errors: {},
      });
    } else {
      this.setState({
        ...this.state,
        errors: {
          status: this.props.intl.formatMessage(messages.statusLengthError),
        },
      });
    }
  };

  handleEmoji = (emoji) => {
    this.setState({
      emoji,
      errors: {},
    });
    this.toggleEmojiTab();
  };

  toggleEmojiTab = () => {
    const { emojiPanelOpen } = this.state;
    this.setState({
      emojiPanelOpen: !emojiPanelOpen,
    });
  };

  reset = () => {
    this.setState({
      emoji: false,
      label: '',
      emojiPanelOpen: false,
      errors: {},
    });
  };

  selectedDefaultStatus = (emoji, label) => {
    this.setState({
      emoji,
      label,
    });
  };

  arrangeLabel = () =>
    this.state.label !== undefined
      ? this.state.label
      : (this.props.status && this.props.status.label) || '';

  arrangeEmoji = () =>
    this.state.emoji !== undefined
      ? this.state.emoji
      : (this.props.status && this.props.status.emoji) || '';

  render() {
    const {
      isOpen,
      toggle,
      intl: { formatMessage },
      loading,
      error,
    } = this.props;
    const { errors, emojiPanelOpen } = this.state;
    const status = this.arrangeLabel();
    const emoji = this.arrangeEmoji();

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          {formatMessage(messages.setStatus)}
        </ModalHeader>
        <ModalBody>
          {error && (
            <ToastMessage text={formatMessage(messages.error)} type="danger" />
          )}
          <div className="form-row">
            <div className="col-1 my-2">
              <span onClick={this.toggleEmojiTab}>
                {!emoji && (
                  <Icon
                    name="smiley-smile-1"
                    width={27}
                    height={27}
                    color="var(--yn-blue-dark)"
                    className="mt-1"
                  >
                    {formatMessage(messages.emojiTooltip)}
                  </Icon>
                )}
              </span>
              <span onClick={this.toggleEmojiTab}>
                {emoji && (
                  <img
                    width={27}
                    height={27}
                    className="mt-1"
                    src={`${ynConf.publicFolderPath}/emojis/${charsToCode[emoji]}.png`}
                    alt={`${emoji}`}
                  />
                )}
              </span>
            </div>
            <div className="col-11 my-2">
              <span
                style={searchStyle.close}
                onClick={this.reset}
                className="mr-1"
              >
                <Icon name="close-filled" width={12} height={12} />
              </span>
              <Input
                type="status"
                name="status"
                value={status}
                onChange={this.handleChange}
                id="status"
                error={errors.status}
                placeholder={formatMessage(messages.statusPlaceholder)}
              />
            </div>
          </div>
          {!emoji &&
            !status &&
            defaultStatuses.map((defaultStatus) => {
              return (
                <div
                  key={defaultStatus.emoji}
                  className="row"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.selectedDefaultStatus(
                      defaultStatus.emoji,
                      formatMessage(messages[defaultStatus.label])
                    )
                  }
                >
                  <div className="col-1">
                    <img
                      width={27}
                      height={27}
                      className="mt-1"
                      src={`${ynConf.publicFolderPath}/emojis/${
                        charsToCode[defaultStatus.emoji]
                      }.png`}
                      alt={`${defaultStatus.emoji}`}
                    />
                  </div>
                  <div className="col-11 pt-2">
                    {formatMessage(messages[defaultStatus.label])}
                  </div>
                </div>
              );
            })}
          {emojiPanelOpen && (
            <React.Suspense fallback={<div />}>
              <ChatEmojisTab
                onSelect={this.handleEmoji}
                size="BIG"
                context="userStatus"
              />
            </React.Suspense>
          )}
        </ModalBody>
        <ModalFooter>
          {
            <Button
              className="btn-primary"
              text={formatMessage(messages.save)}
              loading={loading}
              onClick={this.onSubmit}
            />
          }
          <Button
            className="btn-outline-primary"
            onClick={toggle}
            text={formatMessage(messages.close)}
          />
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: !getSetStatusLoading(state),
    success: getSetStatusSuccess(state),
    error: getSetStatusError(state),
    status: getMeUserStatus(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    setStatus: setUserStatusRequest,
  })(SetStatusModal)
);
