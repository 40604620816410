export const CREATE_WORKING_HOURS_PLAN_REQUEST =
  'CREATE_WORKING_HOURS_PLAN_REQUEST';
export const CREATE_WORKING_HOURS_PLAN_SUCCESS =
  'CREATE_WORKING_HOURS_PLAN_SUCCESS';
export const CREATE_WORKING_HOURS_PLAN_FAILURE =
  'CREATE_WORKING_HOURS_PLAN_FAILURE';
export const UPDATE_WORKING_HOURS_PLAN_REQUEST =
  'UPDATE_WORKING_HOURS_PLAN_REQUEST';
export const UPDATE_WORKING_HOURS_PLAN_SUCCESS =
  'UPDATE_WORKING_HOURS_PLAN_SUCCESS';
export const UPDATE_WORKING_HOURS_PLAN_FAILURE =
  'UPDATE_WORKING_HOURS_PLAN_FAILURE';
export const GET_WORKING_HOURS_PLAN_REQUEST = 'GET_WORKING_HOURS_PLAN_REQUEST';
export const GET_WORKING_HOURS_PLAN_SUCCESS = 'GET_WORKING_HOURS_PLAN_SUCCESS';
export const GET_WORKING_HOURS_PLAN_FAILURE = 'GET_WORKING_HOURS_PLAN_FAILURE';
export const GET_WORKING_HOURS_PLANS_REQUEST =
  'GET_WORKING_HOURS_PLANS_REQUEST';
export const GET_WORKING_HOURS_PLANS_SUCCESS =
  'GET_WORKING_HOURS_PLANS_SUCCESS';
export const GET_WORKING_HOURS_PLANS_FAILURE =
  'GET_WORKING_HOURS_PLANS_FAILURE';
export const ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST =
  'ASSIGN_WORKING_HOURS_PLAN_TO_USER_REQUEST';
export const ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS =
  'ASSIGN_WORKING_HOURS_PLAN_TO_USER_SUCCESS';
export const ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE =
  'ASSIGN_WORKING_HOURS_PLAN_TO_USER_FAILURE';
export const DELETE_WORKING_HOURS_PLAN_REQUEST =
  'DELETE_WORKING_HOURS_PLAN_REQUEST';
export const DELETE_WORKING_HOURS_PLAN_SUCCESS =
  ' DELETE_WORKING_HOURS_PLAN_SUCCESS';
export const DELETE_WORKING_HOURS_PLAN_FAILURE =
  ' DELETE_WORKING_HOURS_PLAN_FAILURE';
export const ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST =
  'ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_REQUEST';
export const ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS =
  'ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_SUCCESS';
export const ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE =
  'ASSIGN_WORKING_HOURS_PLAN_TO_ALL_USERS_FAILURE';
export const WORKING_PLANS_CLEAR_ERRORS = 'WORKING_PLANS_CLEAR_ERRORS';
export const ASSIGN_WORKING_HOURS_PLAN_REQUEST =
  'ASSIGN_WORKING_HOURS_PLAN_REQUEST';
export const ASSIGN_WORKING_HOURS_PLAN_SUCCESS =
  'ASSIGN_WORKING_HOURS_PLAN_SUCCESS';
export const ASSIGN_WORKING_HOURS_PLAN_FAILURE =
  'ASSIGN_WORKING_HOURS_PLAN_FAILURE';
export const SAVE_WORKING_ROUND_REQUEST =
  'SAVE_WORKING_ROUND_REQUEST';
export const SAVE_WORKING_ROUND_SUCCESS =
  'SAVE_WORKING_ROUND_SUCCESS';
export const SAVE_WORKING_ROUND_FAILURE =
  'SAVE_WORKING_ROUND_FAILURE';
export const SAVE_WORKING_BREAK_REQUEST =
  'SAVE_WORKING_BREAK_REQUEST';
export const SAVE_WORKING_BREAK_SUCCESS =
  'SAVE_WORKING_BREAK_SUCCESS';
export const SAVE_WORKING_BREAK_FAILURE =
  'SAVE_WORKING_BREAK_FAILURE';
