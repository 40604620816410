import React from 'react';
import PropTypes from 'prop-types';

export const starStyle = {
  width: '320px',
  height: '320px',
  maxFontSize: 24,
  menuStyle: {
    position: 'absolute',
    top: '84px',
    left: '113px',
    width: '31%',
    height: '0',
    borderBottom: '23px solid rgba(76, 175, 80, 0.1)',
    borderLeft: '10px solid transparent',
    borderRight: '11px solid transparent',
    marginBottom: '0',
    padding: '0 2px 0 10px',
  },
  subMenuStyle: {
    position: 'absolute',
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    top: '107px',
    left: '98px',
    overflow: 'hidden',
  },
  textDivStyle: {
    display: 'block',
    width: '50%',
    height: '45%',
    padding: '6px',
    top: '109px',
    left: '80px',
    position: 'absolute',
    cursor: 'pointer',
  },
  shareStyle: {
    position: 'absolute',
    top: '273px',
    left: '68px',
  },
  deleteStyle: {
    position: 'absolute',
    top: '271px',
    left: '225px',
  },
  sharedStyle: {
    position: 'absolute',
    top: '44px',
    left: '150px',
  },
};

export const StarShape = ({ id, color }) => (
  <>
    <svg viewBox="0 0 200 200">
      <g transform="translate(0,-852.36218)">
        <path
          filter={`url(#sticky-filter-${id})`}
          style={{ fill: `${color}`, padding: '10px' }}
          d="M 94.999998,135 12.093561,87.052427 -74.405419,128.16656 -54.424069,34.501237 -120.25559,-35.059217 -24.999996,-45 20.81287,-129.10485 59.702712,-41.583276 153.84814,-24.002481 82.627791,40.029612 z"
          transform="matrix(0.72690142,-0.02678522,0.02678705,0.72685168,88.581808,951.77077)"
        />
        <filter
          id={`sticky-filter-${id}`}
          x="-1px"
          y="-1px"
          width="210%"
          height="210%"
        >
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="1" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </g>
    </svg>
  </>
);

StarShape.propTypes = {
  id: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
