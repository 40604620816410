export const FETCH_ME_REQUEST = 'FETCH_ME_REQUEST';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';
export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const SET_STATUS_REQUEST = 'SET_STATUS_REQUEST';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
export const SET_STATUS_FAILURE = 'SET_STATUS_FAILURE';
export const UPDATE_EMAIL_REQUEST = 'UPDATE_EMAIL_REQUEST';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILURE = 'UPDATE_EMAIL_FAILURE';
export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';
export const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
export const REMOVE_FAVORITE_SUCCESS = 'REMOVE_FAVORITE_SUCCESS';
export const REMOVE_FAVORITE_FAILURE = 'REMOVE_FAVORITE_FAILURE';
export const INIT_MAIN_SUCCESS = 'INIT_MAIN_SUCCESS';
export const NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS =
  'NETHESIS_ME_PHONE_STATUS_CHANGES_SUCCESS';
export const ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS =
  'ABILIS_ME_PHONE_STATUS_CHANGES_SUCCESS';
export const FETCH_CUSTOMER_SETTINGS_REQUEST =
  'FETCH_CUSTOMER_SETTINGS_REQUEST';
export const FETCH_CUSTOMER_SETTINGS_SUCCESS =
  'FETCH_CUSTOMER_SETTINGS_SUCCESS';
export const FETCH_CUSTOMER_SETTINGS_FAILURE =
  'FETCH_CUSTOMER_SETTINGS_FAILURE';
export const FETCH_EXTENSIONS_ORDER_SUCCESS = 'FETCH_EXTENSIONS_ORDER_SUCCESS';
export const FETCH_EXTENSIONS_ORDER_FAILURE = 'FETCH_EXTENSIONS_ORDER_FAILURE';
export const UPDATE_EXTENSIONS_ORDER_REQUEST =
  'UPDATE_EXTENSIONS_ORDER_REQUEST';
export const UPDATE_EXTENSIONS_ORDER_SUCCESS =
  'UPDATE_EXTENSIONS_ORDER_SUCCESS';
export const UPDATE_EXTENSIONS_ORDER_FAILURE =
  'UPDATE_EXTENSIONS_ORDER_FAILURE';
export const FETCH_HELP_REQUEST = 'FETCH_HELP_REQUEST';
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';
export const FETCH_HELP_FAILURE = 'FETCH_HELP_FAILURE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const FETCH_PBXS_REQUEST = 'FETCH_PBXS_REQUEST';
export const FETCH_PBXS_SUCCESS = 'FETCH_PBXS_SUCCESS';
export const FETCH_PBXS_FAILURE = 'FETCH_PBXS_FAILURE';
export const TOGGLE_SHOW_HELP = 'TOGGLE_SHOW_HELP';
export const FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST =
  'FETCH_CUSTOMER_REGISTRY_FIELDS_REQUEST';
export const FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS =
  'FETCH_CUSTOMER_REGISTRY_FIELDS_SUCCESS';
export const FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE =
  'FETCH_CUSTOMER_REGISTRY_FIELDS_FAILURE';
export const SET_USER_STATUS_REQUEST = 'SET_USER_STATUS_REQUEST';
export const SET_USER_STATUS_SUCCESS = 'SET_USER_STATUS_SUCCESS';
export const SET_USER_STATUS_FAILURE = 'SET_USER_STATUS_FAILURE';
export const UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST =
  'UPDATE_PLATFORM_SETTINGS_CUSTOMER_REQUEST';
export const UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS =
  'UPDATE_PLATFORM_SETTINGS_CUSTOMER_SUCCESS';
export const UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE =
  'UPDATE_PLATFORM_SETTINGS_CUSTOMER_FAILURE';
export const CHECK_RELEASE = 'CHECK_RELEASE';
