import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  selectFont: {
    id: 'FontSelector.selectFont',
    defaultMessage: 'Select font',
  },
});

class FontSelector extends Component {
  selectFont = (font) => {
    this.props.selectFont(font);
  };

  render() {
    const {
      fonts,
      intl: { formatMessage },
    } = this.props;

    return (
      <div>
        {fonts.map((font) => (
          <span key={font} onClick={() => this.selectFont(font)}>
            <p
              style={{
                backgroundColor: 'white',
                borderRadius: '3px',
                padding: '3px',
                margin: '3px',
                cursor: 'pointer',
                fontFamily: `${font}`,
                fontSize: '13px',
              }}
            >
              {formatMessage(messages.selectFont)}
            </p>
          </span>
        ))}
      </div>
    );
  }
}

FontSelector.propTypes = {
  selectFont: PropTypes.func.isRequired,
  fonts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default injectIntl(FontSelector);
