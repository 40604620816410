import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPuncherTimeDifference } from '../js/presence/selectors';
import { Fade } from 'reactstrap';

class Clock extends Component {
  componentDidMount() {
    this.interval = setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = this.forceUpdate.bind(this);
  style = {
    outer: {
      fontSize: '2rem',
      flex: 1,
      fontWeight: 800,
      color: 'var(--yn-blue)',
      lineHeight: 1.1,
      marginRight: '1rem',
    },
    seconds:
      this.props.secondsSize === 'sm'
        ? {
            fontSize: '1rem',
            marginLeft: '0.1rem',
          }
        : {},
  };

  render() {
    const now = new Date().getTime() - this.props.timeDifference;
    const hours = moment(now).format('HH');
    const minutes = moment(now).format('mm');
    const seconds = moment(now).format('ss');
    return (
      <Fade style={this.style.outer}>
        <span>{hours}</span>
        <span>:</span>
        <span>{minutes}</span>
        <span style={this.style.seconds}>
          <span>:</span>
          {seconds}
        </span>
      </Fade>
    );
  }
}

Clock.propTypes = {
  secondsSize: PropTypes.oneOfType(['md', 'sm']),
};

Clock.defaultProps = {
  secondsSize: 'md',
};

function mapStateToProps(state) {
  return { timeDifference: getPuncherTimeDifference(state) };
}

export default connect(mapStateToProps, {})(Clock);
