import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { setPinnedGroupsFilter } from '../../../js/groups/actions';
import Search from '../../../components/formControls/Search';
import GroupsPanel from './GroupsPanel';
import {
  isGroupsMembersLoaded,
  getPinnedGroupsFilter,
} from '../../../js/groups/selectors';
import HelpButton from '../../../components/HelpButton';
import PersonalPuncherShortcut from './PersonalPuncherShortcut';
import { isPuncherEnabled } from '../../../js/presence/selectors';
import { isMyPuncherEnabled } from '../../../js/me/selectors';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'RightColumn.searchPlaceholder.',
    defaultMessage: 'Search in groups',
  },
});

class RightColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      this.setState({
        filter,
      });
    }
  }

  onChange = (text) => {
    const { setFilter } = this.props;
    setFilter(text);
    this.setState({ filter: text });
  };

  render() {
    const {
      loading,
      puncherEnabled,
      intl: { formatMessage },
    } = this.props;
    return (
      <div className="yn-right">
        {puncherEnabled && <PersonalPuncherShortcut />}
        <div
          className="bg-white rounded border"
          style={{
            height: puncherEnabled ? 'calc(100% - 49px)' : '100%',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                height: 'var(--yn-section-title-height)',
                padding: '5px',
                marginBottom: '3px',
                flexGrow: 1,
              }}
            >
              <Search
                id="PinnedGroupsFilterText"
                name="PinnedGroupsFilterText"
                disabled={loading}
                placeholder={formatMessage(messages.searchPlaceholder)}
                onChange={this.onChange}
                text={this.state.filter}
              />
            </div>
            <HelpButton fileName="groups" style={{ padding: '7px' }} />
          </div>
          <GroupsPanel />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: !isGroupsMembersLoaded(state),
    filter: getPinnedGroupsFilter(state),
    puncherEnabled: isPuncherEnabled(state) && isMyPuncherEnabled(state),
  };
}

export default injectIntl(
  connect(mapStateToProps, { setFilter: setPinnedGroupsFilter })(RightColumn)
);
