import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const AdminUsersList = React.lazy(() => import('./AdminUsersList'));

const AdminUsersListPage = (props) => (
  <Groups scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AdminUsersList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminUsersListPage;
