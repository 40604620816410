export const FETCH_CUSTOMER_CONTRACT_DATA_REQUEST =
  'FETCH_CUSTOMER_CONTRACT_DATA_REQUEST';
export const FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS =
  'FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS';
export const FETCH_CUSTOMER_CONTRACT_DATA_FAILURE =
  'FETCH_CUSTOMER_CONTRACT_DATA_FAILURE';
export const FETCH_CUSTOMER_BILLS_REQUEST = 'FETCH_CUSTOMER_BILLS_REQUEST';
export const FETCH_CUSTOMER_BILLS_SUCCESS = 'FETCH_CUSTOMER_BILLS_SUCCESS';
export const FETCH_CUSTOMER_BILLS_FAILURE = 'FETCH_CUSTOMER_BILLS_FAILURE';
export const FETCH_CUSTOMER_PHONE_LINES_REQUEST =
  'FETCH_CUSTOMER_PHONE_LINES_REQUEST';
export const FETCH_CUSTOMER_PHONE_LINES_SUCCESS =
  'FETCH_CUSTOMER_PHONE_LINES_SUCCESS';
export const FETCH_CUSTOMER_PHONE_LINES_FAILURE =
  'FETCH_CUSTOMER_PHONE_LINES_FAILURE';
export const FETCH_CUSTOMER_CONTRACTS_REQUEST =
  'FETCH_CUSTOMER_CONTRACTS_REQUEST';
export const FETCH_CUSTOMER_CONTRACTS_SUCCESS =
  'FETCH_CUSTOMER_CONTRACTS_SUCCESS';
export const FETCH_CUSTOMER_CONTRACTS_FAILURE =
  'FETCH_CUSTOMER_CONTRACTS_FAILURE';
export const FETCH_CONTRACT_NUMBERS_REQUEST = 'FETCH_CONTRACT_NUMBERS_REQUEST';
export const FETCH_CONTRACT_NUMBERS_SUCCESS = 'FETCH_CONTRACT_NUMBERS_SUCCESS';
export const FETCH_CONTRACT_NUMBERS_FAILURE = 'FETCH_CONTRACT_NUMBERS_FAILURE';
export const FETCH_CONTRACT_TRAFFIC_REQUEST = 'FETCH_CONTRACT_TRAFFIC_REQUEST';
export const FETCH_CONTRACT_TRAFFIC_SUCCESS = 'FETCH_CONTRACT_TRAFFIC_SUCCESS';
export const FETCH_CONTRACT_TRAFFIC_FAILURE = 'FETCH_CONTRACT_TRAFFIC_FAILURE';
export const DELETE_CONTRACT_TRAFFIC = 'DELETE_CONTRACT_TRAFFIC';
export const FETCH_CUSTOMER_SERVICES_REQUEST =
  'FETCH_CUSTOMER_SERVICES_REQUEST';
export const FETCH_CUSTOMER_SERVICES_SUCCESS =
  'FETCH_CUSTOMER_SERVICES_SUCCESS';
export const FETCH_CUSTOMER_SERVICES_FAILURE =
  'FETCH_CUSTOMER_SERVICES_FAILURE';
export const RESET_EMAIL_REQUEST = 'RESET_EMAIL_REQUEST';
export const RESET_EMAIL_FAILURE = 'RESET_EMAIL_FAILURE';
export const RESET_EMAIL_SUCCESS = 'RESET_EMAIL_SUCCESS';
