import { request } from '../request';

export default {
  getDocumentsTree: (parentId) =>
    request({
      url: '/wiki/tree',
      method: 'GET',
      params: parentId ? { parent: parentId } : undefined,
    }),
  getDocumentsSearch: (params) =>
    request({
      url: '/wiki/search',
      method: 'GET',
      params,
    }),
  getTags: () =>
    request({
      url: '/wiki/tags',
      method: 'GET',
    }),
  getDocument: (id) =>
    request({
      url: `/wiki/documents/${id}`,
      method: 'GET',
    }),
  createDocument: (document) =>
    request({
      url: '/wiki/documents',
      method: 'POST',
      data: document,
    }),
  updateDocument: (document) =>
    request({
      url: `/wiki/documents/${document.id}`,
      method: 'POST',
      data: document,
    }),
  deleteDocument: (id) =>
    request({
      url: `/wiki/documents/${id}`,
      method: 'DELETE',
    }),
};
