import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { retrieveInterlocutorIdFromConversationId } from '../../../js/chat/ChatUtils';
import {
  getInterlocutorName,
  getInterlocutorAvatar,
  getUnreadMessagesByConversation,
  isMainGroupConversation,
} from '../../../js/chat/selectors';
import UserAvatar from '../../../components/UserAvatar';
import GroupAvatar from '../../../components/GroupAvatar';
import NotificationBadge from '../../../components/NotificationBadge';
import { getUserStatus } from '../../../js/users/selectors';

const ChatWindowHeaderAvatar = ({
  conversationId,
  unreadMessages,
  interlocutorName,
  interlocutorAvatar,
  isMainGroup,
  userStatus,
}) => {
  const interlocutorId =
    retrieveInterlocutorIdFromConversationId(conversationId);
  return (
    <span
      className={
        userStatus && Object.keys(userStatus).length > 0
          ? 'd-inline-block ml-3'
          : ''
      }
    >
      {interlocutorId.userId ? (
        <UserAvatar
          size="sm"
          src={interlocutorAvatar}
          alt={interlocutorName}
          className="mr-1"
          id={interlocutorId.userId}
          imageSize="sm"
          clickable={false}
          showStatus
          statusPosition={{ bottom: 1, left: -18 }}
          context="ChatWindowHeaderAvatar"
        />
      ) : (
        <GroupAvatar
          size="sm"
          alt={interlocutorName}
          className="mr-1"
          clickable={false}
          main={isMainGroup}
        />
      )}
      {unreadMessages > 0 && (
        <NotificationBadge number={unreadMessages} top="0px" left="16px" />
      )}
    </span>
  );
};

ChatWindowHeaderAvatar.propTypes = {
  conversationId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    interlocutorName: getInterlocutorName(state, ownProps.conversationId),
    interlocutorAvatar: getInterlocutorAvatar(state, ownProps.conversationId),
    unreadMessages: getUnreadMessagesByConversation(
      state,
      ownProps.conversationId
    ),
    isMainGroup: isMainGroupConversation(state, ownProps.conversationId),
    userStatus:
      ownProps.conversationId &&
      retrieveInterlocutorIdFromConversationId(ownProps.conversationId)
        .userId &&
      getUserStatus(
        state,
        retrieveInterlocutorIdFromConversationId(ownProps.conversationId).userId
      ),
  };
}

export default connect(mapStateToProps)(ChatWindowHeaderAvatar);
