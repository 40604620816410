import {
  SET_PUNCHINGS_FILTER,
  SET_MANAGE_PUNCHINGS_FILTER,
  FETCH_PUNCHINGS_REQUEST,
  FETCH_PUNCHINGS_SUCCESS,
  FETCH_PUNCHINGS_FAILURE,
  SAVE_PUNCHING_REQUEST,
  SAVE_PUNCHING_SUCCESS,
  SAVE_PUNCHING_FAILURE,
  FETCH_PRESENCE_SETTINGS_REQUEST,
  FETCH_PRESENCE_SETTINGS_SUCCESS,
  FETCH_PRESENCE_SETTINGS_FAILURE,
  FETCH_VACATIONS_TO_VALIDATE_REQUEST,
  FETCH_VACATIONS_TO_VALIDATE_SUCCESS,
  FETCH_VACATIONS_TO_VALIDATE_FAILURE,
  SET_VACATIONS_FILTER,
  FETCH_PUNCHING_DETAILS_REQUEST,
  FETCH_PUNCHING_DETAILS_SUCCESS,
  FETCH_PUNCHING_DETAILS_FAILURE,
  FETCH_USERS_PUNCHINGS_REQUEST,
  FETCH_USERS_PUNCHINGS_SUCCESS,
  FETCH_USERS_PUNCHINGS_FAILURE,
  INSERT_VACATION_NOTIFICATION,
  DELETE_VACATION_NOTIFICATION,
  ASK_DELETING_VACATION_NOTIFICATION,
  CONFIRM_VACATION_NOTIFICATION,
  CONFIRM_DELETING_VACATION_NOTIFICATION,
  REJECT_DELETING_VACATION_NOTIFICATION,
  REJECT_VACATION_NOTIFICATION,
  CONFIRM_PUNCHING_NOTIFICATION,
  REJECT_PUNCHING_NOTIFICATION,
  FETCH_WORKING_USERS_REQUEST,
  FETCH_WORKING_USERS_SUCCESS,
  FETCH_WORKING_USERS_FAILURE,
  SET_WORKING_USERS_FILTER,
  FETCH_PBX_REGISTRY_REQUEST,
  FETCH_PBX_REGISTRY_SUCCESS,
  FETCH_PBX_REGISTRY_FAILURE,
  SAVE_PBX_REGISTRY_SUCCESS,
  SAVE_PBX_REGISTRY_FAILURE,
  SAVE_PBX_REGISTRY_REQUEST,
  FETCH_PUNCHINGS_PLAN_REQUEST,
  FETCH_PUNCHINGS_PLAN_SUCCESS,
  FETCH_PUNCHINGS_PLAN_FAILURE,
  FETCH_EXPORT_TEMPLATES_REQUEST,
  FETCH_EXPORT_TEMPLATES_SUCCESS,
  FETCH_EXPORT_TEMPLATES_FAILURE,
  INSERT_PUNCHING_NOTIFICATION,
  FETCH_EXPORT_TEMPLATE_REQUEST,
  FETCH_EXPORT_TEMPLATE_SUCCESS,
  FETCH_EXPORT_TEMPLATE_FAILURE,
  SAVE_EXPORT_TEMPLATE_REQUEST,
  SAVE_EXPORT_TEMPLATE_SUCCESS,
  SAVE_EXPORT_TEMPLATE_FAILURE,
  DELETE_EXPORT_TEMPLATE_SUCCESS,
  DELETE_EXPORT_TEMPLATE_REQUEST,
  DELETE_EXPORT_TEMPLATE_FAILURE,
  SET_PUNCHINGS_TO_VALIDATE_FILTER,
  FETCH_PUNCHINGS_TO_VALIDATE_REQUEST,
  FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS,
  FETCH_PUNCHINGS_TO_VALIDATE_FAILURE,
  VALIDATE_PUNCHING_REQUEST,
  VALIDATE_PUNCHING_SUCCESS,
  VALIDATE_PUNCHING_FAILURE,
  FETCH_ABSENCE_REQUEST,
  FETCH_ABSENCE_SUCCESS,
  FETCH_ABSENCE_FAILURE,
  SET_ABSENCE_FILTER,
  SAVE_ABSENCE_REQUEST,
  SAVE_ABSENCE_SUCCESS,
  SAVE_ABSENCE_FAILURE,
  SAVE_OTHER_PUNCHING_REQUEST,
  SAVE_OTHER_PUNCHING_SUCCESS,
  SAVE_OTHER_PUNCHING_FAILURE,
  DELETE_ABSENCE_REQUEST,
  DELETE_ABSENCE_SUCCESS,
  DELETE_ABSENCE_FAILURE,
  FETCH_QRCODE_SETTINGS_REQUEST,
  FETCH_QRCODE_SETTINGS_SUCCESS,
  FETCH_QRCODE_SETTINGS_FAILURE,
  SAVE_QRCODE_SETTINGS_REQUEST,
  SAVE_QRCODE_SETTINGS_SUCCESS,
  SAVE_QRCODE_SETTINGS_FAILURE,
  FETCH_GUEST_DATA_REQUEST,
  FETCH_GUEST_DATA_SUCCESS,
  FETCH_GUEST_DATA_FAILURE,
  SAVE_GUEST_VISIT_REQUEST,
  SAVE_GUEST_VISIT_SUCCESS,
  SAVE_GUEST_VISIT_FAILURE,
  FETCH_GUEST_VISITS_SUCCESS,
  FETCH_GUEST_VISITS_FAILURE,
  FETCH_GUEST_VISITS_REQUEST,
  FETCH_PUNCHING_JUSTIFICATIONS_REQUEST,
  FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS,
  FETCH_PUNCHING_JUSTIFICATIONS_FAILURE,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS,
  DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS,
  EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE,
  DELETE_OTHER_PUNCHING_REQUEST,
  DELETE_OTHER_PUNCHING_SUCCESS,
  DELETE_OTHER_PUNCHING_FAILURE,
  EDIT_OTHER_PUNCHING_REQUEST,
  EDIT_OTHER_PUNCHING_SUCCESS,
  EDIT_OTHER_PUNCHING_FAILURE,
  FETCH_WORKING_USERS_EXCEL_SUCCESS,
  FETCH_WORKING_USERS_EXCEL_FAILURE,
  SET_CASSA_INTEGRAZIONE_FILTER,
  FETCH_CASSA_INTEGRAZIONE_REQUEST,
  FETCH_CASSA_INTEGRAZIONE_SUCCESS,
  FETCH_CASSA_INTEGRAZIONE_FAILURE,
  SAVE_CASSA_INTEGRAZIONE_REQUEST,
  SAVE_CASSA_INTEGRAZIONE_SUCCESS,
  SAVE_CASSA_INTEGRAZIONE_FAILURE,
  DELETE_CASSA_INTEGRAZIONE_REQUEST,
  DELETE_CASSA_INTEGRAZIONE_SUCCESS,
  DELETE_CASSA_INTEGRAZIONE_FAILURE,
  SAVE_EXTRA_WORKINGTIME_REQUEST,
  SAVE_EXTRA_WORKINGTIME_SUCCESS,
  SAVE_EXTRA_WORKINGTIME_FAILURE,
  DELETE_EXTRA_WORKINGTIME_REQUEST,
  DELETE_EXTRA_WORKINGTIME_SUCCESS,
  DELETE_EXTRA_WORKINGTIME_FAILURE,
} from './types';

export const setPunchingsFilter = (payload) => ({
  type: SET_PUNCHINGS_FILTER,
  payload,
});
export const setManagePunchingsFilter = (payload) => ({
  type: SET_MANAGE_PUNCHINGS_FILTER,
  payload,
});
export const fetchPunchingsRequest = (payload) => ({
  type: FETCH_PUNCHINGS_REQUEST,
  payload,
});
export const fetchPunchingsSuccess = (payload) => ({
  type: FETCH_PUNCHINGS_SUCCESS,
  payload,
});
export const fetchPunchingsFailure = (errors) => ({
  type: FETCH_PUNCHINGS_FAILURE,
  errors,
});
export const savePunchingRequest = (payload) => ({
  type: SAVE_PUNCHING_REQUEST,
  payload,
});
export const savePunchingSuccess = () => ({
  type: SAVE_PUNCHING_SUCCESS,
});
export const savePunchingFailure = (errors) => ({
  type: SAVE_PUNCHING_FAILURE,
  errors,
});
export const fetchPresenceSettingsRequest = () => ({
  type: FETCH_PRESENCE_SETTINGS_REQUEST,
});
export const fetchPresenceSettingsSuccess = (settings) => ({
  type: FETCH_PRESENCE_SETTINGS_SUCCESS,
  settings,
});
export const fetchPresenceSettingsFailure = (errors) => ({
  type: FETCH_PRESENCE_SETTINGS_FAILURE,
  errors,
});
export const fetchVacationsToValidateRequest = (payload) => ({
  type: FETCH_VACATIONS_TO_VALIDATE_REQUEST,
  payload,
});
export const fetchVacationsToValidateSuccess = (vacations) => ({
  type: FETCH_VACATIONS_TO_VALIDATE_SUCCESS,
  vacations,
});
export const fetchVacationsToValidateFailure = (errors) => ({
  type: FETCH_VACATIONS_TO_VALIDATE_FAILURE,
  errors,
});
export const setVacationsFilter = (payload) => ({
  type: SET_VACATIONS_FILTER,
  payload,
});
export const fetchPunchingDetailsRequest = (payload) => ({
  type: FETCH_PUNCHING_DETAILS_REQUEST,
  payload,
});
export const fetchPunchingDetailsSuccess = (payload) => ({
  type: FETCH_PUNCHING_DETAILS_SUCCESS,
  payload,
});
export const fetchPunchingDetailsFailure = (errors) => ({
  type: FETCH_PUNCHING_DETAILS_FAILURE,
  errors,
});
export const fetchUsersPunchingsRequest = (payload) => ({
  type: FETCH_USERS_PUNCHINGS_REQUEST,
  payload,
});
export const fetchUsersPunchingsSuccess = (data) => ({
  type: FETCH_USERS_PUNCHINGS_SUCCESS,
  data,
});
export const fetchUsersPunchingsFailure = (errors) => ({
  type: FETCH_USERS_PUNCHINGS_FAILURE,
  errors,
});
export const fetchPunchingsPlanRequest = (payload) => ({
  type: FETCH_PUNCHINGS_PLAN_REQUEST,
  payload,
});
export const fetchPunchingsPlanSuccess = (data) => ({
  type: FETCH_PUNCHINGS_PLAN_SUCCESS,
  data,
});
export const fetchPunchingsPlanFailure = (errors) => ({
  type: FETCH_PUNCHINGS_PLAN_FAILURE,
  errors,
});
export const saveOtherPunchingRequest = (payload) => ({
  type: SAVE_OTHER_PUNCHING_REQUEST,
  payload,
});
export const saveOtherPunchingSuccess = (data) => ({
  type: SAVE_OTHER_PUNCHING_SUCCESS,
  data,
});
export const saveOtherPunchingFailure = (errors) => ({
  type: SAVE_OTHER_PUNCHING_FAILURE,
  errors,
});
export const insertVacationNotification = (payload) => ({
  type: INSERT_VACATION_NOTIFICATION,
  payload,
});
export const deleteVacationNotification = (payload) => ({
  type: DELETE_VACATION_NOTIFICATION,
  payload,
});
export const askDeletingVacationNotification = (payload) => ({
  type: ASK_DELETING_VACATION_NOTIFICATION,
  payload,
});
export const confirmVacationNotification = (payload) => ({
  type: CONFIRM_VACATION_NOTIFICATION,
  payload,
});
export const rejectVacationNotification = (payload) => ({
  type: REJECT_VACATION_NOTIFICATION,
  payload,
});
export const confirmDeletingVacationNotification = (payload) => ({
  type: CONFIRM_DELETING_VACATION_NOTIFICATION,
  payload,
});
export const rejectDeletingVacationNotification = (payload) => ({
  type: REJECT_DELETING_VACATION_NOTIFICATION,
  payload,
});
export const insertPunchingNotification = (payload) => ({
  type: INSERT_PUNCHING_NOTIFICATION,
  payload,
});
export const confirmPunchingNotification = (payload) => ({
  type: CONFIRM_PUNCHING_NOTIFICATION,
  payload,
});
export const rejectPunchingNotification = (payload) => ({
  type: REJECT_PUNCHING_NOTIFICATION,
  payload,
});
export const fetchWorkingUsersRequest = (payload) => ({
  type: FETCH_WORKING_USERS_REQUEST,
  payload,
});
export const fetchWorkingUsersSuccess = (payload) => ({
  type: FETCH_WORKING_USERS_SUCCESS,
  payload,
});
export const fetchWorkingUsersFailure = (errors) => ({
  type: FETCH_WORKING_USERS_FAILURE,
  errors,
});
export const fetchWorkingUsersExcelSuccess = (payload) => ({
  type: FETCH_WORKING_USERS_EXCEL_SUCCESS,
  payload,
});
export const fetchWorkingUsersExcelFailure = (errors) => ({
  type: FETCH_WORKING_USERS_EXCEL_FAILURE,
  errors,
});
export const setWorkingUsersFilter = (payload) => ({
  type: SET_WORKING_USERS_FILTER,
  payload,
});
export const fetchPbxRegistryRequest = (id) => ({
  type: FETCH_PBX_REGISTRY_REQUEST,
  id,
});
export const fetchPbxRegistrySuccess = (params) => ({
  type: FETCH_PBX_REGISTRY_SUCCESS,
  params,
});
export const fetchPbxRegistryFailure = (errors) => ({
  type: FETCH_PBX_REGISTRY_FAILURE,
  errors,
});
export const savePbxRegistryRequest = (payload) => ({
  type: SAVE_PBX_REGISTRY_REQUEST,
  payload,
});
export const savePbxRegistrySuccess = (params) => ({
  type: SAVE_PBX_REGISTRY_SUCCESS,
  params,
});
export const savePbxRegistryFailure = (errors) => ({
  type: SAVE_PBX_REGISTRY_FAILURE,
  errors,
});
export const fetchExportTemplatesRequest = () => ({
  type: FETCH_EXPORT_TEMPLATES_REQUEST,
});
export const fetchExportTemplatesSuccess = (data) => ({
  type: FETCH_EXPORT_TEMPLATES_SUCCESS,
  data,
});
export const fetchExportTemplatesFailure = (errors) => ({
  type: FETCH_EXPORT_TEMPLATES_FAILURE,
  errors,
});
export const fetchExportTemplateRequest = (id) => ({
  type: FETCH_EXPORT_TEMPLATE_REQUEST,
  id,
});
export const fetchExportTemplateSuccess = (data) => ({
  type: FETCH_EXPORT_TEMPLATE_SUCCESS,
  data,
});
export const fetchExportTemplateFailure = (errors) => ({
  type: FETCH_EXPORT_TEMPLATE_FAILURE,
  errors,
});
export const saveExportTemplateRequest = (payload) => ({
  type: SAVE_EXPORT_TEMPLATE_REQUEST,
  payload,
});
export const saveExportTemplateSuccess = (id) => ({
  type: SAVE_EXPORT_TEMPLATE_SUCCESS,
  id,
});
export const saveExportTemplateFailure = (errors) => ({
  type: SAVE_EXPORT_TEMPLATE_FAILURE,
  errors,
});
export const deleteExportTemplateRequest = (payload) => ({
  type: DELETE_EXPORT_TEMPLATE_REQUEST,
  payload,
});
export const deleteExportTemplateSuccess = (templateId) => ({
  type: DELETE_EXPORT_TEMPLATE_SUCCESS,
  templateId,
});
export const deleteExportTemplateFailure = (errors) => ({
  type: DELETE_EXPORT_TEMPLATE_FAILURE,
  errors,
});
export const setPunchingsValidationsFilter = (payload) => ({
  type: SET_PUNCHINGS_TO_VALIDATE_FILTER,
  payload,
});
export const fetchPunchingsToValidateRequest = (payload) => ({
  type: FETCH_PUNCHINGS_TO_VALIDATE_REQUEST,
  payload,
});
export const fetchPunchingsToValidateSuccess = (punchings) => ({
  type: FETCH_PUNCHINGS_TO_VALIDATE_SUCCESS,
  punchings,
});
export const fetchPunchingsToValidateFailure = (errors) => ({
  type: FETCH_PUNCHINGS_TO_VALIDATE_FAILURE,
  errors,
});
export const validatePunchingRequest = (payload) => ({
  type: VALIDATE_PUNCHING_REQUEST,
  payload,
});
export const validatePunchingSuccess = () => ({
  type: VALIDATE_PUNCHING_SUCCESS,
});
export const validatePunchingFailure = (errors) => ({
  type: VALIDATE_PUNCHING_FAILURE,
  errors,
});
export const setAbsenceFilter = (payload) => ({
  type: SET_ABSENCE_FILTER,
  payload,
});
export const fetchAbsenceRequest = (payload) => ({
  type: FETCH_ABSENCE_REQUEST,
  payload,
});
export const fetchAbsenceSuccess = (absence) => ({
  type: FETCH_ABSENCE_SUCCESS,
  absence,
});
export const fetchAbsenceFailure = (errors) => ({
  type: FETCH_ABSENCE_FAILURE,
  errors,
});
export const saveAbsenceRequest = (payload, id) => ({
  type: SAVE_ABSENCE_REQUEST,
  payload,
  id,
});
export const saveAbsenceSuccess = (id) => ({
  type: SAVE_ABSENCE_SUCCESS,
  id,
});
export const saveAbsenceFailure = (errors) => ({
  type: SAVE_ABSENCE_FAILURE,
  errors,
});
export const deleteAbsenceRequest = (id) => ({
  type: DELETE_ABSENCE_REQUEST,
  id,
});
export const deleteAbsenceSuccess = (id) => ({
  type: DELETE_ABSENCE_SUCCESS,
  id,
});
export const deleteAbsenceFailure = (errors) => ({
  type: DELETE_ABSENCE_FAILURE,
  errors,
});
export const fetchQRCodeSettingsRequest = () => ({
  type: FETCH_QRCODE_SETTINGS_REQUEST,
});
export const fetchQRCodeSettingsSuccess = (payload) => ({
  type: FETCH_QRCODE_SETTINGS_SUCCESS,
  payload,
});
export const fetchQRCodeSettingsFailure = (errors) => ({
  type: FETCH_QRCODE_SETTINGS_FAILURE,
  errors,
});
export const saveQRCodeSettingsRequest = (payload) => ({
  type: SAVE_QRCODE_SETTINGS_REQUEST,
  payload,
});
export const saveQRCodeSettingsSuccess = (id) => ({
  type: SAVE_QRCODE_SETTINGS_SUCCESS,
  id,
});
export const saveQRCodeSettingsFailure = (errors) => ({
  type: SAVE_QRCODE_SETTINGS_FAILURE,
  errors,
});
export const fetchGuestDataRequest = (payload) => ({
  type: FETCH_GUEST_DATA_REQUEST,
  payload,
});
export const fetchGuestDataSuccess = (payload) => ({
  type: FETCH_GUEST_DATA_SUCCESS,
  payload,
});
export const fetchGuestDataFailure = (errors) => ({
  type: FETCH_GUEST_DATA_FAILURE,
  errors,
});
export const saveGuestVisitRequest = (payload) => ({
  type: SAVE_GUEST_VISIT_REQUEST,
  payload,
});
export const saveGuestVisitSuccess = (id) => ({
  type: SAVE_GUEST_VISIT_SUCCESS,
  id,
});
export const saveGuestVisitFailure = (errors) => ({
  type: SAVE_GUEST_VISIT_FAILURE,
  errors,
});
export const fetchGuestVisitsRequest = (payload) => ({
  type: FETCH_GUEST_VISITS_REQUEST,
  payload,
});
export const fetchGuestVisitsSuccess = (payload) => ({
  type: FETCH_GUEST_VISITS_SUCCESS,
  payload,
});
export const fetchGuestVisitsFailure = (errors) => ({
  type: FETCH_GUEST_VISITS_FAILURE,
  errors,
});
export const fetchPunchingJustificationsRequest = () => ({
  type: FETCH_PUNCHING_JUSTIFICATIONS_REQUEST,
});
export const fetchPunchingJustificationsSuccess = (data) => ({
  type: FETCH_PUNCHING_JUSTIFICATIONS_SUCCESS,
  data,
});
export const fetchPunchingJustificationsFailure = () => ({
  type: FETCH_PUNCHING_JUSTIFICATIONS_FAILURE,
});
export const deletePunchingJustificationRequest = (id) => ({
  type: DELETE_OTHER_PUNCHING_JUSTIFICATION_REQUEST,
  id,
});
export const deletePunchingJustificationSuccess = () => ({
  type: DELETE_OTHER_PUNCHING_JUSTIFICATION_SUCCESS,
});
export const deletePunchingJustificationFailure = (error) => ({
  type: DELETE_OTHER_PUNCHING_JUSTIFICATION_FAILURE,
  error,
});
export const editPunchingJustificationRequest = (payload) => ({
  type: EDIT_OTHER_PUNCHING_JUSTIFICATIONS_REQUEST,
  payload,
});
export const editPunchingJustificationSuccess = (payload) => ({
  type: EDIT_OTHER_PUNCHING_JUSTIFICATIONS_SUCCESS,
  payload,
});
export const editPunchingJustificationFailure = (errors) => ({
  type: EDIT_OTHER_PUNCHING_JUSTIFICATIONS_FAILURE,
  errors,
});
export const deletePunchingRequest = (payload) => ({
  type: DELETE_OTHER_PUNCHING_REQUEST,
  payload,
});
export const deletePunchingSuccess = () => ({
  type: DELETE_OTHER_PUNCHING_SUCCESS,
});
export const deletePunchingFailure = (errors) => ({
  type: DELETE_OTHER_PUNCHING_FAILURE,
  errors,
});
export const editPunchingRequest = (payload) => ({
  type: EDIT_OTHER_PUNCHING_REQUEST,
  payload,
});
export const editPunchingSuccess = () => ({
  type: EDIT_OTHER_PUNCHING_SUCCESS,
});
export const editPunchingFailure = (errors) => ({
  type: EDIT_OTHER_PUNCHING_FAILURE,
  errors,
});
export const setCassaIntegrazioneFilter = (payload) => ({
  type: SET_CASSA_INTEGRAZIONE_FILTER,
  payload,
});
export const fetchCassaIntegrazioneRequest = (payload) => ({
  type: FETCH_CASSA_INTEGRAZIONE_REQUEST,
  payload,
});
export const fetchCassaIntegrazioneSuccess = (cassaIntegrazione) => ({
  type: FETCH_CASSA_INTEGRAZIONE_SUCCESS,
  cassaIntegrazione,
});
export const fetchCassaIntegrazioneFailure = (errors) => ({
  type: FETCH_CASSA_INTEGRAZIONE_FAILURE,
  errors,
});
export const saveCassaIntegrazioneRequest = (payload, id) => ({
  type: SAVE_CASSA_INTEGRAZIONE_REQUEST,
  payload,
  id,
});
export const saveCassaIntegrazioneSuccess = (id) => ({
  type: SAVE_CASSA_INTEGRAZIONE_SUCCESS,
  id,
});
export const saveCassaIntegrazioneFailure = (errors) => ({
  type: SAVE_CASSA_INTEGRAZIONE_FAILURE,
  errors,
});
export const deleteCassaIntegrazioneRequest = (id) => ({
  type: DELETE_CASSA_INTEGRAZIONE_REQUEST,
  id,
});
export const deleteCassaIntegrazioneSuccess = (id) => ({
  type: DELETE_CASSA_INTEGRAZIONE_SUCCESS,
  id,
});
export const deleteCassaIntegrazioneFailure = (errors) => ({
  type: DELETE_CASSA_INTEGRAZIONE_FAILURE,
  errors,
});
export const saveExtraWorkingTimeRequest = (payload) => ({
  type: SAVE_EXTRA_WORKINGTIME_REQUEST,
  payload,
});
export const saveExtraWorkingTimeSuccess = (data) => ({
  type: SAVE_EXTRA_WORKINGTIME_SUCCESS,
  data,
});
export const saveExtraWorkingTimeFailure = (errors) => ({
  type: SAVE_EXTRA_WORKINGTIME_FAILURE,
  errors,
});
export const deleteExtraWorkingTimeRequest = (id) => ({
  type: DELETE_EXTRA_WORKINGTIME_REQUEST,
  id,
});
export const deleteExtraWorkingTimeSuccess = (id) => ({
  type: DELETE_EXTRA_WORKINGTIME_SUCCESS,
  id,
});
export const deleteExtraWorkingTimeFailure = (errors) => ({
  type: DELETE_EXTRA_WORKINGTIME_FAILURE,
  errors,
});
