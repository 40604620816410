export class MeEnums {
  static MeTypes = {
    USER: 'USER',
    GUEST: 'GUEST',
  };
}

export const defaultStatuses = [
  {
    emoji: '🏠',
    label: 'atHome',
  },
  {
    emoji: '🍝',
    label: 'atLunch',
  },
  {
    emoji: '☕',
    label: 'coffeeBreak',
  },
  {
    emoji: '💼',
    label: 'inMeeting',
  },
  {
    emoji: '🏖',
    label: 'atHoliday',
  },
  {
    emoji: '⛔',
    label: 'busy',
  },
];
