import {
  FETCH_ME_CALLS_REQUEST,
  FETCH_ME_CALLS_SUCCESS,
  FETCH_ME_CALLS_FAILURE,
  FETCH_PBX_CALLS_REQUEST,
  FETCH_PBX_CALLS_SUCCESS,
  FETCH_PBX_CALLS_FAILURE,
  SET_PBX_CALLS_FILTER,
  DELETE_RECORDED_CALL_REQUEST,
  DELETE_RECORDED_CALL_SUCCESS,
  DELETE_RECORDED_CALL_FAILURE,
  DELETE_OWN_CALL_REQUEST,
  DELETE_OWN_CALL_SUCCESS,
  DELETE_OWN_CALL_FAILURE,
  DELETE_OTHER_CALL_REQUEST,
  DELETE_OTHER_CALL_SUCCESS,
  DELETE_OTHER_CALL_FAILURE,
  INSERT_CALLS_NOTES_REQUEST,
  INSERT_CALLS_NOTES_SUCCESS,
  INSERT_CALLS_NOTES_FAILURE,
  REFRESH_ME_CALLS,
  FETCH_QUEUES_CALLS_REQUEST,
  FETCH_QUEUES_CALLS_SUCCESS,
  FETCH_QUEUES_CALLS_FAILURE,
  SET_ME_CALLS_FILTER,
  SET_QUEUES_CALLS_FILTER,
} from './types';

export const fetchMeCallsRequest = (params) => ({
  type: FETCH_ME_CALLS_REQUEST,
  params,
});
export const fetchMeCallsSuccess = (calls) => ({
  type: FETCH_ME_CALLS_SUCCESS,
  calls,
});
export const fetchMeCallsFailure = (error) => ({
  type: FETCH_ME_CALLS_FAILURE,
  error,
});
export const fetchPbxCallsRequest = (params) => ({
  type: FETCH_PBX_CALLS_REQUEST,
  params,
});
export const fetchPbxCallsSuccess = (payload) => ({
  type: FETCH_PBX_CALLS_SUCCESS,
  payload,
});
export const fetchPbxCallsFailure = (error) => ({
  type: FETCH_PBX_CALLS_FAILURE,
  error,
});
export const setPbxCallsFilter = (filter) => ({
  type: SET_PBX_CALLS_FILTER,
  filter,
});
export const deleteRecordedCallRequest = (filename) => ({
  type: DELETE_RECORDED_CALL_REQUEST,
  filename,
});
export const deleteRecordedCallSuccess = (filename) => ({
  type: DELETE_RECORDED_CALL_SUCCESS,
  filename,
});
export const deleteRecordedCallFailure = (error) => ({
  type: DELETE_RECORDED_CALL_FAILURE,
  error,
});
export const deleteOwnCallRequest = (callId) => ({
  type: DELETE_OWN_CALL_REQUEST,
  callId,
});
export const deleteOwnCallSuccess = (callId) => ({
  type: DELETE_OWN_CALL_SUCCESS,
  callId,
});
export const deleteOwnCallFailure = (error) => ({
  type: DELETE_OWN_CALL_FAILURE,
  error,
});
export const deleteOtherCallRequest = (callId) => ({
  type: DELETE_OTHER_CALL_REQUEST,
  callId,
});
export const deleteOtherCallSuccess = (callId) => ({
  type: DELETE_OTHER_CALL_SUCCESS,
  callId,
});
export const deleteOtherCallFailure = (error) => ({
  type: DELETE_OTHER_CALL_FAILURE,
  error,
});
export const insertCallsNotesRequest = (params, context) => ({
  type: INSERT_CALLS_NOTES_REQUEST,
  params,
  context,
});
export const insertCallsNotesSuccess = (params, context) => ({
  type: INSERT_CALLS_NOTES_SUCCESS,
  params,
  context,
});
export const insertCallsNotesFailure = (error) => ({
  type: INSERT_CALLS_NOTES_FAILURE,
  error,
});
export const refreshMeCalls = () => ({
  type: REFRESH_ME_CALLS,
});
export const fetchQueuesCallsRequest = (params) => ({
  type: FETCH_QUEUES_CALLS_REQUEST,
  params,
});
export const fetchQueuesCallsSuccess = (calls) => ({
  type: FETCH_QUEUES_CALLS_SUCCESS,
  calls,
});
export const fetchQueuesCallsFailure = (error) => ({
  type: FETCH_QUEUES_CALLS_FAILURE,
  error,
});
export const setMeCallsFilter = (filter) => ({
  type: SET_ME_CALLS_FILTER,
  filter,
});
export const setQueuesCallsFilter = (filter) => ({
  type: SET_QUEUES_CALLS_FILTER,
  filter,
});
