import { takeLatest, call, put } from 'redux-saga/effects';
import {
  /** Fetch Stickies */
  fetchStickyNotesSuccess,
  fetchStickyNotesFailure,
  /** Add Stickies */
  addStickyNotesSuccess,
  addStickyNotesFailure,
  /** Delete Sticky */
  deleteStickyNoteSuccess,
  deleteStickyNoteFailure,
  /** Update Sticky */
  updateStickyVisibilitySuccess,
  updateStickyVisibilityFailure,
  /** Modify Sticky */
  updateStickySuccess,
  updateStickyFailure,
  /* Share sticky */
  shareStickySuccess,
  shareStickyFailure,
  /* Fetch Static Notes */
  fetchStaticNotesSuccess,
  fetchStaticNotesFailure,
  createStaticNoteSuccess,
  createStaticNoteFailure,
  fetchStaticNotesRequest,
  /* Update Static notes order */
  updateStaticNotesOrderSuccess,
  updateStaticNotesOrderFailure,
  /* Edit a static note */
  editStaticNoteSuccess,
  editStaticNoteFailure,
  /* Delete a note */
  removeStaticNoteSuccess,
  removeStaticNoteFailure,
  fetchStickyNotesRequest,
  updateStickyVisibilityRequest,
} from './actions';
import { INIT_MAIN_SUCCESS } from '../me/types';
import {
  /** Fetch Stickies */
  ADD_STICKY_NOTES_REQUEST,
  /** Delete Sticky */
  DELETE_STICKY_NOTE_REQUEST,
  /** Update Sticky */
  UPDATE_STICKY_VISIBILITY_REQUEST,
  /** Modify Sticky */
  UPDATE_STICKY_REQUEST,
  /** Share Sticky */
  SHARE_STICKY_REQUEST,
  /* Fetch Static Notes */
  FETCH_STATIC_NOTES_REQUEST,
  CREATE_STATIC_NOTE_REQUEST,
  UPDATE_STATIC_NOTES_ORDER_REQUEST,
  EDIT_STATIC_NOTE_REQUEST,
  REMOVE_STATIC_NOTE_REQUEST,
  FETCH_STICKY_NOTES_REQUEST,
  SHARE_STICKY_NOTIFICATION,
  EDIT_STICKY_NOTIFICATION,
  DELETE_STICKY_NOTIFICATION,
} from './types';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import StickyUtils from './StickyUtils';

function* fetchStickies() {
  try {
    const res = yield call(api.stickyNotes.getStickyNotes);
    yield call(checkApiResponse, res);
    yield put(fetchStickyNotesSuccess(res.data, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchStickyNotesFailure(error));
  }
}

function* addStickyNote(action) {
  try {
    const params = action.params || {
      text: StickyUtils.stickyText,
      detail: StickyUtils.stickyDetails,
    };
    const res = yield call(api.stickyNotes.addStickyNote, params);
    yield call(checkApiResponse, res);
    yield put(
      addStickyNotesSuccess({ ...params, id: res.data.id }, res.status)
    );
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(addStickyNotesFailure(error));
  }
}

function* deleteStickyNote(action) {
  try {
    const res = yield call(api.stickyNotes.deleteSticky, action.id);
    yield call(checkApiResponse, res);
    yield put(deleteStickyNoteSuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deleteStickyNoteFailure(error));
  }
}

function* updateStickyVisibility(action) {
  try {
    const res = yield call(
      api.stickyNotes.updateStickyVisibility,
      action.stickyShow
    );
    yield call(checkApiResponse, res);
    yield put(updateStickyVisibilitySuccess(action.stickyShow, res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateStickyVisibilityFailure(error));
  }
}

function* updateSticky(action) {
  try {
    const res = yield call(
      api.stickyNotes.updateSticky,
      action.id,
      action.sticky
    );
    yield call(checkApiResponse, res);
    yield put(updateStickySuccess(res.status));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateStickyFailure(error));
  }
}

function* shareSticky(action) {
  try {
    const res = yield call(
      api.stickyNotes.shareSticky,
      action.id,
      action.users
    );
    yield call(checkApiResponse, res);
    yield put(shareStickySuccess());
    yield put(fetchStickyNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(shareStickyFailure(error));
  }
}

export function* manageShareStickyNotification() {
  try {
    yield put(fetchStickyNotesRequest());
    yield put(updateStickyVisibilityRequest(true));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchStickyNotesFailure(error));
  }
}

export function* manageEditStickyNotification() {
  try {
    yield put(fetchStickyNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchStickyNotesFailure(error));
  }
}

export function* manageDeleteStickyNotification() {
  try {
    yield put(fetchStickyNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchStickyNotesFailure(error));
  }
}

/* Static Notes */
export function* fetchStaticNotes() {
  try {
    const res = yield call(api.stickyNotes.getStaticNotes);
    yield call(checkApiResponse, res);
    yield put(fetchStaticNotesSuccess(res.status === 200 ? res.data : []));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchStaticNotesFailure(error));
  }
}

export function* createStaticNote(action) {
  try {
    const res = yield call(api.stickyNotes.createStaticNote, action.payload);
    yield call(checkApiResponse, res);
    yield put(createStaticNoteSuccess(res.status === 200 ? res.data : []));
    yield put(fetchStaticNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(createStaticNoteFailure(error));
  }
}

export function* updateStaticNotesOrder(action) {
  try {
    const res = yield call(
      api.stickyNotes.updateStaticNotesOrder,
      action.id,
      action.data
    );
    yield call(checkApiResponse, res);
    yield put(updateStaticNotesOrderSuccess());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(updateStaticNotesOrderFailure(error));
  }
}

export function* editStaticNotes(action) {
  try {
    const res = yield call(api.stickyNotes.editStaticNotes, action.payload);
    yield call(checkApiResponse, res);
    yield put(editStaticNoteSuccess());
    yield put(fetchStaticNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editStaticNoteFailure(error));
  }
}

export function* removeStaticNote(action) {
  try {
    const res = yield call(api.stickyNotes.removeStaticNote, action.id);
    yield call(checkApiResponse, res);
    yield put(removeStaticNoteSuccess());
    yield put(fetchStaticNotesRequest());
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(removeStaticNoteFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(INIT_MAIN_SUCCESS, fetchStickies);
  yield takeLatest(ADD_STICKY_NOTES_REQUEST, addStickyNote);
  yield takeLatest(DELETE_STICKY_NOTE_REQUEST, deleteStickyNote);
  yield takeLatest(UPDATE_STICKY_VISIBILITY_REQUEST, updateStickyVisibility);
  yield takeLatest(UPDATE_STICKY_REQUEST, updateSticky);
  yield takeLatest(SHARE_STICKY_REQUEST, shareSticky);
  yield takeLatest(FETCH_STICKY_NOTES_REQUEST, fetchStickies);

  yield takeLatest(FETCH_STATIC_NOTES_REQUEST, fetchStaticNotes);
  yield takeLatest(CREATE_STATIC_NOTE_REQUEST, createStaticNote);
  yield takeLatest(UPDATE_STATIC_NOTES_ORDER_REQUEST, updateStaticNotesOrder);
  yield takeLatest(EDIT_STATIC_NOTE_REQUEST, editStaticNotes);
  yield takeLatest(REMOVE_STATIC_NOTE_REQUEST, removeStaticNote);

  yield takeLatest(SHARE_STICKY_NOTIFICATION, manageShareStickyNotification);
  yield takeLatest(EDIT_STICKY_NOTIFICATION, manageEditStickyNotification);
  yield takeLatest(DELETE_STICKY_NOTIFICATION, manageDeleteStickyNotification);
}
