import React from 'react';
import classNames from 'classnames';

class PrinciplesList extends React.Component {
  principleSatisfied(principle) {
    const { password } = this.props;

    return principle.predicate(password);
  }

  principleClass(principle) {
    const satisfied = this.principleSatisfied(principle);

    return classNames({
      'text-success': satisfied,
    });
  }

  render() {
    const { principles } = this.props;

    return (
      <div>
        {principles.map((principle, i) => (
          <span
            className={this.principleClass(principle)}
            style={{ display: 'inline' }}
            key={`password-principle-${i}`}
          >
            {principle.label}
          </span>
        ))}
      </div>
    );
  }
}

export default PrinciplesList;
