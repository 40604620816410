import React from 'react';
import Groups from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';

const AdminGroupsList = React.lazy(() => import('./AdminGroupsList'));

const AdminGroupsListPage = (props) => (
  <Groups scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <AdminGroupsList {...props} />
      </React.Suspense>
    </ErrorBoundary>
  </Groups>
);

export default AdminGroupsListPage;
