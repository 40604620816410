import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import FileboxUploaderToaster from '../containers/sections/Filebox/FileboxUploaderToaster';
import { getFiles } from '../js/files/selectors';
import { resetFileboxUploadedFiles } from '../js/files/actions';

class ToastMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploaderToasterId: null,
    };
  }

  componentDidUpdate(prevprops) {
    const { uploadingFiles, resetFiles } = this.props;
    const { uploaderToasterId } = this.state;

    if (uploadingFiles !== prevprops.uploadingFiles) {
      const pendingUploads = uploadingFiles.filter((f) => !f.loaded);
      const errorUploads = uploadingFiles.filter((f) => f.error);

      if (uploadingFiles.length) {
        if (!uploaderToasterId) {
          const toasterId = toast(
            <FileboxUploaderToaster files={uploadingFiles} />,
            {
              type: toast.TYPE.DEFAULT,
              onClose: () => {
                resetFiles();
                this.setState({ uploaderToasterId: null });
              },
            }
          );
          this.setState({ uploaderToasterId: toasterId });
        } else {
          toast.update(uploaderToasterId, {
            type: toast.TYPE.DEFAULT,
            render: (
              <FileboxUploaderToaster
                files={uploadingFiles}
                pending={pendingUploads.length > 0}
                error={errorUploads.length > 0}
              />
            ),
            closeButton: pendingUploads.length === 0, // all uploads are fineshed
            autoClose: false,
            /*pendingUploads.length === 0 && errorUploads.length === 0
                ? 5000
                : false,*/
            closeOnClick: pendingUploads.length === 0,
          });
        }
      }
    }
  }

  render() {
    return (
      <ToastContainer
        id="ToastMessagesContainer"
        hideProgressBar
        autoClose={false}
        position="bottom-right"
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
      />
    );
  }
}

//ToastMessages.propTypes = {};

function mapStateToProps(state) {
  return {
    uploadingFiles: getFiles(state, 'filebox'),
  };
}

export default connect(mapStateToProps, {
  resetFiles: resetFileboxUploadedFiles,
})(ToastMessages);
