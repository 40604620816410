import React from 'react';
import Fax from './index';
import { FaxEnums } from '../../../js/fax/FaxUtils';
import ErrorBoundary from '../../../components/ErrorBoundary';

const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const FaxListFilter = React.lazy(() => import('./FaxListFilter'));
const FaxListResult = React.lazy(() => import('./FaxListResult'));

const FaxReceivedListPage = (props) => (
  <Fax scrolling>
    <ErrorBoundary>
      <React.Suspense fallback={<div />}>
        <>
          <div className="text-right">
            <HelpButton fileName="fax-received" />
          </div>
          <FaxListFilter
            location={props.location}
            type={FaxEnums.FaxType.RECEIVE}
          />
          <FaxListResult type={FaxEnums.FaxType.RECEIVE} />
        </>
      </React.Suspense>
    </ErrorBoundary>
  </Fax>
);

export default FaxReceivedListPage;
