import { request } from '../request';

export default {
  getTickets: (params) =>
    request({
      url: '/crm/tickets',
      method: 'GET',
      params,
    }),
  getTicketStatus: () =>
    request({
      url: '/crm/ticket-status',
      method: 'GET',
    }),
  insertTicket: (params) =>
    request({
      url: `/crm/tickets`,
      method: 'POST',
      data: params,
    }),
  fetchCustomizedQuestions: (params) =>
    request({
      url: `/crm/customized-trees`,
      method: 'GET',
      params,
    }),
  getTicketDetails: (id) =>
    request({
      url: `/crm/tickets/${id}`,
      method: 'GET',
    }),
  getTicketsTree: (params) =>
    request({
      url: '/crm/customized-trees',
      method: 'GET',
      params,
    }),
  saveTicketsTree: (params) =>
    request({
      url: '/crm/customized-trees',
      method: 'POST',
      data: params,
    }),
  deleteTicketsTree: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}`,
      method: 'DELETE',
    }),
  createQuestion: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions`,
      method: 'POST',
      data: params,
    }),
  updateQuestion: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions/${params.questionId}`,
      method: 'POST',
      data: params,
    }),
  deleteQuestion: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions/${params.questionId}`,
      method: 'DELETE',
    }),
  createChoice: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions/${params.questionId}/choices`,
      method: 'POST',
      data: params,
    }),
  updateChoice: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions/${params.questionId}/choices/${params.choiceId}`,
      method: 'POST',
      data: params,
    }),
  deleteChoice: (params) =>
    request({
      url: `/crm/customized-trees/${params.treeId}/questions/${params.questionId}/choices/${params.choiceId}`,
      method: 'DELETE',
    }),
  closeTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/close`,
      method: 'POST',
      data: params.data,
    }),
  assignTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/assign`,
      method: 'POST',
      data: params.data,
    }),
  unassignTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/unassign`,
      method: 'POST',
      data: params.data,
    }),
  takeTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/take`,
      method: 'POST',
      data: params.data,
    }),
  workTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/work`,
      method: 'POST',
      data: params.data,
    }),
  suspendTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/suspend`,
      method: 'POST',
      data: params.data,
    }),
  shareTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/share`,
      method: 'POST',
      data: params.data,
    }),
  unshareTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/unshare`,
      method: 'POST',
      data: params.data,
    }),
  deleteTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}`,
      method: 'DELETE',
    }),
  deleteTicketGroup: (params) =>
    request({
      url: `/crm/tickets/group/${params.group}`,
      method: 'DELETE',
    }),
  changeTicketStatus: (params) =>
    request({
      url: `/crm/tickets/${params.id}/change-status`,
      method: 'POST',
      data: params.data,
    }),
  changeCategory: (params) =>
    request({
      url: `/crm/tickets/${params.id}/change-category`,
      method: 'POST',
      data: params.data,
    }),
  insertTicketStatus: (params) =>
    request({
      url: '/crm/ticket-status',
      method: 'POST',
      data: params,
    }),
  editTicketStatus: (params) =>
    request({
      url: `/crm/ticket-status/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  deleteTicketStatus: (params) =>
    request({
      url: `/crm/ticket-status/${params.id}`,
      method: 'DELETE',
    }),
  getTicketCategories: () =>
    request({
      url: '/crm/ticket-categories',
      method: 'GET',
    }),
  insertTicketCategory: (params) =>
    request({
      url: '/crm/ticket-categories',
      method: 'POST',
      data: params,
    }),
  editTicketCategory: (params) =>
    request({
      url: `/crm/ticket-categories/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  deleteTicketCategory: (params) =>
    request({
      url: `/crm/ticket-categories/${params.id}`,
      method: 'DELETE',
    }),
  editTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}`,
      method: 'POST',
      data: params.data,
    }),
  editTicketGroup: (params) =>
    request({
      url: `/crm/tickets/group/${params.group}`,
      method: 'POST',
      data: params.data,
    }),
  getTicketSettings: () =>
    request({
      url: `/crm/ticket-settings`,
      method: 'GET',
    }),
  updateTicketSettings: (settings) =>
    request({
      url: `/crm/ticket-settings`,
      method: 'POST',
      data: settings,
    }),
  getTicketDashboardHeader: () =>
    request({
      url: `/crm/ticket-dashboard-header`,
      method: 'GET',
    }),
  getTicketDashboardProgressBar: () =>
    request({
      url: `/crm/ticket-dashboard-progress-bar`,
      method: 'GET',
    }),
  getTicketDashboardUsersStatistics: () =>
    request({
      url: `/crm/ticket-dashboard-users-statistics`,
      method: 'GET',
    }),
  getTicketDashboardGraphStatistics: (params) =>
    request({
      url: `/crm/ticket-dashboard-graph-statistics`,
      method: 'GET',
      params,
    }),
  getTicketsListShortcut: () =>
    request({
      url: `/crm/ticket-list-header`,
      method: 'GET',
    }),
  reopenTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/reopen`,
      method: 'POST',
      data: params.data,
    }),
  archiveTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/archiving`,
      method: 'POST',
      data: {
        archive: params.archive,
      },
    }),
  muteTicket: (params) =>
    request({
      url: `/crm/tickets/${params.id}/mute`,
      method: 'POST',
      data: {
        muted: params.muted,
      },
    }),
};
