import React from 'react';
import { connect } from 'react-redux';
import Mailoffice from '.';
import BackButton from '../../../components/buttons/BackButton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
import { fetchMailofficeCreditRequest } from '../../../js/mailoffice/actions';
import { fetchConfigurationsRequest } from '../../../js/settings/actions';

const MailofficeSendAlertToHandleLowCredit = React.lazy(() =>
  import('./MailofficeSendAlertToHandleLowCredit')
);

const MailofficeSendForm = React.lazy(() => import('./MailofficeSendForm'));

class MailofficeSendPage extends React.Component {
  componentDidMount() {
    this.props.fetchCredits();
    this.props.fetchConfigurations();
  }

  render() {
    const { match, credit, maxPrice, minPrice } = this.props;
    const showAlert = credit < minPrice;
    const showWarning = credit > minPrice && credit < maxPrice;

    return (
      <Mailoffice scrolling={showAlert || showWarning}>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <>
              <MailofficeSendAlertToHandleLowCredit
                credit={credit}
                showAlert={showAlert}
                showWarning={showWarning}
              />
              <div className="row mb-2">
                <div className="col text-left">
                  <BackButton />
                </div>
                <div className="col text-right">
                  <HelpButton fileName="mailoffice-send" />
                </div>
              </div>
              <MailofficeSendForm id={match.params.id} />
            </>
          </React.Suspense>
        </ErrorBoundary>
      </Mailoffice>
    );
  }
}

function mapStateToProps(state) {
  return {
    credit: state.mailoffice.credit,
    maxPrice: state.settings.configurations.mailoffice.maxPrice,
    minPrice: state.settings.configurations.mailoffice.minPrice,
  };
}

export default connect(mapStateToProps, {
  fetchCredits: fetchMailofficeCreditRequest,
  fetchConfigurations: fetchConfigurationsRequest,
})(MailofficeSendPage);
