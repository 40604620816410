import {
  FETCH_HOTEL_AREA_PREFIXES_REQUEST,
  FETCH_HOTEL_AREA_PREFIXES_SUCCESS,
  FETCH_HOTEL_AREA_PREFIXES_FAILURE,
  FETCH_HOTEL_PRICES_REQUEST,
  FETCH_HOTEL_PRICES_SUCCESS,
  FETCH_HOTEL_PRICES_FAILURE,
  SAVE_HOTEL_PRICES_REQUEST,
  SAVE_HOTEL_PRICES_SUCCESS,
  SAVE_HOTEL_PRICES_FAILURE,
  DELETE_HOTEL_PRICE_REQUEST,
  DELETE_HOTEL_PRICE_SUCCESS,
  DELETE_HOTEL_PRICE_FAILURE,
  RESET_HOTEL_PRICES_REQUEST,
  RESET_HOTEL_PRICES_SUCCESS,
  RESET_HOTEL_PRICES_FAILURE,
  FETCH_HOTEL_CALLS_REQUEST,
  FETCH_HOTEL_CALLS_SUCCESS,
  FETCH_HOTEL_CALLS_FAILURE,
  SET_HOTEL_CALLS_FILTER,
  TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST,
  TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS,
  TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE,
  FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST,
  FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE,
  UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST,
  UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE,
  FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST,
  FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS,
  FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE,
  SET_HOTEL_ACTIVE_WAKEUPS_FILTER,
  DELETE_HOTEL_WAKEUP_FAILURE,
  DELETE_HOTEL_WAKEUP_SUCCESS,
  DELETE_HOTEL_WAKEUP_REQUEST,
  SET_HOTEL_ARCHIVE_WAKEUPS_FILTER,
  FETCH_HOTEL_WAKEUP_ANSWER_REQUEST,
  FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS,
  FETCH_HOTEL_WAKEUP_ANSWER_FAILURE,
  SAVE_HOTEL_WAKEUP_REQUEST,
  SAVE_HOTEL_WAKEUP_SUCCESS,
  SAVE_HOTEL_WAKEUP_FAILURE,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS,
  FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE,
} from './types';

export const fetchHotelAreaPrefixesRequest = () => ({
  type: FETCH_HOTEL_AREA_PREFIXES_REQUEST,
});
export const fetchHotelAreaPrefixesSuccess = (prefixes) => ({
  type: FETCH_HOTEL_AREA_PREFIXES_SUCCESS,
  prefixes,
});
export const fetchHotelAreaPrefixesFailure = (errors) => ({
  type: FETCH_HOTEL_AREA_PREFIXES_FAILURE,
  errors,
});
export const fetchHotelPricesRequest = () => ({
  type: FETCH_HOTEL_PRICES_REQUEST,
});
export const fetchHotelPricesSuccess = (prices) => ({
  type: FETCH_HOTEL_PRICES_SUCCESS,
  prices,
});
export const fetchHotelPricesFailure = (errors) => ({
  type: FETCH_HOTEL_PRICES_FAILURE,
  errors,
});
export const saveHotelPriceRequest = (price) => ({
  type: SAVE_HOTEL_PRICES_REQUEST,
  price,
});
export const saveHotelPriceSuccess = (price) => ({
  type: SAVE_HOTEL_PRICES_SUCCESS,
  price,
});
export const saveHotelPriceFailure = (errors) => ({
  type: SAVE_HOTEL_PRICES_FAILURE,
  errors,
});
export const deleteHotelPriceRequest = (id) => ({
  type: DELETE_HOTEL_PRICE_REQUEST,
  id,
});
export const deleteHotelPriceSuccess = (id) => ({
  type: DELETE_HOTEL_PRICE_SUCCESS,
  id,
});
export const deleteHotelPriceFailure = (errors) => ({
  type: DELETE_HOTEL_PRICE_FAILURE,
  errors,
});
export const resetHotelPricesRequest = () => ({
  type: RESET_HOTEL_PRICES_REQUEST,
});
export const resetHotelPricesSuccess = () => ({
  type: RESET_HOTEL_PRICES_SUCCESS,
});
export const resetHotelPricesFailure = (errors) => ({
  type: RESET_HOTEL_PRICES_FAILURE,
  errors,
});
export const fetchHotelCallsRequest = (params) => ({
  type: FETCH_HOTEL_CALLS_REQUEST,
  params,
});
export const fetchHotelCallsSuccess = (calls) => ({
  type: FETCH_HOTEL_CALLS_SUCCESS,
  calls,
});
export const fetchHotelCallsFailure = (error) => ({
  type: FETCH_HOTEL_CALLS_FAILURE,
  error,
});
export const setHotelCallsFilter = (filter) => ({
  type: SET_HOTEL_CALLS_FILTER,
  filter,
});
export const toggleHotelWakeupServiceRequest = (enabled) => ({
  type: TOGGLE_HOTEL_WAKEUP_SERVICE_REQUEST,
  enabled,
});
export const toggleHotelWakeupServiceSuccess = (enabled) => ({
  type: TOGGLE_HOTEL_WAKEUP_SERVICE_SUCCESS,
  enabled,
});
export const toggleHotelWakeupServiceFailure = (error) => ({
  type: TOGGLE_HOTEL_WAKEUP_SERVICE_FAILURE,
  error,
});
export const fetchHotelWakeupSettingsRequest = () => ({
  type: FETCH_HOTEL_WAKEUP_SETTINGS_REQUEST,
});
export const fetchHotelWakeupSettingsSuccess = (settings) => ({
  type: FETCH_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  settings,
});
export const fetchHotelWakeupSettingsFailure = (error) => ({
  type: FETCH_HOTEL_WAKEUP_SETTINGS_FAILURE,
  error,
});
export const updateHotelWakeupSettingsRequest = (settings) => ({
  type: UPDATE_HOTEL_WAKEUP_SETTINGS_REQUEST,
  settings,
});
export const updateHotelWakeupSettingsSuccess = (settings) => ({
  type: UPDATE_HOTEL_WAKEUP_SETTINGS_SUCCESS,
  settings,
});
export const updateHotelWakeupSettingsFailure = (error) => ({
  type: UPDATE_HOTEL_WAKEUP_SETTINGS_FAILURE,
  error,
});
export const fetchHotelActiveWakeupsRequest = (params) => ({
  type: FETCH_HOTEL_ACTIVE_WAKEUPS_REQUEST,
  params,
});
export const fetchHotelActiveWakeupsSuccess = (wakeups) => ({
  type: FETCH_HOTEL_ACTIVE_WAKEUPS_SUCCESS,
  wakeups,
});
export const fetchHotelActiveWakeupsFailure = (error) => ({
  type: FETCH_HOTEL_ACTIVE_WAKEUPS_FAILURE,
  error,
});
export const fetchHotelArchiveWakeupsRequest = (params) => ({
  type: FETCH_HOTEL_ARCHIVE_WAKEUPS_REQUEST,
  params,
});
export const fetchHotelArchiveWakeupsSuccess = (wakeups) => ({
  type: FETCH_HOTEL_ARCHIVE_WAKEUPS_SUCCESS,
  wakeups,
});
export const fetchHotelArchiveWakeupsFailure = (error) => ({
  type: FETCH_HOTEL_ARCHIVE_WAKEUPS_FAILURE,
  error,
});
export const saveHotelWakeupRequest = (wakeup) => ({
  type: SAVE_HOTEL_WAKEUP_REQUEST,
  wakeup,
});
export const saveHotelWakeupSuccess = (wakeup) => ({
  type: SAVE_HOTEL_WAKEUP_SUCCESS,
  wakeup,
});
export const saveHotelWakeupFailure = (error) => ({
  type: SAVE_HOTEL_WAKEUP_FAILURE,
  error,
});
export const deleteHotelWakeupRequest = (ids) => ({
  type: DELETE_HOTEL_WAKEUP_REQUEST,
  ids,
});
export const deleteHotelWakeupSuccess = (id) => ({
  type: DELETE_HOTEL_WAKEUP_SUCCESS,
  id,
});
export const deleteHotelWakeupFailure = (errors) => ({
  type: DELETE_HOTEL_WAKEUP_FAILURE,
  errors,
});
export const setHotelActiveWakeupsFilter = (filter) => ({
  type: SET_HOTEL_ACTIVE_WAKEUPS_FILTER,
  filter,
});
export const setHotelArchiveWakeupsFilter = (filter) => ({
  type: SET_HOTEL_ARCHIVE_WAKEUPS_FILTER,
  filter,
});
export const fetchHotelWakeupAnswerRequest = (params) => ({
  type: FETCH_HOTEL_WAKEUP_ANSWER_REQUEST,
  params,
});
export const fetchHotelWakeupAnswerSuccess = (answer) => ({
  type: FETCH_HOTEL_WAKEUP_ANSWER_SUCCESS,
  answer,
});
export const fetchHotelWakeupAnswerFailure = (error) => ({
  type: FETCH_HOTEL_WAKEUP_ANSWER_FAILURE,
  error,
});
