import { call, put, takeLatest } from 'redux-saga/effects';
import api from '../api';
import { checkApiResponse, checkApiError } from '../rootSaga';
import {
  deletePhoneExternalUrlFailure,
  deletePhoneExternalUrlSuccess,
  editPhoneExternalUrlFailure,
  editPhoneExternalUrlSuccess,
  fetchPhoneExternalUrlFailure,
  fetchPhoneExternalUrlRequest,
  fetchPhoneExternalUrlSuccess,
  insertPhoneExternalUrlFailure,
  insertPhoneExternalUrlSuccess,
} from './actions';
import {
  DELETE_PHONE_EXTERNAL_URL_REQUEST,
  EDIT_PHONE_EXTERNAL_URL_REQUEST,
  FETCH_PHONE_EXTERNAL_URL_REQUEST,
  INSERT_PHONE_EXTERNAL_URL_REQUEST,
} from './types';

export function* fetchExternalUrl(action) {
  try {
    const res = yield call(
      api.externalUrls.getExternalUrls,
      action.user ? { user: action.user } : null
    );
    yield call(checkApiResponse, res);

    yield put(fetchPhoneExternalUrlSuccess(res.data));
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(fetchPhoneExternalUrlFailure(error));
  }
}

export function* deleteExternalUrl(action) {
  try {
    const res = yield call(api.externalUrls.deleteExternalUrl, action.id);
    yield call(checkApiResponse, res);
    yield put(deletePhoneExternalUrlSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchPhoneExternalUrlRequest(action.user));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(deletePhoneExternalUrlFailure(error));
  }
}

export function* insertExternalUrl(action) {
  try {
    const res = yield call(api.externalUrls.insertExternalUrl, action.payload);
    yield call(checkApiResponse, res);
    yield put(insertPhoneExternalUrlSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchPhoneExternalUrlRequest(action.payload.user));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error)
      yield put(
        insertPhoneExternalUrlFailure(err.data.error ? err.data.error : error)
      );
  }
}

export function* editExternalUrl(action) {
  try {
    const res = yield call(api.externalUrls.editExternalUrl, action.payload);
    yield call(checkApiResponse, res);
    yield put(editPhoneExternalUrlSuccess(res.data));
    if (res.status === 200) {
      yield put(fetchPhoneExternalUrlRequest(action.payload.data.user));
    }
  } catch (err) {
    const error = yield call(checkApiError, err);
    if (error) yield put(editPhoneExternalUrlFailure(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_PHONE_EXTERNAL_URL_REQUEST, fetchExternalUrl);
  yield takeLatest(DELETE_PHONE_EXTERNAL_URL_REQUEST, deleteExternalUrl);
  yield takeLatest(INSERT_PHONE_EXTERNAL_URL_REQUEST, insertExternalUrl);
  yield takeLatest(EDIT_PHONE_EXTERNAL_URL_REQUEST, editExternalUrl);
}
