import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import {
  ChatEnums,
  retrieveInterlocutorIdFromConversationId,
} from '../../../js/chat/ChatUtils';
import {
  getInterlocutorName,
  getUnreadMessagesByConversation,
} from '../../../js/chat/selectors';
import Icon from '../../../components/icons/Icon';
import Input from '../../../components/formControls/Input';
import Utils from '../../../js/lib/utils';
import ChatWindowHeaderAvatar from './ChatWindowHeaderAvatar';
import { isWsAvailable } from '../../../js/auth/selectors';
import { getUserById } from '../../../js/users/selectors';

const messages = defineMessages({
  minimize: {
    id: 'ChatWindowHeader.tooltip.minimize',
    defaultMessage: 'Minimize',
  },
  normalize: {
    id: 'ChatWindowHeader.tooltip.normalize',
    defaultMessage: 'Normalize',
  },
  maximize: {
    id: 'ChatWindowHeader.tooltip.maximize',
    defaultMessage: 'Maximize',
  },
  close: {
    id: 'ChatWindowHeader.tooltip.close',
    defaultMessage: 'Close',
  },
  mainChatName: {
    id: 'ChatWindowHeader.mainChatName',
    defaultMessage: 'All users',
  },
});

class ChatWindowHeader extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeInterlocutorName = this.onChangeInterlocutorName.bind(this);
    this.onKeyPressInterlocutorName =
      this.onKeyPressInterlocutorName.bind(this);
    this.onBlurInterlocutorName = this.onBlurInterlocutorName.bind(this);
    this.state = {
      interlocutorName: null,
    };
  }

  onChangeInterlocutorName = (e) => {
    this.setState({
      interlocutorName: e.target.value,
    });
  };

  onKeyPressInterlocutorName = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.handleRenameGroup(
        this.state.interlocutorName &&
          this.state.interlocutorName.trim() &&
          this.state.interlocutorName !== this.props.interlocutorName
          ? this.state.interlocutorName
          : null
      );
      this.setState({
        interlocutorName: null,
      });
    }
  };

  onBlurInterlocutorName = () => {
    this.props.handleRenameGroup(
      this.state.interlocutorName &&
        this.state.interlocutorName.trim() &&
        this.state.interlocutorName !== this.props.interlocutorName
        ? this.state.interlocutorName
        : null
    );
    this.setState({
      interlocutorName: null,
    });
  };

  handleInterlocutorName = () =>
    Utils.getValorizedVariable(
      this.state.interlocutorName,
      this.props.interlocutorName
    );

  minimizeWindow = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.props.minimizeWindow();
  };

  maximizeWindow = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.props.maximizeWindow();
  };

  normalizeWindow = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.props.normalizeWindow();
  };

  closeWindow = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.props.closeWindow();
  };

  render = () => {
    const {
      size,
      width,
      isActive,
      isMain,
      conversationId,
      interlocutorName,
      // closeWindow,
      renamingEnabled,
      chatUnavailable,
      intl: { formatMessage },
    } = this.props;

    return (
      <nav
        className="rounded-top navbar p-1"
        style={{
          background: chatUnavailable
            ? 'var(--yn-gray-500)'
            : isActive
            ? 'var(--yn-blue)'
            : 'var(--yn-blue-dark)',
          height: '36px',
        }}
      >
        <span
          className="text-truncate d-inline-block position-relative"
          style={{ width: `${width - 80}px`, color: 'var(--white)' }}
        >
          <ChatWindowHeaderAvatar conversationId={conversationId} />
          {isMain ? (
            <span className="ml-2" style={{ color: 'var(--white)' }}>
              {formatMessage(messages.mainChatName)}
            </span>
          ) : renamingEnabled ? (
            <Input
              type="text"
              className="float-right"
              style={{ width: `${width - 110}px`, height: '29px' }}
              name={`${interlocutorName}_header`}
              id={`${interlocutorName}_header`}
              value={this.handleInterlocutorName()}
              onChange={this.onChangeInterlocutorName}
              onKeyPress={this.onKeyPressInterlocutorName}
              onBlur={this.onBlurInterlocutorName}
            />
          ) : (
            <span className="ml-2" style={{ color: 'var(--white)' }}>
              {this.handleInterlocutorName()}
            </span>
          )}
        </span>
        <ul className="nav justify-content-end">
          {size !== ChatEnums.ChatWindowSize.MIN && (
            <li className="nav-item  pr-1">
              <span onClick={this.minimizeWindow}>
                <Icon width={16} name="subtract-1-filled" color="var(--white)">
                  {formatMessage(messages.minimize)}
                </Icon>
              </span>
            </li>
          )}
          {size !== ChatEnums.ChatWindowSize.NORMAL && (
            <li className="nav-item  pr-1">
              <span onClick={this.normalizeWindow}>
                <Icon width={16} name="frame-1-filled" color="var(--white)">
                  {formatMessage(messages.normalize)}
                </Icon>
              </span>
            </li>
          )}
          {size !== ChatEnums.ChatWindowSize.BIG && (
            <li className="nav-item  pr-1">
              <span onClick={this.maximizeWindow}>
                <Icon width={16} name="expand-1-filled" color="var(--white)">
                  {formatMessage(messages.maximize)}
                </Icon>
              </span>
            </li>
          )}
          <li className="nav-item  pr-1">
            <span onClick={this.closeWindow}>
              <Icon width={16} name="close-filled" color="var(--white)">
                {formatMessage(messages.close)}
              </Icon>
            </span>
          </li>
        </ul>
      </nav>
    );
  };
}

ChatWindowHeader.propTypes = {
  conversationId: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  renamingEnabled: PropTypes.bool.isRequired,
  handleRenameGroup: PropTypes.func.isRequired,
  isMain: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const interlocutorId = retrieveInterlocutorIdFromConversationId(
    ownProps.conversationId
  ).userId;
  return {
    interlocutorName: getInterlocutorName(state, ownProps.conversationId),
    unreadMessages: getUnreadMessagesByConversation(
      state,
      ownProps.conversationId
    ),
    chatUnavailable: !isWsAvailable(state),
    user: getUserById(state, interlocutorId),
  };
}

export default injectIntl(connect(mapStateToProps)(ChatWindowHeader));
