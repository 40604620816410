import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { isContactFavorite } from '../../js/contacts/selectors';
import { addFavoriteRequest, removeFavoriteRequest } from '../../js/me/actions';
import { isUserFavorite } from '../../js/users/selectors';
import Icon from '../icons/Icon';
import { isFileFavorite } from '../../js/filebox/selectors';
import {
  addFileboxFavoriteFileRequest,
  removeFileboxFavoriteFileRequest,
} from '../../js/filebox/actions';

const messages = defineMessages({
  addFavorite: {
    id: 'FavoriteButton.tooltip.addFavorite',
    defaultMessage: 'Add to favorites',
  },
  removeFavorite: {
    id: 'FavoriteButton.tooltip.removeFavorite',
    defaultMessage: 'Remove from favorites',
  },
});

class FavoriteButton extends Component {
  toggleFavorite = () => {
    const {
      favorite,
      type,
      id,
      addFavorite,
      removeFavorite,
      addFavoriteFile,
      removeFavoriteFile,
    } = this.props;

    if (!favorite) {
      if (type === 'F') {
        addFavoriteFile({ id });
      } else {
        addFavorite({
          type,
          id,
        });
      }
    } else if (type === 'F') {
      removeFavoriteFile(id);
    } else {
      removeFavorite({
        type,
        id,
      });
    }
  };

  render() {
    const {
      id,
      width,
      height,
      loading,
      toggleing,
      favorite,
      intl: { formatMessage },
    } = this.props;
    return loading && toggleing === id ? (
      <Icon
        name="rank-army-star-2-filled"
        color="var(--white)"
        width={width}
        height={height}
      />
    ) : (
      <span onClick={this.toggleFavorite}>
        {favorite ? (
          <Icon
            name="rank-army-star-2-filled"
            width={width}
            height={height}
            color="var(--yellow)"
          >
            {formatMessage(messages.removeFavorite)}
          </Icon>
        ) : (
          <Icon
            name="rank-army-star-2"
            width={width}
            height={height}
            color="var(--yn-blue-dark)"
          >
            {formatMessage(messages.addFavorite)}
          </Icon>
        )}
      </span>
    );
  }
}

FavoriteButton.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['A', 'U', 'F']).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

FavoriteButton.defaultProps = {
  width: 22,
  height: 22,
  onClick: null,
};

function mapStateToProps(state, ownProps) {
  return {
    favorite:
      ownProps.type === 'U'
        ? isUserFavorite(state, ownProps.id)
        : ownProps.type === 'A'
        ? isContactFavorite(state, ownProps.id)
        : ownProps.type === 'F'
        ? isFileFavorite(state, ownProps.id)
        : false,
    loading: !state.groups.toggleFavorite.loaded,
    toggleing: state.groups.toggleFavorite.id,
  };
}

export default injectIntl(
  connect(mapStateToProps, {
    addFavorite: addFavoriteRequest,
    removeFavorite: removeFavoriteRequest,
    addFavoriteFile: addFileboxFavoriteFileRequest,
    removeFavoriteFile: removeFileboxFavoriteFileRequest,
  })(FavoriteButton)
);
