import React from 'react';
import ChatWindow from './ChatWindow';
import ErrorBoundaryEmpty from '../../../components/ErrorBoundaryEmpty';

const ChatWindowPage = (props) => (
  <ErrorBoundaryEmpty>
    <ChatWindow {...props} />
  </ErrorBoundaryEmpty>
);

export default ChatWindowPage;
