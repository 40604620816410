import React from 'react';
import BackButton from '../../../components/buttons/BackButton';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';
import PresencesManagement from './index';

const TemplateExportEdit = React.lazy(() => import('./TemplateExportEdit'));

const TemplateExportEditPage = (props) => {
  return (
    <PresencesManagement scrolling>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <div className="row mb-2">
            <div className="col text-left">
              <BackButton />
            </div>
            <div className="col text-right">
              <HelpButton fileName="presences-export-template-new" />
            </div>
          </div>
          <TemplateExportEdit {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default TemplateExportEditPage;
