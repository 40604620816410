import { request } from '../request';

export default {
  getPaymentsCredits: () =>
    request({
      url: '/payments/credits',
      method: 'GET',
    }),
  updatePaymentsEmail: (params) =>
    request({
      url: '/payments/emails',
      method: 'POST',
      data: params,
    }),
  getPurchases: (params) =>
    request({
      url: '/payments/purchases',
      method: 'GET',
      params,
    }),
  pay: (params) =>
    request({
      url: '/paypal/complete',
      method: 'POST',
      data: params,
    }),
  getDenominations: () =>
    request({
      url: '/payments/denominations',
      method: 'GET',
    }),
};
