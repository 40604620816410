import { takeLatest, call, put, select } from 'redux-saga/effects';
import { EXEC_VOCALASSISTANT_ACTION, FIND_CALL_NUMBER_REQUEST } from './types';
import { getUsers } from '../users/selectors';
import api from '../api';
import { findCallNumberSuccess, findCallNumberFailure } from './actions';
import { addressbookGrant } from '../me/selectors';

export function* execAction(action) {
  try {
    yield put(action.payload.action(action.payload.params));
  } catch (err) {
    // TODO
  }
}

export function* findCallNumber(action) {
  try {
    if (!action.payload.calls || !action.payload.type) {
      return null;
    }

    let find = null;
    for (let i = 0; i < action.payload.calls.length; i += 1) {
      const number = action.payload.calls[i][action.payload.type];
      const users = yield select(getUsers);
      const user = users.filter((u) => u.mainExtensionNumber === number);
      if (user.length === 0) {
        const callGrant = yield select(addressbookGrant);
        if (callGrant) {
          const contact = yield call(api.contacts.getContactFromNumber, {
            number,
          });
          if (contact.status === 204 || contact.data.length > 0) {
            find = number;
            break;
          }
        } else {
          find = number;
        }
      }
    }
    if (find) {
      yield put(findCallNumberSuccess(find));
    } else {
      yield put(findCallNumberSuccess(''));
    }
  } catch (err) {
    yield put(findCallNumberFailure());
  }
}

export default function* rootSaga() {
  yield takeLatest(EXEC_VOCALASSISTANT_ACTION, execAction);
  yield takeLatest(FIND_CALL_NUMBER_REQUEST, findCallNumber);
}
