import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { injectIntl } from 'react-intl';
import SecondaryNavItem from './SecondaryNavItem';
import SecondaryNavItemDropdown from './SecondaryNavItemDropdown';

const style = {
  padding: 0,
  position: 'relative',
  height: 'var(--yn-secondary-nav-height)',
};

class SecondaryNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMaxWidth: 'unset',
    };
    this.targetRef = React.createRef();
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this));
  }

  handleWindowResize = () => {
    if (!this.targetRef.current || !this.targetRef.current.clientWidth) return;
    this.setState({
      tabMaxWidth: `${
        Math.floor(
          this.targetRef.current.clientWidth / this.props.tabs.length
        ) - 35
      }px`,
    });
  };

  render() {
    const {
      tabs,
      intl: { formatMessage },
    } = this.props;

    return (
      <div style={style} ref={this.targetRef}>
        <Nav tabs className="yn-nav-tabs">
          {tabs.map((tab, idx) =>
            tab.subNav && tab.subNav.length > 0 ? (
              <SecondaryNavItemDropdown
                key={idx}
                tab={{ ...tab, label: formatMessage(tab.label) }}
                width={this.state.tabMaxWidth}
                navs={tab.subNav}
              />
            ) : (
              <SecondaryNavItem
                key={idx}
                tab={{ ...tab, label: formatMessage(tab.label) }}
                width={this.state.tabMaxWidth}
              />
            )
          )}
        </Nav>
      </div>
    );
  }
}

SecondaryNav.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default injectIntl(SecondaryNav);
