import {
  FETCH_CUSTOMER_CONTRACT_DATA_REQUEST,
  FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS,
  FETCH_CUSTOMER_CONTRACT_DATA_FAILURE,
  FETCH_CUSTOMER_BILLS_REQUEST,
  FETCH_CUSTOMER_BILLS_SUCCESS,
  FETCH_CUSTOMER_BILLS_FAILURE,
  FETCH_CUSTOMER_PHONE_LINES_REQUEST,
  FETCH_CUSTOMER_PHONE_LINES_SUCCESS,
  FETCH_CUSTOMER_PHONE_LINES_FAILURE,
  FETCH_CUSTOMER_CONTRACTS_REQUEST,
  FETCH_CUSTOMER_CONTRACTS_SUCCESS,
  FETCH_CUSTOMER_CONTRACTS_FAILURE,
  FETCH_CONTRACT_NUMBERS_REQUEST,
  FETCH_CONTRACT_NUMBERS_SUCCESS,
  FETCH_CONTRACT_NUMBERS_FAILURE,
  FETCH_CONTRACT_TRAFFIC_REQUEST,
  FETCH_CONTRACT_TRAFFIC_SUCCESS,
  FETCH_CONTRACT_TRAFFIC_FAILURE,
  DELETE_CONTRACT_TRAFFIC,
  FETCH_CUSTOMER_SERVICES_REQUEST,
  FETCH_CUSTOMER_SERVICES_SUCCESS,
  FETCH_CUSTOMER_SERVICES_FAILURE,
} from './types';

/** documents */
export const fetchCustomerContractDataRequest = () => ({
  type: FETCH_CUSTOMER_CONTRACT_DATA_REQUEST,
});
export const fetchCustomerContractDataSuccess = (payload, success) => ({
  type: FETCH_CUSTOMER_CONTRACT_DATA_SUCCESS,
  payload,
  success,
});
export const fetchCustomerContractDataFailure = (errors) => ({
  type: FETCH_CUSTOMER_CONTRACT_DATA_FAILURE,
  errors,
});

/** Bills */
export const fetchCustomerBillsRequest = (params) => ({
  type: FETCH_CUSTOMER_BILLS_REQUEST,
  payload: params,
});
export const fetchCustomerBillsSuccess = (payload, success) => ({
  type: FETCH_CUSTOMER_BILLS_SUCCESS,
  payload,
  success,
});
export const fetchCustomerBillsFailure = (errors) => ({
  type: FETCH_CUSTOMER_BILLS_FAILURE,
  errors,
});

/** Phone lines */
export const fetchCustomerPhoneLinesRequest = (params) => ({
  type: FETCH_CUSTOMER_PHONE_LINES_REQUEST,
  payload: params,
});
export const fetchCustomerPhoneLinesSuccess = (payload, success) => ({
  type: FETCH_CUSTOMER_PHONE_LINES_SUCCESS,
  payload,
  success,
});
export const fetchCustomerPhoneLinesFailure = (errors) => ({
  type: FETCH_CUSTOMER_PHONE_LINES_FAILURE,
  errors,
});

/** Customer contracts */
export const fetchCustomerContractsRequest = (params) => ({
  type: FETCH_CUSTOMER_CONTRACTS_REQUEST,
  payload: params,
});
export const fetchCustomerContractsSuccess = (payload, success) => ({
  type: FETCH_CUSTOMER_CONTRACTS_SUCCESS,
  payload,
  success,
});
export const fetchCustomerContractsFailure = (errors) => ({
  type: FETCH_CUSTOMER_CONTRACTS_FAILURE,
  errors,
});

/** Customer numbers */
export const fetchCustomerNumbersRequest = (id) => ({
  type: FETCH_CONTRACT_NUMBERS_REQUEST,
  id,
});
export const fetchCustomerNumbersSuccess = (payload, success) => ({
  type: FETCH_CONTRACT_NUMBERS_SUCCESS,
  payload,
  success,
});
export const fetchCustomerNumbersFailure = (errors) => ({
  type: FETCH_CONTRACT_NUMBERS_FAILURE,
  errors,
});

/** Customer traffic */
export const fetchCustomerTrafficRequest = (id, params) => ({
  type: FETCH_CONTRACT_TRAFFIC_REQUEST,
  id,
  payload: params,
});
export const fetchCustomerTrafficSuccess = (payload, success) => ({
  type: FETCH_CONTRACT_TRAFFIC_SUCCESS,
  payload,
  success,
});
export const fetchCustomerTrafficFailure = (errors) => ({
  type: FETCH_CONTRACT_TRAFFIC_FAILURE,
  errors,
});

export const deleteContractTraffic = () => ({
  type: DELETE_CONTRACT_TRAFFIC,
});

/** Customer services */
export const fetchCustomerServicesRequest = (id, params) => ({
  type: FETCH_CUSTOMER_SERVICES_REQUEST,
  id,
  payload: params,
});
export const fetchCustomerServicesSuccess = (payload, success) => ({
  type: FETCH_CUSTOMER_SERVICES_SUCCESS,
  payload,
  success,
});
export const fetchCustomerServicesFailure = (errors) => ({
  type: FETCH_CUSTOMER_SERVICES_FAILURE,
  errors,
});
