import React from 'react';
import Tickets from './index';
import ErrorBoundary from '../../../components/ErrorBoundary';
const HelpButton = React.lazy(() => import('../../../components/HelpButton'));
const TicketsDashboard = React.lazy(() => import('./TicketsDashboard'));
const TicketsDashboardPage = () => {
  return (
    <Tickets>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <>
            <div className="text-right mb-2">
              <HelpButton fileName="tickets-dashboard" />
            </div>
            <TicketsDashboard />
          </>
        </React.Suspense>
      </ErrorBoundary>
    </Tickets>
  );
};

export default TicketsDashboardPage;
