import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import PresencesManagement from './index';

const TemplateExportList = React.lazy(() => import('./TemplateExportList'));

const TemplateExportShowPage = (props) => {
  return (
    <PresencesManagement>
      <ErrorBoundary>
        <React.Suspense fallback={<div />}>
          <TemplateExportList {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </PresencesManagement>
  );
};

export default TemplateExportShowPage;
